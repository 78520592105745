import React from "react";

export class VideoPreview extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.onVideoClickHandler = this.onVideoClickHandler.bind(this);
    }
    componentDidMount(){
        const video = this.videoRef.current;
       video.currentTime = this.props.currentTime ? this.props.currentTime : 0
       if(this.props.currentTime){
        video.controls = false
        video.addEventListener('mouseover', () => { video.controls = 'controls' })
        video.addEventListener('mouseout', () => { video.controls = false })
       }
       
    }
    onVideoClickHandler(e) {
        e.preventDefault();
        const video = this.videoRef.current;
        // console.log(`%c video clicked!!`,'font-size:20px')
        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
        e.stopPropagation();
    }

    render() {
        return (

            <video
                muted={this.props.mute}
                loop
                playsInline
                controls={this.props.showControls}
                className={this.props.className}
                width={this.props.width}
                ref={this.videoRef}
                onClick={this.onVideoClickHandler}
                autoPlay={this.props.play}
            >
                <source src={this.props.source} type={this.props.mimeType}/>
                Your browser does not support HTML video.
            </video>

        )

    }
}