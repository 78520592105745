import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'
import PubSub from 'pubsub-js';
import { creatorStoreActions, dropActions, userAction } from 'store/actions';
import history from 'helpers/history';
import InfiniteScroll from 'react-infinite-scroll-component';
import { configPath } from '../../config';
import { SolarSystemLoading } from 'react-loadingg';
import { ArtPreview } from 'components/art-preview/art-preview';
import { checkMintedTokens, checkTokenGroup, flowDecimal } from 'helpers';
import { babMintCount } from 'components/artdetail-view/bab-common-view';
import { Helmet } from 'react-helmet';
import { toast, Zoom } from 'react-toastify';
import { storeServices } from 'services';

class StoreDrops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artsList: [],
            offSet: 0,
            recordLimit: 20,
            sortBy: "updatedAt",
            sortOrder: "DESC",
            hasMore: true,
            loader: true,
            isCheckArt: false,
            isStarred: false,
            tooltipTextAdd: 'Starred NFT will be featured in banner section of your store.',
            tooltipTextRemove: 'Click here to remove featuring'
        }
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.callApi()
        this.gotoTop()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    componentDidUpdate(props, state) {
        if ((this.props &&
            this.props.artsList &&
            this.props.artsList.statusCode === 200) &&
            (this.props.artsList) !== (props.artsList)
        ) {
            const data = this.props && this.props.artsList && this.props.artsList.data

            this.props.resetGetArts()
            const page = Number(this.state.recordLimit) + Number(this.state.offSet)
            if (data.arts.length === 0 || (data.arts.length < (this.state.recordLimit))) {
                const data1 = state.artsList.concat((props.artsList && props.artsList.data && props.artsList.data.arts))
                this.setState({
                    hasMore: false,
                    loader: false,
                    artsList: data1,
                    offSet: page,
                    isCheckArt: props.isCheckArt,
                })

            }
            const data2 = this.state.artsList.concat((data && data.arts))
            this.setState({
                artsList: data2,
                offSet: page,
                isCheckArt: props.isCheckArt,
            })

        }
        if(this.props && this.props.artsListFailed && this.props.artsListFailed.length > 0 && 
            (this.props.artsListFailed !== props.artsListFailed)){
                this.props.resetGetUserArtsFailed()
                this.setState({
                    loader : false,
                    hasMore : false
                })
            }
        if(this.props && this.props.addedStar && this.props.addedStar.statusCode === 200 && (this.props.addedStar !== props.addedStar)){
            this.props.resetAddSuccess()
        }
        if(this.props && this.props.addstarFailed && this.props.addstarFailed.length > 0 && (this.props.addstarFailed !== props.addstarFailed)){
            this.props.resetAddFailed()
            toast.error(this.props.addstarFailed,{transition:Zoom})
            
        }
        if(this.props && this.props.removedStar && this.props.removedStar.statusCode === 200 && (this.props.removedStar !== props.removedStar)){
            this.props.resetremovesuccess()
        }
        if(this.props && this.props.removedStarFailed && this.props.removedStarFailed.length > 0 && (this.props.removedStarFailed !== props.removedStarFailed)){
            this.props.resetremoveFailed()
            toast.error(this.props.removedStarFailed,{transition:Zoom})
            
        }
    }
    callApi = () => {
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
        }
        this.props.getArts(obj)

    }
    showTotalEditions = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null)) {
            return ''
        } else {
            if ((items.isBab)) {
                return <p className="mb-0 small">1 of <span className="">{babMintCount}</span> Total Edition</p>
            } else {
                if (items && items.groups && items.groups != null) {
                    return <p className="mb-0 small"><span className="">{checkTokenGroup(items && items.groups, (items.toatalMintedTokens))}</span> of {(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {this.checkEDitionCount(items)}</p>
                } else {
                    <p className="mb-0 small">1 of <span className="">1</span> Total Edition</p>
                }
            }
        }

    }
    checkEDitionCount = (items) => {
        if(items && items.groups && items.groups !== null && items.toatalMintedTokens){
        if((checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1){
            return ' Total Editions'
        }else{
            return ' Total Edition'
        }
    }else{
        return ' Total Edition'
    }

    }
    gotoDropsDet = (items) => {
        if ((items && items.drops && items.drops.length > 0)){
            history.push(`/store/art/${items && items.tokenId}`)
        }

    }
    isHidden = (items) => {
        if(items && items.status && items.status === 2){
            return true
        }else{
            return false
        }
    }
    showPrice = (items) => {

        if(items && items.drops && items.drops[0] && items.drops[0].markedPrice) {
            return <h6 className="text-white " title={flowDecimal(parseFloat(items.drops[0].markedPrice))}>{flowDecimal(parseFloat(items.drops[0].markedPrice)) + ' USD'}</h6>

        }
    }
    refreshArts = () => {
      
        this.setState({

            offSet: 0,
            artsList: [],
            hasMore: true,
            loader: true,

        }, () => {
            this.callApi()
        })


    }
    handleToAddStar = (item,index) => {
        if(item && item.drops && item.drops[0] && item.drops[0].id){
            setTimeout(() => {
                this.setState({ tooltipTextRemove: 'Click here to remove featuring' });
            }, 1000);
            this.setState((state, props) => {
    
                state.artsList[index].drops[0].starred = true
                return {
                    artsList: state.artsList,
                    tooltipTextRemove: 'Featured'
                }
            })
            storeServices.storeupdateFeatureddropsArts(item.drops[0].id).then(res => {
                if(res && res.statusCode === 200){

                }
            }).catch(e => {
                toast.error(e,{transition:Zoom})
                this.setState((state, props) => {
    
                    state.artsList[index].drops[0].starred = false
                    return {
                        artsList: state.artsList,
                        tooltipTextRemove: 'Click here to remove featuring'
                    }
                })
            })
            
            
        }
    }
    handleToRemove = (item,index) => {
        if(item && item.drops && item.drops[0] && item.drops[0].id){
            setTimeout(() => {
                this.setState({ tooltipTextAdd: 'Starred NFT will be featured in banner section of your store.' });
            }, 1000);
            this.setState((state, props) => {
    
                state.artsList[index].drops[0].starred = false
                return {
                    artsList: state.artsList,
                    tooltipTextAdd: 'Removed'
                }
            })
            storeServices.removeFromStarredCollection(item.drops[0].id).then(res => {
                
            }).catch(e => {
                toast.error(e,{transition:Zoom})
                this.setState((state, props) => {
    
                    state.artsList[index].drops[0].starred = true
                    return {
                        artsList: state.artsList,
                        tooltipTextAdd: 'Starred NFT will be featured in banner section of your store.'
                    }
                })
            })
            
            
        }
    }
    render() {
        const { artsList, hasMore, loader } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>{"On Sale"}</title>
                    <meta name="title" content={`Explore NFT for sale`}></meta>
                    <meta name="description" content={`Explore NFT for sale`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit row">
                    <div className="col-md-6"><h1 className="mb-0">On Sale</h1></div>
                        <div className="col-md-6 text-right"><button type="button" className="btn btn-rounded btn-outline-dark btn-sm mr-0" onClick={this.refreshArts}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>

                    </div>
                    <div className="propagecont mb-5">
                        <div className="container1">
                            <InfiniteScroll
                                dataLength={artsList && artsList.length} //This is important field to render the next data
                                next={(artsList && artsList.length > 0) ? this.callApi : null}
                                hasMore={hasMore}
                                loader={
                                    <div className="solarloader" >
                                        <SolarSystemLoading />

                                    </div>}
                                endMessage={
                                    (artsList.length > 0) ?
                                        <p style={{ textAlign: 'center', fontSize: "15px" }} className="usernamelilac">
                                            <b>You have reached the end of list</b>
                                        </p> : (!loader && <div className="col-md-4 offset-md-4 text-center">
                                            <img src={`/${configPath.imageAssets}/noartyet-wt.png`} className="mw-100" alt="" />
                                        </div>)
                                }
                                style={{ overflow: "hidden" }}

                            >
                                <div className="ds-listing ds-infiniscroll">
                                    <div className="row">
                                        {artsList && artsList.map((items, index) => (
                                            <div className="col-sm-6 col-md-4 col-lg-3" key={index}  >
                                                <span className={this.isHidden(items) ? "ds-list hidden-arts" : 'ds-list'} title={(this.isHidden(items)) ? "Hidden" : ''}>
                                                    <ArtPreview src={items && items.thumbnailHash} alt={items && items.title} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />
                                                    {(items && items.drops && items.drops[0] && items.drops[0].starred) ? <span className='fastarbanner' onClick={() => this.handleToRemove(items,index)}>
                                                    
                                                    <i className="fa fa-star text-white" ></i> 
                                                    <span className='toottip'>{this.state.tooltipTextRemove}</span> 
                                                    </span> : <span className='fastarbanner' onClick={() => this.handleToAddStar(items,index)}>
                                                    
                                                    <i className="fa fa-star-o text-white"></i> 
                                                   <span className='toottip'>
                                                        {this.state.tooltipTextAdd}
                                                    </span>
                                                    </span>}

                                                    <span className="ds-list-details">
                                                   
                                                        <h4>{items && items.title}</h4>
                                                        <p>{this.showTotalEditions(items)}</p>
                                                        <p>{this.showPrice(items)}</p>
                                                       

                                                    </span>
                                                </span>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        artsList: state.creatorStore.getUserDrops,
        artsListFailed: state.creatorStore.getUserDropsFailed,
        addedStar: state.creatorStore.featuredDropUpdated,
        addstarFailed: state.creatorStore.getUserDropsFailed,
        removedStar: state.creatorStore.featuredDropUpdatedFailed,
        removedStarFailed: state.creatorStore.removefromFeaturedFailed
    }
}
const actionCreators = {
    resetGetUserArtsFailed: creatorStoreActions.resetGetUserDropsFailed,
    getArts: creatorStoreActions.storeGetUserDrop,
    resetGetArts: creatorStoreActions.resetGetUsersDropsSuccess,
    addStar: creatorStoreActions.storeupdateFeatureddropsArts,
    resetAddSuccess: creatorStoreActions.resetUpdateFeaturedDropSuccess,
    resetAddFailed: creatorStoreActions.resetUpdateFeaturedDropFailed,
    removeStar: creatorStoreActions.removeFromStarredCollection,
    resetremovesuccess: creatorStoreActions.resetRemoveFromStarSuccess,
    resetremoveFailed: creatorStoreActions.resetRemoveFromStarFailed


}
const connectStoreDrops = connect(mapStateToProps, actionCreators)(StoreDrops)
export { connectStoreDrops as StoreDrops }
