import { collectorConstants } from "../../constants";

const initialState = {
    collectorDetail:[],
    collectors : []
}
export function collector(state=initialState,action){
    switch(action.type){
        case collectorConstants.GET_DETAIL_COLLECTOR:
            return {
                collectorDetail : action.collectorDetail
            }
            case collectorConstants.GET_ALL_COLLECTORS:
                return {
                    collectors : action.collectors
                }
          
            
            default:
                return state
    }
}