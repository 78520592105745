export const followConstants = {
  
   GET_FOLLOWERS : 'GET_FOLLOWERS',
   GET_FOLLOWINGS : 'GET_FOLLOWINGS',
   FOLLOW : 'FOLLOW',
   FAILED_FOLLOW : 'FAILED_FOLLOW',
   UNFOLLOW : 'UNFOLLOW',
   CHECK_FOLLOW_USER : 'CHECK_FOLLOW_USER',
   FAILED_CHECK_FOLLOW : 'FAILED_CHECK_FOLLOW',
   GET_FOLLOWERS_FAILED : 'GET_FOLLOWERS_FAILED'
  
}