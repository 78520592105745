import React from "react";
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { configPath } from "../../config";
import { Helmet } from "react-helmet";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { toast, Zoom } from "react-toastify";

var timeout;


class BannerCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            imageHash: '',
            bannerImageHash: '',
            image: '',
            banner: '',
            submitted: false,
            bannerData: [
                // {title : "Firsr slide Firsr slide Firsr slide", subTitle : 'First slide Firsr slide',image :'/api/v1/user-store-management/user-store/2/logo/cc835c76-1665578157906.webp'}
            ],
            isShown: false
        };

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        // this.getProfile()
        this.gotoTop()
    }
    getProfile = () => {
        let userDetail = localStorage.getItem("webLogin")
        let user = JSON.parse(userDetail)
        if (user && user.data && user.data.authUser && user.data.authUser.uuid) {
            this.props.getBannerData(user.data.authUser.uuid)
        }
    }
    componentDidUpdate(prevProps, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        if (
            (this.props && this.props.bannerData && this.props.bannerData.statusCode === 200) &&
            (this.props.bannerData !== (prevProps && prevProps.bannerData))) {
            if (this.props && this.props.bannerData && this.props.bannerData.data) {
                let data = this.props && this.props.bannerData && this.props.bannerData.data && this.props.bannerData.data.store
                this.setState({
                    bannerData: this.props.bannerData.data,
                    title: data.name,
                    description: data.supportEmail,
                    banner: data.logoUrl ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.logoUrl}` : null,
                    bannerImageHash: data.logoUrl ? data.logoUrl : null,
                    storeId: data.id,

                });
            }
        }
        if (this.props &&
            this.props.bannerAddedFailed &&
            this.props.bannerAddedFailed.length > 0 &&
            (this.props.bannerAddedFailed !== prevProps.bannerAddedFailed)
        ) {
            this.props.resetAddBannerDataFailed()
            toast.error(this.props.bannerAddedFailed, { transition: Zoom })

        }
        if (this.props &&
            this.props.bannerAddedSuccess &&
            this.props.bannerAddedSuccess.statusCode === 200 &&
            (this.props.bannerAddedSuccess !== prevProps.bannerAddedSuccess)) {
            this.props.resetAddBannerData()
            toast.success("Banner Added", { transition: Zoom })
            this.setState({
                title:'',
                description : '',
                banner :'',
                bannerImageHash : '',
                bannerFileData : []
            })
            this.getProfile()
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.getprofileFailed &&
            props.getprofileFailed.length > 0) {
            props.resetGetprofileFailed()
            toast.error(props.getprofileFailed, { transition: Zoom })
        }
        return null
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }


    checkFullName = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }


    onTitleChange = (event) => {
        this.setState({
            title: event.target.value
        });
    }
    onDescChange = (e) => {
        this.setState({
            description: e.target.value
        });
    }

    onErrorHandle = (error) => {
        toast.error(error, { transition: Zoom })

    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { title, bannerFileData, storeId, description } = this.state
        if (title &&
            this.checkFullName(title) && description && this.checkFullName(description)
        ) {
            let formData = new FormData()
            formData.append("name", title);
            formData.append("description", description)
            // if (storeId) {
            //     formData.append('id', storeId)
            // } else {
            //     toast.error('Store id was missing', { transition: Zoom })
            //     return false
            // }
            if (bannerFileData) {
                formData.append("storeLogo", bannerFileData);
                this.props.addBanner(formData)

            }else{
                toast.error('Banner image was mandatory', { transition: Zoom })

            }

        } else {
            toast.error('Please enter valid data', { transition: Zoom })
        }

    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    async onBannerPicChanged(file) {
        const types = ['image/png', 'image/jpeg', 'image/gif']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        if (types.every(type => file && file.type !== type)) {

            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)


        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported cover file size is ${2} MB`;
            this.onErrorHandle(err)

        }
        else if (file) {

            const reader = new FileReader();
            reader.onload = (e) => {

                this.setState({
                    bannerFileData: file,
                    banner: reader.result
                })
            };
            reader.readAsDataURL(file)
        }
    }
    handleToggleForm = () => {
        this.setState({
            isShown: !this.state.isShown
        })
    }
    render() {
        console.log(this.state.bannerData)
        const bannerList = this.state.bannerData && this.state.bannerData.map((items, index) => {
            console.log(items)

            return <tbody>
                <tr>
                    <td><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.image && items.image.substring(1)}`} alt="" width={"100px"}></img></td>
                    <td><p>{items && items.title}</p></td>
                    <td>{items && items.subTitle ? items.subTitle : "-"}</td>
                    <td><button type="button" className="btn btn-primary" >Remove Banner</button></td>
                </tr>

            </tbody>

        })
        return (

            <div className="">
                <Helmet>
                    <title>Store Banner</title>
                    <meta name="title" content={`Update profile`}></meta>
                    <meta name="description" content={`Update profile`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit">
                        <h1 className="mb-0">Store Banner</h1>
                        <button type="button" className="btn btn-primary" onClick={this.handleToggleForm}>{this.state.isShown ? "Close Form" : "Add Banner"}</button>
                    </div>

                    {this.state.isShown && <div className="propagecont mb-5">
                        <div className="container1">
                            <div className="row mb-5">
                                <div className="coverimg coverbrd imgcovergif rounded-0">
                                    <img src={this.state.banner ? this.state.banner : `../${configPath.imageAssets}/cover-default.png`} alt="" />
                                    <span className="editcov">
                                        <span className="choosefile" title="Upload Cover Image">
                                            <input type="file" className="custom-file-input inp-box" id="bannerPic" accept="image/png, image/jpeg, image/webp, image/gif" onChange={(e) => {
                                                this.onBannerPicChanged(e.target.files[0])
                                            }} />

                                            <label htmlFor="bannerPic" className="btn btn-outline-dark w-auto px-3" >
                                                <i className="fas fa-pencil-alt"></i>
                                            </label>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="row sizecls small ">
                                <div className="col-12 text-center">
                                    <span className="usernamelilac ">Logo image size :</span> 400px x 400px
                                </div>

                            </div>
                            <form>

                                <div className="mt-0 mt-md-5 pt-md-3 row">

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="title" aria-describedby="title" placeholder="Enter store banner title" value={this.state.title} onChange={this.onTitleChange} />
                                                    <div className="input-floating-label"><span>Title</span></div>
                                                </div>
                                                {this.state.submitted && !this.state.title && <div className="mandatory">{"Title is Required"}</div>}
                                                {this.state.submitted && this.state.title && !this.checkFullName(this.state.title) && <div className="mandatory">{"Title must be at least 3 characters and atmost 100 characters"}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <textarea className="form-control" id="description" aria-describedby="description" placeholder="Enter store banner description" value={this.state.description} onChange={this.onDescChange} />
                                                    <div className="input-floating-label"><span>Description</span></div>
                                                </div>
                                                {this.state.submitted && !this.state.description && <div className="mandatory">{"Desctiption is Required"}</div>}
                                                {this.state.submitted && this.state.description && !this.checkFullName(this.state.description) && <div className="mandatory">{"Description must be at least 3 characters and atmost 100 characters"}</div>}
                                            </div>

                                        </div>



                                    </div>


                                    <div className="text-center col-md-12">
                                        <hr className="lightline" />
                                        <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>}
                    <div className="table-responsive d-flex align-items-center justify-content-center h-100">
                        <table className="table table-bordered table-striped-cusom">
                            <thead>
                                <tr>
                                    <th>Banner Image</th>
                                    <th>Title</th>
                                    <th>Sub Tilte</th>
                                    <th>Delete</th>

                                </tr>
                            </thead>

                            {bannerList}

                        </table>
                    </div>
                    {this.state.bannerData && this.state.bannerData.length === 0 && <div className="text-center">No Banners Yet</div>}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        bannerData: state.creatorStore.getBannerData,
        bannerDataFailed: state.creatorStore.getBannerDataFailed,
        bannerAddedSuccess : state.creatorStore.bannedadded,
        bannerAddedFailed : state.creatorStore.bannerAddedFailed,
        bannerDeleted : state.creatorStore.deletedBanner,
        bannerDeleteFailed : state.creatorStore.deletedBannerFailed,

    }
}

const actionCreators = {
    getBannerData: creatorStoreActions.storeGetBannerdata,
    resetGetBannerData: creatorStoreActions.resetGetBannerSuccess,
    resetGetBannerDataFailed: creatorStoreActions.resetGetBannerFailed,
    addBanner : creatorStoreActions.storeAddBannerdata,
    resetAddBannerData : creatorStoreActions.resetAddBannerSuccess,
    resetAddBannerDataFailed : creatorStoreActions.resetAddBannerFailed,
    deleteBanner : creatorStoreActions.storeDeleteBannerData,
    resetDeleteBannerSuccess : creatorStoreActions.resetDeleteBannerSuccess,
    resetDeleteBannerFailes : creatorStoreActions.resetDeleteBannerFailed
}

const connectBannerCollection = connect(mapStateToProps, actionCreators)(BannerCollection)

export { connectBannerCollection as BannerCollection }