export const transactionConstants = {
  
    GET_TRANSACTION_HISTORY : 'GET_TRANSACTION_HISTORY',
    GET_TRANSACTION_HISTOTY_FAILED : 'GET_TRANSACTION_HISTOTY_FAILED',
    GET_TRANSACTION_DETAIL : 'GET_TRANSACTION_DETAIL',

    ///Drop-History-management
    FORSALE_EVENT_HISTORY_INITIATED : 'FORSALE_EVENT_HISTORY_INITIATED',
    FORSALE_EVENT_HISTORY_INITIATED_FAILED : 'FORSALE_EVENT_HISTORY_INITIATED_FAILED',

    FORSALE_EVENT_HISTORY_CANCELLED : 'FORSALE_EVENT_HISTORY_CANCELLED',
    FORSALE_EVENT_HISTORY_CANCELLED_FAILED : 'FORSALE_EVENT_HISTORY_CANCELLED_FAILED',

    FORSALE_EVENT_HISTORY_FAILURE : 'FORSALE_EVENT_HISTORY_FAILURE',
    FORSALE_EVENT_HISTORY_FAILURE_FAILED : 'FORSALE_EVENT_HISTORY_FAILURE_FAILED',

    FORSALE_EVENT_HISTORY_TRANSFER_COMPLETE : 'FORSALE_EVENT_HISTORY_TRANSFER_COMPLETE',
    FORSALE_EVENT_HISTORY_TRANSFER_FAILED : 'FORSALE_EVENT_HISTORY_TRANSFER_FAILED',

    FORSALE_EVENT_HISTORY_LISTING_SUCCESS : 'FORSALE_EVENT_HISTORY_LISTING_SUCCESS',
    FORSALE_EVENT_HISTORY_LISTING_FAILED : 'FORSALE_EVENT_HISTORY_LISTING_FAILED',

    FORSALE_EVENT_HISTORY_LIST_STAGING : 'FORSALE_EVENT_HISTORY_LIST_STAGING',
    FORSALE_EVENT_HISTORY_LIST_STAGING_FAILED : 'FORSALE_EVENT_HISTORY_LIST_STAGING_FAILED'


   
 }