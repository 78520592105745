export const AirDropStatus = {

    HIDDEN : 0,

    ACTIVE : 1,

    DONE : 2,

    WITHDRAWN : 3

}