import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { eventActions, midwestActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { debounce } from 'utils';
import { ArtPreview } from 'components/art-preview/art-preview';
import { configPath } from '../../../config';
import { VideoPreview } from 'components/video-player/video-preview';


class MidwestFetchEventsGallery extends Component {
    constructor(props) {
        super(props);
      
        this.state = {
            events: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 20,
            pageCount: '',
            isFetched: false,
            search: '',
            selectedEvtType: -1,
            eventsGallery : [],
            selectType : 0
        }
        this.videoRef = React.createRef();

        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.props.getAllEvents()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.alleventGallery && props.alleventGallery.statusCode === 200)) {
            props.resetAllMidwestEventsGallery()
            return {
                eventsGallery: props.alleventGallery && props.alleventGallery.data && props.alleventGallery.data.gallery ? props.alleventGallery.data.gallery : [],
                totalCount: props.alleventGallery && props.alleventGallery.data && props.alleventGallery.data.count,
                pageCount: props.alleventGallery && props.alleventGallery.data && props.alleventGallery.data.count / state.recordLimit,
                isFetched: true
            }
        }
        
        
        return null

    }
    componentDidUpdate(props,state){
        if((this.props && this.props.allevents && this.props.allevents.statusCode === 200) && (this.props.allevents) !== (props.allevents)){
            props.resetEvents()
            const allevents = (this.props && this.props.allevents && this.props.allevents.data && this.props.allevents.data.conventions)
            if(allevents && allevents[0] && allevents[0].id){
                this.callApi(allevents && allevents[0] && allevents[0].id)
                this.setState({selectType : allevents[0].id})
            }
            const allTypes = []
            allevents && allevents.length > 0 && allevents.map(items => {
                let obj = {
                    event: items && items.name,
                    value: items && items.id
                }
                allTypes.push(obj)

            })
            this.setState({events : allTypes})
        }
        if(((this.props && this.props.deletedEvent && this.props.deletedEvent.statusCode) === 200) && (this.props.deletedEvent) !== (props.deletedEvent)){
            this.props.resetDeleteEvent()
            this.callApi(this.state.selectType)
        }
    }
    handleRefresh = () => {
        this.setState({ offSet: 0, forcePage: 0,search : '', selectedEvtType: -1 }, () => {
            this.callApi(this.state.selectType)
        })
    }


    callApi = (id) => {
        const { search, selectedEvtType } = this.state;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy : 'createdAt',
            sortOrder : 'DESC'
        }
        if (search) {
            obj.search = search;
        }
        if (Number.isInteger(selectedEvtType) && selectedEvtType !== -1) {
            obj.eventType = selectedEvtType;
        }
        this.props.getAllEventsGallery(obj,id)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi(this.state.selectType))

    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
        // console.log(e.target.value)
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

  
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    
   
    gotoAddEvent = () => {
        history.push('/admin/midwestEventsGallery/add')
    }
    editAccount = (items) => {
        if ((items && items.id)) {
            history.push(`/admin/midwestEvent/edit/${items.id}`)
        }
    }
    handleDropDown = (e) => {
        this.setState({selectType : e.target.value,offset:0},() => {
            this.callApi(e.target.value)
        })
    }
    deleteAccount = (items) => {
        // const filterArray = events && events.filter(el => (el && el.id) != (items && items.id))
        // setEvents(filterArray)
        if (items && items.conventionId && items.id) {
            this.props.deleteEvent(items.conventionId, items.id)

        }
    }
    onVideoClickHandler = (e) => {
        e.preventDefault();
        const video = this.videoRef.current;
        // console.log(`%c video clicked!!`,'font-size:20px')
        if (video && video.paused) {
            video.play();
        } else {
            video.pause();
        }
        e.stopPropagation();
    }
    getPreview = (items) => {
        if(items && items.mimeType){
            if (items.mimeType.includes('image')) {
                return (
                    <img src={`${configPath.baseUrl}${items && items.path}`} alt={this.props.alt} width={"50px"} height="50px" />)
    
            } else if (items.mimeType.includes('video')) {  
                    return <video
                            muted={true}
                            loop
                            playsInline
                            controls={false}
                            width={"100px"}
                            onClick={this.onVideoClickHandler}
                            ref={this.videoRef}
                            height="100px"
                         >
                         <source src={`${configPath.baseUrl}${items && items.path}`} type={items.mimeType}/>
                        Your browser does not support HTML video.
                        </video>
                     
    
                
    
            }
        }
        
    }
    render() {
        const {events} = this.state
        console.log(events)
        const eventDropDown = events && events.length > 0 && events.map((filType) => {
            return <option value={filType.value} key={filType.value}>{filType.event}</option>
        });

        const eventList = this.state.eventsGallery && this.state.eventsGallery.map((items, index) => {
            return <tbody key={index}>
                <tr>
                <td>{items && items.conventionId}</td>
            <td className='userdescrip_adm' title={items && items.caption}>{items && items.caption}</td>
            <td>{this.getPreview(items)}</td>
            <td>{items && items.createdAt && moment(items.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
            <td>
                <a className="btn btn-outline-primary btn-sm btn-action mr-2" type="button" onClick={() => { this.deleteAccount(items) }}  title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></a>
            </td>

                </tr>

            </tbody>

        })

       
        
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle row">
                        <div className='col-md-6'>
                            <h2 className="font-weight-bold">Midwestcon events Gallery</h2>
                        </div>
                        {<div className="col-md-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoAddEvent}><i className="fas fa-plus"></i> Add Event Gallery</button>  </div>}

                        </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    
                                    {/* <div className="col-md-4 col-sm-12">

                                        <div className="text-right">
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div> */}
                                    {<div className="col-md-4 col-sm-12">

                                        <div className="text-right">
                                            <select value={this.state.selectType} className="form-control" onChange={this.handleDropDown}>
                                                {eventDropDown}
                                            </select>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                    <th>EVENT ID</th>
                                    <th>CAPTION</th>
                                    <th>GALLERY</th>
                                    <th>CREATED AT</th>
                                    <th>ACTION</th>

                                    </tr>
                                </thead>

                                {eventList}

                            </table>
                            {this.state.eventsGallery && this.state.eventsGallery.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.eventsGallery && this.state.eventsGallery.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        alleventGallery: state.midwestcon.eventGallery,
        allevents : state.midwestcon.midwestEvents,
        deletedEvent : state.midwestcon.deletedEventGallery

    }
}

const actionCreators = {
    getAllEventsGallery : midwestActions.getAllEventGallery,
    resetAllMidwestEventsGallery : midwestActions.resetGetAllEventGallery,
    getAllEvents : midwestActions.getAllMidwestEvents,
    resetEvents : midwestActions.resetGetAllMidwestEvents,
    deleteEvent : midwestActions.deleteEventGallery,
    resetDeleteEvent : midwestActions.resetDeletedEventGallery

}

const connectMidwestFetchEventsGallery = connect(mapStateToProps, actionCreators)(MidwestFetchEventsGallery)

export { connectMidwestFetchEventsGallery as MidwestFetchEventsGallery }