import { accountSetup, isConnectWallet } from 'helpers';
import { commonInfo } from 'helpers/CommonInfo';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import Modal from 'react-modal'
import { getUserAddress } from 'utils';
import PubSub from 'pubsub-js';
import { airDropService, dropServices, fetchService, walletServices } from 'services';
import { SwitchAccount } from './switchaccountPopup';
import { walletActions } from 'store/actions';
import { connect } from 'react-redux';
import history from 'helpers/history';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
class WithdrawPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokens: [],
            tokensDetail: {},
            isErrorPoped: false,
            isOpenWithdraw: false,
            availableCount: 0,
            isWithdrawSwitchAcc: false,
            artDetail: {},
            isImage: false,

        }
        this.maxWithdrawLimit = 50

    }
    componentDidMount() {
        if (this.props && this.props.airdropDetail) {
            this.setState({
                tokensDetail: this.props.airdropDetail, availableCount: this.props.availableCount,
                tokens: this.props.airdropDetail && this.props.airdropDetail.airDropDetails,
                artDetail: this.props.artDetail
            })
            if (isConnectWallet()) {
                this.checkaccountSetup()
            } else {
                this.handleBloctoWalletLogin()
            }
            this.fetchArt(this.props.artDetail && this.props.artDetail.thumbnailHash)
        }

        console.log(this.props)
    }


    show_cp_withdraw = (tokensDetail) => {
        if (tokensDetail && tokensDetail.withDapper && tokensDetail.storefrontListingResourceId) {
            return false
        } else {
            return true
        }

    }
    handleCount = (e) => {
        const { tokens } = this.state
        if (this.show_cp_withdraw(this.state.tokensDetail)) {
            if ((tokens && tokens.length) > 300) {
                if ((e.target.value > 0) && (e.target.value) <= 300 && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            } else {
                if ((e.target.value > 0) && (e.target.value) <= (Number(tokens && tokens.length)) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            }
        } else {
            if ((tokens && tokens.length) > (this.maxWithdrawLimit)) {
                if ((e.target.value > 0) && (e.target.value) <= (this.maxWithdrawLimit) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            } else {
                if ((e.target.value > 0) && (e.target.value) <= (Number(tokens && tokens.length)) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            }
        }

    }

    closeWithdraw = () => {
        this.setState({ isOpenWithdraw: false })
        this.props.closeWithdrawMainPopup()
    }
    approveWithdraw = () => {
        this.setState({ isOpenWithdraw: false })

        this.handleResale()
    }
    handleResale = async () => {
        const { tokensDetail } = this.state
        const airdropId = tokensDetail.id

        if (isConnectWallet()) {
            try {
                const currentuserAddress = await getUserAddress()
                this.showLoader()

                const checkWalletRes = await walletServices.checkWallet(currentuserAddress)
                if (checkWalletRes && checkWalletRes.statusCode === 200) {
                    const validateAirdrop = await airDropService.validateWithdrawAirdropTokens(airdropId)
                    if (validateAirdrop && validateAirdrop.statusCode === 200) {
                        this.setState({currentUserAddress : currentuserAddress})
                        let obj = {
                            walletAddress: currentuserAddress,
                            airDropId: airdropId
                        }
                        const withdrawRes = await airDropService.withdrawAirdropArt(JSON.stringify(obj))
                        if (withdrawRes && withdrawRes.statusCode === 200) {
                            this.hideLoader()
                            this.setState({ isWithDrawn: true })

                        } else {
                            this.hideLoader()
                            this.setState({ isErrorPoped: true, cadencErrorMessage: String(withdrawRes) })
                        }
                    }else{
                        this.hideLoader()
                        this.setState({ isErrorPoped: true, cadencErrorMessage: String(validateAirdrop) })
                    }



                } else {
                    this.hideLoader()
                    this.props.errorPopup('Failed to check your wallet address')
                }
            } catch (e) {
                this.setState({ isErrorPoped: true })
                this.hideLoader()
                if(e && typeof e === 'string' && e.toLowerCase().includes('art is not available for claim')){
                    this.setState({ cadencErrorMessage: String('Art is not available for withdraw. Please refresh and try again later') })

                }else if (e && e.data && e.data.Error) {

                    this.setState({ cadencErrorMessage: String(e.data.Error) })

                } else if (e && e.Error) {

                    this.setState({ cadencErrorMessage: String(e.Error) })

                } else if (e && e.message) {

                    this.setState({ cadencErrorMessage: String(e.message) })

                } else {
                    this.setState({ cadencErrorMessage: String(e) })

                }
            }
        } else {
            this.props.errorPopup("Please connect your wallet properly")
            this.gotoTop()
        }




    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    openWithdrawWarnPopup = () => {

        this.setState({ isOpenWithdraw: true })

    }
    checkaccountSetup = async () => {

        const walletAddress = await getUserAddress()
        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.props.successPopup('Your Flow account has been successfully added. Now you may proceed to claim NFT')
                this.gotoTop()
                this.openWithdrawWarnPopup()

            } else if (setup && setup.status === 201) {
                this.openWithdrawWarnPopup()
            } else {
                this.props.errorPopup(setup)
                this.gotoTop()
            }
        } catch (e) {
            console.log(e)
            if (typeof e === 'string') {
                this.props.errorPopup(e)

            } else if (e && e.message && typeof e.message === 'string') {
                this.props.errorPopup(e.message)
            }
            this.gotoTop()
        }
    }
    handleBloctoWalletLogin = async () => {
        this.handleToWithdrawSwitchAccount()
    }
    handleToWithdrawSwitchAccount = () => {
        this.setState({
            isWithdrawSwitchAcc: true
        })
    }
    withdrawcloseSwitchAccModal = () => {
        this.setState({
            isWithdrawSwitchAcc: false,
        })
        this.props.closeWithdrawMainPopup()
    }
    withdrawcloseSwitchAccModalOnly = () => {
        this.setState({
            isWithdrawSwitchAcc: false,
        })
    }
    withdrawswitchWalletConnected = (wallet) => {
        if (wallet && wallet.walletAddress) {
            
            this.withdrawcloseSwitchAccModalOnly()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForWithdrawArt: true, currentUserAddress: wallet.walletAddress })
        }
    }
    handleWithdrawSwitchAccFailed = (message) => {
        this.props.errorPopup(message)

        this.gotoTop()

    }
    componentDidUpdate(props, state) {

        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode) &&
            (this.state.isForWithdrawArt)) {
            this.props.resetlinkWallet()
            this.setState({
                isForWithdrawArt: false
            })
            this.checkaccountSetup()

        }
        if (((this.props &&
            this.props.walletLinkError &&
            this.props.walletLinkError.length > 0)) &&
            (props.walletLinkError) != (this.props.walletLinkError) &&
            (this.state.isForWithdrawArt)) {
            this.props.resetWalletLinkErr()
            this.props.errorPopup(`Couldn't connect wallet properly : ${this.props.walletLinkError}`)
            this.gotoTop()
            this.setState({
                isForWithdrawArt: false
            })

        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    fetchArt(url) {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        }).catch((err) => {

        })

    }
    resetPage = () => {
        localStorage.setItem('page', "0")
    }
    closeErrorPopup = (e) => {
        e.preventDefault(); 
        this.setState({ isErrorPoped: false }); 
        this.props.closeWithdrawMainPopup()
    }
    render() {
        const { tokens, artDetail } = this.state
        return (
            <Fragment>
                {this.state.isErrorPoped &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{this.state.cadencErrorMessage}</div>

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { this.closeErrorPopup(e)}}>Close</button></div></div></div>
                }
                {this.state.isWithDrawn &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={artDetail && artDetail.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Art Withdrawn and moved to your wallet <span className='usernamelilac'>({this.state.currentUserAddress})</span> Successfully</b></div>
                            <div className="mt-3">It may take 30 seconds for your changes to appear in collections</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Go To Collections</button></div></div>
                }
                {this.state.isWithdrawSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.withdrawcloseSwitchAccModalOnly}
                        closeModalWithProps={this.withdrawcloseSwitchAccModal}
                        switchWalletConnected={(val) => this.withdrawswitchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleWithdrawSwitchAccFailed(val)} />
                    : ''}
                <Modal
                    isOpen={this.state.isOpenWithdraw}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">

                        <div className="py-3">

                            <h2 className='mb-3 text-center'>Do you want to withdraw ?</h2>
                            {<div className="alert alert-cuslilac text-center mb-3">{commonInfo.WITHDRAW_AIRDROP_INFO}</div>}
                            <div className="col-sm-12 text-center">
                                <h5 className="mb-3 ">Editions listed for airdrop : {tokens && (tokens.length)}</h5>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdraw}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary-fill' type="button" onClick={this.approveWithdraw}>Withdraw</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>


            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        wallet: state.wallet.wallet,
        walletLinkError: state.wallet.walletlinkErr
    }
}
const actionCreators = {
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetWalletLinkErr: walletActions.resetWalletLinkError,

}
const connectWithdrawPopup = connect(mapStateToProps, actionCreators)(WithdrawPopup)
export { connectWithdrawPopup as WithdrawPopup }

