import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { adminServices, creatorServices } from 'services';
import moment from 'moment'
import { airdropActions } from 'store/actions';


class FetchAirdropDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            airdropDetail : {},
            isError : false
        }

    }
    componentDidMount(){
        this.callApi()
    }
     

    callApi = () => {
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.id){
        let id = this.props.match.params.id

        this.props.getAirdropDetail(id)
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.detailAirdrop && props.detailAirdrop.data) {
              props.resetAirDropDetail()
                return {
                    airdropDetail: props.detailAirdrop && props.detailAirdrop.data,
                   
                }

        }
      

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    handleRefresh = () => {
        this.callApi()
    }
    render(){
        const {airdropDetail} = this.state
        return(
           
            <Fragment>
             
                 <div className="container-fluid">
                   <div className="row">
                   <div className="mb-4 col-6 admintitle"><h2 className="font-weight-bold">Airdrop Detail</h2></div>
                    <div className="col-6 text-right">
                                        <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>
                   </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="row">
                            {(airdropDetail && airdropDetail.artTitle && airdropDetail.artTitle) && <div className="col-md-4 mb-3">
                                <label>Art Title</label>
                                <div className="comboxgray">
                                {airdropDetail && airdropDetail.artTitle && airdropDetail.artTitle}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.user && airdropDetail.user.userName) && <div className="col-md-4 mb-3">
                                <label>Listed by</label>
                                <div className="comboxgray">
                                    {airdropDetail && airdropDetail.user && airdropDetail.user.userName && `@${airdropDetail.user.userName}`}
                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.airDropDate && airdropDetail.airDropDate) && <div className="col-md-4 mb-3">
                                <label>Airdrop starts on</label>
                                <div className="comboxgray">
                                {moment(airdropDetail && airdropDetail.airDropDate).format('DD-MM-YYYY')}, {moment.utc(airdropDetail && airdropDetail.airDropDate).local().format("hh:mm:ss A")}
                                </div>
                            </div>}
                            {(airdropDetail ) && <div className="col-md-4 mb-3">
                                <label>Airdrop Type</label>
                                <div className="comboxgray">
                                {(airdropDetail && airdropDetail.airDropType === 2) ? 'Location based' : (airdropDetail && airdropDetail.airDropType == 1) ? "Selected Users" : "Public"}

                                </div>
                            </div>}
                            {<div className="col-md-4 mb-3">
                                <label>Status</label>
                                <div className="comboxgray">
                                {(airdropDetail && airdropDetail.status == 1) ? "Active" : (airdropDetail && airdropDetail.status == 2) ? "Claimed" : "Closed"}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.totalTokens !== null) ? <div className="col-md-4 mb-3">
                                <label>Total airdrop count</label>
                                <div className="comboxgray">
                                {(airdropDetail && airdropDetail.totalTokens)}

                                </div>
                            </div> : ''}
                            {(airdropDetail && airdropDetail.totalTokensClaimed != undefined && airdropDetail.totalTokensClaimed != null ) && <div className="col-md-4 mb-3">
                                <label>Total claimed count</label>
                                <div className="comboxgray">
                                {(airdropDetail && airdropDetail.totalTokensClaimed)}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.latitude && airdropDetail.latitude) && <div className="col-md-4 mb-3">
                                <label>Latitude</label>
                                <div className="comboxgray">
                                {airdropDetail && airdropDetail.latitude && airdropDetail.latitude}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.longitude && airdropDetail.longitude) && <div className="col-md-4 mb-3">
                                <label>Longitude</label>
                                <div className="comboxgray">
                                {airdropDetail && airdropDetail.longitude && airdropDetail.longitude}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.blockHieght ) && <div className="col-md-4 mb-3">
                                <label>Block Height</label>
                                <div className="comboxgray">
                                {(airdropDetail && airdropDetail.blockHieght)}

                                </div>
                            </div>}
                            {(airdropDetail && airdropDetail.airDropSelectedUsers && airdropDetail.airDropSelectedUsers.length > 0) && <div className="col-md-12 mb-3 airdrop-tab-hgt">
                            <p className="font-weight-bold col-sm-6">Selected User Detail</p>

                            <table className="table table-bordered table-striped-cusom">
                                <thead>
                                    <tr>
                                    {/* <th scope="col">Airdrop Id</th> */}
                                    <th scope="col">Username/Email</th>
                                    <th scope="col">Fullname</th>
                                    {/* <th scope="col">Email</th> */}
                                    {/* <th scope="col">Created At</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {(airdropDetail && airdropDetail.airDropSelectedUsers && airdropDetail.airDropSelectedUsers.map(items => (
                                        (items.status === 1) &&
                                    <tr>
                                    <td>{(items && items.user && items.user.userName) ? (`@${items.user.userName}`) : (items && items.unregisteredEmail) ? (items.unregisteredEmail) : '-'}</td>
                                    <td>{(items && items.user && items.user.fullName) ? (items.user.fullName) : '-'}</td>

                                    </tr>
                                   )))}
                                </tbody>
                                </table>
                            </div>}
                            {(airdropDetail && airdropDetail.airDropDetails && airdropDetail.airDropDetails.length > 0) && <div className="col-md-12 mb-3 airdrop-tab-hgt">
                            {/* <p className="font-weight-bold col-sm-6">Airdrop Detail</p> */}
                            <table className="table table-bordered table-striped-cusom">
                                <thead>
                                    <tr>
                                    <th scope="col">Token Id</th>
                                    <th scope="col">User</th>
                                    {/* <th scope="col">Airdrop Type</th> */}
                                    <th scope="col">Status</th>
                                    <th scope="col">Block Height</th>
                                    <th scope="col">Claimed On</th> 
                                    <th scope="col">View Transaction</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(airdropDetail && airdropDetail.airDropDetails && airdropDetail.airDropDetails.map(items => (
                                    <tr>
                                    <th scope="row">{items && items.token && items.token.tokenId}</th>
                                    <td>{(items && (items.user && items.user.userName) ? `@${items.user.userName}` : '-')}</td>
                                    {/* <td>{(airdropDetail && airdropDetail.airDropType == 1) ? "Selected Users" : "Public"}</td> */}
                                    <td>{items && (items.withdrawn === 1) ? 'Withdrawn' : (items.user && items.user.userName) ? "Claimed" : "Yet to Claim"}</td>
                                    <th scope="row">{(items && items.blockHeight) ? items.blockHeight : '-'}</th>

                                    <td>{(items && items.user && items.user.userName) ? (moment(items && items.updatedAt).format("DD-MM-YYYY, hh:mm:ss A")) : "-"}</td>
                                    <td className="text-center">{(items && items.txnId) ? <a href={process.env.REACT_APP_FLOW_TRANSACTION + `/${items && items.txnId}`} target="_blank" className="designLink btn btn-primary">View flow scan</a> : '-' }</td>
                                    </tr>
                                   )))}
                                </tbody>
                                </table>
                            </div>}
                            
                            </div>
                               </div>       
                           </div> 
                       
                        </div>
                         
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        detailAirdrop: state.airdrop.airdropDetail,
        isFetchDetail: state.airdrop.isDetailAirDrop
    }
}

const actionCreators = {
    getAirdropDetail: airdropActions.getAirdropDetail,
    resetAirDropDetail: airdropActions.resetAirDropDetail,
}

const connectAirdropDetail = connect(mapStateToProps,actionCreators)(FetchAirdropDetail)

export {connectAirdropDetail as FetchAirdropDetail}