import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'
import PubSub from 'pubsub-js';
import { creatorStoreActions } from 'store/actions';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { configPath } from '../../config';
import { SolarSystemLoading } from 'react-loadingg';
import moment from 'moment'
import { toast, Zoom } from 'react-toastify';

class StorePurchaseData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artsList: [],
            offSet: 0,
            recordLimit: 50,
            sortBy: "purchaseDate",
            sortOrder: "DESC",
            hasMore: true,
            loader: false,
            isCheckArt: false,
        }
        this.formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
         });
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.callApi()
        this.gotoTop()
    }
    callApi = () => {
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
        }
        this.props.getPurchaseData(obj)

    }
   
    componentDidUpdate(props, state) {
        console.log(props);
        if ((this.props &&
            this.props.purchaseData &&
            this.props.purchaseData.statusCode === 200) &&
            (this.props.purchaseData) !== (props.purchaseData)
        ) {
            const data = this.props && this.props.purchaseData && this.props.purchaseData.data

            this.props.resetPurchaseData()
            const page = Number(this.state.recordLimit) + Number(this.state.offSet)
            if (data.sales && data.sales.length === 0 || (data.sales.length < (this.state.recordLimit))) {
                const data1 = state.artsList.concat((props.purchaseData && props.purchaseData.data && props.purchaseData.data.sales))
                this.setState({
                    hasMore: false,
                    loader: false,
                    artsList: data1,
                    offSet: page,
                    isCheckArt: props.isCheckArt,
                })

            }
            const data2 = this.state.artsList.concat((data && data.sales))
            this.setState({
                artsList: data2,
                offSet: page,
                isCheckArt: props.isCheckArt,
            })

        }
        if(this.props && this.props.failedToGet && this.props.failedToGet.length > 0 && 
            (this.props.failedToGet !== props.failedToGet)){
                this.props.resetPurchaseDataFailed()
                toast.error(this.props.failedToGet,{transition:Zoom})
                this.setState({
                    loader : false,
                    hasMore : false
                })
            }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    refreshArts = () => {
      
        this.setState({

            offSet: 0,
            artsList: [],
            hasMore: true,
            loader: true,

        }, () => {
            this.callApi()
        })


    }
    isHidden = (items) => {
        if(items && items.status && items.status === 2){
            return true
        }else{
            return false
        }
    }
    showBuyerEmail = (items) => {
         const email = items.email 
            const firstEmail = email && email.split(',')
            if(firstEmail && firstEmail.length > 1){
               return firstEmail.map((items,idx) => (
                    <span key={idx}>{items}</span>
                ))
            }else{
                return items.email
            }
    }
    
    render() {
        const { artsList, hasMore, loader } = this.state

        const eventList = this.state.artsList && this.state.artsList.map((items, index) => {
            return <tr>
                    <td>{items && items.tokenId ? '#'+items.tokenId : "-"}</td>
                    <td>{items && items.token && items.token.title ? items.token.title : "-"}</td>
                    <td>{items && items.email ? items.email : "-"}</td>
                    <td>{items && items.walletAddress ? items.walletAddress : "-"}</td>
                    <td className='text-right'>{moment(items && items.purchaseDate).format(' DD-MM-YYYY hh:mm:ss A')}</td>
                </tr>

        })

        return (
            <Fragment>
                <Helmet>
                    <title>{"Purchase Report"}</title>
                    <meta name="title" content={`Explore NFT for sale`}></meta>
                    <meta name="description" content={`Explore NFT for sale`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit row">
                        <div className="col-md-6"><h1 className="mb-0">Purchase Report</h1></div>
                       
                        <div className="col-md-6 text-right"><button type="button" className="btn btn-rounded btn-outline-dark btn-icon mr-0" onClick={this.refreshArts}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>
                        

                    </div>
                    <div className='row'>
                        <div className="col-sm-11">
                        <p className=" font-italic alert-info">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                The table below includes the purchase data of buyers who voluntarily provided their email addresses after purchase. Buyers who haven't provided their email addresses will not be listed.
                            </p>
                        </div>
                        </div>
                    <div className="propagecont mb-5">
                        <div className="container1">
                        <InfiniteScroll
                                dataLength={artsList && artsList.length} //This is important field to render the next data
                                next={(artsList && artsList.length > 0) ? this.callApi : () => {}}
                                hasMore={hasMore}
                                loader={
                                    <div className="solarloader" >
                                        <SolarSystemLoading />

                                    </div>}
                                endMessage={
                                    (artsList.length > 0) ?
                                        <p style={{ textAlign: 'center', fontSize: "15px" }} className="usernamelilac mt-2">
                                            <b>You have reached the end of list</b>
                                        </p> : (!loader && <div className="col-md-4 offset-md-4 text-center">
                                            <img src={`/${configPath.imageAssets}/nohistoryyet.png`} className="mw-100" alt="" />
                                        </div>)
                                }
                                style={{ overflow: "hidden" }}

                            >
                                <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>TOKEN ID</th>
                                        <th>TITLE</th>
                                        <th>EMAIL</th>
                                        <th>WALLET ADDRESS</th>
                                        <th className='text-right'>PURCHASED AT</th>

                                    </tr>
                                </thead>
                                <tbody>
                                {eventList}
                                </tbody>
                            </table>
                            {this.state.artsList && (this.state.artsList.length === 0) && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                            </InfiniteScroll>
                            
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        purchaseData: state.creatorStore.getPurchaseData,
        failedToGet: state.creatorStore.resetPurchaseDataFailed,
    }
}
const actionCreators = {
    getPurchaseData: creatorStoreActions.storeGetPurchaseData,
    resetPurchaseData: creatorStoreActions.resetGetPurchaseData,
    resetPurchaseDataFailed: creatorStoreActions.resetGetPurchaseDataFailed
}
const connectStorePurchaseData = connect(mapStateToProps, actionCreators)(StorePurchaseData)
export { connectStorePurchaseData as StorePurchaseData }
