import { userConstants } from '../../constants'

// let admin = localStorage.getItem("admin")

// function checkAdmin(admin){

//         JSON.parse(admin)

// }

const initialState = {
    loggedIn: false,
    webUser: {},
    isAdmin: false,
    admin: [],
    userProfile: [],
    isWebLoggedIn: false,
    isUpdate: false,
    usersList: [],
    usd: [],
    regRes: [],
    verifyRes: [],
    webLogRes: [],
    approveStatus: [],
    modifiedStatus: [],
    allCreators: [],
    creatorRes: [],
    allCreatorApplications: [],
    resetpassword: [],
    changepassword: [],
    changeuser: [],
    revokeCreator: [],
    failedRes: [],
    failedReg: [],
    failedchangeuser: [],
    dashboard: [],
    bulkUser: [],
    otp: [],
    uploadedUsers: [],
    allUsers: [],
    isTourEnabled: false,
    addedFav: [],
    addFavFailed : '',
    removeFav : [],
    removeFavFailed : '',
    userFavs : [],
    userFavsFailed : '',
    favrtdUser : [],
    favrtdUserFailes : '',
    bankDetailAdded : [],
    addedBankDetailFailed : '',
    gotBankDetail : [],
    getBankDetailFailed : '',
    userwallets : [],
    userwalletsFailed : '',
    personalDetailAdded : [],
    addPersonalDetailFailed : '',
    gotPersonalDetail : [],
    getPersonalDetailFailed : '',
    selfAirdrops : [],
    selfAirdropsFailed : '',
    isCheckSelfAirdrop : false,
    changeSelfPass: [],
    changeSelfPassFailed : ''
}

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_SUCCESS:
            return {
                isWebLoggedIn: action.isWebLoggedIn,
                webUser: action.user,

            }
        case userConstants.ADMIN_LOGIN_SUCCESS:
            return {
                isAdmin: action.isAdmin,
                admin: action.user
            }
        // case userConstants.ADMIN_LOGOUT:
        //     return {
        //         isAdmin : action.isAdmin,
        //         admin : {}
        //     }
        case userConstants.USER_PROFILE_SUCCESS:
            return {
                isUpdate: action.isUpdate
            }
        case userConstants.GET_USERS:
            return {
                usersList: action.usersList
            }
        case userConstants.LOGOUT:
            return {

            }
        case userConstants.REGISTER_USER:
            return {
                regRes: action.regRes
            }
        case userConstants.VERIFY_EMAIL:
            return {
                verifyRes: action.verifyRes
            }
        case userConstants.ALREADY_VERIFY:
            return {
                verifyRes: action.verifyRes
            }
        case userConstants.WEBLOGIN:
            return {
                webLogRes: action.webLogRes
            }
        case userConstants.APPROVE_STATUS:
            return {
                approveStatus: action.approveStatus
            }
        case userConstants.MODIFY_STATUS:
            return {
                modifiedStatus: action.modifiedStatus
            }
        case userConstants.GET_ALL_CREATORS:
            return {
                allCreators: action.allCreators
            }
        case userConstants.RAISE_CREATOR_REQUEST:
            return {
                creatorRes: action.creatorRes
            }
        case userConstants.GET_ALL_CREATOR_APPLICATIONS:
            return {
                allCreatorApplications: action.allCreatorApplications
            }
        case userConstants.RESET_PASSWORD:
            return {
                resetpassword: action.resetpassword
            }
        case userConstants.CHANGE_PASSWORD:
            return {
                changepassword: action.changepassword
            }
        case userConstants.CHANGE_USER_PASSWORD:
            return {
                changeuser: action.changeuser
            }
        case userConstants.REVOKE_CREATOR:
            return {
                revokeCreator: action.revokeCreator
            }
        case userConstants.GET_USD:
            return {
                usd: action.usd
            }
        case userConstants.GET_ALL_USERS:
            return {
                allUsers: action.allUsers
            }
        case userConstants.LOGIN_FAILED:
            return {
                failedRes: action.failedRes
            }
        case userConstants.REGISTER_FAILED:
            return {
                failedReg: action.failedReg
            }
        case userConstants.CHANGE_PASSWORD_FAILED:
            return {
                failedchangeuser: action.failedchangeuser
            }
        case userConstants.DASHBOARD:
            return {
                dashboard: action.dashboard
            }
        case userConstants.BULK_UPLOAD:
            return {
                bulkUser: action.bulkUser
            }
        case userConstants.SEND_OTP:
            return {
                otp: action.otp
            }
        case userConstants.GET_UPLOAD_USERS:
            return {
                uploadedUsers: action.uploadedUsers
            }
        case userConstants.TOUR_OPTION:
            return {
                isTourEnabled : action.isTourEnabled
            }
        case userConstants.ADD_FAVOURITES:
            return {
                ...state,
                addedFav : action.addedFav
            }
        case userConstants.ADD_FAVOURITES_FAILED:
            return {
                ...state,
                addFavFailed : action.addFavFailed
            }
        case userConstants.REMOVE_FAVOURITES:
            return {
                ...state,
                removeFav : action.removeFav
            }
        case userConstants.REMOVE_FAVOURITES_FAILED:
            return {
                ...state,
                removeFavFailed : action.removeFavFailed
            }
        case userConstants.GET_USER_FAVOURITES:
            return{
                ...state,
                userFavs : action.userFavs
            }
        case userConstants.GET_USER_FAVOURITES_FAILED:
            return {
                ...state,
                userFavsFailed : action.userFavsFailed
            }
        case userConstants.GET_FAVOURITEDUSER:
            return {
                ...state,
                favrtdUser : action.favrtdUser
            }
        case userConstants.GET_FAVOURITEDUSER_FAILED:
            return {
                ...state,
                favrtdUserFailes : action.favrtdUserFailes
            }
        case userConstants.ADD_BANK_DETAIL:
            return {
                ...state,
                bankDetailAdded : action.bankDetailAdded
            }
        case userConstants.ADD_BANK_DETAIL_FAILED:
            return {
                ...state,
                addedBankDetailFailed : action.addedBankDetailFailed
            }
        case userConstants.GET_BANK_DETAIL:
            return { 
                ...state,
                gotBankDetail : action.gotBankDetail
            }
        case userConstants.GET_BANK_DETAIL_FAILED:
            return {
                ...state,
                getBankDetailFailed : action.getBankDetailFailed
            }
        case userConstants.GET_USERS_WALLET_DETAIL:
            return {
                ...state,
                userwallets : action.userwallets
            }
        case userConstants.GET_USERS_WALLET_DETAIL_FAILED:
            return {
                ...state,
                userwalletsFailed : action.userwalletsFailed
            }
        case userConstants.ADD_PERSONAL_DETAIL:
            return {
                ...state,
                personalDetailAdded : action.personalDetailAdded
            }
        case userConstants.ADD_PERSONAL_DETAIL_FAILED:
            return {
                ...state,
                addPersonalDetailFailed : action.addPersonalDetailFailed
            }
        case userConstants.GET_PERSONAL_DETAIL:
            return {
                ...state,
                gotPersonalDetail : action.gotPersonalDetail
            }
        case userConstants.GET_PERSONAL_DETAIL_FAILED:
            return {
                ...state,
                getPersonalDetailFailed : action.getPersonalDetailFailed
            }
        case userConstants.GET_SELF_AIRDROP_ARTS:
            return {
                ...state,
                selfAirdrops : action.selfAirdrops,
                isCheckSelfAirdrop : action.isCheckSelfAirdrop
            }
        case userConstants.GET_SELF_AIRDROP_ARTS_FAILED:
            return {
                ...state,
                selfAirdropsFailed : action.selfAirdropsFailed,
                isCheckSelfAirdrop : action.isCheckSelfAirdrop
            }
        case userConstants.CHANGE_SELF_PASSWORD:
            return {
                ...state,
                changeSelfPass : action.changeSelfPass
            }
        case userConstants.CHANGE_SELF_PASSWORD_FAILED:
            return {
                ...state,
                changeSelfPassFailed : action.changeSelfPassFailed
            }
        default:
            return state
    }
}