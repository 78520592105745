import React, { Component, createFactory, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { adminActions, categoryActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { AddBadges } from './AddBadges';
import Toggle from 'react-toggle';

const validations = {
    maxLength: 15,
    minLength: 3,
    maxCategoryLimit: 10
}
class FetchBadges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableBadges: [],

        }
    }
    componentDidMount() {
        this.getAllBadges()

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    getAllBadges = () => {
        adminServices.getAllAvailableBadges().then(res => {
            if (res && res.statusCode === 200) {
                if (res && res.data && res.data.badges) {
                    this.setState({
                        availableBadges: res.data.badges
                    })
                }
            } else {
                this.setState({
                    availableBadges: []
                })
            }
        }).catch(e => {
            this.setState({
                availableBadges: []
            })
        })
    }
    static getDerivedStateFromProps(props, state) {
        
    }


    componentDidUpdate(props,state){
        if ((this.props && this.props.updatedBadgeSuccess && this.props.updatedBadgeSuccess.statusCode === 202) && 
        ((this.props.updatedBadgeSuccess) !== (props.updatedBadgeSuccess))) {
            this.props.resetUpdateBadgesSuccess()
            this.getAllBadges()
            
        }
    }

    statusChange = (items) => {
        const status = (items && items.isActive) ? 0 : 1
        let obj = {
            status: status
        }
        this.props.updateBadges(JSON.stringify(obj),(items.id))

    }

    render() {
        return (
            <Fragment>
                <div className="smallilac px-3 container">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-12"><h2 className="font-weight-bold">Badges</h2>  </div>

                    </div>

                    <AddBadges getAllBadges={this.getAllBadges}/>
                    <div className="purchasebox p-2 mb-3">
                        <div className="card">
                            <div className="card-body">

                                <div className="table-responsive" >
                                    <table className="table table-bordered table-striped-cusom ">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>BADGE NAME</th>
                                                <th className='text-center'>IMAGE</th>
                                                <th className='text-center'>STATUS</th>
                                                <th className='text-center'>EDIT</th>
                                            </tr>
                                        </thead>
                                        {this.state.availableBadges && this.state.availableBadges.map((items, index) => (
                                            <tbody>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td className="text-capitalize">{items && items.name}</td>
                                                    <td className='text-center usernamelilac'><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.badgePath && items.badgePath.slice(1)}`} alt="" /></td>
                                                    <td className='text-center'><a className={'card flowtop'} style={{ display: "inline-block" }}>
                                                        <Toggle checked={items && items.isActive} onChange={() => this.statusChange(items)} />
                                                        {<span className="copycon">
                                                            <span className="uparrow"></span>
                                                            {items && items.isActive ? ("Disable badge") : ("Enable badge")}
                                                        </span>}</a></td>
                                                    <td className='text-center'><button className='btn btn-primary btn-sm' disabled type="button"><i className='fa fa-pencil fa-lg' ></i></button></td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>




            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        updatedBadgeSuccess: state.adminaccount.updatedBadge
    }
}
const actionCreator = {
    updateBadges: adminActions.updateBadgeStatus,
    resetUpdateBadgesSuccess: adminActions.resetUpdateBadgeStatus
}
const connectFetchBadges = connect(mapStateToProps, actionCreator)(FetchBadges)
export { connectFetchBadges as FetchBadges }