import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { artActions, creatorActions, dropActions, followActions, userAction, walletActions } from 'store/actions';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { creatorServices, userServices } from 'services';
import { configPath } from '../../config';
import { checkMintedTokens, checkMintedTokensWithEdition, checkTokenGroup, isWebLogin, ReactPagination, flowDecimal, getUSDValue, localArtPage, localDropPage, localAirdropPage, checkMintedTokensWithTotalEdition, isConnectWallet, accountSetup } from 'helpers';
import {
    FacebookShareButton, TelegramShareButton, WhatsappIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, TelegramIcon
} from 'react-share'
import { ArtPreview } from '../art-preview/art-preview';
import { Helmet } from 'react-helmet';
import moment from 'moment'
import { ProfilePreview } from '../profilePreview';
import { SolarSystemLoading } from 'react-loadingg';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { InvalidPage } from '../Common-error-ui/Invalidpag';
import { ChangePasswordPopup } from '../popups/changePasswordPopup';
import { getUserAddress } from 'utils';
import { SwitchAccount } from 'components/switchaccountPopup';

var timeout;
var controller;

class CreatorProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailCreator: {},
            dataArray: {},
            onSaleArray: [],
            dataArrayCount: '',
            size: 8,
            pageCount: 0,
            dropPageCount: '',
            offset: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            onSaleArrayCount: '',
            isSale: true,
            visible: false,
            isAlert: false,
            message: '',
            style: '',
            followers: [],
            followings: [],
            isFollower: false,
            isFollowing: false,
            images: [`${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`],
            copySuccess: 'Copy Link',
            checkUser: false,
            followerCount: 0,
            followingCount: 0,
            creatorArtsList: {},
            createArtCount: 0,
            isCheckCreator: false,
            isCheckCollect: false,
            isShowLoader: true,
            show: false,
            isOwner: false,
            title: "Copy",
            isError: false,
            isInvalidPage: false,
            showPasswordPop: false,
            isSwitchAcc: false,
            tabIndex: 1

        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.tabRef = React.createRef()
    }
    copyToClipboard = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ copySuccess: 'Copied!' });
        const interval = setInterval(() => {
            this.setState({ copySuccess: 'Copy Link' });
        }, 3000);

    };
    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        // this.props.getUSD()

        this.getCreator()

    }
    getCreator = () => {
        window.scrollTo({
            top: 10,
            behavior: "smooth"
        });
        this.checkUser()
        this.setCount()
        let userAddress = ""
        isWebLogin() && this.props.getUserProfile()

        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isFromLogin) {
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isFromLogin;
            history.replace({ ...location, state });

        }
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.address) {

            let id = decodeURIComponent(this.props.match.params.address).replace('@', '')

            this.setState({ isSale: true, isCheckCreator: false, isCheckCollect: false, })

            // this.showLoader()
            creatorServices.getDetailCreator(id).then(res => {
                // console.log(res)
                // this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    userAddress = res && res.data && res.data.userAddress
                    let userId = res && res.data && res.data.id
                    this.setState({ detailCreator: res && res.data, userAddress, userId, isShowLoader: false, isError: false, isInvalidPage: false, tabIndex: 1 })

                    isWebLogin() && this.checkFollow(userId)
                    this.props.getFollowings(userId)
                    this.props.getFollowers(userId)
                    this.getCreatorArts(userId)
                    this.props.getViewCount(userId)

                }
            }).catch(err => {
                console.log(err)
                if (err instanceof TypeError) {
                    this.setState({
                        isAlert: true,
                        message: err,
                        style: "danger"
                    })
                }
                else if (err && err.includes("disabled")) {
                    this.setState({ isError: true })
                } else if (err && err.toLowerCase().includes('requested resource could not be found')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else if (err && err.toLowerCase().includes('in request are either missing or invalid')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else if (err && err.toLowerCase().includes('username should contain only ')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else {
                    this.setState({
                        isAlert: true,
                        message: err,
                        style: "danger"
                    })
                }
                this.setState({ isShowLoader: false })
                this.hideLoader()
            })
        }


    }
    componentDidUpdate(props, state) {

        if (props && props.userFollow && props.userFollow.statusCode == 200) {
            props.resetFollow()
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (props && props.unfollowmsg && props.unfollowmsg.statusCode == 200) {
            props.resetUnFollow()
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if ((props && props.failedFollow && props.failedFollow.length > 0)) {
            props.resetFailedFollow()
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

        let nft = configPath.basename
        if (window.location.pathname.includes('nft')) {
            if ((nft + decodeURIComponent(props && props.location && props.location.pathname)) != decodeURIComponent(window.location.pathname)) {
                this.getCreator()
                this.resetErrorState()
            }
        } else if (decodeURIComponent(props && props.location && props.location.pathname) != decodeURIComponent(window.location.pathname)) {
            this.getCreator()
            this.resetErrorState()
        }
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && ((props.wallet) !== (this.props.wallet)) && (state.callSetup)) {
            this.props.resetlinkWallet()
            this.createaccountSetup()
            this.setState({
                callSetup: false
            })
        }

    }
    static getDerivedStateFromProps(props, state) {

        if (props && props.userFollow && props.userFollow.statusCode == 200) {
            props.resetFollow()
            props.checkFollowUser(state.userId)
            props.getFollowings(state.userId)
            props.getFollowers(state.userId)
            return {
                isAlert: true,
                message: props && props.userFollow && props.userFollow.data && props.userFollow.data.message ? props.userFollow.data.message : `You can follow ${props.userFollow.data.userName && props.userFollow.data.userName} from now`,
                style: "success"
            }

        }
        if ((props && props.failedFollow && props.failedFollow.length > 0)) {
            props.resetFailedFollow()
            return {
                isAlert: true,
                message: props && props.failedFollow,
                style: "danger"
            }
        }
        if (props && props.unfollowmsg && props.unfollowmsg.statusCode == 200) {
            props.resetUnFollow()
            props.checkFollowUser(state.userId)
            props.getFollowings(state.userId)
            props.getFollowers(state.userId)
            return {
                isAlert: true,
                message: props && props.unfollowmsg && props.unfollowmsg.data && props.unfollowmsg.data.Message && props.unfollowmsg.data.Message,
                style: "success"
            }

        }

        if ((props && props.checkUser && props.checkUser.statusCode) == 200) {
            props.resetCheckUser()

            return {
                checkUser: true
            }
        }
        if ((props && props.failedCheck && props.failedCheck.length > 0)) {
            props.resetFailedCheck()

            return {
                checkUser: false
            }
        }
        if ((props && props.followers && props.followers.statusCode) == 200) {
            props.resetFollowers()

            return {
                followers: props.followers && props.followers.data && props.followers.data.follower ? props.followers.data.follower : [],
                followerCount: props.followers && props.followers.data && props.followers.data.count ? props.followers.data.count : 0,
            }
        }
        if ((props && props.followings && props.followings.statusCode) == 200) {
            props.resetFollowing()

            return {
                followings: props.followings && props.followings.data && props.followings.data.following ? props.followings.data.following : [],
                followingCount: props.followings && props.followings.data && props.followings.data.count ? props.followings.data.count : 0
            }
        }
        if ((props && props.usersList && props.usersList.statusCode == 200)) {
            props.resetGetUsers()
            return {
                profileData: props && props.usersList && props.usersList.data
            }
        }
        if ((props && props.creatorsList && props.creatorsList.statusCode == 200 && props.creatorsList.data) || (props && props.singleUserArts && props.singleUserArts.statusCode == 200)) {
            props.resetCreatorArts()
            props.resetOneUserArts()


            return {
                onSaleArray: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.drops && props.singleUserDrop.data.drops.length > 0 ? props.singleUserDrop.data.drops : [],
                dataArray: props && props.singleUserArts && props.singleUserArts.data,
                dataArrayCount: props && props.singleUserArts && props.singleUserArts.data && props.singleUserArts.data.count,
                onSaleArrayCount: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.count,
                pageCount: props.singleUserArts && props.singleUserArts.data && props.singleUserArts.data.count ? props.singleUserArts.data.count / state.size : 0,
                dropPageCount: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.count / state.size,
                creatorArtsList: props && props.creatorsList && props.creatorsList.data,
                createArtCount: props && props.creatorsList && props.creatorsList.data && props.creatorsList.data.count ? props.creatorsList.data.count / state.size : 0,
                totalCount: props && props.creatorsList && props.creatorsList.data && props.creatorsList.data.count,
                isCheckCreator: props && props.isCheckCreatorArt,
                isCheckCollect: props && props.isCheckCollect,

                // rate: rate
            }
        }
        return null;



    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    checkFollow = (userId) => {
        this.props.checkFollowUser(userId)
    }
    changeOnSale = () => {
        if (this.state.isSale == false) {
            this.setState({ isSale: true, offset: 0, tabIndex: 1 }, () => {
                this.getCreatorArts(this.state.userId)
            })
        }

    }
    getCreatorArts = (id) => {
        // window.scrollTo({
        //     top: 10,
        //     behavior: "smooth"
        // });


        this.setState({ forcePage: this.state.offset != null ? ((this.state.offset) / this.state.size) : 0 })

        controller = new AbortController();

        const signal = controller.signal;
        let obj = {
            offSet: this.state.offset,
            recordLimit: this.state.size,
            sortBy: 'createdAt',
            sortOrder: this.state.sortOrder,
            signal
        }

        this.props.creatorArts(obj, id)
        this.setState({ dataArray: [], isSale: true, isCheckCollect: false })

    }
    onSale = (id) => {
        let obj = {
            offSet: this.state.offset,
            recordLimit: this.state.size,
            sortBy: 'updatedAt',
            sortOrder: this.state.sortOrder

        }

        this.props.getOneUserDrop(obj, id)
        this.setState({ dataArray: [], isSale: true })
        this.props.resetOneUserArts()

    }
    checkUser = () => {
        let user = localStorage.getItem("webLogin")
        let userDetail = JSON.parse(user)

        this.setState({ loginUser: userDetail && userDetail.data && userDetail.data.authUser && userDetail.data.authUser.userName })
    }
    handleOnSale = () => {
        let images = [`${configPath.imageAssets}/art-1.jpg`, `${configPath.imageAssets}/art-1.jpg`]
        this.setState({ images, dataArray: [] })
    }
    changeCollectibles = () => {
        this.setState({ isSale: false, offset: 0, tabIndex: 2 }, () => {
            this.handleCollectibles()
        })
    }
    handleCollectibles = () => {
        // window.scrollTo({
        //     top: 10,
        //     behavior: "smooth"
        // });
        if(this.tabRef && this.tabRef.current){
            this.tabRef.current.scrollIntoView({behavior : "smooth"})
        }
        this.setState({ forcePage: this.state.offset != null ? ((this.state.offset) / this.state.size) : 0 })
        let obj = {
            offSet: this.state.offset,
            recordLimit: this.state.size,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder

        }
        controller.abort()
        controller = new AbortController();
        this.props.getOneUserArts(obj, this.state.userId)
        this.setState({ isSale: false, creatorArtsList: [{ arts: [] }], isCheckCreator: false })

    }

    handleCreateList = () => {
        let images = [`${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`, `${configPath.imageAssets}/art-pic.jpg`]
        this.setState({ images, dataArray: [] })
    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)

    }
    handleUnfollow = () => {
        this.props.unfollow((this.state.userId))

    }

    onPageChange = (data) => {
        let offset = this.state.size * (data && data.selected)
        
        if (this.state.isSale) {
            
            this.setState({ offset, isCheckCreator: false }, () => this.getCreatorArts(this.state.userId))
        } else {
            this.setState({ offset, isCheckCollect: false }, () => this.handleCollectibles())
        }

    }
    getFollowers = () => {
        this.setState({ isFollower: true })
        this.props.getFollowers(this.state.userId)
    }
    getFollowings = () => {
        this.setState({ isFollowing: true })
        this.props.getFollowings(this.state.userId)
    }
    gotoDrops = (items, userAddress) => {
        const { userId } = this.state

        if ((items && items.drops && items.drops[0] && items.drops[0].id)) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else {
            if (userId) {
                history.push(`/art/${items && items.id}#collector-${userId}`)
            }

        }
    }
    gotoArts = (items, userAddress) => {

        if ((items && items.drops && items.drops[0] && items.drops[0].id)) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else {
            history.push(`/art/${items && items.id}`)

        }
    }
    gotoUser = (items) => {
        this.setState({ isFollower: false }, () => {
            if ((items && items.roleId) == 1 && (items && items.id != null)) {
                history.push(`/creator/@${items.userName && encodeURIComponent(items.userName)}`, { obj: { userName: items.userName } })
            }
            else if ((items && items.roleId) == 2 && (items && items.id != null)) {
                history.push(`/collector/@${items.userName && encodeURIComponent(items.userName)}`)
            }
        })


    }
    getBanner = () => {
        const { detailCreator, isShowLoader } = this.state
        if (detailCreator && detailCreator.bannerImage && detailCreator.bannerImage != null) {
            return <img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCreator.bannerImage}`} alt=""></img>

        } else if (!isShowLoader) {
            return <img src={`../${configPath.imageAssets}/cover-default.png`} alt=""></img>

        }

    }
    colseFollower = () => {
        this.setState({ isFollower: false, isFollowing: false })
    }
    startTime = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return <div className="mt-1"><p className="mb-0  usernamelilac">Bidding Closed</p></div>
        }
        // return <div className="mt-1"><p className="mb-0 ">Ends by : <span className="usernamelilac">{(`${days ? days+"d" : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    gotoProfile = () => {
        history.push(`/profile`)
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    getUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }
        }
    }
    getTwitUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }
        }
    }
    displayDomain = (link) => {
        if (link && link.includes('https')) {
            return link.replace('https://', '')
        } else {
            return link
        }
    }
    handleCopy = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ title: "Copied" });
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        setTimeout(() => {
            this.setState({ title: "Copy" });
            this.setState({ active: currentState });
        }, 1000);
    }
    getSocialLinks = (detailCreator) => {
        return <div className="w-100">
            <div className="prosoclinks">
                <a href={detailCreator && detailCreator.instagramLink} target="_blank" rel="noreferrer" className={`${!(detailCreator && detailCreator.instagramLink) ? 'd-none' : ''}`}>
                    <span className="badgeview psocial"><i className="fab fa-instagram"></i></span> {this.getUserName(detailCreator && detailCreator.instagramLink)}</a>

                <a href={detailCreator && detailCreator.twitterLink} target="_blank" rel="noreferrer" className={`${!(detailCreator && detailCreator.twitterLink) ? 'd-none' : ''}`}>
                    <span className="badgeview psocial"><i className="fab fa-twitter"></i></span>@{this.getTwitUserName(detailCreator && detailCreator.twitterLink)}</a>

                {detailCreator && detailCreator.discord && <a id="uploadArt" onClick={() => this.handleCopy(detailCreator && detailCreator.discord)} className={this.state.active ? 'activecopy card flowtop' : 'card flowtop'}><span className="badgeview psocial"><i className="fab fa-discord"></i></span >  {detailCreator && detailCreator.discord}
                    <span className="copycon">
                        <span className="uparrow"></span>
                        {this.state.title}
                    </span></a>}
            </div>
            {((detailCreator && detailCreator.website) || (detailCreator && detailCreator.additionalLink1) || (detailCreator && detailCreator.additionalLink2) || (detailCreator && detailCreator.additionalLink3)) &&

                <div className="weblinks">
                    <hr className="dividerhr" />
                    <h5>Links</h5>
                    <div className="weblinklists">
                        <i className="fa fa-globe"></i>
                        <a
                            href={detailCreator && detailCreator.website}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCreator && detailCreator.website) ? 'd-none' : ''}`}>
                            {detailCreator && detailCreator.website && this.displayDomain(detailCreator.website)}

                        </a>
                        <a
                            href={detailCreator && detailCreator.additionalLink1}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCreator && detailCreator.additionalLink1) ? 'd-none' : ''}`}>
                            {detailCreator && detailCreator.additionalLink1 && this.displayDomain(detailCreator.additionalLink1)}

                        </a>
                        <a
                            href={detailCreator && detailCreator.additionalLink2}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCreator && detailCreator.additionalLink2) ? 'd-none' : ''}`}>
                            {detailCreator && detailCreator.additionalLink2 && this.displayDomain(detailCreator.additionalLink2)}

                        </a>
                        <a
                            href={detailCreator && detailCreator.additionalLink3}
                            target="_blank"
                            rel="noreferrer"
                            className={`${!(detailCreator && detailCreator.additionalLink3) ? 'd-none' : ''}`}>
                            {detailCreator && detailCreator.additionalLink3 && this.displayDomain(detailCreator.additionalLink3)}

                        </a>
                    </div>
                </div>}
            {/* <hr className="dividerhr" />
            <div className="joindedate">
                Joined<br /> {detailCreator.createdAt && moment(detailCreator.createdAt).format('MMMM YYYY')}
            </div> */}
        </div>
    }
    checkTotalMintedTokens = (totalTokens) => {
        if ((totalTokens) && Number.isInteger(totalTokens)) {
            return totalTokens
        } else if (totalTokens.includes('-')) {
            return 1
        } else {
            return 1
        }
    }
    getImageSource = (items) => {
        if ((items && items.localPreview && items.localPreview !== null)) {
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        } else {
            if (items && items.thumbnailHash) {
                return items.thumbnailHash
            }
        }
    }
    getMimetype = (items) => {
        if ((items && items.localPreview && items.localPreview !== null && items.localPreviewMimeType !== null)) {
            return items.localPreviewMimeType
        } else {
            if (items && items.thumbnailMimeType) {
                return items.thumbnailMimeType
            }
        }
    }
    resetErrorState = () => {
        this.setState({
            isInvalidPage: false,
            isError: false
        })
    }
    getMessage = (creator) => {
        if (creator && creator.fullName) {
            return `${creator.fullName}'s profile at ${process.env.REACT_APP_BASE_SITE}`
        } else {
            return ''
        }
    }
    handleChangePassword = () => {
        this.setState({
            showPasswordPop: true
        })
    }
    closePassModal = () => {
        this.setState({
            showPasswordPop: false,
        })
    }
    changepasswordFailed = (message) => {
        this.closePassModal()
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

    }
    changepasswordSuccess = (wallet) => {
        this.closePassModal()
        this.setState({
            isAlert: true,
            message: "Password changed successfully",
            style: "success",

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ callSetup: true })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
        });
        this.closeAlert()
        this.resetTimeout()
        this.gotoTop()

    }
    createaccountSetup = async () => {
        const walletAddress = await getUserAddress()
        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.setState({
                    isAlert: true,
                    message:
                        "Your Flow account has been successfully added. Now you may proceed to claim NFT",
                    style: "success",
                });
                this.closeAlert()
                this.resetTimeout()
                this.gotoTop()

            } else if (setup && setup.status === 201) {

            } else {
                this.setState({
                    isAlert: true,
                    message: setup,
                    style: "danger",
                });
                this.closeAlert()
                this.resetTimeout()
                this.gotoTop()

            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger",
            });
            this.closeAlert()
            this.resetTimeout()
            this.gotoTop()

        }
    }
    webSiteLinks = () => {
        const {detailCreator} = this.state
        if (((detailCreator && detailCreator.website) || 
        (detailCreator && detailCreator.additionalLink1) || 
        (detailCreator && detailCreator.additionalLink2) || 
        (detailCreator && detailCreator.additionalLink3))) {
            if(detailCreator.additionalLink1 === null && detailCreator.additionalLink2 === null && detailCreator.additionalLink3 === null){
                return <a href={detailCreator && detailCreator.website} target="_blank" rel="noreferrer"><span className="badgeview psocial"><i className="fa fa-globe"></i></span></a>

            }else{
                return <span className="dropdown">
                <a className="dropdown-toggle badgeview psocial " id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                    <i className="fa fa-globe"></i>
                </a>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    
                    {(detailCreator.website) ? <a className="dropdown-item" href={detailCreator.website} target="_blank" rel="noreferrer">{detailCreator.website}</a> : ''}
                    {(detailCreator.additionalLink1) ? <a className="dropdown-item" href={detailCreator.additionalLink1} target="_blank" rel="noreferrer">{detailCreator.additionalLink1}</a> : ''}
                    {(detailCreator.additionalLink2) ? <a className="dropdown-item" href={detailCreator.additionalLink2} target="_blank" rel="noreferrer">{detailCreator.additionalLink2}</a> : ''}
                    {(detailCreator.additionalLink3) ? <a className="dropdown-item" href={detailCreator.additionalLink3} target="_blank" rel="noreferrer">{detailCreator.additionalLink3}</a> : ''}


                    
                </div>
            </span>
            }
            

        } else {
            return ''
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        const basePath = process.env.REACT_APP_BASEPATH

        const { detailCreator, followers, followings, checkUser, isCheckCreator, isCheckDrop, isCheckCollect, isInvalidPage } = this.state
        const shareUrl = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const Creat_Name = ((detailCreator && detailCreator.userName) ? (`@(${detailCreator.userName})`) : '...')
        const Creat_UName = ((detailCreator && detailCreator.userName) ? (`@${detailCreator.userName}`) : '...')

        const Create_FullName = ((detailCreator && detailCreator.fullName) ? (`${detailCreator.fullName}`) : '...')
        return (
            <Fragment>
                <Helmet>
                    <title>Creator - {Creat_UName}</title>
                    <meta name="title" content={`${process.env.REACT_APP_SITE_CONTRACT} Creator - ${Create_FullName} ${Creat_Name}`}></meta>
                    <meta name="description" content={`NFT Creations & Collections of ${process.env.REACT_APP_SITE_CONTRACT} Creator - ${Create_FullName} ${Creat_Name}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent"  >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

                <div className="modal" id="following" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Following</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mb-3" style={{ overflow: 'auto', maxHeight: '300px' }}>
                                {followings && followings.length > 0 ? followings.map(items => (
                                    <div className="modalfollowlist" key={items && items.id}>
                                        <div className="row" >
                                            <div className="col-md-9" onClick={() => { this.gotoUser(items) }} data-dismiss="modal" style={{ cursor: "pointer" }}>

                                                <div className="upic">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <img src={items && items.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} />
                                                        </span>
                                                    </span>

                                                </div>

                                                <h5 >{items && items.userName && items.userName != null && items.userName}</h5>
                                            </div>
                                        </div>


                                    </div>)) : <div>No Followings Yet</div>}


                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="followers" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Followers</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mb-3" style={{ overflow: 'auto', maxHeight: '300px' }}>
                                {followers && followers.length > 0 ? followers.map(items => (
                                    <div className="modalfollowlist" key={items && items.id}>
                                        <div className="row" >
                                            <div className="col-md-9" onClick={() => { this.gotoUser(items) }} data-dismiss="modal" style={{ cursor: "pointer" }}>

                                                <div className="upic">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <img src={items && items.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} />
                                                        </span>
                                                    </span>

                                                </div>

                                                <h5 >{items && items.userName && items.userName != null && items.userName}</h5>
                                            </div>
                                        </div>


                                    </div>)) : <div>No Followers Yet</div>}
                            </div>

                        </div>
                    </div>
                </div>
                {this.state.showPasswordPop ?
                    <ChangePasswordPopup
                        closeModal={this.closePassModal}
                        changepasswordSuccess={(val) => this.changepasswordSuccess(val)}
                        changepasswordFailed={(val) => this.changepasswordFailed(val)}
                    /> : ''}
                     {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                    : ''}
                {(!(this.state.isError) && !(isInvalidPage)) ? <div>
                    <div className="coverimg imgcovergif rounded-0">
                        {this.getBanner()}
                    </div>

                    <div className="propagecont ccdetailbox">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-start cctitpro">
                                        <div className="d-flex flex-column lftsdbar">
                                            <div className="badgeview propicdetail">
                                                <div className="picpro">
                                                    <span className="artcovimg">
                                                        <span className="artcov "></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <ProfilePreview src={(detailCreator && detailCreator.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCreator && detailCreator.profileImage}`)} alt="Avatar" />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            {(isWebLogin() && !isConnectWallet() && ((this.state.profileData && this.state.profileData.userName) === (detailCreator && detailCreator.userName))) ? <button className="designLink btn btn-primary connect_wallet" type="button" onClick={this.handleToSwitchAccount}>Connect Wallet</button> : ''}
                                          

                                        </div>
                                        <div className="d-flex flex-column w-100 biodeta">
                                            <div className="badgeview protittxt">
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <h3>{detailCreator && detailCreator.isPremiumAccount ? <img src={`../${configPath.imageAssets}/permium.png`} alt="" /> : ''} {detailCreator && detailCreator.fullName}</h3>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="biodetadesk">
                                                            {/* {this.getSocialLinks(detailCreator)} */}

                                                            <div className="prosoclinks">
                                                                {(detailCreator && detailCreator.instagramLink) ? <a href={detailCreator && detailCreator.instagramLink} target="_blank" rel="noreferrer"><span className="badgeview psocial"><i className="fab fa-instagram"></i></span></a> : ''}
                                                                {(detailCreator && detailCreator.twitterLink) ? <a href={detailCreator && detailCreator.twitterLink} target="_blank" rel="noreferrer"><span className="badgeview psocial"><i className="fab fa-twitter"></i></span></a> : ''}
                                                                {(detailCreator && detailCreator.discord) ? <a onClick={() => this.handleCopy(detailCreator && detailCreator.discord)} className={this.state.active ? 'activecopy card flowtop' : 'card flowtop'}>
                                                                    <span className="badgeview psocial"><i className="fab fa-discord"></i></span>
                                                                    <span className="copycon">
                                                                        <span className="uparrow"></span>
                                                                        {this.state.title}
                                                                    </span></a> : ''}
                                                                    
                                                                     {this.webSiteLinks()}
                                                                    
                                                                <a rel="noreferrer" onClick={this.toggleMenu}><span className="badgeview psocial" ><i className="fa fa-share-alt"></i></span></a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="useredit">
                                                            {detailCreator && detailCreator.userName && <h2 className="usernameClick useroverflow" title={detailCreator.userName}>{detailCreator && ((detailCreator.userName && detailCreator.userName.includes('@')) ? detailCreator.userName : (`@${detailCreator.userName}`))} </h2>}
                                                            {((this.state.profileData && this.state.profileData.userName) === (detailCreator && detailCreator.userName)) && <button type="button" className="btn btn-outline-dark btn-sm btn-primary-gradient" onClick={this.gotoProfile}>Edit Profile</button>}

                                                            {!checkUser && (((this.state.profileData && this.state.profileData.userName) != (detailCreator && detailCreator.userName))) && <div>
                                                                {isWebLogin() ? <button className="btn btn-primary btn-sm px-3 btn-primary-gradient" onClick={this.handleFollow} >Follow</button> : <button className="btn btn-primary btn-sm px-3 btn-primary-gradient" onClick={() => { history.push('/login', { creatorUrl: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname) }) }} >Follow</button>}
                                                            </div>}

                                                            {checkUser && (((this.state.profileData && this.state.profileData.userName) != (detailCreator && detailCreator.userName))) && <div>
                                                                {isWebLogin() ? <button onClick={this.handleUnfollow} className="btn btn-primary btn-sm px-3 decbtn .btn-primary-gradient"  > <span><i className="fas fa-check"></i> &nbsp;Following</span>  </button> : <button className="btn btn-primary btn-sm px-3 decbtn .btn-primary-gradient" onClick={() => { history.push('/login', { creatorUrl: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname) }) }} ><span><i className="fas fa-check"></i> &nbsp;Following</span></button>}
                                                            </div>}

                                                            {isWebLogin() ? (((this.state.profileData && this.state.profileData.userName) === (detailCreator && detailCreator.userName)) && detailCreator.id) ? <button type="button" className='btn btn-primary btn-sm px-3 btn-primary-gradient ml-3' onClick={this.handleChangePassword}>Change password</button> : '' : ''}


                                                        </div>
                                                        <div className="joindedate">
                                                            Joined {detailCreator.createdAt && moment(detailCreator.createdAt).format('MMMM YYYY')}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="card followboxcon">
                                                            <div className="followbox" data-toggle="modal" data-target="#following" onClick={this.getFollowings}><h4>{this.state.followingCount}</h4><p>Following</p></div>
                                                            <div className="followbox" data-toggle="modal" data-target="#followers" onClick={this.getFollowers}><h4>{this.state.followerCount}</h4><p>Followers</p></div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="text-justify biocont">
                                                    {(detailCreator && detailCreator.description && detailCreator.description.length > 600) ?
                                                        <div className="showless">
                                                            <input id="showless1" type="radio" name="showless" />
                                                            <label htmlFor="showless1" className="showless1">Read more</label>
                                                            <input id="showless2" type="radio" name="showless" checked={this.state.checked} />
                                                            <label htmlFor="showless2" className="showless2">Show less</label>
                                                            <div className="content">
                                                                <div id="showless1" className="showlesscontent">
                                                                    {detailCreator && detailCreator.description}
                                                                </div>
                                                            </div>
                                                        </div> : <div className="content">
                                                            <div id="showless1" className="showlesscontent">
                                                                {detailCreator && detailCreator.description}
                                                            </div>
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* ********* share social ************ */}
                                            <div className="d-flex flex-row w-100 mt-3 align-items-center">
                                                {this.state.visible &&
                                                    <div className="shareout" id="shareout">
                                                        <div className="share_overlaybg">
                                                            <div className="sharebox">
                                                                <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                                <span className="shareicons">
                                                                    <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/copylink.png`} alt="Copy Link" />
                                                                        <p className="txtchange">{this.state.copySuccess}</p>
                                                                    </a>
                                                                    <FacebookShareButton url={shareUrl} quote={this.getMessage(detailCreator)} hashtag={`#${process.env.REACT_APP_SITE_NAME}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/facebook.png`} alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                                    <TwitterShareButton url={shareUrl} title={this.getMessage(detailCreator)} hashtags={['nft', `${process.env.REACT_APP_SITE_NAME}`]} via={process.env.REACT_APP_SITE_NAME}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                                    <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}&description=${this.getMessage(detailCreator)}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/pinterest.png`} alt="Pinterest Share" />
                                                                        <p>Pinterest</p>
                                                                    </a>
                                                                    <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                                    <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/messenger.png`} alt="Messenger Share" />
                                                                        <p>Messenger</p>
                                                                    </a>
                                                                    <WhatsappShareButton url={shareUrl} title={this.getMessage(detailCreator)}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                                    <TelegramShareButton url={shareUrl} title={this.getMessage(detailCreator)}><TelegramIcon size={46} round={true} /><p>Telegram</p></TelegramShareButton>

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>}




                                            </div>


                                        </div>
                                        <div className="biodetamob">
                                            {this.getSocialLinks(detailCreator)}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="dandcont cc-tabs" ref={this.tabRef}>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation" onClick={this.changeOnSale}>
                                        <button className={(this.state.tabIndex === 1) ? "nav-link active" : 'nav-link '} id="Creations-tab" data-toggle="tab" data-target="#Creations" type="button" role="tab" aria-controls="Creations" aria-selected="true">Creations {this.state.totalCount ? <span>({this.state.totalCount})</span> : ''}</button>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={this.changeCollectibles}>
                                        <button className={(this.state.tabIndex === 2) ? "nav-link active" : 'nav-link '} id="Collection-tab" data-toggle="tab" data-target="#Collection" type="button" role="tab" aria-controls="Collection" aria-selected="false">Collections {this.state.dataArrayCount ? <span>({this.state.dataArrayCount})</span> : ''}</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link " id="Favorites-tab" data-toggle="tab" data-target="#Favorites" type="button" role="tab" aria-controls="Favorites" aria-selected="false">Favorites {this.state.dataArrayCount ? <span>({this.state.dataArrayCount})</span> : ''}</button>
                                    </li> */}
                                </ul>
                                <div className="tab-content">
                                    
                                    <div className={(this.state.tabIndex === 1) ? "tab-pane active" : 'tab-pane '}  id="Creations" role="tabpanel" aria-labelledby="Creations-tab">
                                        <div className="card1 progallists mb-5">
                                            <div className="card-body1">
                                                <div className="row">

                                                    {!isCheckCreator && this.state.isSale ? <div className="col-md-12 py-5"><SolarSystemLoading /></div> :


                                                        this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.map(items => (
                                                            <div className="col-md-3" key={items && items.id} onClick={() => { this.gotoArts((items), items && items.ownerWalletAddress) }}>
                                                                <div className="cardlist">

                                                                    <ArtPreview src={items && items.thumbnailHash} alt="" style={{ cursor: "pointer" }} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                                    <span className="cont" style={{ cursor: "pointer" }}>
                                                                        <h3>{items && items.title ? items.title : ""}</h3>
                                                                        <div className="row">
                                                                            {(items && items.drops && items.drops.length > 0) ?
                                                                                <div className="col-sm-6">
                                                                                    <h4 className="usernamelilac">In drops</h4>

                                                                                </div>
                                                                                : ''}


                                                                            {items && items.groups && items.groups != null ? <p className={(items && items.drops && items.drops.length > 0) ? "mb-0 small mt-1 text-right col-sm-6" : 'mb-0 small mt-1 col-sm-12'}>{(items && items.toatalMintedTokens) ? (checkMintedTokensWithEdition(items.groups, items.toatalMintedTokens)) : 1 + ' Edition'}</p> : <p className="mb-0 small mt-1 text-right">1 Edition<span className="usernamelilac"></span></p>}
                                                                        </div>





                                                                        {/* { this.state.show && (items && !items.hasSold) && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])} */}
                                                                        {/* {(items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null ) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1">Edition <span className="usernamelilac">{checkTokenGroup(items && items.groups,items.toatalMintedTokens)}</span> of {(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups,items.toatalMintedTokens)) : 1}</p> : <p className="mb-0 small mt-1">Edition 1 of <span className="usernamelilac">1</span></p>} */}


                                                                    </span>

                                                                </div>


                                                            </div>


                                                        ))}



                                                    {this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.length == 0 && isCheckCreator &&

                                                        <div className="col-md-4 offset-md-4 text-center">
                                                            <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                                                        </div>}
                                                </div>
                                                {this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.length > 0 && isCheckCreator && <ReactPagination pageDetails={{ pageCount: this.state.createArtCount, perPage: this.state.size, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                                            </div>

                                        </div>

                                    </div>
                                    <div className={(this.state.tabIndex === 2) ? "tab-pane active" : 'tab-pane '} id="Collection" role="tabpanel" aria-labelledby="Collection-tab">
                                        <div className="card1 progallists mb-5">
                                            <div className="card-body1">
                                                <div className="row">
                                                    {!isCheckCollect && !this.state.isSale ? <div className="col-md-12 py-5"><SolarSystemLoading /></div> :
                                                        (!this.state.isSale && this.state.dataArray && this.state.dataArray.arts && this.state.dataArray.arts.map(items => (
                                                            // items && items.drops && items.drops.length == 0 && 
                                                            <div className="col-md-3" key={items && items.id} onClick={() => { this.gotoDrops((items), items && items.ownerWalletAddress) }} >
                                                                <div className="cardlist">

                                                                    {/* <img src={items && items.imageHash} alt="" style={{ cursor: "pointer" }} /> */}
                                                                    <ArtPreview src={items && items.thumbnailHash} alt="" style={{ cursor: "pointer" }} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                                    <span className="cont" style={{ cursor: "pointer" }}>
                                                                        <h3>{items && items.title ? items.title : ""}</h3>

                                                                        {(items && items.drops && items.drops.length > 0) && <h4 className="usernamelilac">In drops</h4>}





                                                                        {/* temporary hide not for sale */}
                                                                        {/* { this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])} */}

                                                                        {/* temporary hide not for sale */}

                                                                        {/* {items && items.hasSold && <b>Sold</b>} */}
                                                                        {
                                                                            items && items.groups && items.groups != null ?
                                                                                <p className="mb-0 small mt-1">
                                                                                    {(items && items.toatalMintedTokens) ?
                                                                                        (checkMintedTokens(items.groups, (items && items.groups && items.groups.artsCount))) + " of " +
                                                                                        (checkMintedTokensWithTotalEdition(items.groups, items.toatalMintedTokens)) :
                                                                                        1 + " Total Edition"}</p> :
                                                                                <p className="mb-0 small mt-1">1 Total Edition<span className="usernamelilac"></span></p>}
                                                                    </span>
                                                                </div>
                                                            </div>


                                                        )))}



                                                    {!this.state.isSale && this.state.dataArray && this.state.dataArray.arts && this.state.dataArray.arts.length == 0 && isCheckCollect &&

                                                        <div className="col-md-4 offset-md-4 text-center">
                                                            <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                                                        </div>}

                                                </div>
                                                {!this.state.isSale && this.state.dataArray && this.state.dataArray.arts && this.state.dataArray.arts.length > 0 && isCheckCollect && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.size, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> :
                    (!isInvalidPage && this.state.isError) ? (<div className="container-fluid text-center">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="error-template userdisableview">
                                    <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                    <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED</h3>
                                    <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4>
                                  
                                    <p className="mb-0">The Team</p>
                                    <p className="usernamelilac">DisruptArt</p>


                                </div>
                            </div>

                        </div>
                    </div>) : <InvalidPage />
                }




            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        detailCreator: state.creator.detailCreator,
        usd: state.user.usd,
        singleUserDrop: state.drop.singleUserDrop,
        singleUserArts: state.art.singleUserArts,
        userFollow: state.follow.userFollow,
        failedFollow: state.follow.failedFollow,
        followers: state.follow.followers,
        followings: state.follow.followings,
        unfollowmsg: state.follow.unfollow,
        checkUser: state.follow.checkUser,
        failedCheck: state.follow.failedCheck,
        creatorsList: state.art.creatorArts,
        isCheckCreatorArt: state.art.isCheckCreatorArt,
        isCheckUserDrop: state.drop.isCheckUserDrop,
        isCheckCollect: state.art.isCheckCollect,
        usersList: state.user.usersList,
        wallet: state.wallet.wallet

    }
}
const actionCreators = {
    getDetailCreator: creatorActions.getDetailCreator,
    getUSD: userAction.getUSD,
    getOneUserArts: artActions.getOneUserArts,
    getOneUserDrop: dropActions.getOneUserDrop,
    resetOneUserArts: artActions.resetOneUserArts,
    resetOneUserDrop: dropActions.resetOneUserDrop,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    resetFailedFollow: followActions.resetFailedFollow,
    getFollowers: followActions.getFollowers,
    getFollowings: followActions.getFollowings,
    resetFollowers: followActions.resetFollowers,
    resetFollowing: followActions.resetFollowing,
    unfollow: followActions.unfollow,
    resetUnFollow: followActions.resetUnFollow,
    checkFollowUser: followActions.checkFollowUser,
    resetCheckUser: followActions.resetCheckUser,
    resetFailedCheck: followActions.resetFailedCheck,
    creatorArts: artActions.creatorArts,
    resetCreatorArts: artActions.resetCreatorArts,
    getViewCount: creatorActions.getViewCount,
    getUserProfile: userAction.getUsers,
    resetGetUsers: userAction.resetGetUsers,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,

}
const connectCreatorProfile = connect(mapStateToProps, actionCreators)(CreatorProfile)
export { connectCreatorProfile as CreatorProfile }
