
export function authHeader(){
   let userDetail =  localStorage.getItem("userDetail")
   let user = JSON.parse(userDetail)
   if(user && user.data && user.data.accessToken){
        return {'Authorization' : "Bearer" +  " " + user.data.accessToken}
   }else{
       return {}
   }
}

export function accessHeader(){
    let userDetail =  localStorage.getItem("webLogin")
    let user = JSON.parse(userDetail)
    if(user && user.data && user.data.accessToken){
         return {'Authorization' : "Bearer" +  " " + user.data.accessToken}
    }else{
        return {}
    }
 }

export function isUserLogin(){
    let userDetail =  localStorage.getItem("userDetail")
   if(userDetail){
        return true
   }else{
       return false
   }
}

export function isWebLogin(){
    let userDetail =  localStorage.getItem("webLogin")
   
   if(userDetail){
        return true
   }else{
       return false
   }
}
export function getRole(){
    let userDetail =  localStorage.getItem("webLogin")
    let user = JSON.parse(userDetail)
   if(user && user.data && user.data.authUser && user.data.authUser.roleId){
        return user.data.authUser.roleId
   }else{
       return false
   }
}

export function isConnectWallet(){
    let useraddress = ""
    if(window.sessionStorage.getItem("CURRENT_USER"))
    useraddress=JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))["addr"];
    if(useraddress && useraddress != null ){
        return true
    }
    else{
        return false
     }

}
export function refreshToken(){
    let userDetail =  localStorage.getItem("webLogin")
    let user = JSON.parse(userDetail)
    if(user && user.data && user.data.refreshToken){
         return {'refreshToken':user.data.refreshToken}
    }else{
        return {}
    }
 }

 export function adminrefreshToken(){
    let userDetail =  localStorage.getItem("adminDetail")
    let user = JSON.parse(userDetail)
    if(user && user.data && user.data.token){
         return {'refreshToken':user.data.token}
    }else{
        return {}
    }
 }

export function adminToken(){
    let adminDetail =  localStorage.getItem("adminDetail")
    let admin = JSON.parse(adminDetail)
    if(admin && admin.data && admin.data.token){
         return {'Authorization' : "Bearer" +  " " + admin.data.token}
    }else{
        return {}
    }
 }

export function isAdminLogin(){
    let user = ""
    if(localStorage.getItem("adminDetail")){
        let admin = localStorage.getItem('adminDetail')
        user = admin && JSON.parse(admin)
    }
    if(user){
        return user;
   }else{
       return false
   }

  
}
export function getCurrentUserId(){
    let userDetail =  localStorage.getItem("webLogin")
    let user = JSON.parse(userDetail)
    if(user && user.data && user.data.authUser && user.data.authUser.id){
         return user.data.authUser.id
    }else{
        return ''
    }
 }
export function getUniqueUUID(){
    let userDetail = localStorage.getItem("webLogin")
    let user = JSON.parse(userDetail)
    if (user && user.data && user.data.authUser && user.data.authUser.uuid) {
        return user.data.authUser.uuid
    }else{
        return false
    }
}

export function isSuperAdmin(){
    let user = {}
    if(localStorage.getItem("adminDetail")){
        let admin = localStorage.getItem('adminDetail')
        user = admin && JSON.parse(admin)
        if(user && user.data && user.data.userInfo && user.data.userInfo.roleId === 3){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
    

  
}