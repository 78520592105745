import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from 'store/actions';
import Modal from 'react-modal'
import { configPath } from '../../config';


var timeout;
const customStyles = {
    content: {
        top: '10%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',


    },
};
class ChangePasswordPopup extends Component{
    constructor(props){
        super(props);
        this.state = {
            password : '',
            confirmPassword : '',
            submitted : false,
            verificationCode : '',
            uuid : '',
            isSuccess : false,
            message:'',
            isAlert : false,
            isInvalid : false,
            isOpen: true,
            show: false,  
        }
    }
    componentDidMount(){
        
        
        
    }
    static getDerivedStateFromProps(props,state){
       
        
        
    }
    componentDidUpdate(props,state){
        if(this.props && this.props.failedchangeuser && this.props.failedchangeuser.length > 0 && ((this.props.failedchangeuser) !== (props.failedchangeuser))){
            this.setState({
                isOpen: false
            })
            this.props.resetFailedchangeUserPassword()

            if(this.props.failedchangeuser && typeof this.props.failedchangeuser === 'string'){
                this.props.changepasswordFailed(this.props.failedchangeuser)
            }else{
                this.props.changepasswordFailed("Unhandled error occured")
            }
            
        }
        if((state && state.isAlert)){
            this.closeAlert()
        }
        if((this.props && this.props.changeuser && this.props.changeuser.statusCode) === 200 && (this.props.changeuser !== (props.changeuser))){
          
            this.props.resetchangeUserPassword()
            this.props.changepasswordSuccess()
            this.setState({
                isSuccess : true,
                password:'',
                isOpen: false
            })
        }
      
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    closeAlert = () => {
        setTimeout(() => {
              
            this.setState({isAlert:false,message:'',style:""})
        },15000)
    }
    checkPassword = (value) => {
        if(value == (this.state.password)){
            return true
        }else{
            return false
        }
    }
    validPassword = (value) => {
        if((value && value.length) >= 8){
            return true
        }else{
            return false
        }
    }
    isSamePassword = (newPass,cnfrmPass) => {
        if(newPass === cnfrmPass){
            return true
        }else{
            return false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const {password,uuid,confirmPassword} = this.state
        this.setState({submitted:true})
        if(password && this.validPassword(password) && this.isSamePassword(password,confirmPassword)){
            let obj = {
                newPassword : password
            }
            this.props.changeUserPassword(JSON.stringify(obj),uuid)
        }
    }
    
    closeModalOnly = () => {
        this.setState({
            isOpen: false
        })
       
        this.props.closeModal()
       
    }
    
    render(){
        return (
            <Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Switch Modal"
                    ariaHideApp={false}>
                    <div className="custom-ui reportpop py-3 px-3 minw-28rem">
                    <h4 className='mb-3 tex-center mt-2  px-5'>Change password</h4>
                    {<button onClick={this.closeModalOnly} className="btn btn-primary mb-3 clsbtn">X</button>}
                        <div className="">
                            <div className="form-group text-center">
                                <div className="custom-btn text-center mt-5">                                   
                                        
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <label >Password</label>
                                        <input type="password" className="form-control" value={this.state.password} name="password" onChange={this.handleChange} placeholder="Enter password here"/>
                                        {this.state.submitted && !this.state.password && <div className="mandatory">Password Required</div>}
                                        {this.state.submitted && this.state.password && !this.validPassword(this.state.password) && <div className="mandatory">Password must be atleast 8 characters</div>}

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input type="password" name="confirmPassword" id="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} className="form-control" placeholder="Enter confirm password here" disabled={!(this.validPassword(this.state.password))} />
                                        {this.state.submitted && !this.state.confirmPassword && <div className="mandatory">Password is required</div>}
                                        {this.state.submitted && this.state.confirmPassword && (this.state.confirmPassword !== this.state.password) && <div className="mandatory">{"Password did not match"}</div>}
                                        {!this.state.submitted && this.state.confirmPassword && (this.state.confirmPassword !== this.state.password) && <div className="mandatory-req">{"Password did not match"}</div>}
                                        {this.state.confirmPassword && (this.state.confirmPassword == this.state.password) && <div className="mandatory-req">{"Looks good!"}</div>}
                                    </div>
                                    
                                    <div className="form-group text-center">
                                        <button type="submit" className="btn btn-primary px-5">Change</button>
                                    </div>
                                </form>
                                       
                                        </div>
                                        
                                   
                                </div></div>
                        </div>
                    

                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        changeuser : state.user.changeSelfPass,
        failedchangeuser : state.user.changeSelfPassFailed
    }
}

const actionCreators = {
    changeUserPassword : userAction.changeSelfPassword,
    resetchangeUserPassword : userAction.resetChangeSelfPassword,
    resetFailedchangeUserPassword : userAction.resetChangeSelfPasswordFailed
}

const connectChangePasswordPopup = connect(mapStateToProps,actionCreators)(ChangePasswordPopup)

export {connectChangePasswordPopup as ChangePasswordPopup}