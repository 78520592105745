import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {adminActions, userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import history from 'helpers/history';

class EditAccounts extends Component{
    constructor(props){
        super(props);
        this.state = {
            fullName:'',
            submitted: false,
            email :'',
            password:'',
            repassword:'',
            isSend : false
        }
    }
    componentDidMount(){
        if((this.props && this.props.match && this.props.match.params && this.props.match.params.id)){
            let id = this.props.match.params.id
            this.props.getUserDetail(id)
        }else{
            history.goBack()
        }
    }
    componentDidUpdate(props,state){
        if(this.props 
            && this.props.userDetail 
            && this.props.userDetail.statusCode === 200 && this.props.userDetail.data && 
            this.props.userDetail.data.admin
            && (this.props.userDetail !== props.userDetail)){
                this.props.resetUserDetail()
                this.setState({
                    fullName : this.props.userDetail.data.admin.displayName,
                    email : this.props.userDetail.data.admin.email,
                })
            }
    }
 
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
   
    gotoBack = () => {
        history.goBack()
    }
    onFullNameChange = (e) => {
        this.setState({fullName : e.target.value})
    }
    onEmailChange = (e) => {
        this.setState({email : e.target.value})
    }
    onPasswordChange = (e) => {
        this.setState({password : e.target.value})
    }
    onRepasswordChange = (e) => {
        this.setState({repassword : e.target.value})
    }
    handleChecked = (value) => {
        const {isSend} = this.state
        const isSended = isSend ? false : true
        this.setState({isSend : isSended})
    }
    checkPassword = (value) => {
        if((value && value.length) >= 8){
            return true
        }else{
            return false
        }
    }
    checkConfirmPassword = () => {
        const {password,repassword} = this.state
        if(password == repassword){
            return true
        }else{
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if(emailReg.test(value)){
            return true
        }else{
            return false
        }
    } 
    checkFullName = (value) => {
        if(((value && value.length) <= 100) && ((value && value.length) >= 3)){
            return true
        }else{
            return false
        }
    }
   handleSubmit = (e) => {
       e.preventDefault()
       this.setState({submitted:true})
       const {fullName,email,password,repassword,isSend} = this.state
        if(fullName && email && password && repassword){
            if(this.checkemail(email)){
                if(this.checkPassword(password)){
                    if(this.checkFullName(fullName)){
                        if(isSend) {
                            history.goBack()
                        }
                    }
                }
            }else{
                toast.error("Please enter proper email address")
            }
        }else{
            toast.error("Please fill out mandatory fileds")
        }
   }
    render(){
        const {account} = this.state
        return(
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-5 admintitle">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">View Account <span className="betaLink">(Soon)</span></h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}>Back</button>  </div>
                        
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="fullName" className="font-weight-bold">Full name</label>
                                <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Enter full name" value={this.state.fullName} onChange={this.onFullNameChange} disabled/>
                                {this.state.submitted && !this.state.fullName && <div className="mandatory">{"Fullname required"}</div>}
                                {!this.state.submitted && this.state.fullName && !this.checkFullName(this.state.fullName) && <div className="mandatory-req">{"Must be contain atleast 3 characters and atmost 100 characters"}</div>}
                                {this.state.submitted && this.state.fullName && !this.checkFullName(this.state.fullName) && <div className="mandatory">fullname must be contain atleast 3 characters and atmost 100 characters</div>}                                        

                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="email" className="font-weight-bold" >Email</label>
                                <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="Enter email" value={this.state.email} onChange={this.onEmailChange} disabled/>
                                {this.state.submitted && !this.state.email && <div className="mandatory">{"Email required"}</div>}
                                {this.state.submitted && this.state.email && !this.checkemail(this.state.email) && <div className="mandatory">Invalid Email</div>}                                        

                            </div>
                            
                                                                                          
                        </div>
                    </form>
                </div>
             
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        userDetail : state.adminaccount.adminUser,

    }
}
const actionCreator = {
    getUserDetail : adminActions.getAdminUser,
    resetUserDetail : adminActions.resetGetAdminUser
}
const connectEditAccounts = connect(mapStateToProps,actionCreator)(EditAccounts)
export {connectEditAccounts as EditAccounts}