import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import { discoverActions, userAction, walletActions } from 'store/actions'

import { Helmet } from 'react-helmet';
import AliceCarousel from 'react-alice-carousel';
import history from 'helpers/history'
import PubSub from 'pubsub-js';
import moment from 'moment';
import { SolarSystemLoading } from 'react-loadingg';

import "react-alice-carousel/lib/alice-carousel.css";
import { ArtPreview } from './art-preview/art-preview';
import { OwlCarousalArt } from './owlCarousalArt';
import { OwlCarousal } from './owlCarousalCreator';
import { configPath } from '../config'
import { flowDecimal, getUSDValue, isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { SpotlightAliceCarousal } from './spotlightalicecarousal';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { AliceCarousalArt } from './aliceCarousal';
import { Preview } from './preview';
import { DiscoverArtPreview } from './art-preview/discover-art-preview';
import { DiscoverProfilePreview } from './art-preview/discoverv3-preview';

class DiscoverV3 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			responsive: {
				0: { items: 1 },
				767: { items: 1 },
				992: { items: 2 },
				1920: { items: 2 }
			},
			responsiveArt: {
				0: { items: 1 },
				576: { items: 2 },
				768: { items: 3 },
				1920: { items: 3 }
			},
			responsiveCreator: {
				0: { items: 1 },
				576: { items: 2 },
				768: { items: 3 },
				1920: { items: 3 }
			},
			spotLight: {},
			featureArts: [],
			featureCreators: [],
			featureCollectors: [],
			sortBy: 'updatedAt',
			sortOrder: 'DESC',
			offSet: 0,
			recordLimit: 4,
			artRecord: 4,
			creatorRecord: 6,
			collectorRecord: 8,
			isArtTrue: false,
			isCreatorTrue: false,
			isCollectorTrue: false,
			isSpotCheck: false,
			isFeatureArt: false,
			isCreator: false,
			isCollector: false,
			spotlightOn: true,
			show: false,
			rate: 0,
			randomCreators: []
		}

	}
	componentDidMount() {
		localArtPage()
		localDropPage()
		localAirdropPage()
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		})


		this.getUSD()
	}
	getSpotLight = (offSet, recordLimit) => {
		let obj = {
			sortBy: this.state.sortBy,
			sortOrder: this.state.sortOrder
		}
		if (offSet != 0) {
			obj['offSet'] = null
		} else {
			obj['offSet'] = 0
		}
		if (recordLimit) {
			obj['recordLimit'] = recordLimit
		}
		this.gotoTop()
		this.props.getAllSpotLightArts(obj)
	}
	getFeatureArt = (offSet, recordLimit) => {
		let obj = {
			sortBy: this.state.sortBy,
			sortOrder: this.state.sortOrder
		}
		if (offSet != 0) {
			obj['offSet'] = null
		} else {
			obj['offSet'] = 0
		}
		if (recordLimit) {
			obj['recordLimit'] = recordLimit
		}
		this.props.getAllFeatureArts(obj)
	}
	getFeatureCreator = (offSet, recordLimit) => {
		let obj = {
			sortBy: this.state.sortBy,
			sortOrder: this.state.sortOrder,
			count: 75,
			shuffle: true
		}
		if (offSet != 0) {
			obj['offSet'] = null
		} else {
			obj['offSet'] = 0
		}
		if (recordLimit) {
			obj['recordLimit'] = recordLimit
		}
		this.props.getCreators(obj)
	}
	getFeatureCollector = (offSet, recordLimit) => {
		let obj = {
			sortBy: this.state.sortBy,
			sortOrder: this.state.sortOrder
		}
		if (offSet != 0) {
			obj['offSet'] = null
		} else {
			obj['offSet'] = 0
		}
		if (recordLimit) {
			obj['recordLimit'] = recordLimit
		}
		this.props.getAllFeatureCollectors(obj)
	}


	componentDidUpdate(props, state) {
		if (this.props &&
			this.props.spotArts &&
			this.props.spotArts.statusCode == 200 &&
			(this.props.spotArts !== (props.spotArts))
		) {

			props.resetSpotLight()
			this.setState({
				spotLightList: this.props && this.props.spotArts && this.props.spotArts.data && this.props.spotArts.data.entity && this.props.spotArts.data.entity,
				isSpotCheck: true
			}, () => {
				this.filterSpotLight()
			})

		}
		if (this.props &&
			this.props.featureArts &&
			this.props.featureArts.statusCode == 200 &&
			(this.props.featureArts !== (props.featureArts))) {
			props.resetFeatureArts()
			this.setState({
				featureArts: this.props && this.props.featureArts && this.props.featureArts.data && this.props.featureArts.data.entity,
				isFeatureArt: true,

			})

		}
		if (this.props &&
			this, props.randomCreatorsList &&
			this.props.randomCreatorsList.statusCode == 200 &&
			(this.props.randomCreatorsList !== (props.randomCreatorsList))) {
			props.resetRandomCreators()
			this.setState({
				randomCreators: this.props && this.props.randomCreatorsList && this.props.randomCreatorsList.data && this.props.randomCreatorsList.data.creators,
				isCreator: true
			})

		}
		if (this.props &&
			this.props.randomCreatorsFailed &&
			this.props.randomCreatorsFailed.length > 0 &&
			(this.props.randomCreatorsFailed !== (props.randomCreatorsFailed))) {
			props.resetRandomCreatorsFailed()
			this.setState({
				randomCreators: [],
				isCreator: true
			})
		}
	}


	showLoader() { PubSub.publish('msg', true); }
	hideLoader() { PubSub.publish('msg', false); }
	gotoDetail = (items) => {

		let webLog = localStorage.getItem("webLogin")
		let loginDetail = JSON.parse(webLog)

		if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

			history.push(`/list/${items && items.tokenId}`)

		} else {

			history.push(`/art/${items && items.id}`)
		}


	}
	gotoCreator = (owner) => {
		if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
			history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
		} else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
			history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
		}
	}
	gotoSpecificCreator = (owner) => {
		history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })

	}
	handleSpotlight = () => {
		this.setState({ spotlightOn: true })
	}
	handleArts = () => {
		history.push('arts?page=1&limit=12')
	}
	handleCreator = () => {
		history.push('creators?page=1&limit=12')
	}

	getEdition = (items) => {

		if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.dropsCount != null)) {
			return <h6>Edition : 1</h6>
		} else if ((items && items.drops && items.drops[0] && items.dropsEdition != null)) {
			return (<h6>Edition :  {items.dropsEdition}</h6>)
		} else if ((items.toalCount != null && items.dropsCount != null)) {
			return <h6>Edition : {((items.toalCount) - (items.dropsCount))}</h6>
		} else if ((items.toalCount != null && items.dropsCount == null)) {
			return <h6>Edition : {((items.toalCount))}</h6>
		} else {
			<h6>Edition : 1</h6>
		}
	}
	gotoPath = (e, items) => {
		if (e.target.tagName == "H3") {
			this.gotoDetail(items)
		} else if (e.target.tagName == "SPAN") {
			this.gotoCreator(items && items.creator)
		}
	}
	gotoArts = () => {
		history.push('/arts')
		this.handlePage()
	}
	gotoCreators = () => {
		history.push('/creators')
		this.handlePage()
	}
	gotoCollectors = () => {
		history.push('/collectors')
		this.handlePage()
	}
	gotoTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
	gotoDrops = () => {

		history.push('/drops')
		this.handlePage()
	}
	handlePage = () => {
		localStorage.setItem("page", 0)
	}
	getUSD = async () => {
		this.getSpotLight('', '')
		this.getFeatureArt('', '')
		this.getFeatureCreator('', '')
		// this.getFeatureCollector('', '')
		

	}
	filterSpotLight = () => {
		console.log(this.state.spotLightList)
		const ListedArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops[0]))
		const notInDropsArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops.length === 0))

		if (this.state.spotLightList && this.state.spotLightList.length > 0) {
			if (ListedArray && ListedArray.length > 0) {
				var item = ListedArray[Math.floor(Math.random() * ListedArray.length)];
				if (item && item.id) {
					this.setState({ spotLight: item })
				}
			} else if (notInDropsArray && notInDropsArray.length > 0) {
				var item = notInDropsArray[Math.floor(Math.random() * notInDropsArray.length)];
				if (item && item.id) {
					this.setState({ spotLight: item })
				}
			}
		} else {
			this.setState({ spotLight: {} })
		}


		console.log(item)


	}
	gotoBuy = (items) => {
		if (items.id && items.drops && items.drops[0] && items.drops[0].id) {
			history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
		} else if (items.id) {
			history.push(`/art/${items.id}`)
		}
	}
	getPrice = (items) => {
		if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
			if(items && items.drops && items.drops[0] && items.drops[0].storefrontListingResourceId && items.drops[0].withDapper){
				return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} USD`
			}else{
				// return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} FLOW`
				return ''
			}
		} else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
			if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
				const lastBid = items.drops[0].auction.lastBid
				const bidIncrement = items.drops[0].auction.minimumBidIncrement
				var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
				// return `${flowDecimal(parseFloat(amount))} FLOW`
				return ''
			} else {
				if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
					const startingPrice = items.drops[0].auction.startPrice
					const bidIncrement = items.drops[0].auction.minimumBidIncrement

					var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
					if (this.dayClosed(items.drops[0])) {
						return false
					} else {
						// return `${flowDecimal(parseFloat(amount))} FLOW`
						return ''
					}

				}
			}
		} else {
			return false
		}
	}
	dayClosed = (items) => {
		var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
		var countDownDate = new Date(futureDate).getTime();
		var now = new Date().getTime();
		var distance = countDownDate - now;
		if (distance < 0) {
			return true
		} else {
			return false
		}
	}
	getUsdValue = (items, rate) => {
		if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
			return `$${(getUSDValue(rate, items.drops[0].markedPrice))} `
		} else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
			if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
				const lastBid = items.drops[0].auction.lastBid
				const bidIncrement = items.drops[0].auction.minimumBidIncrement
				var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
				return `$${getUSDValue(rate, (amount))} `
			} else {
				if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
					const startingPrice = items.drops[0].auction.startPrice
					const bidIncrement = items.drops[0].auction.minimumBidIncrement

					var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
					if (this.dayClosed(items.drops[0])) {
						return false
					} else {
						return `$${getUSDValue(rate, (amount))} `
					}

				}
			}
		} else {
			return false
		}
	}
	startTime = (items) => {
		var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
		var countDownDate = new Date(futureDate).getTime();
		var now = new Date().getTime();
		var distance = countDownDate - now;
		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		var seconds = Math.floor((distance % (1000 * 60)) / 1000);

		if (distance < 0) {
			return `Bidding Closed`
		}

	}
	isAvailabletoBuy = (items) => {
		if (items && items.drops && items.drops[0]) {
			if (this.dayClosed(items.drops[0])) {
				return false
			}
			return true
		} else {
			return false
		}
	}
	
	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}
	render() {
		const { spotLight, featureArts, featureCreators, featureCollectors, randomCreators, isFeatureArt, isCreator, isCollector } = this.state
		const metaDesc = `Discover amazing NFT collections from various disruptor creators. ${process.env.REACT_APP_BASE_SITE} Marketplace's featured and spotlight NFTs`
		const bgImage1 = `../${configPath.imageAssets}/slider/header-5.jpg`
        const bgImage2 = `../${configPath.imageAssets}/slider/gal5.jpg`
		return (

			<Fragment>
				<Helmet>
					<title>{`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}</title>
					<meta name="title" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}></meta>
					<meta name="description" content={metaDesc}></meta>
				</Helmet>

				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="mb-0 mt-3 text-center text-uppercase">Art for Disruptors</h1>
								<h5 className="my-3 text-center">Connecting Cultures Revolutionizing Equity</h5>
							</div>
						</div>
					</div>
					<div className="dicoverarts">
						<div className='bigart'>
							{(spotLight && spotLight.id) ?
								<div className='row align-items-center'>
									<div className='col-md-6'>
										<div className='bigartimg mb-5' style={{ background: "url(" + (spotLight && spotLight.thumbnailHash) + ") no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' }} onClick={() => this.gotoBuy(spotLight)}>
											<div className='baimgcover'>
												<DiscoverArtPreview src={(spotLight && spotLight.thumbnailHash)} alt="" showControls={true} autoPlay={true} mimeType={spotLight && spotLight.thumbnailMimeType} />

											</div>
										</div>
									</div>

									<div className="col-md-6">
										<div className='bigartdetails'>
											<h3 className="bigarttit spottit" onClick={() => this.gotoBuy(spotLight)}>{spotLight.title}</h3>
											<span className="ownerDec" title={spotLight && spotLight.creator && spotLight.creator.fullName} onClick={() => this.gotoCreator(spotLight && spotLight.creator)}>{spotLight && spotLight.creator && spotLight.creator.fullName && `by ${spotLight.creator.fullName}`}</span>
											<div className="bidown mb-5">
												<div className="row align-items-center">
													<div className="col-6">

														<h4 className="usernamelilac">{this.getPrice(spotLight)}</h4>
														{this.startTime(spotLight && spotLight.drops && spotLight.drops[0]) ? <div className="usernamelilac">{spotLight && spotLight.drops && spotLight.drops[0] && spotLight.drops[0].auction && spotLight.drops[0].auction.id && this.startTime(spotLight && spotLight.drops && spotLight.drops[0])}</div> : ''}

													</div>
													{this.isAvailabletoBuy(spotLight) ? <div className="col-6">
														<button className="btn btn-primary-fill" type="button" onClick={() => this.gotoBuy(spotLight)}>Purchase</button>
													</div> : ''}

												</div>
											</div>
										</div>
									</div>
								</div> : this.state.isSpotCheck ? <div className='row align-items-center'>

									<div className='col-md-2 offset-md-5'>
									<img src={`../${configPath.imageAssets}/no-art-discover.png`} alt="" className='mw-100' /></div></div> : ''}


						</div>


					</div>

				</div>

				{!isFeatureArt && <div className="featuredsec featureart dark-purple">
					<div className="container">
						<div className="ds-title ds-title-sm text-uppercase text-center">Featured Drops</div>
						<div className={isFeatureArt ? 'row' : 'row discovload'}>

							{!isFeatureArt && <SolarSystemLoading />}
						</div>
					</div>
				</div>}
				{((featureArts && featureArts.length > 0) && isFeatureArt) ?
					<div className="featuredsec featureart dark-purple dsv34item">
						<div className="container">
							<div className="ds-title ds-title-sm text-uppercase text-center">Featured Drops</div>
							{featureArts && featureArts.length > 0 &&
								<AliceCarousalArt featureArts={featureArts} rate={this.state.rate} />}

						</div>
						{isFeatureArt && (featureArts && featureArts.length > 0) && <div className="text-center mt-3 mt-sm-5 ds-button">
							<button className="btn btn-rounded btn-primary-fill  btn-sm text-uppercase  " onClick={this.gotoDrops} style={{ textTransform: "capitalize" }}>All Drops</button>
						</div>}
						{featureArts && featureArts.length == 0 && isFeatureArt &&
							<div className="col-md-4 offset-md-4 text-center">
								<i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br />
								<button type="button" className=" btn btn-rounded btn-primary-fill text-uppercase  " onClick={this.gotoArts}>All Drops</button>
							</div>
						}
					</div>
					: (isFeatureArt) ? <div className="featuredsec featureart dark-purple">
					<div className="container">
						<div className="ds-title ds-title-sm">Featured Drops</div>
						<div className='row align-items-center'>

									<div className='col-md-2 offset-md-5'>
									<img src={`../${configPath.imageAssets}/no-art-discover.png`} alt="" className='mw-100' /></div></div>
					</div>
				</div> : ''}



				{isCreator ? <div className="featuredsec featuredcreator d-none">
					<div className="container">
						<div className="ds-title ds-title-sm text-center text-uppercase">Meet the Global Disruptors</div>
						<div className='dsv3-mtgdlist text-center'>
							{randomCreators && randomCreators.length > 0 && randomCreators.map((items, index) => (
								<div className='mtgdbox' title={(items && items.fullName)} onClick={() => this.gotoSpecificCreator(items)}>
									<div className='mtgd'  >
										<DiscoverProfilePreview src={items && items.profileImage && (`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.profileImage}`)} alt="" className="comimg" style={{}} />
									</div>
									<p>{(items && items.fullName)}</p>
								</div>

							))}

						</div>
						<div className="text-center mt-2 ds-button"><button className="btn btn-primary text-uppercase  " type='button' onClick={this.gotoCreators}> All Creators</button></div>
					</div>
				</div> : <div className="featuredsec featuredcreator d-none">
					<div className="container">
						<div className="ds-title ds-title-sm">Meet the Global Disruptors</div>
						<div className='dsv3-mtgdlist'><SolarSystemLoading /></div>
					</div>
				</div>
				}


				<div className="dark-purple" id="whodis">
					<div className="container">
						<h2 className="text-center mb-5 text-uppercase">How do we Disrupt Art?</h2>
						<div className="watdcon3box">
							<div className='row'>
								<div className='col-md-4'>
									<div className='hwdabox' style={{ background: "url(" +(bgImage1) +") no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' }}>
										<div className='hwdaboxcon'>
											<h5>Marketplace</h5>
											<h3>NFT Marketplace for everyone</h3>
											<img src={'assets/images/logoaw.png'} alt="" className="mw-100" />
										</div>
									</div>
									<div className="text-center"><button className="btn btn-primary-fill" type='button'onClick={this.gotoDrops} >COLLECT NFTS</button></div>
								</div>

								<div className='col-md-4'>
									<div className='hwdabox' style={{ background: "url(" +(bgImage2) +") no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' }}>
										<div className='hwdaboxcon'>
											<h5>StoreFront</h5>
											<h3>Custom built NFT storefronts & PFP collections</h3>
											<img src={'assets/images/dastore-w.png'} alt="" className="mw-100" />
										</div>
									</div>
									<div className="text-center"><a className="btn btn-primary-fill" href="https://studio.disrupt.art/" target="_blank">LAUNCH ULTRA STOREFRONT</a></div>
								</div>

								<div className='col-md-4'>
									<div className='hwdabox' style={{ background: "url(https://api.disrupt.art/api/v1/convention-management/convention/1/gallery/99/file/69ad68ab-1664532451633.jpg) no-repeat", backgroundSize: 'cover', backgroundPosition: 'center' }}>
										<div className='hwdaboxcon'>
											<h5>Community Events</h5>
											<h3>Largest & Diverse Web3 Conference for Creators</h3>
											<img src={'https://midwestcon.disrupt.art/assets/img/midwestcon-logo.png'} alt="" className="mw-100" />
										</div>
									</div>
									<div className="text-center"><a className="btn btn-primary-fill" href="https://midwestcon.disrupt.art/" target="_blank">BECOME A SPONSOR</a></div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		spotArts: state.discover.spotArts,
		featureArts: state.discover.featureArts,
		featureCreators: state.discover.featureCreators,
		featureCollectors: state.discover.featureCollectors,
		randomCreatorsList: state.discover.randomCreators,
		randomCreatorsFailed: state.discover.randomCreatorsFailed
	}
}
const actionCreators = {
	getAllSpotLightArts: discoverActions.getAllSpotLightArts,
	resetSpotLight: discoverActions.resetSpotLight,
	getAllFeatureArts: discoverActions.getAllFeatureArts,
	getAllFeatureCreators: discoverActions.getAllFeatureCreators,
	getAllFeatureCollectors: discoverActions.getAllFeatureCollectors,
	resetFeatureArts: discoverActions.resetFeatureArts,
	resetFeatureCreators: discoverActions.resetFeatureCreators,
	resetFeatureCollectors: discoverActions.resetFeatureCollectors,
	getCreators: discoverActions.getAllRandomCreators,
	resetRandomCreators: discoverActions.resetRandomCreators,
	resetRandomCreatorsFailed: discoverActions.resetRandomCreatorsFailed
}

const connectDiscoverv2 = connect(mapStateToProps, actionCreators)(DiscoverV3)
export { connectDiscoverv2 as DiscoverV3 }

