import { configPath } from "config";
import { accessHeader } from "helpers"
import { fetchService } from "./fetch-service";
import PubSub from 'pubsub-js'; 

export const tagServices = {
    getTagsList,
    getAllCategories,
    getAllTrendingTags
}


function getTagsList(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	
	}
	let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	// let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	// let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''


    params =  offSet + recordLimit + search 
	return fetchService.fetchResource(`${configPath.apiUrl}/tag-management/tag?` + params,requestOptions).then(handleResponse,handleError)
}

function getAllCategories(){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/nft-category-management/nft-category` ,requestOptions).then(handleResponse,handleError)

}
function getAllTrendingTags(){
    const requestOptions = {
        method : 'GET',
        headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/tag-management/trending-tag` ,requestOptions).then(handleResponse,handleError)
}


function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            throw err
        }else{
            PubSub.publish('msg', false)

        }
    }
    catch(err) {
    console.log(err)
  
    }
}
