import { configPath } from '../../config';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'

class CreatorStoreFooter extends Component {
	constructor(props) {
		super(props);
		
	}
	

	
	render() {
		return (
			<Fragment>
					<div className="ft">
						<div className="container">
							<div className="flow">
								<div className="blk">
									<div className="txt"><h4 className="">TECHNOLOGY</h4></div>
									<div className="logos">
										<ul className="footer-links builtwallet">
											<li >
												<a target="_blank" href="https://www.onflow.org/">
													<img src={`../${configPath.imageAssets}/onflow.png`} alt="" className="onflowicon" />
													<div className="txt">Flow</div>
												</a>
											</li>
											<li >
												<a target="_blank" href="https://www.meetdapper.com/">
													<img src={`../${configPath.imageAssets}/dapper_logo.png`} alt="" className="onflowicon" />
													<div className="txt">Dapper</div>
												</a>
											</li>
											<li >
												<a target="_blank" href="https://www.pinata.cloud/">
													<img src={`../${configPath.imageAssets}/pinata.png`} alt="" className="onflowicon" />
													<div className="txt">Pinata</div>
												</a>
											</li>
											<li className="developlink "><a target="_blank" href="https://disrupt.art/" rel="noreferrer"><img src={`../${configPath.imageAssets}/disruptart.png`} alt="" className="onflowicon" /><div className="txt"><span className="small">Powered by</span><span>DisruptArt</span></div></a></li>
											<li className="developlink ">
												<a target="_blank" href="https://blaze.ws/">
													<img src={`../${configPath.imageAssets}/blaze-logo.png`} alt="" className="onflowicon" />
													<div className="txt">
														<span className="small">Developed at</span>
														<span> BLA<span className="text-danger">Z</span>E.ws</span>
													</div>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="ft_blk">
								<div className="cnt">
									<p>© 2023 <a href="#">DisruptArt Store</a></p>
								</div>
								<div className="icon">
									<ul>
										<li>
											<a href="https://twitter.com/DisruptArt" target="_blank"><i className="fa fa-twitter"></i></a>
										</li>
										<li>
											<a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fa fa-instagram"></i></a>
										</li>
										<li>
											<a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					
			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
	}
}
const actionCreators = {
	

}
const connectCreatorStoreFooter = connect(mapStateToProps, actionCreators)(CreatorStoreFooter)
export { connectCreatorStoreFooter as CreatorStoreFooter }
