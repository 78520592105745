import { configPath } from "../config";
import { accessHeader } from "helpers"
import { fetchService } from "./fetch-service";
import PubSub from 'pubsub-js'; 

export const txnServices = {
    getTxnHistory,
    forsaleEventHistoryInitiate,
    forsaleEventHistoryCancelled,
    forsaleEventHistoryFailure,
    forsaleEventHistoryTransferSuccess,
    forsaleEventHistorylistSuccess,
    forsaleEventHistoryListStage
}


function getTxnHistory(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''
	let showHiddenArts = obj && obj.showHiddenArts ? '&showHiddenArts=' + obj.showHiddenArts : ''



	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts

	return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/sale` + params, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistoryInitiate(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale`, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistoryCancelled(hisId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale/${hisId}/cancellation`, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistoryFailure(hisId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale/${hisId}/failiure`, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistorylistSuccess(hisId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale/${hisId}/listing/completion`, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistoryTransferSuccess(hisId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale/${hisId}/transfer/completion`, requestOptions).then(handleResponse, handleError)
}
function forsaleEventHistoryListStage(obj,hisId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-history-management/dapper/for-sale/${hisId}/stage/list`, requestOptions).then(handleResponse, handleError)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)

            Promise.reject("Forsale TypeError : Failed to fetch")
        }else{
            PubSub.publish('msg', false)

            Promise.reject(err)

        }
    }
    catch(err) {
        PubSub.publish('msg', false)

        Promise.reject(err)
  
    }
}
