import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { adminServices } from 'services';


class FetchStoreDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            isError: false,
            id : ''
        }

    }
    componentDidMount() {
        this.callApi()
    }


    callApi = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.uuid) {
            let id = decodeURIComponent(this.props.match.params.uuid)
            this.getDetail(id)
            this.setState({id})
        }
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getDetail = (id) => {
        this.showLoader()
        adminServices.getStoreDetail(id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) == 200 && res.data && res.data.store) {
                this.setState({
                    detail: res.data.store
                })
            }
        }).catch(err => {
            this.hideLoader()

        })
    }
    viewsalesReport = () => {
        if(this.state.id){
            const baseurl = process.env.REACT_APP_BASEPATH
   
            const navPath = (baseurl + `/admin/store-Report/${encodeURIComponent(this.state.id)}`)
            window.open(navPath, '_blank')
        }
    }
    render() {
        const { detail } = this.state
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-md-6">Store Detail</h2>
                        <div className="form-group text-right col-sm-6">
                            <button type="button" onClick={this.viewsalesReport} className="btn btn-primary btn-sm px-3 ml-2">View sales report</button></div>
                    </div>
                    {(!(this.state.isError)) ? <div className="purchasebox p-2">
                        <div className="card">
                            <div className="row">
                                {(detail && detail.name) && <div className="col-md-4 mb-3">
                                    <label>Name</label>
                                    <div className="comboxgray">
                                        {detail && detail.name}
                                    </div>
                                </div>}
                                {(detail && detail.uuid) && <div className="col-md-4 mb-3">
                                    <label>Store uuid</label>
                                    <div className="comboxgray">
                                        {detail && detail.uuid}
                                    </div>
                                </div>}
                                {(detail && detail.domain) && <div className="col-md-4 mb-3">
                                    <label>Domain</label>
                                    <div className="comboxgray">
                                        {detail && detail.domain}
                                    </div>
                                </div>}
                                {(detail && detail.supportEmail) && <div className="col-md-4 mb-3">
                                    <label>Support Email</label>
                                    <div className="comboxgray">
                                        {detail && detail.supportEmail}
                                    </div>
                                </div>}
                                {/* {detail && detail.sales ?
                                    <div className="col-md-4 mb-3">
                                        <label>Sales report</label>
                                        <div className="comboxgray">
                                        Today - <span className='usernamelilac'>${detail.sales.today}</span> &nbsp;
                                        This month - <span className='usernamelilac'>${detail.sales.thisMonth}</span> &nbsp;
                                        Last 3 months - <span className='usernamelilac'>${detail.sales.last3Months}</span> &nbsp;
                                        This year - <span className='usernamelilac'>${detail.sales.thisYear}</span>
                                    </div>
                                        

                                    </div> : ''} */}
                                
                               
                                {(detail && detail.socials && detail.socials.length > 0) && <div className="col-md-5 mb-3">
                                    <label>Social Links</label>
                                    <div className="comboxgray">
                                        {detail && detail.socials && detail.socials.map((items,index) => (
                                            <span>{items.name} - {items.url}<br/></span>
                                        ))}
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div> :
                        <div className="container-fluid text-center">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="error-template userdisableview">
                                        <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                        <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED NOW</h3>


                                    </div>
                                </div>

                            </div>
                        </div>
                    }

                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const actionCreators = {

}

const connectFetchStoreDetail = connect(mapStateToProps, actionCreators)(FetchStoreDetail)

export { connectFetchStoreDetail as FetchStoreDetail }