import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class Terms extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="pagetitabtn">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="w-auto text-center">Terms and Conditions</h1>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div >
                        <div className='text-justify py-5 statcontsec'>
                            <p><span style={{ fontStyle: "inherit" }}>DisruptArt is a decentralized application and marketplace operating on the Flow blockchain. The DisruptArt marketplace facilitates the creation, sale and purchase of unique, non-fungible assets between users on the Flow blockchain through smart contracts.</span><br /></p>
                            
                            <p><span >The DisruptArt team strongly suggests to all current and potential users to familiarize themselves with blockchain technology in general, and the Flow blockchain in particular. Please visit the website of the Flow </span><a href="https://www.onflow.org/flow-token-economics"><span >https://www.onflow.org/flow-token-economics</span></a></p>
                            
                            <p><span >Please read these Terms of Use (“Terms”, “Terms of Use”, or “Agreement”) very carefully. This Agreement is between you (the “User(s),” “Artist(s),” “Collector(s),” “Owner(s),” “Gallery(ies),” and collectively with others using the Site – “Users”) and Disruption Now Media , a company that owns and operates the DisruptArt platform and organized under the laws of the state of Ohio in the United States of America (“Disrupt Art”, “Disruption Now Media, LLC.”, “the Company”, “we”, “our” or “us” and together with you, the “Parties”). These Terms govern your use of the website and App located at https://DisruptArt.io (the “Site”), and all related tools, mobile applications, web applications, decentralized applications, smart contracts and APIs located at any of Disruption Now Media’s websites, including without limitation, successor website(s) or application(s) thereto (the “Platform”).</span></p>
                            
                            <p>The DisruptArt team strongly suggests to all current and potential users to familiarize themselves with blockchain technology in general, and the Flow blockchain in particular and Dapper Wallet. Please visit the website(s) of the Flow <a href="https://www.onflow.org/flow-token-economics"><span>https://www.onflow.org/flow-token-economics</span></a> and Dapper Wallet Terms and Conditions <a href="https://accounts.meetdapper.com/terms#terms-of-service"><span>https://accounts.meetdapper.com/terms#terms-of-service</span></a></p>
                            <p><span >THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE OR PLATFORM. BY ACCESSING OR USING THE SITE, SERVICES, OR SMART CONTRACTS YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, YOU ARE PROHIBITED FROM ACCESSING, USING, OR TRANSACTING ON THE SITE OR PLATFORM. YOU FURTHER REPRESENT AND WARRANT THAT YOU ARE OTHERWISE LEGALLY PERMITTED TO USE THE SERVICES IN YOUR JURISDICTION AND THAT THE COMPANY IS NOT LIABLE FOR YOUR COMPLIANCE WITH SUCH APPLICABLE LAWS.</span></p>
                            
                            <p><span >DisruptArt IS A PLATFORM FACILITATING THE TRANSFER OF DIGITAL ART BETWEEN USERS. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICES ARE AN ADMINISTRATIVE PLATFORM ONLY. DisruptArt FACILITATES TRANSACTIONS BETWEEN THE BUYER AND SELLER IN THE AUCTION BUT IS NOT A PARTY TO ANY AGREEMENT BETWEEN THE BUYER AND SELLER OF DIGITAL ART OR BETWEEN ANY USERS.</span></p>
                            
                            <p><span >We reserve the right to change or modify this Agreement at any time and in our sole discretion. By continuing to access or use the Site, you confirm your acceptance of the revised Agreement and all of the terms incorporated therein by reference. We encourage you to review the Agreement frequently to ensure that you understand the terms and conditions that apply when you access or use the Site. If you do not agree to the revised Agreement, you may not access or use the Site.</span></p>
                            <h3 className="usernamelilac">Gas Fees, Payment and Taxes</h3>
                            <p><span >(i) Financial Transactions on App. Any payments or financial transactions that you engage in via the App will be conducted solely through the Flow Network.&nbsp; We have no control over these payments or transactions, nor do we have the ability to reverse any payments or transactions. We have no liability to you or to any third party for any claims or damages that may arise as a result of any payments or transactions that you engage in via the App, or any other payment or transactions that you conduct via the Flow Network. We do not provide refunds for any purchases that you might make on or through the App.</span></p>
                            
                            <p><span >(ii) Gas Fees. Every transaction on the Flow Network requires the payment of a transaction fee (each, a “Gas Fee”). The Gas Fees fund the network of computers that run the decentralized Flow Network. This means that you will need to pay a Gas Fee for each transaction that you instigate via the App. Except as otherwise expressly set forth in these Terms, you will be solely responsible to pay any Gas Fee for any transaction that you instigate via the App.</span></p>
                            
                            <p><span >(iii) Responsibility for Taxes. You will be solely responsible to pay any and all sales, use, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, the “Taxes”) associated with your use of the App. Except for income taxes levied on us, you: (a) will pay or reimburse us for all national, federal, state, local or other taxes and assessments of any jurisdiction, including value added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (b) will not be entitled to deduct the amount of any such taxes, duties or assessments from payments (including Gas Fees) made to us pursuant to these Terms.&nbsp;</span></p>
                            
                            <h3 className="usernamelilac">Defined Terms</h3>
                            <p><span >“NFT(s)” or “Token(s)” means Flow-based tokens NFTs are intended to be “non-fungible” tokens representing a unique Collectible, being non-fungible with other NFTs (i.e., they have different Collectible Metadata).</span></p>
                            <p><span >“Collectible” means the association on Flow of an NFT with a Uniform Resource Identifier (“URI”) identifying an appropriately configured JSON file conforming to the ERC-721 Metadata JSON Schema, ERC-1155 Metadata URI JSON Schema or a similar JSON schema, as applicable (such JSON file, the “Collectible ID”).</span></p>
                            <p><span >The Collectible ID of a Collectible specifies the properties of the Collectible, including the name and description of the Collectible (the “Collectible Descriptors”), a URI identifying any image file associated with the Collectible (the “Collectible Image”) and potentially other “metadata” associated with the Collectible (the Collectible Descriptors, Collectible Image and such other metadata, collectively, the “Collectible Metadata”). The Collectible Metadata for Collectibles created through the DisruptArt Platform are typically stored on IPFS. The Collectible Metadata for Collectibles created outside the DisruptArt Platform may be stored in other ways, depending on how such Collectibles were created.</span></p>
                            <p><span >There can be no guarantee or assurance of the uniqueness, originality or quality of any Collectible or Collectible Metadata. In the absence of an express legal agreement between the creator of a Collectible and purchasers of the Collectible, there cannot be any guarantee or assurance that the purchase or holding of the Collectible confers any license to or ownership of the Collectible Metadata or other intellectual property associated with the Collectible or any other right or entitlement, notwithstanding that User may rightfully own or possess the NFT associated with the Collectible.</span></p>
                            <p><span >“Tokenize” or “Mint” or “Publish” refers to the act of creating an NFT by an artist or a gallery on the DisruptArt platform.</span></p>
                            <p><span >“DisruptArt Token” means an NFT created through the DisruptArt platform by a user interacting with a Smart Contract deployed to theFlow blockchain, as opposed to NFTs created through other platforms.</span></p>
                            <p><span >“Gas” – Some Services involve the use of theFlow Blockchain, which may require that you pay a fee, commonly known as “Gas Charges,” for the computational resources required to perform a transaction on theFlow Blockchain. You acknowledge and agree that DisruptArt has no control over: (a) anyFlow Blockchain transactions; (b) the calculation or method of payment of anyFlow Gas Charges; or (c) any actual payments of Flow Gas Charges. Accordingly, you must ensure that you have a sufficient balance stored at your Flow Address to complete any transaction on the Flow Blockchain before initiating such Flow Blockchain transaction.</span></p>
                            <p><span >“Smart contracts” is a term used to describe computer code that automatically executes when interacted with and is stored on theFlow blockchain. The code itself is replicated across multiple nodes of the blockchain and, therefore, benefits from the security, permanence and immutability that the blockchain offers. That replication also means that as each new block is added to the blockchain, the code may be executed. If the parties have indicated, by initiating a transaction, that certain parameters have been met, the code will execute the step triggered by those parameters. If no such transaction has been initiated, the code will not take any steps. Most smart contracts are written in one of the programming languages directly suited for such computer programs, such as Solidity. Smart contracts stored on a blockchain platform are immutable. Once deployed to theFlow blockchain the code contained in the Smart Contract cannot be changed by any user or node of the blockchain, including the original deployer. The DisruptArt Platform facilitates the creation of transactions to interact with smart contracts on the Flow blockchain.</span></p>
                            <ol>
                                <li><span > All transactions on the DisruptArt Marketplace (the “Marketplace”), including transfers, offers, sales, or purchases of DisruptArt Tokens are initiated at the sole discretion of the Users. To initiate a transaction on the DisruptArt Platform, a User must voluntarily invoke one or more Smart Contract operations from a Dapperlabs Wallet or through blocto. The Smart Contracts are configured to facilitate the execution of a voluntary User offer, an acceptance of an offer, or other confirmation to purchase, sell, publish, or transfer an DisruptArt Token. The User agrees to be bound by the outcome of any Smart Contract operation by invoking, calling, requesting, or otherwise engaging with the Smart Contract, whether or not the Smart Contract behaves as the User expects.</span></li>
                            </ol>
                            <p><span >“Bid” is an offer to purchase an NFT on the DisruptArt Platform . Any registered user on DisruptArt may place a bid on any NFT on the platform. Users may also withdraw bids that have previously been placed. Placing or withdrawing bids requires performing a transaction on theFlow blockchain. By using the Platform to bid on an NFT, the User agrees and acknowledges that a bid constitutes a legally binding offer exclusively between the NFT owner and the bidding User. In placing a bid the user must send the bid amount to the smart contract. The seller of the NFT, which may be the original creator or another collector, will be notified on the Platform of the bid and may accept or reject it. If the seller rejects the bid the bid amount is transferred back to the user who placed the bid. If the seller of the NFT chooses to accept the bid, the bid amount will automatically be transferred to the seller’s Flow address less any applicable fees.</span></p>
                            <ol>
                                <li><span >The starting bid amount for every auction is 0.1 Flow. b. If an NFT has an existing bid, the user must place a bid that is at least&nbsp; Flow FUSD greater than the previous bid. c. Upon receipt of a higher bid, all previous bids are returned to the respective users who placed them. d. Rejecting a bid resets the starting bid amount to 0.1 ETH.</span></li>
                            </ol>
                            <p><span >“Buy it Now” is a listed price in Flow that the owner of an NFT can choose to set for an NFT. When a user chooses to buy an NFT at the Buy it Now price, the user must send the listed amount in Flow, and they will receive the NFT from the seller automatically. In order for a seller to set a Buy it Now price, they must perform a transaction on theFlow blockchain.</span></p>
                            <p><span >“Fees” refers to the amounts deducted from the successful sale of an NFT. The fee structure depends on whether the NFT is being sold for the first time in a (from its creator to a collector, i.e. a “Primary Sale”) or whether the NFT is being sold for the second time, or any subsequent transaction thereafter (e.g. from a collector to another collector, i.e. a “Secondary Sale”).</span></p>
                            <h3 className="usernamelilac">Primary Sales</h3>
                            <ol>
                                <li><span >Platform Fee: DisruptArt collects 15% of the final sale amount.</span></li>
                            </ol>
                            <h3 className="usernamelilac">Secondary Sales</h3>
                            <p><span >Platform Fee: DisruptArt collects 5% of the final sale amount.</span></p>
                            <p><span >Artist Royalty: DisruptArt distributes 10% of the final sale amount to the creator and their collaborator(s), if any.</span></p>
                            <p><span >DisruptArt reserves the right to change the platform fee at a future date. DisruptArt will provide notice of any change of platform fee through email communication with users and in these Terms.</span></p>
                            <p><span >“Disruptors List” refers to the list of Artists and Galleries that have been allowed to tokenize NFTs on the DisruptArt Platform. Artists and Galleries must apply to be whitelisted in order to publish artwork on the DisruptArt Platform (see below).</span></p>
                            <p><span >“Creator” refers to any user, Artist or Gallery who has been whitelisted to tokenize NFTs on the DisruptArt Platform.</span></p>
                            
                            <h3 className="usernamelilac">Accessing the Platform</h3>
                            
                            <p><span >In order to access the DisruptArt marketplace, the user must first be using a web browser such as Google Chrome, Mozilla Firefox or Brave. DisruptArt will only recognize you as a user, and you will only be able to interact with DisruptArt, if your electronic wallet is connected and unlocked through your blocto account and/or Moonpay gateway services. There is no other way to sign up as a User, or to interact directly with the Platform.</span></p>
                            <p><span >ALL TRANSACTIONS INITIATED THROUGH OUR SITE ARE FACILITATED AND RUN BY BLOCTO AND/OR MOONPAY. THE USER ACKNOWLEDGES THAT WHILE USING THE BLOCTO PRODUCT THEIR ACTIONS ARE ALSO GOVERNED BY THE TERMS OF USE </span><a href="https://blocto.portto.io/en/terms/z"><span >HTTPS://BLOCTO.PORTTO.IO/EN/TERMS/</span></a><span >) AND PRIVACY POLICY (</span><a href="https://blocto.portto.io/en/privacy"><span >HTTPS://BLOCTO.PORTTO.IO/EN/PRIVACY</span></a><span > ) OF THE BLOCTO PLATFORM. THE USER FURTHER ACKNOWLEDGES THAT WHILE USING THE MOONPAY GATEWAY PRODUCT THEIR ACTIONS ARE ALSO GOVERNED BY THE TERMS OF USE (</span><a href="https://www.moonpay.com/legal/terms_of_use"><span >HTTPS://WWW.MOONPAY.COM/LEGAL/TERMS_OF_USE</span></a><span >) AND PRIVACY POLICY (</span><a href="https://www.moonpay.com/legal/privacy_policy"><span >HTTPS://WWW.MOONPAY.COM/LEGAL/PRIVACY_POLICY</span></a><span > ) OF THE MOONPAY GATEWAY PRODUCT. DISRUPTART DOES NOT HAVE ANY OWNERSHIP OF, ECONOMIC INTEREST IN OR CONTROL OVER THE EITHER BLOCTO OR MOONPAY. WE HAVE NO CONTROL OVER THESE PAYMENTS OR TRANSACTIONS, NOR DO WE HAVE THE ABILITY TO REVERSE ANY PAYMENTS OR TRANSACTIONS. WE HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY CLAIMS OR DAMAGES THAT MAY ARISE AS A RESULT OF ANY PAYMENTS OR TRANSACTIONS THAT YOU ENGAGE IN VIA THE APP, OR ANY OTHER PAYMENT OR TRANSACTIONS THAT YOU CONDUCT VIA THE MOONPAY OR BLOCTO. WE DO NOT PROVIDE REFUNDS FOR ANY PURCHASES THAT YOU MIGHT MAKE ON OR THROUGH THE APP – WHETHER FOR SETS, PACKS, OR ANYTHING ELSE.</span></p>
                            
                            <h3 className="usernamelilac">DisruptArt is Non-Custodial</h3>
                            
                            <p><span >The Smart Contracts and the Site facilitate users collection of non-fungible tokens representing digital art on theFlow blockchain, but DisruptArt and its affiliates, the Platform, and the Smart Contracts are not the custodians of any User-owned non- fungible tokens. The User understands and acknowledges that the Smart Contracts do not give DisruptArt custody, possession, or control of any non-fungible token or cryptocurrency at any time for the purpose of facilitating transactions on the DisruptArt platform. The DisruptArt Platform facilitates interaction with the Smart Contracts, but cannot control the actions taken by the Smart Contract once a transaction has been sent. You affirm that you are aware and acknowledge that DisruptArt is a non- custodial service provider and has designed this Platform to be directly accessible by the Users without any involvement or actions taken by DisruptArt or any third-party.</span></p>
                            
                            <h3 className="usernamelilac">DisruptArt Artists</h3>
                            
                            <p><span >Only Artists invited and approved by DisruptArt are able to use the platform to publish DisruptArt Tokens. Artwork published and sold on the DisruptArt Marketplace includes, but is not limited to: visual works, audiovisual works, animations, audio, photographs, 3D works, GIFS, and other creative digital works.</span></p>
                            <p><span >Artists seeking an invitation to create and list works for sale on the DisruptArt platform must submit a request for approval. DisruptArt has unilateral discretion in granting approval to artists to use the DisruptArt platform and makes no guarantees or promises that any Artists will be approved, even if the Artist was invited by a member of the DisruptArt Team, a Gallery or its partners to submit the request.</span></p>
                            <p><span >The DisruptArt Team makes their decision on which Artists will be the Disruptors List based on a number of factors that may include, but are not limited to, the style of art of the Artist, their portfolio, their exhibition history, if any, their social media presence, etc. We use a proprietary evaluation framework to select the Artists we feel are best suited to our platform.</span></p>
                            <p><span >Artists that are approved by DisruptArt are added to a “Disruptors List” and granted permission to publish DisruptArt Tokens using one or more of our Smart Contracts. To create an DisruptArt Token, approved Artists must upload an original Artwork, provide a title and description for the artwork, information about the artwork, and agree to these Terms and Conditions, after which they shall perform a transaction with an DisruptArt smart contract that will create an NFT on the DisruptArt Platform. Upon creation of an NFT, the Artist has sole possession of said NFT.</span></p>
                            <p><span >Failure to abide by these Terms may result in revoking the Artist’s permissions to the DisruptArt Platform. DisruptArt has the unilateral authority and discretion to remove, suspend, or revoke Artists’ access to the DisruptArt Platforms. Upon suspension, Artists will no longer have the ability to create new NFTs, however they will still be able to accept bids on previously created NFTs they still own.</span></p>
                            
                            <h3 className="usernamelilac">DisruptArt Galleries</h3>
                            
                            <p><span >Galleries are special users who have the ability to tokenize NFTs on behalf of an approved list of artists. When tokenizing an NFT the Gallery must indicate one or more Artist(s) from their approved list of artists. On the page displaying the NFT the Artist(s) will have the words “Managed by:” and the name of the Gallery that tokenized the artwork. Galleries bring curatorial skills to the DisruptArt Platform, and provenance to the Artist(s) they work with.</span></p>
                            <p><span >Only Galleries invited and approved by DisruptArt are able to use the platform to publish DisruptArt Tokens on theFlow blockchain on behalf of their represented Artists. Artwork published on theFlow blockchain and sold on the DisruptArt Marketplace includes, but is not limited to: visual works, audiovisual works, animations, audio, photographs, 3D works, GIFS, and other creative digital works.</span></p>
                            <p><span >Galleries seeking an invitation to create and list works for sale on the DisruptArt platform on behalf of their represented Artists must submit a request for approval. DisruptArt has unilateral discretion in granting approval to Galleries to use the DisruptArt platform and makes no guarantees or promises that any Galleries will be approved, even if the Gallery was invited by a member of the DisruptArt Team or its partners to submit the request.</span></p>
                            <p><span >Galleries do not need to have physical locations. They do not need to be companies, or established organizations. The DisruptArt Team makes their decision on which Galleries will be whitelisted based on a proprietary evaluation framework that takes into account a number of factors that may include, but are not limited to, their experience working with theFlow blockchain, the curatorial style of the Gallery, the Artists they have worked with in the past, the Artists they intend to represent on DisruptArt, their exhibition history, their social media presence, etc.</span></p>
                            <p><span >Once accepted on the Platform, the Gallery should submit any Artist(s) that are not already whitelisted on DisruptArt to the DisruptArt team for consideration. In order to consider associating an Artist with a Gallery, the DisruptArt team will request proof that the Artist has agreed to allow the Gallery to tokenize artwork on their behalf. The DisruptArt team doesn’t require details of the relationship between the two parties. The DisruptArt team will attempt to accommodate whatever means the Gallery chooses to prove the existence of a relationship allowing for the Gallery to tokenize on behalf of the Artist. However, DisruptArt reserves the right to deny a Gallery the ability to tokenize on behalf of other artists if we feel the nature of the relationship has been misrepresented or fraudulent.</span></p>
                            <p><span >The process for Artist(s) associated with Galleries is the same process as explained above in “DisruptArt Artists”.</span></p>
                            <p><span >Any Gallery found to be in breach of these terms will have their ability to tokenize NFTs on the DisruptArt platform revoked. However, they will still be able to accept and reject bids on artwork that has already been tokenized.</span></p>
                            
                            <h3 className="usernamelilac">Collaborators</h3>
                            
                            <p><span >DisruptArt Token creators (Artists and Galleries) have the option of indicating one or more collaborators at the time of creation of an DisruptArt token. Collaborators are referenced by theirFlow address. DisruptArt Artists have no restrictions on who may be added as a collaborator. DisruptArt Galleries may only add Gallery Represented Artists as collaborators. Collaborators are assigned on a per DisruptArt Token basis. If a collaborator is set, they must also set the percentage of collaboration of their collaborators. For example, Artist 1 may add Artist 2 and Artist 3 as collaborators, with a collaboration percentage of 30% and 20% respectively. In this case, the funds received by the Artist and their collaborators would be split as follows: 50% for Artist 1, 30% by Artist 2, 20% by Artist 3.</span></p>
                            <p><span >Artists must only add collaborators to an NFT with whom they have actually collaborated. Any Artist found to be misrepresenting their relationship with another artist by indicating a collaboration where none exists will be in breach of these Terms may have their ability to tokenize on the DisruptArt Platform revoked and the NFT in question removed from display on DisruptArt.</span></p>
                            <p><span >ONCE AN DisruptArt TOKEN HAS BEEN PUBLISHED ON THE BLOCKCHAIN THE NUMBER OF COLLABORATORS AND THEIR PERCENTAGES OF COLLABORATION CANNOT BE CHANGED. DisruptArt WILL NOT BE HELD LIABLE FOR MISTAKES MADE WHEN INPUTTING INDICATED COLLABORATORS OR THEIR PERCENTAGES OF COLLABORATION.</span></p>
                            
                            <h3 className="usernamelilac">Creators Indemnify and Releases DisruptArt</h3>
                            
                            <p><span >Creators hereby agree to indemnify DisruptArt and be held liable for any claim against DisruptArt arising out of the Creator’s breach of these Terms. Creators hereby release and forever discharge DisruptArt from any damages or causes of action resulting from a sale of any of the Creator’s listed DisruptArt Items occurring after the Creator’s breach of these Terms.</span></p>
                            
                            <h3 className="usernamelilac">DisruptArt Token Ownership</h3>
                            
                            <p><span >Buying an DisruptArt Token and its associated artwork on the DisruptArt Platform is analogous to buying an original painting or print from an artist. It does not transfer the Artist’s copyright to the Collector. The Collector obtains ownership, they acquire the right to display and resell the digital art to another collector if they wish either on or off the Platform. However, the Artist does not give up copyright, meaning the Collector cannot prevent an Artist from using it for further commercial work, and the purchase of an DisruptArt Token does not grant the Collector any right to use the associated digital artworks for commercial purposes.</span></p>
                            
                            <p><span >Users Agree to the Automated Collection and Disbursement of Fees by the Smart Contracts</span></p>
                            
                            <p><span >The User agrees and understands that all fees, commissions, and royalties are transferred, processed, or initiated directly through one or more of the DisruptArt Smart Contracts on theFlow blockchain network. By transacting on the platform and by using the Smart Contracts, the User hereby acknowledges, consents to, and accepts all automated fees, commissions, and royalties for the sale of Items on the DisruptArt Platform. The User hereby consents to and agrees to be bound by the Smart Contracts’ execution and distribution of the fees, commissions, and royalties. Users hereby waive any entitlement to royalties or fees paid to another by operation of the Smart Contracts.</span></p>
                            
                            <h3 className="usernamelilac">Copyright Claims</h3>
                            
                            <p><span >The Artist and all Users irrevocably release, acquit, and discharge DisruptArt and its subsidiaries, affiliates, officers, and successors of any liability for direct or indirect copyright or trademark infringement for DisruptArt’s use of a Work in accordance with these Terms, including without limitation, DisruptArt’s solicitation, encouragement, or request for Users or third parties to host the Work for the purpose of operating a distributed database and DisruptArt’s deployment or distribution of a reward, a token, or other digital asset to Users or third parties for hosting Works on a distributed database with the exception of any blatant breach of the copyright or trademark of an Artist’s works by DisruptArt other than through incidental use or copying for the purposes of using the Platform.</span></p>
                            <p><span >All parties must agree that the copyright of any digital artwork is owned by the Artist at all times and that DisruptArt will respect the copyright and rights of the Artist and will not infringe their rights except as otherwise explicitly agreed to in this Agreement.</span></p>
                            
                            <h3 className="usernamelilac">Creator Grants DisruptArt a License to All Minted Works</h3>
                            
                            <p><span >The Creator hereby acknowledges, understands, and agrees that Minting a Work on the Platform constitutes an express and affirmative grant to DisruptArt, its affiliates and successors a non-exclusive, world-wide, assignable, sublicensable, perpetual, and royalty-free license to make copies of, display, perform, reproduce, and distribute the Work on any media whether now known or later discovered for the broad purpose of operating, promoting, sharing, developing, marketing, and advertising the Platform, the Site, the Marketplace, or any other purpose related to the DisruptArt Platform or business, including without limitation, the express right to: (i) display or perform the Work on the Site, a third party platform, social media posts, blogs, editorials, advertising, market reports, virtual galleries, museums, virtual environments, editorials, or to the public; (ii) create and distribute digital or physical derivative works based on the Work, including without limitation, compilations, collective works, and anthologies; (iii) indexing the Work in electronic databases, indexes, catalogues, the Smart Contracts, or ledgers; and (iv) hosting, storing, distributing, and reproducing one or more copies of the Work within a distributed file keeping system, node cluster, or other database (e.g., IPFS) or causing, directing, or soliciting others to do so.</span></p>
                            
                            <h3 className="usernamelilac">Off Market Transactions</h3>
                            
                            <p><span >DisruptArt does not generally collect any fees, commissions, or royalties for transactions occurring outside of the DisruptArt Marketplace and not involving the DisruptArt Smart Contracts. To support the DisruptArt Artists and the Platform, we encourage Collectors to list Items for sale on the DisruptArt Marketplace, however due to the nature of theFlow blockchain network Collectors are permitted to sell or transfer their Items on third party exchanges marketplace . The Artist and all other Users hereby waive any entitlement to royalties or fees for off market transactions.</span></p>
                            
                            <h3 className="usernamelilac">Assumption of Risk</h3>
                            
                            <p><span >You accept and acknowledge each of the following:</span></p>
                            <p><span >The prices of blockchain assets are extremely volatile. Fluctuations in the price of other digital assets could materially and adversely affect the value of your DisruptArt Tokens, which may also be subject to significant price volatility. We cannot guarantee that any purchasers of DisruptArt Tokens will not lose money. You also acknowledge that the cost of transacting on such technologies is variable and may increase at any time causing impact to any activities taking place on theFlow blockchain. You acknowledge these risks and represent that DisruptArt cannot be held liable for such fluctuations or increased costs.</span></p>
                            <p><span >You are solely responsible for determining what, if any, taxes apply to your DisruptArt token transactions. DisruptArt is not responsible for determining the taxes that apply to DisruptArt token transactions.</span></p>
                            <p><span >The DisruptArt platform does not store, send, or receive DisruptArt Tokens. This is because DisruptArt Tokens exist only by virtue of the ownership record maintained on the platform’s supporting blockchain in theFlow network. Any transfer of DisruptArt Tokens occurs within the supporting blockchain in theFlow network, and not on the platform.</span></p>
                            <p><span >There are risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that DisruptArt will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using theFlow network, however caused.</span></p>
                            <p><span >A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact the development of the DisruptArt ecosystem, and therefore the potential utility or value of DisruptArt Items.</span></p>
                            <p><span >The DisruptArt Platform, ETH and digital assets could be impacted by one or more regulatory inquiries or regulatory action, which could impede or limit the ability of DisruptArt to continue to develop, or which could impede or limit your ability to access or use the Platform orFlow blockchain, including to your digital assets or other funds, and new regulations or policies may materially adversely affect the development of the DisruptArt ecosystem, and therefore the potential utility or value of DisruptArt.</span></p>
                            
                            <h3 className="usernamelilac">User Conduct</h3>
                            
                            <p><span >You agree that you are responsible for your actions while accessing or using the DisruptArt Platform, and for any consequences thereof. You and DisruptArt both agree to use the DisruptArt Platform only for purposes that are in accordance with these Terms and any applicable laws or regulations. The parties, including Ephemera, agree that they will not and will not allow any third party to:</span></p>
                            <p><span >send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content;</span></p>
                            <p><span >undertake any unlawful activity which would violate, or assist in violation of, any law, statute, ordinance, or regulation, sanctions program administered in any relevant country, in any involve proceeds of any unlawful activity;</span></p>
                            <p><span >distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature;</span></p>
                            <p><span >impersonate another person (via the use of an email address or otherwise);</span></p>
                            <p><span >upload, post, transmit or otherwise make available through the DisruptArt Platform any content that infringes the intellectual proprietary rights of any party;</span></p>
                            <p><span >operate to defraud DisruptArt, other users, or any other person or provide false, inaccurate or misleading information;</span></p>
                            <p><span >use the Platform to violate the legal rights (such as rights of privacy and publicity) of others;</span></p>
                            <p><span >engage in, promote, or encourage illegal activity (including, without limitation, terrorism, tax evasion or money laundering);</span></p>
                            <p><span >interfere with another individual’s or entity’s access to or use of the DisruptArt Platform; defame, abuse, extort, harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights or privacy, publicity and intellectual property) of others; incite, threaten, facilitate, promote, or encourage hate, racial intolerance or violent acts against others; harvest or otherwise collect information from the DisruptArt Platform about others, including without limitation email addresses, without proper consent;</span></p>
                            <p><span >exploit the DisruptArt Platform for any unauthorized commercial purpose;</span></p>
                            <p><span >modify, adapt, translate, or reverse engineer any portion of the DisruptArt Platform;</span></p>
                            <p><span >remove any copyright, trademark or other proprietary rights notices contained in or on the DisruptArt Platform or any part of it;</span></p>
                            <p><span >reformat or frame any portion of the DisruptArt Platform;</span></p>
                            <p><span >display any content on the DisruptArt Platform that contains any hate-related or violent content or contains any other material, products or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;</span></p>
                            <p><span >use any robot, spider, site search/retrieval application, or other device to retrieve or index any portion of the DisruptArt Platform or the content posted on the DisruptArt Platform, or to collect information about its users for any unauthorized purpose;</span></p>
                            <p><span >create user accounts by automated means or under false or fraudulent pretenses; or</span></p>
                            <p><span >access or use the DisruptArt Platform for the purpose of creating a product or service that is competitive with any of our products or services.</span></p>
                            <h3 className="usernamelilac">External Sites</h3>
                            
                            <p><span >The DisruptArt Platform may include hyperlinks to other websites or resources (collectively, “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites. You acknowledge and agree that we are not responsible for the availability of any External Sites, and that we do not endorse any advertising, products or other materials on or made available from any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of accessing External sites, the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the completeness, accuracy or existence of any advertising, products or other materials on, or made available from, any External Sites.</span></p>
                            
                            <h3 className="usernamelilac">Age of Majority</h3>
                            
                            <p><span >You affirm that you are over the age of 18, as the marketplace is not intended for children under 18. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT OR GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT CHILD’S USE OF THE PLATFORM, INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE MAY INCUR.</span></p>
                            
                            <h3 className="usernamelilac">Changes to the Terms and Conditions</h3>
                            
                            <p>We may make changes to the Terms and Conditions (collectively, the “Terms”) from time to time. When we make changes, we will make the updated Terms available on the DisruptArt Platform and update the “Last Updated” date at the beginning of these Terms accordingly. Please check these Terms periodically for changes. Any changes to the Terms will apply on the date that they are made, and your continued access to or use of the DisruptArt Platform after the Terms have been updated will constitute your binding acceptance of the updates. If you do not agree to any revised Terms, you may not access or use the DisruptArt Platform.</p>						
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export { Terms }