import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { getUserAddress, GetCreatorRoyality, GetMarketFee, CalculateShare, getBatchSize, dapperTransferTokens, spaceCheck } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { connect } from 'react-redux';
import { artActions, txnActions, userAction, walletActions } from 'store/actions';
import { artServices, dropServices, fetchService, userServices, walletServices, airDropService } from 'services';
import { checkWalletAddress, flowDecimal, getCurrentUserId, isConnectWallet, localAirdropPage, localArtPage, localDropPage, MaintenancePopup } from 'helpers';
import * as fcl from "@onflow/fcl";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { configPath } from '../config'
import moment from 'moment'
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { ArtViewPreview } from './art-preview/artview-preview';
import Toggle from 'react-toggle';
import { EditCategory } from './artdetail-view/edit-category';
import { EditTag } from './artdetail-view/edit-tag';
import { getCreatorImage, getCreatorName } from './artdetail-view/bab-common-view';
import { JoyRide } from './Joy-ride';
import { checkOwnListingAuctionPage } from 'helpers/joy-rideCheck';
import { flowMoveToAirDrop } from 'utils/flow-air-drop';
import { isDapperConnected } from 'helpers/switchauthdapper';
import { CommonWalletConnectUI } from 'helpers/commonWalletConnectUI';
import { SwitchAccount } from './switchaccountPopup';
import { MapComponent } from './googleMaps/Map';

var timeout;
var dataURL = {}
class DapperListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            price: 350,
            basePrice: Number(350).toFixed(8),
            usd: '',
            isSuccess: false,
            message: '',
            isAlert: false,
            isVisible: false,
            isShare: false,
            count: 1,
            submitted: false,
            ShowPop: false,
            isList: false,
            totalOwnerArt: 0,
            resale_royalty: '',
            market_Fee: '',
            crt_share: '',
            tabIndex: 0,
            disableList: false,
            isTransLoader: false,
            startPrice: 350,
            minBid: 1,
            minimumBid: 1,
            startingPrice: 350,
            endDays: 7,
            isSubmitAuction: false,
            isSuccessAuction: false,
            isAuction: false,
            isImage: false,
            listingInProgress: false,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            tokensProcessed: 0,
            isListingAborted: false,
            tokensListedBeforeAbort: 0,
            listingAbortError: '',
            isMaintenance: false,
            onstart: moment(),
            airdropCount: 1,
            pauseResume: false,
            isAirDrop: false,
            selectedMembers: [],
            member: '',
            uploadedFile: [],
            localImageName: '',
            isArtStatusChangeSuccess: false,
            isThumbnailSuccess: false,
            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isThumbnailUpdateSuccess: false, // if thumbnail upload success,
            isCategoryUpdateSuccess: false, // if category update success
            isTagUpdateSuccess: false,
            isErrorPoped: false,
            errorMessage: '',
            selectedTokenId: [],
            loaderMes: '',
            txnId: '',
            historyId: '',
            walletArray: [],
            countLimit: 0,
            pathName: '',
            AirdropcountLimit: 0,
            isSwitchAcc: false,
            withdrawWarnError: false,
            airdropWithdrawWarnError: false,
            selectedAirdropOption: 2,
            latitude: null,
            longitude: null,
            radius: null,
            onend: '',
            goToNfTStash:false,
            infoLimit:10,
            maxAllowedAirdropLimit : 10,
            eventName: '',
            eventDescription: '',
            isAlertOff: true 
        }
        this.scrollRef = React.createRef()
        this.maxAllowedListLimit = 180
        this.eventNameMinLength = 3
        this.eventNameMaxLength = 100
        this.eventDescMinLength = 10
        this.eventDescMaxLength = 200
        // this.maxSelectedAirDroplimit = 10
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {

    this.scrollRef.current && this.scrollRef.current.scrollIntoView()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.initialFunc()
    }
    initialFunc = () => {
        if (isConnectWallet()) {
            this.getRoyalty()
            this.calculateSharings(this.state.basePrice)
            this.getArtByToken()
        } else {
            this.connectMyWallet()
        }
    }
    getArtByToken = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.showLoader()
            artServices.getArtByToken(id).then(art => {
                this.hideLoader()


                if ((art && art.statusCode) === 200) {
                    this.gotoTop()
                    userServices.getUsers().then(res => {
                        if ((res && res.statusCode) === 200) {
                            if ((res && res.data && res.data.id) === ((art && art.data && art.data.owner && art.data.owner.id))) {

                                let dataVal = art && art.data
                                let dropStr = dataVal && dataVal.dropsCount
                                let dropArr = '[' + dropStr + ']'
                                let dropsCountarray = eval(dropArr && dropArr)
                                let totalTokenId = dataVal && dataVal.totalTokenId
                                let arr = '[' + totalTokenId + ']'
                                let groupIds = eval(arr && arr)
                                // console.log(groupIds)
                                let totalOwnerArt = dataVal && dataVal.totalOwnerArt
                                dropsCountarray && dropsCountarray.map((items) => {
                                    if (items) {
                                        groupIds = groupIds.filter((el) => !(items && items.tokens).includes(el))

                                    }
                                })
                                this.fetchArt(art && art.data)


                                this.setState({ data: art && art.data ? art.data : [], artOwner: art && art.data && art.data.owner && art.data.owner.userName, artCreator: art && art.data && art.data.creator && art.data.creator.userName, groupIds, totalOwnerArt, pathName: '' }, () => {
                                    this.getUserWalletAddressDetail(art && art.data && art.data.id)
                                })
                            } else {
                                // history.push('/notFound')
                                let dataVal = art && art.data
                                history.push(`/art/${dataVal && dataVal.id}`)
                            }
                        }
                    }).catch(
                        err => {
                            PubSub.publish('msg', false)
                        }
                    )
                } else {
                    if (art && art.statusCode === 400) {
                        if (art && art.message && art.message.toLowerCase().includes("has been hidden by the owner")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been hidden by the art owner"
                            })
                        } else if (art && art.message && art.message.toLowerCase().includes("has been disabled by the administrator")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been disabled by the administrator"
                            })
                        } else {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "Art has been disabled"
                            })
                        }
                    }
                }
            }).catch(err => {
                if (err && err.toLowerCase().includes("has been hidden by the owner")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been hidden by the art owner"
                    })
                } else if (err && err.toLowerCase().includes("has been disabled by the administrator")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been disabled by the administrator"
                    })
                } else {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "Art has been disabled"
                    })
                }
                this.hideLoader()
            })
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.listedArt && props.listedArt.statusCode === 200) {
            props.resetListSuccess()
            props.forsalelistsuccess(state.historyId)

        }
        if (props && props.listedArtFailed && ((props.listedArtFailed.length > 0) || (props.listedArtFailed.txnId))) {
            props.resetListFailed()
            props.forsaleEventFailure(state.historyId)

            if (props.listedArtFailed.txnId) {
                return {

                    errorMessage: `Listing for Sale Failed. Please note down the below Token ID and contact Disrupt Art Admin.`,
                    txnId: props.listedArtFailed.txnId
                }
            } else {
                return {

                    errorMessage: `Listing for Sale Failed. Please note down the below Token ID and contact Disrupt Art Admin.`,
                    txnId: ''
                }
            }

        }
        return null
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    componentDidUpdate(props, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

        if ((this.props && this.props.artVisibilityStatusChangedFailed && this.props.artVisibilityStatusChangedFailed.length > 0) && (this.props.artVisibilityStatusChangedFailed) != (props.artVisibilityStatusChangedFailed)) {
            this.props.resetVisibilityStatusFailed()
            this.setState({ isAlert: true, message: this.props.artVisibilityStatusChangedFailed, style: "danger" })
            this.gotoTop()
        }
        if ((this.props && this.props.artVisibilityStatusChangesSuccess && this.props.artVisibilityStatusChangesSuccess.statusCode === 200) && (this.props.artVisibilityStatusChangesSuccess) != (props.artVisibilityStatusChangesSuccess)) {
            this.props.resetVisibityStatusSuccess()
            this.getArtByToken()
            this.setState({
                isArtStatusChangeSuccess: true
            })
        }
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            this.initialFunc()
            const data = this.state.data
            // this.getUserWalletAddressDetail((data && data.id))

            // if (this.state.pathName === "sell") {
            //     this.setState({ ShowPop: false, isList: true })
            //     this.handleSale(data && data.tokenId)
            // } else if (this.state.path === "auction") {
            //     this.setState({ ShowPop: false, isAuction: true })

            //     // this.listToAuction()
            // } else if (this.state.pathName == "airdrop") {
            //     this.setState({ ShowPop: false, isAirDrop: true })
            //     this.handleAirDrop(data && data.tokenId)
            // } else {
            //     this.initialFunc()
            // }
        }

        //event initiate - step 1
        if (this.props && this.props.eventInitiatefailed && this.props.eventInitiatefailed.length > 0 && (props.eventInitiatefailed !== (this.props.eventInitiatefailed))) {
            this.props.resetForsaleInitiateFailed()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                errorMessage: String(`Forsale Initiated : ${this.props.eventInitiatefailed}`),
                loaderMes: '',
                txnId: '',
                submitted: false
            })
        }

        if ((this.props && this.props.eventInitiateSuccess && this.props.eventInitiateSuccess.statusCode === 200) && (this.props.eventInitiateSuccess) != (props.eventInitiateSuccess)) {
            this.props.resetForsaleInitiate()
            if (this.props.eventInitiateSuccess && this.props.eventInitiateSuccess.data && this.props.eventInitiateSuccess.data.history && this.props.eventInitiateSuccess.data.history.id) {
                this.setState({ historyId: this.props.eventInitiateSuccess.data.history.id })
                this.forsaleProcess(this.props.eventInitiateSuccess.data.history.id)
            }
        }
        //event cancel
        if (this.props && this.props.eventCancelledFailed && this.props.eventCancelledFailed.length > 0 && (props.eventCancelledFailed !== (this.props.eventCancelledFailed))) {
            this.props.resetEventCancelFailed()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
                submitted: false
            })
        }

        if ((this.props && this.props.eventCancelledsuccess && this.props.eventCancelledsuccess.statusCode === 200) && (this.props.eventCancelledsuccess) != (props.eventCancelledsuccess)) {
            this.props.resetEventCancel()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
            })
        }

        //event failure

        if (this.props && this.props.eventFailureFailed && this.props.eventFailureFailed.length > 0 && (props.eventFailureFailed !== (this.props.eventFailureFailed))) {
            this.props.resetEventFailuredFailed()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
                submitted: false
            })
        }

        if ((this.props && this.props.eventFailureSuccess && this.props.eventFailureSuccess.statusCode === 200) && (this.props.eventFailureSuccess) != (props.eventFailureSuccess)) {
            this.props.resetEventFailured()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
            })
        }
        //list for stage
        if (this.props && this.props.liststageFailed && this.props.liststageFailed.length > 0 && (props.liststageFailed !== (this.props.liststageFailed))) {
            this.props.resetliststagefailed()
            if (this.props.liststageFailed && this.props.liststageFailed.toLowerCase().includes('unhandled error')) {
                this.listforsale()
            } else {
                this.setState({
                    disableList: false,
                    isList: false,
                    isTransLoader: false,
                    isErrorPoped: true,
                    isSuccess: false,
                    loaderMes: '',
                    errorMessage: String(`List stage failed : ${this.props.liststageFailed}`),

                })
            }

        }

        if ((this.props && this.props.liststagesucess && this.props.liststagesucess.statusCode === 200) && (this.props.liststagesucess) != (props.liststagesucess)) {
            this.props.resetliststagesuccess()
            this.listforsale()

        }
        if (this.props && this.props.transferFailed && this.props.transferFailed.length > 0 && (props.transferFailed !== (this.props.transferFailed))) {
            this.props.resetTransferFailed()
            if (this.props.transferFailed.toLowerCase().includes('unhandled error')) {
                this.listStageToHistory()
            } else {
                this.setState({
                    disableList: false,
                    isList: false,
                    isTransLoader: false,
                    isErrorPoped: true,
                    isSuccess: false,
                    loaderMes: '',
                    errorMessage: String(`Failed to save transfer event : ${this.props.transferFailed}`),
                    submitted: false,
                })
            }

        }

        if ((this.props && this.props.transferSuccess && this.props.transferSuccess.statusCode === 200) && (this.props.transferSuccess) != (props.transferSuccess)) {
            this.props.resetTransferSuccess()
            this.listStageToHistory()

        }
        if (this.props && this.props.lisEventFailed && this.props.lisEventFailed.length > 0 && (props.lisEventFailed !== (this.props.lisEventFailed))) {
            this.props.resetListEventFailed()
            this.setState({
                isList: false,
                disableList: false,
                isTransLoader: false,
            })
            if (this.props.lisEventFailed && this.props.lisEventFailed.toLowerCase().includes('unhandled error')) {
                this.setState({
                    isSuccess: true,
                    isErrorPoped: false,
                })
            } else {
                this.setState({
                    isErrorPoped: true,
                    isSuccess: false,
                    loaderMes: '',
                    errorMessage: String(`Failed to save : ${this.props.lisEventFailed}`),

                })
            }

        }

        if ((this.props && this.props.listEventSuccess && this.props.listEventSuccess.statusCode === 200) && (this.props.listEventSuccess) != (props.listEventSuccess)) {
            this.props.resetListEventSuccess()
            this.setState({
                isSuccess: true,
                isList: false,
                disableList: false,
                isTransLoader: false,
                isErrorPoped: false,
            })

        }
        if ((this.props && this.props.walletDetailsuccess && this.props.walletDetailsuccess.statusCode === 200) && (this.props.walletDetailsuccess) !== (props.walletDetailsuccess)) {
            this.props.resetGetWalletAddDetail()
            const data = this.state.data

            if (this.props.walletDetailsuccess && this.props.walletDetailsuccess.data && this.props.walletDetailsuccess.data.wallets) {
                this.setState({
                    walletArray: this.props.walletDetailsuccess.data.wallets
                }, () => {
                    if (this.state.pathName === "sell") {
                        this.setState({ ShowPop: false, isList: true })
                        this.handleSale(data && data.tokenId)
                    } else if (this.state.path === "auction") {
                        this.setState({ ShowPop: false, isAuction: true })

                        // this.listToAuction()
                    } else if (this.state.pathName == "airdrop") {
                        this.setState({ ShowPop: false, isAirDrop: true })
                        this.handleAirDrop(data && data.tokenId)
                    } else {
                        this.setState({ ShowPop: false, isList: false, isAirDrop: false })
                    }
                })
            }

        }
        if ((this.props && this.props.walletDetailFailed && this.props.walletDetailFailed.length > 0) && (this.props.walletDetailFailed) !== (props.walletDetailFailed)) {
            this.props.resetGetWalletAddDetailFailed()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
                walletArray: [],
                errorMessage: String(`Failed to get wallet accounts that own art : ${this.props.walletDetailFailed}`),

            })

        }
        if ((this.props && this.props.walletRelatedArtId && this.props.walletRelatedArtId.statusCode === 200) && (this.props.walletRelatedArtId) !== (props.walletRelatedArtId)) {
            this.props.resetWalletArtIds()
            if (this.props.walletRelatedArtId && this.props.walletRelatedArtId.data && this.props.walletRelatedArtId.data.arts && this.props.walletRelatedArtId.data.arts.length > 0) {
                const avltoken = this.props.walletRelatedArtId.data.arts.map(a => a.tokenId)
                this.setState({
                    disableList: true,
                    isTransLoader: true,
                    isAirDrop: false
                })
                this.setState({ isList: true, loaderMes: 'Transferring NFT' })
                this.setState({ selectedTokenId: avltoken })
                this.processSale(avltoken)
            } else {
                this.setState({
                    disableList: false,
                    isList: false,
                    isTransLoader: false,
                    isErrorPoped: true,
                    isSuccess: false,
                    loaderMes: '',
                    walletArray: [],
                    errorMessage: String(`Failed to get tokens : Tokens not available`),

                })
            }

        }
        if ((this.props && this.props.walletRelatedArtIdFailed && this.props.walletRelatedArtIdFailed.length > 0) && (this.props.walletRelatedArtIdFailed) !== (props.walletRelatedArtIdFailed)) {
            this.props.resetGetWalletAddDetailFailed()
            this.setState({
                disableList: false,
                isList: false,
                isTransLoader: false,
                isErrorPoped: true,
                isSuccess: false,
                loaderMes: '',
                walletArray: [],
                errorMessage: String(`Failed to get tokens : ${this.props.walletRelatedArtIdFailed}`),

            })

        }

    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab, count: 1, airdropCount: 1, onstart: '', submitted: false, selectedAirdropOption: 2, onend: ''
        })
    }
    handleActionChange = (e) => {

        if ((e.target.value >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= 30)) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    checkNumber = (value) => {
        if (Number.isInteger(value)) {
            return true
        } else {
            return false
        }
    }
    handleBidPrice = (e) => {
        // Split the input string into two arrays containing integers/decimals
        // to check allow only two degits after decimals
        var res = (e.target.value).split(".");
        if ((e.target.value >= 0) && this.digitValidate(e.target.value) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                startPrice: e.target.value, startingPrice: num
            })
        }
    }
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    handleMinBid = (e) => {
        if (!(isNaN(e.target.value)))
            var res = (e.target.value).split(".");
        if (e.target.value >= 0 && (this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                minBid: e.target.value, minimumBid: num
            })
        }
    }

    checkValidAddressForList = (walletArray, currentaddress) => {
        const activeObj = walletArray && walletArray.find(el => el.walletAddress === currentaddress)
        if (activeObj && activeObj.isActive) {
            return activeObj
        } else {
            return false
        }
    }

    checkValidEditionForList = (walletArray, currentaddress, count) => {
        const activeObj = walletArray && walletArray.find(el => el.walletAddress === currentaddress)
        if (activeObj && activeObj.isActive) {
            if(count <= activeObj.editionCount ){
                return true
            }else{
                return false
            }
        } else {
            return false
        }
    }

    handleCount = async (e) => {
        e.persist()
        const { groupIds, walletArray } = this.state
        const value = e.target.value
        const currentaddress = await getUserAddress()
        if (currentaddress && currentaddress !== null) {
            const activeObj = this.checkValidAddressForList(walletArray, currentaddress)
            if (activeObj) {
                if ((value) > 0 && (value) <= (this.handlewalletEditionCountlimit(activeObj && activeObj.editionCount)) && ((value) % 1 === 0)) {
                    this.setState({ count: value, countLimit: this.handlewalletEditionCountlimit(activeObj && activeObj.editionCount) })
                } else {
                    this.setState({ count: '', countLimit: this.handlewalletEditionCountlimit(activeObj && activeObj.editionCount) })
                }
            } else {
                if ((value) > 0 && (value) <= (Number(this.handleEditionCountlimit(groupIds))) && ((value) % 1 === 0)) {
                    this.setState({ count: value, countLimit: this.handleEditionCountlimit(groupIds) })
                } else {
                    this.setState({ count: '', countLimit: this.handleEditionCountlimit(groupIds) })
                }
            }

        } else {
            if ((value) > 0 && (value) <= (Number(this.handleEditionCountlimit(groupIds))) && ((value) % 1 === 0)) {
                this.setState({ count: value, countLimit: this.handleEditionCountlimit(groupIds) })
            } else {
                this.setState({ count: '', countLimit: this.handleEditionCountlimit(groupIds) })
            }
        }

    }

    handleCountforAirdrop = async (e) => {
        e.persist()
        const { groupIds, walletArray } = this.state
        const value = e.target.value
        const currentaddress = await getUserAddress()
        if (currentaddress && currentaddress !== null) {
            const activeObj = this.checkValidAddressForList(walletArray, currentaddress)
            if (activeObj) {
                if ((value) > 0 && (value) <= (this.handlewalletEditionCountlimitForAirDrop(activeObj && activeObj.editionCount)) && ((value) % 1 === 0)) {
                    this.setState({ airdropCount: value, AirdropcountLimit: this.handlewalletEditionCountlimitForAirDrop(activeObj && activeObj.editionCount) })
                } else {
                    this.setState({ airdropCount: '', AirdropcountLimit: this.handlewalletEditionCountlimitForAirDrop(activeObj && activeObj.editionCount) })
                }
            } else {
                if ((value) > 0 && (value) <= (Number(this.handleEditionCountlimitForAirDrop(groupIds))) && ((value) % 1 === 0)) {
                    this.setState({ airdropCount: value, AirdropcountLimit: this.handleEditionCountlimitForAirDrop(groupIds) })
                } else {
                    this.setState({ airdropCount: '', AirdropcountLimit: this.handleEditionCountlimitForAirDrop(groupIds) })
                }
            }

        } else {
            if ((value) > 0 && (value) <= (Number(this.handleEditionCountlimitForAirDrop(groupIds))) && ((value) % 1 === 0)) {
                this.setState({ airdropCount: value, AirdropcountLimit: this.handleEditionCountlimitForAirDrop(groupIds) })
            } else {
                this.setState({ airdropCount: '', AirdropcountLimit: this.handleEditionCountlimitForAirDrop(groupIds) })
            }
        }
    }
    handleOnstart = (e) => {
        this.setState({
            onstart: e, onend: ''
        })
    }
    handleOnend = (e) => {
        this.setState({
            onend: e
        })
    }
    handleEventName = (e) => {
        if(spaceCheck(e.target.value)){
            this.setState({
                eventName : e.target.value
            })
        }else{
            this.setState({
                eventName : e.target.value.trim()
            })
        }
        
    }
    handleEventDescription = (e) => {
        if(spaceCheck(e.target.value)){
            this.setState({
                eventDescription : e.target.value
            })
        }else{
            this.setState({
                eventDescription : e.target.value.trim()
            })
        }
        
    }
    handleLogin = (path) => {
        const { data } = this.state
        fcl.logIn().then(res => {
            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                this.getUserWalletAddressDetail((data && data.id))
                this.setState({ pathName: path })

            }
        }).catch(err => {
            this.setState({ ShowPop: false, isList: false })
        })

    }
    getRoyalty = () => {
        let resale_royalty = ""
        let market_Fee = ""
        GetCreatorRoyality().then(res => {
            if ((res && res.status) === 200) {
                resale_royalty = parseFloat(res && res.data && res.data.Value).toFixed(0)
                this.setState({ resale_royalty })
            }
        })
        GetMarketFee().then(res => {
            if ((res && res.status) === 200) {
                market_Fee = parseFloat(res && res.data && res.data.Value).toFixed(0)
                // console.log("marketFee", market_Fee)
                this.setState({ market_Fee })

            }
        })
    }

    calculateSharings = (markedPrice) => {
        console.log(process.env.REACT_APP_DAPPER_ROYALTY_PERCENTAGE)
        if ((markedPrice && markedPrice > 0)) {
            let creatorRoyalty = ""
            let marketFee = ""
            let crtShr = 0
            let markShr = 0


            GetCreatorRoyality().then(res => {

                if ((res && res.status) === 200) {
                    creatorRoyalty = res && res.data && res.data.Value
                    // console.log("creatorRo",creatorRoyalty,markedPrice)
                    CalculateShare(markedPrice, creatorRoyalty).then(res => {
                        // console.log(res)
                        crtShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                        this.setState({ crt_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                        GetMarketFee().then(res => {
                            if ((res && res.status) === 200) {
                                marketFee = res && res.data && res.data.Value
                                // console.log("marketFee",marketFee)

                                CalculateShare(markedPrice, marketFee).then(res => {
                                    markShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                                    this.setState({ mark_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                    let remain_shr = (100.0 - (Number(creatorRoyalty) + Number(marketFee))).toFixed(8) 
                                    // let remain_shr = (100.0 - (Number(marketFee))).toFixed(8)

                                    // console.log("tot",remain_shr)
                                    CalculateShare(markedPrice, remain_shr).then(res => {

                                        this.setState({ sell_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value, isShare: true, isVisible: false })
                                    }).catch(err => { })
                                }).catch(err => { })
                                this.setState({ marketFee: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                            }
                        }).catch(err => { })
                    }).catch(err => { })
                    this.setState({ creatorRoyalty: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                }
            }).catch(err => { })






        } else {
            this.setState({ isVisible: false, crt_share: 0, sell_share: 0, mark_share: 0 })
        }
    }
    handlePriceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0) {
            if ((this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
                let basePrice = ''
                const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

                const num = (Number(e.target.value))
                const precision = num.toFixed(8)


                basePrice = precision

                //}
                this.setState({ price: e.target.value, basePrice, usd: rate ? rate.toFixed(2) : '', isVisible: true }, () => {
                    this.calculateSharings(this.state.basePrice)
                })
            }
        } else {
            this.setState({ isVisible: false, isShare: false })
            this.setState({ isAlert: true, message: 'Invalid Price', style: "danger" })
            this.gotoTop()

        }
    }

    showError(error) {
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        this.setState({
            isAlert: true,
            message: mes,
            style: "danger",
            listingInProgress: false,
            tokensProcessed: 0,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            ShowPop: false,
            isList: false,
            disableList: false
        })
        this.gotoTop()
        // this.closeAlert()

        // toast.error("User rejected signature", { position: toast.POSITION.BOTTOM_CENTER })
    }
    listStageToHistory = () => {
        const { selectedTokenId, historyId } = this.state

        let tokenIdobj = {
            tokenIds: selectedTokenId,
        }
        this.props.forsalelistStage(JSON.stringify(tokenIdobj), historyId)

    }
    listforsale = () => {
        const { selectedTokenId } = this.state
        let obj = {
            price: Number(this.state.basePrice),
            tokenIds: selectedTokenId,
        }
        this.props.listedArtForSale(JSON.stringify(obj))

    }
    forsaleProcess = async (id) => {
        const { selectedTokenId } = this.state

        const tokenArray = selectedTokenId
        if (selectedTokenId.length > 0) {
            try {
                let txnResponse;
                if (isDapperConnected()) {
                    txnResponse = await dapperTransferTokens(tokenArray)
                } else {
                    txnResponse = await flowMoveToAirDrop(tokenArray, (process.env.REACT_APP_STORE_ADMIN_ACCOUNT));
                }
                if (txnResponse.status === 200) {
                    const { transactionId, blockHeight } = txnResponse.data;

                    if (txnResponse && txnResponse.data && txnResponse.data.transaction && txnResponse.data.transaction.errorMessage) {
                        this.setState({
                            errorMessage: String(txnResponse.data.transaction.errorMessage),
                        })
                        this.props.forsaleEventFailure(id)
                        return false

                    } else {
                        this.setState({ isErrorPoped: false, loaderMes: 'Transfer is almost done, Please wait' })
                        setTimeout(() => {
                            this.setState({ isErrorPoped: false, loaderMes: 'Listing NFT for sale' })

                            this.props.forsaleTransfetSuccess(id)

                        }, 5000)

                    }


                } else {

                    const error = txnResponse.data.Error

                    let mes = ''; const splitError = error.split(':');
                    if (
                        splitError[2] &&
                        splitError[2].includes("Session expired")
                    ) {
                        mes = "Session Expired.Please reconnect your wallet account";
                    } else if (
                        splitError[2]
                    ) {
                        mes = splitError[2]
                    } else if (splitError[1]) {
                        if (error && error.toLowerCase().includes('declined')) {
                            mes = "User rejected signature"
                        } else {
                            mes = splitError[1]
                        }
                    } else {
                        mes = splitError[0];
                    }
                    this.setState({
                        errorMessage: mes,
                    })
                    if (error && error.toLowerCase().includes('user rejected')) {
                        this.props.forsaleEventCancelled(id)
                    } else {
                        this.props.forsaleEventFailure(id)
                    }
                    return false
                }

            } catch (e) {

                this.props.forsaleEventFailure(id)

                if (e && e.data && e.data.Error) {
                    this.setState({ errorMessage: String(e.data.Error) })
                    return false
                } else if (e && e.data && e.data.message) {
                    this.setState({ errorMessage: (e.data.message) })

                    return false
                } else if (e && e.message) {
                    this.setState({ errorMessage: (e.message) })

                    return false

                } else {
                    this.setState({ errorMessage: (String(e)) })

                    return false
                }
            }
        } else {
            if (!(this.isArtVisible(this.state.data))) {
                this.setState({
                    disableList: false, isList: false, isTransLoader: false, submitted: false
                })
                this.setState({ isAlert: true, message: 'Art is hidden. Cannot be listed for sale', style: "danger" })
                this.gotoTop()

            } else {
                this.setState({
                    disableList: false, isList: false, isTransLoader: false, submitted: false
                })
                this.setState({ isAlert: true, message: 'Token not available for listing air drops', style: "danger" })
                this.gotoTop()
                this.hideLoader()
            }
        }


    }
    async processSale(avltoken) {

        let obj = {
            tokenIds: avltoken,
            price: Number(this.state.basePrice),
            initiatedFor: 0
        }
        if (avltoken.length > 0) {
            const artTokenGroupId = this.state.data.tokenGroupId;
            try {
                await dropServices.saveEvent(artTokenGroupId, avltoken.length, dropServices.DropEventType.FOR_SALE, this.state.data.tokenId);
                await this.props.forsaleInitiate(JSON.stringify(obj))


            } catch (e) {
                console.log((e))
                this.setState({
                    disableList: false, isList: false, isTransLoader: false, issuccess: false, isErrorPoped: true, txnId: ''
                })
                if (e && e.data && e.data.Error) {
                    this.setState({ errorMessage: String(e.data.Error) })
                    return false
                } else if (e && e.data && e.data.message) {
                    this.setState({ errorMessage: (e.data.message) })

                    return false
                } else if (e && e.message) {
                    this.setState({ errorMessage: (e.message) })

                    return false

                } else {
                    this.setState({ errorMessage: (String(e)) })

                    return false
                }
            }
        } else {
            if (!(this.isArtVisible(this.state.data))) {
                this.setState({
                    disableList: false, isList: false, isTransLoader: false, submitted: false
                })
                this.setState({ isAlert: true, message: 'Art is hidden. Cannot be listed for sale', style: "danger" })
                this.gotoTop()

            } else {
                this.setState({
                    disableList: false, isList: false, isTransLoader: false, submitted: false
                })
                this.setState({ isAlert: true, message: 'Token not available for listing for sale', style: "danger" })
                this.gotoTop()
                this.hideLoader()
            }
        }


    }
    checkIfNeedtoWithdrawArt = () => {
        const { groupIds, totalOwnerArt, walletArray } = this.state

        let sumofTokens = 0;

        for (var i = 0; i < walletArray.length; i++) {
            sumofTokens += walletArray[i].editionCount
        }

        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            if (sumofTokens === 1) {
                return true
            } else {
                return false
            }

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            if (sumofTokens === 1) {
                return true
            } else {
                return false
            }
        }
        else {
            if (sumofTokens === (groupIds && groupIds.length)) {
                return true
            } else {
                return false
            }
        }

    }
    walletEditionCount =  (currentaddress) => {
        const { walletArray } = this.state
        if (currentaddress && currentaddress !== null) {
            const activeObj = this.checkValidAddressForList(walletArray, currentaddress)
            if(activeObj){
                return this.handlewalletEditionCountlimit(activeObj && activeObj.editionCount)
            }
        }
    }
    handleSale = (id) => {
        const { groupIds, totalOwnerArt, data, walletArray } = this.state
        const totalMintedArt = (data.toatalMintedTokens)

        const ownerWalletAddress = data && data.ownerWalletAddress
        const walletProvider = data && data.ownerWallet && data.ownerWallet.walletProvider ? data.ownerWallet.walletProvider : ''

        // console.log("ownerWalletAddress",ownerWalletAddress)
        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.count
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens
            let tokenArray = JSON.stringify(selectedTokens)

            if (this.state.basePrice && Number(this.state.basePrice) > 0) {
                isConnectWallet() ?
                    getUserAddress().then(res => {
                        if (this.checkIfNeedtoWithdrawArt()) { // need to withdraw
                            if ((this.checkValidAddressForList(walletArray, res))) {
                                if((this.checkValidEditionForList(walletArray, res, checkCount))){
                                if (res != null) {
                                    const artTokenGroupId = data.tokenGroupId;
                                    this.showLoader();
                                    walletServices.checkWallet(res).then(wallet => {
                                        this.hideLoader()
                                        if ((wallet && wallet.statusCode) === 200) {
                                            this.setState({ disableList: true })
                                            this.setState({ isTransLoader: true })

                                            // if(totalMintedArt > 1){
                                            this.showLoader();
                                            if (checkCount && (data["id"]) && (res)) {
                                                let obj = {
                                                    count: checkCount,
                                                    fields: 'id,tokenId'
                                                }
                                                this.props.getwalletRelatedArtIds(res, (data.id), obj)

                                            } else {
                                                this.setState({ isAlert: true, message: `Couldn't get art data`, style: "danger" })

                                                this.hideLoader()

                                                this.setState({ disableList: false, isTransLoader: false })
                                                this.setState({ ShowPop: false, isList: false, submitted: false })
                                            }
                                            // }else{
                                            //     this.setState({ disableList: true, isList: true, loaderMes : 'Transferring NFT' })
                                            //     this.setState({ isTransLoader: true,selectedTokenId : selectedTokens})
                                            //     this.processSale(selectedTokens)
                                            // }
                                        }
                                    }).catch(err => {
                                        this.setState({ isAlert: true, message: err, style: "danger" })
                                        this.gotoTop()
                                        this.hideLoader()
                                        this.setState({
                                            disableList: false, isTransLoader: false, submitted: false,
                                            isList: false
                                        })

                                    })
                                } else {
                                    this.setState({ disableList: false, submitted: false })
                                    this.setState({ ShowPop: false, isList: false })
                                    this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                                    this.gotoTop()
                                }
                            }else{
                                this.setState({ disableList: false, submitted: false,  ShowPop: false, isList: false, isSuccess: false })
                                this.setState({ isAlert: true, message: `You have only ${this.walletEditionCount(res)} Editions in your wallet. Please enter a valid edition count`, style: "danger" })
                                this.gotoTop()
                            }
                            } else {
                                this.setState({
                                    disableList: false,
                                    isList: false,
                                    isTransLoader: false,
                                    isErrorPoped: true,
                                    isSuccess: false,
                                    loaderMes: '',
                                    txnId: '',
                                    submitted: false
                                })
                                if(walletProvider && walletProvider.toLowerCase().includes('disrupt')){
                                    this.setState({
                                        errorMessage: [`Please transfer this NFT from your DISRUPT WALLET, before listing for sale`],
                                        goToNfTStash :true
                                    })
                                }else{
                                    this.setState({
                                        errorMessage: [`Please connect `, <i className='usernamelilac'>{ownerWalletAddress}&nbsp;({walletProvider})&nbsp; </i>, ` wallet account to list this NFT for sale.`],
                                    })
                                }
                                

                            }
                        } else {
                            this.setState({
                                disableList: false,
                                isList: false,
                                isTransLoader: false,
                                isErrorPoped: false,
                                isSuccess: false,
                                loaderMes: '',
                                txnId: '',
                                submitted: false,
                                withdrawWarnError: true,
                                airdropWithdrawWarnError: false
                            })

                        }
                    }).catch(err => {
                        this.setState({ disableList: false, submitted: false })
                        this.setState({ ShowPop: false, isList: false })
                        this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                        this.gotoTop()
                    }) : this.connectMyWallet("sell")

            } else {
                this.setState({ disableList: false, submitted: false })

                this.setState({ isAlert: true, message: 'Please enter valid price', style: "danger" })
                this.gotoTop()
                // this.closeAlert()
                // toast.error("Please enter valid price", { position: toast.POSITION.BOTTOM_CENTER })
            }
        } else {
            this.setState({ disableList: false, submitted: false })
            if (this.checkIfNeedtoWithdrawArt()) {
                this.setState({ isAlert: true, message: 'Please enter valid edition', style: "danger", count: '' })
                this.gotoTop()
            } else {
                this.setState({
                    disableList: false,
                    isList: false,
                    isTransLoader: false,
                    isErrorPoped: false,
                    isSuccess: false,
                    loaderMes: '',
                    txnId: '',
                    submitted: false,
                    withdrawWarnError: true,
                    airdropWithdrawWarnError: false
                })
            }

        }
    }
    handleAirdropSuccess = (response) => {
        if ((response && response.statusCode) === 200) {
            this.setState({
                disableList: false,
                isTransLoader: false,
                isAirDropSuccess: true,
                isAirDrop: false
            })
        }
    }
    handleAirdropFailed = (err) => {
        console.log(err)
        this.hideLoader()
        this.setState({
            disableList: false,
            isTransLoader: false,
            isAirDropSuccess: false,
            isAirDrop: false,
            isSuccess: false,
            submitted: false,
            isErrorPoped: true,
            loaderMes: '',
            errorMessage: String(`Failed to save airdrop : ${err}`),
        })
    }
    addTodrops = (avltoken) => {

        if (avltoken.length > 0) {
            let airDropType = airDropService.airDropType.PUBLIC;
            let selectedUsers = null;
            let location = {}
            let endDate = null
            if (this.state.selectedAirdropOption === 2) {
                airDropType = airDropService.airDropType.SELECTED_USERS;
                if (this.state.member !== '') {
                    selectedUsers = this.state.member.split(',');

                    if ((selectedUsers && selectedUsers.length) !== (avltoken && avltoken.length)) {
                        this.setState({
                            isAlert: true,
                            message: "No.of editions provided and selected members must be equal",
                            style: "danger",
                            disableList: false,
                            isTransLoader: false,
                            isAirDropSuccess: false,
                            isAirDrop: false
                        })
                        this.gotoTop();
                        return;
                    }
                } else {
                    this.setState({
                        isAlert: true,
                        message: "Members list cannot be empty for Selected members only option",
                        style: "danger",
                        disableList: false,
                        isTransLoader: false,
                        isAirDropSuccess: false,
                        isAirDrop: false
                    })
                    this.gotoTop();
                    return;
                }
            } else if (this.state.selectedAirdropOption === 4) {
                airDropType = airDropService.airDropType.LOCATED_USERS;
                if (this.state.latitude !== null && this.state.longitude !== null && this.state.radius !== null) {
                    location = {
                        latitude: this.state.latitude.toFixed(8),
                        longitude: this.state.longitude.toFixed(8),
                        radius: this.state.radius.toString()
                    }
                    if (this.state.onend !== '') {
                        endDate = new Date(this.state.onend)
                    } else {
                        endDate = null
                    }
                    console.log(endDate)
                }
            }
            if(this.state.selectedAirdropOption === 5){
                console.log(this.state.eventName,this.state.eventDescription)
                airDropService.eventAirdropCreation(
                    (avltoken),
                    new Date(this.state.onstart),
                    this.state.eventName,
                    this.state.eventDescription,
                )
                    .then((response) => {
                       this.handleAirdropSuccess(response)
                    })
                    .catch(err => {
                        this.handleAirdropFailed(err)
                    })
            }else{
                airDropService.addToAirDrops(
                    (avltoken),
                    new Date(this.state.onstart),
                    airDropType,
                    selectedUsers,
                    location,
                    endDate,
                    (this.state.isAlertOff ? "0" : "1")
                )
                    .then((response) => {
                       this.handleAirdropSuccess(response)
                    })
                    .catch(err => {
                        this.handleAirdropFailed(err)
                    })
            }
            
        } else {
            if (!(this.isArtVisible(this.state.data))) {
                this.setState({
                    message: "Art is hidden. Cannot be listed in airdrop"
                })
            } else {
                this.setState({
                    message: "Token not available for listing air drops"
                })
            }
            this.setState({
                isAlert: true,
                style: "danger",
                disableList: false,
                isTransLoader: false,
                isAirDropSuccess: false,
                isAirDrop: false
            })
            this.gotoTop()
        }
    }
    handleImportCSV = (avltoken) => {
        if (avltoken && avltoken.length > 0) {
            if (this.state.selectedAirdropOption === 3) {
                let airDropType = airDropService.airDropType.SELECTED_USERS;

                airDropService.addCSVImport((avltoken),
                    new Date(this.state.onstart),
                    airDropType,
                    this.state.uploadedFile,(this.state.isAlertOff ? "0" : "1"))
                    .then((response) => {
                        if ((response && response.statusCode) === 200) {
                            this.setState({
                                disableList: false,
                                isTransLoader: false,
                                isAirDropSuccess: true,
                                isAirDrop: false
                            })
                        }
                    })
                    .catch(err => {
                        this.resetCSVFile()
                        console.log(err)
                        this.hideLoader()
                        this.setState({
                            disableList: false,
                            isTransLoader: false,
                            isAirDropSuccess: false,
                            isAirDrop: false,
                            isSuccess: false,
                            submitted: false,
                            isErrorPoped: true,
                            loaderMes: '',
                            errorMessage: String(`Failed to save airdrop : ${err}`),
                        })
                    })
            }
        } else {
            this.resetCSVFile()
            this.setState({
                isAlert: true,
                message: "Token not available for listing air drops",
                style: "danger",
                disableList: false,
                isTransLoader: false,
                isAirDropSuccess: false,
                isAirDrop: false
            })
            this.gotoTop()
        }

    }
    handleAirDrop = (id) => {
        const { groupIds, totalOwnerArt, data, walletArray, eventDescription, eventName } = this.state
        const totalMintedArt = (data.toatalMintedTokens)
        const ownerWalletAddress = data && data.ownerWalletAddress
        const walletProvider = data && data.ownerWallet && data.ownerWallet.walletProvider ? data.ownerWallet.walletProvider : ''

        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.airdropCount
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })

        if (!checkCount || (checkCount < 1) || (checkCount > arrayLength)) {
            this.setState({ disableList: false, isAirDrop: false })
            if (this.checkIfNeedtoWithdrawArt()) {
                this.setState({ isAlert: true, message: 'Please enter valid edition', style: "danger", count: '' })
                this.gotoTop()
            } else {
                this.setState({
                    disableList: false,
                    isList: false,
                    isTransLoader: false,
                    isErrorPoped: false,
                    isSuccess: false,
                    loaderMes: '',
                    txnId: '',
                    submitted: false,
                    isAirDrop: false,
                    airdropWithdrawWarnError: true,
                    withdrawWarnError: false
                })
            }


        } else if (
            !this.state.onstart ||
            this.state.onstart === ''
        ) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please select Date & Time', style: "danger", count: '' })
            this.gotoTop();
        } else if (
            (this.state.selectedAirdropOption === 2) && ((this.state.member === '') || (!this.usernameValidation(this.state.member)))) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter proper username', style: "danger", count: '' })
            this.gotoTop();
        } else if ((this.state.selectedAirdropOption === 3) && (this.state.uploadedFile && this.state.uploadedFile.length === 0) && (this.state.localImageName === '')) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please upload your members csv file', style: "danger", count: '' })
            this.gotoTop();
        }else if ((this.state.selectedAirdropOption === 5) && (this.state.eventName === '')) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter event name', style: "danger", count: '' })
            this.gotoTop();
        }else if ((this.state.selectedAirdropOption === 5) && 
        (!this.checkLengthValue(this.state.eventName,this.eventNameMinLength,this.eventNameMaxLength)) ) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: `Event name must be Min ${this.eventNameMinLength} - Max ${this.eventNameMaxLength} characters`, style: "danger", count: '' })
            this.gotoTop();
        }else if(
            (this.state.selectedAirdropOption === 5) && 
            (this.state.eventDescription !== '' && !this.checkLengthValue(this.state.eventDescription,this.eventDescMinLength,this.eventDescMaxLength))
        ){
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: `Event description must be Min ${this.eventDescMinLength} - Max ${this.eventDescMaxLength} characters`, style: "danger", count: '' })
            this.gotoTop();
        }
        else {
            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens;
            isConnectWallet() ? //check if wallet was connected
                getUserAddress().then(res => { // get user address
                    if (this.checkIfNeedtoWithdrawArt()) {
                        if ((this.checkValidAddressForList(walletArray, res))) { // check connected wallet address and owner of the art was same 
                            if (res != null) {
                                const artTokenGroupId = data.tokenGroupId;

                                this.showLoader();
                                walletServices.checkWallet(res).then(wallet => {
                                    this.hideLoader()
                                    if ((wallet && wallet.statusCode) === 200) {
                                        //get wallet associate tokens
                                        this.showLoader();

                                        if (checkCount && (data["id"]) && (res)) {
                                            let obj = {
                                                count: checkCount,
                                                fields: 'id,tokenId'
                                            }
                                            artServices.getArtsForRelatedWallet(res, (data.id), obj).then(avltoken => {
                                                this.hideLoader()
                                                if (avltoken && avltoken.statusCode === 200) {
                                                    this.setState({
                                                        disableList: true,
                                                        isTransLoader: true,
                                                        isAirDrop: false
                                                    })
                                                    if ((avltoken && avltoken.data && avltoken.data.arts && avltoken.data.arts.length) === Number(checkCount)) {
                                                        const avltokens = avltoken.data.arts.map(a => a.tokenId)

                                                        if ((this.state.selectedAirdropOption === 3) && avltokens && avltokens.length > 0) {
                                                            this.handleImportCSV(avltokens)
                                                        } else {
                                                            if (avltokens && avltokens.length > 0) {
                                                                this.addTodrops(avltokens)
                                                            } else {
                                                                this.setState({
                                                                    isAlert: true,
                                                                    message: `Couldn't get tokens`,
                                                                    style: "danger",
                                                                    disableList: false,
                                                                    isTransLoader: false,
                                                                    isAirDropSuccess: false,
                                                                    isAirDrop: false
                                                                })
                                                            }
                                                        }


                                                    } else {
                                                        this.hideLoader()
                                                        this.resetCSVFile()
                                                        this.setState({
                                                            isAlert: true,
                                                            message: `You have only ${avltoken && avltoken.data && avltoken.data.count} token in this wallet`,
                                                            style: "danger",
                                                            disableList: false,
                                                            isTransLoader: false,
                                                            isAirDropSuccess: false,
                                                            isAirDrop: false
                                                        })
                                                        this.gotoTop()
                                                    }

                                                } else {
                                                    this.resetCSVFile()
                                                }
                                            }).catch(tknerr => {
                                                this.hideLoader()
                                                this.resetCSVFile()
                                                this.setState({
                                                    isAlert: true,
                                                    message: tknerr,
                                                    style: "danger",
                                                    disableList: false,
                                                    isTransLoader: false,
                                                    isAirDropSuccess: false,
                                                    isAirDrop: false
                                                })
                                                this.gotoTop()
                                            })
                                        } else {
                                            this.resetCSVFile()
                                            this.setState({ disableList: false, isAirDrop: false })
                                            this.setState({ ShowPop: false, isList: false })
                                            this.setState({ isAlert: true, message: "Required arguments missing", style: "danger" })
                                            this.gotoTop()
                                        }


                                    }

                                }).catch(err => {
                                    this.resetCSVFile()
                                    this.setState({ isAlert: true, isAirDrop: false, message: err, style: "danger", isAirDropSuccess: false })
                                    this.gotoTop()
                                    // this.closeAlert()
                                    this.hideLoader()
                                    this.setState({ disableList: false })

                                    // toast.error(err)
                                })
                            }
                            // }
                        } else {
                            this.resetCSVFile()
                            this.setState({
                                disableList: false,
                                isList: false,
                                isTransLoader: false,
                                isErrorPoped: true,
                                isSuccess: false,
                                loaderMes: '',
                                isAirDrop: false,
                                ShowPop: false,
                            })
                            if(walletProvider && walletProvider.toLowerCase().includes('disrupt')){
                                this.setState({
                                    errorMessage: [`Please transfer this NFT from your DISRUPT WALLET, before creating this airdrop`],
                                    goToNfTStash :true
                                })
                            }else{
                                this.setState({
                                    errorMessage: [`Please connect `, <i className='usernamelilac'>{ownerWalletAddress}&nbsp;({walletProvider})&nbsp; </i>, ` wallet account to create this NFT AirDrop.`],
                                })
                            }
                            // this.gotoTop()
                        }
                    } else {
                        this.setState({
                            disableList: false,
                            isList: false,
                            isTransLoader: false,
                            isErrorPoped: false,
                            isSuccess: false,
                            loaderMes: '',
                            txnId: '',
                            submitted: false,
                            airdropWithdrawWarnError: true,
                            withdrawWarnError: false
                        })
                    }
                }).catch(err => {
                    this.setState({ disableList: false, isAirDrop: false })
                    this.setState({ ShowPop: false, isList: false })
                    // toast.error("Please reconnect your wallet")
                    this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                    this.gotoTop()
                    this.resetCSVFile()
                    // this.closeAlert()
                }) : this.connectMyWallet("airdrop")



        }
    }
    resetCSVFile = () => {
        this.setState({ uploadedFile: [], localImageName: '' })
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
        this.gotoTop()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    fetchArt(url) {
        if (url && url.localPreview && url.localPreviewMimeType && url.localPreviewMimeType !== null) {
            if (url.localPreviewMimeType.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        } else {
            if (url && url.thumbnailHash) {
                fetchService.getBlob(url.thumbnailHash).then((blob) => {

                    if (blob.type.match(/image.*/)) {
                        return this.setState({ isImage: true })
                    } else {
                        return this.setState({ isImage: false })
                    }
                }).catch((err) => {
                    return this.setState({ isImage: false })
                })
            }
        }

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    percentageShower = () => {
        return <div className="purchasebox">
            <div className="table-responsive">
                <table className="table custable">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Marketplace Fee</th>
                            <th>Royalty for Creator</th>
                            <th>Creator</th>
                        </tr>
                        <tr>
                            <th>First Sale</th>
                            <td>15%</td>
                            <td>0%</td>
                            <td>85%</td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Reseller</th>
                        </tr>
                        <tr>
                            <th>Every Resale</th>
                            <td>5%</td>
                            <td>10%</td>
                            <td>85%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    resetPage = () => {
        localStorage.setItem('page', "0")
        history.push('/wallet')
    }
    resettoDropPage = () => {
        localStorage.setItem('page', "0")
        history.push('/drops')
    }
    redirectToAirDrops = () => {
        localStorage.setItem('page', "0")
        history.push('/wallet',{GoToTab : 2})
    }
    redirectToNftStash = () => {
        localStorage.setItem('page', "0")
        history.push('/wallet',{GoToTab : 3})
        this.setState({goToNfTStash :false})
    }
    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }
    handleplayresume = () => {
        const { pauseResume } = this.state
        this.setState({
            pauseResume: !pauseResume
        })
    }
    handleEventUsers = (value) => {
        if (value === 5) {
            return false
        } else {
            this.setState({ 
                uploadedFile: [], 
                localImageName: '', 
                selectedAirdropOption: 5,
                infoLimit:250,
                maxAllowedAirdropLimit:250, 
                airdropCount: 1 })
        }
    }
    handleChecked = (value) => {
        if (value === 1) {
            return false
        } else {
            this.setState({ 
                uploadedFile: [], 
                localImageName: '', 
                selectedAirdropOption: 1,
                infoLimit:250,
                maxAllowedAirdropLimit:250, 
                airdropCount: 1,eventName: '', eventDescription: '' })
        }
    }
    handleMembersChecked = (value) => {
        if (value === 2) {
            return false
        } else {
            this.setState({ selectedAirdropOption: 2, uploadedFile: [], localImageName: '',infoLimit:10,maxAllowedAirdropLimit:10, airdropCount: 1,eventName: '', eventDescription: '' })
        }
    }
    handleImported = (value) => {

        if (value === 3) {
            return false
        } else {
            this.setState({ selectedAirdropOption: 3 ,infoLimit:10,maxAllowedAirdropLimit:10, airdropCount: 1,eventName: '', eventDescription: ''})
        }
    }
    handleLocationCheck = (value) => {
        if (value === 4) {
            return false
        } else {
            this.setState({ selectedAirdropOption: 4, uploadedFile: [], localImageName: '',infoLimit:250,maxAllowedAirdropLimit:250, airdropCount: 1,eventName: '', eventDescription: ''})
        }
    }
    handleSelectedMembers = (e) => {
        this.setState({
            member: (e.target.value).toLowerCase(),
        }, () => {
        })

    }
    usernameValidation = (value) => {
        let isValide = true
        const array = value.split(',')
        const regext = /^([a-zA-Z0-9.]{4,})+$/
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < array.length; i++) {
            if ((!(emailReg.test(array[i]))) &&
                (!(regext.test(array[i])))
            ) {
                isValide = false;
            }
        }
        return isValide;

    }
    checkIsEmail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((emailReg.test(value))) {
            return true
        } else {
            return false
        }
    }

    validation = (currentDate) => {
        return currentDate.isAfter(moment().subtract(1, "days"));

    }
    validationForEndDate = (currentDate) => {
        return currentDate.isAfter(moment(this.state.onstart).subtract(1, "days"));


    }
    handleSelectCSV = (e) => {
        let uploadFile = e.target.files[0]
        console.log(uploadFile)

        const types = ['text/csv']
        let err = '';

        const sizeLimit = 1 * (1e+6);
        e.target.value = null;
        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger", uploadedFile: [], localImageName: '' })
            this.gotoTop()

        } else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += `Maximum supported file size is ${1} MB`;
            this.setState({ isAlert: true, message: err, style: "danger", uploadedFile: [], localImageName: '' })
            this.gotoTop()

        } else {
            if (uploadFile) {
                this.setState({ uploadedFile: uploadFile, localImageName: uploadFile && uploadFile.name })
            }
        }
        // const formData = new FormData();

        // formData.append('File', this.state.uploadedFile);
    }
    downloadCSV = () => {
        this.showLoader()
        airDropService.downloadSampleAirdropCSV().then(res => {
            res && res.text().then(text => {
                const url = window.URL.createObjectURL(
                    new Blob([text]),
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `sampleFile.csv`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            this.hideLoader()
        }).catch(e => {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Error occured while download sample csv file', style: "danger" })
            this.gotoTop()
        })
    }

    isOwnerisCreator = (data) => {
        if (getCurrentUserId()) {
            if ((data && data.creator && data.creator.id) === (getCurrentUserId())) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    statusChange = (items) => {
        if ((items && items.id)) {
            const status = (items && items.status && items.status === 1) ? 2 : 1
            let obj = {
                status
            }

            this.props.updatevisibilityStatus(obj, (items.id))
        } else {
            this.setState({ isAlert: true, message: "Something went wrong : Art id was missing", style: "danger", localFileUpload: false, localBrowsed: false })
            this.gotoTop()
        }

    }
    isArtVisible = (data) => {
        if ((data && data.status && data.status) === 1) {
            return true
        } else {
            return false
        }
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    resetStatusPage = () => {

        this.setState({
            isArtStatusChangeSuccess: false
        })
    }

    getImageSource = (items) => {
        if ((items && items.localPreview && items.localPreview !== null)) {
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        } else {
            if (items && items.thumbnailHash) {
                return items.thumbnailHash
            }
        }
    }
    updateError = (error) => {
        this.setState({ isAlert: true, message: error, style: "danger" })
        this.gotoTop()

    }
    updateThumbnailSuccess = () => {
        this.setState({
            isThumbnailUpdateSuccess: true
        })
    }
    resetAfterUpdateThumbnail = () => {
        this.setState({
            isThumbnailUpdateSuccess: false
        })
    }
    updateCategorySuccess = () => {
        this.getArtByToken()
        this.setState({
            isCategoryUpdateSuccess: true, isAlert: false
        })
    }
    resetAfterCategory = () => {
        this.setState({
            isCategoryUpdateSuccess: false
        })
    }
    updateTagSuccess = () => {
        this.getArtByToken()
        this.setState({
            isTagUpdateSuccess: true, isAlert: false
        })
    }
    resetAfterTag = () => {
        this.setState({
            isTagUpdateSuccess: false
        })
    }
    isArtisMusicCategory = (item) => {
        if (item && item.id) {
            if (item && item.nftCategory !== null && item.nftCategory !== undefined && item.nftCategory.name === "music") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    handleEditionCountlimit = (groupIds) => {
        if (groupIds && groupIds.length > this.maxAllowedListLimit) {
            return this.maxAllowedListLimit
        } else {
            return groupIds.length
        }
    }
    handlewalletEditionCountlimit = (count) => {
        if (count > this.maxAllowedListLimit) {
            return this.maxAllowedListLimit
        } else {
            return count
        }
    }
    handleEditionCountlimitForAirDrop = (groupIds) => {
        if (groupIds && groupIds.length > this.state.maxAllowedAirdropLimit) {
            return this.state.maxAllowedAirdropLimit
        } else {
            return groupIds.length
        }
    }
    handlewalletEditionCountlimitForAirDrop = (count) => {
        if (count > this.state.maxAllowedAirdropLimit) {
            return this.state.maxAllowedAirdropLimit
        } else {
            return count
        }
    }
    isFirstSale = () => {
        if ((this.state.artOwner === this.state.artCreator)) {
            return true
        } else {
            return false
        }
    }
    getUserWalletAddressDetail = (id) => {
        if (id) {
            this.props.getWalletAddDetail(id)
        }
    }

    connectMyWallet = (path) => {
        this.setState({ pathName: path })
        this.handleToSwitchAccount()
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false,
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
        }
    }
    isListPage = () => {
        this.closeSwitchAccModal()
        history.push('/wallet')
    }
    handleSwitchAccFailed = (message) => {
        history.push('/wallet')
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isAirDrop: false

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    addZeroes = (num) => {
        const decNum = flowDecimal(parseFloat(num))

        let value = (decNum);
        // Split the input string into two arrays containing integers/decimals
        var res = decNum && decNum.split(".");
        // If there is no decimal point or only one decimal place found.
        if ((res.length == 1 || res[1] && res[1].length < 3)) {
            // Set the number to two decimal places
            value = value && Number(value).toFixed(2);
        } else {
            value = 0.00
        }
        // Return updated or original number.
        return value;
    }
    fetchMapCoordinates = (detail) => {
        this.setState({
            latitude: detail.center.lat,
            longitude: detail.center.lng,
            radius: detail.radius
        })
        console.log("detail", detail)
    }
    checkLengthValue = (value,minLength,maxLength) => {
        if (((value && value.length) >= minLength) && (value && value.length) <= maxLength) {
            return true
        } else {
            return false
        }
    }
    handleSendAlertToUsers = () => {
        this.setState({
            isAlertOff : !this.state.isAlertOff
        })
    }
    isCurrentOwnerIsCreator = (data) => {
        if (getCurrentUserId()) {
            if ((data && data.creator && data.creator.id) === (getCurrentUserId()) &&
            ((data && data.creator && data.creator.id) === (data && data.owner && data.owner.id))) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        if ((this.state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        const { data, sell_share, crt_share, mark_share, groupIds, totalOwnerArt, isArtDisabled, disabledMessage } = this.state
        // console.log(sell_share,crt_share,mark_share)
        // console.log((Number(crt_share)+Number(mark_share)))
        const Art_Name = ((data && data.title) ? (data.title) : '...');
        const Art_Desc = ((data && data.description) ? (data.description) : '...');
        const metaTitle = `${Art_Name} - Sell or Auction NFT on ${process.env.REACT_APP_MARKETPLACE}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={`List NFT for sale`}></meta>
                    <meta name="description" content={`List NFT for sale`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {/* {this.state.isAlert && <Alert className="alertcustom" message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />} */}
                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                </div>}
                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }
                {this.state.withdrawWarnError && !this.state.isSuccess && !this.state.isList &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size py-3">
                            <h3 className='text-danger  mb-3'>USER ACTION REQUIRED</h3>
                            <svg className="crossmark mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3 text-left uareq">
                                <h5 className='mb-3 text-uppercase  text-center'>WITHDRAW ACTIVE DROPS FIRST</h5>
                                <p><span>Step 1</span> Visit "My Collection"{' > '}"On Sale" Tab</p>
                                <p><span>Step 2</span> View all NFTs for sale in FLOW Currency</p>
                                <p><span>Step 3</span> Click "WITHDRAW" button to remove listing in FLOW currency price</p>
                                <p><span>Step 4</span> Attempt to "List for Sale" in USD price</p>
                            </div>

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ withdrawWarnError: false }) }}>Close</button></div></div></div>
                }
                {this.state.airdropWithdrawWarnError && !this.state.isSuccess && !this.state.isList &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size py-3">
                            <h3 className='text-danger  mb-3'>USER ACTION REQUIRED</h3>
                            <svg className="crossmark mb-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3 text-left uareq">
                                <h5 className='mb-3 text-uppercase  text-center'>WITHDRAW ACTIVE DROPS FIRST</h5>
                                <p><span>Step 1</span> Visit "My Collection"{' > '}"On Sale" Tab</p>
                                <p><span>Step 2</span> View all NFTs for sale in FLOW Currency</p>
                                <p><span>Step 3</span> Click "WITHDRAW" button to remove listing in FLOW currency price</p>
                                <p><span>Step 4</span> Attempt to "List for Airdrop" again</p>
                            </div>

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ airdropWithdrawWarnError: false }) }}>Close</button></div></div></div>
                }
                {this.state.isErrorPoped && !this.state.isSuccess && !this.state.isList &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{this.state.errorMessage}</div>
                            {/* <div className='py-3'>Selected token id : 
                                {(this.state.selectedTokenId && Array.isArray(this.state.selectedTokenId)) ?
                                 this.state.selectedTokenId.map(items => (items ? items.join(',') : '')) : this.state.selectedTokenId}</div> */}
                            {(this.state.selectedTokenId && this.state.selectedTokenId.length > 0) ? <div className='py-3 popmesoverflow'>Selected token id : {this.state.selectedTokenId && this.state.selectedTokenId.toString()}</div> : ''}

                            {this.state.txnId ? <div className='py-3'>Transaction id : {this.state.txnId}</div> : ''}

                            <div>
                                {this.state.goToNfTStash ? 
                                 <button type="button" className="btn btn-primary px-5" onClick={(e) => this.redirectToNftStash()}>Go to DISRUPT WALLET</button>
                                 :
                                 <button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false }) }}>Close</button>    
                            }
                               
                            </div></div></div>
                }
                {this.state.listingInProgress &&
                    <div className="show-success">
                        <div className="success-pop">
                            <div className="sale-progress">
                                <div className="progress mb-1 cus-progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${this.state.listingProgress}%` }}></div>
                                </div>
                                <div className='mb-5 text-small'>
                                    <span className="usernamelilac">{this.state.tokensProcessed}/{this.state.totalTokensToBeListed}</span> Editions listed
                                </div>
                                <h3 className="mb-3">Listing more than <span className="usernamelilac">{getBatchSize()}</span> Editions will be done in batches.</h3>
                                <h4> You will be asked to approve the transaction for each batch</h4>
                            </div>
                        </div>
                    </div>
                }
                {this.state.isSuccessAuction &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully listed for auction! Good luck!</b></div>
                            <div className="mt-3">It may take 30 seconds for your listed auction art to appear in drops :)</div>

                            {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drops</button></div></div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>Go To My Collection</button></div></div>

                }
                {this.state.isArtStatusChangeSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Successfully changed art's visibility status</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your listed auction art to appear in drops :)</div> */}

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetStatusPage() }}>Close</button></div></div>

                }

                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} alt="" width="170px" />}
                            {
                                this.state.isListingAborted === false && <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            }
                            {
                                this.state.isListingAborted === true &&
                                <i className="fas fa-exclamation-triangle fa-5x text-warning"></i>
                            }
                            {
                                this.state.isListingAborted
                                    ? <div className="mt-3">
                                        <p>
                                            <b>Transaction aborted</b>
                                        </p>
                                        <p>
                                            Transaction has been aborted because {this.state.listingAbortError !== ''
                                                ? <span>{this.state.listingAbortError}</span>
                                                : <span>of an error</span>
                                            }. {this.state.tokensListedBeforeAbort} editions have been listed.
                                        </p>
                                    </div>
                                    : <div className="mt-3"><b>Woohoo..! Successfully listed for sale! Good luck!</b></div>
                            }
                            <div className="mt-3">It may take 30 seconds for your listed art to appear in drops :)</div>

                            {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drops</button></div></div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resettoDropPage() }}>Go to Drops</button></div></div>

                }
                {this.state.isAirDropSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully listed for Air Drops! Good luck!</b></div>
                            <div className="mt-3">It may take 30 seconds for your listed art to appear in Air drops :)</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.redirectToAirDrops() }}>Go to Air Drops</button></div></div>

                }

                {this.state.isList && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">{this.state.loaderMes}</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />

                        </div>
                    </div>
                }
                {this.state.isAuction && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing for auction</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div>
                }
                {this.state.isAirDrop && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing for air drops</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div>
                }
                {/* {this.state.isThumbnailUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated thumbnail for your art!</b></div>
                            <div className="mt-3">Changes will appear in your collection :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterUpdateThumbnail() }}>Close</button></div></div>

                } */}
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        isListPage={this.isListPage}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                    : ''}
                {this.state.isCategoryUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated category for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterCategory() }}>Close</button></div></div>

                }
                {this.state.isTagUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated tag for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterTag() }}>Close</button></div></div>

                }

                {
                isConnectWallet() ? 
                (!(isArtDisabled) ? 
                <div ref={this.scrollRef}>
                <div className="detailWallet mb-5">
                    <JoyRide start={checkOwnListingAuctionPage()} isScroll={false} page="list_auction_page" />
                    <div className="detasingimg detailMyWallet">
                        <div className="container">
                            <span className="detaimg text-center">
                                <ArtViewPreview src={data && data.imageHash} alt="" className="mw-100" showControls={true} autoPlay={true} isDetail={true} isPremiumArt={data && data.isPremiumArt} mimeType={data && data.imageMimeType} sharedData={data} />
                            </span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                {/* {(this.isArtisMusicCategory(data)) ? '' :  */}
                                <div className="badgeview propicdetail mb-3 mb-sm-0">
                                    <div className="picpro">
                                        <span className="artcovimg">
                                            <span className="artcov "></span>
                                            <span className="artcovimginset no-proimg ">
                                                {getCreatorImage(data)}
                                            </span>
                                        </span>

                                    </div>
                                    {getCreatorName(data)}
                                </div>
                                {/* } */}
                            </div>
                            <div className="col-12 col-sm-6 mobrevdiv">

                                <div className="badgeview hideshowart mr-2 float-right">

                                    <h2 className="usernameClick" >

                                        {<span className='hideshowart'> <span className='mr-2 span-cursor'>Visibility</span>
                                            <a className={'card flowtop'}><Toggle checked={this.isArtVisible(data)} onChange={() => this.statusChange(data)} />
                                                {<span className="copycon">
                                                    <span className="uparrow"></span>
                                                    {(this.isArtVisible(data)) ? ("Hide Art") : ("Show Art")}
                                                </span>} </a>
                                            <span className='ml-2 small usernamelilac span-cursor'>{(this.isArtVisible(data)) ? `[Visible]` : '[Hidden]'}</span></span>}
                                    </h2>


                                </div>
                            </div>


                        </div>
                    </div>
                    {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.redirectToAirDrops() }}>Go to Air Drops</button> */}
                    <div className="container dmwsec">
                        
                        <div className="card1">
                            <div className="card-body1">
                                <div className="det-head"></div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="dettittxt mb-3"><h2 className="py-0 ">{data && data.title ? data.title : ''} &nbsp;{(data && data.isBab) ? <span className='ownerDec'>@Jim Jones</span> : <span className="ownerDec" onClick={() => this.gotoCreator(data && data.creator)}>{data && data.creator && data.creator.fullName && `by ${data.creator.fullName}`}</span>}</h2></div>
                                        <div className="dettittxt mb-3">
                                            <h4 className="py-0 ">{data && data.description}</h4>
                                        </div>
                                        {(data && data.tags && data.tags.length > 0) ? <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="probtns activebtnscus py-0 custagbadge">
                                                    <ul className="list-unstyled mb-0 ">
                                                        {data && data.tags && data.tags.map((items) => (

                                                            <li>

                                                                <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "badge badge-dark btn-sm active cushashtag" : 'badge badge-dark badge-sm cushashtag'} onClick={() => { this.gotoTagView(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button>
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> : ''}
                                        <div className='mb-3'>
                                            {(data && data.nftCategory && data.nftCategory.name) ?
                                                <span className='category-btn mr-2  align-items-center category_art' onClick={() => this.gotoCategoryPaga(data.nftCategory)}><i className={data.nftCategory && data.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {data.nftCategory && data.nftCategory.name.charAt(0).toUpperCase() + data.nftCategory.name.slice(1)}</span>
                                                : ''}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.imageHash && data && data.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View On IPFS</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.metadataJsonUrl && data && data.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View Metadata</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.flowScanURL && data.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View on Flow Scan</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            {(this.isFirstSale()) ?
                                                <span className='category-btn mr-2  align-items-center' style={{ cursor: "default" }}>Royalty for this NFT - {this.state.resale_royalty}%</span>
                                                : ''}
                                        </div>
                                        {(this.isOwnerisCreator(data)) ? <div className="prpabout">
                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="card-header update_category_art" id="headingOne1">

                                                        <button className="btn btn-dark-cus btn-block text-left accordicotxt" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                            <span className='accordicon mr-1'><i className='fa fa-pencil usernamelilac'></i></span>
                                                            <span className='accordtxt'>Update Category/Tags</span>
                                                            <span className='accordicon ml-1 acdarrow'><i className='fa fa-angle-down'></i></span>
                                                        </button>

                                                    </div>
                                                    <div id="collapseOne1" className="collapse show" aria-labelledby="headingOne1" >
                                                        <div className="card-body">
                                                            <EditCategory updateError={(value) => { this.updateError(value) }} data={data} updateCategorySuccess={() => { this.updateCategorySuccess() }} />
                                                            <EditTag updateError={(value) => { this.updateError(value) }} data={data} updateTagSuccess={() => { this.updateTagSuccess() }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> : ''}


                                        {/* {(this.isOwnerisCreator(data)) ? <UpdateThumbnail updateError={(value) => {this.updateError(value)}} data={data} updateThumbnailSuccess={() => {this.updateThumbnailSuccess()}}/> : ''} */}

                                    </div>
                                    <div className="col-md-6">
                                        <div className="det-align">
                                            <Tabs className="tab-wrap" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabChange(tabIndex)}>

                                                <TabList className="tab-change">
                                                    <Tab className={this.state.tabIndex === 0 ? "tab-select sell_art" : 'sell_art'}>
                                                        <h5 className={this.state.tabIndex === 0 ? '' : ''}>Sell
                                                        </h5>
                                                    </Tab>

                                                    <Tab className={this.state.tabIndex === 1 ? "tab-select airdrop_art" : 'airdrop_art'} >
                                                        <h5 className={this.state.tabIndex === 1 ? '' : ''}>Airdrop</h5>
                                                    </Tab>

                                                </TabList>

                                                <TabPanel>
                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="equalheight">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className='purchasebox'>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="purchasebox1">
                                                                                <label className="mb-3" htmlFor="editionsforsale" >Editions for sale</label>
                                                                                <input id="editionsforsale" name="editionsforsale" type="text" value={this.state.count} onChange={this.handleCount} className="form-control mw-200 text-right" />
                                                                                {this.state.submitted && !this.state.count && <div className="mandatory mt-2">{"Please enter your valid tokens edition for listing"}</div>}
                                                                                {!this.state.submitted && !this.state.count && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${this.state.countLimit} (must be whole number)`}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <div className="purchasebox1 mh100sb py-3 py-md-0">
                                                                                <label className="mb-2">Wallets</label>

                                                                                {this.state.walletArray && this.state.walletArray.length > 0 && this.state.walletArray.map((items, index) => (
                                                                                    <div className='row  mb-2'>
                                                                                        <span className="fw-bold usernamelilac col-9">{items && items.walletAddress}</span>
                                                                                        <span className='col-3 text-right'>{items && items.editionCount}</span>
                                                                                    </div>
                                                                                ))}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : ''}

                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="purchasebox">
                                                                    <label htmlFor="price" className="mb-3">Price</label>
                                                                    <div className="input-group dollorsec">
                                                                        <input id="price" name="price" type="text" value={this.state.price} onChange={this.handlePriceChange} className="form-control mw-200 text-right" />
                                                                        {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className="input-group-append ">
                                                                            <span className="input-group-text bg-white" id="basic-addon2">USD</span>
                                                                        </div> :
                                                                            <div className="input-group-append ">
                                                                                <span className="input-group-text bg-white" id="basic-addon2">USD</span>
                                                                            </div>}



                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-6 ">
                                                                <h5 className="mb-3">List Price</h5>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <h5 className="" title={(this.state.price)}>
                                                                    {(Number(this.state.price) ? (
                                                                        <div className="usernamelilac">
                                                                            {this.addZeroes(this.state.price) + ' USD'} &nbsp;
                                                                            {/* {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (this.state.price))))}>{(flowDecimal(parseFloat((this.state.rate) * (this.state.price)))) + ' USD'}</span>)} */}
                                                                        </div>)
                                                                        : <div className="usernamelilac">0.00 USD</div>)}</h5>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-3">Marketplace Fee {(this.state.market_Fee + "%")}</h5>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                                <h5 className="" title={mark_share}>
                                                                        {(this.state.price && mark_share) ?
                                                                            <div className="usernamelilac">
                                                                                {this.addZeroes(mark_share) + ' USD'} &nbsp;
                                                                                {/* {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (mark_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (mark_share)))) + ' USD'}</span>)} */}

                                                                            </div>
                                                                            : <div className="usernamelilac">0.00 USD</div>}</h5>
                                                            </div>
                                                            {this.isFirstSale() ? '' : <div className="col-6">
                                                                <h5 className="mb-3">Royalty {(this.state.artOwner === this.state.artCreator) ? ("0%") : (this.state.resale_royalty + "%")} to <span className='usernamelilac cursor-pointer' onClick={() => this.gotoCreator(data && data.creator)}>@{this.state.artCreator}</span></h5>
                                                            </div>}
                                                            {this.isFirstSale() ? '' : <div className="col-6 text-right">
                                                                <h5 className="" title={(this.state.artOwner !== this.state.artCreator) ? crt_share : "0"}>
                                                                    {(this.state.artOwner !== this.state.artCreator) ?
                                                                        (this.state.price && crt_share) ?
                                                                            <div className="usernamelilac">
                                                                                {this.addZeroes(crt_share) + ' USD'} &nbsp;
                                                                                {/* {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (crt_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (crt_share)))) + ' USD'}</span>)} */}

                                                                            </div>
                                                                            :
                                                                            <div className="usernamelilac">0.00 USD</div> : <div className="usernamelilac">0.00 USD</div>}</h5>
                                                            </div>}
                                                            <div className="col-6">
                                                                <h5 className="mb-3">Net Earnings</h5>
                                                            </div>
                                                            <div className="col-6 text-right">
                                                            
                                                                <h5 className="" title={(this.state.artOwner !== this.state.artCreator) ? sell_share : (parseFloat(sell_share) + parseFloat(crt_share))}>
                                                                {(this.state.artOwner !== this.state.artCreator) ?
                                                                        (Number(this.state.price) && sell_share) ?
                                                                            <div className="usernamelilac">
                                                                                {this.addZeroes(sell_share) + ' USD'} &nbsp;
                                                                                {/* {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (mark_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (mark_share)))) + ' USD'}</span>)} */}

                                                                            </div>
                                                                            : <div className="usernamelilac">0.00 USD</div>
                                                                        : (sell_share && crt_share && this.state.price) ?
                                                                            <div className="usernamelilac">
                                                                                {(this.addZeroes((parseFloat(sell_share) + parseFloat(crt_share))) + ' USD')} &nbsp;
                                                                                {/* {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * ((parseFloat(mark_share) + parseFloat(crt_share))))))}>{(flowDecimal(parseFloat((this.state.rate) * ((parseFloat(mark_share) + parseFloat(crt_share)))))) + ' USD'}</span>)} */}

                                                                            </div>
                                                                            : <div className="usernamelilac">0.00 USD</div>}</h5>
                                                            </div>

                                                        </div>
                                                    </div>



                                                    <hr className="my-3 d-block" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Art listed for sale will not appear in drops."}</div>}
                                                            {/* <button type="button" className="btn btn-primary mr-3 px-3" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button> */}
                                                            {/* <button type="button" className="btn btn-primary px-5" onClick={() => this.handleSale((data && data.value && data.value.fields && data.value.fields[1] && data.value.fields[1].value && data.value.fields[1].value.value))}>LIST ON SALE</button> */}
                                                            <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleSale((data && data.tokenId)) }}>List For Sale</button>
                                                        </div>

                                                    </div>}

                                                </TabPanel>
                                                {/* <TabPanel>

                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="endDays">Duration</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.endDays} name="endDays" id="endDays" onChange={this.handleActionChange} />
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">Days</span>
                                                            </div>
                                                        </div>
                                                        {!this.state.isSubmitAuction && <div className="mandatory-req">Min 1 Day & Max 30 Days</div>}
                                                        {this.state.isSubmitAuction && !this.state.endDays && <div className="mandatory">Please enter proper no.of days</div>}
                                                        {!this.state.isSubmitAuction && this.state.endDays && !this.checkNumber(parseInt(this.state.endDays)) && <div className="mandatory">must be an integer</div>}
                                                        {this.state.isSubmitAuction && this.state.endDays && (this.state.endDays === 0) && <div className="mandatory">Must be atleast 1 day and atmost 30 days</div>}

                                                    </div>
                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="startPrice">Starting Price</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.startPrice} name="startPrice" id="startPrice" onChange={this.handleBidPrice} />
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                            </div>
                                                        </div>
                                                        {(this.state.rate) && (this.state.startPrice) && <div className="small text-white text-right mw-200 py-1">{(flowDecimal(parseFloat(((this.state.rate) * (this.state.startPrice))))) + ' USD'}</div>}
                                                        {this.state.isSubmitAuction && !this.state.startPrice && <div className="mandatory">Starting Price Required</div>}
                                                        {this.state.isSubmitAuction && this.state.startPrice && (this.state.startPrice === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                    </div>
                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="minBid">Minimum Bid Increment</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.minBid} name="minBid" id='minBid' onChange={this.handleMinBid} />

                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                            </div>

                                                            {this.state.isSubmitAuction && !this.state.minBid && <div className="mandatory">Minimum Bid Increment Required</div>}
                                                            {this.state.isSubmitAuction && this.state.minBid && (this.state.minBid === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                        </div>
                                                        {(this.state.rate) && (this.state.minBid) && <div className="small text-white text-right mw-200 py-1">{(flowDecimal(parseFloat(((this.state.rate) * (this.state.minBid))))) + ' USD'}</div>}
                                                        {this.state.isSubmitAuction && !this.state.minBid && <div className="mandatory">Minimum Bid Increment Required</div>}
                                                        {this.state.isSubmitAuction && this.state.minBid && (this.state.minBid === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                    </div>
                                                    {this.percentageShower()}

                                                    
                                                    <hr className="my-3 d-block" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Art listed for auction will not appear in drops."}</div>}
                                                            <button type="button" className="btn btn-primary px-3" onClick={this.listToAuction} disabled={this.state.disableList}>Create Auction</button>
                                                        </div>
                                                    </div>}

                                                </TabPanel> */}
                                                <TabPanel>
                                               
                                                    
                                                    
                                                    <div className="purchasebox overflow-visible" >

                                                        <div className="row">

                                                            <div className="col-md-6 mb-3">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                                <div>
                                                                    <label className="mb-3" htmlFor="airdropCount" >Editions for air drop</label>
                                                                    <input id="airdropCount" name="airdropCount" type="text" value={this.state.airdropCount} onChange={this.handleCountforAirdrop} className="form-control text-right" />

                                                                    {this.state.submitted && !this.state.airdropCount && <div className="mandatory mt-2">{"Please enter your valid tokens edition for air drops"}</div>}

                                                                    {((this.state.airdropCount.toString()) === '') && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${this.state.AirdropcountLimit} (must be whole number)`}</div>}
                                                                    {this.state.airdropCount && <div className="mandatory-req ">{`Min 1 - Max ${this.state.infoLimit} Editions`} </div>}


                                                                </div>
                                                                <div className="" onKeyDown={(e) => e.preventDefault()}>
                                                                    <label className="mb-3 mt-3" htmlFor="onstart" >Airdrop starts from</label>
                                                                    <DatePicker
                                                                        value={this.state.onstart}
                                                                        dateFormat="DD-MM-YYYY"
                                                                        timeFormat="hh:mm A"
                                                                        onChange={(val) => this.handleOnstart(val)}
                                                                        isValidDate={this.validation}
                                                                        initialViewDate={new Date()}

                                                                    />
                                                                    {this.state.submitted && !this.state.onstart && <div className="mandatory ">{"Please select your time for launch"}</div>}
                                                                    <br />
                                                                </div>
                                                                {(this.state.selectedAirdropOption === 4) ?
                                                                    <div className="" onKeyDown={(e) => e.preventDefault()}>
                                                                        <label className="mb-3" htmlFor="onend" >Airdrop ends by</label>
                                                                        <DatePicker
                                                                            value={this.state.onend}
                                                                            dateFormat="DD-MM-YYYY"
                                                                            timeFormat="hh:mm A"
                                                                            onChange={(val) => this.handleOnend(val)}
                                                                            isValidDate={this.validationForEndDate}
                                                                            initialViewDate={new Date()}
                                                                            inputProps={{ disabled: !(this.state.onstart) }}

                                                                        /> <br />
                                                                        {/* {this.state.submitted && !this.state.onend && <div className="mandatory mt-2">{"Please select your time for close"}</div>} */}
                                                                    </div>
                                                                    : ''}
                                                                {(this.state.selectedAirdropOption === 5) ?
                                                                    <div className=''>
                                                                        <label className="mb-3 mt-2" >Event name</label>
                                                                        <input
                                                                            value={this.state.eventName}
                                                                            name="eventName"
                                                                            onChange={this.handleEventName}
                                                                            placeholder='Event name'
                                                                            className='form-control'
                                                                        /> 
                                                                        {this.state.submitted && !this.state.eventName && <div className="mandatory">{"Event name Required"}</div>}
                                                                        {!this.state.submitted && <div className="mandatory-req">{`Min ${this.eventNameMinLength} - Max ${this.eventNameMaxLength}`}</div>}
                                                                        {this.state.submitted && this.state.eventName !== '' && !this.checkLengthValue(this.state.eventName,this.eventNameMinLength,this.eventNameMaxLength) && <div className="mandatory">{`Min ${this.eventNameMinLength} - Max ${this.eventNameMaxLength}`}</div>}

                                                                        <br />
                                                                        

                                                                    </div>
                                                                    
                                                                    : ''}
                                                                    {(this.state.selectedAirdropOption === 5) ?
                                                                    <div className="" >
                                                                        <label className="mb-3" >Event remarks</label>
                                                                        <textarea
                                                                            value={this.state.eventDescription}
                                                                            name="eventDescription"
                                                                            onChange={this.handleEventDescription}
                                                                            placeholder='Event description'
                                                                            className='form-control'
                                                                        /> 
                                                                        {!this.state.submitted && this.state.eventDescription !== '' && <div className="mandatory-req">{`Min ${this.eventDescMinLength} - Max ${this.eventDescMaxLength}`}</div>}
                                                                        {this.state.submitted && this.state.eventDescription !== '' && !this.checkLengthValue(this.state.eventDescription,this.eventDescMinLength,this.eventDescMaxLength) && <div className="mandatory">{`Min ${this.eventDescMinLength} - Max ${this.eventDescMaxLength}`}</div>}
                                                                        <br />
                                                                    </div>
                                                                    
                                                                    : ''}
                                                                

                                                               
                                                            </div>


                                                            <div className="col-md-6">

                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck2" checked={this.state.selectedAirdropOption === 2} onChange={() => this.handleMembersChecked(this.state.selectedAirdropOption)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck2">Selected members only</label>

                                                                    {(this.state.selectedAirdropOption === 2) ? <div><textarea className="form-control mt-3" value={this.state.member} onChange={this.handleSelectedMembers} disabled={!(this.state.selectedAirdropOption === 2)}></textarea>

                                                                        <div className="usernamelilac small mt-1 word-break-word">Enter username or email as comma separated values (For ex. kitty,robert,johndoe@example.com,janedoe@example.com)</div>
                                                                        {this.state.submitted && (this.state.selectedAirdropOption === 2) && !this.usernameValidation(this.state.member) && <div className="mandatory mt-2">{"Please enter proper username. Username has only contain alphabets,numbers and atleast 4 characters long. or Enter proper email address"}</div>}<br />
                                                                        {this.state.member && <div className="comboxgray">
                                                                            {this.state.member && this.state.member.split(',').map(items => (
                                                                                <a className="badge bg-dark mr-1 p-2" >
                                                                                    {this.checkIsEmail(items) ?
                                                                                        <span>
                                                                                            <i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;{items}
                                                                                        </span>
                                                                                        :
                                                                                        `@${items}`}</a>)
                                                                            )}
                                                                        </div>}
                                                                    </div> : ''}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck3" checked={this.state.selectedAirdropOption === 3} onChange={() => this.handleImported(this.state.selectedAirdropOption)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck3">Import members </label>


                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck4" checked={this.state.selectedAirdropOption === 4} onChange={() => this.handleLocationCheck(this.state.selectedAirdropOption)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck4">Airdrop to a location </label>


                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck1" checked={this.state.selectedAirdropOption === 1} onChange={() => this.handleChecked(this.state.selectedAirdropOption)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck1">Any first coming followers can claim (Not Recommended)</label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck5" checked={this.state.selectedAirdropOption === 5} onChange={() => this.handleEventUsers(this.state.selectedAirdropOption)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck5">Disrupt custodial</label>
                                                                </div>
                                                            </div>
                                                            {(this.state.selectedAirdropOption === 3) ?
                                                                <div className="col-md-12 dmwbtns">
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <button className="btn btn-primary-fill w-100" type='button' onClick={this.downloadCSV}>Download sample file &nbsp; <i className="fa fa-download" aria-hidden="true"></i></button>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <input type="file" id="ufile" name="image" onChange={this.handleSelectCSV} className="inp-box" accept=".csv" />
                                                                            <label htmlFor="ufile" className="btn btn-primary-fill w-100">Upload members &nbsp; <i className="fa fa-upload" aria-hidden="true"></i></label>
                                                                            <div className="usernamelilac small mt-1">Maximum upload file size limit 1MB</div>
                                                                            <div className="usernamelilac small mt-1">Only 10 records are allowed in import</div>

                                                                            <div className="col-md-6"><div className="mandatory-req text-white">{this.state.localImageName}</div></div>

                                                                        </div>
                                                                    </div>
                                                                </div> : ''}
                                                            {(this.state.selectedAirdropOption === 4) ?
                                                                <MapComponent fetchMapCoordinates={(values) => { this.fetchMapCoordinates(values) }}

                                                                /> : ''}

                                                        </div>
                                                        <hr className="my-3 d-block" />
                                                        <div className="form-group">
                                                        <input type="checkbox" className="form-check-input mr-3" id="alertCheck" checked={this.state.isAlertOff} onChange={this.handleSendAlertToUsers} />
                                                        <label className="form-check-label" htmlFor="alertCheck">Send email alert to collectors</label>
                                                        </div>
                                                   
                                                    </div>




                                                    <hr className="my-3 d-block" />
                                                    <div className="row">
                                                        {this.isCurrentOwnerIsCreator(data) ? <div className="col-md-12 text-center">
                                                            <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleAirDrop((data && data.tokenId)) }} >Schedule</button>
                                                        </div> : <div className='col-md-12 text-center usernameblue'>Sorry for now, only creator of this art has the ability to initiate an airdrop</div>}

                                                    </div>

                                                </TabPanel>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div> : <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="error-template userdisableview">
                                <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                                {(disabledMessage && disabledMessage.includes('admin')) ? <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4> : ''}

                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="mb-0">The Team</p> : ''}
                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="usernamelilac">DisruptArt</p> : ''}


                            </div>
                        </div>

                    </div>
                </div>) 
                : <CommonWalletConnectUI connectMyWallet={this.connectMyWallet} isMyCollection />
            }
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        wallet: state.wallet.wallet,
        thumbnailUpdatSuccess: state.art.thumbnailUpdated,
        uploadThumbnailFailed: state.art.uploadThumbnailFailed,
        artVisibilityStatusChangedFailed: state.art.artStatusChangedFailed,
        artVisibilityStatusChangesSuccess: state.art.artStatusChangedSuccess,
        listedArt: state.art.dapperlistedArtForsale,
        listedArtFailed: state.art.dapperlistedArtSaleFailed,

        eventInitiateSuccess: state.transactions.eventInitiated,
        eventInitiatefailed: state.transactions.eventInitiatedFailed,

        eventCancelledsuccess: state.transactions.eventCancelled,
        eventCancelledFailed: state.transactions.eventCancelledFailed,

        eventFailureSuccess: state.transactions.eventFailured,
        eventFailureFailed: state.transactions.eventFailuredFailed,

        liststagesucess: state.transactions.listStaged,
        liststageFailed: state.transactions.listStagedFailed,

        transferSuccess: state.transactions.eventTransferSuccess,
        transferFailed: state.transactions.eventTransferFaied,

        listEventSuccess: state.transactions.listsuccess,
        lisEventFailed: state.transactions.listFailed,

        walletDetailsuccess: state.art.walletAddDetail,
        walletDetailFailed: state.art.walletAddDetailFailed,

        walletRelatedArtId: state.art.walletRelatedArts,
        walletRelatedArtIdFailed: state.art.walletRelatedArtsFailed


    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    updateThumbnail: artActions.updateThumbnailnft,
    resetUploadSuccess: artActions.resetUpdateThumbnailSuccess,
    resetUpdateThumbnailFailed: artActions.resetUpdateThumbnailFailed,
    updatevisibilityStatus: artActions.artsVisibilitystatusChange,
    resetVisibityStatusSuccess: artActions.resetArtvisibilitySuccess,
    resetVisibilityStatusFailed: artActions.resetArtVisibilityFailed,
    listedArtForSale: artActions.dapperListArtForSale,
    resetListSuccess: artActions.resetDapperListArtForSale,
    resetListFailed: artActions.resetDapperArtForSaleFailed,
    forsaleInitiate: txnActions.forsaleEventHistoryInitiate,
    resetForsaleInitiate: txnActions.resetEventHistoryInitiated,
    resetForsaleInitiateFailed: txnActions.resetEventHistoryInitiatedFailed,
    forsaleEventCancelled: txnActions.forsaleEventHistoryCancelled,
    resetEventCancel: txnActions.resetEventCancelled,
    resetEventCancelFailed: txnActions.resetEventCancelledFailed,
    forsaleEventFailure: txnActions.forsaleEventHistoryFailure,
    resetEventFailured: txnActions.resetEventFailure,
    resetEventFailuredFailed: txnActions.resetEventFailureFailed,
    forsaleTransfetSuccess: txnActions.forsaleEventHistoryTransferSuccess,
    resetTransferSuccess: txnActions.resetEventTransaferSuccess,
    resetTransferFailed: txnActions.resetEventTransferFailed,
    forsalelistStage: txnActions.forsaleEventHistoryListStage,
    resetliststagesuccess: txnActions.resetlistStageEvent,
    resetliststagefailed: txnActions.resetlistStageEventFailed,
    forsalelistsuccess: txnActions.forsaleEventHistorylistSuccess,
    resetListEventSuccess: txnActions.resetlistsuccess,
    resetListEventFailed: txnActions.resetlistfailed,
    getWalletAddDetail: artActions.getWalletAddressDetail,
    resetGetWalletAddDetail: artActions.resetgetWalletAddressDetail,
    resetGetWalletAddDetailFailed: artActions.resetgetWalletAddressDetailFailed,
    getwalletRelatedArtIds: artActions.getArtsForRelatedWallet,
    resetWalletArtIds: artActions.resetGetArtsForRelatedWallet,
    resetWalletArtIdsFailed: artActions.resetGetArtsForRelatedWalletFailed

}
const connectDapperListing = connect(mapStateToProps, actionCreators)(DapperListing)
export { connectDapperListing as DapperListing }
