import { creatorConstants } from "../../constants";

const initialState = {
    creatorsList : [],
    detailCreator : [],
    views : []
}
export function creator(state=initialState,action){
    switch(action.type){
        case creatorConstants.GET_CREATORS:
            return {
                creatorsList : action.creatorsList
            }
            case creatorConstants.GET_DETAIL_CREATOR:
                return {
                    detailCreator : action.detailCreator
                }
                case creatorConstants.VIEW_COUNT:
                    return {
                        views : action.views
                    }
            default:
                return state
    }
}