import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class Faq extends Component {
	render() {
		return (
			<Fragment>
				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="w-auto  text-center">Frequently Asked Questions</h1>
							</div>
						</div>
					</div>
					<hr />
					<div className='purchasebox text-center'>
						<h5 className='usernamelilac'>For Immediate Questions Ask on Discord.</h5>
						<h4><a href='https://discord.io/disruptart' target={"_blank"}>https://discord.io/disruptart</a></h4>
					</div>

					<div id="accordion" className='statcontsec'>
						<div className="card">
							<div className="card-header p-0" id="headingOne">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										What is Disrupt Art?
									</button>
								</h5>
							</div>

							<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
								<div className="card-body">
									The Disrupt Art Platform is an ecosystem where artists, activists, and allies intersect to revolutionize the impact of art and build a more equitable and sustainable future. Every digital Artwork available through Disrupt Art is a unique and authenticated digital creation, verified by the creator/collector through blockchain technology.
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header p-0" id="headingTwo">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										What are NFTs?
									</button>
								</h5>
							</div>
							<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
								<div className="card-body">
									NFTs are “Non-Fungible Tokens”, unique tokens backed by Blockchain technology. These tokens can represent a unique good or asset. Often, they are called “crypto-collectibles”, because much like trading cards, NFTs can be bought and sold by collectors. NFTs also provide scarcity and demand which increases their value. The NFT Revolution has the potential to open up more opportunities for Equity with Diverse Creators.
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header p-0" id="headingThree">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										What is the Flow Blockchain?
									</button>
								</h5>
							</div>
							<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
								<div className="card-body">
									Flow is a fast, decentralized, and developer-friendly blockchain.<br />
									More info at https://www.onflow.org/primer
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header p-0" id="heading4">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
										Who owns the Flow Blockchain?
									</button>
								</h5>
							</div>
							<div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
								<div className="card-body">
									Flow is the product of Dapper Labs, the creators of CryptoKitties, Flow, and NBA Top Shot.
								</div>
							</div>
						</div>


						<div className="card">
							<div className="card-header p-0" id="heading5">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
										How does the Flow Blockchain work?
									</button>
								</h5>
							</div>
							<div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#accordion">
								<div className="card-body">
									Flow applies a pipeline to blockchains by separating the jobs of a validator node into four different roles: Collection, Consensus, Execution, and Verification. In other words, every validator node still participates in the validation of every transaction, but they do so only at one of the stages of validation.
									Find out more at https://www.onflow.org/primer
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header p-0" id="heading6">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
										What can NFTs do?
									</button>
								</h5>
							</div>
							<div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
								<div className="card-body">
									Make digital art rare and collectible by using blockchain technology. NFTs can be tickets, collectibles, unlockable content, videos, photographs, animations and so much more.
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header p-0" id="heading7">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
										What Do You Get When You Buy an NFT?
									</button>
								</h5>
							</div>
							<div id="collapse7" className="collapse" aria-labelledby="heading7" data-parent="#accordion">
								<div className="card-body">
									When you buy an NFT, you receive a unique token representing the digital artwork. Ownership is then recorded. Owning the work doesn’t restrict how the work can be seen or shared; instead, it works more like an interactive trading card. NFTs can be distributed and traded among collectors, rising in value in the process. A buyer or collector has ownership of the NFT just like owning something purchased at a physical market. However, with the Disrupt Art platform, the Creator earns a percentage of every secondary sale. Every time the artwork sells the Creator receives payment.
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header p-0" id="heading8">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
										Can't Someone Copy My Art and Sell It?
									</button>
								</h5>
							</div>
							<div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#accordion">
								<div className="card-body">
									No. That is the power of NFTs. The use of Blockchain technologies keeps track of ownership without limiting an artwork’s ability to freely circulate in the digital realm. We believe Black and Brown Artists should be properly compensated for their work. With NFTs, Artists are empowered to sell digital artworks as unique objects, without limiting the ability for that artwork to move through networks. The secondary trading of digital art using NFTs makes things even more promising for Artists. As an artwork gains traction, the original creator/collector can choose to resell it to another collector for a higher price. Our model is that every time an NFT is traded on Disrupt Art, the Artist makes a percentage of that sale. This opens up more opportunities for diverse Artists to receive the Equity they deserve.
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header p-0" id="heading9">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
										Are NFTs Valuable in the Art World?
									</button>
								</h5>
							</div>
							<div id="collapse9" className="collapse" aria-labelledby="heading9" data-parent="#accordion">
								<div className="card-body">
									From 2019 to 2020, the market capitalization of the NFT space rose 50% from $210 million to $315 million, with over $167.7 million worth of NFTs purchased up until January 2021. Robert Alice’s Block 21—an NFT work based on Blockchain technology was recently sold at a Christie’s auction for 69 million dollars. Hundreds of millions of transactions have occurred as of 2020 and even the NBA has gotten in on the action. This is just the beginning and Disrupt Art is making it easy to get started.
								</div>
							</div>
						</div>

						<div className="card">
							<div className="card-header p-0" id="heading10">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
										Where can I buy NFTs?
									</button>
								</h5>
							</div>
							<div id="collapse10" className="collapse" aria-labelledby="heading10" data-parent="#accordion">
								<div className="card-body">
									Here on Disrupt Art! Disrupt Art is an NFT Platform on the Flow Blockchain where you can buy and sell NFTs. NFTs can be bought and traded on over 100 platforms and mostly on the Ethereum Blockchain. Such sites include SuperRare, Rarible, MakersPlace, and Nifty Gateway.
								</div>
							</div>
						</div>


						<div className="card">
							<div className="card-header p-0" id="heading11">
								<h5 className="mb-0">
									<button className="btn btn-primary w-100 text-left collapsed" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
										Will NFTs last?
									</button>
								</h5>
							</div>
							<div id="collapse11" className="collapse" aria-labelledby="heading11" data-parent="#accordion">
								<div className="card-body">
									The average value of NFTs tends to fluctuate over time. Since the NFT market is so new the data on it doesn’t go back very far. This means it’s tough to predict due to the low amount of market data.
								</div>
							</div>
						</div>

					</div>

				</div>


			</Fragment>
		)
	}
}
export { Faq }