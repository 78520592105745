export const artConstants = {
    UPLOAD_ART: 'UPLOAD_ART',
    GET_ARTS: 'GET_ARTS',
    GET_DETAIL_ART: 'GET_DETAIL_ART',
    GET_NOT_DROPS: 'GET_NOT_DROPS',
    GET_ART_BY_TOKEN: 'GET_ART_BY_TOKEN',
    GET_REALATIVE_ARTS: 'GET_REALATIVE_ARTS',

    GET_USER_ARTS: 'GET_USER_ARTS',
    GET_ONE_USER_ARTS: 'GET_ONE_USER_ARTS',
    FAILED_UPLOAD_ART: 'FAILED_UPLOAD_ART',
    GET_CREATOR_ARTS: 'GET_CREATOR_ARTS',
    GET_ALL_ARTS: 'GET_ALL_ARTS',
    ART_STATUS_CHANGE: 'ART_STATUS_CHANGE',

    GET_OWNER_ARTS: 'GET_OWNER_ARTS',
    GET_ART_FOR_TAG: 'GET_ART_FOR_TAG',

    GET_CATEGORY_ARTS : 'GET_CATEGORY_ARTS',
    GET_CATEGORY_DETAIL : 'GET_CATEGORY_DETAIL',

    EDIT_THUMBNAIL : 'EDIT_THUMBNAIL',
    EDIT_THUMBNAIL_FAILED : 'EDIT_THUMBNAIL_FAILED',

    SHOW_HIDE_STATUS_ARTS_CHANGE : 'SHOW_HIDE_STATUS_ARTS_CHANGE',
    SHOW_HIDE_STATUS_CHANGE_FAILED : 'SHOW_HIDE_STATUS_CHANGE_FAILED',

    EDIT_CATEGORY : 'EDIT_CATEGORY',
    EDIT_CATEGORY_FAILED : 'EDIT_CATEGORY_FAILED',

    EDIT_TAGS : 'EDIT_TAGS',
    EDIT_TAGS_FAILED : 'EDIT_TAGS_FAILED',

    DAPPER_LIST_ARTS_FOR_SALE : 'DAPPER_LIST_ARTS_FOR_SALE',
    DAPPER_LIST_ARTS_FOR_SALE_FAILED : 'DAPPER_LIST_ARTS_FOR_SALE_FAILED',

    GET_ARTS_FOR_RELATED_WALLET : 'GET_ARTS_FOR_RELATED_WALLET',
    GET_ARTS_FOR_RELATED_WALLET_FAILED : 'GET_ARTS_FOR_RELATED_WALLET_FAILED',

    GET_WALLET_ADDRESS_DETAIL : 'GET_WALLET_ADDRESS_DETAIL',
    GET_WALLET_ADDRESS_DETAIL_FAILED : "GET_WALLET_ADDRESS_DETAIL_FAILED",

    GET_CUSTODIAL_ARTS : 'GET_CUSTODIAL_ARTS',
    GET_CUSTODIAL_ARTS_FAILED : 'GET_CUSTODIAL_ARTS_FAILED'


}