import {  flowbalance, getUserAddress } from "../utils"


// export async function userAddress() {
//     const address  = await getUserAddress()
//     return address 
    
// }

export async function userBalance(){
    const address  = await getUserAddress() 

    const balance = await flowbalance(address)
    let obj = {
        address,balance
    }
    return obj
   

}