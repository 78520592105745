import React from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { userAction } from "store/actions";
import { ProfileContent } from "./profile-content";
import { ProfileForm } from "./profile-form";
import { Alert } from "helpers/alert";
import history from "helpers/history";
import { localArtPage, localDropPage } from "helpers";

var timeout;
class ProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            userName: '',
            description: '',
            image: '',
            banner: '',
            canEdit: true,
            submitted: false,
        };
    }
    
  
    

    

    render() {
        
        return (
            <div className="profilesec">              
                
                    
                    {
                        // this.state.canEdit ?
                         <ProfileForm     />
                            // : <ProfileContent profile={this.state} />
                    }


                
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        
    }
}

const actionCreators = {
    

}

const connectProfile = connect(mapStateToProps, actionCreators)(ProfilePage)

export { connectProfile as ProfilePage }
