import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { adminActions, eventActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { debounce } from 'utils';


class FetchStores extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            stores: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 10,
            pageCount: '',
            isFetched: false,
            search: '',
            sortBy: 'createdAt',
            sortOrder: 'DESC'
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.allStores && props.allStores.statusCode === 200)) {
            props.resetGetStores()
            return {
                stores: props.allStores && props.allStores.data && props.allStores.data.stores ? props.allStores.data.stores : [],
                totalCount: props.allStores && props.allStores.data && props.allStores.data.count,
                pageCount: props.allStores && props.allStores.data && props.allStores.data.count / state.recordLimit,
                isFetched: true
            }
        }

    }
    handleRefresh = () => {
        this.setState({ offSet: 0,search : '' }, () => {
            this.callApi()
        })
    }


    callApi = () => {
        const { search } = this.state;
        this.setState({ forcePage: this.state.offSet != null ? ((this.state.offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (search) {
            obj.search = search;
        }
       
        this.props.getAllStores(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
        // console.log(e.target.value)
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    
    gotoUser = (items) => {
        if (items && items.initiatedUser && items.initiatedUser.id) {
            const baseurl = process.env.REACT_APP_BASEPATH
            const username = items && items.initiatedUser && items.initiatedUser.userName && encodeURIComponent(items.initiatedUser.userName)

            const navPath = (baseurl + `/admin/userDetail/${items && items.initiatedUser && items.initiatedUser.id}`)
            window.open(navPath, '_blank')
        }
    }
    gotoDetail = (items) => {
        if(items && items.uuid){
            const baseurl = process.env.REACT_APP_BASEPATH
            const id = items.uuid
            const navPath = (baseurl + `/admin/store/${encodeURIComponent(id)}`)
            window.open(navPath, '_blank')
        }
    }
    render() {


        const eventList = this.state.stores && this.state.stores.map((items, index) => {
            return <tbody>
                <tr>
                    <td><a className={'designLink userdescrip_adm'} style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.name} onClick={() => this.gotoDetail(items)}>{items && items.name}</a> <a className={'designLink mr-2 float-right'} onClick={() => this.gotoDetail(items)}><i className="fas fa-external-link-alt"></i></a></td>
                    <td><a className=" userdescrip_adm" style={{ display: 'inline-block', maxWidth: "150px", cursor:'default' }} title={items && items.user && items.user.userName} onClick={() => { this.gotoUser(items) }}>{items && items.user && items.user.userName ? `@${items.user.userName}` : '-'}</a></td>
                    <td><a className="userdescrip_adm" style={{ display: 'inline-block', maxWidth: "150px", cursor:'default' }} title={items && items.uuid}>{items && items.uuid ? items.uuid : "-"}</a></td>
                    <td>{items && items.user && items.user.uuid && items.user.uuid}</td>
                    <td>{(items && items.status) ? "Active" : "In Active"}</td>
                    <td>{moment(items && items.createdAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>


                </tr>

            </tbody>

        })

        
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Stores</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                   
                                    <div className="col-md-8 col-sm-12">

                                        <div className="text-right">
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>STORE NAME</th>
                                        <th>USER NAME</th>
                                        <th>STORE UUID</th>
                                        <th>USER UUID</th>
                                        <th>STATUS</th>
                                        <th>CREATED AT</th>

                                    </tr>
                                </thead>

                                {eventList}

                            </table>
                            {this.state.stores && this.state.stores.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.stores && this.state.stores.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allStores: state.adminaccount.allStores,

    }
}

const actionCreators = {
    getAllStores: adminActions.getStores,
    resetGetStores: adminActions.resetGetStores
}

const connectFetchStores = connect(mapStateToProps, actionCreators)(FetchStores)

export { connectFetchStores as FetchStores }