import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { artActions, categoryActions, dropActions, tagActions, userAction } from 'store/actions'
import history from 'helpers/history';
import { checkMintedTokens, checkTokenGroup, flowDecimal, getUSDValue, localAirdropPage, localDropPage, ReactPagination } from 'helpers'
import { getUserAddress } from 'utils';
import { configPath } from '../config';
import { ArtPreview } from './art-preview/art-preview';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { userServices } from 'services';
import Select from 'react-select';
import { SolarSystemLoading } from 'react-loadingg';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { babMintCount } from './artdetail-view/bab-common-view';
import { JoyRide } from './Joy-ride';
import { checkArtPage } from 'helpers/joy-rideCheck';
export const options = [
    { value: 'DESC', label: 'Most Recent', id: 1 },
    { value: 'ASC', label: 'Oldest', id: 2 },
    { value: 'high', label: 'Rarity : High', id: 3 },
    { value: 'medium', label: 'Rarity : Medium', id: 4 },
    { value: 'low', label: 'Rarity : Low', id: 5 },

];
export const customStyles = {
    control: (base, state) => ({
        ...base,
        minWidth: "200px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
let scrolled = false
let controller = new AbortController();
let isFinish = false

class Gallery extends Component {
    constructor(props) {
        super(props);
        // this.filterTypes = {
        //     1: "Most Recent",
        //     2: "Oldest",
        //     3: "Rarity : High",
        //     4: "Rarity : Medium",
        //     5: "Rarity : Low",
        // }
        // const filtTypes = [];
        // for (const key in this.filterTypes) {
        //     filtTypes.push({
        //         evtName: this.filterTypes[key],
        //         value: parseInt(key)
        //     });
        // }

        this.state = {
            artsList: [],
            dropList: [],
            offSet: 0,
            recordLimit: 15,
            totalCount: '',
            dropCount: '',
            currentUser: '',
            sortBy: "createdAt",
            sale: false,
            sortOrder: "DESC",
            rate: 0,
            isCheck: false,
            isCheckArt: false,
            show: false,
            isFilter: "all",
            // filtTypes,
            // selectedFiltType: 1,
            selectedOption: { value: 'DESC', label: 'Most Recent' },
            loadText: 1,
            categoryTypes: [],
            categoryOption: { value: 0, label: "Select Category" }
        }
    }
    componentDidMount() {
        this.props.getAllTrendingTags()
        this.props.getUserCategories()
        localDropPage()
        localAirdropPage()
        this.setState({ sale: false, isFilter: 'all' })
        localStorage.setItem('artPage', JSON.stringify(0));
        const data = JSON.parse(localStorage.getItem('artData'))
        const dataLength = data && data[0] && data[0].length
        if ((dataLength)) {
            scrolled = false
            this.setState({
                artsList: data
            }, () => {
            })
            const count = Number(dataLength - (this.state.recordLimit))
            localStorage.setItem('artPage', JSON.stringify(count))
        } else {
            this.callApi()
        }

        this.setCount()
        getUserAddress().then(res => {
            this.setState({ currentUser: res })
        })

        window.addEventListener('scroll', this.infiniteScroll)


    }

    infiniteScroll = () => {
        const { recordLimit } = this.state
        // console.log(scrolled,this.state.artsList)
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;
        const fullHeight = scrollTop + clientHeight
        if ((Number(fullHeight)) >= (Number(scrollHeight) - 1200) &&
            (this.state.artsList && this.state.artsList[0] && this.state.artsList[0].length > 0) && !scrolled
        ) {
            scrolled = true
            this.loadMoreData()


        }

    }
    /* getUSD = () => {
        var rate = ""
        userServices.getUSD().then(res => {
            if ((res && res.statusCode == 200)) {
                res && res.data && res.data.src_side_base && res.data.src_side_base.map(item => {
                    if ((item && item.asset) === "USD") {
                        rate = item.rate
                    }
                })
                this.setState({ rate })
            }
        }).catch(err => {
            console.log(err)
        }) */
    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    static getDerivedStateFromProps(props, state) {

        // let rate = ""
        if ((props && props.artsList && props.artsList.statusCode === 200)) {
            // window.scrollTo({
            //     top: 0,
            //     behavior: "smooth"
            // });
            props.resetGetArts()
            props.resetDropArt()

            // props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
            //     if ((item && item.asset) == "USD") {
            //         rate = item.rate
            //     }
            // })
            if ((state.artsList && state.artsList.length > 0) && state.artsList[0]) {

                if (props.artsList && props.artsList.data && props.artsList.data.arts && props.artsList.data.arts.length == 0) {
                    scrolled = true
                    isFinish = true

                } else {
                    props.artsList && props.artsList.data && props.artsList.data.arts && props.artsList.data.arts.map((items) => {
                        state.artsList[0].push(items)
                        scrolled = false
                        isFinish = false
                    })
                }

            } else {

                state.artsList.push(props.artsList && props.artsList.data && props.artsList.data.arts)

                scrolled = false
                isFinish = false

            }
            return {
                loadText: 1,
                // artsList: props.artsList && props.artsList.data && props.artsList.data.arts ? props.artsList.data.arts : [],
                totalCount: props.artsList && props.artsList.data && props.artsList.data.count,
                pageCount: props.artsList && props.artsList.data && props.artsList.data.count / state.recordLimit,
                dropList: props.dropList && props.dropList.data && props.dropList.data.drops ? props.dropList.data.drops : [],
                dropCount: props.dropList && props.dropList.data && props.dropList.data.count,
                dropPageCount: props.dropList && props.dropList.data && props.dropList.data.count / state.recordLimit,
                // rate,
                isCheck: props.isCheckDrop,
                isCheckArt: props.isCheckArt,
            }

        }
        if ((props && props.trendingTags && props.trendingTags.statusCode) === 200) {
            props.resetTrendingTag()
            if (props.trendingTags.data && props.trendingTags.data.trendingTags) {
                return {
                    trendingTags: props.trendingTags.data.trendingTags
                }
            }
        }
        if ((props && props.getCategories && props.getCategories.statusCode) === 200) {
            props.resetCategories()
            if (props.getCategories && props.getCategories.data && props.getCategories.data.tags) {
                let array = []
                props.getCategories.data.tags && props.getCategories.data.tags.length > 0 && props.getCategories.data.tags.map((items) => {
                    let obj = {
                        value: items && items.id,
                        label: <span><i className={items && items.iconClass}></i>&nbsp; {items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)}</span>,
                        name: items && items.name,
                        iconclass: <i className={items && items.iconClass}></i>
                    }
                    array.push(obj)
                })
                return {
                    categoryTypes: array
                }
            }
        }
        return null;

    }
    callDrop = () => {
        this.props.getDropArt()
        this.props.resetGetArts()
    }
    gotoDetail = (items) => {

        // let webLog = localStorage.getItem("webLogin")
        // let loginDetail = JSON.parse(webLog)

        // if ((items && items.drops && items.drops.length === 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) === (items && items.owner && items.owner.userName)) {

        //     history.push(`/list/${items && items.tokenId}`)

        // } else {

        history.push(`/art/${items && items.id}`, { artObj: { isFromArts: true } })
        // }


    }
    componentDidUpdate(props, state) {

        let nft = configPath.basename
        if (window.location.pathname.includes(nft)) {
            if ((props && props.urlProps && props.urlProps.location && props.urlProps.location.search) !== (window.location.search)) {
                if (this.state.sale) {
                    this.callSale()
                } else {
                    this.callApi()

                }
            }
        } else if ((props && props.urlProps && props.urlProps.location && props.urlProps.location.search) !== (window.location.search)) {
            if (this.state.sale) {
                this.callSale()
            } else {
                this.callApi()

            }
        }

    }
    goDetailArt = (id) => {
        history.push('/detail', { obj: { id } })
    }
    refreshArts = () => {
        if (this.state.sale) {
            this.setState({ offSet: 0 }, () => {
                localStorage.setItem("page", JSON.stringify(0))
                this.callSale()
            })

        } else {
            this.setState({ sale: false, offSet: 0 }, () => {
                localStorage.setItem("page", JSON.stringify(0))
                this.callApi()
            })
        }

    }
    handleForSale = () => {
        localStorage.setItem("page", JSON.stringify(0))
        this.setState({ sale: true, offSet: 0 }, () => {
            this.callSale()

        })

    }
    callSale = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let offSet = localStorage.getItem("page")

        this.setState({ forcePage: offSet != null ? (JSON.parse(offSet) / this.state.recordLimit) : 0 })


        let obj = {
            offSet: offSet != null ? offSet : this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: 'updatedAt',
            sortOrder: this.state.sortOrder
        }
        this.props.getDropArt(obj)
        // this.props.getUSD()
        this.getUSD()
    }
    handleAll = () => {
        if (this.state.sale === true) {
            localStorage.setItem("page", JSON.stringify(0))
            this.setState({ sale: false, offSet: 0 }, () => {
                this.callApi()
            })
        }
    }
    callApi = () => {
        controller = new AbortController();
        let signal = controller.signal;
        const { isFilter, selectedFiltType, selectedOption, artsList, categoryOption } = this.state
        this.setState({
            loadText: 2
        })
        let offSet;
        let recordLimit;
        if ((artsList && artsList[0])) {
            offSet = localStorage.getItem("artPage")
            recordLimit = this.state.recordLimit
        } else {
            if ((localStorage.getItem("artPage")) == "0") {
                recordLimit = this.state.recordLimit
                offSet = localStorage.getItem("artPage")
            } else {
                let records = Number(Number(localStorage.getItem("artPage")) + this.state.recordLimit)
                recordLimit = records
                offSet = 0
            }
        }
        // this.setState({ forcePage: offSet != null ? ((JSON.parse(offSet)) / this.state.recordLimit) : 0 })

        let obj = {
            offSet: offSet != null ? offSet : this.state.offSet,
            recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            signal
        }
        if (isFilter == "all") {
            obj.type = null
        } else {
            // if(categoryOption && categoryOption.value){
            //     obj.type = categoryOption.name
            // }
            obj.type = isFilter
        }
        // if(selectedFiltType == 2){
        //    obj.sortOrder = "ASC"
        // }else if(selectedFiltType == 1){
        //     obj.sortOrder = "DESC"
        // }else if(selectedFiltType == 3){
        //     obj.rarity = "high"
        // }else if(selectedFiltType == 4){
        //     obj.rarity = "medium"
        // }else if(selectedFiltType == 5){
        //     obj.rarity = "low"
        // }else{
        //     obj.sortOrder = this.state.sortOrder
        // }
        if (selectedOption.id == 1 || selectedOption.id == 2) {
            obj.sortOrder = selectedOption.value
        } else if ((selectedOption.id == 3) || (selectedOption.id == 4) || (selectedOption.id == 5)) {
            obj.rarity = selectedOption.value
        } else {
            obj.sortOrder = this.state.sortOrder
        }
        this.props.getArts(obj)
        this.props.resetDropArt()

    }
    onPageChange = (data) => {

        let offSet = this.state.recordLimit * (data && data.selected)
        if (this.state.sale) {
            this.setState({ offSet }, () => this.callSale())

            localStorage.setItem("page", JSON.stringify(offSet))
        } else {
            localStorage.setItem("page", JSON.stringify(offSet))

            this.setState({ offSet }, () => this.callApi())
        }

    }
    checkLastDate = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;

        if (distance < 0) {
            return true
        }
        else {
            return false
        }
    }
    startTime = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return <div className="mt-1 update"><p className="mb-0  usernamelilac">Bidding Closed</p></div>
        }
        return <div className="mt-1 update"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + 'd' : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }

    checkdropsLength = (tokens, totalTokens, items) => {
        if (isNaN(items && items.tokenGroupId)) {
            if (items && items.tokenGroupId && (items.tokenGroupId).includes("-")) {
                return 1
            }
        }
        if (totalTokens && Number.isInteger(totalTokens)) {
            return tokens
        } else {
            return 1
        }
    }
    handleFilter = (value) => {
        controller.abort()
        controller = new AbortController();
        // scrolled = true
        // console.log("filtercoming")
        this.setState({
            isFilter: value,
            artsList: [],
            categoryOption: { value: 0, label: "Select Category" }

        }, () => {
            localStorage.setItem("artPage", JSON.stringify(0))
            this.callApi()
        })
    }
    // handlefilterTypeChange = (e) => {
    //     localStorage.setItem("page", JSON.stringify(0))
    //         if (this.filterTypes[e.target.value]) {
    //             this.setState({
    //                 selectedFiltType: parseInt(e.target.value)
    //             }, () => this.callApi());
    //         } else {
    //             this.setState({
    //                 selectedFiltType: 1
    //             }, () => this.callApi());
    //         }

    // }
    refreshFilterTypes = () => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            selectedFiltType: 1,
            isFilter: "all",
            artsList: [],
            categoryOption: { value: 0, label: "Select Category" },
            selectedOption: { value: 'DESC', label: 'Most Recent' }
        }, () => {
            localStorage.setItem("artPage", JSON.stringify(0))
            this.callApi()
        })


    }
    handleSelectChange = (option) => {
        controller.abort()
        controller = new AbortController();

        this.setState({
            selectedOption: option,
            artsList: []
        }, () => {
            localStorage.setItem("artPage", JSON.stringify(0))
            this.callApi()
        })
    }
    navigationCheck = (e, items) => {

        if ((e.target.id === "creatorprofile")) {
            this.gotoCreator(items && items.creator)
        } else {
            this.gotoDetail(items)
        }
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    loadMoreData = () => {
        const { recordLimit } = this.state
        const getArtPage = JSON.parse(localStorage.getItem('artPage'))
        let page = Number(getArtPage) + recordLimit
        localStorage.setItem("artPage", JSON.stringify(page))
        // this.setState({
        //     loadText : 2,offSet : page
        // })
        this.callApi()


    }
    hasMoreQuotes = (page, limit, total) => {
        const startIndex = (page - 1) * limit + 1;
        return total === 0 || startIndex < total;
    };
    handleTrendTag = (items) => {
        history.push(`/art/tag/${items && items.name}`)
    }
    handleCategoryChange = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            categoryOption: option,
            artsList: [],
            isFilter: ''

        }, () => {
            localStorage.setItem("artPage", JSON.stringify(0))
            this.callApi()
        })
    }
    showEditions = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null)) {
            return ''
        } else {
            if (items && items.isBab) {
                return <p className="mb-0 small mt-1">{babMintCount} Editions </p>
            } else {
                if (items && items.groups && items.groups != null) {
                    return <p className="mb-0 small mt-1 edition_count">{(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {(checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1 ? "Editions" : "Edition"}  </p>
                } else {
                    return <p className="mb-0 small mt-1">Edition 1 </p>
                }
            }
        }

    }
    getCreatorImage = (items) => {
        if (items && items.isBab) {
            return <img src={`../${configPath.imageAssets}/jim-jones.jpg`} alt="" />
        } else {
            return <img src={items && items.creator && items.creator.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.creator && items.creator.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" id="creatorprofile" />
        }
    }
    getCreatorName = (items) => {
        if (items && items.isBab) {
            return <h6 className="mb-0 text-center piccenth6" >by Jim Jones</h6>
        } else {
            return <h6 className="mb-0 text-center piccenth6 created_by" id="creatorprofile" style={{ cursor: "pointer", }} title={items && items.creator && items.creator.fullName}>{items && items.creator && ((items.creator.fullName && items.creator.fullName.includes('@')) ? items.creator.fullName : (`by ${items.creator.fullName}`))}</h6>

        }
    }
    getUserName = (items) => {
        if (items && items.isBab) {
            return <h6 className="mb-0" style={{ maxWidth: "110px" }}>@Jim Jones</h6>
        } else {
            return <h6 className="mb-0" id="creatorprofile" style={{ cursor: "pointer", maxWidth: "110px" }} title={items && items.creator && items.creator.userName}>{items && items.creator && ((items.creator.userName && items.creator.userName.includes('@')) ? items.creator.userName : (`@${items.creator.userName}`))}</h6>

        }
    }
    getImageSource = (items) => {
        if ((items && items.localPreview && items.localPreview !== null)) {
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        } else {
            if (items && items.thumbnailHash) {
                return items.thumbnailHash
            }
        }
    }
    getMimetype = (items) => {
        if ((items && items.localPreview && items.localPreview !== null && items.localPreviewMimeType !== null)) {
            return items.localPreviewMimeType
        } else {
            if (items && items.thumbnailMimeType) {
                return items.thumbnailMimeType
            }
        }
    }
    componentWillUnmount() {
        scrolled = true
        // localStorage.setItem("artPage",JSON.stringify(0))
        localStorage.removeItem('artPage')
        if ((this.state.isFilter == "all") && (this.state.selectedOption && this.state.selectedOption.value) === "DESC") {
            localStorage.setItem("artData", JSON.stringify(this.state.artsList))
        } else {
            localStorage.setItem("artData", JSON.stringify([]))
        }
        // fix Warning: Can't perform a React state update on an unmounted component    
        controller.abort()
        controller = new AbortController();

        window.removeEventListener("scroll", this.infiniteScroll)
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {

        const { isCheck, isCheckArt, selectedFiltType, offSet, trendingTags, categoryTypes } = this.state
        // const filterTypeOptions = this.state.filtTypes.map((filType) => {
        //     return <option value={filType.value}>{filType.evtName}</option>
        // });
        // const filterTypeSelect = (
        //     <select onChange={this.handlefilterTypeChange} className="form-control px-2 js-example-basic-single" value={selectedFiltType} style={{padding:"2px"}}>
        //         {filterTypeOptions}
        //     </select>
        // );
        return (
            <Fragment>
                <Helmet>
                    <title>Arts</title>
                    <meta name="title" content="Explore NFT Arts"></meta>
                    <meta name="description" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}></meta>
                </Helmet>
                <div className="pagetitabtn">

                    <div className="droplisttit">
                        <div className="probtns activebtnscus py-0 row">
                            <div className="col-6"> <h1 className="mb-3 d-inline-block arts_title">Art</h1></div>
                            <div className="col-6 text-right"><button type="button" className=" btn btn-rounded btn-primary-gradient btn-sm" onClick={this.refreshFilterTypes}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>
                        </div>

                        <div className="probtns activebtnscus py-0 row">
                            <div className="col-md-7">
                                <ul className="list-unstyled mb-0">
                                    <li><button type="button" className={(this.state.isFilter === "all") ? "btn btn-primary btn-sm active" : 'btn btn-primary-gradient  btn-sm'} onClick={() => { this.handleFilter("all") }}>All</button></li>
                                    {categoryTypes && categoryTypes.length > 0 && categoryTypes.map(item => (
                                        <li><button type="button" className={(this.state.isFilter == (item && item.name)) ? "btn btn-primary btn-sm active" : 'btn btn-primary-gradient  btn-sm'} onClick={() => { this.handleFilter(item && item.name) }}>
                                            {item && item.iconclass} &nbsp;
                                            {item && item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</button></li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <ul className="list-unstyled mb-0 text-right">
                                    <li style={{ display: "inline-block", verticalAlign: "middle", height: "45px", textAlign: 'center' }} >

                                        <Select
                                            value={this.state.selectedOption}
                                            onChange={this.handleSelectChange}
                                            options={options}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            isSearchable={false}
                                        />

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="row mb-3">
                        <div className="col-md-12">
                            <div className="probtns text-center activebtnscus py-0">
                                <ul className="list-unstyled mb-0 text-right">
                                    {trendingTags && trendingTags.map((items) => (

                                        <li>

                                            <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "btn btn-outline-dark btn-sm active cushashtag" : 'btn   btn-sm cushashtag'} onClick={() => { this.handleTrendTag(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button></li>

                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {!(this.state.artsList && this.state.artsList[0]) && <div className="solarloader" >
                    {<SolarSystemLoading />}

                </div>}
                {!this.state.sale && <div >
                    <div className="gallery">
                        <div className="row">
                        {this.state.artsList && this.state.artsList[0] && this.state.artsList[0].length > 0 && <JoyRide start={checkArtPage()} page="arts" isScroll={true}/>}

                            {this.state.artsList && this.state.artsList[0] && this.state.artsList[0].map((items, index) => (
                                <div className="col-sm-6 col-md-4" onClick={(e) => this.navigationCheck(e, items)}>
                                    <div className="cardlist artimglist">
                                        <ArtPreview src={items && items.thumbnailHash} alt={items && items.title} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                        <div className="cont">
                                            <div className="row">
                                                <div className="col-sm-12 mb-3">
                                                    <div className="piccent">
                                                        <div className="upic" style={{ cursor: "pointer" }}>
                                                            <span className="artcovimg">
                                                                <span className="artcov"></span>
                                                                <span className="artcovimginset no-proimg">
                                                                    {this.getCreatorImage(items)}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <h3 className="userdescrip" title={items && items.title}>{items && items.title}</h3>
                                                    {this.getCreatorName(items)}


                                                </div>
                                                <div className="col-6">
                                                    <div className="userwithpic ">
                                                        {this.getUserName(items)}

                                                    </div>

                                                </div>
                                                <div className="col-6 text-right">
                                                    <p className="price mb-0">


                                                        {(items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ? "" :
                                                            items && items.drops && items.drops[0] && items.drops[0].markedPrice && items.groups && items.groups != null ? <span className="text-white">/ 1 Edition</span> : <span> </span>} </p>
                                                    {this.showEditions(items)}

                                                    {this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])
                                                    }

                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            ))}


                            {/* {this.state.artsList && this.state.artsList[0] && this.state.artsList[0].length > 0 && 
                        (this.state.totalCount > (this.state.artsList && this.state.artsList[0] && this.state.artsList[0].length)) && (this.state.loadText == 1) && 
                        <button className="btn btn-primary" style={{margin : "auto"}}  onClick={this.loadMoreData}>{"Load More"}</button>} */}
                            {(scrolled && !isFinish) && (this.state.artsList && this.state.artsList[0]) &&
                                <div className="solarloader" >
                                    <SolarSystemLoading />

                                </div>
                            }
                            {(this.state.artsList && this.state.artsList[0]) && (scrolled && isFinish) &&
                                <div className="text-center solarloader usernamelilac" style={{ fontSize: "20px" }} >
                                    You have reached the end of list

                                </div>
                            }
                        </div>

                        {/* {this.state.artsList && this.state.artsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />} */}
                        {this.state.artsList && this.state.artsList[0] && this.state.artsList[0].length === 0 && isCheckArt &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                            </div>
                        }
                    </div>
                </div>}
                {this.state.sale && <div>

                    <div className="gallery">
                        <div className="row">
                            {this.state.dropList && this.state.dropList.map((items, index) => (
                                <div className="col-sm-6 col-md-4" key={items && items.art && items.art.id} onClick={(e) => {
                                    e.preventDefault();
                                    history.push(`/art/${(items && items.art && items.art.id && items.art.id.length > 1) ? (items.art.id[0]) : (items.art.id)}`,
                                        { dropObj: { id: items && items.id, userAddress: items && items.art && items.art.creator && items.art.creator.userAddress } })
                                }}>
                                    <div className="cardlist artimglist">

                                        <ArtPreview src={items && items.art && items.art.thumbnailHash} alt="" isPremiumArt={items && items.art && items.art.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.art && items.art.thumbnailMimeType} />

                                        <div className="cont">

                                            <div className="row">
                                                <div className="col-sm-12">


                                                    <h4 className="userdescrip mb-0">{items && items.art && items.art.title}</h4>
                                                    <div className="userwithpic">
                                                        <div className="upic mb-2">
                                                            <span className="artcovimg">
                                                                <span className="artcov"></span>
                                                                <span className="artcovimginset no-proimg">
                                                                    <img src={items && items.user && items.user.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.user && items.user.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                </span>
                                                            </span>

                                                        </div>
                                                        {/* {items && items.art && items.art.owner && items.art.owner.isPremiumUser && <span className="articoni permium" data-toggle="tooltip" title="" data-original-title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt=""/></span>} */}
                                                        {items && items.art && items.art.owner && items.art.owner.userName && <h6 className="mb-0" style={{ cursor: "pointer", maxWidth: "100px" }} title={items && items.art && items.art.owner && items.art.owner.userName}>{items && items.art && items.art.owner && ((items.art.owner.userName && items.art.owner.userName.includes('@')) ? items.art.owner.userName : (`@${items.art.owner.userName}`))}</h6>}

                                                    </div>


                                                </div>
                                                <div className="col-sm-7">

                                                    {(items && items.auction && items.auction.id && items.auction.id != null) ? "" : (items && items.group && items.group != null ? <div className="userwithpic"><div className="upic artgalemp"></div>
                                                        <p className="mb-0 mt-1"><span className="small">Edition {this.checkdropsLength(items && items.totalTokens && items.totalTokens, (items.art.toatalMintedTokens), (items.groups))} of {(items && items.art && items.art.toatalMintedTokens) ? (checkMintedTokens((items.group), items.art.toatalMintedTokens)) : 1}</span></p></div> : <div className="userwithpic"><div className="upic artgalemp"></div><p className="mb-0 mt-1"><span className="small">Edition 1 of </span>1</p></div>)}

                                                </div>
                                                <div className="col-sm-5 text-right">

                                                    {(items && items.markedPrice) && <p className="update usernamelilac" title={items && items.markedPrice}>{(items && items.auction && items.auction.id && items.auction.id != null) ? '' : (items && items.markedPrice && flowDecimal(parseFloat(items.markedPrice)) + ' FLOW')}
                                                        {/* {(items && items.auction && items.auction.id && items.auction.id != null) ? '' : <span className="small">/ 1 Edition</span>}  */}
                                                        <br />
                                                        {(items && items.markedPrice) && (this.state.rate) && <span className="small text-white" title={getUSDValue(this.state.rate, items.markedPrice)}>{(flowDecimal(parseFloat((this.state.rate) * (items.markedPrice)))) + ' USD'}</span>}

                                                    </p>}

                                                    {!this.checkLastDate(items) ? (items && items.auction && items.auction.id && items.auction.id != null &&
                                                        <p className="mb-0 update "><span className="usernamelilac" title={items && items.auction && items.auction.lastBid}>{items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ? (flowDecimal(parseFloat(items.auction.lastBid)) + ' FLOW') :
                                                            <span className="usernamelilac ">
                                                                {items && items.auction && items.auction.id && items.auction.id != null && (items.auction.startPrice &&
                                                                    `${(flowDecimal(parseFloat(items.auction.startPrice)) + ' FLOW')}`)}
                                                            </span>)}</span></p>
                                                    ) : (items && items.auction && items.auction.id && items.auction.id != null &&
                                                        <p className="mb-0 update "><span className="usernamelilac" title={items && items.auction && items.auction.lastBid}>{items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ? (flowDecimal(parseFloat(items.auction.lastBid)) + ' FLOW') : '')}</span></p>
                                                    )}
                                                    {!this.checkLastDate(items) ? (items && items.auction && items.auction.id && items.auction.id != null &&
                                                        <p className="mb-0 update "><span className="usernamelilac" >{items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ? (
                                                            (this.state.rate) && <span className="small text-white " title={getUSDValue(this.state.rate, items.auction.lastBid)}>{(flowDecimal(parseFloat(((this.state.rate) * (items.auction && items.auction.lastBid))))) + ' USD'}</span>)

                                                            :
                                                            <span className="usernamelilac ">
                                                                {items && items.auction && items.auction.id && items.auction.id != null && (items.auction.startPrice &&
                                                                    ((this.state.rate) && <span className="small text-white " title={getUSDValue(this.state.rate, items.auction.startPrice)}>{(flowDecimal(parseFloat(((this.state.rate) * (items.auction && items.auction.startPrice))))) + ' USD'}</span>)

                                                                )}
                                                            </span>)}</span></p>
                                                    ) : (items && items.auction && items.auction.id && items.auction.id != null &&
                                                        <p className="mb-0 update "><span className="usernamelilac">{items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ?
                                                            ((this.state.rate) && <span className="small text-white " title={getUSDValue(this.state.rate, items.auction.lastBid)} >{(flowDecimal(parseFloat(((this.state.rate) * (items.auction && items.auction.lastBid))))) + ' USD'}</span>)

                                                            : '')}</span></p>
                                                    )}

                                                    {this.state.show && items && items.auction && items.auction.id && items.auction.id != null && this.startTime(items)}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    {this.state.dropList && this.state.dropList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.dropPageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                    {this.state.dropList && this.state.dropList.length === 0 && isCheck &&
                        <div className="col-md-4 offset-md-4 text-center">
                            <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                        </div>
                    }
                </div>}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        artsList: state.art.artsList,
        notDrops: state.art.notDrops,
        dropList: state.drop.dropList,
        usd: state.user.usd,
        isCheckDrop: state.drop.isCheckDrop,
        isCheckArt: state.art.isCheckArt,
        trendingTags: state.tags.trendingTags,
        getCategories: state.category.userCategories,

    }
}
const actionCreators = {
    getArts: artActions.getArts,
    notDrop: artActions.notDrop,
    getDropArt: dropActions.getDropArt,
    resetGetArts: artActions.resetGetArts,
    resetDropArt: dropActions.resetDropArt,
    getUSD: userAction.getUSD,
    getAllTrendingTags: tagActions.getAllTrendingTags,
    resetTrendingTag: tagActions.resetTrendingTags,
    getUserCategories: categoryActions.getUserCategories,
    resetCategories: categoryActions.resetGetUserCategories,
}
const connectArt = connect(mapStateToProps, actionCreators)(Gallery)
export { connectArt as Gallery }

