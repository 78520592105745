import { configPath } from '../config';
import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAction } from 'store/actions';
import ReCAPTCHA from "react-google-recaptcha"
import GoogleLogin from 'react-google-login';
import { Helmet } from 'react-helmet';
import { localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { userServices } from 'services';
import PubSub from 'pubsub-js';

var timeout;
class WebSignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            username: '',
            email: '',
            password: '',
            isAgree: false,
            submitted: false,
            repassword: '',
            reCaptchaToken: '',
            isSuccess: false,
            isAlert: false,
            message: '',
            style: '',
            isUsernameError : '',
            isUserLoading : false,
            isValidUsername : false,
            isEmailError : '',
            isEmailLoading : false,
            isValidEmail : false,
            isUserTyping : false,
            isEmailTyping : false
        }
        this.recaptchaRef = React.createRef()

    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({ isSuccess: false })
        const form = document.getElementById('username');
        const email = document.getElementById('email');
        const fullname = document.getElementById('fullname');

        form && form.addEventListener('focusout', (event) => {
            this.checkUsernameVerification()
            console.log('focueds')
        });
        email.addEventListener('focusout', (event) => {
            this.checkEmailVerification()
        });
        fullname && fullname.addEventListener('focusout', () => {
            this.setState({fullnameError : true})
        })

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            if (props && props.regRes && props.regRes.statusCode === 200) {
                if(props && props.regRes && props.regRes.data && props.regRes.data.id){
                history.push(`/registration/user/${props.regRes.data.uuid}/verify`)
                }
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                props.resetRegister()
                return {
                    fullname: '',
                    username: '',
                    password: '',
                    email: '',
                    isAgree: false,
                    submitted: false,
                    isSuccess: true,
                    reCaptchaToken: '',
                    isAlert: false,
                    message: '',
                    style: ''
                }
            }
            if ((props && props.failedReg && state.submitted)) {
                // props.resetRegFailure()
                if ((props && props.failedReg) === 'userName should contain only A-Z, a-z, 0-9 and Period symbol(.)') {
                    // if(history && history.location && history.location.pathname.includes('signup')){
                    //     return {
                    //      isAlert: true,
                    //      message: 'Username should contain only a-z, 0-9 and period symbol(.)',
                    //      style: "danger"
                    //  }
                    //  }else{
                        props.updateRegError('Username should contain only a-z, 0-9 and period symbol(.)')
 
                    //  }

                   
                }
                else if ((props && props.failedReg) === 'fullName must be at least 3 characters long. And fullName must not contain leading and trailing spaces.') {
                    // if(history && history.location && history.location.pathname.includes('signup')){
                    //     return {
                    //      isAlert: true,
                    //      message: 'Fullname must be at least 3 characters long. And fullname must not contain leading and trailing spaces.',
                    //      style: "danger"
                    //  }
                    //  }else{
                        props.updateRegError('Fullname must be at least 3 characters long. And fullname must not contain leading and trailing spaces.')
 
                    //  }

                    
                }
                else {
                    // if(history && history.location && history.location.pathname.includes('signup')){
                    //    return {
                    //     isAlert: true,
                    //     message: props && props.failedReg,
                    //     style: "danger"
                    // }
                    // }else{
                        props.updateRegError(props && props.failedReg)

                    // }

                    
                }
            }
        }
    }
    componentDidUpdate(props, state) {
        if ((props && props.failedReg)) {
            props.resetRegFailure()
            this.gotoTop()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value,repassword : '' })
    }
    handleEmailChange = (e) => {
        if (((e.target.value && e.target.value.length) <= 50)) {

            this.setState({ [e.target.name]: (e.target.value).toLowerCase(),isEmailTyping : true })
        }
    }
    handleFullNameChange = (e) => {
        if (((e.target.value && e.target.value.length) <= 110)) {

            this.setState({ [e.target.name]: e.target.value, fullnameError : false })
        }
    }
    handleUserName = (e) => {
        if (((e.target.value && e.target.value.length) <= 50)) {
            this.setState({ [e.target.name]: (e.target.value).toLowerCase(),isUserTyping : true }, () => {
                this.userNameValidation(e.target.value)
            })
        }
    }
    handleReEnterPassword = (e) => {
        // if((this.state.password && this.state.password.length >= (e.target.value && e.target.value.length)))
        this.setState({ [e.target.name]: e.target.value })

    }

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    checkEmailLength = (value) => {
        if ((value && value.length) <= 50) {
            return true
        } else {
            return false
        }
    }

    checkPassword = (value) => {
        if ((value && value.length) >= 8) {
            return true
        } else {
            return false
        }
    }

    checkusername = (value) => {
        if (((value && value.length) <= 50) && (value && value.length) >= 4) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= 110) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }

    handleChecked = (value) => {
        let isAgree = value ? false : true
        this.setState({ isAgree })
    }

    // checkPassword = (e) => {
    //     const { password } = this.state
    //     if ((e.target.value) == password) {
    //         this.setState({ repassword: e.target.value })
    //     }
    // }
    captchaChange = (value) => {
        this.setState({ reCaptchaToken: value })
        // console.log("Captcha value:", value,configPath.siteKey)
    }
    handleClose = () => {
        this.setState({
            isSuccess: false
        })
        history.push('/login')
    }
    checkConfirmPassword = () => {
        const { password, repassword } = this.state
        if (password === repassword) {
            return true
        } else {
            return false
        }
    }
    success() {

        return (
            <div>
                <h5>DisruptArt</h5>
                <h6>Mandatory fields are missing</h6>
            </div>
        );

    }
    userNameValidation = (value) => {
        const regext = /^([a-z0-9.]{4,})+$/
        if (regext.test(value)) {
            return true
        } else {
            return false
        }

    }
    checkEmailVerification = () => {
        const {email} = this.state
        let obj = {
            email
        }
        if(email){
            if(this.checkemail(email)){
                if(this.checkEmailLength(email)){
                    this.setState({
                        isEmailLoading : true
                    })
                    userServices.emailVerification(obj).then(res => {
                        if((res && res.statusCode) === 200){
                            this.setState({
                                isValidEmail : true, isEmailError : false,isEmailLoading : false
                            })
                        }else{
                            this.updateErrorAlert('Unexpected error occured')
                            this.setState({ 
                                // isAlert: true, 
                                // message: 'Unexpected error occured', 
                                // style: "danger",
                                isEmailLoading : false ,
                                isEmailError : '',
                                isValidEmail : false,
                                isEmailTyping : false
                            })
                        // this.gotoTop()
                        }
                        
                    }).catch(error => {
                        if((error).toLowerCase().includes("email is already in use.")){
                            this.setState({
                                isEmailError : "There is already an account with above Email. Please try Login or use different Email.",
                                isValidEmail : false,
                                isEmailLoading : false,
                                isEmailTyping : false
                            })
                        }else{
                            this.updateErrorAlert(error)
                        this.setState({ 
                            
                            isEmailLoading : false,
                            isEmailError : '' ,
                            isValidEmail : false,
                            isEmailTyping : false
                        })
                        // this.gotoTop()
                        }
                    })
                }else{}
            }else{
                this.setState({
                isValidEmail : false,
                isEmailError : 'Invalid Email',
                isEmailTyping : false
            })
            }
        }else{
            
        }
        
    }
    checkUsernameVerification = () => {
        const {username} = this.state
        let obj = {
            userName : username
        }
        this.setState({
        })
        if((username)){
            if(this.checkusername(username) ){
                if((this.userNameValidation(username))){

                    this.setState({isUserLoading : true})

                    userServices.usernameVerification(obj).then(res => {
                        if((res && res.statusCode) === 200){
                            this.setState({
                                isValidUsername : true, isUserLoading : false,isUsernameError : false
                            })
                        }else{
                            this.updateErrorAlert('Unexpected error occured')
                            this.setState({ 
                               
                                isUserLoading : false ,
                                isUsernameError : '',
                                isValidUsername : false,
                                isUserTyping : false

                            })
                        // this.gotoTop()
                        }
                    }).catch(error => {
                        if((error).toLowerCase().includes("user name is already taken")){
                            this.setState({
                                isUsernameError : "Someone else is using above username,please try a different one",
                                isValidUsername : false,
                                isUserLoading : false,
                                isUserTyping : false

                            })
                        }else{
                            this.updateErrorAlert(error)

                        this.setState({ 
                           
                            isUserLoading : false,
                            isUsernameError : '' ,
                            isValidUsername : false,
                            isUserTyping : false

                        })
                        // this.gotoTop()
                        }
                    })
                }else{
                    this.setState({
                        isUsernameError : ""
                    })
                }
            }else{
                this.setState({
                    isUsernameError : ""
                })
            }
        }else{
            this.setState({
                isUsernameError : ""
            })
        }
        
    }
    handleSubmit = async(e) => {
        e.preventDefault();
        const { fullname, username, email, password, isAgree, repassword, reCaptchaToken, isUserLoading, isUsernameError, isValidUsername, isEmailLoading, isEmailError, isValidEmail } = this.state;
        this.setState({ submitted: true })
        
        if (
       
            email && 
            password && 
            this.checkemail(email) && 
            this.checkPassword(password) && 
         
            repassword && this.checkEmailLength(email)) {
            
                        if((isValidEmail && !isEmailError)){
                            if(!isEmailLoading){
                                if (this.checkConfirmPassword()) {
                                    if (isAgree) {
                                        this.showLoader()
                                        const token = await this.recaptchaRef.current.executeAsync()
                                        this.recaptchaRef.current.reset()
                                        if (token) {
                                            let obj = {
                                              
                                                email,
                                                password,
                                                reCaptchaToken : token
                                            }
                                            this.props.register(JSON.stringify(obj))
                                        } else {
                                            this.hideLoader()
                                            this.updateErrorAlert('Recaptcha execution failed')
                                        }
                    
                                    } else {
                                        this.updateErrorAlert('Please agree the terms and conditions')
                                    }
                                } else {
                                    this.updateErrorAlert('Password did not match. Please enter correct password')
                                }
                            }else{
                                this.updateErrorAlert('Please wait, we are checking your email')
                            }
                        }else{
                            this.updateErrorAlert('Please provide a valid email address')
                        }
                  
        } else {
            this.updateErrorAlert('Mandatory fields are missing')
        }
    }

    handleLogin = async (googleData) => {
        if (googleData.tokenId) {
            let obj = { tokenId: googleData.tokenId }
            this.props.googleLogin(JSON.stringify(obj))
            history.push('/login')
        }
    }
    updateErrorAlert = (message) => {
        // if(history && history.location && history.location.pathname.includes('signup')){
        //     this.setState({ isAlert: true, message: message, style: "danger" })
        //     this.gotoTop()
        // }else{
            this.props.updateRegError(message)
    //    } 
          
    }
    updateSuccessAlert = (message) => {
        this.props.updateRegSuccess(message)
    }
    showLoader = () => {
        PubSub.publish('msg', true);
    }
    
    hideLoader = () => {
        PubSub.publish('msg', false);
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        const path = history && history.location && history.location.pathname

        return (
            <Fragment>
                {!this.state.isSuccess && <div className="container">
                <div className='dlilabg pb-5'>

                <h1 className="text-center mt-5 mb-3">New to Disrupt Art? <div className='usernamelilac  mt-2'>Sign Up</div></h1>
                    <div className="purchasebox1 p-3 reglogfrm">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group text-center">
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    render={renderProps => (
                                        <div className="form-group text-center">
                                            <a onClick={renderProps.onClick} className="btn btn-primary-fill btn-icon w-100 ">
                                                <img src={`${configPath.imageAssets}/google.png`} alt="" className="mw-100" />Sign up with Google
                                                    </a>
                                        </div>
                                    )}
                                    // buttonText="Log in with Google"
                                    onSuccess={this.handleLogin}
                                    onFailure={this.handleLogin}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>
                            <div className="form-group text-center">
                                        <div className="vertical-separator">
                                            <hr className="vertical-separator_hr" />
                                            <div className="vertical-separator_or">
                                                <p className="">or</p>
                                            </div>
                                            <hr className="vertical-separator_hr" />
                                        </div>
                                        
                                    </div>
                            
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <div className="input-group mb-3">
                                <input type="email" name="email" id="email" onChange={this.handleEmailChange} value={this.state.email} className="form-control fc-custom" placeholder="Enter email address here" />
                                {(this.state.isEmailLoading) && <div className="icon-container">
                                    <i className="userloader"></i>
                                    </div>}
                                    </div>
                                {this.state.submitted && !this.state.email && <div className="mandatory">Email is required</div>}
                                {this.state.email && this.checkemail(this.state.email) && this.checkEmailLength(this.state.email) && this.state.isValidEmail && <div className="mandatory-req">
                                <span className="badge badge-olutline-primary"><i className="fa fa-check" aria-hidden="true"></i></span> Looks good and valid!</div>}
                                {this.state.email && this.state.isEmailError && !this.state.isEmailTyping && <div className="mandatory">{this.state.isEmailError}</div>}

                                {/* {this.state.email && !this.checkemail(this.state.email) && this.state.isEmailError && <div className="mandatory">Invalid Email</div>} */}
                                {this.state.submitted && this.state.email && this.checkemail(this.state.email) && !this.checkEmailLength(this.state.email) && <div className="mandatory">Max 50 characters</div>}
                                {this.state.email && this.checkemail(this.state.email) && this.checkEmailLength(this.state.email) && !this.state.isValidEmail && !this.state.isEmailError && <div className="mandatory-req">{"Looks good!"}</div>}

                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" id="password" onChange={this.handleInputChange} value={this.state.password} className="form-control" placeholder="Enter password here" />
                                {this.state.submitted && !this.state.password && <div className="mandatory">Password is required</div>}
                                {this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory">Min 8 characters</div>}
                                {!this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory-req">{"Min 8 characters"}</div>}


                            </div>
                            <div className="form-group">
                                <label htmlFor="repassword">Confirm Password</label>
                                <input type="password" name="repassword" id="repassword" onChange={this.handleReEnterPassword} value={this.state.repassword} className="form-control" placeholder="Enter password here" disabled={!(this.checkPassword(this.state.password))} />
                                {this.state.submitted && !this.state.repassword && <div className="mandatory">Password is required</div>}
                                {/* {this.state.submitted && this.state.repassword && !this.checkPassword(this.state.repassword) && <div className="mandatory">Password must be contain atleast 8 characters</div>}                                         */}
                                {this.state.submitted && this.state.repassword && (this.state.repassword !== this.state.password) && <div className="mandatory">{"Password did not match"}</div>}
                                {!this.state.submitted && this.state.repassword && (this.state.repassword !== this.state.password) && <div className="mandatory-req">{"Password did not match"}</div>}
                                {this.state.repassword && (this.state.repassword == this.state.password) && <div className="mandatory-req">{"Looks good!"}</div>}
                            </div>
                            
                            <div className="form-group d-flex justify-content-center">
                                <ReCAPTCHA sitekey={configPath.siteKey} onChange={this.captchaChange} theme={'dark'}  size="invisible" ref={this.recaptchaRef}/>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.isAgree} onChange={() => this.handleChecked(this.state.isAgree)} />
                                <label className="form-check-label" htmlFor="exampleCheck1"> I agree with the <a href="/nft/terms/" target="blank" className="mx-1 d-inline">terms and conditions of Disrupt.art</a></label>
                            </div>

                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-primary px-5">Sign up</button>
                            </div>

                                    <div className="form-group text-center loginhide">
                                        {/* <a onClick={this.handleRegister}>If you are new user, go to register</a> */}
                                        Already have an account? <Link to="/login">Please Login</Link>
                                    </div>

                        </form>
                    </div>
                    </div>
                </div>
                }
                {this.state.isSuccess && <div>
                    <div style={{ margin: "30px" }}>
                        <div className="success-pop">
                            <div className="mb-4">
                                <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div className="text-center">
                                <div>
                                    <h4>Thanks for joining with DisruptArt</h4>
                                </div>
                                <div>
                                    <h5>
                                        Kindly check your email inbox and click the verification link.
                                </h5>
                                </div>
                                <button className="btn btn-primary" onClick={this.handleClose}>Login</button>

                            </div>
                        </div>
                    </div>
                </div>}

            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        regRes: state.user.regRes,
        failedReg: state.user.failedReg
    }
}

const actionCreators = {
    register: userAction.register,
    googleLogin: userAction.googleLogin,
    resetRegister: userAction.resetRegister,
    resetRegFailure: userAction.resetRegFailure
}

const connectWebSignUp = connect(mapStateToProps, actionCreators)(WebSignUp)

export { connectWebSignUp as WebSignUp }