import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction, walletActions } from 'store/actions';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from '../config';
import * as fcl from "@onflow/fcl";
import { isWebLogin } from 'helpers';
import { Link } from 'react-router-dom';
import { userServices } from 'services';
import PubSub from 'pubsub-js';

var timeout;
class OTPVerifivation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false,
            loginData: [],
            reCaptchaToken: '',
            isSuccess: false,
            isAlert: false,
            style: '',
            iswallet: false,
            verificationCode: '',
            otp : ['','','','','',''],
            isVerified : false,
            isUserDisabled : false,
            isLoading : true,
            isOtherError : false,
            isInvaliduuid : false,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if ((props && props.verifyRes && props.verifyRes.statusCode) === 200) {
            // Email verified successfully.

            let userDetail = JSON.stringify(props.verifyRes)
            localStorage.setItem("webLogin", userDetail)
            props.getUserProfile()
            history.push('/connect-wallet')

        } else if ((props && props.verifyRes && props.verifyRes.statusCode) === 400) {
            if (
                (props.verifyRes.data &&
                    props.verifyRes.data &&
                    props.verifyRes.data.errorCode)
                === 'EMAIL_VERIFICATION_FAILED'
            ) {
                // Email verification failed.
                return {
                    isAlert: true,
                    message: 'Invalid verification code',
                    style: "danger"
                }
            }

            // Email has already been verified
            return {
                isAlert: true,
                message: props.verifyRes.message,
                style: "danger"
            }
        }else if((props && props.verifyRes && props.verifyRes.statusCode) === 403){
            if (
                (props.verifyRes.data &&
                    props.verifyRes.data &&
                    props.verifyRes.data.errorCode)
                === 'DISABLE_USER'
            ) {
                // Email verification failed.
                return {
                    isUserDisabled : true
                }
            }
        }
        else{
            if(props.verifyRes &&
                props.verifyRes.message)
            return {
                isAlert: true,
                message: props.verifyRes.message,
                style: "danger"
            }
        }



    }
    componentDidUpdate(props, state) {
        if ((props && props.verifyRes && props.verifyRes.statusCode) === 400) {
            props.resetLoginFailure()
            this.resetTimeout()
            this.gotoTop()

        }else{
            if((props.verifyRes && props.verifyRes.message)){
                props.resetLoginFailure()
            this.resetTimeout()
            this.gotoTop()
            }
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

    }
    componentDidMount() {
        this.gotoTop()
        this.emailVerificationStatus()
        if (!(isWebLogin())) {
            if ((this.props && this.props.match && this.props.match.params && this.props.match.params.userId)) {
                this.setState({
                    userId: this.props.match.params.userId
                })
            } else {
                history.goBack()
            }
        } else {
            history.push('/')
        }
    }
    emailVerificationStatus = () => {
        if ((this.props && this.props.match && this.props.match.params && this.props.match.params.userId)) {
            this.showLoader()
        userServices.emailVerificationStatus(this.props.match.params.userId).then(res => {
            this.hideLoader()
            if((res && res.statusCode) === 200){
                if(res && res.data && res.data.isVerified){
                    this.setState({
                        isVerified : true
                    })
                }else{
                    this.setState({
                        isVerified : false,
                        isLoading : false
                    })
                }
            }
        }).catch(err => {
            console.log(err)
            if(err && err.toLowerCase().includes('user account has been disabled')){
                this.setState({
                    isUserDisabled : true
                })
            }
            else if (err && err.includes('uuid must be an UUID')){
                this.setState({isInvaliduuid:true})
                
            }else{
                this.setState({isOtherError:true})
            }
            this.hideLoader()
        })
    }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    digitValidate = (s) => {
        var n = Number(s)
        var regex = /[.]/g
        
        if ((n <= 999999) && (n % 1 === 0) && !(regex.test(s))) {
            if (!s.match(/\d{0,6}\.\d{6}/)) {

                return true
            }else{
                return true
            }
        } 
        return false
    }
    handleChange = (e) => {
        if(this.digitValidate(e.target.value)){
            if((e.target.value).length <= 6){
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
            
        }
    

    }

    captchaChange = (value) => {
        this.setState({ reCaptchaToken: value })
    }


    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { userId, verificationCode } = this.state;
        if (verificationCode != '') {
            if((verificationCode).length === 6){
                const obj = {
                    verificationCode
                }
                this.props.verify(JSON.stringify(obj), userId)
            }else{
                this.setState({ isAlert: true, message: 'Invalid verification code', style: "danger" })
            this.gotoTop()
            }

        } else {

            this.setState({ isAlert: true, message: 'Verification code required', style: "danger" })
            this.gotoTop()


        }
    }

    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    digitValidate1 = (e,index) => {
        console.log(e.target.value,(e.target.value).toString().length)
        const [first,...rest] = e.target.value
        e.target.value = first ?? ''
       
       const {otp} = this.state
      if(((e.target.value).toString().length) > 1){
          console.log("true")
      }else{
          console.log("false")
      }

       
       let filterArray = otp
        otp.map((d,idx) => (index === idx) ? (filterArray[idx] = e.target.value) : (filterArray[idx]=d))
       this.setState({
           otp : filterArray
       },() => {
       })
    
    
    if((e.target.value)){
       if((e.target).nextSibling){
           e.target.nextSibling.focus()
       }
    }
    
    }
    tabChange = (val) => {
        let ele = document.querySelectorAll('input');
        for (var i = 1; i <= 5; i++) {
            if (ele[i + 1]) {
            }
        }
        if (ele[val + 1].value != '') {
            if (ele[val + 2]) {
                ele[val + 2].focus()
            }
        } else if (ele[val + 1].value == '') {
            ele[val].focus()
        }
    }
    onChange = (val,key) => {
        const inputElements = document.querySelectorAll('input.form-control.code')
        inputElements.forEach((ele,index)=>{
          
         
        })
    }
    handleKeyDown = (e,val) => {
        const {otp} = this.state
        let filterArray = otp
        if((e.keyCode) === 8){
            if(e.target.value === ''){
                if((e.target).previousSibling){
                    e.target.previousSibling.focus()
                }
            }else{
                otp.map((d,idx) => (val === idx) ? (filterArray[idx] = '') : (filterArray[idx]=d))
                e.target.focus()

            }
             
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    resendVerification = () => {
        const {userId} = this.state
        if (userId !== '') {
            this.showLoader()
            userServices.resendVerificationEmail(userId)
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({isAlert:true,message:'An email containing the verification code has been sent to your email',style:"success"})
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                    this.hideLoader()
                })
                .catch((err) => {
                    this.hideLoader()
                    if(err && err.message && err.message.toLowerCase().includes('email has already been verified')){
                        this.setState({isAlert:true,message:err.message,style:"danger"})
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }else{
                    this.setState({isAlert:true,message:'Unable to send an email containing the verification code',style:"danger"})
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    }
                })
        }else{
            this.setState({isAlert:true,message:'Uuid missing',style:"danger"})
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    gotoLogin = () => {
        history.push('/login')
    }
    goHome = () => {
        history.push('/')
    }
    goLogin = () => {
        history.push('/login')
    }
    render() {
        
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        return (
            <Fragment>
                <Helmet>
                    <title>Email Verification</title>
                    <meta name="title" content="Code"></meta>
                    <meta name="description" content={`Login to ${process.env.REACT_APP_SITE_CONTRACT}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

                <div className="container">
                {(this.state.isVerified && !this.state.isUserDisabled) &&
                !(this.state.isOtherError) &&
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">Your email has been already verified </h3>
                                    <div className="form-group text-center mt-4">
                                    <button type="button" className="btn btn-primary px-5" onClick={this.gotoLogin}>Login</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {(this.state.isUserDisabled && !this.state.isVerified) && !(this.state.isOtherError) && <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="error-template userdisableview">
                        <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                            <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED</h3>
                            <h4  className="mb-2">If you have any queries, please contact Disrupt Art admin</h4>
                          
                                <p className="mb-0">The Team</p>
                                <p className="usernamelilac">DisruptArt</p>


                        </div>
                    </div>

                </div>
            </div>}
             {(!this.state.isUserDisabled && !this.state.isVerified) && (this.state.isOtherError) && <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="error-template userdisableview">
                        <i className="fa fa-chain-broken mw-100 my-5 fa-5x" aria-hidden="true"></i>

                            <h3 className="mb-2 usernamelilac ">Invalid link</h3>
                          
                            <button type="button" className="btn btn-primary mt-4" onClick={this.goHome}>Home</button>

                        </div>
                    </div>

                </div>
            </div>}
            {(!this.state.isUserDisabled && !this.state.isVerified) && !(this.state.isOtherError) && (this.state.isInvaliduuid) && <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="error-template userdisableview">
                        <i className="fas fa-hourglass-end mw-100 my-5 fa-5x" aria-hidden="true"></i>

                            <h3 className="mb-2 usernamelilac ">Verification link expired.Please login again</h3>
                          
                            <button type="button" className="btn btn-primary mt-4" onClick={this.goLogin}>Login</button>

                        </div>
                    </div>

                </div>
            </div>}

                    {!(this.state.isVerified) && !this.state.isUserDisabled && !this.state.isLoading && !(this.state.isOtherError) && <div className='otpverifiy'>

                        <h1 className="text-center mt-5 mb-3">Email verification</h1>

                        <div className="purchasebox p-3 reglogfrm">
                            <form onSubmit={this.handleSubmit}>
                              

                                <div className="form-group text-center">
                                    <label htmlFor="verificationCode" className='mb-0 ' style={{textTransform:"none"}}>Enter the verification code received in your email inbox</label>
                                    <input className="form-control" type="text" inputMode="numeric" pattern="^[0-9]{0,6}$" value={this.state.verificationCode} onChange={this.handleChange} name="verificationCode" id="verificationCode"  />
                                    {/* <div className='otpinputs'>
                                    {this.state.otp && this.state.otp.map((items,index) => (
                                        
                                    <input 
                                    type="text" 
                                    className="form-control code" 
                                    name="otp" 
                                    onChange={(e) => this.digitValidate1(e,index)} 
                                    value={items}
                                    // onKeyUp={() => this.onChange(1)} 
                                    onKeyDown={(e) => this.handleKeyDown(e,index)} 
                                    id="verification-input-1" 
                                    placeholder="-" 
                                    // maxLength={1} 
                                    onFocus={e => (e.target.select())}
                                    />

                                    ))}
                                    </div> */}
                                    {/* <div className='otpinputs'>
                                    <input type="number" className="form-control code" name="verificationCode" onChange={this.digitValidate} onKeyUp={() => this.onChange(2)} onKeyDown={this.handleKeyDown} id="verification-input-2" placeholder="-" maxLength={1} />
                                    <input type="number" className="form-control code" name="verificationCode" onChange={this.digitValidate} onKeyUp={() => this.onChange(3)} onKeyDown={this.handleKeyDown} id="verification-input-3" placeholder="-" maxLength={1} />
                                    <input type="number" className="form-control code" name="verificationCode" onChange={this.digitValidate} onKeyUp={() => this.onChange(4)} onKeyDown={this.handleKeyDown} id="verification-input-4" placeholder="-" maxLength={1} />
                                    <input type="number" className="form-control code" name="verificationCode" onChange={this.digitValidate} onKeyUp={() => this.onChange(5)} onKeyDown={this.handleKeyDown} id="verification-input-5" placeholder="-" maxLength={1} />
                                    <input type="number" className="form-control code" name="verificationCode" onChange={this.digitValidate} onKeyUp={() => this.onChange(6)} onKeyDown={this.handleKeyDown} id="verification-input-6" placeholder="-" maxLength={1} />
                                    </div> */}


                                    {this.state.submitted && !this.state.verificationCode && <div className="mandatory">Please enter the verification code you have received in your email</div>}
                                    {/* {this.state.submitted && this.state.username && !this.checkemail(this.state.username) && <div className="mandatory">Invalid Email</div>}                                         */}
                                </div>
                                

                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-primary px-5">Verify</button>
                                </div>

                                <div className="form-group text-center">
                                <a>Haven't received code yet?</a>&nbsp;<a onClick={this.resendVerification} style={{cursor:"pointer"}} className="usernamelilac">Resend code</a>

                                    </div>
                            </form>
                        </div>
                    </div>}
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        webLogRes: state.user.webLogRes,
        failedRes: state.user.failedRes,
        wallet: state.wallet.wallet,
        verifyRes: state.user.verifyRes

    }
}

const actionCreators = {
    login: userAction.webLogin,
    googleLogin: userAction.googleLogin,
    resetWebLogin: userAction.resetWebLogin,
    resetLoginFailure: userAction.resetLoginFailure,
    getUserProfile: userAction.getUsers,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    verify: userAction.verify


}

const connectOTPVerifivation = connect(mapStateToProps, actionCreators)(OTPVerifivation)

export { connectOTPVerifivation as OTPVerifivation }
