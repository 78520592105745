import { configPath } from 'config'
import { isConnectWallet, isWebLogin, localArtPage } from 'helpers'
import history from 'helpers/history'
import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import * as fcl from "@onflow/fcl";
import { walletServices } from 'services'
import PubSub from 'pubsub-js';
import { connect } from 'react-redux'
import { userAction, walletActions } from 'store/actions'
import Modal from 'react-modal'
import { CreatorRequest } from "./creatorRequest";
import { Alert } from "helpers/alert";
import moment from 'moment'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '700px', // <-- This sets the height
        overlfow: 'auto' // <-- This tells the modal to scrol
    },
};
class Footer extends Component {
    constructor(props){
        super(props);
        this.state = {
            isVisible:false,
            isRequest: false,
            userRole: '2',
            isSuccess: false
        }
    }
    componentDidMount(){
        isWebLogin() && this.props.getUserProfile()

        let checkStorage = localStorage.getItem("cookieConset")
        if(checkStorage){
            this.setState({isVisible:false})
        }else{
            this.setState({isVisible:true})
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    gotoTesting = () => {
        this.setState({ isRequest: true })
    }
    handleError = (message) => {
        // console.log("error coming")
        this.setState({ isAlert: true, message, style: "danger" })
        this.closeAlert()
    }
    closeAlert = () => {
        setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    closeTest = () => {
        this.setState({ isRequest: false })
    }
    hideDiv = () => {
        this.setState({isVisible:false})
    }
    handleGotit = () => {
        localStorage.setItem("cookieConset",true)
        this.setState({isVisible:false})
    }

    // static getDerivedStateFromProps(props,state){
    //     if((props && props.wallet && props.wallet.statusCode) == 200){
    //         props.resetlinkWallet()
    //         // history.push('/')
    //           history.push('/mint')
    //     }
    // }
    handleLogin = () => {

        fcl.logIn().then(res => {

            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }
                this.props.linkWallet(JSON.stringify(obj))
                history.push('/')
                history.push('/mint')




            }
        }).catch(err => {
            // console.log("rejected")
        })

    }
    static getDerivedStateFromProps(props,state){
        // console.log("footer props",props)
        return {
            userRole: props && props.usersList && props.usersList.data && props.usersList.data.roleId ? props.usersList.data.roleId : '',

        }
    }
    gotoLogin = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        history.push('/login')
    }
    gotoCreateArt = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (isConnectWallet) {
            history.push('/mint')
        }




    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleArtPage = () => {
        localStorage.setItem("artPage", "0")
        localArtPage()
        history.push('/arts')
        window.scrollTo(0,0);
    }
    render() {
        const currentYear = moment().year()
        return (
            <Fragment>
               
               <hr  className='mt-0'/>
                    <footer className="site-footer">

                    {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />}

{this.state.isSuccess &&
    <div className="show-success">
        <div className="success-pop">
            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
            <div className="mt-3">Thanks for applying to become one of a creator in Disrupt.art, <br /> We will review your application and upgrade your account as a creator account soon. <br />Until then enjoy Arts & Drops and start collecting more NFT in DisruptArt.</div>
            {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptNowio" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disruptnow.io/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.com/users/DisruptNow#5704" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); this.setState({ isSuccess: false }); }}>Close</button></div></div>
}
<Modal
    isOpen={this.state.isRequest}
    style={customStyles}
    className="creatorrequ"
    //   overlayClassName="Overlay"
    contentLabel="Example Modal"

>

    <button onClick={this.closeTest} className="btn btn-primary px-2 clsbtn">X</button>
    <CreatorRequest closeModal={this.closeTest} success={() => this.setState({ isSuccess: true })} errorMessage={(mes) => this.handleError(mes)} />

</Modal>

                        <div className="container">
                           
                            <div className="row">
                                <div className="col-sm-12 col-md-6 text-center">
                                    <div className='d-flex flex-column align-items-center h-100 justify-content-center'>
                                    {/* <h6>About</h6>
                                    <p className="text-justify">At the intersection of art and activism, DisruptArt aims to curate an interactive space for artists, collectors, and activists to revolutionize the impact of art. Artists sell their pieces as a digital creation through blockchain technology. Our vision is to create a community where spoken word artists, musicians, entrepreneurs, photographers, and crypto artists intersect, creating dialogue about social impact, contemporary art, and the blockchain. We believe artists deserve more equity and it’s why we ensure artists receive a majority of the funds and always receive royalty for their work.</p> */}
                                    <img src={`../${configPath.imageAssets}/DA-new-logo.png`} alt="" style={{width:'50%'}} />

                                    <div className="copyright mt-3">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="copyright-text">Copyright © 2021 - {currentYear} All Rights Reserved by DISRUPT ART, INC.  <br/> <a href="https://disrupt.art/nft/terms/" target="_blank">Terms & Conditions</a> | <a href="https://disrupt.art/nft/privacy/" target="_blank">Privacy Policy</a>
                                        </p>
                                    </div>
                                    {/* <div className="col-sm-4 col-xs-12 text-right">
                                        <div className="socialicons"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                                    </div> */}
                                </div>

                            </div>
                            </div>
                                </div>

                                <div className="col-sm-4 col-md-2">
                                    <h6>learn</h6>
                                    <ul className="footer-links">
                                    <li><a href="https://disrupt.art/nft/about/">About Us</a></li>
                                    <li><a href="https://disrupt.art/nft/faq">FAQ</a></li>
                                    <li><a href="https://disrupt.art/nft/contact/">Contact Us</a></li>
                                    <li><a href="https://form.typeform.com/to/q8hbxtBV" target="_blank">Support</a></li>
                                   
                                       
                                        

                                    </ul>
                                </div>

                                <div className="col-sm-4 col-md-2">
                                    <h6>Join Us</h6>
                                    <ul className="footer-links">
                                    {(isWebLogin() && this.state.userRole && (this.state.userRole == "2")) ? <li><a onClick={this.gotoTesting} style={{cursor:"pointer"}}>Become a Disruptor</a></li> : (!isWebLogin() ? <li><Link to="/login">Become a Disruptor</Link></li> : <li><Link to="/creators">Creators</Link></li>)}                                       
                                    <li><NavLink to="/collectors">Collectors</NavLink></li>
                                    <li><NavLink to="/arts" onClick={this.handleArtPage}>All NFTs</NavLink></li>
                                        {/* <li><a onClick={()=> {history.push('/terms')}}>Terms and Conditions</a></li> */}
                                        {/* <li><a href="https://disrupt.art/terms/" target="_blank">Terms and Conditions</a></li> */}
                                        {/* <li><a href="https://disrupt.art/privacy/" target="_blank">Privacy Policy</a></li> */}
                                        <li><a href="https://discord.io/disruptart" target="_blank">Join Discord</a></li>
                                        <li><a href="https://twitter.com/DisruptArt" target="_blank">Twitter</a></li>
                                        <li><a href="https://www.youtube.com/channel/UCldh5Tc_ICSTgVV0BuLwVxg" target="_blank">Youtube</a></li>
                                        <li><a href="https://www.instagram.com/disrupt.art/" target="_blank">Instagram</a></li>
                                        
                                    </ul>
                                </div>
                               
                                <div className="col-sm-4 col-md-2">
                                    <h6>Technology</h6>
                                    <ul className="footer-links builtwallet">
                                    <li className="developlink"><a target="_blank" href="https://www.onflow.org/"><img src={`/${configPath.imageAssets}/onflow.png`} alt="" className="onflowicon" /> Built on <br/><span> Flow Blockchain</span></a></li>

                                    <li className="developlink"><a target="_blank" href="https://blaze.ws/"><img src={`/${configPath.imageAssets}/blaze-logo.png`} alt="" className="onflowicon" /> Developed at <br/><span> BLA<span className='text-danger'>Z</span>E.ws</span></a></li>


                                    <li className="developlink"><a target="_blank" href="https://www.meetdapper.com/"><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="onflowicon" /> Purchase with <br/><span> Dapper Wallet </span></a></li>

                                    {/* <li className="developlink"><a target="_blank" href="https://blocto.portto.io/en/"><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="onflowicon" /> Mint with <br/><span> Blocto Wallet</span></a></li> */}

                                    <li className="developlink"><a target="_blank" href="https://www.pinata.cloud/"><img src={`/${configPath.imageAssets}/pinata.png`} alt="" className="onflowicon" /> IPFS Storage <br/><span> Pinata</span></a></li>

                                    </ul>
                                </div>
                            </div>

                           
                            
                        </div>
                                                   

                        <button className="btn btn-primary gototop" type="button" onClick={this.gotoTop}>
                            <i className="fa fa-arrow-up" aria-hidden="true" ></i>
                        </button>


                    </footer>
                    {this.state.isVisible && <div className="cookie-banner">
                        <div className="cookie-content">
                            We use cookies to enhance user experience, analyze site usage, and assist in our marketing efforts. By
                            continuing to browse or closing this banner, you acknowledge that you have read and agree to our <a
                                href="https://disrupt.art/nft/privacy/" target="_blank" rel="noopener noreferrer" aria-label="Cookie Policy">Cookie Policy</a>, <a
                                    href="https://disrupt.art/nft/privacy/" target="_blank" rel="noopener noreferrer" aria-label="Privacy Policy">Privacy Policy</a> and <a href="https://disrupt.art/nft/terms/" target="_blank" rel="noopener noreferrer" aria-label="Terms of Service">Terms of Service</a>.
                        </div>
                        <div className="cookie-btn">
                            <button name="button" className="flex1 gotit" type="button" onClick={this.handleGotit}>
                                <span className="flex items-center justify-center trans trans-opacity">Got it</span>
                            </button>
                            <button className="btn-icon-only ml-2" aria-label="hide banner" type="button" data-ember-action=""
                                data-ember-action-60="60" onClick={this.hideDiv}>
                                <figure className="svg-container transform scale-90 text-secondary">
                                    <svg width="15" height="15">
                                        <path fill="currentColor"
                                            d="M.304 1.77A1.037 1.037 0 1 1 1.77.305l5.479 5.48 5.48-5.48a1.037 1.037 0 1 1 1.466 1.467L8.716 7.25l5.48 5.48a1.037 1.037 0 1 1-1.467 1.466L7.25 8.716l-5.48 5.48A1.037 1.037 0 1 1 .305 12.73l5.48-5.479-5.48-5.48z">
                                        </path>
                                    </svg>
                                </figure>
                            </button>
                        </div>
                    </div>}
                
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        wallet: state.wallet.wallet,
        usersList: state.user.usersList,

    }
}
const actionCreators = {

    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    getUserProfile: userAction.getUsers

}

const connectFooter = connect(mapStateToProps, actionCreators)(Footer)
export { connectFooter as Footer }

