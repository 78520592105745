import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom';
import { userServices } from 'services';
import history from 'helpers/history';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';

class FetchCreators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            creators: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: ''
        }
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.allCreatorApplications) {
            return {
                creators: props.allCreatorApplications && props.allCreatorApplications.data && props.allCreatorApplications.data.creators ? props.allCreatorApplications.data.creators : [],
                totalCount: props.allCreatorApplications && props.allCreatorApplications.data && props.allCreatorApplications.data.count,
                pageCount: props.allCreatorApplications && props.allCreatorApplications.data && props.allCreatorApplications.data.count / state.recordLimit
            }
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleRefresh = () => {
        this.setState({ sortBy: "createdAt", search: '', sortOrder: "DESC", offSet: 0 }, () => {
            this.callApi()
        })
    }
    handleRemarks = (items, e) => {
        // console.log(items)

        this.setState({ remarks: e.target.value }, () => {

        })
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        }, () => { this.callApi() })
    }
    handleSortBy = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }
    checkRemarks = (value) => {
        if ((value && (value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    approve = (items) => {
        const { remarks } = this.state
        let obj = {}
        if (remarks) {
            if (this.checkRemarks(remarks)) {
                obj['adminRemarks'] = remarks
                obj['userId'] = Number((items && items.id))
                obj['status'] = Number(1)
                // this.props.statusApprove(JSON.stringify(obj))
                this.showLoader()
                userServices.statusApprove(JSON.stringify(obj)).then(res => {
                    history.push('/admin/users')
                    this.hideLoader()
                    toast.success("Approved as creator")
                    this.callApi()
                    // this.setState({isShow:false})
                }).catch(err => { this.hideLoader() })
            } else {
                toast.error("Remarks should have atlease 3 characters")
            }
        } else {
            toast.error("Please give your remarks")
        }
    }
    reject = (items) => {
        const { remarks } = this.state
        let obj = {}

        if (remarks) {
            if (this.checkRemarks(remarks)) {
                obj['adminRemarks'] = remarks
                obj['userId'] = Number((items && items.id))
                obj['status'] = Number(2)
                // this.props.statusApprove(JSON.stringify(obj))
                this.showLoader()
                userServices.statusApprove(JSON.stringify(obj)).then(res => {
                    this.hideLoader()
                    toast.success("Rejected")
                    this.callApi()
                }).catch(err => { this.hideLoader() })
            } else {
                toast.error("Remarks should have atlease 3 characters")
            }
        } else {
            toast.error("Please give your proper remarks")
        }
    }
    callApi = () => {
        const { search } = this.state

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            // offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (search) {
            obj['search'] = search

        } else {
            obj['offSet'] = this.state.offSet
        }
        this.props.getCreatorApplications(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet }, () => this.callApi())

    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {
            this.setState({ isShow: false, })
        } else {
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }

    render() {
        const {creators} = this.state

        const creatorsList = this.state.creators && this.state.creators.map((items, index) => {
            return <tbody>
                <tr>
                    <td>{items && items.id}</td>
                    <td>{moment(items && items.createdAt).format('DD-MM-YYYY')}</td>
                    <td><a className="designLink" onClick={() => { this.handleView(items) }}>{items && items.userName}</a></td>
                    <td><a className="designLink" onClick={() => { this.handleView(items) }}>{items && items.email}</a></td>
                    <td><a className="designLink" onClick={() => { this.handleView(items) }}>{items && items.fullName}</a></td>
                    {/* <td>{moment(items && items.updatedAt).format('MMM/DD/YYYY hh:mm:ss A')}</td> */}
                    {/* <td><Toggle defaultChecked={items && items.accountStatus} /></td>
                <td><Toggle defaultChecked={items && items.isEmailVerified} disabled={items && items.isEmailVerified == true} /></td> */}
                </tr>
                {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                    <td colSpan="5" className="px-3 graybgbox">
                        <h4 className="mb-0">Detailed Bio</h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label>Name </label>
                                <div className="comboxgray">
                                    {items && items.fullName}
                                </div>
                            </div>
                            {((items.instagramLink) || (items.fbLink) || (items.twitterLink) || (items.linkedInLink) || (items.shortDesc)) && <div className="col-md-4 mb-3"><label>Artwork Links</label>
                                <div className="comboxgray adminLinkBox">
                                    {items.instagramLink && <a href={items.instagramLink} target="blank" className="designLink">{items.instagramLink}</a>}<br/>
                                    {items.fbLink && <a href={items.fbLink} target="blank" className="designLink">{items.fbLink}</a>}<br/>
                                    {items.twitterLink && <a href={items.twitterLink} target="blank" className="designLink">{items.twitterLink}</a>}<br/>
                                    {items.linkedInLink && <a href={items.linkedInLink} target="blank" className="designLink">{items.linkedInLink}</a>}<br/>
                                    {items.shortDesc && <a href={items.shortDesc} target="blank" className="designLink">{items.shortDesc}</a>}<br/>

                                </div>
                            </div>}
                            <div className="col-md-4 mb-3">
                                <label>Description </label>
                                <div className="comboxgray">
                                    {(items && items.description) ? (items.description) : '-'}
                                </div>
                            </div>

                            {items && items.creatorCategories && items.creatorCategories[0] && items.creatorCategories[0].name && 
                                <div className="col-md-12 mb-3">
                                    <label>Best in</label>
                                    {/* <div className="comboxgray">{items.creatorCategories.map(data => (<span className="badge bg-dark mr-1 p-2">{data && data.name}</span>))} */}
                                    <div className="comboxgray">{(items.creatorCategories[0].name && Array.isArray(items.creatorCategories[0].name)) ? ((items.creatorCategories[0].name && items.creatorCategories[0].name.map(data => (<span className="badge bg-dark mr-1 p-2">{data}</span>)))) : (<span className="badge bg-dark mr-1 p-2">{items.creatorCategories[0].name}</span>)}

                                    </div>
                                </div>}



                            <div className="col-md-12 mb-3">
                                <label>Remarks/ Notes by Admin</label>


                                <textarea className="form-control" value={this.state.remarks} onChange={(e) => this.handleRemarks(items, e)} key={items && items.id} />

                                {/* <div>
                            {items.instagramLink && <div>Instagram Link : <a href={items.instagramLink} target="blank">{items.instagramLink}</a></div>}
                        {items.fbLink && <div>Google Drive Link  : <a href={items.fbLink} target="blank">{items.fbLink}</a></div>}
                        {items.twitterLink && <div>Social Media Link : <a href={items.twitterLink} target="blank">{items.twitterLink}</a></div>}
                        {items.linkedInLink && <div>Dropbox Link  : <a href={items.linkedInLink} target="blank">{items.linkedInLink}</a></div>}
                            </div> */}

                            </div>

                            <div className="col-sm-12">
                                <hr />
                            </div>

                            <div className="col-sm-12 text-right mb-3">

                                <button className="btn btn-primary px-3 mr-3" onClick={() => this.approve(items)}>Approve</button>
                                <button className="btn btn-primary px-3" onClick={() => this.reject(items)}>Reject</button>

                            </div>

                        </div>




                    </td>
                </tr>}
            </tbody>

        })


        return (

            <Fragment>
                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Creators Applications</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                                <div className="row">


                                    <div className="col-6">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-6 text-right">
                                        <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>DATE</th>
                                        <th>USER NAME</th>
                                        <th>EMAIL</th>
                                        <th>FULL NAME</th>
                                        {/* <th>JOINED DATE TIME</th> */}
                                        {/* <th>Status</th>
                                        <th>Email Verified</th> */}
                                        {/* <th>ACTIONS</th> */}
                                    </tr>
                                </thead>

                                {creatorsList}

                            </table>
                            {this.state.creators && !this.state.creators.length > 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.creators && this.state.creators.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allCreators: state.user.allCreators,
        allCreatorApplications: state.user.allCreatorApplications
    }
}

const actionCreators = {
    getAllCreators: userAction.getAllCreators,
    getCreatorApplications: userAction.getCreatorApplications
}

const connectCreator = connect(mapStateToProps, actionCreators)(FetchCreators)

export { connectCreator as FetchCreators }