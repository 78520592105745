import React from "react";

const IconComponent = ({ type }) => {
  const renderIcon = (type) => {
    switch (type) {
      case 0:
        return (<i className="fa fa-globe iconcomponent" aria-hidden="true"></i>);
      case 1:
        return (<i className="fa fa-users iconcomponent" aria-hidden="true"></i>);
      case 2:
        return (
          <i className="fa fa-map-marker iconcomponent" aria-hidden="true"></i>
        );
      case "Hidden":
        return (
          <i className="fa fa-eye-slash iconcomponent" aria-hidden="true"></i>
        );  
      case "Active":
        return <i className="fa fa-eye iconcomponent" aria-hidden="true"></i>;
      case "Claimed":
        return (
          <i className="fa fa-check iconcomponent" aria-hidden="true"></i>
        );
      case "Withdrawn":
        return (
          <i className="fa fa-sign-out iconcomponent" aria-hidden="true"></i>
        );
      default:
        return (
          <i
            className="fa fa-question-circle iconcomponent"
            aria-hidden="true"
          ></i>
        );
    }
  };

  return <>{renderIcon(type)}</>;
};

export default IconComponent;
