import { tagConstants } from "../../constants";

const initialState = {
    tagsList:[],
    isCheckAirDrop : false,
    tagAdded : [],
    trendingTags : []
}
export function tags(state=initialState,action){
    switch(action.type){
        case tagConstants.GET_TAGS:
            return {
                tagsList : action.tagsList,
               
            }
            case tagConstants.ADD_TAGS:
                return {
                    tagAdded : action.tagAdded
                }
                case tagConstants.GET_ALL_TRENDING_TAGS:
                    return {
                        trendingTags : action.trendingTags
                    }
                
          
            
            default:
                return state
    }
}