import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { artActions, creatorActions, dropActions, flowActions, followActions, reportActions, userAction, walletActions } from 'store/actions'
import { changePrice, marketSaleWithdrawn, getUserAddress, marketBuyTokenMultiple, RetrieveTokens, createSetUp, getDisplayMessageForRuntimeTxnError, getAdminUsername, getOrdinalSuffix } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { airDropService, artServices, collectorServices, creatorServices, dropServices, fetchService, userServices, walletServices } from 'services';
import moment from 'moment'
import { Link } from 'react-router-dom';
import * as fcl from "@onflow/fcl";
import { configPath } from '../../config';
import { checkMintedTokens, checkTokenGroup, checkWalletAddress, isConnectWallet, isWebLogin, userBalance, MaintenancePopup, flowDecimal, getUSDValue, getCurrentUserId, accountSetup } from 'helpers';
import Modal from 'react-modal'
import { SignIn } from '../sign-in';
import {
    FacebookShareButton, TelegramShareButton, WhatsappIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, TelegramIcon
} from 'react-share'
import { ArtPreview } from '../art-preview/art-preview';
import { cancelFlowAuction, placeBidOnFlowAuction, settleFlowAuction } from 'utils/flowauction';
import { Helmet } from 'react-helmet';
import { connectBlocto, connectDapper, getFlowUsdValue } from 'helpers/getFlowUsd';
import { ShowAttributes } from '../attributes';
import { TagsCategoryView } from '../artdetail-view/tags-category';
import { getCreatorImage, getCreatorName, ownershipEdition, showCreatorName, showTotalEditions, showTotalEditionsAfterOf, showTotalEditionsforDrops } from '../artdetail-view/bab-common-view';
import { ArtViewPreview } from '../art-preview/artview-preview';
import Toggle from 'react-toggle';
import { InvalidPage } from '../Common-error-ui/Invalidpag';
import { EditCategory } from '../artdetail-view/edit-category';
import { EditTag } from '../artdetail-view/edit-tag';
import { JoyRide } from '../Joy-ride';
import { checkBidPage, checkOwnAuctionPage, checkownAuctionSettlement, checkOwnCancelAuctionPage, checkOwnSalePage, checkPurchasePage } from 'helpers/joy-rideCheck';
import { ReportForm } from '../reportForm';
import { FollowPopup } from '../follow-popup';
import { getUniqueUUID } from "helpers";
import { isDapperConnected, switchAuthDapper } from 'helpers/switchauthdapper';
import { purchaseTokens } from 'utils/testnetdapper';
import { purchaseTokenss } from 'utils/mainnetdapper';
import { commonInfo } from 'helpers/CommonInfo';
import { SwitchAccount } from '../switchaccountPopup';
import { CelebrationGif } from '../common/celebrationGif';
import { ArtStoryTab } from './story';
import { Badges } from './badges';



var timeout;
var transactions = {}
var loggedUser = {}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

let celebrationTimeout;
class ArtDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList: [],
            dropList: {},
            rate: '',
            relativeArray: [],
            relativeArts: [],
            creator: '',
            usd: '',
            crt_share: '',
            mark_share: '',
            sell_share: '',
            visible: false,
            imgpop: false,
            isDrop: false, // set true if it is in drop(not own)
            isSuccess: false,
            artTxnHistoy: [],
            artCreator: null,
            artOwner: null,
            myArt: {},
            itsMine: false, // set true if own arts sale
            changedPrice: '',
            isPriceChange: '',
            openLogin: false,
            isAlert: false,
            isMySale: false,
            message: '',
            style: '',
            artPrice: '',
            balance: '',
            copySuccess: 'Copy Link',
            count: '',
            totalOwnerArt: 0,
            dropsCount: [],
            totalEdition: 0,
            tokensArray: [],
            purchaseCount: 1,
            isPurchase: false,
            priceCount: '',
            processCance: '',
            totalDropArray: [],
            totalDrop: 0,
            disablePurchase: false,
            disableChangePrice: false,
            disableWithdraw: false,
            iswallet: false,
            isShowLoader: false,
            isTransLoader: false,
            isAuction: false,
            showAuction: false, // set true if it is in auction (not own)
            isLoader: false,
            bidAmount: '',
            bidAmounting: '',
            auctionId: '',
            bidList: {},
            isAuctionSuccess: false,
            show: false,
            distanceTime: '',
            isMyAuction: false, // set true for own art auction
            isCancelBid: false,
            isSettlementSuccess: 0, //nothing succeed,
            isActSetup: false,
            auctionHistory: [],
            isShowHistory: true,
            isImage: false,
            isWithDrawn: false,
            isError: false,
            errorMsg: '',
            isActivityCall: false,
            isMaintenance: false,
            isRevokeAuction: false,
            showAllCreators: false,
            ownerShipDetail: {},//collector ownership
            isOwnershipFailed: '',
            bearAttributes: [],
            isMyartStatusChangeSuccess: false, // visibility changes success,
            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isThumbnailUpdateSuccess: false, //update thumbnail success,
            isInvalidPage: false,
            isCategoryUpdateSuccess: false, //category update,
            isTagUpdateSuccess: false,
            openReport: false,
            isCadenceError: false,
            cadencError: '',
            isForAirdropClaim: false,
            isFollower: true,
            isAirdropClaimMessage: '',
            isAirDrop: false,
            isWarning: false,
            isCheckAirdrop: true,
            favouritedUser: [],
            isFavourite: false,
            isDapperPurchaseStart: false,
            callDapperWallet: false,
            isOpenWithdraw: false,
            isOpenWithdrawCount: false,
            isSwitchAcc: false,
            isWithdrawSwitchAcc: false,
            followerSuccessMessage: '',
            isCelebrateSplash: false,
            isOpenSerialNoPopup: false,
            serialNos: [],
            tabIndex: 1,
            availableBadges: [],
            selectedBadges: [],
            isRemoveBadgePopup: false

        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.imgpopview = this.imgpopview.bind(this);
        this.maxWithdrawLimit = 50
    }
    copyToClipboard = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ copySuccess: 'Copied!' });
        const interval = setInterval(() => {
            this.setState({ copySuccess: 'Copy Link' });
        }, 3000);

    };

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    imgpopview() {
        this.setState({ imgpop: !this.state.imgpop })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }


    componentDidMount() {
        //dapper purchase
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isForDapperPurchase) {
            this.setState({ callDapperWallet: true })
            this.props.getUserProfile()
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isForDapperPurchase;
            history.replace({ ...location, state });

        }
        //other flow events
        else if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isFromLogin) {

            this.handletoConnectWallet()
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isFromLogin;
            history.replace({ ...location, state });

        }
        //reports, fav art concept
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isForReport) {
            this.props.getUserProfile()
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isForReport;
            history.replace({ ...location, state });
            // this.setState({openReport : true})
        }
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isSplash) {
            this.setState({
                isCelebrateSplash: true
            })
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isSplash;
            history.replace({ ...location, state });
        }


        this.getDrops()
        this.getCurrentUserwalletAddress()
        this.getBalance()
        isWebLogin() && this.checkLoggedUser()
        this.getAvailableBadges()

    }
    getDrops = () => {

        let userAddress = ""
        let tokenId = ""
        let dropList = []
        let detailDropList = []
        let markedPrice = ""
        let artList = []
        let myArt = []
        let userId = ''
        let detailMyArt = []
        let mySaleArt = []
        let ownerShipDetail = []

        clearTimeout(celebrationTimeout)
        //get id from url
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {

            let id = this.props.match.params.id
            this.showLoader()
            //get art detail by id
            artServices.detailArt(id).then(res => {
                this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    this.getFavArt()
                    if (res && res.data && res.data.tokenGroupId) {
                        this.getAllSerialNo(res.data.tokenGroupId)
                    } else {
                        this.setState({ serialNos: [] })
                    }

                    //get #drop hash
                    let queryString = window.location.hash;

                    let list_id = '' //drop id
                    let collector_id = '' //user id

                    //if it is a drop or if it is a ownership page
                    if ((queryString && queryString.includes('#drop'))) {
                        list_id = queryString && queryString.replace('#drop', '')
                    } else if ((queryString && queryString.includes('#collector'))) {
                        collector_id = queryString && queryString.replace('#collector-', '')
                    }

                    this.gotoTop() // to scroll top when api calls
                    this.setCount() //timer



                    /*click from Arts page*/
                    //if url has not list id
                    //not drops
                    if (!(list_id)) {
                        this.clearCelebrationTimeout()

                        tokenId = res && res.data && res.data.tokenId
                        userAddress = res && res.data && res.data.ownerWalletAddress
                        userId = res && res.data && res.data.owner && res.data.owner != null && res.data.owner.id != null ? res.data.owner.id : res.data.ownerWallet && res.data.ownerWallet != null && res.data.ownerWallet.id

                        // call ownership api
                        if (id && collector_id) {
                            this.setState({ tabIndex: 2 })
                            let obj = { userId: collector_id, artId: id }

                            userServices.getOwnershipUser(obj).then(ownership => {
                                if ((ownership && ownership.statusCode) == 200) {
                                    ownerShipDetail = ownership.data
                                    this.setState({
                                        ownerShipDetail,
                                        isOwnershipFailed: ''
                                    })

                                } else {
                                    this.setState({ ownerShipDetail: [], isOwnershipFailed: '' })
                                }
                            }).catch(ownershipErr => {
                                this.setState({ ownerShipDetail: [] })
                                if ((ownershipErr && ownershipErr.toLowerCase().includes('user not found'))) {
                                    history.push('/notfound')
                                } else if ((ownershipErr && ownershipErr.toLowerCase().includes('user is not the owner of this art'))) {
                                    this.setState({ isOwnershipFailed: "Collector is not the owner of this art" })
                                }
                            })
                        } else {
                            this.setState({ ownerShipDetail: [], isOwnershipFailed: '' })
                        }

                        this.setState({ tokenId, userAddress }, () => {

                        })

                        artList = res && res.data
                        // check dropscount (because dropsCount field not came some times)
                        if ((artList && artList.dropsCount) || (((artList && artList.dropsCount === null) || !(artList.dropsCount)) && (artList && artList.drops.length == 0))) {
                            let arr = (artList && artList.dropsCount)
                            arr = arr && '[' + arr + ']'
                            let dropsCountarray = arr ? eval(arr && arr) : []
                            var count = []
                            var dropArrCount = 0;
                            var val = artList && artList.totalTokenId ? artList.totalTokenId : ''
                            var tokens = val.split(",").length
                            dropsCountarray && dropsCountarray.map((items) => {
                                if (items) {
                                    var len = (items && items.tokens).split(",").length
                                    count.push(len)
                                }
                                function mySum(total, num) {
                                    return total + num;
                                }
                                dropArrCount = count.reduce(mySum)
                            })

                            let totalEdition = tokens - (dropArrCount)

                            let totalOwnerArt = artList && artList.totalOwnerArt

                            // console.log(totalEdition,totalOwnerArt,dropArrCount,dropsCountarray)
                            this.setState({
                                artList,
                                isDrop: false,
                                dropList: [],
                                myArt: [],
                                itsMine: false,
                                totalOwnerArt,
                                dropsCount: dropsCountarray,
                                totalEdition,
                                isMySale: false,
                                isShowLoader: true,
                                myAuctionList: [],
                                bidList: [],
                                showAuction: false,
                                isMyAuction: false,
                                detailMyArt: [],
                                detailDropList: [],
                            })
                        } else if ((artList && artList.drops && artList.drops[0])) {
                            let dropsCount = []
                            let artIds = artList.id.toString()
                            let marked_price = artList.drops[0].markedPrice
                            let tokens = artList.tokenId
                            let count = 1
                            let obj = {}
                            if ((artList && artList.drops && artList.drops[0] && artList.drops[0].auction && artList.drops[0].auction.id != null)) {
                                obj = {
                                    artIds, marked_price: "null", tokens, count
                                }
                            } else {
                                obj = {
                                    artIds, marked_price, tokens, count
                                }
                            }
                            dropsCount.push(obj)
                            let totalOwnerArt = artList && artList.totalOwnerArt


                            this.setState({
                                artList,
                                isDrop: false,
                                dropList: [],
                                myArt: [],
                                itsMine: false,
                                dropsCount,
                                isMySale: false,
                                isShowLoader: true,
                                myAuctionList: [],
                                bidList: [],
                                showAuction: false,
                                isMyAuction: false,
                                totalOwnerArt
                            })

                        }
                        this.txnFunction(tokenId, userId)
                        this.getAttributes(res && res.data && res.data.metadataJsonUrl)
                        this.getAvailableBadges()
                    } else {
                        let obj = {

                        }
                        // if it is in drops
                        dropServices.dropDetail(list_id, id, obj).then(d_res => {
                            this.hideLoader()
                            if ((d_res && d_res.statusCode) === 200) {

                                tokenId = d_res && d_res.data && d_res.data.art && d_res.data.art.tokenId
                                userAddress = res && res.data && res.data.ownerWalletAddress
                                userId = d_res && d_res.data && d_res.data.user && d_res.data.user != null && d_res.data.user.id != null ? d_res.data.user.id : d_res.data.ownerWallet && d_res.data.ownerWallet != null && d_res.data.ownerWallet.id
                                this.setState({ tokenId, userAddress })

                                /* Not click from arts */
                                /* first if condition -> if it is in auction */
                                // if (d_res && d_res.data && d_res.data && d_res.data.auction && d_res.data.auction.id && d_res.data.auction.id != null) {
                                //     //auction history
                                //     dropServices.getAuctionDetail((d_res && d_res.data && d_res.data.auction && d_res.data.auction.id)).then(auctionDet => {
                                //         if ((auctionDet && auctionDet.statusCode) == 200) {
                                //             this.setState({ auctionHistory: auctionDet && auctionDet.data && auctionDet.data.auction })
                                //         }
                                //     }).catch(err => {
                                //     })
                                //     if (d_res && d_res.data && d_res.data.isOwner) {
                                //         // this.setCount()
                                //         this.fetchArt(res && res.data && res.data.thumbnailHash)
                                //         this.setState({
                                //             myAuctionList: res && res.data,
                                //             detailMyAuctionList: d_res && d_res.data,
                                //             isMyAuction: true,
                                //             showAuction: false,
                                //             itsMine: false,
                                //             isDrop: false,
                                //             dropList: [],
                                //             myArt: [],
                                //             isOwnershipFailed: '',
                                //             ownerShipDetail: []
                                //         })
                                //     } else {
                                //         // this.setCount()
                                //         const bidIncrement = d_res && d_res.data && d_res.data.auction && d_res.data.auction.minimumBidIncrement && d_res.data.auction.minimumBidIncrement != null && d_res.data.auction.minimumBidIncrement
                                //         const startingPrice = d_res && d_res.data && d_res.data.auction && d_res.data.auction.startPrice && d_res.data.auction.startPrice != null && d_res.data.auction.startPrice
                                //         const auctionDetails = d_res && d_res.data && d_res.data.auction && d_res.data.auction.auctionDetails
                                //         if (auctionDetails && auctionDetails.length > 0) {
                                //             var final_am = Math.round(((auctionDetails && auctionDetails[0] && auctionDetails[0].bidAmount) + parseFloat(bidIncrement)) * 1e12) / 1e12
                                //         }
                                //         else {
                                //             var final_am = Math.round((Number(startingPrice) + (bidIncrement)) * 1e12) / 1e12

                                //         }
                                //         this.fetchArt(res && res.data && res.data.thumbnailHash)

                                //         this.setState({
                                //             showAuction: true,
                                //             isMyAuction: false,
                                //             bidList: res && res.data,
                                //             bidDetailList: d_res && d_res.data,
                                //             isLoader: true,
                                //             itsMine: false,
                                //             isDrop: false,
                                //             dropList: [],
                                //             myArt: [],
                                //             bidAmount: ((final_am)),
                                //             isOwnershipFailed: '',
                                //             ownerShipDetail: []
                                //         })
                                //     }
                                // } else {
                                    //self created drop
                                    if (d_res && d_res.data && d_res.data.markedPrice && d_res.data.markedPrice != null) {
                                        if ((d_res && d_res.data && d_res.data.isOwner)) {
                                            myArt = res && res.data
                                            detailMyArt = d_res && d_res.data

                                            let arr = (myArt && myArt.dropsCount);
                                            arr = arr && '[' + arr + ']'
                                            let myDropArray = detailMyArt && detailMyArt.listedCount

                                            let tokensArray = detailMyArt && detailMyArt.tokensInDrop

                                            let totalOwnerArt = this.getOwnerCount((myArt && myArt.artOwners), (detailMyArt && detailMyArt.user))
                                            this.fetchArt(res && res.data && res.data.thumbnailHash)

                                            this.setState({
                                                myArt,
                                                detailMyArt,
                                                itsMine: true,
                                                totalOwnerArt,
                                                dropList: [],
                                                artList: [],
                                                dropsCount: myDropArray,
                                                tokensArray,
                                                myAuctionList: [],
                                                bidList: [],
                                                showAuction: false,
                                                isMyAuction: false,
                                                changedPrice: (detailMyArt && detailMyArt.markedPrice && flowDecimal(parseFloat(detailMyArt.markedPrice))),
                                                isOwnershipFailed: '',
                                                ownerShipDetail: [],
                                                isDrop: false,
                                            })
                                        }

                                        else {

                                            //not self listed art but in drops
                                            dropList = res && res.data
                                            detailDropList = d_res && d_res.data
                                            // let arr = (dropList && dropList.dropsCount);
                                            // arr = arr && '[' + arr + ']'
                                            // let dropsCountarray = arr ? eval(arr && arr) : []
                                            let myDropArray = detailDropList && detailDropList.listedCount
                                            // let remainArray = []
                                            // let count = []
                                            // let total = 0
                                            // dropsCountarray && dropsCountarray.map((items) => {
                                            //     if ((items && items.tokens && items.tokens.includes(tokenId))) {
                                            //         myDropArray = items
                                            //     } else {
                                            //         remainArray.push(items)
                                            //     }
                                            //     if (items) {
                                            //         var len = (items && items.count)
                                            //         count.push(len)
                                            //     }
                                            //     function mySum(total, num) {
                                            //         return Number(total) + Number(num);
                                            //     }
                                            //     total = count.reduce(mySum)
                                            // })
                                            let totalOwnerArt = this.getOwnerCount((dropList && dropList.artOwners), (detailDropList && detailDropList.user))
                                            let tokensArray = detailDropList && detailDropList.tokensInDrop


                                            markedPrice = d_res && d_res.data && d_res.data.markedPrice && d_res.data.markedPrice.toFixed(8)




                                            this.fetchArt(res && res.data && res.data.thumbnailHash)


                                            this.setState({
                                                dropList,
                                                detailDropList,
                                                dropsCount: myDropArray,
                                                isDrop: true,
                                                myArt: [],
                                                artPrice: markedPrice,
                                                totalOwnerArt,
                                                tokensArray,
                                                isShowLoader: true,
                                                myAuctionList: [],
                                                bidList: [],
                                                showAuction: false,
                                                isMyAuction: false,
                                                isOwnershipFailed: '',
                                                ownerShipDetail: [],
                                                itsMine: false,
                                            }, () => {
                                                //call dapper connection once user come from login page
                                                if (this.state.callDapperWallet) {
                                                    this.handleDapperConnectWallet()
                                                    this.setState({ callDapperWallet: false })
                                                }
                                            })
                                        }



                                    } else {
                                        this.clearCelebrationTimeout()

                                        //if it is in not for sale
                                        artList = res && res.data
                                        let arr = (artList && artList.dropsCount)
                                        arr = arr && '[' + arr + ']'
                                        let dropsCountarray = arr ? eval(arr && arr) : []
                                        var count = []
                                        var dropArrCount = 0;
                                        var val = artList && artList.totalTokenId ? artList.totalTokenId : ''
                                        var tokens = val.split(",").length
                                        dropsCountarray && dropsCountarray.map((items) => {
                                            if (items) {
                                                var len = (items && items.tokens).split(",").length
                                                count.push(len)
                                            }
                                            function mySum(total, num) {
                                                return total + num;
                                            }
                                            dropArrCount = count.reduce(mySum)
                                        })

                                        let totalEdition = tokens - (dropArrCount)

                                        let totalOwnerArt = artList && artList.totalOwnerArt

                                        this.setState({
                                            artList,
                                            isDrop: false,
                                            dropList: [],
                                            myArt: [],
                                            itsMine: false,
                                            totalOwnerArt,
                                            dropsCount: dropsCountarray,
                                            totalEdition,
                                            isMySale: false,
                                            isShowLoader: true,
                                            myAuctionList: [],
                                            bidList: [],
                                            showAuction: false,
                                            isMyAuction: false,
                                            isOwnershipFailed: '',
                                            ownerShipDetail: []
                                        })


                                    }

                                // }
                            } else {
                                console.log(d_res)
                                if (d_res && d_res.statusCode === 400) {
                                    if (d_res && d_res.message && d_res.message.toLowerCase().includes("has been hidden by the owner")) {
                                        this.setState({
                                            isArtDisabled: true,
                                            disabledMessage: "This art has been hidden"
                                        })
                                    } else if (d_res && d_res.message && d_res.message.toLowerCase().includes("has been disabled by the administrator")) {
                                        this.setState({
                                            isArtDisabled: true,
                                            disabledMessage: "This art has been disabled by the administrator"
                                        })
                                    } else if (d_res && d_res.message && d_res.message.toLowerCase().includes("in request are either missing or invalid")) {
                                        this.setState({
                                            isInvalidPage: true,
                                            isArtDisabled: false,
                                        })
                                    } else if (d_res && d_res.message && d_res.message.toLowerCase().includes("could not be found")) {
                                        this.setState({
                                            isInvalidPage: true,
                                            isArtDisabled: false,
                                        })
                                    } else {
                                        this.setState({
                                            isArtDisabled: true,
                                            disabledMessage: d_res && d_res.message
                                        })
                                    }
                                }
                            }
                            this.txnFunction(tokenId, userId)
                            this.getAttributes(res && res.data && res.data.metadataJsonUrl)
                            this.getAvailableBadges()
                        }).catch(err => {
                            this.hideLoader()
                            this.setState({
                                artList,
                                isDrop: false,
                                dropList: [],
                                myArt: [],
                                itsMine: false,
                                totalOwnerArt: 0,
                                dropsCount: '',
                                totalEdition: 0,
                                isMySale: false,
                                isShowLoader: false,
                                myAuctionList: [],
                                bidList: [],
                                detailDropList: [],
                                showAuction: false,
                                isMyAuction: false,
                                isOwnershipFailed: '',
                                ownerShipDetail: [],
                                detailMyArt: [],
                                detailMyAuctionList: [],
                                bidDetailList: []
                            })
                            if (err && typeof err === "string" && err.toLowerCase().includes('requested resource could not be found')) {
                                this.setState({
                                    isInvalidPage: true
                                })
                            } else if (err && typeof err === "string" && err.toLowerCase().includes('in request are either missing or invalid')) {
                                this.setState({
                                    isInvalidPage: true
                                })
                            } else if (err && typeof err === "string" && err.toLowerCase().includes("has been hidden by the owner")) {
                                this.setState({
                                    isInvalidPage: false,
                                    isArtDisabled: true,
                                    disabledMessage: "This art has been hidden"
                                })
                            } else if (err && typeof err === "string" && err.toLowerCase().includes("has been disabled by the administrator")) {
                                this.setState({
                                    isInvalidPage: false,
                                    isArtDisabled: true,
                                    disabledMessage: "This art has been disabled by the administrator"
                                })
                            } else {
                                this.setState({
                                    isInvalidPage: false,
                                    isArtDisabled: true,
                                    disabledMessage: err.toString()
                                })
                            }

                            console.log(err)
                        })

                    }


                } else {
                    if (res && res.statusCode === 400) {
                        if (res && res.message && res.message.toLowerCase().includes("has been hidden by the owner")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been hidden"
                            })
                        } else if (res && res.message && res.message.toLowerCase().includes("has been disabled by the administrator")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been disabled by the administrator"
                            })
                        } else if (res && res.message && res.message.toLowerCase().includes("in request are either missing or invalid")) {
                            this.setState({
                                isInvalidPage: true,
                                isArtDisabled: false,
                            })
                        } else if (res && res.message && res.message.toLowerCase().includes("could not be found")) {
                            this.setState({
                                isInvalidPage: true,
                                isArtDisabled: false,
                            })
                        } else {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: res && res.message
                            })
                        }
                    }

                }
            }).catch(derr => {
                this.hideLoader()
                this.setState({
                    artList,
                    isDrop: false,
                    dropList: [],
                    myArt: [],
                    itsMine: false,
                    totalOwnerArt: 0,
                    dropsCount: '',
                    totalEdition: 0,
                    isMySale: false,
                    isShowLoader: false,
                    myAuctionList: [],
                    bidList: [],
                    detailDropList: [],
                    showAuction: false,
                    isMyAuction: false,
                    isOwnershipFailed: '',
                    ownerShipDetail: [],
                    detailMyArt: [],
                    detailMyAuctionList: [],
                    bidDetailList: []
                })
                const err = derr ? typeof derr === "string" ? derr.toLowerCase() : derr.toString().toLowerCase() : ''
                if (err && err.includes('requested resource could not be found')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.includes('in request are either missing or invalid')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.includes("has been hidden by the owner")) {
                    this.setState({
                        isInvalidPage: false,
                        isArtDisabled: true,
                        disabledMessage: "This art has been hidden"
                    })
                } else if (err && err.includes("has been disabled by the administrator")) {
                    this.setState({
                        isInvalidPage: false,
                        isArtDisabled: true,
                        disabledMessage: "This art has been disabled by the administrator"
                    })
                } else {
                    this.setState({
                        isInvalidPage: false,
                        isArtDisabled: true,
                        disabledMessage: err
                    })
                }

                console.log(err)
            })



            this.setState({ id })
        }

    }
    txnFunction = (tokenId, userId) => {
        let artTxnHistoy = []
        artServices.getArtTransactionHistory(tokenId)
            .then((res) => {
                transactions = res && res.data && res.data.addressDetails
                artTxnHistoy = res && res.data && res.data.addressDetails && res.data.addressDetails.transactions
                const art = res && res.data && res.data.addressDetails && res.data.addressDetails
                let artCreator, artOwner = null;
                if ((artTxnHistoy).length) {
                    if (art.creatorWalletAddress !== artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress) {
                        artCreator = {
                            id: art.creator.id,
                            userAddress: art.creatorWalletAddress,
                            userName: art.creator.userName,
                            roleId: art.creator.roleId
                        };
                    } else {
                        artCreator = {
                            id: artTxnHistoy[artTxnHistoy.length - 1].owner.id,
                            userAddress: artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress,
                            userName: artTxnHistoy[artTxnHistoy.length - 1].owner.userName,
                            roleId: artTxnHistoy[artTxnHistoy.length - 1].owner.roleId
                        };
                    }
                    artOwner = {
                        id: artTxnHistoy[0].owner.id,
                        userAddress: artTxnHistoy[0].ownerWalletAddress,
                        userName: artTxnHistoy[0].owner.userName,
                        roleId: artTxnHistoy[0].owner.roleId
                    };
                }
                this.setState({ artTxnHistoy, artCreator, artOwner, transactions, isActivityCall: true });
            })
            .catch((err) => {
                artTxnHistoy = [];
                this.setState({ artTxnHistoy });
            });
        this.props.getRelativeArts(tokenId, userId)
    }
    componentDidUpdate(props, state) {
        let nft = configPath.basename
        if (window.location.pathname.includes('nft')) {
            if ((nft + (props && props.location && props.location.pathname)) != (window.location.pathname)) {
                this.getDrops()
                this.resetDisableState()
            } else if (((props && props.location && props.location.hash)) != (window.location.hash)) {
                this.getDrops()
                this.resetDisableState()

            }
        } else if ((props && props.location && props.location.pathname) != (window.location.pathname)) {
            this.getDrops()
            this.resetDisableState()


        } else if ((props && props.location && props.location.hash) != (window.location.hash)) {
            this.getDrops()
            this.resetDisableState()


        }
        //Visibility status change
        if ((this.props && this.props.artVisibilityStatusChangedFailed && this.props.artVisibilityStatusChangedFailed.length > 0) && (this.props.artVisibilityStatusChangedFailed) != (props.artVisibilityStatusChangedFailed)) {
            this.props.resetVisibilityStatusFailed()
            this.setState({ isAlert: true, message: this.props.artVisibilityStatusChangedFailed, style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if ((this.props && this.props.artVisibilityStatusChangesSuccess && this.props.artVisibilityStatusChangesSuccess.statusCode === 200) && (this.props.artVisibilityStatusChangesSuccess) != (props.artVisibilityStatusChangesSuccess)) {
            this.props.resetVisibityStatusSuccess()
            this.getDrops()
            this.resetDisableState()

            this.setState({
                isMyartStatusChangeSuccess: true
            })
        }
        //Report response
        if (this.props && this.props.reportSent && this.props.reportSent.statusCode === 201 && (this.props.reportSent !== (props.reportSent))) {
            this.props.resetSendReport()
            this.setState({
                isAlert: true, message: "Report Sent", style: "success"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.reportFailed && this.props.reportFailed.length > 0 && (this.props.reportFailed !== (props.reportFailed))) {
            this.props.resetSendReportFailed()
            this.setState({
                isAlert: true, message: this.props.reportFailed, style: "danger"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode) &&
            (this.state.isForAirdropClaim)) {
            props.resetlinkWallet()
            this.setState({
                isForAirdropClaim: false
            })
            this.createaccountSetup()
        }

        //Get Followers list
        if ((this.props && this.props.followersList && this.props.followersList.statusCode === 200 &&
            (props.followersList !== (this.props.followersList)))) {
            this.followResponse(this.props.followersList)

            this.props.resetGetFollowers()
        }
        // Failed
        if (this.props &&
            this.props.getFollowerFailed &&
            this.props.getFollowerFailed.length > 0 &&
            (this.props.getFollowerFailed !== (props.getFollowerFailed))) {
            this.props.resetGetFollowersFailed()
            this.setState({
                isAlert: true, message: `Couldn't get followers detail ${this.props.getFollowerFailed}`, style: "danger", isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }


        // follow user
        if (this.props && this.props.userFollower && this.props.userFollower.statusCode === 200 &&
            (this.props.userFollower !== (props.userFollower))) {
            this.setState({
                isAirDrop: true,
                isAirdropClaimMessage: ((this.props.userFollower.data && this.props.userFollower.data.message) ? this.props.userFollower.data.message : ''),
            })
            if (this.props.userFollower.data && this.props.userFollower.data.user && this.props.userFollower.data.user.userName && this.isQueryStringAvailable()) {
                this.setState({
                    followerSuccessMessage: ((this.props.userFollower.data.user.userName) ? `You are now following the Creator @${this.props.userFollower.data.user.userName}` : 'You are now following the user')

                })
            }
            // this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            this.props.resetFollow()
            // after follow
            if (isConnectWallet()) {
                this.claimArt(this.state.selectedAirdrop) // wallet
            } else {
                this.claimWalletlessArt(this.state.selectedAirdrop) // walletless

            }

        }

        //Follow user failed
        if ((this.props && this.props.failedFollow && this.props.failedFollow.length > 0) &&
            (this.props.failedFollow !== (props.failedFollow))) {
            this.props.resetFailedFollow()
            this.setState({
                isAlert: true,
                message: this.props.failedFollow,
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.state.artList && this.state.artList.id && this.state.isCheckAirdrop) {
            this.setState({ isCheckAirdrop: false })
            if (this.isQueryStringAvailable()) { // first click to check #airdrop query was available
                if (this.state.artList && this.state.artList.airDrops && this.state.artList.airDrops[0]) {
                    this.setState({ isWarning: false })
                } else {
                    this.setState({ isWarning: true })

                }
            }
        }
        if ((this.props && this.props.addedToFav && this.props.addedToFav.statusCode === 200 &&
            (props.addedToFav !== (this.props.addedToFav)))) {

            this.props.resetAddFav()
            this.getFavArt()
            this.setState({
                isAlert: true, message: "Art added to your favourite list", style: "success"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        // Failed
        if (this.props &&
            this.props.addToFavFailed &&
            this.props.addToFavFailed.length > 0 &&
            (this.props.addToFavFailed !== (props.addToFavFailed))) {
            this.props.resetAddFavFailed()
            this.setState({
                isAlert: true, message: this.props.addToFavFailed, style: "danger"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if ((this.props && this.props.favby && this.props.favby.statusCode === 200 &&
            (props.favby !== (this.props.favby)))) {

            this.props.resetGetFav()
            if (this.props.favby && this.props.favby.data && this.props.favby.data.arts && this.props.favby.data.arts.length > 0) {
                this.checkIsFavourited(this.props.favby.data.arts)
            } else {
                this.setState({ favouritedUser: [], isFavourite: false })
            }
        }
        // Failed
        if (this.props &&
            this.props.favbyFailed &&
            this.props.favbyFailed.length > 0 &&
            (this.props.favbyFailed !== (props.favbyFailed))) {
            this.props.resetAddFavFailed()
            this.setState({ favouritedUser: [] })
        }

        if ((this.props && this.props.removeFav && this.props.removeFav.statusCode === 200 &&
            (props.removeFav !== (this.props.removeFav)))) {

            this.props.resetRemoveFav()
            this.getFavArt()
            this.setState({
                isAlert: true, message: "Art removed from your favourite list", style: "success"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        // Failed
        if (this.props &&
            this.props.removeFavFailed &&
            this.props.removeFavFailed.length > 0 &&
            (this.props.removeFavFailed !== (props.removeFavFailed))) {
            this.props.resetRemoveFavFailed()
            this.setState({
                isAlert: true, message: this.props.removeFavFailed, style: "danger"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        //dapper wallet connection
        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode) &&
            (this.state.isDapperPurchase)) {
            props.resetlinkWallet()
            this.setState({
                isDapperPurchase: false
            })
            this.handlePurchase()
        }
        //withdraw
        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode) &&
            (this.state.isForWithdrawArt)) {
            props.resetlinkWallet()
            this.setState({
                isForWithdrawArt: false
            })
            this.checkaccountSetup()

        }

        if (((this.props &&
            this.props.walletLinkError &&
            this.props.walletLinkError.length > 0)) &&
            (props.walletLinkError) != (this.props.walletLinkError) &&
            (this.state.isForAirdropClaim)) {
            this.props.resetWalletLinkErr()
            this.setState({ isAlert: true, message: `Couldn't connect wallet properly : ${this.props.walletLinkError}`, style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            this.setState({
                isForAirdropClaim: false, isAirDrop: false
            })

        }
    }
    getDropById = (id) => {
        let userAddress = ""
        let tokenId = ""
        let dropList = []
        this.showLoader()
        dropServices.getDetailDropArt(id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) == 200) {
                userAddress = res && res.data && res.data.art && res.data.art.ownerUserAddress
                tokenId = res && res.data && res.data.tokenId
                dropList = res && res.data
            }
            this.props.getRelativeArts(tokenId, userAddress)
            this.setState({ dropList })

        }).catch(err => {
            this.hideLoader()
        })
    }
    static getDerivedStateFromProps(props, state) {
        // let rate = ""
        if (((props && props.wallet && props.wallet.statusCode) == 200) && (state.iswallet)) {
            props.resetlinkWallet()
            window.location.reload()

        }



        if (props && props.detailList) {

            return {
                detailList: props.detailList && props.detailList.data,

                // rate: rate

            }
        }

        if ((props && props.relativeArts && props.relativeArts.statusCode) == 200) {
            props.resetRelativeArts()

            return {
                relativeArts: props.relativeArts && props.relativeArts.data && props.relativeArts.data.entity ? props.relativeArts.data.entity : [],

                // rate: rate
            }
        }

        return null;
    }

    clickRelative = (items) => {
        this.gotoTop()
        if ((items && items.drops && items.drops[0] && items.drops[0].id)) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else {
            history.push(`/art/${items && items.id}`)
        }


    }

    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    gotoCreatorForArtOwners = (owner) => {
        const art_id = this.props && this.props.match && this.props.match.params && this.props.match.params.id
        if ((owner && owner.drop && owner.drop.id)) {
            history.push(`/art/${art_id}#drop${owner.drop.id}`)
        }
        else {
            if ((owner && owner.ownerUserId)) {
                history.push(`/art/${art_id}#collector-${owner.ownerUserId}`)
            }
        }
    }

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    getBalance = () => {

        userBalance().then(text => {
            if ((text && text.balance && text.balance.status === 200)) {
                if (text && text.balance && text.balance.data && text.balance.data.Value) {
                    this.setState({ balance: JSON.parse(text.balance.data.Value) })

                }
            } else {
                this.setState({ balance: "0.0" })
            }
        }).catch(err => { })

    }
    handleResale1 = async () => {
        const { tokensArray, totalOwnerArt, count, myArt, detailMyArt } = this.state
        let tokenIds = []
        const ownerWalletAddress = detailMyArt && detailMyArt.art && detailMyArt.art.ownerWalletAddress

        let arrayLength = 0
        let checkCount = 0
        tokenIds.push(this.state.tokenId)

        if ((totalOwnerArt == 1) && (tokensArray && tokensArray.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((totalOwnerArt > 1) && (tokensArray && tokensArray.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.count
            arrayLength = tokensArray && tokensArray.length

        }
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {
            let selectedTokens = (tokensArray && tokensArray.length > 0 && (totalOwnerArt > 1)) ? tokensArray.slice(0, checkCount) : tokenIds
            const currentuserAddress = await getUserAddress()

            if ((checkWalletAddress(ownerWalletAddress, (currentuserAddress)))) {
                this.showLoader()
                walletServices.checkWallet(currentuserAddress).then(wallet => {
                    this.hideLoader()
                    if ((wallet && wallet.statusCode) == 200) {
                        this.setState({ disableWithdraw: true })
                        this.setState({ isTransLoader: true })
                        dropServices.saveEvent(
                            (detailMyArt.art && detailMyArt.art.tokenGroupId),
                            selectedTokens.length,
                            dropServices.DropEventType.WITHDRAW,
                            (detailMyArt.art && detailMyArt.art.tokenId)
                        )
                            .then((evtRes) => {
                                if (evtRes === true) {
                                    marketSaleWithdrawn(selectedTokens).then(res => {
                                        console.log("withdrawn res", res)
                                        this.setState({ isTransLoader: false })
                                        if ((res && res.status) == 200) {

                                            if (res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                                this.setState({ disableWithdraw: false })

                                            } else {
                                                this.setState({ isWithDrawn: true })
                                            }
                                        } else {
                                            this.setState({ disableWithdraw: false })

                                            let mes = this.getTxnError(res?.data?.Error);
                                            this.setState({ isAlert: true, message: mes, style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()

                                        }
                                    }).catch(err => {
                                        this.setState({ disableWithdraw: false })
                                        this.setState({ isTransLoader: false })
                                        this.setState({ isAlert: true, message: String(err), style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                    })
                                } else {
                                    this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                }
                            }).catch((err) => {
                                this.setState({ disableWithdraw: false })
                                this.setState({ isTransLoader: false })
                                this.setState({ isAlert: true, message: String(err), style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            })
                    }
                }).catch(err => {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    this.setState({ disableWithdraw: false })
                })
            } else {
                this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger", count: '' })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
                this.setState({ disableWithdraw: false })
            }
        } else {
            this.setState({ isAlert: true, message: 'Please enter valid count', style: "danger", count: '' })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            this.setState({ disableWithdraw: false })

        }

    }

    priceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0 && (this.digitValidate(e.target.value)) && (res.length == 1 || res[1].length < 6)) {
            const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

            this.setState({ changedPrice: e.target.value, usd: rate ? rate.toFixed(2) : '' })
        } else {
            this.setState({ isAlert: true, message: 'Please enter valid price', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    getTxnError(error) {
        return (error && error.split(':') && error.split(':')[2] && error.split(':')[2].includes("Session expired")) ? ("Session Expired.Please reconnect your wallet account") : (error.split(':')[2]) ? (error.split(':')[2]) : (error.split(':')[1])
    }


    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    checkBalance = () => {
        this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => { history.push('/fund-wallet') }} style={{ color: "blue", cursor: "pointer" }}> add funds</a> into your wallet</div>, style: "danger" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    closePurchase = () => {
        this.setState({ isPurchase: false, purchaseCount: '' })
    }

    openPurchase = () => {
        const { artPrice, balance } = this.state
        if ((balance != null && parseFloat(balance)) > (artPrice != null && parseFloat(artPrice))) {

            this.setState({ isPurchase: true })
        } else {
            this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => { history.push('/fund-wallet') }} style={{ color: "blue", cursor: "pointer" }}> add funds</a> into your wallet</div>, style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    getListUser(user) {
        if (user) {
            if ((user.id != null) && (user.roleId == 1)) {
                return (<Link className="usernameblue" to={{ pathname: `/creator/@${user.userName && encodeURIComponent(user.userName)}`, state: { obj: { userName: user && user.userName } } }} >{((user.userName && user.userName.includes('@')) ? user.userName : (`@${user.userName}`))}</Link>);
            }
            return <Link className="usernameblue" to={`/collector/@${user.userName && encodeURIComponent(user.userName)}`}>{((user.userName && user.userName.includes('@')) ? user.userName : (`@${user.userName}`))}</Link>

        }
    }
    getArtOwner() {
        const { artOwner, dropList, detailDropList, artList, bidList, bidDetailList, detailMyAuctionList, myAuctionList } = this.state;
        if (artOwner) {
            if (artOwner.userName) {
                if (artOwner.id != null && (artOwner.roleId == 1)) {
                    return (<Link to={{ pathname: `/creator/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`, state: { obj: { userName: artOwner && artOwner.userName } } }} >{((artOwner.userName && artOwner.userName.includes('@')) ? artOwner.userName : (`@${artOwner.userName}`))}</Link>);
                } return <Link to={`/collector/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`}>{((artOwner.userName && artOwner.userName.includes('@')) ? artOwner.userName : (`@${artOwner.userName}`))}</Link>
            } else {
                if (artOwner.id != null && (artOwner.roleId == 1)) {
                    return (<Link to={`/creator/${artOwner.id}`}>{artOwner.userAddress}</Link>);
                }
                return (<Link to={`/collector/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`}>{artOwner.userAddress}</Link>);
            }
        } else {

            if (detailDropList && detailDropList.user && detailDropList.user.userName) {
                if (detailDropList.user.id != null && (detailDropList && detailDropList.user && detailDropList.user.roleId == 1)) {

                    return (<Link to={{ pathname: `/creator/@${detailDropList.user.userName && encodeURIComponent(detailDropList.user.userName)}`, state: { obj: { userName: detailDropList.user.userName } } }}>{((detailDropList.user.userName && detailDropList.user.userName.includes('@')) ? detailDropList.user.userName : (`@${detailDropList.user.userName}`))}</Link>);
                }

                return (<Link to={{ pathname: `/collector/@${detailDropList.user.userName && encodeURIComponent(detailDropList.user.userName)}`, state: { obj: { userName: detailDropList.user.userName } } }}>{((detailDropList.user.userName && detailDropList.user.userName.includes('@')) ? detailDropList.user.userName : (`@${detailDropList.user.userName}`))}</Link>);
            } else if (detailDropList && detailDropList.art && detailDropList.art.ownerWalletAddress) {

                if (detailDropList.user && detailDropList.user.id != null) {

                    return detailDropList.art.ownerWalletAddress

                }
                return detailDropList.art.ownerWalletAddress;
            } else if (artList && artList.owner && artList.owner.userName) {
                if (artList.owner.id != null && (artList && artList.owner && artList.owner.roleId == 1)) {

                    return (<Link to={{ pathname: `/creator/@${artList.owner.userName && encodeURIComponent(artList.owner.userName)}`, state: { obj: { userName: artList.owner.userName } } }}>{((artList.owner.userName && artList.owner.userName.includes('@')) ? artList.owner.userName : (`@${artList.owner.userName}`))}</Link>);
                }

                return (<Link to={{ pathname: `/collector/@${artList.owner.userName && encodeURIComponent(artList.owner.userName)}`, state: { obj: { userName: artList.owner.userName } } }}>{((artList.owner.userName && artList.owner.userName.includes('@')) ? artList.owner.userName : (`@${artList.owner.userName}`))}</Link>);
            } else if (artList && artList.ownerWalletAddress) {

                if (artList.owner && artList.owner.id != null) {

                    return artList.ownerWalletAddress;
                }
                return artList.ownerWalletAddress;
            } else if (bidDetailList && bidDetailList.user && bidDetailList.user.userName) {
                if (bidDetailList.user.id != null && (bidDetailList && bidDetailList.user && bidDetailList.user.roleId == 1)) {

                    return (<Link to={{ pathname: `/creator/@${bidDetailList.user.userName && encodeURIComponent(bidDetailList.user.userName)}`, state: { obj: { userName: bidDetailList.user.userName } } }}>{((bidDetailList.user.userName && bidDetailList.user.userName.includes('@')) ? bidDetailList.user.userName : (`@${bidDetailList.user.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${bidDetailList.user.userName && encodeURIComponent(bidDetailList.user.userName)}`, state: { obj: { userName: bidDetailList.user.userName } } }}>{((bidDetailList.user.userName && bidDetailList.user.userName.includes('@')) ? bidDetailList.user.userName : (`@${bidDetailList.user.userName}`))}</Link>);
            } else if (bidList && bidList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (bidList.owner && bidList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${dropList.owner.id}`}>{dropList.ownerWalletAddress}</Link>);
                    return bidList.ownerWalletAddress

                }

                // return user address if owner id is not present.
                return bidList.ownerWalletAddress;
            } else if (detailMyAuctionList && detailMyAuctionList.user && detailMyAuctionList.user.userName) {
                if (detailMyAuctionList.user.id != null && (detailMyAuctionList && detailMyAuctionList.user && detailMyAuctionList.user.roleId == 1)) {


                    // Return Link to profile if droplist has owner id.
                    return (<Link to={{ pathname: `/creator/@${detailMyAuctionList.user.userName && encodeURIComponent(detailMyAuctionList.user.userName)}`, state: { obj: { userName: detailMyAuctionList.user.userName } } }}>{((detailMyAuctionList.user.userName && detailMyAuctionList.user.userName.includes('@')) ? detailMyAuctionList.user.userName : (`@${detailMyAuctionList.user.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${detailMyAuctionList.user.userName && encodeURIComponent(detailMyAuctionList.user.userName)}`, state: { obj: { userName: detailMyAuctionList.user.userName } } }}>{((detailMyAuctionList.user.userName && detailMyAuctionList.user.userName.includes('@')) ? detailMyAuctionList.user.userName : (`@${detailMyAuctionList.user.userName}`))}</Link>);
            } else if (myAuctionList && myAuctionList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (myAuctionList.owner && myAuctionList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${dropList.owner.id}`}>{dropList.ownerWalletAddress}</Link>);
                    return myAuctionList.ownerWalletAddress

                }

                // return user address if owner id is not present.
                return myAuctionList.ownerWalletAddress;
            }
            else {
                return 'N/A'
            }
        }
    }

    getArtCreator() {
        const { artCreator, dropList, artList, bidList, myAuctionList } = this.state;
        if (artCreator) {
            if (artCreator.userName) {
                if (artCreator.id != null && (artCreator.roleId == 1)) {
                    // Return user name when it is not null.
                    return (<Link to={{ pathname: `/creator/@${artCreator.userName && encodeURIComponent(artCreator.userName)}`, state: { obj: { userName: artCreator && artCreator.userName } } }}>{(artCreator.userName && artCreator.userName.includes('@') ? artCreator.userName : (`@${artCreator.userName}`))}</Link>);
                }
                return (<Link to={`/collector/@${artCreator.userName && encodeURIComponent(artCreator.userName)}`}>{(artCreator.userName && artCreator.userName.includes('@') ? artCreator.userName : (`@${artCreator.userName}`))}</Link>);
            } else {
                if (artCreator.id != null && (artCreator.roleId == 1)) {
                    // return user address when user name is null. 
                    return (<Link to={`/creator/${artCreator.id}`}>{artCreator.userAddress}</Link>);
                }
                return (<Link to={`/collector/${artCreator.id}`}>{artCreator.userAddress}</Link>);


                // return user address when user name is null. 
            }
        } else {

            // Use droplist data when artCreator is not available


            // return user name when it is not null
            if (dropList && dropList.creator && dropList.creator.userName) {
                if (dropList.creator.id && dropList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${dropList.creator.userName && encodeURIComponent(dropList.creator.userName)}`, state: { obj: { userName: dropList.creator.userName } } }}>{((dropList.creator.userName && dropList.creator.userName.includes('@')) ? dropList.creator.userName : (`@${dropList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${dropList.creator.userName && encodeURIComponent(dropList.creator.userName)}`}>{((dropList.creator.userName && dropList.creator.userName.includes('@')) ? dropList.creator.userName : (`@${dropList.creator.userName}`))}</Link>);
            } else if (dropList && dropList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (dropList.creator && dropList.creator.id && dropList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return dropList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return dropList.creatorWalletAddress;

            } else if (artList && artList.creator && artList.creator.userName) {
                if (artList.creator.id && artList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${artList.creator.userName && encodeURIComponent(artList.creator.userName)}`, state: { obj: { userName: artList.creator.userName } } }}>{((artList.creator.userName && artList.creator.userName.includes('@')) ? artList.creator.userName : (`@${artList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${artList.creator.userName && encodeURIComponent(artList.creator.userName)}`}>{((artList.creator.userName && artList.creator.userName.includes('@')) ? artList.creator.userName : (`@${artList.creator.userName}`))}</Link>);
            }
            else if (artList && artList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (artList.creator && artList.creator.id && artList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${artList.creator.id}`}>{artList.creatorWalletAddress}</Link>);
                    return artList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${artList.creator.id}`}>{artList.creatorWalletAddress}</Link>);
                return artList.creatorWalletAddress;

            } else if (bidList && bidList.creator && bidList.creator.userName) {
                if (bidList.creator.id && bidList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${bidList.creator.userName && encodeURIComponent(bidList.creator.userName)}`, state: { obj: { userName: bidList.creator.userName } } }}>{((bidList.creator.userName && bidList.creator.userName.includes('@')) ? bidList.creator.userName : (`@${bidList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${bidList.creator.userName && encodeURIComponent(bidList.creator.userName)}`}>{((bidList.creator.userName && bidList.creator.userName.includes('@')) ? bidList.creator.userName : (`@${bidList.creator.userName}`))}</Link>);
            } else if (bidList && bidList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (bidList.creator && bidList.creator.id && bidList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return bidList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return bidList.creatorWalletAddress;

            } else if (myAuctionList && myAuctionList.creator && myAuctionList.creator.userName) {
                if (myAuctionList.creator.id && myAuctionList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${myAuctionList.creator.userName && encodeURIComponent(myAuctionList.creator.userName)}`, state: { obj: { userName: myAuctionList.creator.userName } } }}>{((myAuctionList.creator.userName && myAuctionList.creator.userName.includes('@')) ? myAuctionList.creator.userName : (`@${myAuctionList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${myAuctionList.creator.userName && encodeURIComponent(myAuctionList.creator.userName)}`}>{((myAuctionList.creator.userName && myAuctionList.creator.userName.includes('@')) ? myAuctionList.creator.userName : (`@${myAuctionList.creator.userName}`))}</Link>);
            } else if (myAuctionList && myAuctionList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (myAuctionList.creator && myAuctionList.creator.id && myAuctionList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return myAuctionList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return myAuctionList.creatorWalletAddress;

            }
            else {
                return 'N/A'
            }
        }
    }

    getArtTransactions() {
        const { artTxnHistoy, isActivityCall } = this.state;

        const txnHistory = transactions && transactions.transactions
        if ((txnHistory && txnHistory.length)) {
            const txnRows = (txnHistory).map((item, index) => {
                if (index !== ((txnHistory.length) - 1)) {

                    // return user name when it is not null
                    if ((item && item.owner && item.owner.userName)) {
                        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                            return (
                                <div className="row mb-3">
                                    <div className="col-6 col-sm-6 col-md-8">
                                        <h5 className="mb-0 acvticotxtbox">
                                            <span className="acvticon">
                                                {
                                                    (item &&
                                                        item.priceDetail &&
                                                        item.priceDetail.auctionId != null
                                                    ) ? (
                                                        <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                    ) :
                                                        (item &&
                                                            item.priceDetail &&
                                                            item.priceDetail.dropSellingPrice != null
                                                        ) ? (
                                                            <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                        ) :
                                                            (
                                                                <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                            )
                                                }
                                            </span>
                                            <span className="acvttext">
                                                {
                                                    (
                                                        item &&
                                                        item.airDropDetail &&
                                                        item.airDropDetail.airDropId
                                                    ) ? "Claimed by"
                                                        : "Purchased by"
                                                } {((item && item.owner && item.owner.email === "hidden")) ? <span><i className="fa fa-user-secret" aria-hidden="true"></i> {(item.owner.userName)}</span> : <Link title={item && item.owner && item.owner.userName} to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link>}&nbsp;
                                                {(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice) ?
                                                    <span> for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)} className="usernamelilac d-inline-block"> {(flowDecimal(parseFloat((item && item.priceDetail !== null && item.priceDetail.dropSellingPrice))) + " " + this.showCurrencyValue(item))}</p> &nbsp;
                                                    </span> :
                                                    (item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice) ?
                                                        <span>
                                                            for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)} className="usernamelilac d-inline-block"> {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")}</p> &nbsp;
                                                        </span>
                                                        : ''}
                                            </span>
                                        </h5></div>
                                    <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>

                                </div>
                            )
                        }
                        return (
                            <div className="row mb-3">
                                <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                    <span className="acvticon">
                                        {
                                            (item &&
                                                item.priceDetail &&
                                                item.priceDetail.auctionId != null
                                            ) ? (
                                                <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            ) :
                                                (item &&
                                                    item.priceDetail &&
                                                    item.priceDetail.dropSellingPrice != null
                                                ) ? (
                                                    <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                ) :
                                                    (
                                                        <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                    )
                                        }
                                    </span>
                                    <span className="acvttext">
                                        {
                                            (
                                                item &&
                                                item.airDropDetail &&
                                                item.airDropDetail.airDropId
                                            ) ? "Claimed by"
                                                : "Purchased by"
                                        }  {(item && item.owner && item.owner.email === "hidden") ? <span><i className="fa fa-user-secret" aria-hidden="true"> </i> {(item.owner.userName)}</span> : <Link title={item && item.owner && item.owner.userName} to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@') ? item.owner.userName : (`@${item.owner.userName}`)))}</Link>}
                                        {((item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)) ?
                                            <span> for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)} className="usernamelilac d-inline-block">  {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))}</p> &nbsp;
                                            </span> :
                                            (item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice) ?
                                                <span>
                                                    for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)} className="usernamelilac d-inline-block"> {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")}</p> &nbsp;
                                                </span>
                                                : ''}
                                    </span>
                                </h5></div>
                                <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>
                            </div>
                        )
                    }
                    if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                        return (
                            <div className="row mb-3 ">
                                {/* <div className="col-sm-6">Purchased by <Link to={`/creator/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                                <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                    <span className="acvticon">
                                        {
                                            (item &&
                                                item.priceDetail &&
                                                item.priceDetail.auctionId != null
                                            ) ? (
                                                <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            ) :
                                                (item &&
                                                    item.priceDetail &&
                                                    item.priceDetail.dropSellingPrice != null
                                                ) ? (
                                                    <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                ) :
                                                    (
                                                        <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                    )
                                        }
                                    </span>
                                    <span className="acvttext">
                                        {
                                            (
                                                item &&
                                                item.airDropDetail &&
                                                item.airDropDetail.airDropId
                                            ) ? "Claimed by"
                                                : "Purchased by"
                                        }  {item.ownerWalletAddress}<br />
                                        {(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice) ?
                                            <span> for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)} className="usernamelilac">  {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))}</p> &nbsp;
                                            </span> :
                                            (item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice) ?
                                                <span>
                                                    for &nbsp;<p title={(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)} className="usernamelilac"> {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")}</p> &nbsp;
                                                </span>
                                                : ''}
                                    </span>
                                </h5></div>
                                <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>

                            </div>
                        )
                    }
                    return (
                        <div className="row mb-3">
                            {/* <div className="col-sm-6">Purchased by <Link to={`/collector/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                            <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                <span className="acvticon">
                                    {
                                        (item &&
                                            item.priceDetail &&
                                            item.priceDetail.auctionId != null
                                        ) ? (
                                            <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        ) :
                                            (item &&
                                                item.priceDetail &&
                                                item.priceDetail.dropSellingPrice != null
                                            ) ? (
                                                <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            ) :
                                                (
                                                    <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                )
                                    }
                                </span>
                                <span className="acvttext">{
                                    (
                                        item &&
                                        item.airDropDetail &&
                                        item.airDropDetail.airDropId
                                    ) ? "Claimed by"
                                        : "Purchased by"
                                }   {item.ownerWalletAddress}<br />

                                    {(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice) ?
                                        <div className="row">
                                            for &nbsp;
                                            <p title={(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)} className="usernamelilac">  {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))}</p> &nbsp;
                                        </div> :
                                        (item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice) ?
                                            <div className="row">
                                                for &nbsp;
                                                <p title={(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)} className="usernamelilac"> {(flowDecimal(parseFloat(item && item.priceDetail !== null && item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")}</p> &nbsp;
                                            </div>
                                            : ''}</span></h5></div>
                            <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>
                        </div>
                    )
                } else {

                    // return user name when it is not null
                    if ((item && item.owner && item.owner.userName)) {
                        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                            return (
                                <div className="row mb-3">
                                    <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                        <span className="acvticon"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        </span>
                                        <span className="acvttext">
                                            Minted by <Link title={item && item.owner && item.owner.userName} to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></span></h5></div>
                                    <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>
                                </div>
                            )
                        }
                        return (
                            <div className="row mb-3">
                                <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                    <span className="acvticon"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i></span>
                                    <span className="acvttext">Minted by <Link title={item && item.owner && item.owner.userName} to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link> </span></h5></div>
                                <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>
                            </div>
                        )
                    }
                    if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                        return (
                            <div className="row mb-3">
                                {/* <div className="col-sm-6">Minted by <Link to={`/creator/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                                <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                    <span className="acvticon"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i></span>
                                    <span className="acvttext">Minted by {item.ownerWalletAddress}</span></h5></div>
                                <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>
                            </div>
                        )
                    }
                    return (
                        <div className="row mb-3">
                            {/* <div className="col-sm-6">Minted by <Link to={`/collector/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                            <div className="col-6 col-sm-6 col-md-8"><h5 className="mb-0 acvticotxtbox">
                                <span className="acvticon"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i></span>
                                <span className="acvttext">Minted by {item.ownerWalletAddress}</span></h5></div>
                            <div className="col-6 col-sm-6 col-md-4"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2 usernameblue"><i className="fas fa-external-link-alt"></i></a></h6></div>

                        </div>
                    )
                }
            });
            return (
                <div className='w-100 d-block'>
                    {txnRows}
                </div>
            );
        } else {
            if (isActivityCall) {
                return (
                    <p className="text-center">
                        <i className="fa fa-info-circle"></i>&nbsp;No Activity data available.<br />
                        {/* <img src={`../${configPath.imageAssets}/no-images.png`} /> */}
                    </p>
                )
            } else {
                return (
                    <p className="text-center">
                        <img src={`../${configPath.imageAssets}/ajax-loader.gif`} className="hexagonloader" />
                    </p>
                )
            }
        }
    }
    handleLogin = () => {

        fcl.logIn().then(res => {

            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                this.setState({ iswallet: true })
            }
        }).catch(err => {
        })
    }
    closeModal = () => {
        this.setState({ openLogin: false })
    }
    handlePriceCount = (e) => {
        const { priceCount, tokensArray } = this.state
        if ((tokensArray && tokensArray.length) > 1000) {
            if ((e.target.value > 0) && (e.target.value) <= 1000 && ((e.target.value) % 1 == 0)) {
                this.setState({ priceCount: e.target.value }, () => {
                })
            } else {
                this.setState({ priceCount: '' })
            }
        } else {
            if ((e.target.value > 0) && (e.target.value) <= (Number(tokensArray && tokensArray.length)) && ((e.target.value) % 1 == 0)) {
                this.setState({ priceCount: e.target.value }, () => {
                })
            } else {
                this.setState({ priceCount: '' })
            }
        }
    }
    handleCount = (e) => {
        const { tokensArray } = this.state
        if (this.show_cp_withdraw(this.state.detailMyArt)) {
            if ((tokensArray && tokensArray.length) > 300) {
                if ((e.target.value > 0) && (e.target.value) <= 300 && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            } else {
                if ((e.target.value > 0) && (e.target.value) <= (Number(tokensArray && tokensArray.length)) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            }
        } else {
            if ((tokensArray && tokensArray.length) > (this.maxWithdrawLimit)) {
                if ((e.target.value > 0) && (e.target.value) <= (this.maxWithdrawLimit) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            } else {
                if ((e.target.value > 0) && (e.target.value) <= (Number(tokensArray && tokensArray.length)) && ((e.target.value) % 1 == 0)) {
                    this.setState({ count: e.target.value }, () => {
                    })
                } else {
                    this.setState({ count: '' })
                }
            }
        }

    }
    handlePurchaseCount = (e) => {
        const { tokensArray } = this.state

        if ((tokensArray && (tokensArray.length) > 50)) {
            if ((e.target.value > 0) && ((e.target.value) <= 50) && ((e.target.value) % 1 == 0)) {
                this.setState({ purchaseCount: e.target.value }, () => {
                })
            }
            else {
                this.setState({ purchaseCount: '' })
            }
        } else {
            if ((e.target.value > 0) && ((e.target.value) <= Number((tokensArray && tokensArray.length))) && ((e.target.value) % 1 == 0)) {
                this.setState({ purchaseCount: e.target.value }, () => {
                })
            } else {
                this.setState({ purchaseCount: '' })
            }
        }

    }
    enablePurchase = (value) => {
        const { currentUserAddress, userAddress } = this.state
        if (value) {
            this.setState({ openLogin: true })
        } else {
            this.setState({ openLogin: false })

            this.handlePurchase()
            // this.showLoader()
            // getUserAddress().then(res => {
            //     this.hideLoader()
            //     if(res == userAddress){
            //         history.push('/')
            //     }else{
            //         this.handlePurchase()
            //     }
            // }).catch(err => {
            //     this.hideLoader()
            // })




        }
    }
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    handleBidPrice = (e) => {
        function validate(s) {
            var n = Number(s)

            if (n > 0 && n <= 999.99) {
                if (!s.match(/\d{1,3}\.\d{3}/)) {

                    return true
                }
            } else {

                return true
            }
            return false
        }
        //   validate(e.target.value)
        var res = (e.target.value).split(".");
        if ((e.target.value >= 0) && (res.length == 1 || res[1].length < 6)) {

            if (validate(e.target.value)) {

                var num = Number(e.target.value).toFixed(8)
                this.setState({
                    bidAmount: e.target.value, bidAmounting: num, isError: false
                })
            }
            // }
        }
    }
    handleCancelBid = () => { // cancel auction
        const { myAuctionList, detailMyAuctionList } = this.state
        const ownerWalletAddress = detailMyAuctionList && detailMyAuctionList.art && detailMyAuctionList.art.ownerWalletAddress
        const auctionId = detailMyAuctionList && detailMyAuctionList.auction && detailMyAuctionList.auction.id && detailMyAuctionList.auction.id != null && detailMyAuctionList.auction.id
        getUserAddress().then(res => {
            if ((checkWalletAddress(ownerWalletAddress, (this.state.currentUserAddress)))) {
                if (res != null) {
                    this.showLoader()
                    walletServices.checkWallet(res).then(wallet => {
                        this.hideLoader()
                        if ((wallet && wallet.statusCode) == 200) {
                            this.setState({ isTransLoader: true })
                            dropServices.saveEvent((detailMyAuctionList.art && detailMyAuctionList.art.tokenGroupId), 1, dropServices.DropEventType.AUCTION_CANCELLED, (detailMyAuctionList.art && detailMyAuctionList.art.tokenId))
                                .then((evtRes) => {
                                    if (evtRes === true) {
                                        cancelFlowAuction(auctionId).then(res => {
                                            this.setState({ isTransLoader: false })
                                            if ((res && res.status == 200)) {
                                                if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                    this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                    this.gotoTop()
                                                    this.resetTimeout()
                                                    this.closeAlert()
                                                    this.setState({ disableList: false })

                                                } else {

                                                    this.setState({ isCancelBid: true })
                                                    this.setState({ disableList: false })

                                                }
                                            } else {
                                                let mes = '';
                                                if (
                                                    res &&
                                                    res.data &&
                                                    res.data.Error
                                                ) {
                                                    const splitError = res.data.Error.split(':');
                                                    if (
                                                        splitError[2] &&
                                                        splitError[2].includes("Session expired")
                                                    ) {
                                                        mes = "Session Expired.Please reconnect your wallet account";
                                                    } else if (
                                                        splitError[2]
                                                    ) {
                                                        mes = splitError[2]
                                                    } else if (splitError[1]) {
                                                        mes = splitError[1]
                                                    } else {
                                                        mes = splitError[0];
                                                    }
                                                }
                                                this.setState({ ShowPop: false, isAuction: false })
                                                this.setState({ disableList: false })

                                                this.setState({ isAlert: true, message: mes, style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()

                                            }
                                            console.log("cancel", res)
                                        }).catch(err => {
                                            this.setState({ isTransLoader: false })
                                            console.log("cancel err", err)
                                        })
                                    }
                                    else {
                                        this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                    }
                                }).catch(err => {
                                    this.setState({ isTransLoader: false })
                                    console.log("cancel err", err)
                                })
                        }
                    }).catch(err => {
                        this.setState({ isAlert: true, message: err, style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        this.hideLoader()
                        this.setState({ disableList: false })

                    })
                }
            } else {
                this.setState({ disableList: false })

                this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        }).catch(err => {
            this.setState({ disableList: false })

            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        })
    }
    handleSettlement = (items, evtName) => { // auction settlement function
        const { bidAmount, currentUserAddress, settlement, myAuctionList, } = this.state
        const auctionId = items && items.auction && items.auction.id && items.auction.id != null && items.auction.id
        const ownerAddress = items && items.art && items.art.ownerWalletAddress
        // console.log('%c items','font-size:20px',evtName);
        getUserAddress().then(res => {
            if (res != null) {
                this.showLoader()
                walletServices.checkWallet(res).then(wallet => {
                    this.hideLoader()
                    if ((wallet && wallet.statusCode) == 200) {
                        this.setState({ isTransLoader: true })
                        dropServices.saveEvent((items.art && items.art.tokenGroupId), 1, dropServices.DropEventType.AUCTION_SETTLED, (items.art && items.art.tokenId))
                            .then((evtRes) => {
                                if (evtRes === true) {
                                    settleFlowAuction(auctionId, ownerAddress).then(res => {
                                        this.setState({ isTransLoader: false })
                                        if ((res && res.status == 200)) {
                                            if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                                this.setState({ disableList: false })
                                            } else {
                                                this.setState({ disableList: false })
                                                // isSettlementSuccess for revoke auction 1 , for claim art 2 , for settlement 3 
                                                if (evtName == "revoke") {
                                                    this.setState({ isSettlementSuccess: 1 })
                                                } else if (evtName == "claim") {
                                                    this.setState({ isSettlementSuccess: 2 })
                                                } else if (evtName == "settled") {
                                                    this.setState({ isSettlementSuccess: 3 })
                                                }
                                            }
                                        } else {

                                            let mes = '';
                                            if (
                                                res &&
                                                res.data &&
                                                res.data.Error
                                            ) {
                                                const splitError = res.data.Error.split(':');
                                                if (
                                                    splitError[2] &&
                                                    splitError[2].includes("Session expired")
                                                ) {
                                                    mes = "Session Expired.Please reconnect your wallet account";
                                                } else if (
                                                    splitError[2]
                                                ) {
                                                    mes = splitError[2]
                                                } else if (splitError[1]) {
                                                    mes = splitError[1]
                                                } else {
                                                    mes = splitError[0];
                                                }
                                            }
                                            this.setState({ ShowPop: false, isAuction: false })
                                            this.setState({ disableList: false })

                                            this.setState({ isAlert: true, message: mes, style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()

                                        }
                                        console.log("settle obj", res)
                                    }).catch(err => {
                                        this.setState({ isTransLoader: false })
                                        console.log("settle err", err)
                                    })
                                } else {
                                    this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                }
                            }).catch(err => {
                                this.setState({ isAlert: true, message: err, style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                                this.hideLoader()
                                this.setState({ disableList: false })

                            })
                    }
                }).catch(err => {
                    this.setState({ isAlert: true, message: err, style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    this.hideLoader()
                    this.setState({ disableList: false })

                })
            }
        }).catch(err => {
            this.setState({ disableList: false })

            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        })
    }
    handlePlaceBid = () => {
        const { bidAmount, currentUserAddress, settlement, bidList, balance, bidDetailList } = this.state
        // console.log('%cBid list','font-size:20px',bidList)
        const auctionId = bidDetailList && bidDetailList.auction && bidDetailList.auction.id && bidDetailList.auction.id != null && bidDetailList.auction.id
        const bidIncrement = bidDetailList && bidDetailList.auction && bidDetailList.auction.minimumBidIncrement && bidDetailList.auction.minimumBidIncrement != null && bidDetailList.auction.minimumBidIncrement
        const startingPrice = bidDetailList && bidDetailList.auction && bidDetailList.auction.startPrice && bidDetailList.auction.startPrice != null && bidDetailList.auction.startPrice
        const endDay = bidDetailList && bidDetailList.auction && bidDetailList.auction.endDay && bidDetailList.auction.endDay != null && bidDetailList.auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        const ownerAddress = bidDetailList && bidDetailList.art && bidDetailList.art.ownerWalletAddress
        const auctionDetails = bidDetailList && bidDetailList.auction && bidDetailList.auction.auctionDetails
        var countDownDate = new Date(futureDate).getTime();
        const today = new Date().getTime()
        if (today < (countDownDate)) {
            if (auctionDetails && auctionDetails.length > 0) {
                var set_am = Math.round(((auctionDetails && auctionDetails[0] && auctionDetails[0].bidAmount) + parseFloat(bidIncrement)) * 1e12) / 1e12

            }
            else {
                var set_am = Math.round((Number(startingPrice) + (bidIncrement)) * 1e12) / 1e12

            }
            if ((parseFloat(bidAmount) >= set_am)) {

                if ((balance != null && parseFloat(balance)) > (set_am)) {

                    if (bidAmount && (Number(bidAmount) > 0)) {
                        if ((balance != null && parseFloat(balance)) > parseFloat(bidAmount)) {
                            this.showLoader()
                            RetrieveTokens(this.state.currentUserAddress).then(act => {
                                this.hideLoader()
                                if ((act && act.data && act.data.Value) == 1) {

                                    getUserAddress().then(res => {
                                        if (res != null) {
                                            this.showLoader()
                                            walletServices.checkWallet(res).then(wallet => {
                                                this.hideLoader()
                                                if ((wallet && wallet.statusCode) == 200) {
                                                    // this.setState({ disableList: true })
                                                    this.setState({ isTransLoader: true, isError: false, errorMsg: '' })
                                                    dropServices.saveEvent((bidDetailList.art && bidDetailList.art.tokenGroupId), 1, dropServices.DropEventType.NEW_BID, (bidDetailList.art && bidDetailList.art.tokenId))
                                                        .then((evtRes) => {
                                                            if (evtRes === true) {
                                                                placeBidOnFlowAuction((auctionId), ownerAddress, Number(parseFloat(bidAmount).toFixed(8))).then(res => {
                                                                    this.setState({ isTransLoader: false, isError: false, errorMsg: '' })
                                                                    console.log("bid", res)
                                                                    if ((res && res.status == 200)) {
                                                                        if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                                            this.setState({ isAlert: true, isError: false, errorMsg: '', message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                                            this.gotoTop()
                                                                            this.resetTimeout()
                                                                            this.closeAlert()
                                                                            this.setState({ disableList: false })

                                                                        } else {

                                                                            this.setState({ isAuctionSuccess: true, isError: false })
                                                                            this.setState({ disableList: false })

                                                                        }
                                                                    }
                                                                    else {
                                                                        let mes = '';
                                                                        if (
                                                                            res &&
                                                                            res.data &&
                                                                            res.data.Error
                                                                        ) {
                                                                            const splitError = res.data.Error.split(':');
                                                                            if (
                                                                                splitError[2] &&
                                                                                splitError[2].includes("Session expired")
                                                                            ) {
                                                                                mes = "Session Expired.Please reconnect your wallet account";
                                                                            } else if (
                                                                                splitError[2]
                                                                            ) {
                                                                                mes = splitError[2]
                                                                            } else if (splitError[1]) {
                                                                                mes = splitError[1]
                                                                            } else {
                                                                                mes = splitError[0];
                                                                            }
                                                                        }
                                                                        this.setState({ ShowPop: false, isAuction: false })
                                                                        this.setState({ disableList: false })

                                                                        this.setState({ isAlert: true, message: mes, style: "danger" })
                                                                        this.gotoTop()
                                                                        this.resetTimeout()
                                                                        this.closeAlert()

                                                                    }
                                                                }).catch(err => {
                                                                    this.setState({ isTransLoader: false })

                                                                    this.setState({ isAlert: true, message: err, style: "danger" })
                                                                    this.gotoTop()
                                                                    this.resetTimeout()
                                                                    this.closeAlert()

                                                                    console.log("bid err", err)
                                                                })
                                                            } else {
                                                                this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                                            }
                                                        }).catch(err => {
                                                            this.hideLoader()
                                                            this.setState({ isAlert: true, message: err, style: "danger" })
                                                            this.gotoTop()
                                                            this.resetTimeout()
                                                            this.closeAlert()
                                                            this.setState({ disableWithdraw: false })
                                                        })
                                                }
                                            }).catch(err => {
                                                this.setState({ isAlert: true, message: err, style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                                this.hideLoader()
                                                this.setState({ disableList: false })

                                            })
                                        }
                                    }).catch(err => {
                                        this.setState({ disableList: false })

                                        this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                    })
                                } else {
                                    this.setState({ isActSetup: true })
                                    createSetUp().then(res => {
                                        this.setState({ isActSetup: false })
                                        if (res && res.status == 200) {
                                            if (res.data.transaction && res.data.transaction.errorMessage) {
                                                this.setState({ isLoading: false, isMinting: false, isUpload: false })
                                                this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                            } else {
                                                this.handlePlaceBid()
                                                this.setState({ isAlert: true, message: 'Your Flow account has been successfully added. Now you may proceed to bid NFT', style: "success" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                            }
                                        }
                                        else {
                                            this.setState({ disableProceed: false })

                                            //  this.setState({ isLoading: false, isMinting: false, processCancel: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]) })

                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        }
                                        console.log("res", res)
                                    }).catch(err => {
                                        this.setState({ isActSetup: false })
                                        console.log("err", err)
                                        this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                    })
                                }
                            }).catch(err => {
                                this.hideLoader()
                            })


                        } else {
                            this.gotoFundYourWallet(bidList, bidAmount, balance, "bid")

                            // this.setState({ isError: true, errorMsg: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => { history.push('/fund-wallet') }} style={{ color: "#9E66BF", cursor: "pointer" }}> add funds</a> into your wallet</div>, })

                        }

                    } else {
                        this.setState({ isError: true, errorMsg: "Please enter valid bid amount", })
                        // this.gotoTop()
                        // this.resetTimeout()
                        // this.closeAlert()
                    }
                } else {
                    this.gotoFundYourWallet(bidList, bidAmount, balance, "bid")

                    // this.setState({ isError: true, errorMsg: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => { history.push('/fund-wallet') }} style={{ color: "#9E66BF", cursor: "pointer" }}> add funds</a> into your wallet</div>, })

                }
            } else {
                this.setState({ isError: true, errorMsg: `Bid Amount should be greater than or equal to ${set_am} FLOW`, })
                // this.gotoTop()
                // this.resetTimeout()
                // this.closeAlert()
            }

        } else {
            this.setState({ isAlert: true, message: "Bidding closed", style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    goLogin1 = () => {
        // history.push('/login')
        history.push('/login',
            {
                artId: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname),
                hashId: (this.props && this.props.location && this.props.location.hash && this.props.location.hash)

            })
    }
    goLogin = () => {
        console.log("isDapperPurchase")
        history.push('/login',
            {
                artId: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname),
                hashId: (this.props && this.props.location && this.props.location.hash && this.props.location.hash),
                isDapperPurchase: true

            })
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
        // this.closeAlert()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    startTime = (items, isRelative) => {
        const { bidList } = this.state
        const endDay = items && items.auction && items.auction.endDay && items.auction.endDay != null && items.auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        // this.setState({distanceTime : distance})
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0 && !isRelative) {
            return false
        }


        // this.setState({seconds,days,hours,minutes})
        // setInterval(this.startTime(items), 1000);
        if (isRelative) {
            if (distance < 0) {

                return <div className="mt-1"><p className="mb-0  usernamelilac">Bidding Closed</p></div>
            }
            return <div className="mt-1"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + 'd' : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>
        }
        else {
            return <div className="counttimebox">
                <h2 className="mb-0 ">
                    {/* {(`${days ? days : ''} ${days ? (days > 1 ? "D" : "D") : ''}  ${hours}Hours ${minutes}Minutes ${seconds}Seconds`)}                         */}
                    {days ? (<span className="counttime">{days} {days && <span className="small usernamelilac">{(days && days == 1) ? "Day" : "Days"}</span>}</span>) : ''}
                    <span className="counttime">{hours} <span className="small usernamelilac">Hours</span></span>
                    <span className="counttime">{minutes} <span className="small usernamelilac">Minutes</span></span>
                    <span className="counttime">{seconds} <span className="small usernamelilac">Seconds</span></span>
                </h2>
            </div>
        }

    }


    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    checkLastDate = (items) => {
        const endDay = items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.endDay && items.drops[0].auction.endDay != null && items.drops[0].auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        }
        else {
            return false
        }
    }
    checkPremiumTimeClose = (items) => {

        const endDay = items && items.forPremiumUserExpiry
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        }
        else {
            return false
        }
    }
    isCheckLeader = (bidList) => {
        const webUser = localStorage.getItem("webLogin")
        const webUserDetail = webUser && JSON.parse(webUser)
        const webUserId = webUserDetail && webUserDetail != null && webUserDetail.data && webUserDetail.data.authUser && webUserDetail.data.authUser.id && webUserDetail.data.authUser.id != null && webUserDetail.data.authUser.id
        const bidderId = bidList && bidList.auction && bidList.auction.auctionDetails && bidList.auction.auctionDetails[0] && bidList.auction.auctionDetails[0].bidderId ? bidList.auction.auctionDetails[0].bidderId : ''

        if ((webUserId) == (bidderId)) {
            return true
        } else {
            return false
        }
    }
    openHistory = (e) => {

        e.preventDefault()

        this.setState({ isShowHistory: !this.state.isShowHistory })
    }
    fetchArt(url) {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        }).catch((err) => {

        })

    }
    handletoConnectWallet = () => {

        fcl.logIn().then(res => {

            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                this.setState({ iswallet: true })
            } else {
                this.props.getUserProfile()
            }
        }).catch(err => {
        })
    }
    bidOwner = (bidDetailList) => {
        return <div className="cardlist"><div className="row align-items-center">

            <div className="col-sm-4 text-left">
                <div className="badgeview mb-0" onClick={() => { this.gotoCreator((bidDetailList && bidDetailList.user)) }}>
                    <div className="picpro">
                        <span className="artcovimg">
                            <span className="artcov"></span>
                            <span className="artcovimginset no-proimg" style={{ cursor: "poniter" }}>
                                <img src={bidDetailList && bidDetailList.user && bidDetailList.user.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${bidDetailList && bidDetailList.user && bidDetailList.user.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                            </span>
                        </span>
                    </div><h2 className="usernameClick" title={bidDetailList && bidDetailList.user && bidDetailList.user.userName}>{(bidDetailList && bidDetailList.user && bidDetailList.user.userName) ? ("@" + (bidDetailList.user.userName)) : '-'}</h2></div>
            </div>

            <div className="col-sm-3 text-center">
                {/* <label>Start Bid</label> */}
                <div className="usernamelilac mb-0" title={(bidDetailList && bidDetailList.auction && bidDetailList.auction.startPrice)}>
                    {(bidDetailList && bidDetailList.auction && bidDetailList.auction.startPrice) ? (flowDecimal(parseFloat(bidDetailList.auction.startPrice)) + " " + "FLOW") : '-'}
                </div>

            </div>
            <div className="col-sm-5 text-right">
                <h6>{bidDetailList && bidDetailList.createdAt && moment(bidDetailList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
            </div>


        </div>
        </div>
    }
    gotoArtDet = (item) => {
        const id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id)
        const artIds = item && item.artIds && item.artIds.split(',')
        if (id == (artIds && artIds[0])) {
            this.getDrops()
        } else {
            // console.log(item,artIds)
            history.push(`/art/${artIds && artIds[0]}`)
        }
    }
    gotoActivity = () => {
        const basePath = process.env.REACT_APP_BASEPATH
        const id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id)
        const navpath = (basePath + `/art-activity-2/${id}`)

        if (id && id != undefined && id != null) {
            window.open(navpath, "_blank")
        }
    }
    purchaseBtnFunc = (detailDropList) => {
        const { currentUserAddress, totalOwnerArt, tokensArray } = this.state;
        const ownerAddress = detailDropList && detailDropList.art && detailDropList.art.ownerWalletAddress;

        return <div className="col-md-5 purchase_nft text-center">
            {
                (
                    (!isWebLogin()) ? <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.goLogin}>Purchase</button> :
                        (isWebLogin() && !isConnectWallet()) ? <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.handleDapperConnectWallet}>Purchase</button> :

                            <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.handlePurchase} disabled={this.state.disablePurchase}>Purchase</button>
                )
            }

        </div>
    }
    purchaseActivityCheck = (detailDropList) => {

        if (detailDropList && detailDropList.withDapper && detailDropList.storefrontListingResourceId) {
            if (!(detailDropList && detailDropList.forPremiumUser)) {
                return this.purchaseBtnFunc(detailDropList)
            } else {
                if ((this.checkPremiumTimeClose(detailDropList))) {
                    return this.purchaseBtnFunc(detailDropList)
                } else if (isWebLogin()) {
                    // console.log(loggedUser,dropList && dropList.drops)
                    if (loggedUser && loggedUser.isPremiumAccount) {
                        return this.purchaseBtnFunc(detailDropList)
                    } else {
                        return <div className="col-sm-6">
                            <h5 className="mb-3  d-inline-block">
                                <span className="usernamelilac">
                                    Available only for <br />premium members</span></h5>
                        </div>
                    }
                } else {
                    return <div className="col-sm-6">
                        <h5 className="mb-3  d-inline-block">
                            <span className="usernamelilac">
                                Available only for <br />premium members</span></h5>
                    </div>
                }

            }
        } else {
            return <div className="col-sm-6">
            </div>
        }

    }
    checkLoggedUser = () => {

        userServices.getUsers().then(res => {
            if ((res && res.statusCode) == 200) {
                if ((res && res.data && res.data.roleId) == "1") {
                    // console.log(res)
                    creatorServices.getDetailCreator(res && res.data && res.data.userName).then(res => {
                        if (res && res.statusCode == 200) {
                            if ((res && res.data && res.data)) {
                                loggedUser = res.data
                            }
                        } else {
                            loggedUser = []
                        }
                    }).catch(err => {
                        loggedUser = []
                    })
                } else if ((res && res.data && res.data.roleId) == "2") {
                    collectorServices.detailCollector(res && res.data && res.data.userName).then(res => {
                        if (res && res.statusCode == 200) {
                            if ((res && res.data && res.data)) {
                                loggedUser = res.data
                            }
                        } else {
                            loggedUser = []
                        }
                    }).catch(err => {
                        loggedUser = []
                    })
                }
            }
        }).catch(err => {
            loggedUser = []
        })
    }
    placeBidAction = () => {
        return <div className="col-md-4 text-center bid_nft">
            {isWebLogin() ?
                <button type="button" className="btn btn-primary" onClick={isConnectWallet() ? this.handlePlaceBid : this.handleLogin} disabled={this.state.disableList}>Place Bid</button> :
                <button type="button" className="btn btn-primary" onClick={() => {
                    history.push('/login',
                        {
                            artId: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname),
                            hashId: (this.props && this.props.location && this.props.location.hash && this.props.location.hash)
                        })
                }} >Place Bid</button>
            }
        </div>
    }
    bidPlaceAction = (bidDetailList) => {

        if (!(bidDetailList && bidDetailList.forPremiumUser)) {
            return this.placeBidAction()
        } else {
            if ((this.checkPremiumTimeClose(bidDetailList))) {
                return this.placeBidAction()
            } else if (isWebLogin()) {
                //  console.log(loggedUser,bidList && bidList.drops)
                if (loggedUser && loggedUser.isPremiumAccount) {
                    return this.placeBidAction()
                } else {
                    return <div className="col-md-4">
                        <h5 className="mb-3  d-inline-block">
                            <span className="usernamelilac">
                                Available only for <br /> premium members</span></h5>
                    </div>
                }
            } else {
                return <div className="col-md-4">
                    <h5 className="mb-3  d-inline-block">
                        <span className="usernamelilac">
                            Available only for <br /> premium members</span></h5>
                </div>
            }

        }
        // console.log(bidList)

    }
    checkTotalMintedTokens = (totalTokens) => {
        if (isNaN(totalTokens)) {
            if (totalTokens.includes('-')) {
                return 1
            }
        }
        if ((totalTokens) && Number.isInteger(totalTokens)) {

            return totalTokens
        } else {
            return 1
        }
    }
    getArtMetaData() {
        const {
            dropList,
            myAuctionList,
            myArt,
            itsMine,
            isDrop,
            isMyAuction,
            showAuction,
            artList,
            bidList
        } = this.state;
        const artMeta = {
            title: '...',
            creatorFullName: '...',
            creatorUserName: '...',
            description: '...'
        };
        if (dropList && isDrop && !itsMine && !isMyAuction && !showAuction) {
            artMeta.title = dropList.title;
            artMeta.creatorFullName = dropList.creator && dropList.creator.fullName;
            artMeta.creatorUserName = dropList.creator && dropList.creator.userName;
            artMeta.description = `${dropList.creator && dropList.creator.fullName}'s NFT on ${process.env.REACT_APP_SITE_CONTRACT} - ${dropList.title} by @${dropList.creator && dropList.creator.userName} - ${dropList.description && dropList.description.slice(0, 60)}`
        } else if (myArt && !isDrop && !isMyAuction && itsMine && !showAuction) {
            artMeta.title = myArt.title;
            artMeta.creatorFullName = myArt.creator && myArt.creator.fullName;
            artMeta.creatorUserName = myArt.creator && myArt.creator.userName;
            artMeta.description = `${myArt.creator && myArt.creator.fullName}'s NFT on ${process.env.REACT_APP_SITE_CONTRACT} - ${myArt.title} by @${myArt.creator && myArt.creator.userName} - ${myArt.description && myArt.description.slice(0, 60)}`
        } else if (artList && !itsMine && !isDrop && !showAuction && !isMyAuction) {
            artMeta.title = artList && artList.title;
            artMeta.creatorFullName = artList && artList.creator && artList.creator.fullName;
            artMeta.creatorUserName = artList.creator && artList.creator.userName;
            artMeta.description = `${artList.creator && artList.creator.fullName}'s NFT on ${process.env.REACT_APP_SITE_CONTRACT} - ${artList.title} by @${artList.creator && artList.creator.userName} - ${artList.description && artList.description.slice(0, 60)}`
        } else if (showAuction && !isMyAuction) {
            artMeta.title = bidList.title;
            artMeta.creatorFullName = bidList.creator && bidList.creator.fullName;
            artMeta.creatorUserName = bidList.creator && bidList.creator.userName;
            artMeta.description = `${bidList.creator && bidList.creator.fullName}'s NFT on ${process.env.REACT_APP_SITE_CONTRACT} - ${bidList.title} by @${bidList.creator && bidList.creator.userName} - ${bidList.description && bidList.description.slice(0, 60)}`
        } else if (isMyAuction && myAuctionList) {
            artMeta.title = myAuctionList.title;
            artMeta.creatorFullName = myAuctionList.creator && myAuctionList.creator.fullName;
            artMeta.creatorUserName = myAuctionList.creator && myAuctionList.creator.userName;
            artMeta.description = `${myAuctionList.creator && myAuctionList.creator.fullName}'s NFT on ${process.env.REACT_APP_SITE_CONTRACT} - ${myAuctionList.title} by @${myAuctionList.creator && myAuctionList.creator.userName} - ${myAuctionList.description && myAuctionList.description.slice(0, 60)}`
        }
        return artMeta;
    }
    resetPage = () => {
        localStorage.setItem('page', "0")
    }
    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }
    getCollectorsList = (artOwners, creator) => {
        const { showAllCreators } = this.state

        const firstFour = artOwners && artOwners.slice(0, 3)
        if (showAllCreators) {
            return <div className="w-100">
                {artOwners && artOwners.map(items => (
                    ((items && items.ownerUserId) != (creator && creator.id)) &&

                    <div className="creatordetalist">
                        <span className='pr-1 text-right'>{items.ownedCopies}</span>
                        {(items && items.ownerUseName && items.ownerUserId) ? (items && items.artStatus && items.artStatus !== 2) ? <span onClick={() => { this.gotoCreatorForArtOwners(items) }} className="usertxt" title={items.ownerUseName}>
                            <span className={'usernameblue addCursor'}>{items.ownerUseName && `@${items.ownerUseName}`}</span>
                        </span> : <span><i className="fa fa-user-secret" aria-hidden="true"> </i> {items && items.ownerUseName}</span>
                            : <span title={items && items.ownerWalletAddresses && items.ownerWalletAddresses[0]}>{items && items.ownerWalletAddresses && items.ownerWalletAddresses[0]}</span>}
                    </div>))}
            </div>
        } else {
            return <div className="w-100">

                {firstFour && firstFour.map(items => (
                    ((items && items.ownerUserId) != (creator && creator.id)) &&

                    <div className="creatordetalist">
                        <span className='pr-1 text-right'>{items.ownedCopies}</span>
                        {(items && items.ownerUseName && items.ownerUserId) ? (items && items.artStatus && items.artStatus !== 2) ? <span onClick={() => { this.gotoCreatorForArtOwners(items) }} className="usertxt" title={items.ownerUseName}>
                            <span className={' usernameblue addCursor'}>{items.ownerUseName && `@${items.ownerUseName}`}</span>
                        </span> : <span><i className="fa fa-user-secret" aria-hidden="true"> </i> {items && items.ownerUseName}</span>
                            : <span title={items && items.ownerWalletAddresses && items.ownerWalletAddresses[0]}>{items && items.ownerWalletAddresses && items.ownerWalletAddresses[0]}</span>}
                    </div>))}


            </div>
        }

    }
    getOwnerCount = (artOwners, creator) => {
        let ownedCopy = 0
        if (creator) {
            artOwners && artOwners.map(items => {
                if ((items && items.ownerUserId) == (creator && creator.id)) {
                    ownedCopy = (items && items.ownedCopies && items.ownedCopies)
                }
            })
        }
        if (ownedCopy) {
            return ownedCopy
        }
        return ''

    }
    getPurchasedCount = (artOwners, creator) => {
        let ownedCopy = 0
        if (creator) {
            artOwners && artOwners.map(items => {
                if ((items && items.ownerUserId) == (creator && creator.id)) {
                    ownedCopy = (items && items.ownedCopies && items.ownedCopies)
                }
            })
        }
        if (ownedCopy) {
            return ownedCopy
        }
        return ''

    }
    gotoCreatorArt = (artOwners, creator) => {
        if (creator && artOwners) {
            const art_id = this.props && this.props.match && this.props.match.params && this.props.match.params.id

            artOwners && artOwners.map(items => {

                if ((items && items.ownerUserId) == (creator && creator.id)) {
                    if ((items.drop && items.drop.id)) {
                        return history.push(`/art/${art_id}#drop${items.drop.id}`)

                    } else {

                        return history.push(`/art/${art_id}`)
                    }
                }
            })
        }
    }
    getCreator = (artOwners, creator) => {
        const filteredCreator = artOwners && artOwners.filter(items => ((items && items.ownerUserId) === (creator && creator.id)))

        if ((filteredCreator && filteredCreator[0] && filteredCreator[0].artStatus && filteredCreator[0].artStatus !== 2)) {
            return <span onClick={() => { this.gotoCreatorArt(artOwners, creator) }} className='cursor-pointer' title={creator && creator.userName}>
                <span className="usernameblue">{creator && creator.userName && `@${creator.userName}`}</span>

            </span>

        } else {
            return <span title={creator && creator.userName}>
                <span className="">{creator && creator.userName && `@${creator.userName}`}</span>

            </span>
        }



    }
    getPurchaseType = (ownerShipDetail) => {
        if ((ownerShipDetail &&
            ownerShipDetail.purchaseDetail &&
            ownerShipDetail.purchaseDetail.purchaseType
        )) {
            if ((ownerShipDetail.purchaseDetail.purchaseType == "drop")) {
                if (ownerShipDetail.purchaseDetail.withDapper) {
                    return <span>Purchased for&nbsp;
                        <span className="usernamelilac">{flowDecimal(parseFloat(ownerShipDetail.purchaseDetail.price)) + ' USD'}</span> &nbsp;
                        <br /> <span className="small usernameblue">at {moment(ownerShipDetail.purchaseDetail.timestamp).format('DD MMM, YYYY [at] h:mma')}</span></span>
                } else {
                    return <span>Purchased for&nbsp;
                        <span className="usernamelilac">{flowDecimal(parseFloat(ownerShipDetail.purchaseDetail.price)) + ' ' + 'FLOW'}</span> &nbsp;
                        <br /> <span className="small usernameblue">at {moment(ownerShipDetail.purchaseDetail.timestamp).format('DD MMM, YYYY [at] h:mma')}</span></span>
                }

            } else if ((ownerShipDetail.purchaseDetail.purchaseType == "auction")) {
                if (ownerShipDetail.purchaseDetail.withDapper) {
                    return <span>Auction won for&nbsp;
                        <span className="usernamelilac">{flowDecimal(parseFloat(ownerShipDetail.purchaseDetail.price)) + ' USD'}</span> in Bid &nbsp;
                        <br /> <span className="small usernameblue">at {moment(ownerShipDetail.purchaseDetail.timestamp).format('DD MMM, YYYY [at] h:mma')}</span></span>
                } else {
                    return <span>Auction won for&nbsp;
                        <span className="usernamelilac">{flowDecimal(parseFloat(ownerShipDetail.purchaseDetail.price)) + ' ' + 'FLOW'}</span> in Bid &nbsp;
                        <br /> <span className="small usernameblue">at {moment(ownerShipDetail.purchaseDetail.timestamp).format('DD MMM, YYYY [at] h:mma')}</span></span>
                }

            } else if ((ownerShipDetail.purchaseDetail.purchaseType == "airDrop")) {
                return <span>Claimed from AirDrop &nbsp;<span className="small usernameblue">at {moment(ownerShipDetail.purchaseDetail.timestamp).format('DD MMM, YYYY [at] h:mma')}</span></span>
            }
        } else {
            return ""
        }
    }
    isAuctionClosed = (data) => {
        if ((data && data.status && data.status == 2)) {
            return true
        } else {
            return false
        }
    }
    getAuctionPurchaser = (purchaser) => {
        if ((purchaser && purchaser.purchaser)) {
            return <div className="purchasebox col-md-12">
                <div className="propagecont ccdetailbox">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='topcolldetabox'>
                                <h5>

                                    <span>Auction won by</span>
                                    <strong className="usernamelilac">Auction Completed</strong>

                                </h5>
                                <div className='topcolldeta'>
                                    <div className="propicdetail top0">
                                        <div className="picpro">
                                            <span className="artcovimg">
                                                <span className="artcov "></span>
                                                <span className="artcovimginset no-proimg">
                                                    <img src={purchaser && purchaser.purchaser && purchaser.purchaser.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${purchaser && purchaser.purchaser && purchaser.purchaser.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(purchaser && purchaser.purchaser)} />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='tcdeta'>
                                        <h3 className='usernamelilac'>{purchaser && purchaser.purchaser && purchaser.purchaser.fullName}</h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => this.gotoCreator(purchaser && purchaser.purchaser)}>{purchaser && purchaser.purchaser && purchaser.purchaser.userName && `@${purchaser.purchaser.userName}`}</a>
                                        <h5>
                                            <span>Auction won for&nbsp;
                                                <span className="usernamelilac">{flowDecimal(parseFloat(purchaser && purchaser.auction && purchaser.auction.auctionDetails && purchaser.auction.auctionDetails[0] && purchaser.auction.auctionDetails[0].bidAmount && purchaser.auction.auctionDetails[0].bidAmount)) + ' ' + 'FLOW'}</span> in Bid &nbsp;
                                                <br />
                                                Listed by &nbsp;<span className="mb-0 usernamelilac" style={{ cursor: "pointer" }} onClick={() => this.gotoCreator(purchaser && purchaser.user)}>{purchaser && purchaser.user && purchaser.user.userName && `@${purchaser.user.userName}`}</span>

                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
    isPurchased = (data) => {
        if ((data && data.status && data.status === 2) && (data && data.tokensInDrop && data.tokensInDrop.length === 0)) {
            return true
        } else {
            return false
        }
    }
    getPurchasedDetail = (purchaser) => {
        if ((purchaser && purchaser.purchaser)) {

            return <div className="purchasebox">
                <div className="propagecont ccdetailbox">
                    <div className="row">
                        <div className='col-md-12'>
                            <div className='topcolldetabox'>
                                <h5>
                                    <span>Purchased By</span>
                                    <strong className="usernamelilac">Sale Completed</strong>
                                </h5>
                                <div className='topcolldeta'>
                                    <div className="propicdetail top0">
                                        <div className="picpro">
                                            <span className="artcovimg">
                                                <span className="artcov "></span>
                                                <span className="artcovimginset no-proimg">
                                                    <img src={purchaser && purchaser.purchaser && purchaser.purchaser.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${purchaser && purchaser.purchaser && purchaser.purchaser.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(purchaser && purchaser.purchaser)} />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='tcdeta'>
                                        <h3 className='usernamelilac'>{purchaser && purchaser.purchaser && purchaser.purchaser.fullName}</h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => this.gotoCreator(purchaser && purchaser.purchaser)}>{purchaser && purchaser.purchaser && purchaser.purchaser.userName && `@${purchaser.purchaser.userName}`}</a>
                                        <br />
                                        Listed by &nbsp;<span className="mb-0 usernamelilac" style={{ cursor: "pointer" }} onClick={() => this.gotoCreator(purchaser && purchaser.user)}>{purchaser && purchaser.user && purchaser.user.userName && `@${purchaser.user.userName}`}</span>

                                        {/* <h5>{this.getPurchaseType(purchaser && purchaser)}</h5> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
    viewallIds = (ownership) => {

        this.setState({ isOwnedTokens: true })
    }
    closeOwnedTokens = () => {
        this.setState({ isOwnedTokens: false })

    }

    handleViewAllCollectors = () => {
        this.setState({ showAllCreators: !this.state.showAllCreators }, () => {

        })
    }
    getAttributes = (url) => {
        if (url) {
            artServices.metadataAttr(url).then(res => {

                if (res && res.attributes && res.Collection && (res.Collection === "Badazz Bears")) {
                    this.setState({
                        bearAttributes: res
                    })
                } else {
                    this.setState({
                        bearAttributes: []
                    })
                }
            }).catch(err => {
                this.setState({
                    bearAttributes: []
                })
            })
        }
    }
    socialShareUI = (detail) => {
        // const tags = detail && detail.tags && detail.tags.map((items) => (items.name))
        // console.log(tags)

        const title = detail && detail.title
        const creator = detail && detail.creator && detail.creator.fullName
        const message = `${title} NFT from ${creator} at ${process.env.REACT_APP_BASE_SITE}`

        const basePath = process.env.REACT_APP_BASEPATH
        const sharedPath = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}${this.props.location && this.props.location.hash}`

        return <div className="d-flex flex-row w-100 mt-3 align-items-center">
            <div className="shareout" id="shareout">
                <div className="share_overlaybg">
                    <div className="sharebox">
                        <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                        <span className="shareicons">
                            <a className="copylinktxt" onClick={() => { this.copyToClipboard(sharedPath) }}>
                                <img className="share_icon" src={`${basePath}/assets/images/copylink.png`} alt="Copy Link" />
                                <p className="txtchange">{this.state.copySuccess}</p>
                            </a>
                            <FacebookShareButton url={sharedPath} quote={message} hashtag={`#${process.env.REACT_APP_SITE_NAME}`}>
                                <img className="share_icon" src={`${basePath}/assets/images/facebook.png`} alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                            <TwitterShareButton url={sharedPath} title={message} hashtags={['nft', `${process.env.REACT_APP_SITE_NAME}`]} via={process.env.REACT_APP_SITE_NAME}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                            <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${sharedPath}&description=${message}`}>
                                <img className="share_icon" src={`${basePath}/assets/images/pinterest.png`} alt="Pinterest Share" />
                                <p>Pinterest</p>
                            </a>
                            <EmailShareButton url={sharedPath} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                            <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${sharedPath}&redirect_uri=${sharedPath}`}>
                                <img className="share_icon" src={`${basePath}/assets/images/messenger.png`} alt="Messenger Share" />
                                <p>Messenger</p>
                            </a>
                            <WhatsappShareButton url={sharedPath} title={message}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                            <TelegramShareButton url={sharedPath} title={message}><TelegramIcon size={46} round={true} /><p>Telegram</p></TelegramShareButton>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    }
    statusChange = (items) => {
        if ((items && items.art && items.art.id)) {
            const status = (items && items.art && items.art.status && items.art.status === 1) ? 2 : 1
            let obj = {
                status
            }

            this.props.updatevisibilityStatus(obj, (items.art.id))
        } else {

            this.setState({ isAlert: true, message: "Something went wrong : Art id was missing", style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    resetStatusPage = () => {
        this.setState({
            isMyartStatusChangeSuccess: false
        })
    }

    isArtVisible = (data) => {
        if (((data && data.art && data.art.status && data.art.status) === 1)) {
            return true
        } else {
            return false
        }
    }
    resetDisableState = () => {
        this.setState({
            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isInvalidPage: false
        })
    }
    isVisibilityStatusVisible = (data) => {
        if (((data && data.status && data.status) !== 2)) {
            return true
        } else {
            return false
        }
    }
    isHiddenArt = (artList, dropList, myAuctionList, myArt, bidList) => {
        if (artList && artList.owner) {
            if (((artList && artList.status && artList.status) !== 2) && (artList && artList.owner && artList.owner.userName !== "hidden")) {
                return true
            } else {
                return false
            }
        } else if (dropList && dropList.owner) {
            if (((dropList && dropList.status && dropList.status) !== 2) && (dropList && dropList.owner && dropList.owner.userName !== "hidden")) {
                return true
            } else {
                return false
            }
        } else if (myAuctionList && myAuctionList.owner) {
            if (((myAuctionList && myAuctionList.status && myAuctionList.status) !== 2) && (myAuctionList && myAuctionList.owner && myAuctionList.owner.userName !== "hidden")) {
                return true
            } else {
                return false
            }
        } else if (myArt && myArt.owner) {
            if (((myArt && myArt.status && myArt.status) !== 2) && (myArt && myArt.owner && myArt.owner.userName !== "hidden")) {
                return true
            } else {
                return false
            }
        } else if (bidList && bidList.owner) {
            if (((bidList && bidList.status && bidList.status) !== 2) && (bidList && bidList.owner && bidList.owner.userName !== "hidden")) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }

    }
    checkEDitionCount = (items) => {
        if (items && items.groups && items.groups !== null && items.toatalMintedTokens) {
            if ((checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1) {
                return ' Total Editions'
            } else {
                return ' Total Edition'
            }
        } else {
            return ''
        }

    }
    updateCategorySuccess = () => {
        this.getDrops()
        this.setState({ isCategoryUpdateSuccess: true })
    }
    updateError = (error) => {
        this.setState({ isAlert: true, message: error, style: "danger" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    resetAfterCategoryUpdate = () => {
        this.setState({ isCategoryUpdateSuccess: false })

    }
    updateTagSuccess = () => {
        this.getDrops()
        this.setState({ isTagUpdateSuccess: true })
    }
    resetAfterTagUpdate = () => {
        this.setState({ isTagUpdateSuccess: false })
    }
    isOwnerisCreator = (data, dropData) => {
        if (getCurrentUserId()) {
            if (data.id) {
                if ((data && data.creator && data.creator.id) === (getCurrentUserId())) {
                    if (dropData && dropData.status !== 2) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
    isArtisMusicCategory = (item) => {
        if (item && item.id) {
            if (item && item.nftCategory !== null && item.nftCategory !== undefined && item.nftCategory.name === "music") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    gotoFundYourWallet = (data, TotalPrice, balance, type) => {
        const needBalance = parseFloat(TotalPrice) - parseFloat(balance)
        history.push('/fund-wallet', { obj: data, needBalance, type })
    }
    handleReport = () => {
        this.setState({ openReport: true })
    }
    handleCloseReport = () => {
        this.setState({ openReport: false })
    }
    handleReportRes = (value) => {
        if (value && value.url && value.reporterRemarks) {
            this.setState({ openReport: false })
            this.props.sendReport(JSON.stringify(value))
        }
    }
    moreMenuUI = (detail) => {
        if (!isWebLogin() || (getCurrentUserId() !== (detail && detail.creator && detail.creator.id))) {
            return <span className="dropdown">
                <a className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                    <i className="fa fa-ellipsis-v"></i>
                </a>

                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <span className="dropdown-item" onClick={this.handleReport}>
                        <i className="far fa-flag"></i> Report
                    </span>

                </div>
            </span>

        } else {
            return ''
        }

    }
    closeCadenceError = () => {
        this.setState({ isCadenceError: false, cadencError: '' })
    }
    handleClaimAirdrop = (detail) => {
        this.setState({
            selectedAirdrop: detail,
            firstAirdrop: detail && detail.airDrops && detail.airDrops[0],
            followerSuccessMessage: ''
        }, () => {
            if (isWebLogin()) {
                if (isConnectWallet()) {
                    this.createaccountSetup()
                } else {
                    this.followCheck()
                    // this.handleToSwitchAccount()
                    // this.handleWalletLogin()
                }
            } else {
                const reportPath = `${this.props && this.props.location && this.props.location.pathname}${this.props.location && this.props.location.hash}`
                history.push('/login', { reportForm: reportPath })
            }
        })

    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false,
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    handleConnectWalletMessage = () => {
        this.hideLoader()
        this.setState({ isAirDrop: true, isAirdropClaimMessage: 'Connecting wallet...' })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForAirdropClaim: true, path: '' })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isAirDrop: false

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    handleWalletLogin = async (path) => {
        this.setState({ targetPath: path, isAirDrop: true, isAirdropClaimMessage: 'Connecting wallet...' })
        try {
            const wallet = await connectBlocto()
            if (wallet && wallet.walletAddress) {
                this.hideLoader()

                this.props.linkWallet(JSON.stringify(wallet))
                this.hideLoader()
                this.setState({ isForAirdropClaim: true })
            } else {
                this.setState({
                    isAlert: true,
                    message: wallet,
                    style: "danger",
                    isAirDrop: false
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    createaccountSetup = async () => {
        this.setState({ isAirDrop: true, isAirdropClaimMessage: 'Checking flow account setup...' })

        const walletAddress = await getUserAddress()
        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.setState({
                    isAlert: true,
                    message: 'Your Flow account has been successfully added. Now you may proceed to claim NFT',
                    style: "success",
                    isAirdropClaimMessage: 'Account setup done'
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
                this.followCheck()

            } else if (setup && setup.status === 201) {
                this.setState({ isAirdropClaimMessage: 'Account already setup' })

                this.followCheck()
            } else {
                this.setState({
                    isAlert: true,
                    message: setup,
                    style: "danger",
                    isAirDrop: false
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    followCheck = (detail) => {
        const items = this.state.selectedAirdrop
        this.setState({ isAirdropClaimMessage: 'Checking followers status...', isAirDrop: true })
        this.props.getFollowers(items && items['airDrops'] && items['airDrops'][0] && items['airDrops'][0].userId)

    }
    followResponse = (res) => {
        var filterUser = []
        let items = this.state.selectedAirdrop
        console.log(res, items)

        let lgnUser = getCurrentUserId()
        if ((res && res.statusCode) == 200) {
            filterUser = res.data && res.data.follower && res.data.follower.length > 0 && res.data.follower.filter((flw) => (flw.id) == lgnUser)
            if (filterUser && filterUser.length > 0) {
                if (isConnectWallet()) {
                    this.claimArt(items)
                } else {
                    this.claimWalletlessArt(items)
                }
            } else {
                if (items && items.airDrops && items.airDrops[0] && items.airDrops[0].userId) {
                    console.log(this.isQueryStringAvailable())
                    if (this.isQueryStringAvailable()) {
                        this.props.goFollow(items.airDrops[0].userId)
                        this.setState({
                            isAirDrop: true,
                            userId: items.airDrops[0].userId,
                            isFollower: true
                        })
                    } else {
                        this.setState({
                            isAirDrop: false,
                            userId: items.airDrops[0].userId,
                            isFollower: false
                        })
                    }

                } else {
                    this.setState({ isAlert: true, message: 'Listed user id was missing', style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            }
        }
    }
    claimArt = (items) => {

        let tokenId = items && items.airDrops && items.airDrops[0] && items.airDrops[0].tokenIds && items.airDrops[0].tokenIds[0]
        let listedUserId = items && items.airDrops && items.airDrops[0] && items.airDrops[0].userId
        if (
            Number.isInteger(tokenId) &&
            Number.isInteger(listedUserId)
        ) {
            this.setState({
                isFollower: true,
                filterUser: [],
                isAirdropClaimMessage: 'Claiming your NFT...',
                isAirDrop: true
            })

            airDropService.claimAirDrop(tokenId, listedUserId).then(res => {
                res && res.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (res.ok) {
                        this.setState({ isSuccessClaim: true, isAirDrop: false })
                    } else {
                        const validationErorrs = [];

                        if (data &&
                            data.data &&
                            data.data.errorCode &&
                            (
                                data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                                data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                                data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                            ) &&
                            data.data.data[0] &&
                            Array.isArray(data.data.data[0])) {

                            for (const detail of data.data.data) {
                                if (Array.isArray(detail)) {
                                    for (const message of detail) {
                                        if (typeof message === 'string') {
                                            validationErorrs.push(message);

                                        }
                                    }
                                }
                            }
                            this.setState({ isAlert: true, message: validationErorrs[0], style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()

                        }
                        else {
                            const error = (data && data.message)
                            this.setState({ isAlert: true, message: error, style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }
                })


            }).catch(err => {
                console.log(err)
                try {
                    if (err instanceof TypeError) {
                        this.setState({ isAlert: true, message: "TypeError", style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        PubSub.publish('msg', false)
                        return Promise.reject(err)
                    } else {
                        PubSub.publish('msg', false)
                        this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                }
                catch (err) {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            })

        } else {

            this.setState({
                isAlert: true,
                message: "Something went wrong, try again later",
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    claimWalletlessArt = (items) => {

        let tokenId = items && items.airDrops && items.airDrops[0] && items.airDrops[0].tokenIds && items.airDrops[0].tokenIds[0]
        let listedUserId = items && items.airDrops && items.airDrops[0] && items.airDrops[0].userId
        if (
            Number.isInteger(tokenId) &&
            Number.isInteger(listedUserId)
        ) {
            this.setState({
                isFollower: true,
                filterUser: [],
                isAirdropClaimMessage: 'Claiming your NFT...',
                isAirDrop: true
            })

            airDropService.claimWalletlessAirDrop(tokenId, listedUserId).then(res => {
                res && res.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (res.ok) {
                        this.setState({ isSuccessClaim: true, isAirDrop: false })
                    } else {
                        const validationErorrs = [];

                        if (data &&
                            data.data &&
                            data.data.errorCode &&
                            (
                                data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                                data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                                data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                            ) &&
                            data.data.data[0] &&
                            Array.isArray(data.data.data[0])) {

                            for (const detail of data.data.data) {
                                if (Array.isArray(detail)) {
                                    for (const message of detail) {
                                        if (typeof message === 'string') {
                                            validationErorrs.push(message);

                                        }
                                    }
                                }
                            }
                            this.setState({ isAlert: true, message: validationErorrs[0], style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()

                        }
                        else {
                            const error = (data && data.message)
                            this.setState({ isAlert: true, message: error, style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }
                })


            }).catch(err => {
                console.log(err)
                try {
                    if (err instanceof TypeError) {
                        this.setState({ isAlert: true, message: "TypeError", style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        PubSub.publish('msg', false)
                        return Promise.reject(err)
                    } else {
                        PubSub.publish('msg', false)
                        this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                }
                catch (err) {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            })

        } else {

            this.setState({
                isAlert: true,
                message: "Something went wrong, try again later",
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)

    }
    isClaimAvailable = (detail) => {
        let queryString = window.location.search;
        if (detail && detail.airDrops && detail.airDrops.length > 0) {
            return true
        } else if (queryString && detail && detail.airDrops && detail.airDrops.length > 0) {
            return true
        } else if (queryString && detail && detail.airDrops && detail.airDrops.length === 0) {
            return false
        } else {
            return false
        }
    }
    isQueryStringAvailable = () => {
        let queryString = window.location.search;
        if (queryString && queryString.toLowerCase().includes('airdrop')) {
            return true
        } else {
            return false
        }
    }
    resetClaimPage = () => {
        localStorage.setItem('page', JSON.stringify(0))
        history.push('/wallet')
    }
    closeFollowerModal = () => {
        this.setState({
            isFollower: true
        })
    }
    hideWarning = () => {
        this.setState({ isWarning: false })
    }
    addToFav = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            if (isWebLogin()) {
                let obj = {
                    artId: Number(this.props.match.params.id)
                }
                this.props.addToFav(JSON.stringify(obj))
            } else {
                const reportPath = `${this.props && this.props.location && this.props.location.pathname}${this.props.location && this.props.location.hash}`
                history.push('/login', { reportForm: reportPath })
            }

        }

    }
    getFavArt = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {

            this.props.getFav(this.props.match.params.id)
        }
    }
    checkIsFavourited = (users) => {
        const uuid = getUniqueUUID()
        if (uuid && users && users.length > 0) {
            const favouritedUseris = users && users.filter(el => (el.uuid === (uuid)))
            if (favouritedUseris && favouritedUseris.length > 0) {
                this.setState({ isFavourite: true })
            } else {
                this.setState({ isFavourite: false })
            }
        } else {
            this.setState({ isFavourite: false })
        }
    }
    removeFav = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let obj = {
                artId: Number(this.props.match.params.id)
            }
            this.props.removeFromFav(JSON.stringify(obj))
        }
    }
    listPrice = (detailDropList, dropsCount) => {
        if (detailDropList && detailDropList.storefrontListingResourceId !== null && detailDropList.withDapper) {
            return <h4 className="mb-0  d-inline-block"><span className="usernamelilac"
                title={detailDropList && detailDropList.markedPrice}>{(detailDropList && detailDropList.markedPrice ? flowDecimal(parseFloat(detailDropList.markedPrice)) + ' USD' : '')}</span>&nbsp;
                {detailDropList && detailDropList.markedPrice && dropsCount ? <span className="small"> / 1 Edition</span> : <span></span>}  </h4>
        } else {
            return <h4 className="mb-0  d-inline-block"><span className="usernamelilac"
                title={detailDropList && detailDropList.markedPrice}>{(detailDropList && detailDropList.markedPrice ? flowDecimal(parseFloat(detailDropList.markedPrice)) + ' ' + 'FLOW' : '')}</span>&nbsp;
                {detailDropList && detailDropList.markedPrice && dropsCount ? <span className="small"> / 1 Edition</span> : <span></span>}  </h4>
        }

    }
    handleDapperConnectWallet = async () => {
        const { detailDropList } = this.state

        const listingResourceId = detailDropList && detailDropList.storefrontListingResourceId
        const isDapper = detailDropList && detailDropList.withDapper


        if (listingResourceId && isDapper) {
            this.setState({ isDapperPurchaseStart: true, isDapperPurchaseMessage: "Connecting wallet..." })
            const res = await switchAuthDapper()
            if (res) {
                await this.props.disconnect()
                try {
                    const wallet = await connectDapper()
                    if (wallet && wallet.walletAddress) {
                        this.setState({ isDapperPurchase: true })
                        this.props.linkWallet(JSON.stringify(wallet))

                    } else {

                        this.setState({
                            isAlert: true,
                            message: wallet,
                            style: "danger",
                            isDapperPurchaseStart: false
                        })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                } catch (e) {

                    this.setState({
                        isAlert: true,
                        message: e,
                        style: "danger",
                        isDapperPurchaseStart: false
                    })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            } else {
                this.handlePurchase()
            }
        } else {
            this.setState({
                isAlert: true,
                message: `Purchase not available`,
                style: "danger",
                isDapperPurchaseStart: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }



    }
    handlePurchase = async () => {

        const { dropList, detailDropList, artPrice, tokensArray, totalOwnerArt } = this.state


        const tokenId = detailDropList && detailDropList.art && detailDropList.art.tokenId
        let listingResourceId = detailDropList && detailDropList.storefrontListingResourceId
        const storeFrontAddress = process.env.REACT_APP_STORE_ADMIN_ACCOUNT
        const isDapper = detailDropList && detailDropList.withDapper
        let listingResourceIds = detailDropList && detailDropList.listingResourceIds
        if (listingResourceIds && listingResourceIds.length > 0) {
            listingResourceId = listingResourceIds[Math.floor(Math.random() * listingResourceIds.length)];
        }
        var transactionId = ""
        var transactionDetail = []


        const tokens = []


        let arrayLength = 0
        let checkCount = 0
        tokens.push(tokenId)

        if ((totalOwnerArt == 1) && (tokensArray && tokensArray.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((totalOwnerArt > 1) && (tokensArray && tokensArray.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.purchaseCount
            arrayLength = tokensArray && tokensArray.length

        }
        if (storeFrontAddress && artPrice && listingResourceId && isDapper) {
            this.setState({ isDapperPurchaseStart: true })

            if (isDapperConnected()) {
                try {
                    const currentuserAddress = await getUserAddress()
                    if (currentuserAddress) {
                        let selectedTokens = (tokensArray && tokensArray.length > 0 && (totalOwnerArt > 1)) ? tokensArray.slice(0, checkCount) : tokens
                        const tokenArray = selectedTokens

                        // const TotalPrice = artPrice && artPrice != null && parseFloat(artPrice * checkCount).toFixed(8)
                        this.setState({
                            disablePurchase: true,
                            selectedTokenId: listingResourceId,
                            isDapperPurchaseMessage: `Purchasing NFT.. Please wait, Don't refresh or close this page`
                        })
                        const checkWalletRes = await walletServices.checkWallet(currentuserAddress)
                        if (checkWalletRes && checkWalletRes.statusCode === 200) {
                            //save event
                            await dropServices.saveEvent((detailDropList.art && detailDropList.art.tokenGroupId), tokenArray.length, dropServices.DropEventType.PURCHASE, (detailDropList.art && detailDropList.art.tokenId))
                            //purchase
                            const purchaseRes = await purchaseTokens(listingResourceId, storeFrontAddress, artPrice)
                            if ((purchaseRes && purchaseRes.status) === 200) {

                                if ((purchaseRes && purchaseRes.data && purchaseRes.data.transaction && purchaseRes.data.transaction.errorMessage)) {
                                    this.setState({
                                        isErrorPoped: true,
                                        isDapperPurchaseStart: false,
                                        disablePurchase: false
                                    })
                                    if (purchaseRes.data.transaction.errorMessage.toLowerCase().includes('dapperutilitycoin leakage')) {
                                        this.setState({
                                            cadencErrorMessage: String(`Payment processing failed [Error 1101]. Your card provider / bank declined the transaction. Try again with a different card`),
                                        })

                                    } else {
                                        this.setState({
                                            cadencErrorMessage: String(purchaseRes.data.transaction.errorMessage),
                                        })
                                    }
                                } else {
                                    this.setState({ isSuccess: true, isErrorPoped: false, disablePurchase: false, isDapperPurchaseStart: false })
                                    transactionId = purchaseRes && purchaseRes.data && purchaseRes.data.Value ? purchaseRes.data.Value : ''
                                    transactionDetail = purchaseRes && purchaseRes.data && purchaseRes.data.transaction && purchaseRes.data.transaction.events ? purchaseRes.data.transaction.events : []
                                }
                            } else {
                                let mes = this.getTxnErrorForPurchase(purchaseRes?.data?.Error)

                                this.setState({
                                    isErrorPoped: true,
                                    cadencErrorMessage: String(mes),
                                    isDapperPurchaseStart: false,
                                    disablePurchase: false
                                })
                            }
                            console.log("buy", purchaseRes, transactionId, transactionDetail)
                        } else {
                            this.setState({
                                isErrorPoped: true,
                                cadencErrorMessage: String(checkWalletAddress),
                                isDapperPurchaseStart: false,
                                disablePurchase: false
                            })
                        }




                    } else {
                        this.setState({
                            isErrorPoped: true,
                            cadencErrorMessage: 'Please reconnect your wallet address',
                            isDapperPurchaseStart: false,
                            disablePurchase: false
                        })
                    }



                } catch (e) {
                    this.setState({ isDapperPurchaseStart: false, disablePurchase: false, isErrorPoped: true })
                    if (e && e.data && e.data.Error) {
                        if (e.data.Error.toLowerCase().includes('dapperutilitycoin leakage')) {
                            this.setState({
                                cadencErrorMessage: `Payment processing failed [Error 1101]. Your card provider / bank declined the transaction. Try again with a different card`,
                            })
                        } else {
                            this.setState({ cadencErrorMessage: String(e.data.Error) })
                        }
                    } else if (e && e.Error) {
                        if (e.Error.toLowerCase().includes('dapperutilitycoin leakage')) {
                            this.setState({
                                cadencErrorMessage: `Payment processing failed [Error 1101]. Your card provider / bank declined the transaction. Try again with a different card`,
                            })
                        } else {
                            this.setState({ cadencErrorMessage: String(e.Error) })
                        }
                    } else if (e && e.message) {
                        if (e.message.toLowerCase().includes('dapperutilitycoin leakage')) {
                            this.setState({
                                cadencErrorMessage: `Payment processing failed [Error 1101]. Your card provider / bank declined the transaction. Try again with a different card`,
                            })
                        } else {
                            this.setState({ cadencErrorMessage: String(e.message) })
                        }
                    } else {
                        this.setState({ cadencErrorMessage: String(e) })

                    }
                }




            } else {
                this.handleDapperConnectWallet()
            }
        } else {
            this.setState({
                isAlert: true,
                message: `Purchase not available`,
                style: "danger",
                isDapperPurchaseStart: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    getTxnErrorForPurchase(error) {
        if (error && error.split(':') && error.split(':')[2] && error.split(':')[2].includes("Session expired")) {
            return "Session Expired.Please reconnect your wallet account"
        } else if (error.split(':')[2]) {
            return error.split(':')[2]
        } else {
            if (error && error.toLowerCase().includes('declined')) {
                return 'User Rejected Signature'
            } else {
                return error.split(':')[1]
            }
        }
    }
    showPriceInSelfPage = (detailMyArt) => {
        if (detailMyArt && detailMyArt.withDapper && detailMyArt.storefrontListingResourceId) {
            return <h4 className=" d-inline-block" ><span className="usernamelilac" title={detailMyArt && detailMyArt.markedPrice}>{detailMyArt && detailMyArt.markedPrice && flowDecimal(parseFloat(detailMyArt.markedPrice)) + ' USD'}</span>&nbsp;<span className="small"> / 1 Edition</span> </h4>
        } else {
            return <h4 className=" d-inline-block" ><span className="usernamelilac" title={detailMyArt && detailMyArt.markedPrice}>{detailMyArt && detailMyArt.markedPrice && flowDecimal(parseFloat(detailMyArt.markedPrice)) + ' FLOW'}</span>&nbsp;<span className="small"> / 1 Edition</span> </h4>
        }

    }
    show_cp_withdraw = (detailMyArt) => {
        if (detailMyArt && detailMyArt.withDapper && detailMyArt.storefrontListingResourceId) {
            return false
        } else {
            return true
        }

    }
    handleResale = async () => {
        const { tokensArray, totalOwnerArt, count, myArt, detailMyArt } = this.state
        let listingResourceIds = detailMyArt && detailMyArt.listingResourceIds
        let tokenIds = []
        const dropId = detailMyArt && detailMyArt.id
        let arrayLength = 0
        let checkCount = 0
        tokenIds.push(this.state.tokenId)

        if ((listingResourceIds && listingResourceIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((listingResourceIds && listingResourceIds.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.count
            arrayLength = listingResourceIds && listingResourceIds.length

        }

        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

            const shuffled1 = [...listingResourceIds].sort(() => 0.5 - Math.random());
            const randomArray = shuffled1.slice(0, checkCount);

            let selectedTokens = (listingResourceIds && listingResourceIds.length > 0) ? randomArray : tokenIds
            if (isConnectWallet()) {
                try {
                    const currentuserAddress = await getUserAddress()
                    this.showLoader()

                    const checkWalletRes = await walletServices.checkWallet(currentuserAddress)
                    if (checkWalletRes && checkWalletRes.statusCode === 200) {
                        await dropServices.saveEvent(
                            (detailMyArt.art && detailMyArt.art.tokenGroupId),
                            selectedTokens.length,
                            dropServices.DropEventType.WITHDRAW,
                            (detailMyArt.art && detailMyArt.art.tokenId)
                        )
                        let obj = {
                            recipientAddress: currentuserAddress,
                            action: "withdraw",
                            listingResourceIds: selectedTokens
                        }
                        const withdrawRes = await dropServices.withdrawArt(JSON.stringify(obj), dropId)
                        if (withdrawRes && withdrawRes.statusCode === 200) {
                            this.hideLoader()
                            this.setState({ isWithDrawn: true })

                        } else {
                            this.hideLoader()
                            this.setState({ isErrorPoped: true, cadencErrorMessage: String(withdrawRes) })
                        }


                    } else {
                        this.hideLoader()
                        this.setState({ isAlert: true, message: 'Failed to check your wallet address', style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                } catch (e) {
                    this.setState({ isErrorPoped: true })
                    this.hideLoader()
                    if (e && e.data && e.data.Error) {

                        this.setState({ cadencErrorMessage: String(e.data.Error) })

                    } else if (e && e.Error) {

                        this.setState({ cadencErrorMessage: String(e.Error) })

                    } else if (e && e.message) {

                        this.setState({ cadencErrorMessage: String(e.message) })

                    } else {
                        this.setState({ cadencErrorMessage: String(e) })

                    }
                }
            }



        } else {
            this.setState({ isAlert: true, message: 'Please enter valid count', style: "danger", count: '' })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            this.setState({ disableWithdraw: false })
            this.hideLoader()

        }
    }


    withdrawcloseSwitchAccModal = () => {
        this.setState({
            isWithdrawSwitchAcc: false,
        })
    }
    handleToWithdrawSwitchAccount = () => {
        this.setState({
            isWithdrawSwitchAcc: true
        })
    }
    withdrawswitchWalletConnected = (wallet) => {
        if (wallet && wallet.walletAddress) {
            this.withdrawcloseSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForWithdrawArt: true, currentUserAddress: wallet.walletAddress })
        }
    }
    handleWithdrawSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isAirDrop: false

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    handleBloctoWalletLogin = async () => {
        this.handleToWithdrawSwitchAccount()
    }
    openWithdrawWarnPopup = () => {
        if (this.show_cp_withdraw(this.state.detailMyArt)) {
            this.handleResale1()
        } else {
            this.setState({ isOpenWithdraw: true })

        }
    }
    closeWithdraw = () => {
        this.setState({ isOpenWithdraw: false })
    }
    approveWithdraw = () => {
        this.setState({ isOpenWithdraw: false })
        if (this.show_cp_withdraw(this.state.detailMyArt)) {
            this.handleResale1()
        } else {
            this.handleResale()
        }
    }
    openWithdrawCountPopup = () => {
        const withdrawTotalCount = this.state.tokensArray && this.state.tokensArray.length
        if (withdrawTotalCount > this.maxWithdrawLimit) {
            this.setState({ isOpenWithdrawCount: true, count: this.maxWithdrawLimit })
        } else {
            this.setState({ isOpenWithdrawCount: true, count: withdrawTotalCount })
        }
    }
    closeWithdrawCount = () => {
        this.setState({ isOpenWithdrawCount: false })
    }
    approveWithdrawCount = () => {
        if (this.state.count && (Number(this.state.count) > 0)) {
            this.setState({ isOpenWithdrawCount: false })
            if (this.show_cp_withdraw(this.state.detailMyArt)) {
                this.handleResale1()
            } else {
                this.handleResale()
            }
        }
    }
    showCurrencyValue = (item) => {
        if (item &&
            item.priceDetail &&
            item.priceDetail.withDapper) {
            return 'USD'
        } else {
            return 'FLOW'
        }
    }
    isDapperArt = (items) => {
        if (items && items.drops && items.drops[0] && items.drops[0].withDapper) {
            return true
        } else {
            return false
        }
    }
    getCurrentUserwalletAddress = () => {
        getUserAddress()
            .then((address) => {
                this.setState({ currentUserAddress: address })
            })
            .catch((error) => {
                this.setState({ currentUserAddress: null })
            });
    }

    checkaccountSetup = async () => {

        const walletAddress = await getUserAddress()
        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.setState({
                    isAlert: true,
                    message: 'Your Flow account has been successfully added. Now you may proceed to claim NFT',
                    style: "success",
                })
                this.resetTimeout()
                this.closeAlert()
                if (this.state.tokensArray && this.state.tokensArray.length > 1) {
                    this.openWithdrawCountPopup()
                } else {
                    this.openWithdrawWarnPopup()
                }
            } else if (setup && setup.status === 201) {
                if (this.state.tokensArray && this.state.tokensArray.length > 1) {
                    this.openWithdrawCountPopup()
                } else {
                    this.openWithdrawWarnPopup()
                }
            } else {
                this.setState({
                    isAlert: true,
                    message: setup,
                    style: "danger",
                    isAirDrop: false
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger",
                isAirDrop: false
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    clearCelebrationTimeout = () => {
        celebrationTimeout = setTimeout(() => {
            this.setState({
                isCelebrateSplash: false,
            })
        }, 4000)
    }
    viewAllSerialNos = () => {
        this.setState({ isOpenSerialNoPopup: true })
    }
    closeSerialNoPopup = () => {
        this.setState({ isOpenSerialNoPopup: false })
    }
    getAllSerialNo = (id) => {
        artServices.artWithSerials(id).then((data) => {
            if ((data && data.statusCode) === 200) {
                let serialData = data.data.arts
                this.setState({
                    serialNos: serialData
                })

            } else {
                this.setState({ serialNos: [] })
            }
        }).catch(err => {
            this.setState({ serialNos: [] })
            if (err && typeof err === 'string') {
                this.setState({
                    isAlert: true,
                    message: err,
                    style: "danger",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }

        })
    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    getAvailableBadges = () => {
        artServices.getAllAvailableBadges().then(res => {
            if (res && res.statusCode === 200) {
                if (res && res.data && res.data.badges) {
                    this.setState({
                        availableBadges: res.data.badges
                    })
                    this.getAllselectedBadges()
                }
            } else {
                this.setState({
                    availableBadges: []
                })
            }
        }).catch(e => {
            this.setState({
                availableBadges: []
            })
        })
    }
    getAllselectedBadges = () => {
        const art_id = this.props && this.props.match && this.props.match.params && this.props.match.params.id

        artServices.getSelectedBadges(art_id).then(res => {
            // this.hideLoader()
            if (res && res.statusCode === 200) {
                if (res && res.data && res.data.badges) {
                    this.setState({
                        selectedBadges: res.data.badges
                    })
                }
            } else {
                this.setState({
                    selectedBadges: []
                })
            }
        }).catch(e => {
            this.setState({
                selectedBadges: []
            })
        })
    }
    updateBadgeSuccess = () => {
        this.setState({
            isAlert: true,
            message: "Badge(s) updated successfully",
            style: "success",
        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
        this.getAvailableBadges()

    }
    updateBadgeFailed = (err) => {
        this.setState({
            isAlert: true,
            message: err,
            style: "danger",
        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    removeBadges = (detail) => {
        this.setState({ isRemoveBadgePopup: true })
    }
    closeRemoveBadgePopup = () => {
        this.setState({ isRemoveBadgePopup: false })
    }
    approveRemoveBadges = () => {
        this.closeRemoveBadgePopup()
        const art_id = this.props && this.props.match && this.props.match.params && this.props.match.params.id
        this.showLoader()
        artServices.removeBadges(art_id).then(res => {
            this.hideLoader()
            console.log(res)
            if (res && res.status === 204) {
                this.setState({
                    isAlert: true,
                    message: "Badge(s) removed successfully",
                    style: "success",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
                this.getAvailableBadges()
            }
        }).catch(e => {
            this.hideLoader()
            if (e && typeof e === 'string') {
                this.setState({
                    isAlert: true,
                    message: e,
                    style: "danger",
                })
            } else {
                this.setState({
                    isAlert: true,
                    message: "Unhandled error occured while deleting badges",
                    style: "danger",
                })
            }
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        })
    }
    handleTabChange = (idx) => {
        if(idx === 3){
            this.getAvailableBadges()
        }
        this.setState({
            tabIndex : idx
        })
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        const id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id)
        const { detailList,
            isShowLoader,
            myAuctionList,
            myArt,
            dropList,
            bidList,
            relativeArts,
            artList,
            dropsCount,
            totalOwnerArt,
            totalEdition,
            tokensArray,
            artPrice,
            balance,
            priceCount,
            totalDropArray,
            totalDrop,
            auctionHistory,
            detailDropList,
            bidDetailList,
            detailMyArt,
            detailMyAuctionList,
            ownerShipDetail,
            isOwnershipFailed,
            isArtDisabled,
            disabledMessage,
            isInvalidPage,
            firstAirdrop,
            isCelebrateSplash,
            serialNos
        } = this.state;

        // const availableDropsCountNotForSale = ((dropsCount && dropsCount.count && dropsCount.count != null) ? (dropsCount && dropsCount.count) : totalOwnerArt);
        const availableDropsCount = ((dropsCount) ? (dropsCount) : 0);
        const notSaleCount = ((totalOwnerArt - totalEdition))
        const artIds = dropsCount && dropsCount[0] && dropsCount[0].artIds && dropsCount[0].artIds.split(',')
        const sharedPath = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const dropShare = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const artShare = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const myShare = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const bidShare = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`

        const auctionShare = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm', 'audio/mpeg', 'audio/wav']
        const ownerAddress = dropList && dropList.art && dropList.art.owner && dropList.art.owner.userAddress;
        const meta = this.getArtMetaData();
        const metaTitle = `${meta.title} by ${meta.creatorFullName}(@${meta.creatorUserName}) NFT on ${process.env.REACT_APP_BASE_SITE}`;
        const metaTitle1 = `${meta.title} NFT by ${meta.creatorFullName}(@${meta.creatorUserName}) on ${process.env.REACT_APP_BASE_SITE}`;

        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={metaTitle1}></meta>
                    <meta name="description" content={meta.description}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {this.state.isWarning && <div className={"alert alert-custom text-center alert-warning alertheight"}  >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{"Airdrop not available to claim"}</span>
                        <span className="closebtn" onClick={this.hideWarning}>&times;</span>

                    </div>

                </div>}
                <Modal
                    isOpen={this.state.openLogin}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <SignIn enablePurchase={() => this.enablePurchase(false)} />
                </Modal>
                {this.state.isPriceChange &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={myArt && myArt.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Price Changed Successfully...!</b></div>
                            <div className="mt-3">It may take 30 seconds for your changes to appear in drops</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Go To Collections</button></div></div>
                }
                {this.state.isWithDrawn &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={myArt && myArt.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Art Withdrawn and moved back to your wallet <span className='usernamelilac'>({this.state.currentUserAddress})</span> Successfully</b></div>
                            <div className="mt-3">It may take 30 seconds for your changes to appear in drops</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Go To Collections</button></div></div>
                }
                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

                        </div>
                    </div>
                }
                <Modal
                    isOpen={this.state.isPurchase}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal">
                    <div className="custom-ui text-left p-5">
                        <button onClick={this.closePurchase} className="btn btn-primary px-2 clsbtn">X</button>
                        <h5 className="modal-title" id="exampleModalLongTitle">Enter the count <span className="small d-block">(No of tokens you want to purchase)</span></h5>
                        <div className="py-3">
                            {!this.state.purchaseCount && <div className="mandatory-req mt-2 text-center small">{`INFO : Count should be less than or eqaul to ${tokensArray && tokensArray.length}`}</div>}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Edition</span>
                                <input type="number" value={this.state.purchaseCount} onChange={this.handlePurchaseCount} className="form-control w-75px" />
                            </div>
                        </div>
                        <div className="modal-footer1 text-center">
                            <button type="button" className="btn btn-secondary mr-2 sm-mr-0 mb-3" onClick={this.closePurchase}>Close</button>
                            <button type="button" className="btn btn-primary mb-3" onClick={this.handlePurchase}>Purchase</button>
                        </div>

                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isOwnedTokens}
                    style={customStyles}
                    ariaHideApp={false}
                    className="creatorrequ"
                    contentLabel="Example Modal">
                    <div className="custom-ui p-5">
                        <button onClick={this.closeOwnedTokens} className="btn btn-primary px-2 clsbtn">X</button>
                        <h5 className="modal-title" id="exampleModalLongTitle">{(ownerShipDetail && ownerShipDetail.ownedCopies)} tokens owned by {(ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.userName && `@${ownerShipDetail.owner.userName}`)}</h5>
                        <div className="py-3">
                            <div className="row mb-4">{ownerShipDetail && ownerShipDetail.ownedTokens && ownerShipDetail.ownedTokens.map(items => (
                                <div className={(ownerShipDetail && ownerShipDetail.ownedTokens && ownerShipDetail.ownedTokens.length > 4) ? '' : ''}><div className="comboxgray mb-3 mr-2">{items && `#${items}`}</div></div>
                            ))}</div>
                        </div>
                        {/* <div className="modal-footer1 text-center">
                            <button type="button" className="btn btn-secondary mr-2 sm-mr-0 mb-3" onClick={this.closeOwnedTokens}>Close</button>
                        </div> */}

                    </div>
                </Modal>
                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </div>}

                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }
                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={dropList && dropList.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo! Art Purchased Successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your purchase to appear in your collections :) </div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>View Purchased Art</button></div></div>
                }
                {this.state.isAuctionSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={bidList && bidList.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo! Bid Placed Successfully!</b></div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Close</button></div></div>
                }
                {this.state.isCancelBid &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={myAuctionList && myAuctionList.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Bidding for this art cancelled successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your art to disappear in drops :) </div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Close</button></div></div>
                }

                {/* if settlement done popup*/}
                {(this.state.isSettlementSuccess == 3) &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={(myAuctionList && myAuctionList.thumbnailHash)} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Settlement process for this art was successful!</b></div>
                            <div className="mt-3">It may take 30 seconds for your art settlement :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Close</button></div></div>
                }
                {/* if auction revoke done popup*/}
                {(this.state.isSettlementSuccess == 1) &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={((myAuctionList && myAuctionList.thumbnailHash) || (bidList && bidList.thumbnailHash))} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Auction Revoked successfully!</b></div>
                            <div className="mt-3">Your NFT will be restored back to your collection in 30 seconds :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Close</button></div></div>
                }
                {/* if claim done */}
                {(this.state.isSettlementSuccess == 2) &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={(bidList && bidList.thumbnailHash)} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Art Claimed successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your claimed art to appear in your collection :) </div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Close</button></div></div>
                }
                {this.state.isMyartStatusChangeSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={((myAuctionList && myAuctionList.thumbnailHash)) || (myArt && myArt.thumbnailHash)} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Successfully changed art's visibility status</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your listed auction art to appear in drops :)</div> */}

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetStatusPage() }}>Close</button></div></div>

                }
                {this.state.isCategoryUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={((myAuctionList && myAuctionList.thumbnailHash)) || (myArt && myArt.thumbnailHash)} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated category for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterCategoryUpdate() }}>Close</button></div></div>

                }
                {this.state.isTagUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={((myAuctionList && myAuctionList.thumbnailHash)) || (myArt && myArt.thumbnailHash)} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated tag for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterTagUpdate() }}>Close</button></div></div>

                }
                {(this.state.openReport) ? <ReportForm props={this.props} handleCloseReport={this.handleCloseReport} handleReportRes={(v) => { this.handleReportRes(v) }} /> : ''}
                {(this.state.isCadenceError) ?
                    <div className="show-success">
                        <div className="success-pop" style={{ wordBreak: 'break-word', maxWidth: '40rem' }}>

                            <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                            </svg>
                            <div className="mt-3">{this.state.cadencError}</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); this.closeCadenceError() }}>Close</button>

                        </div>

                    </div> : ''
                }
                {this.state.isAirDrop ?
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">{this.state.followerSuccessMessage}</p>

                            <p className="text-white">{this.state.isAirdropClaimMessage}</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div> : ''
                }
                {this.state.isSuccessClaim &&
                    <div className="show-success">
                        <div className="success-pop">
                            {/* {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />} */}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully Claimed! Good luck!</b></div>
                            <div className="mt-3">It may take 30 seconds for your claimed art to appear in collections :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetClaimPage() }}>View Collection</button></div></div>

                }
                {(this.state.isFollower) ? '' :
                    <FollowPopup
                        fullName={firstAirdrop && firstAirdrop.user && firstAirdrop.user.fullName}
                        profileImage={firstAirdrop && firstAirdrop.user && firstAirdrop.user.profileImage}
                        userName={firstAirdrop && firstAirdrop.user && firstAirdrop.user.userName}
                        closeModal={this.closeFollowerModal}
                        ownerDetail={firstAirdrop && firstAirdrop.user}
                        handleFollow={this.handleFollow} />}

                {this.state.isDapperPurchaseStart ?
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">{this.state.isDapperPurchaseMessage}</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div> : ''
                }
                {this.state.isErrorPoped && !this.state.isSuccess &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{this.state.cadencErrorMessage}</div>
                            {this.state.selectedTokenId ? <div className='py-3'>Listing Id : {this.state.selectedTokenId}</div> : ''}
                            {this.state.txnId ? <div className='py-3'>Transaction id : {this.state.txnId}</div> : ''}

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false }) }}>Close</button></div></div></div>
                }
                <Modal
                    isOpen={this.state.isOpenWithdraw}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">

                        <div className="py-3">

                            <h2 className='mb-3 text-center'>Do you want to withdraw ?</h2>
                            {<div className="alert alert-cuslilac text-center mb-3">{commonInfo.WITHDRAW_INFO}</div>}
                            <div className="col-sm-12 text-center">
                                <h5 className="mb-3 ">Editions listed for sale {tokensArray && (tokensArray.length)}</h5>
                                {!this.show_cp_withdraw(this.state.detailMyArt) && <div className="mt-2">{`You may withdraw all editions or few of them. Incase you listed more than 100 Editions., Then try withdrawing 50 Editions at a time.`}</div>}
                            </div>

                            <div className='row mt-5'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdraw}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary-fill' type="button" onClick={this.approveWithdraw}>Withdraw</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isOpenWithdrawCount}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">
                        <div className="py-3">
                            <h2 className='mb-3 text-center'>Withdraw NFT from Drops</h2>

                            {<div className="alert alert-cuslilac text-center mb-3">{commonInfo.WITHDRAW_INFO}</div>}

                            <div className="input-group1 dollorsec1 mb-3">
                                {(tokensArray && tokensArray.length > 1) &&
                                    <div className="row withdrawnedition">
                                        <div className="col-sm-12 text-center">
                                            <h5 className="mb-3 ">Editions listed for sale {tokensArray && (tokensArray.length)}</h5>
                                            <div className='input-group align-items-center'>
                                                <div className='input-group-prepend pl-5 pr-2'>Withdraw</div>
                                                <input name="count" value={this.state.count} className="form-control text-center brdlftcus" onChange={this.handleCount} />
                                                <div className='input-group-append pr-5 pl-2'>Editions</div>
                                            </div>
                                            {!this.show_cp_withdraw(this.state.detailMyArt) && <div className="mt-2">{`You may withdraw all editions or few of them. Incase you listed more than 100 Editions., Then try withdrawing 50 Editions at a time.`}</div>}

                                            {this.show_cp_withdraw(this.state.detailMyArt) && !this.state.count && (tokensArray && (tokensArray.length) > 300) && <div className="mandatory-req">{`INFO : Edition must be less than or equal to 300 (must be whole number)`}</div>}
                                            {this.show_cp_withdraw(this.state.detailMyArt) && !this.state.count && !(tokensArray && (tokensArray.length) > 300) && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${tokensArray && tokensArray.length} (must be whole number)`}</div>}

                                            {/* {!this.show_cp_withdraw(this.state.detailMyArt) && !this.state.count && (tokensArray && (tokensArray.length) > this.maxWithdrawLimit) && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${this.maxWithdrawLimit} (must be whole number)`}</div>}
                                            {!this.show_cp_withdraw(this.state.detailMyArt) && !this.state.count && !(tokensArray && (tokensArray.length) > this.maxWithdrawLimit) && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${tokensArray && tokensArray.length} (must be whole number)`}</div>} */}

                                        </div>
                                    </div>}
                            </div>

                            <div className='row mt-5'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdrawCount}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary-fill' type="button" onClick={this.approveWithdrawCount}>Withdraw</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* for claim button----------- */}
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        handleConnectWalletMessage={this.handleConnectWalletMessage}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                    : ''}
                {/* for withdraw button---------- */}
                {this.state.isWithdrawSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.withdrawcloseSwitchAccModal}
                        switchWalletConnected={(val) => this.withdrawswitchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleWithdrawSwitchAccFailed(val)} />
                    : ''}
                {/* ------token serial numbers-------- */}
                <Modal
                    isOpen={this.state.isOpenSerialNoPopup}
                    style={customStyles}
                    ariaHideApp={false}
                    className="creatorrequ"
                    contentLabel="Example Modal">
                    <div className="custom-ui reportpop text-left py-3 px-3 minw-35rem">
                        <div className="dspcls" onClick={this.closeSerialNoPopup}>x</div>

                        <div className="py-3">
                            <h5 className="modal-title mb-3 text-center" id="exampleModalLongTitle">Edition Index</h5>
                            <div className='maxscroll'>
                                <div className="row">
                                    {serialNos && serialNos.length > 0 && serialNos.map(items => (
                                        <div className='col-md-6 mb-3'>
                                            <div className="snotid">
                                                {(items && items.serialNumber) ? <span className=''><span className='usernamelilac'> {(items.serialNumber)}<sup>{(getOrdinalSuffix(items.serialNumber))}</sup> Edition</span></span> : ''}
                                                <span>Token ID - <span className='usernamelilac'>{items && items.tokenId && `${items.tokenId}`}</span></span>
                                            </div>
                                        </div>
                                    ))}</div>
                            </div>
                        </div>


                    </div>
                </Modal>
                {/* **********remove badge************* */}
                <Modal
                    isOpen={this.state.isRemoveBadgePopup}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">
                        <div className="py-3">
                            <h2 className='mb-3 text-center'>Do you want to remove badges for this NFT?</h2>


                            <div className='row mt-5'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeRemoveBadgePopup}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary-fill' type="button" onClick={this.approveRemoveBadges}>Remove</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {(!(isArtDisabled) && !(isInvalidPage)) ? <div>
                    {/* ***************Drop detail*********** */}
                    {(this.state.isDrop && !this.state.showAuction) ? <div className='dandetails'>
                        <div className='container'>
                            <div className='dandpc'>
                                <div className='dandpic'>
                                    <div className='dandpicimg'>
                                        <ArtViewPreview src={dropList && dropList.imageHash ? dropList && dropList.imageHash : `${configPath.imageAssets}/art-3.jpg`} className="mw-100" showControls={true} isDetail={true} isPremiumArt={dropList && dropList.isPremiumArt} mimeType={dropList && dropList.imageMimeType} sharedData={dropList} />
                                    </div>
                                    <div className='dandpiccont'>

                                        <div className="purchasebox list_price_box">
                                            <div className={!(this.isPurchased(detailDropList)) ? 'row justify-content-center align-items-center' : 'row'}>
                                                <div className="col-7 list_price"> <h5>List Price</h5></div>
                                                <div className="col-5 purchase_nft text-center">
                                                    {!(this.isPurchased(detailDropList)) ? <div className='daplogo'>
                                                        <img src={`../../../../${configPath.imageAssets}/dapper.png`} alt="" /> Dapper
                                                    </div> : ''}
                                                </div>

                                                <div className="col-md-7 list_price">{this.listPrice(detailDropList, dropsCount)}</div>
                                                {!(this.isPurchased(detailDropList)) &&
                                                    this.purchaseActivityCheck(detailDropList)}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='dandcont'>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(1)}>
                                            <button className={(this.state.tabIndex === 1) ? "nav-link active" : 'nav-link'} id="Story-tab" data-toggle="tab" data-target="#Story" type="button" role="tab" aria-controls="Story" aria-selected="true">Story</button>
                                        </li>
                                        <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(2)}>
                                            <button className={(this.state.tabIndex === 2) ? "nav-link active" : 'nav-link'} id="Details-tab" data-toggle="tab" data-target="#Details" type="button" role="tab" aria-controls="Details" aria-selected="false">Details</button>
                                        </li>
                                        <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(3)}>
                                            <button className={(this.state.tabIndex === 3) ? "nav-link active" : 'nav-link'} id="Badges-tab" data-toggle="tab" data-target="#Badges" type="button" role="tab" aria-controls="Badges" aria-selected="false">Badges</button>
                                        </li>
                                    </ul>
                                    <div className='dandconticons'>

                                        {this.state.isFavourite ? <a className='active' onClick={this.removeFav}><i className='fa fa-heart' ></i></a> : <a onClick={this.addToFav}><i className='fa fa-heart-o' ></i></a>}
                                        <a onClick={this.toggleMenu}><i className='fa fa-share-alt'></i></a>
                                        {this.moreMenuUI(dropList)}

                                    </div>

                                    {this.state.visible && this.socialShareUI(dropList)}

                                    <div className="tab-content">
                                        <ArtStoryTab artDetail={dropList} tabIndex={this.state.tabIndex} />
                                        <div className={(this.state.tabIndex === 2) ? "tab-pane active" : "tab-pane "} id="Details" role="tabpanel" aria-labelledby="Details-tab">
                                            {(this.isPurchased(detailDropList)) ? this.getPurchasedDetail(detailDropList) : ''}
                                            <div className="purchasebox">
                                                <div className="row for_sale_box">
                                                    {!(this.isPurchased(detailDropList)) && <div className="col-md-8 for_sale_price">
                                                        <h5 className="mb-3">For Sale</h5>
                                                        {showTotalEditionsAfterOf(detailDropList, availableDropsCount, dropList)}
                                                    </div>}
                                                    <div className="col-sm-4 for_sale_listed_by">
                                                        <h5>Listed by {this.getListUser(detailDropList && detailDropList.user)}</h5>
                                                        <h6 className="mb-0">{detailDropList && detailDropList.updatedAt && moment(detailDropList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="purchasebox edition_box">
                                                <div className="row">
                                                    <div className="col-md-4 edition_box_editions">
                                                        <h5 className="mb-3">Total Editions</h5>
                                                        {showTotalEditionsforDrops(detailDropList, dropList)}{(serialNos && serialNos.length > 0) ? <a className="usernameblue cursor-pointer small" onClick={() => this.viewAllSerialNos()}>View Edition's Index</a> : ''}</div>


                                                    <div className="col-md-4 edition_creators">
                                                        <h5 className="mb-2">Creator</h5>
                                                        {(dropList && dropList.isBab) ? showCreatorName() : <div className='mb-3 creatordetalist'>
                                                            <span className='pr-1'>{this.getPurchasedCount((dropList && dropList.artOwners), (dropList && dropList.creator))}</span>{this.getCreator((dropList && dropList.artOwners), (dropList && dropList.creator))}

                                                        </div>}

                                                    </div>
                                                    {/* If owner is creator only then no need to show */}
                                                    {((dropList && dropList.artOwners && dropList.artOwners.length == 1) &&
                                                        (dropList && dropList.artOwners && dropList.artOwners[0] && dropList.artOwners[0].ownerUserId === (dropList.creator && dropList.creator.id))
                                                    ) ? '' :
                                                        <div className="col-md-4 edition_collectors">
                                                            <h5 className="mb-2">Collectors</h5>
                                                            {this.getCollectorsList((dropList && dropList.artOwners), (dropList && dropList.creator))}
                                                            {((dropList && dropList.artOwners && dropList.artOwners.length > 3)) &&
                                                                <a className="usernameblue mt-3 small d-inline-block" id="uploadArt" onClick={this.handleViewAllCollectors}>{this.state.showAllCreators ? "Hide" : "View all Collectors"}</a>}
                                                        </div>}

                                                </div>
                                            </div>

                                            {(dropList && dropList.isBab) ? "" : <div className="purchasebox activitybox activity_box">
                                                <div className="row abtit">
                                                    <div className="col-6 col-sm-6 col-md-8"><h5>Activity</h5></div>
                                                    {(this.checkTotalMintedTokens(detailDropList && detailDropList.totalMintedTokens)) > 1 ? <div className="col-6 col-sm-6 col-md-4"><a className="usernameblue small" id="uploadArt" onClick={this.gotoActivity}>View all Editions</a></div> : ''}
                                                </div>
                                                {this.getArtTransactions()}

                                            </div>}

                                            <div className='mb-3 custagbadge purchased_category_nft'>
                                                {(dropList && dropList.nftCategory && dropList.nftCategory.name) ?
                                                    <span className='category-btn mr-2  align-items-center' onClick={() => this.gotoCategoryPaga(dropList.nftCategory)}><i className={dropList.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {dropList.nftCategory.name && dropList.nftCategory.name.charAt(0).toUpperCase() + dropList.nftCategory.name.slice(1)}</span>
                                                    : ''}

                                            </div>

                                            <div className="langbtnlinks">
                                                <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={dropList && dropList.imageHash && dropList.imageHash}>
                                                    <span className="texticon"> View on IPFS</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                </a>


                                                <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={dropList && dropList.metadataJsonUrl && dropList.metadataJsonUrl}>
                                                    <span className="texticon"> View MetaData</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                </a>

                                                <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={dropList && dropList.flowScanURL && dropList.flowScanURL}>
                                                    <span className="texticon"> View on Flow Scan</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                </a>

                                            </div>

                                            {(this.state.bearAttributes && this.state.bearAttributes["attributes"]) ? <ShowAttributes metaData={this.state.bearAttributes} /> : ''}

                                        </div>
                                        <Badges
                                            badges={this.state.availableBadges}
                                            selectedBadges={this.state.selectedBadges}
                                            artDetail={dropList}
                                            detailList={detailDropList}
                                            updateBadgeSuccess={this.updateBadgeSuccess}
                                            updateBadgeFailed={(val) => this.updateBadgeFailed(val)}
                                            removeBadges={(val) => this.removeBadges(val)}
                                            tabIndex={this.state.tabIndex}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> :
                        (this.state.itsMine && !this.state.showAuction) ? <div>
                            {/* ******************Own detail Page************ */}

                            <div className='dandetails'>
                                <div className='container'>
                                    <div className='dandpc'>
                                        <div className='dandpic'>
                                            <div className='dandpicimg'>
                                                <ArtViewPreview src={myArt && myArt.imageHash && myArt.imageHash} alt="" className="mw-100 h-auto" showControls={true} isDetail={true} isPremiumArt={myArt && myArt.isPremiumArt} mimeType={myArt && myArt.imageMimeType} sharedData={myArt} />

                                            </div>
                                            <div className='dandpiccont'>
                                                <div className="purchasebox list_price_box">
                                                    <div className={this.isPurchased(detailMyArt) ? 'row' : 'row justify-content-center align-items-center'}>

                                                        <div className="col-7 list_price"> <h5>Price</h5></div>
                                                        <div className="col-5 purchase_nft text-center">
                                                        </div>

                                                        <div className="col-md-7 list_price"> {this.showPriceInSelfPage(detailMyArt)}</div>
                                                        {!(this.isPurchased(detailMyArt)) ?
                                                            <div className="col-md-5 purchase_nft text-center">
                                                                {((tokensArray && tokensArray.length > 1)) ?
                                                                    (isConnectWallet() ?
                                                                        (<button
                                                                            type="button"
                                                                            className="btn btn-primary purchasebtn text-center w-100"

                                                                            onClick={this.checkaccountSetup}
                                                                        >
                                                                            Withdraw Listing</button>) :
                                                                        (<button type="button" className="btn btn-primary purchasebtn text-center w-100"
                                                                            onClick={this.handleBloctoWalletLogin} >Withdraw Listing</button>)) :
                                                                    <button type="button" className="btn btn-primary purchasebtn text-center w-100"
                                                                        disabled={this.state.disableWithdraw}
                                                                        onClick={isConnectWallet() ? this.checkaccountSetup :
                                                                            this.handleBloctoWalletLogin} >Withdraw Listing</button>}
                                                            </div> : ''}
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className='dandcont'>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(1)}>
                                                    <button className={(this.state.tabIndex === 1) ? "nav-link active" : 'nav-link'} id="Story-tab" data-toggle="tab" data-target="#Story" type="button" role="tab" aria-controls="Story" aria-selected="true">Story</button>
                                                </li>
                                                <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(2)}>
                                                    <button className={(this.state.tabIndex === 2) ? "nav-link active" : 'nav-link'} id="Details-tab" data-toggle="tab" data-target="#Details" type="button" role="tab" aria-controls="Details" aria-selected="false">Details</button>
                                                </li>
                                                <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(3)}>
                                                    <button className={(this.state.tabIndex === 3) ? "nav-link active" : 'nav-link'} id="Badges-tab" data-toggle="tab" data-target="#Badges" type="button" role="tab" aria-controls="Badges" aria-selected="false">Badges</button>
                                                </li>
                                            </ul>
                                            <div className='dandconticons'>
                                                {this.state.isFavourite ? <a className='active' onClick={this.removeFav}><i className='fa fa-heart' ></i></a> : <a onClick={this.addToFav}><i className='fa fa-heart-o' ></i></a>}
                                                <a onClick={this.toggleMenu}><i className='fa fa-share-alt'></i></a>
                                                {this.moreMenuUI(myArt)}

                                            </div>
                                            {this.state.visible && this.socialShareUI(myArt)}
                                            <div className="tab-content">
                                                <ArtStoryTab artDetail={myArt} tabIndex={this.state.tabIndex} />

                                                <div className={(this.state.tabIndex === 2) ? "tab-pane active" : "tab-pane "} id="Details" role="tabpanel" aria-labelledby="Details-tab">
                                                    {(this.isPurchased(detailMyArt)) ? this.getPurchasedDetail(detailMyArt) : ''}

                                                    <div className="purchasebox">
                                                        <div className="row for_sale_box">
                                                            {!(this.isPurchased(detailMyArt)) ? <div className="col-md-8 for_sale_price">
                                                                <h5 className="mb-3">For Sale</h5>
                                                                {showTotalEditionsAfterOf(detailMyArt, availableDropsCount, myArt)}                                                        </div> : ''}
                                                            <div className="col-md-4 for_sale_listed_by">
                                                                <h5>Listed by {this.getListUser(detailMyArt && detailMyArt.user)}</h5>
                                                                <h6 className="mb-0">{detailMyArt && detailMyArt.updatedAt && moment(detailMyArt.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="purchasebox edition_box">
                                                        <div className="row">
                                                            <div className="col-md-4 edition_box_editions">
                                                                <h5 className="mb-3">Total Editions</h5>
                                                                {showTotalEditionsforDrops(detailMyArt, myArt)}
                                                                {(serialNos && serialNos.length > 0) ? <a className="usernameblue cursor-pointer small" onClick={() => this.viewAllSerialNos()}>View Edition's Index</a> : ''}
                                                            </div>
                                                            <div className="col-md-4 edition_creators">
                                                                <h5 className="mb-2">Creator</h5>
                                                                {(myArt && myArt.isBab) ? showCreatorName() : <div className='mb-3 creatordetalist'>
                                                                    <span className='pr-1'>{this.getPurchasedCount((myArt && myArt.artOwners), (myArt && myArt.creator))}</span>{this.getCreator((myArt && myArt.artOwners), (myArt && myArt.creator))}

                                                                </div>}
                                                            </div>
                                                            {/* If owner is creator only then no need to show */}
                                                            {((myArt && myArt.artOwners && myArt.artOwners.length == 1) &&
                                                                (myArt && myArt.artOwners && myArt.artOwners[0] && myArt.artOwners[0].ownerUserId === (myArt.creator && myArt.creator.id))
                                                            ) ? '' :
                                                                <div className="col-md-4 edition_collectors">
                                                                    <h5 className="mb-2">Collectors</h5>
                                                                    {this.getCollectorsList((myArt && myArt.artOwners), (myArt && myArt.creator))}
                                                                    {((myArt && myArt.artOwners && myArt.artOwners.length > 3)) &&
                                                                        <a className="usernameblue mt-3 small d-inline-block" id="uploadArt" onClick={this.handleViewAllCollectors}>{this.state.showAllCreators ? "Hide" : "View all Collectors"}</a>}
                                                                </div>}
                                                        </div>
                                                    </div>

                                                    {(myArt && myArt.isBab) ? "" : <div className="purchasebox activitybox activity_box">
                                                        <div className="row abtit">
                                                            <div className="col-6 col-sm-6 col-md-8"><h5>Activity</h5></div>
                                                            {(this.checkTotalMintedTokens(detailMyArt && detailMyArt.totalMintedTokens)) > 1 ? <div className="col-6 col-sm-6 col-md-4"><a className="usernameblue small" id="uploadArt" onClick={this.gotoActivity}>View all Editions</a></div> : ''}
                                                        </div>
                                                        {this.getArtTransactions()}

                                                    </div>}

                                                    <div className='mb-3 custagbadge purchased_category_nft'>
                                                        {(myArt && myArt.nftCategory && myArt.nftCategory.name) ?
                                                            <span className='category-btn mr-2  align-items-center' onClick={() => this.gotoCategoryPaga(myArt.nftCategory)}><i className={myArt.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {myArt.nftCategory.name && myArt.nftCategory.name.charAt(0).toUpperCase() + myArt.nftCategory.name.slice(1)}</span>
                                                            : ''}

                                                    </div>

                                                    <div className="langbtnlinks">
                                                        <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={myArt && myArt.imageHash && myArt.imageHash}>
                                                            <span className="texticon"> View on IPFS</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                        </a>


                                                        <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={myArt && myArt.metadataJsonUrl && myArt.metadataJsonUrl}>
                                                            <span className="texticon"> View MetaData</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                        </a>

                                                        <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={myArt && myArt.flowScanURL && myArt.flowScanURL}>
                                                            <span className="texticon"> View on Flow Scan</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                        </a>

                                                    </div>

                                                    {(this.state.bearAttributes && this.state.bearAttributes["attributes"]) ? <ShowAttributes metaData={this.state.bearAttributes} /> : ''}

                                                </div>
                                                <Badges
                                                    badges={this.state.availableBadges}
                                                    selectedBadges={this.state.selectedBadges}
                                                    artDetail={myArt}
                                                    detailList={detailMyArt}
                                                    updateBadgeSuccess={this.updateBadgeSuccess}
                                                    updateBadgeFailed={(val) => this.updateBadgeFailed(val)}
                                                    removeBadges={(val) => this.removeBadges(val)}
                                                    tabIndex={this.state.tabIndex}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :

                            <div>
                                <div className='dandetails'>
                                    <div className='container'>
                                        <div className='dandpc'>
                                            <div className='dandpic'>
                                                {(artList && artList.imageHash && isCelebrateSplash) ? <CelebrationGif /> : ''}

                                                <div className='dandpicimg'>
                                                    <ArtViewPreview src={artList && artList.imageHash} alt="" className="mw-100" showControls={true} isDetail={true} isPremiumArt={artList && artList.isPremiumArt} mimeType={artList && artList.imageMimeType} sharedData={artList} />

                                                </div>
                                                {(this.isClaimAvailable(artList)) ?
                                                    <div className="text-center mb-3">
                                                        <button type="button" className='btn btn-primary' onClick={() => this.handleClaimAirdrop(artList)}>Claim Airdrop</button></div> : ''}


                                            </div>
                                            <div className='dandcont'>
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(1)}>
                                                        <button className={(this.state.tabIndex === 1) ? "nav-link active" : 'nav-link'} id="Story-tab" data-toggle="tab" data-target="#Story" type="button" role="tab" aria-controls="Story" aria-selected="true">Story</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(2)}>
                                                        <button className={(this.state.tabIndex === 2) ? "nav-link active" : 'nav-link'} id="Details-tab" data-toggle="tab" data-target="#Details" type="button" role="tab" aria-controls="Details" aria-selected="false">Details</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation" onClick={() => this.handleTabChange(3)}>
                                                        <button className={(this.state.tabIndex === 3) ? "nav-link active" : 'nav-link'} id="Badges-tab" data-toggle="tab" data-target="#Badges" type="button" role="tab" aria-controls="Badges" aria-selected="false">Badges</button>
                                                    </li>
                                                </ul>
                                                <div className='dandconticons'>
                                                    {this.state.isFavourite ? <a className='active' onClick={this.removeFav}><i className='fa fa-heart' ></i></a> : <a onClick={this.addToFav}><i className='fa fa-heart-o' ></i></a>}
                                                    <a onClick={this.toggleMenu}><i className='fa fa-share-alt'></i></a>
                                                    {this.moreMenuUI(artList)}

                                                </div>
                                                {this.state.visible && this.socialShareUI(artList)}
                                                <div className="tab-content">
                                                    <ArtStoryTab artDetail={artList} tabIndex={this.state.tabIndex} />
                                                    <div className={(this.state.tabIndex === 2) ? "tab-pane active" : "tab-pane "} id="Details" role="tabpanel" aria-labelledby="Details-tab">
                                                        {(ownerShipDetail && ownerShipDetail.owner) ? <div className="purchasebox edition_box">
                                                            <div className="propagecont ccdetailbox mb-0">
                                                                <div className="row">
                                                                    <div className='col-md-12'>
                                                                        <div className='topcolldetabox'>
                                                                            <h5>
                                                                                {ownershipEdition(artList, ownerShipDetail)}
                                                                                {((ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.userName) !== (getAdminUsername())) && <span className="d-flex flex-column profofown">
                                                                                    {(ownerShipDetail && ownerShipDetail.purchaseDetail && ownerShipDetail.purchaseDetail.txnId) &&
                                                                                        <a target="_blank" href={(ownerShipDetail && ownerShipDetail.purchaseDetail && ownerShipDetail.purchaseDetail.txnId ? ((process.env.REACT_APP_FLOW_TRANSACTION) + "/" + (ownerShipDetail.purchaseDetail.txnId)) : '')} className='cursor-pointer'>Proof of Ownership <i className="fas fa-external-link-alt" aria-hidden="true"></i><br /><span className="usernamelilac">{(ownerShipDetail && ownerShipDetail.purchaseDetail && ownerShipDetail.purchaseDetail.tokenId) ? `#${(ownerShipDetail.purchaseDetail.tokenId)}` : ''}</span></a>}
                                                                                    {(ownerShipDetail && ownerShipDetail.purchaseDetail) && (ownerShipDetail && ownerShipDetail.ownedTokens && ownerShipDetail.ownedTokens.length > 1) && <a className="usernameblue cursor-pointer small my-2" onClick={() => this.viewallIds(ownerShipDetail)}>View all token ids</a>}
                                                                                </span>}

                                                                            </h5>


                                                                            <div className='topcolldeta mt-2'>
                                                                                <div className="propicdetail top0 p-0">
                                                                                    <div className="picpro">
                                                                                        <span className="artcovimg">
                                                                                            <span className="artcov "></span>
                                                                                            <span className="artcovimginset no-proimg">
                                                                                                <img src={ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(ownerShipDetail && ownerShipDetail.owner)} />
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='tcdeta'>
                                                                                    <h3>{ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.fullName}</h3>
                                                                                    <a className='usernameblue cursor-pointer mb-2 d-block' onClick={() => this.gotoCreator(ownerShipDetail && ownerShipDetail.owner)}>{ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.userName && `@${ownerShipDetail.owner.userName}`}</a>
                                                                                    {((ownerShipDetail && ownerShipDetail.owner && ownerShipDetail.owner.userName) !== (getAdminUsername())) && <h5>{this.getPurchaseType(ownerShipDetail)}</h5>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : (isOwnershipFailed) ? <div className="purchasebox">{isOwnershipFailed}</div> : ''}
                                                        <div className="purchasebox edition_box">
                                                            <div className="row">
                                                                <div className="col-md-4 edition_box_editions">
                                                                    <h5 className="mb-3">Total Editions</h5>
                                                                    {showTotalEditions(artList)}
                                                                    {(serialNos && serialNos.length > 0) ? <a className="usernameblue cursor-pointer small" onClick={() => this.viewAllSerialNos()}>View Edition's Index</a> : ''}
                                                                </div>
                                                                <div className="col-md-4 edition_creators">
                                                                    <h5 className="mb-2">Creator</h5>
                                                                    {(artList && artList.isBab) ? showCreatorName() : <div className='mb-3 creatordetalist'>
                                                                        <span className='pr-1'>{this.getPurchasedCount((artList && artList.artOwners), (artList && artList.creator))}</span>{this.getCreator((artList && artList.artOwners), (artList && artList.creator))}

                                                                    </div>}

                                                                </div>

                                                                {/* If owner is creator only then no need to show */}
                                                                {((artList && artList.artOwners && artList.artOwners.length == 1) &&
                                                                    (artList && artList.artOwners && artList.artOwners[0] && artList.artOwners[0].ownerUserId === (artList.creator && artList.creator.id))
                                                                ) ? '' :
                                                                    <div className="col-md-4 edition_collectors">
                                                                        <h5 className="mb-2">Collectors</h5>
                                                                        {this.getCollectorsList((artList && artList.artOwners), (artList && artList.creator))}
                                                                        {((artList && artList.artOwners && artList.artOwners.length > 3)) &&
                                                                            <a className="usernameblue mt-3 small d-inline-block" id="uploadArt" onClick={this.handleViewAllCollectors}>{this.state.showAllCreators ? "Hide" : "View all Collectors"}</a>}
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                        {(artList && artList.isBab) ? "" : <div className="purchasebox activitybox activity_box">
                                                            <div className="row abtit">
                                                                <div className="col-6 col-sm-6 col-md-8"><h5>Activity</h5></div>
                                                                {(artList && artList.toatalMintedTokens) && (this.checkTotalMintedTokens(artList && artList.toatalMintedTokens)) > 1 ? <div className="col-6 col-sm-6 col-md-4"><a className="usernameblue small" id="uploadArt" onClick={this.gotoActivity}>View all Editions</a></div> : ''}
                                                            </div>
                                                            {this.getArtTransactions()}

                                                        </div>}


                                                        <div className='mb-3 custagbadge purchased_category_nft'>
                                                            {(artList && artList.nftCategory && artList.nftCategory.name) ?
                                                                <span className='category-btn mr-2  align-items-center' onClick={() => this.gotoCategoryPaga(artList.nftCategory)}><i className={artList.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {artList.nftCategory.name && artList.nftCategory.name.charAt(0).toUpperCase() + artList.nftCategory.name.slice(1)}</span>
                                                                : ''}

                                                        </div>

                                                        <div className="langbtnlinks">
                                                            <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={artList && artList.imageHash && artList.imageHash}>
                                                                <span className="texticon"> View on IPFS</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                            </a>


                                                            <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={artList && artList.metadataJsonUrl && artList.metadataJsonUrl}>
                                                                <span className="texticon"> View MetaData</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                            </a>

                                                            <a target="blank" className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center" href={artList && artList.flowScanURL && artList.flowScanURL}>
                                                                <span className="texticon"> View on Flow Scan</span><i className="fas fa-external-link-alt" aria-hidden="true"></i>
                                                            </a>

                                                        </div>

                                                        {(this.state.bearAttributes && this.state.bearAttributes["attributes"]) ? <ShowAttributes metaData={this.state.bearAttributes} /> : ''}

                                                    </div>
                                                    <Badges
                                                        badges={this.state.availableBadges}
                                                        selectedBadges={this.state.selectedBadges}
                                                        artDetail={artList}
                                                        updateBadgeSuccess={this.updateBadgeSuccess}
                                                        updateBadgeFailed={(val) => this.updateBadgeFailed(val)}
                                                        removeBadges={(val) => this.removeBadges(val)}
                                                        tabIndex={this.state.tabIndex}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}


                    {/* *****************More arts******************* */}
                    {(relativeArts && relativeArts.length > 0) && (this.isHiddenArt(artList, dropList, myAuctionList, myArt, bidList)) && <div className="morefromarts container">
                        <div className="card1 mb-5 morelist">
                            <div className="card-body1">

                                {this.state.isDrop && !this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " id="uploadArt" onClick={() => { this.gotoCreator(detailDropList && detailDropList.user) }}>{detailDropList && detailDropList.user && detailDropList.user.userName ? `More from @${detailDropList.user.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && !this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " id="uploadArt" onClick={() => { this.gotoCreator(artList && artList.owner) }}>{artList && artList.owner && artList.owner.userName ? `More from @${artList.owner.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " id="uploadArt" onClick={() => { this.gotoCreator(detailMyArt && detailMyArt.user) }} >{detailMyArt && detailMyArt.user && detailMyArt.user.userName ? `More from @${detailMyArt.user.userName}` : "More Arts"}</h4>}

                                <div className="row">
                                    {relativeArts && relativeArts.map(items => (
                                        <div className="col-6 col-md-3" key={items && items.id} onClick={() => this.clickRelative(items)}>
                                            <div className="cardlist ">

                                                <ArtPreview src={items && items.thumbnailHash} className="comimg mb-0" isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                <span className="cont">
                                                    <h3>{items && items.title ? items.title : "Title"}</h3>

                                                    <span className="usernamelilac " title={items && items.drops && items.drops[0] && items.drops[0].markedPrice && flowDecimal(parseFloat(items.drops[0].markedPrice))}>{items && items.drops && items.drops[0] && items.drops[0].markedPrice ? (flowDecimal(parseFloat(items.drops[0].markedPrice)) + (this.isDapperArt(items) ? ' USD' : ' FLOW')) : (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ?
                                                        (<div>

                                                            {!this.checkLastDate(items) ?
                                                                (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                                    <p className="mb-0"><span className="usernamelilac" title={items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid}>{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' FLOW') :
                                                                        <span className="usernamelilac ">
                                                                            {items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.startPrice && `Starting Price : ${(flowDecimal(parseFloat(items.drops[0].auction.startPrice)) + ' FLOW')}`)}
                                                                        </span>)}
                                                                    </span>
                                                                    </p>) : (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                                        <p className="mb-0"><span className="usernamelilac" title={items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid}>{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' FLOW') : '')}</span>
                                                                        </p>)
                                                            }
                                                        </div>) :
                                                        "Not for sale"} </span>

                                                    {(items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1"><span className="small usernamelilac">{checkTokenGroup(items.groups, (items.toatalMintedTokens))}</span> of {(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {this.checkEDitionCount(items)}</p> : <p className="mb-0 small mt-1">1 of <span className="small usernamelilac">1</span> Total Edition</p>}

                                                    {this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime((items && items.drops && items.drops[0]), true)}

                                                </span>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

                    :
                    (!(isInvalidPage) && (isArtDisabled)) ?
                        <div className="container-fluid text-center">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="error-template userdisableview">
                                        <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                        <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                                        {(disabledMessage && disabledMessage.includes('admin')) ? <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4> : ''}

                                        {(disabledMessage && disabledMessage.includes('admin')) ? <p className="mb-0">The Team</p> : ''}
                                        {(disabledMessage && disabledMessage.includes('admin')) ? <p className="usernamelilac">DisruptArt</p> : ''}


                                    </div>
                                </div>

                            </div>
                        </div> : <InvalidPage />}
            </Fragment >
        )
    }
}
const mapStateToProps = (state) => {
    return {

        detailList: state.art.detailArt,
        dropList: state.drop.detailDrop,
        usd: state.user.usd,
        relativeArts: state.art.relativeArts,
        wallet: state.wallet.wallet,
        artVisibilityStatusChangedFailed: state.art.artStatusChangedFailed,
        artVisibilityStatusChangesSuccess: state.art.artStatusChangedSuccess,
        reportSent: state.reportReducer.addedReport,
        reportFailed: state.reportReducer.addReportFailed,
        followersList: state.follow.followers,
        getFollowerFailed: state.follow.getfollowersFailed,
        userFollower: state.follow.userFollow,
        failedFollow: state.follow.failedFollow,
        addedToFav: state.user.addedFav,
        addToFavFailed: state.user.addFavFailed,
        removeFav: state.user.removeFav,
        removeFavFailed: state.user.removeFavFailed,
        favby: state.user.favrtdUser,
        favbyFailed: state.user.favrtdUserFailes,
        withdrawnArtSuccess: state.drop.withdrawnArt,
        withdrawnArtFailed: state.drop.withdrawArtFailed,
        walletLinkError: state.wallet.walletlinkErr
    }
}
const actionCreators = {
    detailArt: artActions.detailArt,
    getDetailDropArt: dropActions.getDetailDropArt,
    getRelativeArts: artActions.getRelativeArts,
    getDetailCreator: creatorActions.getDetailCreator,
    getUSD: userAction.getUSD,
    linkWallet: walletActions.linkWallet,
    resetRelativeArts: artActions.resetRelativeArts,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetWalletLinkErr: walletActions.resetWalletLinkError,
    getUserProfile: userAction.getUsers,
    updatevisibilityStatus: artActions.artsVisibilitystatusChange,
    resetVisibityStatusSuccess: artActions.resetArtvisibilitySuccess,
    resetVisibilityStatusFailed: artActions.resetArtVisibilityFailed,
    sendReport: reportActions.addReport,
    resetSendReport: reportActions.resetAddReportSuccess,
    resetSendReportFailed: reportActions.resetAddReportFailed,
    getFollowers: followActions.getFollowers,
    resetGetFollowers: followActions.resetFollowers,
    resetGetFollowersFailed: followActions.resetGetFollowersFailed,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    resetFailedFollow: followActions.resetFailedFollow,
    addToFav: userAction.addFavourites,
    resetAddFav: userAction.resetAddFavourites,
    resetAddFavFailed: userAction.resetAddFavouritesFailed,
    removeFromFav: userAction.removeFavourites,
    resetRemoveFav: userAction.resetRemoveFavourites,
    resetRemoveFavFailed: userAction.resetRemoveFavouritesFailed,
    getFav: userAction.getFavouritedUser,
    resetGetFav: userAction.resetGetFavouritedUser,
    resetGetFavFailed: userAction.resetGetFavouritedUserFailed,
    disconnect: flowActions.disconnectBloctoWallet,
    withdrawDapperArt: dropActions.withdrawArt,
    resetWithdrawDapperArt: dropActions.resetWithdrawArtSuccess,
    resetWithdrawDapperArtFailed: dropActions.resetWithdrawArtFailed

}

const connectArtDetailPage = connect(mapStateToProps, actionCreators)(ArtDetailPage)
export { connectArtDetailPage as ArtDetailPage }
