import { flowConstants } from "../../constants"


export const flowActions = {
   getFlowDetail,
   disconnectBloctoWallet,
   resetDisconnectBloctoWallet
}

function getFlowDetail(obj){
    return dispatch => {
          
        dispatch({type:flowConstants.GET_FLOW_DETAILS})
    }
       
    
}
function disconnectBloctoWallet(){
    console.log("Disconnected")
    return dispatch => {
        dispatch({type : flowConstants.DISCONNECT_BLOCTO_WALLET,bloctoDisconnected : true})
    }
}
function resetDisconnectBloctoWallet(){
    return dispatch => {
        dispatch({type : flowConstants.DISCONNECT_BLOCTO_WALLET,bloctoDisconnected : false})
    }
}





