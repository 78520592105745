import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";

export async function flowMoveToRecipientAccount(tokenIds,address) {
    if (Array.isArray(tokenIds)) {
        if(address){
            return await moveTokensToRecipient(tokenIds, address);
        }else{
            return await moveTokensToRecipient(tokenIds, process.env.REACT_APP_AIR_DROP_ACCOUNT);
        }

    } else {
        return Promise.reject({
            status: 400,
            data: { "message": "Exception happens", "Error": "Token Ids should be an array of numbers" }
        });
    }
}

async function moveTokensToRecipient(tokenIds, toAddress) {
    const script = `
        import ${process.env.REACT_APP_CONTRACT} from ${process.env.REACT_APP_CONTRACT_ADDRESS}

        transaction(recpAccount: Address, tokenIds: [UInt64]) {
        
            prepare(account: AuthAccount) {
                var count = 0
                let acct = getAccount(recpAccount)
                let recipientRef = acct.getCapability(DisruptArt.disruptArtPublicPath)
                .borrow<&{DisruptArt.DisruptArtCollectionPublic}>()
                ?? panic("Could not borrow capability from public collection")
                let tokens = tokenIds as [UInt64]
                while count < tokens.length {
                    let sellerRef = account.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath)!
                    let tokenId  = tokens[count] 
                    let token <- sellerRef.withdraw(withdrawID: tokenId)
                    count = count + 1 
                    recipientRef.deposit(token: <-token)
                }
        
            }
        }
    `;
    try {
        const blockResponse = await fcl.send([
            fcl.getBlock(),
        ])
        const block = blockResponse.block;
        const blockHeight = block && block.height;
        // console.log('%c' + JSON.stringify(blockResponse, null, 1), 'font-size:12px')
        // console.log('%c' + JSON.stringify(toAddress, null, 1), 'font-size:12px')
        const res = await fcl.send([
            fcl.transaction(script),
            fcl.args([
                fcl.arg(toAddress, t.Address),
                fcl.arg(tokenIds, t.Array(t.UInt64))
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ])
        const { transactionId } = res;
        const result = new Promise((resolve, reject) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        const {errorMessage} = transaction;
                        if(errorMessage && errorMessage !== ''){
                            reject({
                                status: 400,
                                data:{
                                    "message": "Transaction failed",
                                    "Error": String(errorMessage)
                                }
                            })
                        }
                        resolve({
                            status: 200,
                            data: {
                                "message": "Flow Token Transfer Raised",
                                "transactionId": transactionId,
                                "blockHeight": blockHeight,
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    }
    catch (e) {
        console.error('Failed to move tokens',e);
        return {
            status: 400,
            data: { "message": "Exception happens", "Error": String(e) }
        };
    }
}
