import { tagConstants } from "../../constants"
import { adminServices, tagServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const tagActions = {
    getTagsData,
    resetGetTags,
    addTagsData,
    resetAddedTags,
    getAllTrendingTags,
    resetTrendingTags
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getTagsData(obj){
    return dispatch => {
        showLoader()
        adminServices.getTagsData(obj)
        .then(tags => {
            if(tags){
                dispatch({type:tagConstants.GET_TAGS,tagsList:tags})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetTags(){
    return dispatch => {
        dispatch({type:tagConstants.GET_TAGS,tagsList:[]})
    }
}
function addTagsData(obj){
    return dispatch => {
        showLoader()
        adminServices.addTagsData(obj)
        .then(tags => {
            if(tags){
                toast.success("Tags Added")
                dispatch({type:tagConstants.ADD_TAGS,tagAdded:tags})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAddedTags(){
    return dispatch => {
        dispatch({type:tagConstants.ADD_TAGS,tagAdded:[]})
    }
}
function getAllTrendingTags(){
    return dispatch => {
        tagServices.getAllTrendingTags()
        .then(tTag => {
            if((tTag && tTag.statusCode) === 200){
                dispatch({ type: tagConstants.GET_ALL_TRENDING_TAGS,trendingTags : tTag})
            }
        },error => {
        })
    }
}
function resetTrendingTags(){
    return dispatch => {
        dispatch({type :tagConstants.GET_ALL_TRENDING_TAGS,trendingTags : []})
    }
}


