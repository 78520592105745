// import logo from '../public/assets/images/logoaw.png';
import React from 'react';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/da-landing.css';
import { AuthCluster } from "./auth-cluster"


export class App extends React.Component {
  render() {
    return (
      <div className="App">
        <AuthCluster />
      </div>
    );
  }
}

export default App;
