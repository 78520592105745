import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { tagServices } from 'services';
import { artActions } from 'store/actions';
var dataURL = {}

class EditCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            categories: [],
            category: 0,
            detailData : {}
        }
    }
    componentDidMount() {
        this.getAllcategories()
    }
    getAllcategories = () => {
        tagServices.getAllCategories().then(res => {
            if ((res && res.statusCode) === 200) {
                let arr = [{ label: 'Select category of your NFT', value: 0 }]

                res && res.data && res.data.tags && res.data.tags.length > 0 && res.data.tags.map((item) => {
                    let obj = { label: (item && item.name && (item && item.name.charAt(0).toUpperCase() + item.name.slice(1))), value: item && item.id }
                    arr.push(obj)
                })
                this.setState({
                    categories: arr
                })
            }

        }).catch(err => {
            this.setState({
                categories: [{}]
            })
        })
    }


    handleCategory = (e) => {
        let category = ''
        this.state.categories && this.state.categories.filter((items) => {
            if ((items && items.value) == (e.target.value)) {
                category = items.label
            }
        })
        this.setState({
            category: e.target.value, categoryName: category.toLowerCase()
        })
    }
    componentDidUpdate(props, state) {
        if ((props && props.data)) {
            if ((props.data) != (state.data)) {
                this.setState({ data: props.data })
                if (props.data.nftCategory && props.data.nftCategory.id) {
                    this.setState({ category: props.data.nftCategory.id })
                }
            }
        }
        if ((props && props.detailData)) {
            if ((props.detailData) != (state.detailData)) {
                this.setState({ detailData: props.detailData })
            }
        }
        if (((this.props && this.props.editsuccess && this.props.editsuccess.statusCode) === 200) && (this.props.editsuccess) != (props.editsuccess)) {
            this.props.resetUpdateCategory()
            // this.setState({
            //     category : 0 
            // })
            this.props.updateCategorySuccess()
        } else if (((this.props && this.props.editsuccess && this.props.editsuccess.statusCode) === 400) && (this.props.editsuccess) != (props.editsuccess)) {
            this.props.resetUpdateCategory()
            this.props.updateError(this.props.editsuccess && this.props.editsuccess.message)
        }
        if (((this.props && this.props.editfailed && this.props.editfailed.length > 0) && (this.props.editfailed) != (props.editfailed))) {
            this.props.resetUpdateCategoryFailed()
            this.props.updateError(this.props.editfailed)

        }
    }
    editCategory = (e, data) => {
        e.preventDefault()
        if (this.state.category && Number(this.state.category)) {
            if (data && data.id) {
                let obj = {
                    categoryId: Number(this.state.category)
                }
                if(this.state.detailData && this.state.detailData.art && this.state.detailData.art.id){
                    this.props.updateCategory((this.state.detailData.art.id), JSON.stringify(obj))

                }else if(data.id){
                    this.props.updateCategory((data.id), JSON.stringify(obj))

                }else{
                    this.props.updateError("Something went wrong,art id was missing")
                }
            } else {
                this.props.updateError("Something went wrong")
            }
        } else {
            this.props.updateError("Please select category")
        }
    }
    render() {
        const { data } = this.state
        return (
            <Fragment>
                <div className="form-group col-md-12">
                <label className="col-form-label " htmlFor="category">Select Category</label>
                    <select className='form-control' value={this.state.category} onChange={this.handleCategory}>
                        {this.state.categories && this.state.categories.map((items) => (
                            <option value={items.value}>{items && items.label}</option>
                        ))}
                    </select>

                </div>
                {(data && data.isBab) ? '' : <div className="row">
                    <div className="col-md-12 text-center">
                        <button type="button" className="btn btn-primary px-3" onClick={(e) => this.editCategory(e, data)} disabled={this.state.disableList}>Update Category</button>
                    </div>
                </div>}
                <hr className="my-3 d-block" />



            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        editsuccess: state.art.editedCategoryStatus,
        editfailed: state.art.editcategoryFailed
    }
}
const actionCreators = {
    updateCategory: artActions.editArtCategory,
    resetUpdateCategory: artActions.resetArtEditcategory,
    resetUpdateCategoryFailed: artActions.resetArtEditCategoryFailed

}
const connectEditCategory = connect(mapStateToProps, actionCreators)(EditCategory)
export { connectEditCategory as EditCategory }
