import { configPath } from "../config";
import { accessHeader } from "helpers"
import { fetchService } from "./fetch-service";
import PubSub from 'pubsub-js'; 

export const storeServices = {
    addstoreCreatorProfile,
    getStoreCreatorProfile,
    getUsersArts,
    storeListArtForSale,
    createStoreSection,
    getStoreSection,
    getSectionArts,
    addSectionArts,
    deleteSectionArts,
    getSearchedDrop,
    editSectionName,
    storeGetUserDrop,
    storeGetArtDetail,
    storeWithdrawArts,
    storeAddBannerdata,
    storeGetBannerdata,
    storeDeleteBannerData,
    storeGetSocialDefinition,
    storeGetSaleHistory,
    getSectionDetail,
    getBackgroundImage,
    deleteSocialMediaMapLink,
    storeupdateFeatureddropsArts,
    removeFromStarredCollection,
    getSectionGalleryItem,
    updateSectionGalleryItem,
    deleteSectionGalleryItem,
    getSectionTimelineItem,
    updateSectionTimelineItem,
    deleteSectionTimelineItem,
    getSectionMetaTags,
    updateSectionMetaTags,
    storeGetPurchaseData,
}

function addstoreCreatorProfile(obj){
    const requestOptions = {
        method : 'PUT',
        headers :  {...accessHeader(),'accept' : 'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store` ,requestOptions).then(handleResponse,handleError)

}
function getStoreCreatorProfile(uuid){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/${uuid}` ,requestOptions).then(handleResponse,handleError)

}
function getUsersArts(obj){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''
	let showHiddenArts = obj && obj.showHiddenArts ? "&showHiddenArts=" + obj.showHiddenArts : ''

    params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/arts?` + params ,requestOptions).then(handleResponse,handleError)

}
function storeListArtForSale(obj){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/drop` ,requestOptions).then(handleListResponse,handleError)
}
function createStoreSection(obj,storeId){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section` ,requestOptions).then(handleResponse,handleError)

}
function getStoreSection(storeId,obj){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    let params = ''

	let category = obj && obj.category ? 'category=' + obj.category : ''

    params = category
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/${storeId}/section?` + params ,requestOptions).then(handleResponse,handleError)

}
function addSectionArts(obj,secId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
       
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${secId}/drop`, requestOptions).then(handleResponse,handleError);
}

function getSectionArts(secId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${secId}/drop`, requestOptions).then(handleResponse,handleError);
}
function deleteSectionArts(obj,secId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${secId}/drop`, requestOptions).then(handleResponse,handleError);
}
function getSearchedDrop(title) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    let params = `activeDropsOnly=true`
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/arts/title/${title}?`+ params, requestOptions).then(handleResponse,handleError);
}
function editSectionName(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section`, requestOptions).then(handleResponse,handleError);
}
function storeGetUserDrop(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''
	let showHiddenArts = obj && obj.showHiddenArts ? '&showHiddenArts=' + obj.showHiddenArts : ''



	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts

	return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/drop` + params, requestOptions).then(handleResponse, handleError)
}
function storeGetArtDetail(obj){
	const requestOptions = {
		method : "GET",
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},

	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/token/` + params,requestOptions).then(handleResponse,handleError)

}
function storeWithdrawArts(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section`, requestOptions).then(handleResponse,handleError);
}
function storeAddBannerdata(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section`, requestOptions).then(handleResponse,handleError);
}
function storeGetBannerdata(obj){
	const requestOptions = {
		method : "GET",
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},

	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/token/` + params,requestOptions).then(handleResponse,handleError)

}
function storeDeleteBannerData(obj,secId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${secId}/drop`, requestOptions).then(handleResponse,handleError);
}
function storeGetSocialDefinition(obj){
	const requestOptions = {
		method : "GET",
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/social-media`,requestOptions).then(handleResponse,handleError)

}
function storeGetSaleHistory(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''
	let showHiddenArts = obj && obj.showHiddenArts ? '&showHiddenArts=' + obj.showHiddenArts : ''



	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts

	return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/sale` + params, requestOptions).then(handleResponse, handleError)
}
function getSectionDetail(id){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}` ,requestOptions).then(handleResponse,handleError)

}
function getBackgroundImage(id){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/background` ,requestOptions).then(handleResponse,handleError)

}
function deleteSocialMediaMapLink(obj){
    const requestOptions = {
        method : 'DELETE',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/social` ,requestOptions).then(handleResponse,handleError)

}
function storeupdateFeatureddropsArts(dropId){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/star/${dropId}` ,requestOptions).then(handleResponse,handleError)

}
function removeFromStarredCollection(dropId){
    const requestOptions = {
        method : 'DELETE',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/star/${dropId}` ,requestOptions).then(handleResponse,handleError)
}
function getSectionGalleryItem(id){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/gallery` ,requestOptions).then(handleResponse,handleError)
}
function updateSectionGalleryItem(id,obj){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/gallery` ,requestOptions).then(handleResponse,handleError)
}
function deleteSectionGalleryItem(id,itemId){
    const requestOptions = {
        method : 'DELETE',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/gallery/${itemId}` ,requestOptions).then(handleResponse,handleError)
}
function getSectionTimelineItem(id){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/timeline` ,requestOptions).then(handleResponse,handleError)
}
function updateSectionTimelineItem(id,obj){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/timeline` ,requestOptions).then(handleResponse,handleError)
}
function deleteSectionTimelineItem(id,itemId){
    const requestOptions = {
        method : 'DELETE',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/section/${id}/timeline/${itemId}` ,requestOptions).then(handleResponse,handleError)
}
function getSectionMetaTags(){
    const requestOptions = {
        method : 'GET',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/metadata` ,requestOptions).then(handleResponse,handleError)
}
function updateSectionMetaTags(obj){
    const requestOptions = {
        method : 'PUT',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/metadata` ,requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)

            Promise.reject("Failed to fetch")
        }else{
            PubSub.publish('msg', false)

            Promise.reject(err)

        }
    }
    catch(err) {
        PubSub.publish('msg', false)

        Promise.reject(err)
  
    }
}
function handleListResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                if(data && data.data && data.data.data.transaction){
                    return Promise.reject({txnId : data.data.data.transaction})
                }else{
                    const error = (data && data.message)
                    return Promise.reject(error)
                }
            }
		}
		return data;
	});
}

function storeGetPurchaseData(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''



	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder

	return fetchService.fetchResource(`${configPath.apiUrl}/user-store-management/user-store/user/self/purchase` + params, requestOptions).then(handleResponse, handleError)
}