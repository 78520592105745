import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom';
import { userServices } from 'services';
import history from 'helpers/history';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';

class FetchUploadedUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            usersList: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: ''
        }
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.uploadedUsers) {
            return {
                usersList: props.uploadedUsers && props.uploadedUsers.data && props.uploadedUsers.data.files ? props.uploadedUsers.data.files : [],
                totalCount: props.uploadedUsers && props.uploadedUsers.data && props.uploadedUsers.data.count,
                pageCount: props.uploadedUsers && props.uploadedUsers.data && props.uploadedUsers.data.count / state.recordLimit
            }
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleRefresh = () => {
        this.setState({ sortBy: "createdAt", search: '', sortOrder: "DESC", offSet: 0 }, () => {
            this.callApi()
        })
    }
    handleRemarks = (items, e) => {
        // console.log(items)

        this.setState({ remarks: e.target.value }, () => {

        })
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        }, () => { this.callApi() })
    }
    handleSortBy = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }
    checkRemarks = (value) => {
        if ((value && (value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }

    callApi = () => {
        const { search } = this.state

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            // offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (search) {
            obj['search'] = search

        } else {
            obj['offSet'] = this.state.offSet
        }
        this.props.getUploadUsers(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet }, () => this.callApi())

    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {
            this.setState({ isShow: false, })
        } else {
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }
    handleOTP = (email) => {
        if(email){
            this.props.sendOTP(email)
        }else{
            toast.error("Email not exist")
        }
    }
    gotoBulk = () => {
        history.push("/admin/uploadBulk")
    }
    render() {
        const usersList = this.state.usersList && this.state.usersList.map((items, index) => {
            return <tbody>
                <tr>
                    <td>{items && items.id}</td>
                    <td>{moment(items && items.createdAt).format('DD-MM-YYYY - HH:MM')}</td>
                    <td>{items && items.fileName}</td>
                    <td><a className="designLink" onClick={() => { this.handleView(items) }}>{items && items.insertUserCount}</a></td>
                    <td><a className="designLink" onClick={() => { this.handleView(items) }}>{items && items.notInsertUserCount}</a></td>
                    <td>{items && items.totalUser}</td>

                </tr>
                
                {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                    <td colSpan="9" className="px-3 graybgbox">
                        <h4 className="mb-0">Uploaded Details</h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label>File Name </label>
                                <div className="comboxgray">
                                    {items && items.fileName}
                                </div>
                            </div>
                               
                              
                           
                            

                            {items && items.insertUser && items.insertUser.length > 0 &&
                                <div className="col-md-9 mb-3">
                                    <label>Inserted User</label>
                                    <div className="comboxgray">{items.insertUser.map(data => (<span className="badge bg-dark mr-1 p-2">{data}</span>))}
                                    </div>
                                </div>}
                                {items && items.notInsertUser && items.notInsertUser.length > 0 &&
                                <div className="col-md-9 mb-3">
                                    <label>Not Inserted User</label>
                                    <div className="comboxgray">{items.notInsertUser.map(data => (<span className="badge bg-dark mr-1 p-2">{data}</span>))}
                                    </div>
                                </div>}


                           
                            <div className="col-sm-12">
                                <hr />
                            </div>

                           

         
                        </div>




                    </td>
                </tr>
                }
                               
            </tbody>

        })


        return (

            <Fragment>
                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Uploaded Users Applications <span className="betaLink">(Beta)</span></h2>
                        <div className="form-group text-right col-sm-6">
                        <button type="button" onClick={this.gotoBulk} className="btn btn-primary btn-sm px-3">Import Bulk Users</button>
                    </div>
                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                                <div className="row">


                                    <div className="col-6">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-6 text-right">
                                        <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>UPLOADED DATE-TIME</th>
                                        <th>FILE NAME</th>
                                        <th>INSERTED USERS</th>
                                        <th>NOT INSERTED USERS</th>
                                        <th>TOTAL USERS</th>
                                    
                                    </tr>
                                </thead>

                                {usersList}

                            </table>
                            {this.state.usersList && !this.state.usersList.length > 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.usersList && this.state.usersList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        uploadedUsers: state.user.uploadedUsers,

    }
}

const actionCreators = {
    getUploadUsers : userAction.getUploadUsers
}

const connectUploadUser = connect(mapStateToProps, actionCreators)(FetchUploadedUsers)

export { connectUploadUser as FetchUploadedUsers }