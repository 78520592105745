import React from "react";
import { creatorStoreActions, userAction } from "store/actions";
import { connect } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';

var timeout;

const allTypes = [
    { value: 1, label: "About Us" },
    { value: 2, label: 'Terms and Conditions' },
    { value: 3, label: 'Privacy Policy' }
]
const customStylesforAll = {
    control: (base, state) => ({
        ...base,
        minWidth: "100px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }

    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "#fff",
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#eee" : null,
            color: "#000",
            cursor: "pointer",

        };
    }
}
class AddConditions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editText: '',
            theme: 'snow',
            allFiltSelectOption: { value: 1, label: "About Us" },

            modules: {
                toolbar: [
                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' },
                    { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image', 'video'],
                    ['clean'],
                ],
                clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                }
            },
            formats: [
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
                'list', 'bullet', 'indent',
                'link', 'image', 'video',
            ]
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(html) {
        this.setState({ editText: html }, () => {
            console.log(this.state.editText)
        });
    }

    handleThemeChange(newTheme) {
        if (newTheme === "core") newTheme = null;
        this.setState({ theme: newTheme })
    }
    handleSelectChange = (option) => {
        this.setState({
            allFiltSelectOption: option,
        })
    }
    render() {
        return (

            <div className="container-fluid">
                <div className="ds-intit">
                    <h1 className="mb-0">Create your condition</h1>
                </div>
                <div className="propagecont mb-5">
                    <div className="container1">
                        <form>

                            <div className="mt-0 pt-md-3 row">

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-4">

                                            <div className="ds-inputs">
                                                <Select
                                                    value={this.state.allFiltSelectOption}
                                                    onChange={this.handleSelectChange}
                                                    options={allTypes}
                                                    styles={customStylesforAll}
                                                    menuPortalTarget={document.body}
                                                    isSearchable={false}
                                                />
                                                <div className="input-floating-label"><span>Select your theme</span></div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4 col-md-12">

                                            <div className="ds-inputs">
                                                <ReactQuill
                                                    theme={this.state.theme}
                                                    onChange={this.handleChange}
                                                    value={this.state.editText}
                                                    modules={this.state.modules}
                                                    formats={this.state.formats}
                                                    bounds={'.app'}
                                                    placeholder={'Write your conditions'}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="themeSwitcher">
            <label>Theme </label>
            <select onChange={(e) => 
                this.handleThemeChange(e.target.value)}>
              <option value="snow">Snow</option>
              <option value="bubble">Bubble</option>
              <option value="core">Core</option>
            </select>
          </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}






const mapStateToProps = (state) => {
    return {
        bannerData: state.creatorStore.getBannerData,
        bannerDataFailed: state.creatorStore.getBannerDataFailed,
        bannerAddedSuccess: state.creatorStore.bannedadded,
        bannerAddedFailed: state.creatorStore.bannerAddedFailed,
        bannerDeleted: state.creatorStore.deletedBanner,
        bannerDeleteFailed: state.creatorStore.deletedBannerFailed,

    }
}

const actionCreators = {
    getBannerData: creatorStoreActions.storeGetBannerdata,
    resetGetBannerData: creatorStoreActions.resetGetBannerSuccess,
    resetGetBannerDataFailed: creatorStoreActions.resetGetBannerFailed,
    addBanner: creatorStoreActions.storeAddBannerdata,
    resetAddBannerData: creatorStoreActions.resetAddBannerSuccess,
    resetAddBannerDataFailed: creatorStoreActions.resetAddBannerFailed,
    deleteBanner: creatorStoreActions.storeDeleteBannerData,
    resetDeleteBannerSuccess: creatorStoreActions.resetDeleteBannerSuccess,
    resetDeleteBannerFailes: creatorStoreActions.resetDeleteBannerFailed
}

const connectAddConditions = connect(mapStateToProps, actionCreators)(AddConditions)

export { connectAddConditions as AddConditions }