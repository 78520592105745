// File: ./src/config.js

import {config} from "@onflow/fcl"

let BASE_PATH = "https://api.disrupt.art";
let API_PATH = `${BASE_PATH}/api/v1`;

export const configPath = {
  baseUrl:BASE_PATH,
  apiUrl:API_PATH,
  basename : '/nft',
//  uploadPath : "https://ipfs.perma.store/access_token",
  uploadPath : "https://api.pinata.cloud/pinning/pinFileToIPFS",
  imageAssets: ((process.env.REACT_APP_PUBLIC_ASSET_PATH && process.env.REACT_APP_PUBLIC_ASSET_PATH.toLowerCase()!=='null')?`../${process.env.REACT_APP_PUBLIC_ASSET_PATH}/assets/images`:"assets/images"),
  // imageAssets: `${process.env.PUBLIC_URL}/assets/images`,
  // siteKey : '6LfvYGscAAAAAKSWCEgsY-WjDo47QereTxcwgQTP'
  siteKey : '6Lcc9hsiAAAAABn_cVC5tsonkzO-GzkTv6T1CXal'
}

console.log("config starts");
config()
  .put("accessNode.api", process.env.REACT_APP_ACCESS_NODE) // Configure FCL's Access Node
  // .put("challenge.handshake", process.env.REACT_APP_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  .put("discovery.wallet", process.env.REACT_APP_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  .put("flow.network", "mainnet")
  .put("app.detail.icon", "https://disrupt.art/logo512.png")
  .put("app.detail.title", "DisruptArt")
  .put("discovery.wallet.method", "HTTP/POST")
console.log("config ends")
