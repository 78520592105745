import { configPath } from "../config"
import { accessHeader } from "helpers"
import { fetchService } from "services"
import PubSub from 'pubsub-js'; 

export const globalSearchSerivce = {
    getGlobalSearchResults: getGlobalSearchResults
}

function getGlobalSearchResults(searchTerm,signal) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'accept': 'application/json', 'Content-Type': 'application/json' },
        signal
    }

    return fetchService.fetchResource(`${configPath.apiUrl}/search/global?searchTerm=${encodeURIComponent(searchTerm)}`, requestOptions).then(handleResponse,handleError)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const error = data && data.message
			return Promise.reject(error)
		}
		return data;
	});
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            throw err
        }else{
            PubSub.publish('msg', false)

        }
    }
    catch(err) {
    console.log(err)
  
    }
}