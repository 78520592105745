import React from "react";
import { Redirect, Route, useLocation } from "react-router";
import { AppLayout } from "./app-layout";


export function AppLayoutRoute({
    component: Component,
    isLoggedIn,
    ...rest
}) {
    const location = useLocation();
    // if (isLoggedIn) {
        return (
            <Route {...rest} render={
                (matchProps) => {
                    return (
                        <AppLayout>
                            <Component {...matchProps} />
                        </AppLayout>
                    );
                }
            } />
        );
    // }
    return (

        // We add a query parameter "redirect" to remember the user's 
        // last visited page before they were redirected to login.
        // So they can be redirected back to the page the user intended
        // to visit.
        <Redirect to={`/auth?redirect=${encodeURIComponent(location.pathname)}`} />
    );
}


