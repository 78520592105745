import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { creatorStoreActions, userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination, toastr } from 'helpers';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminServices, followServices, userServices } from 'services';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import Modal from 'react-modal'
import { debounce } from 'utils';
import { showTime } from 'helpers/timezone';
import { configPath } from '../../../config';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
class FetchUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            creatorsList: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 200,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: '',
            openModal: false,
            selectedRole: "Select Role",
            isFetched: false,
            isAgree: false,
            wallet: [],
            range: "Select range",
            followCount: "0",
            followers: [],
            btnText: "Export All Users",
            userDetailInfo : {}
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        // this.callApi()
        this.list()

    }

    static getDerivedStateFromProps(props, state) {
        if (props && props.allUsers && props.allUsers.data) {
            // if(props.allUsers)
            if (state.isAgree) {
                return {
                    creatorsList: props.allUsers && props.allUsers.data && props.allUsers.data.entity ? props.allUsers.data.entity : [],
                    totalCount: props.allUsers && props.allUsers.data && props.allUsers.data.count,
                    pageCount: props.allUsers && props.allUsers.data && props.allUsers.data.count / state.recordLimit,
                    isFetched: true,
                    recordLimit: props.allUsers && props.allUsers.data && props.allUsers.data.count
                }
            } else {
                return {
                    creatorsList: props.allUsers && props.allUsers.data && props.allUsers.data.entity ? props.allUsers.data.entity : [],
                    totalCount: props.allUsers && props.allUsers.data && props.allUsers.data.count,
                    pageCount: props.allUsers && props.allUsers.data && props.allUsers.data.count / state.recordLimit,
                    isFetched: true
                }
            }

        }
        props.resetAllUsers()

    }

    list = () => {
        if (this.props && this.props.allUsers && this.props.allUsers.statusCode == 200) {
            let request = this.props.allUsers && this.props.allUsers.request ? this.props.allUsers.request : ''
            if (request.recordLimit || request.offSet && request.search) {
                this.setState({
                    recordLimit: 200,
                    offSet: 0,
                    search: ''
                }, () => {
                    this.callApi()
                })
            } else {


                this.callApi()
            }
        } else {
            this.callApi()
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidUpdate(props, state) {
        if ((this.props &&
            this.props.creatorStoreStatusUpdated &&
            this.props.creatorStoreStatusUpdated.statusCode === 200 &&
            (this.props.creatorStoreStatusUpdated) != (props.creatorStoreStatusUpdated)
        )) {
            this.props.resetCreatorStoreStatus()
            toast.success("Store status for creator updated successfully")
            this.callApi()
        }
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    upGradeAsCreator = (userId, status) => {
        // console.log(userId)
        this.setState({ openModal: true, approvalId: userId, approvalStatus: status })
    }
    closeModal = () => {
        this.setState({ openModal: false, remarks: '', approvalId: '' })
        // history.goBack()
    }

    revokeCreator = (userId) => {
        {
            userId != null &&
                this.showLoader()
            adminServices.revokeCreator(userId).then(res => {
                this.hideLoader()
                this.setState({ isShow: false })
                toast.success("User revoked")
                this.callApi()
            }).catch(err => {
                this.hideLoader()
                toast.error(err)
            })
        }

    }
    handleRemarks = (e) => {
        // console.log(items)

        this.setState({ remarks: e.target.value }, () => {

        })
    }
    checkRemarks = (value) => {
        if ((value && (value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    approve = () => {

        const { remarks, approvalId, approvalStatus } = this.state
        let obj = {}
        let formData = new FormData()
        if (remarks) {
            if (this.checkRemarks(remarks)) {
                obj['adminRemarks'] = remarks
                obj['userId'] = Number(approvalId)
                obj['status'] = Number(1)
                // this.props.statusApprove(JSON.stringify(obj))
                this.showLoader()
                userServices.statusApprove(JSON.stringify(obj)).then(res => {
                    // history.push('/admin/creatorsApplications')
                    this.hideLoader()
                    toast.success("Approved")
                    this.setState({ remarks: '', approvalId: '', openModal: false })
                    this.callApi()
                    // this.setState({isShow:false})
                }).catch(err => { this.hideLoader() })
            } else {
                toast.error("Remarks should have atlease 3 characters")
            }
        } else {
            toast.error("Please give your remarks")
        }
    }
    reject = (items) => {
        const { remarks, approvalId } = this.state
        let obj = {}

        if (remarks) {
            if (this.checkRemarks(remarks)) {
                obj['adminRemarks'] = remarks
                obj['userId'] = Number(approvalId)
                obj['status'] = Number(2)
                // this.props.statusApprove(JSON.stringify(obj))
                this.showLoader()
                userServices.statusApprove(JSON.stringify(obj)).then(res => {
                    this.hideLoader()
                    toast.success("Rejected")
                    this.setState({ remarks: '', approvalId: '', openModal: false })
                    this.callApi()
                }).catch(err => { this.hideLoader() })
            } else {
                toast.error("Remarks should have atlease 3 characters")
            }
        } else {
            toast.error("Please give your proper remarks")
        }
    }
    statusChange = (items) => {
        const status = (items && items.accountStatus) ? 0 : 1
        let obj = {
            accountStatus: status
        }
        console.log("statusChange", items, status)
        this.showLoader()
        userServices.modifyStatus(JSON.stringify(obj), (items && items.id), status).then(res => {
            // if((res && res.statusCode) == 200){

            toast.success("Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })
        // this.props.modifyStatus(JSON.stringify(obj),(items && items.id),status)
        // this.list()
    }
    emailStatusChange = (items) => {
        this.showLoader()
        adminServices.emailVerificationChange((items && items.id)).then(res => {
            toast.success("Email Verified")
            this.callApi()
            this.hideLoader()
        })
            .catch(err => {
                toast.error(err)
                this.hideLoader()
            })
    }
    premiumStatusChange = (items) => {
        const status = (items && items.isPremiumMemberDrop) ? false : true
        let obj = {
            isPremiumDropUser: status
        }
        // console.log("statusChange", items, status)
        this.showLoader()
        adminServices.updateUserToPremium(JSON.stringify(obj), (items && items.id)).then(res => {
            // if((res && res.statusCode) == 200){

            toast.success("Premium Member Drop Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })
    }
    handleRefresh = () => {
        //reset all states when refreshed
        this.setState({
            sortBy: "createdAt",
            search: '',
            sortOrder: "DESC",
            offSet: 0,
            role: '',
            selectedRole: 'Select Role',
            recordLimit: 200,
            isAgree: false,
            range: "Select range"
        }, () => {
            this.callApi()
        })

    }
    handleSortBy = (e) => {
        if (e.target.value == 1) {
            this.setState({ selectedRole: "Creators", role: e.target.value, offSet: 0 }, () => {
                // console.log(this.state.selectedRole,this.state.role)
                this.callApi()
            })
        } else if (e.target.value == 2) {
            this.setState({ selectedRole: "Collectors", role: e.target.value, offSet: 0 }, () => {
                // console.log(this.state.selectedRole,this.state.role)
                this.callApi()
            })
        }
    }
    handleRange = (e) => {
        if (e.target.value != this.state.recordLimit) {
            this.setState({ recordLimit: e.target.value, offSet: 0, range: e.target.value }, () => {
                this.callApi()
            })
        } else {
            this.setState({ range: e.target.value })
        }
    }
    handleAllData = () => {
        this.setState({ recordLimit: this.state.totalCount })
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }

        if (role) {
            obj['roleId'] = role

        }
        if (search) {
            obj['search'] = search


        }
        this.props.getCreators(obj)

    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }
    callApi = () => {
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offSet,
            // recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        if (role) {
            obj['roleId'] = role
            // obj['offSet'] = 0

        }
        if (search) {
            obj['search'] = search
            // obj['offSet'] = 0


        }
        this.props.getCreators(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {

            this.setState({ isShow: false, })
        } else {
            this.showLoader()
            adminServices.getUserWalletAddress(items && items.id).then(res => {
                this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    const arr = res && res.data && res.data.details && res.data.details.wallet && res.data.details.wallet
                    const walletAddresses = arr.map(add => (add.walletAddress))

                    const filtered = arr.filter((adds, index) => !walletAddresses.includes((adds.walletAddress), index + 1))
                    this.setState({
                        filtered, 
                        wallet: arr, 
                        lastlogin: res && res.data && res.data.details && res.data.details.lastLogin,
                        userDetailInfo : res && res.data && res.data.details
                    })
                    // console.log(filtered)
                }
            }).catch(err => {
                this.hideLoader()

                // console.log(err)
            })
            followServices.getFollowers(items && items.id).then(res => {
                if ((res && res.statusCode) == 200) {
                    this.setState({ followCount: res && res.data && res.data.count, followers: res.data && res.data.follower })
                }

            }).catch(err => {
                console.log(err)
            })
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }
    gotoBulk = () => {
        history.push("/admin/uploadBulk")
    }
    getUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }
        }
    }
    getTwitUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }
        }
    }
    gotoCreator = (owner) => {
        const baseurl = process.env.REACT_APP_BASEPATH

        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            const id = `/creator/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            const id = `/collector/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        }
    }
    handleChecked = (value) => {
        let isAgree = value ? false : true
        if (value) {
            this.setState({ isAgree, recordLimit: 200, offSet: 0, selectedRole: "Select Role", search: '', role: '', range: "Select range" }, () => {
                this.callApi()
            })
        } else {
            this.setState({ isAgree, selectedRole: "Select Role", search: '', role: '', offSet: 0, range: "Select range" }, () => {
                this.handleAllData()
            })
        }
    }
    checkWalletAddress = (items) => {
        const accounts = items && items.userLinksWalletAccounts
        const walletAddresses = accounts.map(add => (add.walletAccount && add.walletAccount.walletAddress))
        const filtered = accounts.filter((adds, index) => !walletAddresses.includes((adds.walletAccount && adds.walletAccount.walletAddress), index + 1))
        return filtered
    }
    handleDownloadCSV = () => {
        this.showLoader()
        adminServices.getExportData().then(res => {
            this.downloadCSV(res)
            this.hideLoader()
        }).catch(err => {
            this.setState({ btnText: "Export All Users" })
            this.hideLoader()
            console.log(err)
            toast.error(err)

        })
    }
    downloadCSV = (res) => {
        this.setState({ btnText: "Exporting..." })
        const downloadJson = []
        // this.showLoader()
        // adminServices.getExportData().then(res => {
        //     this.hideLoader()
        if ((res && res.statusCode === 200)) {
            const users = res && res.data && res.data.entity
            users && users.map(el => {
                let json = {
                    "userid": el.id,
                    "type": ((el.roleId == 1) ? "Creator" : (el.roleId == 2) ? "Collector" : ''),
                    "username": el.userName,
                    "fullname": el.fullName,
                    "email": el.email,
                    "walletaddress1": ((this.checkWalletAddress(el) && this.checkWalletAddress(el)[0] && this.checkWalletAddress(el)[0].walletAccount && this.checkWalletAddress(el)[0].walletAccount.walletAddress) === undefined ? "-" : this.checkWalletAddress(el)[0].walletAccount.walletAddress),
                    "walletaddress2": ((this.checkWalletAddress(el) && this.checkWalletAddress(el)[1] && this.checkWalletAddress(el)[1].walletAccount && this.checkWalletAddress(el)[1].walletAccount.walletAddress) === undefined ? "-" : this.checkWalletAddress(el)[1].walletAccount.walletAddress),
                    "walletaddress3": ((this.checkWalletAddress(el) && this.checkWalletAddress(el)[2] && this.checkWalletAddress(el)[2].walletAccount && this.checkWalletAddress(el)[2].walletAccount.walletAddress) === undefined ? "-" : this.checkWalletAddress(el)[2].walletAccount.walletAddress),
                    "DOJ": el.createdAt,
                    "sociallink1": ((el.additionalLink1) === null ? "-" : el.additionalLink1),
                    "sociallink2": ((el.additionalLink2) === null ? "-" : el.additionalLink2),
                    "sociallink3": ((el.additionalLink3) === null ? "-" : el.additionalLink3),
                    "website": ((el.website) === null ? "-" : el.website),
                    "emailverifiedstatus": (el.isEmailVerified ? "verified" : "not verified"),
                    "status": (el.accountStatus ? "Active" : "inactive"),


                }
                downloadJson.push(json)
            })
            for (let row = 0; row < (downloadJson.length); row++) {
                var csv;
                let keysAmount = Object.keys(downloadJson[row]).length
                let keysCounter = 0
                // If this is the first row, generate the headings
                if (row === 0) {

                    // Loop each property of the object
                    for (let key in downloadJson[row]) {
                        // This is to not add a comma at the last cell
                        // The '\r\n' adds a new line
                        csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                } else {
                    for (let key in downloadJson[row]) {
                        csv += downloadJson[row - 1][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                        keysCounter++
                    }
                }

                keysCounter = 0
                if ((downloadJson.length) == (row + 1)) {
                    for (let key in downloadJson[row]) {
                        csv += downloadJson[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                    }
                }
            }
        } else {
            this.setState({ btnText: "Export All Users" })

        }
        const downloadedCsv = csv.slice(9)
        const url = window.URL.createObjectURL(
            new Blob([downloadedCsv]),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `users.xls`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        this.setState({ btnText: "Export All Users" })
        // }).catch(err => {
        //     this.setState({btnText : "Export All Users"})
        //     this.hideLoader()
        //     console.log(err)
        // })


    }
    downloadCSV1 = () => {
        const { role, search } = this.state
        let obj = {}
        if (role) {
            obj['roleId'] = role
        }
        if (search) {
            obj['search'] = search
        }
        this.showLoader()
        adminServices.getExportData(obj).then(res => {
            this.downloadCSV(res)
            this.hideLoader()
        }).catch(err => {
            toast.error(err)
            this.hideLoader()
            console.log(err)
        })
    }
    creatorStoreStatusChanges = (items) => {
        const status = items && items.storeStatus ? 0 : 1
        if((items && items.uuid)){
            let obj= { 
                creatorStoreStatus : status
            }
            this.props.updateCreatorStoreStatus((JSON.stringify(obj)),items.uuid)
        }
    }
    render() {

        const creatorsList = this.state.creatorsList && this.state.creatorsList.map((items, index) => {
            return <tbody>
                <tr className={items && items.isDisruptArtUser ? "lilacbg1" : ''}>
                    <td>{items && items.id}</td>
                    <td>{showTime(items.createdAt)} EST</td>
                    <td><a className={items && items.isDisruptArtUser ? "designLink1 userdescrip_adm" : 'designLink userdescrip_adm'} onClick={() => { this.handleView(items) }} style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.userName}>{items && items.userName}</a> <a className={items && items.isDisruptArtUser ? "designLink1 mr-2 float-right" : 'designLink mr-2 float-right'} onClick={() => this.gotoCreator(items)}><i className="fas fa-external-link-alt"></i></a></td>
                    <td><a className={items && items.isDisruptArtUser ? "designLink1 userdescrip_adm" : 'designLink userdescrip_adm'} onClick={() => { this.handleView(items) }} style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.email}>{items && items.email}</a></td>
                    {/* <td><a className="designLink" onClick={() => {this.handleView(items)}}>{items && items.fullName}</a></td> */}
                    <td>{(items && items.roleId == 2) ? "Collector" : "Creator"}</td>
                    <td><a className={'card flowtop'} style={{ display: "inline-block" }}>
                        <Toggle checked={items && items.accountStatus} onChange={() => this.statusChange(items)} />
                        {<span className="copycon">
                            <span className="uparrow"></span>
                            {items && items.accountStatus ? ("Disable User") : ("Enable User")}
                        </span>}</a></td>
                    <td><span className={'card flowtop'} style={{ display: "inline-block" }}><Toggle checked={items && items.isEmailVerified} disabled={items && items.isEmailVerified == 1} onChange={() => this.emailStatusChange(items)} />
                        {!(items && items.isEmailVerified) && <span className="copycon">
                            <span className="uparrow"></span>
                            {"verify the email"}
                        </span>}</span></td>

                        <td>{(items && items.roleId == 1) ? <a className={'card flowtop'} style={{ display: "inline-block" }}>
                        <Toggle checked={items && items.storeStatus} onChange={() => this.creatorStoreStatusChanges(items)} />
                        {<span className="copycon">
                            <span className="uparrow"></span>
                            {items && items.storeStatus ? ("Disable") : ("Enable")}
                        </span>}</a> : '-'}</td>

                    <td>{(items && items.roleId == 1) ? <Toggle checked={items && items.isPremiumMemberDrop} onChange={() => this.premiumStatusChange(items)} /> : '-'}</td>

                </tr>
                {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                    <td colSpan="9" className="px-3 graybgbox">
                        <h4 className="mb-0">Detailed Bio</h4>
                        <hr />
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label>Name </label>
                                <div className="comboxgray">
                                    {items && items.fullName}
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label>Remarks</label>
                                <div className="comboxgray">
                                    {items && items.adminRemarks ? items.adminRemarks : '-'}</div>
                            </div>
                            {/* {items.instagramLink && <div>Instagram Link : <a href={items.instagramLink} target="blank">{items.instagramLink}</a></div>}
                                {items.fbLink && <div>Google Drive Link : <a href={items.fbLink} target="blank">{items.fbLink}</a></div>}
                                {items.twitterLink && <div>Social Media Link : <a href={items.twitterLink} target="blank">{items.twitterLink}</a></div>}
                                {items.linkedInLink && <div>Dropbox Link : <a href={items.linkedInLink} target="blank">{items.linkedInLink}</a></div>} */}
                            {((items.instagramLink) || (items.fbLink) || (items.twitterLink) || (items.linkedInLink) || (items.shortDesc)) && <div className="col-md-4 mb-3"><label>Artwork Links</label>
                                <div className="comboxgray ">
                                    {items.instagramLink && <a href={items.instagramLink} target="blank" className="designLink">{items.instagramLink}</a>}<br />
                                    {items.fbLink && <a href={items.fbLink} target="blank" className="designLink">{items.fbLink}</a>}<br />
                                    {items.twitterLink && <a href={items.twitterLink} target="blank" className="designLink">{items.twitterLink}</a>}<br />
                                    {items.linkedInLink && <a href={items.linkedInLink} target="blank" className="designLink">{items.linkedInLink}</a>}<br />
                                    {items.shortDesc && <a href={items.shortDesc} target="blank" className="designLink">{items.shortDesc}</a>}<br />

                                </div>
                            </div>}


                            {items && items.description != null && <div className="col-md-12 mb-3"><label>Bio</label>
                                <div className="comboxgray">
                                    {/* {items && items.description} */}
                                    {(items && items.description && items.description.length > 600) ?
                                        <div className="showless">
                                            <input id="showless1" type="radio" name="showless" />
                                            <label htmlFor="showless1" className="showless1">Read more</label>
                                            <input id="showless2" type="radio" name="showless" checked={this.state.checked} />
                                            <label htmlFor="showless2" className="showless2">Show less</label>
                                            <div className="content">
                                                <div id="showless1" className="showlesscontent">
                                                    {items && items.description}
                                                </div>
                                            </div>
                                        </div> : <div className="content">
                                            <div id="showless1" className="showlesscontent">
                                                {items && items.description}
                                            </div>
                                        </div>}
                                </div></div>}


                            {items && items.creatorCategories && items.creatorCategories.length > 0 &&
                                <div className="col-md-9 mb-3">
                                    <label>Best in</label>
                                    <div className="comboxgray">{items.creatorCategories.map(data => (<span className="badge bg-dark mr-1 p-2">{data && data.name}</span>))}
                                    </div>
                                </div>}

                            {items && items.status ? <div className="col-md-3 mb-3">
                                <label>Status</label>
                                <div className="comboxgray">
                                    {items && items.status == 1 ? "Approved as creator" : items.status == 2 ? "Rejected user" : items.status == 3 ? "Requseted as creator" : ''}
                                </div>
                            </div> : ''}
                            {items && items.instagramProfile && <div className="col-md-4 mb-3">
                                <label>Instagram </label>
                                <div className="comboxgray">
                                    <a href={items && items.instagramProfile} target="_blank" rel="noreferrer" className={`${!(items && items.instagramProfile) ? 'd-none' : ''}`}>
                                        <span className="badgeview psocial"><i className="fab fa-instagram"></i></span> {this.getUserName(items && items.instagramProfile)}</a>
                                </div>
                            </div>}
                            {items && items.twitterProfile && <div className="col-md-4 mb-3">
                                <label>Twitter </label>
                                <div className="comboxgray">
                                    <a href={items && items.twitterProfile} target="_blank" rel="noreferrer" className={`${!(items && items.twitterProfile) ? 'd-none' : ''}`}>
                                        <span className="badgeview psocial"><i className="fab fa-twitter"></i></span> @{this.getTwitUserName(items && items.twitterProfile)}</a>
                                </div>
                            </div>}
                            <div className="col-md-3 mb-3">
                                <label>Joined</label>
                                <div className="comboxgray">
                                    {items && items.createdAt && moment(items.createdAt).format('MMMM YYYY')}
                                </div>
                            </div>
                            {this.state.userDetailInfo && this.state.userDetailInfo.bankAccountHolderName ? <div className="col-md-9 mb-3">
                                <label> Bank Details</label>
                                <div className="comboxgray">
                                    <div><span className='usernamelilac'>Account Holder Name :</span> {this.state.userDetailInfo && this.state.userDetailInfo.bankAccountHolderName}</div>
                                    <div><span className='usernamelilac'>Bank Name :</span> {this.state.userDetailInfo && this.state.userDetailInfo.bankName}</div>
                                    <div><span className='usernamelilac'> Account Number : </span>{this.state.userDetailInfo.bankAccountNumber}</div>
                                    <div><span className='usernamelilac'>Swift Code :</span> {this.state.userDetailInfo.swiftCode}</div>
                                    <div><span className='usernamelilac'>Routing Number : </span> {this.state.userDetailInfo.routingNumber}</div>
                                </div>
                                    
                            </div> : ''}
                            
                            {this.state.filtered && this.state.filtered.length > 0 &&
                                <div className="col-md-9 mb-3">
                                    <label>Wallet Address</label>

                                    <div className="comboxgray wallet-logo">{this.state.filtered && this.state.filtered.map((data, index, array) => {
                                        if(data && data.walletProvider.toLowerCase().includes('dapper')){
                                            return <a className="badge bg-dark mr-2 p-2 dapper mb-2" href={process.env.REACT_APP_ADDRESS_EXPLORER + `/${data.walletAddress}`} target="_blank">
                                             <img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="Disrupt Now" className="logoaw"/>{data.walletAddress}</a>
                                        }else if(data && data.walletProvider.toLowerCase().includes('blocto')){
                                            return <a className="badge bg-dark mr-2 p-2 dapper mb-2" href={process.env.REACT_APP_ADDRESS_EXPLORER + `/${data.walletAddress}`} target="_blank">
                                            <img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="Disrupt Now" className="logoaw" />{data.walletAddress}</a>
                                        }else if(data && data.walletProvider.toLowerCase().includes('disrupt')){
                                            return <a className="badge bg-dark mr-2  p-2 dapper mb-2" href={process.env.REACT_APP_ADDRESS_EXPLORER + `/${data.walletAddress}`} target="_blank">
                                            <img src={`${process.env.REACT_APP_BASEPATH}/logo192.png`} alt="Disrupt Now" className="logoaw"/>{data.walletAddress}</a>
                                        }else{
                                            return <a className="badge bg-dark mr-2 p-2 mb-2" href={process.env.REACT_APP_ADDRESS_EXPLORER + `/${data.walletAddress}`} target="_blank">
                                            {data.walletAddress}</a>
                                        }
                                        

                                    })}

                                    </div>
                                </div>}
                            {(this.state.followCount != "0") ? <div className="col-md-3 mb-3">
                                <label>Followers count</label>
                                <div className="comboxgray">
                                    {this.state.followCount}
                                </div>
                            </div> : ''}
                            {this.state.followers && this.state.followers.length > 0 && <div className="col-md-3 mb-3">
                                <label>Followers</label>
                                <div className="comboxgray">
                                    {this.state.followers.map(items => (
                                        <a className="badge bg-dark mr-1 p-2 mb-2" onClick={() => this.gotoCreator(items)} style={{ cursor: "pointer" }} >{items.userName && `@${items.userName}`}</a>)
                                    )}
                                </div>
                            </div>}
                            {this.state.lastlogin && <div className="col-md-4 mb-3">
                                <label>Last login </label>
                                <div className="comboxgray">
                                    {showTime(this.state.lastlogin)} EST
                                </div>
                            </div>}
                            
                            <div className="col-sm-12">
                                <hr />
                            </div>

                            {items && items.roleId == 1 && <div className="col-sm-12 text-right mb-3">

                                <button className="btn btn-primary px-3" onClick={() => this.revokeCreator(items && items.id)}>Revoke Creator Permission</button>

                            </div>}
                            {items && items.roleId == 2 && <div className="col-sm-12 text-right mb-3">

                                <button className="btn btn-primary px-3" onClick={() => { this.upGradeAsCreator(items && items.id, items && items.status) }}>Upgrade As Creator</button></div>}
                            {/* {items && items.roleId == 1 && <div className="col-sm-2 text-center">
                            
                        <button className="btn btn-primary px-3 mr-3" onClick={() => this.revokeCreator(items && items.id)}>Revert</button>
                        </div>} */}
                            {/* {(items && items.roleId == 2) && <div className="col-sm-2">
                            <div className="mb-3"><button className="btn btn-success btn-sm" onClick={() => this.approve(items)}>Approve</button></div>
                            <div><button className="btn btn-danger btn-sm" onClick={() => this.reject(items)}>Reject</button></div>
                        </div>
} */}
                        </div>




                    </td>
                </tr>
                }
            </tbody >

        })

        return (

            <Fragment>
                <Modal
                    isOpen={this.state.openModal}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal"                >
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <div className="loginpopcls">
                        <div className="custom-ui text-left">
                            <h4 className="mb-4 text-center">Remarks</h4>
                            <div className="form-group">
                                <textarea value={this.state.remarks} onChange={this.handleRemarks} className="form-control" />
                                <div className="custom-btn text-center mt-5">
                                    <button onClick={this.reject} className="btn btn-outline-dark px-3 mr-2">Reject</button>
                                    <button onClick={this.approve} className="btn btn-outline-dark px-3">Approve</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Users</h2>
                        <div className="form-group text-right col-sm-6">
                            {/* <button type="button" onClick={this.gotoBulk}  className="btn btn-primary btn-sm px-3">Import Bulk
                            Users</button>  */}
                            <button type="button" onClick={this.handleDownloadCSV} className="btn btn-primary btn-sm px-3 ml-2">{this.state.btnText}</button></div>
                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                                <div className="row">

                                    {/* <div className="col-sm-4">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><i class="fas fa-sort"></i></div>
                                        </div>
                                        <select value={this.state.sortOrder} onChange={this.handleSortOrder} className="form-control">
                                            <option value="ASC">Ascending</option>
                                            <option value="DESC">Descending</option>
                                        </select>
                                    </div>
                                </div> */}

                                    <div className="col-3">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fas fa-sort"></i></div>
                                            </div>

                                            <select value={this.state.selectedRole} onChange={this.handleSortBy} className="form-control">
                                                <option disabled>{this.state.selectedRole}</option>
                                                <option value="1">Creators</option>
                                                <option value="2">Collectors</option>

                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-3">
                                <div className="input-group mb-2 mr-sm-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text"><i class="fas fa-sort"></i></div>
                                    </div>
                                
                                    <select value={this.state.range} onChange={this.handleRange} className="form-control">
                                        <option disabled>{this.state.range}</option>
                                        <option value="100">100</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                     
                                    </select>
                                </div>
                            </div> */}
                                    <div className="col-2 text-right">
                                        <div className="form-group">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.isAgree} onChange={() => this.handleChecked(this.state.isAgree)} />
                                            <label className="form-check-label" htmlFor="exampleCheck1">ALL</label>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <button type="button" className='btn btn-primary' onClick={this.downloadCSV1}>Export</button>
                                    </div>
                                    <div className="col-2 text-right">
                                        <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>DATE</th>
                                        <th>USER NAME</th>
                                        <th>EMAIL</th>
                                        {/* <th>FULL NAME</th> */}
                                        <th>ROLE</th>
                                        {/* <th>JOINED DATE TIME</th> */}
                                        <th>STATUS</th>
                                        <th>EMAIL VERIFIED</th>
                                        <th>STORE STATUS</th>
                                        <th>ENABLE PREMIUM MEMBER DROPS</th>
                                        {/* <th>ACTIONS</th> */}
                                    </tr>
                                </thead>
                                {/* <tbody> */}
                                {creatorsList}
                                {/* </tbody> */}

                            </table>
                            {this.state.creatorsList && !this.state.creatorsList.length > 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.creatorsList && this.state.creatorsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allUsers: state.user.allUsers,
        changeStatus: state.user.modifiedStatus,
        approveStatus: state.user.approveStatus,
        revokeCreator: state.user.revokeCreator,
        creatorStoreStatusUpdated: state.creatorStore.updatedCreatorStatus
    }
}

const actionCreators = {
    getCreators: userAction.getAllUsers,
    statusApprove: userAction.statusApprove,
    modifyStatus: userAction.modifyStatus,
    resetAllUsers: userAction.resetAllUsers,
    revokeCreator: userAction.revokeCreator,
    updateCreatorStoreStatus: creatorStoreActions.updateStoreStatusForCreator,
    resetCreatorStoreStatus: creatorStoreActions.resetStoreCreatorStatus
}

const connectCreator = connect(mapStateToProps, actionCreators)(FetchUsers)

export { connectCreator as FetchUsers }
