import React from "react";
import * as fcl from "@onflow/fcl";
import {connect} from 'react-redux'
import { Redirect } from "react-router";
import { userAction } from "store/actions";
import history from "helpers/history";
import { configPath } from "config";

 class SignIn extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
        // this.handleFCLLogin()
    }
   
    // static getDerivedStateFromProps(props,state){
    //     if((props && props.isLoggedIn) == true){
    //         history.push('/')
    //     }
    // }
    enableProfile = () => {
        if(this.props && this.props.enableProfile){
        this.props.enableProfile(false)
        }
    }
    enablePurchase = () => {
        if(this.props && this.props.enablePurchase){
        this.props.enablePurchase(false)
        }
    }

    enableCollection = () => {
        if(this.props && this.props.enableCollection){
            this.props.enableCollection(false)
            }
    }

    enableArt = () => {
        if(this.props && this.props.enableArt){
            this.props.enableArt(false)
            }
    }

    enableNavbar = () => {
        if(this.props && this.props.enableNavbar){
            this.props.enableNavbar(false)
        }
    }
    
    handleFCLLogin = () => {
        // fcl.logIn()
        fcl.logIn().then(res => {
            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if(res && res.addr){
            let obj = {
                userAddress : res && res.addr,
                authProvider: provider
            }
            
            this.props.login(JSON.stringify(obj))
            // this.props.getUSD()
            if((this.props && this.props.isClick) == "profile"){
                this.enableProfile()
            }
            if((this.props && this.props.isClick) == "wallet"){
                this.enableCollection()
            }

            if((this.props && this.props.isClick) == "art"){
                this.enableArt()
            }
            if((this.props && this.props.isClick) == "connectWallet"){
                this.enableNavbar()
            }
            this.enablePurchase()
            // this.enableCollection()
        }
            
        }).catch(err => {
        })
    }
    render() {
        const redirectPath = this.props.redirectPath;
        if (!this.props.isLoggedIn) {
            return (
                <div className="authbg h50vh">
                    <div className="container">
                        <div className=" d-flex h50vh justify-content-center">
                            <div className="card py-5 px-3 w350 br30 d-block align-self-center purchasebox">
                                <div className="text-center authdnlogo mb-5 mt-5">
                                    <img src={`${configPath.imageAssets}/logoaw.png`} alt="Disrupt Now" />
                                </div>

                                <div className="iconbutton text-center mb-5 authbtns">
                                    <button onClick={this.handleFCLLogin} className="rounded btn btn-primary ricobtn  w-125px text-uppercase lgbtn">Log In</button>
                                    {/* <span className="splitor">or</span>
                                    <button onClick={fcl.signUp} className="rounded btn btn-primary ricobtn  w-125px text-uppercase lgbtn">Sign Up</button> */}
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            );
        }
        return (
            <Redirect to={redirectPath} />
        );
    }
 }
// const mapStateToProps = (state) => {
//     return {
//         isLoggedIn : state.user.isWebLoggedIn
//     }
// }

const actionCreators = {
    login : userAction.login,
    getUSD : userAction.getUSD
}
const connectLogin = connect(null,actionCreators)(SignIn)
export {connectLogin as SignIn}