import React, { Component, Fragment } from 'react'
import "react-toggle/style.css"
import PubSub from 'pubsub-js';
import { artActions, userAction, walletActions } from 'store/actions';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import { ipfsServices } from 'services/ipfs.service';
import { artServices } from 'services';
import { isWebLogin } from 'helpers';
import history from 'helpers/history';
var dataURL;
var timeout;
class MintStatus extends Component {

    constructor(props) {

        super(props);

        this.state = {
            percentage : '',
            totalCopies : '',
            mintedCopies : '',
            title : '',
            isError : false,
            isLoading : true
        }
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
 
   }
    componentDidMount(){
        this.handleMintDetail()
        timeout=  setInterval(() => {
            isWebLogin() && this.handleMintDetail()
           }, 10000)
    }
    handleMintDetail = () => {
     
        const artName = this.props && this.props.match && this.props.match.params && this.props.match.params.artname
        const artname = this.props && this.props.location && this.props.location.pathname && (this.props.location.pathname).replace('/mintStatus/','') 
        artServices.artMintProgress((artname)).then(response => {
            (response && response.text().then(data => {
                const res = data && JSON.parse(data)
            
            if(res && res.statusCode == 200){
                const totalCopies = res && res.data && res.data.totalRequestedCopies
                const mintedCopies = res && res.data && res.data.mintedCopies
                const percentage = (Number(mintedCopies)) / Number(totalCopies) * 100
                if(document.getElementById("progressbar")){
                    var elem = document.getElementById("progressbar");
                    elem.style.width = percentage + "%";
                    }
                // console.log(percentage)
                this.setState({totalCopies , 
                    mintedCopies,
                    title : res && res.data && res.data.title,percentage,isError:false,isLoading:false
                })
            }else if(res && res.statusCode === 400){
                this.setState({isError:true,isLoading:false})
            }else{
                // console.log(res)
            }
        }))
        }).catch(err => {
            // console.log(err)
        })
    }
    componentDidUpdate(){
        const {percentage} = this.state
        if((percentage) == "100"){
            clearInterval(timeout)
            if(document.getElementById("progressbar")){
                var elem = document.getElementById("progressbar");
                elem.style.width = percentage + "%";
                }

        }
    }
    componentWillUnmount(){
        clearInterval(timeout)
    }
    
    render() {
   
       const {totalCopies,mintedCopies,title,percentage,isError,isLoading} = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>Mint Status</title>
                    <meta name="title" content="Mint your NFT smart contract"></meta>
                    <meta name="description" content="Mint your NFT smart contract"></meta>
                </Helmet>
                <div className="gallery artpage">
                    <div className="container">
                        <div className="pagetitabtn">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="w-auto float-left">Minting Status</h1>
                                    <div className="probtns text-center activebtnscus float-right pt-0">
                                        <ul className="list-unstyled mb-0">
                                    <li><button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.handleMintDetail}><i className="fa fa-refresh" aria-hidden="true" ></i></button></li>
                                    </ul>
                                    </div>

                                </div>
                            </div>
                            <hr />
                        </div>      
                       { (isLoading) && isWebLogin() && 
                       <div className="container detailWallet">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="dotsloading">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h3 className="mt-3">Please wait </h3>
                                </div>
                            </div>
                        </div>
                    </div>}
                        {(isWebLogin() && !isError && !isLoading) ? 
                        <div className="col-sm-6 col-md-6 offset-md-3" >
                            <div className="cardlist">
                                <div className="text-center mb-3"> 
                                    <h4>{title}</h4>
                                    <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" id="progressbar" style={{width: "0%"}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                </div>
                            <div>
                            <div className="text-center mb-3">
                                <p>{percentage && (Math.floor(percentage) + "%")}</p>

                            </div>
                            <div className="text-center mb-3">
                                <h5 className="designLink mb-0" >Minted {mintedCopies}/{totalCopies} Editions</h5>
                            </div>
                        </div>
                    </div>
                </div> : ((!isError && !isWebLogin()) ? 
              
                        (<div className="text-center"> 
                        Kindly Login Your Account First
                        <div className=" p-3 mb-3">

                            <div className=" ">
                                <button type="button" className="btn btn-primary " onClick={() => {history.push('/login')}}>Login</button>
                            </div>
                      
                    </div>
                    </div> ) : 
                    (!(isLoading) && 
                        <div className="col-sm-6 col-md-6 offset-md-3" >
                            <div className="cardlist">
                    <div className="text-center"> 
                    Please wait. Minting of your art will begin shortly               
                  </div>
                </div>
                </div> )
                    )
                }
                </div> 
                </div> 
                

            
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isArt: state.art.art,
        usd: state.user.usd,
        usersList: state.user.usersList,
        failedArt: state.art.failedArt,
        wallet: state.wallet.wallet

    }
}
const actionCreators = {
    uploadArt: artActions.uploadArt,
    resetArt: artActions.resetUpload,
    getUSD: userAction.getUSD,
    getUserProfile: userAction.getUsers,
    resetUploadFail: artActions.resetUploadFail,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet

}
const connectMintStatus = connect(mapStateToProps, actionCreators)(MintStatus)
export { connectMintStatus as MintStatus }
