import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices } from "services";
import PubSub from 'pubsub-js';
import { connect } from "react-redux";
import { airdropActions, announcementActions } from "store/actions";
import moment from 'moment'

class FetchAirdropUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user : '',
            airdropList : [],
            isFetched : false
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    static getDerivedStateFromProps(props, state) {
        if ((props && props.airdrop && props.airdrop.statusCode === 200)) {
            props.resetFetchAirdrops()
            return {
                airdropList: props.airdrop && props.airdrop.data && props.airdrop.data.airDrops && props.airdrop.data.airDrops.entity ? props.airdrop.data.airDrops.entity : [],
                isFetched : props && props.isfetched
            }
        }
    }
   
    handleUser = (e) => {
        this.setState({
            user : e.target.value && e.target.value.toLowerCase()
        })
    }
    
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    userNameValidation = (value) => {
        const regext = /^([a-z0-9.]{4,})+$/
        if (regext.test(value)) {
            return true
        } else {
            return false
        }

    }
    resetAirdrop = () => {
        this.setState({
            user : '',
            airdropList : []
        })
    }
    gotoArt = (items) => {
        if (items && items.airdrop_id) {
            const baseurl = process.env.REACT_APP_BASEPATH
            const id = items && items.airdrop_id

            const navPath = (baseurl + `/admin/airdropDetail/${id}`)
            window.open(navPath, '_blank')
        }
    }
    submitUser = (e) => {
        e.preventDefault()
        const {user} = this.state
        if(user){
            if(this.checkemail(user) ){
                
                this.props.fetchAirdrops(user)
            }else{
                toast.error("Please enter valid email address")
            }
        }else{
            toast.error("Please enter email address")
        }
    }
    render() {

        const {
            airdropList,isFetched
        } = this.state;
        console.log("airdrops------------",this.state.airdropList)
        const airdropLists = this.state.airdropList && this.state.airdropList.map((items, index) => {
            return <tbody>
                <tr className={items && items.isDisruptArtUser ? "lilacbg1" : ''}>
                    <td>{items && items.airdrop_id}</td>
                    <td>{items && items.art_title}</td>
                    <td><a className={'designLink mr-2 '} onClick={() => this.gotoArt(items)} ><i className="fas fa-external-link-alt"></i></a></td>
                    <td>{items && items.is_claimed && (items.is_claimed === "YES") ? "Claimed" : "Yet to claim"}</td>
                    <td><a className={' userdescrip_adm'} title={items && items.creator_username}>{items && items.creator_username && ` @${items.creator_username}`}</a> </td>
                    {/* <td>{(items && items.airDropType == 1) ? "Selected users" : "Public"}</td> */}
                </tr>
            </tbody >

        })
        return (
            <Fragment>
                <div className="container">

                <div className="mb-4 admintitle">
                        <div className='row'>
                        <h2 className="font-weight-bold  col-sm-6">Airdrop status check</h2>
                                            </div>
                        </div>
                    
                    <div className="purchasebox p-2 mb-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3 col-md-6 offset-md-3">
                                    <input type="email" className="form-control " placeholder="Enter email address" name="user" value={this.state.user} onChange={this.handleUser}/>
                                </div>
                                <div className="text-center">
                                    {/* <button className="btn btn-primary mr-2" type="button" onClick={this.resetAirdrop}>Reset</button> */}
                                    <button className="btn btn-primary mb-3" type="submit" onClick={this.submitUser}>Check Airdrop</button>

                                </div>
                               
                            </div>
                        </div>
                        </div>
                        {(isFetched) &&
                        <div className="purchasebox p-2 mb-5">
                        <div className="card">
                            <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>AIRDROP ID</th>
                                        <th>AIRDROP NAME</th>
                                        <th>DETAIL</th>
                                        <th>STATUS</th>
                                        <th>LISTED BY</th>
                                        {/* <th>AIRDROP TYPE</th> */}
                                    </tr>
                                </thead>

                                {airdropLists}


                            </table>
                            {airdropList && airdropList.length == 0 && <div className="text-center">No Data Found</div>}
                        </div>
                        </div>
                        </div>
                        </div>
                        }

                    </div>
                
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        airdrop : state.airdrop.userAirdrops,
        isfetched : state.airdrop.isUserAirdropFetched
    }
}

const actionCreators = {
    fetchAirdrops : airdropActions.fetchuserAirdrops,
    resetFetchAirdrops : airdropActions.resetUserAirdrops
}

const connectFetchAirdropUsers = connect(mapStateToProps, actionCreators)(FetchAirdropUsers)

export { connectFetchAirdropUsers as FetchAirdropUsers }