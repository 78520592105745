import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { adminServices } from 'services';
import { getMarketAddress } from 'utils';

class CheckSite extends Component {
    constructor(props){
        super(props);
        this.state = {
            isProblem : false,
            mainAddress : ''
        }
    }
    componentDidMount(){
      this.checkMarketAddress();
      setInterval(() => {
          window.location.reload()
      }, 900000);
    }
    checkMarketAddress = () => {
      
        adminServices.getAddressConfig().then(add1 => {
            getMarketAddress().then(add2 => {
                
                if((add1 && add1.data && add1.data.marketplaceAddress) == (add2 && add2.data && add2.data.Value)){
                    this.setState({isProblem : false,mainAddress : add2 && add2.data && add2.data.Value})
                }else{
                    this.setState({isProblem : true,mainAddress : add2 && add2.data && add2.data.Value})

                }
            }).catch(error => {
                console.log(error)
            })
        }).catch(err => {
            console.log(err)

        })
    }
    render(){
     var str = this.state.mainAddress
    const encryptedAdd =  (str.length > 5) ? str.substr(0, 5-1) + '......' +  str.substr(str.length - 4, str.length - 1): str;
        
        return (
            <Fragment>
                {/* <h4 className="add-screen">{encryptedAdd}</h4> */}

                {/* {!this.state.isProblem && <div className="center-screen-ok">
                    <h4 className="center-screen">Alright <br/><span> {encryptedAdd}   </span>  </h4>
                                
                    
                </div>}
                {this.state.isProblem && <div className="center-screen-danger">
                    <h4 className="center-screen">Problem</h4>
                    {encryptedAdd}
                </div>} */}
                 <div className="outer">
                <div className="middle">
                {!this.state.isProblem && <div className="innertext">
                    <h1>Alright</h1>
                    <h4>{encryptedAdd}</h4>
                    </div>}
                    {this.state.isProblem && <div className="innertext-danger">
                    <h1>Problem</h1>
                    <h4>{encryptedAdd}</h4>
                    </div>}
                </div>
            </div>
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        webLogRes : state.user.webLogRes,
        failedRes : state.user.failedRes
    }
}

const actionCreators = {
    // login : userAction.webLogin,
  
}

const connectCheckSite = connect(mapStateToProps, actionCreators)(CheckSite)

export { connectCheckSite as CheckSite }