import Modal from 'react-modal'
import React, { Fragment } from 'react';
import PubSub from 'pubsub-js';
import * as fcl from "@onflow/fcl";
import { Helmet } from 'react-helmet';
import { configPath } from '../config';
import { SendWyreCheckout } from './send-wyre-checkout/send-wyre-checkout';
import { getUserAddress } from 'utils';
import { userAction, walletActions } from 'store/actions';
import { connect } from 'react-redux';
import history from 'helpers/history';
import { moonpayService } from 'services';
import { flowDecimal, localAirdropPage, localArtPage, localDropPage, userBalance } from 'helpers';
import { JoyRide } from './Joy-ride';
import { checkFundWalletPage } from 'helpers/joy-rideCheck';

class FundWalletPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openMoonPayModal: false,
            isAlert: false,
            message: '',
            address: '',
            moonpayGatewayUrl: '',
            openSendWyreModal: false,
            balance : 0.0,
            walletaddress : '',
            dropDetail : {},
            needBalance : 0.0,
            purchaseType : ''
        };
        this.timeout = null;
        this.sendWyreCheckoutRef = React.createRef();
        this.closeMoonPayModal = this.closeMoonPayModal.bind(this);
        this.openMoonPayModal = this.openMoonPayModal.bind(this);
        this.openSendWyreCheckout = this.openSendWyreCheckout.bind(this);
        this.onSendWyreError = this.onSendWyreError.bind(this);
        this.onSendWyreSuccess = this.onSendWyreSuccess.bind(this);
    }

    closeMoonPayModal(e) {
        this.setState({
            openMoonPayModal: false,
            moonpayGatewayUrl: ''
        });
    }

    openMoonPayModal(e) {
        this.showLoader();
        const moonPayGatewayPromise = getUserAddress()
            .then((address) => {
                if (!!address && address !== '') {
                    return moonpayService.getSignMoonpayGatewayUrl(address)
                }
                throw new Error('Wallet is not connected');
            })
            .then((response) => {
                if (
                    !!response.statusCode &&
                    response.statusCode === 200 &&
                    !!response.data &&
                    !!response.data.signedURL &&
                    typeof response.data.signedURL === 'string'
                ) {
                    return response.data.signedURL;
                }
                throw new Error('Failed to get url with signature');
            })
        moonPayGatewayPromise
            .then(signedURL => {
                this.hideLoader();
                if (!!signedURL && signedURL !== '') {
                    this.setState({
                        moonpayGatewayUrl: signedURL,
                        openMoonPayModal: true
                    });
                } else {
                    this.setState({
                        isAlert: true,
                        message: 'Failed to get url with signature'
                    })
                }

            })
            .catch(e => {
                this.hideLoader();
                this.setState({
                    moonpayGatewayUrl: '',
                    isAlert: true,
                    message: e.message ? e.message : e
                });
            });
    }

    openSendWyreCheckout(e) {
        this.sendWyreCheckoutRef.current.open();
    }

    hideAlert = () => {
        this.setState({ isAlert: false, message: '' })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    onSendWyreError(error) {
        this.setState({ isAlert: true, message: error, openSendWyreModal: false });

    }

    onSendWyreSuccess(successEvent) {
        // console.log('Sendwyre payment success',successEvent)
        history.goBack();
    }

    closeAlert = () => {
        this.timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }

    resetTimeout = () => {
        clearTimeout(this.timeout)
    }

    connectWallet() {

        fcl.logIn().then(res => {
            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                this.setState({
                    address: obj.walletAddress,
                    isAlert: false,
                    message: ''
                })
            } else {
                this.setState({ isAlert: true, message: 'Failed to get wallet address' });
            }
        }).catch(err => {
            const error = err.message ? err.message : String(err);
            // console.error('Failed to connect to wallet', err);
            this.setState({ isAlert: true, message: error });
        })
    }
    openSendWyreHost = (hostUrl) => {
        this.setState({ openSendWyreModal: true, hostUrl })
    }
    closeSendWyreModal = () => {
        this.setState({ openSendWyreModal: false, hostUrl: '' })
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            // behavior: "smooth"
        });
    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.gotoTop() 

        if((this.props 
            && this.props.location 
            && this.props.location.state 
            && this.props.location.state.obj 
            && this.props.location.state.needBalance
            && this.props.location.state.type
            )){
            this.setState({
                dropDetail : this.props.location.state.obj,
                needBalance : this.props.location.state.needBalance,
                purchaseType : this.props.location.state.type
            })
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.obj;
            history.replace({ ...location, state });
        }
        this.getFlowDetail()
    }
    getFlowDetail = async() => {
        try {
            const flowDetail = await userBalance()
            if(flowDetail && flowDetail.address && flowDetail.address !== null && flowDetail.address !== undefined){
                this.setState({
                    walletaddress : flowDetail.address
                })
            }
            if(flowDetail.balance && flowDetail.balance.status === 200 && flowDetail.balance.data && flowDetail.balance.data.Value){
                this.setState({
                    balance : JSON.parse(flowDetail.balance.data.Value)
                })
            }
        }catch(e){
            this.setState({balance : 0.0 , walletaddress : ''})
        }
        
    }
    getCreator = (item) => {
        if(item && item.creator && item.creator.fullName){
            return `by ${item.creator.fullName}`
        }else if(item && item.creator && item.creator.userName){
            return `by ${item.creator.userName}`
        }else if(item && item.creatorWalletAddress){
            return ``
        }
    }
    componentDidUpdate(props, state) {

        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
    }
    gotoBack = () => {
        history.goBack()
    }
    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: '35%',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                height: '700px', // <-- This sets the height
                overlfow: 'auto' // <-- This tells the modal to scrol
            },
        };
        return (
            <Fragment>
                <Helmet>
                    <title>Fund your wallet</title>
                    <meta name="title" content="Fund your Flow wallet"></meta>
                    <meta name="description" content="Purchase & Fund your Flow crypto wallet with Credit card, Debit card, Apple Pay or Bank transfer"></meta>
                </Helmet>
                {
                    this.state.isAlert &&
                    <div className="alert alert-custom text-center alert-danger alertheight" id="parent" >
                        <div className="d-flex flex-row align-items-center h-100 w-100">
                            <span className="artmsg">{this.state.message}</span>
                            <span className="closebtn" onClick={this.hideAlert}>&times;</span>
                        </div>
                    </div>
                }
                <div className="fundwallet">
                <JoyRide start={checkFundWalletPage()} page="fund_wallet_page" isScroll={false}  />
                    <div className="container">
                        {(this.state.dropDetail && this.state.dropDetail.id) ? <div className="row my-4 wallet_message">
                            <div className="col-md-12 text-center">
                                <h5 className=''>Your Flow Account <span className='usernamelilac'>{this.state.walletaddress}</span> balance is <span className='usernamelilac'>{(parseFloat(this.state.balance)).toFixed(4)} Flow </span></h5>
                                <h5 className=''>You need {(parseFloat(this.state.needBalance)).toFixed(4)} Flow more to {(this.state.purchaseType === "purchase") ? "purchase" : "bid"} the NFT " {this.state.dropDetail.title} " {this.getCreator(this.state.dropDetail)} </h5>
                                <h5 className='usernamelilac '>Purchase Flow from any of the below methods & add your above Flow account address in their withdrawal / Flow receiver address field</h5>
                                <button className='btn btn-primary mt-3 btn-smcus' type='button' onClick={this.gotoBack}>Continue {(this.state.purchaseType === "purchase") ? "purchase" : "bidding"}</button>
                            </div>
                        </div> : 
                        <div className="row">
                            <div className="col-md-4 offset-md-4">
                                <h1 className="text-center mt-5 mb-3">Fund Your Wallet</h1>
                                <h4 className="text-center ">Purchase Flow</h4>
                            </div>
                        </div>}
                        <hr />

                        <h4 className="my-3">Payment Gateways</h4>


                        <div className="row">
                            {/* Hidden send wyre */}
                            {/* <div className="col-md-6">
                                <div className="fund-wallet-container text-center">
                                    <div className="btn btn-primary  w-100 mb-3 fwcon" >
                                        <div className="fwconh">
                                            <span className="mb-3 w-100 d-block">Credit Card, Debit Card & Apple Pay</span>
                                            <div className="row text-left">
                                                <div className="col-md-12">
                                                    <div className="footer-links builtwallet text-center mb-3">
                                                        <img src={`/${configPath.imageAssets}/apple-pay.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/visa.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/mastercard.png`} alt="" className="onflowicon" />
                                                    </div>
                                                    <span className="text-white text-capitalize mb-3 text-center">Available for 30 + Countries &<br /> USA (Except Texas & New York)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-4">
                                            <a href={process.env.REACT_APP_SENDWYRE_FEES} target="_blank" className="text-white text-capitalize" rel="noreferrer">Fees | </a>                                      
                                                <a href={process.env.REACT_APP_SENDWYRE_GEO} target="_blank" className="text-white text-capitalize" rel="noreferrer">Geo Restrictions</a></div>                                             <div className="col-md-8 text-md-right mt-3 mt-sm-3 mt-md-0">
                                                <span className="text-white text-capitalize d-inline-block  mr-3"><img src={`/${configPath.imageAssets}/sendwyre.png`} alt="" className="onflowicon w-auto mr-0" /> SendWyre</span> <button className="btn btn-primary h-auto" onClick={this.openSendWyreCheckout}>Proceed &nbsp;<i className="fas fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* Sendwyre section ends */}


                            {/* 
                                    {this.state.openSendWyreModal && <div className="fundWallet"> <iframe title="Fund wallet using sendwyre" src={`${this.state.hostUrl}`} width={'100%'} style={{ border: "none", minHeight:'650px'}} allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"></iframe>
                                    <button onClick={this.closeSendWyreModal} className="btn btn-primary px-2 clsbtn">X</button>

                                    </div>} */}

                            <div className="col-md-5 coin_base_box">
                                <div className="fund-wallet-container text-center">
                                    <div  className="btn btn-primary w-100 mb-3 fwcon cbbg">
                                        <span className='cbbgimg'>
                                            <img src={`/${configPath.imageAssets}/conibase.png`} alt="" className='mw-100' />
                                        </span>
                                        <span className='cbbgbtn'>
                                            <a href='https://www.coinbase.com/price/flow' target={'_blank'}  className="btn btn-primary h-auto">Proceed &nbsp;<i className="fas fa-arrow-right"></i></a>                                        
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-7 moon_pay_box">
                                <div className="fund-wallet-container text-center">
                                    <div className="btn btn-primary w-100 mb-3 fwcon">
                                        <div className="fwconh">
                                            <span className="mb-3 w-100 d-block">Credit Card & Bank Transfer</span>
                                            <div className="row text-left">
                                                <div className="col-md-12" >
                                                    <div className="footer-links builtwallet text-center mb-3">
                                                        <img src={`/${configPath.imageAssets}/Samsung-Pay.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/google-pay.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/apple-pay.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/visa.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/mastercard.png`} alt="" className="onflowicon" />
                                                        <img src={`/${configPath.imageAssets}/maestro.png`} alt="" className="onflowicon" />
                                                    </div>
                                                    <span className="text-white text-capitalize mb-3 text-center">Available for 150 + Countries &<br /> USA (Except Hawaii,New York, Rhode Islands, US virgin Islands)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-4">
                                                <a href={process.env.REACT_APP_MOONPAY_FEES} target="_blank" className="text-white text-capitalize" rel="noreferrer">Fees | </a>
                                                <a href={process.env.REACT_APP_MOONPAY_GEO} target="_blank" className="text-white text-capitalize" rel="noreferrer">Geo Restrictions</a></div>

                                            <div className="col-md-8 text-md-right mt-3 mt-sm-3 mt-md-0">
                                                <span className="text-white text-capitalize d-inline-block mr-3">
                                                    <img src={`/${configPath.imageAssets}/moonpay.png`} alt="" className="onflowicon w-auto mr-0" /> MoonPay</span><button className="btn btn-primary h-auto" onClick={this.openMoonPayModal}>Proceed &nbsp;<i className="fas fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row align-items-center mt-5">
                            <div className="col-md-6">
                                <h4 className="mb-4">Crypto Currency Exchanges</h4>
                                <div className="row">
                                    <div className="col-md-6 currency_box1">
                                        <div className="fund-wallet-container text-center">
                                            <a target="_blank" href={process.env.REACT_APP_KRAKEN} className="btn btn-outline-secondary w-100 mb-3 cusbtns" rel="noreferrer">
                                                <img src={`/${configPath.imageAssets}/Kraken-Logo.png`} alt="" />
                                                <span className="d-block w-100 mt-2">BUY FLOW</span> </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 currency_box2">
                                        <div className="fund-wallet-container text-center">
                                            <a target="_blank" href={process.env.REACT_APP_COINLIST} className="btn btn-outline-secondary  w-100 mb-3 cusbtns" rel="noreferrer">
                                                <img src={`/${configPath.imageAssets}/coinlist.png`} alt="" />
                                                <span className="d-block w-100 mt-2">BUY FLOW</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h4 className="mb-4">Crypto Swap</h4>
                                <div className="row">
                                    <div className="col-md-6 currency_box3">
                                        <div className="fund-wallet-container text-center">
                                            <a target="_blank" href={process.env.REACT_APP_BLOCTO_SWAP} className="btn btn-outline-secondary  w-100 mb-3 cusbtns" rel="noreferrer"><img src={`/${configPath.imageAssets}/logo-blocto-swap-full.b965532d.png`} alt="" />
                                                <span className="d-block w-100 mt-2">Swap FUSD, BLT & TUSDT to Flow</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal
                            isOpen={this.state.openMoonPayModal}
                            style={customStyles}
                            className="creatorrequ fundWalletcreat"
                            contentLabel="Fund wallet using moonpay"
                            ariaHideApp={false}
                        >
                            <div className="fundWallet">
                                <button onClick={this.closeMoonPayModal} className="btn btn-primary px-2 clsbtn">X</button>
                                <iframe title="Fund wallet using moonpay" className="fundiframe" src={`${this.state.moonpayGatewayUrl}`} width={'100%'} allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"></iframe>
                                {/* <iframe title="Fund wallet using moonpay" src={`${this.state.moonpayGatewayUrl}`} width={'100%'} style={{ border: "none"}} allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment" className="max-h-screen h-128"></iframe> */}
                            </div>
                        </Modal>
                        <SendWyreCheckout
                            ref={this.sendWyreCheckoutRef}
                            onerror={this.onSendWyreError}
                            onsuccess={this.onSendWyreSuccess}
                        // getHost = {(hostUrl) => {this.openSendWyreHost(hostUrl)}}
                        />
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        detailCreator: state.creator.detailCreator,
        usersList: state.user.usersList,
        creatorRes: state.user.creatorRes,
        wallet: state.wallet.wallet

    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    linkWallet: walletActions.linkWallet,


}
const connectFundWalletPage = connect(mapStateToProps, actionCreators)(FundWalletPage)
export { connectFundWalletPage as FundWalletPage }
