import { transactionConstants } from "../../constants";

const initialState = {
    gettxnHistory:[],
    gettxnFailed : '',
    eventInitiated : [],
    eventInitiatedFailed : '',
    eventCancelled : [],
    eventCancelledFailed : '',
    eventFailured : [],
    eventFailuredFailed : '',
    eventTransferSuccess : [],
    eventTransferFaied : '',
    listStaged: [],
    listStagedFailed : '',
    listsuccess: [],
    listFailed: ''
    
}
export function transactions(state=initialState,action){
    switch(action.type){
        case transactionConstants.GET_TRANSACTION_HISTORY:
            return {
                ...state,
                gettxnHistory : action.gettxnHistory,
               
            }
        case transactionConstants.GET_TRANSACTION_HISTOTY_FAILED:
            return {
                ...state,
                gettxnFailed : action.gettxnFailed
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_INITIATED:
            return {
                ...state,
                eventInitiated : action.eventInitiated
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_INITIATED_FAILED:
            return {
                ...state,
                eventInitiatedFailed : action.eventInitiatedFailed
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED:
            return {
                ...state,
                eventCancelled : action.eventCancelled
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED_FAILED:
            return {
                ...state,
                eventCancelledFailed : action.eventCancelledFailed
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_FAILURE:
            return {
                ...state,
                eventFailured : action.eventFailured
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_FAILURE_FAILED:
            return {
                ...state,
                eventFailuredFailed : action.eventFailuredFailed
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_COMPLETE:
            return {
                ...state,
                eventTransferSuccess : action.eventTransferSuccess
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_FAILED:
            return {
                ...state,
                eventTransferFaied : action.eventTransferFaied
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING:
            return {
                ...state,
                listStaged : action.listStaged
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING_FAILED:
            return {
                ...state,
                listStagedFailed : action.listStagedFailed
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_LISTING_SUCCESS:
            return {
                ...state,
                listsuccess : action.listsuccess
            }
        case transactionConstants.FORSALE_EVENT_HISTORY_LISTING_FAILED:
            return {
                ...state,
                listFailed : action.listFailed
            }
        default:
            return state
    }
}