import { airdropConstants } from "../../constants";

const initialState = {
    airdropList: [],
    isCheckAirDrop: false,
    allairdropList: [],
    isListAirDrop: false,
    airdropDetail: [],
    isDetailAirDrop: false,
    airdropDetailUser: [],
    isCheckAirDropDetail: false,
    verifyAirdropUser: [],
    failedAirdropUser: '',
    regAirdropUser: [],
    failedRegAirdrop: '',
    userAirdrops: [],
    isUserAirdropFetched: [],
    aidropReminderRes: [],
    airdropReminderFailed: [],
    saveAirdropUser: [],
    saveAirdropUserFailed: '',
    saveAirdropGuestUser: [],
    saveAirdropGuestUserFailed: '',
    welcomeUser: [],
    welcomeUserFailed: ''
}
export function airdrop(state = initialState, action) {
    switch (action.type) {
        case airdropConstants.GET_AIRDROP_ART:
            return {
                airdropList: action.airdropList,
                isCheckAirDrop: action.isCheckAirDrop
            }

        case airdropConstants.GET_AIRDROP_LISTING:
            return {
                allairdropList: action.allairdropList,
                isListAirDrop: action.isListAirDrop
            }
        case airdropConstants.GET_AIRDROP_DETAIL:
            return {
                airdropDetail: action.airdropDetail,
                isDetailAirDrop: action.isDetailAirDrop
            }
        case airdropConstants.GET_AIRDROP_DETAIL_USER:
            return {
                airdropDetailUser: action.airdropDetailUser,
                isCheckAirDropDetail: action.isCheckAirDropDetail
            }
        case airdropConstants.VERIFY_AIRDROP_USER:
            return {
                verifyAirdropUser: action.verifyAirdropUser
            }
        case airdropConstants.VERIFY_AIRDROP_USER_FAILED:
            return {
                failedAirdropUser: action.failedAirdropUser
            }
        case airdropConstants.REGISTER_AIRDROP_USER:
            return {
                regAirdropUser: action.regAirdropUser
            }
        case airdropConstants.REGISTER_AIRDROP_USER_FAILED:
            return {
                failedRegAirdrop: action.failedRegAirdrop
            }
        case airdropConstants.FETCH_USERS_AIRDROP:
            return {
                userAirdrops: action.userAirdrops,
                isUserAirdropFetched: action.isUserAirdropFetched
            }
        case airdropConstants.AIRDROP_REMINDER:
            return {
                ...state,
                aidropReminderRes: action.aidropReminderRes
            }
        case airdropConstants.AIRDROP_REMINDER_FAILED:
            return {
                ...state,
                airdropReminderFailed: action.airdropReminderFailed
            }
        case airdropConstants.AUTO_REGISTER_LOGIN_USER:
            return {
                ...state,
                saveAirdropUser: action.saveAirdropUser
            }
        case airdropConstants.AUTO_REGISTER_LOGIN_USER_FAILED:
            return {
                ...state,
                saveAirdropUserFailed: action.saveAirdropUserFailed
            }
        case airdropConstants.AUTO_LOGIN_GUEST:
            return {
                ...state,
                saveAirdropGuestUser: action.saveAirdropGuestUser
            }
        case airdropConstants.AUTO_LOGIN_GUEST_USER_FAILED:
            return {
                ...state,
                saveAirdropGuestUserFailed: action.saveAirdropGuestUserFailed
            }
        case airdropConstants.AUTO_LOGIN_WELCOME_USER:
            return{
                 ...state,
                 welcomeUser: action.welcomeUser
            }
        case airdropConstants.AUTO_LOGIN_WELCOME_USER_FAILED:
            return {
                ...state,
                welcomeUserFailed: action.welcomeUserFailed
            }
        default:
            return state
    }
}