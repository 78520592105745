import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { toastr } from 'helpers';

class UploadUsers extends Component{
    constructor(props){
        super(props);
        this.state = {
            fileData : [],
            imageName : '',
            totaluser : '',
            existsValue : '',
            insertuser : '',
            emptyValue : [],
            uploadRes : []
        }
    }
    componentDidMount(){
        // this.props.dashboard()
        this.setState({fileData:[],imageName:''})
        
    }
 
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props,state){
        if(props && props.bulkResponse && props.bulkResponse.statusCode == 200 ){
            props.resetBulkUpload()

        return {
            uploadRes : props && props.bulkResponse && props.bulkResponse.data,
            totaluser : props && props.bulkResponse && props.bulkResponse.data && props.bulkResponse.data.totaluser,
            existsValue : props && props.bulkResponse && props.bulkResponse.data && props.bulkResponse.data.existsValue && props.bulkResponse.data.existsValue.length >= 1 && props.bulkResponse.data.existsValue[0] && props.bulkResponse.data.existsValue[0].count,
            insertuser : props && props.bulkResponse && props.bulkResponse.data && props.bulkResponse.data.insertuser && props.bulkResponse.data.insertuser.length >= 1 && props.bulkResponse.data.insertuser[0] && props.bulkResponse.data.insertuser[0].count,
            emptyValue : props && props.bulkResponse && props.bulkResponse.data && props.bulkResponse.data.emptyValue && props.bulkResponse.data.emptyValue.length >= 1 && props.bulkResponse.data.emptyValue[0] && props.bulkResponse.data.emptyValue[0].count,
            imageName: '',


        }
    }

    }
    fileProgress = async (e) => {
        let files = e.target.files[0]
        this.setState({file:e.target.files[0]})
        const types = ['text/csv']
        let err = ''
        let com_File = {}

        // console.log("targetfile", e.target.files[0])

        if (types.every(type => files && files.type !== type)) {

            err += files.type + ' is not a supported format\n';
            toast.error(err)
        }
       else if (e.target.files[0]) {
                if (err == '') {
                    let reader = new FileReader();
                    let file = e.target.files[0]
                    reader.onloadend = () => {
                        this.setState({
                            fileData: file,
                            imageName: file.name,
                            imgPreview: reader.result,
                            finalImage: reader.result
                        })
                    }
                    if (e.target.files && e.target.files[0]) {
                        reader.readAsDataURL(file)
                        // console.log("raeder1", reader)
                    }
                }
            
        }
      
    }
    handleSubmit = () => {
        const {imageName,fileData,file} = this.state
        // console.log("---",fileData)
        if(imageName){
            let formdata = new FormData()
            formdata.append("file",fileData)
            this.props.bulkUserUpload((formdata))
        }else{
            toast.error("Please select your csv file")
        }
    }
    downloadCSV = () => {
        this.showLoader()
        adminServices.downloadCSV().then(res => {
            res && res.text().then(text => {
                const url = window.URL.createObjectURL(
                    new Blob([text]),
                  );
                
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `sampleFile.csv`,
                  );
              
                  // Append to html link element page
                  document.body.appendChild(link);
              
                  // Start download
                  link.click();
              
                  // Clean up and remove the link
                  link.parentNode.removeChild(link);
            })
            this.hideLoader()
            
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })
    }
   
    render(){
        const {fileData,existsValue} = this.state
        return(
            <Fragment>
                <div className="container-fluid">

                    <div className="card">
                        <div className="card-body px-0">
                        <div className="text-right col-sm-12"><button className="btn btn-outline-dark " type="button" onClick={this.downloadCSV}>Download Sample CSV File</button></div>

                            <div className="purchasebox1 p-3 reglogfrm">
                                <div className="mb-4"><h2 className="text-center mt-5 mb-3">Import Bulk Users &nbsp;<span className="betaLink">(Beta)</span></h2></div>

                          
                                <div className="form-group">                                        
                                    <label className="text-center">Select Your <b>CSV</b> File : </label>                                        
                                    <div className="custom-file mb-3" id="customFile" lang="es">
                                    <input type="file" className="custom-file-input" onChange={this.fileProgress} id="exampleInputFile" aria-describedby="fileHelp" />
                                <label className="btn btn-outline-dark w-100" for="exampleInputFile">
                                Upload File...
                                </label>
                                {this.state.imageName && this.state.imageName}
                        </div>

                        </div>
                        {/* <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Total Users</label>
                                <div className="comboxgray">
                                    {this.state.totaluser}
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Insterted Users</label>
                                <div className="comboxgray">
                                    { this.state.insertuser}</div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Exist Value</label>
                                <div className="comboxgray">
                                    {existsValue}
                                    </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Empty Value</label>
                                <div className="comboxgray">
                                    { this.state.emptyValue}</div>
                            </div>
                            </div> */}
                    <div className="form-group text-center">
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary btn-sm px-3">Submit</button>
                    </div>
                    
                        </div>
                    </div>
                    </div>
                </div>
            
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        bulkResponse : state.user.bulkUser,
    }
}
const actionCreator = {
    bulkUserUpload : userAction.bulkupload,
    resetBulkUpload : userAction.resetBulkUpload
}
const connectBulkUser = connect(mapStateToProps,actionCreator)(UploadUsers)
export {connectBulkUser as UploadUsers}