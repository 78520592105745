import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, userAction } from 'store/actions';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { userServices } from 'services';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from '../config'
import { CommonDisableError } from './common_disable_error';

var timeout;
class AirdropInvitePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            isSuccess: false,
            isAlert: false,
            isUsernameError: '',
            isUserTyping: false,
            message: '',
            style: '',
            isVerified: false,
            reCaptchaToken: '',
            isAgree: false,
            code: '',
            isError: false,
            isCalled: false,
            verifiedMes: '',
            unregEmail: ''
        }
    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        // if (isWebLogin()) {
        //     history.push('/airdrops')
        // } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.setState({ isSuccess: false })


            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let verificationCode = urlParams.get('code')
            let code = this.props && this.props.match && this.props.match.params && this.props.match.params.code && this.props.match.params.code

            console.log("query", code);


            if (code) {
                this.setState({ code })
           
                this.props.registerAirdropUser(code)
            } else {
                this.setState({
                    isAlert: true,
                    message: 'Invalid url',
                    style: "danger"
                })
            }
        // }

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            if (props && props.reguser && props.reguser.statusCode === 200) {
                if (props && props.reguser && props.reguser.data && props.reguser.data.authCredentias && props.reguser.data.authCredentias.accessToken) {
                    props.resetRegAirdrop()
                    props.getUserProfile()
                    if(props.reguser && props.reguser.data && props.reguser.data.permanentId){
                        history.push(`/art/${props.reguser.data.permanentId}?airdropInvitee`)
                    }else{
                        history.push('/airdrops')
                    }
                    return {
                        
                        submitted: false,
                        isSuccess: true,
                        isAlert: false,
                        message: '',
                        style: '',
                        code: '',
                        isError: false,
                        isVerified: false
                    }

                }


            }
            if ((props && props.failedReg && props.failedReg.length > 0)) {
                // props.resetRegFailure()
                props.resetregUserFailed()
                if(isWebLogin()){
                    history.push('/airdrops')
                }else{
                    if(props.failedReg && (props.failedReg.toLowerCase().includes('invite id expired'))){
                        return {
                            isVerified : true,
                            isError : true,
                            verifiedMes : 'Invite already accepted, Please login'
                        }
                    }else if(props.failedReg && (props.failedReg.toLowerCase().includes('has been disabled'))){
                        return {
                            isVerified : false,
                            isError : true,
                            verifiedMes : props.failedReg
                        }
                    }else{
                        return {
                        
                            isVerified : false,
                            isError : true,
                            verifiedMes : props && props.failedReg
    
                        }
                    }
                }
                
                
                    
                
            }

        }
    }
    componentDidUpdate(props, state) {
       
        if ((props && props.failedVerify)) {
            props.resetVerifyUserFailed()
            this.gotoTop()
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
            this.gotoTop()

        }

    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
   
 
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    
    gotoLogin = () => {
        history.push('/login')
    }
    
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        return (
            <Fragment>
                <Helmet>
                    <title>Sign Up</title>
                    <meta name="title" content="Sign Up"></meta>
                    <meta name="description" content={`Create a new account on ${process.env.REACT_APP_MARKETPLACE}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {(!(this.state.isVerified) && (this.state.isError)) ? 
                (this.state.verifiedMes && this.state.verifiedMes.includes('disable') && this.state.verifiedMes.includes('art')) ? 
                
                <div className="container-fluid text-center">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="error-template userdisableview">
                                        <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                        <h3 className="mb-2 usernamelilac text-uppercase">{this.state.verifiedMes}</h3>

                                    </div>
                                </div>

                            </div>
                        </div> : (this.state.verifiedMes && this.state.verifiedMes.includes('disable') && this.state.verifiedMes.includes('user')) ? <CommonDisableError/> : 
                <div className="container detailWallet">
                    <div className="card py-5 my-5">
                        <div className="card-body">
                            <div className="text-center">

                            <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                 <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                             </svg>
                                <h3 className="mt-3">{this.state.verifiedMes} </h3>
                            </div>
                        </div>
                    </div>
                </div> :
                 ''}
                {!(this.state.isVerified) && !(this.state.isError) && <div className="container detailWallet">
                    <div className="card py-5 my-5">
                        <div className="card-body">
                            <div className="text-center">
                            <h3 className="mt-3">Please wait</h3>

                                <div className="dotsloading">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <h3 className="mt-3">Logging in, Redirecting to NFT claim page </h3>
                            </div>
                        </div>
                    </div>
                </div>}
                {(this.state.isVerified && this.state.isError) &&
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">{this.state.verifiedMes} </h3>
                                    <div className="form-group text-center mt-4">
                                        <button type="button" className="btn btn-primary px-5" onClick={this.gotoLogin}>Login</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reguser: state.airdrop.saveAirdropUser,
        failedReg: state.airdrop.saveAirdropUserFailed
    }
}

const actionCreators = {
    registerAirdropUser: airdropActions.autoLoginAirdropUser,
    resetRegAirdrop: airdropActions.resetLoginAirdropUser,
    resetregUserFailed: airdropActions.resetLoginAirdropUserFailed,
    getUserProfile: userAction.getUsers,



}

const connectAirdropInvitePage = connect(mapStateToProps, actionCreators)(AirdropInvitePage)

export { connectAirdropInvitePage as AirdropInvitePage }