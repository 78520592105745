import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'


class CommonDisableError extends Component{
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
    
    
   
    render(){

        return(
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="error-template userdisableview">
                            <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                            <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED</h3>
                            <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4>
                           
                            <p className="mb-0">The Team</p>
                            <p className="usernamelilac">DisruptArt</p>


                        </div>
                    </div>

                </div>
            </div>
           
         
        )
    }
}
const mapStateToProps = (state) => {
    return {


    }
}
const actionCreator = {
   
}
const connectCommonDisableError = connect(mapStateToProps,actionCreator)(CommonDisableError)
export {connectCommonDisableError as CommonDisableError}