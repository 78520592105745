import React, { Component, createFactory, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { adminActions, categoryActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';

const validations = {
    maxLength: 15,
    minLength: 3,
    maxCategoryLimit: 10
}
class AddBadges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            badge: '',
            imageName: '',
            imgPreview: ''
        }
    }
    componentDidMount() {

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    static getDerivedStateFromProps(props, state) {
        if (props && props.addedBadgeSuccess && props.addedBadgeSuccess.statusCode === 200) {
            props.resetAddBadge()
            props.getAllBadges()
            return {
                image: null,
                badge: '',
                imageName: '',
                imgPreview: ''
            }
        }
        
        if ((props && props.updatedBadgeSuccess && props.updatedBadgeSuccess.statusCode === 200)) {
            props.resetUpdateBadgesSuccess()
            props.getAllBadges()
            return {
                image: null,
                badge: '',
                imageName: '',
                imgPreview: ''
            }
        }
    }

    handleBadgeName = (e) => {
        let regex = /^[a-z0-9\s]+$/i

        if ((e.target.value && e.target.value.length <= (validations.maxLength))) {
            if (regex.test(e.target.value)) {
                this.setState({
                    badge: e.target.value
                })
            }
        } else {
            this.setState({
                badge: e.target.value.slice(0, (validations.maxLength))
            })
        }
    }
    handleClassname = (e) => {
        let regex = /(^$)|^[a-z0-9-\s]+$/i
        if (regex.test(e.target.value)) {
            this.setState({
                classname: e.target.value
            })
        }
    }

    checkLength = (value) => {
        if ((value && value.length) >= (validations.minLength) && (value && value.length) <= (validations.maxLength)) {
            return true
        } else {
            return false
        }
    }
    checkIconLength = (value) => {
        if ((value && value.length) >= (validations.minLength)) {
            return true
        } else {
            return false
        }
    }
    fileProgress = async (e) => {
        let uploadFile = e.target.files[0]
        const types = ['image/png', 'image/jpeg', 'image/webp']
        let err = '';


        const sizeLimit = 2 * (1e+6);


        if (types.every(type => uploadFile && uploadFile.type !== type)) {

            err += uploadFile.type + ' is not a supported format\n';
            toast.error(err)

        }
        else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += 'Maximum supported file size is 2 MB';
            toast.error(err)

        }
        else if (uploadFile) {


            if (err === '') {
                let reader = new FileReader();
                let file = uploadFile
                reader.onloadend = () => {
                    this.setState({
                        image: file,
                        imageName: file.name,
                        imgPreview: reader.result,

                    })
                }

                if (uploadFile && uploadFile) {
                    reader.readAsDataURL(file)

                }
            }

        }

    }
   
    handleSubmit = (e) => {
        e.preventDefault()
        if ((this.state.badge)) {
            if (this.checkLength(this.state.badge)) {
                if (this.state.image && this.state.imageName !== '') {
                    let formData = new FormData()
                    formData.append("badgeName", this.state.badge)

                    formData.append("badge", this.state.image)
                    console.log(formData)
                    this.props.addBadge(formData)

                } else {
                    toast.error('Please upload badge image')
                }
            } else {
                toast.error(`Badge name must be Min ${validations.minLength} - Max ${validations.maxLength} characters`)
            }
        } else {
            toast.error("Please enter badge name")
        }


    }

    render() {
        const { updatedId } = this.state
        return (
            <Fragment>

                <div className="purchasebox p-2 mb-3">
                    <div className="card">
                        <div className="card-body">

                            <form onSubmit={this.handleSubmit}>

                                <div className='row'>
                                    <div className="form-group mb-2 col-md-5 ">
                                        <label htmlFor="badge" className="font-weight-bold" >Enter badge name</label>
                                        <div className="input-group dollorsec mb-2">
                                            <input type="text" className="form-control" id="badge" value={this.state.badge} onChange={this.handleBadgeName} />

                                        </div>
                                        <p className="small warn-mes">
                                            <i className="fas fa-info-circle"></i>&nbsp;
                                            Min {validations.minLength} characters and Max {validations.maxLength} characters<br />
                                            {/* <i className="fas fa-info-circle"></i>&nbsp;
                                                Max limit {validations.maxCategoryLimit} badge<br /> */}
                                            <i className="fas fa-info-circle"></i>&nbsp;
                                            Special characters not allowed<br />
                                        </p>
                                    </div>
                                    <div className="form-group mb-2 col-md-5 ">
                                        <label htmlFor="classname" className="font-weight-bold" >Upload badge image</label>
                                        <div className="input-group dollorsec mb-2">
                                            <input type="file" id="pfile1" onChange={this.fileProgress} className="inp-box" accept="image/jpeg, image/png, image/gif, image/webp,video/mp4, video/webm, audio/mpeg, audio/wav, .wav" />
                                            <label htmlFor="pfile1" className="btn btn-primary-fill w-100 ">Browse File</label>
                                            <img src={this.state.imgPreview} className="w-50" />
                                        </div>
                                        <div className="row mb-3 align-items-center">
                                            <div className="col-sm-6"><div className="mandatory-req">Maximum File Size <strong>2 MB</strong></div></div>
                                            <div className="col-sm-6"> <div className="mandatory-req brdnft">Supported format for Image (JPG, JPEG, PNG, WEBP)</div></div>
                                        </div>


                                    </div>
                                    <div className="form-group mb-2 col-md-2">
                                        <label className="font-weight-bold">&nbsp; </label>
                                        
                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary ">Add</button>
                                            </div>
                                    </div>


                                </div>
                            </form>

                        </div>
                    </div>
                </div>








            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        addedBadgeSuccess: state.adminaccount.addedBadge,
        updatedBadgeSuccess: state.adminaccount.updatedBadge
    }
}
const actionCreator = {
    addBadge: adminActions.addBadges,
    resetAddBadge: adminActions.resetAddBadges,
    updateBadges: adminActions.updateBadgeStatus,
    resetUpdateBadgesSuccess: adminActions.resetUpdateBadgeStatus
}
const connectAddBadges = connect(mapStateToProps, actionCreator)(AddBadges)
export { connectAddBadges as AddBadges }