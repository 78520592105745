import { mintConstants } from "../../constants";

const initialState = {
    allslots: [],
    updatedSlot: []
}
export function mintslot(state = initialState, action) {
    switch (action.type) {
        case mintConstants.GET_MINT_SLOTS:
            return {
                allslots: action.allslots,

            }
        case mintConstants.UPDATE_MINT_SLOT:
            return {
                updatedSlot: action.updatedSlot
            }

        default:
            return state
    }
}