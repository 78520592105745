import { artConstants } from "../../constants"
import { adminServices, artServices } from "services"
import PubSub from 'pubsub-js';

export const artActions = {
    uploadArt,
    resetUpload,
    resetUploadFail,
    getArts,
    resetGetArts,
    detailArt,
    notDrop,
    getArtByToken,
    getRelativeArts,
    resetRelativeArts,
    getUserArts,
    getOneUserArts,
    resetOneUserArts,
    creatorArts,
    resetCreatorArts,
    resetAllArts,
    getAllArts,
    getOwnerArts,
    getArtsForTag,
    resetGetArtsForTag,
    getArtsbyCategory,
    resetgetArtsbyCategory,
    getCategoryArtDetail,
    resetGetCategoryArtDetail,
    updateThumbnailnft,
    resetUpdateThumbnailSuccess,
    resetUpdateThumbnailFailed,
    artsVisibilitystatusChange,
    resetArtvisibilitySuccess,
    resetArtVisibilityFailed,
    editArtCategory,
    resetArtEditcategory,
    resetArtEditCategoryFailed,
    editArtTag,
    resetArtEditTags,
    resetArtEditTagsFailed,
    dapperListArtForSale,
    resetDapperListArtForSale,
    resetDapperArtForSaleFailed,
    getArtsForRelatedWallet,
    resetGetArtsForRelatedWallet,
    resetGetArtsForRelatedWalletFailed,
    getWalletAddressDetail,
    resetgetWalletAddressDetail,
    resetgetWalletAddressDetailFailed,
    getCustodialArts,
    resetAllCustodialArts,
    resetAllCustodialArtsFailed
}
function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}
function uploadArt(obj) {
    return dispatch => {
        showLoader()
        artServices.uploadArt(obj)
            .then(art => {
                console.log(art)
                if ((art && art.statusCode) === 201 || (art && art.statusCode) === 202) {
                    // toast.success(art && art.message,{position:toast.POSITION.BOTTOM_CENTER})
                    dispatch({ type: artConstants.UPLOAD_ART, art: true })
                    hideLoader()
                }
            }, error => {
                console.log('%cARt upload failed', 'font-size:20px', error)
                hideLoader()
                dispatch({ type: artConstants.FAILED_UPLOAD_ART, failedArt: error })
                // toast.error(error,{position:toast.POSITION.BOTTOM_CENTER})
                // console.log("error",error)

            })
    }
}
function resetUpload() {
    return dispatch => {
        dispatch({ type: artConstants.UPLOAD_ART, art: false })
    }
}
function resetUploadFail() {
    return dispatch => {
        dispatch({ type: artConstants.FAILED_UPLOAD_ART, failedArt: [] })
    }
}
function getArts(obj) {
    return dispatch => {
        // showLoader()
        artServices.getArtsForArtPage(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_ARTS, artsList: art, isCheckArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}
function resetGetArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_ARTS, artsList: [] })
    }
}
function detailArt(obj) {
    return dispatch => {
        showLoader()
        artServices.detailArt(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_DETAIL_ART, detailArt: art })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("detail art error",error)
            })
    }
}

function getArtByToken(obj) {
    return dispatch => {
        artServices.getArtByToken(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_ART_BY_TOKEN, tokenArts: art })
                }
            }, error => {
                // console.log("token art",error)
            })
    }
}
function getRelativeArts(id, address) {
    return dispatch => {
        // showLoader()
        artServices.getRelativeArts(id, address)
            .then(arts => {
                if (arts) {
                    dispatch({ type: artConstants.GET_REALATIVE_ARTS, relativeArts: arts })
                    // hideLoader()
                }
            }, error => {
                // hideLoader()
                // console.log("relative errro",error)
            })
    }
}
function resetRelativeArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_REALATIVE_ARTS, relativeArts: [] })
    }
}
function getUserArts(obj) {
    return dispatch => {
        // showLoader()
        artServices.getUserArts(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_USER_ARTS, userArtsList: art, isCheckUserArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}
function resetAllArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_USER_ARTS, userArtsList: [],isCheckUserArt : false })
    }
}

function getOneUserArts(obj, userId) {
    return dispatch => {
        // showLoader()
        artServices.getOneUserArts(obj, userId)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_ONE_USER_ARTS, singleUserArts: art, isCheckCollect: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}
function creatorArts(obj, userId) {
    return dispatch => {
        // showLoader()
        artServices.creatorArts(obj, userId)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_CREATOR_ARTS, creatorArts: art, isCheckCreatorArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}

function resetCreatorArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_CREATOR_ARTS, creatorArts: [], isCheckCreatorArt: false })
    }
}
function resetOneUserArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_ONE_USER_ARTS, singleUserArts: [] })
    }
}
function getAllArts(obj) {
    return dispatch => {
        showLoader()
        adminServices.getAllArts(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_ALL_ARTS, allArts: art, isCheckAdmArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}
function getOwnerArts(artId, ownerName, artName) {
    return dispatch => {
        showLoader()
        artServices.getOwnerArts(artId, ownerName, artName)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_OWNER_ARTS, ownerArt: art })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("art list err",error)
            })
    }
}
function notDrop() {
    return dispatch => {
        artServices.notDrop()
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_NOT_DROPS, notDrops: art })
                }
            }, error => {
                // console.log("not drop",error)
            })
    }
}

function getArtsForTag(tagName, queryParams) {
    console.log(`%c getArtsForTag action called with params tagNmae ${tagName}, queryParams ${JSON.stringify(queryParams)}`,'font-size:16px');
    return (dispatch) => {
        // showLoader()
        artServices.getArtsForTagName(tagName, queryParams)
            .then((art) => {
                if (art) {
                    console.log(`%c getArtsForTag action action response`,'font-size:16px', art);
                    dispatch({ type: artConstants.GET_ART_FOR_TAG, tagArtsList: art, isCheckTagArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function resetGetArtsForTag() {
    return dispatch => {
        dispatch({ type: artConstants.GET_ART_FOR_TAG, tagArtsList: [], isCheckTagArt: false })
    }
}

function getArtsbyCategory(obj){
    return (dispatch) => {
        artServices.getArtsbyCategory(obj)
            .then((art) => {
                if (art) {
                    dispatch({ type: artConstants.GET_CATEGORY_ARTS, categoryArts: art, isCheckCategoryArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function resetgetArtsbyCategory() {
    return dispatch => {
        dispatch({ type: artConstants.GET_CATEGORY_ARTS, categoryArts: [], isCheckCategoryArt: false })
    }
}
function getCategoryArtDetail(categoryName){
    return (dispatch) => {
        artServices.getCategoryArtDetail(categoryName)
            .then((art) => {
                if (art) {
                    dispatch({ type: artConstants.GET_CATEGORY_DETAIL, categoryDetail : art })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}

function resetGetCategoryArtDetail(){
    return dispatch => {
        dispatch({ type: artConstants.GET_CATEGORY_DETAIL, categoryDetail: []})

    }
}

function updateThumbnailnft(obj,artId){
    return (dispatch) => {
        showLoader()
        artServices.updateThumbnailnft(obj,artId)
        .then((art) => {
            if(art) {
                dispatch({ type : artConstants.EDIT_THUMBNAIL, thumbnailUpdated : art})
                hideLoader()
            }
        },error => {
            dispatch({ type : artConstants.EDIT_THUMBNAIL_FAILED, uploadThumbnailFailed : error})
            hideLoader()
        })
    }
}
function resetUpdateThumbnailSuccess(){
    return dispatch => {
        dispatch({ type : artConstants.EDIT_THUMBNAIL, thumbnailUpdated : [] })
    }
}
function resetUpdateThumbnailFailed(){
    return dispatch => {
        dispatch({ type : artConstants.EDIT_THUMBNAIL_FAILED, uploadThumbnailFailed : []})
    }
}
function artsVisibilitystatusChange(obj,artId){
    return (dispatch) => {
        showLoader()
        artServices.artsVisibilitystatusChange(obj,artId)
        .then((art) => {
            if(art){
                dispatch({type : artConstants.SHOW_HIDE_STATUS_ARTS_CHANGE, artStatusChangedSuccess : art})
                hideLoader()
            }
        },error => {
            dispatch({type : artConstants.SHOW_HIDE_STATUS_CHANGE_FAILED, artStatusChangedFailed : error})
            hideLoader()
        })
    }
}
function resetArtvisibilitySuccess(){
    return dispatch => {
        dispatch ({type : artConstants.SHOW_HIDE_STATUS_ARTS_CHANGE, artStatusChangedSuccess : []})
    }
}
function resetArtVisibilityFailed(){
    return dispatch => {
        dispatch({type : artConstants.SHOW_HIDE_STATUS_CHANGE_FAILED, artStatusChangedFailed : []})
    }
}
function editArtCategory(artId,obj){
    return (dispatch) => {
        showLoader()
        artServices.editArtCategory(artId,obj)
        .then((art) => {
            if(art){
                dispatch({type : artConstants.EDIT_CATEGORY, editedCategoryStatus : art})
                hideLoader()
            }
        },error =>{
            dispatch({type : artConstants.EDIT_CATEGORY_FAILED, editcategoryFailed : error})
            hideLoader()
        })
    }
}
function resetArtEditcategory(){
    return dispatch => {
        dispatch ({type : artConstants.EDIT_CATEGORY, editedCategoryStatus : []})
    }
}
function resetArtEditCategoryFailed(){
    return dispatch => {
        dispatch({type : artConstants.EDIT_CATEGORY_FAILED, editcategoryFailed : []})
    }
}
function editArtTag(artId,obj){
    return (dispatch) => {
        showLoader()
        artServices.editArtTag(artId,obj)
        .then((art) => {
            if(art){
                dispatch({type : artConstants.EDIT_TAGS, editedTags : art})
                hideLoader()
            }
        },error => {
            dispatch({type : artConstants.EDIT_TAGS_FAILED, editedTagsFailed : error})
            hideLoader()
        })
    }
}
function resetArtEditTags(){
    return dispatch => {
        dispatch({type : artConstants.EDIT_TAGS, editedTags: []})
    }
}
function resetArtEditTagsFailed(){
    return dispatch => {
        dispatch({type : artConstants.EDIT_TAGS_FAILED, editedTagsFailed : []})
    }
}
function dapperListArtForSale(data){
    return dispatch => {
        artServices.dapperListArtForSale(data)
        .then(res => {
            dispatch({type: artConstants.DAPPER_LIST_ARTS_FOR_SALE,dapperlistedArtForsale : res})
            hideLoader()
        },error => {
            dispatch({type : artConstants.DAPPER_LIST_ARTS_FOR_SALE_FAILED,dapperlistedArtSaleFailed : error})
            hideLoader()
        })
    }
}
function resetDapperListArtForSale(){
    return dispatch => {
        dispatch({type : artConstants.DAPPER_LIST_ARTS_FOR_SALE,dapperlistedArtForsale : []})
    }
}
function resetDapperArtForSaleFailed(){
    return dispatch => {
        dispatch({type : artConstants.DAPPER_LIST_ARTS_FOR_SALE_FAILED,dapperlistedArtSaleFailed : ''})
    }
}

function getArtsForRelatedWallet(address,id,obj){
    return dispatch => {
        showLoader()
        artServices.getArtsForRelatedWallet(address,id,obj)
        .then(res => {
            dispatch({type: artConstants.GET_ARTS_FOR_RELATED_WALLET,walletRelatedArts : res})
            hideLoader()
        },error => {
            dispatch({type : artConstants.GET_ARTS_FOR_RELATED_WALLET_FAILED,walletRelatedArtsFailed : error})
            hideLoader()
        })
    }
}
function resetGetArtsForRelatedWallet(){
    return dispatch => {
        dispatch({type : artConstants.GET_ARTS_FOR_RELATED_WALLET,walletRelatedArts : []})
    }
}
function resetGetArtsForRelatedWalletFailed(){
    return dispatch => {
        dispatch({type : artConstants.GET_ARTS_FOR_RELATED_WALLET_FAILED,walletRelatedArtsFailed : ''})
    }
}
function getWalletAddressDetail(id){
    return dispatch => {
        showLoader()
        artServices.getWalletAddressDetail(id)
        .then(wallet => {
            if ((wallet && wallet.statusCode) === 200) {
                dispatch({ type: artConstants.GET_WALLET_ADDRESS_DETAIL, walletAddDetail : wallet })
                hideLoader()
            }
        }, error => {
            dispatch({ type: artConstants.GET_WALLET_ADDRESS_DETAIL_FAILED, walletAddDetailFailed : error})
            hideLoader()
        })
    }
}
function resetgetWalletAddressDetail(){
    return dispatch => {
        dispatch({type : artConstants.GET_WALLET_ADDRESS_DETAIL,walletAddDetail : []})
    }
}
function resetgetWalletAddressDetailFailed(){
    return dispatch => {
        dispatch({type : artConstants.GET_WALLET_ADDRESS_DETAIL_FAILED,walletAddDetailFailed : ''})
    }
}

function getCustodialArts(obj) {
    return dispatch => {
        // showLoader()
        artServices.getCustodialArts(obj)
            .then(art => {
                if (art) {
                    dispatch({ type: artConstants.GET_CUSTODIAL_ARTS, custodialArts: art, isCheckCustodialArt: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: artConstants.GET_CUSTODIAL_ARTS_FAILED, custodialArtsFailed:error , isCheckCustodialArt: true })
            })
    }
}
function resetAllCustodialArts() {
    return dispatch => {
        dispatch({ type: artConstants.GET_CUSTODIAL_ARTS, custodialArts: [],isCheckCustodialArt : false })
    }
}
function resetAllCustodialArtsFailed() {
    return dispatch => {
        dispatch({ type: artConstants.GET_CUSTODIAL_ARTS_FAILED, custodialArtsFailed: '',isCheckCustodialArt : false })
    }
}