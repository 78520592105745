import * as fcl from "@onflow/fcl"
import { SHA3 } from 'sha3';
import * as Elliptic from 'elliptic';
import { defaultFclLimit } from "./fcl-limit";

const ec = new Elliptic.ec('p256');

function hashMsgHex(msgHex: string) {
  const sha = new SHA3(256);
  sha.update(Buffer.from(msgHex, 'hex'));
  return sha.digest();
}

function signWithKey(privateKey: string, data: string) {
  const key = ec.keyFromPrivate(Buffer.from(privateKey, 'hex'));
  const sig = key.sign(hashMsgHex(data));
  const n = 32; // half of signature length?
  const r = sig.r.toArrayLike(Buffer, 'be', n);
  const s = sig.s.toArrayLike(Buffer, 'be', n);
  return Buffer.concat([r, s]).toString('hex');
}


interface Account {
  address: string;
  publicKey: string;
  privateKey: string;
  keyId: number;
}


const buildAuthorization = ({ address, keyId, privateKey }: Account) => (
  account: any
) => ({
  ...account,
  tempId: address,
  addr: address,
  keyId: keyId,
  resolve: null,
  signingFunction: (data: any) => {
    return {
      addr: address,
      keyId: keyId,
      signature: signWithKey(privateKey, data.message),
    };
  },
});


const admin: Account = {
  address: process.env.REACT_APP_CONTRACT_ADDRESS,
  publicKey: process.env.REACT_APP_PUBLIC_KEY,
  privateKey: process.env.REACT_APP_PRIVATE_KEY,
  keyId: 0,
};



export const mintToken = async (price, title, imagestring, description) => {

  let transaction = `\
					import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                        transaction {
                                                        let minter: &${process.env.REACT_APP_CONTRACT}.Collection
                                                        let receiverAddrss : Address
                                                        prepare(acct: AuthAccount) {

                                                            // Return early if the account already has a collection
                                                            if acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath) == nil {

                                                                // Create a new empty collection
                                                                let collection <- ${process.env.REACT_APP_CONTRACT}.createEmptyCollection()

                                                                // save it to the account
                                                                acct.save(<-collection, to: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath)

                                                                // create a public capability for the collection
                                                                acct.link<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(
                                                                        `+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath,
                                                                        target: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath
                                                                        )
                                                            } 

                                                            // borrow a reference to the NFTMinter resource in storage
                                                            self.minter = acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath)
                                                                ?? panic("Could not borrow a reference to the NFT minter")

                                                            self.receiverAddrss = acct.address

                                                       }
                                                       execute {
                                                                // Borrow the recipient's public NFT collection reference
                                                                let receiver = getAccount(self.receiverAddrss)
                                                                    .getCapability(`+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath)
                                                                    .borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>()
                                                                    ?? panic("Could not get receiver reference to the NFT Collection")

                                                                // Mint the NFT and deposit it to the recipient's collection
                                                                self.minter.Mint(recipient: receiver, content:"`+ imagestring + `", name:"` + title + `", description:"` + description + `")
                                                        }
                                         }`



  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    const { transactionId } = await fcl.send([
      fcl.transaction(transaction),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(defaultFclLimit),
    ])

    // A Promise is used to get the transaction details only after the 
    // said transaction is sealed. This promise doesnot have a reject
    // condition. It can be added if necessary.
    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }
}

export const multipleMintToken = async (price, title, imagestring, description, edition) => {

  let transaction = `\
					import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                        transaction {
                                                        let minter: &${process.env.REACT_APP_CONTRACT}.Collection
                                                        let receiverAddrss : Address
                                                        prepare(acct: AuthAccount) {

                                                            // Return early if the account already has a collection
                                                            if acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath) == nil {

                                                                // Create a new empty collection
                                                                let collection <- ${process.env.REACT_APP_CONTRACT}.createEmptyCollection()

                                                                // save it to the account
                                                                acct.save(<-collection, to: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath)

                                                                // create a public capability for the collection
                                                                acct.link<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(
                                                                        `+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath,
                                                                        target: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath
                                                                        )
                                                            } 

                                                            // borrow a reference to the NFTMinter resource in storage
                                                            self.minter = acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: `+process.env.REACT_APP_CONTRACT+`.disruptArtStoragePath)
                                                                ?? panic("Could not borrow a reference to the NFT minter")

                                                            self.receiverAddrss = acct.address

                                                       }
                                                       execute {
                                                                // Borrow the recipient's public NFT collection reference
                                                                let receiver = getAccount(self.receiverAddrss)
                                                                    .getCapability(`+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath)
                                                                    .borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>()
                                                                    ?? panic("Could not get receiver reference to the NFT Collection")

                                                                // Mint the NFT and deposit it to the recipient's collection
                                                                self.minter.GroupMint(recipient: receiver, content:"`+ imagestring + `", description:"` + description + `", name:"` + title + `", edition: ` + edition + `)
                                                        }
                                         }`



  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    const { transactionId } = await fcl.send([
      fcl.transaction(transaction),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(defaultFclLimit),
    ])

    // A Promise is used to get the transaction details only after the 
    // said transaction is sealed. This promise doesnot have a reject
    // condition. It can be added if necessary.
    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }
}



export const getMultipleMintLimit = async () => {

  let script = ` \

                import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main():UInt {                   
                  return ${process.env.REACT_APP_CONTRACT}.editionLimit
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(script)]);
    return {
      status: 200,
      data: { "message": "Multiple mint token limit", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}

export const createSetUpT = async () => {
  const transaction = `
  // DisruptArt.io NFT Token Smart Contract
// Owner     : DisruptionNowMedia www.disruptionnow.com
// Developer : www.BLAZE.ws
// Version: 0.0.4


// Transaction to mint the multiple tokens

import DisruptArt from ${process.env.REACT_APP_CONTRACT_ADDRESS}


transaction() {
    
    prepare(acct: AuthAccount) {

        // Return early if the account already has a collection
        if acct.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath) == nil {

            // Create a new empty collection
            let collection <- DisruptArt.createEmptyCollection()

            // save it to the account
            acct.save(<-collection, to: DisruptArt.disruptArtStoragePath)

            // create a public capability for the collection
            acct.link<&{DisruptArt.DisruptArtCollectionPublic}>(
                    DisruptArt.disruptArtPublicPath,
                    target: DisruptArt.disruptArtStoragePath
                    )
        }

   }


}
  `;
  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    console.log('Account setup txn \n', transaction);
    const { transactionId } = await fcl.send([
      fcl.transaction(transaction),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(defaultFclLimit),
    ])

    console.log(transactionId)

    // A Promise is used to get the transaction details only after the 
    // said transaction is sealed. This promise doesnot have a reject
    // condition. It can be added if necessary.
    const result = new Promise((resolve) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "setup creation done",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}
export const createSetUp = async () => {
  const mode = process.env.REACT_APP_ENVIRONMENT_MODE
  let transaction;
  const testnetSetup = `
  // DisruptArt.io NFT Token Smart Contract
// Owner     : DisruptionNowMedia www.disruptionnow.com
// Developer : www.BLAZE.ws
// Version: 0.0.1

import DisruptArt from 0x439c2b49c0b2f62b
import NonFungibleToken from 0x631e88ae7f1d7c20
import MetadataViews from 0x631e88ae7f1d7c20

transaction() {
    
    prepare(acct: AuthAccount) {

        // Return early if the account already has a collection
        if acct.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath) == nil {

            // Create a new empty collection
            let collection <- DisruptArt.createEmptyCollection()

            // save it to the account
            acct.save(<-collection, to: DisruptArt.disruptArtStoragePath)

            // create a public capability for the collection
            acct.link<&DisruptArt.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, DisruptArt.DisruptArtCollectionPublic}>(
                    DisruptArt.disruptArtPublicPath,
                    target: DisruptArt.disruptArtStoragePath
                    )
        }

   }


}`;
const mainnetSetup = `
// DisruptArt.io NFT Token Smart Contract
// Owner     : DisruptArt www.Disrupt.art
// Developer : www.BLAZE.ws
// Version: 0.0.1
// Desc: This transaction initilizes DisruptArt Storage Path & Collection to new Dapper wallet accounts.

import DisruptArt from 0xcd946ef9b13804c6
import NonFungibleToken from 0x1d7e57aa55817448
import MetadataViews from 0x1d7e57aa55817448

transaction() {
    
    prepare(acct: AuthAccount) {

        // Return early if the account already has a collection
        if acct.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath) == nil {

            // Create a new empty collection
            let collection <- DisruptArt.createEmptyCollection()

            // save it to the account
            acct.save(<-collection, to: DisruptArt.disruptArtStoragePath)

            // create a public capability for the collection
            acct.link<&DisruptArt.Collection{NonFungibleToken.CollectionPublic, MetadataViews.ResolverCollection, DisruptArt.DisruptArtCollectionPublic}>(
                    DisruptArt.disruptArtPublicPath,
                    target: DisruptArt.disruptArtStoragePath
                    )
        }

   }


}`;
  if(mode === "production"){
    transaction = mainnetSetup
  }else{
    transaction = testnetSetup
  }
  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    console.log('New Account setup txn \n', transaction,mode);
    const { transactionId } = await fcl.send([
      fcl.transaction(transaction),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(defaultFclLimit),
    ])

    console.log(transactionId)

    // A Promise is used to get the transaction details only after the 
    // said transaction is sealed. This promise doesnot have a reject
    // condition. It can be added if necessary.
    const result = new Promise((resolve) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "setup creation done",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}
