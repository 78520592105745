import { DraggableList } from "components/draggable-list/draggable-list";
import React, { Fragment } from "react";
import { toast, Zoom } from "react-toastify";
import { adminServices, discoverServices } from "services";
import PubSub from 'pubsub-js';
import { creatorStoreActions, discoverActions } from "store/actions";
import { connect } from "react-redux";
import { DraggableSecList } from "./DraggableSecList";
import { debounce } from "utils";
import { flowDecimal, getUniqueUUID } from "helpers";
import moment from "moment";
import { AutoInput } from "./AutoInput";
import history from "helpers/history";
import { StoreSectionForm } from "./StoreSectionform";
import { StoreSections } from "./Sections";
import { Helmet } from "react-helmet";

class AddCollections extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spotLightArts0: [],
            spotLightArts1: [],
            spotLightArts2: [],

            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            isLoading: false,
            elementId: Date.now().toString(),
            isSectionAvalbl: false,
            section1: '',
            section2: '',
            section3: '',
            isFetched: false,
            sections:[]

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.getStoreProfile()
        this.gotoTop()
    }
   
    getStoreProfile = () => {
        if (getUniqueUUID()) {
            this.props.getUserStoreProfile(getUniqueUUID())
        }
    }
    componentDidUpdate(prevProps, state) {

        if (
            (this.props && this.props.usersList && this.props.usersList.statusCode === 200) &&
            (this.props.usersList !== (prevProps && prevProps.usersList))) {
            if (this.props && this.props.usersList && this.props.usersList.data) {
                let data = this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.store
                this.setState({
                    storeId: data.id,
                });
                if (data && data.id) {
                    let obj = {
                        category : 'Drops'
                    }
                    this.props.getsections(data.id,obj)
                } else {
                    toast.error("Something went wrong")
                }
            }
        }
        if (
            (this.props && this.props.sections && this.props.sections.statusCode === 200) &&
            (this.props.sections !== (prevProps && prevProps.sections))) {
            this.props.resetGetsections()
            this.setState({
                isFetched: true,
            })

            if (this.props && this.props.sections && this.props.sections.data && this.props.sections.data.sections) {
                if (this.props.sections.data.sections.length > 0) {
                    this.setState({
                        isSectionAvalbl: true,
                        sections: this.props.sections.data.sections,
                        section1: this.props.sections.data.sections[0] && this.props.sections.data.sections[0].name,
                        section2: this.props.sections.data.sections[1] && this.props.sections.data.sections[1].name,
                        section3: this.props.sections.data.sections[2] && this.props.sections.data.sections[2].name
                    })
                }
            }
        }
        if (this.props &&
            this.props.getsectionfailed &&
            this.props.getsectionfailed.length > 0 &&
            (this.props.getsectionfailed !== prevProps.getsectionfailed)
        ) {
            this.props.resetGetSectionFailed()
            toast.error(this.props.getsectionfailed, { transition: Zoom })

        }


    }
    gotoForm = () => {
        history.push('/store/sectionform')
    }
    updateSectionName = () => {
        this.getStoreProfile()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    render() {
        const { spotLightArts, isSectionAvalbl, sections, isFetched } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>Store section</title>
                    <meta name="title" content={`Store section`}></meta>
                    <meta name="description" content={`Store section`}></meta>
                </Helmet>
                {(isSectionAvalbl) ?
                    <div className="container-fluid">
                        <div className="ds-intit">
                            <h1 className="mb-0">Drop Collections</h1>
                        </div>


                        <div className="row mb-3">
                            {sections && sections.length > 0 && sections.map((items,index) => (
                                <StoreSections data={items} key={index} updateSectionName={this.updateSectionName}/>
                            ))}
                            
                        </div>

                    </div> : isFetched ? <StoreSectionForm storeId={this.state.storeId} /> : ''}
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        maxCounts: state.discover.maxCounts,
        selectedArts: state.creatorStore.searchedArts,
        getsectionfailed: state.creatorStore.storeSectionFailed,
        sections: state.creatorStore.storeSections,
        usersList: state.creatorStore.profile,
        sectionartsList: state.creatorStore.sectionArts,

    }
}
const actionCreator = {
    getDiscoverLimits: discoverActions.getDiscoverMaxCountLimit,
    resetGetLimits: discoverActions.resetGetDiscoverSettings,
    searchArts: creatorStoreActions.searchArts,
    resetSearchedArts: creatorStoreActions.resetSearchArts,
    resetSearchedArtsFailed: creatorStoreActions.resetSearchArtsFailed,

    getartsforsection1: creatorStoreActions.getSectionArts,
    resetsection1arts: creatorStoreActions.resetGetSectionArts,


    getsections: creatorStoreActions.getStoreSection,
    resetGetsections: creatorStoreActions.resetGetStoreSection,
    resetGetSectionFailed: creatorStoreActions.resetGetStoreSectionFailed,

    getUserStoreProfile: creatorStoreActions.getStoreCreatorProfile,
    resetGetProfile: creatorStoreActions.resetGetStoreCreatorProfile,
    resetGetprofileFailed: creatorStoreActions.resetGetStoreCreatorProfilefailed


}
const connectAddCollections = connect(mapStateToProps, actionCreator)(AddCollections)
export { connectAddCollections as AddCollections }