import React from "react";
import PubSub from 'pubsub-js';
import { userAction } from "store/actions";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import history from "helpers/history";
import { getCurrentUserId, isWebLogin, localAirdropPage, localArtPage, localDropPage } from "helpers";
import { Redirect } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { adminServices } from "services";
import { AccountVerification } from "./otp";
import { configPath } from "../../config";

var timeout;
class AddUserAccountDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            account_no: '',
            bank_name: '',
            ifsc_code: '',
            bank_address: '',
            holder_name: '',
            swift_code: '',
            routing_no: '',
            du_account_no: '',
            du_bank_name: '',
            du_holder_name: '',
            du_swift_code: '',
            du_routing_no: '',
            isEditable: true,
            isTooLength: false,
            isBankNameTooLength: false,
            tabIndex: 0,
            filteredAddress: [],
            isOtpVerified: false,
            isUsLocation : '',
            du_isUsLocation : '',
            du_bank_address: '',
            isBankAddTooLength: false,
            country_name: '',
            du_country_name: '',
            isCountryTooLength: false,
            phn_no: '',
            du_phn_no: '',
            isPersonalEditable: true,
            personalsubmitted: false,
            payoutMethod : 'bank',
            du_payoutMethod : 'bank',
            paypalEmail : '',
            flowAddress : '',
            du_paypalEmail: '',
            du_flowAddress: ''
        };
        this.maxLength = 100
        this.minLength = 3
        this.accminLength = 1
        this.minAddLength = 1
        this.maxAddLength = 200
        this.minCountryLength = 1
        this.maxCountryLength = 100
        this.minphnno = 8
        this.maxphnno = 16
        this.payoutMethods = {
            BANK : 'bank',
            PAYPAL : 'paypal',
            FLOW : 'flow_wallet'
        }
    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.getAccountDetail()
        this.authorizePage()
    }
    getAccountDetail = () => {
        this.props.getDetail()
    }
    componentDidUpdate(prevProps, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

        if (this.props && this.props.addedSuccess && this.props.addedSuccess.statusCode === 200 &&
            (this.props.addedSuccess !== (prevProps.addedSuccess))) {
            this.props.resetAddedBankAccount()
            this.getAccountDetail()
            this.setState({
                isAlert: true,
                message: "Your account details were updated",
                style: "success",
                isEditable: true,
                isTooLength: false,
                isBankNameTooLength: false,
                submitted: false,
                isBankAddTooLength: false,
                isCountryTooLength: false

            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.addedFailed && this.props.addedFailed.length > 0 &&
            (this.props.addedFailed !== (prevProps.addedFailed))) {
            this.props.resetaddedAccountFailed()
            this.setState({
                isAlert: true,
                message: this.props.addedFailed,
                style: "danger",
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.userDetail && this.props.userDetail.statusCode === 200 &&
            (this.props.userDetail !== (prevProps.userDetail))) {
            this.props.resetgetDetail()
            if (this.props.userDetail && this.props.userDetail.data && this.props.userDetail.data.user && (this.props.userDetail.data.user.bankName || this.props.userDetail.data.user.paypalEmail || this.props.userDetail.data.user.payoutWalletAddress)) {
                this.setState({
                    account_no: this.props.userDetail.data.user.bankAccountNumber ? this.props.userDetail.data.user.bankAccountNumber : '',
                    bank_name: this.props.userDetail.data.user.bankName ? this.props.userDetail.data.user.bankName : '',
                    holder_name: this.props.userDetail.data.user.bankAccountHolderName ? this.props.userDetail.data.user.bankAccountHolderName : '',
                    swift_code: this.props.userDetail.data.user?.swiftCode,
                    routing_no: this.props.userDetail.data.user.routingNumber ? this.props.userDetail.data.user.routingNumber.toString() : '',
                    du_account_no: this.props.userDetail.data.user.bankAccountNumber ? this.props.userDetail.data.user.bankAccountNumber : '',
                    du_bank_name: this.props.userDetail.data.user.bankName ? this.props.userDetail.data.user.bankName : '',
                    du_holder_name: this.props.userDetail.data.user.bankAccountHolderName ? this.props.userDetail.data.user.bankAccountHolderName : '',
                    du_swift_code: this.props.userDetail.data.user?.swiftCode,
                    du_routing_no: this.props.userDetail.data.user.routingNumber ? this.props.userDetail.data.user.routingNumber.toString() : '',
                    isUsLocation: this.props.userDetail.data.user.isLocalBankAccount ? 'us' : 'ous',
                    du_isUsLocation: this.props.userDetail.data.user.isLocalBankAccount ? 'us' : 'ous',
                    payoutMethod : this.props.userDetail.data.user.payoutMethod ? this.props.userDetail.data.user.payoutMethod : this.payoutMethods.BANK,
                    du_payoutMethod : this.props.userDetail.data.user.payoutMethod ? this.props.userDetail.data.user.payoutMethod : this.payoutMethods.BANK,
                    paypalEmail : this.props.userDetail.data.user.paypalEmail ? this.props.userDetail.data.user.paypalEmail : '',
                    du_paypalEmail : this.props.userDetail.data.user.paypalEmail ? this.props.userDetail.data.user.paypalEmail : '',
                    flowAddress : this.props.userDetail.data.user.payoutWalletAddress ? this.props.userDetail.data.user.payoutWalletAddress : '',
                    du_flowAddress : this.props.userDetail.data.user.payoutWalletAddress ? this.props.userDetail.data.user.payoutWalletAddress : '',
                })
            }
            
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.getuserDetailFailed && this.props.getuserDetailFailed.length > 0 &&
            (this.props.getuserDetailFailed !== (prevProps.getuserDetailFailed))) {
            this.props.resetgetDetailFailed()
            this.setState({
                isAlert: true,
                message: `Failed to get data : ${this.props.getuserDetailFailed}`,
                style: "danger",
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.getuserWalletsFailed && this.props.getuserWalletsFailed.length > 0 &&
            (this.props.getuserWalletsFailed !== (prevProps.getuserWalletsFailed))) {
            this.props.resetGetWalletsFailed()
            this.setState({
                isAlert: true,
                message: `Failed to get wallets : ${this.props.getuserWalletsFailed}`,
                style: "danger",
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if ((this.props && this.props.getuserWallets && this.props.getuserWallets.statusCode === 200) &&
            (this.props.getuserWallets !== (prevProps.getuserWallets))) {
            this.props.resetGetWallets()
            if(this.props.getuserWallets && this.props.getuserWallets.data && this.props.getuserWallets.data.walletAccounts){
                this.setState({
                    filteredAddress : this.props.getuserWallets.data.walletAccounts
                })
            }
            
        }
        if (this.props && this.props.addedPersonalDetail && this.props.addedPersonalDetail.statusCode === 200 &&
            (this.props.addedPersonalDetail !== (prevProps.addedPersonalDetail))) {
            this.props.resetAddPersonalDetail()
            this.getUserPersonalDetail()
            this.setState({
                isAlert: true,
                message: "Your personal details were updated",
                style: "success",
                isPersonalEditable: true,
                submitted: false,
                isBankAddTooLength: false,
                isCountryTooLength: false

            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.addedPersonalDetailFailed && this.props.addedPersonalDetailFailed.length > 0 &&
            (this.props.addedPersonalDetailFailed !== (prevProps.addedPersonalDetailFailed))) {
            this.props.resetAddPersonalDetailFailed()
            this.setState({
                isAlert: true,
                message: this.props.addedPersonalDetailFailed,
                style: "danger",
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.personalDetail && this.props.personalDetail.statusCode === 200 &&
            (this.props.personalDetail !== (prevProps.personalDetail))) {
            this.props.resetGetPersonalDetail()
            if (this.props.personalDetail && this.props.personalDetail.data && this.props.personalDetail.data.user) {
                this.setState({
                    bank_address: this.props.personalDetail.data.user.address ? this.props.personalDetail.data.user.address : '',
                    du_bank_address: this.props.personalDetail.data.user.address ? this.props.personalDetail.data.user.address : '',
                    country_name: this.props.personalDetail.data.user.countryName ? this.props.personalDetail.data.user.countryName : '',
                    du_country_name: this.props.personalDetail.data.user.countryName ? this.props.personalDetail.data.user.countryName : '', 
                    phn_no: this.props.personalDetail.data.user.phoneNumber ? this.props.personalDetail.data.user.phoneNumber : '',
                    du_phn_no: this.props.personalDetail.data.user.phoneNumber ? this.props.personalDetail.data.user.phoneNumber : '', 
                })
            }
            
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props && this.props.personalDetailFailed && this.props.personalDetailFailed.length > 0 &&
            (this.props.personalDetailFailed !== (prevProps.personalDetailFailed))) {
            this.props.resetGetPersonalDetailFailed()
            this.setState({
                isAlert: true,
                message: `Failed to get data : ${this.props.personalDetailFailed}`,
                style: "danger",
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }


    onAccountNameChange = (e) => {
        if (e.target.value) {
            if (this.checkMaxLength(e.target.value)) {
                this.setState({
                    holder_name: (e.target.value), isTooLength: false
                }, () => {
                })
            } else {
                this.setState({
                    holder_name: (e.target.value.slice(0, this.maxLength)), isTooLength: true
                }, () => {
                })
            }

        } else {
            this.setState({
                holder_name: '', isTooLength: false
            }, () => {
            })
        }
    }
    onAccountNumberChange = (e) => {
        // if((e.target.value >= 0 && !isNaN(e.target.value))){
        this.setState({ account_no: (e.target.value) }, () => {
        })
        // }


    }
    onBankNameChange = (e) => {
        if (e.target.value) {
            if (this.checkMaxLength(e.target.value)) {
                this.setState({
                    bank_name: (e.target.value), isBankNameTooLength: false
                }, () => {
                })
            } else {
                this.setState({
                    bank_name: (e.target.value.slice(0, this.maxLength)), isBankNameTooLength: true
                }, () => {
                })
            }

        } else {
            this.setState({
                bank_name: '', isBankNameTooLength: false
            }, () => {
            })
        }


    }
    onBankAddressChange = (e) => {
        if (e.target.value) {
            if (this.checkMaxAddLength(e.target.value)) {
                this.setState({
                    bank_address: (e.target.value), isBankAddTooLength: false
                }, () => {
                })
            } else {
                this.setState({
                    bank_address: (e.target.value.slice(0, this.maxAddLength)), isBankAddTooLength: true
                }, () => {
                })
            }

        } else {
            this.setState({
                bank_address: '', isBankAddTooLength: false
            }, () => {
            })
        }
      

    }
    onSwiftCodeChange = (e) => {
        this.setState({ swift_code: (e.target.value).toUpperCase() }, () => {
            this.checkSwiftCode(this.state.swift_code)
        })

    }
    onRoutingNumberChange = (e) => {
        if ((e.target.value >= 0 && !isNaN(e.target.value))) {
            this.setState({ routing_no: (e.target.value) }, () => {
                this.isValidRoutingNumber(this.state.routing_no)
            })
        }


    }
    onCountryNameChange = (e) => {
        if (e.target.value) {
            if (this.checkMaxCountryLength(e.target.value)) {
                this.setState({
                    country_name: (e.target.value), isCountryTooLength: false
                }, () => {
                })
            } else {
                this.setState({
                    country_name: (e.target.value.slice(0, this.maxCountryLength)), isCountryTooLength: true
                }, () => {
                })
            }

        } else {
            this.setState({
                country_name: '', isCountryTooLength: false
            }, () => {
            })
        }
      

    }
    onPhnoChange = (e) => {
        const regex1 =/^(?=.*\d)[\d ]+$/
        const regex = /^\+?\d+(?:[ ]?\d+)*$/
        // if(regex1.test(e.target.value)){
            this.setState({ phn_no: (e.target.value)}, () => {
                this.validatephonenumber(this.state.phn_no)
            }) 
        // }else{
        //     this.setState({ phn_no: ''})
        // }
        
    }
    checkFullName = (value) => {
        if (((value && value.length) <= (this.maxLength)) && ((value && value.length) >= (this.minLength))) {
            return true
        } else {
            return false
        }
    }
    checkAccountNumber = (value) => {
        if (((value && value.length) <= (this.maxLength)) && ((value && value.length) >= (this.accminLength))) {
            return true
        } else {
            return false
        }
    }
    checkMaxLength = (value) => {
        if (((value && value.length) <= (this.maxLength))) {
            return true
        } else {
            return false
        }
    }
    checkAddress = (value) => {
        if (((value && value.length) <= (this.maxAddLength)) && ((value && value.length) >= (this.minAddLength))) {
            return true
        } else {
            return false
        }
    }
    checkMaxAddLength = (value) => {
        if (((value && value.length) <= (this.maxAddLength))) {
            return true
        } else {
            return false
        }
    }
    checkcountryName = (value) => {
        if (((value && value.length) <= (this.maxCountryLength)) && ((value && value.length) >= (this.minCountryLength))) {
            return true
        } else {
            return false
        }  
    }
    checkMaxCountryLength = (value) => {
        if (((value && value.length) <= (this.maxCountryLength))) {
            return true
        } else {
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }





    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }


    checkSwiftCode = (value) => {
        var swiftReg = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
        if (swiftReg.test(value)) {
            return true
        } else {
            return false
        }
        // var expression = /[0-9]{4}-[0-9]{4}-[0-9]{2}-[0-9]{10}/;
        // var swift1 = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
        // var swift2 = /^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$/

    }
    isValidRoutingNumber = (n) => {
        if (n.length !== 9 || isNaN(n)) {
            return false;
        }
        else {
            var checksumTotal = (7 * (parseInt(n.charAt(0), 10) + parseInt(n.charAt(3), 10) + parseInt(n.charAt(6), 10))) +
                (3 * (parseInt(n.charAt(1), 10) + parseInt(n.charAt(4), 10) + parseInt(n.charAt(7), 10))) +
                (9 * (parseInt(n.charAt(2), 10) + parseInt(n.charAt(5), 10) + parseInt(n.charAt(8), 10)));
            if (checksumTotal % 10 === 0) {
                return true
            } else {
                return false
            }
        }
    }
    gotoCreator = () => {
        if (this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.userName) {
            if (this.state.roleId && this.state.roleId == 1) {
                history.push(`/creator/@${this.props.usersList.data.userName && encodeURIComponent(this.props.usersList.data.userName)}`, { obj: { ownuserName: this.props.usersList.data.userName } })
            } else if (this.state.roleId && this.state.roleId == 2) {
                history.push(`/collector/@${this.props.usersList.data.userName && encodeURIComponent(this.props.usersList.data.userName)}`)
            }
        } else {
            history.goBack()
        }

    }
    handletoEdit = () => {

        this.setState({
            isEditable: false,
        })
    }
    handletoPersonalEdit = () => {
        this.setState({
            isPersonalEditable: false
        })
    }
    handletoCancel = () => {
        this.setState({
            isEditable: true,
            submitted: false,
            account_no: this.state.du_account_no,
            bank_name: this.state.du_bank_name,
            holder_name: this.state.du_holder_name,
            swift_code: this.state.du_swift_code,
            routing_no: this.state.du_routing_no,
            isTooLength: false,
            isBankNameTooLength: false,
            isUsLocation: this.state.du_isUsLocation,
            flowAddress: this.state.du_flowAddress,
            paypalEmail: this.state.du_paypalEmail
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { account_no, bank_name, holder_name, swift_code, routing_no, isUsLocation, country_name, phn_no, bank_address, paypalEmail, flowAddress, payoutMethod } = this.state
        if(payoutMethod === (this.payoutMethods.BANK)){
            if (holder_name && bank_name && account_no && routing_no) {
                if (this.checkFullName(holder_name)) {
                    if(isUsLocation){
                        if (this.isValidRoutingNumber(routing_no)) {
                            if (this.checkAccountNumber(account_no)) {
                                if (this.checkFullName(bank_name)) {
                                    let obj = {
                                        bankAccountHolderName: holder_name,
                                        bankName: bank_name,
                                        bankAccountNumber: account_no,
                                        routingNumber: routing_no,
                                        payoutMethod : this.payoutMethods.BANK,
                                    }
                                    if(bank_address){
                                        if(this.checkAddress(bank_address)){
                                            obj["address"] = bank_address
                                        }else{
                                            this.setState({
                                                isAlert: true,
                                                message: `Address must be min ${this.minAddLength} characters and max ${this.maxAddLength} characters`,
                                                style: "danger",
                                            })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                            return false
                                        }
                                    }else{
                                        obj["address"] = null
                                    }
                                    if(phn_no){
                                        obj['phoneNumber'] = phn_no
                                    }else{
                                        obj['phoneNumber'] = null
                                    }
                                    if(country_name){
                                        if(this.checkcountryName(country_name)){
                                            obj['countryName'] = country_name
                                        }else{
                                            this.setState({
                                                isAlert: true,
                                                message: `Country name must be max ${this.maxAddLength} characters`,
                                                style: "danger",
                                            })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                            return false
                                        }
                                    }else{
                                        obj['countryName'] = null
                                    }
                                    if(isUsLocation === "ous"){
                                        if (this.checkSwiftCode(swift_code)) {
                                            obj["isLocalBankAccount"] = false
                                            obj['swiftCode'] = swift_code
                                            this.props.addUserDetail(JSON.stringify(obj))
    
                                        } else {
                                            this.setState({
                                                isAlert: true,
                                                message: "Please enter valid swift code",
                                                style: "danger",
                                            })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        }
                                    }else{
                                        obj["isLocalBankAccount"] = true
                                        obj['swiftCode'] = null
                                        this.props.addUserDetail(JSON.stringify(obj))
    
                                    }
                                    
                                } else {
                                    this.setState({
                                        isAlert: true,
                                        message: `Bank name must be min ${this.minLength} characters and max ${this.maxLength} characters`,
                                        style: "danger",
                                    })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                }
                            } else {
                                this.setState({
                                    isAlert: true,
                                    message: `Account number must be min ${this.accminLength} characters and max ${this.maxLength} characters`,
                                    style: "danger",
                                })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            }
                        } else {
                            this.setState({
                                isAlert: true,
                                message: "Please enter valid routing number",
                                style: "danger",
                            })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }else{
                        this.setState({
                            isAlert: true,
                            message: "Please select your bank location",
                            style: "danger",
                        })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                    
                } else {
                    this.setState({
                        isAlert: true,
                        message: "Please enter valid account holder name as it appear in bank account",
                        style: "danger",
                    })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            } else {
                this.setState({
                    isAlert: true,
                    message: "Mandatory fields are missing",
                    style: "danger",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        }
        

    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab
        })
    }
    gotoArts = () => {

        // this.setState({ offSet: 0 }, () => {
        //     this.getUserArts()
        // })
    }
    getWallets = () => {
        this.props.getWallets()
    }
    authorizePage = () => {
        var hours = 1; // to clear the localStorage after 1 hour

        var now = new Date().getTime();

        const itemStr = JSON.parse(localStorage.getItem("otpsetuptimeout"))
        if (itemStr) {
            if ((now - itemStr) > hours * 60 * 60 * 1000) {
                localStorage.removeItem('otpsetuptimeout')
                this.setState({ isOtpVerified: true })
            } else {
                this.setState({ isOtpVerified: true })
            }
        } else {
            this.setState({ isOtpVerified: true })
        }
    }
    updateErrorProp = (message) => {
        this.setState({ isAlert: true, message: message, style: "danger" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    updateSuccessProp = (message) => {
        this.setState({ isAlert: true, message: message, style: "success", isOtpVerified: true })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    handleLocation = (e) => {
        if(e.target.value){
            this.setState({isUsLocation : e.target.value,swift_code: this.state.du_swift_code})
        }
    }
    validatephonenumber = (inputtxt) => {
        console.log(inputtxt)
        var phoneno = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/;
        if(phoneno.test(inputtxt)) {
            console.log(true)
          return true;
        }
        else {
            console.log(false)
          return false;
        }
      }
      handlePersonalSubmit = (e) => {
        e.preventDefault()
        this.setState({ personalsubmitted: true })
        const { country_name, phn_no, bank_address } = this.state
        const addressValidation = bank_address ? (bank_address && this.checkAddress(bank_address)) ? true : false : true
        const countryValidation = country_name ? (country_name && this.checkcountryName(country_name)) ? true : false : true
        const phnnoValidation = (phn_no) ? true : false

            if(bank_address || phn_no || country_name){
                let obj = {
                
                }
                
                if(addressValidation && phnnoValidation && countryValidation){
                    if(bank_address){
                        obj["address"] = bank_address
                    }else{
                        obj["address"] = null
                    }
                    if(phn_no){
                        obj['phoneNumber'] = phn_no
                    }else{
                        obj['phoneNumber'] = null
                    }
                    if(country_name){
                        obj['countryName'] = country_name
                    }else{
                        obj['countryName'] = null
                    }
                    this.props.addUserPersonalDetail(JSON.stringify(obj))
                }else{
                    if(!this.checkAddress(bank_address)){
                        this.setState({
                            isAlert: true,
                            message: `Address must be min ${this.minAddLength} characters and max ${this.maxAddLength} characters`,
                            style: "danger",
                        })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        return false
                    }else if(!this.checkcountryName(country_name)){
                        this.setState({
                            isAlert: true,
                            message: `Country name must be max ${this.maxAddLength} characters`,
                            style: "danger",
                        })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        return false
                    }
                }
            }else{
                this.setState({
                    isAlert: true,
                    message: `Please enter your personal details`,
                    style: "danger",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }       
            
        
      }
      handletoCancelPersonal = () => {
        this.setState({
            personalsubmitted: false,
            bank_address: this.state.du_bank_address,
            isBankAddTooLength: false,
            country_name: this.state.du_country_name,
            isCountryTooLength: false,
            phn_no: this.state.du_phn_no,
            isPersonalEditable: true
        })
    }
    handlePayoutMethods = (e) => {
        
        if(e.target.value){
            this.setState({payoutMethod : e.target.value, isEditable : true})
        }
        
        this.handletoCancel()
    }
    onPaypalmailChange = (e) => {
     
        this.setState({paypalEmail: (e.target.value)})
    }
    handleSubmitPaypalDetail = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const {payoutMethod,paypalEmail} = this.state
        if(payoutMethod === (this.payoutMethods.PAYPAL)){
            if(paypalEmail){
                if(this.checkemail(paypalEmail)){
                    let obj = {
                        payoutMethod : this.payoutMethods.PAYPAL,
                        paypalEmail : paypalEmail
                    }
                    this.props.addUserDetail(JSON.stringify(obj))

                }else{
                    this.setState({
                        isAlert: true,
                        message: "Please enter valid email address",
                        style: "danger",
                    })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            }else{
                this.setState({
                    isAlert: true,
                    message: "Please enter your paypal email address",
                    style: "danger",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        }
    }
    onFlowAddressChange = (e) => {
        this.setState({flowAddress: (e.target.value)})

    }
    handleSubmitFlowAddress = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const {payoutMethod,flowAddress} = this.state
        if(payoutMethod === (this.payoutMethods.FLOW)){
            if(flowAddress){
                if(this.checkwalletaddress(flowAddress)){
                    let obj = {
                        payoutMethod : this.payoutMethods.FLOW,
                        payoutWalletAddress : flowAddress
                    }
                    this.props.addUserDetail(JSON.stringify(obj))

                }else{
                    this.setState({
                        isAlert: true,
                        message: "Please enter valid flow address",
                        style: "danger",
                    })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            }else{
                this.setState({
                    isAlert: true,
                    message: "Please enter your flow address",
                    style: "danger",
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        }
    }
    checkwalletaddress = (value) => {
        var addressValidator = /^0x[a-fA-F0-9]/g
        if (addressValidator.test(value)) {
            return true
        } else {
            return false
        }
    }
    getUserPersonalDetail = () => {
        this.props.getPersonalDetail()
    }
    render() {
        return (

            (isWebLogin() ? <div className="">
                <Helmet>
                    <title>Account Detail</title>
                    <meta name="title" content={`Update account detail`}></meta>
                    <meta name="description" content={`Update account detail`}></meta>
                </Helmet>

                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>
                </div>}
                <div className="container mt-5">
                    <div className="tabpills">
                        <div className="row">
                            <div className="col-md-3">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <span className="nav-link active cursor-pointer" id="pills-home-tab" data-toggle="pill" data-target="#pills-home"  role="tab" aria-controls="pills-home" aria-selected="true">PAYOUT METHODS</span>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <span className="nav-link cursor-pointer" id="pills-wallet-tab" data-toggle="pill" data-target="#pills-wallet" role="tab" aria-controls="pills-wallet" aria-selected="false" onClick={this.getWallets}>WALLETS</span>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <span className="nav-link cursor-pointer" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={this.getUserPersonalDetail}>PERSONAL DETAILS</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-9">
                                <div className="tab-content purchasebox " id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        {this.state.isOtpVerified ? 
                                        
                                        <div className="row">
                                        <div className="col-md-12 text-right">

                                            {this.state.isEditable ? <button className="btn btn-primary editcls" type="button" onClick={this.handletoEdit}>
                                                <i className="fa fa-pencil" aria-hidden="true" ></i>
                                            </button> :
                                                <button className="btn btn-primary editcls" type="button" onClick={this.handletoCancel}>
                                                    <i className="fa fa-times" aria-hidden="true" ></i>
                                                </button>}

                                        </div></div> : ''}


                                        {this.state.isOtpVerified ? <div className="pb-5">
                                            <div className="p-3 reglogfrm">
                                                <div className="row">
                                                    <div className="form-group col-md-3">
                                                        <input type="radio" className="form-check-input mr-3" id="bank" value={"bank"} onClick={this.handlePayoutMethods} checked={this.state.payoutMethod === "bank"} />
                                                        <label className="form-check-label" htmlFor="bank">Bank</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="radio" className="form-check-input mr-3" id="paypal" value={"paypal"} onClick={this.handlePayoutMethods} checked={this.state.payoutMethod === "paypal"} />
                                                        <label className="form-check-label" htmlFor="paypal">Paypal</label>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <input type="radio" className="form-check-input mr-3" id="flow" value={this.payoutMethods.FLOW} onClick={this.handlePayoutMethods} checked={this.state.payoutMethod === this.payoutMethods.FLOW} />
                                                        <label className="form-check-label" htmlFor="flow">Flow</label>
                                                    </div>
                                                </div>
                                                {(this.state.payoutMethod === "bank") ? <form>
                                                    
                                                    <div className="form-group">
                                                        <label className=" mb-2" htmlFor="holder_name">Account Holder Name</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-user" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className={this.state.isTooLength ? "form-control descdan" : 'form-control '}
                                                                id="holder_name"
                                                                aria-describedby="holder_name"
                                                                placeholder="Enter name as it appears on your bank"
                                                                value={this.state.holder_name}
                                                                onChange={this.onAccountNameChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>
                                                        {!this.state.submitted && !this.state.isEditable && <div className="usernamelilac small mt-1 text-right">Min {this.minLength} - Max {this.maxLength}</div>}

                                                        {this.state.submitted && !this.state.holder_name && <div className="mandatory">{`Min ${this.minLength} - Max ${this.maxLength} characters`}</div>}
                                                        {this.state.submitted && this.state.holder_name && !this.checkFullName(this.state.holder_name) && <div className="mandatory">{`Min ${this.minLength} - Max ${this.maxLength} characters`}</div>}
                                                    </div>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="bank_name">Bank Name</label>
                                                        <div className="input-group mb-2 ">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-bank" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className={(this.state.isBankNameTooLength) ? "form-control descdan" : 'form-control'}
                                                                id="bank_name"
                                                                aria-describedby="bank_name"
                                                                placeholder="Enter your bank name"
                                                                value={this.state.bank_name}
                                                                onChange={this.onBankNameChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>
                                                        {!this.state.submitted && !this.state.isEditable && <div className="usernamelilac small mt-1 text-right">Min {this.minLength} - Max {this.maxLength}</div>}

                                                        {this.state.submitted && !this.state.bank_name && <div className="mandatory">{`Min ${this.minLength} - Max ${this.maxLength} characters`}</div>}
                                                        {this.state.submitted && this.state.bank_name && !this.checkFullName(this.state.bank_name) && <div className="mandatory">{`Min ${this.minLength} - Max ${this.maxLength} characters`}</div>}


                                                    </div>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="account_no">Account No</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-credit-card" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="account_no"
                                                                aria-describedby="account_no"
                                                                placeholder="Enter your bank account no"
                                                                value={this.state.account_no}
                                                                onChange={this.onAccountNumberChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>
                                                        {this.state.submitted && !this.state.account_no && <div className="mandatory">{`Enter your bank account number`}</div>}
                                                        {this.state.submitted && this.state.account_no && !this.checkAccountNumber(this.state.account_no) && <div className="mandatory">{`Min ${this.accminLength} - Max ${this.maxLength} characters`}</div>}

                                                    </div>
                                                    
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="routing_no">Routing No</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-credit-card" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="routing_no"
                                                                aria-describedby="routing_no"
                                                                placeholder="123456789"
                                                                value={this.state.routing_no}
                                                                onChange={this.onRoutingNumberChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>
                                                        {this.state.submitted && !this.state.routing_no && <div className="mandatory">Required field</div>}
                                                        {this.state.routing_no && !this.isValidRoutingNumber(this.state.routing_no) && <div className="mandatory">Please confirm your format</div>}

                                                    </div>
                                                    <div className="form-group ">
                                                    <label className=" mb-2 mt-2" >Where is your bank account located? </label>
                                                        <input type="radio" className="form-check-input mr-3" id="us" value={"us"} onClick={this.handleLocation} checked={this.state.isUsLocation === "us"} disabled={this.state.isEditable}/>
                                                        <label className="form-check-label" htmlFor="us">United States or Canada</label>
                                                    </div>
                            
                                                    <div className="form-group ">
                                                        <input type="radio" className="form-check-input mr-3" id="ous" value={"ous"} onClick={this.handleLocation} checked={this.state.isUsLocation === "ous"} disabled={this.state.isEditable}/>
                                                        <label className="form-check-label" htmlFor="ous">Outside United States and Canada</label>
                                                    </div>
                                                    {(this.state.isUsLocation === "ous") ? <div className="form-group">
                                                        <label className=" mb-2" htmlFor="swift_code">Swift Code</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-credit-card" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="swift_code"
                                                                aria-describedby="swift_code"
                                                                placeholder="AAAA-BB-CC-XXX"
                                                                value={this.state.swift_code}
                                                                onChange={this.onSwiftCodeChange}
                                                                readOnly={this.state.isEditable} />


                                                        </div>
                                                        {!this.state.isEditable && <div className="usernamelilac small mt-1 text-right">Ex: HKBAAU2SXXX</div>}
                                                        {this.state.submitted && !this.state.swift_code && <div className="mandatory">Required field</div>}
                                                        {this.state.swift_code && !this.checkSwiftCode(this.state.swift_code) && <div className="mandatory">Please confirm your format</div>}

                                                    </div> : ''}
                                                    
                                                    
                                                    
                                                    
                                                    {!this.state.isEditable ? <div className="text-center col-md-12 ">
                                                        <button className="btn btn-primary-fill px-4 mt-3" type="button" onClick={this.handleSubmit} disabled={this.state.isEditable}>Save Changes</button>
                                                    </div> : ''}


                                                </form> : (this.state.payoutMethod === "paypal") ? <form>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="paypalEmail">Paypal Email</label>
                                                        <div className="input-group mb-2 ">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-paypal" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="email"
                                                                className={'form-control'}
                                                                id="paypalEmail"
                                                                aria-describedby="paypalEmail"
                                                                placeholder="Enter your paypal email"
                                                                value={this.state.paypalEmail}
                                                                onChange={this.onPaypalmailChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>

                                                        {this.state.submitted && !this.state.paypalEmail && <div className="mandatory">{`Email required`}</div>}
                                                        {this.state.submitted && this.state.paypalEmail && !this.checkemail(this.state.paypalEmail) && <div className="mandatory">{`Please enter valid paypal email address`}</div>}


                                                    </div>
                                                    {!this.state.isEditable ? <div className="text-center col-md-12 ">
                                                        <button className="btn btn-primary-fill px-4 mt-3" type="button" onClick={this.handleSubmitPaypalDetail} disabled={this.state.isEditable}>Save Changes</button>
                                                    </div> : ''}
                                                    </form> : <form>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="flowAddress">Flow Address</label>
                                                        <div className="input-group mb-2 ">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-wallet" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                className={'form-control'}
                                                                id="flowAddress"
                                                                aria-describedby="flowAddress"
                                                                placeholder="Enter your flow address"
                                                                value={this.state.flowAddress}
                                                                onChange={this.onFlowAddressChange}
                                                                readOnly={this.state.isEditable} />

                                                        </div>

                                                        {this.state.submitted && !this.state.flowAddress && <div className="mandatory">{`Flow address required`}</div>}
                                                        {this.state.submitted && this.state.flowAddress && !this.checkwalletaddress(this.state.flowAddress) && <div className="mandatory">{`Please enter valid flow address`}</div>}


                                                    </div>
                                                    {!this.state.isEditable ? <div className="text-center col-md-12 ">
                                                        <button className="btn btn-primary-fill px-4 mt-3" type="button" onClick={this.handleSubmitFlowAddress} disabled={this.state.isEditable}>Save Changes</button>
                                                    </div> : ''}
                                                    </form>}
                                            </div>
                                        </div> : <AccountVerification updateSuccessProp={(v) => this.updateSuccessProp(v)} updateErrorProp={(e) => this.updateErrorProp(e)} />}

                                    </div>
                                    <div className="tab-pane fade" id="pills-wallet" role="tabpanel" aria-labelledby="pills-wallet-tab">

                                       
                                            {this.state.filteredAddress && this.state.filteredAddress.length > 0 &&
                                                <div className="uawallet">

                                                    {this.state.filteredAddress && this.state.filteredAddress.map((data, index, array) => {

                                                        return <div className="mb-5">
                                                                                                                  
                                                        
                                                        <div className="row align-items-center" key={index}> 
                                                        <div className="col-md-4">
                                                                <div className="purchasebox mb-3 uawt">
                                                                {/* <span className="">Wallet Provider</span> */}
                                                                <h4 className="mt-2">{(data.walletProvider && data.walletProvider.toLowerCase().includes('dapper')) ? 
                                                                <img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="Disrupt Now" className="logoaw" /> : 
                                                                (data.walletProvider && data.walletProvider.toLowerCase().includes('blocto')) ? 
                                                                <img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="Disrupt Now" className="logoaw" /> : 
                                                                (data.walletProvider && data.walletProvider.toLowerCase().includes('disrupt')) ? 
                                                                <img src={`${process.env.REACT_APP_BASEPATH}/logo192.png`} alt="Disrupt Now" className="logoaw" /> : ''}{data.walletProvider}</h4>

                                                            </div>
                                                            </div>
                                                             <div className="col-md-8">  
                                                             {/* <span className="">Wallet Address</span> */}
                                                                <h3 className="usernamelilac mt-2 mb-3">{data.walletAddress}</h3>
                                                                </div>                                                        
                                                            
                                                           
                                                            {/* {data && data.isActive ? <div className="col-sm-4">
                                                             <span className="usernamelilac">Active wallet address</span>
                                                            </div> : ''} */}
                                                        </div>
                                                        
                                                        </div>                                                       
                                                        
                                                    })}

                                                  
                                                </div>}
                                       
                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    
                                        
                                        <div className="row">
                                        <div className="col-md-12 text-right">

                                            {this.state.isPersonalEditable ? <button className="btn btn-primary editcls" type="button" onClick={this.handletoPersonalEdit}>
                                                <i className="fa fa-pencil" aria-hidden="true" ></i>
                                            </button> :
                                                <button className="btn btn-primary editcls" type="button" onClick={this.handletoCancelPersonal}>
                                                    <i className="fa fa-times" aria-hidden="true" ></i>
                                                </button>}

                                        </div></div> 
                                    <div className="pb-5">
                                            <div className="p-3 reglogfrm">

                                                <form>
                                                    <div className="">
                                                    <h4 className=" mb-2">Personal Details</h4>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="bank_address">Address</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-address-card" aria-hidden="true"></i></span>
                                                            </div>
                                                            <textarea 
                                                                className={(this.state.isBankAddTooLength) ? "form-control descdan" : 'form-control'}
                                                                id="bank_address" 
                                                                aria-describedby="bank_address" 
                                                                placeholder="Enter your address" 
                                                                value={this.state.bank_address} 
                                                                onChange={this.onBankAddressChange} 
                                                                readOnly={this.state.isPersonalEditable}/>

                                                        </div>
                                                        {!this.state.personalsubmitted && !this.state.isPersonalEditable && <div className="usernamelilac small mt-1 text-right">Max {this.maxAddLength}</div>}

                                                        {this.state.personalsubmitted && this.state.bank_address && !this.checkAddress(this.state.bank_address) && <div className="mandatory">{`Min ${this.minAddLength} - Max ${this.maxAddLength} characters`}</div>}
                                                    </div>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="country_name">Country Name</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-flag" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input 
                                                                type="text" 
                                                                className={(this.state.isCountryTooLength) ? "form-control descdan" : 'form-control'}
                                                                id="country_name" 
                                                                aria-describedby="country_name" 
                                                                placeholder="Enter country name" 
                                                                value={this.state.country_name} 
                                                                onChange={this.onCountryNameChange} 
                                                                readOnly={this.state.isPersonalEditable}/>

                                                        </div>
                                                        {!this.state.personalsubmitted && !this.state.isPersonalEditable && <div className="usernamelilac small mt-1 text-right">Max {this.maxCountryLength}</div>}

                                                        {this.state.personalsubmitted && this.state.country_name && !this.checkcountryName(this.state.country_name) && <div className="mandatory">{`Min ${this.minCountryLength} - Max ${this.maxCountryLength} characters`}</div>}
                                                    </div>
                                                    <div className="form-group ">
                                                        <label className=" mb-2" htmlFor="phn_no">Phone number</label>
                                                        <div className="input-group mb-2">
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-primary igt-custom" id="basic-addon2"><i className="fa fa-phone" aria-hidden="true"></i></span>
                                                            </div>
                                                            <input 
                                                                type="text" 
                                                                className={'form-control'}
                                                                id="phn_no" 
                                                                aria-describedby="phn_no" 
                                                                placeholder="Enter your phone number" 
                                                                value={this.state.phn_no} 
                                                                onChange={this.onPhnoChange} 
                                                                readOnly={this.state.isPersonalEditable}/>

                                                        </div>
                                                        {!this.state.isPersonalEditable && <div className="usernamelilac small mt-1 text-left">Please enter your Ph.no with country code (Ex: +12025550123)</div>}

                                                    </div>
                                                    </div>
                                                    
                                                    
                                                    
                                                    {!this.state.isPersonalEditable ? <div className="text-center col-md-12 ">
                                                        <button className="btn btn-primary-fill px-4 mt-3" type="button" onClick={this.handlePersonalSubmit} disabled={this.state.isPersonalEditable}>Save Changes</button>
                                                    </div> : ''}


                                                </form>
                                            </div>
                                        </div>
                                            
                                       
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>



                    

                </div>
            </div> : <Redirect to="/login" />)
        );
    }
}

const mapStateToProps = (state) => {
    return {
        addedSuccess: state.user.bankDetailAdded,
        addedFailed: state.user.addedBankDetailFailed,
        userDetail: state.user.gotBankDetail,
        getuserDetailFailed: state.user.getBankDetailFailed,
        getuserWallets: state.user.userwallets,
        getuserWalletsFailed: state.user.userwalletsFailed,
        personalDetail: state.user.gotPersonalDetail,
        personalDetailFailed: state.user.getPersonalDetailFailed,
        addedPersonalDetail: state.user.personalDetailAdded,
        addedPersonalDetailFailed: state.user.addPersonalDetailFailed
    }
}

const actionCreators = {
    addUserDetail: userAction.addBankDetail,
    resetAddedBankAccount: userAction.resetaddbankDetail,
    resetaddedAccountFailed: userAction.resetaddbankDetailFailed,
    getDetail: userAction.getBankDetail,
    resetgetDetail: userAction.resetgetbankDetail,
    resetgetDetailFailed: userAction.resetgetbankDetailFailed,
    getWallets: userAction.getuserWalletAccDetail,
    resetGetWallets: userAction.resetgetuserwalletaccdetail,
    resetGetWalletsFailed: userAction.resetgetuserwalletaccdetailFailed,
    addUserPersonalDetail: userAction.addPersonalDetail,
    resetAddPersonalDetail: userAction.resetaddpersonalDetail,
    resetAddPersonalDetailFailed: userAction.resetaddpersonalDetailFailed,
    getPersonalDetail: userAction.getPersonalDetail,
    resetGetPersonalDetail: userAction.resetgetPersonalDetail,
    resetGetPersonalDetailFailed: userAction.resetgetPersonalDetailFailed
}

const connectAddUserAccountDetail = connect(mapStateToProps, actionCreators)(AddUserAccountDetail)

export { connectAddUserAccountDetail as AddUserAccountDetail }