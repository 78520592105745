import { configPath } from "../../config"
import history from "helpers/history"
import React from 'react'

export const babMintCount = 9005
export const gotoCreator = (owner) => {
    if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
        history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
    } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
        history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
    }
}
const checkTotalMintedTokens = (totalTokens) => {
    if (isNaN(totalTokens)) {
        if (totalTokens.includes('-')) {
            return 1
        }
    }
    if ((totalTokens) && Number.isInteger(totalTokens)) {

        return totalTokens
    } else {
        return 1
    }
}
export const getCreatorImage = (item) => {
    if ((item && item.isBab)) {
        return <img src={`../${configPath.imageAssets}/jim-jones.jpg`} alt="" />
    } else {
        return <img src={item && item.creator && item.creator.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${item && item.creator && item.creator.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => gotoCreator(item && item.creator)} />
    }

}

export const getCreatorName = (artList) => {
    if ((artList && artList.isBab)) {
        return <h2 className={"usernameClick"} >{(`@Jim Jones`)}</h2>

    } else {
        return <h2 className={artList && artList.creator && artList.creator.roleId && artList.creator.roleId == 1 ? "usernameClick" : "usernameClick"} onClick={() => gotoCreator(artList && artList.creator)}>{artList && artList.creator && artList.creator != null && artList.creator.userName != null ? ((artList.creator.userName && artList.creator.userName.includes('@')) ? artList.creator.userName : (`@${artList.creator.userName}`)) : artList && artList.creatorWalletAddress != null ? artList.creatorWalletAddress : ''}</h2>
    }
}

export const showCreatorName = () => {
    return <span className="usernamelilac">{`@Jim Jones`}</span>

}

export const showTotalEditions = (detail) => {
    if ((detail && detail.isBab)) {
        return <h3 className=" usernamelilac">{babMintCount} Editions</h3>
    } else {
        return <h3 className=" usernamelilac">{((detail && detail.toatalMintedTokens) ? (checkTotalMintedTokens(detail && detail.toatalMintedTokens)) : 1)} {(detail && detail.toatalMintedTokens) > 1 ? 'Editions' : 'Edition'}</h3>
    }
}

export const showTotalEditionsforDrops = (detail,drops) => {
    if ((drops && drops.isBab)) {
        return <h3 className=" usernamelilac">{babMintCount} Editions</h3>
    } else {
        return <h3 className=" usernamelilac">{((detail && detail.totalMintedTokens) ? (checkTotalMintedTokens(detail && detail.totalMintedTokens)) : 1)} {(detail && detail.totalMintedTokens) > 1 ? 'Editions' : 'Edition'}</h3>
    }
}

export const showTotalEditionsAfterOf = (detail,availableDropsCount,drops) => {
    if ((drops && drops.isBab)) {
        return <h3 className=" usernamelilac">1 <span className='text-white'>of </span> {babMintCount} <span className="totedition">Editions</span></h3>
    } else {
        return <h3 className=" usernamelilac">{availableDropsCount} <span className='text-white'>of </span>
            {((detail && detail.totalMintedTokens) ?
                (checkTotalMintedTokens(detail.totalMintedTokens)) : 1)}
            <span className="totedition">{(((checkTotalMintedTokens(detail.totalMintedTokens)) > 1 ? " Total Editions" : " Total Edition"))}</span>
        </h3>
    }
}

export const ownershipEdition = (detail,ownerShipDetail) => {
    if((detail && detail.isBab)){
        return <span><strong className='usernamelilac'>{(ownerShipDetail && ownerShipDetail.ownedCopies)}</strong>&nbsp;of <strong className='usernamelilac'>{babMintCount}</strong> Editions collected by </span>
    }else{
        return <span><strong className='usernamelilac'>{(ownerShipDetail && ownerShipDetail.ownedCopies)}</strong>&nbsp;of <strong className='usernamelilac'>{(checkTotalMintedTokens(detail && detail.toatalMintedTokens))}</strong> {(checkTotalMintedTokens(detail && detail.toatalMintedTokens)) > 1 ? " Editions" : " Edition"} collected by</span>
    }
}
