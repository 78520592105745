import React from "react";
import { ArtUpload } from "./art-upload";
import { Gallery } from "./gallery";

export class Art extends React.Component {
    render() {
        return (
            <div className="gallery artpage">
               
                    <ArtUpload urlProp={this.props}/>
                
            </div>
        );
    }
}