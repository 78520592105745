import { discoverConstants } from "../../constants"
import { adminServices, discoverServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const discoverActions = {
    getAllSpotLightArts,
    resetSpotLight,
    getAllFeatureArts,
    resetFeatureArts,
    getAllFeatureCreators,
    resetFeatureCreators,
    getAllFeatureCollectors,
    resetFeatureCollectors,
    getDiscoverMaxCountLimit,
    updateDiscoverSettings,
    resetGetDiscoverSettings,
    resetUpdateDiscoverSettings,
    getAllRandomCreators,
    resetRandomCreators,
    resetRandomCreatorsFailed,
    getAllShowCaseCreators,
    resetShowCaseCreators,
    resetShowCaseCreatorsFailed,
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAllSpotLightArts(obj){
    return dispatch => {
        // showLoader()
        discoverServices.getAllSpotLightArts(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_SPOTLIGHT_ARTS,spotArts:art})
                // hideLoader()
            }
        },error => {
            hideLoader()
            // console.log("art list err",error)
        })
    }
}
function resetSpotLight(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_SPOTLIGHT_ARTS,spotArts:[]})
    }
}
function getAllFeatureArts(obj){
    return dispatch => {
        // showLoader()
        discoverServices.getAllFeatureArts(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_FEATURE_ARTS,featureArts:art})
                hideLoader()
            }
        },error => {
            // hideLoader()
            // console.log("art list err",error)
        })
    }
}
function resetFeatureArts(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_FEATURE_ARTS,featureArts:[]})
    }
}
function getAllFeatureCreators(obj){
    return dispatch => {
        // showLoader()
        discoverServices.getAllFeatureCreators(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_FEATURE_CREATOR,featureCreators:art})
                hideLoader()
            }
        },error => {
            // hideLoader()
            // console.log("art list err",error)
        })
    }
}
function resetFeatureCreators(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_FEATURE_CREATOR,featureCreators:[]})
    }
}
function getAllFeatureCollectors(obj){
    return dispatch => {
        // showLoader()
        discoverServices.getAllFeatureCollectors(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_FEATURE_COLLECTOR,featureCollectors:art})
                // hideLoader()
            }
        },error => {
            hideLoader()
            // console.log("art list err",error)
        })
    }
}

function resetFeatureCollectors(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_FEATURE_COLLECTOR,featureCollectors:[]})
    }
}

function getDiscoverMaxCountLimit(){
    return dispatch => {
        adminServices.getDiscoverMaxCountLimit()
        .then(counts => {
            if(counts){
                dispatch({type : discoverConstants.GET_DISCOVER_MAXIMUM_COUNT_LIMIT,maxCounts : counts})
            }
        },error => {
            toast.error(error)
        })
    }
}
function resetGetDiscoverSettings(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_DISCOVER_MAXIMUM_COUNT_LIMIT,maxCounts:[]})
    }
}
function updateDiscoverSettings(obj){
    return dispatch => {
        showLoader()
        adminServices.updateDiscoverSettings(obj)
        .then(discover => {
            if(discover){
                dispatch({type : discoverConstants.UPDATE_DISCOVER_SETTINGS,updatedSettings : discover})
            }
            hideLoader()
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetUpdateDiscoverSettings(){
    return dispatch => {
        dispatch({type:discoverConstants.UPDATE_DISCOVER_SETTINGS,updatedSettings:[]})
    }
}
function getAllRandomCreators(obj){
    return dispatch => {
        // showLoader()
        discoverServices.getAllRandomCreators(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_RANDOM_CREATORS,randomCreators:art})
                hideLoader()
            }
        },error => {
            dispatch({type:discoverConstants.GET_RANDOM_CREATORS_FAILED,randomCreatorsFailed:error})
            hideLoader()
        })
    }
}
function resetRandomCreators(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_RANDOM_CREATORS,randomCreators:[]})
    }
}
function resetRandomCreatorsFailed(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_RANDOM_CREATORS_FAILED,randomCreatorsFailed:[]})
    }
}
function getAllShowCaseCreators(obj){
    return dispatch => {
        discoverServices.getAllShowCaseCreators(obj)
        .then(art => {
            if(art){
                dispatch({type:discoverConstants.GET_ALL_SHOW_CASE_CREATORS,showcaseCreators:art})
                hideLoader()
            }
        },error => {
            dispatch({type:discoverConstants.GET_ALL_SHOW_CASE_CREATORS_FAILED,showcaseCreatorsFailed:error})
            hideLoader()
        })
    }
}
function resetShowCaseCreators(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_ALL_SHOW_CASE_CREATORS,showcaseCreators:[]})
    }
}
function resetShowCaseCreatorsFailed(){
    return dispatch => {
        dispatch({type:discoverConstants.GET_ALL_SHOW_CASE_CREATORS_FAILED,showcaseCreatorsFailed:[]})
    }
}