import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import history from 'helpers/history';
import { configPath } from '../../config';
import { getCurrentUserId } from 'helpers';
import { artServices } from 'services';
import PubSub from 'pubsub-js';

class Badges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Enim egestas dolor pharetra amet ultricies eu bibendum.
            // Odio sollicitudin amet at condimentum netus cursus odio rhoncus ultricies. Malesuada non eget in feugiat donec varius. Ornare integer placerat massa tortor. Habitasse quisque dictum sapien neque ut. Iaculis diam quis odio tellus molestie faucibus ut quam. Vitae a amet egestas mollis facilisi diam aliquet.
            inputValues: [],
            isEdit: false,
            selectedBadges: [],
            listOfBadges: [],
            submitted: false,
        }
        this.minTitle = 3
        this.maxTitle = 140
        this.maxDesc = 1400
    }
    
    componentDidUpdate(props, state) {
        if (props && props.badges) {
            if (((props.badges) != (state.badges))) {
                this.setState({
                    badges: props.badges,
                    listOfBadges: props.badges,
                    isEdit: false,
                    submitted: false
                })
            }
        }
        if (props && props.selectedBadges) {
            if ((props.selectedBadges) != (state.selectedBadges)) {

                if (props.badges) {
                    for (let i = 0; i < props.selectedBadges.length; i++) {
                        const badgeId = props.selectedBadges[i].badgeId;
                        const matchingBadge = props.badges.find(item => item.id === badgeId);

                        if (matchingBadge) {
                            matchingBadge.isSelected = true
                            props.selectedBadges[i].selectedBadge = matchingBadge
                        }
                    }

                }
                this.setState({
                    selectedBadges: props.selectedBadges,
                    badges: props.badges
                })
            }
        }
    }
    handleEdit = () => {
        this.setState({
            isEdit: !this.state.isEdit,
            inputValues: this.state.selectedBadges,
            listOfBadges: this.state.badges
        })
    }

    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    isOwnerisCreator = () => {
        const { artDetail } = this.props
        if (getCurrentUserId()) {
            if (artDetail && artDetail.id) {
                if ((artDetail && artDetail.creator && artDetail.creator.id) === (getCurrentUserId())) {
                    return true

                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
    handleTitle = (e, idx) => {
        // 1. Make a shallow copy of the items
        let items = [...this.state.inputValues];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...items[idx] };
        
        if ((this.checkEmoji(e.target.value))) {
            item.title = e.target.value;
            
            items[idx] = item;
            this.setState({ inputValues: items });
            this.setState({
                isBadgeTooLength: false,
             })
        } else {
            this.props.updateBadgeFailed('Emoji not supported')
        }
        
    }
    handleDescription = (e, idx) => {
        // 1. Make a shallow copy of the items
        let items = [...this.state.inputValues];
        // 2. Make a shallow copy of the item you want to mutate
        let item = { ...items[idx] };
       

        if (((e.target.value).length <= 1400)) {
            if ((this.checkEmoji(e.target.value))) {

                item.description = e.target.value;

                items[idx] = item;
                this.setState({ inputValues: items });
                this.setState({
                   isBadgeTooLength: false,
                })
            } else {
                this.props.updateBadgeFailed('Emoji not supported')
               
            }
        } else {
            if ((this.checkEmoji(e.target.value))) {
                item.description = (e.target.value.slice(0, 1400)) 
                items[idx] = item;
                this.setState({ inputValues: items });
                this.setState({ isBadgeTooLength: true})
            } else {
                this.props.updateBadgeFailed('Emoji not supported')

            }
            
        }
    }
    handleBadgeClick = (item, idx) => {
        // let items = [...this.state.listOfBadges];
        let inputValues = [...this.state.inputValues]
        if (item.isSelected) {
            // item.isSelected = false

            const updatedData = this.state.listOfBadges.map(e => {
                if ((e.id === item.id)) {
                    return { ...e, isSelected: false }
                }
                return e
            })

            inputValues.splice(inputValues.findIndex(e => e.badgeId === item.id), 1);
            this.setState({ inputValues, listOfBadges: updatedData })
        } else {
            // item.isSelected = true
            const updatedData = this.state.listOfBadges.map(e => {
                if ((e.id === item.id)) {
                    return { ...e, isSelected: true }
                }
                return e
            })
            let obj = {
                title: '',
                description: '',
                badgeId: item.id,
                selectedBadge: item
            }

            inputValues.push(obj)
            this.setState({ inputValues, listOfBadges: updatedData }, () => {
            })

        }
        // items[idx] = item;
        // this.setState({ listOfBadges: items });
    }
    updateBadge = (e) => {
        e.preventDefault()
        console.log(this.state.inputValues)
        const { artDetail } = this.props
        let updatedbadgesArray = []
        this.setState({
            submitted: true
        })
        if (artDetail && artDetail.id) {
            // if ((artDetail && artDetail.creator && artDetail.creator.id) === (getCurrentUserId())) {
            if (this.state.inputValues && this.state.inputValues.length > 0) {
                const allFieldsFilled = this.state.inputValues.every(this.areFieldsFilled);

                if (allFieldsFilled) {
                    this.state.inputValues.map((items) => {
                        let obj = {
                            title: items.title,
                            description: items.description,
                            artId: artDetail.id,
                            badgeId: items.badgeId,
                            creatorUserId: artDetail.creator.id
                        }
                        updatedbadgesArray.push(obj)
                    })
                    let obj = {
                        badges: updatedbadgesArray
                    }
                    this.showLoader()
                    artServices.updateBadges(JSON.stringify(obj)).then(res => {
                        this.hideLoader()
                        if (res && res.statusCode === 201) {
                            this.setState({
                                isEdit: false,
                                submtted: false
                            })
                            this.props.updateBadgeSuccess()
                        } else {
                            if (res && typeof res === 'string') {
                                this.props.updateBadgeFailed(res)
                            } else {
                                this.props.updateBadgeFailed("Unhandled error occured while updating badge")
                            }
                        }
                    }).catch(e => {
                        this.hideLoader()
                        if (e && typeof e === 'string') {
                            this.props.updateBadgeFailed(e)
                        } else {
                            this.props.updateBadgeFailed("Unhandled error occured while updating badge")
                        }
                    })
                } else {
                    // this.props.updateBadgeFailed("Please enter your badge title")
                }

            }



            // }
        }
    }
    areFieldsFilled = (obj) => {
        if(obj.title.trim() !== "" && this.checkChar(obj.title.trim())){
            if(obj.description.trim() === ""){
                return true
            }else{
                if(obj.description.trim() !== "" && (this.checkDesc(obj.description.trim()) && this.checkmaxDesc(obj.description.trim()))){
                    return true
                }else{
                    return this.props.updateBadgeFailed(`Description should be Min ${this.minTitle} - Max ${this.maxDesc} characters`)

                }
            }
        } else {
            if (obj.title.trim() === "") {
                return this.props.updateBadgeFailed("Please enter your badge title")
            } else if (!this.checkChar(obj.title.trim())) {
                return this.props.updateBadgeFailed(`Title should be Min ${this.minTitle} - Max ${this.maxTitle} characters`)
            } else {
                return true
            }
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleRemoveBadges = () => {
        this.props.removeBadges(this.props.artDetail)
    }
    gotoForm = () => {
        this.setState({
            isEdit: true,
            inputValues: [],
            listOfBadges: this.state.badges
        })
    }
    checkChar = (value) => {
        if (((value && value.length) >= (this.minTitle)) && (value && value.length <= (this.maxTitle))) {
            return true
        } else {
            return false
        }
    }
    checkDesc = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkmaxDesc = (value) => {
        if ((value && value.length) <= 1400) {
            return true
        } else {
            return false
        }
    }
    badgesDetail = (items) => {
        return <div className='ubboxwon1'>
        <div className='ubboxwon'>
            <div className='ubbox active'>
                <div className='ubboximg'>
                    <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                    <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                    <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.selectedBadge && items.selectedBadge.badgePath && items.selectedBadge.badgePath.slice(1)}`} alt="" /></span>
                </div>
            </div>
            <div className='ubboxtxt'>
                <h5>{items.selectedBadge && items.selectedBadge.name}</h5>
            </div>
        </div>

        <h5>{items.title}</h5>
        <p>{items.description}</p>


    </div>
    }
    checkEmoji = (value) => {
        const regexExp = /\p{Extended_Pictographic}/gmu;

        if (regexExp.test(value)) {
            return false
        } else {
            return true
        }

    }
    render() {
        const id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id)
        const { artDetail, tabIndex, detailList
        } = this.props;
        const { inputValues, badges, selectedBadges, listOfBadges, isBadgeTooLength } = this.state

        return (

            <Fragment>
                {!(this.state.isEdit) ? <div className={(tabIndex === 3) ? "tab-pane active" : "tab-pane "} id="Badges" role="tabpanel" aria-labelledby="Badges-tab">
                    <div>

                        {(this.isOwnerisCreator() && (selectedBadges && selectedBadges.length > 0) && (badges && badges.length > 0)) ? <div className='nadedbtns' >

                            <div className='row align-items-center'>
                                <div className='col-8'><label className='mb-0'>Utility Badges</label></div>
                                <div className='col-4 text-right'>
                                    <div className='btn btn-primary-gradient btn-sm btn-icon' onClick={this.handleEdit}>
                                        <i className="fa fa-edit" ></i>
                                    </div>
                                    <div className='btn btn-primary-gradient  btn-sm btn-icon' onClick={this.handleRemoveBadges}>
                                        <i className="fa fa-trash" ></i>
                                    </div>
                                </div>
                            </div>

                        </div> : (badges && badges.length > 0) ? <div className='nadedbtns' ><div className='row align-items-center'>
                            <div className='col-8'><label className='mb-0'>Utility Badges</label></div></div></div> : ''}

                        <div className='Utility-Badges'>
                            {badges && badges.length > 0 && badges.map((items, index) => (
                                <div className={(items && items.isSelected) ? 'ubbox active' : 'ubbox'}>
                                    <div className='ubboximg'>
                                        <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                                        <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                                        <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.badgePath && items.badgePath.slice(1)}`} alt="" /></span>
                                    </div>

                                    <p title={items.name}>{items.name}</p>
                                </div>
                            ))}

                        </div>

                        {(selectedBadges && selectedBadges.length > 0) ? <div className='ubboxcont'>
                            <h5 className='usernameblue'>About Badges</h5>
                            <p className='text-white d-none'>Timeline</p>
                            <div className="text-justify biocont adshowless">
                            {(selectedBadges && selectedBadges.length > 1) ? <div className="showless">
                                    <input id="showless1" type="radio" name="showless" />
                                    <label htmlFor="showless1" className="showless1">View all badges</label>
                                    <input id="showless2" type="radio" name="showless" checked={this.state.checked} />
                                    <label htmlFor="showless2" className="showless2">Show less</label>
                                    <div className="content">
                                        <div id="showless1" className="showlesscontent">
                                            {(selectedBadges && selectedBadges.length > 0) ? selectedBadges.map((items, index) => (

                                                    this.badgesDetail(items)
                                               
                                            )) : ''}

                                        </div>
                                    </div>
                                </div> : <div className="content">
                                        <div id="showless1" className="showlesscontent">
                                            {(selectedBadges && selectedBadges.length > 0) ? selectedBadges.map((items, index) => (

                                                    this.badgesDetail(items)
                                               
                                            )) : ''}

                                        </div>
                                    </div>}
                            </div>

                        </div> : ''}

                    </div> <div className='nbaab'>
                        {/* <img src={`../../../../${configPath.imageAssets}/Badges/no-badge.png`} alt="" className='mb-3' />
                            {this.isOwnerisCreator() ? '' : <p>No Badges available</p>} */}
                        {this.isOwnerisCreator() && (selectedBadges && selectedBadges.length === 0) ? <button className='btn btn-primary-gradient text-center btn-icon' type="button" onClick={this.gotoForm}><i className='fa fa-plus'></i> Add Badges</button> : ''}
                    </div>
                </div> :
                    <div className={(tabIndex === 3) ? "tab-pane active" : "tab-pane "} id="Badges" role="tabpanel" aria-labelledby="Badges-tab">
                        <div className='nadedbtns' >
                            <div className='row align-items-center'>
                                <div className='col-8'><label className='mb-0'>Select badge(s)</label></div>
                                <div className='col-4 text-right'>
                                    <div className='btn btn-primary-gradient btn-sm btn-icon' onClick={this.handleEdit}>
                                        <i className="fa fa-close" ></i>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <form>

                            <div className='Utility-Badges'>

                                {listOfBadges && listOfBadges.length > 0 && listOfBadges.map((items, index) => (
                                    <div className={(items.isSelected) ? 'ubbox active' : 'ubbox'} onClick={() => this.handleBadgeClick(items, index)} title={"Click here to Add/Remove badges"}>
                                        <div className='ubboximg'>
                                            <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                                            <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                                            <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.badgePath && items.badgePath.slice(1)}`} alt="" /></span>
                                        </div>

                                        <p title={items.name}>{items.name}</p>
                                    </div>
                                ))}



                            </div>

                            {(inputValues && inputValues.length > 0) && inputValues.map((items, index) => (
                                <div className='ubboxcont'>
                                    <div className='ubboxwon1'>
                                        <div className='ubboxwon'>
                                            <div className='ubbox active'>
                                                <div className='ubboximg'>
                                                    <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                                                    <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                                                    <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.selectedBadge && items.selectedBadge.badgePath && items.selectedBadge.badgePath.slice(1)}`} alt="" /></span>
                                                </div>
                                            </div>
                                            <div className='ubboxtxt'>
                                                <h5>{items.selectedBadge && items.selectedBadge.name}</h5>
                                                {/* <p>Diam dictumst justo</p> */}
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <input className='form-control' value={items.title} onChange={(e) => this.handleTitle(e, index)} placeholder='Title' />
                                            {this.state.submitted && !items.title.trim() && <div className="mandatory">{"Title Required"}</div>}
                                            {this.state.submitted && items.title.trim() && !this.checkChar(items.title) && <div className="mandatory">{"Title should have atleast 3 characters and atmost 140 characters"}</div>}
                                        </div>
                                        <div className='mt-3'>
                                            <textarea className={isBadgeTooLength ? 'form-control w-100 descdan' : 'form-control'} rows={5} value={items.description} onChange={(e) => this.handleDescription(e, index)} placeholder='Description' />
                                            {/* {this.state.submitted && !items.description && <div className="mandatory">{"Description Required"}</div>} */}

                                            {!this.state.submitted && !items.description && !isBadgeTooLength &&  <div className="mandatory-req">{"Max 1400 characters"}</div>}
                                            {/* {!this.state.submitted && !items.description && isBadgeTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>} */}
                                            {!this.state.submitted && items.description && this.checkDesc(items.description) && this.checkmaxDesc(items.description) && !isBadgeTooLength && <div className="mandatory-req">{"Description should be no more than 1400 characters"}</div>}
                                            {!this.state.submitted && items.description && !this.checkDesc(items.description) && <div className="mandatory-req">{"Description must be at least 3 characters"}</div>}
                                            {this.state.submitted && items.description && !this.checkDesc(items.description) && <div className="mandatory">{"Description must be at least 3 characters"}</div>}
                                            {!this.state.submitted && items.description && this.checkDesc(items.description) && isBadgeTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                            {this.state.submitted && items.description && this.checkDesc(items.description) && !this.checkmaxDesc(items.description) && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                        </div>
                                    </div>

                                </div>
                            ))}
                            {(inputValues && inputValues.length > 0) ? <div className='mt-3 text-center'>
                                <button className='btn btn-primary-gradient' type="button" onClick={this.updateBadge}>Update Badge</button>
                            </div> : ''}
                        </form>


                    </div>}
            </Fragment>

        )
    }
}
const mapStateToProps = (state) => {
    return {


    }
}
const actionCreators = {


}

const connectBadges = connect(mapStateToProps, actionCreators)(Badges)
export { connectBadges as Badges }
