import React from "react";
import PubSub from 'pubsub-js';

import history from 'helpers/history'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { configPath } from "../config";
import { announcementActions } from "store/actions";
import { connect } from "react-redux";


var items1 = [

]
var items2 = []
class GlitterText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: { items: 1 },
                576: { items: 1 },
                768: { items: 1 },
                1920: { items: 1 }
            },
            texts: []
        }

    }


    componentDidMount() {
        this.props.getGlitteringText()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.glitteringText && props.glitteringText.statusCode === 200)) {
            return {
                texts: props && props.glitteringText && props.glitteringText.data && props.glitteringText.data.glittering_texts
            }
        }
        return null
    }

    render() {
        const { texts } = this.state
        const text = texts && texts.length > 0 && texts.map((items) => {
            return <a href={`${items && items.url}`} target="_blank" className="rainbow rainbow_text_animated mobmenuhide mx-auto">{items && items.title}</a>

        })

        return (
           
                <div className={(this.props && this.props.addclass) ? `rainbow ${this.props.addclass}` : "rainbow"}>
                    <div id="rainbowcarousel" className="carousel slide  " data-ride="carousel" data-interval="3000">
                        <div className="carousel-inner">
                            {texts && texts.length > 0 && texts.map((items,index) => (
                                <div className={(index === 0) ? "carousel-item active" : "carousel-item"} key={items && items.id}>
                                <a href={items && items.url} target="_blank" className=" rainbow_text_animated">{items && items.title}</a>
                            </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            //     <div>
            // {(text) &&  (text).length > 1 ?
            //     <AliceCarousel
            //         autoPlay autoPlayInterval={3000}
            //         infinite
            //         items={text}
            //         responsive={this.state.responsive}
            //         autoPlayDirection={"ltr"}
            //         mouseTracking={true}
            //         disableDotsControls
            //     /> :
            //     (text)
            // }
            // </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {
        glitteringText: state.announcement.glitterText
    }
}

const actionCreators = {
    getGlitteringText: announcementActions.getGlitterText
}

const connectGlitterText = connect(mapStateToProps, actionCreators)(GlitterText)

export { connectGlitterText as GlitterText }