import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userServices } from 'services';
import { Alert } from "helpers/alert";
import PubSub from 'pubsub-js';
import Modal from 'react-modal'
import { isWebLogin } from 'helpers';
import history from 'helpers/history';

var timeout;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '700px', // <-- This sets the height
        overlfow: 'auto' // <-- This tells the modal to scrol
    },
};
class ReportForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            reportedLink: "",
            reason: '',
            user: '',
            submitRequest: false,
            isAgree: false,
            isTooLength : false
        }
        this.maxLength = 1400
        this.minLength = 10
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        if (this.props && this.props.props) {
            const reportPath = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.props && this.props.props.location && this.props.props.location.pathname}${this.props.props.location && this.props.props.location.hash}`
            this.setState({ reportedLink: reportPath })
        }
    }
    handlemorepoints = (e) => {
        if (e.target.value && e.target.value.length <= (this.maxLength)) {
            this.setState({ reason: e.target.value,isTooLength : false })
        } else {
            this.setState({ reason: e.target.value.slice(0, (this.maxLength)),isTooLength : true })
        }
    }

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    sendReport = (e) => {
        e.preventDefault()
        const { reportedLink, reason } = this.state
        this.setState({ submitRequest: true, isTooLength : false })
        if (reportedLink && this.validURL(reportedLink)) {
            if (reason && this.checkmaxPoint(reason)) {
                let obj = {
                    url: reportedLink,
                    reporterRemarks: reason
                }
                this.setState({ isOpen: false })
                this.props.handleReportRes(obj)
            }
        }


    }



    validURL = (str) => {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        var re =/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)/

        const urlReg = /https:\/\/.*/;
        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }

    checkmaxPoint = (value) => {
        if ((value && value.length) <= (this.maxLength) && (value && value.length) >= (this.minLength)) {
            return true
        } else {
            return false
        }
    }

    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    closePopup = () => {
        this.setState({ isOpen: false, reason: '' })
        this.props.handleCloseReport()
    }
    handleLogin = () => {
        const reportPath = `${this.props && this.props.props && this.props.props.location && this.props.props.location.pathname}${this.props.props.location && this.props.props.location.hash}`
        history.push('/login', { reportForm: reportPath })
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()
        }
        return (
            <Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    style={customStyles}
                    className="creatorrequ"

                    contentLabel="Report Modal">
                        <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">
                        <button onClick={this.closePopup} className="btn btn-primary mb-3 clsbtn">X</button>
                        
                    {isWebLogin() ? <div className="">
                   
                        <form>
                            <div className="becomeacreator pt-3 ">
                                <h4 className='usernamelilac text-center'>Why are you reporting?</h4>
                                <h6 className='mb-4 text-center mb-5'>Describe why you think this NFT should be removed from marketplace</h6>
                                {/* <div className="form-group">
                                <label htmlFor="report"> Report Link</label>
                                <input className={"form-control"} value={this.state.reportedLink} name="report" id="report" disabled/>

                            </div> */}

                                <div className="form-group">
                                    <label htmlFor="morePoints">Message &nbsp;*</label>

                                    <textarea rows={5} className={(this.state.isTooLength) ? "form-control descdan" : "? form-control"} value={this.state.reason} name="morePoints" id="morePoints" onChange={this.handlemorepoints} />
                                    {this.state.submitRequest && !this.state.reason && <div className="mandatory">Please give your reason for this report</div>}
                                    {this.state.submitRequest && this.state.reason && !this.checkmaxPoint(this.state.reason) && <div className="mandatory">{`Min ${this.minLength} chars and Max ${this.maxLength} chars`}</div>}
                                    {!this.state.submitRequest && this.state.reason && <div className="mandatory-req">{`Min ${this.minLength} chars and Max ${this.maxLength} chars`}</div>}
                                    {this.state.submitRequest && this.state.reason && this.checkmaxPoint(this.state.reason) && this.state.isTooLength && <div className="mandatory">{`Min ${this.minLength} chars and Max ${this.maxLength} chars`}</div>}


                                </div>



                            </div>
                        </form>


                        <div className="custom-btn">
                            <div className='row'>
                                {/* <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closePopup}>Cancel</button></div> */}
                                <div className='col-12 text-center'><button type="button" className="btn btn-primary-gradient" onClick={this.sendReport} >Report</button></div>
                            </div>
                            
                            

                        </div>
                    </div> : <div className="py-5 ">
                        <h2 className='mt-3 mb-5'>Please Login/Signup to report this NFT</h2>

                        <div className='row'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closePopup}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary' type="button" onClick={this.handleLogin}>Sign in</button></div>
                            </div>                       
                    </div>}
                    </div>     
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const actionCreators = {}

const connectReportForm = connect(mapStateToProps, actionCreators)(ReportForm)

export { connectReportForm as ReportForm }