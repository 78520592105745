import { categoryConstants } from "../../constants";

const initialState = {
    addedCategory : [],
    categories : [],
    userCategories : [],
    updateCategory : []
}
export function category(state=initialState,action){
    switch(action.type){
        case categoryConstants.GET_CATEGORIES:
            return {
                categories : action.categories,
               
            }
            case categoryConstants.ADD_CATEGORIES:
                return {
                    addedCategory : action.addedCategory
                }
                case categoryConstants.GET_USER_CATEGORIES:
                    return {
                        userCategories : action.userCategories
                    }
                    case categoryConstants.UPDATE_CATEGORIES:
                        return {
                            updateCategory : action.updateCategory
                        }
          
            
            default:
                return state
    }
}