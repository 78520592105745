import React from "react"
import history from "./history"
export const CommonWalletConnectUI = (props) => {
    const connectWallet = () => {
        if(props && props.connectMyWallet()){
            props.connectMyWallet()
        }
    }
    const gotoMyCollection = () => {
        if(props && props.isMyCollection){
            history.push('/wallet')
        }else{
            history.push('/store/adddrops')
        }
    }
    return <div className="container text-center pagenotfound py-5">
    <div className="container">
        <div className="card py-5 my-5">
            <div className="card-body">
           
                <div className="mb-4">
                    <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                        <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                        <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                    </svg>
                </div>
                <p>
                    If you want to list NFT for sale or create airdrop, please connect your wallet account
                </p>

                <div className="mb-4">
                    <button className="btn btn-primary px-5" type="button" onClick={connectWallet}>Connect Wallet</button>

                </div>

                <p className="text-center">or</p>


                <p><button className="btn btn-primary btn-fill px-5" onClick={gotoMyCollection}>Back to Collections</button></p>

            </div>
        </div>
    </div>

</div>
}