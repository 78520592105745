export const adminConstants = {
    ADD_ADMIN_ACCOUNT : 'ADD_ADMIN_ACCOUNT',
    ADD_ADMIN_ACCOUNT_FAILED : 'ADD_ADMIN_ACCOUNT_FAILED',
    GET_ADMIN_ACCOUNT : 'GET_ADMIN_ACCOUNT',
    ENABLE_ADMIN_ACCOUNT : 'ENABLE_ADMIN_ACCOUNT',
    DISABLE_ADMIN_ACCOUNT : 'DISABLE_ADMIN_ACCOUNT',
    GET_ABUSE_REPORT : 'GET_ABUSE_REPORT',
    REVIEW_ABUSE_REPORT : 'REVIEW_ABUSE_REPORT',
    GET_ADMIN_USER : 'GET_ADMIN_USER',
    ADMIN_RESET_PASSWORD : 'ADMIN_RESET_PASSWORD',
    ADMIN_CHANGE_PASSWORD : 'ADMIN_CHANGE_PASSWORD',
    GET_LIST_DROP_HISTORY : 'GET_LIST_DROP_HISTORY',
    GET_HISTORY_DETAIL : 'GET_HISTORY_DETAIL',
    DROP_HISTORY_RELISTING : 'DROP_HISTORY_RELISTING',
    DROP_HISTORY_RELIST_SUCCESS_UPDATE : 'DROP_HISTORY_RELIST_SUCCESS_UPDATE',
    GET_STORES : 'GET_STORES',
    GET_STORE_DETAIL : 'GET_STORE_DETAIL',
    GET_STORE_SALES_DETAIL : 'GET_STORE_SALES_DETAIL',
    ADD_BADGES : 'ADD_BADGES',
    UPDATE_BADGES : 'UPDATE_BADGES'
}