import React, { Component, createFactory, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { categoryActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';

const validations = {
    maxLength : 15,
    minLength : 3,
    maxCategoryLimit : 10
}
class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            category: '',
            classname: '',
            allCategories : [],
            updatedId : ''
        }
    }
    componentDidMount() {
        this.getAllCategories()

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    getAllCategories = () => {
        this.props.getAllCategories()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.addedCategories && props.addedCategories.statusCode === 201) {
            props.resetAddedCategories()
            props.getAllCategories()
            return {
                categories: [],
                category: '',
                classname: '',
                updatedId : ''
            }
        }
        if(props && props.allCategories && props.allCategories.statusCode === 200){
            
            if(props.allCategories.data && props.allCategories.data.tags){
                return { 
                    allCategories : props.allCategories.data.tags
                }
            }
            
        }
        if((props && props.updatedCategory && props.updatedCategory.statusCode === 200)){
            props.resetUpdateCategory()
            props.getAllCategories()
            return {
                categories: [],
                category: '',
                classname: '',
                updatedId : ''
            }
        }
    }

    handleCategories = (e) => {
        let regex = /^[a-z0-9\s]+$/i
        
            if((e.target.value && e.target.value.length <= (validations.maxLength))){
                if(regex.test(e.target.value)){
            this.setState({
                category: e.target.value
            })
        }
        }else{
            this.setState({
                category : e.target.value.slice(0,(validations.maxLength))
            })
        }
    }
    handleClassname = (e) => {
        let regex = /(^$)|^[a-z0-9-\s]+$/i
        if(regex.test(e.target.value)){
            this.setState({
                classname: e.target.value
            })
        }
    }
   
    checkLength = (value) => {
        if((value && value.length) >= (validations.minLength) && (value && value.length) <= (validations.maxLength)){
            return true
        }else{
            return false
        }
    }
    checkIconLength = (value) => {
        if((value && value.length) >= (validations.minLength)){
            return true
        }else{
            return false
        }
    }
    updateCategoryParent = (item) => {
        this.showLoader()
        setTimeout(() => {
            this.hideLoader()
            this.setState({
                updatedId : item && item.id,
                category : item && item.name,
                classname :item && item.iconClass
            })
        },500)
        

    }
    resetForm = (e) => {
        e.preventDefault()
        this.setState({
            categories: [],
                category: '',
                classname: '',
                updatedId : ''
        })
    }
    submitUpdateCategory = (e) => {
        e.preventDefault()
        if((this.state.category)){
            if(this.checkLength(this.state.category)){
                if(this.state.classname){
                    if(this.checkIconLength(this.state.classname)){
                    let obj = {
                        name: this.state.category,
                        iconClass : this.state.classname
                    }
                    this.props.updateCategory(this.state.updatedId,JSON.stringify(obj))
                }else{
                    toast.error(`Icon classname must be atlease ${validations.minLength} characters`)
                }
                }else{
                    toast.error('Please enter icon classname')
                }
            }else{
                toast.error(`Category name must be minimum ${validations.minLength} characters long and maximum ${validations.maxLength} characters`)
            }
        }else{
            toast.error("Please enter category name")
        }
        
    }
    handleSubmit = (e) => {
        e.preventDefault()
        let category = []
        if((this.state.category)){
            if(this.checkLength(this.state.category)){
                if(this.state.classname){
                    if(this.checkIconLength(this.state.classname)){

                    let obj = {
                        name: this.state.category,
                        iconClass : this.state.classname
                    }
                    category.push(obj)
                    this.props.addCategory(JSON.stringify({categories : category}))
                }else{
                    toast.error(`Icon classname must be atlease ${validations.minLength} characters`)
                }
                }else{
                    toast.error('Please enter icon classname')
                }
            }else{
                toast.error(`Category name must be minimum ${validations.minLength} characters long and maximum ${validations.maxLength} characters`)
            }
        }else{
            toast.error("Please enter category name")
        }
    
        
    }
    render() {
        const {updatedId} = this.state
        return (
            <Fragment>
                <div className="smallilac px-3 container">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-12"><h2 className="font-weight-bold">Category Settings</h2>  </div>
                        
                    </div>
                    <div className="purchasebox p-2 mb-3">
                        <div className="card">
                            <div className="card-body">
                   
                            <form onSubmit={this.handleSubmit}>

                                <div className='row'>
                                <div className="form-group mb-2 col-md-5 ">
                                    <label htmlFor="category" className="font-weight-bold" >Enter category name</label>
                                    <div className="input-group dollorsec mb-2">
                                        <input type="text" className="form-control" id="category" value={this.state.category} onChange={this.handleCategories} />
                                        
                                    </div>
                                    <p className="small warn-mes">
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Min {validations.minLength} characters and Max {validations.maxLength} characters<br/>
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Max limit {validations.maxCategoryLimit} category<br/>
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Special characters not allowed<br/>
                                        </p>
                                </div>
                                <div className="form-group mb-2 col-md-5 ">
                                    <label htmlFor="classname" className="font-weight-bold" >Enter class name</label>
                                    <div className="input-group dollorsec mb-2">
                                        <input type="text" className="form-control" id="classname" value={this.state.classname} onChange={this.handleClassname} />
                                        
                                    </div>
                                    <p className="small warn-mes">
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Example : fa fa-image, fa fa-play<br/>
                                        <a href='https://fontawesome.com/v4/icons/' target="_blank">Reference : https://fontawesome.com/v4/icons/</a>
                                        </p>
                                    
                                </div>
                                <div className="form-group mb-2 col-md-2">
                                 <label className="font-weight-bold">&nbsp; </label>
                                    {(updatedId && updatedId != '') ? 
                                    (<div className="form-group ">
                                    <button type="button" className="btn btn-primary mr-2" onClick={this.resetForm}>cancel</button>

                                    <button type="button" className="btn btn-primary " onClick={this.submitUpdateCategory}>Update</button>
                                </div>) : 
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary ">Add</button>
                                    </div>}
                                </div>

                             
                                </div>
                            </form>

                            </div>
                </div>
                </div>
                            
                <div className="purchasebox p-2 mb-3">
                        <div className="card">
                            <div className="card-body">

                            <div className="table-responsive" >
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>CATEGORY NAME</th>
                                        <th className='text-center'>ICON</th>
                                        <th className='text-center'>EDIT</th>
                                    </tr>
                                </thead>
                                {this.state.allCategories && this.state.allCategories.map((items,index) => (
                                    <tbody>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">{items && items.name}</td>
                                            <td className='text-center usernamelilac'><i className={items && items.iconClass}></i></td>
                                            <td className='text-center'><button className='btn btn-primary btn-sm' onClick={() => this.updateCategoryParent(items)} type="button"><i className='fa fa-pencil fa-lg' ></i></button></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </div>

                        </div>
                        </div>
                        </div>

</div>

                    
                

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        allCategories: state.category.categories,
        addedCategories: state.category.addedCategory,
        updatedCategory : state.category.updateCategory
    }
}
const actionCreator = {
    getAllCategories: categoryActions.getCategories,
    addCategory: categoryActions.addCategories,
    resetGetCategories: categoryActions.resetGetCategories,
    resetAddedCategories : categoryActions.resetAddCategories,
    updateCategory : categoryActions.updateCategoryParent,
    resetUpdateCategory : categoryActions.resetUpdateCategoryParent
}
const connectCategories = connect(mapStateToProps, actionCreator)(Category)
export { connectCategories as Category }