import { airdropConstants } from "../../constants"
import { adminServices, airDropService, userServices } from "services"
import PubSub from 'pubsub-js';
import * as fcl from "@onflow/fcl";
import { isWebLogin } from "helpers";

export const airdropActions = {
    getAirDropArt,
    resetAirDropArt,
    getAirdropListing,
    getAirdropDetail,
    resetAirDropList,
    resetAirDropDetail,
    getAirDropDetailUser,
    resetAirDropDetailUser,
    verifyAirdropUser,
    resetVerifyAirdropUser,
    resetFailedVerifyAirdropUser,
    registerAirdropUser,
    resetRegAirdropUser,
    resetFailedRegAirdropUser,
    fetchuserAirdrops,
    resetUserAirdrops,
    airdropReminder,
    resetAirdropReminder,
    resetAirdropReminderFailed,
    autoLoginAirdropUser,
    resetLoginAirdropUser,
    resetLoginAirdropUserFailed,
    autoLoginAirdropGuest,
    resetLoginAirdropGuestUser,
    resetLoginAirdropGuestUserFailed,
    autoLoginWelcomeUser,
    resetAuoLoginWelcomeUser,
    resetAuoLoginWelcomeUserFailed
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAirDropArt(obj){
    return dispatch => {
        showLoader()
        airDropService.getAirDropArt(obj)
        .then(drop => {
            if(drop){
                dispatch({type:airdropConstants.GET_AIRDROP_ART,airdropList:drop,isCheckAirDrop:true})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function getAirdropListing(obj){
    return dispatch => {
        showLoader()
        adminServices.getAirdropListing(obj)
        .then(drop => {
            if(drop){
                dispatch({type:airdropConstants.GET_AIRDROP_LISTING,allairdropList:drop,isListAirDrop:true})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function getAirdropDetail(id){
    return dispatch => {
        showLoader()
        adminServices.getAirdropDetail(id)
        .then(drop => {
            if(drop){
                dispatch({type:airdropConstants.GET_AIRDROP_DETAIL,airdropDetail:drop,isDetailAirDrop:true})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAirDropArt(){
    return dispatch => {
        dispatch({type:airdropConstants.GET_AIRDROP_ART,airdropList:[]})
    }
}
function resetAirDropList(){
    return dispatch => {
        dispatch({type:airdropConstants.GET_AIRDROP_LISTING,allairdropList:[]})
    }
}
function resetAirDropDetail(){
    return dispatch => {
        dispatch({type:airdropConstants.GET_AIRDROP_DETAIL,airdropDetail:[]})
    }
}
function getAirDropDetailUser(id){
    return dispatch => {
        showLoader()
        airDropService.getAirDropDetailUser(id)
        .then(drop => {
            if(drop){
                dispatch({type:airdropConstants.GET_AIRDROP_DETAIL_USER,airdropDetailUser:drop,isCheckAirDropDetail:true})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAirDropDetailUser(){
    return dispatch => {
        dispatch({type:airdropConstants.GET_AIRDROP_DETAIL_USER,airdropDetailUser:[],isCheckAirDropDetail:false})
    }
}
function verifyAirdropUser(code) {
    return dispatch => {
        showLoader()
        airDropService.verifyAirdropUser(code)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: airdropConstants.VERIFY_AIRDROP_USER, verifyAirdropUser: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.VERIFY_AIRDROP_USER_FAILED, failedAirdropUser: error })
                
            })
    }
}

function resetVerifyAirdropUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.VERIFY_AIRDROP_USER, verifyAirdropUser: [] })

    }
}
function resetFailedVerifyAirdropUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.VERIFY_AIRDROP_USER_FAILED, failedAirdropUser: '' })
    }
}
function registerAirdropUser(obj) {


    return dispatch => {
        showLoader()
        airDropService.registerAirdropUser(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    let userDetail = JSON.stringify(user)
                    localStorage.setItem("webLogin", userDetail)
                    dispatch({ type: airdropConstants.REGISTER_AIRDROP_USER, regAirdropUser: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.REGISTER_AIRDROP_USER_FAILED, failedRegAirdrop: error })
                
            })
    }
}

function resetRegAirdropUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.REGISTER_AIRDROP_USER, regAirdropUser: [] })
    }
}

function resetFailedRegAirdropUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.REGISTER_AIRDROP_USER_FAILED, failedRegAirdrop: "" })
    }
}
function fetchuserAirdrops(obj){
    return dispatch => {
        showLoader()
        adminServices.fetchuserAirdrops(obj)
        .then(drop => {
            if(drop){
                dispatch({type:airdropConstants.FETCH_USERS_AIRDROP,userAirdrops:drop,isUserAirdropFetched:true})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetUserAirdrops(){
    return dispatch => {
        dispatch({type:airdropConstants.FETCH_USERS_AIRDROP,userAirdrops:[],isUserAirdropFetched : false})
    }
}

function airdropReminder(id) {
    return dispatch => {
        showLoader()
        airDropService.airdropReminder(id)
            .then(air => {
                if ((air && air.statusCode) === 200) {
                    dispatch({ type: airdropConstants.AIRDROP_REMINDER, aidropReminderRes: air })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.AIRDROP_REMINDER_FAILED, airdropReminderFailed: error })
                
            })
    }
}

function resetAirdropReminder() {
    return dispatch => {
        dispatch({ type: airdropConstants.AIRDROP_REMINDER, aidropReminderRes : [] })

    }
}
function resetAirdropReminderFailed(){
    return dispatch => {
        dispatch ({ type: airdropConstants.AIRDROP_REMINDER_FAILED,airdropReminderFailed : []})
    }
}

function autoLoginAirdropUser(id) {
    return dispatch => {
        showLoader()
        airDropService.autoLoginAirdropUser(id)
            .then(user => {
                if (((user && user.statusCode) === 200) && (user && user.data && user.data.authCredentias)) {
                    if(isWebLogin()){
                        userServices.logout().then(res => {
                            localStorage.removeItem("userDetail");
                            localStorage.removeItem("webLogin");
                            localStorage.removeItem('walletDetail');
                            sessionStorage.removeItem('CURRENT_USER');
                            fcl.unauthenticate()
                            let obj = {
                                statuCode : 200,
                                data : user.data.authCredentias
                            }
                            let userDetail = JSON.stringify(obj)
                            localStorage.setItem("webLogin", userDetail)
                            dispatch({ type: airdropConstants.AUTO_REGISTER_LOGIN_USER, saveAirdropUser : user })
                        })
                    }else{
                        let obj = {
                            statuCode : 200,
                            data : user.data.authCredentias
                        }
                        let userDetail = JSON.stringify(obj)
                        localStorage.setItem("webLogin", userDetail)
                        dispatch({ type: airdropConstants.AUTO_REGISTER_LOGIN_USER, saveAirdropUser : user })
                    }
                    
                       
                   
                    
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.AUTO_REGISTER_LOGIN_USER_FAILED, saveAirdropUserFailed: error })
                
            })
    }
}

function resetLoginAirdropUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_REGISTER_LOGIN_USER, saveAirdropUser: [] })
    }
}

function resetLoginAirdropUserFailed() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_REGISTER_LOGIN_USER_FAILED, saveAirdropUserFailed: "" })
    }
}

function autoLoginAirdropGuest(id,airdropId) {
    return dispatch => {
        showLoader()
        airDropService.autoLoginAirdropGuest(id, airdropId)
            .then(user => {
                if (((user && user.statusCode) === 200) && (user && user.data)) {
                    if(isWebLogin()){
                        userServices.logout().then(res => {
                            localStorage.removeItem("userDetail");
                            localStorage.removeItem("webLogin");
                            localStorage.removeItem('walletDetail');
                            sessionStorage.removeItem('CURRENT_USER');
                            fcl.unauthenticate()
                            let obj = {
                                statuCode : 200,
                                data : user.data.authResult
                            }
                            let userDetail = JSON.stringify(obj)
                            localStorage.setItem("webLogin", userDetail)
                            dispatch({ type: airdropConstants.AUTO_LOGIN_GUEST, saveAirdropGuestUser : user })
                        })
                    }else{
                        let obj = {
                            statuCode : 200,
                            data : user.data.authResult
                        }
                        let userDetail = JSON.stringify(obj)
                        localStorage.setItem("webLogin", userDetail)
                        dispatch({ type: airdropConstants.AUTO_LOGIN_GUEST, saveAirdropGuestUser : user })
                    }
                     
                    // hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.AUTO_LOGIN_GUEST_USER_FAILED, saveAirdropGuestUserFailed: error })
                
            })
    }
}

function resetLoginAirdropGuestUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_LOGIN_GUEST, saveAirdropGuestUser: [] })
    }
}

function resetLoginAirdropGuestUserFailed() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_LOGIN_GUEST_USER_FAILED, saveAirdropGuestUserFailed: "" })
    }
}

function logoutExistingUser(){
    localStorage.removeItem("userDetail");
    localStorage.removeItem("webLogin");
    localStorage.removeItem('walletDetail');
    sessionStorage.removeItem('CURRENT_USER');
    fcl.unauthenticate()
}
function autoLoginWelcomeUser(id, token) {
    return dispatch => {
        showLoader()
        airDropService.autoLoginWelcomeUser(id, token)
            .then(user => {
                console.log(id,token)
                if (((user && user.statusCode) === 200) && (user && user.data)) {
                    if(isWebLogin()){
                        userServices.logout().then(res => {
                            logoutExistingUser()
                            let obj = {
                                statuCode : 200,
                                data : user.data.authResult
                            }
                            let userDetail = JSON.stringify(obj)
                            localStorage.setItem("webLogin", userDetail)
                            dispatch({ type: airdropConstants.AUTO_LOGIN_WELCOME_USER, welcomeUser : user })
                        })
                    }else{
                        let obj = {
                            statuCode : 200,
                            data : user.data.authResult
                        }
                        let userDetail = JSON.stringify(obj)
                        localStorage.setItem("webLogin", userDetail)
                        dispatch({ type: airdropConstants.AUTO_LOGIN_WELCOME_USER, welcomeUser : user })
                    }
                     
                    // hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: airdropConstants.AUTO_LOGIN_WELCOME_USER_FAILED, welcomeUserFailed : error })
                
            })
    }
}

function resetAuoLoginWelcomeUser() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_LOGIN_WELCOME_USER, welcomeUser: [] })
    }
}

function resetAuoLoginWelcomeUserFailed() {
    return dispatch => {
        dispatch({ type: airdropConstants.AUTO_LOGIN_WELCOME_USER_FAILED, welcomeUserFailed: "" })
    }
}