import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';

class Dashboard extends Component{
    constructor(props){
        super(props);
        this.state = {
            // dashboard:[]
        }
    }
    componentDidMount(){
        // this.props.dashboard()
        this.showLoader()
        adminServices.dashboard().then(res => {
            this.hideLoader()
            if((res && res.statusCode) == 200){
                let response = res && res.data 
                this.setState({dashboard:response})
            }
        }).catch(err => {this.hideLoader()})
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    // static getDerivedStateFromProps(props,state){
    //     console.log(props)
    //     if(props){
    //         return {
    //             dashboard : props && props.dashboard && props.dashboard.data && props.dashboard.data.count ? props.dashboard.data.count : []
    //         }
    //     }
    // }
    handleIncrement = () => {
        this.props.increment()
    }
    handleDecrement = () => {
        this.props.decrement()
    }
    render(){
        const {dashboard} = this.state
        return(
            <Fragment>
                <div className="container">
                {/* {dashboard && dashboard.map(items => ( */}
                    <div className="row">
                                          
                            <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.art_statistics && dashboard.art_statistics.all && dashboard.art_statistics.all.arts} </h3>
                                    <p> Total Arts </p>
                                </div>
                                <div className="icon">
                                <i className="fa fa-paint-brush" aria-hidden="true"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>

                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac">{dashboard && dashboard.user_statistics && dashboard.user_statistics.creators} </h3>
                                    <p> Total Creators </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-palette"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.user_statistics && dashboard.user_statistics.collectors} </h3>
                                    <p> Total Collectors </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-male"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.user_statistics && dashboard.user_statistics.creatorPendingRequests} </h3>
                                    <p> Pending Requests </p>
                                </div>
                                <div className="icon">
                                    <i className="far fa-paper-plane"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.art_statistics && dashboard.art_statistics.today && dashboard.art_statistics.today.arts} </h3>
                                    <p> New Arts (Today) </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-folder-plus"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.user_statistics && dashboard.user_statistics.today} </h3>
                                    <p> New Users (Today) </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-users"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.art_statistics && dashboard.art_statistics.currentMonth && dashboard.art_statistics.currentMonth.arts } </h3>
                                    <p> Arts For Current Month </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-calendar-alt"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.purchase_statistics && dashboard.purchase_statistics.count} </h3>
                                    <p> Purchased Arts </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-shopping-bag"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.user_statistics && dashboard.user_statistics.currentMonth} </h3>
                                    <p> Users For Current Month </p>
                                </div>
                                <div className="icon">
                                    <i className="far fa-calendar-alt"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 ">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac"> {dashboard && dashboard.user_statistics && dashboard.user_statistics.disabledAccounts} </h3>
                                    <p> Disable Users </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-ban"></i>
                                </div>
                                {/* <a href="#" className="card-box-footer">View More <i className="fa fa-arrow-circle-right"></i></a> */}
                            </div>
                        
                        </div>
                        <div className="col-6 col-md-3 d-none">
                            <div className="card-box bg2f2f2f">
                                <div className="inner">
                                    <h3 className="usernamelilac">{dashboard && dashboard.purchase_statistics && (parseFloat(dashboard.purchase_statistics.transactionAmount).toFixed(5) + ' ' + "FLOW")} &nbsp;</h3>
                                    <p> Transaction Amount </p>
                                </div>
                                <div className="icon">
                                    <i className="fas fa-exchange-alt"></i>
                                </div>
                            </div>
                        
                        </div>
                        
                    </div>
                    {/* ))} */}
                </div>
            
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        dashboard : state.user.dashboard
    }
}
const actionCreator = {
    dashboard : userAction.dashboard,
    decrement : userAction.decrement
}
const connectDashboard = connect(mapStateToProps,actionCreator)(Dashboard)
export {connectDashboard as Dashboard}