import { followConstants } from "../../constants";

const initialState = {
    followers:[],
    followings:[],
    userFollow:[],
    failedFollow:[],
    unfollow:[],
    checkUser : [],
    failedCheck : [],
    getfollowersFailed : ''
}
export function follow(state=initialState,action){
    switch(action.type){
        case followConstants.GET_FOLLOWERS:
            return {
                ...state,
                followers : action.followers
            }
            case followConstants.GET_FOLLOWINGS:
                return {
                    ...state,
                    followings : action.followings
                }
                case followConstants.FOLLOW:
                    return {
                        ...state,
                        userFollow : action.userFollow
                    }
                    case followConstants.FAILED_FOLLOW:
                        return {
                            ...state,
                            failedFollow : action.failedFollow
                        }
                        case followConstants.UNFOLLOW:
                            return {
                                unfollow : action.unfollow
                            }
                            case followConstants.CHECK_FOLLOW_USER:
                                return {
                                    checkUser : action.checkUser
                                }
                                case followConstants.FAILED_CHECK_FOLLOW:
                                    return {
                                        failedCheck : action.failedCheck
                                    }
                                    case followConstants.GET_FOLLOWERS_FAILED:
                                        return {
                                            ...state,
                                            getfollowersFailed : action.getfollowersFailed
                                        }
          
            
            default:
                return state
    }
}