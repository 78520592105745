import { followConstants} from "../../constants"
import { followServices } from "services"
import PubSub from 'pubsub-js';


export const followActions = {
    getFollowers,
    getFollowings,
    follow,
    resetFollow,
    resetFailedFollow,
    resetFollowers,
    resetFollowing,
    unfollow,
    resetUnFollow,
    checkFollowUser,
    resetCheckUser,
    resetFailedCheck,
    resetGetFollowersFailed
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
} 

function follow(userId){
    return dispatch => {
        showLoader()
        followServices.follow(userId)
        .then(follow => {
            if((follow && follow.statusCode) === 200){
                dispatch({type:followConstants.FOLLOW,userFollow:follow})
                hideLoader()
            }
        },error => {
            dispatch({type:followConstants.FAILED_FOLLOW,failedFollow:error})
            // console.log("error",error)
            hideLoader()
        })
    }
}
function resetFollow(){
    return dispatch => {
        dispatch({type:followConstants.FOLLOW,userFollow:[]})
    }
}
function resetFailedFollow(){
    return dispatch => {
        dispatch({type:followConstants.FAILED_FOLLOW,failedFollow:[]})
    }
}

function getFollowers(userId){
    return dispatch => {
        // showLoader()
        followServices.getFollowers(userId)
        .then(follow => {
            if((follow && follow.statusCode) === 200){
                dispatch({type:followConstants.GET_FOLLOWERS,followers:follow})
                hideLoader()
            }
        },error => {
            dispatch({type:followConstants.GET_FOLLOWERS_FAILED,getfollowersFailed:error})
            hideLoader()
        })
    }
}
function resetFollowers(){
    return dispatch => {
        dispatch({type:followConstants.GET_FOLLOWERS,followers:[]})
    }
}
function resetGetFollowersFailed(){
    return dispatch => {
        dispatch({type : followConstants.GET_FOLLOWERS_FAILED,getfollowersFailed : ''})
    }
}

function getFollowings(userId){
    return dispatch => {
        // showLoader()
        followServices.getFollowings(userId)
        .then(follow => {
            if((follow && follow.statusCode) === 200){
                dispatch({type:followConstants.GET_FOLLOWINGS,followings:follow})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetFollowing(){
    return dispatch => {
        dispatch({type:followConstants.GET_FOLLOWINGS,followings:[]})
    }
}

function unfollow(userId){
    
    return dispatch => {
        showLoader()
        followServices.unfollow(userId)
        .then(follow => {
            if((follow && follow.statusCode) === 200){
                dispatch({type:followConstants.UNFOLLOW,unfollow:follow})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}

function resetUnFollow(){
    return dispatch => {
        dispatch({type:followConstants.UNFOLLOW,unfollow:[]})
    }
}
function checkFollowUser(userId){
    return dispatch => {
        // showLoader()
        followServices.checkFollowUser(userId)
        .then(follow => {
            if((follow && follow.statusCode) === 200){
                dispatch({type:followConstants.CHECK_FOLLOW_USER,checkUser:follow})
                hideLoader()
            }
        },error => {
            dispatch({type:followConstants.FAILED_CHECK_FOLLOW,failedCheck:error})
            hideLoader()
        })
    }
}

function resetCheckUser(){
    return dispatch => {
        dispatch({type:followConstants.CHECK_FOLLOW_USER,checkUser:[]})
    }
}
function resetFailedCheck(){
    return dispatch => {
        dispatch({type:followConstants.FAILED_CHECK_FOLLOW,failedCheck:[]})
    }
}



