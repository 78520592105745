
export const checkJoyRide = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const detail = JSON.parse(getLocal)
    let obj = {
        home_login: false,
        home_airdrop : false,
        drop: false,
        arts: false,
        discover: false,
        purchase: false,
        bid: false,
        creator: false,
        own_sale_page : false,
        own_auction_page : false,
        own_cancel_auction_page : false,
        list_auction_page : false,
        my_wallet : false,
        airdrop : false,
        own_auction_settlement_page : false,
        fund_wallet_page : false,
        art_detail_page : false,
        art_claim_page : false,
        login_page : false
    }
    if (detail == null || !detail) {  
        localStorage.setItem('joy-ride-storage', JSON.stringify(obj))
    }

}
export const resetToTrue = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const detail = JSON.parse(getLocal)
    let obj = {
        home_login: true,
        home_airdrop: true,
        drop: true,
        arts: true,
        discover: true,
        purchase: true,
        bid: true,
        creator: true,
        own_sale_page : true,
        own_auction_page : true,
        own_cancel_auction_page : true,
        list_auction_page : true,
        my_wallet : true,
        airdrop : true,
        own_auction_settlement_page : true,
        fund_wallet_page : true,
        art_detail_page : true,
        art_claim_page : true,
        login_page : true
    }
    if (detail == null || !detail) {  

        localStorage.setItem('joy-ride-storage', JSON.stringify(obj))
    }

}
export const checkHomeLoginPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const detail = JSON.parse(getLocal)
    if (detail != null) {
        if (detail && detail.home_login) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}
export const checkDropPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.drop) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkCreatorPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const detail = JSON.parse(getLocal)
    if (detail != null) {
        if (detail && detail.creator) {
            return false
        } else {
            return false
        }
    } else {
        return false
    }
}
export const checkPurchasePage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        if (d_detail && d_detail.purchase) {
            return false
        } else {
            return false
        }
    } else {
        return false
    }
}
export const checkBidPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        if (d_detail && d_detail.bid) {
            return false
        } else {
            return false
        }
    } else {
        return false
    }
}

export const checkWithEnableTour = () => {
    const tourEnable = localStorage.getItem('enable_tour')
    const tourEnableDetail = JSON.parse(tourEnable)
    if(tourEnableDetail !== null){
        if(tourEnableDetail){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}
export const checkArtPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.arts) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkOwnSalePage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.own_sale_page) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkOwnAuctionPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.own_auction_page) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkOwnCancelAuctionPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.own_cancel_auction_page) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkOwnListingAuctionPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.list_auction_page) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkMywalletPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.my_wallet) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkAirdropPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.airdrop) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkownAuctionSettlement = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
        // if (d_detail && d_detail.home) {
            if (d_detail && d_detail.own_auction_settlement_page) {
                return false
            } else {
                return false
            }
        // } else {
        //     return false
        // }
    } else {
        return false
    }
}
export const checkFundWalletPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
            if (d_detail && d_detail.fund_wallet_page) {
                return false
            } else {
                return false
            }
    } else {
        return false
    }
}
export const checkArtDetailPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
            if (d_detail && d_detail.art_detail_page) {
                return false
            } else {
                return false
            }
    } else {
        return false
    }
}
export const checkClaimPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
            if (d_detail && d_detail.art_claim_page) {
                return false
            } else {
                return false
            }
    } else {
        return false
    }
}
export const checkLoginPage = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
            if (d_detail && d_detail.login_page) {
                return false
            } else {
                return true
            }
    } else {
        return false
    }
}
export const checkCommunityAirdrop = () => {
    const getLocal = localStorage.getItem('joy-ride-storage')
    const d_detail = JSON.parse(getLocal)
    if (d_detail != null) {
            if (d_detail && d_detail.home_airdrop) {
                return false
            } else {
                return true
            }
    } else {
        return false
    }
}
export const initiateTour = () => {
    const getLocal = localStorage.getItem('completed_ride')
    const detail = JSON.parse(getLocal)
    let obj = {
        isLoginShowed : false,
        isConnectWalletShowed : false,
        isAfterConnectWalletShowed : false
    }
    if (detail == null || !detail) {  
        localStorage.setItem('completed_ride', JSON.stringify(obj))
    }

}
export const stopTourGuide = () => {
    const getLocal = localStorage.getItem('completed_ride')
    const detail = JSON.parse(getLocal)
    let obj = {
        isLoginShowed : true,
        isConnectWalletShowed : true,
        isAfterConnectWalletShowed : true
    }
    if (detail == null || !detail) {  
        localStorage.setItem('completed_ride', JSON.stringify(obj))
    }
}
export const checkTourCompleted = () => {
    const getLocal = localStorage.getItem('completed_ride')
    const detail = JSON.parse(getLocal)
    if(detail !== null && detail && detail !== undefined){
        const areTrue = Object.values(detail).every(
            value => value === true
          );
          return areTrue
    }else{
        return false
    }
    
}