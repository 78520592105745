
import React, { createRef } from 'react'
import { Component } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  StandaloneSearchBox,
  DirectionsRenderer,
  DrawingManager,
  Circle,
  InfoWindow

} from '@react-google-maps/api';
import Geocode from 'react-geocode';

const containerStyle = {
  width: '500px',
  height: '500px'
};







class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 4,
      markerPositions: { lat: -34.397, lng: 150.644 },
      selectedPlace: '',
      travelMode: {},
      destination: { lat: -34.397, lng: 150.644 },
      origin: {
        lat: 37.832,
        lng: -122.424
      },
      directionResponse: null,
      isLoaded: false,
      circleRadius: 5, // 20km in meters,
      bounds: {}

    }
    this.mapRef = createRef()
    this.circleRef = createRef()
    this.autocomplete = null
    this.handleLoad = this.handleMapLoad.bind(this); //<- this line is essentially "useCallback" for a class component
    this.drawingOnloadFunc = this.drawingOnload.bind(this)
    this.handleCircleBoundsChangedFunc = this.handleCircleBoundsChanged.bind(this)
  }

  componentDidMount() {

    navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);

    if (!this.state.isLoaded) {
      this.loadMapsLibrary();
    }

  }
  successCallback = (position) => {
    console.log(position);
    if (position && position.coords && position.coords.latitude) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      this.setState({ markerPositions: { lat, lng }, center: { lat, lng }, zoom: 7 }, () => {
        this.getBoundariesCenterRadius()
      })

    }

  };

  errorCallback = (error) => {
    console.log(error);
  };
  handleClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    // Set the marker position state
    this.setState({ markerPositions: { lat, lng }, center: { lat, lng } }, () => {
      this.getBoundariesCenterRadius()
    })
    console.log(lat, lng)
    // Use Geocode to get the location details
    Geocode.fromLatLng(lat, lng, process.env.REACT_APP_GOOGLE_MAP_API_KEY).then(
      (response) => {
        const address = response.results[0].formatted_address;
        console.log(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  handleAddressSubmit = (address) => {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY)
    Geocode.setLanguage("en");

    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng)
        // this.setState({ markerPosition: { lat, lng } })

      },
      (error) => {
        console.error(error);
      }
    );
  };
  onPlaceSelected = () => {
    const places = this.autocomplete.getPlaces()
    if (places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      console.log(lat, lng)
      this.setState({ zoom: 6, selectedPlace: place })
      this.setState({ markerPositions: { lat, lng }, center: { lat, lng } }, () => {
        this.getBoundariesCenterRadius()
      })
      this.handleAddressSubmit(place.formatted_address)
    }
    console.log(places)

  };

  onLoad = (autocomplete) => {
    console.log('autocomplete: ', autocomplete)

    this.autocomplete = autocomplete
  }
  handleMapLoad(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(this.state.center);
    console.log(bounds)
    // map.fitBounds(bounds);
    const divElement = document.getElementById('infoDiv')
    map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
      divElement
    );
  };

  componentDidUpdate(props, state) {
    // if (this.state.selectedPlace !== state.selectedPlace) {
    //   const bounds = new window.google.maps.LatLngBounds();

    // }
  }
  clearMarkers = () => {
    this.setState({ markerPositions: {} })
  };

  loadMapsLibrary() {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=drawing,places`;
      script.onload = () => {
        const { google } = window;
        this.setState({ isLoaded: true });
        this.getBoundariesCenterRadius()
        resolve(google);
      };
      script.onerror = () => {
        reject(new Error('Failed to load Google Maps library.'));
      };
      document.head.appendChild(script);
    });

  }

  getBoundariesCenterRadius = () => {

    let obj = {
      center: this.state.center,
      radius: this.state.circleRadius
    }
    this.props.fetchMapCoordinates(obj)


    // const circle = new window.google.maps.Circle({
    //   center: this.state.center,
    //   radius: this.state.circleRadius,
    // });
    // const bounds = circle.getBounds()?.toJSON();
    // console.log("bounds", bounds)

    // const bounds1 = new window.google.maps.LatLngBounds(
    //   new window.google.maps.LatLng(bounds.south, bounds.west), // Southwest corner of the bounds
    //   new window.google.maps.LatLng(bounds.north, bounds.east) // Northeast corner of the bounds
    // );

    // const center = bounds1.getCenter();

    // // const radius = window.google.maps.geometry.spherical.computeDistanceBetween(center, bounds1.getNorthEast());

    // console.log('Center:', center, center.lat(), center.lng());
    // console.log(this.state.center)

    // // console.log('Radius:', radius);
  }

  drawingOnload(drawingManager) {

    console.log(drawingManager, "drawingManagerdrawingManager")
  }


  onCircleComplete = (circle) => {
    console.log(circle)
    console.log(circle.getBounds()?.toJSON(), circle.getCenter(), circle.getRadius())
    const shape = circle.overlay;
    this.setState({
      bounds: circle.getBounds()?.toJSON()
    })




  }

  handleCircleBoundsChanged(circle) {
    console.log(circle)
  };
  handleRadiusChanged = (event) => {
    const newBounds = this.circleRef.current;
    console.log(new window.google.maps.Circle)
    if (newBounds) {
      console.log(newBounds.state, newBounds.state.circle.getRadius())
      console.log(newBounds.state, newBounds.state.circle.getBounds())
      this.setState({
        bounds: newBounds.state.circle.getBounds()?.toJSON(),
        circleRadius: newBounds.state.circle.getRadius()
      }, () => {
        this.getBoundariesCenterRadius()
      })
    }

  }
  getMiles = (meters) => {
    const miles = (meters * 0.000621371192);
    const feet = (miles * 5280);
    if (miles < 1) {
        return `${feet.toFixed(1)} Feet`
    } else {
        return `${miles.toFixed(1)} Miles` 
    }
}
  render() {
    const { isLoaded } = this.state;
    console.log(isLoaded)
    const directionsOptions = {
      directions: this.state.directionResponse,
    };

    return (
      <div className='GoogleMap'>
        {isLoaded ?
          // <LoadScript
          //   googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          //   libraries={["places", "drawing"]}
          // >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={this.state.center}
            zoom={this.state.zoom}
            onClick={this.handleClick}
            onLoad={this.handleLoad}

          >

            {/* {this.state.markerPositions.map((marker, index) => ( */}
            <Marker position={this.state.markerPositions} />
            {/* ))} */}
            <Circle
              ref={this.circleRef}
              center={this.state.center}
              radius={this.state.circleRadius}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
              }}
              editable={true}
              draggable={undefined}
              onCenterChanged={this.handleCircleBoundsChangedFunc}
              onRadiusChanged={this.handleRadiusChanged}
            />
            
              <div
                style={{
                  background: '#9E66BF',
                  padding: '15px',
                  borderRadius: '5px',
                  position: 'absolute',
                  bottom: '30px',
                  left: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                id="infoDiv"
              >
                {/* <div
                  style={{
                    background: '#FF0000',
                    width: '10px',
                    height: '10px',
                    marginRight: '5px',
                  }}
                ></div> */}
                <span>Radius: {this.getMiles(this.state.circleRadius)}</span>
              </div>
              {/* <div
                  style={{
                  background: '#FFF',
                  padding: '5px',
                  borderRadius: '5px',
                  position: 'static',
                  bottom: '30px',
                  left: '10px',
                  color: 'black'
                }}
              >
                Radius: {this.state.circleRadius.toFixed(1)} meters
              </div> */}
            {/* <DrawingManager
                onLoad={this.drawingOnloadFunc}
                onCircleComplete={this.onCircleComplete}
                drawingMode={window.google.maps.drawing.OverlayType.CIRCLE}
                options={{
                  drawingControl: true,
                  drawingControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
                  },
                  circleOptions: {
                    editable: true,
                    draggable: true,
                  },
                }}
                // drawingMode={window.google.maps.drawing.OverlayType.CIRCLE}
              /> */}


            { /* Child components, such as markers, info windows, etc. */}
            <StandaloneSearchBox
              onLoad={this.onLoad}
              onPlacesChanged={
                this.onPlaceSelected
              }
            ><input
                type="text"
                placeholder="Search places"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  marginTop: `27px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: `relative`,
                  margin: `0 auto`,
                  display: `block`
                }}
              />

            </StandaloneSearchBox>
          </GoogleMap>

          // </LoadScript>
          : ''}
      </div>
    )

  }
}

export { MapComponent }