import { midwestconConstants } from "../../constants";

const initialState = {
    midwestEvents : [],
    addedMidwestEvent : [],
    updatedMidwestEvent : [],
    getMidwestEventDetail : [],
    e_galleryAdded : [],
    eventGallery : [],
    deletedEventGallery : {},
    e_gallery_detail : []
}
export function midwestcon(state=initialState,action){
    switch(action.type){
        case midwestconConstants.ADMIN_GET_MIDWEST_EVENTS:
            return {
                ...state,
                midwestEvents : action.midwestEvents
            }
        case midwestconConstants.ADMIN_ADD_MIDWEST_EVENTS:
            return {
                ...state,
                addedMidwestEvent : action.addedMidwestEvent
            }
        case midwestconConstants.ADMIN_EDIT_MIDWSET_EVENTS:
            return{
                ...state,
                updatedMidwestEvent : action.updatedMidwestEvent
            }
        case midwestconConstants.ADMIN_GET_MIDWEST_EVENT_DETAIL:
            return {
                ...state,
                getMidwestEventDetail : action.getMidwestEventDetail
            }
        case midwestconConstants.ADMIN_ADD_MIDWEST_EVENT_GALLERY:
            return {
                ...state,
                e_galleryAdded : action.e_galleryAdded
            }
        case midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY:
            return {
                ...state,
                eventGallery : action.eventGallery
            }
        case midwestconConstants.ADMIN_DELETE_MIDWEST_EVENT_GALLERY:
            return {
                ...state,
                deletedEventGallery : action.deletedEventGallery
            }
        case midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY_DETAIL:
            return {
                ...state,
                e_gallery_detail : action.e_gallery_detail
            }
     
            default:
                return state
    }
}