import { walletConstants } from "../../constants"
import PubSub from 'pubsub-js';
import { walletServices } from "services/wallet.service";


export const walletActions = {
    linkWallet,
    resetlinkWallet,
    checkWallet,
    resetWalletLinkError,
    walletError,
    resetWalletError,
    walletSuccess,
    resetWalleSuccess,

}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
} 

function linkWallet(obj){
    return dispatch => {
        showLoader()
        walletServices.linkWallet(obj)
        .then(wallet => {
            if((wallet && wallet.statusCode) === 200){
                let walletDetail = JSON.stringify(wallet)
                    localStorage.setItem("walletDetail",walletDetail)
                dispatch({type:walletConstants.WALLET_LINK_ACCOUNT,wallet})
                hideLoader()
            }
        },error => {
            hideLoader()
            dispatch({type:walletConstants.WALLET_LINK_FAILED,walletlinkErr : error})
        })
    }
}
function resetlinkWallet(){
    return dispatch => {
        dispatch({type:walletConstants.WALLET_LINK_ACCOUNT,wallet:[]})
    }
}

function resetWalletLinkError(){
    return dispatch => {
        dispatch({type:walletConstants.WALLET_LINK_FAILED,walletlinkErr : []})
    }
}
 
function checkWallet(address){
    return dispatch => {
        showLoader()
        walletServices.checkWallet(address)
        .then(wallet => {
            if((wallet && wallet.statusCode) === 200){
                dispatch({type:walletConstants.GET_WALLET_ACCOUNT,checkWallet : wallet})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}

function walletError(mes){
    return dispatch => {
        dispatch({type : walletConstants.WALLET_ERROR, walleterr : mes})
    }
}

function resetWalletError(){
    return dispatch => {
        dispatch({type:walletConstants.WALLET_ERROR,walleterr : []})
    }
}

function walletSuccess(mes){
    return dispatch => {
        dispatch({type : walletConstants.WALLET_SUCCESS, walletsuc : mes})
    }
}
function resetWalleSuccess(){
    return dispatch => {
        dispatch({type:walletConstants.WALLET_SUCCESS,walletsuc : []})
    }
}

