import { transactionConstants } from "../../constants"
import { txnServices } from "services"
import PubSub from 'pubsub-js';

export const txnActions = {
   
    getTxnHistory,
    resetGetTxnHistory,
    resetGetTxnHistoryFailed,
    forsaleEventHistoryInitiate,
    resetEventHistoryInitiated,
    resetEventHistoryInitiatedFailed,
    forsaleEventHistoryCancelled,
    resetEventCancelled,
    resetEventCancelledFailed,
    forsaleEventHistoryFailure,
    resetEventFailure,
    resetEventFailureFailed,
    forsaleEventHistoryTransferSuccess,
    resetEventTransaferSuccess,
    resetEventTransferFailed,
    forsaleEventHistoryListStage,
    resetlistStageEvent,
    resetlistStageEventFailed,
    forsaleEventHistorylistSuccess,
    resetlistsuccess,
    resetlistfailed
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getTxnHistory(obj){
    return dispatch => {
        showLoader()
        txnServices.getTxnHistory(obj)
        .then(res => {
            hideLoader()
            dispatch({type: transactionConstants.GET_TRANSACTION_HISTORY,gettxnHistory : res})
        },error => {
            dispatch({type : transactionConstants.GET_TRANSACTION_HISTOTY_FAILED,gettxnFailed:error})
            hideLoader()
        })
    }
}
function resetGetTxnHistory(){
    return dispatch => {
        dispatch({type : transactionConstants.GET_TRANSACTION_HISTORY,gettxnHistory :[]})
    }
}
function resetGetTxnHistoryFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.GET_TRANSACTION_HISTOTY_FAILED,gettxnFailed:''})
    }
}
//drop-history-management

function forsaleEventHistoryInitiate(obj){
    return dispatch => {
        txnServices.forsaleEventHistoryInitiate(obj)
        .then(res => {
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_INITIATED,eventInitiated : res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_INITIATED_FAILED,eventInitiatedFailed:error})
        })
    }
}
function resetEventHistoryInitiated(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_INITIATED,eventInitiated :[]})
    }
}
function resetEventHistoryInitiatedFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_INITIATED_FAILED,eventInitiatedFailed:''})
    }
}

function forsaleEventHistoryCancelled(id){
    return dispatch => {
        // showLoader()
        txnServices.forsaleEventHistoryCancelled(id)
        .then(res => {
            // hideLoader()
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED,eventCancelled: res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED_FAILED,eventCancelledFailed:error})
            // hideLoader()
        })
    }
}
function resetEventCancelled(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED,eventCancelled :[]})
    }
}
function resetEventCancelledFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_CANCELLED_FAILED,eventCancelledFailed:''})
    }
}

function forsaleEventHistoryFailure(id){
    return dispatch => {
        txnServices.forsaleEventHistoryFailure(id)
        .then(res => {
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_FAILURE,eventFailured: res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_FAILURE_FAILED,eventFailuredFailed:error})
        })
    }
}
function resetEventFailure(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_FAILURE,eventFailured :[]})
    }
}
function resetEventFailureFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_FAILURE_FAILED,eventFailuredFailed:''})
    }
}

function forsaleEventHistoryTransferSuccess(id){
    return dispatch => {
        txnServices.forsaleEventHistoryTransferSuccess(id)
        .then(res => {
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_COMPLETE,eventTransferSuccess: res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_FAILED,eventTransferFaied:error})
        })
    }
}
function resetEventTransaferSuccess(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_COMPLETE,eventTransferSuccess :[]})
    }
}
function resetEventTransferFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_TRANSFER_FAILED,eventTransferFaied:''})
    }
}
function forsaleEventHistoryListStage(obj,id){
    return dispatch => {
        txnServices.forsaleEventHistoryListStage(obj,id)
        .then(res => {
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING,listStaged: res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING_FAILED,listStagedFailed:error})
        })
    }
}
function resetlistStageEvent(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING,listStaged :[]})
    }
}
function resetlistStageEventFailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LIST_STAGING_FAILED,listStagedFailed:''})
    }
}
function forsaleEventHistorylistSuccess(id){
    return dispatch => {
        showLoader()
        txnServices.forsaleEventHistorylistSuccess(id)
        .then(res => {
            hideLoader()
            dispatch({type: transactionConstants.FORSALE_EVENT_HISTORY_LISTING_SUCCESS,listsuccess: res})
        },error => {
            dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LISTING_FAILED,listFailed:error})
            hideLoader()
        })
    }
}
function resetlistsuccess(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LISTING_SUCCESS,listsuccess :[]})
    }
}
function resetlistfailed(){
    return dispatch => {
        dispatch({type : transactionConstants.FORSALE_EVENT_HISTORY_LISTING_FAILED,listFailed:''})
    }
}