import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet';

import {Header,Sidebar} from '../Components'





class AdminLayout extends Component{
    constructor(props){
        super(props);
        this.state = {
            open:false
        }
    }
    openSidebar = () => {
        this.setState({open: !this.state.open})
    }

    render(){
        const {children} = this.props
        return (
            <Fragment>
                <Helmet>
                <title>{`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Admin`}</title>
                    <meta name="title" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Admin`}></meta>
                    <meta name="description" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Admin`}></meta>
                </Helmet>
              <Header openSidebar={this.openSidebar}/>
             {this.state.open ? 
             
              <main className="content-shift">{children}</main> : <main className="content-not-shift">{children}</main>}

              
                <Sidebar open={this.state.open}/>
            </Fragment>
        )
    }
}

export {AdminLayout}