import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { artActions, dropActions, userAction } from 'store/actions';
import { configPath } from "../../config";
import './Creatorstore.css'

class CreatorStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStoreStatus: false
		}
	}
	gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
	componentDidMount(){
		this.gotoTop()
	}

	componentWillUnmount() {
		document.body.classList.remove('body-color')
	}
	render() {
		const { isStoreStatus } = this.state
		return (
			<Fragment>
				{/* {(isStoreStatus) ?  */}

				<Helmet>
					<title>{"Store Dashboard"}</title>
					<meta name="title" content={`Dashboard`}></meta>
					<meta name="description" content={`Dashboard`}></meta>
				</Helmet>
				<div className='ds-mylist'>
					<div className='container-fluid'>
						<div className='row mobtxt'>
							<div className='col-12'>
								<div className='ds-weltxt'>
									<h1>Welcome to DisruptArt Store</h1>
									<p>Hello {this.state.fullname}, Welcome back!</p>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-8'>
								<div className='ds-coverimg'>
									<img src={`/${configPath.imageAssets}/slider/header-5.jpg`} alt="" />
									<div className='ds-userdetail'>
										<div className='row'>
											<div className='col-md-12'>
												<div className='ds-weltxt'>
													<h2 className='text-uppercase'>Create and sell your NFT</h2>
												</div>
												<a href='/mint' target="_blank" className='btn btn-primary-filled'>Create NFT</a>
											</div>

										</div>
									</div>
								</div>
							</div>
							<div className='col-sm-4'>
								<div className='ds-mystatsbg'>
									<div className='row'>
										<div className='col-md-8'>
											<div className='ds-mystats'>
												<h3>My Stats</h3>
												<div className='row'>
													<div className='col-6'>
														<p className='small'>Today</p>
														<p>4 Orders</p>
													</div>
													<div className='col-6'>
														<p className='small'>This Month</p>
														<p>173 Orders</p>
													</div>
												</div>
												<div className='gototxt'>
													<a href="">Go to my orders <i className='fa fa-long-arrow-right'></i></a>
												</div>
											</div>
										</div>
										<div className='ds-msimg'>
											<img src={`/${configPath.imageAssets}/nft_art.png`} alt="" />
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className='row'>
							<div className='col-md-8'>
								<div className='ds-sectit'>
									<div className='row align-items-center'>
										<div className='col-8'><h3>My Collections</h3></div>
										<div className='col-4 text-right'><a href="">View all <i className='fa fa-angle-right'></i></a></div>
									</div>
								</div>
								<div className='ds-listing'>
									<div className="row">
										<a className="col-sm-4" href="">
											<span className="ds-list">
												<span><img src="https://ipfs.perma.store/content/bafybeib5ncrlej6yb2pqyplacmyora2p62yuzas4t3tbjil4wn4ixsuvpe" alt="" /></span>
												<span className="ds-list-details">
													<h4>DESPAIR</h4>
													<p>Edition 1</p>
												</span>
											</span>
										</a>
										<a className="col-sm-4" href="">
											<span className="ds-list">
												<span><img src="https://ipfs.perma.store/content/bafybeihuc2m3y4qgwywydaex4oezhbrgn6spickexmpsdozmhux6elbb7e" alt="" /></span>
												<span className="ds-list-details">
													<h4>BLACK REVOLT</h4>
													<p>Edition 1</p>
												</span>
											</span>
										</a>
										<a className="col-sm-4" href="">
											<span className="ds-list">
												<span><img src="https://ipfs.perma.store/content/bafybeigg4bavvonrqrmx6usxnqn2oiuktbgnxut77bn5xruhzkgq4ltgoq" alt="" /></span>
												<span className="ds-list-details">
													<h4>The stone man</h4>
													<p>Edition 1</p>
												</span>
											</span>
										</a>
									</div>
								</div>

							</div>
							<div className='col-md-4'>
								<div className='ds-sectit'>
									<div className='row align-items-center'>
										<div className='col-8'><h3>My Activity</h3></div>
										<div className='col-4 text-right'><a href="">View all <i className='fa fa-angle-right'></i></a></div>
									</div>
								</div>
								<div className='ds-actilists'>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
									<div className='ds-aclist'>
										<div className='ds-acimg'>
											<span className='ds-plimg'></span>
										</div>
										<div className='ds-actext'>
											<h5>Ageratum houstonianum</h5>
											<p>Orderd new nft</p>
										</div>
										<div className='ds-achours'>3 mins ago </div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

				{/* : ''} */}
			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		usersList: state.user.usersList,
	}
}
const actionCreators = {
	getUserProfile: userAction.getUsers,
	getUserArts: artActions.getUserArts,
	getUserDrop: dropActions.getUserDrop,
	resetAllArts: artActions.resetAllArts,
	resetAllDrops: dropActions.resetAllDrops,
}
const connectCreatorStore = connect(mapStateToProps, actionCreators)(CreatorStore)
export { connectCreatorStore as CreatorStore }