
import { configPath } from '../config'
import React, { Fragment } from 'react';
 
const Setupflowuiloader = () =>
{
    return  <Fragment>
    <div className="show-content">
             <div className="child-show purchasebox">
                 <p className="text-white">Accessing your Flow wallet account</p>
                 <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
 
             </div>
         </div>
         </Fragment>
}
export {Setupflowuiloader}