import React from "react";
import { ProfilePreview } from './profilePreview'
import history from 'helpers/history'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { configPath } from "../config";
export class DALandingCreators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
            responsive: {
                0: { items: 1 },
                476: { items: 2 },                
                576: { items: 3 },
                768: { items: 3 },               
                1920: { items: 3 }

            }
        }

    }


    componentDidMount() {
        if ((this.props && this.props.featureCreators)) {

            const basePath = process.env.REACT_APP_BASEPATH



            const creatorsProfile = this.props && this.props.featureCreators
            console.log(creatorsProfile)
            const featureCreatorsArr1 = creatorsProfile && creatorsProfile.map((item, index) => {
                return <span className='daalist' onClick={(e) => { e.preventDefault(); history.push(`/creator/@${item && item.userName && encodeURIComponent(item.userName)}`) }} key={(item && item.uuid) ? (item.uuid + index) : ''}>
                        <span className='bg'><img src={`${basePath}/assets/images/landing/da-list-bg.png`} alt='' /></span>
                        <span className='da-listcont'>
                            <span className='da-lccover'>
                                {this.getBanner(item)}

                            </span>
                            <span className='da-lcproandcont'>
                                <span className='da-lcprof'>
                                    <ProfilePreview src={item && item.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${item.profileImage}`} alt="" />

                                </span>
                                <p className='usernamelilac'>{(item && item.userName) ? `@${item.userName}` : ''}</p>
                                <p className=''>{(item && item.fullName) ? `${item.fullName}` : ''}</p>
                            </span>
                        </span>
                    </span>
                
            })


            this.setState({ creatorsProfile, featureCreatorsArr1 })
            // })
        }


    }
    getBanner = (item) => {
        if (item && item.bannerImage && item.bannerImage != null) {
            return <img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${item.bannerImage}`} alt=""></img>

        } else {
            return <img src={`../${configPath.imageAssets}/cover-default.png`} alt=""></img>

        }

    }
    render() {
        return (
            <div>
                {(this.state.featureCreatorsArr1 && this.state.featureCreatorsArr1.length > 1) ?
                    <AliceCarousel
                        autoPlay={false}
                        autoPlayInterval={4000}
                        items={this.state.featureCreatorsArr1}
                        infinite
                        responsive={this.state.responsive}
                        autoPlayDirection={"ltr"}
                        mouseTracking={true}
                        disableDotsControls
                    >

                    </AliceCarousel> :
                    (this.state.featureCreatorsArr1)
                }
            </div>

        )
    }
}
