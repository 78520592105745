import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { configPath } from "../../config";
import './Creatorstore.css'
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from 'store/actions';
import { getUniqueUUID, isWebLogin } from 'helpers';
import history from 'helpers/history';
import { Link, NavLink } from 'react-router-dom'
import { toast, Zoom } from 'react-toastify';
import { isConnectWallet, userBalance } from 'helpers';
import { isDapperConnected } from 'helpers/switchauthdapper';

class CreatorStoreSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fullname: '',
			isStoreStatus: false
		}
	}
	
	showLoader() {
		PubSub.publish('msg', true);
	}

	hideLoader() {
		PubSub.publish('msg', false);
	}
	componentDidMount() {
		if (!(isWebLogin())) {
			history.push('/login')
		} else {
			this.props.getUserProfile()
		}
	}
	
	componentDidUpdate(props,state){
		console.log(window.location.pathname)
		if((this.props && 
			this.props.usersList && 
			this.props.usersList.statusCode === 200) && 
			(this.props.usersList) !== (props.usersList)
		){
			let data = this.props && this.props.usersList && this.props.usersList.data
			this.setState({
                fullname: data.fullName ? data.fullName : '',
                image: data.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.profileImage}` : null
            })
			this.props.resetUserProfile()
			if(this.props.usersList.data && 
				(this.props.usersList.data.roleId === 1) && 
				(this.props.usersList.data.storeStatus === 1) && 
				(this.props.usersList.data.storeRequestStatus === 4)
				){
					return true
			}else{
				history.push('/')
			}
		}
		if (this.props && this.props.getprofileFailed &&
            this.props.getprofileFailed.length > 0 && (this.props.getprofileFailed !== props.getprofileFailed)) {
            this.props.resetGetprofileFailed()
            toast.error(this.props.getprofileFailed, { transition: Zoom })
        }		
	}

	render() {
		const {pathname,open} = this.props		
		return (
			<Fragment>
					<div className={open ? "ds-sidebar ds-mobside" : 'ds-sidebar'} >
						<div className='ds-logo'>
							<img src={`/${configPath.imageAssets}/dastore.png`} alt="Disrupt Now" className="logoaw" />
						</div>
						<div className='dssscroll'>
						<div className='ds-menu mobtxt mscname'>
							<h5 className='mb-0'>{this.state.fullname}</h5>
							<ul className='list-unstyled sdwallet'>
                                {isConnectWallet() ? <li>{isDapperConnected() ? 
                                            <span className='mb-0'><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="Disrupt Now" className="logoaw " />{this.state.address}</span> : 
                                            <span><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="Disrupt Now" className="logoaw "/> 
                                            {this.state.address}</span>}</li> : ''}
                                            </ul> 

											<div>{isConnectWallet() && <span className="nav-link cursor-pointer" title={"Switch wallet account"}>
                                                    <span className="nav-link" onClick={this.handleToSwitchAccount}><i aria-hidden="true" className="fa fa-exchange mr-2"></i> Switch</span>
                                                </span>}</div>  
						</div>
						<div className='ds-menu'>
							<ul className='list-unstyled'>
							<li className={pathname && pathname.includes('creatorstore') ? "active d-none" : 'd-none'}><NavLink to="/creatorstore"><i className='fa fa-dashboard'></i> Dashboard &nbsp;<span>(Soon)</span></NavLink></li>
								{/* <li className={pathname.includes('creatorstore') ? "active" : ''}><a href='/creatorstore'><i className='fa fa-dashboard'></i> Dashboard</a></li> */}
								<li className={pathname && pathname.includes('salehistory') ? "active d-none" : 'd-none'}><NavLink to="/store/salehistory"><i className='fa fa-comment-dollar'></i> Sales Report</NavLink></li>
								<li className={pathname && pathname.includes('adddrops') ? "active" : ''}><NavLink to="/store/adddrops"><i className='fa fa-fire'></i> Add Drops</NavLink></li>
								<li className={pathname && pathname.includes('storedrops') ? "active" : ''}><NavLink to="/store/storedrops"><i className='fa fa-fire'></i> On Sale</NavLink></li>

							</ul>
						</div>
						<h6>Settings</h6>
						<div className='ds-menu'>
							<ul className='list-unstyled'>
						
							<li className={pathname && pathname.includes('storeprofile') ? "active d-none" : 'd-none'}><Link to="/store/storeprofile"><i className='fa fa-cog'></i> Store Profile &nbsp;<span>(Soon)</span></Link></li>
							<li className={pathname && pathname.includes('store/section') ? "active" : ''}><Link to="/store/section"><i className='fa fa-list-alt'></i> NFT Sections &nbsp;</Link></li>
							<li className={pathname && pathname.includes('/store/purchasedata') ? "active" : ''}><Link to="/store/purchasedata"><i className='fa fa-store'></i> Purchase Report &nbsp;</Link></li>
							<li className={pathname && pathname.includes('dynamic') ? "active d-none" : 'd-none'}><Link to="/store/dynamicsection"><i className='fa fa-list-alt'></i> CMS &nbsp;</Link></li>
							<li className={pathname && pathname.includes('metatag') ? "active d-none" : 'd-none'}><Link to="/store/metatags"><i className='fa fa-search'></i> Meta Tags &nbsp;</Link></li>

							{/* <li className={pathname && pathname.includes('banner') ? "active" : ''}><Link to="/store/banner"><i className='fa fa-picture-o'></i> Store Banner &nbsp;<span>(Soon)</span></Link></li>
							<li className={pathname && pathname.includes('conditions') ? "active" : ''}><Link to="/store/conditions"><i className='fa fa-info-circle'></i> Store Conditions &nbsp;<span>(Soon)</span></Link></li> */}

								{/* <li className={pathname.includes('settings') ? "active" : ''}><a href='/store/settings'><i className='fa fa-cog'></i> Settings</a></li> */}
								<li className='d-none'><a href=''><i className='fa fa-pie-chart'></i> Chart &nbsp;<span>(Soon)</span></a></li>
								<li className='d-none'><a href=''><i className='fa fa-line-chart'></i> Trends &nbsp;<span>(Soon)</span></a></li>
							</ul>
						</div>
						<hr className='lightline mobtxt' />
						<div className='ds-menu mobtxt'>
							<ul className='list-unstyled'>
								<li><a href=''><i className='fa fa-sign-out'></i> Logout</a></li>
							</ul>
						</div>
						</div>
					</div>
					
			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		usersList: state.user.usersList,
        getprofileFailed: state.creatorStore.getprofilefailed

	}
}
const actionCreators = {
	getUserProfile: userAction.getUsers,
	resetUserProfile : userAction.resetGetUsers,
	getUserStoreProfile: creatorStoreActions.getStoreCreatorProfile,
    resetGetProfile: creatorStoreActions.resetGetStoreCreatorProfile,
    resetGetprofileFailed: creatorStoreActions.resetGetStoreCreatorProfilefailed

}
const connectCreatorStoreSidebar = connect(mapStateToProps, actionCreators)(CreatorStoreSidebar)
export { connectCreatorStoreSidebar as CreatorStoreSidebar }
