import { isConnectWallet, isWebLogin } from 'helpers';
import history from 'helpers/history';
import { checkTourCompleted, resetToTrue, stopTourGuide } from 'helpers/joy-rideCheck';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import Joyride, { CallBackProps, STATUS, EVENTS, ACTIONS } from 'react-joyride';
import { connect } from 'react-redux';
import { joyrideActions, userAction } from 'store/actions';
import { getUserAddress } from 'utils';

const test_steps = [
    {
        content: `Info 1`,
        target: '.discover',
        title: 'Dicover',
        disableBeacon: true,
        placement:"left",
        spotlightPadding: 0,
    },
    {
        content: 'Info 2',
        spotlightPadding: 0,
        target: '.drops',
        placement : "left",
        
    },
]
const home_steps = [
    // {
    //     content: `Info 1`,
    //     target: '.discover',
    //     title: 'Dicover',
    //     disableBeacon: true,
    //     placement:"left",
    //     spotlightPadding: 0,
    // },
    // {
    //     content: 'Info 2',
    //     spotlightPadding: 0,
    //     target: '.drops',
    //     placement : "left",
        
    // },
    {
        content: 'Login and create a profile',
        styles: {
            options: {
                width: 300,
            },
        },
        target: '.login_dist',
        title: 'Login',
        placement : "left",
        spotlightPadding: 0,
        disableBeacon: true,
        spotlightClicks : true,
    },
    {
        content: <div><p>Choose Blocto and enter your same email address as login. 
        </p><p>Verify with the code sent and Get Your Flow Address </p></div>,
        styles: {
            options: {
                width: 300,
            },
        },
        target: '.connect_wallet',
        title: 'Connect wallet',
        placement : "left",
        disableBeacon: true,
        spotlightClicks : true,
        spotlightPadding: 2,
    },
    {
        content: 'Claim first NFT go to Community-Air drops. Claim to receive a free collectible as a gift for joining.',
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 5,
        target: '.community_airdrop',
        title: 'Airdrop',
        placement : "left",
        disableBeacon : true,
        spotlightClicks : true,
    },
    {
        content: 'Fund your Wallet go Coinbase send money to your flow address! (We might show the copied Flow address to show them where to send money).',
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 2,
        target: '.fund_wallet_menu',
        title: 'Fund your Wallet ',
        placement : "left",
        spotlightClicks : true,
        
    },

]
const home_login_steps = [
    // {
    //     content: `Info 1`,
    //     target: '.discover',
    //     title: 'Dicover',
    //     disableBeacon: true,
    //     placement : "left"
    // },
    // {
    //     content: 'Info 2',
    //     floaterProps: {
    //         disableAnimation: true,
    //     },
    //     spotlightPadding: 2,
    //     target: '.drops',
    //     placement : "left"
    // },
    {
        content: <div><p>Choose Blocto and enter your same email address as login. 
        </p><p>Verify with the code sent and Get Your Flow Address </p></div>,
        styles: {
            options: {
                width: 300,
            },
        },
        target: '.connect_wallet',
        title: 'Connect wallet',
        placement : "left",
        disableBeacon: true,
        spotlightClicks : true,
        spotlightPadding: 2,
    },
   
    // {
    //     content: 'Claim first NFT go to Community-Air drops. Claim to receive a free collectible as a gift for joining.',
    //     styles: {
    //         options: {
    //             width: 300,
    //         },
    //     },
    //     spotlightPadding: 2,
    //     target: '.community_airdrop',
    //     title: 'Airdrop',
    //     placement : "left",
    //     spotlightClicks : true,
    //     disableBeacon: true,
    
    // },
    
]
const home_wallet_steps = [
    // {
    //     content: `Info 1`,
    //     target: '.discover',
    //     title: 'Dicover',
    //     disableBeacon: true,
    //     placement : "left"
    // },
    // {
    //     content: 'Info 2',
    //     floaterProps: {
    //         disableAnimation: true,
    //     },
    //     spotlightPadding: 2,
    //     target: '.drops',
    //     placement : "left"
    // },
    // {
    //     content: 'Info 3',
    //     styles: {
    //         options: {
    //             width: 300,
    //         },
    //     },
    //     spotlightPadding: 2,
    //     target: '.flow_address',
    //     title: 'Flow Address',
    //     placement : "left",
        
    // },
    {
        content: 'Claim first NFT go to Community-Air drops. Claim to receive a free collectible as a gift for joining.',
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 5,
        target: '.community_airdrop',
        title: 'Airdrop',
        placement : "left",
        disableBeacon : true,
        spotlightClicks : true,
    },
    {
        content: 'Fund your Wallet go Coinbase send money to your flow address! (We might show the copied Flow address to show them where to send money).',
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 2,
        target: '.fund_wallet_menu',
        title: 'Fund your Wallet ',
        placement : "left",
        spotlightClicks : true,
        
    },

]
const drop_steps = [
    {
        content: <span></span>,
        target: '.drops_title',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'right',
    },
    {
        content:'',
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.listed_nft',
        title: <h4 className='designLink'>Info2</h4>,
        placement: 'right',
        disableBeacon: true,


    },
    {
        content:'',
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.created_by',
        title: <h4 className='designLink'>Info3</h4>,
        placement: 'right',
        disableBeacon: true,


    },
    {
        content: (
            <div>
                
            </div>
        ),
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.edition_count',
        title: <h4 className='designLink'>Info4</h4>,
        placement: 'right',
        disableBeacon: true,
    },
    {
        content: (
            <div>
               
            </div>
        ),
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.listed_by',
        title: <h4 className='designLink'>Info5</h4>,
        placement: 'right',
        disableBeacon: true,
    },
    
    
]
const purchase_steps = [
    {
        content: `Info 1`,
        target: '.list_price_box',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    {
        content: `Info 2`,
        target: '.for_sale_box',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    // {
    //     content: `Info 3`,
    //     target: '.for_sale_price',
    //     title: 'Our projects',
    //     placement : 'left',
    //     disableBeacon: true,
    // },
    // {
    //     content: `Info 4`,
    //     target: '.for_sale_listed_by',
    //     title: 'Our projects',
    //     placement : 'left',
    //     disableBeacon: true,
    // },
    {
        content: `Info 3`,
        target: '.edition_box',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    {
        content: `Info 4`,
        target: '.edition_box_editions',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    {
        content: `Info 5`,
        target: '.edition_creators',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    {
        content: `Info 6`,
        target: '.edition_collectors',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    {
        content: `Info 7`,
        target: '.activity_box',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
    // {
    //     content: `Info 8`,
    //     target: '.list_price',
    //     title: 'Our projects',
    //     placement : 'left',
    //     disableBeacon: true,
    // },
   
    {
        content: `Info 8`,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.purchased_category_nft',
        placement : 'right'
    },
    {
        content: `Info 9`,
        target: '.purchase_nft',
        title: 'Our projects',
        placement : 'left',
        disableBeacon: true,
    },
]
const bid_steps = [
    {
        content: ``,
        target: '.crnt_bid',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement:"left",
        spotlightPadding : 2
    },
    {
        content: ``,
        target: '.str_bid',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement:"left"
    },
    {
        content: ``,
        target: '.bid_ends_by',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        placement:"left"
    },
    {
        content: ``,
        target: '.bid_nft',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement:"left"
    },
    
]
const art_steps = [
    {
        content: <span></span>,
        target: '.arts_title',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'right'
    },
    {
        content: ``,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.created_by',
        title: <h4 className='designLink'>Info2</h4>,
        placement: 'right',
        disableBeacon: true,


    },
    {
        content: (
            <div>
                
            </div>
        ),
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.edition_count',
        title: <h4 className='designLink'>Info3</h4>,
        placement: 'right',
        disableBeacon: true,
    },
    
]
const own_sale_steps = [
    {
        content: <span></span>,
        target: '.change_prc',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
    {
        content: ``,
        floaterProps: {
            disableAnimation: true,
        },
        spotlightPadding: 2,
        target: '.wtdrw',
        title: <h4 className='designLink'>Info2</h4>,
        placement: 'left',
        disableBeacon: true,


    },
    // {
    //     content: (
    //         <div>
    //             <i></i>
    //         </div>
    //     ),
    //     floaterProps: {
    //         disableAnimation: true,
    //     },
    //     spotlightPadding: 2,
    //     target: '.updt_cat_tags',
    //     title: <h4 className='designLink'>Info3</h4>,
    //     placement: 'right',
    //     disableBeacon: true,
    // },
    
]
const own_auction_steps = [
    {
        content: <span></span>,
        target: '.auc_crt_name',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.auc_visibility',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.revk',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.sett_bids_history',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.auc_activity_history',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    
]
const own_cancel_auction_steps = [
    {
        content: <span></span>,
        target: '.abort_auction',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
]
const own_list_auction_steps = [
    {
        content: <span></span>,
        target: '.sell_art',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.auction_art',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.airdrop_art',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.category_art',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement : 'right'
    },{
        content: <span></span>,
        target: '.update_category_art',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement : 'right'
    },
]
const my_wallet_steps = [
    {
        content: <span></span>,
        target: '.collection_arts',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.onsale_arts',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
]
const airdrop_steps = [
    {
        content: <span></span>,
        target: '.airdrop_title',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.claim_btn',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'right'
    },
]
const own_auction_settlement_steps = [
    {
        content: <span></span>,
        target: '.auc_crt_name',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.auc_visibility',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.settlement_art',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.final_bid_amount',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement : 'left',
        spotlightPadding : 2

    },
    {
        content: <span></span>,
        target: '.sett_bids_history',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.auc_activity_history',
        title: <h4 className='designLink'>Info6</h4>,
        disableBeacon: true,
        placement : 'left'
    },
]
const fund_wallet_page_steps = [
    {
        content: <span></span>,
        target: '.wallet_message',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.coin_base_box',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.moon_pay_box',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.currency_box1',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.currency_box2',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.currency_box3',
        title: <h4 className='designLink'>Info6</h4>,
        disableBeacon: true,
        placement : 'left'
    },
]
const artDetailSteps = [
    {
        content: ``,
        target: '.art_crt_name',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement:"right",
        spotlightPadding : 2
    },
    {
        content: ``,
        target: '.art_tot_edition',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement:"left"
    },
    {
        content: ``,
        target: '.art_crt',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        placement:"left"
    },
    {
        content: ``,
        target: '.art_coll',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement:"left"
    },
    {
        content: ``,
        target: '.art_activity',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement:"left"
    },
    
]
const auction_claim_steps = [
    {
        content: <span></span>,
        target: '.auc_crt_name',
        title: <h4 className='designLink'>Info1</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.auc_visibility',
        title: <h4 className='designLink'>Info2</h4>,
        disableBeacon: true,
        placement : 'right'
    },
    {
        content: <span></span>,
        target: '.settlement_art',
        title: <h4 className='designLink'>Info3</h4>,
        disableBeacon: true,
        disableScrolling: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.final_bid_amount',
        title: <h4 className='designLink'>Info4</h4>,
        disableBeacon: true,
        placement : 'left',
        spotlightPadding : 2

    },
    {
        content: <span></span>,
        target: '.sett_bids_history',
        title: <h4 className='designLink'>Info5</h4>,
        disableBeacon: true,
        placement : 'left'
    },
    {
        content: <span></span>,
        target: '.auc_activity_history',
        title: <h4 className='designLink'>Info6</h4>,
        disableBeacon: true,
        placement : 'left'
    },
]

class JoyRide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            run: false,
            page: '',
            isTourOpen: false,
            mainKey : 1
        }
        this.joyRide = React.createRef()
    }
    static getDerivedStateFromProps(props, state) {
        if(props && props.closeWallet && (props.closeWallet !== state.closeWallet)){
            return {
                run : false,
                stepIndex : 0,
                steps : [],
                closeWallet : props.closeWallet
            }
        }
        if(props && props.closeTooltip && (props.closeTooltip !== state.closeTooltip)){
            return {
                run : false,
                stepIndex : 0,
                steps : [],
                closeTooltip : props.closeTooltip
            }
        }
        if(props && props.joyStepsList && props.joyStepsList.length > 0){
            props.resetJoySteps()
                let obj = {
                    isLoginShowed : props.isLoginShowed,
                    isConnectWalletShowed : props.isConnectWalletShowed,
                    isAfterConnectWalletShowed : props.isAfterConnectWalletShowed
                }
                
                localStorage.setItem('completed_ride',(JSON.stringify(obj)))

                if(checkTourCompleted()){
                    localStorage.removeItem('joy-ride-storage')
                    localStorage.removeItem('completed_ride')
                    
                    localStorage.setItem('enable_tour', JSON.stringify(false))
                    resetToTrue()
                    stopTourGuide()
                    props.changeTourOption()
                }
            return {
                steps : props.joyStepsList,
                run : true
            }
        }
        return null

    }

    componentDidUpdate(props, state) {
    //     if (props 
    //         && props.start && 
    //         (((props.page) !== (state.page)))) {

    //         console.log(props,this.props,isConnectWallet(),isWebLogin())
    //         this.setState({ run: true, page: props.page, isWalletConnect : props.isWalletConnect })
    //         this.accessSteps(props.page)
           
            
    //    } 
        
    }
   
    accessSteps = (page) => {
        if (page === "home_login") {
            if(history && history.location && history.location.pathname && history.location.pathname.includes('discover')){
                const getLocal = localStorage.getItem('joy-ride-storage')
                const detail = JSON.parse(getLocal)
                if (detail) {
                    detail.home_login = true
                }
                if(!isWebLogin()){
                    this.setState({steps : home_steps})
                }else if(isWebLogin() && !isConnectWallet()){
                    this.setState({ steps: home_login_steps })
                }
                else{
                    
                    this.setState({ steps: home_wallet_steps })
                }
            }
            

        } else if (page === "drops") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.drop = true
            }
            this.setState({ steps: drop_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        } else if (page === "purchase") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.purchase = true
            }
            this.setState({ steps: purchase_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        } else if (page === "bid") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.bid = true
            }
            this.setState({ steps: bid_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "arts") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.arts = true
            }
            this.setState({ steps: art_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "own_sale_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.own_sale_page = true
            }
            this.setState({ steps: own_sale_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "own_auction_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.own_auction_page = true
            }
            this.setState({ steps: own_auction_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "own_cancel_auction_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.own_cancel_auction_page = true
            }
            this.setState({ steps: own_cancel_auction_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "list_auction_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.list_auction_page = true
            }
            this.setState({ steps: own_list_auction_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "my_wallet") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.my_wallet = true
            }
            this.setState({ steps: my_wallet_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "airdrop") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.airdrop = true
            }
            this.setState({ steps: airdrop_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "own_auction_settlement_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.own_auction_settlement_page = true
            }
            this.setState({ steps: own_auction_settlement_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "fund_wallet_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.fund_wallet_page = true
            }
            this.setState({ steps: fund_wallet_page_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }else if (page === "art_detail_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.art_detail_page = true
            }
            this.setState({ steps: artDetailSteps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }
        else if (page === "art_claim_page") {
            const getLocal = localStorage.getItem('joy-ride-storage')
            const detail = JSON.parse(getLocal)
            if (detail) {
                detail.art_claim_page = true
            }
            this.setState({ steps: auction_claim_steps })
            // localStorage.setItem('joy-ride-storage',JSON.stringify(detail))

        }
        // else if (page === "home_airdrop") {
        //     console.log(isConnectWallet())
        //     const getLocal = localStorage.getItem('joy-ride-storage')
        //     const detail = JSON.parse(getLocal)
        //     if (detail) {
        //         detail.home_airdrop = true
        //     }
        //     this.setState({ steps: home_wallet_steps })
        //     console.log('add steps called')
          

        // }

    }
    handleJoyrideCallback = (data) => {
        const { status, type, index, action } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (type === EVENTS.TOUR_END) {
            // NOTE: This step doesn't work.  Tour ends and doesn't reset.
            this.setState({
              stepIndex: 0,
            //   mainKey: this.state.mainKey + 1,

              run: false
            });
          } else if (action === ACTIONS.CLOSE) {
            this.setState({
              stepIndex: 0
            });
          } 
        else if (finishedStatuses.includes(status)) {

            this.setState({ run: false, stepIndex : 0 });

        }
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            let nextIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            
            // if (nextIndex === (data && data.size)) {
            //     this.setState({
            //       stepIndex: 0,
            //       run: false
            //     });
            //   } else {
            //     this.setState({ stepIndex: nextIndex });
            //   }
        }
        if(this.props && this.props.getSteps){
            this.props.getSteps(data,type,status)
        }

    };
    
    render() {
        const { run, stepIndex, steps } = this.state;

        return (
            <Fragment>
                <Joyride
                    callback={this.handleJoyrideCallback}
                    continuous
                    hideCloseButton
                    disableScrolling={this.props.isScroll}
                    // disableScrolling={true}
                    scrollOffset={10}
                    scrollToFirstStep={true}
                    locale={{
                        last: "Got It",
                        skip: "Skip tour"
                    }}
                    key={ this.state.mainKey } // <<<<<<<<<<<< YES

                    disableOverlayClose
                    ref={this.joyRide}
                    run={this.state.run}
                    // showProgress
                    // spotlightClicks
                    showSkipButton
                    steps={this.state.steps}
                    stepIndex={stepIndex}
                    styles={{
                        buttonNext: {

                            background: '#9E66BF',
                            color: '#fff',
                            borderRadius: 10,
                            fontSize:14,
                            fontWeight:900

                        },

                        buttonBack: {

                            color: '#333',

                        },
                        options: {
                            arrowColor: '#fff',

                            backgroundColor: '#fff',

                            beaconSize: 36,

                            overlayColor: 'rgba(0, 0, 0, 0.8)',

                            primaryColor: '#fff',

                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',

                            textColor: '#333',

                            zIndex: 100,


                        },
                       

                    }}
                />
                
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        joyStepsList : state.joyrideReducer.joySteps,
        isLoginShowed : state.joyrideReducer.isLoginShowed,
        isConnectWalletShowed : state.joyrideReducer.isConnectWalletShowed,
        isAfterConnectWalletShowed : state.joyrideReducer.isAfterConnectWalletShowed,

    }
}
const actionCreators = {
    resetJoySteps: joyrideActions.resetJoySteps,
    callJoyRide: joyrideActions.getorUpdateJoyride,
    changeTourOption: userAction.enableDisableTourOption,


}
const connectJoyRide = connect(mapStateToProps, actionCreators)(JoyRide)
export { connectJoyRide as JoyRide }


