// @ts-nocheck
import React from "react";
import history from "helpers/history";
import { airdropActions, followActions, walletActions } from "store/actions";
import { connect } from "react-redux";
import { Fragment } from "react";
import { getCurrentUserId, isConnectWallet, isWebLogin, localArtPage, localDropPage } from 'helpers'
import { createSetUp, getUserAddress, isValidEmailAddress, RetrieveTokens } from "utils";
import { configPath } from "../config";
import { ArtPreview } from "./art-preview/art-preview";
import { Helmet } from "react-helmet";
import moment from 'moment'
import { airDropService, fetchService, followServices, userServices } from "services";
import PubSub from 'pubsub-js';
import { SolarSystemLoading } from 'react-loadingg';
import { JoyRide } from "./Joy-ride";
import { checkAirdropPage } from "helpers/joy-rideCheck";
import { connectBlocto } from "helpers/getFlowUsd";
import { FollowPopup } from "./follow-popup";
import { SwitchAccount } from "./switchaccountPopup";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AirdropMapComponent } from "./googleMaps/AirdropMap";
import Modal from 'react-modal'
import { ProfilePreview } from "./profilePreview";
import { CommonDisableError } from "./common_disable_error";
import { CommonNotFoundError } from "./commonNotFound";
import Geocode from 'react-geocode';
import { CelebrationGif } from "./common/celebrationGif";
import IconComponent from "./IconComponent";
import { Link } from 'react-router-dom';

var timeout;
let controller = new AbortController();
let interval;
let celebrationTimeout;
let celebrationOverTimeout;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
class AirDrops extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropList: {},
            offSet: 0,
            isCheck: false,
            recordLimit: 15,
            totalCount: '',
            pageCount: '',
            rate: '',
            currentUser: '',
            sortOrder: "DESC",
            sortBy: "createdAt",
            show: false,
            showModal: false,
            selectedArt: [],
            isFollower: true,
            clickedUser: '',
            userId: '',
            isAlert: false, message: '', style: "",
            isActSetup: false,
            currentUserDet: {},
            isSuccessClaim: false,
            iswallet: false,
            scrolled: false,
            loadText: 1,
            isErrorPoped: false,
            popedDapperError: '',
            isForAirdropPage: false,
            tabIndex: 0,
            nearByAirdrop: {},
            locationLoading: false,
            userNotFound: false,
            userDisabled: false,
            guestEmail: '',
            submitted: false,
            isOpenEmail: false,
            isSendGuestEmail: false,
            isCelebration: false,
            hashValue: null,
            isLocationAccessed: false,
            accessState: false,
            isDisabled: false,
            locatedAddress: '',
            airdropDirection: '',
            isCelebrationOver: false,
            geolocationID: '',
            movingLocation: {},
            distanceInMeters: '',
            refreshLoading: false,
            nextAirdropType: '',
            nftId: '',
            scanModal: false, // scan airdrop modal,
            isImage: false
        }
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        localArtPage()
        localDropPage()
        getUserAddress().then(res => {
            this.setState({ currentUser: res })
        })
        this.setCount()
        const data = JSON.parse(localStorage.getItem('airdropData'))
        const dataLength = data && data[0] && data[0].length
        const count = Number(dataLength - (this.state.recordLimit))


        if (window && window.location && window.location.hash && window.location.hash !== '') {
            this.getQueryValues(window.location.hash)

        }
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.initiateScan) {
            this.handleSacnForAirdrops()
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.initiateScan;
            history.replace({ ...location, state });
        }

    }
    getQueryValues = (windowhash) => {
        this.setState({ locationLoading: false, hashValue: '' })

        clearTimeout(celebrationTimeout)
        clearTimeout(celebrationOverTimeout)
        if (windowhash !== '' && windowhash !== null) {
            const hash = windowhash.replace('#', '')

            // Split the URL into parts using '&tokenid' as the separator
            const parts = hash.split("&tokenId=");
            if (parts.length === 2) {
                const hashValue = parts[0];
                const nftId = parts[1];
                this.setState({ hashValue: hashValue, nftId: nftId })
                this.getUserDetail(hashValue, nftId)

            } else {
                this.setState({ hashValue: hash, nftId: '' })
                this.getUserDetail(hash, '')
            }
        }


    }
    infiniteScroll = () => {
        const { scrolled } = this.state
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;
        const fullHeight = scrollTop + clientHeight

        if ((Number(fullHeight)) >= (Number(scrollHeight) - 1200) &&
            (this.state.dropList && this.state.dropList[0] && this.state.dropList[0].length > 0) && !scrolled
        ) {

            this.setState({
                scrolled: true
            }, () => {

                this.loadMoreData()
            })

        }

    }
    static getDerivedStateFromProps(props, state) {
        let rate = ""
        if (props && props.dropList && props.dropList.statusCode == 200) {
            props.resetDropArt()

            if ((state.dropList && state.dropList.length > 0) && state.dropList[0]) {

                if (props.dropList && props.dropList.data && props.dropList.data.airDrops && props.dropList.data.airDrops.length == 0) {

                    return {
                        scrolled: true,
                        isCheck: props.isCheckDrop,
                        loadText: 1
                    }

                } else {
                    props.dropList && props.dropList.data && props.dropList.data.airDrops && props.dropList.data.airDrops.map((items) => {

                        state.dropList[0].push(items)

                    })
                    return {
                        scrolled: false,
                        isCheck: props.isCheckDrop,
                        loadText: 1
                    }
                }

            } else {

                state.dropList.push(props.dropList && props.dropList.data && props.dropList.data.airDrops)

                return {
                    scrolled: false,
                    isCheck: props.isCheckDrop,
                    loadText: 1
                }

            }

        } return null;

    }

    refreshArts = () => {
        controller.abort()
        controller = new AbortController();
        localStorage.setItem("page", "0")
        this.setState({
            offSet: 0,
            dropList: []
        }, () => {
            this.callDrops()
        })
    }

    componentDidUpdate(props, state) {
        if (window.location) {
            let nft = configPath.basename
            if (window.location.pathname.includes(nft)) {
                if ((props && props.location && props.location.hash) != (window.location.hash)) {
                    this.getQueryValues(window.location.hash)
                    this.setState({ dropList: {}, isCheck: false, nearByAirdrop: {}, detailCreator: {}, isCelebrationOver: false })

                }
            } else if ((props && props.location && props.location.hash) != (window.location.hash)) {
                this.getQueryValues(window.location.hash)
                this.setState({ dropList: {}, isCheck: false, nearByAirdrop: {}, detailCreator: {}, isCelebrationOver: false })
            }
        }

        if (this.props &&
            this.props.userFollow &&
            this.props.userFollow.statusCode === 200 &&
            (this.props.userFollow !== (props.userFollow))) {
            props.resetFollow()
            this.setState({
                isFollower: true,
            })
            if (isConnectWallet()) {
                this.claimNFT()
            } else {
                this.claimWalletlessNFT()
            }
            this.resetTimeout()
            this.closeAlert()
        }

        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (this.state.isForAirdropPage) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            this.setState({
                isWallet: false,
                isForAirdropPage: false
            })
            this.createActSetup()
        }
        if ((props && props.failedFollow && props.failedFollow.length > 0)) {
            props.resetFailedFollow()
            this.setState({
                isFollower: true,
                isAlert: true,
                message: props && props.failedFollow,
                style: "danger"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    callDrops = () => {
        controller = new AbortController();
        let signal = controller.signal;
        let offSet = this.state.offSet
        // this.setState({ forcePage: offSet != null ? (JSON.parse(offSet) / this.state.recordLimit) : 0 })

        let obj = {
            offSet: offSet != null ? offSet : this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: 'createdAt',
            sortOrder: this.state.sortOrder,
            signal
        }
        this.props.getDrops(obj)

    }

    createSetupClicked(e) {
        createSetUp().then(response => console.log('account setup txn', response)).catch((e) => console.error('account setup error', e));
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {


        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleWalletLogin = async (path) => {
        this.setState({ targetPath: path })
        try {
            const wallet = await connectBlocto()
            if (wallet && wallet.walletAddress) {
                this.props.linkWallet(JSON.stringify(wallet))
                this.setState({
                    isForAirdropPage: true
                })

            } else {
                this.setState({
                    isAlert: true,
                    message: wallet,
                    style: "danger"
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger"
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    createActSetup = (items) => {
        this.showLoader()
        getUserAddress().then(res => {
            this.hideLoader()
            if (res != null) {

                const walletAddress = res
                if (walletAddress) {
                    this.showLoader()
                    RetrieveTokens(walletAddress).then(resp => {
                        this.hideLoader()

                        if ((resp && resp.status === 200)) {

                            if ((resp && resp.data && resp.data.Value) == 0) {

                                this.setState({ isActSetup: true, isErrorPoped: false })

                                createSetUp().then(res => {
                                    if (res && res.status == 200) {
                                        this.setState({ isActSetup: false })
                                        if (res.data.transaction && res.data.transaction.errorMessage) {
                                            this.setState({
                                                isFollower: true,
                                                clickedUser: '',
                                                userId: '',
                                            })
                                            this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        } else {
                                            this.setState({ isAlert: true, message: 'Your Flow account has been successfully added. Now you may proceed to claim NFT', style: "success" })
                                            this.gotoTop()
                                            this.followCheck()
                                            this.resetTimeout()
                                            this.closeAlert()

                                        }
                                    }
                                    else {

                                        if (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]) {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        } else if (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[1]) {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[1]), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        } else {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        }
                                    }

                                }).catch(err => {
                                    this.hideLoader()
                                    this.setState({ isActSetup: false })
                                    this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                })
                                // }

                            } else {
                                if (resp && resp.status == 200) {
                                    this.followCheck()

                                }
                            }
                        } else {
                            if ((resp && resp.status === 400)) {
                                if ((resp && resp.data && resp.data.message)) {
                                    this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                } else {
                                    this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                }
                            } else {
                                this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            }
                        }
                    }).catch(err => {
                    })
                } else {

                    this.setState({ isAlert: true, message: 'Please connect your wallet properly', style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            }
        }).catch(err => {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop();
            this.resetTimeout()
            this.closeAlert()
        })
    }
    followCheck = () => { // to check claimed user follow the creator
        const items = this.state.currentUserDet

        var filterUser = []
        let user = localStorage.getItem("webLogin")
        let userDetail = JSON.parse(user)
        let lgnUser = userDetail.data && userDetail.data.authUser && userDetail.data.authUser.id
        this.showLoader()
        followServices.getFollowers(items && items['art'] && items['art'].owner && items['art'].owner.id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) == 200) {
                filterUser = res.data && res.data.follower && res.data.follower.length > 0 && res.data.follower.filter((flw) => (flw.id) == lgnUser)
                if (filterUser && filterUser.length > 0) {
                    this.setState({ isFollower: true, filterUser: [] })
                    if (isConnectWallet()) {
                        this.claimNFT() // follow true / claim with wallet address
                    } else {
                        this.claimWalletlessNFT() // follow true / walletless claim
                    }
                } else {
                    //not follow so open follow popup
                    this.setState({
                        isFollower: false,
                        clickedUser: items && items.art && items.art.owner && items.art.owner.userName,
                        userId: items.art && items.art.owner && items.art.owner.id
                    })
                }
            }

        }).catch(err => {
            this.hideLoader()
        })
    }
    handleClaimWithoutWallet = (items) => {
        this.setState({
            currentUserDet: items
        }, () => {
            if (isWebLogin()) {
                if (isConnectWallet()) {
                    this.createActSetup()
                } else {
                    this.followCheck()
                    // this.handleToSwitchAccount()
                    // this.handleWalletLogin()
                }
            } else {
                // history.push('/login')
                this.setState({ isOpenEmail: true })
            }
        })

    }
    handleClaimWithWallet = (items) => {
        this.setState({
            currentUserDet: items
        }, () => {
            if (isWebLogin()) {
                if (isConnectWallet()) {
                    this.createActSetup()
                } else {
                    // this.followCheck()
                    this.handleToSwitchAccount()
                    // this.handleWalletLogin()
                }
            } else {
                // history.push('/login')
                this.setState({ isOpenEmail: true })
            }
        })

    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)

    }
    resetUser = () => {
        this.setState({ isFollower: true, clickedUser: '' })

    }
    claimNFT = () => { // claim with wallet
        const items = this.state.currentUserDet
        this.showLoader()
        this.setState({ isAirDrop: true })

        if (
            Number.isInteger(items.art && items.art.tokenId) &&
            (items.art && items.art.owner) &&
            Number.isInteger(items.art.owner.id)
        ) {
            airDropService.claimAirDrop(items.art.tokenId, items.art.owner.id).then(res => {
                this.hideLoader()
                this.setState({ isAirDrop: false })

                res && res.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (res.ok) {
                        this.setState({ isSuccessClaim: true })
                    } else {
                        const validationErorrs = [];

                        if (data &&
                            data.data &&
                            data.data.errorCode &&
                            (
                                data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                                data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                                data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                            ) &&
                            data.data.data[0] &&
                            Array.isArray(data.data.data[0])) {

                            for (const detail of data.data.data) {
                                if (Array.isArray(detail)) {
                                    for (const message of detail) {
                                        if (typeof message === 'string') {
                                            validationErorrs.push(message);

                                        }
                                    }
                                }
                            }
                            this.setState({ isAlert: true, message: validationErorrs[0], style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()

                        }
                        else {
                            const error = (data && data.message)
                            this.setState({ isAlert: true, message: error, style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }
                })


            }).catch(err => {
                console.log(err)
                try {
                    if (err instanceof TypeError) {
                        this.setState({ isAlert: true, message: "TypeError", style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        PubSub.publish('msg', false)
                        return Promise.reject(err)
                    } else {
                        if (err && typeof err === "string" && err.toLowerCase().includes('air drop already claimed')) {
                            this.setState({ isAlert: true, message: 'You have already claimed this air drop', style: "danger", isAirDrop: false })
                        } else {
                            this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                        }
                        PubSub.publish('msg', false)
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()

                    }
                }
                catch (err) {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            })

        } else {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Something went wrong, try again later', style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    claimWalletlessNFT = () => { // claim without wallet
        const items = this.state.currentUserDet
        this.showLoader()
        this.setState({ isAirDrop: true })

        if (
            Number.isInteger(items.art && items.art.tokenId) &&
            (items.art && items.art.owner) &&
            Number.isInteger(items.art.owner.id)
        ) {
            airDropService.claimWalletlessAirDrop(items.art.tokenId, items.art.owner.id).then(res => {
                this.hideLoader()
                this.setState({ isAirDrop: false })

                res && res.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (res.ok) {
                        this.setState({ isSuccessClaim: true })
                    } else {
                        const validationErorrs = [];

                        if (data &&
                            data.data &&
                            data.data.errorCode &&
                            (
                                data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                                data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                                data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                            ) &&
                            data.data.data[0] &&
                            Array.isArray(data.data.data[0])) {

                            for (const detail of data.data.data) {
                                if (Array.isArray(detail)) {
                                    for (const message of detail) {
                                        if (typeof message === 'string') {
                                            validationErorrs.push(message);

                                        }
                                    }
                                }
                            }
                            this.setState({ isAlert: true, message: validationErorrs[0], style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()

                        }
                        else {
                            const error = (data && data.message)
                            this.setState({ isAlert: true, message: error, style: "danger", isAirDrop: false })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }
                })


            }).catch(err => {
                console.log(err)
                try {
                    if (err instanceof TypeError) {
                        this.setState({ isAlert: true, message: "TypeError", style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        PubSub.publish('msg', false)
                        return Promise.reject(err)
                    } else {
                        if (err && typeof err === "string" && err.toLowerCase().includes('air drop already claimed')) {
                            this.setState({ isAlert: true, message: 'You have already claimed this air drop', style: "danger", isAirDrop: false })
                        } else {
                            this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                        }
                        PubSub.publish('msg', false)
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()

                    }
                }
                catch (err) {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            })

        } else {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Something went wrong, try again later', style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    startTime = (items) => {

        var futureDate = (moment(items).format('MMM DD, YYYY HH:mm:ss'))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return ''
        }
        return <div className="col-sm-12 text-center  claimtime"><div className="mt-1" title={moment(items).format('DD-MMM-YYYY hh:mm:ss A')}><p className="mb-0 ">Available in <br /><span className="usernamelilac">{(`${days ? days + "d" : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div></div>

    }
    setCount = (items) => {
        interval = setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    closeModal = () => {
        this.setState({
            isFollower: true
        })
    }
    loggedUserCheck = (items) => {
        let user = localStorage.getItem("webLogin")
        let userDetail = user && JSON.parse(user)
        let lgnUser = userDetail && userDetail.data && userDetail.data.authUser && userDetail.data.authUser.id
        if ((items && items.art && items.art.owner && items.art.owner.id == lgnUser)) {
            return false
        } else {
            return true
        }
    }
    dayClosed = (items) => {
        var futureDate = ((moment(items).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    resetClaimPage = () => {
        localStorage.setItem('page', JSON.stringify(0))
        history.push('/wallet')
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    handleEdit = (items) => {
        if (isWebLogin()) {
            if (items && items.id) {
                history.push(`/airdrop/${items.id}`)
            }
        } else {
            history.push('/login')
        }
    }
    navigationCheck = (e, items) => {
        if ((e.target.id === "creator")) {
            this.gotoCreator(items && items.art && items.art.creator)
        } else if ((e.target.id === "owner")) {
            this.gotoCreator(items && items.art && items.art.owner)
        } else if ((e.target.id === "claim")) {
            this.handleClaimWithoutWallet(items)
        } else if ((e.target.id === "edit")) {
            this.handleEdit(items)
        } else if ((e.target.id === "claimwithwallet")) {
            this.handleClaimWithWallet(items)
        }
        else {
            this.gotoDetail(items)
        }
    }
    gotoDetail = (items) => {

        if (
            (items && items.art && items.art.id)
        ) {
            history.push(`/art/${items.art.id}`)
        }

    }
    loadMoreData = () => {
        const { recordLimit, offSet } = this.state
        const page = Number(recordLimit) + Number(offSet)

        this.setState({
            loadText: 2, offSet: page
        }, () => {
            this.callDrops()
        })



    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForAirdropPage: true, path: '' })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger"
        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    handleSacnForAirdrops = () => {
        this.setState({
            dropList: {},
            isCheck: false,
            nearByAirdrop: {},
            accessState: false,
            isCelebrationOver: false
        })
        clearTimeout(celebrationTimeout)
        clearTimeout(celebrationOverTimeout)
        this.setState({ locationLoading: true })
        navigator.geolocation.clearWatch(Number(this.state.geolocationID));

        this.getCurrentLocation()



    }
    getCurrentLocation = () => {
        if (navigator.geolocation) {
            let options = {
                enableHighAccuracy: true,
                // timeout: 10000,    
                // maximumAge: 300000  
            };
            navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback, options);
        } else {
            this.setState({ locationLoading: false })
            this.setState({ isAlert: true, message: `Your browser doesn't support to get location`, style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    successCallback = (position) => {
        if (position && position.coords && position.coords.latitude) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.setState({ currentLocation: { lat, lng }, accessState: false })
            console.log(lat, lng)
            this.scanByLocation(lat, lng)
        } else {
            this.hideLoader()
            this.setState({ locationLoading: false })

            this.setState({ isAlert: true, message: 'An unknown error occurred while get user location.', style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    };

    errorCallback = (error) => {
        // this.hideLoader()
        this.setState({ locationLoading: false, refreshLoading: false })
        switch (error.code) {
            case error.PERMISSION_DENIED:
                this.setState({ isLocationAccessed: true })
                // this.setState({ isAlert: true, message: "User denied the request for Geolocation.", style: "danger", isAirDrop: false })
                break;
            case error.POSITION_UNAVAILABLE:
                this.setState({ isAlert: true, message: "Location information is unavailable.", style: "danger", isAirDrop: false })
                break;
            case error.TIMEOUT:
                this.setState({ isAlert: true, message: "The request to get user location timed out.", style: "danger", isAirDrop: false })
                break;
            case error.UNKNOWN_ERROR:
                this.setState({ isAlert: true, message: "An unknown error occurred while get user location.", style: "danger", isAirDrop: false })
                break;
        }
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    };
    scanByLocation = async (lat, lng) => {

        if (lat && lng) {
            let obj = {
                latitude: Number(lat),
                longitude: Number(lng)
            }
            if (this.state.nextAirdropType && this.state.nextAirdropType !== '') {
                obj['scanType'] = this.state.nextAirdropType
            }
            if (this.state.hashValue !== null && this.state.hashValue !== '') {
                if (this.state.nftId !== null && this.state.nftId !== '') {
                    obj['tokenId'] = Number(this.state.nftId)
                }
                obj['userName'] = this.state.hashValue
            }
            controller = new AbortController();
            let signal = controller.signal;
            try {
                let response = await airDropService.scanByAirdrop(JSON.stringify(obj), signal)

                if (response && response.statusCode === 200) {
                    this.setState({ locationLoading: false }) // hideLoader
                    if (response.data && response.data.airDrop && response.data.airDrop !== null) {
                        if ((response.data && response.data.airDrop.airDropType &&
                            (response.data.airDrop.airDropType === airDropService.airDropType.LOCATED_USERS) &&
                            ((response.data.airDrop.nearByLocation && response.data.airDrop.nearByLocation.locationCoordinates))
                        )) {
                            this.saveNearByAirdrop(response)
                        } else {
                            if (response.data && response.data.airDrop && response.data.airDrop.id) {
                                this.saveAvailableAirdrop(response.data.airDrop)

                            } else {
                                this.setState({
                                    nearByAirdrop: {}
                                })
                            }

                        }
                        if (response.data && response.data.nextType) {
                            this.setState({ nextAirdropType: response.data.nextType })
                        }


                    } else {
                        this.setState({
                            dropList: {},
                            isCheck: true,
                            nearByAirdrop: {}
                        })

                    }
                } else {
                    this.setState({
                        dropList: {},
                        isCheck: true,
                        nearByAirdrop: {}
                    })
                    this.setState({ locationLoading: false }) // hideLoader
                }
            } catch (e) {
                this.setState({ locationLoading: false }) // hideLoader
                this.setState({
                    dropList: {},
                    nearByAirdrop: {}
                })
                if (e && typeof e === 'string') {
                    if (e.toLowerCase().includes('aborted')) {
                        this.setState({
                            isCheck: false,
                        })
                    } else {
                        this.setState({ isAlert: true, message: e, style: "danger", isAirDrop: false, isCheck: true })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }

                }
            }
        }

    }


    handleLatChanges = (e) => {
        this.setState({
            input1: e.target.value
        })
    }
    handleLngChanges = (e) => {
        this.setState({
            input2: e.target.value
        })
    }
    handleEmailChange = (e) => {
        this.setState({ guestEmail: (e.target.value).toLowerCase() })
    }
    closeWithdraw = () => {
        this.setState({ isOpenEmail: false })
    }
    approveWithdraw = () => {
        this.sendLinkToEmail()
    }
    sendLinkToEmail = () => {
        const items = this.state.currentUserDet
        if (items && items.art && items.art.tokenId && items.art.id && Number.isInteger(items.art && items.art.tokenId) && Number.isInteger(items.art.owner.id)) {
            this.setState({ submitted: true })
            if (this.state.guestEmail !== '') {
                if (isValidEmailAddress(this.state.guestEmail)) {
                    let obj = {
                        airDropId: items.id,
                        listedUserId: items.art.owner.id,
                        email: this.state.guestEmail
                    }
                    this.sendGuestUserDetail(obj)
                    this.setState({ isOpenEmail: false })

                }
            }
        }

    }
    sendGuestUserDetail = (obj) => {
        this.showLoader()
        airDropService.postGuestUserAirdrop(obj).then(res => {
            this.hideLoader()
            this.setState({ guestEmail: '', submitted: false })
            if (res && res.statusCode === 200) {
                this.setState({
                    isSendGuestEmail: true
                })
            } else {

            }
        }).catch(e => {
            this.hideLoader()
            this.setState({ guestEmail: '' })
        })
    }
    getBanner = () => {
        const { detailCreator, isShowLoader } = this.state
        if (detailCreator && detailCreator.coverImage && detailCreator.coverImage != null) {
            return <img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCreator.coverImage}`} alt=""></img>

        } else if (!isShowLoader) {
            return <img src={`../${configPath.imageAssets}/cover-default.png`} alt=""></img>

        }

    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab
        })
    }
    getUserDetail = (id, nftId) => {
        this.showLoader()
        userServices.getDetailOfUser(id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) === 200) {
                this.setState({
                    detailCreator: res && res.data.userDetails, isShowLoader: false, isDisabled: false,
                    userNotFound: false
                }, () => {
                        if (nftId !== '') {
                            this.handleSacnForAirdrops()
                        }
                    
                })
            }
        }).catch(err => {
            console.log(err)
            this.hideLoader()
            if (err instanceof TypeError) {
                this.setState({
                    isAlert: true,
                    message: err,
                    style: "danger",
                    isDisabled: false,
                    userNotFound: false
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()

            } else if (err && typeof err === 'string' && err.toLowerCase().includes('account has been disabled')) {
                this.setState({ isDisabled: true, userNotFound: false })
            }
            else if (err && typeof err === 'string' && err.toLowerCase().includes('user not found')) {
                this.setState({ userNotFound: true })
            } else {
                this.setState({
                    isAlert: true,
                    message: err,
                    style: "danger",
                    isDisabled: false,
                    userNotFound: false
                })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
            this.setState({ isShowLoader: false, detailCreator: {} })
            this.hideLoader()
        })
    }
    closeWarning = () => {
        this.setState({ isLocationAccessed: false, accessState: true })
    }
    getAddressByPosition = (lat, lng) => {
        if (lat && lng) {
            Geocode.fromLatLng(lat, lng, process.env.REACT_APP_GOOGLE_MAP_API_KEY).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    this.setState({ locatedAddress: address })
                },
                (error) => {
                    console.error(error);
                    this.setState({ locatedAddress: '' })
                }
            );
        } else {
            this.setState({ locatedAddress: '' })
        }

    }
    calculateDirection = (nearByAirdrop) => {
        const originLat = this.state.currentLocation.lat
        const originLng = this.state.currentLocation.lng
        const destLat = nearByAirdrop.nearByLocation.locationCoordinates.latitude
        const destLng = nearByAirdrop.nearByLocation.locationCoordinates.longitude
        //         You can use slope. Assume you have a marker M1 and center marker C.
        // Slope should be equal to (C.lat - C.lng)/(M1.lat - M1.lng)
        // If the slope happens to be nearly 0 it can be either East or West if M1.lng > C.lng it should be West.
        // if slope is nearly 1 it is either South West or North East. Then, if M1.lng > C.lng it is South West
        // If slope is nearly -1 it is either North West or South East. Then i M1.lng > C.lng, it is North West.
        // You can use the same approach for other directions
        let direction1;
        let direction2;
        const lng_diff = destLng - originLng
        const lat_diff = destLat - originLat

        if (lat_diff > 0) {
            direction1 = "NORTH"
        } else if (lat_diff < 0) {
            direction1 = "SOUTH"
        } else {
            direction1 = ""
        }


        if (lng_diff > 0) {
            direction2 = "EAST"
        } else if (lng_diff < 0) {
            direction2 = "WEST"
        } else {
            direction2 = ""
        }
        this.setState({ airdropDirection: direction1 + ' ' + direction2 })
    }
    getMiles = (meters) => {
        const miles = (meters * 0.000621371192);
        const feet = (miles * 5280);
        if (miles < 1) {
            return <span className="usernamelilac rainbow rainbow_text_animated w-auto rainairdrops">{feet.toFixed(1)} Feet</span>
        } else {
            return <span className="usernamelilac rainbow rainbow_text_animated w-auto rainairdrops">{miles.toFixed(1)} Miles</span>
        }
    }
    isUserPage = (creator) => {
        if (getCurrentUserId() && creator && creator.id) {
            if (getCurrentUserId() === (creator.id)) {
                console.log(creator, getCurrentUserId())
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    watchsuccessCallback = (position) => {
        console.log(position);
        if (position && position.coords && position.coords.latitude) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.setState({ movingLocation: { lat, lng }, accessState: false })
            this.checkMovingPosition(lat, lng)
        } else {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'An unknown error occurred while get user location.', style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    };

    watcherrorCallback = (error) => {
        console.log(error);
    };
    checkMovingPosition = (lat, lng) => {
        const nearByAirDrop = this.state.nearByAirdrop
        console.log(nearByAirDrop)
        if (nearByAirDrop && nearByAirDrop.nearByLocation &&
            nearByAirDrop.nearByLocation.locationCoordinates.latitude &&
            nearByAirDrop.nearByLocation.locationCoordinates.longitude && lat && lng) {
            this.calculateDistance(nearByAirDrop.nearByLocation.locationCoordinates.latitude, nearByAirDrop.nearByLocation.locationCoordinates.longitude, lat, lng)
        }
    }

    calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371e3; // Radius of the Earth in kilometers
        const dLat = this.deg2rad(lat2 - lat1);
        const dLon = this.deg2rad(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;

        this.setState({ distanceInMeters: distance })
    }

    deg2rad = (deg) => {
        return deg * (Math.PI / 180);
    }
    isReached = () => {
        const nearByAirDrop = this.state.nearByAirdrop.nearByLocation

        const distance = this.state.distanceInMeters
        // 
        if (distance !== '' && nearByAirDrop && nearByAirDrop.radius !== null) {
            const closeToValue = Number(nearByAirDrop.radius) + 2
            if (Number(distance) <= Number(closeToValue)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }
    checkMiles = (meters) => {
        const miles = (meters * 0.000621371192);
        if (miles > 2) {
            return true
        } else {
            return false
        }
    }
    saveAvailableAirdrop = (responseAirdrop) => {
        this.setCount()
        navigator.geolocation.clearWatch(Number(this.state.geolocationID));
        this.setState({ locationLoading: false }) // hideLoader
        this.setState({ distanceInMeters: '' })
        this.setState({
            isCelebration: true,
            dropList: responseAirdrop,
            isCheck: true,
        })
        this.fetchArt(responseAirdrop)
        celebrationTimeout = setTimeout(() => {
            this.setState({
                isCelebration: false,
            })
        }, 4000)
    }
    calledNearByAirdrop = async (obj) => {
        console.log("calledNearby")
        const nearByLocation = await airDropService.nearByLocation(JSON.stringify(obj))
        if (nearByLocation && nearByLocation.statusCode === 200) {
            if (nearByLocation.data && nearByLocation.data.nearByAirDrop) {
                this.saveNearByAirdrop(nearByLocation)
            }

            this.setState({ locationLoading: false }) // hideLoader
        }

    }
    saveNearByAirdrop = async (nearByLocation) => {
        if (nearByLocation.data) {
            clearInterval(interval)
            this.setState({
                nearByAirdrop: nearByLocation.data.airDrop,
                tabIndex: 0
            })
            this.calculateDirection(nearByLocation.data.airDrop)
            if (nearByLocation.data.airDrop.nearByLocation) {
                this.getAddressByPosition((nearByLocation.data.airDrop.nearByLocation.locationCoordinates.latitude), (nearByLocation.data.airDrop.nearByLocation.locationCoordinates.longitude))
                const geolocationID = navigator.geolocation.watchPosition(
                    this.watchsuccessCallback,
                    this.watcherrorCallback,
                    { enableHighAccuracy: true }
                );
                this.setState({ geolocationID })
            }
        }



    }
    handleRefreshAirdrop = (e) => {
        e.preventDefault()
        this.setState({
            dropList: {},
            isCheck: false,
            accessState: false,
            isCelebrationOver: false
        })
        clearTimeout(celebrationTimeout)
        clearTimeout(celebrationOverTimeout)
        this.setState({ locationLoading: true })
        navigator.geolocation.clearWatch(Number(this.state.geolocationID));
        this.refreshCurrentLocation()
    }

    refreshCurrentLocation = () => {
        if (navigator.geolocation) {
            console.log("searching")

            let options = {
                enableHighAccuracy: true,
                // timeout: 10000,    
                // maximumAge: 300000  
            };
            navigator.geolocation.getCurrentPosition(this.refreshsuccessCallback, this.errorCallback, options);
        } else {
            this.setState({ locationLoading: false })
            this.setState({ isAlert: true, message: `Your browser doesn't support to get location`, style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    refreshsuccessCallback = (position) => {
        if (position && position.coords && position.coords.latitude) {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            this.setState({ currentLocation: { lat, lng }, accessState: false })
            console.log(lat, lng)
            this.refreshByLocation(lat, lng, this.state.nearByAirdrop.id)
            // this.scanByLocation(lat, lng)
        } else {
            this.hideLoader()
            this.setState({ locationLoading: false })
            this.setState({ isAlert: true, message: 'An unknown error occurred while get user location.', style: "danger", isAirDrop: false })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    };
    refreshByLocation = async (lat, lng, airdropId) => {

        if (lat && lng) {
            let obj = {
                latitude: Number(lat),
                longitude: Number(lng),
                airdropId
            }
            // if (this.state.hashValue !== null && this.state.hashValue !== '') {
            //     obj['userName'] = this.state.hashValue
            // }

            try {
                let response;
                response = await airDropService.refreshLocationAirdrop(JSON.stringify(obj))

                if (response && response.statusCode === 200) {
                    this.setState({ locationLoading: false }) // hideLoader
                    if (response.data && response.data.airDrop && response.data.airDrop !== null) {
                        if ((response.data && response.data.airDrop.airDropType &&
                            (response.data.airDrop.airDropType === airDropService.airDropType.LOCATED_USERS) &&
                            ((response.data.airDrop.nearByLocation && response.data.airDrop.nearByLocation.locationCoordinates))
                        )) {
                            this.saveNearByAirdrop(response)
                        } else {
                            this.saveAvailableAirdrop(response.data.airDrop)
                            this.setState({
                                nearByAirdrop: {}
                            })
                        }


                    } else {

                        this.setState({
                            dropList: {},
                            isCheck: true,
                            nearByAirdrop: {}
                        })
                    }
                } else {
                    this.setState({
                        dropList: {},
                        isCheck: true,
                        nearByAirdrop: {}
                    })
                    this.setState({ locationLoading: false }) // hideLoader
                }
            } catch (e) {
                this.setState({ locationLoading: false }) // hideLoader
                this.setState({
                    dropList: {},
                    isCheck: true,
                    nearByAirdrop: {}
                })
                if (e && typeof e === 'string') {
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    if (e.toLowerCase().includes('airdrop does not exist')) {
                        this.setState({ isAlert: true, message: 'You are too late. Unfortunately, the airdrop has already been claimed', style: "danger", isAirDrop: false })
                    } else {
                        this.setState({ isAlert: true, message: e, style: "danger", isAirDrop: false })
                    }

                }
            }
        }

    }
    closeScanModal = () => {
        this.setState({ scanModal: false })
    }
    fetchArt(url) {
        if (url && url.art && url.art.thumbnailMimeType && url.art.thumbnailMimeType !== null) {
            if (url.art.thumbnailMimeType.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        } else {
            if (url && url.art && url.art.thumbnailHash) {
                fetchService.getBlob(url.art.thumbnailHash).then((blob) => {

                    if (blob.type.match(/image.*/)) {
                        return this.setState({ isImage: true })
                    } else {
                        return this.setState({ isImage: false })
                    }
                }).catch((err) => {
                    return this.setState({ isImage: false })
                })
            }
        }


    }
    closeSuccessPopup = () => {
        this.setState({ isSuccessClaim: false })
    }
    closeClaimInitiatePopup = (e) => {
        e.preventDefault()
        this.setState({ isSendGuestEmail: false })
    }
    cancelScanAirdrop = () => {

        this.setState({
            dropList: {},
            isCheck: false,
            nearByAirdrop: {},
            locationLoading: false
        })
        controller.abort()
        controller = new AbortController();
    }
    componentWillUnmount() {

        controller.abort()
        controller = new AbortController();
        window.removeEventListener("scroll", this.infiniteScroll)
        localStorage.setItem("airdropData", JSON.stringify(this.state.dropList))
        clearTimeout(celebrationTimeout)
        clearTimeout(celebrationOverTimeout)
        navigator.geolocation.clearWatch(Number(this.state.geolocationID));
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const basePath = process.env.REACT_APP_BASEPATH
        const localUser = localStorage.getItem('webLogin')
        const localUserDetail = JSON.parse(localUser)
        const { dropList, rate, isCheck, nearByAirdrop, detailCreator, currentUserDet } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>Artdrops</title>
                    <meta name="title" content="Explore Latest NFT Drops"></meta>
                    <meta name="description" content={`Explore latest NFT drops on ${process.env.REACT_APP_SITE_CONTRACT} NFT marketplace`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

                        </div>
                    </div>
                }





                {/* <Modal
                    isOpen={this.state.isSuccessClaim}
                    style={customStyles}
                    className="creatorrequ "
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left successpopup">
                       
                        <div className="artimgpop">
                            {this.state.isImage && <img src={dropList && dropList.art && dropList.art.thumbnailHash} alt="" />}
                        </div>
                       
                        <h3>Success!</h3>
                        <img src={`../${configPath.imageAssets}/divider.png`} className="w-100" />
                        
                        <div className="mt-3">Woohoo..! Successfully Claimed! Good luck!</div>
                        <div className="mt-3">It may take 30 seconds for your NFT to appear in collections. 
                       
                        </div>

                        <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetClaimPage() }}>View Collection</button> </div>
                </Modal> */}

                <Modal
                    isOpen={this.state.isSuccessClaim}
                    style={customStyles}
                    className="creatorrequ "
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left successpopup sspppopup">
                        <div className="dspcls" onClick={this.closeSuccessPopup}>x</div>
                        {/* <div className="artimgpop">
                            {this.state.isImage && <img src={dropList && dropList.art && dropList.art.thumbnailHash} alt="" />}
                        </div> */}
                        <h3 className="font-weight-normal">Drop successfully claimed</h3>
                        <svg className="checkmark claimedchek" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>

                        {/*<img src={`../${configPath.imageAssets}/divider.png`} className="w-100" />
                         <div className="mt-3">Art has been added to your new Disrupt Art account <span className="usernamelilac">(0x44f38aa8c1ce9deb)</span>.</div> */}
                        <div className="mt-3">It may take up to 30 seconds for your claimed art to appear in your collection.</div>
                        {/*<div className="mt-3">It may take 30 seconds for your NFT to appear in collections. 
                         Finalize your profile by adding details and a password now. 
                        </div> */}

                        <button type="button" className="btn btn-primary-gradient px-3 mt-5" onClick={(e) => { this.resetClaimPage() }}>View Collection</button> </div>
                </Modal>


                {(this.state.isFollower) ? '' :
                    <FollowPopup
                        fullName={currentUserDet && currentUserDet.art && currentUserDet.art.owner && currentUserDet.art.owner.fullName}
                        profileImage={currentUserDet && currentUserDet.art && currentUserDet.art.owner && currentUserDet.art.owner.profileImage}
                        userName={currentUserDet && currentUserDet.art && currentUserDet.art.owner && currentUserDet.art.owner.userName}
                        closeModal={this.closeModal}
                        ownerDetail={currentUserDet&&currentUserDet.art&&currentUserDet.art.owner}
                        handleFollow={this.handleFollow} />}

                {this.state.isErrorPoped &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size">
                            <div><h2 className="">Try again., By connecting a Blocto Wallet</h2></div>
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{this.state.popedDapperError}</div>


                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false }) }}>Close</button></div></div></div>
                }
                {this.state.isAirDrop ?
                    <div className="loader"><SolarSystemLoading /></div> : <div className="loader-hide"><SolarSystemLoading /></div>
                }
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                    : ''}
                <Modal
                    isOpen={this.state.isOpenEmail}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left claimpop">
                        <div className="dspcls" onClick={this.closeWithdraw}>x</div>
                        <div className="">
                            <h2 className=' text-center usernamelilac'>Claim ArtDrop Now </h2>
                            <div className="brdgradeint">
                                <img src={`../${configPath.imageAssets}/claim-reward.png`} className="dsp-img" />
                            </div>
                            
                            <img src={`../${configPath.imageAssets}/divider.png`} className="w-100 my-3" />
                            {/* {<div className="alert alert-cuslilac text-center mb-3">Or Please login</div>} */}
                            <div className="mb-3 text-center">
                                By using this site,<br/>you agree to our <a target="_blank" href={basePath + `/terms`}  rel="noreferrer">Terms of Use</a> and <a target="_blank" href={basePath + `/privacy`}>Privacy Policy.</a>
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="username">Email Address</label> */}
                                <input className="form-control dspinputme w-100 rounded-0" value={this.state.guestEmail} onChange={this.handleEmailChange} name="email" id="guestEmail" placeholder="Enter your email address" />
                                {this.state.submitted && !this.state.guestEmail && <div className="mandatory">Email required</div>}
                                {this.state.submitted && this.state.guestEmail && !isValidEmailAddress(this.state.guestEmail) && <div className="mandatory">Invalid email</div>}

                            </div>


                            <div className='row mt-5'>
                                {/* <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdraw}>Cancel</button></div> */}
                                <div className='col-12 text-center'><button className='btn btn-primary' type="button" onClick={this.approveWithdraw}>Request Claim Link</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isLocationAccessed}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">
                        <div className="dspcls" onClick={this.closeWarning}>x</div>
                        <div className="py-3">

                            <h2 className='mb-3 text-center'>Allow site to access this location? </h2>
                            {/* <img src={`../${configPath.imageAssets}/claim-reward.png`} className="dsp-img mt-4" /> */}
                            <div className="col-sm-12 text-center">
                                <h6>
                                    <p>Hi! Unfortunately, we are unable to proceed <b className='usernamelilac'>without access to your location</b></p>
                                    <p>To provide you with the <b className='usernamelilac'>most relevant airdrop results</b>, we kindly ask for your permission to <b className='usernamelilac'>access your current location</b>. </p>
                                    <p>Please click <b className='usernamelilac'>'Allow'</b> when prompted to grant us access to your location, so we can ensure you receive the most accurate airdrop.</p>
                                    <p>If you prefer not to share your location or have already denied access, <b className='usernamelilac'>you can change your site settings</b> to enable location services.</p>
                                    <p>Thank you for your cooperation :)</p>
                                </h6>
                            </div>


                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.isSendGuestEmail}
                    style={customStyles}
                    className="creatorrequ "
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left successpopup sspppopup">
                        {/*<div className="dspcls" onClick={this.closeClaimInitiatePopup}>x</div>
                         <div className="artimgpop">

                            {this.state.isImage && <img src={dropList && dropList.art && dropList.art.thumbnailHash} alt="" />}
                        </div> */}


                        <h3 class="font-weight-normal">An ArtDrop claim link has been sent to your email address.</h3>
                        <svg className="checkmark claimedchek" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        {/* <img src={`../${configPath.imageAssets}/divider.png`} className="w-100" /> */}

                        {/* <div className="mt-3"><b>We have sent a NFT AIRDROP claim link to your email address.</b></div> */}
                        <div className="mt-3">Hurry, as ArtDrops are available on a <span className="usernamelilac">FIRST-COME FIRST-CLAIM</span> basis.</div>
                        <div className="mt-3">Please check your inbox and click the provided link to proceed with your claim.</div>
                        <button type="button" className="btn btn-primary-gradient px-3 mt-4" onClick={this.closeClaimInitiatePopup}>Close</button>
                    </div>
                </Modal>



                <Modal
                    isOpen={this.state.scanModal}
                    style={customStyles}
                    className="creatorrequ adscanpop"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left minw-35rem">
                        <div className="dspcls" onClick={this.closeScanModal}>x</div>


                        <div className="col-sm-12 text-center">
                            <div className="bg-141414 py-3">
                                <div className="radar">
                                    <div className="radar__dot"></div>
                                    <div className="radar__dot"></div>
                                    <div className="radar__dot"></div>
                                </div>
                            </div>
                            <div>
                                <h4>Scanning area for available Airdrops</h4>
                            </div>
                        </div>
                        <div style={{ width: "100%" }}><img src={`../${configPath.imageAssets}/divider.png`} alt="" width="100%" /></div>
                        <div className='row'>
                            <div className='col-12 text-center'><button className='btn btn-primary-gradient' type="button" onClick={this.closeScanModal}>Cancel</button></div>
                            <div className='col-12 text-center'><Link to="/drops" className="usernameblue">Read more about Airdrops</Link></div>
                        </div>

                    </div>
                </Modal>
                <div className="container">
                    <div className="pagetitabtn mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="mb-3 mt-5 d-inline-block airdrop_title">Artdrops  <span className="small dsadsmalltxt cursor-pointer" onClick={() => this.gotoCreator(detailCreator)}>{(detailCreator && detailCreator.userName) ? <span>by {detailCreator.userName}</span> : ''}</span></h1>
                                <div className="probtns text-center activebtnscus float-right pt-0">
                                    <button type="button" className="d-none btn btn-rounded btn-outline-dark btn-sm" onClick={this.createSetupClicked}>Setup account</button>
                                    <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm d-none" onClick={this.refreshArts}><i className="fa fa-refresh" aria-hidden="true" ></i></button>
                                </div>
                            </div>
                        </div>
                        <hr className="mb-3 d-none" />
                    </div>

                    <div className="da-sfair">
                        {(this.state.hashValue !== null && this.state.hashValue !== '') ? (detailCreator && detailCreator.id) ? <div>
                            <div className="coverimg imgcovergif rounded-0">
                                {this.getBanner()}
                            </div>
                            <div className="propagecont ccdetailbox mb-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="d-flex align-items-start cctitpro justify-content-center">
                                                <div className="d-flex flex-column lftsdbar">
                                                    <div className="badgeview propicdetail">
                                                        <div className="picpro">
                                                            <span className="artcovimg">
                                                                <span className="artcov "></span>
                                                                <span className="artcovimginset no-proimg">
                                                                    <ProfilePreview src={(detailCreator && detailCreator.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCreator && detailCreator.profileImage}`)} alt="Avatar" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : (this.state.isDisabled) ? <CommonDisableError /> : (this.state.userNotFound) ? <CommonNotFoundError /> : '' : ''}
                        {(this.state.accessState) ?
                            <div className="row">
                                <div className="col-md-4 offset-md-4">
                                    <div className="alert alert-cuslilac text-center mb-3 p-5">
                                        <img src={`../${configPath.imageAssets}/locationunlock.png`} style={{ width: "80px", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: '15px', }} />
                                        Unlock the full potential of our services by granting location access or adjusting your site settings.
                                    </div>
                                </div>
                            </div>
                            : ''}
                        <div className="airdroplist">
                            <div className="row  mb-1">


                                {(dropList && dropList["id"] && dropList.art.title) ?

                                    <div className="col-sm-12" >

                                        <div className="cardlist dropseclist flex1 dsairartcol" onClick={(e) => {
                                            e.preventDefault();

                                            this.navigationCheck(e, dropList)
                                        }}>

                                            {this.state.isCelebration ? <CelebrationGif /> : ''}

                                            <div className="dsairartconte"><a href="" className="artcovimglink">
                                                <ArtPreview
                                                    src={dropList && dropList.art && dropList.art.thumbnailHash}
                                                    alt={dropList && dropList.art && dropList.art.title ? dropList.art.title : "Avatar"}
                                                    isPremiumArt={dropList && dropList.art && dropList.art.isPremiumArt}
                                                    showControls={true}
                                                    autoPlay={true}
                                                    mimeType={dropList && dropList.art && dropList.art.thumbnailMimeType} />
                                            </a>
                                                <div className="cont">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="piccent">
                                                                <div className="upic mb-2" style={{ cursor: "pointer" }}>
                                                                    <span className="artcovimg">
                                                                        <span className="artcov"></span>
                                                                        <span className="artcovimginset no-proimg">
                                                                            <img
                                                                                src={dropList && dropList.art && dropList.art.creator && dropList.art.creator.profileImage ?
                                                                                    `${process.env.REACT_APP_PROFILE_IMG_PATH}${dropList && dropList.art.creator && dropList.art.creator.profileImage}` :
                                                                                    `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <h3 className="userdescrip text-center airdroptitle"
                                                                title={dropList && dropList.art && dropList.art.title}> <IconComponent type={dropList.airDropType} />{dropList && dropList.art && dropList.art.title ?
                                                                    dropList.art.title :
                                                                    "Title"}</h3>
                                                            <h6 className="mb-0 text-center piccenth6"
                                                                id="creator" style={{ cursor: "pointer", }}
                                                                title={dropList && dropList.art && dropList.art.creator && dropList.art.creator.fullName}>
                                                                {dropList && dropList.art && dropList.art.creator && ((dropList.art.creator.fullName && dropList.art.creator.fullName.includes('@')) ?
                                                                    dropList.art.creator.fullName : (`by ${dropList.art.creator.fullName}`))}</h6>

                                                        </div>
                                                        <div className="col-6">
                                                            <div className="userwithpic">
                                                                <h6 className="mb-0"
                                                                    id="owner"
                                                                    style={{ cursor: "pointer", maxWidth: "100px" }}
                                                                    title={dropList && dropList.art && dropList.art.owner && dropList.art.owner.userName}>
                                                                    {dropList && dropList.art && dropList.art.owner && dropList.art.owner.userName ?
                                                                        ((dropList.art.owner.userName && dropList.art.owner.userName.includes('@')) ?
                                                                            dropList.art.owner.userName :
                                                                            <p>
                                                                                Listed by <span className="text-link" id="owner">@{dropList.art.owner.userName}</span>
                                                                            </p>
                                                                        ) :
                                                                        dropList.art.ownerWalletAddress != null && dropList.art.ownerWalletAddress}</h6>


                                                            </div><br />
                                                        </div>

                                                        {this.state.show && this.startTime(dropList && dropList.airDropDate)}



                                                        <div className="col-sm-12 text-center d-block d-sm-none"><img src={`../${configPath.imageAssets}/divider.png`} alt="" width="100%" /></div>

                                                    </div>
                                                    <div className="row mt-3">
                                                        {((localUserDetail && localUserDetail.data && localUserDetail.data.authUser && localUserDetail.data.authUser.id) == (dropList && dropList.art && dropList.art.owner && dropList.art.owner.id)) && <div className="col-sm-12 text-center ">
                                                            <button type="button" id="edit" className='btn btn-outline-dark w-50'  >Edit</button>

                                                        </div>}
                                                        {this.loggedUserCheck(dropList) && <div className="col-sm-12 text-center">
                                                            {/* <button type="button" id="claimwithwallet" className='btn btn-outline-dark w-100 claim_btn mb-3' disabled={!this.dayClosed(dropList && dropList.airDropDate)}>Connect wallet to claim</button> */}

                                                            <button type="button" id="claim" className='btn btn-primary-gradient' disabled={!this.dayClosed(dropList && dropList.airDropDate)}>Claim ArtDrop</button>

                                                        </div>
                                                        }
                                                    </div>

                                                </div></div>


                                        </div>
                                    </div> : (nearByAirdrop && nearByAirdrop.id && !this.state.locationLoading) ?
                                        <div className="col-md-12 text-center">
                                            <Tabs className="tab-wrap" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabChange(tabIndex)}>

                                                <TabList className="tab-change">
                                                    <Tab className={this.state.tabIndex === 0 ? "tab-select collection_arts " : 'collection_arts'} >
                                                        <h5 className={this.state.tabIndex === 0 ? "" : ''}>NEAR BY AIRDROP

                                                        </h5>
                                                    </Tab>
                                                    <Tab className={this.state.tabIndex === 1 ? "tab-select onsale_arts " : 'onsale_arts'} >
                                                        <h5 className={this.state.tabIndex === 1 ? "" : ''}>HINT</h5>

                                                    </Tab>
                                                </TabList>
                                                <TabPanel>
                                                    <h2 className="mt-5">Woohoo!</h2>
                                                    {this.isReached() ? <span className="usernamelilac rainbow rainbow_text_animated w-auto rainairdrops mb-3">You're getting closer!</span> : ''}
                                                    <h5 className="  ">
                                                        {this.checkMiles(nearByAirdrop.nearByLocation.distance) ? <p>There is a NFT AIRDROP available, around {this.getMiles(nearByAirdrop.nearByLocation.distance)} away in <span className="usernamelilac">{this.state.airdropDirection} </span>direction.</p> :
                                                            <p>There is a NFT AIRDROP available near your current location, just around {this.getMiles(nearByAirdrop.nearByLocation.distance)} away in <span className="usernamelilac">{this.state.airdropDirection} </span>direction.</p>}
                                                        Happy collecting!</h5>

                                                </TabPanel>
                                                <TabPanel>
                                                    <AirdropMapComponent currentLocation={this.state.currentLocation} destlocation={nearByAirdrop.nearByLocation} />
                                                </TabPanel>
                                            </Tabs>
                                            <div className="row mb-5">
                                                {this.state.locationLoading ? <div className="col-md-12 text-center mb-5">
                                                    <button className="btn btn-primary-fill mr-3" type="button" disabled>Refreshing</button>
                                                </div> : <div className="col-md-12 text-center mb-5">
                                                    <button className="btn btn-primary-fill mr-3" type="button" onClick={this.handleRefreshAirdrop}>Refresh <i className="fa fa-refresh" aria-hidden="true" ></i></button>
                                                    <button className="btn btn-primary-fill" type="button" onClick={this.handleSacnForAirdrops}>Scan new</button>
                                                </div>}
                                            </div>



                                        </div> :
                                        (this.state.locationLoading ? <div className="adscanpop w-100 mb-5">
                                            <div className="custom-ui1 reportpop1 text-left minw-35rem mx-auto">
                                                <div className="col-sm-12 text-center">
                                                    <div className="bg-1414141 py-3">
                                                        <div className="radar">
                                                            <div className="radar__dot"></div>
                                                            <div className="radar__dot"></div>
                                                            <div className="radar__dot"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h4>Scanning area for available drops...</h4>
                                                        <a class="btn btn-primary-gradient" onClick={this.cancelScanAirdrop}>Cancel</a>
                                                    </div>
                                                </div>
                                                {/* <div style={{ width: "100%" }}><img src={`../${configPath.imageAssets}/divider.png`} alt="" width="100%" /></div>
                                                    <div className='row'>
                                                        <div className='col-12 text-center'><button className='btn btn-primary-gradient' type="button" onClick={this.cancelScanAirdrop}>Cancel</button></div>
                                                        <div className='col-12 text-center d-none'><Link to="/drops" className="usernameblue">Read more about Airdrops</Link></div>
                                                    </div> */}

                                            </div>
                                        </div> :
                                            (isCheck &&

                                                <div className="col-md-12 text-center">
                                                    <div class="radarimg">
                                                        <img src={`../${configPath.imageAssets}/NoneFound.png`} className="mw-100 mb-3" alt="" /></div>

                                                    <h5 className="mb-3">Stand tuned. Some cool ArtDrops are on the way!</h5>
                                                </div>))
                                }

                                {(this.isUserPage(detailCreator) && this.state.nftId === '') ? <div className="col-md-4 offset-md-4 text-center">
                                    <div className=" mb-5 purchasebox">
                                        <img src={`../${configPath.imageAssets}/dont-claim.png`} className="mw-100 mb-3" alt="" style={{ width: "150px", height: "150px", marginTop: "15px" }} />
                                        <h4 className="usernamelilac  text-uppercase mb-1">You cannot claim</h4>
                                        <h5 className=" text-uppercase">your own airdrop</h5>
                                    </div></div> : ''}

                            </div>



                            {(!this.state.locationLoading && 
                                !this.state.userNotFound && 
                                !this.state.isDisabled && 
                                !this.isUserPage(detailCreator) && 
                                !(nearByAirdrop && nearByAirdrop.nearByLocation && nearByAirdrop.nearByLocation.distance)) ? <div className="row mb-5">
                                <div className="col-md-12 text-center mb-5">

                                    {((dropList && dropList["id"] && dropList.art.title) || isCheck) ? <div>
                                        <button type="button" className="btn btn-primary" onClick={this.handleSacnForAirdrops}>Scan Again</button>
                                    </div> :
                                        <div className="radarimg">
                                            <img src={`../${configPath.imageAssets}/scanfordrops.png`} className="mw-100" alt="" />
                                            <button type="button" className="btn btn-primary text-transform-none" onClick={this.handleSacnForAirdrops}>Scan for Drops</button>

                                        </div>
                                    }
                                </div>
                            </div> : ''}




                        </div>

                    </div>

                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dropList: state.airdrop.airdropList,
        isCheckDrop: state.airdrop.isCheckAirDrop,
        userFollow: state.follow.userFollow,
        wallet: state.wallet.wallet,
        failedFollow: state.follow.failedFollow,
    }
}
const actionCreators = {
    getDrops: airdropActions.getAirDropArt,
    resetDropArt: airdropActions.resetAirDropArt,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetFailedFollow: followActions.resetFailedFollow,

}
const connectAirDrops = connect(mapStateToProps, actionCreators)(AirDrops)
export { connectAirDrops as AirDrops }