import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { configPath } from 'config'

class HuntLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <Fragment>
                <div className='shlanding'>

                    <div className='container'>
                        <h3>Midwestcon scavenger hunt</h3>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='mwconbox'>
                                    <div className='mwconboxbg'><img src='https://midwestcon.disrupt.art/assets/img/mwc-banner-3.png' alt="" /></div>
                                    <div className='mwconboxcont'>
                                        <img src='https://midwestcon.disrupt.art/assets/img/midwestcon-logo.png' alt="" height={47} />
                                        <p>Brought to you by</p>
                                        <div className='twologos'>
                                            <img src={`../../../../${configPath.imageAssets}/DA-new-logo.png`} alt="" height="50" />
                                            <span>X</span>
                                            <img src='https://midwestcon.disrupt.art/assets/img/DigitalFuturesIconwordmark.png' alt="" height="29" />
                                        </div>
                                        <h4>New art to claim each day!</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='mwconbox sfld'>
                                    <div className='mwconboxbg'><img src={`../../../../${configPath.imageAssets}/Isolation_Mode.png`} alt="" /></div>
                                    <div className='mwconboxcont'>
                                        <h4>scan for local drops</h4>
                                        <a href='' className='btn btn-primary'>Scan for Drops</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='msh-content'>
                            <p>Dive into the future with our curated sessions, interactive digital encounters, and thought-provoking panels. Meet the innovators, venture capitalists, corporate leaders, brands, policy influencers, pioneering researchers, and trailblazers in social impact. Embrace the urgency to shape the future at MidwestCon - crafting tomorrow, today.</p>
                        </div>

                        <div className='msh-upcoming'>
                            <h3>upcoming</h3>
                            <div className='purchasebox'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                    <img src={`../../../../${configPath.imageAssets}/CandCLogotryone.png`} alt="" className='w-100' />
                                    </div>
                                    <div className='col-md-8'>
                                        <h4>cocktails and creatives</h4>
                                        <h5>August 27, 2023</h5>
                                        <p>Discover a New World of Artistic Libations at 'Cocktails and Creatives'!  Don't miss out on the opportunity to witness the intersection of art and cocktails like never before. Sign up for our mailing list to receive exclusive updates on ticket sales for 'Cocktails and Creatives.' Be among the first to secure your spot and witness the captivating collaboration between brilliant bartenders and cutting-edge AI artists. Get ready for a sensory journey like no other!</p>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const actionCreators = {


}

const connectHuntLanding = connect(mapStateToProps, actionCreators)(HuntLanding)
export { connectHuntLanding as HuntLanding }
