import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { eventActions, midwestActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { debounce } from 'utils';


class MidwestFetchEvents extends Component {
    constructor(props) {
        super(props);
     
        
        this.state = {
            events: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 10,
            pageCount: '',
            isFetched: false,
            search: '',
            selectedEvtType: -1
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.alleventHistory && props.alleventHistory.statusCode === 200)) {
            props.resetAllMidwestEvents()
            return {
                events: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.conventions ? props.alleventHistory.data.conventions : [],
                totalCount: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.count,
                pageCount: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.count / state.recordLimit,
                isFetched: true
            }
        }
        return null

    }
    handleRefresh = () => {
        this.setState({ offSet: 0, forcePage: 0,search : '', selectedEvtType: -1 }, () => {
            this.callApi()
        })
    }


    callApi = () => {
        const { search, selectedEvtType } = this.state;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy : 'createdAt',
            sortOrder : 'DESC'
        }
        if (search) {
            obj.search = search;
        }
        if (Number.isInteger(selectedEvtType) && selectedEvtType !== -1) {
            obj.eventType = selectedEvtType;
        }
        this.props.getAllEventsHistory(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
        // console.log(e.target.value)
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

  
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    
   
    gotoAddEvent = () => {
        history.push('/admin/midwestconEvent/add')
    }
    editAccount = (items) => {
        if ((items && items.id)) {
            history.push(`/admin/midwestconEvent/edit/${items.id}`)
        }
    }
    render() {


        const eventList = this.state.events && this.state.events.map((items, index) => {
            return <tbody key={index}>
                <tr>
                <td>{items && items.name}</td>
                <td>{items && items.startsAt && moment(items.startsAt).format('DD/MM/YYYY')}</td>
                <td>{items && items.endsAt && moment(items.endsAt).format('DD/MM/YYYY')}</td>
                <td>{items && items.venueAddress}</td>
                <td>{items && items.createdAt && moment(items.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
                <td>
                    <a className="btn btn-primary btn-sm btn-action mr-2" type="button" onClick={() => { this.editAccount(items) }} title="Edit"><i className="fa fa-pencil" aria-hidden="true"></i></a>
                </td>

                </tr>

            </tbody>

        })

       
        
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle row">
                        <div className='col-md-6'>
                        <h2 className="font-weight-bold">Midwestcon events</h2>
                        </div>
                        
                        {<div className="col-md-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoAddEvent}><i className="fas fa-plus"></i> Add Event</button>  </div>}

                        </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    
                                    {/* <div className="col-md-4 col-sm-12">

                                        <div className="text-right">
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>EVENT NAME</th>
                                        <th>STARTS AT</th>
                                        <th>ENDS AT</th>
                                        <th>VENUE ADDRESS</th>
                                        <th>CREATED AT</th>
                                        <th>ACTION</th>

                                    </tr>
                                </thead>

                                {eventList}

                            </table>
                            {this.state.events && this.state.events.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.events && this.state.events.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        alleventHistory: state.midwestcon.midwestEvents,

    }
}

const actionCreators = {
    getAllEventsHistory : midwestActions.getAllMidwestEvents,
    resetAllMidwestEvents : midwestActions.resetGetAllMidwestEvents

}

const connectMidwestFetchEvents = connect(mapStateToProps, actionCreators)(MidwestFetchEvents)

export { connectMidwestFetchEvents as MidwestFetchEvents }