import history from 'helpers/history';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { gotoCreator } from './bab-common-view';
import Toggle from 'react-toggle';
import { configPath } from "../../config";
import { getOrdinalSuffix } from 'utils';

class TagsCategoryView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            category: {},
            artDetail: {},
            serialNos: []
        }
    }

    componentDidUpdate(props, state) {
        if ((props && props.tags)) {
            if ((props.tags) != (state.data)) {
                this.setState({ data: props.tags })

            }
        }
        if (props && props.category) {
            if ((props.category) != (state.category)) {
                this.setState({
                    category: props.category
                })
            }
        }

        if (props && props.artDetail) {
            if ((props.artDetail) != (state.artDetail)) {
                this.setState({
                    artDetail: props.artDetail
                })
            }
        }

        if(props && props.serialNos){
            if((props.serialNos) !== (state.serialNos)){
                this.setState({
                    serialNos: props.serialNos
                })
            }
        }
    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    isArtVisible = (data) => {
        if((data && data.status && data.status) === 1){
            return true
        }else{
            return false
        }
    }
    displayToken = () => {
        if(this.state.serialNos && this.state.serialNos.length > 0){
            if(this.state.artDetail && this.state.artDetail.tokenId){
                const filteredObj = this.state.serialNos.find(el => el.tokenId === this.state.artDetail.tokenId)
                if(filteredObj && filteredObj.serialNumber){
                    return <span className='badge badge-dark tokenidimg'><img src={`../${configPath.imageAssets}/nft-1.png`} alt="" /> {filteredObj.serialNumber}<sup>{getOrdinalSuffix(filteredObj.serialNumber)}</sup>&nbsp;Edition - {this.state.artDetail.tokenId}</span>
                }else{
                    return <span className='badge badge-dark tokenidimg'><img src={`../${configPath.imageAssets}/nft-1.png`} alt="" /> {this.state.artDetail.tokenId}</span>
                }
            }
        }else{
            if(this.state.artDetail && this.state.artDetail.tokenId){
                return <span className='badge badge-dark tokenidimg'><img src={`../${configPath.imageAssets}/nft-1.png`} alt="" /> {this.state.artDetail.tokenId}</span>
            }            
        }
    }
    render() {
        const { data, category, artDetail } = this.state
        const { showStatus } = this.props
        return (
            <Fragment>
                <div className="dettittxt mb-3">
                    <h2 className="py-0 ">{artDetail && artDetail.title ? artDetail.title : ''} &nbsp;{(artDetail && artDetail.isBab) ?
                        <span className='ownerDec'>@Jim Jones</span> : 
                        <span className="ownerDec" onClick={() => gotoCreator(artDetail && artDetail.creator)}>
                        {artDetail && artDetail.creator && artDetail.creator.fullName && `by ${artDetail.creator.fullName}`}                       
                        </span>}
                        </h2>
                        {(artDetail.tokenId) ? <h5>
                        {this.displayToken()}
                        </h5> : ''}
                    </div>
                
                <div className="dettittxt mb-3">
                    <h4 className="py-0 ">{artDetail && artDetail.description}</h4>
                </div>
                {(data && data.length > 0) ? <div className="row mb-3">
                    <div className="col-md-12">
                        <div className="probtns activebtnscus py-0 custagbadge">
                            <ul className="list-unstyled mb-0 ">
                                {data && data.map((items) => (

                                    <li>

                                        <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "badge badge-dark btn-sm active cushashtag" : 'badge badge-dark badge-sm cushashtag'} onClick={() => { this.gotoTagView(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button>
                                    </li>

                                ))}
                            </ul>
                        </div>
                    </div>
                </div> : ''}
                <div className='mb-3 custagbadge purchased_category_nft'>
                {(category && category.name) ?
                    <span className='category-btn mr-2  align-items-center' onClick={() => this.gotoCategoryPaga(category)}><i className={category && category.iconClass} aria-hidden="true"></i>&nbsp; {category && category.name && category.name.charAt(0).toUpperCase() + category.name.slice(1)}</span>
                    : ''}
                    {showStatus ? <span className='category-btn mr-2  align-items-center' title={(this.isArtVisible(artDetail)) ? "Hide Art" : "Show Art"}>{this.isArtVisible(artDetail) ? <i className="fa fa-eye mr-2" aria-hidden="true"></i> : <i className="fa fa-eye-slash mr-2" aria-hidden="true"></i>}<Toggle checked={this.isArtVisible(artDetail)} onChange={() => this.props.statusChange(artDetail)} /></span> : ''}

                </div> 
                <div className="row">
                    <div className="col-sm-8 mb-3">
                        <a target="blank" href={artDetail && artDetail.imageHash && artDetail && artDetail.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                            <span className="texticon"> View On IPFS</span>
                            <i className="fas fa-external-link-alt"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8 mb-3">
                        <a target="blank" href={artDetail && artDetail.metadataJsonUrl && artDetail && artDetail.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                            <span className="texticon"> View Metadata</span>
                            <i className="fas fa-external-link-alt"></i>
                        </a>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-8 mb-3">
                        <a target="blank" href={artDetail && artDetail.flowScanURL && artDetail.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                            <span className="texticon"> View on Flow Scan</span>
                            <i className="fas fa-external-link-alt"></i>
                        </a>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export { TagsCategoryView }