import { creatorConstants } from "../../constants"
import { creatorServices } from "services"
import PubSub from 'pubsub-js';


export const creatorActions = {
    getCreators,
    getDetailCreator,
    resetCreators,
    getViewCount
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
} 
function getCreators(obj){
    return dispatch => {
        showLoader()
        creatorServices.getCreators(obj)
        .then(creator => {
            if(creator){
                dispatch({type:creatorConstants.GET_CREATORS,creatorsList:creator})
                hideLoader()
            }
        },error => {
            hideLoader()
            // toast.error(error)
            // console.log("error",error)
        })
    }
}
function resetCreators(){
    return dispatch => {
        dispatch({type:creatorConstants.GET_CREATORS,creatorsList:[]})
    }
}

function getDetailCreator(obj){
    return dispatch => {
        showLoader()
        creatorServices.getDetailCreator(obj)
        .then(creator => {
            if(creator){
                dispatch({type:creatorConstants.GET_DETAIL_CREATOR,detailCreator : creator})
                hideLoader()
            }
        },error => {
            hideLoader()
            // console.log("error",error)
        })
    }
}
function getViewCount(userId){
    return dispatch => {
        // showLoader()
        creatorServices.getViewCount(userId)
        .then(view => {
            if(view){
                dispatch({type:creatorConstants.VIEW_COUNT,views : view})
                hideLoader()
            }
        },error => {
            hideLoader()
            // console.log("error",error)
        })
    }
}


