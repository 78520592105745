import { configPath } from "config";
import { adminToken, refreshToken, accessHeader } from "helpers";
import PubSub from 'pubsub-js';
import { fetchService } from "./fetch-service";
import history from "helpers/history";
export const userServices = {
    login,
    logout,
    refresh,
    adminLogin,
    updateProfile,
    getUsers,
    getUSD,
    register,
    verify,
    webLogin,
    resendVerificationEmail,
    statusApprove,
    modifyStatus,
    forgotPassword,
    changeUserPassword,
    raiseRequest,
    googleLogin,
    setupSocialUserProfile,
    getOwnershipUser,
    getAnnouncementMessage,
    emailVerification,
    usernameVerification,
    emailVerificationStatus,
    getGlitterText,
    getUserFavourites,
    getFavouritedUser,
    addFavourites,
    removeFavourites,
    addBankDetail,
    getBankDetail,
    getuserWalletAccDetail,
    addPersonalDetail,
    getPersonalDetail,
    getDetailOfUser,
    getSelfAirDropArt,
    changeSelfPassword
}

function login(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/user-auth/auth`, requestOptions).then(handleResponse,handleError).then(user => { return user })
}

function logout() {
    // console.log("...accessHeader()",accessHeader())
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-auth/logout`, requestOptions).then(handleResponse,handleError)
}

function register(obj) {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/user-auth/register`, requestOptions).then(handleResponse,handleError)
}
function webLogin(obj) {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/user-auth/sign-in`, requestOptions).then(handleResponse,handleError)
}
function adminLogin(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/auth`, requestOptions).then(handleResponse).then(user => { return user })
}

function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },

    };
    return fetch(`${configPath.apiUrl}/user-management/user/${email}/reset-password`, requestOptions).then(handleResponse,handleError)
}

function changeUserPassword(obj, userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/user-management/user/${userId}/change-password`, requestOptions).then(handleResponse,handleError)
}

function verify(obj, userId) {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };

    return fetch(`${configPath.apiUrl}/user-management/user/${userId}/verify`, requestOptions).then(handleVerifyResponse,handleError)
}

function resendVerificationEmail(userId) {
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' }
    };

    return fetch(`${configPath.apiUrl}/user-management/user/${userId}/resend-verification`, requestOptions).then(handleVerifyResponse,handleError)
}

function updateProfile(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile`, requestOptions).then(handleResponse, handleError)
}

function getUsers() {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile`, requestOptions).then(handleResponse, handleError)
}

function raiseRequest(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj,
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/raise-creator-request`, requestOptions).then(handleResponse, handleError)
}

function statusApprove(obj) {
    const requestOptions = {
        method: "PUT",
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/creator-status`, requestOptions).then(handleResponse,handleError)

}
function getOwnershipUser(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/${(obj && obj.userId)}/ownership/art/${(obj && obj.artId)}`, requestOptions).then(handleResponse, handleError)
}
function modifyStatus(obj, userId, status) {
    const requestOptions = {
        method: "PUT",
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/${userId}/account-status`, requestOptions).then(handleResponse,handleError)

}
function getUSD() {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/coin-exchange/flow-to-usd`, requestOptions).then(handleResponse, handleError)
    // const requestOptions = {
    //     method : 'GET',
    //     headers: { ...authHeader(),'X-CoinAPI-Key' : '6771CC0C-A72A-4D62-8A3A-8292847917BE'},
    // }
    // return fetch(`https://rest.coinapi.io/v1/exchangerate/FLOW/USD`,requestOptions).then(handleResponse)

}
function getAnnouncementMessage(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/common/announcement`, requestOptions).then(handleResponse, handleError)
}
function refresh() {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(refreshToken())
    };
    return fetch(`${configPath.apiUrl}/user-auth/refresh`, requestOptions).then(handleResponse,handleError).then(user => { return user })
}
function emailVerification(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-auth/validation/email`, requestOptions).then(handleResponse,handleError)
}
function usernameVerification(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-auth/validation/user-name`, requestOptions).then(handleResponse,handleError)
}
function emailVerificationStatus(uuid){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/${uuid}/email-verification-status`, requestOptions).then(handleResponse, handleError)
}
function getGlitterText(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/glittering-texts`, requestOptions).then(handleResponse, handleError)
}
function getUserFavourites(queryParams){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = '';
	let offSet = queryParams && queryParams.offSet;

	let recordLimit = queryParams && queryParams.recordLimit ? '&recordLimit=' + queryParams.recordLimit : '';

	let sortBy = queryParams && queryParams.sortBy ? "&sortBy=" + queryParams.sortBy : '';

	let sortOrder = queryParams && queryParams.sortOrder ? '&sortOrder=' + queryParams.sortOrder : '';
	let type = queryParams && queryParams.type ? '&type=' + queryParams.type : '';

	let rarity = queryParams && queryParams.rarity ? '&rarity=' + queryParams.rarity : '';

	params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + type + rarity;
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/favourites?` + params, requestOptions).then(handleResponse, handleError)
}
function getFavouritedUser(artId){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/art/${artId}/favouritedUser`, requestOptions).then(handleResponse, handleError)
}
function addFavourites(obj){
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/favourites`, requestOptions).then(handleResponse, handleError)
}
function removeFavourites(obj){
    const requestOptions = {
        method: 'DELETE',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/favourites`, requestOptions).then(handleResponse, handleError)
}
function addBankDetail(obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile/payout`, requestOptions).then(handleResponse, handleError)
}
function getBankDetail(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile/payout`, requestOptions).then(handleResponse, handleError)
}
function getuserWalletAccDetail(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/wallet-account`, requestOptions).then(handleResponse, handleError)
}
function addPersonalDetail(obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile/personal`, requestOptions).then(handleResponse, handleError)
}
function getPersonalDetail(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/profile/personal`, requestOptions).then(handleResponse, handleError)
}
function getDetailOfUser(obj){
	const requestOptions = {
		method : "GET",
		headers : {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/${encodeURIComponent(params)}`,requestOptions).then(handleResponse,handleError)
}
function getSelfAirDropArt(obj) {
    const requestOptions = {
        method: "GET",
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        signal : obj.signal
    }
    let params = ''
    let offSet = obj && obj.offSet

    let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

    let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''


    params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder

    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/air-drop` + params, requestOptions).then(handleResponse, handleError)
}
function changeSelfPassword(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-management/user/self/auth/change-password`, requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
    if(!(response && response.res))
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 403) {
                // refresh().then(res => {
                //     localStorage.setItem("userDetail",JSON.stringify(res))
                //     console.log("ref",res)
                // })
            }
            
            if((data && data.message && data.message.toLowerCase()) === "email has not been verified"){

                if(data && data.data && data.data.data && data.data.data.id){
                    return Promise.reject({
                        id : data.data.data.uuid,
                        message : data && data.message
                    })
                }
            }
            const error = (data && data.data && data.data.data && data.data.data[0] && (data.data.data[0][0])) || (data && data.message)
            return Promise.reject(error)
        }
        return data;
    });
}

function handleVerifyResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 403) {
                // refresh().then(res => {
                //     localStorage.setItem("userDetail",JSON.stringify(res))
                //     console.log("ref",res)
                // })
            } else if (response.status === 400) {
                if (data.message && data.message.toLowerCase().includes('user not found')) {
                    history.push('/notFound');
                }
            }
            const error = data
            return Promise.reject(error)
        }
        return data;
    });
}

function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            // Promise.reject(new Error(err))
            // throw err
            return Promise.reject(err)
        }else{
            PubSub.publish('msg', false)
            return Promise.reject("Unexpected error occured")
        }
    }
    catch(err) {
    console.log(err)
  
    }
}
function googleLogin(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-auth/google`, requestOptions).then(handleResponse).then(user => { return user })
}
function setupSocialUserProfile(username, token) {
    const body = JSON.stringify({
        userName: username,
        token
    });
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body
    };
    return fetch(`${configPath.apiUrl}/user-auth/google/profile-setup`, requestOptions).then(handleResponse).then(user => { return user })
}
