import { isSuperAdmin } from 'helpers';
import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { userAction } from 'store/actions'

class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state={
            open:false
        }
    }
    handleLogout = () => {
        userAction.adminLogout()
    }
    
    render(){
        const pathname = window.location.pathname
        const {open} = this.props
        return(
            <Fragment>
               <div className={open ? "sidebar bg1c1c1c showsidebar" : 'sidebar bg1c1c1c'}>
                    {/* <div className="smallilac mobview">
                            <Link className="nav-link small">ADMIN<br /><span className="usernamelilac">999.9869</span> Flow</Link>
                            <Link className="nav-link small">FUSD<br /><span className="usernamelilac">0x195a94a64949ed6b</span></Link>                            
                            <Link className="nav-link small">MARKET<br /><span className="usernamelilac">999.9869</span> Flow</Link>
                            <Link className="nav-link small">FUSD<br /><span className="usernamelilac">0x195a94a64949ed6b</span></Link>                        
                        </div> */}

                    <Link to="/admin/dashboard" className={pathname.includes('dashboard') ? "highLight" : ''} >Dashboard</Link>
                    <Link to="/admin/artsList" className={pathname.includes('arts') ? "highLight" : ''} >Arts</Link>
                    <Link to="/admin/dropsList" className={pathname.includes('dropsList') ? "highLight" : ''} >Drops</Link>
                    <Link to="/admin/transactions" className={pathname.includes('transactions') ? "highLight" : ''} >Listing History</Link>

                    <Link to="/admin/users" className={pathname.includes('users') ? "highLight" : ''} >Users</Link>

                    <Link to="/admin/creatorsApplications" className={(pathname == "/admin/creatorsApplications") ? "highLight" : ''} >Creator Applications</Link>
                    {/* <Link to="/admin/uploadUser" className={pathname.includes('uploadUser') ? "highLight" : ''} >Import Users <span className="betaLink">(Beta)</span> </Link> */}
                    <Link to="/admin/adminStatus" className={pathname.includes('adminStatus') ? "highLight" : ''} >Admin/Market Details </Link>
                    {/* <Link to="/admin/marketStatus" className={pathname.includes('marketStatus') ? "highLight" : ''} >Market Details </Link> */}

                    {/* <Link to="/admin/creators" className={(pathname == "/admin/creators") ? "highLight" : ''}>Creators</Link> */}
                    <Link to="/admin/discover" className={pathname.includes('discover') ? "highLight" : ''}>Discover</Link>
                    {/* <Link to="/admin/accounts" className={pathname.includes('accounts') ? "highLight" : ''} >Admin Accounts</Link> */}
                    <Link to="/admin/saleDetail" className={pathname.includes('sale') ? "highLight" : ''} >Sales </Link>
                    <Link to="/admin/mintdetail" className={pathname.includes('mintdetail') ? "highLight" : ''} >Mint Detail </Link>
                    <Link to="/admin/processBlock" className={pathname.includes('processBlock') ? "highLight" : ''} >Process Block  </Link>
                    <Link to="/admin/eventHistory" className={pathname.includes('eventHistory') ? "highLight" : ''} >Events History </Link>
                    {isSuperAdmin() ? <Link to="/admin/accounts" className={pathname.includes('account') ? "highLight" : ''} >Admin Accounts  <span className="betaLink">(Soon)</span></Link> : ''}
                    <Link to="/admin/settings" className={pathname.includes('settings') ? "highLight" : ''} >Settings</Link>
                    <Link to="/admin/tags" className={pathname.includes('tags') ? "highLight" : ''} >Tags </Link>
                    <Link to="/admin/priorityCreator" className={pathname.includes('priorityCreator') ? "highLight" : ''} >Priority Creators </Link>
                    <Link to="/admin/announcement" className={pathname.includes('announcement') ? "highLight" : ''} >Announcement </Link>
                    <Link to="/admin/airdrops" className={pathname.includes('airdrops') ? "highLight" : ''} >Airdrops </Link>
                    <Link to="/admin/category" className={pathname.includes('category') ? "highLight" : ''} >Category <span className="betaLink">(Beta)</span></Link>
                    <Link to="/admin/trending" className={pathname.includes('trending') ? "highLight" : ''} >Trending Tags <span className="betaLink">(Beta)</span></Link>
                    <Link to="/admin/badges" className={pathname.includes('badges') ? "highLight" : ''} >Badges <span className="betaLink">(Beta)</span></Link>
                    <Link to="/admin/fetch-user-air-drops" className={pathname.includes('fetch-user-air-drops') ? "highLight" : ''} >Airdrop status check</Link>
                    <Link to="/admin/addevents" className={pathname.includes('addevents') ? "highLight" : ''} >Add Events</Link>
                    <Link to="/admin/mintslots" className={pathname.includes('mintslots') ? "highLight" : ''} >Mint Slots</Link>

                    <Link to="#" className={pathname.includes('mintslots') ? "highLight dropdown dropcustom" : 'dropdown dropcustom'}>
                    <span id="dropdownMenuButton" className='dropdown-toggle '  data-toggle="dropdown" aria-expanded="false">Midwestcon</span>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <Link to="/admin/midwestconEventList" className={pathname.includes('midwestconEvent') ? "highLight" : ''} >Events</Link>
                                <Link to="/admin/midwestEventsGalleryList" className={pathname.includes('midwestEventsGallery') ? "highLight" : ''} >Events Gallery</Link>
                            </div>
                    </Link>
                    <Link to="/admin/reports" className={pathname.includes('reports') ? "highLight" : ''} >Reports <span className="betaLink">(Soon)</span></Link>
                    <Link to="/admin/stores" className={pathname.includes('store') ? "highLight" : ''} >Stores</Link>
                    <Link to="/admin/missed-tokens" className={pathname.includes('missed-tokens') ? "highLight" : ''} >Missed Tokens <span className="betaLink">(Beta)</span></Link>


                    {/* <a href="#about">Logout</a> */}
                    <a className="p-3 mobview" style={{cursor:"pointer"}} onClick={this.handleLogout}>Logout</a>

                </div>
            </Fragment>
        )
    }
}

export {Sidebar}