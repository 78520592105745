import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { getUserAddress, GetCreatorRoyality, GetMarketFee, CalculateShare, marketSaleTokenGroup, getDisplayMessageForRuntimeTxnError, splitArray, getBatchSize } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { connect } from 'react-redux';
import { artActions, userAction, walletActions } from 'store/actions';
import { artServices, dropServices, fetchService, userServices, walletServices, airDropService } from 'services';
import { checkWalletAddress, flowDecimal, getCurrentUserId, isConnectWallet, localAirdropPage, localArtPage, localDropPage, MaintenancePopup } from 'helpers';
import * as fcl from "@onflow/fcl";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { configPath } from '../config'
import { listArtForFlowAuction } from 'utils/flowauction';
import moment from 'moment'
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { ArtViewPreview } from './art-preview/artview-preview';
import Toggle from 'react-toggle';
import { EditCategory } from './artdetail-view/edit-category';
import { EditTag } from './artdetail-view/edit-tag';
import { getCreatorImage, getCreatorName } from './artdetail-view/bab-common-view';
import { JoyRide } from './Joy-ride';
import { checkOwnListingAuctionPage } from 'helpers/joy-rideCheck';

var timeout;
var dataURL = {}
class DetailWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            price: 350,
            basePrice: Number(350).toFixed(8),
            usd: '',
            isSuccess: false,
            message: '',
            isAlert: false,
            isVisible: false,
            isShare: false,
            count: 1,
            submitted: false,
            ShowPop: false,
            isList: false,
            totalOwnerArt: '',
            resale_royalty: '',
            market_Fee: '',
            crt_share: '',
            tabIndex: 0,
            disableList: false,
            isTransLoader: false,
            startPrice: 350,
            minBid: 1,
            minimumBid: 1,
            startingPrice: 350,
            endDays: 7,
            isSubmitAuction: false,
            isSuccessAuction: false,
            isAuction: false,
            isImage: false,
            listingInProgress: false,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            tokensProcessed: 0,
            isListingAborted: false,
            tokensListedBeforeAbort: 0,
            listingAbortError: '',
            isMaintenance: false,
            onstart: moment(),
            airdropCount: 1,
            pauseResume: false,
            isAgree: true,
            isAirDrop: false,
            isSelectedMembers: false,
            selectedMembers: [],
            member: '',
            isImported: false,
            uploadedFile: [],
            localImageName: '',
            localFileData: undefined,
            localImgPreview: '',
            localVideoPreview: '',
            localAudioPreview: '',
            localArtType: '',
            isArtStatusChangeSuccess: false,
            isThumbnailSuccess: false,
            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isThumbnailUpdateSuccess: false, // if thumbnail upload success,
            isCategoryUpdateSuccess: false, // if category update success
            isTagUpdateSuccess: false

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    /* getUSD = () => {
        var rate  = ""
        userServices.getUSD().then(res => {
            if((res && res.statusCode == 200)){
                res && res.data && res.data.src_side_base && res.data.src_side_base.map(item => {
                    if ((item && item.asset) === "USD") {
                        rate = item.rate
                    }
                })
                this.setState({rate})
            }
        }).catch(err => {
            console.log(err)
        }) */
    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()
        // console.log("sale props",this.props.mySaleProps)
        // if(this.props && this.props.mySaleProps){
        //     this.setState({ data : this.props.mySaleProps ? this.props.mySaleProps : [],artOwner:this.props.mySaleProps && this.props.mySaleProps.ownerWalletAddress,artCreator:this.props.mySaleProps && this.props.mySaleProps.creatorWalletAddress})
        // }
        this.getRoyalty()
        this.calculateSharings(this.state.basePrice)
        this.getArtByToken()
    }
    getArtByToken = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.showLoader()
            artServices.getArtByToken(id).then(art => {
                this.hideLoader()


                if ((art && art.statusCode) === 200) {
                    this.gotoTop()
                    userServices.getUsers().then(res => {
                        if ((res && res.statusCode) === 200) {
                            if ((res && res.data && res.data.id) === ((art && art.data && art.data.owner && art.data.owner.id))) {

                                let dataVal = art && art.data
                                let dropStr = dataVal && dataVal.dropsCount
                                let dropArr = '[' + dropStr + ']'
                                let dropsCountarray = eval(dropArr && dropArr)
                                let totalTokenId = dataVal && dataVal.totalTokenId
                                let arr = '[' + totalTokenId + ']'
                                let groupIds = eval(arr && arr)
                                // console.log(groupIds)
                                let totalOwnerArt = dataVal && dataVal.totalOwnerArt
                                dropsCountarray && dropsCountarray.map((items) => {
                                    if (items) {
                                        groupIds = groupIds.filter((el) => !(items && items.tokens).includes(el))

                                    }
                                })
                                this.fetchArt(art && art.data)

                                this.setState({ data: art && art.data ? art.data : [], artOwner: art && art.data && art.data.owner && art.data.owner.userName, artCreator: art && art.data && art.data.creator && art.data.creator.userName, groupIds, totalOwnerArt })
                            } else {
                                // history.push('/notFound')
                                let dataVal = art && art.data
                                history.push(`/art/${dataVal && dataVal.id}`)
                            }
                        }
                    }).catch(
                        err => {
                            PubSub.publish('msg', false)
                        }
                    )
                } else {
                    if (art && art.statusCode === 400) {
                        if (art && art.message && art.message.toLowerCase().includes("has been hidden by the owner")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been hidden by the art owner"
                            })
                        } else if (art && art.message && art.message.toLowerCase().includes("has been disabled by the administrator")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been disabled by the administrator"
                            })
                        } else {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "Art has been disabled"
                            })
                        }
                    }
                }
            }).catch(err => {
                if (err && err.toLowerCase().includes("has been hidden by the owner")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been hidden by the art owner"
                    })
                } else if (err && err.toLowerCase().includes("has been disabled by the administrator")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been disabled by the administrator"
                    })
                } else {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "Art has been disabled"
                    })
                }
                this.hideLoader()
            })
        }

        this.getUSD()
    }
    static getDerivedStateFromProps(props, state) {
        // let rate = ""

        if ((props && props.wallet && props.wallet.statusCode) === 200) {
            props.resetlinkWallet()


        }
        // if (props) {
        // props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
        //     if ((item && item.asset) === "USD") {
        //         rate = item.rate
        //     }
        // })
        // return {
        //     rate
        // }
        //}
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    componentDidUpdate(props, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

        if ((this.props && this.props.artVisibilityStatusChangedFailed && this.props.artVisibilityStatusChangedFailed.length > 0) && (this.props.artVisibilityStatusChangedFailed) != (props.artVisibilityStatusChangedFailed)) {
            this.props.resetVisibilityStatusFailed()
            this.setState({ isAlert: true, message: this.props.artVisibilityStatusChangedFailed, style: "danger" })
            this.gotoTop()
        }
        if ((this.props && this.props.artVisibilityStatusChangesSuccess && this.props.artVisibilityStatusChangesSuccess.statusCode === 200) && (this.props.artVisibilityStatusChangesSuccess) != (props.artVisibilityStatusChangesSuccess)) {
            this.props.resetVisibityStatusSuccess()
            this.getArtByToken()
            this.setState({
                isArtStatusChangeSuccess: true
            })
        }
    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab, count: 1, airdropCount: 1, onstart: '', submitted: false, isAgree: true, isImported: false
        })
    }
    handleActionChange = (e) => {

        if ((e.target.value >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= 30)) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    checkNumber = (value) => {
        if (Number.isInteger(value)) {
            return true
        } else {
            return false
        }
    }
    handleBidPrice = (e) => {
        // Split the input string into two arrays containing integers/decimals
        // to check allow only two degits after decimals
        var res = (e.target.value).split(".");
        if ((e.target.value >= 0) && this.digitValidate(e.target.value) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                startPrice: e.target.value, startingPrice: num
            })
        }
    }
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    handleMinBid = (e) => {
        if (!(isNaN(e.target.value)))
            var res = (e.target.value).split(".");
        if (e.target.value >= 0 && (this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                minBid: e.target.value, minimumBid: num
            })
        }
    }
    handleCount = (e) => {
        const { groupIds } = this.state
        if ((e.target.value) > 0 && (e.target.value) <= (Number(groupIds && groupIds.length)) && ((e.target.value) % 1 === 0)) {
            this.setState({ count: e.target.value })
        } else {
            this.setState({ count: '' })
        }
    }
    handleCountforAirdrop = (e) => {
        const { groupIds } = this.state
        if (
            (e.target.value) > 0 &&
            (e.target.value) <= (Number(groupIds && groupIds.length)) &&
            ((e.target.value) % 1 === 0) &&
            ((e.target.value) <= 250)
        ) {
            this.setState({ airdropCount: e.target.value })
        } else {
            this.setState({ airdropCount: '' })
        }
    }
    handleOnstart = (e) => {
        this.setState({
            onstart: e
        })
    }
    listToAuction = () => {
        const { startPrice, minBid, endDays, data, startingPrice, minimumBid } = this.state
        this.setState({ isSubmitAuction: true })
        var daysForward = moment().add(endDays, 'day');
        const endTimeStamp = Math.floor(new Date(daysForward.toLocaleString()).getTime() / 1000)
        const ownerWalletAddress = data && data["ownerWalletAddress"]
        // console.log(checkWalletAddress(ownerWalletAddress,ownerWalletAddress))
        if (((data && data['tokenId']) && (startingPrice && startPrice && Number(startPrice) > 0) && (minimumBid && minBid && Number(minBid) > 0) && (endDays && endDays > 0) && (endTimeStamp) && (this.checkNumber(parseInt(endDays))))) {
            isConnectWallet() ?
                getUserAddress().then(res => {
                    if ((checkWalletAddress(ownerWalletAddress, res))) {
                        if (res != null) {
                            this.showLoader()
                            walletServices.checkWallet(res).then(wallet => {
                                this.hideLoader()
                                if ((wallet && wallet.statusCode) === 200) {
                                    this.setState({ isTransLoader: true })
                                    this.setState({ disableList: true })
                                    dropServices.saveEvent(data.tokenGroupId, 1, dropServices.DropEventType.FOR_AUCTION, data.tokenId)
                                        .then((evtRes) => {
                                            if (evtRes === true) {
                                                listArtForFlowAuction((data && data['tokenId']), endTimeStamp, parseFloat(startingPrice), parseFloat(minimumBid))
                                                    .then(res => {
                                                        this.setState({ isTransLoader: false })
                                                        if ((res && res.status === 200)) {
                                                            console.log("move to auction", res)
                                                            if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                                this.setState({ ShowPop: false, isAuction: false })
                                                                this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                                this.gotoTop()
                                                                this.setState({ disableList: false })

                                                                // toast.error("Transaction failed", { position: toast.POSITION.BOTTOM_CENTER })
                                                            } else {
                                                                // toast.success("Token Move To Market Successfully...!",{position:toast.POSITION.BOTTOM_CENTER})
                                                                // history.push('/wallet')
                                                                this.setState({ ShowPop: false, isAuction: false })
                                                                this.setState({ isSuccessAuction: true })
                                                                this.setState({ disableList: false })

                                                            }
                                                        }
                                                        else {
                                                            let mes = '';
                                                            if (
                                                                res &&
                                                                res.data &&
                                                                res.data.Error
                                                            ) {
                                                                const splitError = res.data.Error.split(':');
                                                                if (
                                                                    splitError[2] &&
                                                                    splitError[2].includes("Session expired")
                                                                ) {
                                                                    mes = "Session Expired.Please reconnect your wallet account";
                                                                } else if (
                                                                    splitError[2]
                                                                ) {
                                                                    mes = splitError[2]
                                                                } else if (splitError[1]) {
                                                                    mes = splitError[1]
                                                                } else {
                                                                    mes = splitError[0];
                                                                }
                                                            }
                                                            this.setState({ ShowPop: false, isAuction: false })
                                                            this.setState({ disableList: false })

                                                            this.setState({ isAlert: true, message: mes, style: "danger" })
                                                            this.gotoTop()

                                                        }
                                                        console.log("auction", res)
                                                    }).catch(err => {
                                                        this.setState({ isTransLoader: false })
                                                        this.setState({ disableList: false })

                                                        console.log("action err", err)
                                                    })
                                            } else {
                                                this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                            }
                                        })
                                        .catch(err => {
                                            this.hideLoader()
                                            this.setState({ isAlert: true, message: err, style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                            this.setState({ disableWithdraw: false })
                                        })
                                }
                            }).catch(err => {
                                this.setState({ isAlert: true, message: err, style: "danger" })
                                this.gotoTop()
                                this.hideLoader()
                                this.setState({ disableList: false })

                                // toast.error(err)
                            })
                        }
                    } else {
                        this.setState({ disableList: false })
                        this.setState({ ShowPop: false, isAuction: false })

                        this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                        this.gotoTop()
                    }
                }).catch(err => {
                    this.setState({ disableList: false })
                    this.setState({ ShowPop: false, isAuction: false })

                    // toast.error("Please reconnect your wallet")
                    this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                    this.gotoTop()
                    // this.closeAlert()
                }) : this.handleLogin("auction")
        }
        else {

            this.setState({ isAlert: true, message: "Mandatory fields are missing", style: "danger" })
            this.gotoTop()
        }
    }
    handleLogin = (path) => {
        const { data } = this.state
        fcl.logIn().then(res => {
            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                if (path === "sell") {
                    this.setState({ ShowPop: false, isList: true })

                    this.handleSale(data && data.tokenId)
                } else if (path === "auction") {
                    this.setState({ ShowPop: false, isAuction: true })

                    this.listToAuction()
                } else if (path == "airdrop") {
                    this.setState({ ShowPop: false, isAirDrop: true })
                    this.handleAirDrop(data && data.tokenId)
                }

            }
        }).catch(err => {
            this.setState({ ShowPop: false, isList: false })
        })

    }
    getRoyalty = () => {
        let resale_royalty = ""
        let market_Fee = ""
        GetCreatorRoyality().then(res => {
            if ((res && res.status) === 200) {
                resale_royalty = parseFloat(res && res.data && res.data.Value).toFixed(0)
                this.setState({ resale_royalty })
            }
        })
        GetMarketFee().then(res => {
            if ((res && res.status) === 200) {
                market_Fee = parseFloat(res && res.data && res.data.Value).toFixed(0)
                // console.log("marketFee", market_Fee)
                this.setState({ market_Fee })

            }
        })
    }

    calculateSharings = (markedPrice) => {
        if ((markedPrice && markedPrice > 0)) {
            let creatorRoyalty = ""
            let marketFee = ""
            let crtShr = 0
            let markShr = 0


            GetCreatorRoyality().then(res => {

                if ((res && res.status) === 200) {
                    creatorRoyalty = res && res.data && res.data.Value
                    // console.log("creatorRo",creatorRoyalty,markedPrice)
                    CalculateShare(markedPrice, creatorRoyalty).then(res => {
                        crtShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                        this.setState({ crt_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                        GetMarketFee().then(res => {
                            if ((res && res.status) === 200) {
                                marketFee = res && res.data && res.data.Value
                                // console.log("marketFee",marketFee)

                                CalculateShare(markedPrice, marketFee).then(res => {
                                    markShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                                    this.setState({ mark_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                    let remain_shr = (100.0 - (Number(creatorRoyalty) + Number(marketFee))).toFixed(8)
                                    // console.log("tot",remain_shr)
                                    CalculateShare(markedPrice, remain_shr).then(res => {

                                        this.setState({ sell_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value, isShare: true, isVisible: false })
                                    }).catch(err => { })
                                }).catch(err => { })
                                this.setState({ marketFee: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                            }
                        }).catch(err => { })
                    }).catch(err => { })
                    this.setState({ creatorRoyalty: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                }
            }).catch(err => { })






        } else {
            this.setState({ isVisible: false, crt_share: '' })
        }
    }
    handlePriceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0) {
            if ((this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
                let basePrice = ''
                const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

                const num = (Number(e.target.value))
                // const precision = num.toPrecision(10)
                const precision = num.toFixed(8)

                // if (e.target.value && Number.isInteger(num)) {
                //     basePrice = precision
                // } else {
                basePrice = precision

                //}
                this.setState({ price: e.target.value, basePrice, usd: rate ? rate.toFixed(2) : '', isVisible: true }, () => {
                    this.calculateSharings(this.state.basePrice)
                })
            }
        } else {
            this.setState({ isVisible: false, isShare: false })
            this.setState({ isAlert: true, message: 'Invalid Price', style: "danger" })
            this.gotoTop()
            // this.closeAlert()
            // toast.error("Invalid Price", { position: toast.POSITION.BOTTOM_CENTER })
        }
    }

    showError(error) {
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        this.setState({
            isAlert: true,
            message: mes,
            style: "danger",
            listingInProgress: false,
            tokensProcessed: 0,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            ShowPop: false,
            isList: false,
            disableList: false
        })
        this.gotoTop()
        // this.closeAlert()

        // toast.error("User rejected signature", { position: toast.POSITION.BOTTOM_CENTER })
    }
    async processSale(tokens) {
        this.showLoader();
        this.setState({ isTransLoader: false })
        const artTokenGroupId = this.state.data.tokenGroupId;
        await dropServices.saveEvent(artTokenGroupId, tokens.length, dropServices.DropEventType.FOR_SALE, this.state.data.tokenId);
        const batchSize = getBatchSize();
        const batchCount = Math.ceil(tokens.length / batchSize);
        const itemsPerBatch = Math.floor(tokens.length / batchCount);
        const batches = splitArray(tokens, itemsPerBatch);
        // console.log(`%cBatches ${batches.length}`, 'font-size:20px', itemsPerBatch)
        let count = 0;
        if (batches.length > 1) {
            this.setState({
                listingInProgress: true,
                totalTokensToBeListed: tokens.length
            });
            this.hideLoader();
        } else {
            this.setState({
                listingInProgress: false
            });
        }
        let listedTokensCount = this.state.tokensProcessed;
        let isAborted = false;
        for (const batch of batches) {
            const tokenResponse = await marketSaleTokenGroup(batch, this.state.basePrice);
            this.setState({ isTransLoader: false })
            if ((tokenResponse && tokenResponse.status) === 200) {

                if (tokenResponse && tokenResponse.data && tokenResponse.data.transaction && tokenResponse.data.transaction.errorMessage) {
                    isAborted = true;
                    this.setState({
                        ShowPop: false,
                        isList: false,
                        isAlert: true,
                        message: getDisplayMessageForRuntimeTxnError(tokenResponse.data.transaction.errorMessage),
                        style: "danger",
                        disableList: false,
                        listingInProgress: false,
                        tokensProcessed: 0,
                        listingProgress: 0,
                        totalTokensToBeListed: 0
                    });
                    this.gotoTop()
                    // this.closeAlert()
                    console.error('Transaction Failed', tokenResponse.data.transaction.errorMessage);
                    break;
                    // toast.error("Transaction failed", { position: toast.POSITION.BOTTOM_CENTER })
                } else {
                    // toast.success("Token Move To Market Successfully...!",{position:toast.POSITION.BOTTOM_CENTER})
                    // history.push('/wallet')
                    // console.log("%ctoken group id is" + artTokenGroupId, 'font-size:20px')
                    // console.log("%ctype of token group id is" + typeof artTokenGroupId, 'font-size:20px')
                    // console.log("%cartTokenGroupId && artTokenGroupId != null" + JSON.stringify(artTokenGroupId && artTokenGroupId != null), 'font-size:20px')
                    count++;
                    listedTokensCount += batch.length;
                    const progressPercent = Math.round((count / batches.length) * 100);
                    // console.log(`%cprogressPercent Math.round((${count}/${batches.length})*100)`, 'font-size:20px', progressPercent)
                    this.setState({
                        listingProgress: progressPercent,
                        tokensProcessed: listedTokensCount
                    })
                    console.log(`%cmoved to market (${count}/${batches.length})`, 'font-size:16px', tokenResponse)
                }
            } else {
                if (tokenResponse && tokenResponse.data && tokenResponse.data.Error) {
                    this.showError(tokenResponse.data.Error);
                    isAborted = true;
                    break;
                }
            }
        }
        if (count) {
            this.setState({
                ShowPop: false,
                isList: false,
                isAlert: false,
                isSuccess: true,
                disableList: false,
                listingInProgress: false,
                tokensProcessed: 0,
                listingProgress: 0,
                totalTokensToBeListed: 0,
                isListingAborted: isAborted,
                tokensListedBeforeAbort: listedTokensCount,
                listingAbortError: this.state.message
            });
            this.hideLoader();
        }
    }
    handleSale = (id) => {
        const { groupIds, totalOwnerArt, data } = this.state

        const ownerWalletAddress = data && data.ownerWalletAddress
        // console.log("ownerWalletAddress",ownerWalletAddress)
        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.count
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens
            let tokenArray = JSON.stringify(selectedTokens)
            // console.log("dropsCountarray",groupIds,selectedTokens,arrayLength,checkCount)


            if (this.state.basePrice && this.state.basePrice > 0) {
                isConnectWallet() ?
                    getUserAddress().then(res => {
                        if ((checkWalletAddress(ownerWalletAddress, res))) {
                            if (res != null) {
                                const artTokenGroupId = data.tokenGroupId;
                                this.showLoader();
                                walletServices.checkWallet(res).then(wallet => {
                                    this.hideLoader()
                                    if ((wallet && wallet.statusCode) === 200) {
                                        // console.log("wallet detail", wallet)
                                        this.setState({ disableList: true })
                                        this.setState({ isTransLoader: true })
                                        this.processSale(JSON.parse(tokenArray)).then(() => {
                                            this.hideLoader();
                                        }).catch(err => {
                                            this.hideLoader()
                                            this.setState({ disableList: false })
                                            this.setState({ isTransLoader: false })
                                        })
                                    }
                                }).catch(err => {
                                    this.setState({ isAlert: true, message: err, style: "danger" })
                                    this.gotoTop()
                                    // this.closeAlert()
                                    this.hideLoader()
                                    this.setState({ disableList: false })

                                    // toast.error(err)
                                })
                            }
                        } else {
                            this.setState({ disableList: false })
                            this.setState({ ShowPop: false, isList: false })
                            this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                            this.gotoTop()
                        }
                    }).catch(err => {
                        this.setState({ disableList: false })
                        this.setState({ ShowPop: false, isList: false })
                        // toast.error("Please reconnect your wallet")
                        this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                        this.gotoTop()
                        // this.closeAlert()
                    }) : this.handleLogin("sell")

            } else {
                this.setState({ disableList: false })

                this.setState({ isAlert: true, message: 'Please enter valid price', style: "danger" })
                this.gotoTop()
                // this.closeAlert()
                // toast.error("Please enter valid price", { position: toast.POSITION.BOTTOM_CENTER })
            }
        } else {
            this.setState({ disableList: false })

            this.setState({ isAlert: true, message: 'Please enter valid edition', style: "danger", count: '' })
            this.gotoTop()
            // this.closeAlert()
        }
    }
    addTodrops = (avltoken) => {

        if (avltoken.length > 0) {
            let airDropType = airDropService.airDropType.PUBLIC;
            let selectedUsers = null;
            if (this.state.isSelectedMembers === true) {
                airDropType = airDropService.airDropType.SELECTED_USERS;
                if (this.state.member !== '') {
                    selectedUsers = this.state.member.split(',');

                    if ((selectedUsers && selectedUsers.length) !== (avltoken && avltoken.length)) {
                        this.setState({
                            isAlert: true,
                            message: "No.of editions provided and selected members must be equal",
                            style: "danger",
                            disableList: false,
                            isTransLoader: false,
                            isAirDropSuccess: false,
                            isAirDrop: false
                        })
                        this.gotoTop();
                        return;
                    }
                } else {
                    this.setState({
                        isAlert: true,
                        message: "Members list cannot be empty for Selected members only option",
                        style: "danger",
                        disableList: false,
                        isTransLoader: false,
                        isAirDropSuccess: false,
                        isAirDrop: false
                    })
                    this.gotoTop();
                    return;
                }
            }
            airDropService.addToAirDrops(
                (avltoken),
                new Date(this.state.onstart),
                airDropType,
                selectedUsers
            )
                .then((response) => {
                    if ((response && response.statusCode) === 200) {
                        this.setState({
                            disableList: false,
                            isTransLoader: false,
                            isAirDropSuccess: true,
                            isAirDrop: false
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.hideLoader()
                    this.setState({
                        isAlert: true,
                        message: err,
                        style: "danger",
                        disableList: false,
                        isTransLoader: false,
                        isAirDropSuccess: false,
                        isAirDrop: false
                    })
                    this.gotoTop()
                })
        } else {
            if (!(this.isArtVisible(this.state.data))) {
                this.setState({
                    message: "Art is hidden. Cannot be listed in airdrop"
                })
            } else {
                this.setState({
                    message: "Token not available for listing air drops"
                })
            }
            this.setState({
                isAlert: true,
                style: "danger",
                disableList: false,
                isTransLoader: false,
                isAirDropSuccess: false,
                isAirDrop: false
            })
            this.gotoTop()
        }
    }
    handleImportCSV = (avltoken) => {
        if (avltoken && avltoken.length > 0) {
            if (this.state.isImported === true) {
                let airDropType = airDropService.airDropType.SELECTED_USERS;

                airDropService.addCSVImport((avltoken),
                    new Date(this.state.onstart),
                    airDropType,
                    this.state.uploadedFile)
                    .then((response) => {
                        if ((response && response.statusCode) === 200) {
                            this.setState({
                                disableList: false,
                                isTransLoader: false,
                                isAirDropSuccess: true,
                                isAirDrop: false
                            })
                        }
                    })
                    .catch(err => {
                        this.resetCSVFile()
                        console.log(err)
                        this.hideLoader()
                        this.setState({
                            isAlert: true,
                            message: err,
                            style: "danger",
                            disableList: false,
                            isTransLoader: false,
                            isAirDropSuccess: false,
                            isAirDrop: false
                        })
                        this.gotoTop()
                    })
            }
        } else {
            this.resetCSVFile()
            this.setState({
                isAlert: true,
                message: "Token not available for listing air drops",
                style: "danger",
                disableList: false,
                isTransLoader: false,
                isAirDropSuccess: false,
                isAirDrop: false
            })
            this.gotoTop()
        }

    }
    handleAirDrop = (id) => {
        const { groupIds, totalOwnerArt, data } = this.state
        const totalMintedArt = (data.toatalMintedTokens)
        const ownerWalletAddress = data && data.ownerWalletAddress
        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.airdropCount
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })

        if (!checkCount || (checkCount < 1) || (checkCount > arrayLength)) {
            this.setState({ disableList: false, isAirDrop: false })

            this.setState({ isAlert: true, message: 'Please enter valid edition', style: "danger", count: '' })
            this.gotoTop()

        } else if (
            !this.state.onstart ||
            this.state.onstart === ''
        ) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please select Date & Time', style: "danger", count: '' })
            this.gotoTop();
        } else if (
            (this.state.isSelectedMembers) && ((this.state.member === '') || (!this.usernameValidation(this.state.member)))) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter proper username', style: "danger", count: '' })
            this.gotoTop();
        } else if ((this.state.isImported) && (this.state.uploadedFile && this.state.uploadedFile.length === 0) && (this.state.localImageName === '')) {
            this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please upload your members csv file', style: "danger", count: '' })
            this.gotoTop();
        }
        else {
            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens;
            isConnectWallet() ? //check if wallet was connected
                getUserAddress().then(res => { // get user address
                    if ((checkWalletAddress(ownerWalletAddress, res))) { // check connected wallet address and owner of the art was same 
                        if (res != null) {
                            const artTokenGroupId = data.tokenGroupId;

                            this.showLoader();
                            walletServices.checkWallet(res).then(wallet => {
                                this.hideLoader()
                                if ((wallet && wallet.statusCode) === 200) {
                                    //if total minted art greater than 1 , call get available tokens api
                                    // otherwise pass a single token
                                    if (totalMintedArt > 1) {
                                        this.showLoader();
                                        if (checkCount && (data["tokenGroupId"]) && (data["title"])) {
                                            artServices.getAvailableTokens(data["tokenGroupId"], data["title"], Number(checkCount)).then(avltoken => {
                                                this.hideLoader()
                                                if (avltoken.statusCode === 200) {
                                                    this.setState({
                                                        disableList: true,
                                                        isTransLoader: true,
                                                        isAirDrop: false
                                                    })
                                                    if (this.state.isImported) {
                                                        this.handleImportCSV(avltoken && avltoken.data && avltoken.data.tokens)
                                                    } else {
                                                        this.addTodrops(avltoken && avltoken.data && avltoken.data.tokens)
                                                    }
                                                } else {
                                                    this.resetCSVFile()
                                                }
                                            }).catch(tknerr => {
                                                this.hideLoader()
                                                this.resetCSVFile()
                                                this.setState({
                                                    isAlert: true,
                                                    message: tknerr,
                                                    style: "danger",
                                                    disableList: false,
                                                    isTransLoader: false,
                                                    isAirDropSuccess: false,
                                                    isAirDrop: false
                                                })
                                                this.gotoTop()
                                            })
                                        } else {
                                            this.resetCSVFile()
                                            this.setState({ disableList: false, isAirDrop: false })
                                            this.setState({ ShowPop: false, isList: false })
                                            this.setState({ isAlert: true, message: "Required arguments missing", style: "danger" })
                                            this.gotoTop()
                                        }
                                    } else {
                                        this.setState({
                                            disableList: true,
                                            isTransLoader: true,
                                            isAirDrop: false
                                        })
                                        if (this.state.isImported) {
                                            this.handleImportCSV(selectedTokens)
                                        } else {
                                            this.addTodrops(selectedTokens)
                                        }
                                    }

                                }

                            }).catch(err => {
                                this.resetCSVFile()
                                this.setState({ isAlert: true, isAirDrop: false, message: err, style: "danger", isAirDropSuccess: false })
                                this.gotoTop()
                                // this.closeAlert()
                                this.hideLoader()
                                this.setState({ disableList: false })

                                // toast.error(err)
                            })
                        }
                    } else {
                        this.resetCSVFile()
                        this.setState({ disableList: false, isAirDrop: false })
                        this.setState({ ShowPop: false, isList: false })
                        this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                        this.gotoTop()
                    }
                }).catch(err => {
                    this.setState({ disableList: false, isAirDrop: false })
                    this.setState({ ShowPop: false, isList: false })
                    // toast.error("Please reconnect your wallet")
                    this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                    this.gotoTop()
                    this.resetCSVFile()
                    // this.closeAlert()
                }) : this.handleLogin("airdrop")



        }
    }
    resetCSVFile = () => {
        this.setState({ uploadedFile: [], localImageName: '' })
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
        this.gotoTop()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    fetchArt(url) {
        if (url && url.localPreview && url.localPreviewMimeType && url.localPreviewMimeType !== null) {
            if (url.localPreviewMimeType.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        } else {
            if (url && url.thumbnailHash) {
                fetchService.getBlob(url.thumbnailHash).then((blob) => {

                    if (blob.type.match(/image.*/)) {
                        return this.setState({ isImage: true })
                    } else {
                        return this.setState({ isImage: false })
                    }
                }).catch((err) => {
                    return this.setState({ isImage: false })
                })
            }
        }

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    percentageShower = () => {
        return <div className="purchasebox">
            <div className="table-responsive">
                <table className="table custable">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Marketplace Fee</th>
                            <th>Royalty for Creator</th>
                            <th>Creator</th>
                        </tr>
                        <tr>
                            <th>First Sale</th>
                            <td>15%</td>
                            <td>0%</td>
                            <td>85%</td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Reseller</th>
                        </tr>
                        <tr>
                            <th>Every Resale</th>
                            <td>5%</td>
                            <td>10%</td>
                            <td>85%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    resetPage = () => {
        localStorage.setItem('page', 0)
        history.push('/wallet')
    }

    redirectToAirDrops = () => {
        localStorage.setItem('page', 0)
        history.push('/airdrops')
    }
    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }
    handleplayresume = () => {
        const { pauseResume } = this.state
        this.setState({
            pauseResume: !pauseResume
        })
    }
    handleChecked = (value) => {
        if (value) {
            return false
        } else {
            this.setState({ isAgree: true, isSelectedMembers: false, isImported: false, uploadedFile: [], localImageName: '' })
        }

    }
    handleMembersChecked = (value) => {
        if (value) {
            return false
        } else {
            this.setState({ isAgree: false, isSelectedMembers: true, isImported: false, uploadedFile: [], localImageName: '' })
        }
    }
    handleImported = (value) => {
        if (value) {
            return false
        } else {
            this.setState({ isAgree: false, isSelectedMembers: false, isImported: true })
        }
    }
    handleSelectedMembers = (e) => {
        this.setState({
            member: (e.target.value).toLowerCase(),
        }, () => {
        })

    }
    usernameValidation = (value) => {
        let isValide = true
        const array = value.split(',')
        const regext = /^([a-zA-Z0-9.]{4,})+$/
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < array.length; i++) {
            if ((!(emailReg.test(array[i]))) &&
                (!(regext.test(array[i])))
            ) {
                isValide = false;
            }
        }
        return isValide;

    }
    checkIsEmail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((emailReg.test(value))) {
            return true
        } else {
            return false
        }
    }

    validation = (currentDate) => {
        return currentDate.isAfter(moment().subtract(1, "days"));

    }
    handleSelectCSV = (e) => {
        let uploadFile = e.target.files[0]
        console.log(uploadFile)

        const types = ['text/csv']
        let err = '';

        const sizeLimit = 1 * (1e+6);
        e.target.value = null;
        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger", uploadedFile: [], localImageName: '' })
            this.gotoTop()

        } else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += `Maximum supported file size is ${1} MB`;
            this.setState({ isAlert: true, message: err, style: "danger", uploadedFile: [], localImageName: '' })
            this.gotoTop()

        } else {
            if (uploadFile) {
                this.setState({ uploadedFile: uploadFile, localImageName: uploadFile && uploadFile.name })
            }
        }
        // const formData = new FormData();

        // formData.append('File', this.state.uploadedFile);
    }
    downloadCSV = () => {
        this.showLoader()
        airDropService.downloadSampleAirdropCSV().then(res => {
            res && res.text().then(text => {
                const url = window.URL.createObjectURL(
                    new Blob([text]),
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `sampleFile.csv`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            this.hideLoader()
        }).catch(e => {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Error occured while download sample csv file', style: "danger" })
            this.gotoTop()
        })
    }

    isOwnerisCreator = (data) => {
        if(getCurrentUserId()){
            if ((data && data.creator && data.creator.id) === (getCurrentUserId())) {
                return true
            } else {
                return false
            }
        }else{
            return false
        }
        
    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    statusChange = (items) => {
        if ((items && items.id)) {
            const status = (items && items.status && items.status === 1) ? 2 : 1
            let obj = {
                status
            }

            this.props.updatevisibilityStatus(obj, (items.id))
        } else {
            this.setState({ isAlert: true, message: "Something went wrong : Art id was missing", style: "danger", localFileUpload: false, localBrowsed: false })
            this.gotoTop()
        }

    }
    isArtVisible = (data) => {
        if ((data && data.status && data.status) === 1) {
            return true
        } else {
            return false
        }
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    resetStatusPage = () => {

        this.setState({
            isArtStatusChangeSuccess: false
        })
    }

    getImageSource = (items) => {
        if ((items && items.localPreview && items.localPreview !== null)) {
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        } else {
            if (items && items.thumbnailHash) {
                return items.thumbnailHash
            }
        }
    }
    updateError = (error) => {
        this.setState({ isAlert: true, message: error, style: "danger" })
        this.gotoTop()

    }
    updateThumbnailSuccess = () => {
        this.setState({
            isThumbnailUpdateSuccess: true
        })
    }
    resetAfterUpdateThumbnail = () => {
        this.setState({
            isThumbnailUpdateSuccess: false
        })
    }
    updateCategorySuccess = () => {
        this.getArtByToken()
        this.setState({
            isCategoryUpdateSuccess: true, isAlert: false
        })
    }
    resetAfterCategory = () => {
        this.setState({
            isCategoryUpdateSuccess: false
        })
    }
    updateTagSuccess = () => {
        this.getArtByToken()
        this.setState({
            isTagUpdateSuccess: true, isAlert: false
        })
    }
    resetAfterTag = () => {
        this.setState({
            isTagUpdateSuccess: false
        })
    }
    isArtisMusicCategory = (item) => {
        if (item && item.id) {
            if (item && item.nftCategory !== null && item.nftCategory !== undefined && item.nftCategory.name === "music") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        if ((this.state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        const { data, sell_share, crt_share, mark_share, groupIds, totalOwnerArt, isArtDisabled, disabledMessage } = this.state
        // console.log(sell_share,crt_share,mark_share)
        // console.log((Number(crt_share)+Number(mark_share)))
        const Art_Name = ((data && data.title) ? (data.title) : '...');
        const Art_Desc = ((data && data.description) ? (data.description) : '...');
        const metaTitle = `${Art_Name} - Sell or Auction NFT on ${process.env.REACT_APP_MARKETPLACE}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={`List NFT for sale`}></meta>
                    <meta name="description" content={`List NFT for sale`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {/* {this.state.isAlert && <Alert className="alertcustom" message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />} */}
                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                </div>}
                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }

                {this.state.listingInProgress &&
                    <div className="show-success">
                        <div className="success-pop">
                            <div className="sale-progress">
                                <div className="progress mb-1 cus-progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${this.state.listingProgress}%` }}></div>
                                </div>
                                <div className='mb-5 text-small'>
                                    <span className="usernamelilac">{this.state.tokensProcessed}/{this.state.totalTokensToBeListed}</span> Editions listed
                                </div>
                                <h3 className="mb-3">Listing more than <span className="usernamelilac">{getBatchSize()}</span> Editions will be done in batches.</h3>
                                <h4> You will be asked to approve the transaction for each batch</h4>
                            </div>
                        </div>
                    </div>
                }
                {this.state.isSuccessAuction &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully listed for auction! Good luck!</b></div>
                            <div className="mt-3">It may take 30 seconds for your listed auction art to appear in drops :)</div>

                            {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drops</button></div></div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>Go To My Collection</button></div></div>

                }
                {this.state.isArtStatusChangeSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Successfully changed art's visibility status</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your listed auction art to appear in drops :)</div> */}

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetStatusPage() }}>Close</button></div></div>

                }

                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} alt="" width="170px" />}
                            {
                                this.state.isListingAborted === false && <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            }
                            {
                                this.state.isListingAborted === true &&
                                <i className="fas fa-exclamation-triangle fa-5x text-warning"></i>
                            }
                            {
                                this.state.isListingAborted
                                    ? <div className="mt-3">
                                        <p>
                                            <b>Transaction aborted</b>
                                        </p>
                                        <p>
                                            Transaction has been aborted because {this.state.listingAbortError !== ''
                                                ? <span>{this.state.listingAbortError}</span>
                                                : <span>of an error</span>
                                            }. {this.state.tokensListedBeforeAbort} editions have been listed.
                                        </p>
                                    </div>
                                    : <div className="mt-3"><b>Woohoo..! Successfully listed for sale! Good luck!</b></div>
                            }
                            <div className="mt-3">It may take 30 seconds for your listed art to appear in drops :)</div>

                            {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drops</button></div></div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>Go To My Collection</button></div></div>

                }
                {this.state.isAirDropSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully listed for Air Drops! Good luck!</b></div>
                            <div className="mt-3">It may take 30 seconds for your listed art to appear in Air drops :)</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.redirectToAirDrops() }}>View Air Drops</button></div></div>

                }

                {this.state.isList && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing to list for sale</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />

                        </div>
                    </div>
                }
                {this.state.isAuction && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing for auction</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div>
                }
                {this.state.isAirDrop && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing for air drops</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div>
                }
                {/* {this.state.isThumbnailUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated thumbnail for your art!</b></div>
                            <div className="mt-3">Changes will appear in your collection :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterUpdateThumbnail() }}>Close</button></div></div>

                } */}
                {this.state.isCategoryUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated category for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterCategory() }}>Close</button></div></div>

                }
                {this.state.isTagUpdateSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Successfully updated tag for your art!</b></div>
                            <div className="mt-3">Changes will appear soon :)</div>

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetAfterTag() }}>Close</button></div></div>

                }

                {!(isArtDisabled) ? <div className="detailWallet mb-5">
                    <JoyRide start={checkOwnListingAuctionPage()} isScroll={false} page="list_auction_page" />
                    <div className="detasingimg detailMyWallet">
                        <div className="container">
                            <span className="detaimg text-center">
                                <ArtViewPreview src={data && data.imageHash} alt="" className="mw-100" showControls={true} autoPlay={true} isDetail={true} isPremiumArt={data && data.isPremiumArt} mimeType={data && data.imageMimeType} sharedData={data} />
                            </span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                {/* {(this.isArtisMusicCategory(data)) ? '' :  */}
                                <div className="badgeview propicdetail">
                                    <div className="picpro">
                                        <span className="artcovimg">
                                            <span className="artcov "></span>
                                            <span className="artcovimginset no-proimg ">
                                                {getCreatorImage(data)}
                                            </span>
                                        </span>

                                    </div>
                                    {getCreatorName(data)}
                                </div>
                                {/* } */}
                            </div>
                            <div className="col-6 text-right">

                                <div className="badgeview hideshowart">

                                    <h2 className="usernameClick" >

                                        {<span className='hideshowart'> <span className='mr-2 span-cursor'>Visibility</span>
                                            <a className={'card flowtop'}><Toggle checked={this.isArtVisible(data)} onChange={() => this.statusChange(data)} />
                                                {<span className="copycon">
                                                    <span className="uparrow"></span>
                                                    {(this.isArtVisible(data)) ? ("Hide Art") : ("Show Art")}
                                                </span>} </a>
                                            <span className='ml-2 small usernamelilac span-cursor'>{(this.isArtVisible(data)) ? `[Visible]` : '[Hidden]'}</span></span>}
                                    </h2>


                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="container dmwsec">
                        <div className="card1">
                            <div className="card-body1">
                                <div className="det-head"></div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="dettittxt mb-3"><h2 className="py-0 ">{data && data.title ? data.title : ''} &nbsp;{(data && data.isBab) ? <span className='ownerDec'>@Jim Jones</span> : <span className="ownerDec" onClick={() => this.gotoCreator(data && data.creator)}>{data && data.creator && data.creator.fullName && `by ${data.creator.fullName}`}</span>}</h2></div>
                                        <div className="dettittxt mb-3">
                                            <h4 className="py-0 ">{data && data.description}</h4>
                                        </div>
                                        {(data && data.tags && data.tags.length > 0) ? <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="probtns activebtnscus py-0 custagbadge">
                                                    <ul className="list-unstyled mb-0 ">
                                                        {data && data.tags && data.tags.map((items) => (

                                                            <li>

                                                                <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "badge badge-dark btn-sm active cushashtag" : 'badge badge-dark badge-sm cushashtag'} onClick={() => { this.gotoTagView(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button>
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> : ''}
                                        <div className='mb-3'>
                                            {(data && data.nftCategory && data.nftCategory.name) ?
                                                <span className='category-btn mr-2  align-items-center category_art' onClick={() => this.gotoCategoryPaga(data.nftCategory)}><i className={data.nftCategory && data.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {data.nftCategory && data.nftCategory.name.charAt(0).toUpperCase() + data.nftCategory.name.slice(1)}</span>
                                                : ''}
                                            {/* {<span className='category-btn align-items-center' title={(this.isArtVisible(data)) ? "Hide Art" : "Show Art"}>{this.isArtVisible(data) ? <i className="fa fa-eye mr-2" aria-hidden="true"></i> : <i className="fa fa-eye-slash mr-2" aria-hidden="true"></i>}<Toggle checked={this.isArtVisible(data)} onChange={() => this.statusChange(data)} /></span>} */}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.imageHash && data && data.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View On IPFS</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.metadataJsonUrl && data && data.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View Metadata</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.flowScanURL && data.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View on Flow Scan</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        {(this.isOwnerisCreator(data)) ? <div className="prpabout">
                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="card-header update_category_art" id="headingOne1">

                                                            <button className="btn btn-dark-cus btn-block text-left accordicotxt" type="button" data-toggle="collapse" data-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                                                <span className='accordicon mr-1'><i className='fa fa-pencil usernamelilac'></i></span>
                                                                <span className='accordtxt'>Update Category/Tags</span>
                                                                <span className='accordicon ml-1 acdarrow'><i className='fa fa-angle-down'></i></span>
                                                            </button>
                                                       
                                                    </div>
                                                    <div id="collapseOne1" className="collapse show" aria-labelledby="headingOne1" >
                                                        <div className="card-body">
                                                            <EditCategory updateError={(value) => { this.updateError(value) }} data={data} updateCategorySuccess={() => { this.updateCategorySuccess() }} />
                                                            <EditTag updateError={(value) => { this.updateError(value) }} data={data} updateTagSuccess={() => { this.updateTagSuccess() }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> : '' }
                                       

                                        {/* {(this.isOwnerisCreator(data)) ? <UpdateThumbnail updateError={(value) => {this.updateError(value)}} data={data} updateThumbnailSuccess={() => {this.updateThumbnailSuccess()}}/> : ''} */}

                                    </div>
                                    <div className="col-md-6">
                                        <div className="det-align">
                                            <Tabs className="tab-wrap" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabChange(tabIndex)}>

                                                <TabList className="tab-change">
                                                    <Tab className={this.state.tabIndex === 0 ? "tab-select sell_art" : 'sell_art'}>
                                                        <h5>Sell
                                                        </h5>
                                                    </Tab>
                                                    <Tab className={this.state.tabIndex === 1 ? "tab-select auction_art" : 'auction_art'} >
                                                        <h5>Auction</h5>
                                                    </Tab>
                                                    <Tab className={this.state.tabIndex === 2 ? "tab-select airdrop_art" : 'airdrop_art'} >
                                                        <h5>Air Drop</h5>
                                                    </Tab>
                                                    {/* {(this.isOwnerisCreator(data)) ? <Tab className={this.state.tabIndex === 3 ? "tab-select" : ''}>
                                                        <h5>Thumbnail</h5>
                                                    </Tab> : ''} */}
                                                </TabList>

                                                <TabPanel>
                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="equalheight">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) && <div className="purchasebox">
                                                                    <label className="mb-3" htmlFor="editionsforsale" >Editions for sale</label>
                                                                    <input id="editionsforsale" name="editionsforsale" type="text" value={this.state.count} onChange={this.handleCount} className="form-control mw-200 text-right" />
                                                                    {this.state.submitted && !this.state.count && <div className="mandatory mt-2">{"Please enter your valid tokens edition for listing"}</div>}
                                                                    {!this.state.submitted && !this.state.count && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${groupIds && groupIds.length} (must be whole number)`}</div>}
                                                                </div>}
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="purchasebox">
                                                                    <label htmlFor="price" className="mb-3">Price</label>
                                                                    <div className="input-group dollorsec">
                                                                        <input id="price" name="price" type="text" value={this.state.price} onChange={this.handlePriceChange} className="form-control mw-200 text-right" />
                                                                        {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className="input-group-append ">
                                                                            <span className="input-group-text bg-white" id="basic-addon2">FLOW/1 Edition</span>
                                                                        </div> :
                                                                            <div className="input-group-append ">
                                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                                            </div>}
                                                                        {/* {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) && <div className="input-group-append ">
                                                                            <span className="input-group-text bg-white" id="basic-addon2">/ 1 Edition</span>
                                                                        </div>} */}


                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {/* {this.state.isVisible && !this.state.isShare &&
                                                <div className="text-center p-3">
                                                    <h5 className="text-white">Calculating</h5>

                                                </div>}
                                                {!this.state.isVisible && this.state.isShare && this.state.price && <div className="text-center p-3">

                                                    <h5 className="text-white mb-3">Share Details</h5>
                                                    <span className="badge bg-dark mr-1 p-2 mb-3">Seller Share - {sell_share}</span>
                                                    <span className="badge bg-dark mr-1 p-2 mb-3">Creator Share - {crt_share}</span>
                                                    <span className="badge bg-dark mr-1 p-2 mb-3">Market Share - {mark_share}</span>
                                                    <p className="text-white">{(this.state.artOwner === this.state.artCreator) && `You are the owner of art then the market share is ${Number(crt_share) + Number(mark_share)}`}</p>
                                                </div>} */}

                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-6 ">
                                                                <h5 className="mb-3">List Price</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="" title={this.state.price}>
                                                                    {(this.state.price ? (
                                                                        <div className="usernamelilac">
                                                                            {flowDecimal(parseFloat(this.state.price)) + ' FLOW'} &nbsp;
                                                                            {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (this.state.price))))}>{(flowDecimal(parseFloat((this.state.rate) * (this.state.price)))) + ' USD'}</span>)}
                                                                        </div>)
                                                                        : <div className="usernamelilac">0.00 FLOW</div>)}</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="mb-3">Marketplace Fee {(this.state.artOwner === this.state.artCreator) ? (parseFloat(this.state.market_Fee) + parseFloat(this.state.resale_royalty) + "%") : (this.state.market_Fee + "%")}</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="" title={(this.state.artOwner !== this.state.artCreator) ? mark_share : (parseFloat(mark_share) + parseFloat(crt_share))}>
                                                                    {(this.state.artOwner !== this.state.artCreator) ?
                                                                        (this.state.price && mark_share) ?
                                                                            <div className="usernamelilac">
                                                                                {(flowDecimal(parseFloat(mark_share)) + ' FLOW')} &nbsp;
                                                                                {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (mark_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (mark_share)))) + ' USD'}</span>)}

                                                                            </div>
                                                                            : <div className="usernamelilac">0.00 FLOW</div>
                                                                        : (mark_share && crt_share && this.state.price) ?
                                                                            <div className="usernamelilac">
                                                                                {(flowDecimal((parseFloat(mark_share) + parseFloat(crt_share))) + ' FLOW')} &nbsp;
                                                                                {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * ((parseFloat(mark_share) + parseFloat(crt_share))))))}>{(flowDecimal(parseFloat((this.state.rate) * ((parseFloat(mark_share) + parseFloat(crt_share)))))) + ' USD'}</span>)}

                                                                            </div>
                                                                            : <div className="usernamelilac">0.00 FLOW</div>}</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="mb-3">Royalty {(this.state.artOwner === this.state.artCreator) ? ("0%") : (this.state.resale_royalty + "%")}</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="" title={(this.state.artOwner !== this.state.artCreator) ? crt_share : 0}>
                                                                    {(this.state.artOwner !== this.state.artCreator) ?
                                                                        (this.state.price && crt_share) ?
                                                                            <div className="usernamelilac">
                                                                                {(flowDecimal(parseFloat(crt_share)) + ' FLOW')} &nbsp;
                                                                                {(this.state.rate) && (<span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (crt_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (crt_share)))) + ' USD'}</span>)}

                                                                            </div>
                                                                            :
                                                                            <div className="usernamelilac">0.00 FLOW</div> : <div className="usernamelilac">0.00 FLOW</div>}</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="mb-3">Net Earnings</h5>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h5 className="" title={sell_share}>
                                                                    {(this.state.price && sell_share) ?
                                                                        <div className="usernamelilac">
                                                                            {(flowDecimal(parseFloat(sell_share)) + ' FLOW')} &nbsp;
                                                                            {(this.state.rate) && <span className="small text-white" title={(flowDecimal(parseFloat((this.state.rate) * (sell_share))))}>{(flowDecimal(parseFloat((this.state.rate) * (sell_share)))) + ' USD'}</span>}

                                                                        </div>
                                                                        :
                                                                        <div className="usernamelilac">0.00 FLOW</div>}</h5>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    {this.percentageShower()}
                                                    {/* 


                                                    <div className="row mb-3">
                                                            <h5 className="col-md-2">Price</h5>
                                                            <div className="col-md-9">
                                                                <div className="input-group dollorsec">
                                                                    <input type="number" value={this.state.price} onChange={this.handlePriceChange} className="form-control d-inline-block w-auto" />
                                                                    <div className="input-group-append ">
                                                                        <span className="input-group-text bg-white" id="basic-addon2">FUSD</span>
                                                                    </div>
                                                                    {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) && <div className="input-group-append ">
                                                                        <span className="input-group-text bg-white" id="basic-addon2"> / 1 Edition</span>
                                                                    </div>}
                                                                    {this.state.isVisible && !this.state.isShare && <div className="card" >
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">Calculating</h5>
                                                                            
                                                                        </div>
                                                                        </div>}
                                                                        {!this.state.isVisible && this.state.isShare && this.state.price && <div style={{textAlign:"center"}} >
                                                                        <div style={{backgroundColor:"white"}}>
                                                                            <h5 style={{color:"black"}}>Share Details</h5>
                                                                            <p className="badge bg-dark mr-1 p-2">Seller Share - {sell_share}</p>
                                                                            <p className="badge bg-dark mr-1 p-2">Creator Share - {crt_share}</p>
                                                                            <p className="badge bg-dark mr-1 p-2">Market Share - {mark_share}</p>
                                                                            <p style={{color:"black"}}>{(this.state.artOwner === this.state.artCreator) && `You are the owner of art then the market share is ${Number(crt_share)+Number(mark_share)}`}</p>

                                                                            
                                                                        </div>
                                                                        </div>}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    {/* {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) && <div className="row mb-3">
                                                            <h5 className="col-md-2">Count</h5>
                                                                <div className="col-md-9">
                                                                <input type="number" value={this.state.count} onChange={this.handleCount} className="form-control" />
                                                                    {this.state.submitted && !this.state.count && <div className="mandatory">{"Please enter your valid tokens count for listing"}</div>}
                                                                    {!this.state.submitted && !this.state.count && <div className="mandatory-req">{`INFO : Count must be less than or equal to ${groupIds && groupIds.length}`}</div>}
                                                                </div>

                                                </div>} */}

                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h5 className="mb-0" onClick={() => { this.gotoCreator(data && data.owner) }} id="uploadArt">User {data && data.owner && data.owner != null && data.owner.userName && data.owner.userName != null ? ((data.owner.userName && data.owner.userName.includes('@')) ? data.owner.userName : (`@${data.owner.userName}`)) : data.ownerWalletAddress && data.ownerWalletAddress}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mb-3">
                                                    <h5 className="col-md-2">User</h5>
                                                    <h4 className="col-md-9"> {data && data.owner && data.owner != null && data.owner.userName && data.owner.userName != null ? data.owner.userName : data.ownerWalletAddress && data.ownerWalletAddress}</h4>

                                                </div> */}
                                                    {/* <div className="row mb-3">
                                                    <h5 className="col-md-2">Edition</h5>
                                                    <h4 className="col-md-9"> {((groupIds && groupIds.length >= 1) && (totalOwnerArt && totalOwnerArt > 1)) ? (groupIds && groupIds.length) : totalOwnerArt }</h4>

                                                </div> */}
                                                    <hr className="my-3 d-block" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Art listed for sale will not appear in drops."}</div>}
                                                            {/* <button type="button" className="btn btn-primary mr-3 px-3" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button> */}
                                                            {/* <button type="button" className="btn btn-primary px-5" onClick={() => this.handleSale((data && data.value && data.value.fields && data.value.fields[1] && data.value.fields[1].value && data.value.fields[1].value.value))}>LIST ON SALE</button> */}
                                                            <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleSale((data && data.tokenId)) }}>List For Sale</button>
                                                        </div>

                                                    </div>}

                                                </TabPanel>
                                                <TabPanel>
                                                    {/* <h2>Auction</h2> */}

                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="endDays">Duration</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.endDays} name="endDays" id="endDays" onChange={this.handleActionChange} />
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">Days</span>
                                                            </div>
                                                        </div>
                                                        {!this.state.isSubmitAuction && <div className="mandatory-req">Min 1 Day & Max 30 Days</div>}
                                                        {this.state.isSubmitAuction && !this.state.endDays && <div className="mandatory">Please enter proper no.of days</div>}
                                                        {!this.state.isSubmitAuction && this.state.endDays && !this.checkNumber(parseInt(this.state.endDays)) && <div className="mandatory">must be an integer</div>}
                                                        {this.state.isSubmitAuction && this.state.endDays && (this.state.endDays === 0) && <div className="mandatory">Must be atleast 1 day and atmost 30 days</div>}

                                                    </div>
                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="startPrice">Starting Price</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.startPrice} name="startPrice" id="startPrice" onChange={this.handleBidPrice} />
                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                            </div>
                                                        </div>
                                                        {(this.state.rate) && (this.state.startPrice) && <div className="small text-white text-right mw-200 py-1">{(flowDecimal(parseFloat(((this.state.rate) * (this.state.startPrice))))) + ' USD'}</div>}
                                                        {this.state.isSubmitAuction && !this.state.startPrice && <div className="mandatory">Starting Price Required</div>}
                                                        {this.state.isSubmitAuction && this.state.startPrice && (this.state.startPrice === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                    </div>
                                                    <div className="purchasebox">
                                                        <label className="mb-3" htmlFor="minBid">Minimum Bid Increment</label>
                                                        <div className="input-group dollorsec">
                                                            <input type="text" className="form-control mw-200 text-right" value={this.state.minBid} name="minBid" id='minBid' onChange={this.handleMinBid} />

                                                            <div className="input-group-append ">
                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                            </div>

                                                            {this.state.isSubmitAuction && !this.state.minBid && <div className="mandatory">Minimum Bid Increment Required</div>}
                                                            {this.state.isSubmitAuction && this.state.minBid && (this.state.minBid === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                        </div>
                                                        {(this.state.rate) && (this.state.minBid) && <div className="small text-white text-right mw-200 py-1">{(flowDecimal(parseFloat(((this.state.rate) * (this.state.minBid))))) + ' USD'}</div>}
                                                        {this.state.isSubmitAuction && !this.state.minBid && <div className="mandatory">Minimum Bid Increment Required</div>}
                                                        {this.state.isSubmitAuction && this.state.minBid && (this.state.minBid === 0) && <div className="mandatory">Must have atleast 0.00001 FLOW</div>}

                                                    </div>
                                                    {this.percentageShower()}

                                                    {/* <div className="purchasebox">
                                                            <div className="row">
                                                            <div className="col-sm-6">
                                                                <h5 className="mb-3">Marketplace Fee {(this.state.artOwner === this.state.artCreator) ? (parseFloat(this.state.market_Fee) + parseFloat(this.state.resale_royalty) + "%") : (this.state.market_Fee + "%")}</h5>
                                                            </div>
                                                            
                                                            <div className="col-sm-6">
                                                                <h5 className="mb-3">Royalty {(this.state.artOwner === this.state.artCreator) ? ("0%") : (this.state.resale_royalty + "%")}</h5>
                                                            </div>
                                                      
                                                            </div>
                                                        </div> */}
                                                    <hr className="my-3 d-block" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Art listed for auction will not appear in drops."}</div>}
                                                            <button type="button" className="btn btn-primary px-3" onClick={this.listToAuction} disabled={this.state.disableList}>Create Auction</button>
                                                        </div>
                                                    </div>}

                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="purchasebox overflow-visible" >
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                            </div>

                                                            {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) && <div className="col-md-6 mb-3">
                                                                <label className="mb-3" htmlFor="airdropCount" >Editions for air drop</label>
                                                                <input id="airdropCount" name="airdropCount" type="text" value={this.state.airdropCount} onChange={this.handleCountforAirdrop} className="form-control text-right" />
                                                                {this.state.submitted && !this.state.airdropCount && <div className="mandatory mt-2">{"Please enter your valid tokens edition for air drops"}</div>}
                                                                {!this.state.airdropCount && <div className="mandatory-req">{`${(groupIds && groupIds.length) <= 250 ? `INFO : Edition must be less than or equal to ${(groupIds && groupIds.length)} (must be whole number)` : `Min 1 - Max 250 Editions`} `}</div>}
                                                                {this.state.airdropCount && <div className="mandatory-req">{`Min 1 - Max 250 Editions`} </div>}


                                                            </div>}

                                                            <div className="col-md-6 mb-3">
                                                                <div className="">
                                                                    <label className="mb-3" htmlFor="onstart" >Drop from</label>
                                                                    <DatePicker
                                                                        value={this.state.onstart}
                                                                        dateFormat="DD-MM-YYYY"
                                                                        timeFormat="hh:mm A"
                                                                        onChange={(val) => this.handleOnstart(val)}
                                                                        isValidDate={this.validation}
                                                                        // initialValue ={new Date()}
                                                                        initialViewDate={new Date()}
                                                                    /> <br />
                                                                    {/* <input id="onstart" name="onstart" type="datetime-local" value={this.state.onstart} onChange={this.handleOnstart} className="form-control text-right" min={moment().format('YYYY-MM-DDThh:mm')} /> */}
                                                                    {this.state.submitted && !this.state.onstart && <div className="mandatory mt-2">{"Please select your time for launch"}</div>}
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-6 mb-3">
                                                                <h5 className="mb-3">Launch on time ?</h5>
                                                                {this.state.pauseResume ? 
                                                                <h3 className="" onClick={this.handleplayresume}>{<i className="fa fa-pause" aria-hidden="true"></i>}</h3> : 
                                                                <h3 className="" onClick={this.handleplayresume}>{<i className="fa fa-play" aria-hidden="true"></i>}</h3>}
                                                            </div> */}

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck1" checked={this.state.isAgree} onClick={() => this.handleChecked(this.state.isAgree)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck1">Any first coming followers can claim</label>
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck2" checked={this.state.isSelectedMembers} onClick={() => this.handleMembersChecked(this.state.isSelectedMembers)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck2">Selected members only</label>

                                                                    {(this.state.isSelectedMembers) ? <div><textarea className="form-control mt-3" value={this.state.member} onChange={this.handleSelectedMembers} disabled={this.state.isAgree || this.state.isImported}></textarea>

                                                                        <div className="usernamelilac small mt-1">Enter username or email as comma separated values (For ex. kitty, robert, johndoe@example.com, janedoe@example.com)</div>
                                                                        {this.state.submitted && this.state.isSelectedMembers && !this.usernameValidation(this.state.member) && <div className="mandatory mt-2">{"Please enter proper username. Username has only contain alphabets,numbers and atleast 4 characters long. or Enter proper email address"}</div>}<br />
                                                                        {this.state.member && <div className="comboxgray">
                                                                            {this.state.member && this.state.member.split(',').map(items => (
                                                                                <a className="badge bg-dark mr-1 p-2" >
                                                                                    {this.checkIsEmail(items) ?
                                                                                        <span>
                                                                                            <i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;{items}
                                                                                        </span>
                                                                                        :
                                                                                        `@${items}`}</a>)
                                                                            )}
                                                                        </div>}
                                                                    </div> : ''}
                                                                </div>
                                                                <div className="form-group">
                                                                    <input type="radio" className="form-check-input mr-3" id="exampleCheck3" checked={this.state.isImported} onClick={() => this.handleImported(this.state.isImported)} />
                                                                    <label className="form-check-label" htmlFor="exampleCheck3">Import members </label>


                                                                </div>
                                                            </div>
                                                            {(this.state.isImported) ?
                                                                <div className="col-md-12 dmwbtns">
                                                                    <div className='row'>
                                                                        <div className='col-md-6'>
                                                                            <button className="btn btn-primary-fill w-100" type='button' onClick={this.downloadCSV}>Download sample file &nbsp; <i class="fa fa-download" aria-hidden="true"></i></button>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <input type="file" id="ufile" name="image" single="" onChange={this.handleSelectCSV} className="inp-box" accept=".csv" />
                                                                            <label htmlFor="ufile" className="btn btn-primary-fill w-100">Upload members &nbsp; <i className="fa fa-upload" aria-hidden="true"></i></label>
                                                                            <div className="usernamelilac small mt-1">Maximum upload file size limit 1MB</div>
                                                                            <div className="usernamelilac small mt-1">Only 250 records are allowed in import</div>

                                                                            <div className="col-md-6"><div className="mandatory-req text-white">{this.state.localImageName}</div></div>

                                                                        </div>
                                                                    </div>
                                                                </div> : ''}

                                                        </div>
                                                    </div>




                                                    <hr className="my-3 d-block" />
                                                    <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleAirDrop((data && data.tokenId)) }} >Schedule</button>
                                                        </div>

                                                    </div>

                                                </TabPanel>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="error-template userdisableview">
                                <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                                {(disabledMessage && disabledMessage.includes('admin')) ? <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4> : ''}

                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="mb-0">The Team</p> : ''}
                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="usernamelilac">DisruptArt</p> : ''}


                            </div>
                        </div>

                    </div>
                </div>}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        wallet: state.wallet.wallet,
        thumbnailUpdatSuccess: state.art.thumbnailUpdated,
        uploadThumbnailFailed: state.art.uploadThumbnailFailed,
        artVisibilityStatusChangedFailed: state.art.artStatusChangedFailed,
        artVisibilityStatusChangesSuccess: state.art.artStatusChangedSuccess
    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    updateThumbnail: artActions.updateThumbnailnft,
    resetUploadSuccess: artActions.resetUpdateThumbnailSuccess,
    resetUpdateThumbnailFailed: artActions.resetUpdateThumbnailFailed,
    updatevisibilityStatus: artActions.artsVisibilitystatusChange,
    resetVisibityStatusSuccess: artActions.resetArtvisibilitySuccess,
    resetVisibilityStatusFailed: artActions.resetArtVisibilityFailed

}
const connectDetailWallet = connect(mapStateToProps, actionCreators)(DetailWallet)
export { connectDetailWallet as DetailWallet }
