export const midwestconConstants = {
    ADMIN_GET_MIDWEST_EVENTS : 'ADMIN_GET_MIDWEST_EVENTS',
    ADMIN_ADD_MIDWEST_EVENTS : 'ADMIN_ADD_MIDWEST_EVENTS',
    ADMIN_EDIT_MIDWSET_EVENTS : 'ADMIN_EDIT_MIDWSET_EVENTS',
    ADMIN_GET_MIDWEST_EVENT_DETAIL : 'ADMIN_GET_MIDWEST_EVENT_DETAIL',
    ADMIN_GET_MIDWEST_EVENT_GALLERY : 'ADMIN_GET_MIDWEST_EVENT_GALLERY',
    ADMIN_GET_MIDWEST_EVENT_GALLERY_DETAIL : 'ADMIN_GET_MIDWEST_EVENT_GALLERY_DETAIL',
    ADMIN_ADD_MIDWEST_EVENT_GALLERY : 'ADMIN_ADD_MIDWEST_EVENT_GALLERY',
    ADMIN_DELETE_MIDWEST_EVENT_GALLERY : 'ADMIN_DELETE_MIDWEST_EVENT_GALLERY'
   
 }