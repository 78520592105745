import { configPath } from "../config";
import { accessHeader } from "helpers"
import PubSub from 'pubsub-js'; 

export const discoverServices = {
   getAllSpotLightArts,
   getAllFeatureArts,
   getAllFeatureCreators,
   getAllFeatureCollectors,
   getAllRandomCreators,
   getAllShowCaseCreators
}

function getAllSpotLightArts(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/discover-management/discover/spotLightArts?` + params,requestOptions).then(handleResponse,handleError)
}
function getAllFeatureArts(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && ((obj.offSet != null && obj.offSet !== undefined)) ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/discover-management/discover/featuredArts?` + params,requestOptions).then(handleResponse,handleError)
}
function getAllFeatureCreators(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && ((obj.offSet != null && obj.offSet !== undefined)) ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/discover-management/discover/featuredCreators?` + params,requestOptions).then(handleResponse,handleError)
}

function getAllFeatureCollectors(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && ((obj.offSet != null && obj.offSet !== undefined)) ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/discover-management/discover/featuredCollectors?` + params,requestOptions).then(handleResponse,handleError)
}
function getAllRandomCreators(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && ((obj.offSet != null && obj.offSet !== undefined)) ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let shuffle = obj && obj.shuffle ? '&shuffle=' + obj.shuffle : ''
    let count = obj && obj.count ? '&count=' + obj.count : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search + shuffle + count

    return fetch(`${configPath.apiUrl}/discover-management/discover/creators?` + params,requestOptions).then(handleResponse,handleError)
}
function getAllShowCaseCreators(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.recordLimit && ((obj.offSet != null && obj.offSet !== undefined)) ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let shuffle = obj && obj.shuffle ? '&shuffle=' + obj.shuffle : ''
    let count = obj && obj.count ? '&count=' + obj.count : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search + shuffle + count

    return fetch(`${configPath.apiUrl}/discover-management/discover/showcase-creators?` + params,requestOptions).then(handleResponse,handleError)
}

function handleResponse(response) {
    if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
               localStorage.removeItem('adminDetail')
			}
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || (data && data.message)
			return Promise.reject(error)
		}
		return data;
	});
    }
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            throw err
        }else{
            PubSub.publish('msg', false)

        }
    }
    catch(err) {
    console.log(err)
  
    }
}
