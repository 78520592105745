import React from "react";
import { configPath } from '../config';

export class ProfilePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectURL: '',
            profileURL : ''
        };
    }

 

    componentWillUnmount() {

        if (this.state.objectURL && this.state.objectURL !== '') {
            URL.revokeObjectURL(this.state.objectURL);
            // console.log(`%c revoked ${this.state.objectURL}`, 'background:#2962ff; color: #fff');
        }
    }  

    static getDerivedStateFromProps(props, state) {
        
        if ((props.src !== state.prevSrc) ) {
            // console.log(`%c Props src changed from ${state.prevSrc} to ${props.src} `, 'background:#2962ff; color: #fff');
            return {
                prevSrc: props.src,
                objectURL: props.src,
                
            }
        }
        return null;
    }

    render() {
        if(this.props && this.props.src === null){

           return (<img src={`${process.env.REACT_APP_BASEPATH}/assets/images/no-proimg.jpg`} alt={this.props.alt}  className={this.props.className} style={this.props.style} />)
        }
        
        else if (this.state.objectURL !== '' ) {
                return (<img src={this.state.objectURL} alt={this.props.alt} className={this.props.className} width={this.props.width} style={this.props.style} />)
        }
        
        else  if(this.props && this.props.src === null){
           return (<img src={`${process.env.REACT_APP_BASEPATH}/assets/images/no-proimg.jpg`} alt={this.props.alt}  className={this.props.className} style={this.props.style} />)
        }      
       
        else {
            // return null
            return (<img src={`../${configPath.imageAssets}/no-proimg-gif.gif`} width={this.props.width} style={this.props.style} alt="" />)
        }
    
    }

 
}