import React from "react";
import { configPath } from '../config';

export class Preview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectURL: '',
            profileURL : ''
        };
    }

 

    componentWillUnmount() {

        if (this.state.objectURL && this.state.objectURL !== '') {
            URL.revokeObjectURL(this.state.objectURL);
            // console.log(`%c revoked ${this.state.objectURL}`, 'background:#2962ff; color: #fff');
        }
    }  

    static getDerivedStateFromProps(props, state) {
        
        if ((props.src !== state.prevSrc) ) {
            // console.log(`%c Props src changed from ${state.prevSrc} to ${props.src} `, 'background:#2962ff; color: #fff');
            return {
                prevSrc: props.src,
                objectURL: props.src,
                
            }
        }
        return null;
    }

    render() {
        // console.log(this.props)
        
        if(this.props && this.props.src === null){

           return (<span className="ccimgbox">
               {(this.props && this.props.isPremiumAccount) ? <span className="articoni permium" data-toggle="tooltip"  title="Premium">
           <img src={`../${configPath.imageAssets}/permium.png`} alt=""/>
           </span> : ''}<img src={`../${configPath.imageAssets}/no-img.jpg`} alt={this.props.alt}  className={this.props.className} style={this.props.style} /> </span>)

        }
        else if (this.state.objectURL === null) {
           
            return (<img src={`../${configPath.imageAssets}/ajax-loader.gif`}  className="hexagonloader" width={this.props.width} style={this.props.style} alt="" />)
        } 
        else if (this.state.objectURL !== '' ) {
                return (<span className="ccimgbox">
                     {(this.props && this.props.isPremiumAccount) ? <span className="articoni permium" data-toggle="tooltip"  title="Premium">
                <img src={`../${configPath.imageAssets}/permium.png`} alt=""/>
                </span> : ''}<img src={this.state.objectURL} alt={this.props.alt} className={this.props.className} width={this.props.width} style={this.props.style} /></span>)
        }
        else {
                return (<img src={`../${configPath.imageAssets}/ajax-loader.gif`}  className="hexagonloader" width={this.props.width} style={this.props.style} alt=""/>)
        }
    
    }

 
}