import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { getCreatorImage } from './bab-common-view';
import { getCreatorName } from './bab-common-view';
import history from 'helpers/history';




class ArtStoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }

    render() {
        const id = (this.props && this.props.match && this.props.match.params && this.props.match.params.id)
        const { artDetail,tabIndex
        } = this.props;

        return (

            <div className={(tabIndex === 1) ? "tab-pane active" : "tab-pane "} id="Story" role="tabpanel" aria-labelledby="Story-tab">
                <h1>{artDetail && artDetail.title ? artDetail.title : ''}</h1>
                <div className="badgeview propicdetail">
                    <div className="picpro"><span className="artcovimg"><span className="artcov "></span><span className="artcovimginset no-proimg ">
                        {getCreatorImage(artDetail)}
                    </span></span>
                    </div>
                    <h2 className="usernameClick">{getCreatorName(artDetail)}</h2>
                </div>
                <p>{artDetail && artDetail.description}</p>
                {(artDetail && artDetail.tags && artDetail.tags.length > 0) ?
                    <div className='dandcontbadge custagbadge'>
                        {artDetail && artDetail.tags && artDetail.tags.map((items) => (
                            <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "badge badge-dark btn-sm active cushashtag" : 'badge badge-dark badge-sm cushashtag'} onClick={() => { this.gotoTagView(items) }}>#{items && items.name}</button>
                        ))}
                    </div>
                    : ''}
            </div>

        )
    }
}
const mapStateToProps = (state) => {
    return {


    }
}
const actionCreators = {


}

const connectArtStoryTab = connect(mapStateToProps, actionCreators)(ArtStoryTab)
export { connectArtStoryTab as ArtStoryTab }
