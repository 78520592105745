import React from "react";

export class InvalidPage extends React.Component {
    render() {
        return (
            <div className="container-fluid text-center">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="error-template userdisableview">
                                <i className="fas fa-exclamation-circle fa-5x my-5 mw-100"></i>

                                    <h3 className="mb-2 usernamelilac text-uppercase">Invalid Page</h3>
                               


                                </div>
                            </div>

                        </div>
                </div>
        );
    }
}