import { toastr } from 'helpers';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { adminServices } from 'services';
import PubSub from 'pubsub-js';
import Modal from 'react-modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
class ProcessBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            srange: '',
            erange: '',
            submitted: false,
            scode: '',
            isValid: false,
            openModal: false,
        }
    }


    componentDidMount() {
        this.setState({ artId: (this.props && this.props.location && this.props.location.state && this.props.location.state.artId), creatorUrl: (this.props && this.props.location && this.props.location.state && this.props.location.state.creatorUrl && this.props.location.state.creatorUrl) })
        this.gotoTop()
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0)) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            this.setState({
                [e.target.name]: ''
            })
        }
    }
    handleEndChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0)) {
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            this.setState({
                [e.target.name]: ''
            })
        }
    }
    handlesecretCode = (e) => {
        const realCode = window.btoa(process.env.REACT_APP_VALIDATION_CODE)
        this.setState({ scode: (e.target.value) }, () => {
            if ((e.target.value) == (window.atob(realCode))) {
                this.setState({ isValid: true })
            } else {
                this.setState({ isValid: false })
            }
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { srange, erange } = this.state;
        //check ranges were given
        if (srange) {
            if (erange) {
                if (Number(erange) > Number(srange)) {

                    this.setState({ openModal: true })

                } else {
                    toast.error('Ending range must be greater than starting range')
                }
            } else {
                toast.error('Ending range required')

            }
        } else {
            toast.error('Starting range required')

        }

    }
    handleOk = (obj) => {
        this.showLoader()
        adminServices.processBlockRange(JSON.stringify(obj)).then(res => {
            this.hideLoader()
            if ((res && res.statusCode === 202)) {
                this.setState({ srange: '', erange: '', submitted: false, scode: '', openModal: false, isValid: false })
                toast.success("Process Initiated")
            }
        }).catch(err => {
            this.hideLoader()
        })


    }
    handleRefresh = () => {
        this.setState({ srange: '', erange: '', submitted: false })
    }
    closeModal = () => {
        this.setState({ openModal: false, scode: '', isValid: false })
    }
    reject = () => {
        this.setState({ openModal: false, scode: '', isValid: false })
    }
    approve = () => {
        const { srange, erange } = this.state;
        let obj = {
            from: Number(srange),
            to: Number(erange)
        }
        this.handleOk(obj)
    }
    render() {

        return (
            <Fragment>
                <Modal
                    isOpen={this.state.openModal}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal">
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <div className="loginpopcls">
                        <div className="custom-ui text-left p-0 ">                            
                                <div className="form-group px-5 pt-5 pb-3 text-center">
                                    <label htmlFor="scode ">Enter your code</label>
                                    <input className="form-control" type="password" value={this.state.scode} onChange={this.handlesecretCode} name="scode" id="scode" placeholder="Enter Code Here" />
                                </div>
                                <hr/>
                                <div className='px-5 pt-3 pb-5'>
                                <h4 className="text-center">Are you sure to do this?</h4>
                                    <div className="custom-btn text-center">
                                        <button onClick={this.reject} className="btn btn-outline-dark px-3 mr-3">No</button>
                                        <button onClick={this.approve} className="btn btn-outline-dark px-3" disabled={!this.state.isValid}>Yes</button>
                                    </div>   
                                </div>                         
                        </div>
                    </div>

                </Modal>
                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Process Block</h2></div>
                    <div className="card mb-3">
                        <div className="card-body">

                            <div className="row justify-content-center">
                                <div className="col-md-3" >
                                    <form onSubmit={this.handleSubmit}>

                                        <div className="form-group">
                                            <label htmlFor="srange">Starting Range</label>
                                            <input className="form-control" type="text" value={this.state.srange} onChange={this.handleChange} name="srange" id="srange" placeholder="Enter Starting Range Here" />
                                            {this.state.submitted && !this.state.srange && <div className="mandatory">Starting range required</div>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="erange">Ending Range</label>
                                            <input className="form-control" type="text" value={this.state.erange} onChange={this.handleEndChange} id="erange" name="erange" placeholder="Enter Ending Range Here" />
                                            {this.state.submitted && !this.state.erange && <div className="mandatory">Ending range required</div>}
                                        </div>

                                        <div className="form-group text-center">
                                            <button type="submit" className="btn btn-primary px-5">Submit</button>
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </Fragment>


        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const actionCreators = {


}

const connectProcessBlock = connect(mapStateToProps, actionCreators)(ProcessBlock)

export { connectProcessBlock as ProcessBlock }
