import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { sampleFunc1, sampleFunc2, sampleFunc3, sampleFunc4 } from 'utils/testing-page-util';

class TestPage extends Component {
	render() {
		return (
			<Fragment>
				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="w-auto  text-center">Testing</h1>
							</div>
						</div>
					</div>
					<hr />
                    <div className="row">
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary" onClick={sampleFunc1}>Button1</button>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary" onClick={sampleFunc2}>Button2</button>
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary" onClick={sampleFunc3}>Button3</button>
                    </div>
                    <div className="col-md-3 d-none">
                        <button type="button" className="btn btn-primary" onClick={sampleFunc4}>Button4</button>
                    </div>
                    </div>

					


					
				
				</div>

			</Fragment>
		)
	}
}
export { TestPage }