import { configPath } from "../config";
import PubSub from 'pubsub-js'; 
import { fetchService } from "./fetch-service";
import history from "helpers/history";
import { accessHeader } from "helpers";

export const reportServices = {
    addReport,
    
 }

function addReport(obj){
	const requestOptions = {
		method : 'POST',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/abuse-report-management/abuse-report`,requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
	if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
				// logout();
			}else if(response.status ===400){
				if (data.message && data.message.toLowerCase().includes('not found') ) {
					history.push('/notFound');
				}else if(data.message && data.message.toLowerCase().includes('does not exist')){
					history.push('/notFound');
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
			
		}
		return data;
	});
}
}
function handleError(err) {
	try {
		if (err instanceof TypeError) {
			PubSub.publish('msg', false)
			return Promise.reject(err)
		} else {
			PubSub.publish('msg', false)
			return Promise.reject(`Unexpected error occured : ${err}`)
		}
	}
	catch (err) {
		return Promise.reject(`Unexpected error occured : ${err}`)
	}
}