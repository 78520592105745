import React from "react";
import history from "helpers/history";
import { categoryActions, creatorStoreActions, dropActions, tagActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { Fragment } from "react";
import { checkMintedTokens, flowDecimal, localArtPage, localAirdropPage, getUniqueUUID } from 'helpers'
import { createSetUp, getUserAddress } from "utils";
import { Helmet } from "react-helmet";
import moment from 'moment'
import Select from 'react-select';
import { getFlowUsdValue } from "helpers/getFlowUsd";
import PubSub from 'pubsub-js';
import { configPath } from "../../config";
import { DynamicSectionForm } from "./DynamicSectionForm";
import { toast, Zoom } from "react-toastify";




const customStylesforAll = {
    control: (base, state) => ({
        ...base,
        minWidth: "100px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "#fff",
        zIndex: 9

    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#62A82A" : null,
            color: "#000",
            cursor: "pointer"
        };
    },
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    })
}
const customStyles = {
    control: (base, state) => ({
        ...base,
        minWidth: "200px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",

        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "#fff",
        zIndex: 9999
    }),
    menuList: base => ({
        ...base,
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
            ...styles,
            backgroundColor: isFocused ? "#62A82A" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
class DynamicSections extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            isFilter: "all",
            allFiltSelectOption: {},
            allTypes : []
        }
    }
    componentDidMount() {
        localArtPage()
        localAirdropPage()
        this.getStoreProfile()

    }
    static getDerivedStateFromProps(props, state) {
        
        return null;

    }
    
    componentDidUpdate(prevProps) {
        if (
            (this.props && this.props.usersList && this.props.usersList.statusCode === 200) &&
            (this.props.usersList !== (prevProps && prevProps.usersList))) {
            if (this.props && this.props.usersList && this.props.usersList.data) {
                let data = this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.store
                this.setState({
                    storeId: data.id,
                });
                if (data && data.id) {
                    this.props.getsections(data.id)
                } else {
                    toast.error("Something went wrong")
                }
            }
        }
        if (
            (this.props && this.props.sections && this.props.sections.statusCode === 200) &&
            (this.props.sections !== (prevProps && prevProps.sections))) {
            this.props.resetGetsections()
            this.setState({
                isFetched: true,
            })

            if (this.props && this.props.sections && this.props.sections.data && this.props.sections.data.sections) {
                if (this.props.sections.data.sections.length > 0) {
                    let array = []
                    this.props.sections.data.sections && this.props.sections.data.sections.length > 0 && this.props.sections.data.sections.map((items) => {
                        let obj = {
                            value : items && items.id,
                            label : items && items.name,
                        }
                        array.push(obj)
                    })
                    this.setState({
                        isSectionAvalbl: true,
                        sections: this.props.sections.data.sections,
                        allTypes : array,
                        allFiltSelectOption: {value: array[0].value, label:array[0].label}
                    },() => {
                       
                    })
                }
            }
        }
        if (this.props &&
            this.props.getsectionfailed &&
            this.props.getsectionfailed.length > 0 &&
            (this.props.getsectionfailed !== prevProps.getsectionfailed)
        ) {
            this.props.resetGetSectionFailed()
            toast.error(this.props.getsectionfailed, { transition: Zoom })

        }
    }
    getStoreProfile = () => {
        if (getUniqueUUID()) {
            this.props.getUserStoreProfile(getUniqueUUID())
        }
    }
    handleSelectChange = (option) => {
        this.setState({
            allFiltSelectOption: option,

        }, () => {
        })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }
    
    hideLoader() {
        PubSub.publish('msg', false);
    } 
    componentWillUnmount() {
    
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        const {allTypes} = this.state

        return (
            <Fragment>
                <Helmet>
                    <title>Content Management System</title>
                    <meta name="title" content="Dynamic Sections"></meta>
                    <meta name="description" content={`Dynamic Sections`}></meta>

                </Helmet>
                <div className="pagetitabtn dynamicsec">

                    <div className="droplisttit">
                    <div className="probtns activebtnscus propagecont1  mb-3 px-3">
                            <div className="row">
                            <div className="col-md-9"> <h1 className="mb-3 d-inline-block drops_title">Content Management System</h1></div>
                            {/* <div className="col-md-6 text-right"><button type="button" className="btn btn-rounded btn-outline-dark btn-sm mr-0" onClick={this.refreshArts}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div> */}
                           
                            <div className="col-md-3 text-right ds-inputs">
                                <div className="text-center">
                                        <Select
                                            value={this.state.allFiltSelectOption}
                                            onChange={this.handleSelectChange}
                                            options={allTypes}
                                            styles={customStylesforAll}
                                            menuPortalTarget={document.body}
                                            isSearchable={false}
                                        />
                                        </div>
                            
                            </div>
                            </div>
                        </div>
                        
                           
                        </div>
                   
                    {/* <hr className="mb-3" /> */}
                    {(this.state.allFiltSelectOption && this.state.allFiltSelectOption.value) ? 
                    <DynamicSectionForm selectedSection={this.state.allFiltSelectOption}/> : ''}
                    
                </div>




            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
       
        getsectionfailed: state.creatorStore.storeSectionFailed,
        sections: state.creatorStore.storeSections,
        usersList: state.creatorStore.profile,
        sectionartsList: state.creatorStore.sectionArts,
    }
}
const actionCreators = {
    getsections: creatorStoreActions.getStoreSection,
    resetGetsections: creatorStoreActions.resetGetStoreSection,
    resetGetSectionFailed: creatorStoreActions.resetGetStoreSectionFailed,

    getUserStoreProfile: creatorStoreActions.getStoreCreatorProfile,
    resetGetProfile: creatorStoreActions.resetGetStoreCreatorProfile,
    resetGetprofileFailed: creatorStoreActions.resetGetStoreCreatorProfilefailed
}
const connectDynamicSections = connect(mapStateToProps, actionCreators)(DynamicSections)
export { connectDynamicSections as DynamicSections }
