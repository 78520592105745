import React, { Component, Fragment } from 'react'
import "react-toggle/style.css"
import PubSub from 'pubsub-js';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { artServices, fetchService } from 'services';
import history from 'helpers/history';

class DownloadArts extends Component {

    constructor(props) {

        super(props);

        this.state = {
            imageurl: ''
        }
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        const tokenId = this.props && this.props.match && this.props.match.params && this.props.match.params.tokenId
        const pathname = this.props && this.props.location && this.props.location.pathname
        if (pathname && pathname.includes('mime')) {
            this.showLoader()
            artServices.mimeDownloadProcess(Number(tokenId)).then(async (res) => {
                this.hideLoader()
                const url = await window.URL.createObjectURL(
                    new Blob([JSON.stringify(res)]),
                );

                const link = await document.createElement('a');
                link.href = await (url);
                await link.setAttribute(
                    'download',
                    `mimeFile`,
                );

                // Append to html link element page
                await document.body.appendChild(link);

                // Start download
                await link.click();

                // Clean up and remove the link
                await link.parentNode.removeChild(link);
                history.goBack()
            }).catch(err => {
                this.hideLoader()
            })
        } else {
            artServices.artDownloadProcess(Number(tokenId)).then(res => {
                this.showLoader()
                fetchService.getBlob(res.url).then(async (blob) => {
                    if (blob) {
                        this.hideLoader()
                        const imageURL = await URL.createObjectURL(blob)

                        const link = await document.createElement('a')
                        link.href = await imageURL
                        link.download = 'preview'
                        await document.body.appendChild(link)
                        await link.click()
                        document.body.removeChild(link)
                        history.goBack()
                    } else {
                        this.hideLoader()
                        history.goBack()
                    }
                }).catch(error => {
                    this.hideLoader()
                })

            }).catch(err => {
                this.hideLoader()
            })
        }


    }

    render() {

        return (
            <Fragment>
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="error-template userdisableview">
                                <i className="fas fa-spinner fa-spin fa-5x"></i>
                                <h3 className="mb-2 usernamelilac">Processing</h3>

                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const actionCreators = {


}
const connectDownload = connect(mapStateToProps, actionCreators)(DownloadArts)
export { connectDownload as DownloadArts }
