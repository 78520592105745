import { configPath } from 'config'
import history from 'helpers/history'
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { adminServices } from 'services';
import { userAction } from 'store/actions'
import { flowbalance, fusdbalance } from 'utils';

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            open:false,
            adminAddress : '',
            marketplaceAddress : '',
            adminBalance : '',
            marketBalance : '',
            adminFlowBalance :'',
            marketFlowBalance :''
        }
    }
    componentDidMount(){
        // adminServices.getAddressConfig().then(res => {
        //     fusdbalance((res && res.data && res.data.adminAddress)).then(balance => {
        //         if ((balance && balance.status) == 200) {
        //             console.log("balan", balance)
        //             this.setState({ adminBalance: JSON.parse(balance && balance.data && balance.data.Value) })
        //         }
        //     }).catch(error => {})
        //     flowbalance((res && res.data && res.data.adminAddress)).then(balance => {
        //         if ((balance && balance.status) == 200) {
        //             console.log("flowbalan", balance)
        //             this.setState({ adminFlowBalance: JSON.parse(balance && balance.data && balance.data.Value) })
        //         }
        //     }).catch(error => {})
        //     fusdbalance((res && res.data && res.data.marketplaceAddress)).then(m_balance => {
        //         if ((m_balance && m_balance.status) == 200) {
        //             console.log("balan", m_balance)
        //             this.setState({ marketBalance: JSON.parse(m_balance && m_balance.data && m_balance.data.Value) })
        //         }
        //     }).catch(error => {})
        //     flowbalance((res && res.data && res.data.marketplaceAddress)).then(m_balance => {
        //         if ((m_balance && m_balance.status) == 200) {
        //             console.log("balan", m_balance)
        //             this.setState({ marketFlowBalance: JSON.parse(m_balance && m_balance.data && m_balance.data.Value) })
        //         }
        //     }).catch(error => {})
        //    this.setState({adminAddress : (res && res.data && res.data.adminAddress),marketplaceAddress:(res && res.data && res.data.marketplaceAddress)})
        // }).catch(err => {})
    }
    handleLogout = () => {
        userAction.adminLogout()
    }
    addSideBar = () => {
        this.props.openSidebar()
    }
    render(){
        const {marketBalance,marketFlowBalance,adminBalance,adminFlowBalance,adminAddress,marketplaceAddress} = this.state
        return(
            <Fragment>
                <div className="header bg1c1c1c">
                    <a href="#default" className="logo"><img src={`../../../../${configPath.imageAssets}/DA-new-logo.png`} alt="" height="50" /></a>
                    <div className="header-right d-flex flex-row">
                        {/* <a  href="">Admin</a> */}
                        {/* <div className="smallilac mobhide">
                            <Link className="nav-link small">ADMIN<br /><span className="usernamelilac">{adminFlowBalance ? parseFloat(adminFlowBalance).toFixed(4) : ''}</span> Flow</Link>
                            <Link className="nav-link small">{(adminBalance && adminBalance > 0) ? parseFloat(adminBalance).toFixed(4) : ''} FUSD<br /><span className="usernamelilac">{adminAddress}</span></Link>                            
                            <Link className="nav-link small">MARKET<br /><span className="usernamelilac">{marketFlowBalance ? parseFloat(marketFlowBalance).toFixed(4) : ''}</span> Flow</Link>
                            <Link className="nav-link small">{(marketBalance && marketBalance > 0) ? parseFloat(marketBalance).toFixed(4) : ''} FUSD<br /><span className="usernamelilac">{marketplaceAddress}</span></Link>                        
                        </div> */}
                        <a className="p-4 mobhide" style={{color:"white",cursor:"pointer"}} onClick={this.handleLogout}>Logout</a>
                        {/* <span className="btn btn-outline-dark mobtoggle" onClick={this.addSideBar}><i className="fa fa-bars"></i></span> */}
                        <span className="btn btn-outline-dark mobtoggle"><i className="fa fa-bars"></i></span>
                    </div>
                </div>
            </Fragment>
        )
    }
    // toggleSidenav() {
    //     var css = (this.props.showHideSidenav === "hidden") ? "show" : "hidden";
    //     this.setState({"showHideSidenav":css});
    // }
}

export {Header}