import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class Privacy extends Component {
    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="pagetitabtn">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="w-auto text-center">Privacy Policy</h1>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='text-justify py-5 statcontsec'>
                        <p>
                            <span >
                                <strong>Last Updated:</strong> June 20, 2021 Disruption Now Media d/b/a DisruptArt (“DisruptArt,” “we” or “us”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices
                                regarding the Personal Data (as defined below) we collect from users of our website, located at
                            </span>
                            <a href="https://disrupt.art"><span >https://disrupt.art</span></a><span > (the “Site”) and online services (collectively, the “Service”).</span>
                        </p>
                        <p>
                            <span >
                                <strong>Questions;</strong><br />
                                Contacting DisruptArt; Reporting Violations. If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, or if you want to report any security violations to us,
                                please contact us through email at Info@disrupt.art.
                            </span>
                        </p>
                        <p>
                            <span >
                                User Consent. By submitting Personal Data through our Site or Services, you agree to the terms of this Privacy Policy and you expressly consent to the collection, use and disclosure of your Personal Data in accordance with this
                                Privacy Policy. For conducting Ethereum transactions we require the use of third-party electronic wallet extensions such as (but not limited to)DapperWallet and/or Blocto your interactions with Blocto and/or any third-party
                                electronic wallet extensions are governed by the applicable privacy policies.
                            </span>
                        </p>
                        <p>
                            <span >
                                A Note About Children. We do not intentionally gather Personal Data from visitors who are under the age of 13. If a child under 13 submits Personal Data and we learn that the Personal Data is the information of a child under 13,
                                we will attempt to delete the information as soon as possible. If you believe that we might have any Personal Data from a child under 13, please contact us at the address indicated in Section 1 above.
                            </span>
                        </p>
                        <p>
                            <span >
                                Types of Data We Collect. “Personal Data” means data that allows someone to identify or contact you, including, for example, your name, address, telephone number, e-mail address, as well as any other non-public information about
                                you that is associated with or linked to any of the foregoing data. “Anonymous Data” means data, including aggregated and de-identified data, that is not associated with or linked to your Personal Data; Anonymous Data does not,
                                by itself, permit the identification of individual persons. We collect Personal Data and Anonymous Data, as described below.
                            </span>
                        </p>
                        <h3 className="usernamelilac">Information You Provide Us.</h3>
                        <p><span >We may collect Personal Data from you, such as your display name, e-mail address, and Ethereum address.</span></p>
                        <p>
                            <span >
                                When connecting to our Services via a service provider that uniquely identifies your mobile device, we may receive this identification and use it to offer extended services and/or functionality.
                            </span>
                        </p>
                        <p>
                            <span >
                                If you provide us feedback or contact us via e-mail, we will collect your name and e-mail address, as well as any other content included in the e-mail, in order to send you a reply. iv. We may also collect Personal Data at other
                                points in our Service that state that Personal Data is being collected.
                            </span>
                        </p>
                        <p>
                            <span >
                                Information Collected via Technology. As you navigate through and interact with our Service, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns,
                                including:
                            </span>
                        </p>
                        <p>
                            <span >
                                Information Collected by Our Servers. To make our Service more useful to you, our servers (which may be hosted by a third party service provider) collect information from you, including your browser type, operating system,
                                Internet Protocol (“IP”) address (a number that is automatically assigned to your computer when you use the Internet, which may vary from session to session), domain name, Ethereum address, wallet type, and/or a date/time stamp
                                for your visit.
                            </span>
                        </p>
                        <p>
                            <span >
                                Log Files. As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (“ISP”), referring/exit pages,
                                operating system, date/time stamp, and clickstream data. We use this information to analyze trends, administer the Service, track users’ movements around the Service, gather demographic information about our user base as a
                                whole, and better tailor our Services to our users’ needs. For example, some of the information may be collected so that when you visit the Service, it will recognize you and the information could then be used to<br />
                                serve advertisements and other information appropriate to your interests.
                            </span>
                        </p>
                        <p>
                            <span >
                                Cookies. Like many online services, we use cookies to collect information. “Cookies” are small pieces of information that a website sends to your computer’s hard drive while you are viewing the website. We may use both session
                                Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Service. This type of
                                information is collected to make the Service more useful to you and to tailor the experience with us to meet your special interests and needs.
                            </span>
                        </p>
                        <p><span >How We Respond to Do Not Track Signals. We do not currently respond to “do not track” signals or other mechanisms that might enable Users to opt out of tracking on our site.</span></p>
                        <p>
                            <span >
                                Analytics Services. In addition to the tracking technologies we place, other companies may set their cookies or similar tools when you visit our Service. This includes third party analytics services, including but not limited to
                                Google Analytics (“Analytics Services”), that we engage to help analyze how users use the Service. We may receive reports based on these parties’ use of these tools on an individual or aggregate basis. We use the information we
                                get from Analytics Services only to improve our Service. The information generated by the Cookies or other technologies about your use of our Service (the “Analytics Information”) is transmitted to the Analytics Services. The
                                Analytics Services use Analytics Information to compile reports on user activity. The Analytics Services may also transfer information to third parties where required to do so by law, or where such third parties process
                                Analytics Information on their behalf. Each Analytics Services’ ability to use and share Analytics Information is restricted by such Analytics Services’ Terms of Use and Privacy Policy. By using our Service, you consent to the
                                processing of data about you by Analytics Services in the manner and for the purposes set out above. For a full list of Analytics Services, please contact us at the email address given in paragraph (1).
                            </span>
                        </p>
                        <p>
                            <span >
                                Information Collected from Third Party Companies. We may receive Personal and/or Anonymous Data about you from companies that provide our Services by way of a co-branded or private-labeled website or companies that offer their
                                products and/or services on our Service. In particular, Blocto and other wallets provide us with your address and certain other information you choose to share with Blocto and other wallets. These third party companies may
                                supply us with Personal Data. We may add this to the information we have already collected from you via our Service in order to improve it. We do not collect Personal Data automatically, but we may tie the information that we
                                collect automatically to Personal Data about you that we collect from other sources or that you provide to us.
                            </span>
                        </p>
                        <h3 className="usernamelilac">Use of Your Personal Data</h3>
                        <p>
                            <span >General Use. In general, Personal Data you submit to us is used either to respond to requests that you make, or to aid us in serving you better. We use your Personal Data in the following ways:</span>
                        </p>
                        <p><span >facilitate the creation of and secure your Account on our network;</span></p>
                        <p><span >identify you as a user in our system;</span></p>
                        <p><span >provide improved administration of our Service;</span></p>
                        <p><span >provide the Services you request, including but not limited to facilitating your cryptocurrency transactions through Blocto or other wallets;</span></p>
                        <p><span >improve the quality of experience when you interact with our Service;</span></p>
                        <p><span >send you a welcome e-mail to verify ownership of the e-mail address provided when your Account was created;</span></p>
                        <p><span >protect you and other users from any conduct that violates the Terms of Use or to prevent abuse or harassment of any user;</span></p>
                        <p><span >display your username next to the digital assets you wish to sell on the Website;</span></p>
                        <p><span >send you administrative e-mail notifications, such as security or support and maintenance advisories;</span></p>
                        <p><span >send you e-mail notifications related to actions on Service, including notifications of offers on your digital assets;</span></p>
                        <p><span >respond to your inquiries related to employment opportunities or other requests;</span></p>
                        <p><span >in any other way we may describe when you provide the Personal Data; and</span></p>
                        <p><span >send newsletters, surveys, offers, and other promotional materials related to our Services and for other marketing purposes of DisruptArt.</span></p>
                        <p><span >We may use your Personal Data to contact you about our own and third parties’ goods and services that may be of interest to you.</span></p>
                        <p>
                            <span >
                                Creation of Anonymous Data. We may create Anonymous Data records from Personal Data by excluding information (such as your name) that makes the data personally identifiable to you. We use this Anonymous Data to analyze request
                                and usage patterns so that we may enhance the content of our Services and improve Service navigation. We reserve the right to use Anonymous Data for any purpose and to disclose Anonymous Data to third parties without
                                restriction.
                            </span>
                        </p>
                        <p><span >Disclosure of Your Personal Data. We disclose your Personal Data as described below and as described elsewhere in this Privacy Policy.</span></p>
                        <p>
                            <span >
                                Third Party Service Providers . We may share your Personal Data with third party service providers to: provide you with the Services that we offer you through our Service; to conduct quality assurance testing; to facilitate
                                creation of accounts; to provide technical support; and/or to provide other services to DisruptArt.
                            </span>
                        </p>
                        <p>
                            <span >
                                Affiliates . We may share some or all of your Personal Data with our parent company, subsidiaries, joint ventures, or other companies under a common control (“Affiliates”), in which case we will require our Affiliates to honor
                                this Privacy Policy.
                            </span>
                        </p>
                        <p>
                            <span >
                                Corporate Restructuring . We may share some or all of your Personal Data in connection with or during negotiation of any merger, financing, acquisition or dissolution transaction or proceeding involving sale, transfer,
                                divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a business asset. If another company acquires our
                                company, business, or assets, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Data as described in this Privacy Policy.
                            </span>
                        </p>
                        <p>
                            <span >
                                As Legally Required . Regardless of any choices you make regarding your Personal Data (as described below), DisruptArt may disclose Personal Data if it believes in good faith that<br />
                                such disclosure is necessary (a) in connection with any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on DisruptArt; (c) to protect or defend the rights or property of
                                DisruptArt or users of the Service; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or our Terms of Use.
                            </span>
                        </p>
                        <p>
                            <span >
                                Other Disclosures . We may also disclose your Personal Data, to fulfill the purpose for which you provide it; for any other purpose disclosed by us when you provide it; or with your consent. We do not sell your Personal Data.
                            </span>
                        </p>
                        <p>
                            <span >
                                Third Party Websites. Our Service may contain links to third party websites. When you click on a link to any other website or location, you will leave our Service and go to another<br />
                                site, and another entity may collect Personal Data or Anonymous Data from you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content. Please be aware that the terms of
                                this Privacy Policy do not apply to these outside websites or content, or to any collection of your Personal Data after you click on links to such outside websites. We encourage you to read the privacy policies of every website
                                you visit. The links to third party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content or websites.
                            </span>
                        </p>
                        <p>Your Choices Regarding Information. You have several choices regarding the use of information on our Services:</p>
                        <ol>
                            <li>
                                <span >
                                    Email Communications. We will periodically send you free, opt-in newsletters and e-mails that directly promote the use of our Service. When you receive newsletters or promotional communications from us, you may indicate a
                                    preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions<br />
                                    provided in the email you receive or by contacting us directly (please see contact information in paragraph (1)). Despite your indicated email preferences, we may send you occasional service related communications,<br />
                                    including notices of updates to our Terms of Use or Privacy Policy. b. If you decide at any time that you no longer wish to accept Cookies from our Service for any of the purposes described above, then you can instruct your
                                    browser, by changing its settings, to stop accepting Cookies or to prompt you before accepting a Cookie from the websites you visit. Consult your browser’s technical information. If you do not<br />
                                    accept Cookies, however, you may not be able to use all portions of the Service or all functionality of the Service. If you have any questions about how to disable or modify Cookies, please let us know at the<br />
                                    address given in paragraph (1).
                                </span>
                            </li>
                            <li>
                                Data Access and Control. You can view, access, edit, or delete your personal data for many aspects of the Service via your Edit Profile page. You can also make choices about DisruptArt’s use of your data. You can always choose
                                whether you want to receive marketing communications from us. You can also opt out from receiving marketing communications from us by using the opt-out link on the communication, or by visiting your Edit Profile page. We may
                                retain an archived copy of your records as required by law or for legitimate business purposes.
                            </li>
                        </ol>
                        <p><span >Data Access . You can access your Personal Data on your account settings page.</span></p>
                        <p>
                            <span >
                                Data Portability . You can request a copy of your Personal Data by submitting an email to us at Info@disrupt.art and including “Please send me my Personal Data” in the subject line. DisruptArt will verify your ability to access
                                that email, then send you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 7 days, but in all events within 45 days of our receipt
                                of the request.
                            </span>
                        </p>
                        <p>
                            <span >
                                Data Erasure . You can delete your Personal Data on your account settings page. Alternatively, you may request that DisruptArt delete your personal data by submitting an email to us at Info@disrupt.art and including “Please
                                delete my Personal Data” in the subject line. DisruptArt will verify your ability to access that email, then delete the Personal Data associated with your email address. We will use reasonable efforts to respond to your request
                                within 7 days, but in all events within 28 days of our receipt of the request.
                            </span>
                        </p>
                        <p><span >Data Correction . You can modify your Personal Data on your account settings page.</span></p>
                        <p>
                            <span >
                                Data Retention. We may retain your personal information as long as you continue to use the Service, have an account with us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close
                                your account by contacting us as described above, and we will delete your Personal Data on request. We may, however, retain Personal Data for an additional period as is permitted or required under applicable laws, for legal,
                                tax, or regulatory reasons, or for legitimate and lawful business purposes.
                            </span>
                        </p>
                        <p>
                            <span >
                                Data Protection. We implement various security measures to ensure the safety of your Personal Data when you enter, submit, or access your Personal Data. All supplied sensitive information is transmitted via Secure Socket Layer
                                technology.
                            </span>
                        </p>
                        <p>
                            <span >
                                Changes to This Privacy Policy. This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at https://disrupt.art/privacy . The
                                date the Privacy Policy was last revised is identified at the beginning of this Privacy Policy. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting
                                our Service and this Privacy Policy to check for any changes.
                            </span>
                        </p>
                    </div>

                </div>

            </Fragment>
        )
    }
}
export { Privacy }