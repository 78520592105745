import React from "react";
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { ArtContent } from "./art-content";
import { artActions, creatorActions, dropActions, followActions, userAction } from "store/actions";
import { connect } from "react-redux";
import history from "helpers/history";
import { checkMintedTokens, checkMintedTokensWithEdition, checkTokenGroup, isWebLogin, ReactPagination, flowDecimal, getUSDValue, localArtPage, localDropPage, localAirdropPage, checkMintedTokensWithTotalEdition } from 'helpers'
import { getUserAddress } from "utils";
import { ToastContainer } from "react-toastify";
import { configPath } from "config";
import Modal from 'react-modal'
import { CreatorRequest } from "./creatorRequest";
import { Alert } from "helpers/alert";
import { Fragment } from "react";
import { collectorServices, creatorServices, userServices } from "services";
import PubSub from 'pubsub-js';
import {
    TelegramIcon,
    FacebookMessengerIcon,
    FacebookShareButton, TelegramShareButton, WhatsappIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon
} from 'react-share'
import { ArtPreview } from "./art-preview/art-preview";
import { Helmet } from "react-helmet";
import moment from 'moment'
import { ProfilePreview } from "./profilePreview";
import { SolarSystemLoading } from 'react-loadingg';
import { getFlowUsdValue } from "helpers/getFlowUsd";
import { InvalidPage } from "./Common-error-ui/Invalidpag";
import { ChangePasswordPopup } from "./popups/changePasswordPopup";

var timeout;
class CollectorDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            detailCollector: {},
            offset: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            size: 8,
            dataArray: [],
            onSaleArray: [],
            followerCount: 0,
            followingCount: 0,
            isSale: true,
            copySuccess: 'Copy Link',
            checkUser: false,
            show: false,
            isShowLoader: true,
            title: "Copy",
            isError: false,
            isCheckCollect: false,
            isInvalidPage: false,
            showPasswordPop: false

        }
    }
    copyToClipboard = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ copySuccess: 'Copied!' });
        const interval = setInterval(() => {
            this.setState({ copySuccess: 'Copy Link' });
        }, 3000);

    };
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    /* getUSD = () => {
        var rate  = ""
        userServices.getUSD().then(res => {
            if((res && res.statusCode == 200)){
                res && res.data && res.data.src_side_base && res.data.src_side_base.map(item => {
                    if ((item && item.asset) === "USD") {
                        rate = item.rate
                    }
                })
                this.setState({rate})
            }
        }).catch(err => {
            console.log(err)
        }) */
    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.getCollectors()

    }
    getCollectors = () => {
        let id = ""
        this.setCount()
        this.checkedUser()
        isWebLogin() && this.props.getUserProfile()

        if (this.props && this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.isFromLogin) {
            let state = this.props.history.location.state;
            let location = this.props.history.location
            delete state.isFromLogin;
            history.replace({ ...location, state });

        }

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {

            id = decodeURIComponent(this.props.match.params.id.replace('@', ''))
            // this.showLoader()
            this.setState({ isCheckCollect: false })
            collectorServices.detailCollector(id).then(res => {
                // this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    let userId = res && res.data && res.data.id

                    this.handleCollectibles(userId)
                    this.props.getFollowings(userId)
                    this.props.getFollowers(userId)
                    this.checkFollow(userId)
                    this.props.getViewCount(userId)

                    this.setState({ detailCollector: res && res.data, userId, isShowLoader: false, isError: false })
                }
            }).catch(err => {
                if (err instanceof TypeError) {
                    this.setState({
                        isAlert: true,
                        message: err,
                        style: "danger"
                    })
                }
                else if (err && err.includes("disabled")) {
                    this.setState({ isError: true })
                } else if (err && err.toLowerCase().includes('requested resource could not be found')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else if (err && err.toLowerCase().includes('in request are either missing or invalid')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else if (err && err.toLowerCase().includes('username should contain only ')) {
                    this.setState({
                        isInvalidPage: true,
                        isError: false
                    })
                } else {
                    this.setState({
                        isAlert: true,
                        message: err,
                        style: "danger"
                    })
                }
                this.hideLoader()
                this.setState({ isShowLoader: false })

            })

            // this.setState({ userId: id })
            // this.props.getUSD()

        }
    }
    static getDerivedStateFromProps(props, state) {
        // let rate = ""
        if (props) {
            if (props && props.userFollow && props.userFollow.statusCode == 200) {
                props.resetFollow()
                props.checkFollowUser(state.userId)
                props.getFollowings(state.userId)
                props.getFollowers(state.userId)
                return {
                    isAlert: true,
                    message: props && props.userFollow && props.userFollow.data && props.userFollow.data.message ? props.userFollow.data.message : `You can follow ${props.userFollow.data.userName && props.userFollow.data.userName} from now`,
                    style: "success"
                }

            }
            if ((props && props.failedFollow && props.failedFollow.length > 0)) {
                props.resetFailedFollow()
                return {
                    isAlert: true,
                    message: props && props.failedFollow,
                    style: "danger"
                }
            }
            if (props && props.unfollowmsg && props.unfollowmsg.statusCode == 200) {
                props.resetUnFollow()
                props.checkFollowUser(state.userId)
                props.getFollowings(state.userId)
                props.getFollowers(state.userId)

                return {
                    isAlert: true,
                    message: props && props.unfollowmsg && props.unfollowmsg.data && props.unfollowmsg.data.Message && props.unfollowmsg.data.Message,
                    style: "success"
                }

            }
            if ((props && props.checkUser && props.checkUser.statusCode) == 200) {
                props.resetCheckUser()
                return {
                    checkUser: true
                }
            }
            if ((props && props.failedCheck && props.failedCheck.length > 0)) {
                props.resetFailedCheck()
                return {
                    checkUser: false
                }
            }
            if ((props && props.followers && props.followers.statusCode) == 200) {
                props.resetFollowers()
                return {
                    followers: props.followers && props.followers.data && props.followers.data.follower ? props.followers.data.follower : [],
                    followerCount: props.followers && props.followers.data && props.followers.data.count ? props.followers.data.count : 0,

                }
            }
            if ((props && props.followings && props.followings.statusCode) == 200) {
                props.resetFollowing()
                return {
                    followings: props.followings && props.followings.data && props.followings.data.following ? props.followings.data.following : [],
                    followingCount: props.followings && props.followings.data && props.followings.data.count ? props.followings.data.count : 0

                }
            }
            if ((props && props.usersList && props.usersList.statusCode == 200)) {
                props.resetGetUsers()
                return {
                    profileData: props && props.usersList && props.usersList.data
                }
            }
            // props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
            //     if ((item && item.asset) == "USD") {
            //         rate = item.rate
            //     }
            // })
            return {

                onSaleArray: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.drops && props.singleUserDrop.data.drops.length > 0 ? props.singleUserDrop.data.drops : [],
                dataArray: props && props.singleUserArts && props.singleUserArts.data,
                dataArrayCount: props && props.singleUserArts && props.singleUserArts.data && props.singleUserArts.data.count,
                onSaleArrayCount: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.count,
                pageCount: props.singleUserArts && props.singleUserArts.data && props.singleUserArts.data.count / state.size,
                dropPageCount: props && props.singleUserDrop && props.singleUserDrop.data && props.singleUserDrop.data.count / state.size,
                isCheckCollect: props && props.isCheckCollect
                // rate
            }
        }
        props.resetOneUserArts()
    }
    componentDidUpdate(props, state) {

        if (props && props.userFollow && props.userFollow.statusCode == 200) {
            props.resetFollow()
            this.closeAlert()
            this.resetTimeout()
            this.gotoTop()
        }
        if (props && props.unfollowmsg && props.unfollowmsg.statusCode == 200) {
            props.resetUnFollow()
            this.closeAlert()
            this.resetTimeout()
            this.gotoTop()
        }
        let nft = configPath.basename

        if (window.location.pathname.includes('nft')) {
            if ((nft + decodeURIComponent(props && props.location && props.location.pathname)) != decodeURIComponent(window.location.pathname)) {
                this.getCollectors()
            }
        } else if (decodeURIComponent(props && props.location && props.location.pathname) != decodeURIComponent(window.location.pathname)) {
            this.getCollectors()
        }

    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)
    }
    handleUnfollow = () => {
        this.props.unfollow((this.state.userId))

    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    checkFollow = (userId) => {
        this.props.checkFollowUser(userId)
    }
    changeOnSale = () => {
        if (this.state.isSale == false) {
            this.setState({ isSale: true, offset: 0 }, () => {
                this.onSale(this.state.userId)
            })
        }

    }
    onSale = (id) => {
        let obj = {
            offSet: this.state.offset,
            recordLimit: this.state.size,
            sortBy: 'updatedAt',
            sortOrder: this.state.sortOrder

        }

        this.props.getOneUserDrop(obj, id)
        this.setState({ dataArray: [], isSale: true })
        this.props.resetOneUserArts()
    }
    changeCollectibles = () => {
        this.setState({ isSale: false, offset: 0 }, () => {
            this.handleCollectibles(this.state.userId)
        })
    }
    handleCollectibles = (id) => {
        window.scrollTo({
            top: 10,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offset,
            recordLimit: this.state.size,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder

        }
        this.props.getOneUserArts(obj, id)
        this.setState({ isSale: false, onSaleArray: [] })
        this.props.resetOneUserDrop()

    }
    gotoDrops = (items, userAddress) => {
        const { userId } = this.state
        console.log(userId)
        if ((items && items.drops && items.drops[0] && items.drops[0].id)) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else {
            if (userId) {
                history.push(`/art/${items && items.id}#collector-${userId}`)
            }
        }
    }
    onPageChange = (data) => {

        let offset = this.state.size * (data && data.selected)

        if (this.state.isSale) {
            this.setState({ offset, isCheckCollect: false }, () => this.onSale(this.state.userId))
        } else {
            this.setState({ offset, isCheckCollect: false }, () => this.handleCollectibles(this.state.userId))
        }

    }
    getFollowers = () => {

        this.props.getFollowers(this.state.userId)
    }
    getFollowings = () => {

        this.props.getFollowings(this.state.userId)
    }
    toggleMenu = () => {
        this.setState({ visible: !this.state.visible })
    }
    gotoUser = (items) => {
        if ((items && items.roleId) == 1 && (items && items.id != null)) {
            history.push(`/creator/@${items.userName && encodeURIComponent(items.userName)}`, { obj: { userName: items.userName } })
        }
        else if ((items && items.roleId) == 2 && (items && items.id != null)) {
            history.push(`/collector/@${items.userName && encodeURIComponent(items.userName)}`)
        }

    }
    startTime = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return <div className="mt-1"><p className="mb-0  usernamelilac">Bidding Closed</p></div>

            // return <div className="endofdate mt-1"><p className="mb-0 small  usernamelilac">Bidding Closed</p></div>
        }
        // this.setState({seconds,days,hours,minutes})
        // setInterval(this.startTime(items), 1000);
        // return <div className="mt-1"><p className="mb-0 ">Ends by : <span className="usernamelilac">{(`${days ? days+"d" : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        })
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    checkedUser = () => {
        let user = localStorage.getItem("webLogin")
        let userDetail = JSON.parse(user)
        this.setState({ loginUser: userDetail && userDetail.data && userDetail.data.authUser && userDetail.data.authUser.userName })
    }
    gotoProfile = () => {
        history.push(`/profile`)
    }
    getUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }


        }
    }
    getTwitUserName = (link) => {
        if (link) {
            let regex = /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/im
            let match = regex.exec(link)
            if (match) {
                return match[1]
            }

        }
    }
    displayDomain = (link) => {
        if (link && link.includes('https')) {
            return link.replace('https://', '')
        } else {
            return link
        }
    }
    getCollectorMetadata() {
        const metaData = {
            fullName: '...',
            userName: '...',
            description: '...'
        };
        const { detailCollector } = this.state;
        if (
            detailCollector
        ) {
            if (detailCollector.userName) {
                metaData.fullName = detailCollector.fullName;
            }
            if (detailCollector.userName) {
                metaData.userName = detailCollector.userName;
            }
            if (detailCollector.description) {
                metaData.description = detailCollector.description;
            }
        }
        return metaData;
    }
    handleCopy = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ title: "Copied" });
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        setTimeout(() => {
            this.setState({ title: "Copy" });
            this.setState({ active: currentState });
        }, 1000);
    }
    getSocialLinks = (detailCollector) => {
        return <div className="w-100">
            <div className="prosoclinks">
                <a href={detailCollector && detailCollector.instagramLink} target="_blank" rel="noreferrer" className={`${!(detailCollector && detailCollector.instagramLink) ? 'd-none' : ''}`}>
                    <span className="badgeview psocial"><i className="fab fa-instagram"></i></span> {this.getUserName(detailCollector && detailCollector.instagramLink)}</a>

                <a href={detailCollector && detailCollector.twitterLink} target="_blank" rel="noreferrer" className={`${!(detailCollector && detailCollector.twitterLink) ? 'd-none' : ''}`}>
                    <span className="badgeview psocial"><i className="fab fa-twitter"></i></span> @{this.getTwitUserName(detailCollector && detailCollector.twitterLink)}</a>


                {detailCollector && detailCollector.discord && <a id="uploadArt" onClick={() => this.handleCopy(detailCollector && detailCollector.discord)} className={this.state.active ? 'activecopy card flowtop' : 'card flowtop'}><span className="badgeview psocial"><i className="fab fa-discord"></i></span>  {detailCollector && detailCollector.discord}
                    <span className="copycon">
                        <span className="uparrow"></span>
                        {this.state.title}
                    </span></a>}

            </div>

            {((detailCollector && detailCollector.website) || (detailCollector && detailCollector.additionalLink1) || (detailCollector && detailCollector.additionalLink2) || (detailCollector && detailCollector.additionalLink3)) &&

                <div className="weblinks">
                    <hr className="dividerhr" />
                    <h5>Links</h5>
                    <div className="weblinklists">
                        <i className="fa fa-globe"></i>
                        <a
                            href={detailCollector && detailCollector.website}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCollector && detailCollector.website) ? 'd-none' : ''}`}>
                            {detailCollector && detailCollector.website && this.displayDomain(detailCollector.website)}

                        </a>
                        <a
                            href={detailCollector && detailCollector.additionalLink1}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCollector && detailCollector.additionalLink1) ? 'd-none' : ''}`}>
                            {detailCollector && detailCollector.additionalLink1 && this.displayDomain(detailCollector.additionalLink1)}

                        </a>
                        <a
                            href={detailCollector && detailCollector.additionalLink2}
                            target="_blank"
                            rel="noreferrer"
                            className={` ${!(detailCollector && detailCollector.additionalLink2) ? 'd-none' : ''}`}>
                            {detailCollector && detailCollector.additionalLink2 && this.displayDomain(detailCollector.additionalLink2)}

                        </a>
                        <a
                            href={detailCollector && detailCollector.additionalLink3}
                            target="_blank"
                            rel="noreferrer"
                            className={`${!(detailCollector && detailCollector.additionalLink3) ? 'd-none' : ''}`}>
                            {detailCollector && detailCollector.additionalLink3 && this.displayDomain(detailCollector.additionalLink3)}

                        </a>
                    </div>
                </div>}
            <hr className="dividerhr" />
            <div className="joindedate">
                Joined<br /> {detailCollector.createdAt && moment(detailCollector.createdAt).format('MMMM YYYY')}
            </div>
        </div>
    }
    checkTotalMintedTokens = (totalTokens) => {
        if ((totalTokens) && Number.isInteger(totalTokens)) {
            return totalTokens
        } else if (totalTokens.includes('-')) {
            return 1
        } else {
            return 1
        }
    }
    getMessage = (collector) => {
        if (collector && collector.fullName) {
            return `${collector.fullName}'s profile at ${process.env.REACT_APP_BASE_SITE}`
        } else {
            return ''
        }
    }
    handleChangePassword = () => {
        this.setState({
            showPasswordPop: true
        })
    }
    closePassModal = () => {
        this.setState({
            showPasswordPop: false,
        })
    }
    changepasswordFailed = (message) => {
        this.closePassModal()
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

    }
    changepasswordSuccess = (wallet) => {
        this.closePassModal()
        this.setState({
            isAlert: true,
            message: "Password changed successfully",
            style: "success",

        })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }

        const basePath = process.env.REACT_APP_BASEPATH

        const { detailCollector, followers, followings, checkUser, loginUser, rate } = this.state
        const shareUrl = `${process.env.REACT_APP_BASEPATH}${this.props && this.props.location && this.props.location.pathname}`
        const meta = this.getCollectorMetadata();
        const pageTitle = `${meta.fullName} (@${meta.userName}) ${process.env.REACT_APP_SITE_CONTRACT} profile`;
        const metaTitle = `${process.env.REACT_APP_SITE_CONTRACT} Collector - ${meta.fullName} (@${meta.userName}) `;

        return (
            <Fragment>
                <Helmet>
                    <title>{pageTitle}</title>
                    <meta name="title" content={metaTitle}></meta>
                    <meta name="description" content={`NFT Collections of ${process.env.REACT_APP_SITE_CONTRACT} Collector - ${meta.fullName} (@${meta.userName})`}></meta>
                </Helmet>
                {/* {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />} */}
                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                <div className="modal" id="following" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Following</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mb-3" style={{ overflow: 'auto', maxHeight: '300px' }}>
                                {followings && followings.length > 0 ? followings.map(items => (
                                    <div className="modalfollowlist">
                                        <div className="row" >
                                            <div className="col-md-9" onClick={() => { this.gotoUser(items) }} data-dismiss="modal" style={{ cursor: "pointer" }}>
                                                <div className="upic">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <img src={items && items.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} />
                                                        </span>
                                                    </span>

                                                </div>

                                                <h5 >{items && items.userName && items.userName != null && items.userName}</h5>
                                            </div>
                                        </div>


                                    </div>)) : <div>No Followings Yet</div>}


                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-primary px-5" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="followers" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Followers</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mb-3" style={{ overflow: 'auto', maxHeight: '300px' }}>
                                {followers && followers.length > 0 ? followers.map(items => (
                                    <div className="modalfollowlist">
                                        <div className="row" >
                                            <div className="col-md-9" onClick={() => { this.gotoUser(items) }} data-dismiss="modal" style={{ cursor: "pointer" }}>


                                                <div className="upic">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <img src={items && items.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} />
                                                        </span>
                                                    </span>

                                                </div>
                                                <h5 >{items && items.userName && items.userName != null && items.userName}</h5>
                                            </div>
                                        </div>


                                    </div>)) : <div>No Followers Yet</div>}
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-primary px-5" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
                {this.state.showPasswordPop ?
                    <ChangePasswordPopup
                        closeModal={this.closePassModal}
                        changepasswordSuccess={(val) => this.changepasswordSuccess(val)}
                        changepasswordFailed={(val) => this.changepasswordFailed(val)}
                    /> : ''}
                {(!(this.state.isError) && !(this.state.isInvalidPage)) ? <div>
                    <div className="coverimg imgcovergif rounded-0">
                        {!this.state.isShowLoader && <img src={detailCollector && detailCollector.bannerImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCollector && detailCollector.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt=""></img>}
                    </div>

                    <div className="propagecont ccdetailbox">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex align-items-start cctitpro">
                                        <div className="d-flex flex-column lftsdbar">
                                            <div className="badgeview propicdetail">
                                                <div className="picpro">
                                                    <span className="artcovimg">
                                                        <span className="artcov "></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <ProfilePreview src={(detailCollector && detailCollector.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCollector && detailCollector.profileImage}`)} alt="Avatar" />

                                                            {/* <img src={detailCollector && detailCollector.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${detailCollector && detailCollector.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" /> */}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="biodetadesk">
                                                {this.getSocialLinks(detailCollector)}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column w-100 biodeta">
                                            <div className="badgeview protittxt">
                                                <h3>{detailCollector && detailCollector.isPremiumAccount ? <img src={`../${configPath.imageAssets}/permium.png`} alt="" /> : ''} {detailCollector && detailCollector.fullName} </h3>
                                                <div className="useredit">
                                                    {detailCollector && detailCollector.userName && <h2 className="usernameClick useroverflow" title={detailCollector.userName}>{detailCollector && ((detailCollector.userName && detailCollector.userName.includes('@')) ? detailCollector.userName : (`@${detailCollector.userName}`))} </h2>}
                                                    {((this.state.profileData && this.state.profileData.userName) == (detailCollector && detailCollector.userName)) && <button type="button" className="btn btn-outline-dark btn-sm " onClick={this.gotoProfile}>Edit Profile</button>}

                                                    {!checkUser && (((this.state.profileData && this.state.profileData.userName) != (detailCollector && detailCollector.userName))) && <div>
                                                        {isWebLogin() ? <button className="btn btn-primary btn-sm px-3" onClick={this.handleFollow} >Follow</button> : <button className="btn btn-primary btn-sm px-3" onClick={() => { history.push('/login', { creatorUrl: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname) }) }} >Follow</button>}
                                                    </div>}
                                                    {checkUser && (((this.state.profileData && this.state.profileData.userName) != (detailCollector && detailCollector.userName))) && <div>
                                                        {isWebLogin() ? <button onClick={this.handleUnfollow} className="btn btn-primary btn-sm px-3 decbtn"  > <span><i className="fas fa-check"></i> &nbsp;Following</span>  </button> : <button className="btn btn-primary btn-sm px-3 decbtn" onClick={() => { history.push('/login', { creatorUrl: (this.props && this.props.location && this.props.location.pathname && this.props.location.pathname) }) }} ><span><i className="fas fa-check"></i> &nbsp;Following</span></button>}

                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-row w-100 mt-3 align-items-center">
                                                {this.state.visible &&
                                                    <div className="shareout" id="shareout">
                                                        <div className="share_overlaybg">
                                                            <div className="sharebox">
                                                                <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                                <span className="shareicons">
                                                                    <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/copylink.png`} alt="Copy Link" />
                                                                        <p className="txtchange">{this.state.copySuccess}</p>
                                                                    </a>
                                                                    <FacebookShareButton url={shareUrl} quote={this.getMessage(detailCollector)} hashtag={`#${process.env.REACT_APP_SITE_NAME}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/facebook.png`} alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                                    <TwitterShareButton url={shareUrl} title={this.getMessage(detailCollector)} hashtags={['nft', `${process.env.REACT_APP_SITE_NAME}`]} via={process.env.REACT_APP_SITE_NAME}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                                    <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}&description=${this.getMessage(detailCollector)}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/pinterest.png`} alt="Pinterest Share" />
                                                                        <p>Pinterest</p>
                                                                    </a>
                                                                    <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                                    <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                        <img className="share_icon" src={`${basePath}/assets/images/messenger.png`} alt="Messenger Share" />
                                                                        <p>Messenger</p>
                                                                    </a>
                                                                    <WhatsappShareButton url={shareUrl} title={this.getMessage(detailCollector)} ><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                                    <TelegramShareButton url={shareUrl} title={this.getMessage(detailCollector)}><TelegramIcon size={46} round={true} /><p>Telegram</p></TelegramShareButton>

                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                <div className="badgeview sharedrop">
                                                    <div className="dropdown">
                                                        <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                            <i className="far fa-share-square"></i>
                                                            Share
                                                        </h2>
                                                    </div>
                                                </div>

                                                <div className="card followboxcon">
                                                    <div className="followbox" data-toggle="modal" data-target="#following" onClick={this.getFollowings}><h4>{this.state.followingCount}</h4><p>Following</p></div>
                                                    <div className="followbox" data-toggle="modal" data-target="#followers" onClick={this.getFollowers}><h4>{this.state.followerCount}</h4><p>Followers</p></div>
                                                </div>



                                            </div>

                                            <div className="text-justify my-3">
                                                {(detailCollector && detailCollector.description) && <h4 className="usernamelilac">Bio</h4>}
                                                {(detailCollector && detailCollector.description && detailCollector.description.length > 600) ?
                                                    <div className="showless">
                                                        <input id="showless1" type="radio" name="showless" />
                                                        <label htmlFor="showless1" className="showless1">Read more</label>
                                                        <input id="showless2" type="radio" name="showless" checked={this.state.checked} />
                                                        <label htmlFor="showless2" className="showless2">Show less</label>
                                                        <div className="content">
                                                            <div id="showless1" className="showlesscontent">
                                                                {detailCollector && detailCollector.description}
                                                            </div>
                                                        </div>
                                                    </div> : <div className="content">
                                                        <div id="showless1" className="showlesscontent">
                                                            {detailCollector && detailCollector.description}
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="biodetamob">
                                            {this.getSocialLinks(detailCollector)}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="probtns activebtnscus">
                                        <ul className="list-unstyled mb-0 text-right">
                                            {isWebLogin() ? ((this.state.profileData && this.state.profileData.userName) === (detailCollector && detailCollector.userName)) ? <li><button type="button" className='btn btn-outline-dark btn-sm' onClick={this.handleChangePassword}>Change password</button></li> : '' : ''}
                                            <li><button type="button" className="btn btn-outline-dark btn-sm" onClick={this.changeCollectibles}>COLLECTIONS {this.state.dataArrayCount ? <span style={{ color: "white" }}>({this.state.dataArrayCount})</span> : ''}  </button></li>
                                        </ul>
                                    </div>

                                    <div className="card1 progallists mb-5">
                                        <div className="card-body1">
                                            <div className="row">
                                                {(!this.state.isCheckCollect && !this.state.isSale) ? <SolarSystemLoading /> :
                                                    !this.state.isSale && this.state.dataArray && this.state.dataArray.arts && this.state.dataArray.arts.map(items => (
                                                        // items && items.drops && items.drops.length == 0 && 
                                                        <div className="col-md-6" key={items && items.id} onClick={() => { this.gotoDrops((items), items && items.ownerWalletAddress) }} >
                                                            <div className="cardlist">

                                                                {/* <img src={items && items.imageHash} alt="" style={{ cursor: "pointer" }} /> */}
                                                                <ArtPreview src={items && items.thumbnailHash} alt="" style={{ cursor: "pointer" }} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                                <span className="cont" style={{ cursor: "pointer" }}>
                                                                    <h3>{items && items.title ? items.title : ""}</h3>
                                                                    {/* {(items && items.drops && items.drops.length > 0) ? 
                                <div>
                                <p title={items && items.drops && items.drops[0] && items.drops[0].markedPrice } className="usernamelilac">{items && items.drops && items.drops[0] && items.drops[0].markedPrice && flowDecimal(parseFloat(items.drops[0].markedPrice)) + ' ' + 'FLOW'}</p> 
                                {items && items.drops && items.drops[0] && items.drops[0].markedPrice && <span className="small text-white" title={getUSDValue(this.state.rate,items.drops[0].markedPrice)}>{(flowDecimal(parseFloat((this.state.rate) * (items.drops[0].markedPrice)))) + ' USD'}</span>}
                                </div>
                                : ''} */}
                                                                    {(items && items.drops && items.drops.length > 0) && <h4 className="usernamelilac">In drops</h4>}
                                                                    {items && items.groups && items.groups != null ?
                                                                        <p className="mb-0 small mt-1 text-right">{(items && items.toatalMintedTokens) ?
                                                                            ((checkMintedTokens(items.groups, (items && items.groups && items.groups.artsCount))) + " of " +
                                                                                (checkMintedTokensWithTotalEdition(items.groups, items.toatalMintedTokens))) : 1 + ' Total Edition'}</p> : <p className="mb-0 small mt-1 text-right">1 Total Edition</p>}

                                                                    {/* {items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null &&
                            <p className="mb-0"><span className="usernamelilac" title={items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid}>{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' ' + "FLOW") : 
                            <span className="usernamelilac ">
                                {items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.startPrice && `Starting Price : ${(flowDecimal(parseFloat(items.drops[0].auction.startPrice)) + ' ' + "FLOW")}`)} 
                            </span>)}</span></p>}
                            {items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null &&
                            <p className="mb-0"><span className="usernamelilac" >{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? 
                                (<span className="small text-white ">{(flowDecimal(parseFloat((this.state.rate) * (items.drops[0].auction.lastBid)))) + ' USD'}</span>)
                                 : 
                            <span className="usernamelilac ">
                                {items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0] && items.drops[0].auction && items.drops[0].auction.startPrice && items.drops[0].auction.startPrice && 
                                (<span className="small text-white">{(flowDecimal(parseFloat(((this.state.rate) * (items.drops[0].auction.startPrice))))) + ' USD'}</span>)
                                    )} 
                            </span>)}</span></p>}
                          
                        { this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])
                    } */}
                                                                    {/* {(items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null ) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1">Edition <span className="usernamelilac">{checkTokenGroup(items && items.groups,items.toatalMintedTokens)}</span> of {(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups,items.toatalMintedTokens)) : 1}</p> : <p className="mb-0 small mt-1">Edition 1 of <span className="usernamelilac">1</span></p>} */}



                                                                </span>
                                                            </div>
                                                        </div>


                                                    ))}


                                                {this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.map(items => (
                                                    <div className="col-md-6" key={items && items.id} onClick={() => { this.gotoDrops((items), items && items.ownerWalletAddress) }}>
                                                        <div className="cardlist">

                                                            <ArtPreview src={items && items.thumbnailHash} alt="" style={{ cursor: "pointer" }} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                            <span className="cont" style={{ cursor: "pointer" }}>
                                                                <h3>{items && items.title ? items.title : ""}</h3>
                                                                {(items && !items.hasSold) ? (items && items.drops && items.drops.length > 0) ? <p title={items && items.drops && items.drops[0] && items.drops[0].markedPrice}>{items && items.drops && items.drops[0] && items.drops[0].markedPrice && flowDecimal(parseFloat(items.drops[0].markedPrice)) + ' ' + 'FLOW'}</p> : <p>Not for Sale</p> : ''}
                                                                {(items && !items.hasSold && !this.dayClosed(items && items.drops && items.drops[0])) ? (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                                    <p className="mb-0"><span className="usernamelilac" title={items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid}>{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' ' + "FLOW") :
                                                                        <span className="usernamelilac ">
                                                                            {items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.startPrice && `Starting Price : ${(flowDecimal(parseFloat(items.drops[0].auction.startPrice)) + ' ' + "FLOW")}`)}
                                                                        </span>)}</span></p>)
                                                                    : (items && !items.hasSold) ? (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                                        <p className="mb-0"><span className="usernamelilac" title={items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid}>{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' ' + "FLOW") : '')}</span></p>) : ''}
                                                                {items && items.hasSold && <p className=" usernamelilac">Sold Out</p>}
                                                                {/* {items && items.groups && items.groups != null && <p className="mb-0"><span className="small">Edition </span>{items && items.groups && items.groups.artsCount}</p> } */}
                                                                {this.state.show && (items && !items.hasSold) && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])
                                                                }
                                                                {/* {(items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null ) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1">Edition 1 of <span className="usernamelilac">{items && items.groups && items.groups.artsCount}</span></p> : <p className="mb-0 small mt-1">Edition 1 of <span className="usernamelilac">1</span></p>} */}
                                                                {(items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1">Edition <span className="usernamelilac">{items && items.groups && items.groups.artsCount}</span> of {(items && items.toatalMintedTokens) ? (this.checkTotalMintedTokens(items.toatalMintedTokens)) : 1}</p> : <p className="mb-0 small mt-1">Edition 1 of <span className="usernamelilac">1</span></p>}

                                                            </span>

                                                        </div>


                                                    </div>


                                                ))}

                                                {/* {this.state.pageCount == 0 && this.state.createArtCount == 0 && (isCheckCollect || isCheckCreator) && 

                    <div className="col-md-4 offset-md-4 text-center">
                        <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                    </div>

                } 
                 {!this.state.isSale && this.state.dataArray &&this.state.dataArray.arts &&  this.state.dataArray.arts.length == 0 && isCheckCollect &&
                 
                 <div className="col-md-4 offset-md-4 text-center">
                 <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
             </div> }
             {this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.length == 0 && isCheckCreator &&
                 
                 <div className="col-md-4 offset-md-4 text-center">
                 <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
             </div> }*/}
                                            </div>
                                            {!this.state.isSale && this.state.dataArray && this.state.dataArray.arts && this.state.dataArray.arts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.size, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                                            {this.state.isSale && this.state.creatorArtsList && this.state.creatorArtsList.arts && this.state.creatorArtsList.arts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.createArtCount, perPage: this.state.size, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    (!(this.state.isInvalidPage) && (this.state.isError)) ? <div className="container-fluid text-center">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="error-template userdisableview">
                                    <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                    <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED</h3>
                                    <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4>

                                    <p className="mb-0">The Team</p>
                                    <p className="usernamelilac">DisruptArt</p>


                                </div>
                            </div>

                        </div>
                    </div> : <InvalidPage />
                }

            </Fragment>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        allUsers: state.user.allUsers,
        singleUserDrop: state.drop.singleUserDrop,
        singleUserArts: state.art.singleUserArts,
        userFollow: state.follow.userFollow,
        failedFollow: state.follow.failedFollow,
        followers: state.follow.followers,
        followings: state.follow.followings,
        unfollowmsg: state.follow.unfollow,
        checkUser: state.follow.checkUser,
        failedCheck: state.follow.failedCheck,
        creatorsList: state.art.creatorArts,
        usd: state.user.usd,
        isCheckCollect: state.art.isCheckCollect,
        usersList: state.user.usersList,


    }
}
const actionCreators = {

    getCollectors: userAction.getAllUsers,
    getUSD: userAction.getUSD,
    resetAllUsers: userAction.resetAllUsers,
    getOneUserArts: artActions.getOneUserArts,
    resetOneUserArts: artActions.resetOneUserArts,
    getOneUserDrop: dropActions.getOneUserDrop,
    resetOneUserDrop: dropActions.resetOneUserDrop,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    resetFailedFollow: followActions.resetFailedFollow,
    getFollowers: followActions.getFollowers,
    getFollowings: followActions.getFollowings,
    resetFollowers: followActions.resetFollowers,
    resetFollowing: followActions.resetFollowing,
    unfollow: followActions.unfollow,
    resetUnFollow: followActions.resetUnFollow,
    checkFollowUser: followActions.checkFollowUser,
    resetCheckUser: followActions.resetCheckUser,
    resetFailedCheck: followActions.resetFailedCheck,
    creatorArts: artActions.creatorArts,
    resetCreatorArts: artActions.resetCreatorArts,
    getUserProfile: userAction.getUsers,
    getViewCount: creatorActions.getViewCount,
    resetGetUsers: userAction.resetGetUsers



}
const connectCollectorDetail = connect(mapStateToProps, actionCreators)(CollectorDetail)
export { connectCollectorDetail as CollectorDetail }
