
// function to return user address store in session storage
export const getUserAddress = async () => {

   let useraddress = ""

   if (window.sessionStorage.getItem("CURRENT_USER"))
      useraddress = JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))["addr"];

   return useraddress

}


/**
 * Debounce a function for a set period of time.
 * 
 * The debounce function waits for a certain period of
 * time before invoking the callback function. It is used
 * to delay function execution.
 * @param {Function} cb Callback function
 * @param {number} waitTime Milliseconds to debounce before invoking callback.
 * @returns A Function that that invokes the callback after debouncing.
 */
export const debounce = (cb, waitTime = 300) => {
   let debounceTimer;
   return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(
         () => {
            cb.apply(this, args);
         },
         waitTime
      );
   }
}

export const getDisplayMessageForRuntimeTxnError = (errorMessage) => {
   let message = 'Transaction failed';
   if (
      errorMessage.toLowerCase().includes('tokens are not available')
   ) {
      message += ': Tokens are not available'
   } else if (
      errorMessage.toLowerCase().includes('not enough fusd to purchase nft')
   ) {
      message += ': Not enough FUSD to purchase NFT'
   } else if (
      errorMessage.toLowerCase().includes('no token matching this id for sale')
   ) {
      message += ': No token matching this ID for sale'
   } else if (
      errorMessage.toLowerCase().includes('please provide tokens')
   ) {
      message += ': Please provide tokens'
   } else if (
      errorMessage.toLowerCase().includes('tokens not available')
   ) {
      message += ': Tokens not available'
   } else if (
      errorMessage.toLowerCase().includes('nft does not exist in the collection')
   ) {
      message += ': NFT does not exist in the collection'
   } else if (
      errorMessage.toLowerCase().includes('the created collection must be empty')
   ) {
      message += ': The created collection must be empty'
   } else if (
      errorMessage.toLowerCase().includes('the id of the withdrawn token must be the same as the requested id')
   ) {
      message += ': The ID of the withdrawn token must be the same as the requested ID'
   } else if (
      errorMessage.toLowerCase().includes('endtime should greater than current time')
   ) {
      message += ': endtime should greater than current time'
   } else if (
      errorMessage.toLowerCase().includes('there are no auction items')
   ) {
      message += ': There are no auction items'
   } else if (
      errorMessage.toLowerCase().includes('auction not found')
   ) {
      message += ': Auction not found'
   } else if (
      errorMessage.toLowerCase().includes('auction has not completed yet')
   ) {
      message += ': Auction has not completed yet'
   } else if (
      errorMessage.toLowerCase().includes('auciton closed can\'t cancel')
   ) {
      message += ': Auciton closed can\'t cancel'
   } else if (
      errorMessage.toLowerCase().includes('auction does not exist')
   ) {
      message += ': Auction does not exist'
   } else if (
      errorMessage.toLowerCase().includes('auction has already completed')
   ) {
      message += ': auction has already completed'
   } else if (
      errorMessage.toLowerCase().includes('bid amount be larger than minimum bid increment')
   ) {
      message += ': bid amount be larger than minimum bid increment'
   }
   return message;
}

export function splitArray(array, chunckCount) {
   const chunks = [];
   for (let i = 0; i < array.length; i += chunckCount) {
      chunks.push(array.slice(i, i + chunckCount))
   }
   return chunks;
}

export function getBatchSize() {
   const size = parseInt(process.env.REACT_APP_BATCH_SIZE);
   if (Number.isInteger(size)) {
      return size;
   }
   return 250
}
export function getAdminUsername(){
   const userName = "disrupt.art"
   return userName
}

/**
 * Checks if given value is empty (=== '', === null, === undefined).
 * @param {unknown} value The value to be checked.
 */
export function isEmpty(value) {
   return (value === '' || value === null || value === undefined)
}

export function isValidEmailAddress(value){
   var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if (emailReg.test(value)) {
       return true
   } else {
       return false
   }
}

export function getOrdinalSuffix(number){
   // Special cases for numbers ending in 11, 12, and 13
   if (number % 100 >= 11 && number % 100 <= 13) {
     return "th";
   }
 
   // Get the last digit of the number
   const lastDigit = number % 10;
 
   // Determine the ordinal suffix based on the last digit
   switch (lastDigit) {
     case 1:
       return "st";
     case 2:
       return "nd";
     case 3:
       return "rd";
     default:
       return "th";
   }
 }
 export function spaceCheck(value) {
   const regex = /^\S.*[a-zA-Z\s]*$/g
   if (regex.test(value)) {
      return true
   }else{
      return false
   }
 }

 export const roleEnum = {
   CREATOR : 1,
   COLLECTOR : 2
 }