import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class Contact extends Component {
	render() {
		return (
			<Fragment>
				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="w-auto text-center ">Get In Touch</h1>
							</div>
						</div>
					</div>
					<hr />
					<section className='py-5 statcontsec'>
						<div className='row'>
							<div className="col-md-6 mb-3">
								<div className='purchasebox'>
									<h5 className='usernamelilac'>For quick help join our discord server</h5>
									<h4><a href='https://discord.io/disruptart+' target={"_blank"}>https://discord.io/disruptart+</a></h4>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div className='purchasebox'>
									<h5 className='usernamelilac'>Email For Creator Requests & Assistance</h5>
									<h4><a href='mailto:creators@disrupt.art'>creators@disrupt.art</a></h4>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div className='purchasebox'>
									<h5 className='usernamelilac'>For Collectors & Marketplace Assistance</h5>
									<h4><a href='mailto:marketplace@disrupt.art'>marketplace@disrupt.art</a></h4>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div className='purchasebox'>
									<h5 className='usernamelilac'>For Press Releases, Partnerships and General</h5>
									<h4><a href='mailto:contact@disrupt.art'>contact@disrupt.art</a></h4>
								</div>
							</div>

						
						</div>
					
					</section>
				</div>
			</Fragment>
		)
	}
}
export { Contact }