import { accessHeader } from "helpers";
import { airdropMoveTokens, flowMoveToAirDrop } from "utils/flow-air-drop";
import { fetchService } from "./fetch-service";
import PubSub from 'pubsub-js';
import { configPath } from "../config";
import history from '../helpers/history'
import { getDisplayMessageForRuntimeTxnError, getUserAddress } from "utils";
import moment from 'moment';

const airDropType = {
    PUBLIC: 0,
    SELECTED_USERS: 1,
    LOCATED_USERS: 2
};
export const airDropService = {
    addToAirDrops,
    getAirDropArt,
    claimAirDrop,
    airDropType,
    getAirDropDetailUser,
    editAirdropUsers,
    userstoremovefromairdrops,
    usersaddtoairdrops,
    addCSVImport,
    downloadSampleAirdropCSV,
    verifyAirdropUser,
    registerAirdropUser,
    airdropReminder,
    autoLoginAirdropUser,
    validateWithdrawAirdropTokens,
    withdrawAirdropArt,
    scanByLocationForGuest,
    scanByAirdrop,
    nearByLocation,
    autoLoginAirdropGuest,
    postGuestUserAirdrop,
    refreshLocationAirdrop,
    hideOrVisibleAirdrop,
    claimWalletlessAirDrop,
    autoLoginWelcomeUser,
    eventAirdropCreation
};

async function addToAirDrops(
    tokenIds, 
    airDropDate,
    airDropType,
    selectedUsers,
    location,
    endDate,
    isAlertOff
    ) {
    // console.log('%ctokenArray' + JSON.stringify(tokenIds), 'font-size:20px');
    try {
        await validateAirDropTokens(
            tokenIds, 
            airDropDate,
            airDropType,
            selectedUsers,
            location,
            endDate
            );
        const txnResponse = await airdropMoveTokens(tokenIds);
        if (txnResponse.status === 200) {
            const { transactionId, blockHeight } = txnResponse.data;

            const payLoad = {
                tokenIds,
                airDropDate,
                airDropType,
                selectedUsers,
                blockHeight: blockHeight,
                txnId: transactionId,
                isAlertOff
            };
            if(location !== null){
                payLoad['location'] = location
            }
            if(endDate !== null){
                payLoad['airDropEnd'] = endDate
            }
            // console.log(`%c${JSON.stringify(payLoad,null,4)}`, 'font-size:10px')
            const requestOptions = {
                method: 'POST',
                headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
                body: JSON.stringify(payLoad)
            }
            return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop`, requestOptions).then(handleResponse, handleError);
        }
        const error = txnResponse.data.Error
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        return Promise.reject((mes))
    } catch (e) {
        console.error(e, 'Failed to save air drop')
        if (e && e.data && e.data.Error) {
            return Promise.reject((e.data.Error))
        }else if (e && e.data && e.data.message) {
            return Promise.reject((e.data.message))
        }else if (e && e.message) {
            return Promise.reject(e.message)
        } else {
            return Promise.reject(String(e));
        }
    }
}
function getAirDropArt(obj) {
    const requestOptions = {
        method: "GET",
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        signal : obj.signal
    }
    let params = ''
    let offSet = obj && obj.offSet

    let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

    let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''


    params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder

    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop` + params, requestOptions).then(handleResponse, handleError)
}

function validateAirDropClaim(tokenId, listedUserId) {
    const payLoad = {
        tokenId,
        listedUserId
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/validate-claim`, requestOptions).then(handleResponse, handleError);
}

function saveAirDropClaim(tokenId, listedUserId, walletAddress) {
    const payLoad = {
        tokenId,
        listedUserId,
        walletAddress
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/claim`, requestOptions)
}
function saveWalletlessAirDropClaim(tokenId, listedUserId) {
    const payLoad = {
        tokenId,
        listedUserId
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/custodial-wallet/claim`, requestOptions)
}

function validateAirDropTokens(
    tokenIds, 
    airDropDate,
    airDropType,
    selectedUsers,location,endDate) {
    const payLoad = {
        tokenIds,
        airDropDate,
        airDropType,
        selectedUsers
    };
    if(location !== null){
        payLoad['location'] = location
    }
    if(endDate !== null){
        payLoad['airDropEnd'] = endDate
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/validate-tokens`, requestOptions).then(handleValidateAirdrops, handleError);
}

async function claimAirDrop(tokenId, listedUserId) {
    await validateAirDropClaim(tokenId, listedUserId);
    const walletAddress = await getUserAddress();
    return await saveAirDropClaim(tokenId, listedUserId, walletAddress);
}
async function claimWalletlessAirDrop(tokenId, listedUserId) {
    await validateAirDropClaim(tokenId, listedUserId);
    return await saveWalletlessAirDropClaim(tokenId, listedUserId);
}
function getAirDropDetailUser(airdropId) {
    
    const requestOptions = {
        method: "GET",
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/${airdropId}/detail` , requestOptions).then(handleResponse, handleError)
}
async function editAirdropUsers(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop`, requestOptions).then(handleValidateAirdrops, handleError);
        
       
    
}
function userstoremovefromairdrops(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/remove-selected-users`, requestOptions).then(handleValidateAirdrops, handleError);
           
    
}
function usersaddtoairdrops(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/add-selected-users`, requestOptions).then(handleValidateAirdrops, handleError);
           
    
}
function validateAirDropCSV(obj){
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'accept': 'application/json' },
        body: obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/validate-import`, requestOptions).then(handleValidateAirdrops, handleError);
}
async function addCSVImport(tokenIds, 
    airDropDate,
    airDropType,
    file,isAlertOff) {
        let formData = new FormData()
            formData.append("tokenIds",tokenIds)
            formData.append("airDropDate",(moment(airDropDate).toISOString(true)))
            formData.append("airDropType",airDropType)
            formData.append("uploadFile",file)
            
            console.log(file,tokenIds,airDropDate,airDropType,formData)
          
    try {
        await validateAirDropCSV(
            formData
            );
        const txnResponse = await airdropMoveTokens(tokenIds);
        if (txnResponse.status === 200) {
            const { transactionId, blockHeight } = txnResponse.data;

    
            formData.append("blockHeight",blockHeight)
            formData.append("txnId",transactionId)
            formData.append("isAlertOff",isAlertOff)
            // console.log(`%c${JSON.stringify(payLoad,null,4)}`, 'font-size:10px')
            const requestOptions = {
                method: 'POST',
                headers: { ...accessHeader(),'accept': 'application/json' },
                body: formData
            }
            return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/import`, requestOptions).then(handleResponse, handleError);
        }
        const error = txnResponse.data.Error
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        return Promise.reject((mes))
    } catch (e) {
        console.error(e, 'Failed to save air drop')
        if (e && e.data && e.data.Error) {
            return Promise.reject((e.data.Error))
        }else if (e && e.data && e.data.message) {
            return Promise.reject((e.data.message))
        }else if (e && e.message) {
            return Promise.reject(e.message)
        } else {
            return Promise.reject(String(e));
        }
    }
}
function downloadSampleAirdropCSV(){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(),'accept': 'text/csv' },
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/sample-csv`, requestOptions)
}
function verifyAirdropUser(obj){
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/user-auth/validation/invitee-uuid`, requestOptions).then(handleResponse, handleError)
}
function registerAirdropUser(obj){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/user-auth/invitee-register`, requestOptions).then(handleResponse,handleError)
}
function airdropReminder(id){
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/${id}/reminder`, requestOptions).then(handleResponse, handleError)
}
function autoLoginAirdropUser(id){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/user-auth/${id}/authorization`, requestOptions).then(handleResponse,handleError)
}

function validateWithdrawAirdropTokens(airDropId) {
    const payLoad = {
        airDropId
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/validate-withdraw`, requestOptions).then(handleResponse, handleError);
}
function withdrawAirdropArt(obj) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/withdraw`, requestOptions).then(handleResponse, handleError)
}
function scanByLocationForGuest(obj) {
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/scan`, requestOptions).then(handleResponse, handleError)
}
function scanByAirdrop(obj,signal) {
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj,
        signal
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/find`, requestOptions).then(handleResponse, handleError)
}
function nearByLocation(obj) {
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/scan-nearby`, requestOptions).then(handleResponse, handleError)
}
function autoLoginAirdropGuest(id, airdropId){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/air-drop-management/air-drop/verify-guest/${id}/${airdropId}`, requestOptions).then(handleResponse,handleError)
}
function postGuestUserAirdrop(obj){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    };
    return fetch(`${configPath.apiUrl}/air-drop-management/air-drop/claim-guest`, requestOptions).then(handleResponse,handleError)
}
function refreshLocationAirdrop(obj,signal) {
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj,
        signal
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/distance-recalculation`, requestOptions).then(handleResponse, handleError)
}
function hideOrVisibleAirdrop(id, obj) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/air-drop/${id}/visibility`, requestOptions).then(handleResponse, handleError)
}
function autoLoginWelcomeUser(id, token){
    const requestOptions = {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/user-auth/event-guest-authorization/${id}?token=${token}`, requestOptions).then(handleResponse,handleError)
}
function validateEventUserAirDropTokens(
    tokenIds, 
    airDropDate,
    eventName,
    description) {
    const payLoad = {
        tokenIds,
        eventDateTime : airDropDate,
        eventName
    };
    if(description && description !== ''){
        payLoad[description] = description
    }
    
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/event-air-drop/validation`, requestOptions).then(handleValidateAirdrops, handleError);
}
async function eventAirdropCreation(
    tokenIds, 
    airDropDate,
    eventName,
    description
    ) {
    // console.log('%ctokenArray' + JSON.stringify(tokenIds), 'font-size:20px');
    try {
        await validateEventUserAirDropTokens(
            tokenIds, 
            airDropDate,
            eventName,
            description
            );
            const txnResponse = await airdropMoveTokens(tokenIds);
            if (txnResponse.status === 200) {
                const payLoad = {
                    tokenIds,
                    eventDateTime : airDropDate,
                    eventName,
                };
                if(description && description !== ''){
                    payLoad[description] = description
                }
               
                // console.log(`%c${JSON.stringify(payLoad,null,4)}`, 'font-size:10px')
                const requestOptions = {
                    method: 'POST',
                    headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
                    body: JSON.stringify(payLoad)
                }
                return fetchService.fetchResource(`${configPath.apiUrl}/air-drop-management/event-air-drop`, requestOptions).then(handleResponse, handleError);
            }
            const error = txnResponse.data.Error
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        return Promise.reject((mes))
       
    } catch (e) {
        console.error(e, 'Failed to save air drop')
        if (e && e.data && e.data.Error) {
            return Promise.reject((e.data.Error))
        }else if (e && e.data && e.data.message) {
            return Promise.reject((e.data.message))
        }else if (e && e.message) {
            return Promise.reject(e.message)
        } else {
            return Promise.reject(String(e));
        }
    }
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                // logout();
            } else if (response.status === 400) {
                if (data.message && data.message.toLowerCase().includes('not found')) {
                    history.push('/notFound');
                }else if(data.message && data.message.toLowerCase().includes('doesnot exist')){
                    history.push('/notFound');
                }
            }
            const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                if(validationErorrs[0] && validationErorrs[0].includes("Cannot claim air drop before")){
                    const splitstr = validationErorrs[0].split(' ')
                    const lastDate = moment(splitstr[5]).format('DD MMM YYYY hh:mm A')
                    console.log(lastDate)
                    return Promise.reject(`Cannot claim air drop before ${lastDate}`);
                }else{
                    return Promise.reject(validationErorrs[0]);
                }
                

            }else if(data && data.data && 
                data.data.errorCode && (data.data.errorCode === "EXHAUSTED_REMINDER_QUOTA") ){
                    console.log(data)
                    if(data.message && data.message.toLowerCase().includes('reminder can be sent only once every 3 days')){
                        if(data && data.data && data.data.data && data.data.data.lastNotified && data.data.data.nextEligibileDate){
                            let obj = {
                                lastNotified : data.data.data.lastNotified,
                                nextEligibileDate : data.data.data.nextEligibileDate,
                                message : "Reminder can be sent only once in every 3 days"
                            }
                            return Promise.reject(obj)
                        }
                    }
                }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
        }
        return data;
    });
}

function handleError(err) {
    console.log(err)
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            return Promise.reject("Failed to fetch : Unexpected error occured")
        } else {
            PubSub.publish('msg', false)
            if(err && typeof err === 'string'){
                return Promise.reject(err)
            }else{
                if(err instanceof DOMException){
                    return Promise.reject("Request aborted")
                }else{
                    return Promise.reject("Unexpected error occured")
                }
            }
        }
    }
    catch (err) {
        PubSub.publish('msg', false)
        if(err && typeof err === 'string'){
            return Promise.reject(err)
        }else{
            return Promise.reject("Unexpected error occured")
        }
    }
}
function handleValidateAirdrops(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // logout();
            } else if (response.status === 400) {
                if (data.message && data.message.toLowerCase().includes('not found')) {
                    history.push('/notFound');
                }
            }
            const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                if(validationErorrs[0] && validationErorrs[0].includes("Cannot claim air drop before")){
                    const splitstr = validationErorrs[0].split(' ')
                    const lastDate = moment(splitstr[5]).format('DD MMM YYYY hh:mm A')
                    return Promise.reject(`Cannot claim air drop before ${lastDate}`);
                }else if((validationErorrs[0] && validationErorrs[0].includes("one or more user names provided are not available"))){
                    if(validationErorrs[1]){
                        const splitstr =validationErorrs[1].split(' ')
                        if(splitstr[7]){
                            const usernames = splitstr[7].split(',')
                            if(usernames && usernames.length > 1){
                                const error = `User names ${splitstr[7]} are not available `
                                return Promise.reject(error) 
                            }else{
                                const error = `User name ${splitstr[7]} is not available `
                                return Promise.reject(error)
                            }
                        }else{
                            const error = `User names ${splitstr[7]} are not available `
                            return Promise.reject(error)
                        }
                        
                    }else{
                        return Promise.reject(validationErorrs[0]);
                    }
                    
                }else if((validationErorrs[0] && validationErorrs[0].includes("one or more provided users's account has been disabled"))){
                    if(validationErorrs[1]){
                        const splitstr =validationErorrs[1].split(' ')
                        if(splitstr[9]){
                            const usernames = splitstr[9].split(',')
                            if(usernames && usernames.length > 1){
                                const error = `Users ${splitstr[9]} are disabled `
                                return Promise.reject(error)
                            }else{
                                const error = `User ${splitstr[9]} is disabled `
                                return Promise.reject(error)
                            }
                        }else{
                            const error = `User names ${splitstr[9]} are disabled `
                            return Promise.reject(error)
                        }
                        
                    }else{
                        return Promise.reject(validationErorrs[0]);
                    }
                    
                }else if((validationErorrs[0] && validationErorrs[0].includes("provided users do not exist"))){
                    if(validationErorrs[1]){
                        const splitstr =validationErorrs[1].split(' ')
                        if(splitstr[6]){
                            const usernames = splitstr[6].split(',')
                            if(usernames && usernames.length > 1){
                                const error = `User names ${splitstr[6]} are not available `
                                return Promise.reject(error) 
                            }else{
                                const error = `User name ${splitstr[6]} is not available `
                                return Promise.reject(error)
                            }
                        }else{
                            const error = `User names ${splitstr[6]} are not available `
                            return Promise.reject(error)
                        }
                    }else{
                        return Promise.reject(validationErorrs[0]);
                    }
                    
                }else if((validationErorrs[0] && validationErorrs[0].includes("must contain at most 250 rows"))){
                    return Promise.reject("Only 250 records are allowed in import")
                }else if((validationErorrs[0] && validationErorrs[0].includes("does not conform to the specified format"))){
                    return Promise.reject("Invalid file structure.Please download sample file below")
                }
                else{
                    return Promise.reject(validationErorrs[0]);
                }

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
        }
        return data;
    });
}
