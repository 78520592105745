import { configPath } from 'config';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class ComingSoon extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Fragment>
                <div style={{ background: "#000", padding: "0px", margin: "0px", position:'relative' }}>
                    <div className="grain"></div>
                    <div className="bgimg">
                        <img src={`/${configPath.imageAssets}/logoaw.png`} alt="Disrupt Art" style={{ maxWidth: "100%" }} />
                        <h1 style={{ color: "#fff", fontFamily: "Courier',New Courier, monospace" }}>Coming soon</h1>
                    </div>
                </div>
               
            </Fragment>
        )
    }
}

export { ComingSoon }