import { configPath } from "../config";

export const moonpayService = {
    getSignMoonpayGatewayUrl
}

function getSignMoonpayGatewayUrl(userWalletAddress) {
    const endpoint = `${configPath.apiUrl}/moon-pay-service/sign-url`;
    const body = {
        walletAddress: userWalletAddress
    };
    const options = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };
    return fetch(endpoint, options).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || data.message
            return Promise.reject(error)
        }
        return data;
    });
}
