import React, { useState } from "react";
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { SHA3 } from 'sha3';
import * as Elliptic from 'elliptic';
import { Gallery } from "./gallery";
import { RetrieveTokens, RetrieveMarketToken, marketSaleObjCreation, flowbalance, getUserAddress, RetrieveTokenById, transferFlow, marketSaleToken, marketBuyToken, RetrieveMarketTokenById, addfile, mintToken, MarketTokenPrice } from "./../utils"
import { Navbar } from "./Navbar";

window.fcl = fcl
window.t = t

const ec = new Elliptic.ec('p256');

export class ArtUpload extends React.Component {

  async onMintClick(e) {
    e.preventDefault();
    function hashMsgHex(msgHex: string) {
      const sha = new SHA3(256);
      sha.update(Buffer.from(msgHex, 'hex'));
      return sha.digest();
    }

    function signWithKey(privateKey: string, data: string) {
      const key = ec.keyFromPrivate(Buffer.from(privateKey, 'hex'));
      const sig = key.sign(hashMsgHex(data));
      const n = 32; // half of signature length?
      const r = sig.r.toArrayLike(Buffer, 'be', n);
      const s = sig.s.toArrayLike(Buffer, 'be', n);
      return Buffer.concat([r, s]).toString('hex');
    }


    interface Account {
      address: string;
      publicKey: string;
      privateKey: string;
      keyId: number;
    }


    const buildAuthorization = ({ address, keyId, privateKey }: Account) => (
      account: any
    ) => ({
      ...account,
      tempId: address,
      addr: address,
      keyId: keyId,
      resolve: null,
      signingFunction: (data: any) => {
        return {
          addr: address,
          keyId: keyId,
          signature: signWithKey(privateKey, data.message),
        };
      },
    });


    const admin: Account = {
      address: process.env.REACT_APP_CONTRACT_ADDRESS,
      publicKey: process.env.REACT_APP_PUBLIC_KEY,
      privateKey: process.env.REACT_APP_PRIVATE_KEY,
      keyId: 0,
    };

    let createCollection = `\
         import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

      transaction() {

           // local variable for storing the minter reference
          let minter: &DisruptNow.NFTMint
          let receiverAddrss : Address
          prepare(signer: AuthAccount,admin: AuthAccount) {

          // borrow a reference to the NFTMinter resource in storage
          self.minter = admin.borrow<&DisruptNow.NFTMint>(from: /storage/NFTMint)
            ?? panic("Could not borrow a reference to the NFT minter")

	  self.receiverAddrss = signer.address
      }

      execute {
          // Borrow the recipient's public NFT collection reference
          let receiver = getAccount(self.receiverAddrss)
              .getCapability(/public/NFTPublicCollection)
              .borrow<&{DisruptNow.NFTPublicCollection}>()
              ?? panic("Could not get receiver reference to the NFT Collection")

          // Mint the NFT and deposit it to the recipient's collection
          self.minter.Mint(recipient: receiver, ipfsendpoint: "testtoken",creator:`+ process.env.REACT_APP_CONTRACT_ADDRESS + `,price:1.5,royality:10.75)
    }
  } `


    const blockResponse = await fcl.send([
      fcl.getBlock(),
    ])

    console.log(blockResponse["block"])

    try {
      const { transactionId } = await fcl.send([
        fcl.transaction(createCollection),
        fcl.args([]),
        fcl.proposer(buildAuthorization(admin)),
        fcl.authorizations([
          fcl.currentUser().authorization, buildAuthorization(admin)
        ]),
        fcl.payer(fcl.currentUser().authorization),
        fcl.ref(blockResponse["block"].id),
        fcl.limit(1000),
      ])

      console.log(transactionId)

    } catch (error) {
      console.log("send to error reponse")
      console.error(error);
    }

  }

  async onCreateCollctionClick(e) {
    e.preventDefault();
    let createCollection = `\
					import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                        transaction {
                                                        prepare(acct: AuthAccount) {

                                                            // Return early if the account already has a collection
                                                            if acct.borrow<&DisruptNow.Collection>(from: /storage/NFTCollection) != nil {
                                                                  return
                                                             }

                                                             // Create a new empty collection
                                                             let collection <- DisruptNow.createEmptyCollection()

                                                             // save it to the account
                                                             acct.save(<-collection, to: /storage/NFTCollection)

                                                            // create a public capability for the collection
                                                            acct.link<&{DisruptNow.NFTPublicCollection}>(
                                                                      /public/NFTPublicCollection,
                                                                      target: /storage/NFTCollection
                                                                     )
                                                       }
                                         }`
    console.log(createCollection);


    try {

      const blockResponse = await fcl.send([
        fcl.getBlock(),
      ])

      const { transactionId } = await fcl.send([
        fcl.transaction(createCollection),
        fcl.args([]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.authorizations([
          fcl.currentUser().authorization
        ]),
        fcl.payer(fcl.currentUser().authorization),
        fcl.ref(blockResponse["block"].id),
        fcl.limit(1000),
      ])

      console.log(transactionId)
      // const unsub = fcl
      //  .tx({ tfcl.tx.isSealed(transaction)

    } catch (error) {
      console.log("send to error reponse")
      console.error(error);
    }

  }

  async onRetrieveTokensClick(e) {
    e.preventDefault();
    let address = await getUserAddress()
    console.log(await RetrieveTokens(address));
    let mintvalue = await mintToken(20.75, "my image", "sample", "0xd58500f9642c66ec")
    console.log(mintvalue);
    // await RetrieveMarketToken(address);
    // await marketSaleObjCreation();
  }

  async handleChange(e) {
    let token = await RetrieveTokenById(await getUserAddress(), e.target.value);
    console.log(token);
  }

  async onflowtransfer(e) {
    e.preventDefault();
    let flowtransfer = await transferFlow("0xe1834b11d56cadf8", "200.50");
    console.log(flowtransfer);
  }

  async marketobjcreation(e) {
    e.preventDefault();
    await marketSaleObjCreation();
  }

  async retrievemarkettoken(e) {
    e.preventDefault();
    console.log(await MarketTokenPrice("0x5f67f6439dd44140", 45));
    console.log(await RetrieveMarketToken(await getUserAddress()));
  }

  async movetokentomarket(e) {
    e.preventDefault();
    console.log(await marketSaleToken());
  }

  async buytoken(e) {
    e.preventDefault();
    // console.log(await RetrieveMarketTokenById("0x11900dcc0d90cb0f", 6));
    console.log(await marketBuyToken("0xfe364fcfc4a83602", 1));
  }

  async captureFile(e) {
    e.stopPropagation()
    e.preventDefault()
    const file = e.target.files[0]
    await addfile(file);
  }

  render() {
    return (
      <div className="container">
        <form className="row artfrmtop d-none">
          <div className="col-md-6">&nbsp;</div>
          <div className="col-md-6 text-right mb-3">
            <input type="text" className="form-control d-inline-block w-auto mt-1" onChange={this.handleChange} placeholder="" />
          </div>

          <div className="custom-btns col-md-12 probtns activebtnscus ">
            <ul className="list-unstyled mb-0">
              <li><button className="btn btn-outline-dark btn-sm mt-2 " onClick={this.onCreateCollctionClick}>Create Collection</button></li>
              <li> <button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.onRetrieveTokensClick}>My Tokens</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.onMintClick}>Mint</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.onflowtransfer}>Transfer Flow</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.marketobjcreation}>Market object</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.retrievemarkettoken}>Market token</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.movetokentomarket}>Token move to market</button></li>
              <li><button className="btn btn-outline-dark btn-sm mt-2  " onClick={this.buytoken}>Buy token</button></li>
            </ul>
          </div>

        </form>
        <Gallery urlProps={this.props.urlProp}/>


      </div>
    );

  }
}
