import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from 'store/actions';
import history from 'helpers/history';
import InfiniteScroll from 'react-infinite-scroll-component';
import { configPath } from '../../config';
import { SolarSystemLoading } from 'react-loadingg';
import { ArtPreview } from 'components/art-preview/art-preview';
import { checkMintedTokens, checkTokenGroup } from 'helpers';
import { babMintCount } from 'components/artdetail-view/bab-common-view';
import { Helmet } from 'react-helmet';

class StoreAddDrops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artsList: [],
            offSet: 0,
            recordLimit: 20,
            sortBy: "createdAt",
            sortOrder: "DESC",
            hasMore: true,
            loader: true,
            isCheckArt: false,
        }
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.callApi()
        this.gotoTop()
    }

    componentDidUpdate(props, state) {
        if ((this.props &&
            this.props.artsList &&
            this.props.artsList.statusCode === 200) &&
            (this.props.artsList) !== (props.artsList)
        ) {
            const data = this.props && this.props.artsList && this.props.artsList.data

            this.props.resetGetArts()
            const page = Number(this.state.recordLimit) + Number(this.state.offSet)
            if (data.arts.length === 0 || (data.arts.length < (this.state.recordLimit))) {
                const data1 = state.artsList.concat((props.artsList && props.artsList.data && props.artsList.data.arts))
                this.setState({
                    hasMore: false,
                    loader: false,
                    artsList: data1,
                    offSet: page,
                    isCheckArt: props.isCheckArt,
                })

            }
            const data2 = this.state.artsList.concat((data && data.arts))
            this.setState({
                artsList: data2,
                offSet: page,
                isCheckArt: props.isCheckArt,
            })

        }
        if(this.props && this.props.artsListFailed && this.props.artsListFailed.length > 0 && 
            (this.props.artsListFailed !== props.artsListFailed)){
                this.props.resetGetUserArtsFailed()
                this.setState({
                    loader : false,
                    hasMore : false
                })
            }
    }
    callApi = () => {
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
        }
        this.props.getArts(obj)

    }
    showTotalEditions = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null)) {
            return ''
        } else {
            if ((items.isBab)) {
                return <p className="mb-0 small">1 of <span className="">{babMintCount}</span> Total Edition</p>
            } else {
                if (items && items.groups && items.groups != null) {
                    return <p className="mb-0 small"><span className="">{checkTokenGroup(items && items.groups, (items.toatalMintedTokens))}</span> of {(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {this.checkEDitionCount(items)}</p>
                } else {
                    <p className="mb-0 small">1 of <span className="">1</span> Total Edition</p>
                }
            }
        }

    }
    checkEDitionCount = (items) => {
        if(items && items.groups && items.groups !== null && items.toatalMintedTokens){
        if((checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1){
            return ' Total Editions'
        }else{
            return ' Total Edition'
        }
    }else{
        return ' Total Edition'
    }

    }
    gotoDropsDet = (items) => {
        if ((items && items.drops && items.drops.length === 0)){
            history.push(`/store/list/${items && items.tokenId}`)
        }

    }
    isHidden = (items) => {
        if(items && items.status && items.status === 2){
            return true
        }else{
            return false
        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    render() {
        const { artsList, hasMore, loader } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>{"Add drops"}</title>
                    <meta name="title" content={`Explore NFT for sale`}></meta>
                    <meta name="description" content={`Explore NFT for sale`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit">
                        <h1 className="mb-0">Add Drops</h1>
                    </div>
                    <div className="propagecont mb-5">
                        <div className="container1">
                            <InfiniteScroll
                                dataLength={artsList && artsList.length} //This is important field to render the next data
                                next={(artsList && artsList.length > 0) ? this.callApi : ''}
                                hasMore={hasMore}
                                loader={
                                    <div className="solarloader" >
                                        <SolarSystemLoading />

                                    </div>}
                                endMessage={
                                    (artsList.length > 0) ?
                                        <p style={{ textAlign: 'center', fontSize: "15px" }} className="usernamelilac">
                                            <b>You have reached the end of list</b>
                                        </p> : (!loader && <div className="col-md-4 offset-md-4 text-center">
                                            <img src={`/${configPath.imageAssets}/noartyet-wt.png`} className="mw-100" alt="" />
                                        </div>)
                                }
                                style={{ overflow: "hidden" }}

                            >
                                <div className="ds-listing ds-infiniscroll">
                                    <div className="row">
                                        {artsList && artsList.map((items, index) => (
                                            <div className="col-sm-6 col-md-4 col-lg-3" key={index}  onClick={() => this.gotoDropsDet(items)}>
                                                <span className={this.isHidden(items) ? "ds-list hidden-arts" : 'ds-list'} title={(this.isHidden(items)) ? "Hidden" : ''}>
                                                    <ArtPreview src={items && items.thumbnailHash} alt={items && items.title} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />
                                                    <span className="ds-list-details">
                                                        <h4>{items && items.title}</h4>
                                                        <p>{this.showTotalEditions(items)}</p>
                                                    </span>
                                                </span>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        artsList: state.creatorStore.userArts,
        artsListFailed: state.creatorStore.userArtsFailed
    }
}
const actionCreators = {
    getArts: creatorStoreActions.getUsersArts,
    resetGetArts: creatorStoreActions.resetGetUsersArts,
    resetGetUserArtsFailed: creatorStoreActions.resetGetUsersArtsFailed

}
const connectStoreAddDrops = connect(mapStateToProps, actionCreators)(StoreAddDrops)
export { connectStoreAddDrops as StoreAddDrops }
