import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { artActions, userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import Toggle from 'react-toggle';
import { adminServices, artServices, fetchService } from 'services';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import Modal from 'react-modal'
import { debounce, isEmpty } from 'utils';
import history from 'helpers/history';

const customStyles = {
    content: {
        top: '10%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',


    },
};
class FetchArts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            creators: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: '',
            sDate: '',
            eDate: '',
            user: "Select",
            selectedUserId: '',
            selectedCreatorId: '',
            artTitles: [],
            title: '',
            openModal: false,
            previewItem: [],
            ownerName: '',
            isFetched: false

        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);

    }
    componentDidMount() {
        console.log(this.props)
        const obj = JSON.parse(localStorage.getItem("artParams"))
        if ((obj && obj != null && obj != undefined)) {
            this.setState({
                eDate: obj.eDate,
                offSet: obj.offSet,
                ownerName: obj.ownerName,
                sDate: obj.sDate,
                sortBy: obj.sortBy,
                sortOrder: obj.sortOrder,
                title: obj.title,
                forcePg: obj.offSet / this.state.recordLimit
            }, () => {
                this.callApi()
            })
            localStorage.removeItem('artParams')
        } else {
            this.callApi()
        }
        // this.allArts()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.allArts) || (props && props.allUsers && props.allUsers.data && props.allUsers.data.entity)) {
            return {
                arts: props.allArts && props.allArts.data && props.allArts.data.entity ? props.allArts.data.entity : [],
                totalCount: props.allArts && props.allArts.data && props.allArts.data.count,
                pageCount: props.allArts && props.allArts.data && props.allArts.data.count / state.recordLimit,
                isFetched: props.isCheckAdmArt
            }
        }

        // props.resetAllUsers()

    }
    handleRefresh = () => {
        this.setState({ sortBy: "createdAt", search: '', sortOrder: "DESC", offSet: 0, title: '', sDate: '', eDate: '', ownerName: '', forcePg: 0 }, () => {
            this.callApi()
        })
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        }, () => { this.callApi() })
    }
    handleSortBy = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }
    allArts = () => {
        adminServices.getAllArts().then(res => {
            if ((res && res.statusCode) === 200) {
                this.setState({ artTitles: (res && res.data && res.data.entity) ? (res.data.entity) : [] })
            }
        }).catch(err => { })
        adminServices.getAllUsers().then(res => {
            if ((res && res.statusCode) === 200) {
                this.setState({ usersList: (res && res.data && res.data.entity) ? (res.data.entity) : [] })
            }
        })
    }
    callApi = () => {
        const { sDate, eDate, selectedUserId, title, ownerName } = this.state

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (sDate && eDate) {
            obj['starting'] = sDate
            obj['ending'] = eDate
        }
        if (ownerName) {
            obj['ownerName'] = ownerName

        }
        if (selectedUserId) {
            obj["ownerId"] = selectedUserId
        }
        if (title) {
            obj['title'] = title
        }
        this.props.getAllArts(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {
            this.setState({ isShow: true, })
        } else {
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }
    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
    }
    handleProceed = (e) => {
        e.preventDefault();
        const { sDate, eDate, ownerName, title } = this.state
        if(
            isEmpty(sDate) && 
            isEmpty(eDate) && 
            isEmpty(ownerName )&& 
            isEmpty(title)
            ){
                toast.info("Please provide a search term")
            }
        else if (!sDate && !eDate) {
            this.callApi()
        } else {
            if (sDate) {
                if (eDate) {
                    if ((moment.utc(new Date(eDate)).isAfter(new Date(sDate))) || (moment.utc(new Date(eDate)).isSame(new Date(sDate)))) {
                        this.callApi()
                    } else {
                        toast.error("End date should greater than or same as start date")
                    }
                } else {
                    toast.error("Please select end date")
                }
            } else {
                toast.error("Please select start date")
            }
        }
    }
    handlePickUser = (e) => {

        this.setState({ ownerName: e.target.value, offSet: 0 })

    }
    handlePickArts = (e) => {

        this.setState({ title: e.target.value, offSet: 0 })

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeModal = () => {
        this.setState({ openModal: false, })
        // history.goBack()
    }
    previewPopup = (items) => {
        this.setState({ openModal: true, previewItem: items })
    }

    statusChange = (items) => {
        const status = (items && items.status) ? 0 : 1
        let obj = {
            status: status
        }
        this.showLoader()
        artServices.artStatusChange((items && items.id), JSON.stringify(obj)).then(res => {
            // if((res && res.statusCode) == 200){

            toast.success("Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })

    }
    fetchArt = (url) => {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return true
            } else {
                return false
            }
        }).catch((err) => {

        })

    }
    gotoArt = (items) => {
        const baseurl = process.env.REACT_APP_BASEPATH
        const id = ((items && items.id))
        const navPath = (baseurl + `/art/${id}`)
        window.open(navPath, '_blank')
    }
    gotoDetailpage = (items) => {
        const { sDate, eDate, selectedUserId, title, ownerName, sortBy, sortOrder, offSet } = this.state
        let obj = {
            sDate,
            eDate,
            selectedUserId,
            title,
            ownerName,
            sortBy,
            sortOrder, offSet
        }

        const baseurl = process.env.REACT_APP_BASEPATH
        const id = ((items && items.id))
        history.push(`/admin/artDetail/${id}`, { obj })
        localStorage.setItem("artParams", JSON.stringify(obj))
        // const navPath =  (baseurl + `/admin/artDetail/${id}`)
        // window.open(navPath,'_blank')
    }
    render() {

        const { usersList, previewItem } = this.state

        const artsList = this.state.arts && this.state.arts.map((items, index) => {
            return <tbody>
                <tr>
                    {/* <td>{((Number(offSet) + 1) + (Number(index)))}</td> */}
                    <td><a className="userdescrip_adm designLink" style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.title}  onClick={() => this.gotoDetailpage(items)}  >{items && items.title}</a></td>

                    {/* <td onClick={() => {this.previewPopup(items)}}>{ 
                 <span className="artcovimg "><span className="artcov"></span><span className="artcovimginset"><span className="articon"><ArtPreview src={items && items.imageHash} isAdmin={true} thumbnailHeight="100px" thumbnailWidth="100px" /></span></span></span>
                 
                 
                 }</td>  */}
                    <td><a className={'designLink mr-2 '} onClick={() => this.gotoArt(items)} ><i className="fas fa-external-link-alt"></i></a></td>
                    <td><a className={'designLink mr-2 '} onClick={() => this.gotoDetailpage(items)} >{items && items.nftCategoryName ? (items.nftCategoryName && items.nftCategoryName.charAt(0).toUpperCase() + items.nftCategoryName.slice(1)) : ''} </a> <a className={'designLink mr-2 float-right'} onClick={() => this.gotoDetailpage(items)}> <i className="fa fa-pencil" aria-hidden="true"></i>  </a></td>

                    {/* <td>{items && items.groups && items.groups.artsCount ? items.groups.artsCount : 1}</td> */}
                    <td>{(items && items.owner && items.owner.userName) ? (`@${items.owner.userName}`) : <span className='text-center'>------</span>}</td>
                    {/* <td>{items && items.creator && items.creator.userName && `@${items.creator.userName}`}</td> */}
                    <td>{items && items.creatorWalletAddress && items.creatorWalletAddress}</td>
                    <td>{items && items.ownerWalletAddress && items.ownerWalletAddress}</td>
                    <td><a className={'card flowtop'} style={{ display: "inline-block" }}>
                        <Toggle checked={items && items.status} onChange={() => this.statusChange(items)} />
                        {<span className="copycon">
                            <span className="uparrow"></span>
                            {items && items.status ? ("Disable Art") : ("Enable Art")}
                        </span>}</a></td>
                    <td>{moment(items && items.createdAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>
                    {/* <td><Toggle defaultChecked={items && items.accountStatus} /></td>
                <td><Toggle defaultChecked={items && items.isEmailVerified} disabled={items && items.isEmailVerified == true} /></td> */}


                </tr>
                {/* {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                <td colSpan="9" className="px-3">
                    <div className="row">
                        <div className="col-sm-4">
                    
                      <p>Now Account Status {items && items.accountStatus}</p>
                     
                      </div>
                        
                    </div>
                
                
                
                
                </td>
            </tr>} */}
            </tbody>

        })


        return (

            <Fragment>
                <Modal
                    isOpen={this.state.openModal}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal"                >
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <div className="loginpopcls adminimgprev">
                        <div className="custom-ui text-left">
                            <h4 className="mb-4 text-center">{previewItem && previewItem.title}</h4>
                            <div className="form-group text-center">
                                <img src={previewItem && previewItem.imageHash} />
                                <div className="custom-btn text-center mt-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Owner : &nbsp;</label>
                                            {/* <div className="upic mb-5" style={{cursor:"pointer"}}>
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                        <img src={previewItem && previewItem.owner && previewItem.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${previewItem && previewItem.owner && previewItem.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" width="50px" />
                                                        </span>
                                                    </span>
                                                </div> */}
                                            {previewItem && previewItem.owner && previewItem.owner.userName && (`@${previewItem.owner.userName}`)}

                                        </div>
                                        <div className="col-md-6">
                                            <label>Creator : &nbsp;</label>

                                            {previewItem && previewItem.creator && previewItem.creator.userName && (`@${previewItem.creator.userName}`)}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Arts</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    {/* <div className="col-3">
                             <label>Search User Name here</label>
                         <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search User Name" name="selectedUserId" onChange={this.handlePickUser} value={this.state.selectedUserId}/>
                                </div> */}
                                    {/* <label>
                            Pick User: </label>
                            <select
                                value={this.state.user}
                                onChange={this.handlePickUser}>
                                {usersList && usersList.map(user => (
                                <option key={user.id} value={user && user.id}>{user && user.userName}</option>
                                ))}
                            </select> */}

                                    {/* </div> */}
                                    <div className="col-md-3">
                                        <label>Art Name</label>
                                        <div>
                                            <input type="text" className="form-control" placeholder="Mona Lisa" name="title" onChange={this.handlePickArts} value={this.state.title} />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Owner User Name</label>
                                        <div>
                                            <input type="text" className="form-control" placeholder="jane.doe" name="ownerName" onChange={this.handlePickUser} value={this.state.ownerName} />

                                        </div>
                                        {/* <label>
                            Pick Art: </label>
                            <select
                                value={this.state.title}
                                onChange={this.handlePickArts}>
                                {artTitles && artTitles.map(art => (
                                <option key={art.id} value={art && art.title}>{art && art.title}</option>
                                ))}
                            </select> */}

                                    </div>


                                    <div className="col-md-3">
                                        {/* <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search}/>
                                </div> */}
                                        <label>Created At From</label>
                                        <div>
                                            <input type="date" name="sDate" onChange={this.handleDate} value={this.state.sDate} max={moment().format("YYYY-MM-DD")} className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        {/* <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search}/>
                                </div> */}
                                        <label>Created At To</label>
                                        <div>
                                            <input type="date" name="eDate" onChange={this.handleDate} value={this.state.eDate} max={moment().format("YYYY-MM-DD")} className="form-control" />

                                        </div>


                                    </div>
                                    <div className="col-md-3">

                                        <div className="mt-4">
                                            <button type="button" onClick={this.handleProceed} className="btn btn-primary ">
                                                <i className="fa fa-search" aria-hidden="true"></i> Search
                                            </button>
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-3 text-right">
                               <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                               <i className="fa fa-refresh" aria-hidden="true"></i>
                               </div>
                           </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        {/* <th>S.NO</th> */}
                                        <th>ART NAME</th>
                                        <th>IMAGE</th>
                                        <th>CATEGORY</th>
                                        {/* <th>ART COUNT</th> */}
                                        <th>OWNER NAME</th>
                                        {/* <th>CREATOR NAME</th> */}
                                        <th>CREATOR ADDRESS</th>
                                        <th>OWNER ADDRESS</th>
                                        <th>STATUS</th>
                                        <th>CREATED AT</th>
                                        {/* <th>Status</th>
                                        <th>Email Verified</th> */}
                                        {/* <th>ACTIONS</th> */}
                                    </tr>
                                </thead>

                                {artsList}

                            </table>
                            {this.state.arts && !this.state.arts.length > 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.arts && this.state.arts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePg }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allArts: state.art.allArts,
        allUsers: state.user.allUsers,
        isCheckAdmArt: state.art.isCheckAdmArt
    }
}

const actionCreators = {
    getAllArts: artActions.getAllArts,
    getAllUsers: userAction.getAllUsers,
    resetAllUsers: userAction.resetAllUsers,

}

const connectArt = connect(mapStateToProps, actionCreators)(FetchArts)

export { connectArt as FetchArts }