import React from "react";

export class SaleListProgress extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="sale-progress">
                    <div className="row w-100">
                        <div className="col-md-6 offset-md-3 purchasebox text-center py-5">
                            <div className="progress mb-5 cus-progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '75%' }}></div>
                            </div>
                            <h3 className="mb-3">Listing more than <span className="usernamelilac">350</span> will be done in batches.</h3>
                            <h4> You will be asked to approve a transaction for each batch</h4>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}