import React from "react";
import history from 'helpers/history'
import { ArtPreview } from './art-preview/art-preview'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { configPath } from "../config";
import moment from 'moment'
import { flowDecimal, getUSDValue } from "helpers";



export class OwlCarousalArt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            }
        }

    }


    componentDidMount() {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            // offSet:0,
            // recordLimit:6
        }
        console.log(this.props)
        // discoverServices.getAllFeatureArts(obj).then(res => {
            const rate = this.props && this.props.rate

        const featureArts = this.props && this.props.featureArts

        const featureArtsArr1 = featureArts && featureArts.map((items, index) => {
            return <div className="col-12" key={items && items.id}>
                <div className="cardlist artimglist" onClick={() => this.gotoDetail(items)}>
                    <ArtPreview src={(items && items.thumbnailHash)} alt="" isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} isValue={true} />

                    <div className="mediumcon">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 onClick={() => this.gotoDetail(items)} id="uploadArt" title={items && items.creator && items.creator.fullName}>{items && items.title} <span >{items && items.creator && items.creator.fullName && `by ${items.creator.fullName}`}</span></h3>

                            </div>

                        </div>
                    </div>

                </div> </div>
        })

        const featureArtsArr = featureArts && featureArts.map((items, index) => {
            return <div className="item falist" key={items && items.id}>
                <div className="falist-con" onClick={() => this.gotoBuy(items)} id="uploadArt">
                    {items && items.isPremiumArt &&
                        <div className="fafeaturetxt">
                            <img src="assets/images/permium.png" alt="" className="permiumicon" /> <i className="fa fa-video ml-2 fa-cus"></i>
                        </div>}
                    <div className="faimg" id="faimg">
                        <ArtPreview src={(items && items.thumbnailHash)} alt="" showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} isValue={true} />
                    </div>
                    <h3><span onClick={() => this.gotoCreator(items && items.creator)} id="uploadArt" title={items && items.creator && items.creator.fullName}>{items && items.creator && items.creator.fullName && `${items.creator.fullName}`}</span></h3>
                </div>
                <div className="falistdesc">
                    {/* <h3><span onClick={() => this.gotoCreator(items && items.creator)} id="uploadArt" >{items && items.creator && items.creator.fullName && `${items.creator.fullName}`}</span></h3> */}

                    {/* <h4><span onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title}</span></h4> */}
                    <div className='row'>
                        <div className='col-md-6'><h4><span onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title}</span></h4></div>
                        <div className='col-md-6'>
                            <div className='dscpb'>
                                {(this.getUsdValue(items,rate) && this.getPrice(items)) ? <div className='dscpbtxt'><span className='small'>{this.getUsdValue(items,rate)} </span><br/>{this.getPrice(items)}</div> : ''}
                                {(this.startTime(items && items.drops && items.drops[0])) ? <div className="dscpbtxt">{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && this.startTime(items && items.drops && items.drops[0])}</div> : ''}
                                {this.isAvailabletoBuy(items) ? <div className='dscpbbtn'><button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={() => this.gotoBuy(items)}>Buy Now</button></div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })

        this.setState({ featureArtsArr, featureArtsArrLen: featureArtsArr && featureArtsArr.length, featureArts, featureArtsArr1 })
        // })
    }
    getPrice = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} FLOW`
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                return `${flowDecimal(parseFloat(amount))} FLOW`
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        return `${flowDecimal(parseFloat(amount))} FLOW`
                    }

                }
            }
        } else {
            return false
        }
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    getUsdValue = (items, rate) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            return `$${(getUSDValue(rate, items.drops[0].markedPrice))} `
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                return `$${getUSDValue(rate, (amount))} `
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        return `$${getUSDValue(rate, (amount))} `
                    }

                }
            }
        } else {
            return false
        }
    }

    startTime = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return 'Bidding Closed'
        }

    }
    gotoBuy = (items) => {
        if (items.id && items.drops && items.drops[0] && items.drops[0].id) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        }else if(items.id){
            history.push(`/art/${items.id}`)
        }
    }
    isAvailabletoBuy = (items) => {
        if (items && items.drops && items.drops[0]) {
            if (this.dayClosed(items.drops[0])) {
                return false
            }
            return true
        } else {
            return false
        }
    }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }


    }
    gotoPath = (e, items) => {
        // console.log(e.target)
        if (e.target.tagName == "H3") {
            this.gotoDetail(items)
        } else if (e.target.tagName == "SPAN") {
            this.gotoCreator(items && items.creator)
        }
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    gotoArts = () => {
        history.push('/arts')
    }
    gotoCreators = () => {
        history.push('/creators')
    }
    gotoCollectors = () => {
        history.push('/collectors')
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    renderImage = (items) => {
        // console.log(items)
        if ((items && items.imageMimeType && items.imageMimeType.match(/image.*/))) {
            return <img src={(items && items.thumbnailHash && items.thumbnailHash)} />
        } else if (items && items.imageMimeType && items.imageMimeType.match(/video.*/)) {
            return (<span className="articonstatic hexagon" id="articonstatic"><img src={`../${configPath.imageAssets}/ajax-loader.gif`} className="hexagonloader" width={this.props.width} style={this.props.style} alt="" /></span>)

        }
    }

    render() {

        return (
            <div>
                {(this.state.featureArtsArr) && (this.state.featureArtsArrLen) && (this.state.featureArtsArrLen) > 1 ?
                    <AliceCarousel
                        autoPlay autoPlayInterval={3500}
                        infinite
                        items={this.state.featureArtsArr}
                        responsive={this.state.responsive}
                        autoPlayDirection={"ltr"}
                        mouseTracking={true}
                        disableDotsControls
                    /> :
                    (this.state.featureArtsArr)
                }
            </div>

        )
    }
}
