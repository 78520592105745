import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { eventActions, midwestActions } from 'store/actions';
import moment from 'moment'
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { toast } from 'react-toastify';


class ModwestAddEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name : '',
            desc : '',
            onstart : '',
            endat : '',
            venueAddress : '',
            googleMapLink : '',
            appleMapLink : '',
            submitted : false
        }

    }
    componentDidMount() {
        const props = this.props && this.props.params
        if(props && props.id){
            this.props.getEventDetail(props.id)
        }
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.addedEvent && props.addedEvent.statusCode === 201)) {
            props.resetaddEvent()
            toast.success("Event added successfully")
            history.push('/admin/midwestconEventList')
        }
        if((props && props.getEventDetailRes && props.getEventDetailRes.statusCode === 200)){
            props.resetEventDetail()
            if(props.getEventDetailRes.data && 
               props.getEventDetailRes.data.convention &&
               props.getEventDetailRes.data.convention.id 
                )
            return {
                name : props.getEventDetailRes.data.convention.name,
                desc : props.getEventDetailRes.data.convention.description,
                onstart : (props.getEventDetailRes.data.convention.startsAt && moment(props.getEventDetailRes.data.convention.startsAt).format('YYYY-MM-DD')),
                endat : (props.getEventDetailRes.data.convention.endsAt && moment(props.getEventDetailRes.data.convention.endsAt).format('YYYY-MM-DD')),
                googleMapLink : props.getEventDetailRes.data.convention.googleMapLink,
                appleMapLink : props.getEventDetailRes.data.convention.appleMapLink,
                venueAddress : props.getEventDetailRes.data.convention.venueAddress
            }
        }
        if((props && props.updatedEvent && props.updatedEvent.statusCode === 200)){
            props.resetUpdateEvent()
            history.push('/admin/midwestconEventList')
        }
        return null

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    onFullNameChange = (e) => {
        this.setState({name : e.target.value})
    }
    onDescChange = (e) => {
        this.setState({desc : e.target.value})

    }

    checkname = (value) => {
        if ((value && value.length) > 100) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkDesc = (value) => {
        if (((value && value.length) <= 250) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    handleOnstart = (e) => {
        this.setState({onstart : e.target.value})
    }
    handleEndTime = (e) => {
        this.setState({endat : e.target.value})
    }
    checkValidDate = () => {
        if (new Date(this.state.endat) > new Date(this.state.onstart)) {
            return true
        } else {
            return false
        }
    }
    checkmaxInput = (value) => {
        if ((value && value.length) <= 100) {
            return true
        } else {
            return false
        }
    }
    checkInput = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkMaxDescInput = (value) => {
        if ((value && value.length) <= 250) {
            return true
        } else {
            return false
        }
    }
    validURL = (str) => {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }
    gotoBack = () => {
        history.goBack()
    }
    handleLinkChanges = (e) => {
        this.setState({googleMapLink : e.target.value})
    }
    handleappleLinkChanges = (e) => {
        this.setState({appleMapLink : e.target.value})
    }
    onAddresschange = (e) => {
        this.setState({venueAddress : e.target.value})

    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({
            submitted : true
        })
        console.log(this.state)
        const {name,desc,onstart,endat,googleMapLink,appleMapLink,venueAddress} = this.state
        const props = this.props
        if (name && this.checkFullName(name)) {
            if ( desc && this.checkDesc(desc)) {
                if (onstart) {
                    if (endat) {
                        if (this.checkValidDate()) {
                            if (venueAddress && this.checkDesc(venueAddress)) {

                                if (googleMapLink && this.validURL(googleMapLink)) {
                                    if (appleMapLink && this.validURL(appleMapLink)) {


                                        let obj = {
                                            name,
                                            startsAt: moment(onstart).toISOString(),
                                            endsAt: moment(endat).toISOString(),
                                            venueAddress : venueAddress,
                                            googleMapLink : googleMapLink,
                                            appleMapLink : appleMapLink,
                                            description : desc
                                        }
                                        if (props && props.params && props.params.id) {
                                            obj["id"] = Number(props && props.params && props.params.id)
                                            this.props.updateEvent(JSON.stringify(obj))
                                        } else {
                                            this.props.addevent(JSON.stringify(obj))
                                        }


                                    } else {
                                        toast.error("Please provide your valid apple map link")
                                    }
                                } else {
                                    toast.error("Please provide your valid google map link")

                                }
                            } else {
                                toast.error("Venue address must be contain atlease 3 characters and atmost 250 characters long")
                            }

                        } else {
                            toast.error("End date and time should be greater than start date")
                        }
                    } else {
                        toast.error("Please select end date")
                    }
                } else {
                    toast.error("Please select start date")
                }
            } else {
                toast.error("Description must be contain atlease 3 characters and atmost 250 characters long")
            }

        } else {
            toast.error("Event name mandatory and should be atleast 3 characters and atmost 100 characters")
        }
    }
    render() {
        const props = this.props
        return (

            <Fragment>

                <div className="smallilac px-3">
                    <div className='ca-card mb-3 '>
                        <div className="row admintitle align-items-center">
                            <div className="col-sm-6 "><h2 className="font-weight-bold">
                                {(props && props.params && props.params.id) ? "Edit Event" : "Add Event"}</h2>  </div>
                            <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}><i className="fas fa-angle-left"></i> Back</button>  </div>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className='ca-card mb-3 '>
                            <div className="row">
                                <div className="form-group col-md-4 mb-4">
                                    <label htmlFor="name " className="font-weight-bold" >Event name &nbsp;*</label>
                                    <input type="text" className={this.checkname(this.state.name) ? "form-control descdan" : "form-control"} id="name" aria-describedby="name" placeholder="Enter event name" value={this.state.name} onChange={this.onFullNameChange} />
                                    {<div className="mandatory-req small">{"Min 3 characters and max 100 characters"}</div>}
                                    {this.state.submitted && !this.state.name && <div className="mandatory small">{"Event name required"}</div>}
                                    {this.state.submitted && this.state.name && !this.checkInput(this.state.name) && <div className="mandatory small">{"Event name must be at least 3 characters"}</div>}
                                    {this.state.submitted && this.state.name && !this.checkmaxInput(this.state.name) && <div className="mandatory small">Event name should be no more than 100 characters</div>}
                                </div>
                                <div className="form-group col-md-8 mb-4">
                                    <label htmlFor="desc " className="font-weight-bold" >Description &nbsp;*</label>
                                    <textarea className="form-control textareaexapand" id="desc" aria-describedby="desc" placeholder="Enter event description" value={this.state.desc} onChange={this.onDescChange} />
                                    {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}
                                    {this.state.submitted && !this.state.desc && <div className="mandatory small">{"Description required"}</div>}
                                    {this.state.submitted && this.state.desc && !this.checkInput(this.state.desc) && <div className="mandatory small">{"Description must be at least 3 characters"}</div>}
                                    {this.state.submitted && this.state.desc && !this.checkMaxDescInput(this.state.desc) && <div className="mandatory small">Description should be no more than 250 characters</div>}

                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <div className="">
                                        <label className="font-weight-bold" htmlFor="onstart" >Starts from&nbsp;*</label>

                                        <input id="onstart" name="onstart" type="date" value={this.state.onstart} onChange={this.handleOnstart} className="form-control" min={moment().format('YYYY-MM-DD')} />
                                        {this.state.submitted && !this.state.onstart && <div className="mandatory mt-2 small">{"Please select your event start date"}</div>}
                                    </div>
                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <div className="">
                                        <label className="font-weight-bold" htmlFor="endat" >Ends at&nbsp;*</label>

                                        <input id="endat" name="endat" type="date" value={this.state.endat} onChange={this.handleEndTime} className="form-control" min={moment(this.state.onstart).format('YYYY-MM-DD')} disabled={!this.state.onstart} />
                                        {this.state.submitted && !this.state.endat && <div className="mandatory mt-2 small">{"Please select your event end date"}</div>}
                                    </div>
                                </div>


                                <div className="form-group col-md-12 mb-4">
                                    <label htmlFor="venueAddress" className="font-weight-bold" >Venue Address &nbsp;*</label>
                                    <textarea className="form-control textareaexapand" id="venueAddress" aria-describedby="venueAddress" placeholder="Enter venue address" value={this.state.venueAddress} onChange={this.onAddresschange} />
                                    {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}
                                    {this.state.submitted && !this.state.venueAddress && <div className="mandatory small">{"Venue address required"}</div>}
                                    {this.state.submitted && this.state.venueAddress && !this.checkInput(this.state.venueAddress) && <div className="mandatory small">{"Address must be at least 3 characters"}</div>}
                                    {this.state.submitted && this.state.venueAddress && !this.checkMaxDescInput(this.state.venueAddress) && <div className="mandatory small">Address should be no more than 250 characters</div>}

                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label htmlFor="googleMapLink " className="font-weight-bold" >Google map link&nbsp;*</label>
                                    <input className={"form-control"} value={this.state.googleMapLink} name="googleMapLink" onChange={this.handleLinkChanges} placeholder="Enter google map link" />
                                    {this.state.submitted && this.state.googleMapLink && !this.validURL(this.state.googleMapLink) && <div className="mandatory">Please provide a proper link</div>}
                                    {<div className="mandatory-req">Ex: https://goo.gl/maps/14UkaD1xUKVRQRHt6</div>}


                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label htmlFor="appleMapLink " className="font-weight-bold" >Apple map link&nbsp;*</label>
                                    <input className={"form-control"} value={this.state.appleMapLink} name="appleMapLink" onChange={this.handleappleLinkChanges} placeholder="Enter apple map link" />
                                    {this.state.submitted && this.state.appleMapLink && !this.validURL(this.state.appleMapLink) && <div className="mandatory">Please provide a proper link</div>}
                                    {<div className="mandatory-req">Ex: https://goo.gl/maps/14UkaD1xUKVRQRHt6</div>}


                                </div>
                                {<div className="form-group col-md-12 text-right">
                                    <hr />
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>}
                            </div>
                        </div>

                    </form>

                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addedEvent: state.midwestcon.addedMidwestEvent,
        updatedEvent : state.midwestcon.updatedMidwestEvent,
        getEventDetailRes : state.midwestcon.getMidwestEventDetail

    }
}

const actionCreators = {
    addevent : midwestActions.addMidwestEvents,
    updateEvent : midwestActions.updateMidwestEvent,
    resetaddEvent : midwestActions.resetAddedMidwestEvent,
    resetUpdateEvent : midwestActions.resetUpdatedMidwestEvent,
    getEventDetail : midwestActions.getMidwestEventDetail,
    resetEventDetail : midwestActions.resetGetMidwestEventDetail

}

const connectModwestAddEvent = connect(mapStateToProps, actionCreators)(ModwestAddEvent)

export { connectModwestAddEvent as ModwestAddEvent }