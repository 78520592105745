import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices } from "services";
import PubSub from 'pubsub-js';
import { DraggableCreators } from "./DraggableCreators";
import { connect } from "react-redux";
import { tagActions } from "store/actions";
import { ReactPagination, toastr } from 'helpers';

class Tags extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();


        this.state = {
            tags: [],
            tag: '',
            tagsList: [],
            offSet: 0,
            recordLimit: 40,
            search: '',
            forcePage : 0

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.alltagsList && props.alltagsList.data && (props.alltagsList.statusCode == 200)) {
            props.resetallTags()
            return {
                tagsList: props.alltagsList.data && props.alltagsList.data.tags,
                pageCount: props.alltagsList.data && props.alltagsList.data.count && props.alltagsList.data.count / state.recordLimit
            }

        }
        // if(props && props.addedtags && (props.addedtags.statusCode === 201) && props.addedtags.data){
        //     props.resetAddTags()
        //     console.log(props)
        //    return {
        //         tags :[],
        //         tag : ''
        //     }
        // }


    }
    componentDidUpdate(props, state) {
        if (props && props.addedtags && (props.addedtags.statusCode === 201) && props.addedtags.data && (state.tag || (state.tags && state.tags.length > 0))) {
            props.resetAddTags()
            console.log(props)
            this.setState({
                tags: [],
                tag: '',
                offSet: 0,
                forcePage:0
            }, () => {
                this.callApi()
            })


        }


    }
    callApi = () => {
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })
        let obj = {
            offSet,
            recordLimit,

        }
        if (search) {
            obj["search"] = search
        }
        this.props.getAllTags(obj)
    }

    checkMin = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkMax = (value) => {
        if ((value && value.length) <= 50) {
            return true
        } else {
            return false
        }
    }
   checkCharacType = (value) => {
       const char = /[^a-zA-Z0-9 ]/
       if((value && char.test(value))){
           return false
       }else{
           return true
       }
   }
    handleTagsChange = (e) => {
        if(this.checkCharacType(e.target.value)){
            this.setState({ tag: e.target.value })
        }
    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    onKeyDown = (e) => {
        const { tags, tag, tagsList } = this.state

        if (e.keyCode == 188) {
            console.log(e.target.value)
            let tag = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
           
            if (tag.length > 1 && !tags.includes(tag.trim().toLowerCase())) {
                if ((this.checkMax(tag)) && (this.checkMin(tag))){
                    const found = tagsList.some(el => ((el.name).toLowerCase()) === ((tag).toLowerCase()));
                   
                 
                    // if(!found){
                        tags.push(tag.trim().toLowerCase());
                        
                        this.setState({ tags, tag: '' }, () => {
                        })
                    // }else{
                    //     this.setState({tag : ''})
                    //     toast.error("Tag already exist")
                    // }
                  
                    
                   
                }
               

            }else{
                toast.error("You have already entered the tag")
            }

        }

    }
    removeItem = (value, index) => {
        const { tags } = this.state
        let tagArray = []
        tagArray = tags.filter((tag, idx) => idx != index)
        this.setState({
            tags: tagArray
        })
        console.log(value, index, tagArray)
    }
    checkChar = (value) => {
        var charRegex =  /^[ -~]*$/
        if(charRegex.test(value)){
           
            return true
        }else{
            return false
        }
    }
    checkTags = () => {
        const { tags } = this.state
        var found;
        tags && tags.map(tag => {
            let obj = {
                search : tag
            }
    adminServices.getTagsData(obj).then(res => {
        if((res && res.statusCode) == 200){
            
                found = res.data.tags.some(el => ((el.name).toLowerCase()) === ((tag).toLowerCase()));
                return found
        }
    })
})
    }
    handleAddTag = (e) => {
        e.preventDefault();
      
        const { tags, tag } = this.state
        let obj;
        if ((tags && tags.length > 0)) {
            if(tag){
                if(this.checkChar(tag)){
                    toast.error("Please remove unwanted characters and add")
                    return false
                }
            }
             
            tags.map((items) => {
                if(!(this.checkMin(items)) || !(this.checkMax(items))){
                    toast.error("Tag must be at least 3 characters min and 50 characters max")
                    return false
                }
            })
            obj = {
                tagNames: tags
            }
        } else {
            if (tag) {
                if((this.checkMin(tag))){
                 if((this.checkMax(tag))){
                    toast.error("Press comma to create at least one tag")
                 }else{
                    toast.error("Tag should be no more than 50 characters")
                 }
                }else{
                    toast.error("Tag must be at least 3 characters")
                }
                
                return false
            } else {
                toast.error("Please create at least one tag")

                return false
            }
        }
       
  

        this.props.addTags(JSON.stringify(obj))
        
    }

    render() {
       
        const {
            tags, tag, tagsList
        } = this.state;
        return (
            <Fragment>
                <div className="container-fluid adconfg">

                    <div className="row mb-3">
                        <div className="col-sm-12">
                            <h2>Tags </h2>
                            {/* <span className="betaLink">(Still not integrated on marketplace, Available only on Super Admin)</span> */}
                        </div>
                    </div>


                    <div className="row mt-3">



                        <div className="col-md-6 ">
                            <div className="purchasebox mb-3">
                                <div className="font-weight-bold col-sm-12 ">
                                    <h3>Add Tags</h3>
                                    <p className="small warn-mes">
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Once tag created, can't be removed<br />
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Add <i><strong>comma</strong></i> to create a new tag<br />
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Tag must be at least 3 characters min and 50 characters max<br/>
                                        <i className="fas fa-info-circle"></i>&nbsp;
                                        Special characters not allowed 
                                    </p>
                                </div>
                                <div className="input-group mb-3 inputtags">
                                     {/* <span className="mb-4">Tags to be listed here</span><br/> */}
                               
                                    {tags && tags.map((items, index) => (
                                        <a className="badge badge-primary mr-1 p-2 mb-2 font-weight-normal">
                                            {items}&nbsp; <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeItem(items, index)}></i></a>)
                                    )}
                                
                                    <textarea
                                        className="form-control w-50 tagged"
                                        placeholder="Type here..."
                                        id="exist-values"
                                        name="tag"
                                        data-removeBtn="true"
                                        value={this.state.tag}
                                        onChange={this.handleTagsChange}
                                        onKeyDown={this.onKeyDown}
                                        ref={this.inputRef}
                                    >
                                    </textarea>
                                   
                                </div>
                                <div className="text-right">
                                    <button className="btn btn-primary" type="button" onClick={this.handleAddTag}>Add</button>
                                </div>
                                
                            </div>
                           

                        </div>

                        <div className="col-md-6" >
                        <div className="purchasebox mb-3">
                                <h5>Added Tags</h5>

                                <div className="comboxgray">
                                    {tagsList && tagsList.map(items => (
                                        <a className="badge bg-dark mr-1 p-2 mb-2" >{items && items.name}</a>
                                    ))}

                                </div>
                                {tagsList && tagsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                            </div>
                            </div>
                    </div>                   
                       
                    

                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        alltagsList: state.tags.tagsList,
        addedtags: state.tags.tagAdded
    }
}
const actionCreators = {
    getAllTags: tagActions.getTagsData,
    addTags: tagActions.addTagsData,
    resetallTags: tagActions.resetGetTags,
    resetAddTags: tagActions.resetAddedTags


}

const connectTags = connect(mapStateToProps, actionCreators)(Tags)
export { connectTags as Tags }
