import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {adminActions, userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import history from 'helpers/history';

class ResetAdminPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            fullName:'',
            submitted: false,
            email :'',
            password:'',
            repassword:'',
            isSend : false,
            verificationCode : '',
            publicId : ''
        }
    }
    componentDidMount(){
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.id){
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let verificationCode = urlParams.get('verificationCode')
     
            if (verificationCode) {
                this.setState({verificationCode})
            }
        }else{
            history.goBack()
        }
    }
    componentDidUpdate(props,state){
        if(this.props && 
            this.props.userDetail &&
            this.props.userDetail.statusCode === 200 &&
            (this.props.userDetail !== props.userDetail)){
                this.props.resetUserDetail()
                if(this.props.userDetail 
                    && this.props.userDetail.data 
                    && this.props.userDetail.data.admin && 
                    this.props.userDetail.data.admin.email
                    ){
                        this.props.resetPassword(this.props.userDetail.data.admin.email)
                    }
            }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props,state){
      

    }
    gotoBack = () => {
        history.goBack()
    }
    
    onPasswordChange = (e) => {
        this.setState({password : e.target.value})
    }
    onRepasswordChange = (e) => {
        this.setState({repassword : e.target.value})
    }
    handleChecked = (value) => {
        const {isSend} = this.state
        const isSended = isSend ? false : true
        this.setState({isSend : isSended})
    }
    checkPassword = (value) => {
        if((value && value.length) >= 8){
            return true
        }else{
            return false
        }
    }
    checkConfirmPassword = () => {
        const {password,repassword} = this.state
        if(password == repassword){
            return true
        }else{
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if(emailReg.test(value)){
            return true
        }else{
            return false
        }
    } 
    checkFullName = (value) => {
        if(((value && value.length) <= 100) && ((value && value.length) >= 3)){
            return true
        }else{
            return false
        }
    }
   handleSubmit = (e) => {
       e.preventDefault()
       this.setState({submitted:true})
       const {verificationCode,password,repassword} = this.state
        if(password && repassword){
                if(this.checkPassword(password)){
                    if(this.checkConfirmPassword()){
                        if(verificationCode) {
                            let obj = {
                                newPassword : password,
                                verificationCode
                            }
                            this.props.changePassword()
                        }else{
                            history.goBack()
                        }
                    }else{
                        toast.error("Password did not match")
                    }
                }else{
                    toast.error("Password must be atleast 8 characters")
                }
           
        }else{
            toast.error("Please enter new password")
        }
   }
    render(){
        const {account} = this.state
        return(
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-5 admintitle">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">Reset Password <span className="betaLink">(Soon)</span></h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}>Back</button>  </div>
                        
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="password" className="font-weight-bold" >New Password *</label>
                                <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Enter new password" value={this.state.password} onChange={this.onPasswordChange} />
                                {this.state.submitted && !this.state.password && <div className="mandatory">{"Password required"}</div>}
                                {!this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory-req">{"Must be contain atleast 8 characters"}</div>}
                                {this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory">Password must be contain atleast 8 characters</div>}                                        

                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="repassword" className="font-weight-bold">Re - Enter Password *</label>
                                <input type="password" className="form-control" id="repassword" aria-describedby="repassword" placeholder="Re-enter password" value={this.state.repassword} onChange={this.onRepasswordChange} disabled={!(this.checkPassword(this.state.password))} />
                                {this.state.submitted && !this.state.repassword && <div className="mandatory">{"Re-enter password required"}</div>}
                                {this.state.submitted && this.state.repassword && (this.state.repassword != this.state.password)  && <div className="mandatory">{"Password did not match"}</div>}
                                {!this.state.submitted && this.state.repassword && (this.state.repassword != this.state.password)  && <div className="mandatory-req">{"Password did not match"}</div>}
                            </div>
                     
                            <div className="form-group mb-4 col-md-6 text-center offset-md-3">
                                <button type="submit" className="btn btn-primary w-50">Submit</button>
                            </div>
                                                                                          
                        </div>
                    </form>
                </div>
             
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        changePasswordResponse : state.adminaccount.changePasswordRes,
    }
}
const actionCreator = {
    
    changePassword : adminActions.adminChangePassword,
    resetChangePassword : adminActions.resetAdminChangePassword,
    
}
const connectResetAdminPassword = connect(mapStateToProps,actionCreator)(ResetAdminPassword)
export {connectResetAdminPassword as ResetAdminPassword}