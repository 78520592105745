import { eventConstants } from "../../constants"
import { adminServices } from "services"
import PubSub from 'pubsub-js';


export const eventActions = {
   getEventHistory
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
} 

function getEventHistory(obj){
    return dispatch => {
        showLoader()
        adminServices.getEventHistory(obj)
        .then(events => {
            if((events && events.statusCode) === 200){
                
                dispatch({type:eventConstants.GET_EVENT_HISTORY,eventHistory:events})
                hideLoader()
            }
        },error => {
            
            // console.log("error",error)
            hideLoader()
        })
    }
}






