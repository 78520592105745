
import React, { createRef } from 'react'
import { Component } from 'react';
import {
    GoogleMap,
    Marker,
    DirectionsService,
    StandaloneSearchBox,
    DirectionsRenderer,
    Circle,
    Polyline

} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { configPath } from '../../config';

const containerStyle = {
    width: '500px',
    height: '500px'
};


class AirdropMapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 15,
            markerPositions: { lat: -34.397, lng: 150.644 },
            selectedPlace: '',
            travelMode: {},
            destination: { lat: 9.93128857, lng: 78.09714966 },
            directionResponse: null,
            isLoaded: false,
            bounds: {},
            path: [],
            routeOptions: {}

        }
        this.mapRef = createRef()
        this.circleRef = createRef()
        this.circleRef2 = createRef()
        this.autocomplete = null
        this.handleLoad = this.handleMapLoad.bind(this); //<- this line is essentially "useCallback" for a class component
        this.directionsCallbackFunc = this.directionsCallback.bind(this)
        this.handleCircleBoundsChangedFunc = this.handleCircleBoundsChanged.bind(this)
    }

    componentDidMount() {

        if (!this.state.isLoaded) {
            this.loadMapsLibrary();
        }
        if (this.props && this.props.currentLocation && this.props.destlocation) {
            const lat = Number(this.props.destlocation.locationCoordinates.latitude)
            const lng = Number(this.props.destlocation.locationCoordinates.longitude)
            const circleRadius = Number(this.props.destlocation.radius)
            this.setState({
                destination: { lat, lng },
                center: { lat, lng },
                circleRadius: circleRadius,
                markerPositions: { lat, lng },
                markerPosition2: this.props.currentLocation,
                origin: this.props.currentLocation
            })
        }
    }
    componentDidUpdate() {

    }
    handleClick = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        // Set the marker position state
        this.setState({ markerPositions: { lat, lng }, center: { lat, lng } }, () => {
            this.getBoundariesCenterRadius()
        })
        // Use Geocode to get the location details
        Geocode.fromLatLng(lat, lng, process.env.REACT_APP_GOOGLE_MAP_API_KEY).then(
            (response) => {
                const address = response.results[0].formatted_address;
            },
            (error) => {
            }
        );
    };

    onPlaceSelected = () => {
        const places = this.autocomplete.getPlaces()
        if (places.length > 0) {
            const place = places[0];
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            this.setState({ zoom: 6, selectedPlace: place })
            this.setState({ markerPositions: { lat, lng }, center: { lat, lng } }, () => {
                this.getBoundariesCenterRadius()
            })
        }

    };

    onLoad = (autocomplete) => {

        this.autocomplete = autocomplete
    }
    handleMapLoad(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(this.state.center);
        // map.fitBounds(bounds);

    };


    clearMarkers = () => {
        this.setState({ markerPositions: {} })
    };
    directionsCallback(directionResponse) {
        // console.log(directionResponse)

        // if (directionResponse !== null) {
        //     if (directionResponse.status === 'OK') {
        //         this.setState(
        //             () => ({
        //                 directionResponse
        //             })
        //         )
        //     } else {
        //         console.log('response: ', directionResponse)
        //     }
        // }
    }
    loadMapsLibrary() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=drawing,places`;
            script.onload = () => {
                const { google } = window;
                this.setState({ isLoaded: true });
                this.getBoundariesCenterRadius()
                this.calculateDirections()
                resolve(google);
            };
            script.onerror = () => {
                reject(new Error('Failed to load Google Maps library.'));
            };
            document.head.appendChild(script);
        });

    }

    getBoundariesCenterRadius = () => {

        let obj = {
            center: this.state.center,
            radius: this.state.circleRadius
        }
        // this.props.fetchMapCoordinates(obj)

    }

    handleCircleBoundsChanged(circle) {
        console.log(circle)
    };
    handleRadiusChanged = (event) => {
        const newBounds = this.circleRef.current;
        if (newBounds) {
            this.setState({
                bounds: newBounds.state.circle.getBounds()?.toJSON(),
                circleRadius: newBounds.state.circle.getRadius()
            }, () => {
                this.getBoundariesCenterRadius()
            })
        }

    }
    onPolyLoad = polyline => {
        console.log('polyline: ', polyline)
    };

    calculateDirections = () => {
        // Define the request parameters
        const request = {
            origin: this.state.origin,
            destination: this.state.destination,
            travelMode: window.google.maps.TravelMode.DRIVING
        };

        // Create a DirectionsService instance
        const directionsService = new window.google.maps.DirectionsService();

        // Send the request to the DirectionsService
        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                // Set the directions in the state
                console.log(result)
                const path = result.routes[0].overview_path
                // Decode the path positions using the Google Maps Polyline Utility
                const positions = path.map(point => ({
                    lat: point.lat(),
                    lng: point.lng()
                }));
                console.log(positions)
                const options = {
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    radius: 30000,
                    paths: positions,
                    zIndex: 1
                }
                this.setState({ directionResponse: result, path: positions, routeOptions: options })
            } else {
                // Handle the error
                console.error('Directions request failed due to ' + status);
            }
        });
    };

    render() {
        const { isLoaded } = this.state;
        const directionsOptions = {
            directions: this.state.directionResponse,
        };

        return (
            <div className='GoogleMap'>
                {isLoaded ?

                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        // onClick={this.handleClick}
                        onLoad={this.handleLoad}

                    >
                        <Marker title="Destination" position={this.state.markerPositions} />



                        <Circle
                            ref={this.circleRef}
                            center={this.state.center}
                            radius={this.state.circleRadius}
                            options={{
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: '#FF0000',
                                fillOpacity: 0.35,
                            }}
                            editable={false}
                            draggable={undefined}
                        // onCenterChanged={this.handleCircleBoundsChangedFunc}
                        // onRadiusChanged={this.handleRadiusChanged}
                        />
                        <Marker title="You" position={this.state.markerPosition2} icon={{
                            // path: window.google.maps.SymbolPath.CIRCLE,
                            url: `../${configPath.imageAssets}/location.png`,
                            strokeColor: "#9E66BF",
                            scale: 7
                        }} />
                        <Polyline
                            onLoad={this.onPolyLoad}
                            path={this.state.path}
                            options={this.state.routeOptions}
                        />

                        {/* {
                            (
                                this.state.destination !== undefined &&
                                this.state.origin !== undefined &&
                                this.state.destination !== null &&
                                this.state.origin !== null
                            ) && (
                                <DirectionsService
                                    // required

                                    options={{
                                        destination: 'New York, NY',
                                        origin: 'Los Angeles, CA',
                                        travelMode: window.google.maps.TravelMode.DRIVING
                                    }}
                                    // required
                                    callback={this.directionsCallbackFunc}
                                    // optional
                                    onLoad={directionsService => {
                                        console.log('DirectionsService onLoad directionsService: ', directionsService)
                                    }}
                                    // optional
                                    onUnmount={directionsService => {
                                        console.log('DirectionsService onUnmount directionsService: ', directionsService)
                                    }}
                                />
                            )
                        } */}

                        {/* {
                            this.state.directionResponse && (
                                <DirectionsRenderer
                                    // required
                                    options={directionsOptions}
                                    // optional
                                    onLoad={directionsRenderer => {
                                        console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                                    }}
                                    // optional
                                    onUnmount={directionsRenderer => {
                                        console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                                    }}
                                />
                            )
                        } */}

                    </GoogleMap>

                    : ''}
            </div>
        )

    }
}

export { AirdropMapComponent }