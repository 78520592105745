
export function checkTokenGroup(items,totalTokens){
    if(isNaN(items && items.tokenGroupId )){
        if(items && items.tokenGroupId && (items.tokenGroupId).includes("-")){
            return 1
        }
    }
        else if (totalTokens && Number.isInteger(totalTokens)){
            return items.artsCount
        }
        else{
            return 1
        }
    
}

export function checkMintedTokens(items,count){
    if(isNaN(items && items.tokenGroupId )){
    if(items && items.tokenGroupId && (items.tokenGroupId.includes("-"))){
        return 1
    }
}
    else if((count) && Number.isInteger(count)){
        return count
    }else{
        return 1
    }
}


export function checkMintedTokensWithTotalEdition(items,count){
    if(isNaN(items && items.tokenGroupId )){
    if(items && items.tokenGroupId && (items.tokenGroupId.includes("-"))){
        return "1 Total Edition"
    }
}
    else if((count) && Number.isInteger(count)){
        return count + " Total Editions"
    }else{
        return "1 Total Edition"
    }
}
export function checkMintedTokensWithEdition(items,count){
    if(isNaN(items && items.tokenGroupId )){
    if(items && items.tokenGroupId && (items.tokenGroupId.includes("-"))){
        return "1 Edition"
    }
}
    else if((count) && Number.isInteger(count)){
        return count + " Editions"
    }else{
        return "1 Edition"
    }
}

