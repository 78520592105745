import { configPath } from 'config'
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { Helmet } from "react-helmet";

class COB extends Component {
	render() {
		return (
			<Fragment>
				{/* <Helmet>
                    <title>Blockchain for the culture</title>
                    <meta name="title" content="Explore Blockchain for the culture"></meta>
                    <meta name="description" content={`Explore Blockchain for the culture ${process.env.REACT_APP_SITE_CONTRACT} NFT marketplace`}></meta>
                </Helmet> */}
				<div className="container">
					<div className=" cobmain">
						<div className='pagetitabtn text-center py-5'>
							<h1 className='text-uppercase '>Blockchain
								<span>for the culture</span></h1>
						</div>
						<div className="row mb-5">
							<div className="col-12 text-center text-uppercase">
								<a href="https://www.eventbrite.com/e/blockchain-for-the-culture-tickets-348990077717?aff=erellivmlt" target={'_blank'} className="btn btn-primary px-5 text-uppercase">
									<h5>Register</h5>
									<h2 className='mb-0'>Now!</h2>
								</a>
							</div>
						</div>
						<div className="row align-items-center">
							<div className="col-sm-5 text-center">
								<img src={`/${configPath.imageAssets}/COB-logo.png`} alt="" className="mw-100" />
							</div>
							<div className="col-sm-2 text-center">
								<i aria-hidden="true" className="fa fa-times cobcross"></i>
							</div>
							<div className="col-sm-5 text-center">
								<img src={`/${configPath.imageAssets}/els-logo.png`} alt="" className="mw-100" />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-12 py-4 text-center">
							<h5 >ENGAGEMENT PARTNER</h5>
							</div>
						</div>
						

						<div className="row cob-venue">
							<div className="col-md-12 text-center text-uppercase">
								<h3>Located at hollywood live</h3>
							</div>
							<div className="col-md-3 text-right text-uppercase">

								<h6>starts at 8pm et</h6>
							</div>
							<div className="col-md-3 text-center text-uppercase">
								<h6>june 9 <br /> 2022</h6>
							</div>
							<div className="col-md-6 text-left text-uppercase">
								<h6>2333 hollywood blvd hollywood, fl 33020</h6>
							</div>
						</div>
						<h4 className='p-3 text-center'>Create a Disrupt Art Wallet for a chance to win free Flow (crypto) and NFTs</h4>
						<div className="row mb-3">
							<div className="col-md-8 offset-md-2 text-center text-uppercase">
								<h4 className='p-3 text-center d-flex justify-content-between'>
									<a href='https://disrupt.art'><i className='fa fa-globe'></i> disrupt.art</a>
									<a href='https://twitter.com/DisruptArt' target={'_blank'}><i className='fa fa-twitter'></i> @disruptart</a>
									<a href='https://www.instagram.com/disrupt.art/' target={'_blank'}><i className='fa fa-instagram'></i> @disrupt.art</a>
								</h4>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export { COB }