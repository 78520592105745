import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import { artActions, dropActions, userAction } from 'store/actions';
import { configPath } from "../../config";
import './Creatorstore.css'
import { tokensArrayEclipse, tokensArrayLapis, tokensArrayMagic, tokensArraySequim, tokensArraySkaliche, tokensArrayTahlequa } from './TokensArray';
import PubSub from 'pubsub-js';
import { flowMoveToRecipientAccount } from 'utils/moveTokens';
import { TouchBallLoading	 } from 'react-loadingg';

class MoveTokens extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStoreStatus: false,
			inProgress: false,
			totalTokensToBeMoved: 0,
			isErrorPoped: false,
			isSuccess: false,
			errorMessage: '',
			tokensProcessed: 0,
			listingProgress: 0,
			listingAbortError: '',
			btnTitle : ''
		}
		this.batchSize = 200

	}
	gotoTop = () => {
		window.scrollTo({
			top: 0,
		});
	}
	componentDidMount() {
		this.gotoTop()
	}

	splitArray(array, chunckCount) {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunckCount) {
			chunks.push(array.slice(i, i + chunckCount))
		}
		return chunks;
	}
	showLoader() {
		PubSub.publish('msg', true);
	}

	hideLoader() {
		PubSub.publish('msg', false);
	}

	handleToMoveTokens = async (tokens,title) => {
		this.setState({btnTitle : title})
		// tokens available
		this.showLoader()
		if (tokens && tokens.length > 0) {
			const batchCount = Math.ceil(tokens.length / this.batchSize); // total batch count 
			const itemsPerBatch = Math.floor(tokens.length / batchCount);
			const batches = this.splitArray(tokens, this.batchSize);
			console.log(batches)
			let count = 0
			if (batches.length > 1) {
				this.setState({
					inProgress: true,
					totalTokensToBeMoved: tokens.length,
					disableList: true
				});
				this.hideLoader()
			} else {
				this.setState({
					inProgress: false,
					disableList: true
				});
			}
			let listedTokensCount = this.state.tokensProcessed;
			let isAborted = false;

			for (const batch of batches) {
				try {
					const tokenResponse = await flowMoveToRecipientAccount(batch, (process.env.REACT_APP_MOVE_NFT_RECIPIENT));
					this.setState({ isTransLoader: false })
					if ((tokenResponse && tokenResponse.status) === 200) {

						if (tokenResponse && tokenResponse.data && tokenResponse.data.transaction && tokenResponse.data.transaction.errorMessage) {
							isAborted = true;
							this.setState({
								errorMessage: String(tokenResponse.data.transaction.errorMessage),
								disableList: false,
								inProgress: false,
								isErrorPoped: true,
								tokensProcessed: 0,
								listingProgress: 0,
								totalTokensToBeMoved: 0
							});
							this.hideLoader();

							console.error('Transaction Failed', tokenResponse.data.transaction.errorMessage);
							break;
						} else {
							count++;
							listedTokensCount += batch.length;

							const progressPercent = Math.round((count / batches.length) * 100);

							this.setState({
								listingProgress: progressPercent,
								tokensProcessed: listedTokensCount
							})
							console.log(`%cmoved to market (${count}/${batches.length})`, 'font-size:16px', tokenResponse)
						}
					} else {
						console.error('Transaction Failed', tokenResponse.data);

						if (tokenResponse && tokenResponse.data && tokenResponse.data.Error) {
							this.hideLoader();

							this.setState({
								errorMessage: String(tokenResponse.data.Error),
								isErrorPoped: true,
								inProgress: false,
								tokensProcessed: 0,
								listingProgress: 0,
								totalTokensToBeMoved: 0,
								disableList: false
							})
							isAborted = true;
							break;
						}
					}
				} catch (e) {
					console.error('Transaction Failed', e);

					this.setState({
						isErrorPoped: true,
						inProgress: false,
						tokensProcessed: 0,
						listingProgress: 0,
						totalTokensToBeMoved: 0,
						disableList: false
					})
					this.hideLoader();

					if (e && e.data && e.data.Error) {
						this.setState({
							errorMessage: String(e.data.Error),
						})
						isAborted = true;
						break;
					} else if (e && e.data && e.data.message) {
						this.setState({
							errorMessage: String(e.data.message),
						})
						isAborted = true;
						break;
					} else if (e && e.data) {
						this.setState({
							errorMessage: String(e.data),
						})
						isAborted = true;
						break;
					} else {
						this.setState({
							errorMessage: String(e),
						})
						isAborted = true;
						break;
					}

				}
			}
			if (count) {
				this.setState({
					isSuccess: true,
					isErrorPoped: false,
					disableList: false,
					inProgress: false,
					tokensProcessed: 0,
					listingProgress: 0,
					totalTokensToBeMoved: 0,
					isListingAborted: isAborted,
					tokensListedBeforeAbort: listedTokensCount,
					listingAbortError: this.state.errorMessage
				});
				this.hideLoader();
			}
		} else {
			toast.error('Tokens are unavailable', { transition: Zoom })
		}

	}
	resetPage = () => {
		this.setState({ isSuccess: false })
	}
	componentWillUnmount() {
		document.body.classList.remove('body-color')
	}
	render() {
		const { isStoreStatus } = this.state
		return (
			<Fragment>

				<Helmet>
					<title>{"Move NFT"}</title>
					<meta name="title" content={`Move NFT`}></meta>
					<meta name="description" content={`Move NFT`}></meta>
				</Helmet>
				{this.state.isErrorPoped && !this.state.isSuccess &&
					<div className="show-cancel">
						<div className="success-pop">
							<svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
								<path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
								<path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
							</svg>
							<div className="py-3">{this.state.errorMessage}</div>

							<div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false }) }}>Close</button></div></div></div>
				}

				{this.state.inProgress &&
					<div className="show-success">
						
						<div className="success-pop">
							<h2>{this.state.btnTitle}</h2>
							<span className='text-center'>Transferring NFTs...</span>
							<div className="sale-progress">
								
								<div className="progress mb-1 move-progress">
						
									<div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${this.state.listingProgress}%` }}></div>
								</div>
								<div className='mb-5 text-small'>
									<span className="usernamelilac">{this.state.tokensProcessed}/{this.state.totalTokensToBeMoved}</span> Editions moved
								</div>
								
								<b className='mb-2'>Please wait... Don't refresh the page</b>

								<h4> You will be asked to approve the transaction for each batch</h4>
							</div>
						</div>
					</div>
				}
				{this.state.isSuccess &&
					<div className="show-success">
						<div className="success-pop">
							{
								this.state.isListingAborted === false && <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
									<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
								</svg>
							}
							{
								this.state.isListingAborted === true &&
								<i className="fas fa-exclamation-triangle fa-5x text-warning"></i>
							}
							{
								this.state.isListingAborted
									? <div className="mt-3">
										<p>
											<b>Transaction aborted</b>
										</p>
										<p>
											Transaction has been aborted because {this.state.listingAbortError !== ''
												? <span>{this.state.listingAbortError}</span>
												: <span>of an error</span>
											}. {this.state.tokensListedBeforeAbort} editions have been moved.
										</p>
									</div>
									: <div className="mt-3">
										<b>Great! Successfully transfer your tokens</b>
									</div>
							}

							<button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>Close</button></div></div>

				}
				<div className='ds-mylist'>
					<div className='container-fluid'>
						<div className="ds-intit row">
							<div className="col-md-6"><h1 className="mb-0">Transfer NFTs</h1></div>

						</div>
						<div className='row'>
							<div className='col-sm-3 mt-2'>
								<p>1 Magic L122</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArrayMagic,'1 Magic L122')} disabled={this.state.disableList}>Move Nfts</button>
							</div>
							<div className='col-sm-3 mt-2'>
								<p>2 Sequim K12	</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArraySequim,'2 Sequim K12')} disabled={this.state.disableList}>Move Nfts</button>
							</div>
							<div className='col-sm-3 mt-2 '>
								<p>3 Lapis L103 Lazuli L123</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArrayLapis,'3 Lapis L103 Lazuli L123')} disabled={this.state.disableList}>Move Nfts</button>
							</div>
							<div className='col-sm-3 mt-2'>
								<p>4 Eclipse J41</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArrayEclipse,'4 Eclipse J41')} disabled={this.state.disableList}>Move Nfts</button>
							</div>
							<div className='col-sm-3 mt-2'>
								<p>Tahlequa J35 Phoenix J57</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArrayTahlequa,'Tahlequa J35 Phoenix J57')} disabled={this.state.disableList}>Move Nfts</button>
							</div>
							<div className='col-sm-3 mt-2'>
								<p>SkaliChelh-Tenaut</p>
								<button type="button" className='btn btn-primary' onClick={() => this.handleToMoveTokens(tokensArraySkaliche,'SkaliChelh-Tenaut')} disabled={this.state.disableList}>Move Nfts</button>
							</div>


						</div>

					</div>
				</div>

			</Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		usersList: state.user.usersList,
	}
}
const actionCreators = {
	getUserProfile: userAction.getUsers,
	getUserArts: artActions.getUserArts,
	getUserDrop: dropActions.getUserDrop,
	resetAllArts: artActions.resetAllArts,
	resetAllDrops: dropActions.resetAllDrops,
}
const connectMoveTokens = connect(mapStateToProps, actionCreators)(MoveTokens)
export { connectMoveTokens as MoveTokens }