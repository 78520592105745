import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from '../../../store/actions'
import history from 'helpers/history';
import { configPath } from 'config';
import { toast } from 'react-toastify';
import { userServices } from 'services';

class Login extends Component{
    constructor(props){
        super(props);
        this.props.logout()
        this.state = {
            uname:'',
            password : '',
            submitted: '',
            email : '',
            forgot:false,
            resetPassword:'',
            resubmitted:false,
            isDisable : true
        }
    }
    static getDerivedStateFromProps(props,state){
        if(props && props.isAdmin){
            history.push('/admin/dashboard')
        }
        // else{
        //     history.push('/admin/login')
        // }
        // if((props && props.admin && props.admin.statusCode) == 200){
        //     history.push('/admin/dashboard')
        // }
    }
    handleChange = (e) => {
        const{uname,password} = this.state
        if(password){
            this.setState({isDisable : false})
        }
        this.setState({uname:e.target.value},() => {
            if(((e.target.value) && password) && this.checkemail(e.target.value)){
                this.setState({isDisable : false})
    
            }else {
                this.setState({isDisable : true})
 
            }
        })
    }
    handlePasswordChange = (e) => {
        const{uname,password} = this.state
        if(uname){
            this.setState({isDisable : false})
        }
        this.setState({password : e.target.value},() => {
          
            if(((e.target.value) && uname && this.checkemail(uname))){
                this.setState({isDisable : false})
    
            }else {
                this.setState({isDisable : true})
 
            }
        })
    }

    handleForgot = () => {
        // let obj = {
        //     adminId : 1
        // }
        // userServices.forgotPassword(JSON.stringify(obj),1).then(res => {
        //     if((res && res.statusCde) == 200){
        //         toast.success(res && res.message)
        //      console.log("admin",res)
        //     }
        // })
        this.setState({forgot:true,submitted:false})
    }

    handleReset = (e) => {
        e.preventDefault()
        this.setState({resubmitted:true})
        const {email} = this.state
    }

    isNumber = (val) => {
		if(!isNaN(val)){
			return true
		}else{
			return false
		}
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if(emailReg.test(value)){
            return true
        }else{
            return false
        }
    } 

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({submitted:true})
        const {uname,password} = this.state
        this.setState({isDisable : true})
        if(uname && password){
            let obj = {
                email:uname,
                password
            }
            // if(this.isNumber(this.state.uname)){
            //     obj['mobile'] = uname
            // }else{
            //     obj['email'] = uname
            // }
            this.props.login(JSON.stringify(obj))
        }else{
            toast.error("Mandatory fields are missing")
        }
    }
    render(){
        return(
            <Fragment>
                {!this.state.forgot && <div className="wrapper">
                    <div className="login">
                        <div className="login-form">
                            <a href="/admin/login"><img src={`/${configPath.imageAssets}/DA-new-logo.png`} alt="" className="authogo"/></a>
                            <h3 className="login-title">Log in</h3>
                            <div className="form-wrapper">
                                <div className="input-wrapper">
                                    <form onSubmit={this.handleSubmit} className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="label">Email<span className="mandatory">*</span></label>
                                                <input type="text" name="uname" value={this.state.uname} onChange={this.handleChange} className="input form-control" placeholder="Enter Email or Username*" />
                                                {this.state.submitted && !this.state.uname && <div className="mandatory">{"Email/Username is Required!"}</div>}
                                                {!this.state.submitted && this.state.uname && !this.checkemail(this.state.uname) &&  <div className="mandatory">{"Email should be an email"}</div>}
                                                {this.state.submitted && this.state.uname && !this.checkemail(this.state.uname) &&  <div className="mandatory">{"Email should be an email"}</div>}
                                                {!this.state.submitted && !this.state.uname && <div className="mandatory-req">{"Required!"}</div>}

                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="label">Password<span className="mandatory">*</span></label>
                                                <input type="password" className="input form-control" name="password" value={this.state.password} placeholder="Enter Password*" onChange={this.handlePasswordChange}/>
                                                {this.state.submitted && !this.state.password && <div className="mandatory">{"Password is Required!"}</div>}
                                                {!this.state.submitted && !this.state.password && <div className="mandatory-req">{"Required!"}</div>}

                                            </div>
                                        </div>

                                        {/* <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                        <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                        </div>
                                        </div> */}

                                        <div className="actions col-sm-12 mt-3">
                                            <button type="submit" className="action btn btn-primary" disabled={this.state.isDisable}>Sign in</button>
                                        </div>

                                        {/* <p className="forgot-password text-right">
                                       <a onClick={this.handleForgot}> Forgot password?</a>
                                        </p> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {this.state.forgot && <div className="wrapper">
                    <div className="login">
                        <div className="login-form">
                            <a href="/admin/login"><img src={`/${configPath.imageAssets}/logoaw.png`} alt="" className="authogo"/></a>
                            <h3 className="login-title">Forgot Password</h3>
                            <div className="form-wrapper">
                                <div className="input-wrapper">
                                    <form className="row">

                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="label">Email</label>
                                                <input type="email" className="input form-control" name="email" value={this.state.email} placeholder="Enter Email*" onChange={this.handleChange}/>
                                                {this.state.resubmitted && !this.state.email && <div className="mandatory">{"Email is Required!"}</div>}

                                            </div>
                                        </div>
                                        <div className="actions col-sm-6 mt-3">
                                            <button type="button" className="action btn btn-primary" onClick={(e) => {this.setState({forgot:false,resubmitted:false})}}>Back</button>
                                        </div>
                                        <div className="actions col-sm-6 mt-3">
                                            <button type="button" className="action btn btn-primary" onClick={this.handleReset}>Send</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isAdmin : state.user.isAdmin,
        admin : state.user.admin
    }
}
const actionCreators = {
    login:userAction.adminLogin,
    logout:userAction.adminLogout
}
const connectedLogin = connect(mapStateToProps,actionCreators)(Login)
export {connectedLogin as Login}