import { configPath } from "../../config";
import React from "react";

export class AudioPreivew extends React.Component {
   
  
    render() {
        return (
            <>
            <div className="audio-art mb-0">
                <img src={`/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} className={this.props.className} alt="" />

                <audio controls={this.props.showControls} loop={this.props.loop} >
                    <source src={this.props.source} type={this.props.mimeType} />
                </audio>

            </div>
            
         {/* <div className="App">
                <div id="mp3_player">
                <canvas
                            ref={el => this.el = el}
                            id="analyzer"
                            >
                            </canvas>
                    <div id="audio_box">
                        <audio
                            ref={audio => this.audio = audio}
                            controls={this.props.showControls} loop={this.props.loop}
                            
                            >
                              <source src={this.props.source} type={this.props.mimeType} />
                            </audio>
                        </div>
                        
                        </div>
                    </div> */}
          </>
        )
    }
}





  

 

