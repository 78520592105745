import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

class MidWestCon extends React.Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                <title>Mid-west con</title>
                    <meta name="title" content="Mid-west con"></meta>
                    <meta name="description" content={`Mid-west con`}></meta>
                </Helmet>
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-sm-12">
                        <h1 className="mb-3 usernamelilac text-uppercase">Midwest Con</h1>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}
const actionMWC = {


}

const connectMWC = connect(mapStateToProps, actionMWC)(MidWestCon)
export { connectMWC as MidWestCon }
