import { userServices } from "services";
import * as fcl from "@onflow/fcl";
import { switchAuthBlocto } from "./switchauthdapper";

export async function getFlowUsdValue(){
     try{
         const value = await userServices.getUSD()
         if(value && value.statusCode === 200){
            const rate = value && value.data && value.data.rate
            return rate
         }else{
             return false
         }
     }catch(e){
         return false
     }
    
}

export async function connectBlocto(){
    try {
        const res = await fcl.logIn()
        let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
        if (res && res.addr) {
            let obj = {
                walletAddress: res && res.addr,
                walletProvider: provider
            }
            return obj
        }else{
            return Promise.reject("Something went wrong.Couldn't connect your wallet")
        }
    }catch(err){
        if((err && err.toLowerCase().includes('declined'))){
            return Promise.reject('User rejected signature')
        }
        return Promise.reject("Something went wrong.Couldn't connect your wallet")
    }
}
export async function connectDapper(){
    try {
        const res = await fcl.logIn()
        let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
        if (res && res.addr) {
            let obj = {
                walletAddress: res && res.addr,
                walletProvider: provider
            }
            await switchAuthBlocto()

            return obj
        }else{
            await switchAuthBlocto()

            return Promise.reject("Something went wrong.Couldn't connect your wallet")
        }
    }catch(err){
        await switchAuthBlocto()

        if((err && err.toLowerCase().includes('declined'))){
            return Promise.reject('User rejected signature')
        }
        return Promise.reject("Something went wrong.Couldn't connect your wallet")
    }
}