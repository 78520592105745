import { announcementConstants } from "../../constants";

const initialState = {
    a_message: [],
    mesAdded: [],
    c_message: [],
    getGlitEve: [],
    addGlitEve: [],
    eveDel: [],
    selectedEvent: [],
    updateEvent: [],
    glitterText: [],
    announcementHistory: [],
    deletedBanner: []
}
export function announcement(state = initialState, action) {
    switch (action.type) {
        case announcementConstants.GET_ANNOUNCEMENT:
            return {
                ...state,
                a_message: action.a_message,
            }
        case announcementConstants.ADD_ANNOUNCEMENT:
            return {
                mesAdded: action.mesAdded
            }
        case announcementConstants.GET_COMMON_ANNOUNCEMENT:
            return {
                ...state,
                c_message: action.c_message
            }
        case announcementConstants.GET_GLITTER_EVENTS:
            return {
                getGlitEve: action.getGlitEve
            }
        case announcementConstants.ADD_GLITTER_EVENTS:
            return {
                addGlitEve: action.addGlitEve
            }
        case announcementConstants.DELETE_GLITTER_EVENTS:
            return {
                eveDel: action.eveDel
            }
        case announcementConstants.GET_ONE_GLITTER_EVENT:
            return {
                selectedEvent: action.selectedEvent
            }
        case announcementConstants.UPDATE_GLITTER_EVENT:
            return {
                updateEvent: action.updateEvent
            }
        case announcementConstants.GET_GLITTER_TEXT:
            return {
                ...state,
                glitterText: action.glitterText
            }
        case announcementConstants.GET_ANNOUNCEMENT_HISTORY:
            return {
                ...state,
                announcementHistory: action.announcementHistory
            }
        case announcementConstants.DELETE_ANNOUNCEMENT:
            return {
                ...state,
                deletedBanner: action.deletedBanner
            }
        default:
            return state
    }
}