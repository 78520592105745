import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { GetCreatorRoyality, GetMarketFee, CalculateShare, getUserAddress, dapperTransferTokens } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { connect } from 'react-redux';
import { artActions, creatorStoreActions, txnActions, userAction, walletActions } from 'store/actions';
import { artServices, dropServices, fetchService, userServices, walletServices } from 'services';
import { getCurrentUserId, isConnectWallet, localAirdropPage, localArtPage, localDropPage, MaintenancePopup } from 'helpers';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { configPath } from '../../config'
import moment from 'moment'
import { Helmet } from 'react-helmet';
import 'react-datetime/css/react-datetime.css';
import { connectBlocto, getFlowUsdValue } from 'helpers/getFlowUsd';
import { ArtViewPreview } from 'components/art-preview/artview-preview';
import { toast, Zoom } from 'react-toastify';
import { flowMoveToAirDrop } from 'utils/flow-air-drop';
import { SwitchAccount } from 'components/switchaccountPopup';
import { CommonWalletConnectUI } from 'helpers/commonWalletConnectUI'
import { isDapperConnected } from 'helpers/switchauthdapper';

var timeout;
var dataURL = {}
class StoreListingArts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            price: 350,
            basePrice: Number(350).toFixed(8),
            usd: '',
            isSuccess: false,
            message: '',
            isAlert: false,
            isVisible: false,
            isShare: false,
            count: 1,
            submitted: false,
            ShowPop: false,
            isList: false,
            totalOwnerArt: 0,
            resale_royalty: '',
            market_Fee: '',
            crt_share: '',
            tabIndex: 0,
            disableList: false,
            isTransLoader: false,
            startPrice: 350,
            minBid: 1,
            minimumBid: 1,
            startingPrice: 350,
            isSuccessAuction: false,
            isImage: false,
            listingInProgress: false,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            tokensProcessed: 0,
            isListingAborted: false,
            tokensListedBeforeAbort: 0,
            listingAbortError: '',
            isMaintenance: false,
            onstart: moment(),
            isAgree: true,
            isAirDrop: false,
            isSelectedMembers: false,
            selectedMembers: [],
            member: '',
            isImported: false,

            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isThumbnailUpdateSuccess: false, // if thumbnail upload success,
            isCategoryUpdateSuccess: false, // if category update success
            isTagUpdateSuccess: false,
            isErrorPoped : false,
            errorMessage : '',
            selectedTokenId : '',
            loaderMes : '',
            txnId : '',
            isSwitchAcc : false

        }
        this.maxAllowedListLimit = 6
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.initialFunc()
    }
    initialFunc = () => {
        if(isConnectWallet()){
            this.getRoyalty()
            this.calculateSharings(this.state.basePrice)
            this.getArtByToken()
        }else{
            this.connectMyWallet()
        }
    }
    getArtByToken = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.showLoader()
            artServices.getArtByToken(id).then(art => {
                this.hideLoader()


                if ((art && art.statusCode) === 200) {
                    this.gotoTop()
                    userServices.getUsers().then(res => {
                        if ((res && res.statusCode) === 200) {
                            if ((res && res.data && res.data.id) === ((art && art.data && art.data.owner && art.data.owner.id))) {

                                let dataVal = art && art.data
                                let dropStr = dataVal && dataVal.dropsCount
                                let dropArr = '[' + dropStr + ']'
                                let dropsCountarray = eval(dropArr && dropArr)
                                let totalTokenId = dataVal && dataVal.totalTokenId
                                let arr = '[' + totalTokenId + ']'
                                let groupIds = eval(arr && arr)
                                // console.log(groupIds)
                                let totalOwnerArt = dataVal && dataVal.totalOwnerArt
                                dropsCountarray && dropsCountarray.map((items) => {
                                    if (items) {
                                        groupIds = groupIds.filter((el) => !(items && items.tokens).includes(el))

                                    }
                                })
                                this.fetchArt(art && art.data)

                                this.setState({ data: art && art.data ? art.data : [], artOwner: art && art.data && art.data.owner && art.data.owner.userName, artCreator: art && art.data && art.data.creator && art.data.creator.userName, groupIds, totalOwnerArt })
                            } else {
                                // history.push('/notFound')
                                let dataVal = art && art.data
                                history.push(`/art/${dataVal && dataVal.id}`)
                            }
                        }
                    }).catch(
                        err => {
                            console.log(err)
                            PubSub.publish('msg', false)
                        }
                    )
                } else {
                    console.log(art)
                    if (art && art.statusCode === 400) {
                        if (art && art.message && art.message.toLowerCase().includes("has been hidden by the owner")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been hidden by the art owner"
                            })
                        } else if (art && art.message && art.message.toLowerCase().includes("has been disabled by the administrator")) {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "This art has been disabled by the administrator"
                            })
                        } else {
                            this.setState({
                                isArtDisabled: true,
                                disabledMessage: "Art has been disabled"
                            })
                        }
                    }
                }
            }).catch(err => {
                if (err && err.toLowerCase().includes("has been hidden by the owner")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been hidden by the art owner"
                    })
                } else if (err && err.toLowerCase().includes("has been disabled by the administrator")) {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "This art has been disabled by the administrator"
                    })
                } else {
                    this.setState({
                        isArtDisabled: true,
                        disabledMessage: "Art has been disabled"
                    })
                }
                this.hideLoader()
            })
        }

    }
    static getDerivedStateFromProps(props, state) {
        
        if (props && props.listedArt && props.listedArt.statusCode === 200) {
            props.resetListSuccess()
            props.forsalelistsuccess(state.historyId)
            
        }
        if (props && props.listedArtFailed && ((props.listedArtFailed.length > 0) || (props.listedArtFailed.txnId))) {
            props.resetListFailed()
            props.forsaleEventFailure(state.historyId)

            if(props.listedArtFailed.txnId){
                return {
                    
                    errorMessage : `Listing for Sale Failed. Please note down the below Token ID and contact Disrupt Art Admin.`,
                    txnId : props.listedArtFailed.txnId
                }
            }else{
                return {
                   
                    errorMessage : `Listing for Sale Failed. Please note down the below Token ID and contact Disrupt Art Admin.`,
                    txnId : ''
                }
            }
         
        }
        
        
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    componentDidUpdate(props, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            this.initialFunc()
        }
        //event initiate - step 1
        if(this.props && this.props.eventInitiatefailed && this.props.eventInitiatefailed.length > 0 && (props.eventInitiatefailed !== (this.props.eventInitiatefailed))){
            this.props.resetForsaleInitiateFailed()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                errorMessage : String(`Forsale Initiated : ${this.props.eventInitiatefailed}`),
                loaderMes : '',
                txnId : '',
                submitted: false
            })
        }
        
        if ((this.props && this.props.eventInitiateSuccess && this.props.eventInitiateSuccess.statusCode === 200) && (this.props.eventInitiateSuccess) != (props.eventInitiateSuccess)) {
            this.props.resetForsaleInitiate()
            if(this.props.eventInitiateSuccess && this.props.eventInitiateSuccess.data && this.props.eventInitiateSuccess.data.history && this.props.eventInitiateSuccess.data.history.id){
                this.setState({historyId : this.props.eventInitiateSuccess.data.history.id})
                this.forsaleProcess(this.props.eventInitiateSuccess.data.history.id)
            }
        }
        //event cancel
        if(this.props && this.props.eventCancelledFailed && this.props.eventCancelledFailed.length > 0 && (props.eventCancelledFailed !== (this.props.eventCancelledFailed))){
            this.props.resetEventCancelFailed()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                loaderMes : '',
                submitted: false
            })
        }
        
        if ((this.props && this.props.eventCancelledsuccess && this.props.eventCancelledsuccess.statusCode === 200) && (this.props.eventCancelledsuccess) != (props.eventCancelledsuccess)) {
            this.props.resetEventCancel()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                loaderMes : '',
            })
        }

        //event failure

        if(this.props && this.props.eventFailureFailed && this.props.eventFailureFailed.length > 0 && (props.eventFailureFailed !== (this.props.eventFailureFailed))){
            this.props.resetEventFailuredFailed()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                loaderMes : '',
                submitted: false
            })
        }
        
        if ((this.props && this.props.eventFailureSuccess && this.props.eventFailureSuccess.statusCode === 200) && (this.props.eventFailureSuccess) != (props.eventFailureSuccess)) {
            this.props.resetEventFailured()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                loaderMes : '',
            })
        }
        //list for stage
        if(this.props && this.props.liststageFailed && this.props.liststageFailed.length > 0 && (props.liststageFailed !== (this.props.liststageFailed))){
            this.props.resetliststagefailed()
            if(this.props.liststageFailed && this.props.liststageFailed.toLowerCase().includes('unhandled error')){
                this.listforsale()
            }else{
                this.setState({
                    disableList: false, 
                    isList: false, 
                    isTransLoader: false,
                    isErrorPoped : true,
                    isSuccess : false,
                    loaderMes : '',
                    errorMessage : String(`List stage failed : ${this.props.liststageFailed}`),
    
                })
            }
            
        }
        
        if ((this.props && this.props.liststagesucess && this.props.liststagesucess.statusCode === 200) && (this.props.liststagesucess) != (props.liststagesucess)) {
            this.props.resetliststagesuccess()
            this.listforsale()
            
        }
        if(this.props && this.props.transferFailed && this.props.transferFailed.length > 0 && (props.transferFailed !== (this.props.transferFailed))){
            this.props.resetTransferFailed()
            if(this.props.transferFailed.toLowerCase().includes('unhandled error')){
                this.listStageToHistory()
            }else{
                this.setState({
                    disableList: false, 
                    isList: false, 
                    isTransLoader: false,
                    isErrorPoped : true,
                    isSuccess : false,
                    loaderMes : '',
                    errorMessage : String(`Failed to save transfer event : ${this.props.transferFailed}`),
                    submitted: false,
                })
            }
            
        }
        
        if ((this.props && this.props.transferSuccess && this.props.transferSuccess.statusCode === 200) && (this.props.transferSuccess) != (props.transferSuccess)) {
            this.props.resetTransferSuccess()
            this.listStageToHistory()
            
        }
        if(this.props && this.props.lisEventFailed && this.props.lisEventFailed.length > 0 && (props.lisEventFailed !== (this.props.lisEventFailed))){
            this.props.resetListEventFailed()
            this.setState({
                isList: false,
                disableList: false,
                isTransLoader : false,
            })
            if(this.props.lisEventFailed && this.props.lisEventFailed.toLowerCase().includes('unhandled error')){
                this.setState({
                    isSuccess: true,
                    isErrorPoped : false,
                })
            }else{
                this.setState({
                    isErrorPoped : true,
                    isSuccess : false,
                    loaderMes : '',
                    errorMessage : String(`Failed to save : ${this.props.lisEventFailed}`),
    
                })
            }
            
        }
        
        if ((this.props && this.props.listEventSuccess && this.props.listEventSuccess.statusCode === 200) && (this.props.listEventSuccess) != (props.listEventSuccess)) {
            this.props.resetListEventSuccess()
            this.setState({
                isSuccess: true,
                isList: false,
                disableList: false,
                isTransLoader : false,
                isErrorPoped : false,
            })
            
        }
        if((this.props && this.props.walletRelatedArtId && this.props.walletRelatedArtId.statusCode === 200) && (this.props.walletRelatedArtId) !== (props.walletRelatedArtId)){
            this.props.resetWalletArtIds()
            if(this.props.walletRelatedArtId && this.props.walletRelatedArtId.data && this.props.walletRelatedArtId.data.arts && this.props.walletRelatedArtId.data.arts.length > 0){
                const avltoken = this.props.walletRelatedArtId.data.arts.map(a => a.tokenId)
                this.setState({
                    disableList: true,
                    isTransLoader: true,
                    isAirDrop: false
                })
                this.setState({ isList: true, loaderMes : 'Transferring NFT' })
                this.setState({ selectedTokenId :  avltoken})
                this.processSale(avltoken)
            }else{
                this.setState({
                    disableList: false, 
                    isList: false, 
                    isTransLoader: false,
                    isErrorPoped : true,
                    isSuccess : false,
                    loaderMes : '',
                    walletArray : [],
                    errorMessage : String(`Failed to get tokens : Tokens not available`),
    
                })
            }
            
        }
        if((this.props && this.props.walletRelatedArtIdFailed && this.props.walletRelatedArtIdFailed.length > 0) && (this.props.walletRelatedArtIdFailed) !== (props.walletRelatedArtIdFailed)){
            this.props.resetGetWalletAddDetailFailed()
            this.setState({
                disableList: false, 
                isList: false, 
                isTransLoader: false,
                isErrorPoped : true,
                isSuccess : false,
                loaderMes : '',
                walletArray : [],
                errorMessage : String(`Failed to get tokens : ${this.props.walletRelatedArtIdFailed}`),

            })
            
        }

    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab, count: 1, airdropCount: 1, onstart: '', submitted: false, isAgree: true, isImported: false
        })
    }
    handleActionChange = (e) => {

        if ((e.target.value >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= 30)) {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    checkNumber = (value) => {
        if (Number.isInteger(value)) {
            return true
        } else {
            return false
        }
    }
    handleBidPrice = (e) => {
        // Split the input string into two arrays containing integers/decimals
        // to check allow only two degits after decimals
        var res = (e.target.value).split(".");
        if ((e.target.value >= 0) && this.digitValidate(e.target.value) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                startPrice: e.target.value, startingPrice: num
            })
        }
    }
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    handleMinBid = (e) => {
        if (!(isNaN(e.target.value)))
            var res = (e.target.value).split(".");
        if (e.target.value >= 0 && (this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
            var num = Number(e.target.value).toFixed(8)
            this.setState({
                minBid: e.target.value, minimumBid: num
            })
        }
    }
    handleCount = (e) => {
        const { groupIds } = this.state
        
        if ((e.target.value) > 0 && (e.target.value) <= (Number(this.handleEditionCountlimit(groupIds))) && ((e.target.value) % 1 === 0)) {
            this.setState({ count: e.target.value, countLimit: this.handleEditionCountlimit(groupIds) })
        } else {
            this.setState({ count: '', countLimit: this.handleEditionCountlimit(groupIds) })
        }
    }



    getRoyalty = () => {
        let resale_royalty = ""
        let market_Fee = ""
        GetCreatorRoyality().then(res => {
            if ((res && res.status) === 200) {
                resale_royalty = parseFloat(res && res.data && res.data.Value).toFixed(0)
                this.setState({ resale_royalty })
            }
        })
        GetMarketFee().then(res => {
            if ((res && res.status) === 200) {
                market_Fee = parseFloat(res && res.data && res.data.Value).toFixed(0)
                // console.log("marketFee", market_Fee)
                this.setState({ market_Fee })

            }
        })
    }

    calculateSharings = (markedPrice) => {
        if ((markedPrice && markedPrice > 0)) {
            let creatorRoyalty = ""
            let marketFee = ""
            let crtShr = 0
            let markShr = 0


            GetCreatorRoyality().then(res => {

                if ((res && res.status) === 200) {
                    creatorRoyalty = res && res.data && res.data.Value
                    // console.log("creatorRo",creatorRoyalty,markedPrice)
                    CalculateShare(markedPrice, creatorRoyalty).then(res => {
                        crtShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                        this.setState({ crt_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                        GetMarketFee().then(res => {
                            if ((res && res.status) === 200) {
                                marketFee = res && res.data && res.data.Value
                                // console.log("marketFee",marketFee)

                                CalculateShare(markedPrice, marketFee).then(res => {
                                    markShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                                    this.setState({ mark_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                    let remain_shr = (100.0 - (Number(creatorRoyalty) + Number(marketFee))).toFixed(8)
                                    // console.log("tot",remain_shr)
                                    CalculateShare(markedPrice, remain_shr).then(res => {

                                        this.setState({ sell_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value, isShare: true, isVisible: false })
                                    }).catch(err => { })
                                }).catch(err => { })
                                this.setState({ marketFee: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                            }
                        }).catch(err => { })
                    }).catch(err => { })
                    this.setState({ creatorRoyalty: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                }
            }).catch(err => { })






        } else {
            this.setState({ isVisible: false, crt_share: '' })
        }
    }
    handlePriceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0) {
            if ((this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
                let basePrice = ''
                const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

                const num = (Number(e.target.value))
                // const precision = num.toPrecision(10)
                const precision = num.toFixed(8)

                // if (e.target.value && Number.isInteger(num)) {
                //     basePrice = precision
                // } else {
                basePrice = precision

                //}
                this.setState({ price: e.target.value, basePrice, usd: rate ? rate.toFixed(2) : '', isVisible: true }, () => {
                    this.calculateSharings(this.state.basePrice)
                })
            }
        } else {
            this.setState({ isVisible: false, isShare: false })
            this.setState({ isAlert: true, message: 'Invalid Price', style: "danger" })
            this.gotoTop()
            // this.closeAlert()
            // toast.error("Invalid Price", { position: toast.POSITION.BOTTOM_CENTER })
        }
    }

    showError(error) {
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        this.setState({
            isAlert: true,
            message: mes,
            style: "danger",
            listingInProgress: false,
            tokensProcessed: 0,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            ShowPop: false,
            isList: false,
            disableList: false
        })
        this.gotoTop()

    }
    listStageToHistory = () => {
        const {selectedTokenId,historyId} = this.state

        let tokenIdobj = {
            tokenIds: selectedTokenId,
        }
        this.props.forsalelistStage(JSON.stringify(tokenIdobj),historyId)

    }
    listforsale = () => {
        const {selectedTokenId} = this.state
        let obj = {
            price: Number(this.state.basePrice),
            tokenIds: selectedTokenId,
        }
        this.props.listedArtForSale(JSON.stringify(obj))

    }
    forsaleProcess = async (id) => {
        const {selectedTokenId} = this.state
        
        const tokenArray = selectedTokenId
        if (selectedTokenId.length > 0) {
        try {
            let txnResponse;
            if(isDapperConnected()){
                txnResponse = await dapperTransferTokens(tokenArray)
            }else{
                txnResponse = await flowMoveToAirDrop(tokenArray,(process.env.REACT_APP_STORE_ADMIN_ACCOUNT));
            }
            if (txnResponse.status === 200) {
                const { transactionId, blockHeight } = txnResponse.data;
                
                if (txnResponse && txnResponse.data && txnResponse.data.transaction && txnResponse.data.transaction.errorMessage) {
                    this.setState({
                        errorMessage : String(txnResponse.data.transaction.errorMessage),
                    })
                    this.props.forsaleEventFailure(id)
                    return false

                } else {
                    this.setState({isErrorPoped : false,loaderMes : 'Transfer is almost done, Please wait'})
                    setTimeout(() => {
                        this.setState({isErrorPoped : false,loaderMes : 'Listing NFT for sale'})
                        
                        this.props.forsaleTransfetSuccess(id)

                    },5000)

                }


            } else {

                const error = txnResponse.data.Error
                
                let mes = ''; const splitError = error.split(':');
                if (
                    splitError[2] &&
                    splitError[2].includes("Session expired")
                ) {
                    mes = "Session Expired.Please reconnect your wallet account";
                } else if (
                    splitError[2]
                ) {
                    mes = splitError[2]
                } else if (splitError[1]) {
                    if(error && error.toLowerCase().includes('declined')){
                        mes = "User rejected signature"
                    }else{
                        mes = splitError[1]
                    }
                } else {
                    mes = splitError[0];
                }
                this.setState({
                    errorMessage : mes,
                })
                if(error && error.toLowerCase().includes('user rejected')){
                    this.props.forsaleEventCancelled(id)
                }else{
                    this.props.forsaleEventFailure(id)
                }
                return false
            }

        } catch (e) {
            
            this.props.forsaleEventFailure(id)

            if (e && e.data && e.data.Error) {
                this.setState({errorMessage : String(e.data.Error)})
                return false
            } else if (e && e.data && e.data.message) {
                this.setState({errorMessage : (e.data.message)})

                return false
            } else if (e && e.message) {
                this.setState({errorMessage : (e.message)})

                return false

            } else {
                this.setState({errorMessage : (String(e))})

                return false
            }
        }
    }else{
        this.hideLoader()
        if (!(this.isArtVisible(this.state.data))) {
            this.setState({
                disableList: false, isList: false, isTransLoader: false,submitted: false
            })
            toast.error('Art is hidden. Cannot be listed for sale', { transition: Zoom })

            
        } else {
            this.setState({
                disableList: false, isList: false, isTransLoader: false,submitted: false
            })
            toast.error('Token not available for listing for sale', { transition: Zoom })
            this.hideLoader()
        }
    }


    }
    async processSale(avltoken) {
        
        let obj = {
            tokenIds : avltoken,
            price: Number(this.state.basePrice),
            initiatedFor: 1
        }
         if (avltoken.length > 0) {
             const artTokenGroupId = this.state.data.tokenGroupId;
         try {
            //  await dropServices.saveEvent(artTokenGroupId, avltoken.length, dropServices.DropEventType.FOR_SALE, this.state.data.tokenId);
             await this.props.forsaleInitiate(JSON.stringify(obj))
             
 
         } catch (e) {
            console.log((e))
            this.setState({
                disableList: false, isList: false, isTransLoader: false,issuccess : false,isErrorPoped : true,txnId : ''
            })
            if (e && e.data && e.data.Error) {
                this.setState({errorMessage : String(e.data.Error)})
                return false
            } else if (e && e.data && e.data.message) {
                this.setState({errorMessage : (e.data.message)})

                return false
            } else if (e && e.message) {
                this.setState({errorMessage : (e.message)})

                return false

            } else {
                this.setState({errorMessage : (String(e))})

                return false
            }
             
         }
     }else{
        if (!(this.isArtVisible(this.state.data))) {
            this.setState({
                disableList: false, isList: false, isTransLoader: false,submitted: false
            })
            toast.error('Art is hidden. Cannot be listed for sale', { transition: Zoom })
            
        } else {
            this.setState({
                disableList: false, isList: false, isTransLoader: false,submitted: false
            })
            toast.error('Token not available for listing for sale', { transition: Zoom })
        }
        
     }
 
 
     }
    
    handleSale = (id) => {
        const { groupIds, totalOwnerArt, data } = this.state
        const totalMintedArt = (data.toatalMintedTokens)
        const ownerWalletAddress = data && data.ownerWalletAddress
        const walletProvider = data && data.ownerWallet && data.ownerWallet.walletProvider ? data.ownerWallet.walletProvider : ''
        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.count
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens
            let tokenArray = JSON.stringify(selectedTokens)


            if (this.state.basePrice && Number(this.state.basePrice) > 0) {
                if(isConnectWallet()){
                    getUserAddress().then(res => {
                        if (res != null) {
                            this.showLoader();
                            walletServices.checkWallet(res).then(wallet => {
                                this.hideLoader()
                                if ((wallet && wallet.statusCode) === 200) {
                                    this.setState({ disableList: true })
                                    this.setState({ isTransLoader: true })
                                    
                                        this.showLoader();
                                        if (checkCount && (data["id"]) && (res)) {
                                            let obj = {
                                                count : checkCount,
                                                fields : 'id,tokenId'
                                            }
                                            this.props.getwalletRelatedArtIds(res,(data.id),obj)
                                            
                                        } else {
                                            toast.error(`Couldn't get art data`, { transition: Zoom })
                                            this.hideLoader()
                        
                                            this.setState({ disableList: false,isTransLoader:false })
                                            this.setState({ ShowPop: false, isList: false,submitted: false })
                                        }
                                    
                                }
                            }).catch(err => {
                                toast.error(err, { transition: Zoom })
                                this.hideLoader()
                                this.setState({ disableList: false,  isTransLoader: false,submitted: false,
                                    isList : false })

                            })
                        }else{
                            this.setState({ disableList: false,submitted: false })
                            this.setState({ ShowPop: false, isList: false })
                            toast.error("Please reconnect your wallet or change your wallet account", { transition: Zoom })
                            this.gotoTop()   
                        }
                    }).catch(err => {
                        this.setState({ disableList: false,submitted: false })
                        this.setState({ ShowPop: false, isList: false })
                        toast.error("Please reconnect your wallet", { transition: Zoom })
                    })
                }else{
                    this.setState({ disableList: false })
                    toast.error("Please connect your wallet", { transition: Zoom })
                }
              

            } else {
                this.setState({ disableList: false })
                toast.error("Please enter valid price", { transition: Zoom })
            }
        } else {
            this.setState({ disableList: false })
            toast.error("Please enter valid edition", { transition: Zoom })
        }
    }


    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
        this.gotoTop()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    fetchArt(url) {
        if (url && url.localPreview && url.localPreviewMimeType && url.localPreviewMimeType !== null) {
            if (url.localPreviewMimeType.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        } else {
            if (url && url.thumbnailHash) {
                fetchService.getBlob(url.thumbnailHash).then((blob) => {

                    if (blob.type.match(/image.*/)) {
                        return this.setState({ isImage: true })
                    } else {
                        return this.setState({ isImage: false })
                    }
                }).catch((err) => {
                    return this.setState({ isImage: false })
                })
            }
        }

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    percentageShower = () => {
        return <div className="purchasebox">
            <div className="table-responsive">
                <table className="table custable">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Marketplace Fee</th>
                            <th>Royalty for Creator</th>
                            <th>Creator</th>
                        </tr>
                        <tr>
                            <th>First Sale</th>
                            <td>15%</td>
                            <td>0%</td>
                            <td>85%</td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Reseller</th>
                        </tr>
                        <tr>
                            <th>Every Resale</th>
                            <td>5%</td>
                            <td>10%</td>
                            <td>85%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    resetPage = () => {
        this.setState({ isSuccess: false })
        history.push('/store/storedrops')
    }

    redirectToAirDrops = () => {
        localStorage.setItem('page', "0")
        history.push('/airdrops')
    }
    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }



    handleImported = (value) => {
        if (value) {
            return false
        } else {
            this.setState({ isAgree: false, isSelectedMembers: false, isImported: true })
        }
    }
    handleSelectedMembers = (e) => {
        this.setState({
            member: (e.target.value).toLowerCase(),
        }, () => {
        })

    }
    usernameValidation = (value) => {
        let isValide = true
        const array = value.split(',')
        const regext = /^([a-zA-Z0-9.]{4,})+$/
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < array.length; i++) {
            if ((!(emailReg.test(array[i]))) &&
                (!(regext.test(array[i])))
            ) {
                isValide = false;
            }
        }
        return isValide;

    }
    checkIsEmail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((emailReg.test(value))) {
            return true
        } else {
            return false
        }
    }

    validation = (currentDate) => {
        return currentDate.isAfter(moment().subtract(1, "days"));

    }


    isOwnerisCreator = (data) => {
        if (getCurrentUserId()) {
            if ((data && data.creator && data.creator.id) === (getCurrentUserId())) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }

    isArtVisible = (data) => {
        if ((data && data.status && data.status) === 1) {
            return true
        } else {
            return false
        }
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    
    connectMyWallet = () => {
        this.handleToSwitchAccount()
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false,
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        }) 
    }
    switchWalletConnected = (wallet) => {
        if(wallet){
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
        }
    }
    handleSwitchAccFailed = (message) => {
        history.push('/store/adddrops')
        this.setState({
            isAirDrop: false

        })
        toast.error( message, { transition: Zoom })

    }
    isListPage = () => {
        this.closeSwitchAccModal()
        history.push('/store/adddrops')
    }
    handleEditionCountlimit = (groupIds) => {
        if (groupIds && groupIds.length > this.maxAllowedListLimit) {
            return this.maxAllowedListLimit
        } else {
            return groupIds.length
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        if ((this.state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        const { data, sell_share, crt_share, mark_share, groupIds, totalOwnerArt, isArtDisabled, disabledMessage, isErrorPoped, errorMessage } = this.state
        const Art_Name = ((data && data.title) ? (data.title) : '...');
        const Art_Desc = ((data && data.description) ? (data.description) : '...');
        const metaTitle = `${Art_Name} - Sell or Auction NFT on ${process.env.REACT_APP_MARKETPLACE}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={`List NFT for sale`}></meta>
                    <meta name="description" content={`List NFT for sale`}></meta>
                </Helmet>

                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                </div>}
                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }





                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} alt="" width="170px" />}
                            {
                                this.state.isListingAborted === false && <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            }
                            {
                                this.state.isListingAborted === true &&
                                <i className="fas fa-exclamation-triangle fa-5x text-warning"></i>
                            }
                            {
                                this.state.isListingAborted
                                    ? <div className="mt-3">
                                        <p>
                                            <b>Transaction aborted</b>
                                        </p>
                                        <p>
                                            Transaction has been aborted because {this.state.listingAbortError !== ''
                                                ? <span>{this.state.listingAbortError}</span>
                                                : <span>of an error</span>
                                            }. {this.state.tokensListedBeforeAbort} editions have been listed.
                                        </p>
                                    </div>
                                    : <div className="mt-3"><b>Woohoo..! Successfully listed for sale! Good luck!</b></div>
                            }
                            {/* <div className="mt-3">It may take 30 seconds for your listed art to appear in drops :)</div> */}

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>View On Sale</button></div></div>

                }
                {this.state.isList && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="">{this.state.loaderMes}</p>

                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                }
                 {this.state.isErrorPoped && !this.state.isSuccess && !this.state.isList &&
                    <div className="show-cancel">
                        <div className="success-pop">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{errorMessage}</div>
                            <div className='py-3'>Selected token id : {this.state.selectedTokenId}</div>
                            {this.state.txnId ? <div className='py-3'>Transaction id : {this.state.txnId}</div> : ''}

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false })}}>Close</button></div></div></div>
                }
                {this.state.isSwitchAcc ? 
                    <SwitchAccount 
                    closeModal={this.closeSwitchAccModal} 
                    isListPage={this.isListPage}
                    switchWalletConnected={(val) => this.switchWalletConnected(val)}
                    handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}/> 
                    : ''}
                {isConnectWallet() ? (!(isArtDisabled)) ? <div className="detailWallet mb-5">
                    <div className="detasingimg detailMyWallet">
                        <div className="container">
                            <span className="detaimg text-center">
                                <ArtViewPreview src={data && data.imageHash} alt="" className="mw-100" showControls={true} autoPlay={true} isDetail={true} isPremiumArt={data && data.isPremiumArt} mimeType={data && data.imageMimeType} sharedData={data} />
                            </span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-6">

                            </div>
                            <div className="col-6 text-right">


                            </div>


                        </div>
                    </div>
                    <div className="container dmwsec">
                        <div className="card1">
                            <div className="card-body1">
                                <div className="det-head"></div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="dettittxt mb-3"><h2 className="py-0 ">{data && data.title ? data.title : ''} &nbsp;
                                        </h2></div>
                                        <div className="dettittxt mb-3">
                                            <h4 className="py-0 ">{data && data.description}</h4>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.imageHash && data && data.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View On IPFS</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.metadataJsonUrl && data && data.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View Metadata</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.flowScanURL && data.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View on Flow Scan</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-md-6">
                                        <div className="det-align">
                                            <Tabs className="tab-wrap" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabChange(tabIndex)}>

                                                <TabPanel>
                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <label className="mb-3" htmlFor="editionsforsale" >Editions for sale</label>
                                                                <input id="editionsforsale" name="editionsforsale" type="text" value={this.state.count} onChange={this.handleCount} className="form-control mw-200 text-right" />
                                                                {!this.state.submitted && this.state.count && <div className="mandatory-req mt-2">Maximum allowed editions - {this.maxAllowedListLimit}</div>}
                                                                {this.state.submitted && !this.state.count && <div className="mandatory mt-2">{"Please enter your valid edition for listing"}</div>}
                                                                {!this.state.submitted && !this.state.count && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${this.state.countLimit} (must be whole number)`}</div>}

                                                            </div>
                                                        </div>
                                                    </div> : ''}
                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <label htmlFor="price" className="mb-3 w-100">Price</label>
                                                                <div className="input-group dollorsec">
                                                                    <input id="price" name="price" type="text" value={this.state.price} onChange={this.handlePriceChange} className="form-control mw-200 text-right" />
                                                                    {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className="input-group-append ">
                                                                        <span className="input-group-text bg-white" id="basic-addon2">USD</span>
                                                                    </div> :
                                                                        <div className="input-group-append ">
                                                                            <span className="input-group-text bg-white" id="basic-addon2">USD</span>
                                                                        </div>}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <hr className="my-3 d-block lightline" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Cannot be listed for sale"}</div>}
                                                            {this.isArtVisible(data) ? <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleSale((data && data.tokenId)) }}>List For Sale</button> : ''}
                                                        </div>

                                                    </div>}

                                                </TabPanel>



                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="error-template userdisableview">
                                <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                                {(disabledMessage && disabledMessage.includes('admin')) ? <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4> : ''}

                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="mb-0">The Team</p> : ''}
                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="usernamelilac">DisruptArt</p> : ''}


                            </div>
                        </div>

                    </div>
                </div> : <CommonWalletConnectUI connectMyWallet={this.connectMyWallet}/>}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        listedArt: state.creatorStore.listedArtForsale,
        listedArtFailed: state.creatorStore.listedArtSaleFailed,
        wallet: state.wallet.wallet,
        eventInitiateSuccess: state.transactions.eventInitiated,
        eventInitiatefailed: state.transactions.eventInitiatedFailed,

        eventCancelledsuccess: state.transactions.eventCancelled,
        eventCancelledFailed: state.transactions.eventCancelledFailed,

        eventFailureSuccess: state.transactions.eventFailured,
        eventFailureFailed: state.transactions.eventFailuredFailed,

        liststagesucess: state.transactions.listStaged,
        liststageFailed: state.transactions.listStagedFailed,

        transferSuccess: state.transactions.eventTransferSuccess,
        transferFailed: state.transactions.eventTransferFaied,

        listEventSuccess: state.transactions.listsuccess,
        lisEventFailed: state.transactions.listFailed,

        walletDetailsuccess: state.art.walletAddDetail,
        walletDetailFailed: state.art.walletAddDetailFailed,

        walletRelatedArtId: state.art.walletRelatedArts,
        walletRelatedArtIdFailed: state.art.walletRelatedArtsFailed
    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    listedArtForSale: creatorStoreActions.storeListArtForSale,
    resetListSuccess: creatorStoreActions.resetStoreListArtForSale,
    resetListFailed: creatorStoreActions.resetListedArtForSaleFailed,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    forsaleInitiate: txnActions.forsaleEventHistoryInitiate,
    resetForsaleInitiate: txnActions.resetEventHistoryInitiated,
    resetForsaleInitiateFailed: txnActions.resetEventHistoryInitiatedFailed,
    forsaleEventCancelled: txnActions.forsaleEventHistoryCancelled,
    resetEventCancel: txnActions.resetEventCancelled,
    resetEventCancelFailed: txnActions.resetEventCancelledFailed,
    forsaleEventFailure: txnActions.forsaleEventHistoryFailure,
    resetEventFailured: txnActions.resetEventFailure,
    resetEventFailuredFailed: txnActions.resetEventFailureFailed,
    forsaleTransfetSuccess: txnActions.forsaleEventHistoryTransferSuccess,
    resetTransferSuccess: txnActions.resetEventTransaferSuccess,
    resetTransferFailed: txnActions.resetEventTransferFailed,
    forsalelistStage: txnActions.forsaleEventHistoryListStage,
    resetliststagesuccess: txnActions.resetlistStageEvent,
    resetliststagefailed: txnActions.resetlistStageEventFailed,
    forsalelistsuccess: txnActions.forsaleEventHistorylistSuccess,
    resetListEventSuccess: txnActions.resetlistsuccess,
    resetListEventFailed: txnActions.resetlistfailed,
    getWalletAddDetail: artActions.getWalletAddressDetail,
    resetGetWalletAddDetail: artActions.resetgetWalletAddressDetail,
    resetGetWalletAddDetailFailed: artActions.resetgetWalletAddressDetailFailed,
    getwalletRelatedArtIds: artActions.getArtsForRelatedWallet,
    resetWalletArtIds: artActions.resetGetArtsForRelatedWallet,
    resetWalletArtIdsFailed: artActions.resetGetArtsForRelatedWalletFailed
}
const connectStoreListingArts = connect(mapStateToProps, actionCreators)(StoreListingArts)
export { connectStoreListingArts as StoreListingArts }
