export const airdropConstants = {
    GET_AIRDROP_ART : 'GET_AIRDROP_ART',
    GET_AIRDROP_LISTING : 'GET_AIRDROP_LISTING',
    GET_AIRDROP_DETAIL : 'GET_AIRDROP_DETAIL',
    GET_AIRDROP_DETAIL_USER : 'GET_AIRDROP_DETAIL_USER',
    REGISTER_AIRDROP_USER : 'REGISTER_AIRDROP_USER',
    VERIFY_AIRDROP_USER : 'VERIFY_AIRDROP_USER',
    REGISTER_AIRDROP_USER_FAILED : 'REGISTER_AIRDROP_USER_FAILED',
    VERIFY_AIRDROP_USER_FAILED : 'VERIFY_AIRDROP_USER_FAILED',
    FETCH_USERS_AIRDROP : 'FETCH_USERS_AIRDROP',
    AIRDROP_REMINDER : 'AIRDROP_REMINDER',
    AIRDROP_REMINDER_FAILED : 'AIRDROP_REMINDER_FAILED',

    AUTO_REGISTER_LOGIN_USER : 'AUTO_REGISTER_LOGIN_USER',
    AUTO_REGISTER_LOGIN_USER_FAILED : 'AUTO_REGISTER_LOGIN_USER_FAILED',

    AUTO_LOGIN_GUEST : 'AUTO_LOGIN_GUEST',
    AUTO_LOGIN_GUEST_USER_FAILED : 'AUTO_LOGIN_GUEST_USER_FAILED',

    AUTO_LOGIN_WELCOME_USER : 'AUTO_LOGIN_WELCOME_USER',
    AUTO_LOGIN_WELCOME_USER_FAILED : 'AUTO_LOGIN_WELCOME_USER_FAILED'
}