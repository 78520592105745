import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { GetCreatorRoyality, GetMarketFee, CalculateShare, marketSaleTokenGroup, getDisplayMessageForRuntimeTxnError, splitArray, getBatchSize } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { connect } from 'react-redux';
import { creatorStoreActions, userAction } from 'store/actions';
import { artServices, fetchService, userServices } from 'services';
import { getCurrentUserId, localAirdropPage, localArtPage, localDropPage, MaintenancePopup } from 'helpers';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { configPath } from '../../config'
import moment from 'moment'
import { Helmet } from 'react-helmet';
import 'react-datetime/css/react-datetime.css';
import { getFlowUsdValue } from 'helpers/getFlowUsd';
import { ArtViewPreview } from 'components/art-preview/artview-preview';
import { toast, Zoom } from 'react-toastify';

var timeout;
var dataURL = {}
class StoreWithdrawListing extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            data: {},
            price: 350,
            basePrice: Number(350).toFixed(8),
            usd: '',
            isSuccess: false,
            message: '',
            isAlert: false,
            isVisible: false,
            isShare: false,
            count: 1,
            submitted: false,
            ShowPop: false,
            isList: false,
            totalOwnerArt: 0,
            resale_royalty: '',
            market_Fee: '',
            crt_share: '',
            tabIndex: 0,
            disableList: false,
            isTransLoader: false,
            startPrice: 350,
            minBid: 1,
            minimumBid: 1,
            startingPrice: 350,
            isSuccessAuction: false,
            isImage: false,
            listingInProgress: false,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            tokensProcessed: 0,
            isListingAborted: false,
            tokensListedBeforeAbort: 0,
            listingAbortError: '',
            isMaintenance: false,
            onstart: moment(),
            isAgree: true,
            isAirDrop: false,
            isSelectedMembers: false,
            selectedMembers: [],
            member: '',
            isImported: false,

            isArtDisabled: false, // if art was disabled
            disabledMessage: '',
            isThumbnailUpdateSuccess: false, // if thumbnail upload success,
            isCategoryUpdateSuccess: false, // if category update success
            isTagUpdateSuccess: false

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()

        this.getArtByToken()
        this.gotoTop()
    }
    getArtByToken = () => {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.props.getArtDetail(id)
        }

    }
    static getDerivedStateFromProps(props, state) {
        if(props && props.listedArt && props.listedArt.statusCode === 200){
            props.resetListSuccess()
            return {
                isSuccess : true
            }
        }
        if(props && props.listedArtFailed && props.listedArtFailed.length > 0){
            props.resetListFailed()
            toast.error(props.listedArtFailed)
            return {
                isList: false,
                disableList: false
            }
             //     this.setState({
        //         ShowPop: false,
        //         isList: false,
        //         isAlert: false,
        //         isSuccess: true,
        //         disableList: false,
        //         listingInProgress: false,
        //         tokensProcessed: 0,
        //         listingProgress: 0,
        //         totalTokensToBeListed: 0,
        //         listingAbortError: this.state.message
            
        // })
        }
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    componentDidUpdate(props, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        if(this.props && this.props.artDetail && this.props.artDetail.statusCode === 200 && (this.props.artDetail !== props.artDetail)){
            const art = this.props.artDetail

            this.props.resetgetArtDetailSuccess()

                let dataVal = art && art.data
                let dropStr = dataVal && dataVal.dropsCount
                let dropArr = '[' + dropStr + ']'
                let dropsCountarray = eval(dropArr && dropArr)
                let totalTokenId = dataVal && dataVal.totalTokenId
                let arr = '[' + totalTokenId + ']'
                let groupIds = eval(arr && arr)
                // console.log(groupIds)
                let totalOwnerArt = dataVal && dataVal.totalOwnerArt
                dropsCountarray && dropsCountarray.map((items) => {
                    if (items) {
                        groupIds = groupIds.filter((el) => !(items && items.tokens).includes(el))

                    }
                })
                this.fetchArt(art && art.data)

                this.setState({ data: art && art.data ? art.data : [], artOwner: art && art.data && art.data.owner && art.data.owner.userName, artCreator: art && art.data && art.data.creator && art.data.creator.userName, groupIds, totalOwnerArt })
           
        }
        if(this.props && this.props.artDetailFailed && this.props.artDetailFailed.length > 0 && (this.props.artDetailFailed !== props.artDetailFailed)){
            this.props.resetgetArtDetailFailed()
            toast.error(this.props.artDetailFailed,{transition:Zoom})
        }


    }
    onTabChange = (tab) => {
        this.setState({
            tabIndex: tab, count: 1, airdropCount: 1, onstart: '', submitted: false, isAgree: true, isImported: false
        })
    }
    
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
  
    handleCount = (e) => {
        const { groupIds } = this.state
        if ((e.target.value) > 0 && (e.target.value) <= (Number(groupIds && groupIds.length)) && ((e.target.value) % 1 === 0)) {
            this.setState({ count: e.target.value })
        } else {
            this.setState({ count: '' })
        }
    }


    calculateSharings = (markedPrice) => {
        if ((markedPrice && markedPrice > 0)) {
            let creatorRoyalty = ""
            let marketFee = ""
            let crtShr = 0
            let markShr = 0


            GetCreatorRoyality().then(res => {

                if ((res && res.status) === 200) {
                    creatorRoyalty = res && res.data && res.data.Value
                    // console.log("creatorRo",creatorRoyalty,markedPrice)
                    CalculateShare(markedPrice, creatorRoyalty).then(res => {
                        crtShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                        this.setState({ crt_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                        GetMarketFee().then(res => {
                            if ((res && res.status) === 200) {
                                marketFee = res && res.data && res.data.Value
                                // console.log("marketFee",marketFee)

                                CalculateShare(markedPrice, marketFee).then(res => {
                                    markShr = res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value
                                    this.setState({ mark_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                    let remain_shr = (100.0 - (Number(creatorRoyalty) + Number(marketFee))).toFixed(8)
                                    // console.log("tot",remain_shr)
                                    CalculateShare(markedPrice, remain_shr).then(res => {

                                        this.setState({ sell_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value, isShare: true, isVisible: false })
                                    }).catch(err => { })
                                }).catch(err => { })
                                this.setState({ marketFee: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                            }
                        }).catch(err => { })
                    }).catch(err => { })
                    this.setState({ creatorRoyalty: (res && res.data && res.data.Value ? res.data.Value : ''), isShare: true, isVisible: false })
                }
            }).catch(err => { })






        } else {
            this.setState({ isVisible: false, crt_share: '' })
        }
    }
    handlePriceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0) {
            if ((this.digitValidate(e.target.value)) && (res.length === 1 || res[1].length < 6)) {
                let basePrice = ''
                const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

                const num = (Number(e.target.value))
                // const precision = num.toPrecision(10)
                const precision = num.toFixed(8)

                // if (e.target.value && Number.isInteger(num)) {
                //     basePrice = precision
                // } else {
                basePrice = precision

                //}
                this.setState({ price: e.target.value, basePrice, usd: rate ? rate.toFixed(2) : '', isVisible: true }, () => {
                    this.calculateSharings(this.state.basePrice)
                })
            }
        } else {
            this.setState({ isVisible: false, isShare: false })
            this.setState({ isAlert: true, message: 'Invalid Price', style: "danger" })
            this.gotoTop()
            // this.closeAlert()
            // toast.error("Invalid Price", { position: toast.POSITION.BOTTOM_CENTER })
        }
    }

    showError(error) {
        let mes = ''; const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        this.setState({
            isAlert: true,
            message: mes,
            style: "danger",
            listingInProgress: false,
            tokensProcessed: 0,
            listingProgress: 0,
            totalTokensToBeListed: 0,
            ShowPop: false,
            isList: false,
            disableList: false
        })
        this.gotoTop()

    }
    async processSale() {
        const {data} = this.state
        let obj = {
            price : Number(this.state.basePrice),
            editionCount : Number(this.state.count),
            artId : data && data.id
        }
        this.props.listedArtForSale(JSON.stringify(obj))
       
    }
    handleSale = (id) => {
        const { groupIds, totalOwnerArt, data } = this.state

        let arrayLength = 0
        let checkCount = 0
        if (totalOwnerArt === 1 && (groupIds && groupIds.length <= 1)) {
            arrayLength = 1
            checkCount = 1

        } else if (totalOwnerArt > 1 && (groupIds && groupIds.length === 1)) {
            arrayLength = 1
            checkCount = 1
        }
        else {
            arrayLength = groupIds && groupIds.length
            checkCount = this.state.count
        }

        let tokens = []
        tokens.push(id)


        this.setState({ submitted: true })
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

            let selectedTokens = (groupIds && groupIds.length > 0 && (totalOwnerArt > 1)) ? groupIds.slice(0, checkCount) : tokens
            let tokenArray = JSON.stringify(selectedTokens)


            if (this.state.basePrice && Number(this.state.basePrice) > 0) {
                this.setState({ disableList: true })
                // this.setState({ isTransLoader: true })
                this.processSale()

            } else {
                this.setState({ disableList: false })
                toast.error("Please enter valid price", {transition:Zoom })
            }
        } else {
            this.setState({ disableList: false })
            toast.error("Please enter valid edition", {transition:Zoom })
        }
    }


    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
        this.gotoTop()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    fetchArt(url) {
        if (url && url.localPreview && url.localPreviewMimeType && url.localPreviewMimeType !== null) {
            if (url.localPreviewMimeType.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        } else {
            if (url && url.thumbnailHash) {
                fetchService.getBlob(url.thumbnailHash).then((blob) => {

                    if (blob.type.match(/image.*/)) {
                        return this.setState({ isImage: true })
                    } else {
                        return this.setState({ isImage: false })
                    }
                }).catch((err) => {
                    return this.setState({ isImage: false })
                })
            }
        }

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    percentageShower = () => {
        return <div className="purchasebox">
            <div className="table-responsive">
                <table className="table custable">
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Marketplace Fee</th>
                            <th>Royalty for Creator</th>
                            <th>Creator</th>
                        </tr>
                        <tr>
                            <th>First Sale</th>
                            <td>15%</td>
                            <td>0%</td>
                            <td>85%</td>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Reseller</th>
                        </tr>
                        <tr>
                            <th>Every Resale</th>
                            <td>5%</td>
                            <td>10%</td>
                            <td>85%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    }
    resetPage = () => {
        this.setState({isSuccess : false})
        history.push('/store/adddrops')
    }

    redirectToAirDrops = () => {
        localStorage.setItem('page', "0")
        history.push('/airdrops')
    }
    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }



    handleImported = (value) => {
        if (value) {
            return false
        } else {
            this.setState({ isAgree: false, isSelectedMembers: false, isImported: true })
        }
    }
    handleSelectedMembers = (e) => {
        this.setState({
            member: (e.target.value).toLowerCase(),
        }, () => {
        })

    }
    usernameValidation = (value) => {
        let isValide = true
        const array = value.split(',')
        const regext = /^([a-zA-Z0-9.]{4,})+$/
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < array.length; i++) {
            if ((!(emailReg.test(array[i]))) &&
                (!(regext.test(array[i])))
            ) {
                isValide = false;
            }
        }
        return isValide;

    }
    checkIsEmail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((emailReg.test(value))) {
            return true
        } else {
            return false
        }
    }

    validation = (currentDate) => {
        return currentDate.isAfter(moment().subtract(1, "days"));

    }


    isOwnerisCreator = (data) => {
        if (getCurrentUserId()) {
            if ((data && data.creator && data.creator.id) === (getCurrentUserId())) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }

    isArtVisible = (data) => {
        if ((data && data.status && data.status) === 1) {
            return true
        } else {
            return false
        }
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }


    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        if ((this.state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        const { data, sell_share, crt_share, mark_share, groupIds, totalOwnerArt, isArtDisabled, disabledMessage } = this.state
        const Art_Name = ((data && data.title) ? (data.title) : '...');
        const Art_Desc = ((data && data.description) ? (data.description) : '...');
        const metaTitle = `${Art_Name} - Sell or Auction NFT on ${process.env.REACT_APP_MARKETPLACE}`;
        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={`List NFT for sale`}></meta>
                    <meta name="description" content={`List NFT for sale`}></meta>
                </Helmet>

                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                </div>}
                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }

                {this.state.listingInProgress &&
                    <div className="show-success">
                        <div className="success-pop">
                            <div className="sale-progress">
                                <div className="progress mb-1 cus-progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${this.state.listingProgress}%` }}></div>
                                </div>
                                <div className='mb-5 text-small'>
                                    <span className="usernamelilac">{this.state.tokensProcessed}/{this.state.totalTokensToBeListed}</span> Editions listed
                                </div>
                                <h3 className="mb-3">Listing more than <span className="usernamelilac">{getBatchSize()}</span> Editions will be done in batches.</h3>
                                <h4> You will be asked to approve the transaction for each batch</h4>
                            </div>
                        </div>
                    </div>
                }



                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={data && data.thumbnailHash} alt="" width="170px" />}
                            {
                                this.state.isListingAborted === false && <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            }
                            {
                                this.state.isListingAborted === true &&
                                <i className="fas fa-exclamation-triangle fa-5x text-warning"></i>
                            }
                            {
                                this.state.isListingAborted
                                    ? <div className="mt-3">
                                        <p>
                                            <b>Transaction aborted</b>
                                        </p>
                                        <p>
                                            Transaction has been aborted because {this.state.listingAbortError !== ''
                                                ? <span>{this.state.listingAbortError}</span>
                                                : <span>of an error</span>
                                            }. {this.state.tokensListedBeforeAbort} editions have been listed.
                                        </p>
                                    </div>
                                    : <div className="mt-3"><b>Woohoo..! Successfully listed for sale! Good luck!</b></div>
                            }

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.resetPage() }}>Go To Add Drops</button></div></div>

                }
                {this.state.isList && !this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing to list for sale</p>

                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />

                        </div>
                    </div>
                }

                {!(isArtDisabled) ? <div className="detailWallet mb-5">
                    <div className="detasingimg detailMyWallet">
                        <div className="container">
                            <span className="detaimg text-center">
                                <ArtViewPreview src={data && data.imageHash} alt="" className="mw-100" showControls={true} autoPlay={true} isDetail={true} isPremiumArt={data && data.isPremiumArt} mimeType={data && data.imageMimeType} sharedData={data} />
                            </span>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-6">
                               
                            </div>
                            <div className="col-6 text-right">


                            </div>


                        </div>
                    </div>
                    <div className="container dmwsec">
                        <div className="card1">
                            <div className="card-body1">
                                <div className="det-head"></div>
                                <div className="row">
                                    <div className="col-md-6">

                                        <div className="dettittxt mb-3"><h2 className="py-0 ">{data && data.title ? data.title : ''} &nbsp;
                                        </h2></div>
                                        <div className="dettittxt mb-3">
                                            <h4 className="py-0 ">{data && data.description}</h4>
                                        </div>
                                     
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.imageHash && data && data.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View On IPFS</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.metadataJsonUrl && data && data.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View Metadata</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-sm-8 mb-3">
                                                <a target="blank" href={data && data.flowScanURL && data.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                    <span className="texticon"> View on Flow Scan</span>
                                                    <i className="fas fa-external-link-alt"></i>
                                                </a>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="col-md-6">
                                        <div className="det-align">
                                            <Tabs className="tab-wrap" selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.onTabChange(tabIndex)}>

                                                

                                                <TabPanel>
                                                    <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <h5 className="mb-3">{groupIds && groupIds.length > 1 ? "Available Editions" : totalOwnerArt && totalOwnerArt > 1 ? "Available Editions" : "Available Edition"}</h5>

                                                                <h3 className="">{(groupIds && groupIds.length >= 1 && totalOwnerArt && totalOwnerArt > 1) ? (groupIds && groupIds.length) : totalOwnerArt}</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) &&  <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                               
                                                                    <label className="mb-3" htmlFor="editionsforsale" >Editions for sale</label>
                                                                    <input id="editionsforsale" name="editionsforsale" type="text" value={this.state.count} onChange={this.handleCount} className="form-control mw-200 text-right" />
                                                                    {this.state.submitted && !this.state.count && <div className="mandatory mt-2">{"Please enter your valid tokens edition for listing"}</div>}
                                                                    {!this.state.submitted && !this.state.count && <div className="mandatory-req">{`INFO : Edition must be less than or equal to ${groupIds && groupIds.length} (must be whole number)`}</div>}
                                                               
                                                            </div>
                                                            </div>
                                                            </div> }
                                                            <div className="purchasebox">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                               
                                                                    <label htmlFor="price" className="mb-3">Price</label>
                                                                    <div className="input-group dollorsec">
                                                                        <input id="price" name="price" type="text" value={this.state.price} onChange={this.handlePriceChange} className="form-control mw-200 text-right" />
                                                                        {(totalOwnerArt && totalOwnerArt > 1) && (groupIds && groupIds.length > 1) ? <div className="input-group-append ">
                                                                            <span className="input-group-text bg-white" id="basic-addon2">FLOW/1 Edition</span>
                                                                        </div> :
                                                                            <div className="input-group-append ">
                                                                                <span className="input-group-text bg-white" id="basic-addon2">FLOW</span>
                                                                            </div>}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <hr className="my-3 d-block lightline" />
                                                    {(data && data.isBab) ? '' : <div className="row">
                                                        <div className="col-md-12 text-center">
                                                            {this.isArtVisible(data) ? '' : <div className='usernamelilac mb-3'>{"Art is hidden. Art listed for sale will not appear in drops."}</div>}
                                                            <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleSale((data && data.tokenId)) }}>Withdraw Listing</button>
                                                        </div>

                                                    </div>}

                                                </TabPanel>



                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="error-template userdisableview">
                                <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                                <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                                {(disabledMessage && disabledMessage.includes('admin')) ? <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4> : ''}

                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="mb-0">The Team</p> : ''}
                                {(disabledMessage && disabledMessage.includes('admin')) ? <p className="usernamelilac">DisruptArt</p> : ''}


                            </div>
                        </div>

                    </div>
                </div>}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        listedArt: state.creatorStore.withdrawn,
        listedArtFailed: state.creatorStore.withdrawnFailed,
        artDetail : state.creatorStore.getArtDetail,
        artDetailFailed : state.creatorStore.getArtDetailFailed
    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    listedArtForSale: creatorStoreActions.storeWithdrawArts,
    resetListSuccess: creatorStoreActions.resetStoreWithdrawArts,
    resetListFailed: creatorStoreActions.resetStoreWithdrawArtsFailed,
    getArtDetail : creatorStoreActions.storeGetArtDetail,
    resetgetArtDetailSuccess : creatorStoreActions.resetGetArtDetail,
    resetgetArtDetailFailed : creatorStoreActions.resetGetArtDetailFailed

}
const connectStoreWithdrawListing = connect(mapStateToProps, actionCreators)(StoreWithdrawListing)
export { connectStoreWithdrawListing as StoreWithdrawListing }
