import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination, toastr } from 'helpers';

import PubSub from 'pubsub-js';
import Toggle from 'react-toggle';

import { debounce } from 'utils';
import { adminServices, airDropService } from 'services';
import { toast } from 'react-toastify';
import { AirDropStatus } from 'helpers/enums';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
class FetchAirdrops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            airdropsList: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: '',
            openModal: false,
            selectedRole: "Select Role",
            isFetched: false,
            isAgree: false,
            wallet: [],
            range: "Select range",
            followCount: "0",
            followers: [],
            btnText: "Export All Users"
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.list()

    }

    static getDerivedStateFromProps(props, state) {
        if (props && props.allAirdrops && props.allAirdrops.data) {
            props.resetAirdrop()

                return {
                    airdropsList: props.allAirdrops && props.allAirdrops.data && props.allAirdrops.data.entity ? props.allAirdrops.data.entity : [],
                    totalCount: props.allAirdrops && props.allAirdrops.data && props.allAirdrops.data.count,
                    pageCount: props.allAirdrops && props.allAirdrops.data && props.allAirdrops.data.count / state.recordLimit,
                    isFetched: true,
                }

        }

    }

    list = () => {
        if (this.props && this.props.allUsers && this.props.allUsers.statusCode == 200) {
            let request = this.props.allUsers && this.props.allUsers.request ? this.props.allUsers.request : ''
            if (request.recordLimit || request.offSet && request.search) {
                this.setState({
                    recordLimit: 10,
                    offSet: 0,
                    search: ''
                }, () => {
                    this.callApi()
                })
            } else {


                this.callApi()
            }
        } else {
            this.callApi()
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    upGradeAsCreator = (userId, status) => {
        this.setState({ openModal: true, approvalId: userId, approvalStatus: status })
    }
    closeModal = () => {
        this.setState({ openModal: false, remarks: '', approvalId: '' })
    }


    handleRefresh = () => {
        this.setState({
            sortBy: "createdAt",
            search: '',
            sortOrder: "DESC",
            offSet: 0,
            
            recordLimit: 10,
           
        }, () => {
            this.callApi()
        })

    }
   
    callApi = () => {
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offSet,

            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        if (role) {
            obj['roleId'] = role


        }
        if (search) {
            obj['search'] = search



        }
        this.props.getAirdrops(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    
    
    
    gotoArt = (items) => {
        if (items && items.id) {
            const baseurl = process.env.REACT_APP_BASEPATH
            const id = items && items.id

            const navPath = (baseurl + `/admin/airdropDetail/${id}`)
            window.open(navPath, '_blank')
        }
    }
    statusChange = (items) => {
        const status = (items && items.status) ? true : false
        let obj = {
            isHidden : status
        }
        this.showLoader()
        adminServices.updateAirdropVisibilityStatus((items && items.id), JSON.stringify(obj)).then(res => {
            // if((res && res.statusCode) == 200){

            toast.success("Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })

    }
    isDisabled = (item) => {
        if(item.status === (AirDropStatus.DONE) || item.status === (AirDropStatus.WITHDRAWN)){
            return true
        }else{
            return false
        }
    }
    render() {
        console.log(this.state.airdropsList)
        const airdropList = this.state.airdropsList && this.state.airdropsList.map((items, index) => {
            return <tbody>
                <tr className={items && items.isDisruptArtUser ? "lilacbg1" : ''}>
                    <td>{items && items.id}</td>
                    <td><a className={' designLink userdescrip_adm'} title={items && items.artTitle} style={{display:'inline-block',maxWidth:"150px"}} onClick={() => { this.gotoArt(items) }}>{items && items.artTitle}</a> <a className={'designLink mr-2 float-right'} onClick={() => this.gotoArt(items)}><i className="fas fa-external-link-alt"></i></a></td>
                    <td>{moment(items && items.airDropDate).format('DD-MM-YYYY')}, {moment.utc(items && items.airDropDate).local().format("hh:mm:ss A")}</td>

                    <td><a className={' userdescrip_adm'} title={items && items.user && items.user.userName}>{items && items.user && items.user.userName && `@${items.user.userName}`}</a> </td>
                    <td>{(items && items.airDropType === airDropService.airDropType.LOCATED_USERS) ? 'Location based' : (items && items.airDropType === airDropService.airDropType.SELECTED_USERS) ? "Selected users" : "Public"}</td>
                    
                    <td>{(items && items.status === (AirDropStatus.ACTIVE)) ? "Active" : (items && items.status ===  (AirDropStatus.DONE)) ? "Claimed" : (items && items.status ===  (AirDropStatus.HIDDEN)) ? 'Hidden' : 'Closed'}</td>
                    <td><a className={'card flowtop'} style={{ display: "inline-block" }}>
                        <Toggle checked={items && items.status} onChange={() => this.statusChange(items)} disabled={this.isDisabled(items)} />
                        {!this.isDisabled(items) ? <span className="copycon">
                            <span className="uparrow"></span>
                            { items && items.status ? ("Hide Airdrop") : ("Visible Airdrop")}
                        </span> : ''}</a></td>
                        <td>{(items && items.status ===  (AirDropStatus.HIDDEN)) ? (items && items.hiddenByAdmin) ? 'Admin' : 'User' : '-'}</td>

                    <td>{moment(items && items.createdAt).format('DD-MM-YYYY')}, {moment.utc(items && items.createdAt).local().format("hh:mm:ss A")}</td>

                    <td className="text-center"><a href={process.env.REACT_APP_FLOW_TRANSACTION + `/${items && items.txnId}`} target="_blank" className="designLink btn btn-primary" >View</a></td>


                </tr>
            </tbody >

        })
       
        
        

        return (

            <Fragment>
                
                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Airdrops</h2>
                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                                <div className="row">
                                    <div className="col-3">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-9 text-right">
                                        <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>ART NAME</th>
                                        <th>AIRDROP STARTS ON</th>
                                        <th>LISTED BY</th>
                                        <th>TYPE</th>
                                        <th>STATUS</th>
                                        <th>VISIBLE STATUS</th>
                                        <th>HIDDEN BY</th>
                                        <th>LISTED ON</th>
                                        <th>VIEW TRANSACTION </th>
                                    </tr>
                                </thead>

                                {airdropList}


                            </table>
                            {this.state.airdropsList && !this.state.airdropsList.length > 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.airdropsList && this.state.airdropsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allAirdrops: state.airdrop.allairdropList,
        isFetchAirdrop: state.airdrop.isListAirDrop,
        
    }
}

const actionCreators = {
    getAirdrops: airdropActions.getAirdropListing, 
    resetAirdrop: airdropActions.resetAirDropList,
    
}

const connectFetchAirdrops = connect(mapStateToProps, actionCreators)(FetchAirdrops)

export { connectFetchAirdrops as FetchAirdrops }
