import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { configPath } from "../config";

class Sxsw extends Component {
	render() {
		return (
			<Fragment>
				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="w-auto  text-center">SXSW - BLOCK PARTY</h1>
							</div>
						</div>
					</div>
					<hr />

					<div className='row statcontsec'>
						<div className='col-md-12 text-center mt-5'>
							<h3 className='usernamelilac'>BLOCK PARTY – Blockchain for the Culture.</h3>
							<p>40 Acres DAO and Disrupt Art are uniting BIPOC creators, entrepreneurs  at SXSW to change the narratives and constructs through the power of Web3. <br/><br/> Join us<br/><br/> Drinks and Food are included.</p>
							<a href="https://www.eventbrite.com/e/block-party-disrupt-art-tickets-296756716327?ref=estw" className="btn btn-primary">RSVP  now</a>							
						</div>
						
						<div className='col-md-12 my-5  text-center'>
						<hr/>
						<a href="https://www.eventbrite.com/e/block-party-disrupt-art-tickets-296756716327?ref=estw" target={'_blank'}><img src={`/${configPath.imageAssets}/SXSW-Deck-External.png`} alt="" className='mw-100 my-5' />
						</a>
						</div>
						
					</div>


					
				
				</div>

			</Fragment>
		)
	}
}
export { Sxsw }