import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices } from "services";
import PubSub from 'pubsub-js';
import { connect } from "react-redux";
import { announcementActions } from "store/actions";

class AddEvents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            eventName : '',
            events : [],
            url : '',
            updateId : '',
            submitted : false,
            isError : false,
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.props.getAllevents()
    }
    static getDerivedStateFromProps(props, state) {

        if((props && props.allevents && props.allevents.statusCode === 200)){
            props.resetAllgetEvents()
            return {
                events : props && props.allevents && props.allevents.data && props.allevents.data.glittering_texts,
                updateId : '',
                submitted : false,
                isError : false,
            }
        }
        if ((props && props.addEventname && props.addEventname.statusCode === 201)) {
            props.resetAddEvents()
            props.getAllevents()
            return {
                eventName : '',
                url : '',
                updateId : '',
                submitted : false,
                isError : false,
            }
        }

        if((props && props.Selectevent && props.Selectevent.statusCode === 200)){
            props.resetoneEvent()
            return {
                eventName : props && props.Selectevent && props.Selectevent.data && props.Selectevent.data.details && props.Selectevent.data.details.title,
                url : props && props.Selectevent && props.Selectevent.data && props.Selectevent.data.details && props.Selectevent.data.details.url,
                updateId : props && props.Selectevent && props.Selectevent.data && props.Selectevent.data.details && props.Selectevent.data.details.id,
                isError : false,
            }
        }

        if((props && props.deleteEve && props.deleteEve.statusCode === 200)){
            props.resetDeleteEvent()
            props.getAllevents()
            return {
                eventName : '',
                url : '',
                updateId : '',
                submitted : false,
                isError : false,
            }
        }

        if((props && props.updatedEvent && props.updatedEvent.id)){
            props.resetUpdatedEvent()
            props.getAllevents()
            toast.success("Event updated")
            return {
                eventName : '',
                url : '',
                updateId : '',
                submitted : false,
                isError : false
            }
        }
    }
    
    handleEvent = (e) => {
        if(this.alertEventTitle(e.target.value)){
            this.setState({
                eventName : e.target.value,
                isError : false
            })
        }else{
            this.setState({
                isError : true,eventName : (e.target.value.slice(0,50))
            })
        }
    }
    handleUrl = (e) => {
        this.setState({
            url : e.target.value
        })
    }
    eventsDeleter(e,item) {
        console.log(item)
        if ((item && item.id)) {
            this.props.deleteEvent(item.id)
        }else{
            toast.error("Something went wrong")
        }

    }
    eventUpdate = (items) => {
        if ((items && items.id)) {
            this.props.getoneevent(items.id)
        }else{
            toast.error("Something went wrong")
        }
    }
    checkEventNameLength = (value) => {
        if (((value && value.length) <= 50) && ((value && value.length) >= 5)) {
            return true
        } else {
            return false
        }
    }
    alertEventTitle = (value) => {
        if (((value && value.length) <= 50)) {
            return true
        } else {
            return false
        }
    }
    checkURL(value) {
        const urlReg = /https:\/\/.*/;
        if (urlReg.test(value)) {
            return true;
        }
        return false;
    }
    cancelAction = () => {
        this.setState({
            eventName : '',
                url : '',
                updateId : '',
                isError : false,
                submitted : false
        })
    }
    addEvent = (e) => {
        e.preventDefault()
        this.setState({
            submitted : true
        })
        const {eventName,url} = this.state
        if(eventName){
            if(url){
            if(this.checkEventNameLength(eventName)){
                if(this.checkURL(url)){
                    let obj = {
                    title : eventName,
                    url
                    }
                    this.props.addevent(JSON.stringify(obj))
                }else{
                    toast.error("Please enter valid url")
                }
            }else{
                toast.error("Event name must be at least 5 characters min and 50 characters max")
            }
        }else{
            toast.error("Please enter event url")
        }
        }else{
            toast.error("Please enter event name")
        }
    }
    updateEvent = (e) => {
        e.preventDefault()
        this.setState({
            submitted : true
        })
        const {eventName,url,updateId} = this.state
        if(eventName){
            if(url){
            if(this.checkEventNameLength(eventName)){
                if(this.checkURL(url)){
                    if(updateId && updateId != ''){
                    let obj = {
                    title : eventName,
                    url
                    }
                    this.props.updateEvent(JSON.stringify(obj),updateId)
                }else{
                    toast.error("Something went wrong")
                }
                }else{
                    toast.error("Please enter valid url")
                }
            }else{
                toast.error("Event name must be at least 5 characters min and 50 characters max")
            }
        }else{
            toast.error("Please enter event url")
        }
        }else{
            toast.error("Please enter event name")
        }
    }
    render() {

        const {
            events,updateId, isError
        } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Add Events </h2>
                        </div>
                    </div>


                    
                        <div className="purchasebox p-0 mb-5">
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <textarea className={isError ? "form-control descdan" :  "form-control"} placeholder="Enter your event name" name="eventName" value={this.state.eventName} onChange={this.handleEvent}/>
                                    {<div className="mandatory-req">Min 5 characters and Max 50 characters</div>}
                                    {/* {(this.state.eventName) && (isError) &&  <div className="mandatory-req">Looks </div>} */}
                                </div>
                                <div className="mb-3">
                                    <input className="form-control " placeholder="Enter event url" name="url" value={this.state.url} onChange={this.handleUrl}/>
                                </div>
                                
                                {(updateId && updateId != '') ? 
                                    <div className="text-center">
                                    <button className="btn btn-primary mr-3" type="button" onClick={this.cancelAction}>Cancel</button>

                                    <button className="btn btn-primary" type="button" onClick={this.updateEvent}>Update Event</button>
                                </div> :
                                <div className="text-center">
                                    <button className="btn btn-primary" type="button" onClick={this.addEvent}>Add Event</button>
                                </div>}

                                </div>
                                </div>
                                </div>
                                
                                {(events && events.length > 0) && events.map((items) => ( 
                                <div className="purchasebox p-0 mb-1">
                        <div className="card">
                            <div className="card-body">
                            <div className="draggable-item row align-items-center">
                                <div className="col-md-10 col-8">
                                    {items.title} <br/>
                                    <small className="font-italic mb-3 w-100 d-block">{items.url}</small>
                                    </div>
                                    <div className="col-md-2 col-4 text-center">
                                    <button type="button" className="mr-3 btn btn-primary btn-sm " onClick={() => this.eventUpdate(items)}> <i className="fa fa-pencil" aria-hidden="true" ></i></button>

                                        <button type="button" className=" btn btn-primary btn-sm"  onClick={(e) => {this.eventsDeleter(e,items)}}><i className="fas fa-times"></i></button>
                                    </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    </div></div></div>))}
                                
                            
                        </div>

                   
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        allevents : state.announcement.getGlitEve,
        addEventname : state.announcement.addGlitEve,
        deleteEve : state.announcement.eveDel,
        Selectevent : state.announcement.selectedEvent,
        updatedEvent : state.announcement.updateEvent,
    }
}

const actionCreators = {
    getAllevents : announcementActions.getAllGlitterEvents,
    resetAllgetEvents : announcementActions.resetGetGlitterEve,
    addevent : announcementActions.addGlitterEvent,
    resetAddEvents : announcementActions.resetAddedGlitEvent,
    deleteEvent : announcementActions.deleteGlitterEvent,
    resetDeleteEvent : announcementActions.resetDeleteGlitEvent,
    getoneevent : announcementActions.getOneGlitterEvent,
    resetoneEvent : announcementActions.resetOneGlitterEvent,
    updateEvent : announcementActions.updateGlitterEvent,
    resetUpdatedEvent : announcementActions.resetUpdateEvent
}

const connectAddEvents = connect(mapStateToProps, actionCreators)(AddEvents)

export { connectAddEvents as AddEvents }