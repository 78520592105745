import { mintConstants } from "../../constants"
import { adminServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const mintSlotActions = {
    getAllMintslots,
    resetAllMintSlots,
    updateMintSlot,
    resetUpdateMintSlot
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAllMintslots(){
    return dispatch => {
        showLoader()
        adminServices.getAllMintslots()
        .then(slots => {
            if(slots){
                dispatch({type:mintConstants.GET_MINT_SLOTS,allslots:slots})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetAllMintSlots(){
    return dispatch => {
        dispatch({type:mintConstants.GET_MINT_SLOTS,allslots:[]})
    }
}
function updateMintSlot(obj){
    return dispatch => {
        showLoader()
        adminServices.updateMintSlot(obj)
        .then(slot => {
            if(slot){
                dispatch({type:mintConstants.UPDATE_MINT_SLOT,updatedSlot:slot})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetUpdateMintSlot(){
    return dispatch => {
        dispatch({type:mintConstants.UPDATE_MINT_SLOT,updatedSlot:[]})
    }
}


