import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { artActions, dropActions, userAction } from 'store/actions';
import moment from 'moment'
import { flowDecimal, ReactPagination, toastr } from 'helpers';
import Toggle from 'react-toggle';
import { toast } from 'react-toastify';
import { adminServices, artServices, fetchService, userServices } from 'services';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import Modal from 'react-modal'
import { flowGetLatestBlock } from 'utils/flow-latest-block';

class FetchSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            creatorsList: [],
            totalCount: '',
            offSet: 0,
            sortBy: "saleDate",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: '',
            openModal: false,
            selectedRole: "Select Role",
            saleList: []
        }
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.getSaleDetail || (props && props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.entity)) {
            return {
                arts: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.entity ? props.getSaleDetail.data.entity : [],
                totalCount: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.count,
                pageCount: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.count / state.recordLimit,

            }
        }

    }
    handleRefresh = () => {
        this.setState({ sortBy: "saleDate", search: '', sortOrder: "DESC", offSet: 0, title: '', sDate: '', eDate: '', ownerName: '' }, () => {
            this.callApi()
        })
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value
        }, () => { this.callApi() })
    }
    handleSortBy = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }

    callApi = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }

        this.props.getArtSaleList(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {
            this.setState({ isShow: false, })
        } else {
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }
    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    statusChange = (items) => {
        const status = (items && items.status) ? 0 : 1
        let obj = {
            status: status
        }
        this.showLoader()
        artServices.artStatusChange((items && items.id), JSON.stringify(obj)).then(res => {

            toast.success("Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })

    }
    fetchArt = (url) => {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return true
            } else {
                return false
            }
        }).catch((err) => {

        })

    }
    gotoArt = (items) => {
        const baseurl = process.env.REACT_APP_BASEPATH
        const id = ((items && items.artIds && items.artIds.length )> 1 ? (items.artIds[0]) : (items.artIds) )
        const navPath =  (baseurl + `/art/${id}`)
        window.open(navPath, '_blank')

    }

    isDapperPurchase = (items) => {
        if(items && items.storefrontListingResourceId && items.withDapper){
            return 'USD'
        }else{
            return 'FLOW'
        }
    }
    render() {
        const { saleList, arts } = this.state
        const salesList = arts && arts.map((items, index) => {
            return <tbody>
                <tr>
                <td>{moment(items && items.saleTimestamp).format(" DD-MM-YYYY hh:mm:ss A")}</td>
                    <td><a className="designLink userdescrip_adm" style={{maxWidth:"150px",display:"inline-block"}} title={items && items.title} onClick={() => { this.handleView(items) }}>{items && items.title}</a></td>
                    <td><a className={'designLink mr-2 '} onClick={() => this.gotoArt(items)} ><i className="fas fa-external-link-alt"></i></a></td>
                    <td>{items && items.seller && items.seller.userName && `@${items.seller.userName}`}</td>
                    <td>{items && items.buyer && items.buyer.userName ? `@${items.buyer.userName}` : (items.buyer.walletAddress) ? (items.buyer.walletAddress) : '-'}</td>
                    <td>{items && items.seller && items.seller.walletAddress ? items.seller.walletAddress : '-'}</td>
                    <td>{items && items.buyer && items.buyer.walletAddress ? items.buyer.walletAddress : '-'}</td>

                    <td>{(items && items.sale != null) ? (items && items.sale.sellingPricePerArt && ((flowDecimal(parseFloat(items.sale.sellingPricePerArt)) + ' ' + this.isDapperPurchase(items)))) : (items && items.auction && items.auction.auctionSettlementPrice && (flowDecimal(parseFloat(items.auction.auctionSettlementPrice)) + " " + "FLOW"))}</td>
                    {/* <td>{(items && items.sale != null) ? (items && items.sale && items.sale.count) : '1'}</td> */}
                    {/* <td><Toggle checked={items && items.status} onChange={() => this.statusChange(items)} /></td> */}


                </tr>
                {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                    <td colSpan="9" className="px-3 graybgbox">
                        <h4 className="mb-0">Detail</h4>
                        <hr />
                        {(items && items.sale != null) ?
                            <div className="row">

                                {/* <div className="col-md-4 mb-3">

                                    <div className="comboxgray">
                                        Marked Price Per Art&nbsp; {items && items.sale && items.sale.markedPricePerArt && (items.sale.markedPricePerArt + " " + "FLOW")} </div>


                                </div> */}


                                <div className="col-md-4 mb-3">

                                    <div className="comboxgray">
                                        Selling Price Per Art&nbsp; {items && items.sale && items.sale.sellingPricePerArt && (flowDecimal(parseFloat(items.sale.sellingPricePerArt)) + " " + this.isDapperPurchase(items))} </div>


                                </div>

                                <div className="col-md-4 mb-3">

                                    <div className="comboxgray">
                                        Total Selling Price &nbsp; {items && items.sale && items.sale.totalSellingPrice && (flowDecimal(parseFloat(items.sale.totalSellingPrice)) + " " + this.isDapperPurchase(items))} </div>


                                </div>

                            </div> : <div className="row">

                                <div className="col-md-4 mb-3">

                                    <div className="comboxgray">
                                        Auction Starting Price&nbsp; {items && items.auction && items.auction.auctionStartPrice && (flowDecimal(parseFloat(items.auction.auctionStartPrice)) + " " + this.isDapperPurchase(items))} </div>


                                </div>
                                <div className="col-md-4 mb-3">

                                    <div className="comboxgray">
                                        Auction Settlement Price&nbsp; {items && items.auction && items.auction.auctionSettlementPrice && (flowDecimal(parseFloat(items.auction.auctionSettlementPrice)) + " " + this.isDapperPurchase(items))} </div>


                                </div>
                            </div>}




                    </td>
                </tr>
                }
            </tbody >

        })

        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Sales</h2>

                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                    </div>

                                    <div className="col-6 text-right">
                                        <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>DATE</th>
                                        <th>ART NAME</th>
                                        <th>IMAGE</th>
                                        <th>SELLER NAME</th>
                                        <th>BUYER NAME</th>
                                        <th>SELLER ADDRESS</th>
                                        <th>BUYER ADDRESS</th>
                                        <th>PRICE (PER EDITION)</th>
                                        {/* <th>PURCHASED COUNT</th> */}
                                        {/* <th>STATUS</th> */}
                                    </tr>
                                </thead>
                                {salesList}

                            </table>
                            {arts && !arts.length > 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {arts && arts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange }} />}

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSaleDetail: state.drop.saleArtList,
    }
}

const actionCreators = {
    getArtSaleList: dropActions.getArtSaleList,

}

const connectSale = connect(mapStateToProps, actionCreators)(FetchSale)

export { connectSale as FetchSale }