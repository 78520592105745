import { discoverConstants } from "../../constants";

const initialState = {
    spotArts:[],
    featureArts : [],
    featureCreators : [],
    featureCollectors : [],
    maxCounts : [],
    updatedSettings : [],
    randomCreators : [],
    randomCreatorsFailed : '',
    showcaseCreators : [],
    showcaseCreatorsFailed : ''
}
export function discover(state=initialState,action){
    switch(action.type){
        case discoverConstants.GET_SPOTLIGHT_ARTS:
            return {
                ...state,
                spotArts : action.spotArts
            }
        case discoverConstants.GET_FEATURE_ARTS:
            return {
                ...state,
                featureArts : action.featureArts
            }     
        case discoverConstants.GET_FEATURE_CREATOR:
            return {
                ...state,
                featureCreators : action.featureCreators
            }
        case discoverConstants.GET_FEATURE_COLLECTOR:
            return {
                ...state,
                featureCollectors : action.featureCollectors
            }
        case discoverConstants.GET_DISCOVER_MAXIMUM_COUNT_LIMIT:
            return {
                ...state,
                maxCounts : action.maxCounts
            }   
        case discoverConstants.UPDATE_DISCOVER_SETTINGS:
            return {
                ...state,
                updatedSettings : action.updatedSettings
            }  
        case discoverConstants.GET_RANDOM_CREATORS:
            return {
                ...state,
                randomCreators : action.randomCreators
            } 
        case discoverConstants.GET_RANDOM_CREATORS_FAILED:
            return {
                ...state,
                randomCreatorsFailed : action.randomCreatorsFailed
            }
        case discoverConstants.GET_ALL_SHOW_CASE_CREATORS:
            return {
                ...state,
                showcaseCreators : action.showcaseCreators
            }
        case discoverConstants.GET_ALL_SHOW_CASE_CREATORS_FAILED:
            return {
                ...state,
                showcaseCreatorsFailed : action.showcaseCreatorsFailed
            }
            default:
                return state
    }
}