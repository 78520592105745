import React, { Component, Fragment, useState } from 'react'
import { connect } from 'react-redux';

import PubSub from 'pubsub-js';
import Modal from 'react-modal'
import { dapperwalletConfig, switchAuthBlocto, unauthenticateWallet } from 'helpers/switchauthdapper';
import { connectBlocto, connectDapper } from 'helpers/getFlowUsd';
import { flowActions, walletActions } from 'store/actions';
import { configPath } from '../config';


var timeout;
const customStyles = {
    content: {
        top: '10%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',


    },
};
class SwitchAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            show: false,           
        }
       
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        
    }
    componentDidUpdate(props,state){
        // if ((this.props.walleterror && this.props.walleterror.length > 0) && (props.walleterror) != (this.props.walleterror)) {
        //     this.props.resetwalleterror()
            
        //     this.closeAlert()
        // }
        // if ((this.props.walletsuccess && this.props.walletsuccess.length > 0) && (props.walletsuccess) != (this.props.walletsuccess)) {
        //     this.props.resetwalletsuccess()
            
        //     this.closeAlert()
        // }
    }
    

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }



    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    closeModalOnly = () => {
        this.setState({
            isOpen: false
        })
        if(this.props && this.props.closeModalWithProps){
            this.props.closeModalWithProps()
        }
        this.props.closeModal()
        if(this.props && this.props.isListPage){
            this.props.isListPage()
        }
        if(this.props && this.props.errorModal){
            this.props.errorModal()
        }
    }
    closeModal = () => {
        this.setState({
            isOpen: false
        })
        this.props.closeModal()

    }
    handleBlocto = async () => {
        this.closeModal()
        const disconnect = await unauthenticateWallet()
        if (disconnect) {
            await switchAuthBlocto()
            await this.props.disconnect()
            
            try{
                this.showLoader()
                if(this.props && this.props.handleConnectWalletMessage){
                    this.props.handleConnectWalletMessage()
                }
                const wallet = await connectBlocto()
                if (wallet && wallet.walletAddress) {
                    this.setState({ isSwitchWallet: true })
                        this.props.switchWalletConnected(wallet)

                } else {
                    this.hideLoader()
                    this.props.handleSwitchAccFailed(wallet)
 
                }
            }catch (e) {
                this.hideLoader()
                this.props.handleSwitchAccFailed(e)

            }
        }
    }
    handleDapper = async () => {
        this.closeModal()
        const disconnect = await unauthenticateWallet()
        if (disconnect) {
            await dapperwalletConfig()
            await this.props.disconnect()
            try{
                this.showLoader()
                if(this.props && this.props.handleConnectWalletMessage){
                    this.props.handleConnectWalletMessage()
                }
                const wallet = await connectDapper()
                if (wallet && wallet.walletAddress) {
                    this.setState({ isSwitchWallet: true })
                        this.props.switchWalletConnected(wallet)

                } else {
                    this.hideLoader()
                    this.props.handleSwitchAccFailed(wallet)
                }
            }catch (e) {
                this.hideLoader()
                this.props.handleSwitchAccFailed(e)
            }

        }
    }

    toggle = () => this.setState((currentState) => ({show: !currentState.show}));

  
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()
        }
        const pathname = window.location.pathname

      

        return (
            <Fragment>
                <Modal
                    isOpen={this.state.isOpen}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Switch Modal"
                    ariaHideApp={false}>
                    <div className="custom-ui reportpop py-3 px-3 minw-28rem">
                    <h4 className='mb-3 tex-center mt-2  px-5'>CONNECT YOUR WALLET</h4>
                    {pathname && pathname.includes('invitee-guest') ? '' : <button onClick={this.closeModalOnly} className="btn btn-primary mb-3 clsbtn">X</button>}
                        <div className="">
                            <div className="form-group text-center">
                                <div className="custom-btn text-center mt-5 cwpop">                                   
                                        
                                        <h5 className='text-uppercase '>TO MINT, List For Sale/AIRDROP,</h5>
                                        <h5 className='mb-3 text-uppercase '>CLAIM AIRDROP or PURCHASE</h5>
                                        
                                            <div className='purchasebox cursor-pointer' onClick={this.handleDapper}>
                                                <div className='walletbox'>
                                                { <img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="Disrupt Now" className="logoaw " />}
                                                    <span>CONNECT DAPPER WALLET</span>
                                                </div>
                                            </div>
                                      
                                        <p>Dapper wallet supports USD/Credit Card for NFT purchases</p>
                                       <hr className='my-4'/>
                                       
                                       <div className='showblctxt' onClick={this.toggle}>{this.state.show ? 'Hide' : 'Show'} Blocto</div>
                                       {this.state.show && <div className='showblc'>
                                       <h5 className='mb-3 text-uppercase '>To Relist For sale in USD price</h5>
                                                                        
                                            <div className='purchasebox cursor-pointer' onClick={this.handleBlocto}>
                                                <div className='walletbox'>
                                                { <img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="Disrupt Now" className="logoaw "/>}
                                                    <span>CONNECT BLOCTO WALLET</span>
                                                </div>
                                            </div>
                                            Connect Blocto wallet to "Withdraw" your current sale/auction listings in FLOW & Relist for sale in USD price

                                            </div>
                                            }
                                        </div>
                                        
                                   
                                </div></div>
                        </div>
                    

                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        wallet: state.wallet.wallet,
        walleterror: state.wallet.walleterr,
        walletsuccess: state.wallet.walletsuc,
    }
}

const actionCreators = {
    disconnect: flowActions.disconnectBloctoWallet,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetwalleterror: walletActions.resetWalletError,
    resetwalletsuccess: walletActions.resetWalleSuccess,
    walletSuccessSetup: walletActions.walletSuccess,
    walletnotsetup: walletActions.walletError,
}

const connectSwitchAccount = connect(mapStateToProps, actionCreators)(SwitchAccount)

export { connectSwitchAccount as SwitchAccount }