import React from "react";
import { joyrideConstants } from "../../constants"
import { isWebLogin } from "helpers";
import history from "helpers/history";

const home_login_steps = [
    
    {
        content: <div><p>Choose Blocto and enter your same email address as login. 
        </p><p className="mb-0">Verify with the code sent and Get Your Flow Address </p></div>,
        styles: {
            options: {
                width: 300,
                padding: 10,
            },
        },
        target: '.connect_wallet',
        title: 'Connect wallet',
        placement : "left",
        disableBeacon: true,
        spotlightClicks : true,
        spotlightPadding: 2,
        disableOverlayClose: false,
    },
    
]
const home_steps = [
    
    {
        content: 'Login and create a profile',
        styles: {
            options: {
                width: 300,
            },
        },
        target: '.login_dist',
        title: 'Login',
        placement : "left",
        spotlightPadding: 0,
        disableBeacon: true,
        spotlightClicks : true,
    },
]
const home_wallet_steps = [
    
    {
        content: <div><p>Claim first NFT go to Air drops.
        </p><p className="mb-0">Claim to receive a free collectible as a gift for joining.</p></div>,
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 5,
        target: '.community_airdrop',
        title: 'Airdrop',
        placement : "left",
        disableBeacon : true,
        spotlightClicks : true,
    },
    {
        content: 'Fund your Wallet go Coinbase send money to your flow address!',
        styles: {
            options: {
                width: 300,
            },
        },
        spotlightPadding: 2,
        target: '.fund_wallet_menu',
        title: 'Fund your Wallet ',
        placement : "left",
        spotlightClicks : true,
        
    },

]
export const joyrideActions = {
   getorUpdateJoyride,
   resetJoySteps
}

function getorUpdateJoyride(page,isWallet){
    return dispatch => {
        if (page === "home_login") {
            if(history && history.location && history.location.pathname && history.location.pathname.includes('discover')){
                const getLocal = localStorage.getItem('joy-ride-storage')
                const detail = JSON.parse(getLocal)
                if (detail) {
                    detail.home_login = true
                }
                const checkCompletedRide = localStorage.getItem('completed_ride')
                const checkCompltedParse = JSON.parse(checkCompletedRide)

                    if(!isWebLogin()){
                        if(checkCompltedParse && !checkCompltedParse.isLoginShowed){
                            dispatch({type : joyrideConstants.GET_JOYRIDE_STEPS,joySteps : home_steps,isLoginShowed : true,isConnectWalletShowed : false,isAfterConnectWalletShowed : false})
                        }
                        
                    }else if(isWebLogin() && !isWallet){
                        if(checkCompltedParse && !checkCompltedParse.isConnectWalletShowed){
                            dispatch({type : joyrideConstants.GET_JOYRIDE_STEPS,joySteps : home_login_steps,isLoginShowed : true,isConnectWalletShowed : true,isAfterConnectWalletShowed : false})

                        }
                    }
                    else{
                        if(checkCompltedParse && !checkCompltedParse.isAfterConnectWalletShowed){
                            setTimeout(() => {
                                dispatch({type : joyrideConstants.GET_JOYRIDE_STEPS,joySteps : home_wallet_steps, isLoginShowed : true,isConnectWalletShowed : true,isAfterConnectWalletShowed : true})
                            },500)
                        }
                    }
                
                
            }
            

        }
        
    }
}
function resetJoySteps(){
    return dispatch => {
        dispatch({type : joyrideConstants.GET_JOYRIDE_STEPS, joySteps : []})
    }
}






