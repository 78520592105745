import * as fcl from "@onflow/fcl";
import * as t from "@onflow/types";


export const dapperTransferTokens = async (tokenIds) => {
    const mode = process.env.REACT_APP_ENVIRONMENT_MODE
    let script;
    const testnetTransferTokenScript = `
    // DisruptArt.io NFT Token Smart Contract
    // Owner     : DisruptionNowMedia www.disruptionnow.com
    // Developer : www.BLAZE.ws
    // Version: 0.0.1
    
    import DisruptArt from 0x439c2b49c0b2f62b
    
    transaction(tokenIds: [UInt64]) {
    
        prepare(account: AuthAccount) {
            var count = 0
            let acct = getAccount(0xcdae9ecedcf76fed)
            let recipientRef = acct.getCapability(DisruptArt.disruptArtPublicPath)
            .borrow<&{DisruptArt.DisruptArtCollectionPublic}>()
            ?? panic("Could not borrow capability from public collection")
            let tokens = tokenIds as [UInt64]
            while count < tokens.length {
                let sellerRef = account.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath)!
                let tokenId  = tokens[count] 
                let token <- sellerRef.withdraw(withdrawID: tokenId)
                count = count + 1 
                recipientRef.deposit(token: <-token)
            }
    
        }
    }`;
    const mainnetTransferTokenScript = `
    // DisruptArt.io NFT Token Smart Contract
    // Owner     : Disrupt Art www.Disrupt.Art
    // Developer : www.BLAZE.ws
    // Version: 0.0.1

    import DisruptArt from 0xcd946ef9b13804c6

    transaction(tokenIds: [UInt64]) {

        prepare(account: AuthAccount) {
            var count = 0
            let acct = getAccount(0x95cca0eeb03dbfff)
            let recipientRef = acct.getCapability(DisruptArt.disruptArtPublicPath)
            .borrow<&{DisruptArt.DisruptArtCollectionPublic}>()
            ?? panic("Could not borrow capability from public collection")
            let tokens = tokenIds as [UInt64]
            while count < tokens.length {
                let sellerRef = account.borrow<&DisruptArt.Collection>(from: DisruptArt.disruptArtStoragePath)!
                let tokenId  = tokens[count] 
                let token <- sellerRef.withdraw(withdrawID: tokenId)
                count = count + 1 
                recipientRef.deposit(token: <-token)
            }

        }
    }`;
    if(mode === "production"){
        script = mainnetTransferTokenScript
      }else{
        script = testnetTransferTokenScript
      }
    try {
        const blockResponse = await fcl.send([
            fcl.getBlock(),
        ])
        const block = blockResponse.block;
        const blockHeight = block && block.height;
        
        const res = await fcl.send([
            fcl.transaction(script),
            fcl.args([
                fcl.arg(tokenIds, t.Array(t.UInt64))
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ])
        const { transactionId } = res;
        const result = new Promise((resolve, reject) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        const {errorMessage} = transaction;
                        if(errorMessage && errorMessage !== ''){
                            reject({
                                status: 400,
                                data:{
                                    "message": "Transaction failed",
                                    "Error": String(errorMessage)
                                }
                            })
                        }
                        resolve({
                            status: 200,
                            data: {
                                "message": "Flow Token Transfer Raised",
                                "transactionId": transactionId,
                                "blockHeight": blockHeight,
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    }
    catch (e) {
        console.error('Failed to move tokens',e);
        return {
            status: 400,
            data: { "message": "Exception happens", "Error": String(e) }
        };
    }
}
