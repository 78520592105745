import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { artActions, categoryActions, dropActions, userAction } from 'store/actions';
import moment from 'moment'
import { flowDecimal, ReactPagination } from 'helpers';
import Toggle from 'react-toggle';
import { adminServices, artServices, fetchService } from 'services';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import { debounce, isEmpty } from 'utils';
import history from 'helpers/history';
import Select from 'react-select';
import { SolarSystemLoading } from 'react-loadingg';


const rankFilter = [
    { value: 1, label: 'Default', rank: 0 },
    { value: 2, label: 'Top Priority', rank: 1 },
    { value: 3, label: 'Priority', rank: 2 },
    { value: 4, label: 'Top Priority & Priority', rank: 4}
]
const customStylesforAll = {
    control: (base, state) => ({
        ...base,
        minWidth: "100px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
const customStyles = {
    control: (base, state) => ({
        ...base,
        minWidth: "200px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",

        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
const allTypes = [
    { value: 1, label: "All" },
    { value: 2, label: "Flat Price" },
    { value: 3, label: "Auction" },

]
class FetchDrops extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 30,
            pageCount: '',
            search: '',
            dropsList: [],
            isFetched: false,
            isDropOnly: false,
            allCategories: [],
            selectedCategory: { value: 0, label: <span>All</span>, name: "All" },
            selectedRank: { value: 1, label: 'Default', rank: 0 },
            title: '',
            ownerName: '',
            loader : true

        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 300);
        this.rangeValue = React.createRef()
    }
    componentDidMount() {
        this.callApi()
        this.props.getAllCategories()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.allDrops && props.allDrops.statusCode) === 200) {
            props.resetGetDrops()
            return {
                dropsList: props.allDrops && props.allDrops.data && props.allDrops.data.drops ? props.allDrops.data.drops : [],
                totalCount: props.allDrops && props.allDrops.data && props.allDrops.data.count,
                pageCount: props.allDrops && props.allDrops.data && props.allDrops.data.count / state.recordLimit,
                isFetched: true,
                loader : false
            }
        }
        if (props && props.allCategories && props.allCategories.statusCode === 200) {
            props.resetGetCategories()
            if (props.allCategories.data && props.allCategories.data.tags) {
                let array = [{ value: 0, label: <span>All</span>, name: 'All', iconClass: <i className='fa fa-pencil'></i> }]
                props.allCategories.data.tags && props.allCategories.data.tags.length > 0 && props.allCategories.data.tags.map((items) => {
                    let obj = {
                        value: items && items.id,
                        label: <span><i className={items && items.iconClass}></i>&nbsp; {items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)}</span>,
                        name: items && items.name,
                        iconclass: <i className={items && items.iconClass}></i>
                    }
                    array.push(obj)
                })
                return {
                    allCategories: array
                }
            }

        }
        return null


    }
    componentDidUpdate(props, state) {
        if ((this.props && this.props.priorityStatusUpdated && this.props.priorityStatusUpdated.statusCode === 200) && ((this.props.priorityStatusUpdated) != (props.priorityStatusUpdated))) {
            props.resetUpdateStatus()
            this.showLoader()
            this.callApi()
            toast.success("Updated successfully")
        }
    }
    handleRefresh = () => {
        this.setState({
            sortBy: "createdAt",
            search: '',
            sortOrder: "DESC",
            offSet: 0,
            title: '',
            sDate: '',
            eDate: '',
            ownerName: '',
            forcePg: 0,
            selectedCategory: { value: 0, label: <span>All</span>, name: "All" },
            selectedRank: { value: 1, label: 'Default', rank: 0 }
        }, () => {
            this.callApi()
        })
    }




    callApi = () => {
        const {
            sDate,
            eDate,
            selectedUserId,
            title,
            ownerName,
            isDropOnly,
            selectedCategory,
            selectedRank
        } = this.state

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (sDate && eDate) {
            obj['starting'] = sDate
            obj['ending'] = eDate
        }
        if (ownerName) {
            obj['ownerName'] = ownerName

        }
        if (selectedUserId) {
            obj["ownerId"] = selectedUserId
        }
        if (title) {
            obj['title'] = title
        }
        if (isDropOnly) {
            obj['isDropOnly'] = isDropOnly
        }
        if (selectedCategory && selectedCategory.value) {
            obj["artType"] = selectedCategory.name
        }
        if (selectedRank) {
            if(selectedRank.rank == 4){
                obj['isDropOnly'] = true
            }else{
                obj['priorityRank'] = selectedRank.rank
            }
        }
        this.props.getAllDrops(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
    }
    handleProceed = (e) => {
        e.preventDefault();
        const { sDate, eDate, ownerName, title } = this.state
        // if(

        //     isEmpty(ownerName )&& 
        //     isEmpty(title)
        //     ){
        //         toast.info("Please provide a search term")
        //     }
        //  else {

        this.callApi()
        // }
    }
    handlePickUser = (e) => {
        // this.debouncedFetch(e.target.value);
        this.setState({ ownerName: e.target.value, offSet: 0 })

    }
    handlePickArts = (e) => {
        // this.debouncedFetch(e.target.value);
        this.setState({ title: e.target.value, offSet: 0 })

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeModal = () => {
        this.setState({ openModal: false, })
        // history.goBack()
    }
    previewPopup = (items) => {
        this.setState({ openModal: true, previewItem: items })
    }

    statusChange = (items) => {
        const status = (items && items.status) ? 0 : 1
        let obj = {
            status: status
        }
        this.showLoader()
        artServices.artStatusChange((items && items.id), JSON.stringify(obj)).then(res => {
            // if((res && res.statusCode) == 200){

            toast.success("Status Updated")
            this.callApi()
            // }
            this.hideLoader()
        }).catch(err => {
            this.hideLoader()
            toast.error(err)
        })

    }
    fetchArt = (url) => {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return true
            } else {
                return false
            }
        }).catch((err) => {

        })

    }
    gotoArt = (items) => {
        const baseurl = process.env.REACT_APP_BASEPATH
        const id = ((items && items.id))
        const permanentId = items && items.art && items.art.permanentId
        const artId = items && items.art && items.art.id
        if (permanentId && items.group && items.group.tokenGroupId) {
            const navPath = (baseurl + `/art/${permanentId}#drop${id}`)
            window.open(navPath, '_blank')
        } else {
            const navPath = (baseurl + `/art/${artId}#drop${id}`)
            window.open(navPath, '_blank')
        }
    }
    gotoDetailpage = (items) => {
        const { sDate, eDate, selectedUserId, title, ownerName, sortBy, sortOrder, offSet } = this.state
        let obj = {
            sDate,
            eDate,
            selectedUserId,
            title,
            ownerName,
            sortBy,
            sortOrder, offSet
        }

        const baseurl = process.env.REACT_APP_BASEPATH
        const id = ((items && items.id))
        history.push(`/admin/artDetail/${id}`, { obj })
        localStorage.setItem("artParams", JSON.stringify(obj))
        // const navPath =  (baseurl + `/admin/artDetail/${id}`)
        // window.open(navPath,'_blank')
    }
    changeDropStatus = (e, items) => {
        if ((items && items.id)) {
            let obj = {
                priorityRank: (e.target.value) ? Number(e.target.value) : 0
            }
            this.props.updatePriorityStatus((items && items.id), JSON.stringify(obj))
        } else {
            toast.error('Drop id was missing')
        }



    }
    showPriceAmount = (items) => {
        if ((items && items.markedPrice && items.markedPrice !== null)) {
            if(items && items.storefrontListingResourceId && items.withDapper){
                return <span><i className="fa fa-shopping-cart" aria-hidden="true"></i> {flowDecimal(parseFloat(items.markedPrice))} USD</span>
            }else{
                return <span><i className="fa fa-shopping-cart" aria-hidden="true"></i> {flowDecimal(parseFloat(items.markedPrice))} FLOW</span>
            }
        } else if ((items && items.markedPrice === null && items.auction && items.auction.id && items.auction.startPrice)) {
            return <span><i className="fa fa-gavel" aria-hidden="true"></i> {flowDecimal(parseFloat(items.auction.startPrice))} FLOW {this.dayClosed(items)}</span>
        } else {
            return ''
        }
    }
    handleChecked = (value) => {
        let isDropOnly = value ? false : true
        if (value) {
            this.setState({ isDropOnly, offSet: 0, search: '', role: '', range: "Select range" }, () => {
                this.callApi()
            })
        } else {
            this.setState({ isDropOnly, search: '', role: '', offSet: 0, range: "Select range" }, () => {
                this.callApi()

            })
        }
    }
    changePriorityRank = (value, items) => {
        if ((items && items.id)) {
            let obj = {
                priorityRank: (value)
            }

            this.props.updatePriorityStatus((items && items.id), JSON.stringify(obj))
        } else {
            toast.error("Drop id was missing")
        }
        console.log(value, items)
    }
    handleCategory = (value) => {
        if (value) {
            this.setState({ selectedCategory: value, offSet: 0, forcePg: 0 }, () => {
                // this.callApi()
            })
        }
    }
    handleRankFilter = (value) => {
        if (value) {
            this.setState({ selectedRank: value, offSet: 0, forcePg: 0 }, () => {
                // this.callApi()
            })
        }
    }

    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return '(Auction ended)'
        } else {
            return false
        }
    }

    render() {

        const dropsList = this.state.dropsList && this.state.dropsList.map((items, index) => {
            return <tbody>
                <tr>
                    <td><a className="userdescrip_adm designLink" style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.art && items.art.title} onClick={() => this.gotoArt(items)} >{items && items.art && items.art.title}</a> <a className={'designLink mr-2 float-right'} onClick={() => this.gotoArt(items)} ><i className="fas fa-external-link-alt"></i></a></td>
                    <td>{this.showPriceAmount(items)}</td>
                    <td>{items && items.user && items.user.userName && `@${items.user.userName}`}</td>
                    <td>
                        {/* <select className='form-control' value={items && items.priorityRank} onChange={(e) => this.changeDropStatus(e,items)}>
                            <option value={0}>Drop</option>
                            <option value={1}>Top Prority</option>
                            <option  value={2}>Priority</option>
                        </select> */}
                        {/* <button type='button' className={(items && items.priorityRank === 2) ? "btn btn-primary active" : 'btn btn-primary'} onClick={() => this.changePriorityRank(2,items)}>T</button>
                            <button type='button' className={(items && items.priorityRank === 0) ? "btn btn-primary active" : 'btn btn-primary'} onClick={() => this.changePriorityRank(0,items)}>D</button>
                            <button type='button' className={(items && items.priorityRank === 1) ? "btn btn-primary active" : 'btn btn-primary'} onClick={() => this.changePriorityRank(1,items)}>P</button> */}

                        <form className="switch-form">
                            <div className="switch-field">
                            <input type="radio" id="drop" value="false" checked={(items && items.priorityRank === 0) ? true : false} readOnly />
                                <label className="toggle toggle-yes" htmlFor='drop' onClick={() => this.changePriorityRank(0, items)} title="Default"><i className="fa fa-minus" ></i></label>
                                <input type="radio" value="-1" id='pdrop' checked={(items && items.priorityRank === 2) ? true : false} readOnly />
                                <label className="toggle toggle-yes" htmlFor='pdrop' onClick={() => this.changePriorityRank(2, items)} title="Priority"><i className="fa fa-certificate" ></i></label>
                                <input type="radio" id="tdrop" value="true" checked={(items && items.priorityRank === 1) ? true : false} readOnly className='onebtn' />
                                <label className="toggle toggle-yes" htmlFor='tdrop' onClick={() => this.changePriorityRank(1, items)} title="Top priority"><i className="fa fa fa-trophy"></i></label>
                               
                                
                                <span></span>
                            </div>
                        </form>

                        {/* <div id="form-wrapper">
                                <div id="debt-amount-slider">
                                    <input type="radio" name="debt-amount" id="1" value="1" required/>
                                    <label htmlFor="1" data-debt-amount="< $10k"></label>
                                    <input type="radio" name="debt-amount" id="2" value="2" required/>
                                    <label htmlFor="2" data-debt-amount="$10k-25k"></label>
                                    <input type="radio" name="debt-amount" id="3" value="3" required/>
                                  
                                    <div id="debt-amount-pos"></div>
                                </div>
                        </div> */}
                        {/* <input type="range" min="0" max="2" className="slider" step="1" onInput={(e) => this.changeDropStatus(e,items)} ref={this.rangeValue} value={items && items.priorityRank}/>                        
                    <div className="sliderticks">
                        <p>0</p>
                        <p>1</p>
                        <p>2</p>
                        
                    </div> */}
                    </td>
                    <td>{moment(items && items.createdAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>



                </tr>

            </tbody>

        })


        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Drops</h2></div>
                    {/* <div className='usernamelilac'><p>Note : 0 - Normal Drop 1 - Top priority 2 - Priority</p></div> */}
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    {/* <div className="col-md-3">
                                <div className="form-group">
                                        <input type="checkbox" className="form-check-input" id="dropCheck1" checked={this.state.isDropOnly} onChange={() => this.handleChecked(this.state.isDropOnly)} />
                                        <label className="form-check-label" htmlFor="dropCheck1">Priority Drops only</label>
                                    </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <label>Art Name</label>
                                        <div>
                                            <input type="text" className="form-control" placeholder="Mona Lisa" name="title" onChange={this.handlePickArts} value={this.state.title} />

                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>Listed User Name</label>
                                        <div>
                                            <input type="text" className="form-control" placeholder="jane.doe" name="ownerName" onChange={this.handlePickUser} value={this.state.ownerName} />

                                        </div>


                                    </div>

                                    <div className='col-md-3'>
                                        <label>Select category</label>

                                        <Select
                                            value={this.state.selectedCategory}
                                            onChange={this.handleCategory}
                                            options={this.state.allCategories}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className=' col-md-3 '>
                                        <label>Select priority range</label>

                                        <Select
                                            value={this.state.selectedRank}
                                            onChange={this.handleRankFilter}
                                            options={rankFilter}
                                            styles={customStyles}
                                            menuPortalTarget={document.body}
                                            isSearchable={false}
                                        />
                                    </div>

                                    <div className='col-md-9'>
                                    <div className="mt-4 d-flex ">
                                        <span className='p-2'><i className="fa fa-minus usernamelilac" ></i> &nbsp; Default</span>
                                        <span className='p-2'><i className="fa fa-certificate usernamelilac" ></i> &nbsp; Priority</span>
                                        <span className='p-2'><i className="fa fa-trophy usernamelilac" ></i> &nbsp; Top Priority</span>

                                        </div>
                                    </div>
                                    <div className="col-md-3 text-right">

                                        <div className="mt-4">
                                            <button type="button" onClick={this.handleProceed} className="btn btn-primary ">
                                                <i className="fa fa-search" aria-hidden="true"></i> Search
                                            </button>
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    






                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>ART NAME</th>
                                        <th>PRICE</th>
                                        <th>LISTED BY</th>
                                        <th>STATUS</th>
                                        <th>LISTED AT</th>

                                    </tr>
                                </thead>

                                {dropsList}

                            </table>
                            {this.state.dropsList && this.state.dropsList.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.dropsList && this.state.dropsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePg }} />}
                </div>
                {this.state.loader ?
                    <div className="loader"><SolarSystemLoading /></div> :
                    <div className="loader-hide"><SolarSystemLoading /></div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allDrops: state.drop.admindropList,
        priorityStatusUpdated: state.drop.priorityDropUpdated,
        allCategories: state.category.categories,

    }
}

const actionCreators = {
    getAllDrops: dropActions.getAdminDropList,
    resetGetDrops: dropActions.resetGetAdminDropList,
    updatePriorityStatus: dropActions.updatePriorityRank,
    resetUpdateStatus: dropActions.resetUpdatePriorityRank,
    getAllCategories: categoryActions.getCategories,
    resetGetCategories: categoryActions.resetGetCategories,


}

const connectFetchDrops = connect(mapStateToProps, actionCreators)(FetchDrops)

export { connectFetchDrops as FetchDrops }