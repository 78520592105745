export const discoverConstants = {
    GET_SPOTLIGHT_ARTS : 'GET_SPOTLIGHT_ARTS',
    GET_FEATURE_ARTS : 'GET_FEATURE_ARTS',
    GET_FEATURE_CREATOR : 'GET_FEATURE_CREATOR',
    GET_FEATURE_COLLECTOR : 'GET_FEATURE_COLLECTOR',

    GET_DISCOVER_MAXIMUM_COUNT_LIMIT : 'GET_DISCOVER_MAXIMUM_COUNT_LIMIT',
    UPDATE_DISCOVER_SETTINGS : 'UPDATE_DISCOVER_SETTINGS',
    GET_RANDOM_CREATORS : 'GET_RANDOM_CREATORS',
    GET_RANDOM_CREATORS_FAILED : 'GET_RANDOM_CREATORS_FAILED',
    GET_ALL_SHOW_CASE_CREATORS : 'GET_ALL_SHOW_CASE_CREATORS',
    GET_ALL_SHOW_CASE_CREATORS_FAILED : 'GET_ALL_SHOW_CASE_CREATORS_FAILED'
}