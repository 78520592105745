import { announcementConstants } from "../../constants"
import { adminServices, userServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const announcementActions = {
    getAnnouncement,
    addAnnouncement,
    resetGetAnnouncement,
    resetAddedAnnouncement,
    getAnnouncementMessage,
    resetGetAnnouncementMessage,
    getAllGlitterEvents,
    resetGetGlitterEve,
    addGlitterEvent,
    resetAddedGlitEvent,
    deleteGlitterEvent,
    resetDeleteGlitEvent,
    getOneGlitterEvent,
    resetOneGlitterEvent,
    updateGlitterEvent,
    resetUpdateEvent,
    getGlitterText,
    resetGlitterText,
    getAnnouncementHistory,
    resetGetAnnouncementHistory,
    deleteAnnouncement,
    resetDeleteAnnouncement
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAnnouncement(obj){
    return dispatch => {
        showLoader()
        adminServices.getAnnouncement()
        .then(mes => {
            if(mes){
                dispatch({type:announcementConstants.GET_ANNOUNCEMENT,a_message:mes})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetAnnouncement(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_ANNOUNCEMENT,a_message:[]})
    }
}
function addAnnouncement(obj){
    return dispatch => {
        showLoader()
        adminServices.addAnnouncement(obj)
        .then(mes => {
                toast.success("Banner Updated")
                dispatch({type:announcementConstants.ADD_ANNOUNCEMENT,mesAdded:{statusCode : 200}})
                hideLoader()
            
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAddedAnnouncement(){
    return dispatch => {
        dispatch({type:announcementConstants.ADD_ANNOUNCEMENT,mesAdded:[]})
    }
}
function getAnnouncementMessage(){
    return dispatch => {
        // showLoader()
        userServices.getAnnouncementMessage()
        .then(mes => {
            if(mes){
                dispatch({type:announcementConstants.GET_COMMON_ANNOUNCEMENT,c_message:mes})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetAnnouncementMessage(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_COMMON_ANNOUNCEMENT,c_message:[]})
    }
}
function getAllGlitterEvents(){
    return dispatch => {
        showLoader()
        adminServices.getAllGlitterEvents()
        .then(eve => {
            if(eve){
                dispatch({type:announcementConstants.GET_GLITTER_EVENTS,getGlitEve:eve})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetGlitterEve(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_GLITTER_EVENTS,getGlitEve:[]})
    }
}
function addGlitterEvent(obj){
    return dispatch => {
        showLoader()
        adminServices.addGlitterEvent(obj)
        .then(eve => {
            if(eve){
                toast.success("Event added")
                dispatch({type:announcementConstants.ADD_GLITTER_EVENTS,addGlitEve:eve})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAddedGlitEvent(){
    return dispatch => {
        dispatch({type:announcementConstants.ADD_GLITTER_EVENTS,addGlitEve:[]})
    }
}
function deleteGlitterEvent(id){
    return dispatch => {
        showLoader()
        adminServices.deleteGlitterEvent(id)
        .then(eve => {
            if((eve && eve.statusCode) === 200){
                toast.success("Event deleted")
                dispatch({ type: announcementConstants.DELETE_GLITTER_EVENTS,eveDel : eve})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetDeleteGlitEvent(){
    return dispatch => {
        dispatch({type :announcementConstants.DELETE_GLITTER_EVENTS,eveDel : []})
    }
}

function getOneGlitterEvent(id){
    return dispatch => {
        showLoader()
        adminServices.getOneGlitterEvent(id)
        .then(eve => {
            if(eve){
                dispatch({type:announcementConstants.GET_ONE_GLITTER_EVENT,selectedEvent:eve})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetOneGlitterEvent(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_ONE_GLITTER_EVENT,selectedEvent:[]})
    }
}

function updateGlitterEvent(obj,id){
    return dispatch => {
        showLoader()
        adminServices.updateGlitterEvent(obj,id)
        .then(eve => {
            if(eve){
                dispatch({type:announcementConstants.UPDATE_GLITTER_EVENT,updateEvent:eve})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetUpdateEvent(){
    return dispatch => {
        dispatch({type:announcementConstants.UPDATE_GLITTER_EVENT,updateEvent:[]})
    }
}

function getGlitterText(){
    return dispatch => {
        showLoader()
        userServices.getGlitterText()
        .then(text => {
            if(text){
                dispatch({type:announcementConstants.GET_GLITTER_TEXT,glitterText:text})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGlitterText(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_GLITTER_TEXT,glitterText:[]})
    }
}

function getAnnouncementHistory(){
    return dispatch => {
        showLoader()
        adminServices.getAnnouncementHistory()
        .then(text => {
            if(text){
                dispatch({type:announcementConstants.GET_ANNOUNCEMENT_HISTORY,announcementHistory:text})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetAnnouncementHistory(){
    return dispatch => {
        dispatch({type:announcementConstants.GET_ANNOUNCEMENT_HISTORY,announcementHistory:[]})
    }
}
function deleteAnnouncement(id){
    return dispatch => {
        showLoader()
        adminServices.deleteAnnouncement(id)
        .then(text => {
            if(text){
                dispatch({type:announcementConstants.DELETE_ANNOUNCEMENT,deletedBanner:text})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}

function resetDeleteAnnouncement(){
    return dispatch => {
        dispatch({type : announcementConstants.DELETE_ANNOUNCEMENT,deletedBanner : []
        })
    }
}