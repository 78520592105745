export const userConstants = {
    LOGIN_SUCCESS : 'LOGIN_SUCCESS',
    LOGOUT : 'LOGOUT',
    ADMIN_LOGIN_SUCCESS : 'ADMIN_LOGIN_SUCCESS',
    USER_PROFILE_SUCCESS : 'USER_PROFILE_SUCCESS',
    GET_USERS : 'GET_USERS',
    GET_ALL_USERS : 'GET_ALL_USERS',
    GET_ALL_CREATORS : 'GET_ALL_CREATORS',
    GET_ALL_CREATOR_APPLICATIONS : 'GET_ALL_CREATOR_APPLICATIONS',
    GET_USD : 'GET_USD',
    REGISTER_USER : 'REGISTER_USER',
    ADMIN_LOGOUT : 'ADMIN_LOGOUT',
    VERIFY_EMAIL : 'VERIFY_EMAIL',
    RESET_PASSWORD : 'RESET_PASSWORD',
    CHANGE_PASSWORD : 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_FAILED : 'CHANGE_PASSWORD_FAILED',
    ALREADY_VERIFY : 'ALREADY_VERIFY',
    WEBLOGIN : 'WEBLOGIN',
    APPROVE_STATUS : 'APPROVE_STATUS',
    MODIFY_STATUS : 'MODIFY_STATUS',
    RAISE_CREATOR_REQUEST : 'RAISE_CREATOR_REQUEST',
    FORGOT_PASSWORD_USER : 'FORGOT_PASSWORD_USER',
    CHANGE_USER_PASSWORD : 'CHANGE_USER_PASSWORD',
    REVOKE_CREATOR : 'REVOKE_CREATOR',
    LOGIN_FAILED : 'LOGIN_FAILED',
    REGISTER_FAILED : 'REGISTER_FAILED',
    DASHBOARD : 'DASHBOARD',
    BULK_UPLOAD : 'BULK_UPLOAD',
    SEND_OTP : 'SEND_OTP',
    GET_UPLOAD_USERS : 'GET_UPLOAD_USERS',
    TOUR_OPTION : 'TOUR_OPTION',

    //Favourites
    ADD_FAVOURITES : 'ADD_FAVOURITES',
    ADD_FAVOURITES_FAILED : 'ADD_FAVOURITES_FAILED',

    REMOVE_FAVOURITES : 'REMOVE_FAVOURITES',
    REMOVE_FAVOURITES_FAILED : 'REMOVE_FAVOURITES_FAILED',

    GET_USER_FAVOURITES : 'GET_USER_FAVOURITES',
    GET_USER_FAVOURITES_FAILED : 'GET_USER_FAVOURITES_FAILED',
    
    GET_FAVOURITEDUSER : 'GET_FAVOURITEDUSER',
    GET_FAVOURITEDUSER_FAILED : 'GET_FAVOURITEDUSER_FAILED',

    //Bank detail
    ADD_BANK_DETAIL : 'ADD_BANK_DETAIL',
    ADD_BANK_DETAIL_FAILED : 'ADD_BANK_DETAIL_FAILED',

    GET_BANK_DETAIL : 'GET_BANK_DETAIL',
    GET_BANK_DETAIL_FAILED : 'GET_BANK_DETAIL_FAILED',

    GET_USERS_WALLET_DETAIL : 'GET_USERS_WALLET_DETAIL',
    GET_USERS_WALLET_DETAIL_FAILED : 'GET_USERS_WALLET_DETAIL_FAILED',

    ADD_PERSONAL_DETAIL : 'ADD_PERSONAL_DETAIL',
    ADD_PERSONAL_DETAIL_FAILED : 'ADD_PERSONAL_DETAIL_FAILED',

    GET_PERSONAL_DETAIL : 'GET_PERSONAL_DETAIL',
    GET_PERSONAL_DETAIL_FAILED : 'GET_PERSONAL_DETAIL_FAILED',

    INCREMENT : 'INCREMENT',
    DECREMENT : 'DECREMENT',

    GET_SELF_AIRDROP_ARTS : 'GET_SELF_AIRDROP_ARTS',
    GET_SELF_AIRDROP_ARTS_FAILED : 'GET_SELF_AIRDROP_ARTS_FAILED',

    CHANGE_SELF_PASSWORD : 'CHANGE_SELF_PASSWORD',
    CHANGE_SELF_PASSWORD_FAILED : 'CHANGE_SELF_PASSWORD_FAILED'

}