import moment from "moment";

export const dayClosed = (items) => {
    var futureDate = ((moment(items).format('MMM DD, YYYY HH:mm:ss')))
    var countDownDate = new Date(futureDate).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    if (distance < 0) {
        return true
    } else {
        return false
    }
}