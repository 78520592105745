import {config} from "@onflow/fcl"
import * as fcl from "@onflow/fcl";

export async function switchAuthDapper(){
    if(fetchWalletProvider() && fetchWalletProvider().toLowerCase().includes('dapper')){
        return false
    }else{
        await fcl.unauthenticate()
        localStorage.removeItem('walletDetail')
        sessionStorage.removeItem('CURRENT_USER');
        await config().put("discovery.wallet",process.env.REACT_APP_DAPPER_WALLET_DISCOVERY)
        .put("discovery.wallet.method", "POP/RPC")
        return true
    }
    
    
}
export async function switchAuthBlocto(){

    await config().put("discovery.wallet",process.env.REACT_APP_WALLET_DISCOVERY)
    .put("discovery.wallet.method","IFRAME/RPC")

}

function fetchWalletProvider(){
    if (window.sessionStorage.getItem("CURRENT_USER")){
        let res = JSON.parse(window.sessionStorage.getItem("CURRENT_USER"))
        if(res && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name){
            return res.services[0].provider.name
        }else{
            return false
        }
    }else{
        return false
    }
}
export function isDapperConnected(){
    if(fetchWalletProvider() && fetchWalletProvider().toLowerCase().includes('dapper')){
        return true
    }else{
        return false
    }
    
    
}
export async function unauthenticateWallet(){
    await fcl.unauthenticate()
    localStorage.removeItem('walletDetail')
    sessionStorage.removeItem('CURRENT_USER');
    return true
}

export async function dapperwalletConfig(){
    await config().put("discovery.wallet",process.env.REACT_APP_DAPPER_WALLET_DISCOVERY)
    .put("discovery.wallet.method", "POP/RPC")
}