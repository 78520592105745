export const purchaseLimits = [
    {
        minCount: 1,
        maxCount: 6,
        limit: 9999
    },
    {
        minCount: 7,
        maxCount: 10,
        limit: 9999
    },
    {
        minCount: 11,
        maxCount: 23,
        limit: 9999
    },
    {
        minCount: 24,
        maxCount: 28,
        limit: 9999
    },
    {
        minCount: 29,
        maxCount: 35,
        limit: 9999
    },
    {
        minCount: 36,
        maxCount: 42,
        limit: 9999
    },
    {
        minCount: 43,
        maxCount: 49,
        limit: 9999
    },
    {
        minCount: 50,
        maxCount: 200,
        limit: 9999
    }
];
export const listOnSaleLimits = [
    {
        minCount: 1,
        maxCount: 39,
        limit: 9999
    },
    {
        minCount: 39,
        maxCount: 50,
        limit: 9999
    },
    {
        minCount: 51,
        maxCount: 10000,
        limit: 9999
    }
];
export const widthdrawLimits = [
    {
        minCount: 1,
        maxCount: 35,
        limit: 9999
    },
    {
        minCount: 36,
        maxCount: 50,
        limit: 9999
    },
    {
        minCount: 51,
        maxCount: 10000,
        limit: 9999
    }
];
export const defaultFclLimit = 9999
/**
 * Get the Limit to be supplied for FCL sdk to perfom a transaction.
 * @param {number} tokenCount Number of tokens to be used for the operation.
 * @param {{minCount: number, maxCount: number, limit: number}[]} eventLimts The limits for specific
 * ranges of token count for the operation
 * @returns The FCL limit with the provided limits, else returns 1000 as minimum limit
 */
export function getFCLLimit(tokenCount, eventLimts) {
    const eventLimit = eventLimts.find((evtLimit) => {
        return tokenCount >= evtLimit.minCount && tokenCount <= evtLimit.maxCount
    });
    if (eventLimit) {
        return eventLimit.limit;
    }
    return 1000;
}