import React from "react";
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { configPath } from "../../config";
import { Helmet } from "react-helmet";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { toast, Zoom } from "react-toastify";
import Select from 'react-select';

var timeout;
const allTypes = [
    { value: 1, label: "Theme1" }
]
const customStylesforAll = {
    control: (base, state) => ({
        ...base,
        minWidth: "100px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }

    }),
    menuPortal: base => ({
        ...base,
        zIndex: 9999
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "#fff",
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#eee" : null,
            color: "#000",
            cursor: "pointer",

        };
    }
}
class CreatorProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            imageHash: '',
            bannerImageHash: '',
            image: '',
            uName: '',
            banner: '',
            message: '',
            instagramProfile: '',
            isInstagramLinkValid: false,
            twiterProfile: '',
            discordProfile: '',
            websiteProfile: '',
            isTwitterLinkValid: false,
            isWebsiteLinkValid: false,
            isDiscordLinkValid: false,
            isAlert: false,
            submitted: false,
            formHasError: false,
            roleId: 0,
            domain: '',
            allFiltSelectOption: { value: 1, label: "Theme1" },
            isDomainLinkValid: false,
            SocialOptions: [],
            selectedSocialLinks: [
                {
                    options: { value: 0, label: 'Select your link' },
                    input: { socialLink: '', isValid: false, isEditable: false }
                }],
            totalSocialLength: 0

        };
        this.labelName = 'Select your link'
        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onInstagramLinkChange = this.onInstagramLinkChange.bind(this);
        this.onTwitterLinkChange = this.onTwitterLinkChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.getProfile()
        this.gotoTop()
    }
    getProfile = () => {
        let userDetail = localStorage.getItem("webLogin")
        let user = JSON.parse(userDetail)
        if (user && user.data && user.data.authUser && user.data.authUser.uuid) {
            this.props.getUserProfile(user.data.authUser.uuid)
        }
        this.props.getSocialDefenition()
    }
    componentDidUpdate(prevProps, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        if (
            (this.props.usersList.statusCode === 200) &&
            (this.props.usersList !== (prevProps && prevProps.usersList))) {
            if (this.props && this.props.usersList && this.props.usersList.data) {
                let data = this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.store
                this.setState({
                    fullName: data.name,
                    email: data.supportEmail,
                    image: data.logoUrl ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.logoUrl}` : null,
                    imageHash: data.logoUrl ? data.logoUrl : null,
                    instagramProfile: data.instagramUrl ? data.instagramUrl : '',
                    isInstagramLinkValid: data.instagramUrl && this.checkURL(data.instagramUrl) ? true : false,
                    twiterProfile: data.twitterUrl ? data.twitterUrl : '',
                    isTwitterLinkValid: data.twitterUrl && this.checkURL(data.twitterUrl) ? true : false,
                    discordProfile: data.discord ? data.discord : '',
                    domain: data.domain ? data.domain : '',
                    storeId: data.id,
                    isDomainLinkValid: data.domain && this.checkURL(data.domain) ? true : false

                });
                let socialResponse = this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.store.userStoreSocialUserStoreMap
                let assignedArray = []
                socialResponse && socialResponse.length > 0 && socialResponse.map((items, index) => {
                    if (items && items.userStoreSocial && items.userStoreSocial.id && items.url && items.userStoreSocial.name) {
                        let obj = {
                            options: { value: items.userStoreSocial.id, label: items.userStoreSocial.name },
                            input: { socialLink: items.url, isValid: true, isEditable: true }
                        }
                        assignedArray.push(obj)
                    }
                })
                console.log(assignedArray, this.state.selectedSocialLinks)
                this.setState({
                    selectedSocialLinks: assignedArray,
                    totalSocialLength : state.totalSocialLength + socialResponse.length
                })
            }
        }
        if (this.props &&
            this.props.profileaddedFailed &&
            this.props.profileaddedFailed.length > 0 &&
            (this.props.profileaddedFailed !== prevProps.profileaddedFailed)
        ) {
            this.props.resetprofileupdateFailed()
            toast.error(this.props.profileaddedFailed, { transition: Zoom })

        }
        if (this.props &&
            this.props.profileaddedSuccess &&
            this.props.profileaddedSuccess.statusCode === 200 &&
            (this.props.profileaddedSuccess !== prevProps.profileaddedSuccess)) {
            this.props.resetProfileUpdateSuccess()
            toast.success("Profile updated", { transition: Zoom })
            this.setState({totalSocialLength : 0})
            this.getProfile()
        }
        if (this.props &&
            this.props.socialDefenitions &&
            this.props.socialDefenitions.statusCode === 200 &&
            (this.props.socialDefenitions !== prevProps.socialDefenitions)) {
            this.props.resetSocialDefenition()
            let array = []
            this.props && this.props.socialDefenitions &&
                this.props.socialDefenitions.data && this.props.socialDefenitions.data.socialMedia &&
                this.props.socialDefenitions.data.socialMedia.length > 0 && this.props.socialDefenitions.data.socialMedia.map((items) => {
                    let obj = {
                        value: items && items.id,
                        label: items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)

                    }
                    array.push(obj)
                })
            this.setState({
                SocialOptions: array,
                totalSocialLength : state.totalSocialLength + this.props.socialDefenitions.data.socialMedia.length
            })
        }
        if (this.props &&
            this.props.socialDefFailed &&
            this.props.socialDefFailed.length > 0 &&
            (this.props.socialDefFailed !== prevProps.socialDefFailed)
        ) {
            this.props.resetSocialDefenitionFailed()
            toast.error(this.props.socialDefFailed, { transition: Zoom })

        }
        if (this.props &&
            this.props.deletesocialsFailed &&
            this.props.deletesocialsFailed.length > 0 &&
            (this.props.deletesocialsFailed !== prevProps.deletesocialsFailed)
        ) {
            this.props.resetDeleteSocialsFailed()
            toast.error(this.props.deletesocialsFailed, { transition: Zoom })

        }
        if (this.props &&
            this.props.deletedSocials &&
            this.props.deletedSocials.statusCode === 200 &&
            (this.props.deletedSocials !== prevProps.deletedSocials)) {
            this.props.resetDeleteSocias()
            toast.success("Removed successfully", { transition: Zoom })
            this.getProfile()
            this.setState({totalSocialLength : 0})

        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.getprofileFailed &&
            props.getprofileFailed.length > 0) {
            props.resetGetprofileFailed()
            toast.error(props.getprofileFailed, { transition: Zoom })
        }
        return null
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    checkDesc = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkmaxDesc = (value) => {
        if ((value && value.length) <= 6000) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= 100) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkDiscordName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 4)) {
            return true
        } else {
            return false
        }
    }
    checkusername = (value) => {
        if (((value && value.length) <= 50) && (value && value.length) >= 4) {

            return true
        } else {
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    checkURL(value) {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        const urlReg = /https:\/\/.*/;
        if (re.test(value)) {
            return true;
        }
        return false;
    }
    onFullNameChange(event) {
        this.setState({
            fullName: event.target.value
        });
    }
    onInstagramLinkChange(event) {
        this.setState({
            instagramProfile: event.target.value,
            isInstagramLinkValid: this.checkURL(event.target.value)
        });
    }
    onTwitterLinkChange(event) {
        this.setState({
            twiterProfile: event.target.value,
            isTwitterLinkValid: this.checkURL(event.target.value)
        });
    }
    onDiscordLinkChange = (e) => {
        this.setState({
            discordProfile: e.target.value,
            // isDiscordLinkValid : this.checkDiscord(e.target.value)
        });

    }
    checkDiscord = (userName) => {
        var usernameRegex = /^[a-z0-9_-]{3,16}$/
        // console.log(usernameRegex.test(userName))

        if (usernameRegex.test(userName)) {
            return true
        } else {
            return false
        }
    }
    onWebsiteLinkChange = (e) => {
        this.setState({
            websiteProfile: e.target.value,
            isWebsiteLinkValid: this.checkURL(e.target.value)
        });
    }

    onDescriptionChange(event) {
        this.setState({
            description: event.target.value
        });
    }
    onErrorHandle = (error) => {
        toast.error(error, { transition: Zoom })

    }
    async onProfilePicChanged(file) {
        const types = ['image/png', 'image/jpeg', 'image/gif']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        if (types.every(type => file && file.type !== type)) {
            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)

        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported logo file size is ${2} MB`;
            this.onErrorHandle(err)

        }

        else if (file) {

            const reader = new FileReader();
            reader.onload = (e) => {

                this.setState({
                    fileData: file,
                    image: reader.result
                })
                // this.onFileLoaded(e.target.result);
            };
            reader.readAsDataURL(file)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { fullName, domain, fileData, email, instagramProfile, twiterProfile, discordProfile, storeId, selectedSocialLinks } = this.state
        const isAllValid = selectedSocialLinks && selectedSocialLinks.every((ele, idx) => ele && ele.input && ele.input.isValid)
        const isAllChoosed = selectedSocialLinks && selectedSocialLinks.some((ele, idx) => (ele && ele.options && ele.options.label) === (this.labelName))
        const socials = []
        selectedSocialLinks && selectedSocialLinks.map((itm) => {
            if (itm && itm.options && itm.options.value) {
                if (itm.input && itm.input.socialLink) {
                    let obj = {
                        id: itm.options.value,
                        url: itm.input.socialLink
                    }
                    socials.push(obj)
                }
            }
        })
        console.log(socials, isAllValid, isAllChoosed, selectedSocialLinks)
        if (fullName &&
            this.checkFullName(fullName)
        ) {
            let formData = new FormData()
            formData.append("name", fullName);
            if (storeId) {
                formData.append('id', storeId)
            } else {
                toast.error('Store id was missing', { transition: Zoom })
                return false
            }
            if (fileData) {
                formData.append("storeLogo", fileData);
            }
            if (domain) {
                if (this.checkURL(domain)) {
                    if (this.checkName(domain)) {
                        formData.append('domain', domain)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            if ((selectedSocialLinks && selectedSocialLinks.length > 0)) {
                if (isAllValid) {
                    let obj = {
                        socials: socials
                    }
                    formData.append('socials', JSON.stringify(obj))
                } else {
                    if (isAllChoosed) {
                        toast.error('Please select your link', { transition: Zoom })
                        return false
                    } else {
                        toast.error('Please give valid link', { transition: Zoom })
                        return false
                    }

                }
            }
            if (email) {
                if (this.checkemail(email)) {
                    formData.append('supportEmail', email)
                } else {
                    return false
                }
            }
            if (instagramProfile) {
                if (this.checkURL(instagramProfile)) {
                    if (this.checkName(instagramProfile)) {
                        formData.append('instagramUrl', instagramProfile)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
            if (twiterProfile) {
                if (this.checkURL(twiterProfile)) {
                    if (this.checkName(twiterProfile)) {
                        formData.append('twitterUrl', twiterProfile)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }

            if (discordProfile) {
                if (this.checkDiscordName(discordProfile)) {
                    formData.append('discord', discordProfile)
                } else {
                    return false
                }

            }


            this.props.updateProfile(formData)

        } else {
            toast.error('Please provide valid store name', { transition: Zoom })
        }

    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    userNameValidation = (value) => {
        const regext = /^([a-z0-9.]{4,})+$/
        if (regext.test(value)) {
            return true
        } else {
            return false
        }

    }

    handleEmailChange = (e) => {
        if (((e.target.value && e.target.value.length) <= 50)) {

            this.setState({ email: (e.target.value).toLowerCase(), isEmailTyping: true })
        }
    }
    checkDomainName = (value) => {
        if (((value && value.length) <= 110) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    onDomainChange = (e) => {
        this.setState({
            domain: e.target.value,
            isDomainLinkValid: this.checkURL(e.target.value)

        })
    }
    handleSelectChange = (option) => {
        this.setState({
            allFiltSelectOption: option,
        })
    }
    handleSelectSocialChange = (i) => (event) => {
        let filteredSocialOptions = []
        if(this.state.SocialOptions && this.state.SocialOptions.length > 0 && event && event.value){
            filteredSocialOptions = this.state.SocialOptions.filter(function( obj ) {
                return obj.value !== event.value;
            });
        }
        
        this.setState((state, props) => {
            state.selectedSocialLinks[i].options = event
            return {
                selectedSocialLinks: state.selectedSocialLinks,
                SocialOptions: filteredSocialOptions
            }
        })
    }

    changeInputLink = (i, e) => {
        this.setState((state, props) => {
            console.log(e.target.value, i, state.selectedSocialLinks[i])

            // let array = [...state.selectedSocialLinks]
            //    console.log(array)
            state.selectedSocialLinks[i].input.socialLink = e.target.value
            state.selectedSocialLinks[i].input.isValid = this.checkURL(e.target.value)
            return {
                selectedSocialLinks: state.selectedSocialLinks
            }
        })
    }
    handleAddMoreLinks = () => {
        this.setState((state, props) => {
            let obj = {
                options: { value: 0, label: 'Select your link' },
                input: { socialLink: '', isValid: false, isEditable: false }
            }
            state.selectedSocialLinks.push(obj)
            return {
                selectedSocialLinks: state.selectedSocialLinks
            }
        })
    }
    handleRemoveLinks = (i) => {
        const choosedObj = this.state.selectedSocialLinks && this.state.selectedSocialLinks[i]
        if (choosedObj && choosedObj.options && choosedObj.options.value && choosedObj.input.isEditable) {
            if (choosedObj && choosedObj.input && choosedObj.input.socialLink) {
                if (choosedObj && choosedObj.input && choosedObj.input.isValid) {
                    let obj = {
                        id: choosedObj.options.value,
                        url: choosedObj.input.socialLink
                    }
                    this.props.deleteSocialLink(JSON.stringify(obj))
                    this.setState((state, props) => {
                        state.selectedSocialLinks.splice(i, 1)
                        return {
                            selectedSocialLinks: state.selectedSocialLinks
                        }
                    })
                } else {
                    toast.error('Link must be Valid', { transition: Zoom })
                }

            } else {
                toast.error('Please enter valid Link', { transition: Zoom })
            }
        } else {
            if(choosedObj && choosedObj.options && choosedObj.options.value){
                this.setState((state, props) => {
                    state.SocialOptions.push(choosedObj.options)
                    return {
                        SocialOptions : state.SocialOptions
                    }
                }) 
            }
            this.setState((state, props) => {
                state.selectedSocialLinks.splice(i, 1)
                return {
                    selectedSocialLinks: state.selectedSocialLinks,
                }
            })
            
        }

    }
    isAddMoreNeed = (selectedSocialLinks,SocialOptions) => {
        if(SocialOptions && SocialOptions.length > 0 && !(selectedSocialLinks && selectedSocialLinks.length >= (this.state.totalSocialLength))){
            return true
        }else{
            return false
        }
    }
    render() {
        const { selectedSocialLinks } = this.state

        return (

            <div className="">
                <Helmet>
                    <title>Store Profile</title>
                    <meta name="title" content={`Update profile`}></meta>
                    <meta name="description" content={`Update profile`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit">
                        <h1 className="mb-0">Store Profile <span className="betaLink">(Soon)</span></h1>
                    </div>

                    <div className="propagecont mb-5">
                        <div className="container1">
                            <div className="row mb-5">
                                <div className="col-md-3">
                                    <div className="picpro mt-0">
                                        <span className="artcovimg ">
                                            <span className="artcov"></span>
                                            <span className="artcovimginset no-proimg">
                                                <img src={this.state.image ? this.state.image : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                            </span>

                                        </span>
                                        <span className="editpro">
                                            <span className="choosefile" title="Upload Profile Image">
                                                <input type="file" className="custom-file-input inp-box" id="profilePic" accept="image/png, image/jpeg, image/webp, image/gif" onChange={(e) => {
                                                    this.onProfilePicChanged(e.target.files[0])
                                                }} />
                                                <label htmlFor="profilePic" className="btn btn-primary w-auto px-3"><i className="fas fa-pencil-alt"></i></label>

                                            </span>
                                        </span>

                                    </div>
                                    <div className="row sizecls small mt-4 pt-2">
                                        <div className="col-12 text-center">
                                            <span className="usernamelilac ">Logo image size :</span> 400px x 400px
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-6">

                                            <div className="ds-inputs">
                                                <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Enter store full name" value={this.state.fullName} onChange={this.onFullNameChange} />
                                                <div className="input-floating-label"><span>Store name</span></div>
                                            </div>
                                            {this.state.submitted && !this.state.fullName && <div className="mandatory">{"Store name is Required"}</div>}
                                            {this.state.submitted && this.state.fullName && !this.checkFullName(this.state.fullName) && <div className="mandatory">{"Store name must be at least 3 characters and atmost 100 characters"}</div>}
                                        </div>
                                        <div className="form-group mb-4 col-md-6">

                                            <div className="ds-inputs">
                                                <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="Enter store contact email" value={this.state.email} onChange={this.handleEmailChange} />
                                                <div className="input-floating-label"><span>Store Contact Email</span></div>
                                            </div>
                                            {this.state.submitted && this.state.email && !this.checkemail(this.state.email) && <div className="mandatory">{"Please enter valid email"}</div>}
                                        </div>

                                        <div className="form-group mb-4 col-md-6">
                                            <div className="ds-inputs">
                                                <input type="text" className="form-control" id="domain" aria-describedby="domain" placeholder="Enter store domain name" value={this.state.domain} onChange={this.onDomainChange} />
                                                <div className="input-floating-label"><span>Store Domain</span></div>
                                            </div>
                                            <div className="usernamelilac small mt-1">Ex: https://disrupt.com</div>
                                            {!this.state.isDomainLinkValid && this.state.domain.length > 0 && <div className="mandatory">{"Domain link should be a valid link"}</div>}
                                            {this.state.submitted && this.state.domain && !this.checkName(this.state.domain) && <div className="mandatory">{"Domain link must be at least 3 characters and atmost 120 characters"}</div>}
                                        </div>

                                        <div className="from-group mb-3 col-md-6">

                                            <div className="ds-inputs">
                                                <Select
                                                    value={this.state.allFiltSelectOption}
                                                    onChange={this.handleSelectChange}
                                                    options={allTypes}
                                                    styles={customStylesforAll}
                                                    menuPortalTarget={document.body}
                                                    isSearchable={false}
                                                />
                                                <div className="input-floating-label"><span>Select your theme</span></div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            <form>

                                <div className="mt-0 mt-md-5 pt-md-3 row">

                                    <div className="col-md-12">
                                        <div className="row">

                                            {/* <div className="from-group mb-3 col-md-4">
                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="instagramProfile" aria-describedby="instagramProfile" placeholder="Enter store instagram profile link" value={this.state.instagramProfile} onChange={this.onInstagramLinkChange} />
                                                    <div className="input-floating-label"><span>Store Instagram Link</span></div>
                                                </div>
                                                <div className="usernamelilac small mt-1">Ex: https://www.instagram.com/disruptart</div>
                                                {!this.state.isInstagramLinkValid && this.state.instagramProfile.length > 0 && <div className="mandatory">{"Instagram link should be a valid link"}</div>}
                                                {this.state.submitted && this.state.instagramProfile && !this.checkName(this.state.instagramProfile) && <div className="mandatory">{"Instagram link must be at least 3 characters and atmost 120 characters"}</div>}
                                            </div>
                                            <div className="from-group mb-3 col-md-4">
                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="twiterProfile" aria-describedby="twiterProfile" placeholder="Enter store twitter profile link" value={this.state.twiterProfile} onChange={this.onTwitterLinkChange} />
                                                    <div className="input-floating-label"><span>Store Twitter Link</span></div>
                                                </div>
                                                <div className="usernamelilac small mt-1">Ex: https://twitter.com/disruptart</div>
                                                {!this.state.isTwitterLinkValid && this.state.twiterProfile.length > 0 && <div className="mandatory">{"Twitter link should be a valid link"}</div>}
                                                {this.state.submitted && this.state.twiterProfile && !this.checkName(this.state.twiterProfile) && <div className="mandatory">{"Twitter link must be at least 3 characters and atmost 120 characters"}</div>}
                                            </div>
                                            <div className="from-group mb-3 col-md-4">
                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="discordProfile" aria-describedby="discordProfile" placeholder="Enter store discord username" value={this.state.discordProfile} onChange={this.onDiscordLinkChange} />
                                                    <div className="input-floating-label"><span>Store Discord Username</span></div>
                                                </div>
                                                <div className="usernamelilac small mt-1">Ex: kart#1234</div>
                                                {this.state.submitted && this.state.discordProfile && !this.checkDiscordName(this.state.discordProfile) && <div className="mandatory">{"Discord username must be at least 4 characters and atmost 120 characters"}</div>}
                                            </div> */}


                                            <div className="from-group mb-3 col-md-12">
                                                <h5 className="usernamelilac">Social Links</h5>

                                                <hr className="lightline" />
                                            </div>

                                            {selectedSocialLinks && selectedSocialLinks.map((items, index) => (
                                                <div className="form-group mb-4 col-md-4">






                                                    {(items && items.options && items.options.value === 0) ?
                                                        <div className="ds-inputs"><div className="input-group mb-3 w-100"><Select
                                                            value={items.options}
                                                            onChange={this.handleSelectSocialChange(index)}
                                                            options={this.state.SocialOptions}
                                                            styles={customStylesforAll}
                                                            menuPortalTarget={document.body}
                                                            isSearchable={false}
                                                            placeholder=""
                                                            className="form-control p-0"
                                                            name={`SocialOptions${index}`}
                                                        /><div className="input-group-append"><button className="btn btn-primary" type="button" onClick={() => this.handleRemoveLinks(index)}>X</button></div></div>  <div className="input-floating-label"><span>Select social link</span></div> </div>
                                                        :
                                                        <div className="ds-inputs">

                                                            <div className="input-group mb-3 w-100">
                                                                <input type="text" className="form-control" aria-describedby={items.options.label} value={items && items.input && items.input.socialLink} onChange={(e) => this.changeInputLink(index, e)} name={`socialLink${index}`} disabled={items && items.input && items.input.isEditable} autoFocus/>
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" onClick={() => this.handleRemoveLinks(index)}>X</button>
                                                                </div>
                                                            </div>


                                                            {(items && items.input && items.input.isEditable) ? <div className="input-floating-label"><span>{items.options.label} link</span></div> : <div className="input-floating-label"><span>Enter your {items.options.label} link</span></div>}
                                                        </div>}
                                                </div>


                                            ))}


                                            {(this.isAddMoreNeed(selectedSocialLinks,this.state.SocialOptions)) ? <div className="form-group mb-4 col-md-3">
                                                <button className="btn btn-primary px-3" type="button" onClick={this.handleAddMoreLinks} title="Add More"><i className="fas fa-plus"></i></button>

                                            </div> : ''}
                                        </div>



                                    </div>


                                    <div className="text-center col-md-12">
                                        <hr className="lightline" />
                                        <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usersList: state.creatorStore.profile,
        isUpdate: state.user.isUpdate,
        profileaddedSuccess: state.creatorStore.profileadded,
        profileaddedFailed: state.creatorStore.addedProfilefailed,
        getprofileFailed: state.creatorStore.getprofilefailed,
        socialDefenitions: state.creatorStore.socialDefenitions,
        socialDefFailed: state.creatorStore.socialDefenitionsFailed,
        deletedSocials: state.creatorStore.deletedSocialLinks,
        deletesocialsFailed: state.creatorStore.deletesocialLinkFailed
    }
}

const actionCreators = {
    updateProfile: creatorStoreActions.addstoreCreatorProfile,
    getUserProfile: creatorStoreActions.getStoreCreatorProfile,
    getUSD: userAction.getUSD,
    resetprofileupdateFailed: creatorStoreActions.resetAddProfileFailed,
    resetProfileUpdateSuccess: creatorStoreActions.resetAddStoreCreatorProfile,
    resetGetProfile: creatorStoreActions.resetGetStoreCreatorProfile,
    resetGetprofileFailed: creatorStoreActions.resetGetStoreCreatorProfilefailed,
    getSocialDefenition: creatorStoreActions.storeGetSocialDefinition,
    resetSocialDefenition: creatorStoreActions.resetGetSocialDefenition,
    resetSocialDefenitionFailed: creatorStoreActions.resetGetSocialDefinitionFailed,
    deleteSocialLink: creatorStoreActions.deleteSocialMediaMapLink,
    resetDeleteSocias: creatorStoreActions.resetDeleteSocialLink,
    resetDeleteSocialsFailed: creatorStoreActions.resetDeleteSocialLinkFailed
}

const connectCreatorProfileForm = connect(mapStateToProps, actionCreators)(CreatorProfileForm)

export { connectCreatorProfileForm as CreatorProfileForm }