import { reportConstants } from "../../constants";

const initialState = {
    addedReport : [],
    addReportFailed : '',
}
export function reportReducer(state = initialState, action) {
    switch (action.type) {
        case reportConstants.ADD_ABUSE_REPORT:
            return {
                ...state,
                addedReport : action.addedReport  
            }
        case reportConstants.ADD_ABUSE_REPORT_FAILED:
            return {
                ...state,
                addReportFailed : action.addReportFailed
            }
        default:
            return state
    }
}