import { adminConstants } from "../../constants"
import { adminServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const adminActions = {
    getAllAdminAccount,
    resetGetAllAdminAccounts,
    addAdminAccount,
    resetAddAccountFailed,
    resetAddAccountSuccess,
    enableAdminAccount,
    resetEnabledAccount,
    disableAdminAccount,
    resetDisabledAccount,
    getAbuseReport,
    resetGetAbuseReport,
    updateAbuseReport,
    resetUpdateAbuseReport,
    getAdminUser,
    resetGetAdminUser,
    adminresetPassword,
    resetAdminResetPassword,
    adminChangePassword,
    resetAdminChangePassword,
    listDropHistory,
    resetListDropHistory,
    getDropHistoryDetail,
    resetDropHistoryDetail,
    dropHistoryRelisting,
    resetDropHistoryRelist,
    dropHistoryrelistSuccess,
    resetDropHistoryRelistUpdate,
    getStores,
    resetGetStores,
    getStoreDetail,
    resetGetStoreDetail,
    getStoreSalesDetail,
    resetGetStoreSalesDetail,
    addBadges,
    resetAddBadges,
    updateBadgeStatus,
    resetUpdateBadgeStatus
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAllAdminAccount(obj){
    return dispatch => {
        showLoader()
        adminServices.getAllAdminAccount(obj)
        .then(res => {
            if(res){
                dispatch({type:adminConstants.GET_ADMIN_ACCOUNT,admins:res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetAllAdminAccounts(){
    return dispatch => {
        dispatch({type:adminConstants.GET_ADMIN_ACCOUNT,admins:[]})
    }
}
function addAdminAccount(obj){
    return dispatch => {
        showLoader()
        adminServices.addAdminAccount(obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.ADD_ADMIN_ACCOUNT,addedAccount : res})
                hideLoader()
            }
        },error => {
            dispatch({type: adminConstants.ADD_ADMIN_ACCOUNT_FAILED,addedAccountFailed:error})
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAddAccountSuccess(){
    return dispatch => {
        dispatch({type:adminConstants.ADD_ADMIN_ACCOUNT,addedAccount:[]})
    }
}
function resetAddAccountFailed(){
    return dispatch => {
        dispatch({type:adminConstants.ADD_ADMIN_ACCOUNT_FAILED,addedAccountFailed:[]})
    }
}
function enableAdminAccount(id){
    return dispatch => {
        showLoader()
        adminServices.enableAdminAccount(id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.ENABLE_ADMIN_ACCOUNT,enabledAccount : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetEnabledAccount(){
    return dispatch => {
        dispatch({type:adminConstants.ENABLE_ADMIN_ACCOUNT,enabledAccount:[]})
    }
}
function disableAdminAccount(id){
    return dispatch => {
        showLoader()
        adminServices.disableAdminAccount(id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.DISABLE_ADMIN_ACCOUNT,disabledAccount : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetDisabledAccount(){
    return dispatch => {
        dispatch({type:adminConstants.DISABLE_ADMIN_ACCOUNT,disabledAccount:[]})
    }
}
function getAbuseReport(obj){
    return dispatch => {
        showLoader()
        adminServices.getAbuseReport(obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_ABUSE_REPORT,abusereportList : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetAbuseReport(){
    return dispatch => {
        dispatch({type : adminConstants.GET_ABUSE_REPORT,abusereportList : []})
    }
}
function updateAbuseReport(obj){
    return dispatch => {
        showLoader()
        adminServices.updateAbuseReport(obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.REVIEW_ABUSE_REPORT,reviewAbuseStatus : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetUpdateAbuseReport(){
    return dispatch => {
        dispatch({type : adminConstants.REVIEW_ABUSE_REPORT,reviewAbuseStatus : []})
    }
}
function getAdminUser(id){
    return dispatch => {
        showLoader()
        adminServices.getAdminUser(id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_ADMIN_USER, adminUser : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetAdminUser(){
    return dispatch => {
        dispatch({type : adminConstants.GET_ADMIN_USER,adminUser : [] })
    }
}
function adminresetPassword(email){
    return dispatch => {
        showLoader()
        adminServices.adminresetPassword(email)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.ADMIN_RESET_PASSWORD, resetRes : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAdminResetPassword(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_RESET_PASSWORD,resetRes : [] })
    }
}
function adminChangePassword(obj,publicId){
    return dispatch => {
        showLoader()
        adminServices.adminChangePassword(obj,publicId)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.ADMIN_CHANGE_PASSWORD, changePasswordRes  : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAdminChangePassword(){
    return dispatch => {
        dispatch({type : adminConstants.ADMIN_CHANGE_PASSWORD,changePasswordRes : [] })
    }
}

function listDropHistory(obj){
    return dispatch => {
        showLoader()
        adminServices.listDropHistory(obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_LIST_DROP_HISTORY, dropHistory : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetListDropHistory(){
    return dispatch => {
        dispatch({type : adminConstants.GET_LIST_DROP_HISTORY,dropHistory : [] })
    }
}
function getDropHistoryDetail(id){
    return dispatch => {
        showLoader()
        adminServices.getdropHistoryDetail(id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_HISTORY_DETAIL, dropHistoryDetail : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetDropHistoryDetail(){
    return dispatch => {
        dispatch({type : adminConstants.GET_HISTORY_DETAIL,dropHistoryDetail : [] })
    }
}
function dropHistoryRelisting(obj,id){
    return dispatch => {
        showLoader()
        adminServices.dropHistoryRelisting(obj,id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.DROP_HISTORY_RELISTING, dropHistoryrelisted : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetDropHistoryRelist(){
    return dispatch => {
        dispatch({type : adminConstants.DROP_HISTORY_RELISTING,dropHistoryrelisted : [] })
    }
}
function dropHistoryrelistSuccess(id){
    return dispatch => {
        showLoader()
        adminServices.dropHistoryrelistSuccess(id)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.DROP_HISTORY_RELIST_SUCCESS_UPDATE, dropHistoryupdated : res})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetDropHistoryRelistUpdate(){
    return dispatch => {
        dispatch({type : adminConstants.DROP_HISTORY_RELIST_SUCCESS_UPDATE,dropHistoryupdated : [] })
    }
}
function getStores(obj){
    return dispatch => {
        showLoader()
        adminServices.getStores(obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_STORES, allStores : res})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
        })
    }
}
function resetGetStores(){
    return dispatch => {
        dispatch({type : adminConstants.GET_STORES,allStores : [] })
    }
}
function getStoreDetail(uuid){
    return dispatch => {
        showLoader()
        adminServices.getStoreDetail(uuid)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_STORE_DETAIL, storeDetail : res})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
        })
    }
}
function resetGetStoreDetail(){
    return dispatch => {
        dispatch({type : adminConstants.GET_STORE_DETAIL,storeDetail : [] })
    }
}
function getStoreSalesDetail(uuid,obj){
    return dispatch => {
        showLoader()
        adminServices.getStoreSalesDetail(uuid,obj)
        .then(res => {
            if(res){
                dispatch({type : adminConstants.GET_STORE_SALES_DETAIL, storeSalesDetail : res})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
        })
    }
}
function resetGetStoreSalesDetail(){
    return dispatch => {
        dispatch({type : adminConstants.GET_STORE_SALES_DETAIL,storeSalesDetail : [] })
    }
}

function addBadges(obj){
    return dispatch => {
        showLoader()
        adminServices.addBadges(obj)
        .then(badge => {
            if(badge){
                toast.success("Badge added")
                dispatch({type:adminConstants.ADD_BADGES,addedBadge:badge})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddBadges(){
    return dispatch => {
        dispatch({type:adminConstants.ADD_BADGES,addedBadge:[]})
    }
}
function updateBadgeStatus(obj,id){
    return dispatch => {
        showLoader()
        adminServices.updateBadgeStatus(obj,id)
        .then(badge => {
            if(badge){
                toast.success("Badge updated")
                dispatch({type:adminConstants.UPDATE_BADGES,updatedBadge:badge})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetUpdateBadgeStatus(){
    return dispatch => {
        dispatch({type:adminConstants.UPDATE_BADGES,updatedBadge:[]})
    }
}