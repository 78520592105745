// @ts-nocheck
import React, { Component, Fragment } from 'react'
import "react-toggle/style.css"
import { getUserAddress, getMultipleMintLimit, RetrieveTokens, createSetUp, GetCreatorRoyality, GetMarketFee, getMarketAddress, debounce } from 'utils';
import PubSub from 'pubsub-js';
import { artActions, userAction, walletActions } from 'store/actions';
import { connect } from 'react-redux';
import { getCurrentUserId, isConnectWallet, isWebLogin, localAirdropPage, localArtPage, localDropPage, MaintenancePopup, userBalance } from 'helpers';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import { artServices, tagServices, userServices, walletServices } from 'services';
import history from 'helpers/history';
import { VideoPreview } from './video-player/video-preview';
import { AudioPreivew } from './audio-player/audio-preview';
import { configPath } from '../config';
import { Helmet } from 'react-helmet';
import { options } from 'ipfs-http-client/src/lib/core';
import { ipfsServices } from 'services/ipfs.service';
import axios from 'axios';
import { SwitchAccount } from './switchaccountPopup';
import { refreshJWTFunc } from 'helpers/refreshJWTFunc';
import Select from 'react-select';

var cancelTokenSource = axios.CancelToken.source();
var cancelTokenSource1 = axios.CancelToken.source();
var cancelTokenSource2 = axios.CancelToken.source();

var dataURL = {}
var timeout;

const customStyles = {
    control: (base, state) => ({
        ...base,
        minWidth: "200px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#9e66bf" : "#4f4f4f",
        borderWidth: state.isFocused ? "1px" : "1px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",

        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#9e66bf" : "#9e66bf",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: "#9E66BF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    })
}
const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
        <img
            src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${data.image}`}
            alt={label}
            style={{
                width: '24px', // Customize image width as needed
                marginRight: '8px', // Add some spacing between image and text
            }}
        />
        {label}
    </div>
)
class ArtUploadForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            putSale: false,
            instSale: false,
            purchased: false,
            imageArray: [],
            Uname: '',
            description: '',
            rate: '',
            royalty: 1.0,
            property: '',
            price: 0.0,
            copies: 1,
            usd: '',
            detail: '',
            startTime: '',
            endTime: '',
            isMinting: false,
            cancelMinting: false,
            isLoading: false,
            isUpload: false,
            artUploaded: false,
            submitted: false,
            message: '',
            isAlert: false,
            walletAddress: '',
            roleId: 2,
            called: false,
            processCancel: '',
            imgPreview: '',
            videoPreview: '',
            artType: '',
            audioPreview: '',
            disableProceed: false,
            isActSetup: false,
            isCheckUser: false,
            localImgPreview: '',
            localAudioPreview: '',
            localVideoPreview: '',
            localImageName: '',
            localFinalImage: '',
            localArtType: '',
            localFileData: '',
            originalDimension: null,
            localDimension: null,
            otherArtPreview: '',
            localFileUpload: false,
            originalFileUpload: false,
            localBrowsed: false,
            originalBrowsed: false,
            isMintDisable: true,
            isStorageError: false,
            isMaintenance: false,
            tag: '',
            tags: [],
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            isTagLoading: false,
            categories: [],
            category: 0,
            isSwitchAcc: false,
            api_key: '',
            secret_key: '',
            selectedbadges: [],
            inputValues: '',
            availableBadges: [],
            badgeOptions: []
        }
        this.previewFileUploadProgressHandler = this.previewFileUploadProgressHandler.bind(this);
        this.originalFileUploadProgressHandler = this.originalFileUploadProgressHandler.bind(this);
        this.metaDataUploadProgressHandler = this.metaDataUploadProgressHandler.bind(this);
        this.onStorageSpaceErrorPopupCloseClicked = this.onStorageSpaceErrorPopupCloseClicked.bind(this);
        this.debouncedSearchTags = debounce(this.searchTags.bind(this), 300);

    }
    componentDidMount() {
        // this.props.getUSD()
        localAirdropPage()
        localArtPage()
        localDropPage()
        this.getLimit()
        if (isWebLogin()) {
            this.setState({ isCheckUser: true })
            this.setState({ isCheckUser: true })
            userServices.getUsers().then(res => {
                if ((res && res.statusCode) == 200) {
                    this.setState({ role: res && res.data && res.data.roleId })
                    if ((res && res.data && res.data.roleId) == "1") {
                        refreshJWTFunc()
                        this.setState({ isCheckUser: false })
                        if (!isConnectWallet()) {
                            this.setState({ isCheckUser: false })
                            this.handleToSwitchAccount()
                        }
                    } else {
                        this.setState({ isCheckUser: false })
                        this.hideLoader()
                        history.push('/notFound')
                    }
                }
            }).catch(
                err => {
                    this.setState({ isCheckUser: false })
                    PubSub.publish('msg', false)
                }
            )
        }

        userBalance().then(text => {
            if (text && text.address) {
                this.setState({ address: text.address })
            }
        })
        getUserAddress().then(res => {
            if (res != null) {
                this.setState({ walletAddress: res })
                this.createActSetup(res)

            }
        }).catch(err => {
            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop()

        })
        this.getAllcategories()
        this.getAvailableBadges()
    }
    getAllcategories = () => {
        tagServices.getAllCategories().then(res => {
            if ((res && res.statusCode) === 200) {
                let arr = [{ label: 'Select category of your NFT', value: 0 }]

                res && res.data && res.data.tags && res.data.tags.length > 0 && res.data.tags.map((item) => {
                    let obj = { label: (item && item.name && (item && item.name.charAt(0).toUpperCase() + item.name.slice(1))), value: item && item.id }
                    arr.push(obj)
                })
                this.setState({
                    categories: arr
                })
            }

        }).catch(err => {
            this.setState({
                categories: [{}]
            })
        })
    }
    static getDerivedStateFromProps(props, state) {
        let rate = ""
        if (props) {


            if ((props && props.failedArt && props.failedArt.length > 0)) {
                props.resetUploadFail()
                if (props.failedArt.toLowerCase().includes('insufficient storage')) {
                    return {
                        isStorageError: true,
                        message: props.failedArt,
                        disableProceed: false,
                        secret_key: ''
                    };
                }
                return {
                    isAlert: true,
                    message: props && props.failedArt,
                    style: "danger",
                    disableProceed: false,
                    isStorageError: false,
                    secret_key: ''
                }

            }

            if (props && props.isArt) {


                props.resetArt()
                return {
                    putSale: false,
                    royalty: '',
                    price: '',
                    submitted: false,
                    imageName: '',
                    imageHash: '',
                    description: '',
                    imgPreview: '',
                    artUploaded: true, //success
                    isUpload: false,
                    usd: '',
                    processCancel: '',
                    videoPreview: '',
                    audioPreview: '',
                    disableProceed: false,
                    localImageName: '',
                    localImgPreview: '',
                    localVideoPreview: '',
                    localAudioPreview: '',
                    localFileData: '',
                    localFileUpload: false,
                    localBrowsed: false,
                    originalFileUpload: false,
                    originalBrowsed: false,
                    isMintDisable: true,
                    previewUploadProgress: 0,
                    isPreviewPreparing: false,
                    hasPreviewUploadCompleted: false,
                    originalUploadProgress: 0,
                    hasOriginalUploadCompleted: false,
                    isOriginalUploadInProgress: false,
                    isOriginalPreparing: false,
                    metadataUploadProgress: 0,
                    hasMetadataUploaded: false,
                    isMetadataUploadInProgress: false,
                    isMetadataPreparing: false,
                    tag: '',
                    tags: [],
                    activeSuggestion: 0,
                    filteredSuggestions: [],
                    showSuggestions: false,
                    isTagLoading: false,
                    category: 0,
                    secret_key: ''
                }


            }
            props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
                if ((item && item.asset) == "USD") {
                    rate = item.rate
                }
            })
            return {
                isUpload: false, rate: rate

            }

        }


    }
    componentDidUpdate(props, state) {
        const { Uname, copies } = this.state

        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()

        }
        if ((props && props.failedArt && props.failedArt.length > 0)) {
            this.gotoTop()
            this.revokeKeys(this.state.api_key)
        }
        if (props && props.isArt) {
            this.revokeKeys(this.state.api_key)
            if (copies > 200) {
                history.push(`/mintStatus/${Uname}`)
            }
        }
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            window.location.reload()

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {


        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }

    getImageDimension(file) {
        const dimensionPromise = new Promise((resolve) => {
            const src = URL.createObjectURL(file);
            const image = new Image();
            image.src = src;

            image.onload = function () {
                const dimension = {
                    width: image.width,
                    height: image.height
                };
                URL.revokeObjectURL(src);
                resolve(dimension);
            };
        });
        return dimensionPromise;
    }




    getLimit = () => {
        getMultipleMintLimit().then(res => {
            this.setState({ getLimit: res && res.data && res.data.Value })
            // console.log("limit", res)
        }).catch(err => {
            // console.log("limit err", err)
        })
    }
    getHash = (file) => {
        this.setState({ isOriginalPreparing: true })

        if (this.state.api_key && this.state.api_key !== '' && this.state.secret_key && this.state.secret_key !== '') {
            artServices.uploadFileToIPFS(file, this.state.api_key, this.state.secret_key, this.originalFileUploadProgressHandler, cancelTokenSource1)
                .then((res) => {
                    this.setState({
                        isLoading: false,
                        imageHash: res && res.IpfsHash,
                        isMintDisable: false,
                        hasOriginalUploadCompleted: true,
                        isOriginalUploadInProgress: false,
                        originalUploadProgress: 100
                    }, () => {
                        this.checkForMintEnable();
                    })
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false,
                        imageHash: '',
                        imgPreview: '',
                        videoPreview: '',
                        audioPreview: '',
                        imageName: '',
                        originalFileUpload: false,
                        isOriginalPreparing: false,
                        secret_key: ''
                    })
                    this.revokeKeys(this.state.api_key)
                    if (e && typeof (e) === "string" && e.toLowerCase().includes('usage limit for this key has already been reached')) {
                        this.setState({
                            isAlert: true,
                            message: "Something went wrong, Please try again",
                            style: "danger"
                        })
                        this.gotoTop()

                    } else {
                        this.setState({
                            api_key: '',
                        })
                        if (e && typeof (e) === "string") {
                            this.setState({
                                isAlert: true,
                                message: e,
                                style: "danger"
                            })
                            this.gotoTop()
                        }
                    }

                });
        } else {
            ipfsServices.getApiSecretKeys()
                .then((res) => {
                    if (res && res.data && res.data.apiKey && res.data.apiSecret && this.state.localFileUpload && this.state.originalFileUpload) {
                        this.setState({ api_key: res.data.apiKey, secret_key: res.data.apiSecret })
                        return artServices.uploadFileToIPFS(file, res.data.apiKey, res.data.apiSecret, this.originalFileUploadProgressHandler, cancelTokenSource1);
                    }
                    this.setState({
                        isLoading: false,
                        imageHash: '',
                        imgPreview: '',
                        videoPreview: '',
                        audioPreview: '',
                        imageName: '',
                        originalFileUpload: false,
                        isOriginalPreparing: false,
                        api_key: '',
                        secret_key: ''
                    })
                    throw new Error('IPFS Authorization failed')
                })
                .then((res) => {
                    this.setState({
                        isLoading: false,
                        imageHash: res && res.IpfsHash,
                        isMintDisable: false,
                        hasOriginalUploadCompleted: true,
                        isOriginalUploadInProgress: false,
                        originalUploadProgress: 100
                    }, () => {
                        this.checkForMintEnable();
                    })
                })
                .catch((e) => {
                    this.revokeKeys(this.state.api_key)
                    this.setState({
                        isLoading: false,
                        imageHash: '',
                        imgPreview: '',
                        videoPreview: '',
                        audioPreview: '',
                        imageName: '',
                        originalFileUpload: false,
                        isOriginalPreparing: false,
                        secret_key: ''
                    })
                    if (e && typeof (e) === "string" && e.toLowerCase().includes('usage limit for this key has already been reached')) {
                        this.setState({
                            isAlert: true,
                            message: "Something went wrong, Please try again",
                            style: "danger"
                        })
                        this.gotoTop()

                    } else if (e && typeof (e) === "string") {
                        this.setState({
                            isAlert: true,
                            message: e,
                            style: "danger"
                        })
                        this.gotoTop()
                    }
                });
        }



    }
    getThumbnailHash = (file) => {
        this.setState({ isPreviewPreparing: true })

        ipfsServices.getApiSecretKeys()
            .then((res) => {
                if (res && res.data && res.data.apiKey && res.data.apiSecret && this.state.localFileUpload) {
                    this.setState({ api_key: res.data.apiKey, secret_key: res.data.apiSecret })
                    return artServices.uploadFileToIPFS(file, res.data.apiKey, res.data.apiSecret, this.previewFileUploadProgressHandler, cancelTokenSource);
                }
                this.setState({
                    isLoading: false,
                    previewHash: '',
                    localImageName: '',
                    localImgPreview: '',
                    localVideoPreview: '',
                    localAudioPreview: '',
                    localFileUpload: false,
                    api_key: '',
                    secret_key: '',
                    isPreviewPreparing: false
                })
                throw new Error('IPFS Authorization failed')
            })
            .then((res) => {

                this.setState({
                    isLoading: false,
                    previewHash: res && res.IpfsHash,
                    hasPreviewUploadCompleted: true,
                    isPreviewUploadInProgress: false,
                    previewUploadProgress: 100
                }, () => {
                    this.checkForMintEnable()
                })
            })
            .catch((e) => {
                this.revokeKeys(this.state.api_key)
                this.setState({
                    isLoading: false,
                    previewHash: '',
                    localImageName: '',
                    localImgPreview: '',
                    localVideoPreview: '',
                    localAudioPreview: '',
                    localFileUpload: false,
                    secret_key: '',
                    isPreviewPreparing: false,
                    localBrowsed: false
                })
                if (e && typeof (e) === "string" && e.toLowerCase().includes('usage limit for this key has already been reached')) {
                    this.setState({
                        isAlert: true,
                        message: "Something went wrong, Please try again",
                        style: "danger"
                    })
                    this.gotoTop()

                } else if (e && typeof (e) === "string") {
                    this.setState({
                        isAlert: true,
                        message: e,
                        style: "danger"
                    })
                    this.gotoTop()
                }
            });


    }
    fileProgress1 = (e) => {
        let uploadFile = e.target.files[0]
        // console.log(uploadFile,this.state.localFileUpload,this.state.originalBrowsed)
        if (e.target.files[0]) {
            this.setState({ originalFile: e.target.files[0] })
        }
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm', 'audio/mpeg', 'audio/wav']
        let err = '';
        const sizeLimit = 500 * (1e+6);


        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger", imageHash: '', imgPreview: '', videoPreview: '', audioPreview: '', imageName: '', originalFileUpload: false })
            this.gotoTop()

        } else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += `Maximum supported file size is ${500} MB`;
            this.setState({ isAlert: true, message: err, style: "danger", imageHash: '', imgPreview: '', videoPreview: '', audioPreview: '', imageName: '', originalFileUpload: false })
            this.gotoTop()

        } else {
            this.setState({ originalBrowsed: true, imageName: e.target.files[0] && e.target.files[0].name })
        }
    }
    fileProgress = async (e) => {
        let uploadFile = this.state.originalFile
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm', 'audio/mpeg', 'audio/wav']
        let err = '';

        // set size limit to 300 MB in Bytes
        // to covert MB to bytes multiply with 1e+6
        const sizeLimit = 500 * (1e+6);


        if (types.every(type => uploadFile && uploadFile.type !== type)) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger" })
            this.gotoTop()

        }
        else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += 'Maximum supported file size is 500 MB';
            this.setState({ isAlert: true, message: err, style: "danger" })
            this.gotoTop()

        }
        else if (uploadFile) {



            let originalformData = new FormData()
            originalformData.append("file", uploadFile)
            originalformData.append("pinataOptions", JSON.stringify({ "cidVersion": 1 }))

            this.getHash(originalformData)

            if ((uploadFile && uploadFile.type && ((uploadFile.type == "image/png") || (uploadFile.type == ("image/jpeg")) || (uploadFile.type === ("image/webp")) || (uploadFile.type == ("image/jpg"))))) {



                const resp = uploadFile
                let reader = new FileReader();
                window.URL.createObjectURL(resp);
                reader.onloadend = async (theFile) => {
                    var fileData = await resp
                    const dimension = await this.getImageDimension(resp);
                    const originalDimension = `${dimension.width}x${dimension.height}`;
                    dataURL = await reader.result
                    this.setState({
                        imgPreview: window.URL.createObjectURL(resp),
                        fileData: resp,
                        imageName: resp.name,
                        artType: resp.type,
                        finalImage: reader.result,
                        originalDimension,
                        originalFileUpload: true,

                    })
                    // console.log("i am compressed")

                }

                if (resp) {
                    reader.readAsDataURL(resp)
                }
            }
            else if (
                (uploadFile && uploadFile.type &&
                    ((uploadFile.type === "video/mp4") || (uploadFile.type === "video/webm")))
            ) {
                const file = uploadFile
                const videoPreview = URL.createObjectURL(file);
                this.setState({
                    fileData: file,
                    imageName: file.name,
                    videoPreview,
                    imgPreview: '',
                    audioPreview: '',
                    finalImage: '',
                    otherArtPreview: '',
                    artType: file.type,
                    originalDimension: null,
                    originalFileUpload: true
                })


                // }).catch(error => {videoPreview


            } else if (
                (uploadFile && uploadFile.type &&
                    (uploadFile.type === "audio/mpeg" || uploadFile.type === "audio/wav"))
            ) {
                const file = uploadFile
                this.setState({
                    fileData: file,
                    imageName: file.name,
                    audioPreview: URL.createObjectURL(file),
                    imgPreview: '',
                    videoPreview: '',
                    finalImage: '',
                    otherArtPreview: '',
                    artType: file.type,
                    originalDimension: null,
                    originalFileUpload: true

                });
            } else {
                if (err == '') {
                    let reader = new FileReader();
                    let file = uploadFile
                    // reader.onload = this.GetThumbnail;
                    reader.onloadend = () => {
                        // console.log("i am not compressed")
                        this.setState({
                            fileData: file,
                            imageName: file.name,
                            imgPreview: reader.result,
                            finalImage: reader.result,
                            artType: file.type,
                            originalDimension: null,
                            originalFileUpload: true
                        })
                    }

                    if (uploadFile && uploadFile) {
                        reader.readAsDataURL(file)
                        // console.log("raeder1", reader)
                    }
                }
            }
            //else {
            // if (err == '') {
            //     let reader = new FileReader();
            //     let file = e.target.files[0]
            //     reader.onloadend = () => {
            //         console.log(reader,reader.result)
            //         this.setState({
            //             fileData: file,
            //             imageName: file.name,
            //             otherArtPreview: reader.result,
            //             finalImage: reader.result,
            //             artType: file.type,
            //             originalDimension : null,
            //             audioPreview :'',
            //             videoPreview :'',
            //             imgPreview :''
            //         })
            //     }

            //     if (e.target.files && e.target.files[0]) {
            //         reader.readAsDataURL(file)
            //         // console.log("raeder1", reader)
            //     }
            // }
            //}






        }
        //     if(err == ''){
        //     let reader = new FileReader();
        //     let file = e.target.files[0]
        //     // reader.onload = this.GetThumbnail;
        //     reader.onloadend = () => {
        //         console.log("datareader",reader.result,file)
        //         this.setState({
        //             fileData: file,
        //             imageName: file.name,
        //             imgPreview: reader.result,
        //             finalImage: reader.result
        //         })
        //     }
        //     if (e.target.files && e.target.files[0]) {
        //         reader.readAsDataURL(file)
        //         console.log("raeder1",reader)
        //     }
        // }
    }
    previewFileProgress1 = (e) => {
        let uploadFile = e.target.files[0]

        if (e.target.files[0]) {
            this.setState({ localFile: e.target.files[0] })
        }
        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm', 'audio/mpeg', 'audio/wav']
        let err = '';
        const sizeLimit = 20 * (1e+6);


        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger", localFileUpload: false, localBrowsed: false })
            this.gotoTop()

        } else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += `Maximum supported file size is ${20} MB`;
            this.setState({ isAlert: true, message: err, style: "danger", localFileUpload: false, localBrowsed: false })
            this.gotoTop()

        } else {
            this.setState({ localBrowsed: true, localImageName: e.target.files[0] && e.target.files[0].name })
        }

    }
    previewFileProgress = (e) => {
        // let file = e.target.files[0]
        let uploadFile = this.state.localFile
        // console.log(uploadFile)

        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm', 'audio/mpeg', 'audio/wav']
        let err = '';

        // set size limit to 300 MB in Bytes
        // to covert MB to bytes multiply with 1e+6
        const sizeLimit = 20 * (1e+6);

        // console.log("targetfile", e.target.files[0])
        if (!uploadFile || uploadFile == undefined || !uploadFile.type || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            this.setState({ isAlert: true, message: err, style: "danger" })
            this.gotoTop()

        } else if (uploadFile && uploadFile.size >= sizeLimit) {
            err += `Maximum supported file size is ${20} MB`;
            this.setState({ isAlert: true, message: err, style: "danger" })
            this.gotoTop()

        }
        else if (uploadFile) {

            let localformData = new FormData()
            localformData.append("file", uploadFile)
            localformData.append("pinataOptions", JSON.stringify({ "cidVersion": 1 }))
            this.getThumbnailHash(localformData)

            if ((uploadFile && uploadFile.type && ((uploadFile.type === "image/png") || (uploadFile.type === ("image/jpeg")) || (uploadFile.type === ("image/webp")) || (uploadFile.type === ("image/jpg"))))) {

                const resp = uploadFile
                // console.log("resp", resp, e.target.files[0])
                let reader = new FileReader();
                window.URL.createObjectURL(resp);
                reader.onloadend = async (theFile) => {
                    var fileData = await resp
                    const dimension = await this.getImageDimension(resp);
                    const localDimension = `${dimension.width}x${dimension.height}`;
                    dataURL = await reader.result
                    this.setState({
                        localImgPreview: window.URL.createObjectURL(resp),
                        localFileData: resp,
                        localImageName: resp.name,
                        localArtType: resp.type,
                        localFinalImage: reader.result,
                        localDimension,
                        localFileUpload: true
                    });
                }
                if (resp) {
                    reader.readAsDataURL(resp)
                }



                // }).catch(error => {

                // })
            } else if (
                (uploadFile && uploadFile.type &&
                    ((uploadFile.type === "video/mp4") || (uploadFile.type === "video/webm")))
            ) {
                const file = uploadFile
                const localVideoPreview = URL.createObjectURL(file);
                this.setState({
                    localFileData: file,
                    localImageName: file.name,
                    localVideoPreview,
                    localImgPreview: '',
                    localAudioPreview: '',
                    localFinalImage: '',
                    localArtType: file.type,
                    localDimension: null,
                    localFileUpload: true

                })

            } else if (
                (uploadFile && uploadFile.type &&
                    (uploadFile.type === "audio/mpeg" || uploadFile.type === "audio/wav"))
            ) {
                const file = uploadFile
                this.setState({
                    localFileData: file,
                    localImageName: file.name,
                    localAudioPreview: URL.createObjectURL(file),
                    localImgPreview: '',
                    localVideoPreview: '',
                    localFinalImage: '',
                    localArtType: file.type,
                    localDimension: null,
                    localFileUpload: true

                });
            } else {
                if (err == '') {
                    let reader = new FileReader();
                    let file = uploadFile
                    // reader.onload = this.GetThumbnail;
                    reader.onloadend = () => {
                        this.setState({
                            localFileData: file,
                            localImageName: file.name,
                            localImgPreview: reader.result,
                            localFinalImage: reader.result,
                            localArtType: file.type,
                            localDimension: null,
                            localFileUpload: true
                        })
                    }

                    if (uploadFile && uploadFile) {
                        reader.readAsDataURL(file)
                    }
                }
            }
        }



    }
    GetThumbnail = (e) => {
        var myCan = document.createElement('canvas');
        var img = new Image();
        img.src = e.target.result;
        img.onload = function () {

            myCan.id = "myTempCanvas";
            var tsize = 64;
            myCan.width = Number(tsize);
            myCan.height = Number(tsize);
            if (myCan.getContext) {
                var cntxt = myCan.getContext("2d");
                cntxt.drawImage(img, 0, 0, myCan.width, myCan.height);
                dataURL = myCan.toDataURL();
                var filedetail = myCan.toBlob((blob) => {
                })

            }

        }

    }
    handleToggle = (e) => {

        const putSale = this.state.putSale ? false : true
        this.setState({
            putSale
        })
    }
    handlePurchased = () => {
        const purchased = this.state.purchased ? false : true
        this.setState({ purchased })
    }

    handleInputChange = (e) => {
        if (e.target.value) {
            if ((this.checkEmoji(e.target.value))) {
                this.setState({
                    [e.target.name]: e.target.value, isTooLength: false
                }, () => { this.checkForMintEnable() })
            } else {
                this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                this.gotoTop()
            }
        } else {
            this.setState({
                [e.target.name]: ''
            })
        }
    }
    handleDescChange = (e) => {
        if (e.target.value) {
            if (((e.target.value).length <= 1400)) {
                if ((this.checkEmoji(e.target.value))) {
                    this.setState({
                        [e.target.name]: e.target.value, isTooLength: false,
                    }, () => { this.checkForMintEnable() })
                } else {
                    this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                    this.gotoTop()
                }
            } else {
                if ((this.checkEmoji(e.target.value))) {

                    this.setState({ isTooLength: true, [e.target.name]: (e.target.value.slice(0, 1400)) })
                }else {
                    this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                    this.gotoTop()
                }
            }
        } else {
            this.setState({
                [e.target.name]: ''
            })
        }
    }
    handleCopyChange = (e) => {
        if ((e.target.value) > 0 && (e.target.value) <= (Number(this.state.getLimit)) && ((e.target.value) % 1 == 0)) {
            this.setState({ copies: e.target.value }, () => {
                this.checkForMintEnable()
            })
        } else {
            this.setState({ copies: '' })
        }
    }
    // handlePriceChange = (e) => {
    //     const rate = this.state.rate ? (this.state.rate * e.target.value) : ''
    //     // console.log("usdRate", rate)
    //     this.setState({ price: e.target.value, usd: rate })

    // }

    handleInstSale = () => {
        const instSale = this.state.instSale ? false : true
        this.setState({
            instSale
        })
    }
    // handleStartTime = (e) => {
    //     const startTime = moment(e.target.value).toISOString()
    //     this.setState({startTime})
    // }
    // handleEndTime = (e) => {
    //     const endTime = moment(e.target.value).toISOString()
    //     this.setState({endTime})
    // }
    validNumber = (value) => {
        if (isNaN(value)) {
            return false
        } else {
            return true
        }
    }
    validString = (value) => {
        if ((value && value.length) >= 10) {
            return true
        } else {
            return false
        }
    }

    goNft = () => {
        this.showLoader()
        setTimeout(() => {
            history.push(`/list/${this.state.artTokenId}`)
            this.hideLoader()
        }, 14000)
    }
    checkChar = (value) => {
        if (((value && value.length) >= 3) && (value && value.length <= 140)) {
            return true
        } else {
            return false
        }
    }
    checkEdition = () => {
        const { copies, getLimit } = this.state
        if (copies <= Number(getLimit)) {
            return true
        } else {
            return false
        }

    }
    checkDesc = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkmaxDesc = (value) => {
        if ((value && value.length) <= 1400) {
            return true
        } else {
            return false
        }
    }
    createActSetup = (walletAddress) => {
        if (walletAddress) {
            RetrieveTokens(walletAddress).then(resp => {
                if ((resp && resp.status === 200)) {
                    if ((resp && resp.data && resp.data.Value) == 0) {
                        this.setState({ isActSetup: true })
                        createSetUp().then(res => {
                            this.setState({ isActSetup: false })
                            if (res && res.status == 200) {
                                if (res.data.transaction && res.data.transaction.errorMessage) {
                                    this.setState({ isLoading: false, isMinting: false, isUpload: false })
                                    this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                                    this.gotoTop()
                                } else {
                                    this.setState({ isAlert: true, message: 'Your Flow account has been successfully added. Now you may proceed to create NFT', style: "success" })
                                    this.gotoTop()
                                }
                            }
                            else {
                                console.log("setup err", res)


                                this.setState({
                                    isAlert: true,
                                    message: this.getCadanceError(res),
                                    style: "danger"
                                })
                                this.gotoTop()
                            }

                            // console.log("res", res)
                        }).catch(err => {
                            this.setState({ isActSetup: false })
                            console.log("err", err)
                            this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                            this.gotoTop()
                        })
                    }
                } else {
                    if ((resp && resp.status === 400)) {
                        if ((resp && resp.data && resp.data.message)) {
                            this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        } else {
                            this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    } else {
                        this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                }
            }).catch(err => {
            })
        } else {
            this.setState({ isAlert: true, message: 'Please connect your wallet properly', style: "danger" })
            this.gotoTop()
        }
    }
    checkForMintEnable = () => {
        const { Uname, description, address, copies, price, tags, category } = this.state
        if (

            address &&
            Uname && this.checkChar(Uname) &&
            copies !== null && copies &&
            copies !== undefined &&
            description && this.checkDesc(description) && this.checkmaxDesc(description) &&
            this.validNumber(price) &&
            this.validNumber(copies) && this.state.imageHash && this.state.previewHash
            && (category != 0)
            // && (tags && tags.length > 0)
        ) {
            this.setState({ isMintDisable: false, isTooLength: false })
        } else {
            this.setState({ isMintDisable: true })
        }
    }
    async getCreatorRoyaltyPercentage() {
        const res = await GetCreatorRoyality();
        if (res.status === 200) {
            return (parseFloat(res.data.Value) / 100)
        } else {
            console.log(res)
            throw res.data.message;
        }
    }
    async getMarketFeePercentage() {
        const res = await GetMarketFee();
        if (res.status === 200) {
            return (parseFloat(res.data.Value) / 100)
        } else {
            throw res.data.message;
        }

    }
    async getMarketplaceAddress() {
        const res = await getMarketAddress();
        if (res.status === 200) {
            return res.data.Value;
        } else {
            console.error(res.data.Error);
            throw res.data.message;
        }

    }
    handleSubmit = async (e) => {
        // console.log(this.state.localFileData, this.state.localImageName, this.state.imageName)
        e.preventDefault()
        console.log(this.state.tags)
        const { Uname, description, putSale, address, detail, tags, tag, startTime, endTime, str, purchased, royalty, copies, property, fileData, imageName, localImageName, localFileData, localFinalImage, imageArray, localArtType, price, roleId, getLimit, artType, originalDimension, localDimension } = this.state
        this.setState({ submitted: true })
        let ipfs_path = process.env.REACT_APP_IPFS_PATH
        let tokenId = ''
        let basePrice = ""
        this.setState({
            totalCopiesRequested: Number(copies)
        });
        console.log(this.state)
        if (
            price !== null &&
            price !== undefined &&
            address &&
            Uname && this.checkChar(Uname) &&
            copies !== null && copies &&
            copies !== undefined &&
            description && this.checkDesc(description) && this.checkmaxDesc(description) &&
            this.validNumber(price) &&
            this.validNumber(copies) && this.state.imageHash && this.state.previewHash && localImageName && imageName) {
            if (this.state.category != 0) {
                if (localImageName) {
                    if (imageName) {
                        
                        console.log(this.state.inputValues)
                        // if(tags && tags.length > 0){
                        if (tag && this.checkCharExist(tag)) {
                            this.setState({ isAlert: true, message: 'Please select tag from suggestion or add comma to create new tag', style: "danger" });
                            this.gotoTop();
                            return false
                        }
                        
                        else {

                            const num = (Number(price))
                            // const precision = num.toPrecision(5)
                            const precision = num.toFixed(8)

                            if (price && Number.isInteger(num)) {
                                basePrice = precision
                            } else {
                                basePrice = precision
                            }
                            this.setState({ disableProceed: true })
                            const obj = {
                                title: Uname,
                                walletAddress: this.state.walletAddress,
                                // putOnSale: putSale,
                                totalCopies: Number(copies),
                                description,
                                imageMimeType: '',
                                thumbnailMimeType: '',
                                // isTooLength: false
                            }

                            if (putSale) {
                                if (description && detail && startTime && endTime && this.validString(description) && this.validString(detail)) {
                                    let drop = {
                                        description, startTime: moment(startTime).toISOString(), endTime: moment(endTime).toISOString(), details: detail
                                    }
                                    obj['drop'] = drop

                                } else {
                                    this.setState({ isAlert: true, message: 'Mandatory fields are missing', style: "danger" })
                                    this.gotoTop()

                                    return false
                                }
                            }
                            if(this.state.inputValues && this.state.inputValues.length > 0){
                                let updatedbadgesArray = []
                                const allFieldsFilled = this.state.inputValues.every(this.areFieldsFilled);
                                console.log(allFieldsFilled)
                                if(allFieldsFilled){
                                    this.state.inputValues.map((items) => {
                                        let obj = {
                                            title: items.title,
                                            description: items.description,
                                            badgeId: items.badgeId,
                                            creatorUserId: getCurrentUserId()
                                        }
                                        updatedbadgesArray.push(obj)
                                    })
                                    obj["badges"] = updatedbadgesArray
                                }else{
                                    this.hideLoader()
                                    this.setState({ disableProceed: false })
                                    this.gotoTop()
                                    return false
                                }
                                
                            }

                            let uploadfile = fileData;
                            let formData = new FormData();
                            formData.append("file", uploadfile);
                            const previewFile = localFileData;
                            const previewFormData = new FormData();
                            previewFormData.append("file", previewFile);
                            this.setState({ isMetadataPreparing: true });

                            this.showLoader();
                            const retrieveTokensPromise = RetrieveTokens(this.state.walletAddress)
                                .then((res) => {
                                    console.log("res", res)
                                    if ((res && res.status) == 200) {
                                        if ((res && res.data && res.data.Value) == 0) {
                                            this.setState({ isActSetup: true })
                                            return createSetUp()
                                        } else {
                                            return {
                                                status: 201
                                            }
                                        }
                                    } else {
                                        if ((res && res.status) === 400) {
                                            return {
                                                status: 400,
                                                error: "Retrive Error"
                                            }
                                        }
                                    }

                                })

                            const getUserPromise = userServices.getUsers();
                            const checkArtPromise = artServices.checkArts(Uname);
                            const checkWalletPromise = walletServices.checkWallet(this.state.walletAddress);
                            const royaltyPercentagePromise = this.getCreatorRoyaltyPercentage();
                            const marketFeePercentagePromise = this.getMarketFeePercentage();
                            const marketAddressPromise = this.getMarketplaceAddress();
                            let getApiKeysPromise;
                            if (this.state.api_key === '' && this.state.secret_key === '') {
                                getApiKeysPromise = ipfsServices.getApiSecretKeys();
                            }
                            const metadataJSONPromise = Promise.all(
                                [
                                    getUserPromise,
                                    royaltyPercentagePromise,
                                    marketFeePercentagePromise,
                                    marketAddressPromise,
                                    getApiKeysPromise
                                ]
                            ).then(async (responses) => {
                                const getUserRes = responses[0];
                                const royaltyPercentage = responses[1];
                                const marketFeePercentage = responses[2];
                                const marketAddress = responses[3];
                                const getKeys = responses[4]
                                if (
                                    (getUserRes && getUserRes.statusCode === 200) &&
                                    (getUserRes && getUserRes.data && getUserRes.data.userName) &&
                                    this.state.imageHash &&
                                    this.state.previewHash &&
                                    (getUserRes && getUserRes.data && getUserRes.data.uuid)
                                ) {
                                    const imageString = ipfs_path + this.state.imageHash;
                                    const previewString = ipfs_path + this.state.previewHash;
                                    const metadataJSON = {
                                        "Title": Uname,
                                        "Creator": getUserRes.data.fullName,
                                        // "walletAddress": this.state.walletAddress,
                                        "TotalEditions": Number(copies),
                                        "Description": description,
                                        "MintedDate": moment(new Date()).format('DD-MMMM-YYYY'),
                                        "Media": {
                                            "uri": imageString,
                                            "size": fileData.size,
                                            "mimeType": artType,
                                            "dimensions": originalDimension
                                        },
                                        "MediaPreview": {
                                            "uri": previewString,
                                            // @ts-ignore
                                            "size": localFileData.size,
                                            "mimeType": localArtType,
                                            "dimensions": localDimension

                                        },
                                        "PlatformInfo": {
                                            "Platform": process.env.REACT_APP_MARKETPLACE,
                                            "MintedAt": process.env.REACT_APP_MINTED_AT,
                                            "UserID": getUserRes.data.userName + ' ' + getUserRes.data.uuid
                                        },
                                        "tags": tags,
                                        "royalties": [
                                            {
                                                "address": this.state.walletAddress,
                                                "fee": royaltyPercentage
                                            },
                                            // {
                                            //     "address": marketAddress,
                                            //     "fee": marketFeePercentage
                                            // }
                                        ],
                                        "disruptartCategory": this.state.categoryName
                                    };
                                    console.log("meta data", metadataJSON);
                                    const jsonFormData = new FormData();
                                    const jsonString = JSON.stringify(metadataJSON);
                                    const encodedBytes = new TextEncoder()
                                        .encode(jsonString);
                                    const metaDataJSONBlob = new Blob([encodedBytes], {
                                        type: "application/json;charset=utf-8"
                                    });
                                    jsonFormData.append("file", metaDataJSONBlob);
                                    jsonFormData.append("pinataOptions", JSON.stringify({ "cidVersion": 1 }))
                                    if (this.state.secret_key && this.state.secret_key !== '' && this.state.api_key && this.state.api_key !== '') {
                                        return artServices.uploadFileToIPFS(
                                            jsonFormData,
                                            this.state.api_key,
                                            this.state.secret_key,
                                            this.metaDataUploadProgressHandler,
                                            cancelTokenSource2
                                        );
                                    } else {
                                        if (getKeys && getKeys.data && getKeys.data.apiKey && getKeys.data.apiSecret) {
                                            this.setState({ api_key: getKeys.data.apiKey, secret_key: getKeys.data.apiSecret })
                                            return artServices.uploadFileToIPFS(
                                                jsonFormData,
                                                getKeys.data.apiKey,
                                                getKeys.data.apiSecret,
                                                this.metaDataUploadProgressHandler,
                                                cancelTokenSource2
                                            );
                                        }

                                    }

                                }

                                return Promise.reject(('Failed to create metadata json file'));
                            });
                            Promise.all([
                                // uploadFilePromise,
                                // previewFilePromise,
                                retrieveTokensPromise,
                                getUserPromise,
                                checkArtPromise,
                                checkWalletPromise,
                                metadataJSONPromise
                            ])
                                .then((responses) => {
                                    // const uploadFileRes = responses[0];
                                    // const previewResult = responses[1];
                                    const retriveTokenRes = responses[0];
                                    const getUserRes = responses[1];
                                    const artCheckRes = responses[2];
                                    const wallet = responses[3];
                                    const metaJson = responses[4]
                                    // const imageHash = uploadFileRes && uploadFileRes.Hash;
                                    // const previewHash = previewResult && previewResult.Hash;
                                    const metaDataHash = metaJson && metaJson.IpfsHash
                                    this.hideLoader();
                                    console.log(artCheckRes)
                                    if (artCheckRes && artCheckRes.data && artCheckRes.data.art && artCheckRes.data.art != null) {
                                        this.revokeKeys(this.state.api_key)
                                        this.setState({
                                            disableProceed: false, isMetadataPreparing: false,
                                            isMetadataUploadInProgress: false,
                                            hasMetadataUploaded: false, secret_key: ''
                                        })
                                        this.setState({ isAlert: true, message: 'Art name must be unique', style: "danger" })
                                        this.gotoTop()
                                    } else if (
                                        (getUserRes && getUserRes.statusCode === 200) &&
                                        (getUserRes && getUserRes.data && getUserRes.data.roleId && (getUserRes.data.roleId)) !== 1
                                    ) {
                                        this.revokeKeys(this.state.api_key)
                                        this.setState({
                                            disableProceed: false, isMetadataPreparing: false,
                                            isMetadataUploadInProgress: false,
                                            hasMetadataUploaded: false, secret_key: ''
                                        });
                                        this.setState({ isAlert: true, message: 'You cannot create art. You are now collector, Please contact admin or fill out the creator request form', style: "danger" });
                                        this.gotoTop();
                                    } else if (!(wallet && wallet.statusCode === 200)) {
                                        this.revokeKeys(this.state.api_key)
                                        this.setState({
                                            disableProceed: false, isMetadataPreparing: false,
                                            isMetadataUploadInProgress: false,
                                            hasMetadataUploaded: false, secret_key: ''
                                        });
                                        this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" });
                                        this.gotoTop();
                                    } else if (!(retriveTokenRes && retriveTokenRes.status == 201)) {
                                        this.revokeKeys(this.state.api_key)
                                        if (retriveTokenRes && (retriveTokenRes.status === 400) && (retriveTokenRes.error && retriveTokenRes.error == "Retrive Error")) {
                                            this.setState({ isActSetup: false, isMetadataPreparing: false, isMetadataUploadInProgress: false, disableProceed: false, hasMetadataUploaded: false })
                                            this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })
                                            this.gotoTop()
                                        } else if (retriveTokenRes && (retriveTokenRes.status === 400) && (retriveTokenRes.data && retriveTokenRes.data.Error)) {
                                            this.setState({ isActSetup: false, isMetadataPreparing: false, isMetadataUploadInProgress: false, disableProceed: false, hasMetadataUploaded: false })
                                            this.setState({ isAlert: true, message: (retriveTokenRes && retriveTokenRes.data && retriveTokenRes.data.Error && retriveTokenRes.data.Error.split(':') && retriveTokenRes.data.Error.split(':')[2]), style: "danger" })
                                            this.gotoTop()
                                        } else if ((retriveTokenRes && (retriveTokenRes.status === 200))) {
                                            if (retriveTokenRes.data.transaction && retriveTokenRes.data.transaction.errorMessage) {
                                                this.setState({
                                                    isLoading: false, isMinting: false, isUpload: false, isMetadataPreparing: false,
                                                    isMetadataUploadInProgress: false,
                                                    hasMetadataUploaded: false
                                                })
                                                this.setState({ isActSetup: false, isAlert: true, message: 'Transaction Failed', style: "danger" })
                                                this.gotoTop()
                                            } else {
                                                this.setState({ isActSetup: false, isAlert: true, message: 'Your Flow account has been successfully added. Now you may proceed to create NFT', style: "success" })
                                                this.gotoTop()
                                            }

                                        }
                                        this.setState({ disableProceed: false, secret_key: '' })
                                        this.hideLoader()


                                    } else if (
                                        this.state.imageHash &&
                                        this.state.previewHash &&
                                        (retriveTokenRes && retriveTokenRes.status === 201) &&
                                        (getUserRes && getUserRes.statusCode === 200) &&
                                        (getUserRes && getUserRes.data && getUserRes.data.roleId && (getUserRes.data.roleId)) === 1 &&
                                        (wallet && wallet.statusCode === 200) &&
                                        (artCheckRes && artCheckRes.data && artCheckRes.data.art == null)
                                    ) {
                                        this.setState({ disableProceed: true });
                                        this.setState({ isLoading: false, })
                                        const imageString = ipfs_path + this.state.imageHash;
                                        const previewString = ipfs_path + this.state.previewHash;
                                        const metaDataString = ipfs_path + metaDataHash
                                        obj['imageHash'] = imageString;
                                        obj['thumbnailHash'] = previewString;
                                        obj['imageMimeType'] = artType;
                                        obj['thumbnailMimeType'] = localArtType;
                                        obj['metadataJsonUrl'] = metaDataString
                                        console.log("Mint request object", obj);
                                        this.props.uploadArt(JSON.stringify(obj));
                                        this.setState({ isLoading: false, isMinting: false, isUpload: false, artUploaded: false, disableProceed: false })
                                    }
                                })
                                .catch((err) => {
                                    this.revokeKeys(this.state.api_key)
                                    this.hideLoader()

                                    if (err && typeof (err) === "string" && err.toLowerCase().includes('usage limit for this key has already been reached')) {
                                        this.setState({
                                            message: "Something went wrong, Please try again",
                                        })

                                    } else {
                                        this.setState({
                                            message: err
                                        })
                                    }
                                    this.setState({
                                        isLoading: false,
                                        disableProceed: false,
                                        isMinting: false,
                                        isAlert: true,
                                        style: "danger",
                                        isMetadataPreparing: false,
                                        isMetadataUploadInProgress: false,
                                        hasMetadataUploaded: false,
                                        secret_key: ''
                                    });
                                    this.gotoTop()
                                });




                        }

                        // }else{
                        //     this.hideLoader()
                        //     this.setState({ disableProceed: false })
                        //     this.setState({ isAlert: true, message: 'Tags required', style: "danger" })
                        //     this.gotoTop()
                        // }
                    } else {
                        this.hideLoader()
                        this.setState({ disableProceed: false })
                        this.setState({ isAlert: true, message: 'Media file required', style: "danger" })
                        this.gotoTop()
                    }
                } else {
                    this.hideLoader()
                    this.setState({ disableProceed: false })
                    this.setState({ isAlert: true, message: 'Preview file required', style: "danger" })
                    this.gotoTop()
                }
            } else {
                this.hideLoader()
                this.setState({ disableProceed: false })
                this.setState({ isAlert: true, message: 'Category of your NFT mandatory', style: "danger" })
                this.gotoTop()
            }

        } else {
            this.hideLoader()
            this.setState({ disableProceed: false })
            this.setState({ isAlert: true, message: 'Mandatory fields are missing', style: "danger" })
            this.gotoTop()

        }


    }

    componentWillUnmount() {
        if (this.state.audioPreview !== '') {
            URL.revokeObjectURL(this.state.audioPreview);
        }
        if (this.state.videoPreview !== '') {
            URL.revokeObjectURL(this.state.videoPreview);
        }
    }

    getArtPreview() {
        if (this.state.imgPreview !== '') {

            return (
                <img src={this.state.imgPreview} className="no-images-vertical createartimg" />
            );
        } else if (this.state.videoPreview !== '') {

            const video = {
                source: this.state.videoPreview,
                mime: this.state.artType
            };
            return (
                <VideoPreview source={video.source} mimeType={video.mime} showControls={false} />
            );
        } else if (this.state.audioPreview !== '') {
            return (
                <AudioPreivew source={this.state.audioPreview} mimeType={this.state.artType} thumbNailHeight="190px" thumbnailWidth="190px" className="no-images-vertical createartimg" showControls={false} />
            );
        } else if (this.state.otherArtPreview !== '') {
            const mime = this.state.artType
            const fileName = this.state.imageName
            if (mime && mime.includes("image")) {
                return (
                    <div>
                        <img src={`/${configPath.imageAssets}/no-img.jpg`} className="no-images-vertical createartimg" />
                        <p className="usernamelilac" style={{ position: "absolute", top: "8px", left: "16px" }}>{fileName}</p>
                    </div>
                );
            } else if (mime && mime.includes('video')) {
                return (
                    <div>
                        <img src={`/${configPath.imageAssets}/no-video-art.jpg`} className="no-images-vertical createartimg" />
                        <p className="usernamelilac" style={{ position: "absolute", top: "8px", left: "16px" }}>{fileName}</p>
                    </div>
                );
            } else if (mime && mime.includes('audio')) {
                return (
                    <div>
                        <img src={`/${configPath.imageAssets}/no-audio-art.jpg`} className="no-images-vertical createartimg" />
                        <p className="usernamelilac" style={{ position: "absolute", top: "8px", left: "16px" }}>{fileName}</p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <img src={`/${configPath.imageAssets}/no-img.jpg`} className="no-images-vertical createartimg" />
                        <p className="usernamelilac" style={{ position: "absolute", top: "8px", left: "16px" }}>{fileName}</p>
                    </div>
                );
            }
        }
        else if (this.state.imgPreview === '' && this.state.videoPreview === '' && this.state.audioPreview === '' && this.state.otherArtPreview === '') {
            // console.log('%c Empty background ', 'background:#202020; color:#b798c1');
            return (
                <img src={this.state.imgPreview} className="no-images-vertical createartimg" />
            );
        }
    }
    getLocalPreview() {
        if (this.state.localImgPreview !== '') {

            // console.log('%c ARt preview ', 'background:#202020; color:#b798c1');
            return (
                <img src={this.state.localImgPreview} className="no-images-vertical createartimg" alt="preview" />
            );
        } else if (this.state.localVideoPreview !== '') {

            // console.log('%c Video preview ', 'background:#202020; color:#b798c1');
            const video = {
                source: this.state.localVideoPreview,
                mime: this.state.localArtType
            };
            return (
                <VideoPreview source={video.source} mimeType={video.mime} showControls={false} />
            );
        } else if (this.state.localAudioPreview !== '') {
            return (
                <AudioPreivew source={this.state.localAudioPreview} mimeType={this.state.localArtType} thumbNailHeight="190px" thumbnailWidth="190px" className="no-images-vertical createartimg" showControls={false} />
            );
        } else if (this.state.localImgPreview === '' && this.state.localVideoPreview === '' && this.state.localAudioPreview === '') {
            // console.log('%c Empty background ', 'background:#202020; color:#b798c1');
            return (
                <img src={this.state.localImgPreview} className="no-images-vertical createartimg" alt="" />
            );
        }
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    checkUser = () => {
        this.showLoader()
        setTimeout(() => {
            this.hideLoader()
        }, 3000)
    }
    handleChangeFile = () => {
        cancelTokenSource.cancel();
        cancelTokenSource1.cancel();
        cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource1 = axios.CancelToken.source();
        this.setState({
            localFileData: [],
            localImageName: '',
            localVideoPreview: '',
            localImgPreview: '',
            localAudioPreview: '',
            localFinalImage: '',
            localArtType: '',
            localDimension: null,
            localFileUpload: false,
            localFile: [],
            localBrowsed: false,
            fileData: [],
            imageName: '',
            audioPreview: '',
            imgPreview: '',
            videoPreview: '',
            finalImage: '',
            otherArtPreview: '',
            artType: '',
            originalDimension: null,
            originalBrowsed: false,
            originalFileUpload: false,
            imageHash: '',
            previewHash: '',
            percentCompleted: 0,
            isPreviewUploadInProgress: false,
            hasPreviewUploadCompleted: false,
            isPreviewPreparing: false,
            originalUploadProgress: 0,
            hasOriginalUploadCompleted: false,
            isOriginalUploadInProgress: false,
            isOriginalPreparing: false,
            metadataUploadProgress: 0,
            hasMetadataUploaded: false,
            isMetadataUploadInProgress: false,
            isMetadataPreparing: false,
            isMintDisable: true,
            secret_key: '',
        })
        this.revokeKeys(this.state.api_key)
    }
    handleChangeFileOriginal = () => {
        cancelTokenSource1.cancel();
        cancelTokenSource1 = axios.CancelToken.source();
        this.setState({
            fileData: [],
            imageName: '',
            audioPreview: '',
            imgPreview: '',
            videoPreview: '',
            finalImage: '',
            otherArtPreview: '',
            artType: '',
            originalDimension: null,
            originalBrowsed: false,
            originalFileUpload: false,
            imageHash: '',
            originalUploadProgress: 0,
            hasOriginalUploadCompleted: false,
            isOriginalUploadInProgress: false,
            isOriginalPreparing: false,
            metadataUploadProgress: 0,
            hasMetadataUploaded: false,
            isMetadataUploadInProgress: false,
            isMetadataPreparing: false,
            isMintDisable: true,
            secret_key: '',
        })
        this.revokeKeys(this.state.api_key)
    }
    previewFileUploadProgressHandler(e) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        this.setState({
            previewUploadProgress: percentCompleted < 95 ? percentCompleted : 95,
            hasPreviewUploadCompleted: false,
            isPreviewUploadInProgress: true,
            isPreviewPreparing: false
        });
        // console.log(`%c Progress ${percentCompleted}`, 'font-size:18px');
    }
    originalFileUploadProgressHandler(e) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        this.setState({
            originalUploadProgress: percentCompleted < 95 ? percentCompleted : 95,
            hasOriginalUploadCompleted: false,
            isOriginalUploadInProgress: true,
            isOriginalPreparing: false
        });
        // console.log(`%c Progress ${percentCompleted}`, 'font-size:18px');
    }
    metaDataUploadProgressHandler(e) {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        const isMetadataUploadInProgress = percentCompleted < 100 ? true : false;
        const hasMetadataUploaded = percentCompleted === 100 ? true : false;
        this.setState({
            metadataUploadProgress: percentCompleted,
            hasMetadataUploaded,
            isMetadataUploadInProgress,
            isMetadataPreparing: false
        });
        // console.log(`%c Progress ${percentCompleted}`, 'font-size:18px');
    }
    getUploadStatusText(defaultText, isPreparing, isUploading, hasUploaded, uploadProgressPercent) {

        return (
            isPreparing &&
            !isUploading &&
            !hasUploaded
        ) ? 'Preparing file...'
            : (
                isUploading &&
                !hasUploaded
            ) ? (
                uploadProgressPercent < 95
            ) ? 'Uploading...' : 'Finishing up...'
                : (
                    !isUploading &&
                    hasUploaded
                ) ? <span>Uploaded <i className="fa fa-check"></i></span>
                    : defaultText
    }
    resetPage = () => {
        localStorage.setItem('page', "0")
    }
    getMintSuccessMessage() {
        const { totalCopiesRequested } = this.state;
        // console.log(totalCopiesRequested, 'copies');
        if (totalCopiesRequested <= 300) {
            return (
                <div>
                    <div className="mt-3">Your NFT token minted successfully!</div>
                    <div className="mt-3">It may take 30 seconds for your minted token to appear in your collections :)</div>
                </div>
            );
        }
        return (
            <div>
                <div className="mt-3">Minting of your NFT has been initiated!</div>
                <div className="mt-3">Your minted tokens will start appearing in your collections :)</div>
            </div>
        );
    }
    onStorageSpaceErrorPopupCloseClicked = (e) => {
        e.preventDefault();
        this.setState({ isStorageError: false, artUploaded: false, }, () => console.log('%c state updated', 'font-size:20px', this.state.isStorageError, this.state.artUploaded))
    }
    getStorageSpaceErrorPopup = () => {
        const { message } = this.state;
        const chunks = message.split('.');
        const warning = chunks.shift();
        const details = chunks.join('.').trim()
        return (
            <div className="show-success">
                <div className="success-pop">
                    <div className='text-danger'>
                        <i className="fas fa-exclamation-circle fa-5x"></i>
                    </div>
                    <div className="h3 mt-3">{warning}</div>
                    <div className="mt-3">{details}</div>
                    <div>
                        <button type="button" className="btn btn-primary px-5 mt-5 btn-sm mr-2"
                            onClick={this.onStorageSpaceErrorPopupCloseClicked}>Close</button>
                    </div>
                </div>
            </div>
        );
    }
    gotoMintDetail = () => {
        const { Uname } = this.state
        const baseurl = window.location.origin
        if (Uname) {
            const navPath = (baseurl + `/mintStatus/${Uname}`)
            window.open(navPath, '_blank')

        } else {
            // console.log("cannot open")
        }

    }
    handleMaintenance = () => {
        this.setState({ isMaintenance: true })
    }

    resetMaintenance = () => {
        this.setState({ isMaintenance: false })
    }
    checkEmoji = (value) => {
        const regexExp = /\p{Extended_Pictographic}/gmu;

        if (regexExp.test(value)) {
            return false
        } else {
            return true
        }

    }
    checkMin = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkMax = (value) => {
        if ((value && value.length) <= 50) {
            return true
        } else {
            return false
        }
    }
    checkCharacType = (value) => {
        const char = /[^a-zA-Z0-9 ]/
        if ((value && char.test(value))) {
            return false
        } else {
            return true
        }
    }
    checkCharExist = (value) => {
        var charRegex = /^[ -~]*$/
        if (charRegex.test(value)) {

            return true
        } else {
            return false
        }
    }
    handleTagsChange = (e) => {
        e.persist();
        const { tags } = this.state
        if (this.checkCharacType(e.target.value)) {
            const tag = e.currentTarget.value

            this.debouncedSearchTags(tag)
            this.setState({
                tag,
                showSuggestions: true,
                isTagLoading: false
            })
            if ((tags && tags.length > 0)) {
                this.checkForMintEnable()
            }
        }
    }
    searchTags(searchTerm) {
        const { isFeatureArt } = this.props
        if (searchTerm) {
            if (this.checkCharExist(searchTerm)) {
                let obj = {
                    search: searchTerm
                }
                tagServices.getTagsList(obj).then((response) => {

                    this.setState({
                        isSearching: false,
                        isTagLoading: false
                    })
                    if (response && response.statusCode == 200) {
                        if ((response.data && response.data.tags && Array.isArray(response.data.tags))) {
                            const filteredSuggestions = []
                            for (const tags of response.data.tags) {
                                filteredSuggestions.push({
                                    id: tags.id,
                                    mainText: tags.name,
                                    subText: tags.status
                                })
                            }
                            this.setState({
                                activeSuggestion: 0,
                                filteredSuggestions,
                                showSuggestions: true,
                            });
                        }
                    } else {
                        this.setState({ isAlert: true, message: 'Unexpected error occured on tag search', style: "danger" })
                        this.gotoTop()
                    }

                }).catch(e => {
                    this.setState({ isAlert: true, message: e, style: "danger" })
                    this.gotoTop()
                    this.setState({
                        isSearching: false,
                        isTagLoading: false
                    })

                })
            }
        }
    }
    onKeyDown = (e) => {
        const { tags, tag, tagsList } = this.state
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (e.keyCode == 188) {
            let tag = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

            if (tag.length > 1 && !tags.includes(tag.trim().toLowerCase())) {
                if ((this.checkMax(tag)) && (this.checkMin(tag))) {
                    tags.push(tag.trim().toLowerCase());
                    this.setState({
                        tags,
                        tag: '',
                        activeSuggestion: 0,
                        filteredSuggestions: [],
                        showSuggestions: false
                    }, () => {
                        this.checkForMintEnable()
                    })


                } else {
                    this.setState({ isAlert: true, message: 'Tag must be at least 3 characters min and 50 characters max', style: "danger" })
                    this.gotoTop()
                }

            } else {
                this.setState({ isAlert: true, message: 'You have already entered the tag', style: "danger" });
                this.gotoTop();
            }

        }
        if (e.keyCode === 13) {
            e.preventDefault();

            if ((filteredSuggestions && (filteredSuggestions.length > 0))) {
                const searchResult = filteredSuggestions[activeSuggestion]
                this.setState({
                    activeSuggestion: 0,
                    showSuggestions: false,
                    userInput: ''
                });

                this.handleTagSelect(searchResult);
            } else {
                const { tag } = this.state
                if (tag && (this.checkCharExist(tag))) {
                    this.setState({ isAlert: true, message: 'No suggestions available,add space to create new tag', style: "danger" })
                    this.gotoTop()
                }
            }
        } else if (e.keyCode === 38) {

            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {


            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }

    }
    clickSelectedTag = (selectedSuggestion, e) => {

        this.handleTagSelect(selectedSuggestion)
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            tag: ''
        });
    }
    handleTagSelect = (selectedSuggestion) => {
        const { tags, tagsList } = this.state
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (selectedSuggestion && selectedSuggestion.mainText) {
            let tag = selectedSuggestion.mainText.replace(/[^a-zA-Z0-9 ]/g, '');

            if (tag.length > 1 && !tags.includes(tag)) {
                if ((this.checkMax(tag)) && (this.checkMin(tag))) {
                    document.getElementById('exist-values').focus();
                    tags.push(tag);

                    this.setState({ tags, tag: '' }, () => {
                        this.checkForMintEnable()

                    })


                }

            } else {
                this.setState({ isAlert: true, message: 'You have already entered the tag', style: "danger" });
                this.gotoTop();
            }

        } else {
            this.setState({ isAlert: true, message: 'Unexpected error occured on tag search', style: "danger" })
            this.gotoTop()
        }
    }
    removeItem = (value, index) => {
        const { tags } = this.state
        let tagArray = []
        tagArray = tags.filter((tag, idx) => idx != index)
        this.setState({
            tags: tagArray
        }, () => {
            this.checkForMintEnable()
        })

    }
    handleCategory = (e) => {
        let category = ''
        this.state.categories && this.state.categories.filter((items) => {
            if ((items && items.value) == (e.target.value)) {
                category = items.label
            }
        })
        this.setState({
            category: e.target.value, categoryName: category.toLowerCase()
        }, () => {
            this.checkForMintEnable()
            console.log(this.state.category, this.state.categoryName)
        })
    }
    getCadanceError = (txnResponse) => {
        const error = txnResponse.data.Error

        const splitError = error.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            return "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            return splitError[2]
        } else if (splitError[1]) {
            if (error && error.toLowerCase().includes('declined')) {
                return "User rejected signature"
            } else {
                return splitError[1]
            }
        } else {
            return splitError[0];
        }

    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
        }
    }
    handleSwitchAccFailed = (message) => {
        history.push('/discover')
        this.setState({
            isAlert: true,
            message: message,
            style: "danger"
        })
        this.gotoTop()
    }
    isListPage = () => {
        this.closeSwitchAccModal()
        history.push('/discover')
    }
    revokeKeys = (api_key) => {
        if (api_key && api_key !== '') {
            let obj = {
                apiKey: api_key,
            }
            ipfsServices.revokeKeys(JSON.stringify(obj)).then(res => {
                this.setState({ api_key: '' })
            }).catch(e => {
                console.log("revoke key failed", e)
            })
        }
    }


    getAvailableBadges = () => {
        artServices.getAllAvailableBadges().then(res => {
            if (res && res.statusCode === 200) {
                if (res && res.data && res.data.badges) {
                    if (res.data.badges && res.data.badges.length > 0) {
                        let badgeOptionsArray = []
                        res.data.badges.map((items, index) => {
                            let obj = {
                                value: items.id,
                                label: items.name,
                                image: items.badgePath
                            }
                            badgeOptionsArray.push(obj)
                        })
                        this.setState({
                            availableBadges: res.data.badges,
                            badgeOptions: badgeOptionsArray
                        })
                    }


                }
            } else {
                this.setState({
                    availableBadges: []
                })
            }
        }).catch(e => {
            this.setState({
                availableBadges: []
            })
        })
    }
    handleTitle = (e, idx) => {
        // 1. Make a shallow copy of the items
        let items = [...this.state.inputValues];

        let item = { ...items[idx] };

        
        if (e.target.value) {
            if ((this.checkEmoji(e.target.value))) {
                item.title = e.target.value;

                items[idx] = item;
                this.setState({ inputValues: items });
            } else {
                this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                this.gotoTop()
            }
        } else {
            item.title = '';

                items[idx] = item;
                this.setState({ inputValues: items });
        }
        
    }
    handleDescription = (e, idx) => {
        // 1. Make a shallow copy of the items
        let items = [...this.state.inputValues];

        let item = { ...items[idx] };

        
        if (((e.target.value).length <= 1400)) {
            if ((this.checkEmoji(e.target.value))) {

                item.description = e.target.value;

                items[idx] = item;
                this.setState({ inputValues: items });
                this.setState({
                   isBadgeTooLength: false,
                }, () => { this.checkForMintEnable() })
            } else {
                this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                this.gotoTop()
            }
        } else {
            if ((this.checkEmoji(e.target.value))) {
                item.description = (e.target.value.slice(0, 1400)) 
                items[idx] = item;
                this.setState({ inputValues: items });
                this.setState({ isBadgeTooLength: true})
            } else {
                this.setState({ isAlert: true, message: 'Emoji not supported', style: "danger" })
                this.gotoTop()
            }
            
        }
    
        

        
    }
    handleBadgeClick = (item, idx) => {
        let items = [...this.state.availableBadges];
        let inputValues = [...this.state.inputValues]

        if (item.isSelected) {
            item.isSelected = false
            inputValues.splice(inputValues.findIndex(e => e.badgeId === item.id), 1);
            this.setState({ inputValues })
        } else {
            item.isSelected = true

            let obj = {
                title: '',
                description: '',
                badgeId: item.id,
                selectedBadge: item
            }

            inputValues.push(obj)
            this.setState({ inputValues })

        }
        items[idx] = item;
        this.setState({ availableBadges: items });
    }
    areFieldsFilled = (obj) => {
        if(obj.title.trim() !== "" && this.checkChar(obj.title) && ((obj.description.trim() === "") || 
        ((obj.description.trim() !== "") && this.checkmaxDesc(obj.description) && this.checkDesc(obj.description)))){
            return true
        }else{
            this.setState({ isAlert: true, message: 'Mandatory fields required', style: "danger" })
           
            return false
        }
    }
    render() {
        const { isTooLength, tags, isBadgeTooLength } = this.state
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()
        }
        const {
            clickSelectedTag,
            state: {
                activeSuggestion,
                filteredSuggestions: sugesstionList,
                showSuggestions,
                tag,
                isTagLoading
            }
        } = this;

        let suggestionsListComponent;
        if (showSuggestions && tag && this.checkCharExist(tag)) {
            if (!isTagLoading) {
                if (sugesstionList.length) {
                    suggestionsListComponent = (
                        <ul className="suggestions">

                            {sugesstionList.map((result, index) => {

                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }


                                return (
                                    <li
                                        className={className}
                                        // key={result.artId}
                                        onClick={(e) => clickSelectedTag(result, e)}
                                    >
                                        <span>{result.mainText}</span><br />
                                        {/* <small>{result.subText}</small><br/> */}
                                        <small>{result.artDet}</small>

                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else {
                    suggestionsListComponent = (
                        <div className="no-suggestions">
                            <em>No suggestions available.</em>
                        </div>
                    );
                }
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <i className="fas fa-spin fa-circle-notch fa-lg"></i>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <Helmet>
                    <title>Mint NFT</title>
                    <meta name="title" content="Mint your NFT smart contract"></meta>
                    <meta name="description" content="Mint your NFT smart contract"></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
              
                {this.state.isCheckUser &&
                    <div className="show-content">
                        <div className="child-show">
                            <p className="text-white">Fetching your profile...</p>
                            <div className="bwspinner"></div>

                        </div>
                    </div>
                }
               
                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            {/* <div className="bwspinner"></div> */}
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

                        </div>
                    </div>
                }
                {!this.state.isLoading && !this.state.isMinting && !this.state.isUpload && this.state.artUploaded && !this.state.isActSetup && (Number(this.state.copies) <= 200) &&
                    <div className="show-success">
                        <div className="success-pop">
                            <img src={this.state.localFinalImage} width="170px" />
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            {this.getMintSuccessMessage()}
                            {/* <span onClick={this.gotoMintDetail} style={{cursor:"pointer"}} className="designLink">View Minting Details</span> */}
                            {this.state.artTokenId != null && this.state.artTokenId != undefined && <div className="mt-3"><a style={{ color: "#b798c1", cursor: "pointer" }} onClick={this.goNft}>View NFT</a></div>}
                            <div>
                                <button type="button" className="btn btn-primary px-5 mt-5 btn-sm mr-2" onClick={(e) => { e.preventDefault(); this.setState({ artUploaded: false, totalCopiesRequested: 0, Uname: '', copies: 1 }); this.revokeKeys(this.state.api_key) }}>Close</button>
                                <button type="button" className="btn btn-primary px-5 mt-5 btn-sm" onClick={(e) => { history.push('/wallet'); }}>Go To Collections</button>
                            </div>
                        </div>
                    </div>
                }
                {this.state.isStorageError && this.getStorageSpaceErrorPopup()}
                {this.state.isLoading && !this.state.isMinting && !this.state.isUpload && !this.state.isActSetup &&
                    <div className="show-content"><div className="child-show purchasebox">
                        <div className="upload-btn-container">
                            <svg className="upload-box" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 32 17" version="1.1" xmlSpace="preserve">
                                <path id="upload-box" d="M26 3.82l3.16 1.18 -13.16 4.91 -13.16-4.91 3.16-1.18 0-3.07 -6 2.25 0 8 16 6 16-6 0-8 -6-2.25 0 3.07Z" fill="#b798c1" />
                            </svg>
                            <svg className="upload-arrow" xmlns="http://www.w3.org/2000/svg" width="25" height="50" viewBox="0 0 16 16" version="1.1" xmlSpace="preserve">
                                <path id="upload-arrow" d="M6 16l4 0 0-8 6 0 -8-8 -8 8 6 0 0 8Z" fill="#b798c1" />
                            </svg>
                        </div>
                        <p className="text-white">Art Uploading...</p></div></div>
                }
                {!this.state.isLoading && this.state.isMinting && !this.state.isUpload &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Minting your new NFT token...</p>
                            {/* <div className="bwspinner"></div> */}
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="loading" />

                        </div>
                    </div>
                }

                {!this.state.isLoading && !this.state.isMinting && this.state.isUpload &&
                    <div className="show-content"><div className="child-show purchasebox">
                        <h5 className="">Token Created...!</h5>
                        <div className="upload-btn-container mt-3">
                            <svg className="upload-box" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 32 17" version="1.1" xmlSpace="preserve">
                                <path id="upload-box" d="M26 3.82l3.16 1.18 -13.16 4.91 -13.16-4.91 3.16-1.18 0-3.07 -6 2.25 0 8 16 6 16-6 0-8 -6-2.25 0 3.07Z" fill="#b798c1" />
                            </svg>
                            <svg className="upload-arrow" xmlns="http://www.w3.org/2000/svg" width="25" height="50" viewBox="0 0 16 16" version="1.1" xmlSpace="preserve">
                                <path id="upload-arrow" d="M6 16l4 0 0-8 6 0 -8-8 -8 8 6 0 0 8Z" fill="#b798c1" />
                            </svg>
                        </div>
                        <p className="text-white">Art Uploading</p></div>
                    </div>
                }
                {this.state.isMaintenance &&

                    <MaintenancePopup resetMaintenance={this.resetMaintenance}></MaintenancePopup>

                }
                {!this.state.isLoading && !this.state.isMinting && !this.state.isUpload && this.state.cancelMinting &&
                    <div className="show-cancel">
                        <div className="success-pop">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{(this.state.processCancel && this.state.processCancel.includes("Session expired")) ? ("Session Expired.Please reconnect your wallet account") : (this.state.processCancel)}</div>
                            <div className="py-3">Process Cancelled!</div><div><button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ cancelMinting: false }); window.location.reload() }}>Close</button></div></div></div>
                }
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        isListPage={this.isListPage}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                    : ''}
                {!isWebLogin() ? history.push('/login') : (isWebLogin() && (this.state.role == 1)) && (isConnectWallet()) ? <div className="container artupload">

                    <div className="pagetitabtn"><h1 className="mb-0 my-3 text-center">Mint NFT</h1></div>

                    <div className="row">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                            <div className="card purchasebox1 bg-transparent">
                                <div className="card-body p-0">
                                    <form onSubmit={this.handleSubmit} className="loginfrm">
                                        <div className="row mb-3">


                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <label className="col-form-label " htmlFor="Uname">Title</label>
                                                        <input type="text" className="form-control w-100" name="Uname" id="Uname" value={this.state.Uname} onChange={this.handleInputChange} />
                                                        {this.state.submitted && !this.state.Uname && <div className="mandatory">{"Title Required"}</div>}
                                                        {this.state.submitted && this.state.Uname && !this.checkChar(this.state.Uname) && <div className="mandatory">{"Title should have atleast 3 characters and atmost 140 characters"}</div>}

                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className="col-form-label " htmlFor="copies">Total Editions</label>
                                                        <input type="text" className="form-control w-100 text-right" name="copies" id="copies" value={this.state.copies} onChange={this.handleCopyChange} placeholder="" />
                                                        {this.state.submitted && !this.state.copies && <div className="mandatory">{`Total Copies Required.Must be less than or equal to ${this.state.getLimit}`}</div>}
                                                        {this.state.submitted && this.state.copies && !this.validNumber(this.state.copies) && <div className="mandatory">{"Must be number"}</div>}
                                                        {!this.state.submitted && <div className="mandatory-req text-right">{`Max ${this.state.getLimit} `}</div>}

                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="col-form-label " htmlFor="description">Description</label>
                                                        <textarea className={(isTooLength) ? "form-control w-100 descdan" : "? form-control w-100"} name="description" id="description" value={this.state.description} onChange={this.handleDescChange} placeholder="Describe about your art" />
                                                        {this.state.submitted && !this.state.description && <div className="mandatory">{"Description Required"}</div>}
                                                        {!this.state.submitted && !this.state.description && !isTooLength && <div className="mandatory-req">{"Max 1400 characters"}</div>}
                                                        {!this.state.submitted && !this.state.description && isTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                                        {!this.state.submitted && this.state.description && this.checkDesc(this.state.description) && this.checkmaxDesc(this.state.description) && !isTooLength && <div className="mandatory-req">{"Description should be no more than 1400 characters"}</div>}
                                                        {!this.state.submitted && this.state.description && !this.checkDesc(this.state.description) && <div className="mandatory-req">{"Description must be at least 3 characters"}</div>}
                                                        {this.state.submitted && this.state.description && !this.checkDesc(this.state.description) && <div className="mandatory">{"Description must be at least 3 characters"}</div>}
                                                        {!this.state.submitted && this.state.description && this.checkDesc(this.state.description) && isTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                                        {this.state.submitted && this.state.description && this.checkDesc(this.state.description) && !this.checkmaxDesc(this.state.description) && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}

                                                    </div>

                                                    <div className="form-group col-md-12 adconfg">
                                                        <label className="col-form-label  mb-0" htmlFor="description">Tags</label>
                                                        {<div className="mandatory-req mb-2 mt-0">Type comma,at the end of a word to create a tag</div>}

                                                        <div className="input-group mb-2 inputtags">


                                                            {tags && tags.map((items, index) => (
                                                                <a className="badge badge-primary mr-1 p-2 mb-2 font-weight-normal">
                                                                    {items}&nbsp; <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeItem(items, index)}></i></a>)
                                                            )}

                                                            <textarea
                                                                className="form-control w-50 tagged"
                                                                placeholder="Type here..."
                                                                id="exist-values"
                                                                name="tag"
                                                                data-removeBtn="true"
                                                                value={this.state.tag}
                                                                onChange={this.handleTagsChange}
                                                                onKeyDown={this.onKeyDown}
                                                            >
                                                            </textarea>


                                                            {suggestionsListComponent}
                                                        </div>

                                                        {<div className="mandatory">{"Minimum 3 to maximum 50 characters, Special characters are not allowed"}</div>}


                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="col-form-label " htmlFor="category">Select Category</label>
                                                        <select className='form-control' value={this.state.category} onChange={this.handleCategory}>
                                                            {this.state.categories && this.state.categories.map((items) => (
                                                                <option value={items.value}>{items && items.label}</option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <label className="col-form-label " htmlFor="category">Select badge(s)</label>
                                                            <div className='Utility-Badges'>
                                                                {this.state.availableBadges && this.state.availableBadges.length > 0 && this.state.availableBadges.map((items, index) => (
                                                                    <div className={(items.isSelected) ? 'ubbox active' : 'ubbox'} onClick={() => this.handleBadgeClick(items, index)} title={"Click here to Add/Remove badges"}>
                                                                        <div className='ubboximg'>
                                                                            <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                                                                            <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                                                                            <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.badgePath && items.badgePath.slice(1)}`} alt="" /></span>
                                                                        </div>

                                                                        <p title={items.name}>{items.name}</p>
                                                                    </div>
                                                                ))}



                                                            </div>

                                                            {(this.state.inputValues && this.state.inputValues.length > 0) && this.state.inputValues.map((items, index) => (
                                                                <div className='ubboxcont'>
                                                                    <div className='ubboxwon1'>
                                                                        <div className='ubboxwon'>
                                                                            <div className='ubbox active'>
                                                                                <div className='ubboximg'>
                                                                                    <img src={`../../../../${configPath.imageAssets}/Badges/badgebg.png`} alt="" className='color-in' />
                                                                                    <img src={`../../../../${configPath.imageAssets}/Badges/gray-out.png`} alt="" className='gray-out' />
                                                                                    <span><img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.selectedBadge.badgePath && items.selectedBadge.badgePath.slice(1)}`} alt="" /></span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='ubboxtxt'>
                                                                                <h5>{items.selectedBadge.name}</h5>
                                                                                {/* <p>Diam dictumst justo</p> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <input className='form-control' value={items.title} onChange={(e) => this.handleTitle(e, index)} placeholder='Title' />
                                                                            {this.state.submitted && !items.title && <div className="mandatory">{"Title Required"}</div>}
                                                                            {this.state.submitted && items.title && !this.checkChar(items.title) && <div className="mandatory">{"Title should have atleast 3 characters and atmost 140 characters"}</div>}
                                                                        </div>
                                                                        <div className='mt-3'>
                                                                            <textarea className={isBadgeTooLength ? 'form-control w-100 descdan' : 'form-control'} value={items.description} onChange={(e) => this.handleDescription(e, index)} placeholder='Description' />
                                                                            {/* {this.state.submitted && !items.description && <div className="mandatory">{"Description Required"}</div>} */}

                                                                            {!this.state.submitted && !items.description && !isBadgeTooLength &&  <div className="mandatory-req">{"Max 1400 characters"}</div>}
                                                                            {/* {!this.state.submitted && !items.description && isBadgeTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>} */}
                                                                            {!this.state.submitted && items.description && this.checkDesc(items.description) && this.checkmaxDesc(items.description) && !isBadgeTooLength && <div className="mandatory-req">{"Description should be no more than 1400 characters"}</div>}
                                                                            {!this.state.submitted && items.description && !this.checkDesc(items.description) && <div className="mandatory-req">{"Description must be at least 3 characters"}</div>}
                                                                            {this.state.submitted && items.description && !this.checkDesc(items.description) && <div className="mandatory">{"Description must be at least 3 characters"}</div>}
                                                                            {!this.state.submitted && items.description && this.checkDesc(items.description) && isBadgeTooLength && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                                                            {this.state.submitted && items.description && this.checkDesc(items.description) && !this.checkmaxDesc(items.description) && <div className="mandatory">{"Description should be no more than 1400 characters"}</div>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            ))}

                                                      


                                                    </div>

                                                </div>
                                                <div className="row">

                                                    {/* <div className="form-group mb-4 col-md-4">
                                                        <div className="row">
                                                            <label className="col-md-8 col-form-label ">Put On Sale</label>
                                                            <div className="col-md-4">
                                                                <Toggle checked={this.state.putSale} />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12">
                                                        {this.state.putSale &&

                                                            <div className="row">
                                                                <div className="form-group col-md-6">
                                                                    <label className="col-form-label ">Start Time</label>
                                                                    <input type="date" className="form-control w-100" name="startTime" value={this.state.startTime} onChange={this.handleInputChange} />
                                                                    {this.state.submitted && this.state.putSale && !this.state.startTime && <div className="mandatory">{"Start Time Required"}</div>}

                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label className="col-form-label ">End Time</label>
                                                                    <input type="date" className="form-control w-100" name="endTime" value={this.state.endTime} onChange={this.handleInputChange} />
                                                                    {this.state.submitted && this.state.putSale && !this.state.endTime && <div className="mandatory">{"End Time Required"}</div>}

                                                                </div>

                                                                <div className="form-group col-md-12">
                                                                    <label className="col-form-label ">Description</label>
                                                                    <textarea className="form-control" name="description" value={this.state.description} onChange={this.handleInputChange} placeholder="Describe about your art" />
                                                                    {this.state.submitted && this.state.putSale && !this.state.description && <div className="mandatory">{"Description Required"}</div>}
                                                                    {this.state.submitted && this.state.putSale && this.state.description && !this.validString(this.state.description) && <div className="mandatory">{"Description must be atleast 10 characters"}</div>}

                                                                </div>
                                                                <div className="form-group col-md-12">
                                                                    <label className="col-form-label ">Detail</label>
                                                                    <textarea className="form-control" name="detail" value={this.state.detail} onChange={this.handleInputChange} placeholder="Detail" />
                                                                    {this.state.submitted && this.state.putSale && !this.state.detail && <div className="mandatory">{"Detail Required"}</div>}
                                                                    {this.state.submitted && this.state.putSale && this.state.detail && !this.validString(this.state.detail) && <div className="mandatory">{"Detail must be atleast 10 characters"}</div>}

                                                                </div>
                                                            </div>

                                                        }

                                                        <div className="form-group d-none">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="col-form-label ">NFT Thumbnail/Preview</label>
                                            <p className="usernamelilac mb-1">This is the Image/Video shown to collectors to preview your NFT in our marketplace</p>
                                            <p className="text-white small">(Snippet, Trailer, Preview, Thumbnail, Cover, Short Duration)</p>
                                            <div className="previewimg mb-3">
                                                {this.getLocalPreview()}
                                                <span className="previewtxt">Preview</span>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                                <div className="col-sm-5"><div className="mandatory-req text-white">Maximum File Size <strong className='usernamelilac'>20 MB</strong><br /> Minimum <strong className='usernamelilac'>500px x 500px</strong></div></div>
                                                <div className="col-sm-7"><div className="mandatory-req brdnft text-white">Supported format for Image <strong className='usernamelilac'>(JPG, JPEG, PNG, WEBP, GIF) , Video (MP4, WEBM) , Audio (WAV, MP3)</strong></div></div>
                                            </div>

                                            <div className="row mb-3 nftbtns">
                                                <div className="col-md-12"><div className="mandatory-req mb-3 text-white">{this.state.localImageName}</div></div>
                                                <div className="col-sm-5">
                                                    {this.state.localFileUpload && <div className="choosefile" onClick={this.handleChangeFile}><label className="btn btn-secondary w-100">Change file</label></div>}
                                                    {!this.state.localFileUpload && <div className="choosefile">
                                                        <input type="file" id="pfile" name="image" onChange={this.previewFileProgress1} className="inp-box" accept="image/jpeg, image/png, image/gif, image/webp,video/mp4, video/webm, audio/mpeg, audio/wav, .wav" />
                                                        <label htmlFor="pfile" className="btn btn-primary-fill w-100">Browse File</label>
                                                        {this.state.submitted && !this.state.localImageName && <div className="mandatory">{"Preview File Required"}</div>}
                                                    </div>}
                                                </div>
                                                <div className="col-sm-7" >
                                                    <label className="small changefiletxt">&nbsp;</label>
                                                    <button
                                                        type="button"
                                                        className={
                                                            ((this.state.localFileUpload || !this.state.localBrowsed) ? "btn btn-primary-fill w-100 progress-btn disableDv" : "btn btn-primary-fill w-100 progress-btn")
                                                            + (this.state.isPreviewUploadInProgress ? " active" : "")
                                                        }
                                                        data-progress-style="fill-bottom"
                                                        onClick={this.previewFileProgress}
                                                    >
                                                        <div className="uptxt">
                                                            {
                                                                this.getUploadStatusText(
                                                                    'Upload Thumbnail/Preview',
                                                                    this.state.isPreviewPreparing,
                                                                    this.state.isPreviewUploadInProgress,
                                                                    this.state.hasPreviewUploadCompleted,
                                                                    this.state.previewUploadProgress
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className="progress"
                                                            style={
                                                                {
                                                                    width: `${this.state.previewUploadProgress}%`
                                                                }
                                                            }
                                                        ></div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label ">NFT</label>
                                            <p className="usernamelilac mb-1">Upload your original NFT content</p>
                                            <p className="text-white small">(High Resolution, Full Version, File Output and Final Cut or Source)</p>
                                            <div className="previewimg mb-3">
                                                {this.getArtPreview()}
                                                <span className="previewtxt">Preview</span>
                                            </div>
                                            <div className="row mb-3 align-items-center">
                                                <div className="col-sm-6"><div className="mandatory-req text-white">Maximum File Size <strong className='usernamelilac'>500 MB</strong></div></div>
                                                <div className="col-sm-6"> <div className="mandatory-req brdnft text-white">Supported format for Image <strong className='usernamelilac'>(JPG, JPEG, PNG, WEBP, GIF) , Video (MP4, WEBM) , Audio (WAV, MP3)</strong></div></div>
                                            </div>

                                            <div className="row mb-3 nftbtns">
                                                <div className="col-md-12"><div className="mandatory-req mb-3 text-white">{this.state.imageName}</div></div>
                                                <div className="col-sm-5">
                                                    {this.state.originalFileUpload && <div className="choosefile" onClick={this.handleChangeFileOriginal}><label className="btn btn-secondary w-100">Change file</label></div>
                                                    }
                                                    {!this.state.originalFileUpload && <div className={(this.state.localFileUpload) ? "choosefile " : "choosefile disableDv"} >
                                                        <input type="file" id="pfile1" onChange={this.fileProgress1} className="inp-box" accept="image/jpeg, image/png, image/gif, image/webp,video/mp4, video/webm, audio/mpeg, audio/wav, .wav" />
                                                        <label htmlFor="pfile1" className="btn btn-primary-fill w-100 ">Browse File</label>
                                                        {this.state.submitted && !this.state.imageName && <div className="mandatory">{"Media File Required"}</div>}
                                                    </div>}
                                                </div>
                                                <div className="col-sm-7">
                                                    <label className="small changefiletxt">&nbsp;</label>
                                                    <button
                                                        type="button"
                                                        className={
                                                            (
                                                                (!this.state.originalFileUpload && this.state.originalBrowsed && this.state.localFileUpload)
                                                            ) ? "btn btn-primary-fill w-100 progress-btn " : "btn btn-primary-fill w-100 progress-btn disableDv"
                                                            + (this.state.isOriginalUploadInProgress ? " active" : "")
                                                        }
                                                        data-progress-style="fill-bottom"
                                                        onClick={this.fileProgress}
                                                    >
                                                        <div className="uptxt">
                                                            {
                                                                this.getUploadStatusText(
                                                                    'Upload NFT',
                                                                    this.state.isOriginalPreparing,
                                                                    this.state.isOriginalUploadInProgress,
                                                                    this.state.hasOriginalUploadCompleted,
                                                                    this.state.originalUploadProgress
                                                                )
                                                            }
                                                        </div>
                                                        <div
                                                            className="progress"
                                                            style={
                                                                {
                                                                    width: `${this.state.originalUploadProgress}%`
                                                                }
                                                            }
                                                        ></div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="form-group">
                                            <label className="col-form-label ">Unlocked NFT Once Purchased 
                                            
                                            </label>
                                            <p className="usernamelilac mb-1">(Unlockable content feature coming soon)</p>
                                        </div> */}

                                        <div className='card-footer text-center py-1 mt-0'>
                                            <div className="mt-2 mb-2">
                                                <span className="text-small usernamelilac">
                                                    {
                                                        (
                                                            this.state.isMetadataPreparing &&
                                                            !this.state.isMetadataUploadInProgress &&
                                                            !this.state.hasMetadataUploaded
                                                        ) ? 'Preparing Metadata...'
                                                            : (
                                                                this.state.isMetadataUploadInProgress &&
                                                                !this.state.hasMetadataUploaded
                                                            ) ? `Uploading Metadata... (${this.state.metadataUploadProgress}%)`
                                                                : (
                                                                    !this.state.isMetadataUploadInProgress &&
                                                                    this.state.hasMetadataUploaded
                                                                ) ? <span>Metadata Uploaded <i className="fa fa-check"></i></span>
                                                                    : ''
                                                    }
                                                </span>
                                            </div>
                                            <button type="submit" className="btn btn-primary" disabled={this.state.disableProceed || this.state.isMintDisable}>Mint NFT</button>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <div className="container text-center pagenotfound py-5">
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="mb-4">
                                    <button className="btn btn-primary px-5" type="button" onClick={this.handleToSwitchAccount}>Connect Wallet</button>
                                </div>
                                <div className="mb-4">
                                    <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                    </svg>
                                </div>
                                <p>
                                    If you want to create art, please connect your wallet account
                                </p>


                                <p><a href="/" className="btn btn-primary px-5">Back to home</a></p>

                            </div>
                        </div>
                    </div>

                </div>}
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isArt: state.art.art,
        usd: state.user.usd,
        usersList: state.user.usersList,
        failedArt: state.art.failedArt,
        wallet: state.wallet.wallet

    }
}
const actionCreators = {
    uploadArt: artActions.uploadArt,
    resetArt: artActions.resetUpload,
    getUSD: userAction.getUSD,
    getUserProfile: userAction.getUsers,
    resetUploadFail: artActions.resetUploadFail,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet

}
const connectArt = connect(mapStateToProps, actionCreators)(ArtUploadForm)
export { connectArt as ArtUploadForm }
