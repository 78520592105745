import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import { DraggableList } from "components/draggable-list/draggable-list";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices, discoverServices } from "services";
import PubSub from 'pubsub-js';
import { SuggestTrendingTags } from "./SuggestTrendingTags";

export class AdminTrending extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            trendingTags: [],
            offSet: 0,
            recordLimit: 10,
            sortBy: 'updatedAt',
            sortOrder: 'DESC',

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.getTrendingTags()
    }
    getTrendingTags = () => {
        let trendingTags = []
        this.showLoader()
        adminServices.getAllTrendingTags().then(res => {
            this.hideLoader()
            if (res && res.statusCode === 200) {
                console.log(res)
                res && res.data && res.data.trendingTags && res.data.trendingTags.forEach((item) => {
                    trendingTags.push({
                        text: item && item.tag && item.tag.name,
                        id: item && item.tag && item.tag.id,

                    })
                })
                this.setState({ trendingTags, })
            }
        }).catch(e => { this.hideLoader() })
    }



    trendingArtSelected = (e) => {
        const { trendingTags } = this.state;
        if (Number(trendingTags && trendingTags.length) >= Number(process.env.REACT_APP_TRENDINGTAG_LIMIT)) {
            toast.error("Maximum limit for trending tag was reached")
        } else {

            const isArtSelected = trendingTags.find(art => art.id === e.TagId)
            if (
                !isArtSelected
            ) {
                if (e && e.TagId) {
                    this.showLoader()
                    let obj = {
                        tagIds: [e.TagId]
                    }
                    adminServices.addTrendingTags(JSON.stringify(obj)).then((response) => {
                        if (response && response.statusCode === 201) {
                            this.getTrendingTags()
                            this.hideLoader()
                            toast.success("Tag added")

                        }
                    }).catch(e => {
                        toast.error(e)
                        this.hideLoader()
                    }
                    );
                }

            }
        }

    }

    spotLightArtDeletedHandler = (item, e) => {
        this.showLoader()
        adminServices.deleteTrendingTags(item && item.id).then(update => {
            this.hideLoader()
            if (update && update.statusCode === 200) {
                this.getTrendingTags()
                toast.success("Tag removed")
            }
        }).catch(e => {
            this.hideLoader()
            toast.error(e)

        })

    }




    updateOrderChange = (item, update) => {
        // console.log(item,update)
        if (update === "spot") {
            // this.updateSpotLight(item)
        }
    }

    render() {
        // console.log("spot light1",this.state.spotLightArts)

        const {
            trendingTags,

        } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="mb-4 admintitle">
                        
                        <h2 className="font-weight-bold">Trending Tags configuration</h2>
                        
                        
                                          
                        </div>
                   
                    <div className="purchasebox p-2 mb-3 overflow-visible">
                        <div className="card">
                            <div className="card-body">
                            <h3>Select tag </h3>                            
                            <SuggestTrendingTags
                                id="tagSuggest"
                                placeholder="Search Tag"
                                onSuggetionSelected={this.trendingArtSelected}

                            />
                            <p className="small warn-mes mt-3 text-center">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                Max limit {process.env.REACT_APP_TRENDINGTAG_LIMIT} tags<br />

                            </p>
                            </div>
                            </div>
                    </div>
                            {trendingTags && trendingTags.length > 0 &&
                                <DraggableList id="spotLightList" data={trendingTags} onItemDelete={this.spotLightArtDeletedHandler} onOrderChanged={(e) => { this.updateOrderChange(e, "spot") }} />}
                        
                    

                    

                </div>
            </Fragment>
        );
    }
}