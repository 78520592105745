// [INFO] Added for testing bab testnet
// [TODO] Remove after testing

import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"


export async function sampleFunc1() {

   console.log("Inside the function updated");

   let scriptcode = `
        import FungibleToken from 0x9a0766d93b6608b7
        import NonFungibleToken from 0x631e88ae7f1d7c20
        import DapperUtilityCoin from 0x82ec283f88a62e65
        import NFTStorefront from 0x94b06cfca1d8a476
        import DisruptArt from 0x439c2b49c0b2f62b
        // This transcation can be used to place and NFT for sale on a marketplace such that a specified percentage of the proceeds of the sale
        // go to the dapp as a royalty.
        transaction() {
            let sellerPaymentReceiver: Capability<&{FungibleToken.Receiver}>
            let nftProvider: Capability<&DisruptArt.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>
            let storefront: &NFTStorefront.Storefront
            let dappAddress: Address
            // It's important that the dapp account authorize this transaction so the dapp has the ability
            // to validate and approve the royalty included in the sale.
            prepare(seller: AuthAccount) {
                self.dappAddress = seller.address
                // If the account doesn't already have a storefront, create one and add it to the account
                if seller.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath) == nil {
                    let newstorefront <- NFTStorefront.createStorefront() as! @NFTStorefront.Storefront
                    seller.save(<-newstorefront, to: NFTStorefront.StorefrontStoragePath)
                    seller.link<&NFTStorefront.Storefront{NFTStorefront.StorefrontPublic}>(
                        NFTStorefront.StorefrontPublicPath,
                        target: NFTStorefront.StorefrontStoragePath
                    )
                }

                
                // Get a reference to the receiver that will receive the fungible tokens if the sale executes.
                self.sellerPaymentReceiver = seller.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)

               
                if (self.sellerPaymentReceiver.borrow()  == nil) {
                    seller.save(<-DapperUtilityCoin.createEmptyVault(), to: /storage/dapperUtilityCoinVault)
                    seller.link<&DapperUtilityCoin.Vault{FungibleToken.Receiver}>(
                               /public/dapperUtilityCoinReceiver,
                               target: /storage/dapperUtilityCoinVault
                      )

                      seller.link<&DapperUtilityCoin.Vault{FungibleToken.Balance}>(
                                /public/dapperUtilityCoinBalance,
                                target: /storage/dapperUtilityCoinVault
                      )
               }


                assert(self.sellerPaymentReceiver.borrow() != nil, message: "Missing or mis-typed DapperUtilityCoin receiver")
                // If the user does not have their collection linked to their account, link it.
                let nftProviderPrivatePath = /private/DisruptArtCollectionProviderForNFTStorefront
                let hasLinkedCollection = seller.getCapability<&DisruptArt.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(
                        nftProviderPrivatePath
                    )!.check()
                if !hasLinkedCollection {
                    seller.link<&DisruptArt.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(
                        nftProviderPrivatePath,
                        target: DisruptArt.disruptArtStoragePath
                    )
                }
                // Get a capability to access the user's NFT collection.
                self.nftProvider = seller.getCapability<&DisruptArt.Collection{NonFungibleToken.Provider, NonFungibleToken.CollectionPublic}>(
                        nftProviderPrivatePath
                    )!
                assert(self.nftProvider.borrow() != nil, message: "Missing or mis-typed collection provider")
                // Get a reference to the user's NFT storefront
                self.storefront = seller.borrow<&NFTStorefront.Storefront>(from: NFTStorefront.StorefrontStoragePath)
                    ?? panic("Missing or mis-typed NFTStorefront Storefront")
                // Make sure this NFT is not already listed for sale in this storefront.
                let existingOffers = self.storefront.getListingIDs()
                if existingOffers.length > 0 {
                    for listingResourceID in existingOffers {
                        let listing: &NFTStorefront.Listing{NFTStorefront.ListingPublic}? = self.storefront.borrowListing(listingResourceID: listingResourceID)
                        if listing != nil && listing!.getDetails().nftID == 3803172 && listing!.getDetails().nftType == Type<@DisruptArt.NFT>(){
                            self.storefront.removeListing(listingResourceID: listingResourceID)
                        }
                    }
                }
            }
            // Make sure dapp is actually the dapp and not some random account
            pre {
                self.dappAddress == 0x78dd727209db579c: "Requires valid authorizing signature"
            }
            execute {
                // Calculate the amout the seller should receive if the sale executes, and the amount
                // that should be sent to the dapp as a royalty.
                let amountSeller = 100.0 * (1.0 - 0.5)
                let amountRoyalty = 100.0 - amountSeller
                // Get the royalty recipient's public account object
                let royaltyRecipient = getAccount(0x78dd727209db579c)
                // Get a reference to the royalty recipient's Receiver
                let royaltyReceiverRef = royaltyRecipient.getCapability<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
                assert(royaltyReceiverRef.borrow() != nil, message: "Missing or mis-typed DapperUtilityCoin royalty receiver")
                let saleCutSeller = NFTStorefront.SaleCut(
                    receiver: self.sellerPaymentReceiver,
                    amount: amountSeller
                )
                let saleCutRoyalty = NFTStorefront.SaleCut(
                    receiver: royaltyReceiverRef,
                    amount: amountRoyalty
                )
                self.storefront.createListing(
                    nftProviderCapability: self.nftProvider,
                    nftType: Type<@DisruptArt.NFT>(),
                    nftID: 3803174,
                    salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
                    saleCuts: [saleCutSeller, saleCutRoyalty]
                )
            }
        }
   `;


  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])


  try {

  console.log(blockResponse);

  console.log(scriptcode);

  const { transactionId } = await fcl.send([
    fcl.transaction(scriptcode),
    fcl.args([
    ]),
    fcl.proposer(fcl.currentUser().authorization),
    fcl.authorizations([
      fcl.currentUser().authorization
    ]),
    fcl.payer(fcl.currentUser().authorization),
    fcl.ref(blockResponse["block"].id),
    fcl.limit(9999),
  ])

  console.log(transactionId);
  const result = new Promise((resolve, reject) => {
    fcl

      .tx(transactionId)

      .subscribe(transaction => {

        if (fcl.tx.isSealed(transaction)) {
          resolve({
            status: 200,
            data: {
              "message": "Market Token Price change",
              "Value": String(transactionId),
              "transaction": transaction
            }
          })
        }
      })
  })

  console.log(result);

} catch (e) {
  console.log( {
    status: 400,
    data: { "message": "Exception happens", "Error": String(e) }
  }
  );
}



}
export function sampleFunc2() {
   const array2 = []
   array2.push("function2")
   console.log(array2)
}
export function sampleFunc3() {
   const array3 = []
   array3.push("function3")
   console.log(array3)
}
export function sampleFunc4() {
   const array4 = []
   array4.push("function4")
   console.log(array4)
}