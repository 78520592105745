import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, followActions, userAction, walletActions } from 'store/actions';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { airDropService, followServices, userServices } from 'services';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from '../../config'
import { CommonDisableError } from '../common_disable_error';
import { SwitchAccount } from 'components/switchaccountPopup';
import PubSub from 'pubsub-js';
import { connectBlocto } from 'helpers/getFlowUsd';
import { RetrieveTokens, createSetUp, getUserAddress } from 'utils';
import { FollowPopup } from 'components/follow-popup';
import CommonError from 'components/Common-error-ui/commonError';

var timeout;
let collectionTimeout;
class AirdropGuestPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            isSuccess: false,
            isAlert: false,
            isUsernameError: '',
            isUserTyping: false,
            message: '',
            style: '',
            isVerified: false,
            reCaptchaToken: '',
            isAgree: false,
            code: '',
            isError: false,
            isCalled: false,
            verifiedMes: '',
            unregEmail: '',
            isSwitchAcc: false,
            currentUserDet: {},
            isFollower: true,
            tokenId: '',
            listedUserId: '',
            isActSetup: false,
            isSuccessClaim: false,
            isAirdropClaimMessage: '',
            isArtDisabled: false,
            disabledMessage: ''
        }
        this.closedMessage = <p>Oops! Process closed without completing it.</p>
    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({ isSuccess: false })


        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let airDropId = Number(urlParams.get('airDropId'))
        let listedUserId = Number(urlParams.get('listedUserId'))
        let code = this.props && this.props.match && this.props.match.params && this.props.match.params.token && this.props.match.params.token



        if (code) {
            this.setState({ code, listedUserId, airDropId })
            this.props.registerAirdropUser(code, airDropId)
        } else {
            this.setState({
                isAlert: true,
                message: 'Invalid url',
                style: "danger"
            })
        }

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            
            if ((props && props.failedReg && props.failedReg.length > 0)) {
                props.resetregUserFailed()

                if (props.failedReg && (props.failedReg.toLowerCase().includes('logintoken must be a uuid'))) {
                    return {
                        isVerified: true,
                        isError: true,
                        verifiedMes: 'Oops! Invalid Link'
                    }
                } else if (props.failedReg && (props.failedReg.toLowerCase().includes('account has been disabled'))) {
                    return {
                        isVerified: false,
                        isError: true,
                        verifiedMes: props.failedReg
                    }
                } else if (props.failedReg && (props.failedReg.toLowerCase().includes('token is already used'))) {
                    return {
                        isVerified: true,
                        isError: true,
                        verifiedMes: `Sorry, Link you're trying to access has already been used`
                    }
                }
                else {
                    return {

                        isVerified: true,
                        isError: true,
                        verifiedMes: props && props.failedReg
                    }
                }
                // }




            }

        }
    }
    componentDidUpdate(props, state) {

        if ((props && props.failedVerify)) {
            props.resetVerifyUserFailed()
            this.gotoTop()
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
            this.gotoTop()

        }

        if (this.props &&
            this.props.userFollow &&
            this.props.userFollow.statusCode === 200 &&
            (this.props.userFollow !== (props.userFollow))) {
            this.props.resetFollow()
            this.setState({
                isFollower: true,
            })
            this.claimNFT()

        }

        if (((this.props &&
            this.props.wallet &&
            this.props.wallet.statusCode) == 200) &&
            (this.state.isForAirdropPage) &&
            (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            this.setState({
                isWallet: false,
                isForAirdropPage: false
            })
            this.createActSetup()
        }
        if ((props && props.failedFollow && props.failedFollow.length > 0)) {
            props.resetFailedFollow()
            if (props.failedFollow && (props.failedFollow.toLowerCase().includes('account has been disabled'))) {
                this.setState({
                    isVerified: false,
                    isError: true,
                    verifiedMes: "disable",
                    isFollower: true,

                })
            }else{
                this.setState({
                    isFollower: true,
                    verifiedMes: props && props.failedFollow,
                    isError: true
                })
            }
            
        }
        if (this.props && this.props.reguser && this.props.reguser.statusCode === 200 && (this.props.reguser !== props.reguser)) {
            if (this.props && this.props.reguser && this.props.reguser.data && this.props.reguser.data.authResult) {

                this.props.resetRegAirdrop()
                this.props.getUserProfile()
                
                this.setState({
                    tokenId : this.props.reguser.data.tokenId
                },() => {
                    this.followCheck()
                })
                this.setState({
                    
                    submitted: false,
                    isSuccess: true,
                    isAlert: false,
                    message: '',
                    style: '',
                    code: '',
                    isError: false,
                    isVerified: true
                })

            }


        }

    }
    redirectToAirDrops = () => {
        localStorage.setItem('page', "0")
        history.push('/wallet',{GoToTab : 2})
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }


    gotoLogin = () => {
        history.push('/login')
    }
    gotoAirdrop = () => {
        history.push('/airdrops')
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false,

        })

    }
    handleErrorModal = () => {
        this.setState({
            isVerified: true,
            isError: true,
            verifiedMes: <p>Oops! You closed the step without completing it.</p>
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.setState({
                isSwitchAcc: false,
                isError: false
            })
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForAirdropPage: true, path: '' })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isVerified: true,
            isError: true,
            verifiedMes: <p>Oops! You closed the step without completing it.</p>

        })

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    claimNFT = () => {
        const items = this.state.currentUserDet
        this.hideLoader()
        this.setState({ isAirDrop: true,isAirdropClaimMessage: 'Claiming your NFT...'})

        if (
            Number.isInteger(this.state.tokenId) &&
            Number.isInteger(this.state.listedUserId)
        ) {
            airDropService.claimWalletlessAirDrop(this.state.tokenId, this.state.listedUserId).then(res => {
                this.hideLoader()
                this.setState({ isAirDrop: false })

                res && res.text().then(text => {
                    const data = text && JSON.parse(text);
                    if (res.ok) {
                        if(data.statusCode === 200 && data && data.data && data.data.art.id){
                            this.setState({ isSuccessClaim: true, isVerified: true, isError: false })
                            collectionTimeout = setTimeout(() => {
                                history.push(`/art/${data.data.art.id}`,{isSplash : true})
                            },3000)
                        }else{
                            this.setState({ isSuccessClaim: true, isVerified: true, isError: false })
                            collectionTimeout = setTimeout(() => {
                                history.push('/wallet')
                            },3000)
                        }
                        
                    } else {
                        const validationErorrs = [];
                        this.setState({
                            isVerified: true,
                            isError: true,
                            verifiedMes: this.closedMessage
                        })

                        if (data &&
                            data.data &&
                            data.data.errorCode &&
                            (
                                data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                                data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                                data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                            ) &&
                            data.data.data[0] &&
                            Array.isArray(data.data.data[0])) {

                            for (const detail of data.data.data) {
                                if (Array.isArray(detail)) {
                                    for (const message of detail) {
                                        if (typeof message === 'string') {
                                            validationErorrs.push(message);

                                        }
                                    }
                                }
                            }
                            this.setState({
                                isAlert: true,
                                message: validationErorrs[0],
                                style: "danger",
                                isAirDrop: false
                            })


                        }
                        else {
                            const error = (data && data.message)
                            this.setState({
                                isAlert: true,
                                message: error, style: "danger",
                                isAirDrop: false
                            })
                        }
                    }
                })


            }).catch(err => {
                console.log(err)
                this.setState({
                    isVerified: true,
                    isError: true,
                    verifiedMes: this.closedMessage
                })
                try {
                    if (err instanceof TypeError) {
                        this.setState({ isAlert: true, message: "TypeError", style: "danger", isAirDrop: false })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        PubSub.publish('msg', false)
                        return Promise.reject(err)
                    } else {
                        if (err && typeof err === "string" && err.toLowerCase().includes('air drop already claimed')) {
                            this.setState({ isAlert: true, message: 'You have already claimed this air drop', style: "danger", isAirDrop: false, verifiedMes: err })
                        } else if (err && typeof err === "string" && err.toLowerCase().includes('token is not available')) {
                            this.setState({ isAlert: true, message: 'You are too late', style: "danger", isAirDrop: false, verifiedMes: `Unfortunately, the airdrop you mentioned has already been claimed. They can be claimed on a first-come, first-claimed basis. Don't worry, though! There might be other exciting airdrops are here or coming up`  })
                        } else if (err && typeof err === "string" && err.toLowerCase().includes("has been disabled by the administrator")) {
                            this.setState({
                              isAlert: false,
                              isArtDisabled: true,
                              disabledMessage: err,
                              isAirDrop: false
                            });
                        } else if(err && typeof err === "string"){
                            this.setState({
                                isAlert: false,
                                isAirDrop: false,
                                verifiedMes: err
                              });
                        }
                        else {
                            this.setState({ isAlert: true, message: this.closedMessage, style: "danger", isAirDrop: false })
                        }
                        PubSub.publish('msg', false)
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()

                    }
                }
                catch (err) {
                    this.setState({
                        isVerified: true,
                        isError: true,
                    })
                    if(err && typeof err === 'string'){
                        this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false, verifiedMes: err })
                    }else{
                        this.setState({ isAlert: true, message: err, style: "danger", isAirDrop: false })

                    }
                    this.hideLoader()
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            })

        } else {
            this.hideLoader()
            this.setState({
                isVerified: true,
                isError: true,
                verifiedMes: this.closedMessage
            })
            this.setState({
                isAlert: true,
                message: 'You are too late',
                style: "danger", 
                isAirDrop: false, 
                verifiedMes: `Unfortunately, the airdrop you mentioned has already been claimed. They can be claimed on a first-come, first-claimed basis. Don't worry, though! There might be other exciting airdrops are here or coming up`
            })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    createActSetup = (items) => {
        this.showLoader()
        getUserAddress().then(res => {
            this.hideLoader()
            if (res != null) {

                const walletAddress = res
                if (walletAddress) {
                    this.showLoader()
                    RetrieveTokens(walletAddress).then(resp => {
                        this.hideLoader()

                        if ((resp && resp.status === 200)) {

                            if ((resp && resp.data && resp.data.Value) == 0) {

                                this.setState({ isActSetup: true, isErrorPoped: false })

                                createSetUp().then(res => {
                                    if (res && res.status == 200) {
                                        this.setState({ isActSetup: false })
                                        if (res.data.transaction && res.data.transaction.errorMessage) {
                                            this.setState({
                                                isFollower: true,
                                                clickedUser: '',
                                                userId: '',
                                            })
                                            this.setState({
                                                isVerified: true,
                                                isError: true,
                                                verifiedMes: this.closedMessage
                                            })
                                            this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                                        } else {
                                            this.setState({ isAlert: true, message: 'Your Flow account has been successfully added. Now you may proceed to claim NFT', style: "success" })
                                            this.followCheck()

                                        }
                                    }
                                    else {
                                        this.setState({
                                            isVerified: true,
                                            isError: true,
                                            verifiedMes: this.closedMessage
                                        })
                                        if (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]) {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]), style: "danger" })

                                        } else if (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[1]) {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[1]), style: "danger" })

                                        } else {
                                            this.setState({ isActSetup: false })
                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error), style: "danger" })

                                        }
                                    }

                                }).catch(err => {
                                    this.hideLoader()
                                    this.setState({ isActSetup: false })
                                    this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                    this.setState({
                                        isVerified: true,
                                        isError: true,
                                        verifiedMes: this.closedMessage
                                    })
                                })
                                

                            } else {
                                if (resp && resp.status == 200) {
                                    this.followCheck()

                                }
                            }
                        } else {
                            this.setState({
                                isVerified: true,
                                isError: true,
                                verifiedMes: this.closedMessage
                            })
                            if ((resp && resp.status === 400)) {
                                if ((resp && resp.data && resp.data.message)) {
                                    this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })

                                } else {
                                    this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })

                                }
                            } else {
                                this.setState({ isAlert: true, message: "Unexpected error has occurred. Please try again later", style: "danger" })

                            }
                        }
                    }).catch(err => {
                    })
                } else {
                    this.setState({
                        isVerified: true,
                        isError: true,
                        verifiedMes: this.closedMessage
                    })
                    this.setState({ isAlert: true, message: 'Please connect your wallet properly', style: "danger" })

                }
            }
        }).catch(err => {
            this.hideLoader()
            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.setState({
                isVerified: true,
                isError: true,
                verifiedMes: this.closedMessage
            })

        })
    }
    followCheck = () => {

        var filterUser = []
        let user = localStorage.getItem("webLogin")
        let userDetail = JSON.parse(user)
        let lgnUser = userDetail.data && userDetail.data.authUser && userDetail.data.authUser.id
        this.showLoader()
        followServices.getFollowers(this.state.listedUserId).then(res => {
            if ((res && res.statusCode) == 200) {
                filterUser = res.data && res.data.follower && res.data.follower.length > 0 && res.data.follower.filter((flw) => (flw.id) == lgnUser)
                if (filterUser && filterUser.length > 0) {
                    this.setState({ isFollower: true, filterUser: [] })
                    this.claimNFT()
                } else {
                    this.setState({ isFollower: false, userId: this.state.listedUserId }, () => {
                        this.handleFollow()
                    })

                }
            }

        }).catch(err => {
            this.hideLoader()
        })
    }
    closeModal = () => {
        this.setState({
            isFollower: true
        })
    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)

    }
    resetUser = () => {
        this.setState({ isFollower: true, clickedUser: '' })

    }
    componentWillUnmount(){
        clearTimeout(collectionTimeout)
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        const { currentUserDet } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>Welcome Guest</title>
                    <meta name="title" content="Welcome Guest"></meta>
                    <meta name="description" content={`Create a new account on ${process.env.REACT_APP_MARKETPLACE}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            <img src={`../../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

                        </div>
                    </div>
                }
                {this.state.isAirDrop ?
                    <div className="show-content">
                        <div className="child-show purchasebox">

                            <p className="text-white">{this.state.isAirdropClaimMessage}</p>

                            <img src={`../../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div> : ''
                }
                {(!(this.state.isVerified) && (this.state.isError)) ?
                    (this.state.verifiedMes && this.state.verifiedMes.includes('disable')) ?
                        <CommonDisableError /> :
                        <div className="container detailWallet">
                            <div className="card py-5 my-5">
                                <div className="card-body">
                                    <div className="text-center">

                                        <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                            <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                        </svg>
                                        <h3 className="mt-3">Something went wrong, Please try again later </h3>
                                    </div>
                                </div>
                            </div>
                        </div> :
                    ''}
                {!(this.state.isVerified) && !(this.state.isError) && <div className="container detailWallet">
                    <div className="card py-5 my-5">
                        <div className="card-body">
                            <div className="text-center">
                                <h3 className="mt-3">Please wait</h3>

                                <div className="dotsloading">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {(this.state.isVerified) && !(this.state.isError) && (this.state.isSuccessClaim) && <div className="container detailWallet">
                    <div className="card py-5 my-5">
                        <div className="card-body">
                            <div className="text-center">
                                <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>

                                <div className="mt-3"><b>Woohoo..! Successfully Claimed! Good luck!</b></div>
                                <div className="mt-3">It may take 30 seconds for your claimed art to appear in collections :)</div>
                                <div className='mt-3'>Please wait, Redirecting to your NFT...</div>
                            </div>
                        </div>
                    </div>
                </div>}
                {(this.state.isVerified && this.state.isError) ? 
                (this.state.isArtDisabled) ? <CommonError disabledMessage={this.state.disabledMessage}/> : 
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                    </svg>
                                    <h3 className="mt-3">{this.state.verifiedMes} </h3>
                                    <div className="form-group text-center mt-4">
                                        {isWebLogin() ?  <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.redirectToAirDrops() }}>Go to Air Drops</button> :
                                            <button type="button" className="btn btn-primary px-5" onClick={this.gotoLogin}>Go to Login</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

               
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}
                        errorModal={this.handleErrorModal} />
                    : ''}
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reguser: state.airdrop.saveAirdropGuestUser,
        failedReg: state.airdrop.saveAirdropGuestUserFailed,
        userFollow: state.follow.userFollow,
        wallet: state.wallet.wallet,
        failedFollow: state.follow.failedFollow,
    }
}

const actionCreators = {
    registerAirdropUser: airdropActions.autoLoginAirdropGuest,
    resetRegAirdrop: airdropActions.resetLoginAirdropGuestUser,
    resetregUserFailed: airdropActions.resetLoginAirdropGuestUserFailed,
    getUserProfile: userAction.getUsers,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetFailedFollow: followActions.resetFailedFollow,



}

const connectAirdropGuestPage = connect(mapStateToProps, actionCreators)(AirdropGuestPage)

export { connectAirdropGuestPage as AirdropGuestPage }