import React from "react";
import { ArtContent } from "./art-content";
import { configPath } from "../config";
import * as fcl from '@onflow/fcl'
export class Drops extends React.Component {

    async mintClickHandler(e) {


        // const authorization = await fcl.currentUser().authorization
        const authorization = await fcl.authorizations([fcl.authz])

        // console.log(authorization)

        // console.log("why params not update");

        const endpoint = `${configPath.apiUrl}/ping/mint`;
        const reqBody = {
            imagestring: 'Qmc7trkLZssYAs91FEJJ44ECZJmEM79CeFrKKkYNrPkSvt',
            title: 'Covers',
            userauth: authorization
        }
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
            body: JSON.stringify(reqBody)
        })
            .then((response) => {
                if (response.ok) {
                    // console.log(`Response for ${endpoint} is ${JSON.stringify(response, null, '/t')}`);
                } else {
                    // console.log(`Error received for ${endpoint} is ${JSON.stringify(response, null, '/t')}`);
                }
            })
            .catch((error) => {
                // console.log(`Request for ${endpoint} failed because of an error ${JSON.stringify(error, null, '/t')}`);
            })
    }
    render() {
        const artContent = [];
        for (let i = 0; i < 24; i++) {
            artContent.push(<ArtContent />)
        }
        return (
            <div className="art artcontent">
                <div className="container">
                  
                    <ArtContent urlProp={this.props}/>
                </div>
            </div>
        );
    }
}
