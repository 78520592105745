import { configPath } from 'config'
import React from 'react'
import { Component } from 'react'

export class MaintenancePopup extends Component{
    render (){
    return (
        <div>

                    <div className="show-success" id="parent">
                        <div className="success-pop">
                        <img src={`../${configPath.imageAssets}/maintenance.png`} className="mw-100 my-5" alt="" width="300px"/>
                            <div className="mt-3">Sorry for the inconvenience but we’re performing some maintenance at the moment.</div>
                            <div className="mt-3 mb-4"> we’ll be back online shortly!</div>
                            <button onClick={this.props.resetMaintenance} className="btn btn-primary px-2 clsbtn">Close</button>


                        </div></div>
        </div>
    )
    }
}