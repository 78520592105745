import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAction } from 'store/actions';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from 'config';
import GoogleLogin from 'react-google-login';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { SolarSystemLoading } from 'react-loadingg';
import { Register } from './register';
import PubSub from 'pubsub-js';

var timeout;
var loadTimeout;
class WebLoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loginData: [],
            reCaptchaToken: '',
            isSuccess: false,
            isAlert: false,
            style: '',
            loader: true
        }
    }

    static getDerivedStateFromProps(props, state) {
        let path = state.artId + state.hashId

        if ((props && props.webLogRes && props.webLogRes.statusCode) === 200) {
            props.resetWebLogin()
            state && state.artId ? history.push(path, { isFromLogin: true }) : state && state.creatorUrl ? history.push(state.creatorUrl, { isFromLogin: true }) : history.push('/')
            
        }
        if ((props && props.failedRes && state.submitted)) {
            if (props.failedRes.message) {
                props.updateLogError(props && props.failedRes && props.failedRes.message && ((props.failedRes.message).charAt(0).toUpperCase()) + (props.failedRes.message).slice(1).toLowerCase())
               
            } else {
                if (
                    (props &&
                        props.failedRes &&
                        props.failedRes.includes("userNameOrEmail must be at least 3 characters long. And userNameOrEmail must not contain leading and trailing spaces."))) {
                        props.updateLogError('Username/email or password you entered is incorrect')
                    
                    
                }else{
                    props.updateLogError(props && props.failedRes && ((props.failedRes).charAt(0).toUpperCase()) + (props.failedRes).slice(1).toLowerCase())
                }
            }
        }


    }
    componentDidUpdate(props, state) {
        if ((props && props.failedRes)) {
            if ((props.failedRes && props.failedRes.message && props.failedRes.message.toLowerCase()) === "email has not been verified") {

                if (props && props.failedRes && props.failedRes.id) {
                    history.push(`/registration/user/${props.failedRes.id}/verify`)
                }
            } else if (props.failedRes && props.failedRes.length > 0) {
                this.updateErrorAlert(props.failedRes)
            }
            props.resetLoginFailure()
            this.resetTimeout()
            this.gotoTop()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

    }
    componentDidMount() {
        this.setLoader()
        localArtPage()
        localDropPage()
        localAirdropPage()
        if (!isWebLogin()) {
            this.setState({
                artId: (this.props && this.props.location && this.props.location.state && this.props.location.state.artId),
                hashId: (this.props && this.props.location && this.props.location.state && this.props.location.state.hashId),
                creatorUrl: (this.props && this.props.location && this.props.location.state && this.props.location.state.creatorUrl && this.props.location.state.creatorUrl)
            })
            this.gotoTop()
        } else {
            history.push('/')
        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value).toLowerCase()
        })
    }
    handlePasswordChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value)
        })
    }
    captchaChange = (value) => {
        this.setState({ reCaptchaToken: value })
    }
    handleRegister = () => {
        this.props.enableRegister(true)
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    checkPassword = (value) => {
        if ((value && value.length) >= 8) {
            return true
        } else {
            return false
        }
    }
    handleSubmit = async (e) => {
        e.preventDefault()

        this.setState({ submitted: true, reCaptchaToken: '' })
        const { username, password, reCaptchaToken } = this.state;
        if (username) {
            if (password) {
                if (this.checkPassword(password)) {
                    this.showLoader()
                    const token = await this.captcha.executeAsync();
                    this.captcha.reset()
                    if (token) {
                        let obj = {
                            userNameOrEmail: username,
                            password,
                            reCaptchaToken: token
                        }
                        this.props.login(JSON.stringify(obj))
                    } else {
                        this.hideLoader()
                        this.updateErrorAlert('Recaptcha execution failed')
                        
                    }
                } else {
                    this.updateErrorAlert('Password must be atleast 8 characters')
                }
            } else {
                this.updateErrorAlert('Password required')
            }
        } else {
            this.updateErrorAlert('Email or Username required')
        }
    }
    handleLogin = async (googleData) => {
        if (googleData.tokenId) {
            let obj = { tokenId: googleData.tokenId }
            this.props.googleLogin(JSON.stringify(obj))
        } else {
            // console.error('Google sign-in error',googleData);
        }
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    setLoader = () => {
        loadTimeout = setTimeout(() => {

            this.setState({ loader: false })
        }, 4000)
    }
    componentWillUnmount() {
        clearTimeout(loadTimeout)
    }
    updateErrorAlert = (message) => {
        console.log(message)
        // if(history && history.location && history.location.pathname.includes('login')){
        //     this.setState({ isAlert: true, message: message, style: "danger" })
        //     this.gotoTop()
        // }else{
            this.props.updateLogError(message)
        // }
          
    }
    updateSuccessAlert = (message) => {
        this.props.updateLogSuccess(message)
    }
    showLoader = () => {
        PubSub.publish('msg', true);
    }
    
    hideLoader = () => {
        PubSub.publish('msg', false);
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        return (
            <Fragment> 
                        

                               
                                <h1 className="text-center mt-5 mb-3">Already a Disruptor? <div className='usernamelilac  mt-2'>Login</div></h1>

                                <div className="purchasebox1 p-3 reglogfrm">
                                    <form onSubmit={this.handleSubmit}>
                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            render={renderProps => (
                                                <div className="form-group text-center">
                                                    <a onClick={renderProps.onClick} className="btn btn-primary-fill btn-icon w-100 ">
                                                        <img src={`${configPath.imageAssets}/google.png`} alt="" className="mw-100" />Login with Google
                                                    </a>
                                                </div>
                                            )}
                                            onSuccess={this.handleLogin}
                                            onFailure={this.handleLogin}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                        <div className="form-group text-center">
                                            <div className="vertical-separator">
                                                <hr className="vertical-separator_hr" />
                                                <div className="vertical-separator_or">
                                                    <p className="">or</p>
                                                </div>
                                                <hr className="vertical-separator_hr" />
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="username">Email <span className='login-label-text '>or</span> Username</label>
                                            <input className="form-control" value={this.state.username} onChange={this.handleChange} name="username" id="username" placeholder="Enter email or username here" />
                                            {this.state.submitted && !this.state.username && <div className="mandatory">Username or Email required</div>}
                                            {/* {this.state.submitted && this.state.username && !this.checkemail(this.state.username) && <div className="mandatory">Invalid Email</div>}                                         */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <input className="form-control" value={this.state.password} onChange={this.handlePasswordChange} id="password" name="password" type="password" placeholder="Enter password here" />
                                            {this.state.submitted && !this.state.password && <div className="mandatory">Password required</div>}
                                        </div>
                                        <div className="form-group d-flex justify-content-center">
                                            <ReCAPTCHA sitekey={configPath.siteKey} ref={e => (this.captcha = e)} onChange={this.captchaChange} theme={'dark'} size="invisible"/>
                                        </div>
                                        <div className="form-group text-center">
                                            <button type="submit" className="btn btn-primary-fill px-5">Login</button>
                                        </div>
                                        <div className="form-group text-center">
                                            {/* <a onClick={this.handleRegister}>If you are new user, go to register</a> */}
                                            <i className="fas fa-lock"></i>&nbsp; <Link to="/forgotPassword">Forgot your password? Reset here</Link>
                                        </div>
                                        {/* <div className="form-group text-center">
                                    <Link to="/signup">Sign Up</Link>
                                </div> */}
                                    </form>
                                </div>
                                {/* </div> */}

                       
                {this.state.loader ?
                    <div className="loader"><SolarSystemLoading /></div> :
                    <div className="loader-hide"><SolarSystemLoading /></div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        webLogRes: state.user.webLogRes,
        failedRes: state.user.failedRes
    }
}

const actionCreators = {
    login: userAction.webLogin,
    googleLogin: userAction.googleLogin,
    resetWebLogin: userAction.resetWebLogin,
    resetLoginFailure: userAction.resetLoginFailure,
    getUserProfile: userAction.getUsers,

}

const connectWebLoginPage = connect(mapStateToProps, actionCreators)(WebLoginPage)

export { connectWebLoginPage as WebLoginPage }
