import { createSetUp, RetrieveTokens } from "utils"
import PubSub from 'pubsub-js';



function showLoader() {
    PubSub.publish('flow', true);
}

function hideLoader() {
    PubSub.publish('flow', false);
}
export async function accountSetup(walletAddress){
    try {
        if (walletAddress) {
            PubSub.publish('msg', true);
            const resp = await RetrieveTokens(walletAddress)
            PubSub.publish('msg', false);
                if((resp && resp.status === 200)){
                if ((resp && resp.data && resp.data.Value) == 0) {
                    showLoader()
                    const res = await createSetUp()
                    hideLoader()
                        if (res && res.status == 200) {
                            if (res.data.transaction && res.data.transaction.errorMessage) {
                                return Promise.reject("Transaction Failed")
                            } else {
                                return {
                                    status : 200,
                                    message : "Your Flow account has been successfully added"
                                }
                            }
                        }
                        else {
                            console.log(res)
                            if(res && res.data && res.data.Error && res.data.Error.toLowerCase().includes('declined')){
                                return Promise.reject('Transaction declined')
                            }else if(res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]){
                                return Promise.reject(res.data.Error.split(':')[2])
                            }else if(res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[1]){
                                return Promise.reject(res.data.Error.split(':')[1])
                            }else{
                                return Promise.reject(res.data.Error)
                            }
                        }
                }else{
                    return {
                        status : 201,
                        message : "already setup"
                    }
                }
            }else{
                if((resp && resp.status === 400)){
                    if((resp && resp.data && resp.data.message)){
                        return Promise.reject(`Unexpected error has occurred. Please try again later`)
                    }else{
                        return Promise.reject(`Unexpected error has occurred. Please try again later`)
                    }
                }else{
                    return Promise.reject(`Unexpected error has occurred. Please try again later`)

                }
            }
            
        } else {
            return Promise.reject("Please connect your wallet properly")
        }
    }catch(err){
        return Promise.reject("Failed to execute flow account setup")
    }
}

  