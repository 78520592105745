import React from "react";
import { Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  airdropActions,
  artActions,
  dropActions,
  userAction,
  walletActions,
} from "store/actions";
import {
  isWebLogin,
  ReactPagination,
  checkTokenGroup,
  checkMintedTokens,
  flowDecimal,
  getUSDValue,
  localArtPage,
  localDropPage,
  localAirdropPage,
  isConnectWallet,
  accountSetup,
} from "helpers";
import PubSub from "pubsub-js";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "helpers/history";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { configPath } from "../config";
import { ArtPreview } from "./art-preview/art-preview";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import {
  airDropService,
  artServices,
  userServices,
  walletServices,
} from "services";
import { connectBlocto, getFlowUsdValue } from "helpers/getFlowUsd";
import { getUserAddress } from "utils";
import { babMintCount } from "./artdetail-view/bab-common-view";
import { JoyRide } from "./Joy-ride";
import {
  checkMywalletPage,
  checkOwnListingAuctionPage,
} from "helpers/joy-rideCheck";
import Modal from "react-modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { SolarSystemLoading } from "react-loadingg";
import { AirDropStatus } from "helpers/enums";
import { SwitchAccount } from "./switchaccountPopup";
import IconComponent from "./IconComponent";
import { isDapperConnected } from "helpers/switchauthdapper";
import { scrollToTop } from "helpers/scrollToTop";

var timeout;
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
let controller = new AbortController();

class MyWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      collectionOffSet: 0,
      dropOffset: 0,
      dropCount: "",
      dataArray: [],
      marketArray: [],
      detail: "",
      size: 12,
      isMarket: false,
      pageCount: "",
      description: "",
      startTime: "",
      endTime: "",
      tabIndex: 0,
      sortBy: "createdAt",
      sortOrder: "DESC",
      isCheckUserArt: false,
      show: false,
      rate: "",
      isCheckDrop: false,
      iswallet: false,
      isAlert: false,
      message: "",
      custodialArray: [],
      isOpenMovePopupWarn: false,
      transferSetup: false,
      syncSetup: false,
      transferNft: {},
      currentWalletAddress: "",
      isCheckCustodial: false,
      custodialCount: 0,
      custodialOffset: 0,
      hasMore: true,
      loader: true,
      airdropOffset: 0,
      airdropArray: [],
      airdropCount: 0,
      isCheckAirDrop: false,
      collectionHasMore: true,
      collectionLoader: true,
      onSaleHasMore: true,
      onSaleLoader: true,
      isSwitchAcc: false,
      isSuccessTransfer: false,
      isNFTTransfer: false,
      transferMessage: '',
      isErrorPoped: false,
      errorMessage: ''
    };
    this.scrollRef = React.createRef()
    
  }
  showLoader() {
    PubSub.publish("msg", true);
  }

  hideLoader() {
    PubSub.publish("msg", false);
  }
  componentDidMount() {
    const { GoToTab } = this.props.history.location.state || {}


    // Call a function or perform any other actions after loading
    if (GoToTab === 2) {
      this.gotoAirDrop()
      this.setState({ tabIndex: GoToTab }) // airdrop tab
    } else if (GoToTab === 3) {
      this.gotoCustodial()
      this.setState({ tabIndex: GoToTab }) // airdrop tab
    } else {
      this.getUserArts();
    }
    

    if(GoToTab){
      let state = this.props.history.location.state;
    let location = this.props.history.location
    delete state.GoToTab;
    history.replace({ ...location, state });
    }

    this.scrollRef.current && this.scrollRef.current.scrollIntoView()

    scrollToTop()

    localArtPage();
    localDropPage();
    localAirdropPage();
    localStorage.setItem("page", JSON.stringify(0));
    this.setCount();

    window.addEventListener("scroll", () => {
      let stickyClass = "top";
      if (window.scrollY <= 200) {
        stickyClass = "normal";
      }
      this.setState({ stickyClass });
    });
  }
  getUSD = async () => {
    const value = await getFlowUsdValue();
    this.setState({ rate: value });
  };

  static getDerivedStateFromProps(props, state) {
    //release Infinite coll
    // if (props && props.userArtsList && props.userArtsList.statusCode === 200) {
    //   props.resetAllArts();
    //   const page = Number(state.size) + Number(state.collectionOffSet);
    //   console.log(state.dataArray)
    //   if (
    //     props.userArtsList.data.arts.length === 0 ||
    //     props.userArtsList.data.arts.length < state.size
    //   ) {
    //     const data = state.dataArray.concat(
    //       props.userArtsList &&
    //         props.userArtsList.data &&
    //         props.userArtsList.data.arts
    //     );

    //     return {
    //       collectionHasMore: false,
    //       collectionLoader: false,
    //       dataArray: data,
    //       collectionOffSet: page,
    //       isCheckUserArt: props.isCheckUserArt,
    //     };
    //   }
    //   const data = state.dataArray.concat(
    //     props.userArtsList &&
    //       props.userArtsList.data &&
    //       props.userArtsList.data.arts
    //   );
    //   return {
    //     dataArray: data,
    //     collectionOffSet: page,
    //     isCheckUserArt: props.isCheckUserArt,
    //   };
    // }
    // // release infinite onsale
    // if (props && props.userDropList && props.userDropList.statusCode === 200) {
    //   props.resetAllDrops();
    //   const page = Number(state.size) + Number(state.dropOffset);
    //   if (
    //     props.userDropList.data.arts.length === 0 ||
    //     props.userDropList.data.arts.length < state.size
    //   ) {
    //     const data = state.marketArray.concat(
    //       props.userDropList &&
    //         props.userDropList.data &&
    //         props.userDropList.data.arts
    //     );

    //     return {
    //       onSaleHasMore: false,
    //       onSaleLoader: false,
    //       marketArray: data,
    //       dropOffset: page,
    //       isCheckDrop: props.isCheckArtDrop,
    //     };
    //   }
    //   const data = state.marketArray.concat(
    //     props.userDropList &&
    //       props.userDropList.data &&
    //       props.userDropList.data.arts
    //   );
    //   return {
    //     marketArray: data,
    //     dropOffset: page,
    //     isCheckDrop: props.isCheckArtDrop,
    //   };
    // }

    // //infinite scroll airdrop
    // if (
    //   props &&
    //   props.allairdropList &&
    //   props.allairdropList.statusCode === 200
    // ) {
    //   props.resetAirDropArt();

    //   const page = Number(state.size) + Number(state.airdropOffset);
    //   if (
    //     props.allairdropList.data.airDrops.length === 0 ||
    //     props.allairdropList.data.airDrops.length < state.size
    //   ) {
    //     const data = state.airdropArray.concat(
    //       props.allairdropList &&
    //         props.allairdropList.data &&
    //         props.allairdropList.data.airDrops
    //     );

    //     return {
    //       airDropHasMore: false,
    //       airDropLoader: false,
    //       airdropArray: data,
    //       airdropOffset: page,
    //       isCheckAirDrop: props.isCheckAirDropList,
    //     };
    //   }
    //   const data = state.airdropArray.concat(
    //     props.allairdropList &&
    //       props.allairdropList.data &&
    //       props.allairdropList.data.airDrops
    //   );
    //   return {
    //     airdropArray: data,
    //     airdropOffset: page,
    //     isCheckAirDrop: props.isCheckAirDropList,
    //   };
    // }

    // if (
    //   props &&
    //   props.allcustodialArts &&
    //   props.allcustodialArts.statusCode === 200
    // ) {
    //   props.resetGetcustodialArts();
    //   const page = Number(state.size) + Number(state.custodialOffset);
    //   console.log(state.custodialArray)
    //   if (
    //     props.allcustodialArts.data.arts.length === 0 ||
    //     props.allcustodialArts.data.arts.length < state.size
    //   ) {
    //     const data = state.custodialArray.concat(
    //       props.allcustodialArts &&
    //         props.allcustodialArts.data &&
    //         props.allcustodialArts.data.arts
    //     );

    //     return {
    //       hasMore: false,
    //       loader: false,
    //       custodialArray: data,
    //       custodialOffset: page,
    //       isCheckCustodial: props.isCheckCustodialArt,
    //     };
    //   }
    //   const data = state.custodialArray.concat(
    //     props.allcustodialArts &&
    //       props.allcustodialArts.data &&
    //       props.allcustodialArts.data.arts
    //   );
    //   console.log(data);
    //   return {
    //     custodialArray: data,
    //     custodialOffset: page,
    //     isCheckCustodial: props.isCheckCustodialArt,
    //   };
    // }

    return null;
  }
  componentDidUpdate(props, state) {
    let nft = configPath.basename;
    if (window.location.pathname.includes(nft)) {
      if (
        (props && props.location && props.location.search) !==
        window.location.search
      ) {
        const { tabIndex } = this.state;
        if (tabIndex === 0) {
          this.getUserArts();
        } else {
          this.getDropArts();
        }
      }
    } else if (
      (props && props.location && props.location.search) !==
      window.location.search
    ) {
      const { tabIndex } = this.state;
      if (tabIndex === 0) {
        this.getUserArts();
      } else {
        this.getDropArts();
      }
    }

    if (
      (this.props && this.props.wallet && this.props.wallet.statusCode) ===
      200 &&
      (props && props.wallet && props.wallet.statusCode) !==
      (this.props.wallet && this.props.wallet.statusCode) &&
      state.iswallet
    ) {
      props.resetlinkWallet();
      this.setState({
        iswallet: false,
      });
      if (this.state.syncSetup) {
        this.readyToSync();
      } else {
        this.createaccountSetup();
      }
    }
    if (props && props.walletlinkError && props.walletlinkError.length > 0) {
      props.resetwalletlinkerror();
      this.setState({
        isAlert: true,
        message: props && props.walletlinkError,
        style: "danger",
      });
      this.gotoTop();
      this.resetTimeout();
      this.closeAlert();
    }

    //release Infinite coll
    if (this.props && this.props.userArtsList && this.props.userArtsList.statusCode === 200 && (this.props.userArtsList !== (props.userArtsList))) {
      this.props.resetAllArts();
      const page = Number(this.state.size) + Number(this.state.collectionOffSet);
      console.log(this.state.dataArray)
      if (
        this.props.userArtsList.data.arts.length === 0 ||
        this.props.userArtsList.data.arts.length < state.size
      ) {
        const data = this.state.dataArray.concat(
          this.props.userArtsList &&
          this.props.userArtsList.data &&
          this.props.userArtsList.data.arts
        );
        this.setState({
          collectionHasMore: false,
          collectionLoader: false,
          dataArray: data,
          collectionOffSet: page,
          isCheckUserArt: props.isCheckUserArt,
        })

      }
      const data = this.state.dataArray.concat(
        this.props.userArtsList &&
        this.props.userArtsList.data &&
        this.props.userArtsList.data.arts
      );
      this.setState({
        dataArray: data,
        collectionOffSet: page,
        isCheckUserArt: props.isCheckUserArt,
      })


    }
    // release infinite onsale
    if (this.props && this.props.userDropList && this.props.userDropList.statusCode === 200 && (this.props.userDropList !== (props.userDropList))) {
      this.props.resetAllDrops();
      const page = Number(this.state.size) + Number(this.state.dropOffset);
      if (
        this.props.userDropList.data.arts.length === 0 ||
        this.props.userDropList.data.arts.length < state.size
      ) {
        const data = this.state.marketArray.concat(
          this.props.userDropList &&
          this.props.userDropList.data &&
          this.props.userDropList.data.arts
        );

        this.setState({
          onSaleHasMore: false,
          onSaleLoader: false,
          marketArray: data,
          dropOffset: page,
          isCheckDrop: props.isCheckArtDrop,
        });
      }
      const data = this.state.marketArray.concat(
        this.props.userDropList &&
        this.props.userDropList.data &&
        this.props.userDropList.data.arts
      );
      this.setState({
        marketArray: data,
        dropOffset: page,
        isCheckDrop: props.isCheckArtDrop,
      })
    }

    //infinite scroll airdrop
    if (
      this.props &&
      this.props.allairdropList &&
      this.props.allairdropList.statusCode === 200 &&
      (this.props.allairdropList !== (props.allairdropList))
    ) {
      this.props.resetAirDropArt();

      const page = Number(this.state.size) + Number(this.state.airdropOffset);
      if (
        this.props.allairdropList.data.airDrops.length === 0 ||
        this.props.allairdropList.data.airDrops.length < state.size
      ) {
        const data = this.state.airdropArray.concat(
          this.props.allairdropList &&
          this.props.allairdropList.data &&
          this.props.allairdropList.data.airDrops
        );

        this.setState({
          airDropHasMore: false,
          airDropLoader: false,
          airdropArray: data,
          airdropOffset: page,
          isCheckAirDrop: props.isCheckAirDropList,
        })
      }
      const data = this.state.airdropArray.concat(
        this.props.allairdropList &&
        this.props.allairdropList.data &&
        this.props.allairdropList.data.airDrops
      );
      this.setState({
        airdropArray: data,
        airdropOffset: page,
        isCheckAirDrop: props.isCheckAirDropList,
      })
    }

    if (
      this.props &&
      this.props.allcustodialArts &&
      this.props.allcustodialArts.statusCode === 200 &&
      (this.props.allcustodialArts !== (props.allcustodialArts))
    ) {
      this.props.resetGetcustodialArts();
      const page = Number(this.state.size) + Number(this.state.custodialOffset);
      console.log(state.custodialArray, this.state.custodialArray, this.state.custodialOffset)
      if (
        this.props.allcustodialArts.data.arts.length === 0 ||
        this.props.allcustodialArts.data.arts.length < state.size
      ) {
        const data = this.state.custodialArray.concat(
          this.props.allcustodialArts &&
          this.props.allcustodialArts.data &&
          this.props.allcustodialArts.data.arts
        );

        this.setState({
          hasMore: false,
          loader: false,
          custodialArray: data,
          custodialOffset: page,
          isCheckCustodial: props.isCheckCustodialArt,
        })
      }
      const data = this.state.custodialArray.concat(
        this.props.allcustodialArts &&
        this.props.allcustodialArts.data &&
        this.props.allcustodialArts.data.arts
      );
      console.log(data);
      this.setState({
        custodialArray: data,
        custodialOffset: page,
        isCheckCustodial: props.isCheckCustodialArt,
      })
    }
  }


  ///this on sale
  gotoDrop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    controller.abort()
    controller = new AbortController();
    localStorage.setItem("page", "0");
    this.setState(
      {
        dropOffset: 0,
        onSaleLoader: true,
        onSaleHasMore: true,
        custodialArray: [], dataArray: [], marketArray: []
      },
      () => {
        this.getDropArts();
      }
    );
  };
  getDropArts = () => {
    controller = new AbortController();
    let signal = controller.signal;
    let obj = {
      offSet: this.state.dropOffset,
      recordLimit: this.state.size,
      sortBy: "updatedAt",
      sortOrder: this.state.sortOrder,
      showHiddenArts: true,
      signal
    };
    this.props.getUserDrop(obj);
  };
  //this is collection
  gotoArts = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
    controller.abort()
    controller = new AbortController();
    localStorage.setItem("page", "0");
    this.setState(
      {
        collectionOffSet: 0,
        collectionLoader: true,
        collectionHasMore: true,
        custodialArray: [], dataArray: [], marketArray: []
      },
      () => {
        this.getUserArts();
      }
    );
  };
  getUserArts = () => {
    controller = new AbortController();
    let signal = controller.signal;
    let obj = {
      offSet: this.state.collectionOffSet,
      recordLimit: this.state.size,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      showHiddenArts: true,
      signal
    };

    this.props.getUserArts(obj);
  };

  //this is airdrop
  gotoAirDrop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    controller.abort()
    controller = new AbortController();
    localStorage.setItem("page", "0");
    this.setState(
      {
        airdropOffset: 0,
        airDropLoader: true,
        airDropHasMore: true,
        airdropArray: [],
        custodialArray: [], dataArray: [], marketArray: []
      },
      () => {
        this.getAirdropArts();
      }
    );
  };
  getAirdropArts = () => {
    controller = new AbortController();
    let signal = controller.signal;
    let obj = {
      offSet: this.state.airdropOffset,
      recordLimit: this.state.size,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      showHiddenArts: true,
      signal,
    };
    this.props.getAirdropArts(obj);
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onTabChange = (tab) => {
    controller.abort()
    controller = new AbortController();
    this.setState({
      tabIndex: tab,
    });
  };

  checkLastDate = (items) => {
    var futureDate =
      items &&
      items.auction &&
      items.auction.endDay &&
      moment(items.auction.endDay).format("MMM DD, YYYY HH:mm:ss");
    var countDownDate = new Date(futureDate).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    if (distance < 0) {
      return true;
    } else {
      return false;
    }
  };
  startTime = (items) => {
    var futureDate =
      items &&
      items.auction &&
      items.auction.endDay &&
      moment(items.auction.endDay).format("MMM DD, YYYY HH:mm:ss");
    var countDownDate = new Date(futureDate).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if (distance < 0) {
      return <p className="usernamelilac mb-0 mt-1">Bidding Closed</p>;
    }
    return (
      <p className="mb-0 small  mt-1">
        Ends by :<br />
        <span className="usernamelilac">{`${days ? days + "d" : ""
          }  ${hours}h  ${minutes}m  ${seconds}s`}</span>
      </p>
    );
  };
  setCount = (items) => {
    setInterval(() => {
      this.setState({ show: true });
    }, 1000);
  };
  checkdropsLength = (tokens, totalTokens, items) => {
    if (isNaN(items && items.tokenGroupId)) {
      if (items && items.tokenGroupId && items.tokenGroupId.includes("-")) {
        return 1;
      }
    }
    if (totalTokens && Number.isInteger(totalTokens)) {
      return tokens;
    } else {
      return 1;
    }
  };
  gotoDropDetail = (items) => {
    if (
      items &&
      items.permanentId &&
      items &&
      items.drops &&
      items.drops[0] &&
      items.drops[0].id
    ) {
      history.push(
        `/art/${items && items.permanentId}#drop${items.drops[0].id}`
      );
    } else if (
      items &&
      items.id &&
      items &&
      items.drops &&
      items.drops[0] &&
      items.drops[0].id
    ) {
      history.push(`/art/${items && items.id}#drop${items.drops[0].id}`);
    }
  };
  gotoDropsDet = (items) => {
    if (items && items.id && items.drops && items.drops[0] && items.drops[0].id)
      history.push(`/art/${items && items.id}#drop${items.drops[0].id}`, {
        data: { items },
      });
  };
  syncWalletAccount = async () => {
    if (isConnectWallet()) {
      this.readyToSync();
    } else {
      const wallet = await connectBlocto();
      if (wallet) {
        this.props.linkWallet(JSON.stringify(wallet));
        this.setState({ iswallet: true });
        this.setState({ syncSetup: true, transferSetup: false });

        // this.readyToSync()
      } else {
        this.setState({
          isAlert: true,
          message: "Something went wrong.Couldn't connect your wallet",
          style: "danger",
        });
        this.gotoTop();
        this.resetTimeout();
        this.closeAlert();
      }
    }
  };
  readyToSync = async () => {
    const address = await getUserAddress();
    if (address && address != null && address != undefined) {
      this.showLoader();
      walletServices
        .syncWallet(address)
        .then((res) => {
          this.hideLoader();
          if ((res && res.statusCode) === 200) {
            if (
              res &&
              res.message &&
              res.message.toLowerCase().includes("wallet synced")
            ) {
              this.gotoArts();
              this.setState({
                isAlert: true,
                message: res && res.message,
                style: "success",
              });
              this.gotoTop();
              this.resetTimeout();
              this.closeAlert();
            } else {
              this.setState({
                isAlert: true,
                message: res && res.message,
                style: "success",
              });
              this.gotoTop();
              this.resetTimeout();
              this.closeAlert();
            }
          }
          console.log(res);
        })
        .catch((e) => {
          this.hideLoader();
          this.setState({
            isAlert: true,
            message: e,
            style: "danger",
          });
          this.gotoTop();
          this.resetTimeout();
          this.closeAlert();
        });
    } else {
      this.setState({
        isAlert: true,
        message: "Something went wrong.Couldn't find your address",
        style: "danger",
      });
      this.gotoTop();
      this.resetTimeout();
      this.closeAlert();
    }
  };
  closeAlert = () => {
    timeout = setTimeout(() => {
      this.setState({ isAlert: false, message: "", style: "" });
    }, 15000);
  };
  resetTimeout = () => {
    clearTimeout(timeout);
  };
  hideAlert = () => {
    this.setState({ isAlert: false, message: "", style: "" });
  };
  gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  showTotalEditions = (items) => {
    if (
      items &&
      items.drops &&
      items.drops[0] &&
      items.drops[0].auction &&
      items.drops[0].auction.id &&
      items.drops[0].auction.id != null
    ) {
      return "";
    } else {
      if (items.isBab) {
        return (
          <p className="mb-0 small">
            1 of <span className="usernamelilac">{babMintCount}</span> Total
            Edition
          </p>
        );
      } else {
        if (items && items.groups && items.groups != null) {
          return (
            <p className="mb-0 small">
              <span className="usernamelilac">
                {checkTokenGroup(
                  items && items.groups,
                  items.toatalMintedTokens
                )}
              </span>{" "}
              of{" "}
              {items && items.toatalMintedTokens
                ? checkMintedTokens(items.groups, items.toatalMintedTokens)
                : 1}{" "}
              {this.checkEDitionCount(items)}
            </p>
          );
        } else {
          <p className="mb-0 small">
            1 of <span className="usernamelilac">1</span> Total Edition
          </p>;
        }
      }
    }
  };
  markedPrice = (items) => {
    if (
      items &&
      items.drops &&
      items.drops[0] &&
      items.drops[0].storefrontListingResourceId &&
      items.drops[0].withDapper
    ) {
      return (
        <h5
          className="usernamelilac"
          title={
            items &&
            items.drops &&
            items.drops[0] &&
            items.drops[0].markedPrice &&
            items.drops[0].markedPrice
          }
        >
          {items &&
            items.drops &&
            items.drops[0] &&
            items.drops[0].markedPrice &&
            flowDecimal(parseFloat(items.drops[0].markedPrice)) + " USD"}
          <span className="small text-white">/ 1 Edition</span>
        </h5>
      );
    } else {
      return (
        <h5
          className="usernamelilac"
          title={
            items &&
            items.drops &&
            items.drops[0] &&
            items.drops[0].markedPrice &&
            items.drops[0].markedPrice
          }
        >
          {items &&
            items.drops &&
            items.drops[0] &&
            items.drops[0].markedPrice &&
            flowDecimal(parseFloat(items.drops[0].markedPrice)) + " FLOW"}
          <span className="small text-white">/ 1 Edition</span>
        </h5>
      );
    }
  };
  usdValue = (items) => {
    if (
      items &&
      items.drops &&
      items.drops[0] &&
      items.drops[0].storefrontListingResourceId &&
      items.drops[0].withDapper
    ) {
      return "";
    } else {
      if (
        items &&
        items.drops &&
        items.drops[0] &&
        items.drops[0].markedPrice
      ) {
        return "";
      } else {
        return "";
      }
    }
  };
  showAuctionValue = (items) => {
    if (!this.checkLastDate(items)) {
      if (
        items &&
        items.auction &&
        items.auction.id &&
        items.auction.id != null
      ) {
        return (
          <p className="mb-0 small">
            <span
              className="usernamelilac"
              title={items && items.auction && items.auction.lastBid}
            >
              {items &&
                items.auction &&
                items.auction.id &&
                items.auction.id != null &&
                (items.auction.lastBid ? (
                  flowDecimal(parseFloat(items.auction.lastBid)) + " FLOW"
                ) : (
                  <span className="usernamelilac ">
                    {items &&
                      items.auction &&
                      items.auction.id &&
                      items.auction.id != null &&
                      items.auction.startPrice &&
                      `Starting Price : ${flowDecimal(parseFloat(items.auction.startPrice)) +
                      " FLOW"
                      }`}
                  </span>
                ))}
            </span>
          </p>
        );
      }
    } else {
      if (
        items &&
        items.auction &&
        items.auction.id &&
        items.auction.id != null
      ) {
        return (
          <p className="mb-0 small">
            <span
              className="usernamelilac"
              title={items && items.auction && items.auction.lastBid}
            >
              {items &&
                items.auction &&
                items.auction.id &&
                items.auction.id != null &&
                (items.auction.lastBid
                  ? flowDecimal(parseFloat(items.auction.lastBid)) + " FLOW"
                  : "")}
            </span>
          </p>
        );
      }
    }
  };
  showAuctionUSDValue = (items) => {
    if (!this.checkLastDate(items)) {
      if (
        items &&
        items.auction &&
        items.auction.id &&
        items.auction.id != null
      ) {
        return (
          <p className="mb-0 small d-none">
            <span className="usernamelilac">
              {items &&
                items.auction &&
                items.auction.id &&
                items.auction.id != null &&
                (items.auction.lastBid ? (
                  <span
                    className="small text-white "
                    title={getUSDValue(this.state.rate, items.auction.lastBid)}
                  >
                    {getUSDValue(this.state.rate, items.auction.lastBid) +
                      " USD"}
                  </span>
                ) : (
                  <span className="usernamelilac ">
                    {items &&
                      items.auction &&
                      items.auction.id &&
                      items.auction.id != null &&
                      items.auction.startPrice && (
                        <span
                          className="small text-white"
                          title={getUSDValue(
                            this.state.rate,
                            items.auction.startPrice
                          )}
                        >
                          {flowDecimal(
                            parseFloat(
                              (
                                Number(this.state.rate) *
                                items.auction.startPrice
                              ).toString()
                            )
                          ) + " USD"}
                        </span>
                      )}
                  </span>
                ))}
            </span>
          </p>
        );
      }
    } else {
      if (
        items &&
        items.auction &&
        items.auction.id &&
        items.auction.id != null
      ) {
        return (
          <p className="mb-0 small d-none">
            <span className="usernamelilac">
              {items.auction.lastBid ? (
                <span
                  className="small text-white "
                  title={getUSDValue(this.state.rate, items.auction.lastBid)}
                >
                  {getUSDValue(this.state.rate, items.auction.lastBid) + " USD"}
                </span>
              ) : (
                ""
              )}
            </span>
          </p>
        );
      }
    }
  };
  showPriceWithUSD = (items) => {
    return (
      <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa">
        {items &&
          items.drops &&
          items.drops[0] &&
          items.drops[0].auction &&
          items.drops[0].auction.id &&
          items.drops[0].auction.id != null ? (
          ""
        ) : (
          <div>
            {this.markedPrice(items)}
            {this.usdValue(items)}
          </div>
        )}

        {this.showTotalEditions(items)}

        {this.showAuctionValue(items && items.drops && items.drops[0])}
        {this.showAuctionUSDValue(items && items.drops && items.drops[0])}
        {this.state.show &&
          items &&
          items.drops &&
          items.drops[0] &&
          items.drops[0].auction &&
          items.drops[0].auction.id &&
          items.drops[0].auction.id != null &&
          this.startTime(items && items.drops && items.drops[0])}
      </span>
    );
  };
  isHidden = (items) => {
    if (items && items.status && items.status === 2) {
      return true;
    } else {
      return false;
    }
  };
  getImageSource = (items) => {
    if (items && items.localPreview && items.localPreview !== null) {
      return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview;
    } else {
      if (items && items.thumbnailHash) {
        return items.thumbnailHash;
      }
    }
  };
  getMimetype = (items) => {
    if (
      items &&
      items.localPreview &&
      items.localPreview !== null &&
      items.localPreviewMimeType !== null
    ) {
      return items.localPreviewMimeType;
    } else {
      if (items && items.thumbnailMimeType) {
        return items.thumbnailMimeType;
      }
    }
  };
  checkEDitionCount = (items) => {
    if (
      items &&
      items.groups &&
      items.groups !== null &&
      items.toatalMintedTokens
    ) {
      if (checkMintedTokens(items.groups, items.toatalMintedTokens) > 1) {
        return " Total Editions";
      } else {
        return " Total Edition";
      }
    } else {
      return " Total Edition";
    }
  };
  gotoListPage = (items) => {
    // if(isConnectWallet()){
    return (
      history.push(`/list/${items && items.tokenId}`),
      { data: { dataArray: items } }
    );
    // }
  };
  gotoCustodial = () => {
    // localStorage.setItem("page", "0");
    controller.abort()
    controller = new AbortController();
    this.setState(
      { custodialOffset: 0, custodialArray: [], dataArray: [], marketArray: [], hasMore: true, loader: true },
      () => {
        this.getcustodialArts();
      }
    );
  };
  getcustodialArts = () => {
    controller = new AbortController();
    let signal = controller.signal;
    let obj = {
      offSet: this.state.custodialOffset,
      recordLimit: this.state.size,
      sortBy: this.state.sortBy,
      sortOrder: this.state.sortOrder,
      showHiddenArts: true,
      signal
    };
    this.props.getCustodialArts(obj);
  };
  openMovePopupWarn = async (items) => {
    const walletAddress = await getUserAddress();

    this.setState({
      isOpenMovePopupWarn: true,
      transferNft: items,
      currentWalletAddress: walletAddress,
    });
  };
  closeWarnPopuo = () => {
    this.setState({ isOpenMovePopupWarn: false, transferNft: {} });
  };
  createaccountSetup = async () => {
    const walletAddress = await getUserAddress();
    this.setState({ currentWalletAddress: walletAddress })
    try {
      const setup = await accountSetup(walletAddress);
      if (setup && setup.status === 200) {
        this.setState({
          isAlert: true,
          message:
            "Your Flow account has been successfully added. Now you may proceed to claim NFT",
          style: "success",
        });
        this.gotoTop();
        this.resetTimeout();
        this.closeAlert();
        this.custodialToUserTransfer();
      } else if (setup && setup.status === 201) {
        this.custodialToUserTransfer();
      } else {
        this.setState({
          isAlert: true,
          message: setup,
          style: "danger",
        });
        this.gotoTop();
        this.resetTimeout();
        this.closeAlert();
      }
    } catch (e) {
      this.setState({
        isAlert: true,
        message: e,
        style: "danger",
      });
      this.gotoTop();
      this.resetTimeout();
      this.closeAlert();
    }
  };
  approveToMove = async () => {
    this.setState({ isOpenMovePopupWarn: false, transferNft: {} });

    if (isConnectWallet()) {
      this.createaccountSetup();
    } else {
      this.handleToSwitchAccount();
    }
  };
  handleCheckArt = (items, idx) => {
    if (items && items.id) {
      const updatedObj = { ...items, isChecked: !items.isChecked };
      const newArray = [...this.state.custodialArray];
      newArray[idx] = updatedObj;
      this.setState({ custodialArray: newArray });
    }
  };
  gotoAirdropDet = (item) => {
    if (item.id) {
      if (isWebLogin()) {
        history.push(`/airdrop/${item.id}`);
      } else {
        history.push("/login");
      }
    }
  };
  navigationCheck = (e, items) => {
    if (e.target.id === "creator") {
      this.gotoCreator(items && items.art && items.art.creator);
    } else {
      this.gotoAirdropDet(items);
    }
  };
  gotoCreator = (owner) => {
    if (
      owner &&
      owner.id &&
      owner.id != null &&
      owner.roleId &&
      owner.roleId == 1
    ) {
      history.push(
        `/creator/@${owner.userName && encodeURIComponent(owner.userName)}`,
        {
          obj: {
            userAddress: owner && owner.userAddress,
            userName: owner && owner.userName,
          },
        }
      );
    } else if (
      owner &&
      owner.id &&
      owner.id != null &&
      owner.roleId &&
      owner.roleId == 2
    ) {
      history.push(
        `/collector/@${owner.userName && encodeURIComponent(owner.userName)}`,
        { obj: { userAddress: owner && owner.userAddress } }
      );
    }
  };
  availableTime = (items, status) => {
    var futureDate = moment(items).format("MMM DD, YYYY HH:mm:ss");
    var countDownDate = new Date(futureDate).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    if ((distance < 0) || (status === AirDropStatus.WITHDRAWN)) {
      return false;
    }
    return (
      <div
        className="mt-1"
        title={moment(items).format("DD-MMM-YYYY hh:mm:ss A")}
      >
        <p className="mb-0 ">
          <span className="small">Available in</span>
          <br />
          <span className="usernamelilac">{`${days ? days + "d" : ""
            }  ${hours}h  ${minutes}m  ${seconds}s`}</span>
        </p>
      </div>
    );
  };
  getAirdropType = (type) => {
    if (type === airDropService.airDropType.LOCATED_USERS) {
      return "Location based";
    } else if (type === airDropService.airDropType.SELECTED_USERS) {
      return "Selected user";
    } else {
      return "Public";
    }
  };
  isHiddenAirdrop = (items) => {
    if (items && items.status === AirDropStatus.HIDDEN) {
      return true;
    } else {
      return false;
    }
  };
  handleTransfer = () => {
    this.setState({ transferMessage: "", isNFTTransfer: false })

    if (this.isAnyNFTSelectedForTransfer()) {
      this.openMovePopupWarn();
    } else {
      this.setState({ isAlert: true, message: "Please select your NFTs", style: "danger" })
      this.gotoTop()
      this.resetTimeout()
      this.closeAlert()
    }
  };
  custodialToUserTransfer = async () => {
    const walletAddress = await getUserAddress();

    this.setState({ transferMessage: `Please wait, Transferring NFT to your wallet ${walletAddress}`, isNFTTransfer: true })
    if (isConnectWallet()) {

      if (this.state.custodialArray && this.state.custodialArray.length > 0) {
        const filteredArray = this.state.custodialArray.filter((ele) => {
          if (ele.isChecked) {
            return ele;
          }
        });
        let titles = [];
        filteredArray &&
          filteredArray.length > 0 &&
          filteredArray.forEach((items) => {
            titles.push(items.title);
          });
        console.log(filteredArray, titles, walletAddress);
        let obj = {
          titles: titles,
          destinationWalletAddress: walletAddress,
        };
        // this.showLoader();

        artServices
          .custodialToUser(JSON.stringify(obj))
          .then((res) => {
            // this.hideLoader();
            console.log(res)
            res && res.text().then(text => {
              const data = text && JSON.parse(text);
              console.log(res)
              if (res.ok) {
                this.setState({ isSuccessTransfer: true, isNFTTransfer: false })
              } else {
                const validationErorrs = [];

                  if (data &&
                  data.data &&
                  data.data.errorCode &&
                  (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                  ) &&
                  data.data.data[0] &&
                  Array.isArray(data.data.data[0])) {

                  for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                      for (const message of detail) {
                        if (typeof message === 'string') {
                          validationErorrs.push(message);

                        }
                      }
                    }
                  }
                  this.setState({ isAlert: true, isNFTTransfer: false, message: validationErorrs[0], style: "danger", isAirDrop: false, isErrorPoped: false })
                  this.gotoTop()
                  this.resetTimeout()
                  this.closeAlert()

                }else if(data &&
                  data.data &&
                  data.data.errorCode &&
                    data.data.errorCode === 'TRANSFER_FAILED'
                  ){
                    if(data.data.data && data.data.data.transaction){
                      this.setState({txnId : data.data.data.transaction})
                    }
                    this.setState({ isAlert: false, errorMessage: "Transfer failed", isErrorPoped: true,isNFTTransfer: false })
                }
                else {
                  const error = (data && data.message)
                  this.setState({ isAlert: true, message: error, style: "danger", isNFTTransfer: false, isErrorPoped: false })
                  this.gotoTop()
                  this.resetTimeout()
                  this.closeAlert()
                }
              }
            })

          }).catch(err => {
            this.hideLoader()
            this.setState({
              isNFTTransfer: false
            })
            try {
              if (err instanceof TypeError) {
                this.setState({ isAlert: true, message: "TypeError", style: "danger" })

                return Promise.reject(err)
              } else {
                this.setState({ isAlert: true, message: err, style: "danger" })
              }
              this.gotoTop()
              this.resetTimeout()
              this.closeAlert()
            }
            catch (err) {
              this.setState({ isAlert: true, message: err, style: "danger" })
              this.gotoTop()
              this.resetTimeout()
              this.closeAlert()
            }

          })
      }
    }
  };
  resetCollectionPage = () => {
    this.setState({ isSuccessTransfer: false });
    this.setState({
      tabIndex: 0,
    });
    this.gotoArts()
  };
  closeSwitchAccModal = () => {
    this.setState({
      isSwitchAcc: false,
    });
  };
  handleToSwitchAccount = () => {
    this.setState({
      isSwitchAcc: true,
    });
  };
  switchWalletConnected = (wallet) => {
    if (wallet) {
      this.closeSwitchAccModal();
      this.props.linkWallet(JSON.stringify(wallet));
      this.setState({ iswallet: true });
      this.setState({ transferSetup: true, syncSetup: false });
    }
  };
  handleSwitchAccFailed = (message) => {
    this.setState({
      isAlert: true,
      message: message,
      style: "danger",
      isAirDrop: false,
    });
    this.gotoTop();
    this.resetTimeout();
    this.closeAlert();
  };
  isAnyNFTSelectedForTransfer = () => {

    if (this.state.custodialArray && this.state.custodialArray.length > 0) {
      const filteredArray = this.state.custodialArray.filter((ele) => {
        if (ele.isChecked) {
          return ele
        }
      })
      if (filteredArray && filteredArray.length > 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }
  isMultipleSelected = () => {

    if (this.state.custodialArray && this.state.custodialArray.length > 0) {
      const filteredArray = this.state.custodialArray.filter((ele) => {
        if (ele.isChecked) {
          return ele
        }
      })
      if (filteredArray && filteredArray.length > 1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }
  loadLogo = () => {
    if(isConnectWallet()){
      if(isDapperConnected()){
        return <img src={`../${configPath.imageAssets}/dapper_logo.png`} alt="" />
      }else{
        return <img src={`../${configPath.imageAssets}/blocto_logo.png`} alt="" />
  
      }
    }else{
      return <img src={`../${configPath.imageAssets}/digital-wallet.png`} alt="" />
    }
  }
  infinityLoader = () => {
    return <div className="infinity-loader">
    <div className="bg">

      <div className="left-bg"><img src={`../${configPath.imageAssets}/disruptart-p.png`} alt="" /></div>
      <div className="right-bg">{this.loadLogo()}</div>
    </div>
    <div className="fg">

      <div className="top-left-rect">
        <div></div>
      </div>
      <div className="bottom-right-rect">
        <div></div>
      </div>
      <div className="top-right-rect">
        <div></div>
      </div>
      <div className="bottom-left-rect">
        <div></div>
      </div>
    </div>
  </div>
  }
   getAirDropStatus = (number)=> {
    switch (number) {
      case 0:
        return 'Hidden';
      case 1:
        return 'Active';
      case 2:
        return 'Claimed';
      case 3:
        return 'Withdrawn';
      default:
        return 'Unknown';
    }
  }
  
  componentWillUnmount() {

    controller.abort()
    controller = new AbortController();
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    const {
      isCheckUserArt,
      transferNft,
      isCheckCustodial,
      custodialArray,
      hasMore,
      loader,
      isCheckAirDrop,
      dataArray,
      collectionHasMore,
      collectionLoader,
      onSaleHasMore,
      onSaleLoader,
      marketArray,
      airdropArray,
      airDropHasMore,
      airDropLoader,
    } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>Collections</title>
          <meta
            name="title"
            content={`Explore ${process.env.REACT_APP_SITE_CONTRACT} NFT Collections`}
          ></meta>
          <meta
            name="description"
            content={`Explore all NFT collections`}
          ></meta>
        </Helmet>
        {this.state.isAlert && (
          <div
            className={
              this.state.style === "danger"
                ? "alert alert-custom text-center alert-danger alertheight"
                : this.state.style === "success"
                  ? "alert alert-custom text-center alert-success alertheight"
                  : ""
            }
            id="parent"
          >
            <div className="d-flex flex-row align-items-center h-100 w-100">
              <span className="artmsg">{this.state.message}</span>
              <span className="closebtn" onClick={this.hideAlert}>
                &times;
              </span>
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.isOpenMovePopupWarn}
          style={customStyles}
          className="creatorrequ"
          ariaHideApp={false}
          contentLabel="Report Modal"
        >
          <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">
            <div className="dspcls" onClick={this.closeWarnPopuo}>
              x
            </div>
            <div className="py-3">
              <h2 className="mb-3 text-center">Transfer NFT</h2>
              {/* <img
                src={`../${configPath.imageAssets}/transfer-nft.png`}
                className="dsp-img mt-4"
              /> */}
              {this.infinityLoader()}
              <div className="col-sm-12 text-center">
                <div className="mt-3">
                  <h4 className="usernamelilac">
                    {transferNft && transferNft.title}
                  </h4>
                </div>
                <div className="mt-3">
                  {this.isMultipleSelected() ?
                    <h2>Would you like to transfer these NFT to your wallet? </h2> :
                    <h2>Would you like to transfer this NFT to your wallet? </h2>
                  }
                </div>
               
                  <div className="nftstashimg">
                    <h4>DISRUPT WALLET {" "} <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}</h4>
                    {this.loadLogo()}
                    <h4><span className="usernamelilac">{this.state.currentWalletAddress}</span></h4>
                  </div>
                
              </div>

              <div className="row mt-5">
                {/* <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdraw}>Cancel</button></div> */}
                <div className="col-12 text-center">
                  <button
                    className="btn btn-primary-fill"
                    type="button"
                    onClick={this.approveToMove}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {this.state.isSuccessTransfer && (
          <div className="show-success">
            <div className="success-pop">
              {/* {this.state.isImage && <img src={data && data.thumbnailHash} width="170px" alt="" />} */}
              <svg
                className="checkmark mt-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
              <div className="mt-3">
                <b>
                  Woohoo..! Successfully transferred to your wallet ({this.state.currentWalletAddress}) Good
                  luck!!
                </b>
              </div>
              <div className="mt-3">
                It may take 30 seconds for transferred art to appear in your
                wallet :)
              </div>

              <button
                type="button"
                className="btn btn-primary px-5 mt-5"
                onClick={(e) => {
                  this.resetCollectionPage();
                }}
              >
                View Collections
              </button>
            </div>
          </div>
        )}
        {this.state.isSwitchAcc ? (
          <SwitchAccount
            closeModal={this.closeSwitchAccModal}
            switchWalletConnected={(val) => this.switchWalletConnected(val)}
            handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}
          />
        ) : (
          ""
        )}
        {this.state.isNFTTransfer ?
          <div className="show-content">
            <div className="child-show custom-ui">
              {this.infinityLoader()}

              <p className="text-white">{this.state.transferMessage}</p>


              {/* <div className="nftstashimg mb-3">
                <h4>NFT STASH {" "} <i className="fa fa-arrow-right" aria-hidden="true"></i>{" "}</h4>
               {this.loadLogo()}
                <h4><span className="usernamelilac">{this.state.currentWalletAddress}</span></h4>
              </div> */}

              {/* <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" /> */}
            </div>
          </div>

          : ''
        }
        {this.state.isErrorPoped && !this.state.isSuccessTransfer &&
          <div className="show-cancel">
              <div className="success-pop pop-size">
                  <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                      <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                      <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                  </svg>
                  <div className="py-3">{this.state.errorMessage}</div>
                  
                  {this.state.txnId ? <div className='py-3'>Transaction id : {this.state.txnId}</div> : ''}

                  <div>
                    <button type="button" className="btn btn-primary px-5" onClick={(e) => { e.preventDefault(); this.setState({ isErrorPoped: false }) }}>Close</button>    

                      
                  </div>
                </div></div>
      }
        {isWebLogin() ? (
          <div ref={this.scrollRef}>
            <ToastContainer autoClose={3000} />
            <div className="art artcontent" >
              <div className="container">
                <div className="pagetitabtn mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h1>My Collection</h1>
                    </div>
                    <div className="col-md-4 text-right">
                      <button
                        className="btn btn-primary btn-smc"
                        onClick={this.syncWalletAccount}
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row" >
                  <JoyRide
                    start={checkMywalletPage()}
                    isScroll={true}
                    page="my_wallet"
                  />
                  <div className="col-md-12">
                    <Tabs
                      className="tab-wrap"
                      selectedIndex={this.state.tabIndex}
                      onSelect={(tabIndex) => this.onTabChange(tabIndex)}
                    >
                      <TabList className="tab-change">
                        <Tab
                          className={
                            this.state.tabIndex === 0
                              ? "tab-select collection_arts"
                              : "collection_arts"
                          }
                          onClick={this.gotoArts}
                        >
                          <h5
                            className={
                              this.state.tabIndex === 0
                                ? ""
                                : ""
                            }
                          >                            
                            My Collections
                            <button
                              type="button"
                              className=" btn btn-rounded btn-outline-dark float-right"
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h5>
                        </Tab>
                        <Tab
                          className={
                            this.state.tabIndex === 1
                              ? "tab-select onsale_arts"
                              : "onsale_arts"
                          }
                          onClick={this.gotoDrop}
                        >
                          <h5
                            className={
                              this.state.tabIndex === 1
                                ? ""
                                : ""
                            }
                          >                            
                            Listed for Sale
                            <button
                              type="button"
                              className=" btn btn-rounded btn-outline-dark float-right"
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h5>
                        </Tab>

                        <Tab
                          className={
                            this.state.tabIndex === 2
                              ? "tab-select airdrops"
                              : "airdrops"
                          }
                          onClick={this.gotoAirDrop}
                        >
                          <h5
                            className={
                              this.state.tabIndex === 2
                                ? ""
                                : ""
                            }
                          >                            
                            Listed for Airdrops
                            <button
                              type="button"
                              className=" btn btn-rounded btn-outline-dark float-right"
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h5>
                        </Tab>
                        <Tab
                          className={
                            this.state.tabIndex === 3
                              ? "tab-select custodial_arts"
                              : "custodial_arts"
                          }
                          onClick={this.gotoCustodial}
                        >
                          <h5
                            className={
                              this.state.tabIndex === 3
                                ? ""
                                : ""
                            }
                          >                            
                            Disrupt Wallet
                            <button
                              type="button"
                              className=" btn btn-rounded btn-outline-dark float-right"
                            >
                              <i
                                className="fa fa-refresh"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </h5>
                        </Tab>
                      </TabList>

                      <TabPanel>
                        <InfiniteScroll
                          dataLength={this.state.dataArray && this.state.dataArray.length} //This is important field to render the next data
                          next={
                            this.state.dataArray && this.state.dataArray.length > 0
                              ? this.getUserArts
                              : () => {
                                return false;
                              }
                          }
                          hasMore={collectionHasMore}
                          loader={
                            <div className="solarloader">
                              <SolarSystemLoading />
                            </div>
                          }
                          endMessage={
                            dataArray.length > 0 ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                }}
                                className="usernamelilac"
                              >
                                <b>You have reached the end of list</b>
                              </p>
                            ) : (
                              !collectionLoader && (
                                <div className="col-md-4 offset-md-4 text-center">
                                  <img
                                    src={`../${configPath.imageAssets}/no-nft-yet.png`}
                                    className="mw-100"
                                    alt=""
                                  />
                                </div>
                              )
                            )
                          }
                          style={{ overflow: "hidden" }}
                        >
                          <div className="walletlist">
                            <div className="row">
                              {this.state.dataArray &&
                                this.state.dataArray.map((items, index) => (
                                  <div
                                    className="col-md-3"
                                    key={index}
                                    onClick={(e) => {
                                      e.preventDefault();

                                      return items &&
                                        items.drops &&
                                        items.drops.length === 0
                                        ? this.gotoListPage(items)
                                        : this.gotoDropsDet(items);
                                    }}
                                  >
                                    <a
                                      href="#"
                                      className={
                                        this.isHidden(items)
                                          ? "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf hidden-arts"
                                          : "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf"
                                      }
                                      title={
                                        this.isHidden(items) ? "Hidden" : ""
                                      }
                                    >
                                      <div className="sc-bdnylx sc-gtssRu sc-bUQzRK jMhaxE jmCoux hvTeii img-placeholder">
                                        <div className="sc-bdnylx sc-gtssRu sc-iXeIkk jMhaxE cOXomX gwoePs">
                                          <ArtPreview
                                            src={items && items.thumbnailHash}
                                            alt="Avatar"
                                            className="comimg"
                                            isPremiumArt={
                                              items && items.isPremiumArt
                                            }
                                            showControls={true}
                                            autoPlay={true}
                                            mimeType={
                                              items && items.thumbnailMimeType
                                            }
                                          />
                                        </div>

                                        <div className="row walltcon h-100 align-content-end">
                                          <div className="col-md-12">
                                            <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa w-auto ">
                                              {items && items.title
                                                ? items.title
                                                : "Title"}
                                            </span>
                                          </div>
                                          <div className="col-md-12">
                                            <span
                                              className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa"
                                              title={
                                                items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].markedPrice
                                              }
                                            >
                                              {items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].markedPrice ? (
                                                <span className="usernamelilac">
                                                  {flowDecimal(
                                                    parseFloat(
                                                      items.drops[0].markedPrice
                                                    )
                                                  ) + " FLOW"}
                                                </span>
                                              ) : items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].auction &&
                                                items.drops[0].auction.id &&
                                                items.drops[0].auction.id !=
                                                null ? (
                                                <div>
                                                  {!this.checkLastDate(
                                                    items &&
                                                    items.drops &&
                                                    items.drops[0]
                                                  )
                                                    ? items &&
                                                    items.drops &&
                                                    items.drops[0] &&
                                                    items.drops[0].auction &&
                                                    items.drops[0].auction
                                                      .id &&
                                                    items.drops[0].auction
                                                      .id != null && (
                                                      <p className="mb-0 small">
                                                        <span
                                                          className="usernamelilac"
                                                          title={
                                                            items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.lastBid
                                                          }
                                                        >
                                                          {items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.id &&
                                                            items.drops[0]
                                                              .auction.id !=
                                                            null &&
                                                            (items.drops[0]
                                                              .auction
                                                              .lastBid ? (
                                                              flowDecimal(
                                                                parseFloat(
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .lastBid
                                                                )
                                                              ) + " FLOW"
                                                            ) : (
                                                              <span className="usernamelilac ">
                                                                {items &&
                                                                  items.drops &&
                                                                  items
                                                                    .drops[0] &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .id &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .id !=
                                                                  null &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .startPrice &&
                                                                  `Starting Price : ${flowDecimal(
                                                                    parseFloat(
                                                                      items
                                                                        .drops[0]
                                                                        .auction
                                                                        .startPrice
                                                                    )
                                                                  ) +
                                                                  " FLOW"
                                                                  }`}
                                                              </span>
                                                            ))}
                                                        </span>
                                                      </p>
                                                    )
                                                    : items &&
                                                    items.drops &&
                                                    items.drops[0] &&
                                                    items.drops[0].auction &&
                                                    items.drops[0].auction
                                                      .id &&
                                                    items.drops[0].auction
                                                      .id != null && (
                                                      <p className="mb-0 small">
                                                        <span
                                                          className="usernamelilac"
                                                          title={
                                                            items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.lastBid
                                                          }
                                                        >
                                                          {items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.id &&
                                                            items.drops[0]
                                                              .auction.id !=
                                                            null &&
                                                            (items.drops[0]
                                                              .auction.lastBid
                                                              ? flowDecimal(
                                                                parseFloat(
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .lastBid
                                                                )
                                                              ) + " FLOW"
                                                              : "")}
                                                        </span>
                                                      </p>
                                                    )}
                                                </div>
                                              ) : (
                                                "Not for sale"
                                              )}

                                              {items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].markedPrice ? (
                                                ""
                                              ) : items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].auction &&
                                                items.drops[0].auction.id &&
                                                items.drops[0].auction.id !=
                                                null ? (
                                                <div>
                                                  {!this.checkLastDate(
                                                    items &&
                                                    items.drops &&
                                                    items.drops[0]
                                                  )
                                                    ? items &&
                                                    items.drops &&
                                                    items.drops[0] &&
                                                    items.drops[0].auction &&
                                                    items.drops[0].auction
                                                      .id &&
                                                    items.drops[0].auction
                                                      .id != null && (
                                                      <p className="mb-0 small d-none">
                                                        <span className="usernamelilac">
                                                          {items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.id &&
                                                            items.drops[0]
                                                              .auction.id !=
                                                            null &&
                                                            (items.drops[0]
                                                              .auction
                                                              .lastBid ? (
                                                              <span
                                                                className="small text-white d-block"
                                                                title={getUSDValue(
                                                                  this.state
                                                                    .rate,
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .lastBid
                                                                )}
                                                              >
                                                                {flowDecimal(
                                                                  parseFloat(
                                                                    (
                                                                      Number(
                                                                        this
                                                                          .state
                                                                          .rate
                                                                      ) *
                                                                      items
                                                                        .drops[0]
                                                                        .auction
                                                                        .lastBid
                                                                    ).toString()
                                                                  )
                                                                ) + " USD"}
                                                              </span>
                                                            ) : (
                                                              <span className="usernamelilac  d-none">
                                                                {items &&
                                                                  items.drops &&
                                                                  items
                                                                    .drops[0] &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .id &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .id !=
                                                                  null &&
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .startPrice && (
                                                                    <span
                                                                      className="small text-white"
                                                                      title={getUSDValue(
                                                                        this
                                                                          .state
                                                                          .rate,
                                                                        items
                                                                          .drops[0]
                                                                          .auction
                                                                          .startPrice
                                                                      )}
                                                                    >
                                                                      {flowDecimal(
                                                                        parseFloat(
                                                                          (
                                                                            Number(
                                                                              this
                                                                                .state
                                                                                .rate
                                                                            ) *
                                                                            items
                                                                              .drops[0]
                                                                              .auction
                                                                              .startPrice
                                                                          ).toString()
                                                                        )
                                                                      ) +
                                                                        " USD"}
                                                                    </span>
                                                                  )}
                                                              </span>
                                                            ))}
                                                        </span>
                                                      </p>
                                                    )
                                                    : items &&
                                                    items.drops &&
                                                    items.drops[0] &&
                                                    items.drops[0].auction &&
                                                    items.drops[0].auction
                                                      .id &&
                                                    items.drops[0].auction
                                                      .id != null && (
                                                      <p className="mb-0 small d-none">
                                                        <span
                                                          className="usernamelilac"
                                                          title={
                                                            items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.lastBid
                                                          }
                                                        >
                                                          {items &&
                                                            items.drops &&
                                                            items.drops[0] &&
                                                            items.drops[0]
                                                              .auction &&
                                                            items.drops[0]
                                                              .auction.id &&
                                                            items.drops[0]
                                                              .auction.id !=
                                                            null &&
                                                            (items.drops[0]
                                                              .auction
                                                              .lastBid ? (
                                                              <span
                                                                className="small text-white d-block"
                                                                title={getUSDValue(
                                                                  this.state
                                                                    .rate,
                                                                  items
                                                                    .drops[0]
                                                                    .auction
                                                                    .lastBid
                                                                )}
                                                              >
                                                                {flowDecimal(
                                                                  parseFloat(
                                                                    (
                                                                      Number(
                                                                        this
                                                                          .state
                                                                          .rate
                                                                      ) *
                                                                      items
                                                                        .drops[0]
                                                                        .auction
                                                                        .lastBid
                                                                    ).toString()
                                                                  )
                                                                ) + " USD"}
                                                              </span>
                                                            ) : (
                                                              ""
                                                            ))}
                                                        </span>
                                                      </p>
                                                    )}
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].auction &&
                                                items.drops[0].auction.id &&
                                                items.drops[0].auction.id !=
                                                null ? (
                                                ""
                                              ) : items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].markedPrice &&
                                                items.groups &&
                                                items.groups != null ? (
                                                <span className="text-white">
                                                  / 1 Edition
                                                </span>
                                              ) : (
                                                <span> </span>
                                              )}

                                              {/* {(items.drops && items.drops[0] && items.drops[0].markedPrice) && <span className="small text-white d-block" title={getUSDValue(this.state.rate, items.drops[0].markedPrice)}>{(flowDecimal(parseFloat((this.state.rate) * (items.drops && items.drops[0] && items.drops[0].markedPrice)))) + ' USD'}</span>} */}

                                              {this.showTotalEditions(items)}
                                              {this.state.show &&
                                                items &&
                                                items.drops &&
                                                items.drops[0] &&
                                                items.drops[0].auction &&
                                                items.drops[0].auction.id &&
                                                items.drops[0].auction.id !=
                                                null &&
                                                this.startTime(
                                                  items &&
                                                  items.drops &&
                                                  items.drops[0]
                                                )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                              {/* {this.state.dataArray && this.state.dataArray.length === 0 && isCheckUserArt &&
                                                            <div className="col-md-4 offset-md-4 text-center">
                                                                <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                                                            </div>
                                                        } */}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </TabPanel>
                      <TabPanel>
                        <InfiniteScroll
                          dataLength={this.state.marketArray && this.state.marketArray.length} //This is important field to render the next data
                          next={
                            this.state.marketArray && this.state.marketArray.length > 0
                              ? this.getDropArts
                              : () => {
                                return false;
                              }
                          }
                          hasMore={onSaleHasMore}
                          loader={
                            <div className="solarloader">
                              <SolarSystemLoading />
                            </div>
                          }
                          endMessage={
                            marketArray.length > 0 ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                }}
                                className="usernamelilac"
                              >
                                <b>You have reached the end of list</b>
                              </p>
                            ) : (
                              !onSaleLoader && (
                                <div className="col-md-4 offset-md-4 text-center">
                                  <img
                                    src={`../${configPath.imageAssets}/no-nft-yet.png`}
                                    className="mw-100"
                                    alt=""
                                  />
                                </div>
                              )
                            )
                          }
                          style={{ overflow: "hidden" }}
                        >
                          <div className="walletlist">
                            <div className="row">
                              {this.state.marketArray &&
                                this.state.marketArray.map((items, index) => (
                                  <div
                                    className="col-md-3"
                                    key={items && items.id}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.gotoDropDetail(items);
                                    }}
                                  >
                                    <a
                                      href="#"
                                      className={
                                        this.isHidden(items)
                                          ? "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf hidden-arts"
                                          : "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf"
                                      }
                                    >
                                      <div className="sc-bdnylx sc-gtssRu sc-bUQzRK jMhaxE jmCoux hvTeii img-placeholder">
                                        <div className="sc-bdnylx sc-gtssRu sc-iXeIkk jMhaxE cOXomX gwoePs">
                                          <ArtPreview
                                            src={items && items.thumbnailHash}
                                            alt="Avatar"
                                            className="comimg"
                                            isPremiumArt={
                                              items && items.isPremiumArt
                                            }
                                            showControls={true}
                                            autoPlay={true}
                                            mimeType={
                                              items && items.thumbnailMimeType
                                            }
                                          />
                                        </div>
                                        <div className="row walltcon h-100 align-content-end">
                                          <div className="col-md-12">
                                            <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa w-auto">
                                              {items && items.title}
                                            </span>
                                          </div>
                                          <div className="col-md-12">
                                            {this.showPriceWithUSD(items)}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </TabPanel>

                      <TabPanel>
                        <InfiniteScroll
                          dataLength={this.state.airdropArray && this.state.airdropArray.length} //This is important field to render the next data
                          next={
                            this.state.airdropArray && this.state.airdropArray.length > 0
                              ? this.getAirdropArts
                              : () => {
                                return false;
                              }
                          }
                          hasMore={airDropHasMore}
                          loader={
                            <div className="solarloader">
                              <SolarSystemLoading />
                            </div>
                          }
                          endMessage={
                            airdropArray.length > 0 ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                }}
                                className="usernamelilac"
                              >
                                <b>You have reached the end of list</b>
                              </p>
                            ) : (
                              !airDropLoader && (
                                <div className="col-md-4 offset-md-4 text-center">
                                  <img
                                    src={`../${configPath.imageAssets}/no-nft-yet.png`}
                                    className="mw-100"
                                    alt=""
                                  />
                                </div>
                              )
                            )
                          }
                          style={{ overflow: "hidden" }}
                        >
                          <div className="walletlist">
                            <div className="row">
                              {this.state.airdropArray &&
                                this.state.airdropArray.map((items, index) => (
                                  <div
                                    className="col-md-3"
                                    key={index}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.navigationCheck(e, items);
                                    }}
                                  >
                                    <a
                                      href="#"
                                      className={
                                        this.isHiddenAirdrop(items)
                                          ? "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf hidden-arts"
                                          : "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf"
                                      }
                                      title={
                                        this.isHiddenAirdrop(items)
                                          ? "This airdrop has been hidden"
                                          : ""
                                      }
                                    >
                                      <div className="sc-bdnylx sc-gtssRu sc-bUQzRK jMhaxE jmCoux hvTeii img-placeholder">
                                        <div className="sc-bdnylx sc-gtssRu sc-iXeIkk jMhaxE cOXomX gwoePs">
                                          <ArtPreview
                                            src={
                                              items &&
                                              items.art &&
                                              items.art.thumbnailHash
                                            }
                                            alt={
                                              items &&
                                                items.art &&
                                                items.art.title
                                                ? items.art.title
                                                : "Avatar"
                                            }
                                            isPremiumArt={
                                              items &&
                                              items.art &&
                                              items.art.isPremiumArt
                                            }
                                            showControls={true}
                                            autoPlay={true}
                                            mimeType={
                                              items &&
                                              items.art &&
                                              items.art.thumbnailMimeType
                                            }
                                          />
                                          
                                        </div>

                                        <div className="row walltcon h-100 align-content-end">
                                          <div title={this.getAirDropStatus(items.status)} className="iconComponentParent">
                                            <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa w-auto ml-3 ">
                                                <IconComponent type={this.getAirDropStatus(items.status)} />
                                          </span>
                                          </div>
                                          <div className="col-md-12">

                                            <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa w-auto ">
                                              <IconComponent type={items.airDropType} />
                                              {items &&
                                                items.art &&
                                                items.art.title
                                                ? items.art.title
                                                : "Title"}
                                            </span>
                                          </div>
                                          {this.availableTime(
                                            (items && items.airDropDate), items.status
                                          ) ? (
                                            <div className="col-md-12">
                                              <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa">
                                                {this.state.show &&
                                                  this.availableTime(
                                                    items && items.airDropDate, items.status
                                                  )}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </TabPanel>
                      <TabPanel>
                        {(this.state.custodialArray && this.state.custodialArray.length > 0) ? <div className={`da-transsticky text-center position-sticky mb-3 ${this.state.stickyClass} `}>
                          <button className='btn btn-primary-fill' type='button' onClick={this.handleTransfer}>Transfer</button>
                        </div> : ''}
                        <InfiniteScroll
                          dataLength={this.state.custodialArray && this.state.custodialArray.length} //This is important field to render the next data
                          next={
                            this.state.custodialArray && this.state.custodialArray.length > 0
                              ? this.getcustodialArts
                              : () => {
                                return false;
                              }
                          }
                          hasMore={hasMore}
                          loader={
                            <div className="solarloader">
                              <SolarSystemLoading />
                            </div>
                          }
                          endMessage={
                            custodialArray.length > 0 ? (
                              <p
                                style={{
                                  textAlign: "center",
                                  fontSize: "20px",
                                }}
                                className="usernamelilac"
                              >
                                <b>You have reached the end of list</b>
                              </p>
                            ) : (
                              !loader && (
                                <div className="col-md-4 offset-md-4 text-center">
                                  <img
                                    src={`../${configPath.imageAssets}/no-nft-yet.png`}
                                    className="mw-100"
                                    alt=""
                                  />
                                </div>
                              )
                            )
                          }
                          style={{ overflow: "hidden" }}
                        >
                          <div className="walletlist">
                            <div className="row">
                              {this.state.custodialArray &&
                                this.state.custodialArray.map(
                                  (items, index) => (
                                    <div
                                      className="col-md-3"
                                      key={index}
                                    // onClick={() => this.openMovePopupWarn(items)}
                                    >
                                      <a
                                        href="#"
                                        className={
                                          this.isHidden(items)
                                            ? "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf hidden-arts"
                                            : "sc-dlnjPT sc-hKFyIo sc-fKgIGh sc-bCwgka cuIYFB hEVogm wZdoW gOyaWf"
                                        }
                                        title={
                                          this.isHidden(items) ? "Hidden" : ""
                                        }
                                      >
                                        <div
                                          className={
                                            items.isChecked
                                              ? "sc-bdnylx sc-gtssRu sc-bUQzRK jMhaxE jmCoux hvTeii img-placeholder selected-custodial"
                                              : "sc-bdnylx sc-gtssRu sc-bUQzRK jMhaxE jmCoux hvTeii img-placeholder"
                                          }
                                        >
                                          {this.isHidden(items) ? (
                                            ""
                                          ) : (
                                            <div className="walltcheckcont">
                                              <input
                                                type="checkbox"
                                                id={items.title + index}
                                                name="scales"
                                                checked={items.isChecked}
                                                onChange={() => {
                                                  this.handleCheckArt(
                                                    items,
                                                    index
                                                  );
                                                }}
                                              />
                                              <label
                                                htmlFor={items.title + index}
                                                className="walltcheck"
                                              ></label>
                                            </div>
                                          )}

                                          <div className="sc-bdnylx sc-gtssRu sc-iXeIkk jMhaxE cOXomX gwoePs">
                                            <ArtPreview
                                              src={items && items.thumbnailHash}
                                              alt="Avatar"
                                              className="comimg"
                                              isPremiumArt={
                                                items && items.isPremiumArt
                                              }
                                              showControls={true}
                                              autoPlay={true}
                                              mimeType={
                                                items && items.thumbnailMimeType
                                              }
                                            />
                                          </div>

                                          <div className="row walltcon h-100 align-content-end">
                                            <div className="col-md-12">
                                              <span className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa w-auto ">
                                                {items && items.title
                                                  ? items.title
                                                  : "Title"}
                                              </span>
                                            </div>

                                            <div className="col-md-12">
                                              <span
                                                className="sc-dlnjPT sc-hKFyIo sc-fyjqTV cuIYFB eJAQlC eJdBTa"
                                                title={
                                                  items &&
                                                  items.drops &&
                                                  items.drops[0] &&
                                                  items.drops[0].markedPrice
                                                }
                                              >
                                                {this.showTotalEditions(items)}



                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </InfiniteScroll>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to="/login" />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tokenArts: state.art.tokenArts,
    userArtsList: state.art.userArtsList,
    userDropList: state.drop.userDropList,
    isCheckUserArt: state.art.isCheckUserArt,
    usd: state.user.usd,
    isCheckArtDrop: state.drop.isCheckArtDrop,
    wallet: state.wallet.wallet,
    walletlinkError: state.wallet.walletlinkErr,
    allcustodialArts: state.art.custodialArts,
    isCheckCustodialArt: state.art.isCheckCustodialArt,
    allairdropList: state.user.selfAirdrops,
    isCheckAirDropList: state.user.isCheckSelfAirdrop,
  };
};
const actionCreators = {
  uploadDrop: dropActions.uploadDrop,
  detailArt: artActions.detailArt,
  getArtByToken: artActions.getArtByToken,

  getUserArts: artActions.getUserArts,
  getAirdropArts: userAction.getSelfAirDropArt,

  getUserDrop: dropActions.getUserDrop,
  resetAllArts: artActions.resetAllArts,
  resetAllDrops: dropActions.resetAllDrops,
  getUSD: userAction.getUSD,
  linkWallet: walletActions.linkWallet,
  resetlinkWallet: walletActions.resetlinkWallet,
  resetwalletlinkerror: walletActions.resetWalletLinkError,
  getCustodialArts: artActions.getCustodialArts,
  resetGetcustodialArts: artActions.resetAllCustodialArts,
  resetAirDropArt: userAction.resetGetSelfAirdropArts,
};
const connectUserArt = connect(mapStateToProps, actionCreators)(MyWallet);
export { connectUserArt as MyWallet };