import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { adminActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import { debounce } from 'utils';


class FetchStoreSales extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            stores: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 10,
            pageCount: '',
            isFetched: false,
            search: '',
            sortBy: 'purchaseDate',
            sortOrder: 'DESC',
            id : ''
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.uuid) {
            let id = decodeURIComponent(this.props.match.params.uuid)
            this.setState({id},() => {
                this.callApi()
            })
            
        }
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.storeSales && props.storeSales.statusCode === 200)) {
            props.resetGetStores()
            return {
                stores: props.storeSales && props.storeSales.data && props.storeSales.data.sales ? props.storeSales.data.sales : [],
                totalCount: props.storeSales && props.storeSales.data && props.storeSales.data.count,
                pageCount: props.storeSales && props.storeSales.data && props.storeSales.data.count / state.recordLimit,
                isFetched: true
            }
        }

    }
    handleRefresh = () => {
        this.setState({ offSet: 0,search : '' }, () => {
            this.callApi()
        })
    }


    callApi = () => {
        const { search, id } = this.state;
        this.setState({ forcePage: this.state.offSet != null ? ((this.state.offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (search) {
            obj.search = search;
        }
       
        this.props.getSales(id, obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    
   
    
    showBuyerEmail = (items) => {
        const email = items.buyerEmail 
           const firstEmail = email && email.split(',')
           if(firstEmail && firstEmail.length > 1){
              return firstEmail.map((items,idx) => (
                   <span key={idx}>{items}</span>
               ))
           }else{
               return items.buyerEmail
           }
   }
    render() {


        const eventList = this.state.stores && this.state.stores.map((items, index) => {
            return <tbody>
                <tr>
                    <td><a className={'userdescrip_adm'} style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.title}>{items && items.title}</a> </td>
                    <td>{(items && items.buyerAddress) ? items.buyerAddress : '-'}</td>
                    <td>{(items && items.buyerEmail) ? this.showBuyerEmail(items) : '-'}</td>
                    <td>{items && items.tokenId ? items.tokenId : "-"}</td>
                    <td>{(items && items.pricePerEdition) ? (`${items.pricePerEdition} USD`) : '-'}</td>
                    <td>{moment(items && items.purchaseDate).format(' DD-MM-YYYY hh:mm:ss A')}</td>
                    <td className="text-center"><a href={process.env.REACT_APP_FLOW_TRANSACTION + `/${items && items.txnId}`} target="_blank" className="designLink btn btn-rounded btn-outline-dark btn-icon" title='View' ><i className='fa fa-eye'></i></a></td>

                </tr>

            </tbody>

        })

        
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Store Sales Report</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        {/* <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div> */}
                                    </div>
                                   
                                    <div className="col-md-8 col-sm-12">

                                        <div className="text-right">
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>ART NAME</th>
                                        <th>BUYER ADDRESS</th>
                                        <th>BUYER EMAIL</th>
                                        <th>TOKEN ID</th>
                                        <th>PRICE</th>
                                        <th>PURCHASED AT</th>
                                        <th>VIEW TRANSACTION</th>

                                    </tr>
                                </thead>

                                {eventList}

                            </table>
                            {this.state.stores && this.state.stores.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.stores && this.state.stores.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        storeSales: state.adminaccount.storeSalesDetail,

    }
}

const actionCreators = {
    getSales: adminActions.getStoreSalesDetail,
    resetGetStores: adminActions.resetGetStoreSalesDetail
}

const connectFetchStoreSales = connect(mapStateToProps, actionCreators)(FetchStoreSales)

export { connectFetchStoreSales as FetchStoreSales }