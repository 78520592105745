import moment from "moment"

export const showTime = (date) => {
    if(date){
        if(moment(date).isDST()){
            
            return (
                 moment.utc(date).subtract(4, 'hours').format(" DD-MM-YYYY hh:mm:ss A") 
                 )
        }else{
            
            return (
                moment.utc(date).subtract(5, 'hours').format(" DD-MM-YYYY hh:mm:ss A") 
                )
        }
    }else{
        return false
    }
}