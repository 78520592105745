import { storeCreatorConstants } from "../../constants";

const initialState = {
    updatedCreatorStatus : {},
    profileadded : {},
    addedProfilefailed : {},
    profile : [],
    getprofilefailed : [],
    searchedArts : [],
    searchArtsFailed : '',
    userArts : [],
    userArtsFailed : '',
    listedArtForsale : [],
    listedArtSaleFailed : '',
    storeSections : [],
    storeSectionFailed : '',
    createSection : [],
    createSectionFailed : '',
    sectionArts : [],
    sectionadded : [],
    addsectionFailed : '',
    artdeleted : [],
    getsectionartsFailed : '',
    getUserDrops : [],
    getUserDropsFailed : '',
    getArtDetail : [],
    getArtDetailFailed : '',
    withdrawn : [],
    withdrawnFailed : '',
    bannedadded : [],
    bannerAddedFailed : '',
    getBannerData : [],
    getBannerDataFailed : '',
    deletedBanner : [],
    deletedBannerFailed : '',
    socialDefenitions : [],
    getSaleHistory : [],
    getSaleHistoryFailed : '',
    getSectionDetail: [],
    getSectionDetailFailed: '',
    submitSuccess: [],
    submitFailed: '',
    deletedSocialLinks: [],
    deletesocialLinkFailed: '',
    featuredDropUpdated: [],
    featuredDropUpdatedFailed: '',
    removedFeatured: [],
    removefromFeaturedFailed: '',
    galleryItems: [],
    galleryItemsFailed: '',
    timelineItems: [],
    timelineItemsFailed: '',
    metatags: [],
    metatagsFailed: '',
    getPurchaseData : [],
    getPurchaseDataFailed : '',
}
export function creatorStore(state = initialState, action) {
    switch (action.type) {
        case storeCreatorConstants.UPDATE_STORE_CREATOR_STATUS:
            return {
                ...state,
                updatedCreatorStatus: action.updatedCreatorStatus,

            }
        case storeCreatorConstants.ADD_STORE_CREATOR_PROFILE:
            return {
                ...state,
                profileadded : action.profileadded
            }
        case storeCreatorConstants.STORE_CREATOT_PROFILE_FAILED:
            return {
                ...state,
                addedProfilefailed : action.addedProfilefailed
            }
        case storeCreatorConstants.GET_STORE_CREATOR_PROFILE:
            return {
                ...state,
                profile : action.profile
            }
        case storeCreatorConstants.GET_STORE_PROFILE_FAILED:
            return {
                ...state,
                getprofilefailed : action.getprofilefailed
            }
        case storeCreatorConstants.STORE_SEARCH_ARTS:
            return {
                ...state,
                searchedArts : action.searchedArts
            }
        case storeCreatorConstants.STORE_SEARCH_ARTS_FAILED:
            return {
                ...state,
                searchArtsFailed : action.searchArtsFailed
            }
        case storeCreatorConstants.GET_USERS_ARTS:
            return {
                ...state,
                userArts : action.userArts
            }
        case storeCreatorConstants.GET_USERS_ARTS_FAILED:
            return {
                ...state,
                userArtsFailed : action.userArtsFailed
            }
        case storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE:
            return {
                ...state,
                listedArtForsale : action.listedArtForsale
            }
        case storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE_FAILED:
            return {
                ...state,
                listedArtSaleFailed : action.listedArtSaleFailed
            }
        case storeCreatorConstants.STORE_GET_SECTIONS:
            return {
                ...state,
                storeSections : action.storeSections
            }
        case storeCreatorConstants.STORE_GET_SECTIONS_FAILED:
            return {
                ...state,
                storeSectionFailed : action.storeSectionFailed
            }
        case storeCreatorConstants.STORE_CREATE_SECTION:
            return {
                ...state,
                createSection : action.createSection
            }
        case storeCreatorConstants.STORE_CREATE_SECTION_FAILED:
            return {
                ...state,
                createSectionFailed : action.createSectionFailed
            }
        case storeCreatorConstants.GET_SECTION1_ARTS:
            return {
                ...state,
                sectionArts : action.sectionArts
            }
        case storeCreatorConstants.GET_SECTION_ARTS_FAILED:
            return {
                ...state,
                getsectionartsFailed : action.getsectionartsFailed
            }
        case storeCreatorConstants.STORE_ADD_ARTS_SECTION:
            return {
                ...state,
                sectionadded : action.sectionadded
            }
        case storeCreatorConstants.STORE_ADD_ARTS_SECTION_FAILED:
            return {
                ...state,
                addsectionFailed : action.addsectionFailed
            }
        case storeCreatorConstants.STORE_DELETE_ARTS_SECTION:
            return {
                ...state,
                artdeleted : action.artdeleted
            }
        case storeCreatorConstants.STORE_DELETE_ARTS_SECTION_FAILED:
            return {
                ...state,
                deleteSectionFailed : action.deleteSectionFailed
            }
        case storeCreatorConstants.STORE_GET_USERS_DROPS_LIST:
            return {
                ...state,
                getUserDrops : action.getUserDrops
            }
        case storeCreatorConstants.STORE_GET_USERS_DROPS_LIST_FAILED:
            return {
                ...state,
                getUserDropsFailed : action.getUserDropsFailed
            }
        case storeCreatorConstants.STORE_GET_ART_DETAIL:
            return {
                ...state,
                getArtDetail : action.getArtDetail
            }
        case storeCreatorConstants.STORE_GET_ART_DETAIL_FAILED:
            return {
                ...state,
                getArtDetailFailed : action.getArtDetailFailed
            }
        case storeCreatorConstants.STORE_WITHDRAW_ARTS:
            return {
                ...state,
                withdrawn : action.withdrawn
            }
        case storeCreatorConstants.STORE_WITHDRAW_ARTS_FAILED:
            return {
                ...state,
                withdrawnFailed : action.withdrawnFailed
            }
        case storeCreatorConstants.STORE_ADD_BANNER_DATA:
            return {
                ...state,
                bannedadded : action.bannedadded
            }
        case storeCreatorConstants.STORE_ADD_BANNER_DATA_FAILED:
            return {
                ...state,
                bannerAddedFailed : action.bannerAddedFailed
            }
        case storeCreatorConstants.STORE_GET_BANNER_DATA:
            return {
                ...state,
                getBannerData : action.getBannerData
            }
        case storeCreatorConstants.STORE_GET_BANNER_DATA_FAILED:
            return {
                ...state,
                getBannerDataFailed : action.getBannerDataFailed
            }
        case storeCreatorConstants.STORE_DELETE_BANNER:
            return {
                ...state,
                deletedBanner : action.deletedBanner
            }
        case storeCreatorConstants.STORE_DELETE_BANNER_FAILED:
            return {
                ...state,
                deletedBannerFailed : action.deletedBannerFailed
            }
        case storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION:
            return {
                ...state,
                socialDefenitions : action.socialDefenitions
            }
        case storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION_FAILED:
            return {
                ...state,
                socialDefenitionsFailed : action.socialDefenitionsFailed
            }
        case storeCreatorConstants.STORE_GET_SALEHISTORY:
            return {
                ...state,
                getSaleHistory : action.getSaleHistory
            }
        case storeCreatorConstants.STORE_GET_SALEHISTOTY_FAILED:
            return {
                ...state,
                getSaleHistoryFailed : action.getSaleHistoryFailed
            }
        case storeCreatorConstants.STORE_GET_SECTION_DETAIL:
            return {
                ...state,
                getSectionDetail : action.getSectionDetail
            }
        case storeCreatorConstants.STORE_GET_SECTION_DETAIL_FAILED:
            return {
                ...state,
                getSectionDetailFailed : action.getSectionDetailFailed
            }
        case storeCreatorConstants.STORE_SUBMIT_SECTION_FORM:
            return {
                ...state,
                submitSuccess : action.submitSuccess
            }
        case storeCreatorConstants.STORE_SUBMIT_SECTION_FORM_FAILED:
            return {
                ...state,
                submitFailed : action.submitFailed
            }
        case storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK:
            return {
                ...state,
                deletedSocialLinks : action.deletedSocialLinks
            }
        case storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK_FAILED:
            return {
                ...state,
                deletesocialLinkFailed : action.deletesocialLinkFailed
            }
        case storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS:
            return {
                ...state,
                featuredDropUpdated : action.featuredDropUpdated
            }
        case storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS_FAILED:
            return {
                ...state,
                featuredDropUpdatedFailed : action.featuredDropUpdatedFailed
            }
        case storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP:
            return {
                ...state,
                removedFeatured : action.removedFeatured
            }
        case storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP_FAILED:
            return {
                ...state,
                removefromFeaturedFailed : action.removefromFeaturedFailed
            }
        case storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM:
            return {
                ...state,
                galleryItems : action.galleryItems
            }
        case storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM_FAILED:
            return {
                ...state,
                galleryItemsFailed : action.galleryItemsFailed
            }
        case storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM:
            return {
                ...state,
                timelineItems : action.timelineItems
            }
        case storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM_FAILED:
            return {
                ...state,
                timelineItemsFailed : action.timelineItemsFailed
            }
        case storeCreatorConstants.STORE_GET_META_TAGS:
            return {
                ...state,
                metatags : action.metatags
            }
        case storeCreatorConstants.STORE_GET_META_TAGS_FAILED:
            return {
                ...state,
                metatagsFailed : action.metatagsFailed
            }
        case storeCreatorConstants.STORE_GET_PURCHASE_DATA:
            return {
                ...state,
                getPurchaseData : action.getPurchaseData
            }
        case storeCreatorConstants.STORE_GET_PURCHASE_DATA_FAILED:
            return {
                ...state,
                getPurchaseDataFailed : action.getPurchaseDataFailed
            }
        default:
            return state
    }
}