import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from 'store/actions';
import { Alert } from "helpers/alert";

class ChangePassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            password : '',
            confirmPassword : '',
            submitted : false,
            verificationCode : '',
            uuid : '',
            isSuccess : false,
            message:'',
            isAlert : false,
            isInvalid : false
        }
    }
    componentDidMount(){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let verificationCode = urlParams.get('verificationCode')
        let uuid = this.props && this.props.match && this.props.match.params && this.props.match.params.uuid && this.props.match.params.uuid
        console.log(regex.test(uuid))
        if(regex.test(uuid)){
            this.setState({
                uuid: (uuid),verificationCode,isInvalid : false
            });
        }else{
            this.setState({
                uuid: '',verificationCode:'',isInvalid : true
            });
        }
        
    }
    static getDerivedStateFromProps(props,state){
       
        if((props && props.changeuser && props.changeuser.statusCode) == 200){
          
            props.resetchangeUserPassword()

            return {
                isSuccess : true,
                password:''
            }
        }
        if((props && props.failedchangeuser && state.submitted)){
            
            return {
                isAlert : true,
                message:props && props.failedchangeuser,
                style:"danger"
            }
        }
    }
    componentDidUpdate(props,state){
        if((props && props.failedchangeuser)){
            props.resetFailedchangeUserPassword()
        }
        if((state && state.isAlert)){
            this.closeAlert()
        }
      
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    closeAlert = () => {
        setTimeout(() => {
              
            this.setState({isAlert:false,message:'',style:""})
        },15000)
    }
    checkPassword = (value) => {
        if(value == (this.state.password)){
            return true
        }else{
            return false
        }
    }
    validPassword = (value) => {
        if((value && value.length) >= 8){
            return true
        }else{
            return false
        }
    }
     
    handleSubmit = (e) => {
        e.preventDefault()
        // console.log(this.state.password,this.state.userId)
        const {password,verificationCode,uuid,confirmPassword} = this.state
        this.setState({submitted:true})
        if(password && this.validPassword(password)){
            let obj = {
                verificationCode,
                newPassword : password
            }
            console.log(uuid)
            this.props.changeUserPassword(JSON.stringify(obj),uuid)
        }
    }
    gotopassword = () => {
        history.push('/forgotPassword')
    }
    render(){
        return (
            <Fragment>
                {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert}/>}
                <div className="container restpasswordh">
                    {this.state.isSuccess &&
                    <div className="show-success">
                    <div className="success-pop">
                        <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <div className="mt-3">Your password reset successfully.<br/>Login your account. Enjoy with Disrupt.art.</div>
                        {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptNowio" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disruptnow.io/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.com/users/DisruptNow#5704" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                        {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                        <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/login') }}>Login</button></div></div>
                }
                    {!(this.state.isInvalid) && <h1 className="text-center mt-5 mb-3">Change Password</h1>}
                    {!(this.state.isInvalid) && <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div className="purchasebox1 p-3">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group row">
                                        <label >Password</label>
                                        <input type="password" className="form-control" value={this.state.password} name="password" onChange={this.handleChange} placeholder="Enter password here"/>
                                        {this.state.submitted && !this.state.password && <div className="mandatory">Password Required</div>}
                                        {this.state.submitted && this.state.password && !this.validPassword(this.state.password) && <div className="mandatory">Password must be atleast 8 characters</div>}

                                    </div>
                                    {/* <div className="form-group row">
                                        <label >Confirm Password</label>
                                        <input type="password" className="form-control" value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleChange} disabled={!this.state.password}/>
                                        {this.state.submitted && !this.state.confirmPassword && <div className="mandatory">Confirm password Required</div>}
                                        {this.state.submitted && this.state.confirmPassword && !this.checkPassword(this.state.confirmPassword) && <div className="mandatory">password not match</div>}

                                    </div> */}
                                    <div className="form-group text-center">
                                        <button type="submit" className="btn btn-primary px-5">Reset</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}
                    {(this.state.isInvalid) && <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="error-template userdisableview">
                        <i className="fas fa-hourglass-end mw-100 my-5 fa-5x" aria-hidden="true"></i>

                            <h3 className="mb-2 usernamelilac ">Link expired. Please try Reset password again</h3>
                          
                            <button type="button" className="btn btn-primary mt-4" onClick={this.gotopassword}>Reset password</button>

                        </div>
                    </div>

                </div>
            </div>}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        changeuser : state.user.changeuser,
        failedchangeuser : state.user.failedchangeuser
    }
}

const actionCreators = {
    changeUserPassword : userAction.changeUserPassword,
    resetchangeUserPassword : userAction.resetchangeUserPassword,
    resetFailedchangeUserPassword : userAction.resetFailedchangeUserPassword
}

const connectPassword = connect(mapStateToProps,actionCreators)(ChangePassword)

export {connectPassword as ChangePassword}