import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { globalSearchSerivce } from "services";
import history from "helpers/history"
import { debounce } from "utils";
import { flowDecimal } from "helpers";

let controller = new AbortController();

export class GlobalSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            isSearching: false,
            prevPath: ''
        };
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);

        // Debounce the search api to improve performance
        this.debouncedFetch = debounce(this.fetchSearchResults.bind(this), 300);
    }

    onChange(e) {
        e.persist();
        
        const userInput = e.currentTarget.value;
        if (userInput !== '') {
            controller.abort()
        controller = new AbortController();
            this.debouncedFetch(userInput);
        } else {
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: '',
            });
        }

        this.setState({
            userInput, prevPath: history.location.pathname
        });
    }

    onClick(e) {
        // console.log('click event object', e)
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: ''
        });
    }

    onKeyDown(e) {
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) {
            e.preventDefault();
            const searchResult = filteredSuggestions[activeSuggestion]
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: ''
            });
            console.log(searchResult)

            // manually redirect to the selected option's link location
            if (searchResult.userId) {
                const userName = `@${encodeURIComponent(searchResult.displayText)}`;
                if (searchResult.userRole === 1) {
                    history.push(`/creator/${userName}`);
                    return;
                }
                history.push(`/collector/${userName}`)
                return;
            }else if(searchResult.tagId){
                const encodedTagName = `${encodeURIComponent(searchResult.displayText)}`;
                history.push(`/art/tag/${encodedTagName}`)
                return;
            }
            history.push(`/art/${searchResult.artId}`);
            return;
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    fetchSearchResults(searchTerm) {
        this.setState({
            isSearching: true
        })
        controller = new AbortController();
        let signal = controller.signal;
        globalSearchSerivce.getGlobalSearchResults(searchTerm,signal).then((response) => {
            this.setState({
                isSearching: false
            })
            if (response && 
                response.data &&
                response.data.users &&
                Array.isArray(response.data.users) &&
                response.data.arts &&
                Array.isArray(response.data.arts) &&
                response.data.tags &&
                Array.isArray(response.data.tags)
            ) {
                const filteredSuggestions = [];
                
                for (const art of response.data.arts) {
                    const artResult = {
                        artId: art.id,
                        displayText: art.title,
                        onSale: 0,
                        onAuction: 0,
                        isBiddingClosed: false,
                        ownerUserName: art.owner && art.owner.userName,
                        dropId : art.drops && art.drops[0] && art.drops[0].id,
                        isDapperDrop: false
                    };
                    if (art.drops.length) {
                        artResult.ownerUserName = art.drops && art.drops[0] && art.drops[0].listedUser && art.drops[0].listedUser.userName
                        if (art.drops[0].markedPrice) {
                            if(art.drops[0].storefrontListingResourceId && art.drops[0].withDapper){
                                artResult.isDapperDrop = true
                            }
                            artResult.onSale = parseFloat(art.drops[0].markedPrice);
                        } else if (art.drops[0].auction) {
                            artResult.onAuction = (art.drops[0].auction);
                            const auctionEnd = new Date(art.drops[0].auction.endDay);
                            if (Date.now() > auctionEnd.getTime()) {
                                artResult.isBiddingClosed = true;
                            }
                        }
                    }
                    filteredSuggestions.push(artResult);
                }
                for (const tag of response.data.tags) {
                    filteredSuggestions.push({
                        tagId: tag.id,
                        displayText: tag.name
                    })
                }
                for (const user of response.data.users) {
                    filteredSuggestions.push({
                        userId: user.id,
                        displayText: user.userName,
                        userRole: user.roleId,
                        userFullName: user.fullName
                    })
                }
                this.setState({
                    activeSuggestion: 0,
                    filteredSuggestions,
                    showSuggestions: true,
                });
            }
        }).catch(e => {
            this.setState({
                isSearching: false
            })
            // console.error(e);
        })
    }
    componentDidUpdate(state,props) {
        if (!(this.state.showSuggestions) && (this.state.userInput == "")) {
            const suggestions = document.getElementsByClassName("suggestions")
            const inpId = document.getElementById("globalSearchInput")

            const nosuggestions = document.getElementsByClassName("no-suggestions")
            if (suggestions && suggestions[0] && suggestions[0].style) {
                suggestions[0].style.display = "none"
            }
            if (nosuggestions && nosuggestions[0] && nosuggestions[0].style) {
                nosuggestions[0].style.display = "none"
            }
            // if (inpId && inpId.value) {
            //     inpId.value = ""
            // }

        } else if ((this.state.prevPath != history.location.pathname) && (this.state.showSuggestions) && (this.state.userInput)) {
            
            this.setState({
                userInput: '',
                showSuggestions: false
            })
        }

    }
    isDapperCurrency = (isDapper) => {
        if(isDapper){
            return 'USD'
        }else{
            return 'FLOW'
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions: searchResults,
                showSuggestions,
                userInput,
                isSearching
            }
        } = this;
        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (!isSearching) {
                if (searchResults.length) {
                    suggestionsListComponent = (
                        <ul className="suggestions">
                            {searchResults.map((result, index) => {
                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }
                                let link = null;
                                if (result.tagId) {
                                    const encodedTagName = `${encodeURIComponent(result.displayText)}`;
                                    const tagName = `#${result.displayText}`;
                                    link = <Link to={`/art/tag/${encodedTagName}`}>
                                        {tagName}
                                    </Link>;
                                } else if (result.userId) {
                                    const encodedUserName = `@${encodeURIComponent(result.displayText)}`;
                                    const userName = `@${result.displayText}`;
                                    const fullName = result.userFullName;
                                    if (result.userRole === 1) {
                                        link = <Link to={`/creator/${encodedUserName}`} title={userName}>
                                            {userName}<br />
                                            <small>{fullName}</small>
                                        </Link>;
                                    } else {
                                        link = <Link to={`/collector/${encodedUserName}`} title={userName}>
                                            {userName}<br />
                                            <small>{fullName}</small>
                                        </Link>;
                                    }
                                } else {
                                    const artTitle = result.displayText;
                                    const sale = result.onSale;
                                    const auction = result.onAuction;
                                    const isAuctionClosed = result.isBiddingClosed;
                                    const ownerUserName = result.ownerUserName;
                                    const isDapper = result.isDapperDrop
                                    if (sale) {
                                        link = <Link to={`/art/${result.artId}#drop${result.dropId}`} title={artTitle}>
                                            {artTitle}<br />
                                            {
                                                !!ownerUserName &&
                                                ownerUserName !== '' &&
                                                <small>owner: @{ownerUserName}</small>
                                            }
                                            <br />
                                            <small>On sale for {sale && flowDecimal(sale)} {this.isDapperCurrency(isDapper)}</small>
                                        </Link>
                                    } else if (auction) {
                                        link = <Link to={`/art/${result.artId}#drop${result.dropId}`} title={artTitle}>
                                            {artTitle}<br />
                                            {
                                                !!ownerUserName &&
                                                ownerUserName !== ''
                                                && <small>owner: @{ownerUserName}</small>
                                            }
                                            <br />
                                            {
                                                isAuctionClosed ? <small>Auction has ended</small>
                                                    : <small>On auction</small>
                                            }
                                        </Link>
                                    } else {
                                        link = <Link to={`/art/${result.artId}`} title={artTitle}>
                                            {artTitle}<br />
                                            {
                                                !!ownerUserName &&
                                                ownerUserName !== ''
                                                && <small>owner: @{ownerUserName}</small>
                                            }
                                            <br />
                                            <small><i>Not for sale</i></small>
                                        </Link>
                                    }

                                }
                                return (
                                    <li className={className} key={index} onClick={onClick}>
                                        {link}
                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else {
                    suggestionsListComponent = (
                        <div className="no-suggestions">
                            <em>No suggestions available.</em>
                        </div>
                    );
                }
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <i className="fas fa-spin fa-circle-notch fa-lg"></i>
                    </div>
                );
            }
        }
        return (
            <Fragment>
                <form className="form-inline" autoComplete="false">
                    <div className="form-group mt-1">
                        <div className={"auto-suggest " + (this.state.userInput.length ? "icon-hidden" : "")}>
                            <input type="search" id="globalSearchInput" className="form-control"
                                placeholder="Search artworks,creators and collectors..." onChange={onChange} onKeyDown={onKeyDown} value={userInput} />
                            {suggestionsListComponent}
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}