import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { adminServices } from 'services';
import moment from 'moment'
import { adminActions } from 'store/actions';
import { toast } from 'react-toastify';
import history from 'helpers/history';
import { flowDecimal } from 'helpers';


class FetchTransactionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
            isError: false,
            detail: {},
            btnName: 'Relist'
        }
        this.statusType = {
            COMPLETED: 'COMPLETED',
            INITIATED: 'INITIATED',
            UNTOUCHED: 'UNTOUCHED'
        }

    }
    componentDidMount() {
        this.callApi()
    }


    callApi = () => {

        let id = decodeURIComponent(this.props.match.params.id)

        this.props.dropHistoryDetail(id)

    }
    static getDerivedStateFromProps(props, state) {


        if (props && props.allDrop && props.allDrop.statusCode === 200) {
            props.resetDropHistory()

            if (props.allDrop.data && props.allDrop.data.history) {
                return {
                    history: props.allDrop.data.history
                }
            }

        }

        return null;

    }
    componentDidUpdate(props, state) {
        if (this.props && this.props.relistedsuccess && this.props.relistedsuccess.statusCode === 200 &&
            (this.props.relistedsuccess !== (props.relistedsuccess))) {
            this.props.resetDropRelistSuccess()
            toast.success("Relisted successfully")
            if (this.state.history && this.state.history.id) {
                this.props.dropHistoryUpdate(this.state.history.id)
            }

        }
        if (this.props && this.props.historyUpdatedSuccess && this.props.historyUpdatedSuccess.statusCode === 200 &&
            (this.props.historyUpdatedSuccess !== (props.historyUpdatedSuccess))) {
            this.props.resethistoryupdateSuccess()
            this.setState({
                btnName : 'Waiting for response'
            })
            this.callApi()

        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    handleCategoryChange = (e, item) => {
        const { allCategories } = this.state
        if ((item && item.id)) {
            if (e.target.value) {
                const selectedCategory = allCategories && allCategories.length > 0 && allCategories.filter(cat => (cat.name === e.target.value))
                console.log(e.target.value, item, selectedCategory)
                const obj = {
                    categoryId: selectedCategory && selectedCategory[0] && selectedCategory[0].id
                }
                this.showLoader()
                adminServices.updateArtCategory((item.id), JSON.stringify(obj)).then(res => {
                    if ((res && res.statusCode) === 200) {
                        toast.success("Category updated")
                        this.callApi()
                        this.hideLoader()

                    } else {
                        this.hideLoader()
                    }
                }).catch(error => {
                    toast.error(error)
                    this.hideLoader()
                })
            }
        }
    }
    gotoback = () => {
        history.push('/admin/transactions')

    }
    handleRelist = (history) => {
        if (history && history.id && history.dapperDropHistoryItems && history.dapperDropHistoryItems.length > 0) {
            const incompleteList = history.dapperDropHistoryItems.filter(el => el.listStageStatus !== (this.statusType.COMPLETED))
            if (incompleteList && incompleteList.length > 0) {
                const tokenIds = incompleteList.map(el => el.tokenId)
                let obj = {
                    tokenIds: tokenIds
                }
                this.props.dropRelist(JSON.stringify(obj), history.id)
            }

        }
    }
    isRelist = (history) => {
        if (history && history.dapperDropHistoryItems && history.dapperDropHistoryItems.length > 0) {
            const isTransferComplete = history.dapperDropHistoryItems.every(el => el.transferStageStatus === (this.statusType.COMPLETED))
            if (isTransferComplete) {
                const isListComplete = history.dapperDropHistoryItems.every(el => el.listStageStatus === (this.statusType.COMPLETED))
                if (isListComplete) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }
    gotoCreator = (owner) => {
        const baseurl = process.env.REACT_APP_BASEPATH

        if (owner && owner.roleId && owner.roleId == 1) {
            const id = `/creator/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        } else if (owner && owner.roleId && owner.roleId == 2) {
            const id = `/collector/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        }
    }
    render() {
        const { detail, history } = this.state
        return (

            <Fragment>

                <div className="container-fluid">

                    <div className="mb-4 admintitle">
                        <div className='row'><h2 className="font-weight-bold mr-3">Listing Detail</h2>
                        </div>
                    </div>
                    {<div className="purchasebox p-2">
                        <div className="card">
                            <div className="row">

                                {(history && history.art && history.art.title) && <div className="col-md-4 mb-3">
                                    <label>Art Name</label>
                                    <div className="comboxgray">
                                        {history && history.art.title}
                                    </div>
                                </div>}
                                {(history && history.tokenCount) && <div className="col-md-4 mb-3">
                                    <label>Editions Count</label>
                                    <div className="comboxgray">
                                        {history.tokenCount}
                                    </div>
                                </div>}
                                {(history && history.listPrice) && <div className="col-md-4 mb-3">
                                    <label>List Price</label>
                                    <div className="comboxgray">
                                        {(flowDecimal(parseFloat(history.listPrice)) + ' USD')}
                                    </div>
                                </div>}
                                {(history && history.initiatedUser && history.initiatedUser.userName) && <div className="col-md-4 mb-3">
                                    <label>Seller</label>
                                    <div className="comboxgray" >
                                        <a className='cursor-pointer' onClick={() => this.gotoCreator(history.initiatedUser)}>{history.initiatedUser.userName && `@${history.initiatedUser.userName}`}</a>
                                    </div>
                                </div>}
                                {(history && history.initiatedWalletAccount && history.initiatedWalletAccount.walletAddress) && <div className="col-md-4 mb-3">
                                    <label>Seller Wallet Account</label>
                                    <div className="comboxgray">
                                        {history.initiatedWalletAccount.walletAddress}
                                    </div>
                                </div>}
                                {/* {(history && history.initiatedUser && history.initiatedUser.uuid) && <div className="col-md-4 mb-3">
                                    <label>Initiater UUID</label>
                                    <div className="comboxgray">
                                        {history.initiatedUser.uuid}
                                    </div>
                                </div>} */}
                                {(history && history.initiatedUser && history.initiatedUser.roleId) && <div className="col-md-4 mb-3">
                                    <label>Seller Role</label>
                                    <div className="comboxgray">
                                        {history.initiatedUser.roleId && history.initiatedUser.roleId === 1 ? "Creator" : "Collector"}
                                    </div>
                                </div>}
                                {(history) && <div className="col-md-4 mb-3">
                                    <label>Initiated from</label>
                                    <div className="comboxgray">
                                        {(history && history.initiatedFor) ? 'Store Dashboard' : 'Marketplace'}
                                    </div>
                                </div>}

                                {(history && history.eventStatus) && <div className="col-md-4 mb-3">
                                    <label>Event Status</label>
                                    <div className="comboxgray">
                                        {history.eventStatus}
                                    </div>
                                </div>}
                                {(history && history.initiatedDate) && <div className="col-md-4 mb-3">
                                    <label>Initiated Date</label>
                                    <div className="comboxgray">
                                        {moment(history && history.initiatedDate).format(" DD-MM-YYYY hh:mm:ss A")}
                                    </div>
                                </div>}
                                {(this.isRelist(history) ? <div className="col-md-12 text-right mb-3">
                                    <button className='btn btn-primary btn-sm' type="button" onClick={() => this.handleRelist(history)}>{this.state.btnName}</button>
                                </div> : '')}
                                {history &&
                                    history.dapperDropHistoryItems &&
                                    history.dapperDropHistoryItems.length > 0 &&

                                    <div className="col-md-12 mb-3 airdrop-tab-hgt">
                                        <table className="table table-bordered table-striped-cusom">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Token Id</th>
                                                    <th scope="col">List Stage Status</th>
                                                    <th scope="col">Transfer Stage Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {history.dapperDropHistoryItems.map((items, index) => (
                                                    <tr key={items && items.tokenId}>
                                                        <td>{items && items.tokenId}</td>
                                                        <td>{(items && items.listStageStatus && items.listStageStatus === "COMPLETED") ? <a>COMPLETED</a> : <a className='usernamelilac'>{items.listStageStatus}</a>}</td>
                                                        <td>{(items && items.transferStageStatus && items.transferStageStatus === "COMPLETED") ? <a>COMPLETED</a> : <a className='usernamelilac'>{items.transferStageStatus}</a>}</td>
                                                    </tr>))}
                                            </tbody>
                                        </table>
                                    </div>}





                            </div>
                        </div>



                    </div>
                    }

                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allDrop: state.adminaccount.dropHistoryDetail,
        relistedsuccess: state.adminaccount.dropHistoryrelisted,
        historyUpdatedSuccess: state.adminaccount.dropHistoryupdated
    }
}

const actionCreators = {
    dropHistoryDetail: adminActions.getDropHistoryDetail,
    resetDropHistory: adminActions.resetDropHistoryDetail,
    dropRelist: adminActions.dropHistoryRelisting,
    resetDropRelistSuccess: adminActions.resetDropHistoryRelist,
    dropHistoryUpdate: adminActions.dropHistoryrelistSuccess,
    resethistoryupdateSuccess: adminActions.resetDropHistoryRelistUpdate

}

const connectFetchTransactionDetail = connect(mapStateToProps, actionCreators)(FetchTransactionDetail)

export { connectFetchTransactionDetail as FetchTransactionDetail }