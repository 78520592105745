import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { defaultFclLimit, getFCLLimit, listOnSaleLimits, purchaseLimits, widthdrawLimits } from "./fcl-limit";

export const RetrieveMarketToken = async (useraddress) => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main():[UInt64] {
                    // Get the public account object for account 0x01
                    let account1 = getAccount(`+ useraddress + `)

                    // Find the public Sale reference to their Collection
                    let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                        .borrow()
                        ?? panic("Could not borrow acct2 nft sale reference")

                    // Los the NFTs that are for sale
                    log("Account 1 NFTs for sale")
                    return acct1saleRef.getIDs()
                    //log("Price")
                    //log(acct1saleRef.idPrice(tokenID: 1))
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    console.log("market tokens")
    console.log(scriptresult)
    return {
      status: 200,
      data: { "message": "User DisruptNow Token", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}

export const marketSaleObjCreation = async () => {

  let marketSaleObjCreation = `\
                                        import FungibleToken from `+ process.env.REACT_APP_FUNGIBLE_TOKEN + `
                                        import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                        import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                                        // This transaction creates a new Sale Collection object,
                                        // lists an NFT for sale, puts it in account storage,
                                        // and creates a public capability to the sale so that others can buy the token.
                                        transaction {

                                            prepare(acct: AuthAccount) {

                                                // Borrow a reference to the stored Vault
                                                let receiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)

                                                // Create a new Sale object,
                                                // initializing it with the reference to the owner's vault
                                                let sale <- ${process.env.REACT_APP_MARKET_CONTRACT}.createSaleCollection(ownerVault: receiver)

                                                // Store the sale object in the account storage 
                                                acct.save(<-sale, to: `+ process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                                // Create a public capability to the sale so that others can call its methods
                                                acct.link<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath, target: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                                log("Sale Created for account 1. Selling NFT 1 for 10 tokens")
                                            }
                                        } `

  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    const { transactionId } = await fcl.send([
      fcl.transaction(marketSaleObjCreation),
      fcl.args([]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(defaultFclLimit),
    ])

    console.log(transactionId)

  } catch (error) {
    console.log("send to error reponse")
    console.error(error);
  }

}

export const marketSaleToken = async (tokenid, price) => {

  let marketSaleToken = `\
                                      import FungibleToken from `+ process.env.REACT_APP_FUNGIBLE_TOKEN + `
                                      import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                      import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                                      transaction(tokenid: UInt64, price: UFx64) {


                                          prepare(acct: AuthAccount) {

                                              if acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath) == nil {
                                                   // Borrow a reference to the stored Vault
                                                   let receiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)

                                                   // Create a new Sale object,
                                                   // initializing it with the reference to the owner's vault
                                                   let sales <- ${process.env.REACT_APP_MARKET_CONTRACT}.createSaleCollection(ownerVault: receiver)

                                                   // Store the sale object in the account storage 
                                                   acct.save(<-sales, to: `+ process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                                   // Create a public capability to the sale so that others can call its methods
                                                   acct.link<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath, target: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                              }

                                              let sale = acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                                         ?? panic("Could not borrow acct nft sale reference")

                                              // borrow a reference to the NFTCollection in storage
                                                  ?? panic("Could not borrow owner's nft collection reference")

                                              // Withdraw the NFT from the collection that you want to sell
                                              // and move it into the transaction's context
                                              let token <- collectionRef.withdraw(id: tokenid)

                                              // List the token for sale by moving it into the sale object
                                              sale.listForSale(token: <-token, price: price)

                                          }
                                      } `

  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    const { transactionId } = await fcl.send([
      fcl.transaction(marketSaleToken),
      fcl.args([
        fcl.arg(tokenid, t.UInt64),
        fcl.arg(price, t.UFix64)
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(9999),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    console.log(result);
    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens in list on sale", "Error": String(e) }
    };
  }

}

// Function to get particulart market id

export const RetrieveMarketTokenById = async (useraddress, tokenid) => {

  let retrieveTokensById = ` \

              import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

              pub fun main():UFix64? {
                  // Get the public account object for account 0x01
                  let account1 = getAccount(`+ useraddress + `)

                  // Find the public Sale reference to their Collection
                  let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                      .borrow()
                      ?? panic("Could not borrow acct2 nft sale reference")

                  return acct1saleRef.idPrice(id: `+ tokenid + `)
              }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokensById)]);
    return {
      status: 200,
      data: { "message": "User DisruptNow Token", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}

export const marketBuyToken = async (selleraddress, tokenid, payment) => {

  let marketBuyToken = `\
                          import FungibleToken from `+ process.env.REACT_APP_FUNGIBLE_TOKEN + `
                          import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                          import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                          import FlowToken from `+ process.env.REACT_APP_FLOW_TOKEN + `

                          // This transaction uses the signers Vault tokens to purchase an NFT
                          // from the Sale collection of account 0x01.
                          transaction(selleracct: Address) {
                          
                              // reference to the buyer's NFT collection where they
                              // will store the bought NFT
                              let collectionRef: &{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}
                          
                              // Vault that will hold the tokens that will be used to
                              // but the NFT
                              let temporaryVault: @FungibleToken.Vault
                          
                              prepare(acct: AuthAccount) {

                                  // Return early if the account already has a collection
                                                            if acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: ` + process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath) == nil {

                                                                // Create a new empty collection
                                                                let collection <- ${process.env.REACT_APP_CONTRACT}.createEmptyCollection()

                                                                // save it to the account
                                                                acct.save(<-collection, to: `+ process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath)

                                                                // create a public capability for the collection
                                                                acct.link<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(
                                                                        `+ process.env.REACT_APP_CONTRACT + `.disruptArtPublicPath,
                                                                        target: `+ process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath
                                                                        )
                                                            } 




                                  if acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath) == nil {
                                        // Borrow a reference to the stored Vault
                                        let receiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)

                                        // Create a new Sale object,
                                        // initializing it with the reference to the owner's vault
                                        let sales <- ${process.env.REACT_APP_MARKET_CONTRACT}.createSaleCollection(ownerVault: receiver)

                                        // Store the sale object in the account storage 
                                        acct.save(<-sales, to: `+ process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                        // Create a public capability to the sale so that others can call its methods
                                        acct.link<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath, target: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                  }
 

                          
                                  // get the references to the buyer's fungible token Vault and NFT Collection Receiver
                                  self.collectionRef = acct.borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(from: ` + process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath)!
                                  let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
                                      ?? panic("Could not borrow owner's vault reference")
                          
                                  // withdraw tokens from the buyers Vault
                                  self.temporaryVault <- vaultRef.withdraw(amount: `+ payment + `)
                              }
                          
                              execute {
                                  // get the read-only account storage of the seller
                                  let seller = getAccount(selleracct)
                          
                                  // get the reference to the seller's sale
                                  let saleRef = seller.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                                      .borrow()
                                      ?? panic("Could not borrow seller's sale reference")

                                  // purchase the NFT the the seller is selling, giving them the reference
                                  // to your NFT collection and giving them the tokens to buy it
                                  saleRef.purchase(id: `+ tokenid + `, recipient: self.collectionRef, payment: <-self.temporaryVault)
                              }
                          }`


  try {
    const blockResponse = await fcl.send([
      fcl.getBlock(),
    ])
    const { transactionId } = await fcl.send([
      fcl.transaction(marketBuyToken),
      fcl.args([fcl.arg(selleraddress, t.Address)]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(9999),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const NarketSaleStatus = async (useraddress, tokenid) => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): Bool? {
                    // Get the public account object for account 0x01
                    let account1 = getAccount(`+ useraddress + `)

                    // Find the public Sale reference to their Collection
                    let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                        .borrow()
                        ?? panic("Could not borrow acct2 nft sale reference")

                    return acct1saleRef.isresale(id: `+ tokenid + `)
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Market sale status", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const MarketTokenPrice = async (useraddress, tokenid) => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): UFix64? {
                    // Get the public account object for account 0x01
                    let account1 = getAccount(`+ useraddress + `)

                    // Find the public Sale reference to their Collection
                    let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                        .borrow()
                        ?? panic("Could not borrow acct2 nft sale reference")

                    return acct1saleRef.idPrice(id: `+ tokenid + `)
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Market Token price", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const NarketTokenCreator = async (useraddress, tokenid) => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): Address?? {
                    // Get the public account object for account 0x01
                    let account1 = getAccount(`+ useraddress + `)

                    // Find the public Sale reference to their Collection
                    let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                        .borrow()
                        ?? panic("Could not borrow acct2 nft sale reference")

                    return acct1saleRef.tokenCreator(id: `+ tokenid + `)
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Market Token creator", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const NarketTokenOwner = async (useraddress, tokenid) => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): Address?? {
                    // Get the public account object for account 0x01
                    let account1 = getAccount(`+ useraddress + `)

                    // Find the public Sale reference to their Collection
                    let acct1saleRef = account1.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                        .borrow()
                        ?? panic("Could not borrow acct2 nft sale reference")

                    return acct1saleRef.currentTokenOwner(id: `+ tokenid + `)
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Market Token owner", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const marketSaleWithdrawn = async (tokens) => {

  let marketSaleWithdrawn = `\
                                        import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                                        transaction(tokens:[UInt64]) {

                                          prepare(acct: AuthAccount) {

                                              let salewithdrawn = acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                                         ?? panic("Could not borrow acct nft sale reference")

                                              let tokenids:[UInt64] = tokens

                                              // List the token for sale by moving it into the sale object
                                              salewithdrawn.saleWithdrawn(tokens:tokenids)

                                          }
                                      } `


  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    if (!Array.isArray(tokens)) {
      throw new Error("Expected array for the argument 'tokens'")
    }
    if (!tokens.some(token => Number.isInteger(token))) {
      throw new Error("Expected array of Integers for the argument 'tokens'");
    }
    const limit = getFCLLimit(tokens.length, widthdrawLimits);
    // console.log('marketSaleWithdrawn dynamic limit',limit);
    const { transactionId } = await fcl.send([
      fcl.transaction(marketSaleWithdrawn),
      fcl.args([
        fcl.arg(tokens, t.Array(t.UInt64))
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(limit),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            resolve({
              status: 200,
              data: {
                "message": "Market Token Price change",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const changePrice = async (tokenids, price) => {

  let changePrice = `\
                                        import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                                        transaction(tokenids: [UInt64],price: UFix64) {

                                          prepare(acct: AuthAccount) {

                                              let collectionRef = acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                                         ?? panic("Could not borrow acct nft sale reference")

                                              let tokens:[UInt64] = tokenids

                                              // List the token for sale by moving it into the sale object
                                              collectionRef.changePrice(tokens:tokens,newPrice:price)

                                          }
                                      } `;

  const blockResponse = await fcl.send([
    fcl.getBlock(),
  ])

  try {
    if (!Array.isArray(tokenids)) {
      throw new Error("Expected array for the argument 'tokenids'")
    }
    if (!tokenids.some(token => Number.isInteger(token))) {
      throw new Error("Expected array of Integers for the argument 'tokenids'");
    }
    if (Number.isNaN(price)) {
      throw new Error("Expected number for the argument 'price'");
    }
    const { transactionId } = await fcl.send([
      fcl.transaction(changePrice),
      fcl.args([
        fcl.arg(tokenids, t.Array(t.UInt64)),
        fcl.arg(price, t.UFix64)
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(9999),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            resolve({
              status: 200,
              data: {
                "message": "Market Token Price change",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}


export const GetMarketFee = async () => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): UFix64 {
                    return ${process.env.REACT_APP_MARKET_CONTRACT}.marketFee
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Market Fee", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens in market fee retrieve", "Error": String(e) }
    };
  }

}

export const GetCreatorRoyality = async () => {

  let retrieveTokens = ` \

                import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                pub fun main(): UFix64 {
                    return ${process.env.REACT_APP_MARKET_CONTRACT}.royality
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens)]);
    return {
      status: 200,
      data: { "message": "Creator Royality", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens in market cretor royality", "Error": String(e) }
    };
  }

}

// flow percentage calulation

export const CalculateShare = async (amount, percentage) => {

  let retrieveTokens = ` \

                pub fun main(amount:UFix64,percentage:UFix64): UFix64 {
                    return (amount / 100.0) * percentage
                }`

  try {
    let scriptresult = await fcl.send([fcl.script(retrieveTokens),
    fcl.args([
      fcl.arg(amount, t.UFix64),
      fcl.arg(percentage, t.UFix64),
    ]),]);
    return {
      status: 200,
      data: { "message": "Creator Royality", "Value": scriptresult }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens in market cretor royality", "Error": String(e) }
    };
  }

}
export const marketBuyTokenMultiple = async (selleraddress, tokenids, payment) => {

  let marketBuyToken = `\
                          import FungibleToken from `+ process.env.REACT_APP_FUNGIBLE_TOKEN + `
                          import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                          import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                          import FlowToken from `+ process.env.REACT_APP_FLOW_TOKEN + `

                          // This transaction uses the signers Vault tokens to purchase an NFT
                          // from the Sale collection of account 0x01.
                          transaction(selleracct: Address, tokenids: [UInt64], payment: UFix64) {
                          
                              // reference to the buyer's NFT collection where they
                              // will store the bought NFT
                              let collectionRef: &{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}
                          
                              // Vault that will hold the tokens that will be used to
                              // but the NFT
                              let temporaryVault: @FungibleToken.Vault
                          
                              prepare(acct: AuthAccount) {

                                  // Return early if the account already has a collection
                                                            if acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: ` + process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath) == nil {

                                                                // Create a new empty collection
                                                                let collection <- ${process.env.REACT_APP_CONTRACT}.createEmptyCollection()

                                                                // save it to the account
                                                                acct.save(<-collection, to: `+ process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath)

                                                                // create a public capability for the collection
                                                                acct.link<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(
                                                                        `+ process.env.REACT_APP_CONTRACT + `.disruptArtPublicPath,
                                                                        target: `+ process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath
                                                                        )
                                                            } 




                                  if acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath) == nil {
                                        // Borrow a reference to the stored Vault
                                        let receiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)

                                        // Create a new Sale object,
                                        // initializing it with the reference to the owner's vault
                                        let sales <- ${process.env.REACT_APP_MARKET_CONTRACT}.createSaleCollection(ownerVault: receiver)

                                        // Store the sale object in the account storage 
                                        acct.save(<-sales, to: `+ process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                        // Create a public capability to the sale so that others can call its methods
                                        acct.link<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath, target: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                  }
 

                          
                                  // get the references to the buyer's fungible token Vault and NFT Collection Receiver
                                  self.collectionRef = acct.borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(from: ` + process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath)!
                                  let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
                                      ?? panic("Could not borrow owner's vault reference")
                          
                                  // withdraw tokens from the buyers Vault
                                  self.temporaryVault <- vaultRef.withdraw(amount: payment)
                              }
                          
                              execute {
                                  // get the read-only account storage of the seller
                                  let seller = getAccount(selleracct)
                          
                                  // get the reference to the seller's sale
                                  let saleRef = seller.getCapability<&AnyResource{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath)
                                      .borrow()
                                      ?? panic("Could not borrow seller's sale reference")

                                  let tokens:[UInt64] = tokenids

                                  // purchase the NFT the the seller is selling, giving them the reference
                                  // to your NFT collection and giving them the tokens to buy it
                                  saleRef.purchaseGroup(tokens: tokens, recipient: self.collectionRef, payment: <-self.temporaryVault)
                              }
                          }`
  // console.log("purchase", marketBuyToken, "tokenids", tokenids, "typeof tokenids", typeof tokenids);

  try {
    if (!Array.isArray(tokenids)) {
      throw new Error("Expected array for the argument 'tokenids'")
    }
    if (!tokenids.some(token => Number.isInteger(token))) {
      throw new Error("Expected array of Integers for the argument 'tokenids'");
    }
    if (Number.isNaN(payment)) {
      throw new Error("Expected number for the argument 'payment'");
    }
    if (typeof selleraddress !== 'string') {
      throw new Error("Expected string for the argument 'selleraddress'");
    }
    const limit = getFCLLimit(tokenids.length, purchaseLimits);
    
    console.log('marketBuyTokenMultiple dynamic limit', limit);
    const blockResponse = await fcl.send([
      fcl.getBlock(),
    ])
    const { transactionId } = await fcl.send([
      fcl.transaction(marketBuyToken),
      fcl.args([
        fcl.arg(selleraddress, t.Address),
        fcl.arg(tokenids, t.Array(t.UInt64)),
        fcl.arg(payment, t.UFix64)
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(limit),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    return result;

  } catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens", "Error": String(e) }
    };
  }

}




export const marketSaleTokenGroup = async (tokens, price) => {

  let marketSaleToken = `\
                                      import FungibleToken from `+ process.env.REACT_APP_FUNGIBLE_TOKEN + `
                                      import  `+ process.env.REACT_APP_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `
                                      import `+ process.env.REACT_APP_MARKET_CONTRACT + ` from ` + process.env.REACT_APP_CONTRACT_ADDRESS + `

                                      transaction(tokens: [UInt64] , price:UFix64) {


                                          prepare(acct: AuthAccount) {

                                              if acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath) == nil {
                                                   // Borrow a reference to the stored Vault
                                                   let receiver = acct.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)

                                                   // Create a new Sale object,
                                                   // initializing it with the reference to the owner's vault
                                                   let sales <- ${process.env.REACT_APP_MARKET_CONTRACT}.createSaleCollection(ownerVault: receiver)

                                                   // Store the sale object in the account storage 
                                                   acct.save(<-sales, to: `+ process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)

                                                   // Create a public capability to the sale so that others can call its methods
                                                   acct.link<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection{${process.env.REACT_APP_MARKET_CONTRACT}.SalePublic}>(` + process.env.REACT_APP_MARKET_CONTRACT + `.marketPublicPath, target: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                              }

                                              let sale = acct.borrow<&${process.env.REACT_APP_MARKET_CONTRACT}.SaleCollection>(from: ` + process.env.REACT_APP_MARKET_CONTRACT + `.marketStoragePath)
                                                         ?? panic("Could not borrow acct nft sale reference")

                                              // borrow a reference to the NFTCollection in storage
                                              let collectionRef = acct.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: ` + process.env.REACT_APP_CONTRACT + `.disruptArtStoragePath)
                                                  ?? panic("Could not borrow owner's nft collection reference")


                                              // List the token for sale by moving it into the sale object
                                              sale.listForSaleGroup(sellerRef: collectionRef,tokens: tokens, price: price)

                                          }
                                      } `

  const blockResponse = await fcl.send([
    fcl.getBlock()
  ])

  try {
    if (!Array.isArray(tokens)) {
      throw new Error("Expected array for the argument 'tokens'")
    }
    if (!tokens.some(token => Number.isInteger(token))) {
      throw new Error("Expected array of Integers for the argument 'tokens'");
    }
    if (Number.isNaN(price)) {
      throw new Error("Expected number for the argument 'price'");
    }
    const limit = getFCLLimit(tokens.length, listOnSaleLimits);
    console.log('marketSaleTokenGroup dynamic limit', limit);
    const { transactionId } = await fcl.send([
      fcl.transaction(marketSaleToken),
      fcl.args([
        fcl.arg(tokens, t.Array(t.UInt64)),
        fcl.arg(price, t.UFix64)
      ]),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.authorizations([
        fcl.currentUser().authorization
      ]),
      fcl.payer(fcl.currentUser().authorization),
      fcl.ref(blockResponse["block"].id),
      fcl.limit(limit),
    ])

    const result = new Promise((resolve, reject) => {
      fcl

        .tx(transactionId)

        .subscribe(transaction => {

          if (fcl.tx.isSealed(transaction)) {
            // console.log(`Transaction (${transactionId}) is Sealed`)
            // console.log('Transaction obj', transaction);
            resolve({
              status: 200,
              data: {
                "message": "Minted Token",
                "Value": String(transactionId),
                "transaction": transaction
              }
            })
          }
        })
    })

    console.log(result);
    return result;

  } catch (e) {
    // console.error('marketSaleTokenGroup Failed',String(e));
    return {
      status: 400,
      data: { "message": "Exception happens in list on sale", "Error": String(e) }
    };
  }

}

export const getMarketAddress = async () => {
  let getMarketAddressScript = `
    import ${process.env.REACT_APP_MARKET_CONTRACT} from ${process.env.REACT_APP_MARKET_CONTRACT_ADDRESS}

    pub fun main():Address {
        return ${process.env.REACT_APP_MARKET_CONTRACT}.marketAddress
    }
  `;

  try {
    let scriptresult = await fcl.send([fcl.script(getMarketAddressScript)]);
    return {
      status: 200,
      data: { "message": "Fetched Market address", "Value": scriptresult["encodedData"]["value"] }
    };
  }
  catch (e) {
    return {
      status: 400,
      data: { "message": "Exception happens while fetching market address", "Error": String(e) }
    };
  }
}

