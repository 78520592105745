import React from "react";
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { configPath } from "../../config";
import { Helmet } from "react-helmet";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { toast, Zoom } from "react-toastify";
import Select from 'react-select';

var timeout;


class StoreSectionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            section1: '',
            section2: '',
            section3: '',
            desc1: '',
            desc2: '',
            desc3: '',
            isSectionAvalbl : false,
            storeID : ''
        };
        this.maxLength = 50
        this.maxDescLength = 200
        this.minDescLength = 10

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
    }
   
    
    componentDidUpdate(prevProps, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
       
        if (
            (this.props && this.props.formsubmitSuccess && this.props.formsubmitSuccess.statusCode === 200) &&
            (this.props.formsubmitSuccess !== (prevProps && prevProps.formsubmitSuccess))) {
            this.props.resetSubmitFormSuccess()
            this.props.getsections(this.state.storeID)
            
        }
        if (this.props &&
            this.props.formsubmitFailed &&
            this.props.formsubmitFailed.length > 0 &&
            (this.props.formsubmitFailed !== prevProps.formsubmitFailed)
        ) {
            this.props.resetSubmitFormFailed()
            toast.error(this.props.formsubmitFailed, { transition: Zoom })

        }
      

    }
    static getDerivedStateFromProps(props, state) {
        if(props && props.storeId){
            return {
                storeID : props.storeId,
            }
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    checkDesc = (value) => {
        if (((value && value.length) <= (this.maxDescLength)) && ((value && value.length) >= (this.minDescLength))) {
            return true
        } else {
            return false
        }
    }
    checkmaxDesc = (value) => {
        if ((value && value.length) <= (this.maxDescLength)) {
            return true
        } else {
            return false
        }
    }
    checkSectionName = (value) => {
        if (((value && value.length) <= 50) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkSectionNameMxLgt = (value) => {
        if (((value && value.length) <= 50)) {
            return true
        } else {
            return false
        }
    }

    onsectionName = (event) => {
        if((event.target.value).length <= this.maxLength){
            this.setState({
                [event.target.name]: event.target.value
            });
        }else{
            this.setState({ [event.target.name]: (event.target.value.slice(0,this.maxLength))})
        }
        
    }
    onDescChange = (event) => {
        if((event.target.value).length <= this.maxDescLength){
            this.setState({
                [event.target.name]: event.target.value
            });
        }else{
            this.setState({ [event.target.name]: (event.target.value.slice(0,this.maxDescLength))})
        } 
    }


    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { section1, section2, section3, storeID, desc1, desc2, desc3 } = this.state
        if (section1 && section2 && section3) {
            if(desc1 && desc2 && desc3){
                if(this.checkSectionName(section1) && this.checkSectionName(section2) && this.checkSectionName(section3)){
                    if(this.checkDesc(desc1) && this.checkDesc(desc2) && this.checkDesc(desc3)){
                        let sections = [{
                            "sectionName": section1,
                            "sectionDescription": desc1
                          },{
                            "sectionName": section2,
                            "sectionDescription": desc2
                          },{
                            "sectionName": section3,
                            "sectionDescription": desc3
                          }]
                          let obj = {sections : sections}
                          this.props.submitForm((JSON.stringify(obj)),storeID)
                    }else{
                        toast.error(`Description must be atleast ${this.minDescLength} and atmost ${this.maxDescLength} characters`, { transition: Zoom })
                    }
                }else {
                    toast.error('Please provide a valid section name', { transition: Zoom })
                }
            }
        } else {
            toast.error('Please provide a section name', { transition: Zoom })
        }

    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }



    render() {

        return (

            <div className="">
                <Helmet>
                    <title>Create store section</title>
                    <meta name="title" content={`Update profile`}></meta>
                    <meta name="description" content={`Update profile`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit">
                        <h1 className="mb-0">Create your store section</h1>
                    </div>

                    <div className="propagecont mb-5">
                        <div className="container1">
                            <div className="row mb-5">

                            </div>

                            <form>

                                <div className="mt-0 mt-md-5 pt-md-3 row">

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="section1" name="section1" aria-describedby="section1" placeholder="Enter section name" value={this.state.section1} onChange={this.onsectionName} />
                                                    <div className="input-floating-label"><span>First Section name</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{"Min 3 - Max 50"}</div>}
                                                {this.state.submitted && !this.state.section1 && <div className="mandatory">{"Section name is Required"}</div>}
                                                {this.state.submitted && this.state.section1 && !this.checkSectionName(this.state.section1) && <div className="mandatory">{"Section name must be at least 3 characters and atmost 50 characters"}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="section2" name="section2" aria-describedby="section2" placeholder="Enter section name" value={this.state.section2} onChange={this.onsectionName} />
                                                    <div className="input-floating-label"><span>Second Section name</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{"Min 3 - Max 50"}</div>}
                                                {this.state.submitted && !this.state.section2 && <div className="mandatory">{"Section name is Required"}</div>}
                                                {this.state.submitted && this.state.section2 && !this.checkSectionName(this.state.section2) && <div className="mandatory">{"Section name must be at least 3 characters and atmost 50 characters"}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <input type="text" className="form-control" id="section3" name="section3" aria-describedby="section3" placeholder="Enter section name" value={this.state.section3} onChange={this.onsectionName} />
                                                    <div className="input-floating-label"><span>Third Section name</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{"Min 3 - Max 50"}</div>}
                                                {this.state.submitted && !this.state.section3 && <div className="mandatory">{"Section name is Required"}</div>}
                                                {this.state.submitted && this.state.section3 && !this.checkSectionName(this.state.section3) && <div className="mandatory">{"Section name must be at least 3 characters and atmost 50 characters"}</div>}
                                            </div>



                                            {/* --------------------------------------------------------------------------------------- */}
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <textarea className="form-control" id="desc1" name="desc1" aria-describedby="desc1" placeholder="Enter description" value={this.state.desc1} onChange={this.onDescChange} />
                                                    <div className="input-floating-label"><span>First Section Description</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{`Min ${this.minDescLength} - Max ${this.maxDescLength}`}</div>}
                                                {this.state.submitted && !this.state.desc1 && <div className="mandatory">{"Description Required"}</div>}
                                                {this.state.submitted && this.state.desc1 && !this.checkDesc(this.state.desc1) && <div className="mandatory">{`Description must be at least ${this.minDescLength} characters and atmost ${this.maxDescLength} characters`}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <textarea className="form-control" id="desc2" name="desc2" aria-describedby="desc2" placeholder="Enter description" value={this.state.desc2} onChange={this.onDescChange} />
                                                    <div className="input-floating-label"><span>Second Section Description</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{`Min ${this.minDescLength} - Max ${this.maxDescLength}`}</div>}
                                                {this.state.submitted && !this.state.desc2 && <div className="mandatory">{"Description Required"}</div>}
                                                {this.state.submitted && this.state.desc2 && !this.checkDesc(this.state.desc2) && <div className="mandatory">{`Description must be at least ${this.minDescLength} characters and atmost ${this.maxDescLength} characters`}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-4">

                                                <div className="ds-inputs">
                                                    <textarea className="form-control" id="desc3" name="desc3" aria-describedby="desc3" placeholder="Enter description" value={this.state.desc3} onChange={this.onDescChange} />
                                                    <div className="input-floating-label"><span>Third Section Description</span></div>
                                                </div>
                                                {<div className="usernamelilac small mt-1">{`Min ${this.minDescLength} - Max ${this.maxDescLength}`}</div>}
                                                {this.state.submitted && !this.state.desc3 && <div className="mandatory">{"Description Required"}</div>}
                                                {this.state.submitted && this.state.desc3 && !this.checkDesc(this.state.desc3) && <div className="mandatory">{`Description must be at least ${this.minDescLength} characters and atmost ${this.maxDescLength} characters`}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center col-md-12">
                                        <hr className="lightline" />
                                        <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
       formsubmitFailed : state.creatorStore.createSectionFailed,
       formsubmitSuccess : state.creatorStore.createSection,
       usersList: state.creatorStore.profile,

    }
}

const actionCreators = {
    submitForm : creatorStoreActions.createStoreSection,
    resetSubmitFormSuccess : creatorStoreActions.resetCreateStoreSection,
    resetSubmitFormFailed : creatorStoreActions.resetCreateStoreSctionFailed,
    getsections: creatorStoreActions.getStoreSection,

}

const connectStoreSectionForm = connect(mapStateToProps, actionCreators)(StoreSectionForm)

export { connectStoreSectionForm as StoreSectionForm }