import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify';
import { artActions, creatorActions, dropActions, userAction, walletActions } from 'store/actions'
import { marketBuyToken, GetCreatorRoyality, GetMarketFee, RetrieveMarketToken, changePrice, marketSaleWithdrawn, NarketTokenCreator, NarketTokenOwner, MarketTokenPrice, getUserAddress, CalculateShare, fusdbalance, createFusdAccount, marketBuyTokenMultiple, RetrieveTokens, createSetUp, getDisplayMessageForRuntimeTxnError } from 'utils';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { artServices, dropServices, fetchService, walletServices } from 'services';
import moment from 'moment'
import { Link } from 'react-router-dom';
import * as fcl from "@onflow/fcl";
import { Alert } from "helpers/alert";
import { configPath } from 'config';
import { checkWalletAddress, isConnectWallet, isUserLogin, isWebLogin, userBalance } from 'helpers';
import Modal from 'react-modal'
import { SignIn } from './sign-in';
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton, FacebookMessengerShareButton, WhatsappIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon
} from 'react-share'
import { DetailWallet } from './detailMyWallet';
import { dropConstants } from 'constants';
import { ArtPreview } from './art-preview/art-preview';
import { cancelFlowAuction, placeBidOnFlowAuction, settleFlowAuction } from 'utils/flowauction';
import { Helmet } from 'react-helmet';

var timeout;
var transactions = []
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
class OwnerArt extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.toggleMenu = this.toggleMenu.bind(this);
        this.imgpopview = this.imgpopview.bind(this);
    }
    copyToClipboard = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ copySuccess: 'Copied!' });
        const interval = setInterval(() => {
            this.setState({ copySuccess: 'Copy Link' });
        }, 3000);

    };

    toggleMenu() {
        this.setState({ visible: !this.state.visible })
    }

    imgpopview() {
        this.setState({ imgpop: !this.state.imgpop })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.getDrops()
        // this.props.getUSD()
        getUserAddress()
            .then((address) => {
                this.setState({ currentUserAddress: address })
            })
            .catch((error) => {
                // console.error('Unable to fetch User address', error);
                this.setState({ currentUserAddress: null })
            });
        this.getBalance()




    }
    getDrops = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let userAddress = ""
        let tokenId = ""
        let dropList = []
        let markedPrice = ""
        let artList = []
        let myArt = []
        let userId = ''
        let mySaleArt = []
        // console.log("this", this.props)
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.artId) {
            let artId = this.props.match.params.artId
            let ownerName = decodeURIComponent(this.props.match.params.ownerName).replace('@', '')
            let artName = decodeURIComponent(this.props.match.params.artName)
            // console.log(artName, this.props.match.params.artName)

            this.showLoader()
            artServices.getOwnerArts((artId), (ownerName), (artName)).then(res => {
                this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    // this.fetchArt(res && res.data.res.data.imageHash)
                    tokenId = res && res.data && res.data.tokenId
                    userAddress = res && res.data && res.data.ownerWalletAddress
                    userId = res && res.data && res.data.owner && res.data.owner != null && res.data.owner.id != null ? res.data.owner.id : res.data.ownerWallet && res.data.ownerWallet != null && res.data.ownerWallet.id
                    this.setState({ tokenId, userAddress })
                    if (res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].auction && res.data.drops[0].auction.id && res.data.drops[0].auction.id != null) {
                        dropServices.getAuctionDetail((res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].auction && res.data.drops[0].auction.id)).then(auctionDet => {
                            if ((auctionDet && auctionDet.statusCode) == 200) {
                                this.setState({ auctionHistory: auctionDet && auctionDet.data && auctionDet.data.auction })
                            }
                        }).catch(err => {
                            // console.log(err, "aucerr")
                        })
                        if (res && res.data && res.data.isOwner) {
                            this.setCount()
                            this.fetchArt(res && res.data && res.data.imageHash)
                            // this.startTime()
                            this.setState({ myAuctionList: res && res.data, isMyAuction: true, showAuction: false, itsMine: false, isDrop: false, dropList: [], myArt: [], })
                        } else {
                            this.setCount()
                            const bidIncrement = res && res.data.drops && res.data.drops[0] && res.data.drops[0].auction && res.data.drops[0].auction.minimumBidIncrement && res.data.drops[0].auction.minimumBidIncrement != null && res.data.drops[0].auction.minimumBidIncrement
                            const startingPrice = res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].auction && res.data.drops[0].auction.startPrice && res.data.drops[0].auction.startPrice != null && res.data.drops[0].auction.startPrice
                            const auctionDetails = res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].auction && res.data.drops[0].auction.auctionDetails
                            // console.log(startingPrice, bidIncrement)
                            if (auctionDetails && auctionDetails.length > 0) {
                                var final_am = Math.round(((auctionDetails && auctionDetails[0] && auctionDetails[0].bidAmount) + parseFloat(bidIncrement)) * 1e12) / 1e12
                                // console.log(auctionDetails && auctionDetails[0] && auctionDetails[0].bidAmount, bidIncrement, final_am)
                            }
                            else {
                                var final_am = Math.round((Number(startingPrice) + (bidIncrement)) * 1e12) / 1e12

                            }
                            this.fetchArt(res && res.data && res.data.imageHash)

                            // this.startTime()
                            this.setState({ showAuction: true, isMyAuction: false, bidList: res && res.data, isLoader: true, itsMine: false, isDrop: false, dropList: [], myArt: [], bidAmount: ((final_am)) })
                        }
                    } else {
                        if (res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].id != null) {
                            if ((res && res.data && res.data.isOwner)) {
                                myArt = res && res.data

                                let tokenId = myArt && myArt.tokenId
                                let arr = (myArt && myArt.dropsCount);
                                arr = arr && '[' + arr + ']'
                                let dropsCountarray = eval(arr && arr)
                                let myDropArray = []
                                dropsCountarray && dropsCountarray.map((items) => {
                                    if ((items && items.tokens && items.tokens.includes(tokenId))) {
                                        myDropArray = items

                                    }
                                })
                                let tokensArray = eval('[' + (myDropArray && myDropArray.tokens) + ']')

                                let totalOwnerArt = myArt && myArt.totalOwnerArt
                                this.fetchArt(res && res.data && res.data.imageHash)

                                this.setState({ myArt, itsMine: true, totalOwnerArt, dropList: [], artList: [], dropsCount: myDropArray, tokensArray, myAuctionList: [], bidList: [], showAuction: false, isMyAuction: false })
                            }

                            else {

                                dropList = res && res.data

                                let tokenId = dropList && dropList.tokenId
                                let arr = (dropList && dropList.dropsCount);
                                arr = arr && '[' + arr + ']'
                                let dropsCountarray = arr ? eval(arr && arr) : []
                                let myDropArray = []
                                let remainArray = []
                                let count = []
                                let total = 0
                                dropsCountarray && dropsCountarray.map((items) => {
                                    if ((items && items.tokens && items.tokens.includes(tokenId))) {
                                        myDropArray = items
                                    } else {
                                        remainArray.push(items)
                                    }
                                    if (items) {
                                        var len = (items && items.count)
                                        count.push(len)
                                    }
                                    function mySum(total, num) {
                                        return Number(total) + Number(num);
                                    }
                                    total = count.reduce(mySum)
                                })
                                let totalOwnerArt = dropList && dropList.totalOwnerArt
                                let tokensArray = eval('[' + (myDropArray && myDropArray.tokens) + ']')
                                // console.log("tokenArray", tokensArray, myDropArray)


                                markedPrice = res && res.data && res.data.drops && res.data.drops[0] && res.data.drops[0].markedPrice && res.data.drops[0].markedPrice.toFixed(8)


                                let creatorRoyalty = ""
                                let marketFee = ""



                                GetCreatorRoyality().then(res => {

                                    if ((res && res.status) == 200) {
                                        creatorRoyalty = res && res.data && res.data.Value

                                        CalculateShare(markedPrice, creatorRoyalty).then(res => {

                                            this.setState({ crt_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                        }).catch(err => { })
                                        this.setState({ creatorRoyalty: (res && res.data && res.data.Value ? res.data.Value : '') })
                                    }
                                }).catch(err => { })


                                GetMarketFee().then(res => {
                                    if ((res && res.status) == 200) {
                                        marketFee = res && res.data && res.data.Value
                                        CalculateShare(markedPrice, marketFee).then(res => {
                                            this.setState({ mark_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                        }).catch(err => { })
                                        this.setState({ marketFee: (res && res.data && res.data.Value ? res.data.Value : '') })
                                    }
                                }).catch(err => { })



                                CalculateShare(markedPrice, "85.0").then(res => {

                                    this.setState({ sell_share: res && res.data && res.data.Value && res.data.Value && res.data.Value.encodedData && res.data.Value.encodedData.value })
                                }).catch(err => { })

                                this.fetchArt(res && res.data && res.data.imageHash)


                                this.setState({ dropList, dropsCount: myDropArray, isDrop: true, myArt: [], artPrice: markedPrice, totalOwnerArt, tokensArray, totalDropArray: remainArray, totalDrop: total, isShowLoader: true, myAuctionList: [], bidList: [], showAuction: false, isMyAuction: false })
                            }
                            //         this.setState({ currentUserAddress: address })
                            //     })
                            //     .catch((error) => {
                            //         console.error('Unable to fetch User address', error);
                            //         this.setState({ currentUserAddress: null })
                            //     });


                        } else {

                            artList = res && res.data
                            let arr = (artList && artList.dropsCount)
                            arr = arr && '[' + arr + ']'
                            let dropsCountarray = arr ? eval(arr && arr) : []
                            var count = []
                            var dropArrCount = 0;
                            var val = artList && artList.totalTokenId ? artList.totalTokenId : ''
                            var tokens = val.split(",").length
                            dropsCountarray && dropsCountarray.map((items) => {
                                if (items) {
                                    var len = (items && items.tokens).split(",").length
                                    count.push(len)
                                }
                                function mySum(total, num) {
                                    return total + num;
                                }
                                dropArrCount = count.reduce(mySum)
                            })

                            let totalEdition = tokens - (dropArrCount)
                            console.log(tokens, val, dropArrCount, totalEdition, dropsCountarray, count)

                            let totalOwnerArt = artList && artList.totalOwnerArt
                            // if(totalOwnerArt > 1){

                            this.setState({ artList, isDrop: false, dropList: [], myArt: [], itsMine: false, totalOwnerArt, dropsCount: dropsCountarray, totalEdition, isMySale: false, isShowLoader: true, myAuctionList: [], bidList: [], showAuction: false, isMyAuction: false })
                            // }else{
                            //     this.setState({ artList, isDrop: false, dropList: [], myArt: [], itsMine: false, totalOwnerArt,dropsCount:[], totalEdition : 1, isMySale: false })

                            // }

                        }

                    }
                    // else{
                    //     mySaleArt = res && res.data
                    //     this.setState({mySaleArt, isDrop: false,dropList: [], myArt: [],itsMine: false, artList:[],isMySale:true})
                    // }

                    // get the transaction history for the art
                    let artTxnHistoy = []

                    artServices.getArtTransactionHistory(tokenId)
                        .then((res) => {
                            transactions = res && res.data && res.data.addressDetails
                            artTxnHistoy = res && res.data && res.data.addressDetails && res.data.addressDetails.transactions
                            const art = res && res.data && res.data.addressDetails && res.data.addressDetails
                            let artCreator, artOwner = null;
                            if ((artTxnHistoy).length) {
                                if (art.creatorWalletAddress !== artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress) {
                                    artCreator = {
                                        id: art.creator.id,
                                        userAddress: art.creatorWalletAddress,
                                        userName: art.creator.userName,
                                        roleId: art.creator.roleId
                                    };
                                } else {
                                    artCreator = {
                                        id: artTxnHistoy[artTxnHistoy.length - 1].owner.id,
                                        userAddress: artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress,
                                        userName: artTxnHistoy[artTxnHistoy.length - 1].owner.userName,
                                        roleId: artTxnHistoy[artTxnHistoy.length - 1].owner.roleId
                                    };
                                }
                                artOwner = {
                                    id: artTxnHistoy[0].owner.id,
                                    userAddress: artTxnHistoy[0].ownerWalletAddress,
                                    userName: artTxnHistoy[0].owner.userName,
                                    roleId: artTxnHistoy[0].owner.roleId
                                };
                            }
                            this.setState({ artTxnHistoy, artCreator, artOwner, transactions });
                        })
                        .catch((err) => {
                            artTxnHistoy = [];
                            this.setState({ artTxnHistoy });
                            // toast.error("Failed to get transaction history", { position: toast.POSITION.BOTTOM_CENTER })
                        });

                    this.props.getRelativeArts(tokenId, userId)
                    this.showLoader()
                    NarketTokenCreator(userAddress, tokenId).then(res => {
                        this.hideLoader()
                        this.setState({ creator: res && res.data && res.data.Value && res.data.Value.value && res.data.Value.value.value })
                    })
                        .catch(err => {
                            this.hideLoader()
                        })

                    this.showLoader()
                    NarketTokenOwner(userAddress, tokenId).then(res => {
                        this.hideLoader()
                        this.setState({ owner: res && res.data && res.data.Value && res.data.Value.value && res.data.Value.value.value })
                    })
                        .catch(err => {
                            this.hideLoader()
                        })

                    this.showLoader()
                    MarketTokenPrice(userAddress, tokenId).then(res => {
                        this.hideLoader()
                        this.setState({ price: res && res.data && res.data.Value && res.data.Value.value })
                    })
                        .catch(err => {
                            this.hideLoader()
                        })
                    // this.setState({userAddress,tokenId})
                }
            }).catch(err => {
                this.hideLoader()
            })




        }

    }
    componentDidUpdate(props, state) {
        // if (state && state.isAlert) {
        //     this.resetTimeout()
        //     this.closeAlert()
        // }

        let nft = configPath.basename
        if (window.location.pathname.includes('nft')) {
            if ((nft + decodeURIComponent(props && props.location && props.location.pathname)) != decodeURIComponent(window.location.pathname)) {
                this.getDrops()
            }
        } else if (decodeURIComponent(props && props.location && props.location.pathname) != decodeURIComponent(window.location.pathname)) {
            this.getDrops()
        }

    }
    getDropById = (id) => {
        let userAddress = ""
        let tokenId = ""
        let dropList = []
        this.showLoader()
        dropServices.getDetailDropArt(id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) == 200) {
                userAddress = res && res.data && res.data.art && res.data.art.ownerUserAddress
                tokenId = res && res.data && res.data.tokenId
                dropList = res && res.data
            }
            this.props.getRelativeArts(tokenId, userAddress)
            this.setState({ dropList })

        }).catch(err => {
            this.hideLoader()
        })
    }
    static getDerivedStateFromProps(props, state) {
        let rate = ""
        if (((props && props.wallet && props.wallet.statusCode) == 200) && (state.iswallet)) {
            props.resetlinkWallet()
            window.location.reload()

        }
        props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
            if ((item && item.asset) == "USD") {
                rate = item.rate
            }
        })


        if (props && props.detailList || props.relativeArts) {

            return {
                detailList: props.detailList && props.detailList.data,
                relativeArts: props.relativeArts && props.relativeArts.data && props.relativeArts.data.entity ? props.relativeArts.data.entity : [],
                // dropList : props.dropList && props.dropList.data ? props.dropList.data : [],
                rate: rate

            }
        }

        // if((props && props.relativeArts && props.relativeArts.statusCode) == 200){
        //     props.resetRelativeArts()

        //     return {
        //         relativeArts: props.relativeArts && props.relativeArts.data && props.relativeArts.data.entity ? props.relativeArts.data.entity : [],
        //         // dropList : props.dropList && props.dropList.data ? props.dropList.data : [],


        //     }
        // }

        return null;
    }

    clickRelative = (items) => {

        history.push(`/art/${items && items.id}`)


    }

    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }


    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    getBalance = () => {
        getUserAddress().then(add => {
            if (add && add != null) {


                // fusdbalance(add).then(res => {

                //     if ((res && res.status) == 200) {
                //         this.setState({ balance: JSON.parse(res && res.data && res.data.Value) })
                //     }
                //     if ((res && res.status) == 400) {

                //         this.setState({ balance: "0.0" })

                //     }
                // }).catch(err => {

                // })
            }
        })
    }
    handleResale = (e) => {
        e.preventDefault()
        const { tokensArray, totalOwnerArt, count, myArt } = this.state
        let tokenIds = []
        const ownerWalletAddress = myArt && myArt.ownerWalletAddress
        // console.log("tokensArray", totalOwnerArt, tokensArray, count, ownerWalletAddress)

        let arrayLength = 0
        let checkCount = 0
        tokenIds.push(this.state.tokenId)

        if ((totalOwnerArt == 1) && (tokensArray && tokensArray.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((totalOwnerArt > 1) && (tokensArray && tokensArray.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.count
            arrayLength = tokensArray && tokensArray.length

        }
        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {
            let selectedTokens = (tokensArray && tokensArray.length > 0 && (totalOwnerArt > 1)) ? tokensArray.slice(0, checkCount) : tokenIds
            if ((checkWalletAddress(ownerWalletAddress, (this.state.currentUserAddress)))) {
                this.showLoader()
                walletServices.checkWallet(this.state.currentUserAddress).then(wallet => {
                    this.hideLoader()
                    if ((wallet && wallet.statusCode) == 200) {
                        // this.showLoader()
                        this.setState({ disableWithdraw: true })
                        this.setState({ isTransLoader: true })

                        marketSaleWithdrawn(selectedTokens).then(res => {
                            console.log("withdrawn res", res)
                            this.setState({ isTransLoader: false })
                            // this.hideLoader()
                            if ((res && res.status) == 200) {

                                if (res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                    // toast.error("Transaction failed", { position: toast.POSITION.BOTTOM_CENTER })
                                    this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                    // this.closeAlert()
                                    this.setState({ disableWithdraw: false })

                                } else {

                                    this.setState({ isAlert: true, message: 'Token Removed From Market', style: "success" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                    // this.closeAlert()
                                    // toast.success("Token Removed From Market", { position: toast.POSITION.BOTTOM_CENTER })
                                    history.push('/')
                                }
                            } else {
                                this.setState({ disableWithdraw: false })

                                let mes = (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2] && res.data.Error.split(':')[2].includes("Session expired")) ? ("Session Expired.Please reconnect your wallet account") : (res.data.Error.split(':')[2])
                                this.setState({ isAlert: true, message: mes, style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                                // this.closeAlert()
                                // toast.error("User rejected signature", { position: toast.POSITION.BOTTOM_CENTER })
                            }
                        }).catch(err => {
                            this.setState({ disableWithdraw: false })
                            this.setState({ isTransLoader: false })

                            // this.hideLoader()
                        })
                    }
                }).catch(err => {
                    this.hideLoader()
                    this.setState({ isAlert: true, message: err, style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    // this.closeAlert()
                    this.setState({ disableWithdraw: false })

                    // toast.error(err)
                })
            } else {
                this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger", count: '' })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
                // this.closeAlert()
                this.setState({ disableWithdraw: false })
            }
        } else {
            this.setState({ isAlert: true, message: 'Please enter valid count', style: "danger", count: '' })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            // this.closeAlert()
            this.setState({ disableWithdraw: false })

        }

    }

    priceChange = (e) => {
        var res = (e.target.value).split(".");

        if (e.target.value >= 0 && (this.digitValidate(e.target.value)) && (res.length == 1 || res[1].length < 3)) {
            const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

            this.setState({ changedPrice: e.target.value, usd: rate ? rate.toFixed(2) : '' })
        } else {
            this.setState({ isAlert: true, message: 'Please enter valid price', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            // this.closeAlert()
            // toast.error("Please enter valid price", { position: toast.POSITION.BOTTOM_CENTER })
        }
    }
    submitPrice = (e) => {
        e.preventDefault()
        const { tokensArray, totalOwnerArt, priceCount, myArt } = this.state
        const ownerWalletAddress = myArt && myArt.ownerWalletAddress
        let tokenIds = []

        console.log("tokensArray", totalOwnerArt, tokensArray, priceCount, ownerWalletAddress)

        let arrayLength = 0
        let checkCount = 0
        tokenIds.push(this.state.tokenId)

        if ((totalOwnerArt == 1) && (tokensArray && tokensArray.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((totalOwnerArt > 1) && (tokensArray && tokensArray.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.priceCount
            arrayLength = tokensArray && tokensArray.length

        }
        let basePrice = ''



        console.log("tokenIds", tokenIds, JSON.stringify(tokenIds))
        const num = (Number(this.state.changedPrice))
        // const precision = num.toPrecision(5)
        const precision = num.toFixed(8)

        // if (this.state.changedPrice && Number.isInteger(num)) {
        basePrice = precision
        // } else {
        //     basePrice = this.state.changedPrice
        // }




        if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {
            let selectedTokens = (tokensArray && tokensArray.length > 0 && (totalOwnerArt > 1)) ? tokensArray.slice(0, checkCount) : tokenIds

            if (basePrice && basePrice > 0) {
                if ((checkWalletAddress(ownerWalletAddress, (this.state.currentUserAddress)))) {
                    this.showLoader()
                    walletServices.checkWallet(this.state.currentUserAddress).then(wallet => {
                        this.hideLoader()
                        if ((wallet && wallet.statusCode) == 200) {
                            // this.showLoader()
                            this.setState({ isTransLoader: true })

                            changePrice(selectedTokens, basePrice).then(res => {
                                console.log("price change", res)
                                this.setState({ isTransLoader: false })

                                // this.hideLoader()
                                if ((res && res.status) == 200) {
                                    if (res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                        this.setState({ changedPrice: '', usd: '', count: '' })
                                        this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                        // this.closeAlert()
                                        // toast.error("Transaction Failed", { position: toast.POSITION.BOTTOM_CENTER })
                                    } else {
                                        // toast.success("Price Changed",{position:toast.POSITION.BOTTOM_CENTER})
                                        // history.goBack()
                                        this.setState({ price: this.state.changedPrice, changedPrice: '', usd: '', count: '', isPriceChange: true })
                                    }
                                } else {
                                    let mes = (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2] && res.data.Error.split(':')[2].includes("Session expired")) ? ("Session Expired.Please reconnect your wallet account") : (res.data.Error.split(':')[2])

                                    this.setState({ changedPrice: '', usd: '', count: '' })
                                    this.setState({ isAlert: true, message: mes, style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                    // this.closeAlert()
                                    // toast.error("User rejected signature", { position: toast.POSITION.BOTTOM_CENTER })
                                }
                            }).catch(err => {
                                // this.hideLoader()
                                this.setState({ isTransLoader: false })

                            })
                        }
                    }).catch(err => {
                        this.hideLoader()
                        this.setState({ isAlert: true, message: err, style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        // this.closeAlert()
                        // toast.error(err)
                    })
                } else {
                    this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }

            } else {
                this.setState({ isAlert: true, message: 'Please enter valid price', style: "danger" })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
                // this.closeAlert()
                // toast.error("Please enter valid price", { position: toast.POSITION.BOTTOM_CENTER })
            }
        } else {
            this.setState({ isAlert: true, message: 'Please enter valid count', style: "danger", count: '' })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            // this.closeAlert()
        }
    }



    handlePurchase = () => {
        const { dropList, creatorRoyalty, marketFee, crt_share, mark_share, sell_share, artPrice, balance, tokensArray, totalOwnerArt, purchaseCount, showAuction } = this.state

        const markedPrice = dropList && dropList.markedPrice ? dropList.markedPrice : ''

        const creatorAddress = dropList && dropList.art && dropList.art.creator && dropList.art.creator.userAddress ? dropList.art.creator.userAddress : ''

        // const sellerAddress = dropList && dropList.owner && dropList.owner.userAddress ? dropList.owner.userAddress : ''
        const sellerAddress = dropList && dropList.ownerWalletAddress ? dropList.ownerWalletAddress : ''

        const tokenId = dropList && dropList.tokenId



        console.log("shares", crt_share, mark_share, sell_share, artPrice, balance, purchaseCount, tokensArray, totalOwnerArt)

        var marketShare = ""
        var creatorShare = ""
        var c_share = ""
        var sellerShare = ""
        var Share = ""
        var transactionId = ""
        var transactionDetail = []

        if (creatorAddress == sellerAddress) {
            Share = (Number(creatorRoyalty) + Number(marketFee))
            c_share = 0


        } else {
            Share = marketFee
            c_share = creatorRoyalty

        }
        const remainShare = (100 - (Number(creatorRoyalty) + Number(marketFee)))

        const sellerPrice = ((remainShare / 100) * markedPrice)
        sellerShare = Number.isInteger(sellerPrice) ? (sellerPrice.toFixed(8)) : sellerPrice

        const creatorPrice = ((creatorRoyalty / 100) * markedPrice)
        creatorShare = Number.isInteger(creatorPrice) ? (creatorPrice.toFixed(8)) : creatorPrice
        // creatorShare = creatorPrice



        const marketPrice = ((marketFee / 100) * markedPrice)
        marketShare = Number.isInteger(marketPrice) ? (marketPrice.toFixed(8)) : marketPrice


        console.log("MarkedPrice", markedPrice,
            "SellerPrice", sell_share, "CreatorPrice", crt_share, "MarketPrice", mark_share, this.state.creatorRoyalty, this.state.marketFee, remainShare)


        // this.closePurchase()

        const tokens = []


        let arrayLength = 0
        let checkCount = 0
        tokens.push(tokenId)

        if ((totalOwnerArt == 1) && (tokensArray && tokensArray.length <= 1)) {
            arrayLength = 1
            checkCount = 1
        } else if ((totalOwnerArt > 1) && (tokensArray && tokensArray.length == 1)) {

            arrayLength = 1
            checkCount = 1
        } else {
            checkCount = this.state.purchaseCount
            arrayLength = tokensArray && tokensArray.length

        }
        this.showLoader()
        RetrieveTokens(this.state.currentUserAddress).then(act => {
            this.hideLoader()
            if (act && act.status == 200) {
                console.log("ret", act)

                if ((balance != null && parseFloat(balance)) > (artPrice != null && parseFloat(artPrice))) {
                    if (checkCount && (checkCount > 0) && (checkCount <= arrayLength)) {

                        let selectedTokens = (tokensArray && tokensArray.length > 0 && (totalOwnerArt > 1)) ? tokensArray.slice(0, checkCount) : tokens
                        const tokenArray = selectedTokens
                        const TotalPrice = artPrice && artPrice != null && parseFloat(artPrice * checkCount).toFixed(8)
                        console.log("artPrice balance", artPrice, TotalPrice, checkCount)


                        if ((balance != null && parseFloat(balance)) > parseFloat(TotalPrice)) {
                            this.setState({ disablePurchase: true })
                            this.showLoader()

                            setTimeout(() => {

                                this.hideLoader()
                                if (sellerAddress && artPrice) {
                                    let wallet = localStorage.getItem("walletDetail") ? localStorage.getItem("walletDetail") : []
                                    let walletDetail = wallet && JSON.parse(wallet)
                                    {
                                        walletDetail && walletDetail != null && walletDetail.result && walletDetail.result != null && walletDetail.result.WalletAddress && walletDetail.result.WalletAddress != null && this.showLoader()
                                        walletServices.checkWallet(walletDetail.result.WalletAddress).then(wallet => {
                                            this.hideLoader()
                                            if ((wallet && wallet.statusCode) == 200) {
                                                // this.showLoader()
                                                this.setState({ isTransLoader: true })
                                                marketBuyTokenMultiple(sellerAddress, tokenArray, TotalPrice).then(res => {
                                                    // marketBuyToken(sellerAddress, tokenId, sell_share, mark_share, crt_share).then(res => {
                                                    // marketBuyToken(sellerAddress, tokenId, artPrice).then(res => {
                                                    this.setState({ isTransLoader: false })

                                                    // this.hideLoader()
                                                    if ((res && res.status) == 200) {
                                                        if ((res && res.data && res.data.transaction && res.data.transaction.errorMessage)) {
                                                            if ((res.data.transaction.errorMessage) && (res.data.transaction.errorMessage.includes("Amount withdrawn must be less than or equal than the balance of the Vault"))) {
                                                                this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"blue",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
                                                                this.gotoTop()
                                                                this.resetTimeout()
                                                                this.closeAlert()
                                                                // this.closeAlert()
                                                            } else {
                                                                this.setState({ disablePurchase: false })

                                                                this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                                this.gotoTop()
                                                                this.resetTimeout()
                                                                this.closeAlert()

                                                                // this.closeAlert()
                                                            }
                                                            // toast.error("Transaction Failed", { position: toast.POSITION.BOTTOM_CENTER })
                                                        } else {
                                                            this.setState({ isSuccess: true })
                                                            // toast.success("Your Transaction Succeed",{position:toast.POSITION.BOTTOM_CENTER})
                                                            transactionId = res && res.data && res.data.Value ? res.data.Value : ''
                                                            transactionDetail = res && res.data && res.data.transaction && res.data.transaction.events ? res.data.transaction.events : []
                                                        }
                                                    } else {
                                                        let mes = (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2] && res.data.Error.split(':')[2].includes("Session expired")) ? ("Session Expired.Please reconnect your wallet account") : (res.data.Error.split(':')[2])

                                                        this.setState({ isAlert: true, message: mes, style: "danger" })
                                                        this.gotoTop()
                                                        this.resetTimeout()
                                                        this.closeAlert()
                                                        // this.closeAlert()
                                                        this.setState({ disablePurchase: false })

                                                        // toast.error("User rejected signature", { position: toast.POSITION.BOTTOM_CENTER })
                                                    }
                                                    console.log("buy", res, transactionId, transactionDetail)
                                                }).catch(err => {
                                                    this.setState({ disablePurchase: false })
                                                    this.setState({ isTransLoader: false })

                                                    // this.hideLoader()
                                                })
                                            }
                                        }).catch(err => {
                                            this.setState({ disablePurchase: false })
                                            this.hideLoader()
                                            this.setState({ isAlert: true, message: err, style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                            // this.closeAlert()
                                            // toast.error(err)
                                        })
                                    }
                                } else {
                                    this.setState({ disablePurchase: false })

                                    this.setState({ isAlert: true, message: 'Fields are missing', style: "danger" })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                    // this.closeAlert()
                                    // toast.error("Fields are missing", { position: toast.POSITION.BOTTOM_CENTER })
                                }
                            }, 6000)
                        } else {
                            this.setState({ disablePurchase: false })

                            this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"blue",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                            // this.closeAlert()
                        }
                    } else {
                        // this.openPurchase()
                        this.setState({ disablePurchase: false })

                        this.setState({ isAlert: true, message: 'Please enter valid count', style: "danger", purchaseCount: '' })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()

                        // this.closeAlert()
                    }
                }
                else {
                    this.setState({ disablePurchase: false })

                    this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"blue",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    // this.closeAlert()
                }
            } else {
                this.setState({ isActSetup: true })
                createSetUp().then(res => {
                    this.setState({ isActSetup: false })
                    if (res && res.status == 200) {
                        if (res.data.transaction && res.data.transaction.errorMessage) {
                            this.setState({ isLoading: false, isMinting: false, isUpload: false })
                            this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        } else {
                            this.handlePurchase()
                            this.setState({ isAlert: true, message: 'Successfully setup your account.Now you can purchase your NFT', style: "success" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    }
                    else {
                        this.setState({ disableProceed: false })
                        console.log("setup err", res)

                        //  this.setState({ isLoading: false, isMinting: false, processCancel: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]) })

                        this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]), style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }

                    console.log("res", res)
                }).catch(err => {
                    this.setState({ isActSetup: false })
                    console.log("err", err)
                    this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                })
            }
        }).catch(err => {
            this.hideLoader()
            console.log("ret errr", err)
        })




    }
    checkBalance = () => {
        this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"blue",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

        // this.closeAlert()
    }
    closePurchase = () => {
        this.setState({ isPurchase: false, purchaseCount: '' })
    }
    openPurchase = () => {
        const { artPrice, balance } = this.state
        if ((balance != null && parseFloat(balance)) > (artPrice != null && parseFloat(artPrice))) {

            this.setState({ isPurchase: true })
        } else {
            this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"blue",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
            // this.closeAlert()
        }

    }
    getArtOwner() {
        const { artOwner, dropList, artList, bidList, myAuctionList } = this.state;
        if (artOwner) {
            if (artOwner.userName) {
                if (artOwner.id != null && (artOwner.roleId == 1)) {
                    // Return user name when it is not null.
                    return (<Link to={{ pathname: `/creator/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`, state: { obj: { userName: artOwner && artOwner.userName } } }} >{((artOwner.userName && artOwner.userName.includes('@')) ? artOwner.userName : (`@${artOwner.userName}`))}</Link>);
                } return <Link to={`/collector/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`}>{((artOwner.userName && artOwner.userName.includes('@')) ? artOwner.userName : (`@${artOwner.userName}`))}</Link>
            } else {
                if (artOwner.id != null && (artOwner.roleId == 1)) {
                    // return user address when user name is null. 
                    return (<Link to={`/creator/${artOwner.id}`}>{artOwner.userAddress}</Link>);
                }
                return (<Link to={`/collector/@${artOwner.userName && encodeURIComponent(artOwner.userName)}`}>{artOwner.userAddress}</Link>);
            }
        } else {

            // Use droplist data when artOwner is not available


            // return user name when it is not null
            if (dropList && dropList.owner && dropList.owner.userName) {
                if (dropList.owner.id != null && (dropList && dropList.owner && dropList.owner.roleId == 1)) {


                    // Return Link to profile if droplist has owner id.
                    return (<Link to={{ pathname: `/creator/@${dropList.owner.userName && encodeURIComponent(dropList.owner.userName)}`, state: { obj: { userName: dropList.owner.userName } } }}>{((dropList.owner.userName && dropList.owner.userName.includes('@')) ? dropList.owner.userName : (`@${dropList.owner.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${dropList.owner.userName && encodeURIComponent(dropList.owner.userName)}`, state: { obj: { userName: dropList.owner.userName } } }}>{((dropList.owner.userName && dropList.owner.userName.includes('@')) ? dropList.owner.userName : (`@${dropList.owner.userName}`))}</Link>);
            } else if (dropList && dropList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (dropList.owner && dropList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${dropList.owner.id}`}>{dropList.ownerWalletAddress}</Link>);
                    return dropList.ownerWalletAddress

                }

                // return user address if owner id is not present.
                return dropList.ownerWalletAddress;
            } else if (artList && artList.owner && artList.owner.userName) {
                if (artList.owner.id != null && (artList && artList.owner && artList.owner.roleId == 1)) {


                    // Return Link to profile if droplist has owner id.
                    return (<Link to={{ pathname: `/creator/@${artList.owner.userName && encodeURIComponent(artList.owner.userName)}`, state: { obj: { userName: artList.owner.userName } } }}>{((artList.owner.userName && artList.owner.userName.includes('@')) ? artList.owner.userName : (`@${artList.owner.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${artList.owner.userName && encodeURIComponent(artList.owner.userName)}`, state: { obj: { userName: artList.owner.userName } } }}>{((artList.owner.userName && artList.owner.userName.includes('@')) ? artList.owner.userName : (`@${artList.owner.userName}`))}</Link>);
            } else if (artList && artList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (artList.owner && artList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${artList.owner.id}`}>{artList.ownerWalletAddress}</Link>);
                    return artList.ownerWalletAddress;

                }

                // return user address if owner id is not present.
                return artList.ownerWalletAddress;
            } else if (bidList && bidList.owner && bidList.owner.userName) {
                if (bidList.owner.id != null && (bidList && bidList.owner && bidList.owner.roleId == 1)) {


                    // Return Link to profile if droplist has owner id.
                    return (<Link to={{ pathname: `/creator/@${bidList.owner.userName && encodeURIComponent(bidList.owner.userName)}`, state: { obj: { userName: bidList.owner.userName } } }}>{((bidList.owner.userName && bidList.owner.userName.includes('@')) ? bidList.owner.userName : (`@${bidList.owner.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${bidList.owner.userName && encodeURIComponent(bidList.owner.userName)}`, state: { obj: { userName: bidList.owner.userName } } }}>{((bidList.owner.userName && bidList.owner.userName.includes('@')) ? bidList.owner.userName : (`@${bidList.owner.userName}`))}</Link>);
            } else if (bidList && bidList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (bidList.owner && bidList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${dropList.owner.id}`}>{dropList.ownerWalletAddress}</Link>);
                    return bidList.ownerWalletAddress

                }

                // return user address if owner id is not present.
                return bidList.ownerWalletAddress;
            } else if (myAuctionList && myAuctionList.owner && myAuctionList.owner.userName) {
                if (myAuctionList.owner.id != null && (myAuctionList && myAuctionList.owner && myAuctionList.owner.roleId == 1)) {


                    // Return Link to profile if droplist has owner id.
                    return (<Link to={{ pathname: `/creator/@${myAuctionList.owner.userName && encodeURIComponent(myAuctionList.owner.userName)}`, state: { obj: { userName: myAuctionList.owner.userName } } }}>{((myAuctionList.owner.userName && myAuctionList.owner.userName.includes('@')) ? myAuctionList.owner.userName : (`@${myAuctionList.owner.userName}`))}</Link>);
                }

                // return user address if owner id is not present.
                return (<Link to={{ pathname: `/collector/@${myAuctionList.owner.userName && encodeURIComponent(myAuctionList.owner.userName)}`, state: { obj: { userName: myAuctionList.owner.userName } } }}>{((myAuctionList.owner.userName && myAuctionList.owner.userName.includes('@')) ? myAuctionList.owner.userName : (`@${myAuctionList.owner.userName}`))}</Link>);
            } else if (myAuctionList && myAuctionList.ownerWalletAddress) {

                // Return user address when owner's user name is null.
                if (myAuctionList.owner && myAuctionList.owner.id != null) {

                    // Return Link to profile if droplist has owner id
                    // return (<Link to={`/creator/${dropList.owner.id}`}>{dropList.ownerWalletAddress}</Link>);
                    return myAuctionList.ownerWalletAddress

                }

                // return user address if owner id is not present.
                return myAuctionList.ownerWalletAddress;
            }
            else {
                return 'N/A'
            }
        }
    }

    getArtCreator() {
        const { artCreator, dropList, artList, bidList, myAuctionList } = this.state;
        if (artCreator) {
            if (artCreator.userName) {
                if (artCreator.id != null && (artCreator.roleId == 1)) {
                    // Return user name when it is not null.
                    return (<Link to={{ pathname: `/creator/@${artCreator.userName && encodeURIComponent(artCreator.userName)}`, state: { obj: { userName: artCreator && artCreator.userName } } }}>{(artCreator.userName && artCreator.userName.includes('@') ? artCreator.userName : (`@${artCreator.userName}`))}</Link>);
                }
                return (<Link to={`/collector/@${artCreator.userName && encodeURIComponent(artCreator.userName)}`}>{(artCreator.userName && artCreator.userName.includes('@') ? artCreator.userName : (`@${artCreator.userName}`))}</Link>);
            } else {
                if (artCreator.id != null && (artCreator.roleId == 1)) {
                    // return user address when user name is null. 
                    return (<Link to={`/creator/${artCreator.id}`}>{artCreator.userAddress}</Link>);
                }
                return (<Link to={`/collector/${artCreator.id}`}>{artCreator.userAddress}</Link>);


                // return user address when user name is null. 
            }
        } else {

            // Use droplist data when artCreator is not available


            // return user name when it is not null
            if (dropList && dropList.creator && dropList.creator.userName) {
                if (dropList.creator.id && dropList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${dropList.creator.userName && encodeURIComponent(dropList.creator.userName)}`, state: { obj: { userName: dropList.creator.userName } } }}>{((dropList.creator.userName && dropList.creator.userName.includes('@')) ? dropList.creator.userName : (`@${dropList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${dropList.creator.userName && encodeURIComponent(dropList.creator.userName)}`}>{((dropList.creator.userName && dropList.creator.userName.includes('@')) ? dropList.creator.userName : (`@${dropList.creator.userName}`))}</Link>);
            } else if (dropList && dropList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (dropList.creator && dropList.creator.id && dropList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return dropList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return dropList.creatorWalletAddress;

            } else if (artList && artList.creator && artList.creator.userName) {
                if (artList.creator.id && artList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${artList.creator.userName && encodeURIComponent(artList.creator.userName)}`, state: { obj: { userName: artList.creator.userName } } }}>{((artList.creator.userName && artList.creator.userName.includes('@')) ? artList.creator.userName : (`@${artList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${artList.creator.userName && encodeURIComponent(artList.creator.userName)}`}>{((artList.creator.userName && artList.creator.userName.includes('@')) ? artList.creator.userName : (`@${artList.creator.userName}`))}</Link>);
            }
            else if (artList && artList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (artList.creator && artList.creator.id && artList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${artList.creator.id}`}>{artList.creatorWalletAddress}</Link>);
                    return artList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${artList.creator.id}`}>{artList.creatorWalletAddress}</Link>);
                return artList.creatorWalletAddress;

            } else if (bidList && bidList.creator && bidList.creator.userName) {
                if (bidList.creator.id && bidList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${bidList.creator.userName && encodeURIComponent(bidList.creator.userName)}`, state: { obj: { userName: bidList.creator.userName } } }}>{((bidList.creator.userName && bidList.creator.userName.includes('@')) ? bidList.creator.userName : (`@${bidList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${bidList.creator.userName && encodeURIComponent(bidList.creator.userName)}`}>{((bidList.creator.userName && bidList.creator.userName.includes('@')) ? bidList.creator.userName : (`@${bidList.creator.userName}`))}</Link>);
            } else if (bidList && bidList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (bidList.creator && bidList.creator.id && bidList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return bidList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return bidList.creatorWalletAddress;

            } else if (myAuctionList && myAuctionList.creator && myAuctionList.creator.userName) {
                if (myAuctionList.creator.id && myAuctionList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id.
                    return (<Link to={{ pathname: `/creator/@${myAuctionList.creator.userName && encodeURIComponent(myAuctionList.creator.userName)}`, state: { obj: { userName: myAuctionList.creator.userName } } }}>{((myAuctionList.creator.userName && myAuctionList.creator.userName.includes('@')) ? myAuctionList.creator.userName : (`@${myAuctionList.creator.userName}`))}</Link>);
                }

                // return user address if creator id is not present.
                return (<Link to={`/collector/@${myAuctionList.creator.userName && encodeURIComponent(myAuctionList.creator.userName)}`}>{((myAuctionList.creator.userName && myAuctionList.creator.userName.includes('@')) ? myAuctionList.creator.userName : (`@${myAuctionList.creator.userName}`))}</Link>);
            } else if (myAuctionList && myAuctionList.creatorWalletAddress) {

                // Return user address when creator's user name is null.
                if (myAuctionList.creator && myAuctionList.creator.id && myAuctionList.creator.roleId == 1) {

                    // Return Link to profile if droplist has creator id
                    // return (<Link to={`/creator/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                    return myAuctionList.creatorWalletAddress;

                }

                // return user address if creator id is not present.
                // return (<Link to={`/collector/${dropList.creator.id}`}>{dropList.creatorWalletAddress}</Link>);
                return myAuctionList.creatorWalletAddress;

            }
            else {
                return 'N/A'
            }
        }
    }

    getArtTransactions() {
        const { artTxnHistoy } = this.state;

        const txnHistory = transactions && transactions.transactions
        if ((txnHistory && txnHistory.length)) {
            const txnRows = (txnHistory).map((item, index) => {
                if (index !== ((txnHistory.length) - 1)) {

                    // return user name when it is not null
                    if ((item && item.owner && item.owner.userName)) {
                        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                            return (
                                <div className="row mb-3 align-items-center">
                                    <div className="col-md-6"><h5 className="mb-0">Purchased by <Link to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></h5></div>
                                    <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                                </div>
                            )
                        }
                        return (
                            <div className="row mb-3 align-items-center">
                                <div className="col-md-6"><h5 className="mb-0">Purchased by <Link to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@') ? item.owner.userName : (`@${item.owner.userName}`)))}</Link></h5></div>
                                <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                            </div>
                        )
                    }
                    if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                        return (
                            <div className="row mb-3 align-items-center">
                                {/* <div className="col-sm-6">Purchased by <Link to={`/creator/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                                <div className="col-md-6"><h5 className="mb-0">Purchased by {item.ownerWalletAddress}</h5></div>
                                <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                            </div>
                        )
                    }
                    return (
                        <div className="row mb-3 align-items-center">
                            {/* <div className="col-sm-6">Purchased by <Link to={`/collector/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                            <div className="col-md-6"><h5 className="mb-0">Purchased by {item.ownerWalletAddress}</h5></div>
                            <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                        </div>
                    )
                } else {

                    // return user name when it is not null
                    if ((item && item.owner && item.owner.userName)) {
                        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                            return (
                                <div className="row mb-3 align-items-center">
                                    <div className="col-md-6"><h5 className="mb-0">Minted by <Link to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></h5></div>
                                    <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                                </div>
                            )
                        }
                        return (
                            <div className="row mb-3 align-items-center">
                                <div className="col-md-6"><h5 className="mb-0">Minted by  <Link to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></h5></div>
                                <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                            </div>
                        )
                    }
                    if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                        return (
                            <div className="row mb-3 align-items-center">
                                {/* <div className="col-sm-6">Minted by <Link to={`/creator/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                                <div className="col-md-6"><h5 className="mb-0">Minted by {item.ownerWalletAddress}</h5></div>
                                <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                            </div>
                        )
                    }
                    return (
                        <div className="row mb-3 align-items-center">
                            {/* <div className="col-sm-6">Minted by <Link to={`/collector/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                            <div className="col-md-6"><h5 className="mb-0">Minted by {item.ownerWalletAddress}</h5></div>
                            <div className="col-md-6"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                        </div>
                    )
                }
            });
            return (
               
                <div className='w-100 d-block py-3'>
                {txnRows}
            </div>

            );
        } else {
            return (
                <p className="text-center">
                    <i className="fa fa-info-circle"></i>&nbsp;No Activity data available.<br />
                    <img src={`../${configPath.imageAssets}/no-images.png`} />
                </p>
            )
        }
    }
    handleLogin = () => {

        fcl.logIn().then(res => {
            let provider = res && res.services && res.services && res.services[0] && res.services[0].provider && res.services[0].provider.name
            if (res && res.addr) {
                let obj = {
                    walletAddress: res && res.addr,
                    walletProvider: provider
                }

                this.props.linkWallet(JSON.stringify(obj))
                this.setState({ iswallet: true })
            }
        }).catch(err => {
        })
        // this.setState({openLogin:true})
    }
    closeModal = () => {
        this.setState({ openLogin: false })
        // history.goBack()
    }
    handlePriceCount = (e) => {
        const { priceCount, tokensArray } = this.state
        if ((e.target.value > 0) && (e.target.value) <= (Number(tokensArray && tokensArray.length)) && ((e.target.value) % 1 == 0)) {
            this.setState({ priceCount: e.target.value }, () => {
                console.log("priceCount", this.state.priceCount)
            })
        } else {
            this.setState({ priceCount: '' })
        }
    }
    handleCount = (e) => {
        const { tokensArray } = this.state
        if ((e.target.value > 0) && (e.target.value) <= (Number(tokensArray && tokensArray.length)) && ((e.target.value) % 1 == 0)) {
            this.setState({ count: e.target.value }, () => {
                // console.log("count",this.state.count)
            })
        } else {
            this.setState({ count: '' })
        }
    }
    handlePurchaseCount = (e) => {
        const { tokensArray } = this.state
        if ((e.target.value > 0) && ((e.target.value) <= Number((tokensArray && tokensArray.length))) && ((e.target.value) % 1 == 0)) {
            this.setState({ purchaseCount: e.target.value }, () => {
                console.log("purcount", this.state.purchaseCount)
            })
        } else {
            this.setState({ purchaseCount: '' })
        }

    }
    enablePurchase = (value) => {
        const { currentUserAddress, userAddress } = this.state
        if (value) {
            this.setState({ openLogin: true })
        } else {
            this.setState({ openLogin: false })

            // this.props.getUSD()
            this.handlePurchase()
            // this.showLoader()
            // getUserAddress().then(res => {
            //     this.hideLoader()
            //     if(res == userAddress){
            //         history.push('/')
            //     }else{
            //         this.handlePurchase()
            //     }
            // }).catch(err => {
            //     this.hideLoader()
            // })




        }
    }
    digitValidate = (s) => {
        var n = Number(s)

        if (n > 0 && n <= 999.99) {
            if (!s.match(/\d{1,3}\.\d{3}/)) {

                return true
            }
        } else {

            return true
        }
        return false
    }
    handleBidPrice = (e) => {
        function validate(s) {
            var n = Number(s)

            if (n > 0 && n <= 999.99) {
                if (!s.match(/\d{1,3}\.\d{3}/)) {

                    return true
                }
            } else {

                return true
            }
            return false
        }
        //   validate(e.target.value)
        console.log(validate(e.target.value), "validate")
        var res = (e.target.value).split(".");
        console.log(res, "res")
        if ((e.target.value >= 0) && (res.length == 1 || res[1].length < 3)) {

            if (validate(e.target.value)) {

                var num = Number(e.target.value).toFixed(8)
                this.setState({
                    bidAmount: e.target.value, bidAmounting: num
                })
            }
            // }
        }
    }
    handleCancelBid = () => {
        const { myAuctionList } = this.state
        const ownerWalletAddress = myAuctionList && myAuctionList.ownerWalletAddress
        const auctionId = myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.id && myAuctionList.drops[0].auction.id != null && myAuctionList.drops[0].auction.id
        getUserAddress().then(res => {
            if ((checkWalletAddress(ownerWalletAddress, (this.state.currentUserAddress)))) {
                if (res != null) {
                    this.showLoader()
                    walletServices.checkWallet(res).then(wallet => {
                        this.hideLoader()
                        if ((wallet && wallet.statusCode) == 200) {
                            this.setState({ isTransLoader: true })
                            dropServices.saveEvent(myAuctionList.tokenGroupId, 1, dropServices.DropEventType.AUCTION_CANCELLED, myAuctionList.tokenId)
                                .then((evtRes) => {
                                    if (evtRes === true) {
                                        cancelFlowAuction(auctionId).then(res => {
                                            this.setState({ isTransLoader: false })
                                            if ((res && res.status == 200)) {
                                                if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                    this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                    this.gotoTop()
                                                    this.resetTimeout()
                                                    this.closeAlert()
                                                    this.setState({ disableList: false })

                                                } else {

                                                    this.setState({ isCancelBid: true })
                                                    this.setState({ disableList: false })

                                                }
                                            } else {
                                                let mes = '';
                                                if (
                                                    res &&
                                                    res.data &&
                                                    res.data.Error
                                                ) {
                                                    const splitError = res.data.Error.split(':');
                                                    if (
                                                        splitError[2] &&
                                                        splitError[2].includes("Session expired")
                                                    ) {
                                                        mes = "Session Expired.Please reconnect your wallet account";
                                                    } else if (
                                                        splitError[2]
                                                    ) {
                                                        mes = splitError[2]
                                                    } else if (splitError[1]) {
                                                        mes = splitError[1]
                                                    } else {
                                                        mes = splitError[0];
                                                    }
                                                }
                                                this.setState({ ShowPop: false, isAuction: false })
                                                this.setState({ disableList: false })

                                                this.setState({ isAlert: true, message: mes, style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()

                                            }
                                            console.log("cancel", res)
                                        }).catch(err => {
                                            this.setState({ isTransLoader: false })
                                            console.log("cancel err", err)
                                        })
                                    }
                                    else {
                                        this.setState({ isAlert: true, message: evtRes, style: "danger" })
                                    }
                                }).catch(err => {
                                    this.setState({ isTransLoader: false })
                                    console.log("cancel err", err)
                                })
                        }
                    }).catch(err => {
                        this.setState({ isAlert: true, message: err, style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                        this.hideLoader()
                        this.setState({ disableList: false })

                    })
                }
            } else {
                this.setState({ disableList: false })

                this.setState({ isAlert: true, message: 'Please reconnect your wallet or change your wallet account', style: "danger" })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }
        }).catch(err => {
            this.setState({ disableList: false })

            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        })
    }
    handleSettlement = (items) => {
        const { bidAmount, currentUserAddress, settlement, myAuctionList, } = this.state
        const auctionId = items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && items.drops[0].auction.id
        const ownerAddress = items && items.ownerWalletAddress
        getUserAddress().then(res => {
            if (res != null) {
                this.showLoader()
                walletServices.checkWallet(res).then(wallet => {
                    this.hideLoader()
                    if ((wallet && wallet.statusCode) == 200) {
                        this.setState({ isTransLoader: true })
                        dropServices.saveEvent(items.tokenGroupId,1,dropServices.DropEventType.AUCTION_SETTLED, items.tokenId)
                        .then((evtRes)=>{
                            if (evtRes === true) {
                                settleFlowAuction(auctionId, ownerAddress).then(res => {
                                    this.setState({ isTransLoader: false })
                                    if ((res && res.status == 200)) {
                                        if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                            this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                            this.setState({ disableList: false })
                                        } else {
                                            this.setState({ isSettlementSuccess: true })
                                            this.setState({ disableList: false })
                                        }
                                    } else {
                                        let mes = '';
                                        if (
                                            res &&
                                            res.data &&
                                            res.data.Error
                                        ) {
                                            const splitError = res.data.Error.split(':');
                                            if (
                                                splitError[2] &&
                                                splitError[2].includes("Session expired")
                                            ) {
                                                mes = "Session Expired.Please reconnect your wallet account";
                                            } else if (
                                                splitError[2]
                                            ) {
                                                mes = splitError[2]
                                            } else if (splitError[1]) {
                                                mes = splitError[1]
                                            } else {
                                                mes = splitError[0];
                                            }
                                        }
                                        this.setState({ ShowPop: false, isAuction: false })
                                        this.setState({ disableList: false })

                                        this.setState({ isAlert: true, message: mes, style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()

                                    }
                                    console.log("settle obj", res)
                                }).catch(err => {
                                    this.setState({ isTransLoader: false })
                                    console.log("settle err", err)
                                })
                            } else {
                                this.setState({ isAlert: true, message: evtRes, style: "danger" })
                            }
                        }).catch(err => {
                            this.setState({ isTransLoader: false })
                            console.log("settle err", err)
                        })
                    }
                }).catch(err => {
                    this.setState({ isAlert: true, message: err, style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                    this.hideLoader()
                    this.setState({ disableList: false })

                })
            }
        }).catch(err => {
            this.setState({ disableList: false })

            this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        })
    }
    getTxnError(err) {
        let mes = '';
        const splitError = err.split(':');
        if (
            splitError[2] &&
            splitError[2].includes("Session expired")
        ) {
            mes = "Session Expired.Please reconnect your wallet account";
        } else if (
            splitError[2]
        ) {
            mes = splitError[2]
        } else if (splitError[1]) {
            mes = splitError[1]
        } else {
            mes = splitError[0];
        }
        return mes;
    }
    handlePlaceBid = () => {
        const { bidAmount, currentUserAddress, settlement, bidList, balance } = this.state
        const auctionId = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.id && bidList.drops[0].auction.id != null && bidList.drops[0].auction.id
        const bidIncrement = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.minimumBidIncrement && bidList.drops[0].auction.minimumBidIncrement != null && bidList.drops[0].auction.minimumBidIncrement
        const startingPrice = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice && bidList.drops[0].auction.startPrice != null && bidList.drops[0].auction.startPrice
        const endDay = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.endDay && bidList.drops[0].auction.endDay != null && bidList.drops[0].auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        const ownerAddress = bidList && bidList.ownerWalletAddress
        const auctionDetails = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails
        var countDownDate = new Date(futureDate).getTime();
        const today = new Date().getTime()
        console.log("auctionId", auctionId, bidList && bidList.drops, balance)
        if (today < (countDownDate)) {
            if (auctionDetails && auctionDetails.length > 0) {
                var set_am = Math.round(((auctionDetails && auctionDetails[0] && auctionDetails[0].bidAmount) + parseFloat(bidIncrement)) * 1e12) / 1e12

            }
            else {
                var set_am = Math.round((Number(startingPrice) + (bidIncrement)) * 1e12) / 1e12

            }

            if ((balance != null && parseFloat(balance)) > (set_am)) {

                if (bidAmount && bidAmount > 0) {
                    if ((parseFloat(bidAmount) >= set_am)) {
                        if ((balance != null && parseFloat(balance)) > parseFloat(bidAmount)) {
                            this.showLoader()
                            RetrieveTokens(this.state.currentUserAddress).then(act => {
                                this.hideLoader()
                                if (act && act.status == 200) {
                                    console.log("ret", act)
                                    getUserAddress().then(res => {
                                        if (res != null) {
                                            this.showLoader()
                                            walletServices.checkWallet(res).then(wallet => {
                                                this.hideLoader()
                                                if ((wallet && wallet.statusCode) == 200) {
                                                    // this.setState({ disableList: true })
                                                    this.setState({ isTransLoader: true })
                                                    dropServices.saveEvent(bidList.tokenGroupId, 1, dropServices.DropEventType.NEW_BID, bidList.tokenId).then((evtRes) => {
                                                        if (evtRes === true) {
                                                            placeBidOnFlowAuction((auctionId), ownerAddress, Number(parseFloat(bidAmount).toFixed(8))).then(res => {
                                                                this.setState({ isTransLoader: false })
                                                                console.log("bid", res)
                                                                if ((res && res.status == 200)) {
                                                                    console.log("move to auction", res)
                                                                    if (res && res.data && res.data.transaction && res.data.transaction.errorMessage) {
                                                                        this.setState({ isAlert: true, message: getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage), style: "danger" })
                                                                        this.gotoTop()
                                                                        this.resetTimeout()
                                                                        this.closeAlert()
                                                                        this.setState({ disableList: false })

                                                                    } else {

                                                                        this.setState({ isAuctionSuccess: true })
                                                                        this.setState({ disableList: false })

                                                                    }
                                                                }
                                                                else {
                                                                    let mes = '';
                                                                    if (
                                                                        res &&
                                                                        res.data &&
                                                                        res.data.Error
                                                                    ) {
                                                                        mes = this.getTxnError(res.data.Error)
                                                                    }
                                                                    this.setState({ ShowPop: false, isAuction: false })
                                                                    this.setState({ disableList: false })

                                                                    this.setState({ isAlert: true, message: mes, style: "danger" })
                                                                    this.gotoTop()
                                                                    this.resetTimeout()
                                                                    this.closeAlert()

                                                                }
                                                            }).catch(err => {
                                                                this.setState({ isTransLoader: false })

                                                                this.setState({ isAlert: true, message: err, style: "danger" })
                                                                this.gotoTop()
                                                                this.resetTimeout()
                                                                this.closeAlert()

                                                                console.log("bid err", err)
                                                            })
                                                        } else {
                                                            let mes = '';
                                                            if (
                                                                res &&
                                                                res.data &&
                                                                res.data.Error
                                                            ) {
                                                                mes = this.getTxnError(res.data.Error)
                                                            }
                                                            this.setState({ ShowPop: false, isAuction: false })
                                                            this.setState({ disableList: false })

                                                            this.setState({ isAlert: true, message: mes, style: "danger" })
                                                            this.gotoTop()
                                                            this.resetTimeout()
                                                            this.closeAlert()
                                                        }
                                                    }).catch(err => {
                                                        this.hideLoader()
                                                        this.setState({ isAlert: true, message: err, style: "danger" })
                                                        this.gotoTop()
                                                        this.resetTimeout()
                                                        this.closeAlert()
                                                        this.setState({ disableWithdraw: false })
                                                    })
                                                }
                                            }).catch(err => {
                                                this.setState({ isAlert: true, message: err, style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                                this.hideLoader()
                                                this.setState({ disableList: false })

                                            })
                                        }
                                    }).catch(err => {
                                        this.setState({ disableList: false })

                                        this.setState({ isAlert: true, message: 'Please reconnect your wallet', style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                    })
                                } else {
                                    this.setState({ isActSetup: true })
                                    createSetUp().then(res => {
                                        this.setState({ isActSetup: false })
                                        if (res && res.status == 200) {
                                            if (res.data.transaction && res.data.transaction.errorMessage) {
                                                this.setState({ isLoading: false, isMinting: false, isUpload: false })
                                                this.setState({ isAlert: true, message: 'Transaction Failed', style: "danger" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                            } else {
                                                this.handlePlaceBid()
                                                this.setState({ isAlert: true, message: 'Successfully setup your account.Now you can place NFT', style: "success" })
                                                this.gotoTop()
                                                this.resetTimeout()
                                                this.closeAlert()
                                            }
                                        }
                                        else {
                                            this.setState({ disableProceed: false })
                                            console.log("setup err", res)

                                            //  this.setState({ isLoading: false, isMinting: false, processCancel: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]) })

                                            this.setState({ isAlert: true, message: (res && res.data && res.data.Error && res.data.Error.split(':') && res.data.Error.split(':')[2]), style: "danger" })
                                            this.gotoTop()
                                            this.resetTimeout()
                                            this.closeAlert()
                                        }
                                        console.log("res", res)
                                    }).catch(err => {
                                        this.setState({ isActSetup: false })
                                        console.log("err", err)
                                        this.setState({ isAlert: true, message: 'Process failed', style: "danger" })
                                        this.gotoTop()
                                        this.resetTimeout()
                                        this.closeAlert()
                                    })
                                }
                            }).catch(err => {
                                this.hideLoader()
                            })


                        } else {
                            this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"#9E66BF",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
                            this.gotoTop()
                            this.resetTimeout()
                            this.closeAlert()
                        }
                    } else {
                        this.setState({ isAlert: true, message: `Bid Amount should be greater than or equal to ${set_am} FUSD`, style: "danger" })
                        this.gotoTop()
                        this.resetTimeout()
                        this.closeAlert()
                    }
                } else {
                    this.setState({ isAlert: true, message: "Please enter valid bid amount", style: "danger" })
                    this.gotoTop()
                    this.resetTimeout()
                    this.closeAlert()
                }
            } else {
                this.setState({ isAlert: true, message: <div>Insufficient funds in your flow wallet, Click here to <a onClick={() => {history.push('/fund-wallet')}} style={{color:"#9E66BF",cursor:"pointer"}}> add funds</a> into your wallet</div>, style: "danger" })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }

        } else {
            this.setState({ isAlert: true, message: "Bidding closed", style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }
    }
    goLogin = () => {
        history.push('/login')
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
        // this.closeAlert()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    startTime = (items, isRelative) => {
        const { bidList } = this.state
        const endDay = items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.endDay && items.drops[0].auction.endDay != null && items.drops[0].auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        // this.setState({distanceTime : distance})
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0 && !isRelative) {
            return false
        }
        // this.setState({seconds,days,hours,minutes})
        // setInterval(this.startTime(items), 1000);
        if (isRelative) {
            if (distance < 0) {
                return <div className="mt-1"><p className="mb-0  usernamelilac">Bidding Closed</p></div>
            }
            return <div className="mt-1"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + 'd' : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>
        } else {
            return <div className="counttimebox">
                <h2 className="mb-0 ">
                    {/* {(`${days ? days : ''} ${days ? (days > 1 ? "D" : "D") : ''}  ${hours}Hours ${minutes}Minutes ${seconds}Seconds`)}                         */}
                    {days ? (<span className="counttime">{days} {days && <span className="small usernamelilac">{(days && days == 1) ? "Day" : "Days"}</span>}</span>) : ''}
                    <span className="counttime">{hours} <span className="small usernamelilac">Hours</span></span>
                    <span className="counttime">{minutes} <span className="small usernamelilac">Minutes</span></span>
                    <span className="counttime">{seconds} <span className="small usernamelilac">Seconds</span></span>
                </h2>
            </div>
        }

    }


    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    checkLastDate = (items) => {

        const endDay = items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.endDay && items.drops[0].auction.endDay != null && items.drops[0].auction.endDay
        var futureDate = ((moment(endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;

        if (distance < 0) {
            return true
        }
        else {
            return false
        }
    }
    isCheckLeader = (bidList) => {
        const webUser = localStorage.getItem("webLogin")
        const webUserDetail = webUser && JSON.parse(webUser)
        const webUserId = webUserDetail && webUserDetail != null && webUserDetail.data && webUserDetail.data.authUser && webUserDetail.data.authUser.id && webUserDetail.data.authUser.id != null && webUserDetail.data.authUser.id
        const bidderId = bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidderId ? bidList.drops[0].auction.auctionDetails[0].bidderId : ''
        console.log(webUserDetail, "webUsr", webUserId, bidderId)

        if ((webUserId) == (bidderId)) {
            return true
        } else {
            return false
        }
    }
    openHistory = (e) => {

        e.preventDefault()

        this.setState({ isShowHistory: !this.state.isShowHistory })
    }
    fetchArt(url) {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        }).catch((err) => {

        })

    }
    getArtMetaData(){
        const {
            dropList,
            myAuctionList,
            myArt,
            itsMine,
            isDrop,
            isMyAuction,
            showAuction,
            artList,
            bidList
        } = this.state;
        const artMeta = {
            title: '...',
            creatorFullName: '...',
            creatorUserName: '...',
            description: '...'
        };
        if (dropList && isDrop && !itsMine && !isMyAuction && !showAuction) {
            artMeta.title = dropList.title;
            artMeta.creatorFullName = dropList.creator.fullName;
            artMeta.creatorUserName = dropList.creator.userName;
            artMeta.description = dropList.description;
        } else if (myArt && !isDrop && !isMyAuction && itsMine && !showAuction) {
            artMeta.title = myArt.title;
            artMeta.creatorFullName = myArt.creator.fullName;
            artMeta.creatorUserName = myArt.creator.userName;
            artMeta.description = myArt.description;
        } else if (artList && !itsMine && !isDrop && !showAuction && !isMyAuction) {
            artMeta.title = artList.title;
            artMeta.creatorFullName = artList.creator.fullName;
            artMeta.creatorUserName = artList.creator.userName;
            artMeta.description = artList.description;
        } else if (showAuction && !isMyAuction) {
            artMeta.title = bidList.title;
            artMeta.creatorFullName = bidList.creator.fullName;
            artMeta.creatorUserName = bidList.creator.userName;
            artMeta.description = bidList.description;
        } else if (isMyAuction && myAuctionList) {
            artMeta.title = myAuctionList.title;
            artMeta.creatorFullName = myAuctionList.creator.fullName;
            artMeta.creatorUserName = myAuctionList.creator.userName;
            artMeta.description = myAuctionList.description;
        }
        return artMeta;
    }
    render() {
        // if (this.state.isAlert) {
        //     this.resetTimeout()
        //     // setTimeout(() => {
        //     //     this.closeAlert()
        //     // },1000)
        // }


        const shareUrl = `${process.env.REACT_APP_BASEPATH}${window.location.pathname}`
        const currentURL = window.location.href;
        const { detailList, isShowLoader, myAuctionList, myArt, dropList, isLoader, showAuction, bidList, creatorRoyalty, artTxnHistoy, marketFee, relativeArray, currentUserAddress, relativeArts, artList, dropsCount, totalOwnerArt, totalEdition, tokensArray, artPrice, balance, priceCount, totalDropArray, totalDrop, auctionHistory } = this.state
        // console.log("dropscount", dropsCount, dropsCount && dropsCount.count, totalOwnerArt, totalDropArray, totalDrop)
        const ownerAddress = dropList && dropList.art && dropList.art.owner && dropList.art.owner.userAddress;
        const meta = this.getArtMetaData();        
        const metaTitle = `${meta.title} by ${meta.creatorFullName}(@${meta.creatorUserName}) NFT on ${process.env.REACT_APP_MARKETPLACE}`;
        console.log("auctionH", this.state.isShowHistory)
        return (
            <Fragment>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="title" content={metaTitle}></meta>
                    <meta name="description" content={meta.description}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

                {/* {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />} */}

                <Modal
                    isOpen={this.state.openLogin}
                    style={customStyles}
                    // className="Modal"
                    //   overlayClassName="Overlay"
                    contentLabel="Example Modal"
                >
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <SignIn enablePurchase={() => this.enablePurchase(false)} />
                </Modal>
                {this.state.isPriceChange &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={myArt && myArt.imageHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Price Changed Successfully...!</b></div>
                            <div className="mt-3">It may take 30 seconds for your changes to appear in drops</div>

                            <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drops</button></div></div>
                }
                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            {/* <div className="bwspinner"></div> */}
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                        </div>
                    </div>
                }
                <Modal
                    isOpen={this.state.isPurchase}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal">
                    <div className="custom-ui text-left p-5">
                        <button onClick={this.closePurchase} className="btn btn-primary px-2 clsbtn">X</button>
                        <h5 className="modal-title" id="exampleModalLongTitle">Enter the count <span className="small d-block">(No of tokens you want to purchase)</span></h5>
                        <div className="py-3">
                            {!this.state.purchaseCount && <div className="mandatory-req mt-2 text-center small">{`INFO : Count should be less than or eqaul to ${tokensArray && tokensArray.length}`}</div>}
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">Count</span>
                                <input type="number" value={this.state.purchaseCount} onChange={this.handlePurchaseCount} className="form-control w-75px" />
                            </div>
                        </div>
                        <div className="modal-footer1 text-center">
                            <button type="button" className="btn btn-secondary mr-2 sm-mr-0 mb-3" onClick={this.closePurchase}>Close</button>
                            <button type="button" className="btn btn-primary mb-3" onClick={this.handlePurchase}>Purchase</button>
                        </div>

                    </div>
                </Modal>

                {this.state.isTransLoader && <div className="show-content-D">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "15%", display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </div>}


                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={dropList && dropList.imageHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo! Art Purchased Successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your purchase to appear in your collections :) </div>

                            <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet') }}>View Purchased Art</button></div></div>
                }
                {this.state.isAuctionSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={bidList && bidList.imageHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo! Bid Placed Successfully!</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your art to appear in your collections :) </div> */}

                            <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/') }}>Close</button></div></div>
                }
                {this.state.isCancelBid &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={myAuctionList && myAuctionList.imageHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Bidding for this art cancelled successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your art to disappear in drops :) </div>

                            <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet') }}>Close</button></div></div>
                }
                {this.state.isSettlementSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={((myAuctionList && myAuctionList.imageHash) || (bidList && bidList.imageHash))} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Settlement process for this art was successfully!</b></div>
                            <div className="mt-3">It may take 30 seconds for your art settlement :) </div>

                            <div className="socialicons mt-3"><a href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                            {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet') }}>Close</button></div></div>
                }

                <div>
                    {/* <ToastContainer autoClose={3000}/> */}
                    {this.state.isDrop && !this.state.showAuction && <div>
                        {/* <div className="coverimg imgcovergif rounded-0">

                            {isShowLoader && <img src={dropList && dropList.owner && dropList.owner.bannerImage && dropList.owner.bannerImage != null ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${dropList && dropList && dropList.owner && dropList.owner.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt="" onClick={() => this.gotoCreator(dropList && dropList.owner)}></img>}

                        </div> */}

                        <div className="propagecont mb-5">

                            <div className="detasingimg ">
                                <div className="container">
                                    <span className="detaimg text-center">
                                        <ArtPreview src={dropList && dropList.imageHash ? dropList && dropList.imageHash : `${configPath.imageAssets}/art-3.jpg`} className="mw-100" showControls={true}  autoPlay={true} isDetail={true} />
                                    </span>
                                </div>
                            </div>

                            <div className="container">
                                <div className="detailcontpa">
                                    <div className="row">

                                        <div className="col-6">
                                            <div className="badgeview propicdetail">
                                                <div className="picpro">
                                                    <span className="artcovimg">
                                                        <span className="artcov "></span>
                                                        <span className="artcovimginset no-proimg ">
                                                            <img src={dropList && dropList.owner && dropList.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${dropList && dropList.owner && dropList.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(dropList && dropList.owner)} />
                                                            {/* <img src={'/nft/assets/images/karla-ferguson.jpg'} alt="" /> */}
                                                        </span>
                                                    </span>

                                                </div>
                                                {/* <h2 className="text-center mb-0 py-3 d-flex align-items-center justify-content-center">Karla Ferguson</h2> */}
                                                <h2 className={dropList && dropList.owner && dropList.owner.roleId && dropList.owner.roleId == 1 ? "usernameClick" : "usernameClick"} onClick={() => this.gotoCreator(dropList && dropList.owner)}>{dropList && dropList.owner && dropList.owner != null && dropList.owner.userName != null ? (dropList.owner.userName && dropList.owner.userName.includes('@') ? dropList.owner.userName : (`@${dropList.owner.userName}`)) : dropList.ownerWalletAddress != null && dropList.ownerWalletAddress}</h2>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="badgeview sharedrop">
                                                <div className="dropdown">
                                                    <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                        <i className="far fa-share-square"></i> Share
                                                    </h2>
                                                </div>
                                            </div>
                                            {this.state.visible &&
                                                <div className="shareout" id="shareout">
                                                    <div className="share_overlaybg">
                                                        <div className="sharebox">
                                                            <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                            <span className="shareicons">
                                                                <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                    <img className="share_icon" src="/assets/images/copylink.png" alt="Copy Link" />
                                                                    <p className="txtchange">{this.state.copySuccess}</p>
                                                                </a>
                                                                <FacebookShareButton url={shareUrl} >
                                                                    <img className="share_icon" src="/assets/images/facebook.png" alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                                <TwitterShareButton url={shareUrl}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                                <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}`}>
                                                                    <img className="share_icon" src="/assets/images/pinterest.png" alt="Pinterest Share" />
                                                                    <p>Pinterest</p>
                                                                </a>
                                                                <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                                <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                    <img className="share_icon" src="/assets/images/messenger.png" alt="Messenger Share" />
                                                                    <p>Messenger</p>
                                                                </a>
                                                                <WhatsappShareButton url={shareUrl}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>



                                        <div className="col-md-6">
                                            <div className="dettittxt mb-3"><h2 className="py-0  text-capitalize">{dropList && dropList.title ? dropList.title : ''}</h2></div>
                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    {/* <button type="button" className="btn btn-outline-dark btn-block" onClick={this.imgpopview}>View IPFS</button> */}
                                                    <a target="blank" href={dropList && dropList.imageHash && dropList && dropList.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View On IPFS</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={dropList && dropList.flowScanURL && dropList.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View on Flow Scan</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>



                                        </div>


                                        <div className="col-md-6">
                                            <div className="purchasebox">
                                                <div className="row justify-content-center align-items-center">
                                                    <div className="col-sm-6">
                                                        <h5 className="mb-3">List Price</h5>

                                                        <h4 className="mb-3  d-inline-block"><span className="usernamelilac" title={dropList && dropList.drops && dropList.drops[0] && dropList.drops[0].markedPrice}>{(dropList && dropList.drops && dropList.drops[0] && dropList.drops[0].markedPrice ? parseFloat(dropList.drops[0].markedPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + 'FUSD' : '')}</span> {dropList && dropList.drops && dropList.drops[0] && dropList.drops[0].markedPrice && dropsCount && dropsCount.count ? <span class="small"> / 1 Edition</span> : <span></span>}  </h4>
                                                        {/* <p className="mb-3 d-inline-block">{(dropList && dropList.drops && dropList.drops[0] && dropList.drops[0].markedPrice && ('~' + '$' + ' ' + (dropList.drops[0].markedPrice * this.state.rate).toFixed(2)))}</p> */}


                                                    </div>
                                                    <div className="col-sm-6">
                                                        {
                                                            currentUserAddress !== ownerAddress
                                                                ?
                                                                (!isWebLogin()) ? <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.goLogin}>Purchase</button> :
                                                                    (isWebLogin() && !isConnectWallet()) ? <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.handleLogin}>Purchase</button> :
                                                                        // ((tokensArray && tokensArray.length > 1) && (totalOwnerArt && totalOwnerArt > 1)) ? <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.openPurchase}>Purchase</button> :
                                                                        ((tokensArray && tokensArray.length > 1) && (totalOwnerArt && totalOwnerArt > 1)) ? <div>  <div className="py-3">
                                                                            {/* {!this.state.purchaseCount && <div className="mandatory-req mt-2 text-center small">{`INFO : Count should be less than or eqaul to ${tokensArray && tokensArray.length}`}</div>} */}

                                                                            <div className="input-group  infocontent">
                                                                                <span className="input-group-text counttxt">Count</span>
                                                                                <input type="text" value={this.state.purchaseCount} onChange={this.handlePurchaseCount} className="form-control" />
                                                                                <span className="input-group-text infotxt">i
                                                                                    <span className="infospantxt">
                                                                                        {`Count should be less than or eqaul to ${tokensArray && tokensArray.length}`}
                                                                                    </span>
                                                                                </span>
                                                                            </div>


                                                                        </div><button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.handlePurchase} disabled={this.state.disablePurchase}>Purchase</button></div> :

                                                                            <button type="button" className="btn btn-primary purchasebtn text-center" onClick={this.handlePurchase} disabled={this.state.disablePurchase}>Purchase</button>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="purchasebox">
                                                <div className="row ">
                                                    <div className="col-md-6">
                                                        <h5 className="mb-3">For Sale</h5>
                                                        <h3 className=" usernamelilac">{((dropsCount && dropsCount.count) ? ((dropsCount && dropsCount.count)) : totalOwnerArt)}</h3>
                                                    </div>

                                                    {(dropsCount && dropsCount.count && totalOwnerArt > 1) &&
                                                        <div className="col-6">
                                                            <h5 className="mb-3">Total Count</h5>
                                                            <h3>{totalOwnerArt}</h3>
                                                        </div>

                                                    }

                                                </div>
                                                {/* <div className="row mt-2">
                                                <div className="col-sm-12">
                                                    <h4 className="mb-3">Activity</h4>
                                                    {this.getArtTransactions()}
                                                </div>
                                            </div> */}
                                            </div>
                                            {/* {(dropsCount && dropsCount.count && totalOwnerArt > 1) && <div className="purchasebox">
                                                <div className="row">
                                                    <div className="col-6 col-sm-4 ">
                                                        <h5 className="mb-3">Total Count</h5>
                                                        <h3>{totalOwnerArt}</h3>
                                                    </div>
                                                    <div className="col-sm-4 ">
                                                        <h5 className="mb-3">Not for sale</h5>
                                                        <h3>{(totalOwnerArt - (totalDrop))}</h3>
                                                    </div>
                                                    <div className="col-6  col-sm-4">
                                                        {totalDropArray && totalDropArray.map(item => (
                                                            <span className="badge bg-dark mr-1 p-2 mb-3">{(item && item.marked_price && parseFloat(item.marked_price).toFixed(2)) + ' ' + "FUSD" + ' ' + "-" + ' ' + item.count}</span>

                                                        ))}
                                                    </div>
                                                </div>
                                            </div>} */}
                                            <div className="purchasebox">
                                                <div className="row">

                                                    {/* <div className="col-md-4"><p><span className="">Edition:</span> Drasko V</p></div> */}
                                                    {/* <div className="col-md-6"><p><span className="">Owner:</span> {dropList && dropList.art && dropList.art.owner && dropList.art.owner.userName}</p></div> */}

                                                    <div className="col-sm-6"><h5>Listed by {this.getArtOwner()}</h5><h6 className="mb-0">{dropList && dropList.updatedAt && moment(dropList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                                                    <div className="col-sm-6"><h5>Creator {this.getArtCreator()}</h5><h6 className="mb-0">{dropList && dropList.createdAt && moment(dropList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6></div>
                                                    {/* {artTxnHistoy.length > 0 && <div className="mt-2">

                                                    </div>} */}
                                                </div>

                                                {/* <h5 className="mb-3 ">Dimension</h5> */}
                                                {/* <p><span className="">Royalty :</span> {dropList && dropList.art && dropList.art.royalty}</p> */}


                                            </div>

                                            <div className="purchasebox">

                                                <h4 className="mb-3">Activity</h4>
                                                {this.getArtTransactions()}

                                            </div>
                                            {/* <div className="text-center col-md-12 mt-5">
                                                <button type="button" className="btn btn-outline-dark mr-3 px-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {!this.state.isMyAuction && !this.state.showAuction && !this.state.isDrop && !this.state.itsMine && !this.state.isMySale && <div className="detailWallet">
                        {/* <div className="coverimg imgcovergif rounded-0">
                            {isShowLoader && <img src={artList && artList.owner && artList.owner.bannerImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${artList && artList && artList.owner && artList.owner.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt="" onClick={() => this.gotoCreator(artList && artList.owner)}></img>}
                        </div> */}
                        <div className="propagecont mb-5">

                            <div className="detasingimg">
                                <div className="container">
                                    <span className="detaimg text-center">
                                        <ArtPreview src={artList && artList.imageHash} alt="" className="mw-100" showControls={true}  autoPlay={true} isDetail={true} />
                                    </span>
                                </div>

                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="badgeview propicdetail">
                                            <div className="picpro">
                                                <span className="artcovimg">
                                                    <span className="artcov "></span>
                                                    <span className="artcovimginset no-proimg ">
                                                        <img src={artList && artList.owner && artList.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${artList && artList.owner && artList.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(artList && artList.owner)} />
                                                        {/* <img src={'/nft/assets/images/karla-ferguson.jpg'} alt="" /> */}
                                                    </span>
                                                </span>

                                            </div>
                                            {/* <h2 className="text-center mb-0 py-3 d-flex align-items-center justify-content-center">Karla Ferguson</h2> */}
                                            <h2 className={artList && artList.owner && artList.owner.roleId && artList.owner.roleId == 1 ? "usernameClick" : "usernameClick"} onClick={() => this.gotoCreator(artList && artList.owner)}>{artList && artList.owner && artList.owner != null && artList.owner.userName != null ? ((artList.owner.userName && artList.owner.userName.includes('@')) ? artList.owner.userName : (`@${artList.owner.userName}`)) : artList && artList.ownerWalletAddress != null ? artList.ownerWalletAddress : ''}</h2>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="badgeview sharedrop">
                                            <div className="dropdown">
                                                <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                    <i className="far fa-share-square"></i> Share
                                                </h2>

                                            </div>

                                        </div>


                                        {this.state.visible &&
                                            <div className="shareout" id="shareout">
                                                <div className="share_overlaybg">
                                                    <div className="sharebox">
                                                        <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                        <span className="shareicons">
                                                            <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                <img className="share_icon" src="/assets/images/copylink.png" alt="Copy Link" />
                                                                <p className="txtchange">{this.state.copySuccess}</p>
                                                            </a>
                                                            <FacebookShareButton url={shareUrl} >
                                                                <img className="share_icon" src="/assets/images/facebook.png" alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                            <TwitterShareButton url={shareUrl}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                            <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/pinterest.png" alt="Pinterest Share" />
                                                                <p>Pinterest</p>
                                                            </a>
                                                            <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                            <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/messenger.png" alt="Messenger Share" />
                                                                <p>Messenger</p>
                                                            </a>
                                                            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>

                                <div className="detailcontpa">
                                    <div className="row">


                                        {/* {this.state.imgpop &&
                                            <div className="shareout" id="shareout">
                                                <div className="share_overlaybg">
                                                    <div className="sharebox imgpop">
                                                        <h3> <span className="close" onClick={this.imgpopview}>X</span></h3>
                                                        <span className="shareicons">
                                                            <ArtPreview src={artList && artList.imageHash && `https://ipfs.infura.io/ipfs/${artList && artList.imageHash}`} alt="" className="mw-100" isDetail={true} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>} */}




                                        <div className="col-md-7">
                                            <div className="dettittxt mb-3"><h2 className="py-0 d-flex mb-0 text-capitalize">{artList && artList.title ? artList.title : ''}</h2></div>
                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={artList && artList.imageHash && artList && artList.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View On IPFS</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={artList && artList.flowScanURL && artList.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View on Flow Scan</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-md-5">

                                            <div className="purchasebox">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <h5 className="mb-3">Not For Sale</h5>
                                                        <h3 className="">{((dropsCount && dropsCount.length > 0) ? (totalEdition) : totalOwnerArt)}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            {(dropsCount && dropsCount.length > 0 && totalOwnerArt > 1) && <div className="purchasebox">
                                                <div className="row">
                                                    <div className="col-6  col-sm-4">
                                                        <h5 className="mb-3">Total Count</h5>
                                                        <h3>{totalOwnerArt}</h3>
                                                    </div>
                                                    <div className="col-6 col-sm-4">
                                                        <h5 className="mb-3">List on sale</h5>
                                                        <h3>{(totalOwnerArt - (totalEdition))}</h3>

                                                    </div>
                                                    {/* <div className="col-sm-4">
                                                        {dropsCount && dropsCount.map(item => (
                                                            <span className="badge bg-dark mr-1 p-2 mb-3">{(item && item.marked_price && parseFloat(item.marked_price).toFixed(2)) + ' ' + "FUSD" + ' ' + "-" + ' ' + item.count}</span>
                                                        ))}
                                                    </div> */}
                                                </div>
                                            </div>}
                                            <div className="purchasebox">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h5 className="">Creator {this.getArtCreator()}</h5>
                                                        <h6>{artList && artList.createdAt && moment(artList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h5 className="">Owner {this.getArtOwner()}</h5>
                                                        <h6>{artList && artList.updatedAt && moment(artList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="text-center col-md-12 mt-5">
                                                                    <button type="button" className="btn btn-outline-dark mr-3 px-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button>
                                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    }


                    {this.state.showAuction && !this.state.isMyAuction && <div>
                        {/* <div className="coverimg imgcovergif rounded-0">
                        {isLoader && <img src={bidList && bidList.owner && bidList.owner.bannerImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${bidList && bidList.owner && bidList.owner.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt=""></img>}
                    </div> */}
                        <div className="propagecont mb-5">
                            <div className="detasingimg">
                                <div className="container">
                                    <span className="detaimg text-center">
                                        <ArtPreview src={bidList && bidList.imageHash ? bidList && bidList.imageHash : `${configPath.imageAssets}/art-3.jpg`} className="mw-100" showControls={true}  autoPlay={true} isDetail={true} />
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="badgeview propicdetail">
                                            <div className="picpro">
                                                {/* <img src={'/nft/assets/images/karla-ferguson.jpg'} alt="" /> */}

                                                <span className="artcovimg">
                                                    <span className="artcov "></span>
                                                    <span className="artcovimginset no-proimg">
                                                        <img src={bidList && bidList.owner && bidList.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${bidList && bidList.owner && bidList.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                    </span>
                                                </span>

                                            </div>
                                            {bidList && bidList.owner && bidList.owner.userName && <h2 className="usernameClick" onClick={() => this.gotoCreator(bidList && bidList.owner)}>{bidList && bidList.owner && bidList.owner.userName && `@${bidList && bidList.owner.userName}`} </h2>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="badgeview sharedrop">
                                            <div className="dropdown">
                                                <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                    <i className="far fa-share-square"></i> Share
                                                </h2>
                                            </div>
                                        </div>
                                        {/* <div className="badgeview sharedrop likebtn liked disabled">
                                        <i className="far fa-heart"></i>
                                        <i className="fas fa-heart"></i>
                                    </div> */}
                                        {this.state.visible &&
                                            <div className="shareout" id="shareout">
                                                <div className="share_overlaybg">
                                                    <div className="sharebox">
                                                        <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                        <span className="shareicons">
                                                            <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                <img className="share_icon" src="/assets/images/copylink.png" alt="Copy Link" />
                                                                <p className="txtchange">{this.state.copySuccess}</p>
                                                            </a>
                                                            <FacebookShareButton url={shareUrl} >
                                                                <img className="share_icon" src="/assets/images/facebook.png" alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                            <TwitterShareButton url={shareUrl}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                            <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/pinterest.png" alt="Pinterest Share" />
                                                                <p>Pinterest</p>
                                                            </a>
                                                            <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                            <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/messenger.png" alt="Messenger Share" />
                                                                <p>Messenger</p>
                                                            </a>
                                                            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>

                                </div>
                                <div className="detailcontpa">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="dettittxt mb-3"><h2 className="py-0 d-flex mb-0 text-capitalize">{bidList && bidList.title ? bidList.title : ''}</h2></div>
                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={bidList && bidList.imageHash && bidList && bidList.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View On IPFS</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={bidList && bidList.flowScanURL && bidList.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View on Flow Scan</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        {this.startTime(bidList, false) ?
                                            <div className="col-md-6">
                                                {/* <h3 className="text-center mb-3">Place Bid</h3> */}
                                                <div className="purchasebox">
                                                    <div className="row">
                                                        <div className="col-sm-6 timerline">
                                                            {bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails.length > 0 &&
                                                                <div>
                                                                    <h5>Current Bid</h5>
                                                                    <h2 className="usernamelilac mb-0" title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidAmount} style={{ cursor: "pointer" }}>{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails.length > 0 && bidList.drops[0].auction.auctionDetails[0] && (parseFloat(bidList.drops[0].auction.auctionDetails[0].bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</h2></div>}
                                                            {auctionHistory && auctionHistory.length > 0 && <span className="usernamelilac" onClick={this.openHistory} style={{ cursor: "pointer" }}>Bid History</span>}

                                                            <h5>Start Bid <span title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice} className="usernamelilac">{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice && (parseFloat(bidList.drops[0].auction.startPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</span></h5>


                                                        </div>
                                                        <div className="col-sm-6 ">
                                                            <h5>Ends by</h5>
                                                            {this.state.show && this.startTime(bidList, false)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.state.isShowHistory && <div className="purchasebox" >
                                                    <h5 className="mb-3">Bidders</h5>
                                                    <div className="cardlist dropseclist flex1 text-center" >

                                                        {auctionHistory && auctionHistory.map((auction => (
                                                            <div className="row">

                                                                <div className="col-sm-6">
                                                                    <div className="badgeview" onClick={() => { this.gotoCreator((auction && auction.bidder)) }}>
                                                                        <div className="picpro">
                                                                            <span className="artcovimg">
                                                                                <span className="artcov"></span>
                                                                                <span className="artcovimginset no-proimg" style={{ cursor: "poniter" }}>
                                                                                    <img src={auction && auction.bidder && auction.bidder.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${auction && auction.bidder && auction.bidder.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                                </span>
                                                                            </span>
                                                                        </div><h2 className="usernameClick">{(auction && auction.bidder && auction.bidder.userName) ? ("@" + (auction.bidder.userName)) : '-'}</h2></div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label>Bid Amount</label>
                                                                    <div className="usernamelilac mb-0" title={(auction && auction.bidAmount)}>
                                                                        {(auction && auction.bidAmount) ? (parseFloat(auction.bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "FUSD") : '-'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )))}
                                                    </div>

                                                </div>}
                                                <div className="purchasebox">
                                                    <h5 className="mb-3">Bid Amount</h5>
                                                    <div className="input-group dollorsec">
                                                        <input type="text" className="form-control" value={this.state.bidAmount} name="bidAmount" onChange={this.handleBidPrice} />

                                                        <div className="input-group-append ">
                                                            <span className="input-group-text bg-white" id="basic-addon2">FUSD</span>
                                                        </div>
                                                    </div>

                                                    {/* <div className="endofdate mt-1"><p className="mb-0 small ">Minimum Bid Increment : {bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.minimumBidIncrement && (parseFloat(bidList.drops[0].auction.minimumBidIncrement).toFixed(2) + ' ' + "FUSD")}</p></div> */}

                                                    {/* <div class="collapse" id="collapseExample">
                                                        <div class="card card-body">
                                                        </div>
                                                    </div> */}
                                                    {this.state.isSubmitAuction && !this.state.bidAmount && <div className="mandatory">Please enter valid amount</div>}


                                                </div>


                                                <div className="row  text-center mb-3">
                                                    <div className="col-md-12">
                                                        {isWebLogin() ? <button type="button" className="btn btn-primary px-5" onClick={isConnectWallet() ? this.handlePlaceBid : this.handleLogin} disabled={this.state.disableList}>Place Bid</button> :
                                                            <button type="button" className="btn btn-primary px-5" onClick={() => { history.push('/login') }} >Place Bid</button>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="purchasebox">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h5 className="">Creator {this.getArtCreator()}</h5>
                                                            <h6>{bidList && bidList.createdAt && moment(bidList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h5 className="">Owner {this.getArtOwner()}</h5>
                                                            <h6>{bidList && bidList.updatedAt && moment(bidList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                        </div>
                                                    </div>
                                                </div>



                                            </div> : <div className="col-md-6">
                                                <div className="purchasebox">
                                                    {/* <div className="endofdate mt-1"><p className="mb-0 small ">Starting Price : {bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice && (parseFloat(bidList.drops[0].auction.startPrice).toFixed(2) + ' ' + "FUSD")}</p></div> */}
                                                    {/* <div className="endofdate mt-1"><p className="mb-0 small ">Minimum Bid Increment : {bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.minimumBidIncrement && (parseFloat(bidList.drops[0].auction.minimumBidIncrement).toFixed(2) + ' ' + "FUSD")}</p></div> */}
                                                    {(this.isCheckLeader(bidList)) ? <div>
                                                        <div className="row">
                                                            <div className="col-md-12 text-center">
                                                                {isWebLogin() ? <button type="button" className="btn btn-primary px-3" onClick={isConnectWallet() ? (() => { this.handleSettlement(bidList) }) : this.handleLogin} disabled={this.state.disableList}>Claim Art</button> :
                                                                    <button type="button" className="btn btn-primary px-3" onClick={() => { history.push('/login') }} >Claim Art</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="purchasebox">

                                                            <div>
                                                                <h5>Bid Amount</h5>
                                                                <h2 className="mb-0 usernamelilac" title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidAmount}>{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails.length > 0 && bidList.drops[0].auction.auctionDetails[0] ? (parseFloat(bidList.drops[0].auction.auctionDetails[0].bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD") : '-'}</h2></div>
                                                            {auctionHistory && auctionHistory.length > 0 && <span className="usernamelilac" onClick={this.openHistory} style={{ cursor: "pointer" }}>Bid History</span>}
                                                            <h5>Bidded by <span className="usernamelilac">{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidderName ? ('@' + bidList.drops[0].auction.auctionDetails[0].bidderName) : ''}</span>
                                                            </h5>
                                                            <h5>Start Bid <span className="usernamelilac" title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice}>{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice && (parseFloat(bidList.drops[0].auction.startPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</span>
                                                            </h5>


                                                        </div>
                                                        {this.state.isShowHistory && <div className="purchasebox" >
                                                            <h5 className="mb-3">Bidders</h5>
                                                            <div className="cardlist dropseclist flex1 text-center" >

                                                                {auctionHistory && auctionHistory.map((auction => (
                                                                    <div className="row">
                                                                        <div className="col-sm-6">
                                                                            <div className="badgeview" onClick={() => { this.gotoCreator((auction && auction.bidder)) }}>
                                                                                <div className="picpro">
                                                                                    <span className="artcovimg">
                                                                                        <span className="artcov"></span>
                                                                                        <span className="artcovimginset no-proimg" style={{ cursor: "poniter" }}>
                                                                                            <img src={auction && auction.bidder && auction.bidder.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${auction && auction.bidder && auction.bidder.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                                        </span>
                                                                                    </span>
                                                                                </div><h2 className="usernameClick">{(auction && auction.bidder && auction.bidder.userName) ? ("@" + (auction.bidder.userName)) : '-'}</h2></div>
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <label>Bid Amount</label>
                                                                            <div className="usernamelilac mb-0" title={auction && auction.bidAmount} >
                                                                                {(auction && auction.bidAmount) ? (parseFloat(auction.bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "FUSD") : '-'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )))}
                                                            </div>

                                                        </div>}
                                                        <div className="purchasebox">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h5 className="">Creator {this.getArtCreator()}</h5>
                                                                    <h6>{bidList && bidList.createdAt && moment(bidList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="">Owner {this.getArtOwner()}</h5>
                                                                    <h6>{bidList && bidList.updatedAt && moment(bidList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : <div>
                                                        <b>The bidding was closed.Waiting for settlement</b>
                                                        <div className="purchasebox mt-3">

                                                            {bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails.length > 0 && <div >
                                                                <h5>Bid Amount</h5>
                                                                <h2 className="mb-0 usernamelilac" title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidAmount}>{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails.length > 0 && bidList.drops[0].auction.auctionDetails[0] ? (parseFloat(bidList.drops[0].auction.auctionDetails[0].bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD") : '-'}</h2></div>}
                                                            {auctionHistory && auctionHistory.length > 0 && <span className="usernamelilac" onClick={this.openHistory} style={{ cursor: "pointer" }}>Bid History</span>}
                                                            <h5>Bidded by <span className="usernamelilac">{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.auctionDetails && bidList.drops[0].auction.auctionDetails[0] && bidList.drops[0].auction.auctionDetails[0].bidderName ? ('@' + bidList.drops[0].auction.auctionDetails[0].bidderName) : ''}</span>
                                                            </h5>
                                                            <h5>Start Bid <span className="usernamelilac" title={bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice}>{bidList && bidList.drops && bidList.drops[0] && bidList.drops[0].auction && bidList.drops[0].auction.startPrice && (parseFloat(bidList.drops[0].auction.startPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</span>
                                                            </h5>


                                                        </div>
                                                        {this.state.isShowHistory && <div className="purchasebox" >
                                                            <h5 className="mb-3">Bidders</h5>
                                                            <div className="cardlist dropseclist flex1 text-center" >

                                                                {auctionHistory && auctionHistory.map((auction => (
                                                                    <div className="row">

                                                                        <div className="col-sm-6">
                                                                            <div className="badgeview" onClick={() => { this.gotoCreator((auction && auction.bidder)) }}>
                                                                                <div className="picpro">
                                                                                    <span className="artcovimg">
                                                                                        <span className="artcov"></span>
                                                                                        <span className="artcovimginset no-proimg" style={{ cursor: "poniter" }}>
                                                                                            <img src={auction && auction.bidder && auction.bidder.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${auction && auction.bidder && auction.bidder.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                                        </span>
                                                                                    </span>
                                                                                </div><h2 className="usernameClick">{(auction && auction.bidder && auction.bidder.userName) ? ("@" + (auction.bidder.userName)) : '-'}</h2></div>
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <label>Bid Amount</label>
                                                                            <div className="usernamelilac mb-0" title={auction && auction.bidAmount}>
                                                                                {(auction && auction.bidAmount) ? (parseFloat(auction.bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "FUSD") : '-'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )))}
                                                            </div>

                                                        </div>}
                                                        <div className="purchasebox">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h5 className="">Creator {this.getArtCreator()}</h5>
                                                                    <h6>{bidList && bidList.createdAt && moment(bidList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="">Owner {this.getArtOwner()}</h5>
                                                                    <h6>{bidList && bidList.updatedAt && moment(bidList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}

                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}
                    {!this.state.showAuction && this.state.isMyAuction && <div>
                        {/* <div className="coverimg imgcovergif rounded-0">
                        {isLoader && <img src={bidList && bidList.owner && bidList.owner.bannerImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${bidList && bidList.owner && bidList.owner.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt=""></img>}
                    </div> */}
                        <div className="propagecont mb-5">
                            <div className="detasingimg">
                                <div className="container">
                                    <span className="detaimg text-center">
                                        <ArtPreview src={myAuctionList && myAuctionList.imageHash ? myAuctionList && myAuctionList.imageHash : `${configPath.imageAssets}/art-3.jpg`} className="mw-100" showControls={true}  autoPlay={true} isDetail={true} />
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="badgeview propicdetail">
                                            <div className="picpro">
                                                {/* <img src={'/nft/assets/images/karla-ferguson.jpg'} alt="" /> */}

                                                <span className="artcovimg">
                                                    <span className="artcov "></span>
                                                    <span className="artcovimginset no-proimg">
                                                        <img src={myAuctionList && myAuctionList.owner && myAuctionList.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${myAuctionList && myAuctionList.owner && myAuctionList.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                    </span>
                                                </span>

                                            </div>
                                            {myAuctionList && myAuctionList.owner && myAuctionList.owner.userName && <h2 className="usernameClick" onClick={() => this.gotoCreator(myAuctionList && myAuctionList.owner)}>{myAuctionList && myAuctionList.owner && myAuctionList.owner.userName && `@${myAuctionList.owner.userName}`} </h2>}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="badgeview sharedrop">
                                            <div className="dropdown">
                                                <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                    <i className="far fa-share-square"></i> Share
                                                </h2>
                                            </div>
                                        </div>
                                        {/* <div className="badgeview sharedrop likebtn liked disabled">
                                        <i className="far fa-heart"></i>
                                        <i className="fas fa-heart"></i>
                                    </div> */}
                                        {this.state.visible &&
                                            <div className="shareout" id="shareout">
                                                <div className="share_overlaybg">
                                                    <div className="sharebox">
                                                        <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                        <span className="shareicons">
                                                            <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                <img className="share_icon" src="/assets/images/copylink.png" alt="Copy Link" />
                                                                <p className="txtchange">{this.state.copySuccess}</p>
                                                            </a>
                                                            <FacebookShareButton url={shareUrl} >
                                                                <img className="share_icon" src="/assets/images/facebook.png" alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                            <TwitterShareButton url={shareUrl}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                            <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/pinterest.png" alt="Pinterest Share" />
                                                                <p>Pinterest</p>
                                                            </a>
                                                            <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                            <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/messenger.png" alt="Messenger Share" />
                                                                <p>Messenger</p>
                                                            </a>
                                                            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>

                                </div>
                                <div className="detailcontpa">
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="dettittxt mb-3"><h2 className="py-0 d-flex mb-0 text-capitalize">{myAuctionList && myAuctionList.title ? myAuctionList.title : ''}</h2></div>
                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={myAuctionList && myAuctionList.imageHash && myAuctionList && myAuctionList.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View On IPFS</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-8 mb-3">
                                                    <a target="blank" href={myAuctionList && myAuctionList.flowScanURL && myAuctionList.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                        <span className="texticon"> View on Flow Scan</span>
                                                        <i className="fas fa-external-link-alt"></i>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        {this.startTime(myAuctionList, false) ? <div className="col-md-6">


                                            <div className="purchasebox">
                                                {/* <h3 className="text-center mb-3">Cancel Bid</h3> */}
                                                <div className="row">
                                                    <div className="col-sm-6 timerline">
                                                        {myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails.length > 0 &&
                                                            <div>
                                                                <h5>Current Bid</h5>
                                                                <h2 className="mb-0 usernamelilac" title={myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails[0] && myAuctionList.drops[0].auction.auctionDetails[0].bidAmount}>{myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails.length > 0 && myAuctionList.drops[0].auction.auctionDetails[0] && (parseFloat(myAuctionList.drops[0].auction.auctionDetails[0].bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</h2></div>}
                                                        {auctionHistory && auctionHistory.length > 0 && <span className="usernamelilac" onClick={this.openHistory} style={{ cursor: "pointer" }}>Bid History</span>}

                                                        <h5>Start Bid <span className="usernamelilac" title={myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.startPrice}>{myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.startPrice && (parseFloat(myAuctionList.drops[0].auction.startPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</span>
                                                        </h5>


                                                    </div>
                                                    <div className="col-sm-6 ">
                                                        <h5>Ends by</h5>
                                                        {this.startTime(myAuctionList, false)}

                                                        {/* <div className="endofdate mt-1 "><p className="mb-0 small ">Minimum Bid Increment : {myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.minimumBidIncrement && (parseFloat(myAuctionList.drops[0].auction.minimumBidIncrement).toFixed(2) + ' ' + "FUSD")}</p></div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            {this.state.isShowHistory && <div className="purchasebox" >
                                                <h5 className="mb-3">Bidders</h5>
                                                <div className="" >

                                                    {auctionHistory && auctionHistory.map((auction => (
                                                        <div className="row">

                                                            <div className="col-sm-4">
                                                                <div className="upic mb-5" style={{ cursor: "pointer" }}>
                                                                    <span className="artcovimg">
                                                                        <span className="artcov"></span>
                                                                        <span className="artcovimginset no-proimg" onClick={() => { this.gotoCreator((auction && auction.bidder)) }} style={{ cursor: "poniter" }}>
                                                                            <img src={auction && auction.bidder && auction.bidder.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${auction && auction.bidder && auction.bidder.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className="usernamelilac" onClick={() => { this.gotoCreator((auction && auction.bidder)) }} style={{ cursor: "pointer" }}>

                                                                    {(auction && auction.bidder && auction.bidder.userName) ? ("@" + (auction.bidder.userName)) : '-'}

                                                                </div>
                                                            </div>

                                                            <div className="col-sm-8">
                                                                <label>Bid Amount</label>
                                                                <div className="usernamelilac mb-0" title={auction && auction.bidAmount} >
                                                                    {(auction && auction.bidAmount) ? (parseFloat(auction.bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "FUSD") : '-'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )))}
                                                </div>

                                            </div>}
                                            <div className="row text-center mb-3">
                                                <div className="col-md-12 ">
                                                    {isWebLogin() ? <button type="button" className="btn btn-primary px-5" onClick={isConnectWallet() ? this.handleCancelBid : this.handleLogin} disabled={this.state.disableList}>Cancel Bid</button> :
                                                        <button type="button" className="btn btn-primary px-5" onClick={() => { history.push('/login') }} >Cancel Bid</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="purchasebox">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h5 className="">Creator {this.getArtCreator()}</h5>
                                                        <h6>{myAuctionList && myAuctionList.createdAt && moment(myAuctionList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h5 className="">Owner {this.getArtOwner()}</h5>
                                                        <h6>{myAuctionList && myAuctionList.updatedAt && moment(myAuctionList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                            : <div className="col-md-6">
                                                <div className="row">

                                                    <div className="col-md-12 text-center">
                                                        {isWebLogin() ?
                                                            ((myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails.length > 0) ?
                                                                (<button type="button" className="btn btn-primary px-3 mb-3" onClick={isConnectWallet() ? (() => { this.handleSettlement(myAuctionList) }) : this.handleLogin} disabled={this.state.disableList}>Settlement</button>) : (<button type="button" className="btn btn-primary px-3 mb-3" onClick={isConnectWallet() ? (() => { this.handleSettlement(myAuctionList) }) : this.handleLogin} disabled={this.state.disableList}>Return</button>)) :
                                                            (myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails.length > 0 ?
                                                                (<button type="button" className="btn btn-primary px-3 mb-3" onClick={() => { history.push('/login') }} >Settlement</button>) : (<button type="button" className="btn btn-primary px-3 mb-3" onClick={() => { history.push('/login') }} >Return</button>))
                                                        }
                                                        <div className="purchasebox">

                                                            <div>
                                                                <h5>Bid Amount</h5>
                                                                <h2 className="mb-0 usernamelilac" title={myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails[0] && myAuctionList.drops[0].auction.auctionDetails[0].bidAmount}>{myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails.length > 0 && myAuctionList.drops[0].auction.auctionDetails[0] && (parseFloat(myAuctionList.drops[0].auction.auctionDetails[0].bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</h2></div>
                                                            {auctionHistory && auctionHistory.length > 0 && <span className="usernamelilac" onClick={this.openHistory} style={{ cursor: "pointer" }}>Bid History</span>}
                                                            <h5>Bidded by <span className="usernamelilac">{myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.auctionDetails && myAuctionList.drops[0].auction.auctionDetails[0] && myAuctionList.drops[0].auction.auctionDetails[0].bidderName && ('@' + myAuctionList.drops[0].auction.auctionDetails[0].bidderName)}</span>
                                                            </h5>
                                                            <h5>Start Bid <span className="usernamelilac" title={myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.startPrice}>{myAuctionList && myAuctionList.drops && myAuctionList.drops[0] && myAuctionList.drops[0].auction && myAuctionList.drops[0].auction.startPrice && (parseFloat(myAuctionList.drops[0].auction.startPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + "FUSD")}</span>
                                                            </h5>


                                                        </div>
                                                        {this.state.isShowHistory && <div className="purchasebox" >
                                                            <h5 className="mb-3">Bidders</h5>
                                                            <div className="cardlist dropseclist flex1 text-center" >

                                                                {auctionHistory && auctionHistory.map((auction => (
                                                                    <div className="row">

                                                                        <div className="col-sm-6">
                                                                            <div className="badgeview" onClick={() => { this.gotoCreator((auction && auction.bidder)) }}>
                                                                                <div className="picpro">
                                                                                    <span className="artcovimg">
                                                                                        <span className="artcov"></span>
                                                                                        <span className="artcovimginset no-proimg" style={{ cursor: "poniter" }}>
                                                                                            <img src={auction && auction.bidder && auction.bidder.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${auction && auction.bidder && auction.bidder.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                                                        </span>
                                                                                    </span>
                                                                                </div><h2 className="usernameClick">{(auction && auction.bidder && auction.bidder.userName) ? ("@" + (auction.bidder.userName)) : '-'}</h2></div>
                                                                        </div>

                                                                        <div className="col-sm-6">
                                                                            <label>Bid Amount</label>
                                                                            <div className="usernamelilac mb-0" title={auction && auction.bidAmount} >
                                                                                {(auction && auction.bidAmount) ? (parseFloat(auction.bidAmount).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + "FUSD") : '-'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )))}
                                                            </div>

                                                        </div>}
                                                        <div className="purchasebox">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h5 className="">Creator {this.getArtCreator()}</h5>
                                                                    <h6>{myAuctionList && myAuctionList.createdAt && moment(myAuctionList.createdAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="">Owner {this.getArtOwner()}</h5>
                                                                    <h6>{myAuctionList && myAuctionList.updatedAt && moment(myAuctionList.updatedAt).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>}


                    {this.state.itsMine && !this.state.showAuction && <div className="modal fade" id="changeprice" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Change Price</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { this.setState({ changedPrice: "", usd: '', count: '', priceCount: '' }) }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="input-group dollorsec mb-3">
                                        <input type="text" value={this.state.changedPrice} onChange={this.priceChange} className="form-control" />
                                        {(totalOwnerArt && totalOwnerArt > 1) && (tokensArray && tokensArray.length > 1) ? <div className="input-group-append ">
                                            <span className="input-group-text bg-white" id="basic-addon2">FUSD/1 Edition</span>
                                        </div> : <div className="input-group-append ">
                                            <span className="input-group-text bg-white" id="basic-addon2">FUSD</span>
                                        </div>}
                                        {/* {(totalOwnerArt && totalOwnerArt > 1) && (tokensArray && tokensArray.length > 1) && <div className="input-group-append ">
                                            <span className="input-group-text bg-white" id="basic-addon2">/1 Edition</span>
                                        </div>} */}

                                        {/* <div className="input-group-append dollorsec">
                                            <span className="input-group-text disabled" id="basic-addon2">{`$ ${this.state.usd}`}</span>
                                        </div> */}

                                    </div>
                                    {(totalOwnerArt && totalOwnerArt > 1) && (tokensArray && tokensArray.length > 1) && <div>
                                        <input name="priceCount" value={this.state.priceCount} onChange={this.handlePriceCount} className={"form-control"} placeholder="Enter the count for change price" />
                                        {!this.state.priceCount && <div className="mandatory-req">{`INFO : Count must be less than or equal to ${tokensArray && tokensArray.length} (must be whole number)`}</div>}

                                    </div>}
                                </div>
                                <div className="modal-footer justify-content-center">
                                    {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => { this.setState({ changedPrice: "", usd: '', count: '', priceCount: '' }) }}>Close</button> */}
                                    {(totalOwnerArt && totalOwnerArt > 1) && (tokensArray && tokensArray.length > 1) ? <button type="button" className="btn btn-primary" onClick={this.submitPrice} data-dismiss={((this.state.changedPrice && this.state.changedPrice > 0 && priceCount && priceCount > 0 && checkWalletAddress((myArt && myArt.ownerWalletAddress), (this.state.currentUserAddress)))) ? "modal" : ''}>Change Price</button> :
                                        <button type="button" className="btn btn-primary" onClick={this.submitPrice} data-dismiss={((this.state.changedPrice && this.state.changedPrice > 0 && checkWalletAddress((myArt && myArt.ownerWalletAddress), (this.state.currentUserAddress)))) ? "modal" : ''}>Change Price</button>}

                                </div>
                            </div>
                        </div>
                    </div>}

                    {this.state.itsMine && !this.state.showAuction && <div className="detailWallet">
                        {/* <div className="coverimg imgcovergif rounded-0">

                            <img src={myArt && myArt.owner && myArt.owner.bannerImage && myArt.owner.bannerImage != null ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${myArt && myArt && myArt.owner && myArt.owner.bannerImage}` : `../${configPath.imageAssets}/cover-default.png`} alt="" onClick={() => this.gotoCreator(myArt && myArt.owner)}></img>

                        </div> */}
                        <div className="propagecont mb-5">
                            <div className="detasingimg ">
                                <div className="container">
                                    <span className="detaimg text-center">
                                        <ArtPreview src={myArt && myArt.imageHash && myArt.imageHash} alt="" className="mw-100" showControls={true}  autoPlay={true} isDetail={true} />
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="badgeview propicdetail">

                                            <div className="picpro">
                                                <span className="artcovimg">
                                                    <span className="artcov "></span>
                                                    <span className="artcovimginset no-proimg ">
                                                        <img src={myArt && myArt.owner && myArt.owner.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${myArt && myArt.owner && myArt.owner.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" onClick={() => this.gotoCreator(myArt && myArt.owner)} />
                                                        {/* <img src={'/nft/assets/images/karla-ferguson.jpg'} alt="" /> */}
                                                    </span>
                                                </span>

                                            </div>
                                            {/* <h2 className="text-center mb-0 py-3 d-flex align-items-center justify-content-center">Karla Ferguson</h2> */}
                                            <h2 className={myArt && myArt.owner && myArt.owner.roleId && myArt.owner.roleId == 1 ? "usernameClick" : "usernameClick"} onClick={() => this.gotoCreator(myArt && myArt.owner)}>{myArt && myArt.owner && myArt.owner != null && myArt.owner.userName != null ? ((myArt.owner.userName && myArt.owner.userName.includes('@')) ? myArt.owner.userName : (`@${myArt.owner.userName}`)) : myArt.ownerWalletAddress != null && myArt.ownerWalletAddress}</h2>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="badgeview sharedrop">
                                            <div className="dropdown">
                                                <h2 className="dropdown-toggle usernameClick" onClick={this.toggleMenu}>
                                                    <i className="far fa-share-square"></i> Share
                                                </h2>

                                            </div>

                                        </div>
                                        {this.state.visible &&
                                            <div className="shareout" id="shareout">
                                                <div className="share_overlaybg">
                                                    <div className="sharebox">
                                                        <h3>Share on <span className="close" onClick={this.toggleMenu}>X</span></h3>
                                                        <span className="shareicons">
                                                            <a href="javascript:void(0)" className="copylinktxt" onClick={() => { this.copyToClipboard(shareUrl) }}>
                                                                <img className="share_icon" src="/assets/images/copylink.png" alt="Copy Link" />
                                                                <p className="txtchange">{this.state.copySuccess}</p>
                                                            </a>
                                                            <FacebookShareButton url={shareUrl} >
                                                                <img className="share_icon" src="/assets/images/facebook.png" alt="Facebook Share" /><p>Facebook</p></FacebookShareButton>
                                                            <TwitterShareButton url={shareUrl}><TwitterIcon size={46} round={true} /><p>Twitter</p></TwitterShareButton>
                                                            <a target="_blank" href={`https://in.pinterest.com/pin/create/button/?url=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/pinterest.png" alt="Pinterest Share" />
                                                                <p>Pinterest</p>
                                                            </a>
                                                            <EmailShareButton url={shareUrl} subject={"Check out this Rare Disrupt Art!"} body="Digital Artworks, Artists sell their pieces as a digital creation through block chain technology."><EmailIcon size={46} round={true} /><p>Email</p></EmailShareButton>
                                                            <a target="_blank" href={`https://www.facebook.com/dialog/send?app_id=900965550237850&link=${shareUrl}&redirect_uri=${shareUrl}`}>
                                                                <img className="share_icon" src="/assets/images/messenger.png" alt="Messenger Share" />
                                                                <p>Messenger</p>
                                                            </a>
                                                            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={46} round={true} /><p>Whatsapp</p></WhatsappShareButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="detailcontpa container">
                                {/* <h1 className="mb-3 text-right d-none d-sm-none d-md-block">
                                    {isConnectWallet() ? <button type="button" className="btn btn-primary px-5 btn-sm" data-toggle="modal" data-target="#changeprice">Change Price</button> : <button type="button" className="btn btn-primary px-5 btn-sm" onClick={this.handleLogin}>Change Price</button>}
                                </h1> */}
                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="dettittxt mb-3"><h2 className="py-0  text-capitalize">{myArt && myArt.title}</h2></div>
                                        <div className="row">
                                            <div className="col-md-12 btncws">
                                                {isConnectWallet() ? <button type="button" className="btn btn-primary btn-sm mb-3 " data-toggle="modal" data-target="#changeprice" >Change Price</button> : <button type="button" className="btn btn-primary btn-sm mb-3 " onClick={this.handleLogin}>Change Price</button>}

                                                {/* <button type="button" className="btn btn-primary mr-3 px-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button> */}
                                                <button type="button" className="btn btn-primary mb-3 ml-3" disabled={this.state.disableWithdraw} onClick={isConnectWallet() ? this.handleResale : this.handleLogin}>Withdraw</button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        {myArt && myArt.drops && myArt.drops[0] && myArt.drops[0].markedPrice && <div className="purchasebox">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-6 ">
                                                    <h5 className="mb-3">Price</h5>
                                                    <h3 className=" usernamelilac" title={myArt && myArt.drops && myArt.drops[0] && myArt.drops[0].markedPrice}>{myArt && myArt.drops && myArt.drops[0] && myArt.drops[0].markedPrice && parseFloat(myArt.drops[0].markedPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + ' ' + 'FUSD'} <span className="small text-white">/ 1 Edition</span></h3>
                                                </div>

                                            </div>
                                        </div>}
                                        <div className="purchasebox">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h5 className="mb-3">For Sale</h5>
                                                    <h3 className=" usernamelilac">{((dropsCount && dropsCount.count && dropsCount.count != null) ? (dropsCount && dropsCount.count) : totalOwnerArt)}</h3>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="purchasebox">
                                            <div className="row">
                                                {myArt && myArt.creator && myArt.creator != null && myArt.creator.userName && <div className="col-sm-6">
                                                    <h5 className="mb-0" onClick={() => { this.gotoCreator(myArt.creator) }} style={{ cursor: "pointer" }}>Creator {myArt && myArt.creator && myArt.creator != null && myArt.creator.userName ? ((myArt.creator.userName && myArt.creator.userName.includes('@')) ? myArt.creator.userName : (`@${myArt.creator.userName}`)) : myArt.creatorWalletAddress && myArt.creatorWalletAddress}</h5>

                                                </div>}
                                                {myArt && myArt.owner && myArt.owner != null && myArt.owner.userName && <div className="col-sm-6">
                                                    <h5 className="mb-0" onClick={() => { this.gotoCreator(myArt.owner) }} style={{ cursor: "pointer" }}>Owner {myArt && myArt.owner && myArt.owner != null && myArt.owner.userName ? ((myArt.owner.userName && myArt.owner.userName.includes('@')) ? myArt.owner.userName : (`@${myArt.owner.userName}`)) : myArt.ownerWalletAddress && myArt.ownerWalletAddress}</h5>

                                                </div>}
                                            </div>
                                        </div>

                                        {(totalOwnerArt && totalOwnerArt > 1) && (tokensArray && tokensArray.length > 1) && <div className="purchasebox">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h5 className="mb-3">Withdrawn Count</h5>
                                                    <input name="count" value={this.state.count} className="form-control w-auto" onChange={this.handleCount} />
                                                    {!this.state.count && <div className="mandatory-req">{`INFO : Count must be less than or equal to ${tokensArray && tokensArray.length} (must be whole number)`}</div>}
                                                </div>
                                            </div>
                                        </div>}



                                        {/* <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Royalty</h5>
                                               
                                                <h4 className="mb-3 d-inline-block ml-3"> {dropList && dropList.art && dropList.art.royalty}</h4>


                                            </div> */}


                                        <hr />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>}
                    {relativeArts && relativeArts.length > 0 && <div className="morefromarts container">
                        <div className="card1 mb-5 morelist">
                            <div className="card-body1">

                                {this.state.isDrop && !this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " onClick={() => { this.gotoCreator(dropList && dropList.owner) }}>{dropList && dropList.owner && dropList.owner.userName ? `More from @${dropList.owner.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && !this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " onClick={() => { this.gotoCreator(artList && artList.owner) }}>{artList && artList.owner && artList.owner.userName ? `More from @${artList.owner.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && this.state.itsMine && !this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " onClick={() => { this.gotoCreator(myArt && myArt.owner) }} >{myArt && myArt.owner && myArt.owner.userName ? `More from @${myArt.owner.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && !this.state.itsMine && this.state.showAuction && !this.state.isMyAuction && <h4 className="mb-3 " onClick={() => { this.gotoCreator(bidList && bidList.owner) }}>{bidList && bidList.owner && bidList.owner.userName ? `More from @${bidList.owner.userName}` : "More Arts"}</h4>}
                                {!this.state.isDrop && !this.state.itsMine && !this.state.showAuction && this.state.isMyAuction && <h4 className="mb-3 " onClick={() => { this.gotoCreator(myAuctionList && myAuctionList.owner) }}>{myAuctionList && myAuctionList.owner && myAuctionList.owner.userName ? `More from @${myAuctionList.owner.userName}` : "More Arts"}</h4>}

                                <div className="row">
                                    {relativeArts && relativeArts.map(items => (
                                        <div className="col-6 col-md-3" key={items && items.id} onClick={() => this.clickRelative(items)}>
                                            <div className="cardlist">
                                                <span className="artcovimg ">
                                                    <span className="artcov"></span>
                                                    <span className="artcovimginset img-placeholder">
                                                        <ArtPreview src={items && items.imageHash} className="comimg mb-0" showControls={true}  autoPlay={true} />
                                                    </span>
                                                </span>
                                                <span className="cont">
                                                    <h3>{items && items.title ? items.title : "Title"}</h3>
                                                    <span className="usernamelilac " title={items && items.drops && items.drops[0] && items.drops[0].markedPrice}>{items && items.drops && items.drops[0] && items.drops[0].markedPrice ? (parseFloat(items.drops[0].markedPrice).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + " " + "FUSD") : (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ?
                                                        (<div>
                                                            {/* {items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                    <p className="mb-0 small">Settlement : <span className="usernamelilac">{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.settlement ? (parseFloat(items.drops[0].auction.settlement).toFixed(2) + ' ' + "FUSD") : '0 FUSD')}</span></p>} */}
                                                            {items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                                                <p><span className="usernamelilac">{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (parseFloat(items.drops[0].auction.lastBid).toFixed(2) + ' ' + "FUSD") : 'Be the first to make bid')}</span></p>}</div>) :
                                                        <p>Not for sale</p>} </span>
                                                    {/* {(items && items.drops && items.drops[0] && items.drops[0].auction &&  items.drops[0].auction.id && items.drops[0].auction.id != null ) ? ""  : items && items.drops && items.drops[0] &&  items.drops[0].markedPrice && items.groups && items.groups != null ? <span className="text-white">/ 1 Edition</span> : <span> </span> } </p> */}
                                                    {(items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) ? "" : items && items.groups && items.groups != null ? <p className="mb-0 small mt-1">Edition 1 of <span className="small usernamelilac">{items && items.groups && items.groups.artsCount}</span></p> : <p className="mb-0 small mt-1">Edition 1 of <span className="small usernamelilac">1</span></p>}

                                                    {/* <h6 className="usernamelilac">{items && items.drops && items.drops[0] && items.drops[0].markedPrice ? (parseFloat(items.drops[0].markedPrice).toFixed(2) + ' ' + "FUSD") : "Not for sale"}</h6> */}
                                                    {this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items, true)}

                                                </span>

                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>}

                </div>
            </Fragment >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        detailList: state.art.detailArt,
        dropList: state.drop.detailDrop,
        usd: state.user.usd,
        relativeArts: state.art.relativeArts,
        wallet: state.wallet.wallet


    }
}
const actionCreators = {
    detailArt: artActions.detailArt,
    getDetailDropArt: dropActions.getDetailDropArt,
    getRelativeArts: artActions.getRelativeArts,
    getDetailCreator: creatorActions.getDetailCreator,
    getUSD: userAction.getUSD,
    linkWallet: walletActions.linkWallet,
    resetRelativeArts: artActions.resetRelativeArts,
    resetlinkWallet: walletActions.resetlinkWallet


}

const connectOwnerArt = connect(mapStateToProps, actionCreators)(OwnerArt)
export { connectOwnerArt as OwnerArt }
