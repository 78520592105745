import * as fcl from "@onflow/fcl"


export const RetrieveTokens = async (useraddress) => {
    // console.log(useraddress)

    let retriveTokens = `\
              import  `+ process.env.REACT_APP_CONTRACT  +` from `+ process.env.REACT_APP_CONTRACT_ADDRESS +`

                                        // This transaction returns an array of all the nft ids in the collection

                                        pub fun main(): Int { 
                                            if
                                                getAccount(`+useraddress+`)
                                                                    .getCapability(`+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath)
                                                                    .borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>() == nil {
                                                                        return 0 
                                                                    }
                                                                    return 1 
     
                                        }`
                                   console.log(retriveTokens)

    try {
             let scriptresult = await fcl.send([fcl.script(retriveTokens)]);
             //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
             return {
                 status: 200,
                 data: { "message" : "User DisruptNow Token" , "Value" : scriptresult["encodedData"]["value"] }
             };
    }
    catch(e) {
           return {
               status: 400,
               data: { "message" : "Exception happens" , "Error" : String(e) } 
           };
    }
}

export const RetrieveTokenById = async (useraddress , id) => {
    let retrievetokenbyid = `\
                                        import  `+ process.env.REACT_APP_CONTRACT  +` from `+ process.env.REACT_APP_CONTRACT_ADDRESS +`
                                         pub fun main(): &${process.env.REACT_APP_CONTRACT}.NFT {
                                                   let collectionRef = getAccount(`+ useraddress +`)
                                                    .getCapability(`+process.env.REACT_APP_CONTRACT+`.disruptArtPublicPath)
                                                     .borrow<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>()
                                                 ?? panic("Could not borrow capability from public collection")

                                                return (collectionRef.getvalues(tokenId: `+id+`))
                                        }
                                 `
        try {
                let scriptresult = await fcl.send([fcl.script(retrievetokenbyid)]);
                return {
                    status: 200,
                    data: { "message" : "User DisruptNow Token" , "Value" : scriptresult["encodedData"]["value"] }
                };
          }
          catch(e) {
            return {
                status: 400,
                data: { "message" : "Exception happens" , "Error" : String(e) } 
            };
          }
}
