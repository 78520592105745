import { flowDecimal } from "helpers";
import React, { Fragment } from "react";
import { adminServices, artServices } from "services";
import { tagActions } from "store/actions";
import { debounce } from "utils";
import { connect } from "react-redux";
import PubSub from 'pubsub-js';

 class SuggestTrendingTags extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            isLoading: false,
            elementId: Date.now().toString()
        };
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.debouncedSearchArt = debounce(this.searhForArts.bind(this),300);
        
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.alltagsList && props.alltagsList.data && (props.alltagsList.statusCode == 200)) {
            props.resetallTags()
            const filteredSuggestions = [];
                for (const tag of props.alltagsList && props.alltagsList.data && props.alltagsList.data.tags) {
                    filteredSuggestions.push({
                        TagId: Number(tag.id),
                        mainText: tag.name,
                       
                    })
                }
    
                
            return {
                isSearching: false,
                isLoading: false,
                activeSuggestion: 0,
                filteredSuggestions,
                showSuggestions: true,
            }

        }
        if(props.tagFailed){
            return {
                isSearching: false,
                isLoading: false,
            }
        }
       
    }
    callSuggestionSelectedHandler(selectedSuggestion) {
        if (
            this.props.onSuggetionSelected &&
            typeof this.props.onSuggetionSelected === "function"
        ) {
            this.props.onSuggetionSelected(selectedSuggestion);
        }
    }

    onChange(e) {
        e.persist();
        const userInput = e.currentTarget.value;
        if (
            userInput !== '' 
            // this.props.onTextChange &&
            // typeof this.props.onTextChange === "function"
        ) {
            this.fetchSearchResults(userInput)
            // this.props.onTextChange(userInput);
        }

        this.setState({
            userInput,
            showSuggestions: true
        });
    }
    onClick(selectedSuggestion, e) {
        // console.log('click event object', e)
        this.callSuggestionSelectedHandler(selectedSuggestion);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: ''
        });
    }

    onKeyDown(e) {
        // console.log("key",e)
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) {
            e.preventDefault();
            const searchResult = filteredSuggestions[activeSuggestion]
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: ''
            });

            this.callSuggestionSelectedHandler(searchResult);
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    }
    
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    searhForArts(searchTerm){
        let obj = {

        }
        if (searchTerm) {
            obj["search"] = searchTerm
        }
        this.props.getAllTags(obj)
        this.hideLoader()
    }
    fetchSearchResults(searchTerm) {
        
            this.debouncedSearchArt(searchTerm)
        
    }
    

  

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions: sugesstionList,
                showSuggestions,
                userInput,
                isLoading
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (!isLoading) {
                if (sugesstionList.length) {
                    suggestionsListComponent = (
                        <ul className="suggestions">
                            
                            {sugesstionList.map((result, index) => {

                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }


                                return (
                                    <li
                                        className={className}
                                        // key={result.artId}
                                        onClick={(e) => onClick(result, e)}
                                    >
                                        <span>{result.mainText}</span><br />
                                       

                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else {
                    suggestionsListComponent = (
                        <div className="no-suggestions">
                            <em>No suggestions available.</em>
                        </div>
                    );
                }
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <i className="fas fa-spin fa-circle-notch fa-lg"></i>
                    </div>
                );
            }
        }
        const inputPlaceHolder = (
            this.props.placeholder &&
            typeof this.props.placeholder === 'string'
        )
            ? this.props.placeholder
            : null
        return (
            <Fragment>
                <form className="form-inline" autoComplete="false">
                    <div className="form-group mt-1">
                        <div className={"auto-suggest " + (this.state.userInput.length ? "icon-hidden" : "")}>
                            <input type="search"
                                id={this.state.elementId}
                                className="form-control"
                                placeholder={
                                    inputPlaceHolder ? inputPlaceHolder : "Search..."
                                }
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                value={userInput}
                                style={{width:"500px"}}

                            />
                            {suggestionsListComponent}
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        alltagsList: state.tags.tagsList,
        addedtags: state.tags.tagAdded,
        tagFailed : state.tags.tagFailed
    }
}
const actionCreators = {
    getAllTags: tagActions.getTagsData,
    resetallTags: tagActions.resetGetTags,
}

const connectTrendingTags = connect(mapStateToProps, actionCreators)(SuggestTrendingTags)
export { connectTrendingTags as SuggestTrendingTags }