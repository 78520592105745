import { configPath } from 'config';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { fetchService } from 'services';
import { ArtPreview } from "./art-preview/art-preview";
import { getCreatorImage, getCreatorName, gotoCreator } from './artdetail-view/bab-common-view';
import { MusicNFTVideoPreview } from './video-player/musicnftvideo-preview';
import { VideoPreview } from './video-player/video-preview'
let audio, canvas, ctx, center_x, center_y, radius, bars, x_end, y_end, bar_height, bar_width, frequency_array, rads, x, y, context, analyser;
class MusicTheme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			play: false,
			page: 1,
			isAudio: true,
			audioTime: 0,
			isVideoPlay: false
		}
		this.canvasRef = React.createRef();
		this.buttonRef = React.createRef();

	}
	componentDidMount() {
		const { page } = this.state
		if (page === 1) {
			this.ccreateVisualization()
		} else {
			this.create()
		}

	}

	componentDidUpdate(props, state) {
		let audio = this.buttonRef.current;
		if (context && context.state === "suspended") {
			if (audio && !audio.paused) {
				context.resume().then(() => {
					audio.play()
					this.setState({
						play: true
					})
				})
			}
			return;
		} else if (context && context.state === "running") {
			if (audio && audio.paused) {
				context.suspend().then(() => {
					audio.pause()
					this.setState({
						play: false
					})
				})
			}
		}
	}

	ccreateVisualization = () => {
		var AudioContext = window.AudioContext || window.webkitAudioContext;
		context = new AudioContext();
		let analyser = context.createAnalyser();
		let canvas = this.el
		let ctx = canvas.getContext('2d');
		let audio = this.buttonRef.current;
		audio.crossOrigin = "anonymous";

		let audioSrc = context.createMediaElementSource(audio);
		audioSrc.connect(analyser);
		audioSrc.connect(context.destination);
		analyser.connect(context.destination);

		function renderFrame() {
			let freqData = new Uint8Array(analyser.frequencyBinCount)
			const bufferLength = analyser.frequencyBinCount;

			requestAnimationFrame(renderFrame)
			analyser.getByteFrequencyData(freqData)
			canvas.width = window.innerWidth * 0.40 ;
			canvas.height = window.innerHeight * 0.40 ;
			ctx.clearRect(0, 0, canvas.width, canvas.height)

			let bars = window.innerWidth / 2;
			for (var i = 0; i < bufferLength; i++) {
				let bar_x = i * 6;
				let bar_width = 2;
				let bar_height = -(freqData[i] / 2);


				var barHeight1 = freqData[i];
        
				
				const red = (i * barHeight1) / 10;
				const green = i * 4;
				const blue = barHeight1 / 4 - 12;
				ctx.fillStyle = `rgb(${red}, ${green}, ${blue})`;

				ctx.fillRect(bar_x, canvas.height, bar_width, bar_height)
				
		
        
				
			}
		};
		renderFrame()
		// let audio = this.buttonRef.current;
		// audio.crossOrigin = "anonymous";
		// // audio.load()
		// // audio.play();
		// context = new AudioContext();
		// let canvas = this.el
		// var src = context.createMediaElementSource(audio);
		// var analyser = context.createAnalyser();

		// canvas.width = window.innerWidth;
		// canvas.height = window.innerHeight;
		// var ctx = canvas.getContext("2d");

		// src.connect(analyser);
		// analyser.connect(context.destination);

		// analyser.fftSize = 256;

		// var bufferLength = analyser.frequencyBinCount;
		// console.log(bufferLength);

		// var dataArray = new Uint8Array(bufferLength);

		// var WIDTH = canvas.width;
		// var HEIGHT = canvas.height;

		// var barWidth = (WIDTH / bufferLength) * 2.5;
		// var barHeight;
		// var x = 0;

		// function renderFrame() {
		// 	requestAnimationFrame(renderFrame);

		// 	x = 0;

		// 	analyser.getByteFrequencyData(dataArray);

		// 	ctx.fillStyle = "#000";
		// 	ctx.fillRect(0, 0, WIDTH, HEIGHT);

		// 	for (var i = 0; i < bufferLength; i++) {
		// 		barHeight = dataArray[i];

		// 		var r = barHeight + (25 * (i / bufferLength));
		// 		var g = 250 * (i / bufferLength);
		// 		var b = 50;

		// 		ctx.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
		// 		ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

		// 		x += barWidth + 1;
		// 	}
		// }

		// // audio.play();
		// renderFrame();
	};





playVideo = () => {

	let audio = this.buttonRef.current;

	this.state.play ? audio.pause() : audio.play()
	audio.onplaying = () => {
		this.setState({
			play: true
		})
	}
	audio.onpause = () => {
		this.setState({
			play: false
		})
	}
}



create = () => {



	canvas = this.el;
	ctx = canvas.getContext("2d");

	bar_width = 3;


	let audio = this.buttonRef.current;
	if (audio) {
		// audio.crossOrigin = "anonymous";

	}

	context = new AudioContext()
	analyser = context.createAnalyser();
	const source = context.createMediaElementSource(audio);
	source.connect(analyser);
	source.connect(context.destination)
	analyser.connect(context.destination);
	this.draw()

}

draw = () => {
	frequency_array = new Uint8Array(analyser.frequencyBinCount);
	const width = document.getElementById("mp3_player") && document.getElementById("mp3_player").clientWidth + 200
	const height = document.getElementById("mp3_player") && document.getElementById("mp3_player").clientHeight + 200

	canvas.width = width;
	canvas.height = height;
	center_x = canvas.width / 2;
	center_y = canvas.height / 2;
	radius = 200;

	ctx.clearRect(0, 0, canvas.width, canvas.height);

	ctx.strokeStyle = "#333";
	ctx.beginPath();
	ctx.arc(center_x, center_y, radius, 0, 2 * Math.PI);
	ctx.stroke();

	analyser.getByteFrequencyData(frequency_array);
	bars = 720 / bar_width;
	for (let i = 0; i < bars; i++) {
		rads = (Math.PI * 2) / bars;

		bar_height = frequency_array[i] * 0.75;

		x = center_x + Math.cos(rads * i) * radius;
		y = center_y + Math.sin(rads * i) * radius;
		x_end = center_x + Math.cos(rads * i) * (radius + bar_height);
		y_end = center_y + Math.sin(rads * i) * (radius + bar_height);

		this.drawBar(x, y, x_end, y_end, bar_width, frequency_array[i]);
	}

	window.requestAnimationFrame(this.draw);
}

drawBar = (x1, y1, x2, y2, width, frequency) => {
	let gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
	gradient.addColorStop(0, 'rgba(158, 102, 191,0.3)');
	gradient.addColorStop(1, "#9E66BF");

	ctx.strokeStyle = gradient;
	ctx.lineWidth = width;
	ctx.beginPath();
	ctx.moveTo(x1, y1);
	ctx.lineTo(x2, y2);
	ctx.stroke();
}
creatorFullName = (items) => {
	if (items && items.creator && items.creator.fullName) {
		return <span className="ownerDec" onClick={() => gotoCreator(items && items.creator)}>by {items.creator.fullName}</span>
	} else {
		return ''
	}
}
fetchThumbnailMime(url, artMimeType) {

	if (!artMimeType) {
		fetchService.getBlob(url).then((blob) => {
			// Set the Object URL state property
			// only if the blob is either an image or a video.
			console.log(blob.type)
			this.setState({
				mimeType: blob.type
			})
		}).catch((err) => {

		})
	} else {
		this.setState({
			mimeType: artMimeType
		})
	}
}
showPreview = (items) => {
	if (items && (items.thumbnailMimeType === null || items.thumbnailMimeType === undefined || items.thumbnailMimeType.includes('audio'))) {
		return <div className='col-md-12'>
			<div className='mtartimg'>
				<img src={`/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} alt={''} className={`viewmus-image ${this.props.className}`} />

			</div>
			<div className='mtartimg mtmirrorbg'>
				<div className='mtartmirror'>
					<div className='mtartminheight'>
						<img src={`/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} alt={''} className={`viewmus-image ${this.props.className}`} />
					</div>
				</div>
			</div>

		</div>
	} else if ((items.thumbnailMimeType.includes('video'))) {
		let audio = this.buttonRef.current;
		return <MusicNFTVideoPreview source={(items && items.thumbnailHash) ? items.thumbnailHash : `/${configPath.imageAssets}/no-audio-art.jpg`} mimeType={items.thumbnailMimeType} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} alt={''} className={`viewmus-image ${this.props.className}`} mute={true} currentTime={4} paused={audio && audio.paused} />

	}
	else {
		return <div className='col-md-12'>
			<div className='mtartimg'>
				<img src={(items && items.thumbnailHash) ? items.thumbnailHash : `/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} alt={''} className={`viewmus-image ${this.props.className}`} />

			</div>
			<div className='mtartimg mtmirrorbg'>
				<div className='mtartmirror'>
					<div className='mtartminheight'>
						<img src={(items && items.thumbnailHash) ? items.thumbnailHash : `/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} alt={''} className={`viewmus-image ${this.props.className}`} />
					</div>
				</div>
			</div>

		</div>

	}

}
gotoVideo = () => {
	let audio = this.buttonRef.current;
	if (audio && audio.paused) {
		this.setState({
			isVideoPlay: false
		})
	} else {
		this.setState({
			isVideoPlay: true
		})
	}
	this.setState({
		isAudio: false,
		audioTime: audio && audio.currentTime,
	})

}
gotoMusic = () => {
	let audio = this.buttonRef.current;
	this.setState({
		isAudio: true,
		audioTime: audio && audio.currentTime
	})
}
render() {
	const { play, page, isAudio } = this.state
	const { sharedData } = this.props
	let scrnWidth = document.getElementById("articonstatic") && document.getElementById("articonstatic").offsetWidth

	if (page === 1) {
		if (isAudio) {
			return (
				<Fragment>

					<div className="mustheart">
						<div id="mp3_player" className='mp3_player'>
							<canvas
								ref={el => this.el = el}
								id="analyzer"
							>
							</canvas>
							<div id="audio_box">
								<audio
									ref={this.buttonRef}
									controls={this.props.showControls} loop={this.props.loop}
									preload={"auto"}
									id="player"
								// hidden={true}
								>
									<source src={this.props.source} type={this.props.mimeType} />
								</audio>
							</div>

						</div>
						<div className="container1">

							<div className='row mb-5 text-left'>
								<div className='col-md-12'>
									<div className="mtpd">
										<div className="mtplaybtn" onClick={this.playVideo} id='playButton' title={play ? "Pause audio" : "Play audio"}>{play ? <i aria-hidden="true" className="fa fa-pause"></i> : <i aria-hidden="true" className="fa fa-play"></i>}</div>

										{/* <div className="mtplaybtn" onClick={this.props.pauseVideo}  id='pauseButton'><i aria-hidden="true" className="fa fa-pause"></i></div> */}
										<div className="mtpdetail">
											<h5>

												{(sharedData && sharedData.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
												{(sharedData && sharedData.imageMimeType && sharedData.imageMimeType.includes('video')) ? <span className="mr-3 spancursor " onClick={this.gotoVideo} title="Switch to video">{<i aria-hidden="true" className="fa fa-video-camera"></i>}</span> : ''}

												{sharedData && sharedData.title ? sharedData.title : ''}
											</h5>
											{this.creatorFullName(sharedData)}
										</div>
									</div>
									{/* <div className="purchasebox">
											<div className='row align-items-center mb-3'>
												<div className='col-md-8'>
													<div className='mtauser'>
														<div className="picpro bgrainbow">
															<span className="artcovimg">
																<span className="artcov "></span>
																<span className="artcovimginset no-proimg ">
																	{getCreatorImage(sharedData)}
																</span>
															</span>
														</div>
														{getCreatorName(sharedData)}
													</div>
												</div>
												<div className='col-md-4 text-right'>
												</div>
											</div>
											<p>{sharedData && sharedData.description ? sharedData.description : ''}</p>

										</div> */}
								</div>
								{this.showPreview(sharedData)}
							</div>

						</div>
					</div>

				</Fragment>
			)
		} else {
			return (<Fragment>
				{/* <div className="container">

						<div className='row mb-5 text-left'>
							<div className='col-md-2'>
								<div className="mtpd"> */}

				{/* <div className="mtplaybtn" onClick={this.gotoMusic} id='musicButton'>{<i aria-hidden="true" className="fa fa-music"></i>}</div> */}
				{/* </div>

							</div>
							<div className='col-md-10 text-right'> */}
				<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}>
					{(sharedData && sharedData.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
					<span className="articoni"><i className="fa fa-video-camera" aria-hidden="true"></i></span>
					<VideoPreview source={sharedData && sharedData.imageHash} mimeType={this.props.mimeType} className={this.props.className} width={this.props.width} showControls={this.props.showControls} autoPlay={this.props.autoPlay} currentTime={this.state.audioTime} mute={false} /></span>
				{/* </div>
						</div>
					</div> */}
			</Fragment>
			)
		}
	} else {
		return (
			<Fragment>

				<div className="mustheart">
					{/* <div className="pagetitabtn">
						<div className="probtns activebtnscus py-0 row"><div className="col-md-6"> <h1 className="mb-3 d-inline-block">Music Theme</h1></div>
						</div>
					</div> */}

					<div className="container">

						<div className='row mb-5'>
							<div className='col-md-12'>
								<div className="mtpd">
									<div className="mtplaybtn" onClick={this.playVideo} id='playButton'>{play ? <i aria-hidden="true" className="fa fa-pause"></i> : <i aria-hidden="true" className="fa fa-play"></i>}</div>
									{/* <div className="mtplaybtn" onClick={this.props.pauseVideo}  id='pauseButton'><i aria-hidden="true" className="fa fa-pause"></i></div> */}
									<div className="mtpdetail">
										<h5>Domino</h5>
										<h3>Everblades</h3>
									</div>
								</div>
								<div className="purchasebox">
									<div className='row align-items-center mb-3'>
										<div className='col-md-8'>
											<div className='mtauser'>
												<div className="picpro">
													<span className="artcovimg">
														<span className="artcov "></span>
														<span className="artcovimginset no-proimg ">
															<img src="https://devapi.disrupt.art/./profileUpload/profileImage/dhilip.may27us-profileImage-1658401351507.webp" alt="" />
														</span>
													</span>
												</div>
												<h2 className="usernameClick">@domino</h2>
											</div>
										</div>
										<div className='col-md-4 text-right'>
											<span className='text-secondary'>1 month ago</span>
										</div>
									</div>
									<p>This song means another world to me. I listen to It when I need a reminder that we are all human… And life is worth living. -- <br /><br />
										Produced by Explore and KEV</p>

									<span className='badge badge-primary text-uppercase mt-3'>FROM THE ARTIST</span>
								</div>
							</div>
							<div className='col-md-12'>
								<div className='mtartimg circart'>
									<ArtPreview src={"/assets/images/collection2.jpg"} alt={''} />
									<div id="mp3_player" className='mp3_player'>
										<canvas
											ref={el => this.el = el}
											id="canvas"
										>
										</canvas>

										<div id="analyser">
											<audio
												ref={audio => this.audio = audio}
												controls={true} loop={false}
											>
												<source src="https://ipfs.perma.store/content/bafybeifr5yhaivudny4ezaplyxf3tvtr7aqqkr26vswrq3ku2btv7bwoca" type={"audio/mpeg"} />
											</audio>
										</div>

									</div>
								</div>
							</div>
						</div>

					</div>
				</div>

			</Fragment>
		)
	}
}
}
export { MusicTheme }