import { configPath } from '../config';
import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAction } from 'store/actions';
import ReCAPTCHA from "react-google-recaptcha"
import GoogleLogin from 'react-google-login';
import { Helmet } from 'react-helmet';
import { localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { userServices } from 'services';
import { WebSignUp } from './WebSignup';
import { WebLoginPage } from './WebLogin';

var timeout;
class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            username: '',
            email: '',
            password: '',
            isAgree: false,
            submitted: false,
            repassword: '',
            reCaptchaToken: '',
            isSuccess: false,
            isAlert: false,
            message: '',
            style: '',
            isUsernameError : '',
            isUserLoading : false,
            isValidUsername : false,
            isEmailError : '',
            isEmailLoading : false,
            isValidEmail : false,
            isUserTyping : false,
            isEmailTyping : false,
            logsign: true   
        }
        // this.recaptchaRef = React.createRef()

    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({ isSuccess: false })
    

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            if (props && props.regRes && props.regRes.statusCode === 200) {
                if(props && props.regRes && props.regRes.data && props.regRes.data.id){
                history.push(`/registration/user/${props.regRes.data.uuid}/verify`)
                }
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                props.resetRegister()
                return {
                    fullname: '',
                    username: '',
                    password: '',
                    email: '',
                    isAgree: false,
                    submitted: false,
                    isSuccess: true,
                    reCaptchaToken: '',
                    isAlert: false,
                    message: '',
                    style: ''
                }
            }
            if ((props && props.failedReg && state.submitted)) {
                // props.resetRegFailure()
                if ((props && props.failedReg) === 'userName should contain only A-Z, a-z, 0-9 and Period symbol(.)') {
                    if(history && history.location && history.location.pathname.includes('signup')){
                        return {
                         isAlert: true,
                         message: 'Username should contain only a-z, 0-9 and period symbol(.)',
                         style: "danger"
                     }
                     }else{
                        props.updateRegError('Username should contain only a-z, 0-9 and period symbol(.)')
 
                     }

                   
                }
                else if ((props && props.failedReg) === 'fullName must be at least 3 characters long. And fullName must not contain leading and trailing spaces.') {
                    if(history && history.location && history.location.pathname.includes('signup')){
                        return {
                         isAlert: true,
                         message: 'Fullname must be at least 3 characters long. And fullname must not contain leading and trailing spaces.',
                         style: "danger"
                     }
                     }else{
                        props.updateRegError('Fullname must be at least 3 characters long. And fullname must not contain leading and trailing spaces.')
 
                     }

                    
                }
                else {
                    if(history && history.location && history.location.pathname.includes('signup')){
                       return {
                        isAlert: true,
                        message: props && props.failedReg,
                        style: "danger"
                    }
                    }else{
                        props.updateRegError(props && props.failedReg)

                    }

                    
                }
            }
        }
    }
    componentDidUpdate(props, state) {
        if ((props && props.failedReg)) {
            props.resetRegFailure()
            this.gotoTop()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    
    handleClose = () => {
        this.setState({
            isSuccess: false
        })
        history.push('/login')
    }

  
    updateLogError = (message) => {
        console.log(message)
        this.setState({ isAlert: true, message: message, style: "danger" })
        this.gotoTop()
    }
    updateLogSuccess = (message) => {
        this.setState({ isAlert: true, message: message, style: "success" })
        this.gotoTop()
    }
    updateRegError = (message) => {
        this.setState({ isAlert: true, message: message, style: "danger" })
        this.gotoTop()
    }
    updateRegSuccess = (message) => {
        this.setState({ isAlert: true, message: message, style: "success" })
        this.gotoTop()
    }


    handleUpdate = () => {
        this.setState({logsign: !this.state.logsign})
    }

    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        const path = history && history.location && history.location.pathname

        return (
            <Fragment>
                <Helmet>
                    <title>Sign Up</title>
                    <meta name="title" content="Sign Up"></meta>
                    <meta name="description" content={`Create a new account on ${process.env.REACT_APP_MARKETPLACE}`}></meta>
                </Helmet>
                {/* {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert}/>} */}
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

                {!this.state.isSuccess &&
                <div className="container logsign my-5">
                <div className='row'>
                <div className='col-md-6'>
                    <WebSignUp updateRegError={(value) => { this.updateRegError(value) }} updateRegSuccess={(value) => { this.updateRegSuccess(value) }} />
                    </div>
                    <div className='col-md-6'>
                            <div className="accordion logacordbtn" id="accordionExample">
                                    <div className={this.state.logsign ?  "card-header align-items-center d-flex flex-column" : "card-header align-items-center d-flex flex-column  d-nonecus"} >
                                        <h2 className="mb-3">Already a Disruptor?</h2>
                                            <button onClick = {this.handleUpdate} className="btn btn-primary-gradient px-5" type="button" id="headingOne"  data-toggle="collapse" data-target="#collapseSignup" aria-expanded="false" aria-controls="collapseSignup">
                                                Login
                                            </button>                                       
                                    </div>
                                    <div id="collapseSignup" className="collapse" aria-labelledby="headingOne" >
                                        <div className="card-body p-0">
                                            
                                                <WebLoginPage updateLogError={(v) => {this.updateLogError(v)}} updateLogSuccess={(v) => {this.updateLogSuccess(v)}}/>

                                            
                                        </div>
                                    </div>
                            </div>
                        </div>
                </div>
            </div>

                }
                {this.state.isSuccess && <div>
                    <div style={{ margin: "30px" }}>
                        <div className="success-pop">
                            <div className="mb-4">
                                <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                            </div>
                            <div className="text-center">
                                <div>
                                    <h4>Thanks for joining with DisruptArt</h4>
                                </div>
                                <div>
                                    <h5>
                                        Kindly check your email inbox and click the verification link.
                                </h5>
                                </div>
                                <button className="btn btn-primary" onClick={this.handleClose}>Login</button>

                            </div>
                        </div>
                    </div>
                </div>}

            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        regRes: state.user.regRes,
        failedReg: state.user.failedReg
    }
}

const actionCreators = {
    register: userAction.register,
    googleLogin: userAction.googleLogin,
    resetRegister: userAction.resetRegister,
    resetRegFailure: userAction.resetRegFailure
}

const connectRegister = connect(mapStateToProps, actionCreators)(Register)

export { connectRegister as Register }