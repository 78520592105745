import { adminConstants } from "../../constants";

const initialState = {
    admins: [],
    addedAccount: [],
    addedAccountFailed: '',
    enabledAccount: [],
    disabledAccount: [],
    abusereportList : [],
    reviewAbuseStatus : [],
    adminUser : [],
    resetRes : [],
    changePasswordRes : [],
    dropHistory : [],
    dropHistoryDetail : [],
    dropHistoryrelisted : [],
    dropHistoryupdated : [],
    allStores : [],
    storeDetail : [],
    storeSalesDetail : [],
    addedBadge: [],
    updatedBadge: []
}
export function adminaccount(state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_ADMIN_ACCOUNT:
            return {
                ...state,
                admins: action.admins,
            }
        case adminConstants.ADD_ADMIN_ACCOUNT:
            return {
                ...state,
                addedAccount: action.addedAccount
            }
        case adminConstants.ADD_ADMIN_ACCOUNT_FAILED:
            return {
                ...state,
                addedAccountFailed: action.addedAccountFailed
            }
        case adminConstants.ENABLE_ADMIN_ACCOUNT:
            return {
                ...state,
                enabledAccount: action.enabledAccount
            }
        case adminConstants.DISABLE_ADMIN_ACCOUNT:
            return {
                ...state,
                disabledAccount: action.disabledAccount
            }
        case adminConstants.GET_ABUSE_REPORT:
            return {
                ...state,
                abusereportList: action.abusereportList
            }
        case adminConstants.REVIEW_ABUSE_REPORT:
            return {
                ...state,
                reviewAbuseStatus: action.reviewAbuseStatus
            }
        case adminConstants.GET_ADMIN_USER:
            return {
                ...state,
                adminUser : action.adminUser
            }
        case adminConstants.ADMIN_RESET_PASSWORD:
            return {
                ...state,
                resetRes : action.resetRes
            }
        case adminConstants.ADMIN_CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordRes : action.changePasswordRes
            }
        case adminConstants.GET_LIST_DROP_HISTORY:
            return {
                ...state,
                dropHistory : action.dropHistory
            }
        case adminConstants.GET_HISTORY_DETAIL:
            return {
                ...state,
                dropHistoryDetail : action.dropHistoryDetail
            }
        case adminConstants.DROP_HISTORY_RELISTING:
            return {
                ...state,
                dropHistoryrelisted : action.dropHistoryrelisted
            }
        case adminConstants.DROP_HISTORY_RELIST_SUCCESS_UPDATE:
            return {
                ...state,
                dropHistoryupdated : action.dropHistoryupdated
            }
        case adminConstants.GET_STORES:
            return {
                ...state,
                allStores : action.allStores
            }
        case adminConstants.GET_STORE_DETAIL:
            return {
                ...state,
                storeDetail : action.storeDetail
            }
        case adminConstants.GET_STORE_SALES_DETAIL:
            return {
                ...state,
                storeSalesDetail : action.storeSalesDetail
            }
        case adminConstants.ADD_BADGES:
            return {
                ...state,
                addedBadge : action.addedBadge
            }
        case adminConstants.UPDATE_BADGES:
            return {
                ...state,
                updatedBadge : action.updatedBadge
            }
        default:
            return state
    }
}