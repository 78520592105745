export const storeCreatorConstants = {
    UPDATE_STORE_CREATOR_STATUS : 'UPDATE_STORE_CREATOR_STATUS',
    ADD_STORE_CREATOR_PROFILE : 'ADD_STORE_CREATOR_PROFILE',
    STORE_CREATOT_PROFILE_FAILED : 'STORE_CREATOT_PROFILE_FAILED',
    GET_STORE_CREATOR_PROFILE : 'GET_STORE_CREATOR_PROFILE',
    GET_STORE_PROFILE_FAILED : 'GET_STORE_PROFILE_FAILED',
    STORE_SEARCH_ARTS : 'STORE_SEARCH_ARTS',
    STORE_SEARCH_ARTS_FAILED : 'STORE_SEARCH_ARTS_FAILED',
    GET_USERS_ARTS : 'GET_USERS_ARTS',
    GET_USERS_ARTS_FAILED : 'GET_USERS_ARTS_FAILED',

    STORE_LIST_ARTS_FOR_SALE : 'STORE_LIST_ARTS_FOR_SALE',
    STORE_LIST_ARTS_FOR_SALE_FAILED : 'STORE_LIST_ARTS_FOR_SALE_FAILED',

    STORE_CREATE_SECTION : 'STORE_CREATE_SECTION',
    STORE_CREATE_SECTION_FAILED : 'STORE_CREATE_SECTION_FAILED',
    STORE_GET_SECTIONS : 'STORE_GET_SECTIONS',
    STORE_GET_SECTIONS_FAILED : 'STORE_GET_SECTIONS_FAILED',

    STORE_ADD_ARTS_SECTION : 'STORE_ADD_ARTS_SECTION',
    STORE_ADD_ARTS_SECTION_FAILED : 'STORE_ADD_ARTS_SECTION_FAILED',
  
    GET_SECTION1_ARTS : 'GET_SECTION1_ARTS',
    GET_SECTION_ARTS_FAILED : 'GET_SECTION_ARTS_FAILED',

    STORE_DELETE_ARTS_SECTION : 'STORE_DELETE_ARTS_SECTION',
    STORE_DELETE_ARTS_SECTION_FAILED : 'STORE_DELETE_ARTS_SECTION_FAILED',

    STORE_GET_USERS_DROPS_LIST : 'STORE_GET_USERS_DROPS_LIST',
    STORE_GET_USERS_DROPS_LIST_FAILED : 'STORE_GET_USERS_DROPS_LIST_FAILED',

    STORE_GET_ART_DETAIL : 'STORE_GET_ART_DETAIL',
    STORE_GET_ART_DETAIL_FAILED : 'STORE_GET_ART_DETAIL_FAILED',

    STORE_WITHDRAW_ARTS : 'STORE_WITHDRAW_ARTS',
    STORE_WITHDRAW_ARTS_FAILED : 'STORE_WITHDRAW_ARTS_FAILED',

    STORE_GET_BANNER_DATA : 'STORE_GET_BANNER_DATA',
    STORE_GET_BANNER_DATA_FAILED : 'STORE_GET_BANNER_DATA_FAILED',

    STORE_ADD_BANNER_DATA : 'STORE_ADD_BANNER_DATA',
    STORE_ADD_BANNER_DATA_FAILED : 'STORE_ADD_BANNER_DATA_FAILED',

    STORE_DELETE_BANNER : 'STORE_DELETE_BANNER',
    STORE_DELETE_BANNER_FAILED : 'STORE_DELETE_BANNER_FAILED',

    STORE_GET_SOCIAL_DEFINITION : 'STORE_GET_SOCIAL_DEFINITION',
    STORE_GET_SOCIAL_DEFINITION_FAILED : 'STORE_GET_SOCIAL_DEFINITION_FAILED',

    STORE_GET_SALEHISTORY : 'STORE_GET_SALEHISTORY',
    STORE_GET_SALEHISTOTY_FAILED : 'STORE_GET_SALEHISTOTY_FAILED',

    STORE_GET_SECTION_DETAIL : 'STORE_GET_SECTION_DETAIL',
    STORE_GET_SECTION_DETAIL_FAILED : 'STORE_GET_SECTION_DETAIL_FAILED',

    STORE_SUBMIT_SECTION_FORM : 'STORE_SUBMIT_SECTION_FORM',
    STORE_SUBMIT_SECTION_FORM_FAILED : 'STORE_SUBMIT_SECTION_FORM_FAILED',

    STORE_DELETE_SOCIAL_MEDIA_MAP_LINK : 'STORE_DELETE_SOCIAL_MEDIA_MAP_LINK',
    STORE_DELETE_SOCIAL_MEDIA_MAP_LINK_FAILED : 'STORE_DELETE_SOCIAL_MEDIA_MAP_LINK_FAILED',

    STORE_UPDATE_FEATURED_DROPS_ARTS : 'STORE_UPDATE_FEATURED_DROPS_ARTS',
    STORE_UPDATE_FEATURED_DROPS_ARTS_FAILED : 'STORE_UPDATE_FEATURED_DROPS_ARTS_FAILED',

    STORE_REMOVE_FEATURED_STATUS_DROP : 'STORE_REMOVE_FEATURED_STATUS_DROP',
    STORE_REMOVE_FEATURED_STATUS_DROP_FAILED : 'STORE_REMOVE_FEATURED_STATUS_DROP_FAILED',

    STORE_GET_SECTION_GALLERY_ITEM : 'STORE_GET_SECTION_GALLERY_ITEM',
    STORE_GET_SECTION_GALLERY_ITEM_FAILED : 'STORE_GET_SECTION_GALLERY_ITEM_FAILED',

    STORE_POST_SECTION_GALLERY_ITEM : 'STORE_POST_SECTION_GALLERY_ITEM',
    STORE_POST_SECTION_GALLERY_ITEM_FAILED : 'STORE_POST_SECTION_GALLERY_ITEM_FAILED',

    STORE_DELETE_SECTION_GALLERY_ITEM : 'STORE_DELETE_SECTION_GALLERY_ITEM',
    STORE_DELETE_SECTION_GALLERY_ITEM_FAILED : 'STORE_DELETE_SECTION_GALLERY_ITEM_FAILED',

    STORE_GET_SECTION_TIMELINE_ITEM : 'STORE_GET_SECTION_TIMELINE_ITEM',
    STORE_GET_SECTION_TIMELINE_ITEM_FAILED : 'STORE_GET_SECTION_TIMELINE_ITEM_FAILED',

    STORE_GET_META_TAGS : 'STORE_GET_META_TAGS',
    STORE_GET_META_TAGS_FAILED : 'STORE_GET_META_TAGS_FAILED',

    STORE_GET_PURCHASE_DATA : 'STORE_GET_PURCHASE_DATA',
    STORE_GET_PURCHASE_DATA_FAILED : 'STORE_GET_PURCHASE_DATA_FAILED',
   
}