import React, { Fragment } from "react";
import { connect } from "react-redux";
import { configPath } from '../config'
import { discoverActions } from "store/actions";
import { DiscoverArtPreview } from "./art-preview/discover-art-preview";
import history from "helpers/history";
import { flowDecimal } from "helpers";
import moment from "moment";
import { ArtPreview } from "./art-preview/art-preview";

class CreatorsLandingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            spotLight: {},
            featureArts: [],
            featureCreators: [],
            featureCollectors: [],
            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            offSet: 0,
            recordLimit: 4,
            artRecord: 4,
            creatorRecord: 6,
            collectorRecord: 8,
            isArtTrue: false,
            isCreatorTrue: false,
            isCollectorTrue: false,
            isSpotCheck: false,
            isFeatureArt: false,
            isCreator: false,
            isCollector: false,
            spotlightOn: true,
            show: false,

        }
    }

    //  state = {
    //             now: 0,
    //             end: 0,
    //             daysLeft: 0,
    //             hoursLeft: 0,
    //             minutesLeft: 0,
    //             secondsLeft: 0,
    //             message: '',
    //           };


    countdown() {
        const seconds = 1000;
        const minutes = seconds * 60;
        const hours = minutes * 60;
        const days = hours * 24;
        // timer function
        const countdownTimer = setInterval(() => {
            this.setState({ now: new Date().getTime() });
            // constants
            this.setState({ end: new Date('August 23, 2023 00:00:00').getTime() });
            const difference = this.state.end - this.state.now;

            if (difference < 0) {
                clearInterval(countdownTimer);
                this.setState({ message: 'Inauguration Day! 🎉' });
                return;
            }

            this.setState({ daysLeft: Math.floor(difference / days) });
            this.setState({ hoursLeft: Math.floor((difference % days) / hours) });
            this.setState({
                minutesLeft: Math.floor((difference % hours) / minutes),
            });
            this.setState({
                secondsLeft: Math.floor((difference % minutes) / seconds),
            });
        }, seconds);
    }

    componentDidMount() {
        // this.countdown();
        this.getSpotLight('', '')
        this.getFeatureArt('', '')
        // this.getFeatureCreator('', '')
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    getSpotLight = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.gotoTop()
        this.props.getAllSpotLightArts(obj)
    }
    getFeatureArt = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureArts(obj)
    }
    getFeatureCreator = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            count: 75,
            shuffle: true
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getCreators(obj)
    }
    componentDidUpdate(props, state) {
        if (this.props &&
            this.props.spotArts &&
            this.props.spotArts.statusCode == 200 &&
            (this.props.spotArts !== (props.spotArts))
        ) {

            this.props.resetSpotLight()
            this.setState({
                spotLightList: this.props && this.props.spotArts && this.props.spotArts.data && this.props.spotArts.data.entity && this.props.spotArts.data.entity,
                isSpotCheck: true
            }, () => {
                this.filterSpotLight()
            })

        }
        if (this.props &&
            this.props.featureArts &&
            this.props.featureArts.statusCode == 200 &&
            (this.props.featureArts !== (props.featureArts))) {
            props.resetFeatureArts()
            this.setState({
                featureArts: this.props && this.props.featureArts && this.props.featureArts.data && this.props.featureArts.data.entity,
                isFeatureArt: true,

            }, () => {
                this.getMultipleRandom(4)
            })

        }

    }
    filterSpotLight = () => {
        const ListedArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops[0]))
        const notInDropsArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops.length === 0))

        if (this.state.spotLightList && this.state.spotLightList.length > 0) {
            if (ListedArray && ListedArray.length > 0) {
                var item = ListedArray[Math.floor(Math.random() * ListedArray.length)];
                if (item && item.id) {
                    this.setState({ spotLight: item })
                }
            } else if (notInDropsArray && notInDropsArray.length > 0) {
                var item = notInDropsArray[Math.floor(Math.random() * notInDropsArray.length)];
                if (item && item.id) {
                    this.setState({ spotLight: item })
                }
            }
        } else {
            this.setState({ spotLight: {} })
        }


    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    getPrice = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            if (items && items.drops && items.drops[0] && items.drops[0].storefrontListingResourceId && items.drops[0].withDapper) {
                return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} USD`
            } else {
                // return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} FLOW`
                return ''
            }
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                // return `${flowDecimal(parseFloat(amount))} FLOW`
                return ''
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        // return `${flowDecimal(parseFloat(amount))} FLOW`
                        return ''
                    }

                }
            }
        } else {
            return false
        }
    }
    navigateToAirdrops = () => {
        history.push('/airdrops',{initiateScan : true})
    }
    gotoBuy = (items) => {
        if (items.id && items.drops && items.drops[0] && items.drops[0].id) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else if (items.id) {
            history.push(`/art/${items.id}`)
        }
    }
    getMultipleRandom = (num) => {
        let arr = this.state.featureArts
        const ListedArray = arr && arr.filter(el => (el && el.drops && el.drops[0]))
        const notDropArray = arr && arr.filter(el => (el && el.drops && el.drops.length === 0))

        const listedCount = ListedArray && ListedArray.length
        const remainCount = num - listedCount
        if (arr && arr.length > 0) {
            if (listedCount) {
                const shuffled1 = [...ListedArray].sort(() => 0.5 - Math.random());
                const shuffled2 = [...notDropArray].sort(() => 0.5 - Math.random());

                const randomArray1 = shuffled1.slice(0, num);
                const randomArray2 = shuffled2.slice(0, remainCount);
                const randomArray = randomArray1.concat(randomArray2)

                if (randomArray && randomArray.length > 0) {
                    this.setState({ randomDrops: randomArray })

                }
            } else {
                const shuffled = [...arr].sort(() => 0.5 - Math.random());

                const randomArray = shuffled.slice(0, num);

                this.setState({ randomDrops: randomArray })
            }
        } else {
            this.setState({ randomDrops: {} })
        }


    }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }

    }
    isDapperDrop = (items) => {
        if(items && items.drops && items.drops[0] && items.drops[0].storefrontListingResourceId && items.drops[0].withDapper){
            return true
        }else{
            return false
        }
    }
    render() {
        const { spotLight, featureArts, isFeatureArt, isCreator, randomDrops } = this.state

        return (
            <Fragment>
                <div className='shlanding'>

                    <div className='container'>

                        <div className="nclbox">
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h3 className="text-left d-none">upcoming</h3>

                                    <div className="nclcountdown d-none">
                                        <ul className="list-unstyled">
                                            <li><span id="days" className="nclcdno">{this.state.daysLeft}</span>days</li>
                                            <li><span id="hours" className="nclcdno">{this.state.hoursLeft}</span>Hours</li>
                                            <li><span id="minutes" className="nclcdno">{this.state.minutesLeft}</span>Minutes</li>
                                            <li><span id="seconds" className="nclcdno">{this.state.secondsLeft}</span>Seconds</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {(spotLight && spotLight.id) ? <div className='col-md-8'>
                                    <div className='mwconbox newlandart' onClick={() => this.gotoBuy(spotLight)} >
                                        <div className='mwconboxbg ' >
                                            <DiscoverArtPreview src={(spotLight && spotLight.thumbnailHash)} alt="" showControls={true} autoPlay={true} mimeType={spotLight && spotLight.thumbnailMimeType} />

                                            {/* <img src='https://ipfs.disrupt.art/ipfs/bafkreidhanvgk553kuc7pl7r6ilh5e6imy3tmykobh3trwistbyd25qhaa' alt="" /> */}
                                        </div>
                                    </div>
                                    <div className="newlandartcont">
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <h4 className=" spottit cursor-pointer" onClick={() => this.gotoBuy(spotLight)}>{spotLight.title}</h4>
                                                <p className="usernamelilac">{this.getPrice(spotLight)}</p>
                                            </div>
                                            <div className='col-md-4 text-right'>
                                                <a
                                                    className="usernameblue cursor-pointer"
                                                    title={spotLight && spotLight.creator && spotLight.creator.fullName}
                                                    onClick={() => this.gotoCreator(spotLight && spotLight.creator)}>{spotLight && spotLight.creator && spotLight.creator.fullName && `@${spotLight.creator.fullName}`}</a>
                                                {/* <p><span className="usernamelilac">50</span> of 50 Total Editions</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                <div className='col-md-4'>
                                    <div className='mwconbox sfld'>
                                        <div className='mwconboxbg'><img src={`../../../../${configPath.imageAssets}/Isolation_Mode.png`} alt="" /></div>
                                        <div className='mwconboxcont'>
                                            <h4>scan for local drops</h4>
                                            <a onClick={this.navigateToAirdrops} className='btn btn-primary'>Scan for Drops</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className='msh-upcoming'>
                            <div className='dandcont cc-tabs'>
                                <h3>Events</h3>
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="Active-tab" data-toggle="tab" data-target="#Active" type="button" role="tab" aria-controls="Active" aria-selected="true"> Upcoming</button>
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="Upcoming-tab" data-toggle="tab" data-target="#Upcoming" type="button" role="tab" aria-controls="Upcoming" aria-selected="false">Creations</button>
                                    </li> 
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link " id="Past-tab" data-toggle="tab" data-target="#Past" type="button" role="tab" aria-controls="Past" aria-selected="false">Past </button>
                                    </li>*/}
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane active" id="Active" role="tabpanel" aria-labelledby="Active-tab">
                                        <div className='purchasebox'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                   <a href="https://midwestcon.disrupt.art/" target="_blank"><img src={`../../../../${configPath.imageAssets}/mwc-banner-2024.png`} alt="" className='w-100' /></a>
                                                </div>
                                                <div className='col-md-9'>
                                                <a href="https://midwestcon.disrupt.art/" target="_blank"> <h4>MidwestCon2024</h4></a>
                                                    <h5>Sep 5-6, 2024</h5>
                                                    <p>Dive into the future with our curated sessions, interactive digital encounters, and thought-provoking panels. Meet the innovators, venture capitalists, corporate leaders, brands, policy influencers, pioneering researchers, and trailblazers in social impact. Embrace the urgency to shape the future at MidwestCon - crafting tomorrow, today.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane" id="Upcoming" role="tabpanel" aria-labelledby="Upcoming-tab">
                                        <div className='purchasebox'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <img src={`../../../../${configPath.imageAssets}/disruptart-envent.png`} alt="" className='w-100' />
                                                </div>
                                                <div className='col-md-9'>
                                                    <h4>Disrupt the ART</h4>
                                                    {/* <h5>September 4-7, 2023</h5> */}
                                                    <p>More coming soon</p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='purchasebox'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <img src={`../../../../${configPath.imageAssets}/mwc-banner-2024.png`} alt="" className='w-100' />
                                                </div>
                                                <div className='col-md-9'>
                                                    <h4>MidwestCon2024</h4>
                                                    {/* <h5>August 22-24, 2024</h5> */}
                                                    <p>Date to be announced</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane " id="Past" role="tabpanel" aria-labelledby="Past-tab">

                                    {/* <div className='purchasebox'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <img src={`../../../../${configPath.imageAssets}/disruptart-envent.png`} alt="" className='w-100' />
                                                </div>
                                                <div className='col-md-9'>
                                                    <h4>Disrupt the ART</h4>
                                                    <h5>September 4-7, 2022</h5>
                                                   
                                                </div>
                                            </div>

                                        </div> */}

                                        <div className='purchasebox'>
                                            <div className='row'>
                                                <div className='col-md-3'>
                                                    <img src={`../../../../${configPath.imageAssets}/mwc-banner.png`} alt="" className='w-100' />
                                                </div>
                                                <div className='col-md-9'>
                                                    <h4>MidwestCon2022</h4>
                                                    <h5>August 22-24, 2022</h5>
                                                    {/* <p>Date to be announced</p> */}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>


                        <div className="nclOD morefromarts">
                            <h3 className="text-left">Onsite Drops</h3>
                            <div className="row">
                                {((randomDrops && randomDrops.length > 0) && isFeatureArt) ? randomDrops.map((items) => (
                                    <div className="col-6 col-md-3" key={items && items.id}>
                                        <div className="cardlist" onClick={() => this.gotoBuy(items)}>
                                            {/* <span className="articonstatic" id="articonstatic" > */}
                                            <ArtPreview src={(items && items.thumbnailHash)} alt="" showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} isValue={true} />

                                            {/* </span> */}
                                            <span className="cont">
                                                <h4 onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title}</h4>
                                                {this.isDapperDrop(items) ? <span className="usernamelilac ">{this.getPrice(items)}</span> : (this.getPrice(items)) ? <span className="usernamelilac ">{this.getPrice(items)}</span> : ''}

                                                <p className="mb-0 small d-none">Location:<span className="usernamelilac ">UC Digital Futures</span></p>
                                                <p className="mb-0 small d-none">Date:<span className="small usernamelilac">08/18/2023</span></p>
                                            </span>
                                        </div>
                                    </div>)) : isFeatureArt ? <div className='col-6 offset-md-5'>
                                    <img src={`../${configPath.imageAssets}/no-art-discover.png`} alt="" className='mw-100' /></div> : ''}
                                {/* <div className="col-6 col-md-3">
                                    <div className="cardlist">
                                        <span className="articonstatic" id="articonstatic" ><img src={`../../../../${configPath.imageAssets}/event2.png`} className="comimg mb-0" /></span>
                                        <span className="cont">
                                            <h4>DMX - Brother Earl Truth Teller</h4>
                                            <p className="mb-0 small">Location:<span className="usernamelilac ">Memorial Hall</span></p>
                                            <p className="mb-0 small">Date:<span className="small usernamelilac">08/19/2023</span></p>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="cardlist">
                                        <span className="articonstatic" id="articonstatic" ><img src={`../../../../${configPath.imageAssets}/event1.png`} className="comimg mb-0" /></span>
                                        <span className="cont">
                                            <h4>Sonia Sagnchez - Portal To Our Past</h4>
                                            <p className="mb-0 small">Location:<span className="usernamelilac ">UC Digital Futures</span></p>
                                            <p className="mb-0 small">Date:<span className="small usernamelilac">08/18/2023</span></p>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <div className="cardlist">
                                        <span className="articonstatic" id="articonstatic" ><img src={`../../../../${configPath.imageAssets}/event2.png`} className="comimg mb-0" /></span>
                                        <span className="cont">
                                            <h4>DMX - Brother Earl Truth Teller </h4>
                                            <p className="mb-0 small">Location:<span className="usernamelilac ">Memorial Hall</span></p>
                                            <p className="mb-0 small">Date:<span className="small usernamelilac">08/19/2023</span></p>
                                        </span>
                                    </div>
                                </div> */}
                            </div>

                        </div>


                    </div>
                </div>
            </Fragment>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        spotArts: state.discover.spotArts,
        featureArts: state.discover.featureArts,
        featureCreators: state.discover.featureCreators,
        featureCollectors: state.discover.featureCollectors,
        randomCreatorsList: state.discover.randomCreators,
        randomCreatorsFailed: state.discover.randomCreatorsFailed
    }
}
const actionCreators = {
    getAllSpotLightArts: discoverActions.getAllSpotLightArts,
    resetSpotLight: discoverActions.resetSpotLight,
    getAllFeatureArts: discoverActions.getAllFeatureArts,
    getAllFeatureCreators: discoverActions.getAllFeatureCreators,
    getAllFeatureCollectors: discoverActions.getAllFeatureCollectors,
    resetFeatureArts: discoverActions.resetFeatureArts,
    resetFeatureCreators: discoverActions.resetFeatureCreators,
    resetFeatureCollectors: discoverActions.resetFeatureCollectors,
    getCreators: discoverActions.getAllRandomCreators,
    resetRandomCreators: discoverActions.resetRandomCreators,
    resetRandomCreatorsFailed: discoverActions.resetRandomCreatorsFailed
}
const connectCreatorsLandingPage = connect(mapStateToProps, actionCreators)(CreatorsLandingPage)
export { connectCreatorsLandingPage as CreatorsLandingPage }
