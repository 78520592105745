import { configPath } from '../config';

export const sendwyreService = {
    getWalletOrderReservation
}

function getWalletOrderReservation(walletAddress, email, fullName) {
    const body = {
        walletAddress,
        email,
        userFullName: fullName
    };
    const options = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }
    return fetch(
        `${configPath.apiUrl}/sendwyre/checkout/orders/reserve`,
        options
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 403) {
                localStorage.removeItem('adminDetail')
            }
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || data.message
            return Promise.reject(error)
        }
        return data;
    });
}
