import React, { Fragment } from "react";
import { connect } from "react-redux";
import { userAction } from "store/actions";
import { Helmet } from "react-helmet";
import { configPath } from "../config";
import history from 'helpers/history';
import { Link } from "react-router-dom";
import { isWebLogin } from "helpers";

class SocialUserProfileSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            username: '',
            email: '',
            submitted: false,
            isAlert: false,
            message: '',
            style: '',
            token: '',
            userNameLength: 4,
            invalidLinkError: false,
            isLinkExpired: false
        };
        this.timeout = null;
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { username, token } = this.state;
        if (
            !!username &&
            !!token &&
            username.length >= this.state.userNameLength
        ) {
            this.props.setupSocialUserProfile(username, token)
        } else {
            this.setState({ isAlert: true, message: 'Username required', style: "danger" })
            this.gotoTop()
        }
    }

    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: (e.target.value).toLowerCase() })
    }

    checkusername = (value) => {
        if ((value && value.length) >= this.state.userNameLength) {
            return true
        } else {
            return false
        }
    }

    closeAlert = () => {
        this.timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }

    resetTimeout = () => {
        clearTimeout(this.timeout)
    }

    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    getErrorTemplate(message = "Invalid link") {
        return (
            <Fragment>
                <Helmet>
                    <title>Setup profile - {message}</title>
                </Helmet>
                <div className="container mt-5 mb-3">
                    <div className="row">
                        <div className="col-md-4 offset-md-4 text-center">
                            <p>
                                <img src={`../${configPath.imageAssets}/errorcommon.png`} className="mw-100" alt="" />
                            </p>
                            <h5 className="mb-1">{message}</h5>
                            <p>
                                <Link to="/" className="btn btn-primary mt-5">Go Home</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    componentDidMount() {
        if(!isWebLogin()){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const email = decodeURIComponent(urlParams.get('email'));
        const name = decodeURIComponent(urlParams.get('name'));
        const token = decodeURIComponent(urlParams.get('token'));
        if (email !== 'null' && name !== 'null' && token !== 'null') {
            // console.log(email, name, token);
            this.setState({
                email,
                fullname: name,
                token,
                invalidLinkError: false
            })
        } else {
            this.setState({
                invalidLinkError: true
            })
        }
    }else{
        history.push('/')
    }
    }

    componentDidUpdate(props, state) {
        if ((props && props.failedRes)) {
            props.resetLoginFailure()
            this.resetTimeout()
            this.gotoTop()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            if (
                (props && props.webLogRes && props.webLogRes.statusCode) === 200
            ) {
                props.resetWebLogin()
                state && state.artId ? history.push(state.artId, { isFromLogin: true }) : state && state.creatorUrl ? history.push(state.creatorUrl, { isFromLogin: true }) : history.push('/')
                return {
                    username: '',
                    submitted: false,
                    isAlert: false,
                    message: '',
                    style: '',
                    userNameLength: 4
                }
            }
            if ((props && props.failedRes && state.submitted)) {
                // console.log('Response failed', props.failedRes);
                if (props.failedRes === 'userName should contain only A-Z, a-z, 0-9 and Period symbol(.)') {
                    return {
                        isAlert: true,
                        message: 'User name should contain only a-z, 0-9 and period symbol(.)',
                        style: "danger",
                        isLinkExpired: false,
                        invalidLinkError: false
                    }
                } else if (
                    props.failedRes === 'Token has expired' ||
                    props.failedRes === 'Failed to verify token'
                ) {
                    return {
                        isAlert: false,
                        message: 'The link has expired. Please login again',
                        isLinkExpired: true,
                        invalidLinkError: false
                    }
                } else if (
                    props.failedRes === 'Unhandled error has occured'
                ) {
                    return {
                        isAlert: false,
                        message: 'Unexpected error has occured. Please login again',
                        isLinkExpired: true,
                        invalidLinkError: false
                    }
                }else if(props.failedRes==='Your profile has already been setup. You can login with your google account'){
                    return {
                        isAlert: false,
                        message: props.failedRes,
                        isLinkExpired: true,
                        invalidLinkError: false
                    }
                } else {
                    return {
                        isAlert: true,
                        message: props.failedRes,
                        style: "danger",
                        isLinkExpired: false,
                        invalidLinkError: false
                    }
                }
            }
        }
        return null;
    }

    render() {
        if (this.state.invalidLinkError) {
            return this.getErrorTemplate();
        } else if (this.state.isLinkExpired) {
            return this.getErrorTemplate(this.state.message)
        }
        return (
            <Fragment>
                <Helmet>
                    <title>Setup profile</title>
                    <meta name="title" content="Complete account setup"></meta>
                    <meta name="description" content={`Complete account setup`}></meta>
                </Helmet>
                {
                    this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                        <div className="d-flex flex-row align-items-center h-100 w-100">
                            <span className="artmsg">{this.state.message}</span>
                            <span className="closebtn" onClick={this.hideAlert}>&times;</span>
                        </div>
                    </div>
                }
                <h1 className="text-center mt-5 mb-3">Welcome to DisruptArt</h1>
                <h4 className="text-center mt-2 mb-3">Setup your profile to continue</h4>

                <div className="purchasebox1 p-3 reglogfrm">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label for="username">User name</label>
                            <input type="text" name="username" id="username" onChange={this.handleInputChange} value={this.state.username} className="form-control" placeholder="Enter username here" />
                            {this.state.submitted && !this.state.username && <div className="mandatory">Username is required</div>}
                            {this.state.submitted && this.state.username && !this.checkusername(this.state.username) && <div className="mandatory">username must be contain atleast {this.state.userNameLength} characters</div>}
                            {!this.state.submitted && this.state.username && !this.checkusername(this.state.username) && <div className="mandatory-req">{"Must contain at least 4 characters"}</div>}
                        </div>
                        <div className="form-group">
                            <label>Full name</label>
                            <div className="purchasebox p-3">
                                <div className="">{this.state.fullname}</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email Address</label>
                            <div className="purchasebox p-3">
                                <div className="">{this.state.email}</div>
                            </div>
                        </div>
                        <div className="form-group text-center">
                            <button type="submit" className="btn btn-primary px-5">Submit</button>
                        </div>

                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        webLogRes: state.user.webLogRes,
        failedRes: state.user.failedRes
    }
}

const actionCreators = {
    setupSocialUserProfile: userAction.setupSocialUserProfile,
    resetLoginFailure: userAction.resetLoginFailure,
    resetWebLogin: userAction.resetWebLogin,
}

const connectSetupSocialUserProfile = connect(mapStateToProps, actionCreators)(SocialUserProfileSetup)

export { connectSetupSocialUserProfile as SocialUserProfileSetup }
