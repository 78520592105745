import React from "react";
import { configPath } from "../../../config"

export class DraggableTredingList extends React.Component {
    constructor(props) {
        super(props);
        // console.log("Props on constructor", props);
        this.state = {
            displayData: [],
            id: Date.now()
        };
        this.listContainerRef = React.createRef();
        this.dragEndHandler = this.dragEndHandler.bind(this);
        this.dragOverHanlder = this.dragOverHanlder.bind(this);
        this.dragStartHandler = this.dragStartHandler.bind(this);
    }

    onOrderChanged(orderedElemets) {
        const newOrder = orderedElemets.map(item => {
            return this.state.displayData.find(datum => datum.text === item.textContent)
        });
        this.setState({
            displayData: newOrder
        });

        if (this.props.onOrderChanged) {
            this.props.onOrderChanged(this.state.displayData);
        }
    }

    dragStartHandler(e) {
        e.target.classList.add('dragging')
    }
    dragEndHandler(e) {
        e.target.classList.remove('dragging')
    }

    dragOverHanlder(e) {
        e.preventDefault();
        const dropzone = this.listContainerRef.current;
        const dragging = dropzone.querySelector('.dragging');
        const dropAfterElement = this.getDropAfterElement(dropzone, e.clientY);
        if (dropAfterElement === null) {
            dropzone.appendChild(dragging);
        } else {
            dropzone.insertBefore(dragging, dropAfterElement);
        }
        const listAfterChange = Array.from(dropzone.children);
        let hasOrderChanged = false
        for (let i = 0; i < listAfterChange.length; i++) {
            if (this.state.displayData[i].text !== listAfterChange[i].textContent) {
                hasOrderChanged = true;
                break;
            }
        }

        if (hasOrderChanged) {
            this.onOrderChanged(listAfterChange);

        }
    }

    getDropAfterElement(dropContainer, yPos) {
        const draggables = [...dropContainer.querySelectorAll('.draggable-item')];
        return draggables.reduce((closest, draggable) => {
            const bb = draggable.getBoundingClientRect();
            const offset = yPos - bb.top - bb.height / 2;
            if (offset < 0 && offset > closest.offset) {
                return {
                    offset,
                    element: draggable
                }
            }
            return closest;
        }, {
            offset: Number.NEGATIVE_INFINITY,
            element: null
        }).element;
    }

    componentDidMount() {
        if (this.props.data) {
            const displayData = Array.from(this.props.data)
            this.setState({
                displayData
            });
        }

        if (this.props.id) {
            this.setState({
                id: this.props.id
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (
            props.data &&
            Array.isArray(props.data)
        ) {
            // console.log('display data update', props.data);
            return {
                displayData: Array.from(props.data)
            }
        }
    }

    render() {
        const { displayData } = this.state;
        // console.log('Display data on render', displayData, this.state.displayData);
        // console.log('Component id', this.state.id);
        const draggables = displayData.map((datum) => {
            return (
                <div
                    className="draggable-item"
                // [TODO] remove comments when implementing 
                // Drag & Drop Api
                // draggable={true}
                // key={datum?.id}
                // onDragStart={this.dragStartHandler}
                // onDragEnd={this.dragEndHandler}
                >
                    {datum?.text}&nbsp;
                    {
                        datum.subText?
                        <small className="font-italic">{datum.subText}</small>:
                        ''
                    }
                    <div className="float-right">
                        <i className="fas fa-times"
                            onClick={(e) => this.props?.onItemDelete(datum, e)}
                        ></i>
                    </div>
                    <div className="clearfix"></div>
                </div>
            )
        });
        let content = null;
        if (this.props.isLoading) {
            content = (
                <div className="loading">
                    <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} alt="loader" />
                </div>
            );
        } else {
            content = draggables
        }
        return (
            <div
                className="drag-drop-container"
                // [TODO] remove comments when implementing 
                // Drag & Drop Api
                // onDragOver={this.dragOverHanlder}
                id={this.state.id.toString()}
                ref={this.listContainerRef}
            >
                {content}
            </div>
        )
    }
}