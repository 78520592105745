import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { adminActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import { toastr } from 'helpers';
import history from 'helpers/history';

class AddAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            submitted: false,
            email: '',
            password: '',
            repassword: '',
            isSend: false
        }
        this.fullNameMinLength = 8
        this.fullNameMaxLength = 100
    }
    componentDidMount() {
       
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props, state) {


    }
    componentDidUpdate(props,state){
        if(this.props && 
            this.props.addsuccess &&
            this.props.addsuccess.statusCode === 201 &&
            (this.props.addsuccess !== props.addsuccess)){
                this.props.resetAddAccountSuccess()
                toast.success("Admin created successfully")
                history.goBack()
            }
            
    }
    gotoBack = () => {
        history.goBack()
    }
    onFullNameChange = (e) => {
        this.setState({ fullName: e.target.value })
    }
    onEmailChange = (e) => {
        this.setState({ email: e.target.value })
    }
    onPasswordChange = (e) => {
        this.setState({ password: e.target.value })
    }
    onRepasswordChange = (e) => {
        this.setState({ repassword: e.target.value })
    }
    handleChecked = (value) => {
        const { isSend } = this.state
        const isSended = isSend ? false : true
        this.setState({ isSend: isSended })
        console.log(value)
    }
    checkPassword = (value) => {
        if ((value && value.length) >= 8) {
            return true
        } else {
            return false
        }
    }
    checkConfirmPassword = () => {
        const { password, repassword } = this.state
        if (password == repassword) {
            return true
        } else {
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= (this.fullNameMaxLength)) && ((value && value.length) >= (this.fullNameMinLength))) {
            return true
        } else {
            return false
        }
    }
    checkFullNameLength = (value) => {
        if (((value && value.length) > 100)) {
            return true
        } else {
            return false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { fullName, email, password, repassword, isSend } = this.state
        if (fullName && email && password && repassword) {
            if (this.checkFullName(fullName)) {
                if (this.checkemail(email)) {
                    if (this.checkPassword(password)) {
                        let obj = {
                            email:email,
                            displayName : fullName,
                            password : password
                        }
                        this.props.addAccount(JSON.stringify(obj))

                    }
                } else {
                    toast.error("Please enter proper email address")
                }
            }else{
                toast.error(`Fullname must be atleast ${this.fullNameMinLength} characters and atmost ${this.fullNameMaxLength} characters`)
            }
        } else {
            toast.error("Please fill out mandatory fileds")
        }
    }
    render() {
        return (
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-5 admintitle">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">Create Admin Account <span className="betaLink">(Soon)</span></h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}>Back</button>  </div>

                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="fullName " className="font-weight-bold" for="fullName">Full name &nbsp;*</label>
                                <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Enter full name" value={this.state.fullName} onChange={this.onFullNameChange} />
                                {this.state.submitted && !this.state.fullName && <div className="mandatory">{"Fullname required"}</div>}
                                {<div className="mandatory-req">{`Min ${this.fullNameMinLength} - Max ${this.fullNameMaxLength}`}</div>}
                                {this.state.fullName && this.checkFullNameLength(this.state.fullName) && <div className="mandatory">Fullname must be contain atleast {this.fullNameMinLength} characters and atmost {this.fullNameMaxLength} characters</div>}

                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="email " className="font-weight-bold" for="email">Email &nbsp;*</label>
                                <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="Enter email" value={this.state.email} onChange={this.onEmailChange} />
                                {this.state.submitted && !this.state.email && <div className="mandatory">{"Email required"}</div>}
                                {this.state.submitted && this.state.email && !this.checkemail(this.state.email) && <div className="mandatory">Invalid Email</div>}

                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="password " className="font-weight-bold" for="password">Password &nbsp;*</label>
                                <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Enter password" value={this.state.password} onChange={this.onPasswordChange} />
                                {this.state.submitted && !this.state.password && <div className="mandatory">{"Password required"}</div>}
                                {!this.state.submitted && !this.checkPassword(this.state.password) && <div className="mandatory-req">{"Min 8 characters"}</div>}
                                {this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory">Password must be contain atleast 8 characters</div>}

                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="repassword " className="font-weight-bold" for="repassword">Re - Enter Password &nbsp;*</label>
                                <input type="password" className="form-control" id="repassword" aria-describedby="repassword" placeholder="Re-enter password" value={this.state.repassword} onChange={this.onRepasswordChange} disabled={!(this.checkPassword(this.state.password))} />
                                {this.state.submitted && !this.state.repassword && <div className="mandatory">{"Re-enter password required"}</div>}
                                {this.state.submitted && this.state.repassword && (this.state.repassword != this.state.password) && <div className="mandatory">{"Password did not match"}</div>}
                                {!this.state.submitted && this.state.repassword && (this.state.repassword != this.state.password) && <div className="mandatory-req">{"Password did not match"}</div>}
                            </div>

                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.isSend} onChange={() => this.handleChecked(this.state.isSend)} />
                                {/* <label className="form-check-label" htmlFor="exampleCheck1"> Send login info via email</label> */}
                            </div>
                            <div className="form-group mb-4 col-md-6 text-center offset-md-3">
                                <button type="submit" className="btn btn-primary w-50">Submit</button>
                            </div>

                        </div>
                    </form>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        addsuccess: state.adminaccount.addedAccount,
        addfailed: state.adminaccount.addedAccountFailed,
    }
}
const actionCreator = {
    addAccount: adminActions.addAdminAccount,
    resetAddAccountSuccess: adminActions.resetAddAccountSuccess,
    resetAddAccountFailed: adminActions.resetAddAccountFailed

}
const connectAddAccounts = connect(mapStateToProps, actionCreator)(AddAccounts)
export { connectAddAccounts as AddAccounts }