import React from "react";
import { collectorActions, userAction } from "store/actions";
import { connect } from "react-redux";
import history from "helpers/history";
import { localAirdropPage, localArtPage, localDropPage, ReactPagination } from 'helpers'
import { configPath } from "../config";
import { Helmet } from "react-helmet";
import { Preview } from "./preview";
import { ProfilePreview } from "./profilePreview";

class Collectors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collectorsList: [],
            totalCount: 0,
            size: 12,
            offSet: 0,
            isRequest: false,
            userRole: '2',
            isSuccess: false,
            isShowLoader: true
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()
        localStorage.setItem('page',JSON.stringify(0));
        this.callApi()

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.collectorsList && props.collectorsList.statusCode == 200) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })

            return {
                collectorsList: props && props.collectorsList && props.collectorsList.data && props.collectorsList.data.collectors ? props.collectorsList.data.collectors : [],
                totalCount: props && props.collectorsList && props.collectorsList.data && props.collectorsList.data.count,
                pageCount: state.totalCount / state.size,
                isShowLoader: false
            }
        }
        return null;

    }


    gotoTesting = () => {
        this.setState({ isRequest: true })
    }

    componentDidUpdate(props) {
        let nft = configPath.basename

        if (window.location.pathname.includes(nft)) {
            if ((props && props.location && props.location.search) != (window.location.search)) {
                this.callApi()
            }
        } else if ((props && props.location && props.location.search) != (window.location.search)) {
            this.callApi()
        }

    }
    callApi = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let offSet = localStorage.getItem("page")

        this.setState({ forcePage: offSet != null ? (JSON.parse(offSet) / this.state.size) : 0 })

        let obj = {
            offSet: offSet != null ? offSet : this.state.offSet,
            recordLimit: this.state.size,
            sortBy: 'updatedAt',
            sortOrder: 'DESC'
        }
        this.props.getCollectors(obj)

    }

    onPageChange = (data) => {
        let offSet = this.state.size * (data && data.selected)
        localStorage.setItem("page", JSON.stringify(offSet))

        this.setState({ offSet }, () => this.callApi())

    }


    render() {

        return (
            <div className="art artcontent creators">
                <Helmet>
                    <title>Collectors</title>
                    <meta name="title" content={`Browse ${process.env.REACT_APP_SITE_CONTRACT} Collectors`}></meta>
                    <meta name="description" content={`Browse NFT collectors on ${process.env.REACT_APP_SITE_CONTRACT} NFT Platform`}></meta>
                </Helmet>

                <div className="container">
                    <div className="pagetitabtn">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="w-auto float-left">Collectors </h1>
                            </div>
                        </div>
                        <hr />
                    </div>


                    <div className="row">
                        {this.state.collectorsList && this.state.collectorsList.length > 0 && this.state.collectorsList.map((content, index) => (

                            <div className="col-sm-6 col-md-4 col-lg-3" key={content && content.id} >
                                <div className="cardlist" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); history.push(`/collector/@${content && content.userName && encodeURIComponent(content.userName)}`, { obj: { userAddress: content && content.userAddress, userName: content && content.userName } }) }}>
                                    <Preview src={(content && content.bannerImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${content && content.bannerImage}`)} alt="" className="comimg" isPremiumAccount={content && content.isPremiumAccount} />

                                    <div className="upic">
                                        <span className="artcovimg">
                                            <span className="artcov "></span>
                                            <span className="artcovimginset no-proimg " >
                                                <ProfilePreview src={(content && content.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${content && content.profileImage}`)} alt="Avatar" />
                                            </span>
                                        </span>
                                    </div>
                                    <div className="cont">

                                        <h3 className="" title={content && content.fullName ? content.fullName : "Creator Name"}>{content && content.fullName ? content.fullName : "Creator Name"}</h3>
                                        <h6 className="mt-2 usernamelilac" >@{content && content.userName ? content.userName : "@username"}</h6>
                                        <p className="mb-0 userdescrip" >{content && content.description && content.description}</p>
                                    </div>
                                    <div className="userwithpic" >
                                        <p className="update" title={content && content.userName ? content.userName : "@username"}>{content && content.userName ? content.userName : "@username"}</p>
                                        <h6>{content && content.profileViews} Views</h6>

                                    </div>

                                </div>
                            </div>
                        )
                        )}
                    </div>

                    {this.state.collectorsList && this.state.collectorsList.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.size, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                    {this.state.totalCount == 0 && !this.state.isShowLoader &&
                        <div className="col-md-4 offset-md-4 text-center">
                            <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                        </div>
                    }
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        collectorsList: state.collector.collectors,
    }
}
const actionCreators = {
    getCollectors: collectorActions.getCollectors,
    resetCollectors: userAction.resetAllUsers,
}
const connectCollector = connect(mapStateToProps, actionCreators)(Collectors)
export { connectCollector as Collectors }
