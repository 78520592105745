import { configPath } from 'config';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';

class ShowAttributes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
        }
    }

    componentDidUpdate(props, state) {
        if ((props && props.metaData)) {
            if ((props.metaData) != (state.data)) {
                this.setState({ data: props.metaData })

                console.log("props", props.metaData)
            }
        }
    }

    render() {
        const { data } = this.state
        return (
            <Fragment>
                <div className="prpabout">
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-dark-cus btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className='fa fa-caret-square-o-right usernameblue'></i> Properties
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" >
                                <div className="card-body">
                                    <div className="row">
                                        {(data && data["attributes"] && data["attributes"].length > 0 && data["attributes"].map((items) => (
                                            <div className='col-12 col-sm-6 col-md-4'>
                                                <a className="prpaboutlist">
                                                    <div className="Property--type">{items && items.trait_type}</div>
                                                    <div className="Property--value">{items && items.value}</div>
                                                    {/* <div className="Property--rarity">12% have this trait</div> */}
                                                </a>
                                            </div>)))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-dark-cus btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <i className='fa fa-address-card-o usernameblue'></i> About
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" >
                                <div className="card-body">
                                    <div className='item--about-container'>
                                        <div className='item--about-image'>
                                            <img src={`/${configPath.imageAssets}/badazz.png`} alt="" className='Image--image badazz_logo' />
                                        </div>
                                        <span className='item--about-text'>{data && data.description}</span>
                                    </div>

                                    <div className='item--social-links'>
                                        <div className='fresnel-container'>
                                            <div className='kgqWIT dropdown'>
                                                <a href={process.env.REACT_APP_DISRUPT_BAB} className='eHTcPK fPnOUC' target='_blank'><i className='fa fa-globe'></i></a>
                                                <a href="https://instagram.com/badazzbears?igshid=YmMyMTA2M2Y=" className='eHTcPK fPnOUC' target='_blank'><i className='fa fa-instagram'></i></a>
                                                <a href="https://discord.io/badazzbears" className='eHTcPK fPnOUC' target='_blank'><i className='fab fa-discord'></i></a>
                                                <a href="https://twitter.com/badazz_bears?s=21&t=NZEXVmWNZROom1NLfnZLxw" className='eHTcPK fPnOUC' target='_blank'><i className="fa fa-twitter"></i></a>
                                                {/* <a href='' className='eHTcPK fPnOUC' target='_blank'>
                                                    <svg fill="rgb(247, 247, 247)" height="18" viewBox="0 0 293.775 293.671" width="18" xmlns="http://www.w3.org/2000/svg"><g id="etherscan-logo-circle" transform="translate(-219.378 -213.33)"><path d="M280.433,353.152A12.45,12.45,0,0,1,292.941,340.7l20.737.068a12.467,12.467,0,0,1,12.467,12.467v78.414c2.336-.692,5.332-1.43,8.614-2.2a10.389,10.389,0,0,0,8.009-10.11V322.073a12.469,12.469,0,0,1,12.468-12.47h20.778a12.469,12.469,0,0,1,12.467,12.467v90.279s5.2-2.106,10.269-4.245a10.408,10.408,0,0,0,6.353-9.577V290.9a12.466,12.466,0,0,1,12.466-12.467h20.778A12.468,12.468,0,0,1,450.815,290.9v88.625c18.014-13.055,36.271-28.758,50.759-47.639a20.926,20.926,0,0,0,3.185-19.537,146.6,146.6,0,0,0-136.644-99.006c-81.439-1.094-148.744,65.385-148.736,146.834a146.371,146.371,0,0,0,19.5,73.45,18.56,18.56,0,0,0,17.707,9.173c3.931-.346,8.825-.835,14.643-1.518a10.383,10.383,0,0,0,9.209-10.306V353.152" data-name="Path 1" id="Path_1"></path><path d="M244.417,398.641A146.808,146.808,0,0,0,477.589,279.9c0-3.381-.157-6.724-.383-10.049-53.642,80-152.686,117.4-232.79,128.793" data-name="Path 2" id="Path_2" transform="translate(35.564 80.269)"></path></g></svg></a> */}

                                                {/* <button className="eHTcPK fPnOUC dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                                    <i className='fa fa-ellipsis-v'></i>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a className="dropdown-item" href="#" target='_blank'>Report</a>
                                                </div> */}


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export { ShowAttributes }