export const localArtPage = () => {
    localStorage.setItem("artData",JSON.stringify([]))
}
export const localDropPage = () => {
    localStorage.setItem("dropData",JSON.stringify([]))
    localStorage.setItem('extraLimit','0')
}
export const localAirdropPage = () => {
    localStorage.setItem("airdropData",JSON.stringify([]))

}