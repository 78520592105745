import React, { Component, Fragment} from 'react'

import { connect } from 'react-redux'
import { discoverActions } from 'store/actions'

import { Helmet } from 'react-helmet';
import history from 'helpers/history'
import PubSub from 'pubsub-js';
import moment from 'moment';

import "react-alice-carousel/lib/alice-carousel.css";

import { configPath } from '../config'
import { flowDecimal, getUSDValue, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { Widget } from '@typeform/embed-react'
import { ProfilePreview } from './profilePreview';
import { DALandingCreators } from './da-landing-creators';
import { scrollToTop } from 'helpers/scrollToTop';

class DALanding extends Component {
    constructor(props) {
        super(props);       
        
        this.state = {
            responsive: {
                0: { items: 1 },
                767: { items: 1 },
                992: { items: 2 },
                1920: { items: 2 }
            },
            responsiveArt: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            },
            responsiveCreator: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            },
            spotLight: {},
            featureArts: [],
            featureCreators: [],
            featureCollectors: [],
            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            offSet: 0,
            recordLimit: 4,
            artRecord: 4,
            creatorRecord: 6,
            collectorRecord: 8,
            isArtTrue: false,
            isCreatorTrue: false,
            isCollectorTrue: false,
            isSpotCheck: false,
            isFeatureArt: false,
            isCreator: false,
            isCollector: false,
            spotlightOn: true,
            show: false,
            rate: 0,
            randomCreators: [],
            scroll: false,
            videoPop: false,
            
        }
        

    }

    
    componentDidMount() {
        scrollToTop()
        localArtPage()
        localDropPage()
        localAirdropPage()
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // })
        var scrollpos = window.scrollY;
        var header = document.getElementById("da-connect");

        var hheader = document.getElementById("da-banner").clientHeight + document.getElementById("da-dftc").clientHeight - 700;


        function add_class_on_scroll() {
            header.classList.add("da-imgani");
        }
        function remove_class_on_scroll() {
            header.classList.remove("da-imgani");
        }

        window.addEventListener('scroll', function () {
            //Here you forgot to update the value
            scrollpos = window.scrollY;

            if (scrollpos > hheader) {
                add_class_on_scroll();
            }
            else {
                remove_class_on_scroll();
            }
        });
        window.addEventListener('scroll', () => {
            let activeClass = 'da-fixed';
            if (window.scrollY === 0) {
                activeClass = '';
            }
            this.setState({ activeClass });
        });


        this.getUSD()
        
    }
    getSpotLight = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.gotoTop()
        this.props.getAllSpotLightArts(obj)
    }
    getFeatureArt = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureArts(obj)
    }
    getFeatureCreator = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            // count: 75,
            // shuffle: true
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getCreators(obj)
    }
    getFeatureCollector = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureCollectors(obj)
    }


    componentDidUpdate(props, state) {
       
       
        if (this.props &&
            this.props.showcaseCreatorsList &&
            this.props.showcaseCreatorsList.statusCode == 200 &&
            (this.props.showcaseCreatorsList !== (props.showcaseCreatorsList))) {
            this.props.resetShowcaseCreators()
            const creators = this.props && this.props.showcaseCreatorsList && this.props.showcaseCreatorsList.data && this.props.showcaseCreatorsList.data.creators
            const arrayOfCreators = creators.concat(creators)
            this.setState({
                randomCreators: creators,
                isCreator: true
            })

        }
        if (this.props &&
            this.props.showCaseCreatorsFailed &&
            this.props.showCaseCreatorsFailed.length > 0 &&
            (this.props.showCaseCreatorsFailed !== (props.showCaseCreatorsFailed))) {
            this.props.resetShowcaseCreatorsFailed()
            this.setState({
                randomCreators: [],
                isCreator: true
            })
        }
    }


    showLoader() { PubSub.publish('msg', true); }
    hideLoader() { PubSub.publish('msg', false); }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }


    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    gotoSpecificCreator = (owner) => {
        history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })

    }
    handleSpotlight = () => {
        this.setState({ spotlightOn: true })
    }
    handleArts = () => {
        history.push('arts?page=1&limit=12')
    }
    handleCreator = () => {
        history.push('creators?page=1&limit=12')
    }

    getEdition = (items) => {

        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.dropsCount != null)) {
            return <h6>Edition : 1</h6>
        } else if ((items && items.drops && items.drops[0] && items.dropsEdition != null)) {
            return (<h6>Edition :  {items.dropsEdition}</h6>)
        } else if ((items.toalCount != null && items.dropsCount != null)) {
            return <h6>Edition : {((items.toalCount) - (items.dropsCount))}</h6>
        } else if ((items.toalCount != null && items.dropsCount == null)) {
            return <h6>Edition : {((items.toalCount))}</h6>
        } else {
            <h6>Edition : 1</h6>
        }
    }
    gotoPath = (e, items) => {
        if (e.target.tagName == "H3") {
            this.gotoDetail(items)
        } else if (e.target.tagName == "SPAN") {
            this.gotoCreator(items && items.creator)
        }
    }
    gotoArts = () => {
        history.push('/arts')
        this.handlePage()
    }
    gotoCreators = () => {
        history.push('/creators')
        this.handlePage()
    }
    gotoCollectors = () => {
        history.push('/collectors')
        this.handlePage()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    gotoDrops = () => {

        history.push('/drops')
        this.handlePage()
    }
    handlePage = () => {
        localStorage.setItem("page", "0")
    }
    getUSD = async () => {
        // this.getSpotLight('', '')
        // this.getFeatureArt('', '')
        this.getFeatureCreator('', '')
        // this.getFeatureCollector('', '')


    }
    filterSpotLight = () => {
        console.log(this.state.spotLightList)
        const ListedArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops[0]))
        const notInDropsArray = this.state.spotLightList && this.state.spotLightList.filter(el => (el && el.drops && el.drops.length === 0))

        if (this.state.spotLightList && this.state.spotLightList.length > 0) {
            if (ListedArray && ListedArray.length > 0) {
                var item = ListedArray[Math.floor(Math.random() * ListedArray.length)];
                if (item && item.id) {
                    this.setState({ spotLight: item })
                }
            } else if (notInDropsArray && notInDropsArray.length > 0) {
                var item = notInDropsArray[Math.floor(Math.random() * notInDropsArray.length)];
                if (item && item.id) {
                    this.setState({ spotLight: item })
                }
            }
        } else {
            this.setState({ spotLight: {} })
        }


        console.log(item)


    }
    gotoBuy = (items) => {
        if (items.id && items.drops && items.drops[0] && items.drops[0].id) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else if (items.id) {
            history.push(`/art/${items.id}`)
        }
    }
    getPrice = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            if (items && items.drops && items.drops[0] && items.drops[0].storefrontListingResourceId && items.drops[0].withDapper) {
                return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} USD`
            } else {
                // return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} FLOW`
                return ''
            }
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                // return `${flowDecimal(parseFloat(amount))} FLOW`
                return ''
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        // return `${flowDecimal(parseFloat(amount))} FLOW`
                        return ''
                    }

                }
            }
        } else {
            return false
        }
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    getUsdValue = (items, rate) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            return `$${(getUSDValue(rate, items.drops[0].markedPrice))} `
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                return `$${getUSDValue(rate, (amount))} `
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        return `$${getUSDValue(rate, (amount))} `
                    }

                }
            }
        } else {
            return false
        }
    }
    startTime = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return `Bidding Closed`
        }

    }
    isAvailabletoBuy = (items) => {
        if (items && items.drops && items.drops[0]) {
            if (this.dayClosed(items.drops[0])) {
                return false
            }
            return true
        } else {
            return false
        }
    }
    closeVideo = () => {
        this.setState({ videoPop: this.state.showNav })
        if (document.getElementById('nftvdo')) {
            document.getElementById('nftvdo')['src'] = document.getElementById('nftvdo')['src']
        }

    }
    getBanner = (item) => {
        if (item && item.bannerImage && item.bannerImage != null) {
            return <img src={`${process.env.REACT_APP_PROFILE_IMG_PATH}${item.bannerImage}`} alt=""></img>

        } else {
            return <img src={`../${configPath.imageAssets}/cover-default.png`} alt=""></img>

        }

    }
    handleLogin = () => {
        history.push('/login')
    }
    gotoDiscover = () => {
        history.push('/discover')
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    
   

    render() {
        const { spotLight, featureArts, featureCreators, featureCollectors, randomCreators, isFeatureArt, isCreator, isCollector } = this.state
        const metaDesc = `Discover amazing NFT collections from various disruptor creators. ${process.env.REACT_APP_BASE_SITE} Marketplace's featured and spotlight NFTs`
        const dalandingbg = `../${configPath.imageAssets}/landing/da-landing-bg.png`
        const dalogobig = `../${configPath.imageAssets}/landing/dalogobig.png`
        const basePath = process.env.REACT_APP_BASEPATH        
       
        const scrollcontact = () => {
            const section = document.querySelector( '#contact-us' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
          };

        return (
            <Fragment>
                <Helmet>
                    <title>{`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}</title>
                    <meta name="title" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}></meta>
                    <meta name="description" content={metaDesc}></meta>
                </Helmet>
                <div className='da-landing'>
                    <div className='da-banner' id='da-banner'>
                        <div className='da-bannerimg' style={{ background: `url(${basePath}/assets/images/landing/da-landing-bg.png) no-repeat`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                        <div className='da-bannercon'>
                            <div className='container'>
                                <img src={dalogobig} alt='' />
                                <h1>Engage your community and unlock the potential of your brand. Maximize event advertising dollars with personalized engagement and gaming.</h1>
                                <div className='da-banner-btns'>
                                    <button className='btn btn-outline-voilet' onClick={scrollcontact}>For Brands</button>
                                    <a onClick={this.gotoDiscover} className='btn btn-outline-blue'>For Creators</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='da-content' id='da-dftc'>
                        <div className='container'>
                            <div className='row align-items-center fadeLeft'>
                                <div className='col-md-6'>
                                    <h2>A FAIR SHARE FOR THE CULTURE</h2>
                                </div>
                                <div className='col-md-6 text-right fadeRight '>
                                    <h3>Increasing ownership in media and technology through the blockchain</h3>
                                    {/* <p>a user interface that provides creators, brands, and nonprofit organizations access to a safe, secure, and decentralized internet that run on blockchain technology.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='da-concret da-connect' id="da-connect">
                        <div className='da-concretbg' style={{ background: `url(${basePath}/assets/images/landing/connect-create-bg.png) no-repeat`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                        <div className='container'>
                            <div className='row mb-5'>
                                <div className='col-md-4'>
                                    <h3 className='dal-venusrising'>CONNECT</h3>
                                    <h4>Connecting brands to increase brand equity and foster loyalty. Uncover customer insights, additional opportunities for interactions, and consumer data.</h4>
                                    {/* <h5><span>DisruptART</span> Studios</h5>
                                    <p>At the intersection of art and activism, DisruptArt aims to curate an interactive space for artists, collectors, and activists to revolutionize the impact of art.<br /><br />
                                        Artists sell their pieces as a digital creation through blockchain technology.</p> */}
                                    <div className='da-banner-btns da-mobhide'>
                                        <a onClick={this.handleLogin} className="btn btn-outline-voilet">Learn More</a>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='da-dsascw'>
                                        <img src={`${basePath}/assets/images/landing/DisruptART-studios.png`} alt='' />
                                        <img src={`${basePath}/assets/images/landing/all-start-code-website.png`} alt='' />
                                    </div>
                                    <div className='da-banner-btns da-mobshow'>
                                        <a onClick={this.handleLogin} className="btn btn-outline-voilet">Learn More</a>
                                    </div>
                                </div>
                            </div>

                            <div className='row da-connectrow'>
                                <div className='col-md-8'>
                                    <div className='da-artlisting'>
                                        {/* <div className='da-arscroll'> */}
                                        
                                        {randomCreators && randomCreators.length > 0 && isCreator && <DALandingCreators featureCreators={randomCreators}/>}
                                            {/* {(randomCreators && randomCreators.length > 0) ? randomCreators.map((item,index) => 
                                            <li  onClick={(e) => { e.preventDefault(); history.push(`/creator/@${item && item.userName && encodeURIComponent(item.userName)}`) }} key={(item && item.uuid) ? (item.uuid + index) : ''}>
                                                <span className='daalist'>
                                                    <span className='bg'><img src={`${basePath}/assets/images/landing/da-list-bg.png`} alt='' /></span>
                                                    <span className='da-listcont'>
                                                        <span className='da-lccover'>
                                                            {this.getBanner(item)}

                                                        </span>
                                                        <span className='da-lcproandcont'>
                                                            <span className='da-lcprof'>
                                                            <ProfilePreview src={item && item.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${item.profileImage}`} alt="" />

                                                            </span>
                                                            <p className='usernamelilac'>{(item && item.userName) ? `@${item.userName}` : ''}</p>
                                                            <p className=''>{(item && item.fullName) ? `${item.fullName}` : ''}</p>
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>) : ''} */}                                                                                   
                                        {/* </div> */}
                                        <div className='da-banner-btns justify-content-center'>
                                            <a onClick={this.gotoDiscover} className="btn btn-outline-voilet">Browse</a>
                                        </div>
                                    </div>
                                    <div className='da-banner-btns justify-content-end da-mobshow'>
                                        <a onClick={this.handleLogin} className="btn btn-outline-voilet">Sign Up</a>
                                    </div>
                                </div>
                                <div className='col-md-4 text-right'>
                                    <div className='da-createrht'>
                                        <h3 className='dal-venusrising'>CREATE</h3>
                                        <h4>Creating new ownership opportunities for all by making modern technology more accessible.</h4>
                                        {/* <h5>Own your data and relationships</h5> */}
                                        <div className='da-banner-btns justify-content-end da-mobhide'>
                                            <a onClick={this.handleLogin} className="btn btn-outline-voilet">Sign Up</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='da-activate' id='da-activate'>
                        <div className='da-abg'>
                            <img src={`${basePath}/assets/images/landing/midwest-bg.png`} alt='' />
                        </div>
                        <div className='container'>
                            <div className='row mb-5'>
                                <div className='col-md-5'>
                                    <h3 className='dal-venusrising'>ACTIVATE</h3>
                                    <h4>Activating the future of entertainment with immersive experiences that captivate and inspire</h4>
                                    <div className='da-mid'>
                                        <img src="https://midwestcon.disrupt.art/assets/img/midwestcon-logo.png" alt='' />
                                    </div>
                                    {/* <p>Creating a purpose-built NFT experience focused on social impact is a powerful way to engage donors and expand your impact. It allows your organization to gain additional revenue, donors, and exposure. Disrupt Studios creates web3 NFT
                                        experiences designed for social impact, allowing your organization to focus on operations and strategy.</p> */}
                                    <div className='da-banner-btns'>
                                        <a href="https://midwestcon.disrupt.art/#Speakers" target='_blank' className="btn btn-outline-voilet">Become a Speaker</a>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <div className='da-dsascw'>
                                        <div className='da-wvplay box' >
                                            <a className="button" onClick={() => this.setState({ videoPop: !this.state.showNav })}>
                                                <img src={`${basePath}/assets/images/landing/playbtn.png`} alt='' />
                                                <p>Watch Video</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='da-content' id='da-op'>
                        <div className='container'>
                            <h2 className='fadedDown'>Our <br />Partners</h2>
                            <div className='da-partners fadedDown'>
                                <div className='row align-items-center'>
                                    <div className='col-md-2'>
                                        <span className='fadeLeft'><img src={`${basePath}/assets/images/landing/URL.png`} alt='' /></span>
                                    </div>
                                    <div className='col-md-10'>
                                        <div className='da-partners-list'>
                                            <span><img src={`${basePath}/assets/images/landing/cincinnati.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/ELEV8ONE.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/jim-jones.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/meekmill.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/all-star.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/Triller.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/AYO-TEO.png`} alt='' /></span>
                                            <span><img src={`${basePath}/assets/images/landing/midwestcon.png`} alt='' /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='da-concret da-contact' id='contact-us'>
                        <div className='da-concretbg'>
                            <img src={`${basePath}/assets/images/landing/contact-bg.png`} alt='' />
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='da-createrht'>
                                        <h3>Contact us</h3>
                                        {/* <h4>Make your next NFT your best NFT.</h4> */}
                                        <p>We'd love to collaborate with you to create the best experience possible for your customers and fans. <br /><br /> Tell us a bit about your project, and let's make it happen.</p>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='da-cform'>
                                        <Widget id="V3iLhcWk" style={{ minHeight: "100%" }} className="my-form" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="popup1" className={`overlay ${this.state.videoPop}`}>
                    <div className="popup">
                        <a className="close" onClick={() => this.closeVideo()}>&times;</a>
                        <div className="content">
                            <iframe width="100%" height="480" src="https://www.youtube.com/embed/6Xb1SnMCx1k" title="YouTube video player" frameBorder="0" allowFullScreen id="nftvdo"></iframe>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
  
}
const mapStateToProps = (state) => {
    return {
        showcaseCreatorsList: state.discover.showcaseCreators,
        showCaseCreatorsFailed: state.discover.showcaseCreatorsFailed
    }
}
const actionCreators = {

    getCreators: discoverActions.getAllShowCaseCreators,
    resetShowcaseCreators: discoverActions.resetShowCaseCreators,
    resetShowcaseCreatorsFailed: discoverActions.resetShowCaseCreatorsFailed
}

const connectDiscoverv2 = connect(mapStateToProps, actionCreators)(DALanding)
export { connectDiscoverv2 as DALanding }

