import { isWebLogin } from "helpers";
import React from "react";
import { Route,Redirect } from "react-router";
import { StoreLayout } from "./StoreLayout";


export function StoreLayoutRoute({
    component: Component,
    ...rest
}) {
        return (
            <Route {...rest} render={
                (matchProps) => (
                    isWebLogin() ? 
                     (
                        <StoreLayout>
                            <Component {...matchProps} />
                        </StoreLayout>
                    ) : <Redirect to={{pathname : "/login"}}/>
                )
            } />
        );
    
}


