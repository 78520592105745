// @ts-nocheck
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';

import PubSub from 'pubsub-js';
import Modal from 'react-modal'
import history from 'helpers/history';
import { configPath } from '../config';

var timeout;
const customStyles = {
    content: {
        top: '10%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',


    },
};
class FollowPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            profileImage : null,
            userName : '',
            fullName : '',
            ownerDetail: {}
        }
        this.maxLength = 1400
        this.minLength = 10
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        if (this.props && (this.props.fullName || this.props.profileImage || this.props.userName || this.props.ownerDetail)) {
            this.setState({ fullName: this.props.fullName,profileImage: this.props.profileImage,userName: this.props.userName, ownerDetail: this.props.ownerDetail  })
        }
    }
    handlemorepoints = (e) => {
        if (e.target.value && e.target.value.length <= (this.maxLength)) {
            this.setState({ reason: e.target.value,isTooLength : false })
        } else {
            this.setState({ reason: e.target.value.slice(0, (this.maxLength)),isTooLength : true })
        }
    }

    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    sendReport = (e) => {
        e.preventDefault()
        const { reportedLink, reason } = this.state
        this.setState({ submitRequest: true, isTooLength : false })
        if (reportedLink && this.validURL(reportedLink)) {
            if (reason && this.checkmaxPoint(reason)) {
                let obj = {
                    url: reportedLink,
                    reporterRemarks: reason
                }
                this.setState({ isOpen: false })
                this.props.handleReportRes(obj)
            }
        }


    }



    validURL = (str) => {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        var re =/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)/

        const urlReg = /https:\/\/.*/;
        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }

    checkmaxPoint = (value) => {
        if ((value && value.length) <= (this.maxLength) && (value && value.length) >= (this.minLength)) {
            return true
        } else {
            return false
        }
    }

    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    closePopup = () => {
        this.setState({ isOpen: false, reason: '' })
        this.props.handleCloseReport()
    }
    handleLogin = () => {
        const reportPath = `${this.props && this.props.props && this.props.props.location && this.props.props.location.pathname}${this.props.props.location && this.props.props.location.hash}`
        history.push('/login', { reportForm: reportPath })
    }
    closeModal = () => {
        this.setState({
            isOpen: false
        })
        this.props.closeModal()

    }
    handleFollow = () => {
        this.setState({
            isOpen: false
        })
        this.props.handleFollow()

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()
        }
        const { fullName,profileImage,userName,currentUserDet } = this.state

        return (
            <Fragment>

<Modal
                   isOpen={this.state.isOpen}
                   style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">                         
                   <div className="custom-ui reportpop text-left successpopup sspppopup">
                        <div className="dspcls" onClick={this.closeModal}>x</div>
                        <div className="">
                           
                        <h3 className="font-weight-normal mt-4">You must be following {fullName && `${fullName}`} to claim this drop.</h3>
                            <div className="brdgradeint w-250">
                                <img src={profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" className='mw-100 rounded-circle'  />
                            </div>                           

                            <a onClick={() => this.gotoCreator(this.state.ownerDetail)} className='text-link cursor-pointer'>{userName && `@${userName}`}</a>

                            <div className='row'>
                                <div className='col-12 text-center'>
                                <button type="button" className="btn btn-primary px-5 btn-sm mt-3" onClick={(e) => { this.handleFollow() }}>Follow</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>


                {/* <Modal
                    isOpen={this.state.isOpen}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Example Modal"                >
                    <button onClick={this.closeModal} className="btn btn-primary mb-3 clsbtn">X</button>
                    <div className="loginpopcls adminimgprev">
                        <div className="custom-ui text-left">
                            <div className="form-group text-center">
                                <div className="custom-btn text-center mt-5">
                                    <div className="row">

                                        <div className="col-sm-12 mb-2">
                                            <h5></h5>
                                        </div>
                                        <div className="col-sm-12">
                                            <img src={profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" style={{ width: "200px", height: "200px" }} />
                                        </div>
                                        <div className="mt-3 mb-4 col-sm-12"><b></b></div>
                                        <div className="col-sm-12">
                                            </div>
                                    </div>
                                </div></div>
                        </div>
                    </div>

                </Modal> */}

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const actionCreators = {}

const connectFollowPopup = connect(mapStateToProps, actionCreators)(FollowPopup)

export { connectFollowPopup as FollowPopup }