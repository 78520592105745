import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { mintSlotActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import { debounce } from 'utils';
import Toggle from 'react-toggle';
import { toast } from 'react-toastify';


class FetchMintSlots extends Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            slots: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 10,
            pageCount: '',
            isFetched: false,
            search: '',
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);

    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.getSlots && props.getSlots.statusCode === 200)) {
            props.resetMintSlot()
            return {
                slots: props.getSlots && props.getSlots.data && props.getSlots.data.tags ? props.getSlots.data.tags : [],
                // totalCount: props.getSlots && props.getSlots.data && props.getSlots.data.count,
                // pageCount: props.getSlots && props.getSlots.data && props.getSlots.data.count / state.recordLimit,
                isFetched: true
            }
        }

        

    }
    componentDidUpdate(props,state){
        console.log(props,this.props)
        if((this.props && this.props.slotUpdate && this.props.slotUpdate.statusCode === 200) && (props.slotUpdate && props.slotUpdate.statusCode) != (this.props.slotUpdate && this.props.slotUpdate.statusCode)){
            props.resetUpdateMintSlot()
            toast.success("Slot updated")
            this.callApi()
        }
    }
    handleRefresh = () => {
        this.setState({ offSet: 0, forcePage: 0,search : '',  }, () => {
            this.callApi()
        })
    }


    callApi = () => {
        const { search } = this.state;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,

        }
        if (search) {
            obj.search = search;
        }
       
        this.props.getAllSlots(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

   
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    
    statusChange = (items) => {
        const isLocked = (items && items.isLocked) ? false : true
        let obj = {
            id : items.id,
            isLocked
        }
        this.props.updateMintSlot(JSON.stringify(obj))
    }    
    render() {


        const slotList = this.state.slots && this.state.slots.map((items, index) => {
            return <tbody>
                <tr>
                    <td>{items && items.id}</td>
                    <td><a className={'card flowtop'} style={{display:"inline-block"}}>
                    {(items && items.isLocked) ? <i className="fa fa-lock fa-2x usernamelilac" onClick={() => this.statusChange(items)} aria-hidden="true" style={{cursor : "pointer"}}></i> : <i className="fa fa-unlock fa-2x" aria-hidden="true" style={{cursor : "not-allowed"}}></i>}
                        {/* <Toggle checked={items && items.isLocked} onChange={() => this.statusChange(items)} disabled={items && items.isLocked}/> */}
                    {(items && items.isLocked) ? <span className="copycon">
                    <span className="uparrow"></span>
                    Unlock 
                </span> : ''}</a></td>
                    <td>{moment(items && items.updatedAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>


                </tr>

            </tbody>

        })

       
        return (

            <Fragment>

                <div className="container">
                    <div className="mb-4 admintitle">
                        <div className='row'>
                        <h2 className="font-weight-bold  col-sm-6">Mint Slots</h2>
                        <div className="form-group text-right col-sm-6">
                            <button type="button" className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i></button>
                            </div>    
                        
                                            </div>
                        </div>
                        <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>STATUS</th>
                                        <th>UPDATED AT</th>
                                        
                                        

                                    </tr>
                                </thead>

                                {slotList}

                            </table>
                            {this.state.slots && !this.state.slots.length > 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSlots : state.mintslot.allslots,
        slotUpdate : state.mintslot.updatedSlot
    }
}

const actionCreators = {
    getAllSlots: mintSlotActions.getAllMintslots,
    resetMintSlot : mintSlotActions.resetAllMintSlots,
    updateMintSlot : mintSlotActions.updateMintSlot,
    resetUpdateMintSlot : mintSlotActions.resetUpdateMintSlot
}

const connectFetchMintSlots = connect(mapStateToProps, actionCreators)(FetchMintSlots)

export { connectFetchMintSlots as FetchMintSlots }