import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userServices } from 'services';
import { Alert } from "helpers/alert";
import PubSub from 'pubsub-js';

var timeout;
class CreatorRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: false,
            selectedField: {},
            checkValue: {},
            isApply: false,
            instaLink: '',
            twitterLink: '',
            masterpiece: '',
            waLink: '',
            fbLink: '',
            linkedInLink: '',
            morePoints: '',
            fields: [
                { id: 1, name: "Graphic Artist", isChecked: false }, { id: 2, name: "Photographer", isChecked: false }, { id: 3, name: "Visual Artist", isChecked: false }, { id: 4, name: "Musician", isChecked: false }, { id: 5, name: "Perfoming Artist", isChecked: false },
            ],
            title: "Copy Address",
            rate: '',
            profileImage: '',
            isLogin: false,
            openLogin: false,
            isRegister: false,
            isRequset: false,
            isClick: "",
            user: '',
            isSuccess: false,
            submitRequest: false,
            isAgree: false,
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleInputChange = (e) => {
        if(e.target.value && e.target.value.length <= 120){
        this.setState({ [e.target.name]: e.target.value })
        }else{
            this.setState({ [e.target.name] : e.target.value.slice(0,120)})
        }
    }
    handlemorepoints = (e) => {
        if(e.target.value && e.target.value.length <= 200){
            this.setState({ morePoints : e.target.value})
        }else{
            this.setState({ morePoints : e.target.value.slice(0,200)})
        }
    }
    handletermsChecked = (value) => {
        let isAgree = value ? false : true
        this.setState({ isAgree })
        // console.log("agree", isAgree)
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    gotoCreatorRequest = () => {
        const { fields, instaLink, twitterLink, waLink, linkedInLink, fbLink, masterpiece, morePoints, isAgree } = this.state
        this.setState({ submitRequest: true })
        let selectedField = {}
        let selectedArray = []
        fields && fields.map(items => {
            if ((items && items.isChecked) == true) {
                selectedField = items
                selectedArray.push(items && items.id)
            }
        })
        this.setState({ selectedField })

        if ((Object.keys(selectedField).length !== 0) && morePoints && this.checkmaxPoint(morePoints)) {
            if (isAgree) {
                let obj = {
                    categoryIds: selectedArray,
                    //   shortDescription: masterpiece,
                    longDescription: morePoints,
                    //   instagramLink: instaLink,
                    //   twitterLink: twitterLink,
                    //   linkedinLink: linkedInLink,
                    //   fbLink: fbLink
                }
                if (instaLink) {
                    if (this.validURL(instaLink)) {
                        if(this.checkName(instaLink)){
                        obj['instagramLink'] = instaLink
                        }else{
                            return false
                        }
                    } else {
                        return false
                    }
                }
                if (twitterLink) {
                    if (this.validURL(twitterLink)) {
                        if(this.checkName(twitterLink)){
                        obj['twitterLink'] = twitterLink
                        }else{
                            return false
                        }
                    } else {
                        return false
                    }
                }
                if (linkedInLink) {
                    if (this.validURL(linkedInLink)) {
                        if(this.checkName(linkedInLink)){
                        obj['linkedinLink'] = linkedInLink
                        }else{
                            return false
                        }
                    } else {
                        return false
                    }
                }
                if (fbLink) {
                    if (this.validURL(fbLink)) {
                        if(this.checkName(fbLink)){
                        obj['fbLink'] = fbLink
                        }else{
                            return false
                        }
                    } else {
                        return false
                    }
                }
                if (masterpiece) {
                    if (this.validURL(masterpiece)) {
                        if(this.checkName(masterpiece)){
                            obj['shortDescription'] = masterpiece
                        }else{
                            return false
                        }
                    } else {
                        return false
                    }

                }

                this.showLoader()
                userServices.raiseRequest(JSON.stringify(obj))
                    .then(res => {
                        // console.log("raise",res)
                        this.props.closeModal()
                        this.props.success()
                        this.setState({
                            isSuccess: true,
                            isRequest: false,
                            fields: [
                                { id: 1, name: "Graphic Artist", isChecked: false }, { id: 2, name: "Photographer", isChecked: false }, { id: 3, name: "Visual Artist", isChecked: false }, { id: 4, name: "Musician", isChecked: false }, { id: 5, name: "Perfoming Artist", isChecked: false },
                            ],
                            twitterLink: '',
                            fbLink: '',
                            linkedInLink: '',
                            instaLink: '',
                            masterpiece: '',
                            morePoints: '',
                            submitRequest: false
                        })
                        this.hideLoader()
                    })
                    .catch(err => {
                        this.hideLoader()
                        if (err == "Request to become a creator has been raised already.") {
                            this.setState({ isAlert: true, message: "Request to become a creator has been raised already.", style: "danger" })
                            this.gotoTop()
                            // this.props.closeModal()
                            // this.props.errorMessage("Request to become a creator has been raised already.")
                            // this.setState({
                            //     isRequest: false,
                            //     fields: [
                            //         { id: 1, name: "Graphic Artist", isChecked: false }, { id: 2, name: "Photographer", isChecked: false }, { id: 3, name: "Visual Artist", isChecked: false }, { id: 4, name: "Musician", isChecked: false }, { id: 5, name: "Perfoming Artist", isChecked: false },
                            //     ],
                            //     twitterLink: '',
                            //     fbLink: '',
                            //     linkedInLink: '',
                            //     instaLink: '',
                            //     masterpiece: '',
                            //     morePoints: '',
                            //     submitRequest: false
                            // })
                        }
                        // console.log("raise err",err)
                        if (err && err.includes("shortDescription") && err && err.includes("at least")) {
                            this.setState({ isAlert: true, message: "Please provide a proper link", style: "danger" })
                            this.gotoTop()
                            // this.closeAlert()
                        } else if (err && err.includes("longDescription") && err.includes("at least")) {
                            this.setState({ isAlert: true, message: "Your more points must be at least 10 characters long. And more points must not contain leading and trailing spaces", style: "danger" })
                            this.gotoTop()
                            // this.closeAlert()
                        } else if (err && err.includes("shortDescription") && err.includes("at most")) {
                            this.setState({ isAlert: true, message: "Please provide a proper link", style: "danger" })
                            this.gotoTop()
                            // this.closeAlert()
                        } else if (err && err.includes("longDescription") && err.includes("at most")) {
                            this.setState({ isAlert: true, message: "Your more points must be at most 500 characters long. And more points must not contain leading and trailing spaces", style: "danger" })
                            // this.closeAlert()
                            this.gotoTop()
                        }
                        else {
                            this.setState({ isAlert: true, message: err, style: "danger" })
                            this.gotoTop()
                            // this.closeAlert()
                        }
                        // toast.error(err)
                    })
            } else {
                this.setState({ isAlert: true, message: "Please agree the terms and conditions", style: "danger" })
                this.gotoTop()
                // this.closeAlert()
            }
        } else {
            // toast.error("Mandatory fields are missing")
            // console.log("selectedField",'')
        }


    }


    handleChecked = (items) => {
        const { fields } = this.state
        let isChecked = (items && items.isChecked) ? false : true
        let values = []
        fields && fields.map(field => {
            if ((field && field.name) == (items && items.name)) {
                field.isChecked = isChecked
                //    }if((field && field.name) != (items && items.name)){
                //        field.isChecked = false
            }
            values.push(field)

        })
        this.setState({ fields: values }, () => {
            let checkValue = {}
            fields && fields.map(items => {
                if ((items && items.isChecked) == true) {
                    checkValue = items
                }
            })
            this.setState({ checkValue })
        })
    }
    validURL = (str) => {
        var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        const urlReg = /https:\/\/.*/;
        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }
    checkName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 3)){
            return true
        } else {
            return false
        }
    }
    checkmaxPoint = (value) => {
        if ((value && value.length) <= 200 && (value && value.length) >= 10) {
            return true
        } else {
            return false
        }
    }
    handleCloseRequest = () => {
        this.props.closeModal()
        this.setState({
            isRequest: false,
            fields: [
                { id: 1, name: "Graphic Artist", isChecked: false }, { id: 2, name: "Photographer", isChecked: false }, { id: 3, name: "Visual Artist", isChecked: false }, { id: 4, name: "Musician", isChecked: false }, { id: 5, name: "Perfoming Artist", isChecked: false },
            ],
            twitterLink: '',
            fbLink: '',
            linkedInLink: '',
            instaLink: '',
            masterpiece: '',
            morePoints: '',
            submitRequest: false
        })
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    validLinkLength = (value) => {
        if (((value && value.length) <= 120)){
            return true
        } else {
            return false
        }
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()
        }
        return (
            <Fragment>
                <div className="custom-ui text-left py-3 px-5">
                    {this.state.isAlert && <div className="alert-dan mb-3 mx-3" id="parent"  >
                        <div className="d-flex flex-row align-items-center h-100 w-100">
                            <span className="artmsg">{this.state.message}</span>
                            <span className="closebtn" onClick={this.hideAlert}>&times;</span>
                        </div>
                    </div>}

                    <div className="becomeacreator">

                        <div className="form-group">
                            <label>1.What best describes you? &nbsp;*</label>
                            <div className="row">
                                {this.state.fields && this.state.fields.map((items, index) => (
                                    <div className="col-md-3 mb-2">
                                        <input type="checkbox" className="form-check-input" id={items.name} checked={items.isChecked} onClick={() => this.handleChecked(items)} />
                                        <label className="form-check-label" htmlFor={items.name} >{items.name}</label>
                                    </div>))}
                            </div>
                            {this.state.submitRequest && (Object.keys(this.state.checkValue).length === 0) && <div className="mandatory">Please select any one of the field</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="instaLink"> 2.Provide your artwork links</label>                        
                                <input className={(this.state.instaLink && !this.validLinkLength(this.state.instaLink)) ? "form-control descdan" : "form-control"} value={this.state.instaLink} name="instaLink" id="instaLink" onChange={this.handleInputChange} />
                                {this.state.submitRequest && this.state.instaLink && !this.validURL(this.state.instaLink) && <div className="mandatory">Please provide a proper link</div>}  
                                {this.state.submitRequest && this.state.instaLink && !this.validURL(this.state.instaLink) && <div className="mandatory-req">Ex: https://disrupt.art/nft/art/#id</div>}

                                {this.state.submitRequest && this.state.instaLink && !this.checkName(this.state.instaLink) && this.validURL(this.state.instaLink)  && <div className="mandatory">{"Max 120 characters"}</div>}
                                {!this.state.submitRequest && this.state.instaLink && !this.checkName(this.state.instaLink) && this.validURL(this.state.instaLink)  && <div className="mandatory-req">{"Max 120 characters"}</div>}

                          
                        </div>

                        <div className="form-group">
                            
                                <input className={(this.state.linkedInLink && !this.validLinkLength(this.state.linkedInLink)) ? "form-control descdan" : "form-control"} value={this.state.linkedInLink} name="linkedInLink" onChange={this.handleInputChange} />
                                {this.state.submitRequest && this.state.linkedInLink && !this.validURL(this.state.linkedInLink) && <div className="mandatory">Please provide a proper link</div>}
                                {this.state.submitRequest && this.state.linkedInLink && !this.validURL(this.state.linkedInLink) && <div className="mandatory-req">Ex: https://disrupt.art/nft/art/#id</div>}

                                {this.state.submitRequest && this.state.linkedInLink && this.validURL(this.state.linkedInLink) && !this.checkName(this.state.linkedInLink) && <div className="mandatory">{"Max 120 characters"}</div>}
                                {!this.state.submitRequest && this.state.linkedInLink && !this.checkName(this.state.linkedInLink) && this.validURL(this.state.linkedInLink)  && <div className="mandatory-req">{"Max 120 characters"}</div>}

                        </div>
                        <div className="form-group">
                            
                                <input className={(this.state.fbLink && !this.validLinkLength(this.state.fbLink)) ? "form-control descdan" : "form-control"} value={this.state.fbLink} name="fbLink" onChange={this.handleInputChange} />
                                {this.state.submitRequest && this.state.fbLink && !this.validURL(this.state.fbLink) && <div className="mandatory">Please provide a proper link</div>}
                                {this.state.submitRequest && this.state.fbLink && !this.validURL(this.state.fbLink) && <div className="mandatory-req">Ex: https://disrupt.art/nft/art/#id</div>}
                                {this.state.submitRequest && this.state.fbLink && this.validURL(this.state.fbLink) && !this.checkName(this.state.fbLink) && <div className="mandatory">{"Max 120 characters"}</div>}
                                {!this.state.submitRequest && this.state.fbLink && this.validURL(this.state.fbLink) && !this.checkName(this.state.fbLink) && <div className="mandatory-req">{"Max 120 characters"}</div>}

                            
                        </div>
                        <div className="form-group">
                            
                                <input className={(this.state.masterpiece && !this.validLinkLength(this.state.masterpiece)) ? "form-control descdan" : "form-control"} value={this.state.masterpiece} name="masterpiece" onChange={this.handleInputChange} />
                                {this.state.submitRequest && this.state.masterpiece && !this.validURL(this.state.masterpiece) && <div className="mandatory">Please provide a proper link</div>}
                                {this.state.submitRequest && this.state.masterpiece && !this.validURL(this.state.masterpiece) && <div className="mandatory-req">Ex: https://disrupt.art/nft/art/#id</div>}

                                {this.state.submitRequest && this.state.masterpiece && this.validURL(this.state.masterpiece) && !this.checkName(this.state.masterpiece) && <div className="mandatory">{"Max 120 characters"}</div>}
                                {!this.state.submitRequest && this.state.masterpiece && this.validURL(this.state.masterpiece) && !this.checkName(this.state.masterpiece) && <div className="mandatory-req">{"Max 120 characters"}</div>}

                            
                        </div>
                        <div className="form-group">
                           
                                <input className={(this.state.twitterLink && !this.validLinkLength(this.state.twitterLink)) ? "form-control descdan" : "form-control"} value={this.state.twitterLink} name="twitterLink" onChange={this.handleInputChange} />
                                {this.state.submitRequest && this.state.twitterLink && !this.validURL(this.state.twitterLink) && <div className="mandatory">Please provide a proper link</div>}
                                {this.state.submitRequest && this.state.twitterLink && !this.validURL(this.state.twitterLink) && <div className="mandatory-req">Ex: https://disrupt.art/nft/art/#id</div>}

                                {this.state.submitRequest && this.state.twitterLink && this.validURL(this.state.twitterLink) && !this.checkName(this.state.twitterLink) && <div className="mandatory">{"Max 120 characters"}</div>}
                                {!this.state.submitRequest && this.state.twitterLink && this.validURL(this.state.twitterLink) && !this.checkName(this.state.twitterLink) && <div className="mandatory-req">{"Max 120 characters"}</div>}

                           
                        </div>
                        <div className="form-group">
                            <label htmlFor="morePoints">3.Tell us more please? &nbsp;*</label>
                           
                                <input className="form-control" value={this.state.morePoints} name="morePoints" id="morePoints" onChange={this.handlemorepoints} />
                                {this.state.submitRequest && !this.state.morePoints && <div className="mandatory">Please give more about you</div>}                           
                                {this.state.submitRequest && this.state.morePoints && !this.checkmaxPoint(this.state.morePoints) && <div className="mandatory">{"Min 10 chars and Max 200 chars"}</div>}
                                {!this.state.submitRequest && this.state.morePoints && <div className="mandatory-req">{"Min 10 chars and Max 200 chars"}</div>}


                        </div>



                    </div>

                    <div className="form-group mt-3 px-3">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.isAgree} onChange={() => this.handletermsChecked(this.state.isAgree)} />
                        <label className="form-check-label" htmlFor="exampleCheck1"> Do you agree the <a href="https://disrupt.art/nft/terms/" target="blank" className="usernamelilac mr-1"> &nbsp;Terms and conditions</a> and <a href="https://disrupt.art/nft/privacy/" target="blank" className="usernamelilac ml-1 mr-1"> privacy policy</a> of Disrupt.art</label></div>

                    <div className="custom-btn text-center">
                        <button type="button" className="btn btn-primary px-3 mr-2" onClick={this.handleCloseRequest}>Cancel</button>
                        <button type="button" className="btn btn-primary-fill px-3" onClick={this.gotoCreatorRequest} >Apply</button>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const actionCreators = {}

const connectCreatorRequest = connect(mapStateToProps, actionCreators)(CreatorRequest)

export { connectCreatorRequest as CreatorRequest }