export const dropConstants = {
    UPLOAD_DROP : "UPLOAD_DROP",
    GET_DROP : "GET_DROP",
    DETAIL_DROP_ART : "DETAIL_DROP_ART",
    GET_USER_DROP : 'GET_USER_DROP',
    GET_ONE_USER_DROP : 'GET_ONE_USER_DROP',
    GET_AUCTION_DETAIL : 'GET_AUCTION_DETAIL',
    GET_ART_SALE_LIST : 'GET_ART_SALE_LIST',
    GET_ADMIN_DROP_LIST : 'GET_ADMIN_DROP_LIST',
    UPDATE_PRIORITY_RANK : 'UPDATE_PRIORITY_RANK',
    WITHDRAW_ART : 'WITHDRAW_ART',
    WITHDRAW_ART_FAILED : 'WITHDRAW_ART_FAILED'
}