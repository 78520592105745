import PubSub from 'pubsub-js';
import { userServices } from "services";
import jwtDecode from "jwt-decode";




export async function refreshJWTFunc() {
    const webLoginDetails = JSON.parse(localStorage.getItem('webLogin'));
    if (
        webLoginDetails &&
        webLoginDetails.data &&
        webLoginDetails.data.accessToken
    ) {
        const decodedJWT = jwtDecode(webLoginDetails.data.accessToken);
        if (decodedJWT['roleId'] === 2) {
            try {
                // PubSub.publish('msg', true);
                const resp = await userServices.refresh()
                // PubSub.publish('msg', false);
                if ((resp && resp.statusCode === 200)) {
                    updateAccessJWT(resp)
                } else {
                    if ((resp && resp.statusCode === 400)) {
                        if ((resp && resp.data && resp.data.message)) {
                            return Promise.reject(`Unexpected error has occurred. Please try again later`)
                        } else {
                            return Promise.reject(`Unexpected error has occurred. Please try again later`)
                        }
                    } else {
                        return Promise.reject(`Unexpected error has occurred. Please try again later`)

                    }
                }


            } catch (err) {
                return Promise.reject("Unhandled error occured while refreshing JWT")
            }
        }
        console.log(decodedJWT)
    }

}

function updateAccessJWT(refreshResponseBody) {
    const webLoginDetails = JSON.parse(localStorage.getItem('webLogin'));
    if (
        webLoginDetails &&
        webLoginDetails.data &&
        webLoginDetails.data.accessToken &&
        refreshResponseBody &&
        refreshResponseBody.data &&
        refreshResponseBody.data.accessToken
    ) {

        // Update the new access JWT in the local storage
        webLoginDetails.data.accessToken = refreshResponseBody.data.accessToken;
        const userDetail = JSON.stringify(webLoginDetails);
        localStorage.setItem("webLogin", userDetail);
    }
}