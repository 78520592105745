import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices } from "services";
import PubSub from 'pubsub-js';
import { DraggableCreators } from "./DraggableCreators";

export class AdminConfiguration extends React.Component {

    constructor(props) {
        super(props);
  
        this.featuredCreatorSelected = this.featuredCreatorSelected.bind(this);
        this.featuredCreatorDeletedHandler = this.featuredCreatorDeletedHandler.bind(this);
        
        this.state = {
            priorityCreators: [],
            
            offSet : 0,
            recordLimit : 10,
            sortBy : 'updatedAt',
            sortOrder : 'DESC',
           
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount(){
        this.getPriorityCreator()
        
    }
   


    getPriorityCreator = (offSet,recordLimit) => {
        let priorityCreators = []
        //  this.showLoader()
         adminServices.getPriorityCreators().then(res => {
            // this.hideLoader()
            if(res && res.statusCode == 200){
                console.log(res)
                res && res.data && res.data.creators && res.data.creators.forEach((item) => {
                    priorityCreators.push({
                        text:item && item.userName,
                        id:item && item.id,
                        subText: (item &&
                            item?.accountStatus === 0)
                            ? '(Disabled)' : undefined
                    })
                })
            this.setState({priorityCreators})

            }
        }).catch(e=>{ this.hideLoader() })
    }

   
   
    featuredCreatorSelected(e) {
        const { priorityCreators } = this.state;
    
        const isArtSelected = priorityCreators.find(art => art.id === e.artId)
        if (
            !isArtSelected
        ) {
           

            this.showLoader()
            adminServices.addPriorityCreators(e.artId).then((response) => {
                this.hideLoader()
              
                    this.getPriorityCreator('','')
                toast.success("Creator added as Priority Creator")
                priorityCreators.push({
                    id: e.artId,
                    text: e.mainText
                })
                this.setState({
                    priorityCreators: Array.from(priorityCreators)
                });
            
            }).catch(e => {
                this.hideLoader()
                toast.error(e)})
           
        }else{
            toast.error("Creator already added as priority creator")
        }
    }

    featuredCreatorDeletedHandler(item, e) {
        if((item && item.id)){
            this.showLoader()
            adminServices.removePriorityCreators(item.id).then(update => {
                this.hideLoader()
                
                    this.getPriorityCreator('','')
                    toast.success("Creator removed from priority creators")

            }).catch(e => {
                this.hideLoader()
                toast.error(e)
            })
        }
        
    }


  
  
    render() {

        const {
            priorityCreators
        } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Priority Creators </h2>
                            {/* <span className="betaLink">(Still not integrated on marketplace, Available only on Super Admin)</span> */}
                        </div>
                    </div>
                    
                    
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <h3>Select priority creator</h3>
                            <p className="small font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 Disabled creators are not visible to end users
                            </p>
                            <AutoSuggestInput
                                id="featuredCreatorSuggest"
                                placeholder="Search creators"
                                isFeaturedCreator = {true}
                                onSuggetionSelected={this.featuredCreatorSelected}
                            />
                            {priorityCreators && priorityCreators.length > 0 && <DraggableCreators id="featuredCreatorList" data={priorityCreators} onItemDelete={this.featuredCreatorDeletedHandler} />}
                        </div>
                    </div>
                   
                </div>
            </Fragment>
        );
    }
}