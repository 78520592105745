import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { configPath } from "../config";

class About extends Component {
	render() {
		return (
			<Fragment>
				<div className="container">
					<div className="pagetitabtn">
						<div className="row">
							<div className="col-md-12">
								<h1 className="w-auto text-center ">Our Story</h1>
							</div>
						</div>
					</div>
					<hr />
					<section>
						<div className='row py-3 justify-content-center statcontsec'>
							<div className='col-md-6'>
								{/* <img src={`/${configPath.imageAssets}/Woman-Painting-1024x1024.png`} alt="" className='mw-100 mb-5' /> */}
								<h3 className='usernamelilac'>Our Mission</h3>
								{/* <p className='text-justify d-none'>Creators around the globe struggle to receive&nbsp; meaningful compensation for the value of their creative work. This is even more true for black and brown creators. NFTs are a generational wealth opportunity but equity won’t happen in the blockchain economy without being intentional.&nbsp; That is why we created a new Black owned NFT minting platform disrupt Art.&nbsp; We are focused on equity, diversity and bridging NFT technology to the world.&nbsp; We work with activists, allies and organizations to build a more equitable future using NFTs.&nbsp; Join us and disrupt.</p> */}
								<p className="text-justify">At the intersection of art and activism, DisruptArt aims to curate an interactive space for artists, collectors, and activists to revolutionize the impact of art. Artists sell their pieces as a digital creation through blockchain technology. Our vision is to create a community where spoken word artists, musicians, entrepreneurs, photographers, and crypto artists intersect, creating dialogue about social impact, contemporary art, and the blockchain. We believe artists deserve more equity and it’s why we ensure artists receive a majority of the funds and always receive royalty for their work.</p>

							</div>
						</div>
					</section>
				</div>
			</Fragment>
		)
	}
}
export { About }