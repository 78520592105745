import React from "react";
import * as fcl from "@onflow/fcl";
import { NavLink, Link } from "react-router-dom";
import { userBalance, isConnectWallet, isWebLogin, Categories, localArtPage, localDropPage, localAirdropPage, accountSetup } from "helpers";
import { creatorActions, flowActions, joyrideActions, userAction, walletActions } from "store/actions";
import { connect } from "react-redux";
import { configPath } from "../config";
import history from "helpers/history";
import Modal from 'react-modal'
import PubSub from 'pubsub-js';
import { Alert } from "helpers/alert";
import { CreatorRequest } from "./creatorRequest";
import { createFusdAccount, fusdbalance } from "utils/fusd";
import { createSetUp, getUserAddress, RetrieveTokens } from "utils";
import { GlobalSearch } from "./global-search/global-search";
import { ProfilePreview } from "./profilePreview";
import { GlitterText } from "./glitterText";
import { connectBlocto } from "helpers/getFlowUsd";
import { JoyRide } from "./Joy-ride";
import { checkCommunityAirdrop, checkHomeLoginPage, checkJoyRide, checkWithEnableTour, initiateTour, resetToTrue, stopTourGuide } from "helpers/joy-rideCheck";
import Toggle from 'react-toggle';
import { isDapperConnected } from "helpers/switchauthdapper";
import { SwitchAccount } from "./switchaccountPopup";
import { flowgetdetails } from "utils/flowstorage";

var timeout;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '700px', // <-- This sets the height
        overlfow: 'auto' // <-- This tells the modal to scrol
    },
};

class Navbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            status: false,
            selectedField: {},
            checkValue: {},
            isApply: false,
            instaLink: '',
            twitterLink: '',
            masterpiece: '',
            waLink: '',
            fbLink: '',
            linkedInLink: '',
            morePoints: '',
            fields: [
                { id: 1, name: "Graphic Artist", isChecked: false }, { id: 2, name: "Photographer", isChecked: false }, { id: 3, name: "Visual Artist", isChecked: false }, { id: 4, name: "Musician", isChecked: false }, { id: 5, name: "Perfoming Artist", isChecked: false },
            ],
            title: "copy address",
            rate: '',
            profileImage: '',
            isLogin: false,
            openLogin: false,
            isRegister: false,
            isRequset: false,
            isClick: "",
            user: '',
            isSuccess: false,
            submitRequest: false,
            isTest: false,
            ShowPop: true,
            isActSetup: false,
            path: '',
            isEnable: false,
            openTour: false,
            closeWallet: false,
            closeTooltip: false,
            isSwitchAcc: false,
            isSwitchWalletAcc: false,
            isSwitchAccForMint: false,
            storageDetails: {}
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {

        let data = localStorage.getItem('webLogin')
        let loginData = JSON.parse(data)
        this.setState({ user: loginData && loginData.data && loginData.data.authUser && loginData.data.authUser.userName })

        this.getBalance()
        this.checkWalletConnection()
        this.props.changeTourOption()

        userBalance().then(text => {
            if (text && text.address) {
                flowgetdetails(text.address).then((res) => {
                    if (res && res.status && res.status === 200 && res.data.Value) {
                        console.log(res.data.Value, text.balance)

                        this.setState({ address: text.address, storageDetails: res.data.Value }, () => {
                            this.handleCopyAddressTitle()
                        })
                    }


                }).catch(err => console.error(err))
            }
            // if (text && text.balance && text.balance.data && text.balance.data.Value) {
            //     this.setState({
            //         flowBalance: JSON.parse(text.balance.data.Value),

            //     }, () => {
            //         this.handleCopyAddressTitle()
            //     })

            // }
        }).catch(err => { })

        this.renderTourSteps()
        this.handleCopyAddressTitle()

        window.addEventListener('scroll', () => {
            let activeClass = 'da-fixed';
            if (window.scrollY === 0) {
                activeClass = '';
            }
            this.setState({ activeClass });
        });


    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.usersList && props.usersList.statusCode == 200)) {
            return {
                profileData: props && props.usersList && props.usersList.data
            }
        }
        if (props && props.tourOption) {
            return {
                isEnable: props.tourOption
            }
        } else {

            return {
                isEnable: false
            }
        }
        return null
    }


    gotoTesting = () => {
        this.setState({ isTest: true })
    }
    closeTest = () => {
        this.setState({ isTest: false })
    }

    componentDidUpdate(props, state) {
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            props.resetlinkWallet()
            this.getBalance()

            userBalance().then(text => {
                if (text && text.address) {
                    flowgetdetails(text.address).then((res) => {
                        if (res && res.status && res.status === 200 && res.data.Value) {

                            this.setState({ address: text.address, storageDetails: res.data.Value }, () => {
                                this.handleCopyAddressTitle()
                            })
                        }
                    }).catch(err => console.error(err))
                }
                // if (text && text.balance && text.balance.data && text.balance.data.Value) {
                //     this.setState({ flowBalance: JSON.parse(text.balance.data.Value) }, () => {
                //         this.handleCopyAddressTitle()
                //     })

                // }
            }).catch(err => { })
            if (this.state.path !== '') {
                this.createaccountSetup()
            } else if (this.state.isSwitchWalletAcc) {
                this.setState({ isSwitchWalletAcc: false })
                this.createSwitchAccAccountSetup()
            }
            this.handleCopyAddressTitle()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

        if ((this.props.walleterror && this.props.walleterror.length > 0) && (props.walleterror) != (this.props.walleterror)) {
            props.resetwalleterror()
            this.setState({
                isAlert: true,
                message: this.props.walleterror,
                style: "danger",

            })
            this.closeAlert()
        }
        if ((this.props.walletsuccess && this.props.walletsuccess.length > 0) && (props.walletsuccess) != (this.props.walletsuccess)) {
            props.resetwalletsuccess()
            this.setState({
                isAlert: true,
                message: this.props.walletsuccess,
                style: "success",

            })
            this.closeAlert()
        }
        if ((this.props && this.props.isDisconnectWallet) && (props.isDisconnectWallet) != (this.props.isDisconnectWallet)) {
            this.props.resetDisconnectWallet()
        }
    }
    getBalance = () => {
        getUserAddress().then(add => {
            if (add && add != null) {
            }
        })
    }
    handleCopy = (value) => {
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({ title: <>Copied</> });
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        setTimeout(() => {
            this.handleCopyAddressTitle()
            this.setState({ active: currentState });
        }, 1000);
    }

    handleWalletLogin = async (path) => {
        this.setState({ targetPath: path })
        try {
            const wallet = await connectBlocto()
            if (wallet && wallet.walletAddress) {
                this.props.linkWallet(JSON.stringify(wallet))
                this.setState({
                    path
                })

            } else {
                this.setState({
                    isAlert: true,
                    message: wallet,
                    style: "danger"
                })
                this.resetTimeout()
                this.closeAlert()
            }
        } catch (e) {
            this.setState({
                isAlert: true,
                message: e,
                style: "danger"
            })
            this.resetTimeout()
            this.closeAlert()
        }
    }


    walletLogout = () => {
        this.resetTour()
        fcl.unauthenticate()
        localStorage.removeItem('walletDetail')
        history.push('/')


    }

    handleLogout = () => {
        this.resetTour()

        userAction.logout()
    }

    logout = () => {
        this.resetTour()
        userAction.logout()


    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {
        })
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }

    checkWalletConnection = () => {
        getUserAddress().then(res => {
            let wallet = localStorage.getItem("walletDetail")
            let walletDetail = JSON.parse(wallet)

            if ((res && res != null && wallet && wallet != null && walletDetail != null && (walletDetail.result && walletDetail.result.WalletAddress) && (res != walletDetail.result.WalletAddress))) {

                this.walletLogout()
            }
        })



    }

    gotoCollections = () => {
        this.handlePage()

        isWebLogin() ?
            history.push('/wallet')
            : this.handleWalletLogin("/wallet")

    }

    connectWallet = () => {
        this.setState({ closeWallet: true })
        this.handleWalletLogin('/discover')
    }

    gotoCreateArt = () => {

        isConnectWallet() ?
            history.push('/mint')
            : this.handleToSwitchAccountForMint()

    }

    handleLogin = () => {
        history.push('/login')
    }

    closeLogin = () => {
        this.setState({ isLogin: false })
    }
    handleRegister = () => {
        this.setState({ isRegister: true })
    }
    closeRegister = () => {
        this.setState({ isRegister: false })
    }

    gotoProfile = () => {
        if (isWebLogin() && ((this.state.profileData && this.state.profileData.roleId) == 1)) {
            history.push(`/creator/@${encodeURIComponent(this.state.profileData && this.state.profileData.userName)}`)
        } else if (isWebLogin() && ((this.state.profileData && this.state.profileData.roleId) == 2)) {
            history.push(`/collector/@${encodeURIComponent(this.state.profileData && this.state.profileData.userName)}`)

        }
    }
    gotoAccounts = () => {
        history.push(`/useraccount`)
    }
    gotoAirDrops = () => {
        this.handlePage()
        localAirdropPage()
        // isWebLogin() ? 
        this.setState({ closeTooltip: true })
        history.push("/airdrops")
        // :
        // history.push('/login') 
    }
    gotoCollectors = () => {
        this.handlePage()
        history.push("/collectors")
    }
    call = () => {

        setTimeout(
            this.props.getUserProfile, 3000);

    }
    closeModal = () => {
        this.setState({ openLogin: false })
    }


    handleError = (message) => {
        this.setState({ isAlert: true, message, style: "danger" })
        this.closeAlert()
    }


    toggleArrow = function () {
        if (this.state.status) {
            //closes it
            document.getElementById('navbarSupportedContent').classList.remove('show');
            document.getElementById('togglemenu').classList.remove('close');

        } else {
            //open
            document.getElementById('navbarSupportedContent').classList.add('show');
            document.getElementById('togglemenu').classList.remove('close');
        }

        this.setState({ status: !this.state.status })
    }.bind(this);


    handlePage = () => {
        localStorage.setItem("page", "0")
    }
    handleArtPage = () => {
        localStorage.setItem("artPage", "0")
        localArtPage()
    }
    handleDropPage = () => {
        localArtPage()
        localDropPage()
    }
    gotoFundWallet = () => {
        history.push('/fund-wallet')
    }
    gotoArt = () => {
        this.handleArtPage()

        history.push('/arts')
        var dropdowns = document.getElementsByClassName("dropdown-content");
        if (dropdowns && dropdowns[0] && dropdowns[0].classList && dropdowns[0].classList.contains('show')) {
            dropdowns[0].classList.remove('show');
            console.log(dropdowns)
        } else {
            console.log(dropdowns)
        }
    }

    createaccountSetup = async () => {
        const walletAddress = await getUserAddress()

        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.props.walletSuccessSetup(setup.message)
                history.push(this.state.path)
                this.setState({
                    path: ''
                })
                this.renderTourSteps()

            } else if (setup && setup.status === 201) {
                history.push(this.state.path)
                this.setState({
                    path: ''
                })
                this.renderTourSteps()
            } else {
                this.props.walletnotsetup(setup)
                this.setState({
                    path: ''
                })
            }
        } catch (e) {
            this.props.walletnotsetup(e)
        }

    }
    statusChange = () => {

        localStorage.removeItem('joy-ride-storage')
        localStorage.removeItem('completed_ride')

        if (checkWithEnableTour()) {
            localStorage.setItem('enable_tour', JSON.stringify(false))
            resetToTrue()
            stopTourGuide()
            this.props.changeTourOption()
        } else {
            localStorage.setItem('enable_tour', JSON.stringify(true))
            checkJoyRide()
            initiateTour()
            this.props.changeTourOption()
            history.push('/')
        }
    }
    closeTour = () => {
        this.setState({
            isCloseTour: true
        })
    }
    gotoDashboard = () => {
        const baseurl = process.env.REACT_APP_BASEPATH
        const navPath = (baseurl + `/store/adddrops`)

        window.open(navPath, '_blank')
    }
    handleTourChange = () => {
        this.setState({ openTour: !this.state.openTour })
    }
    renderTourSteps = () => {
        // const isTourEnabled = localStorage.getItem('enable_tour')
        // if(isTourEnabled){
        //     this.props.callJoyRide('home_login',isConnectWallet())

        // }
    }
    handleLogintour = () => {
        this.setState({ closeWallet: true })
    }
    handleCloseTooltip = () => {
        this.setState({ closeTooltip: true })
    }
    resetTour = () => {
        localStorage.removeItem('joy-ride-storage')
        localStorage.removeItem('completed_ride')
        localStorage.setItem('enable_tour', JSON.stringify(false))

        resetToTrue()
        stopTourGuide()
    }
    gotoFavourites = () => {
        this.handlePage()

        isWebLogin() ?
            history.push('/favourites')
            : this.handleWalletLogin("/favourites")

    }
    handleCopyAddressTitle = () => {

        this.setState({
            title: (
                <>
                    {this.state.storageDetails.balance ? parseFloat(this.state.storageDetails.balance).toFixed(5) : ''} FLOW<br />
                    Used: {this.convertToMB(this.state.storageDetails.storageUsed)} MB<br />
                    Capacity: {this.convertToMB(this.state.storageDetails.storageCapacity)} MB
                </>
            ),
        });

    }
    convertToMB = (bytes) => {
        return (bytes / 1048576).toFixed(2);
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isSwitchWalletAcc: true, path: '' })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger"
        })
        this.resetTimeout()
        this.closeAlert()
    }
    createSwitchAccAccountSetup = async () => {
        const walletAddress = await getUserAddress()

        try {
            const setup = await accountSetup(walletAddress)
            if (setup && setup.status === 200) {
                this.props.walletSuccessSetup(setup.message)


            } else if (setup && setup.status === 201) {

            } else {
                this.props.walletnotsetup(setup)

            }
        } catch (e) {
            this.props.walletnotsetup(e)
        }
    }
    closeSwitchAccModalForMint = () => {
        this.setState({
            isSwitchAccForMint: false
        })
    }
    handleToSwitchAccountForMint = () => {
        this.setState({
            isSwitchAccForMint: true
        })
    }
    switchWalletConnectedForMint = (wallet) => {
        if (wallet) {
            this.closeSwitchAccModalForMint()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isSwitchWalletAcc: false, path: '/mint' })
        }
    }
    handleSwitchAccFailedForMint = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger"
        })
        this.resetTimeout()
        this.closeAlert()
    }
    isLandingPage = () => {
        const location = window.location.pathname
        if (location && location.includes('landing')) {
            return true
        } else {
            return false
        }

    }
    gotoExplore = () => {
        history.push('/discover')
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    render() {
        const path = this.props.appPath;
        return (
            <div className="w-100">
                {/* {this.state.isCloseTour ? '' : <span className={(this.state.openTour) ? "takeatour fixedrigt fxtmob" : "takeatour fixedrigt"} >
                    <p onClick={this.handleTourChange}>Take a Tour</p>
                    <div className="edtoggle">
                        {this.state.isEnable ? '' : <span>Enable</span>}
                        <Toggle checked={this.state.isEnable} onChange={this.statusChange} />
                        {this.state.isEnable ? <span>Enabled</span> : ''}
                    </div>
                </span>}
                <JoyRide closeWallet={this.state.closeWallet} closeTooltip={this.state.closeTooltip}/> */}
                {/* {!isWebLogin() ?
                <JoyRide start={checkHomeLoginPage()} page="home_login" /> : 
                (isWebLogin() && !isConnectWallet()) ? 
                <JoyRide start={checkHomeLoginPage()} page="home_login" /> : 
                <JoyRide start={checkCommunityAirdrop()} page="home_airdrop"/>} */}
                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3">Thanks for applying to become one of a creator in Disrupt.art, <br /> We will review your application and upgrade your account as a creator account soon. <br />Until then enjoy Arts & Drops and start collecting more NFT in Disrupt.art.</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); this.setState({ isSuccess: false }) }}>Close</button></div></div>
                }
                {this.state.isActSetup &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Accessing your Flow wallet account</p>
                            {/* <div className="bwspinner"></div> */}
                            <img src={`../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "45%", display: "block", marginLeft: "auto", marginRight: "auto" }} />

                        </div>
                    </div>
                }
                {!this.state.ShowPop &&
                    <div className="show-content">
                        <div className="child-show purchasebox">
                            <p className="text-white">Processing to <b>FUSD</b> account connection for transaction</p>

                            <div className="bwspinner"></div>

                        </div>
                    </div>
                }

                <nav className="navbar navbar-expand-lg navbar-light disnowmenu sticky-sm-top">


                    {this.state.isSwitchAcc ?
                        <SwitchAccount
                            closeModal={this.closeSwitchAccModal}
                            switchWalletConnected={(val) => this.switchWalletConnected(val)}
                            handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)} />
                        : ''}
                    {this.state.isSwitchAccForMint ?
                        <SwitchAccount
                            closeModal={this.closeSwitchAccModalForMint}
                            switchWalletConnected={(val) => this.switchWalletConnectedForMint(val)}
                            handleSwitchAccFailed={(val) => this.handleSwitchAccFailedForMint(val)} />
                        : ''}
                    <Modal
                        isOpen={this.state.isTest}
                        style={customStyles}
                        className="creatorrequ"
                        contentLabel="Example Modal"

                    >

                        <button onClick={this.closeTest} className="btn btn-primary px-2 clsbtn">X</button>
                        <CreatorRequest closeModal={this.closeTest} success={() => this.setState({ isSuccess: true })} errorMessage={(mes) => this.handleError(mes)} />

                    </Modal>


                    <div className="container-fluid">

                        <a className="navbar-brand cursor-pointer" onClick={this.gotoExplore} >
                            <img src={`/${configPath.imageAssets}/DA-new-logo.png`} alt="" className="logoaw logoaw1" />
                            <img src={`/${configPath.imageAssets}/disruptart-p.png`} alt="" className="logoaw logoaw2" />
                        </a>

                        {/* <button onClick={() => this.toggleArrow()} id="togglemenu" className={`${this.props.className} navbar-toggler collapsed`} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
                        <div className="desknone">
                        <ul className="navbar-nav mobnavnew">
                            <li className="nav-item" 
                            // data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            >
                                <NavLink className="nav-link drops" to={`${path}/drops`} onClick={this.handleDropPage} >Gallery</NavLink></li>


                            <li className="nav-item community_airdrop" 
                            // data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            >
                                <NavLink className="nav-link " to={`${path}/airdrops`} onClick={this.gotoAirDrops} style={{ cursor: "pointer" }}>ArtDrops</NavLink></li>

                        </ul>
                        </div>


                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="toggleicon">
                                <i className="bars"></i>
                                <i className="bars"></i>
                                <i className="bars"></i>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse tatcon" id="navbarSupportedContent">
                            {/* {this.state.isCloseTour ? '' : <span className="takeatour">
                                <span className="tatcls" onClick={this.closeTour}>x</span>
                                <p>Take a Tour</p>
                                <div className="edtoggle">
                                    {this.state.isEnable ? '' : <span>Enable</span>}
                                    <Toggle checked={this.state.isEnable} onChange={this.statusChange} />
                                    {this.state.isEnable ? <span>Enabled</span> : ''}
                                </div>
                            </span>} */}
                            <ul className="navbar-nav mb-2 mb-lg-0 mobmenuhide globalsearchbox ml-auto">

                                <li className="nav-item">
                                    <GlobalSearch />
                                </li>

                            </ul>

                            {/* <a href="https://www.eventbrite.com/e/block-party-disrupt-art-tickets-296756716327?ref=estw" target={"_blank"} className="rainbow rainbow_text_animated mobmenuhide">RSVP for SXSW Block Party</a> */}

                            {/* <a href={`${process.env.REACT_APP_DISRUPT_BAB}`} target="_blank" className="rainbow rainbow_text_animated mobmenuhide">Badazz Bears NFT backed by Jim Jones</a>   */}
                            {/* 
                            <GlitterText addclass="mx-auto mobmenuhide" /> */}


                            <ul className="navbar-nav mb-md-2  mb-lg-0 menulink px-0">

                                <li className="desknone" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"><a className="navbarbrandmob cursor-pointer" onClick={this.gotoExplore}> <img src={`/${configPath.imageAssets}/DA-new-logo.png`} alt="" /></a></li>

                                <li className="nav-item desknone" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                    <div className="flowboxmob flow_address">
                                        {(isConnectWallet() && isWebLogin()) ? <span onClick={() => this.handleCopy(this.state.address)} className={this.state.active ? 'activecopy card flowtop' : 'card flowtop'}>
                                            <a className="mobsidepro" href={`${path}/profile`}>
                                                <span className="upic m-0">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <ProfilePreview src={isWebLogin() && this.state.profileData && this.state.profileData.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${this.state.profileData && this.state.profileData.profileImage}`} alt="" />
                                                        </span>
                                                    </span>
                                                </span>

                                                <h6 className="text-center mb-1" title={this.state.profileData && this.state.profileData.userName}> {this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6>
                                            </a>
                                            <span className="nav-link d-none" title={this.state.flowBalance && this.state.flowBalance}>{this.state.flowBalance ? parseFloat(this.state.flowBalance).toFixed(5) : ''} Flow<br /> </span>

                                            <span className="nav-link " title={this.state.balance && this.state.balance}>
                                                {/* {(this.state.balance && this.state.balance > 0) ? parseFloat(this.state.balance).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : this.state.balance ? parseFloat(this.state.balance).toFixed(1) : ''} FUSD<br /> */}
                                                {/* {isDapperConnected() ?
                                                    <span className="dapbloc"><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw " /> Dapper</span> :
                                                    <span className="dapbloc"><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " />
                                                        <bdi>Blocto</bdi>
                                                    </span>}
                                                {this.state.address} */}
                                                <span className="dbsline">
                                                    {isDapperConnected() ?
                                                        <span className="dapbloc"><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw " /></span> :
                                                        <span className="dapbloc"><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " /></span>}
                                                    {this.state.address}
                                                </span>
                                            </span>

                                            <span className="copycon">
                                                <span className="uparrow"></span>
                                                {this.state.title}
                                            </span>

                                        </span> : isWebLogin() && <span className="text-center ">
                                            <a className="mobsidepro" href={`${path}/profile`}>
                                                <span className="upic m-0">
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <ProfilePreview src={isWebLogin() && this.state.profileData && this.state.profileData.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${this.state.profileData && this.state.profileData.profileImage}`} alt="" />
                                                        </span>
                                                    </span>
                                                </span>

                                                <h6 className="text-center mb-1" title={this.state.profileData && this.state.profileData.userName}> {this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6>
                                            </a>
                                            <a onClick={this.handleToSwitchAccount} className="btn btn-primary-gradient my-3">Connect Wallet</a></span>}

                                        {isConnectWallet() && <span className="nav-link d-none" >
                                            <NavLink className="nav-link" to={'/fund-wallet'}>Buy Flow</NavLink>
                                        </span>}
                                        {isConnectWallet() && <span className="nav-link" title={"Switch wallet account"}>
                                            <span className="nav-link" onClick={this.handleToSwitchAccount}>Switch</span>
                                        </span>}
                                    </div>
                                </li>

                                {/* <li className="nav-item">
                                    <NavLink className="nav-link discover" to={`${path}/discover`}>Discover</NavLink></li> */}
                                <li className="nav-item mobmenuhide" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                    <NavLink className="nav-link drops" to={`${path}/drops`} onClick={this.handleDropPage} >Gallery script</NavLink></li>


                                <li className="nav-item community_airdrop mobmenuhide" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                    <NavLink className="nav-link " to={`${path}/airdrops`} onClick={this.gotoAirDrops} style={{ cursor: "pointer" }}>ArtDrops</NavLink></li>

                                {!isWebLogin() && <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                    <NavLink className="nav-link login_dist" to={`/login`} onClick={this.handleLogintour}>Login</NavLink></li>}
                                {!isWebLogin() && <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                    <NavLink className="nav-link btn btn-primary ml-2" to={`${path}/signup`}>Sign Up</NavLink></li>}

                                <li className="nav-item flowboxdes flow_address fund_wallet_menu mobmenuhide">


                                    {(isConnectWallet() && isWebLogin()) ? <span onClick={() => this.handleCopy(this.state.address)} className={this.state.active ? 'activecopy card flowtop' : 'card flowtop'}>
                                        <h6 className="text-center mb-1" title={this.state.profileData && this.state.profileData.userName}> {this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6>
                                        <span className="nav-link d-none" title={this.state.flowBalance && this.state.flowBalance}>{this.state.flowBalance ? parseFloat(this.state.flowBalance).toFixed(5) : ''} Flow<br /> </span>

                                        <span className="nav-link" title={this.state.balance && this.state.balance}>
                                            {/* {(this.state.balance && this.state.balance > 0) ? parseFloat(this.state.balance).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : this.state.balance ? parseFloat(this.state.balance).toFixed(1) : ''} FUSD<br /> */}

                                            {/* {isDapperConnected() ?
                                                <span className="dapbloc"><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw dapper" /> Dapper</span> :
                                                <span className="dapbloc"><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " />
                                                    <bdi className="blk">Blocto</bdi>
                                                </span>}
                                            {this.state.address} */}

                                            <span className="dbsline">
                                                {isDapperConnected() ?
                                                    <span className="dapbloc"><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw " /></span> :
                                                    <span className="dapbloc"><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " /></span>}
                                                {this.state.address}
                                            </span>

                                        </span>
                                        <span className="copycon">
                                            <span className="uparrow"></span>
                                            {this.state.title}
                                        </span>

                                        {/* <span className="nav-link" onClick={this.gotoFundWallet}>
                                                Fund Wallet
                                    </span> */}
                                    </span> : isWebLogin() && <span className="text-center"> <h6 className="text-center mb-1 " title={this.state.profileData && this.state.profileData.userName}>{this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6> <a onClick={this.handleToSwitchAccount} className="btn btn-primary-gradient connect_wallet mr-2">Connect Wallet</a></span>}
                                    {isConnectWallet() &&
                                        <NavLink className="nav-link text-center fund_wallet_menu d-none" to={'/fund-wallet'} style={{ cursor: "pointer" }} onClick={this.handleCloseTooltip}>Buy Flow</NavLink>}
                                    {isConnectWallet() &&
                                        <span className="nav-link text-center fund_wallet_menu" style={{ cursor: "pointer" }} onClick={this.handleToSwitchAccount} title={"Switch wallet account"}>Switch</span>}

                                </li>





                                {isWebLogin() && <li className="nav-item propic br30  p-1 d-flex align-items-center flex-column">
                                    {/* <h6 className="text-center mb-1 mobmenuhide" title={this.state.profileData && this.state.profileData.userName}> {this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6> */}
                                    <span className="dropdown mobmenuhide">
                                        <span className="upic m-0 ">
                                            <span className="artcovimg">
                                                <span className="artcov"></span>
                                                <span className="artcovimginset no-proimg">
                                                    <ProfilePreview src={isWebLogin() && this.state.profileData && this.state.profileData.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${this.state.profileData && this.state.profileData.profileImage}`} alt="" />
                                                </span>
                                            </span>
                                        </span>
                                        {/* <span className="toggleicon">
                                            <i className="bars"></i>
                                            <i className="bars"></i>
                                            <i className="bars"></i>
                                        </span> */}

                                        <span className="dropdown-content">
                                            <span className="ddpnbox">
                                                <h6 className="text-center mb-1 mobmenuhide" title={this.state.profileData && this.state.profileData.userName}> {this.state.profileData && ((this.state.profileData.userName && this.state.profileData.userName.includes('@')) ? this.state.profileData.userName : (`@${this.state.profileData.userName}`))}</h6>
                                            </span>


                                            <a onClick={this.gotoCollections} style={{ cursor: "pointer" }}>My Collection</a>
                                            {((this.state.profileData && this.state.profileData.roleId == 1)) ? <a onClick={this.gotoCreateArt} style={{ cursor: "pointer" }}>Mint NFT</a> : <a onClick={this.gotoTesting} style={{ cursor: "pointer" }} >Creator Request</a>}
                                            {/* <a onClick={this.gotoAirDrops} style={{ cursor: "pointer" }}>Air Drops</a> */}

                                            <a onClick={this.gotoProfile} style={{ cursor: "pointer" }}>Profile</a>
                                            <a onClick={this.gotoAccounts} style={{ cursor: "pointer" }}>Accounts</a>

                                            <a onClick={this.gotoFavourites} style={{ cursor: "pointer" }}>Favourites</a>

                                            {(
                                                (this.state.profileData && this.state.profileData.roleId == 1) &&
                                                (this.state.profileData.storeStatus === 1) &&
                                                (this.state.profileData.storeRequestStatus === 4)) ?
                                                <a onClick={this.gotoDashboard} style={{ cursor: "pointer" }}>Store Dashboard</a> : ''}
                                            {(isConnectWallet()) ? <Link onClick={this.walletLogout} to="" className="designLink btn btn-primary">
                                                {isDapperConnected() ?
                                                    <img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw " /> :
                                                    <img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " />}

                                                Disconnect Wallet
                                            </Link> : ''}


                                            {(isWebLogin() && !isConnectWallet()) ? <Link onClick={this.logout} to="">Log Out</Link> : <Link onClick={this.handleLogout} to="">Log Out</Link>}



                                        </span>
                                    </span>
                                </li>}
                            </ul>

                            {isWebLogin() && <ul className="desknone navbar-nav menulink">
                                <li className="nav-item " data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"><a className="nav-link" onClick={this.gotoCollections} style={{ cursor: "pointer" }}>My Collection</a> </li>

                                <li className="nav-item " data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">{((this.state.profileData && this.state.profileData.roleId == 1)) ? <a className="nav-link" onClick={this.gotoCreateArt} style={{ cursor: "pointer" }}>Mint NFT</a> : <a className="nav-link" onClick={this.gotoTesting} style={{ cursor: "pointer" }} >Creator Request</a>}</li>
                                {/* <a onClick={this.gotoAirDrops} style={{ cursor: "pointer" }}>Air Drops</a> */}

                                <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">            <a className="nav-link" onClick={this.gotoProfile} style={{ cursor: "pointer" }}>Profile</a></li>
                                <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">            <a className="nav-link" onClick={this.gotoAccounts} style={{ cursor: "pointer" }}>Accounts</a></li>

                                <li className="nav-item " data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"><a className="nav-link" onClick={this.gotoFavourites} style={{ cursor: "pointer" }}>Favourites</a> </li>

                                <li className="nav-item " data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">        {(
                                    (this.state.profileData && this.state.profileData.roleId == 1) &&
                                    (this.state.profileData.storeStatus === 1) &&
                                    (this.state.profileData.storeRequestStatus === 4)) ?
                                    <a className="nav-link" onClick={this.gotoDashboard} style={{ cursor: "pointer" }}>Store Dashboard</a> : ''}

                                    {(isConnectWallet()) ? <Link onClick={this.walletLogout} to="" className="designLink btn btn-primary">
                                        {isDapperConnected() ?
                                            <img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="" className="logoaw " /> :
                                            <img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="" className="logoaw " />}

                                        Disconnect Wallet
                                    </Link> : ''}
                                </li>

                                <li className="nav-item" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">  {(isWebLogin() && !isConnectWallet()) ? <Link className="nav-link" onClick={this.logout} to="">Log Out</Link> : <Link className="nav-link" onClick={this.handleLogout} to="">Log Out</Link>}</li>

                            </ul>}

                            {/* <ul className="navbar-nav ml-5 mb-2 mb-lg-0 mobmenuhide socialtopicons">                           
                            <li className="nav-item"><a className="nav-link" href="https://www.youtube.com/channel/UCldh5Tc_ICSTgVV0BuLwVxg" target="_blank"><i className="fab fa-youtube"></i></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://www.instagram.com/disrupt.art/" target="_blank"><i className="fab fa-instagram"></i></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://discord.io/disruptart" target="_blank"><i className="fab fa-discord"></i></a></li>
                            <li className="nav-item"><a className="nav-link" href="https://twitter.com/DisruptArt" target="_blank"><i className="fab fa-twitter"></i></a></li>
                        </ul> */}
                        </div>
                    </div>
                </nav >
                {/* {this.state.isAlert && <div className="alertcommon" style={{ zIndex: 1 }}>
                    <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />

                </div> } */}
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight alertcommon" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight alertcommon" : 'alertcommon'} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}


            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        usd: state.user.usd,
        detailCreator: state.creator.detailCreator,
        usersList: state.user.usersList,
        creatorRes: state.user.creatorRes,
        wallet: state.wallet.wallet,
        walleterror: state.wallet.walleterr,
        walletsuccess: state.wallet.walletsuc,
        tourOption: state.user.isTourEnabled,
        isDisconnectWallet: state.flow.bloctoDisconnected
    }
}
const actionCreators = {
    getUSD: userAction.getUSD,
    login: userAction.login,
    getDetailCreator: creatorActions.getDetailCreator,
    getUsers: userAction.getUsers,
    getUserProfile: userAction.getUsers,
    raiseRequest: userAction.raiseRequest,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetwalleterror: walletActions.resetWalletError,
    resetwalletsuccess: walletActions.resetWalleSuccess,
    walletSuccessSetup: walletActions.walletSuccess,
    walletnotsetup: walletActions.walletError,
    changeTourOption: userAction.enableDisableTourOption,
    callJoyRide: joyrideActions.getorUpdateJoyride,
    resetDisconnectWallet: flowActions.resetDisconnectBloctoWallet

}
const connectNavbar = connect(mapStateToProps, actionCreators)(Navbar)
export { connectNavbar as Navbar }
