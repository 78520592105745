import { dropConstants } from "../../constants"
import { adminServices, dropServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const dropActions = {
    uploadDrop,
    getDropArt,
    resetDropArt,
    getDetailDropArt,
    getUserDrop,
    getOneUserDrop,
    resetOneUserDrop,
    resetAllDrops,
    getAuctionDetail,
    getArtSaleList,
    getAdminDropList,
    resetGetAdminDropList,
    updatePriorityRank,
    resetUpdatePriorityRank,
    withdrawArt,
    resetWithdrawArtSuccess,
    resetWithdrawArtFailed
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}
function uploadDrop(obj){
    return dispatch => {
        dropServices.uploadDrop(obj)
        .then(drop => {
            if(drop){
                // toast.success(drop && drop.message)
                dispatch({type:dropConstants.UPLOAD_DROP,drop:true})
            }
        },error => {
            // toast.error(error)
            // console.log("error",error)
        })
    }
}
function getDropArt(obj){
    return dispatch => {
        // showLoader()
        dropServices.getDropArt(obj)
        .then(drop => {
            if(drop){
                // toast.success(drop && drop.message)
                dispatch({type:dropConstants.GET_DROP,dropList:drop,isCheckDrop:true})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
            // console.log("error",error)
        })
    }
}
function resetDropArt(){
    return dispatch => {
        dispatch({type:dropConstants.GET_DROP,dropList:[]})
    }
}

function getDetailDropArt(obj){
    return dispatch => {
        showLoader()
        dropServices.getDetailDropArt(obj)
        .then(drop => {
            if(drop){
                dispatch({type:dropConstants.DETAIL_DROP_ART,detailDrop : drop})
                hideLoader()
            }
        },error => {
            hideLoader()
            // console.log("detail error",error)
        })
    }
}

function getUserDrop(obj){
    return dispatch => {
        // showLoader()
        dropServices.getUserDrop(obj)
        .then(drop => {
            if(drop){
                dispatch({type:dropConstants.GET_USER_DROP,userDropList:drop,isCheckArtDrop : true})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
            // console.log("error",error)
        })
    }
}
function getOneUserDrop(obj,userId){
    return dispatch => {
        showLoader()
        dropServices.getOneUserDrop(obj,userId)
        .then(drop => {
            if(drop){
                dispatch({type:dropConstants.GET_ONE_USER_DROP,singleUserDrop:drop,isCheckUserDrop:true})
                hideLoader()
            }
        },error => {
            // toast.error(error)
            hideLoader()
            // console.log("error",error)
        })
    }
}
function resetOneUserDrop(){
    return dispatch => {
        dispatch({type:dropConstants.GET_ONE_USER_DROP,singleUserDrop:[]})
    }
}
function resetAllDrops(){
    return dispatch => {
        dispatch({type:dropConstants.GET_USER_DROP,userDropList:[],isCheckArtDrop : false})
    }
}
function getAuctionDetail(auctionId){
    return dispatch => {
        showLoader()
        dropServices.getAuctionDetail(auctionId)
        .then(drop => {
            if(drop){
                dispatch({type:dropConstants.GET_AUCTION_DETAIL,auctionList:drop})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}

function getArtSaleList(obj){
    return dispatch => {
        showLoader()
        adminServices.getArtSaleList(obj)
        .then(drop => {
            if(drop){
                dispatch({type:dropConstants.GET_ART_SALE_LIST,saleArtList:drop})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function getAdminDropList(obj){
    return dispatch => {
        showLoader()
        adminServices.getAdminDropList(obj)
        .then(drop => {
            if(drop){
                dispatch({type : dropConstants.GET_ADMIN_DROP_LIST, admindropList : drop})
                hideLoader()
            }
        },error => {
            hideLoader()
        })
    }
}
function resetGetAdminDropList(){
    return dispatch => {
        dispatch({type:dropConstants.GET_ADMIN_DROP_LIST, admindropList : []})
    }
}
function updatePriorityRank(dropId,obj){
    return dispatch => {
        showLoader()
        adminServices.updatePriorityRank(dropId,obj)
        .then(drop => {
                dispatch({type : dropConstants.UPDATE_PRIORITY_RANK, priorityDropUpdated : {statusCode : 200}})
                // hideLoader()
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetUpdatePriorityRank(){
    return dispatch => {
        dispatch({type:dropConstants.UPDATE_PRIORITY_RANK, priorityDropUpdated : {}})
    }
}
function withdrawArt(obj,dropId){
    return dispatch => {
        showLoader()
        dropServices.withdrawArt(obj,dropId)
        .then(drop => {
            dispatch({type : dropConstants.WITHDRAW_ART, withdrawnArt : drop})
            hideLoader()
        },error => {
            dispatch({type : dropConstants.WITHDRAW_ART_FAILED, withdrawArtFailed : error})
            hideLoader()
        })
    }
}
function resetWithdrawArtSuccess(){
    return dispatch => {
        dispatch({type : dropConstants.WITHDRAW_ART,withdrawnArt:[]})
    }
}
function resetWithdrawArtFailed(){
    return dispatch => {
        dispatch({type : dropConstants.WITHDRAW_ART_FAILED,withdrawArtFailed : ''})
    }
}