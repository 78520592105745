import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { tagServices } from 'services';
import { artActions } from 'store/actions';
import { tags } from 'store/reducers/tag.reducer';
import { debounce } from 'utils';
var dataURL = {}

class EditTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            tag: '',
            tags: [],
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            isTagLoading: false,
            detailData : {}
        }
        this.debouncedSearchTags = debounce(this.searchTags.bind(this), 300);

    }
    componentDidMount() {
    }



    componentDidUpdate(props, state) {
        if ((props && props.data)) {
            if ((props.data) != (state.data)) {
                let array = []
                if (props.data && props.data.tags && props.data.tags.length > 0) {
                    props.data.tags.map((items) => {
                        array.push(items.name)
                    })
                }

                this.setState({ data: props.data, tags: array })

            }
        }
        if ((props && props.detailData)) {
            if ((props.detailData) != (state.detailData)) {
                this.setState({ detailData: props.detailData })
            }
        }
        if (((this.props && this.props.edittagsuccess && this.props.edittagsuccess.statusCode) === 200) && (this.props.edittagsuccess) != (props.edittagsuccess)) {
            this.props.resetUpdateTags()

            this.props.updateTagSuccess()
        } else if (((this.props && this.props.edittagsuccess && this.props.edittagsuccess.statusCode) === 400) && (this.props.edittagsuccess) != (props.edittagsuccess)) {
            this.props.resetUpdateTags()
            this.props.updateError(this.props.edittagsuccess && this.props.edittagsuccess.message)
        }
        if (((this.props && this.props.edittagfailed && this.props.edittagfailed.length > 0) && (this.props.edittagfailed) != (props.edittagfailed))) {
            this.props.resetUpdateTagsFailed()
            this.props.updateError(this.props.edittagfailed)

        }
    }
    editTags = (e, data) => {
        e.preventDefault()
        // if (this.state.tags && this.state.tags.length > 0) {
            if (data &&  data.id) {
                if (this.state.tag && this.checkCharExist(this.state.tag)) {
                    this.props.updateError('Please select tag from suggestion or add comma to create new tag')
                } else {
                    let obj = {}
                    if(this.state.tags && this.state.tags.length > 0){
                        obj['tagNames'] = this.state.tags

                    }else{
                        obj['tagNames'] = null
                    }
                    if(this.state.detailData && this.state.detailData.art && this.state.detailData.art.id){
                        this.props.updateTags((this.state.detailData.art.id), JSON.stringify(obj))

                    }else if(data.id){
                        this.props.updateTags((data.id), JSON.stringify(obj))

                    }else{
                        this.props.updateError("Something went wrong,art id was missing")
                    }
                }
            } else {
                this.props.updateError("Something went wrong,art id was missing")
            }
        // } else {
        //     this.props.updateError("Please add atleast one tag")
        // }
    }
    checkCharacType = (value) => {
        const char = /[^a-zA-Z0-9 ]/
        if ((value && char.test(value))) {
            return false
        } else {
            return true
        }
    }
    handleTagsChange = (e) => {
        e.persist();
        const { tags } = this.state
        if (this.checkCharacType(e.target.value)) {
            const tag = e.currentTarget.value

            this.debouncedSearchTags(tag)
            this.setState({
                tag,
                showSuggestions: true,
                isTagLoading: false
            })

        }
    }
    searchTags(searchTerm) {
        const { isFeatureArt } = this.props
        if (searchTerm) {
            if (this.checkCharExist(searchTerm)) {
                let obj = {
                    search: searchTerm
                }
                tagServices.getTagsList(obj).then((response) => {

                    this.setState({
                        isSearching: false,
                        isTagLoading: false
                    })
                    if (response && response.statusCode == 200) {
                        if ((response.data && response.data.tags && Array.isArray(response.data.tags))) {
                            const filteredSuggestions = []
                            for (const tags of response.data.tags) {
                                filteredSuggestions.push({
                                    id: tags.id,
                                    mainText: tags.name,
                                    subText: tags.status
                                })
                            }
                            this.setState({
                                activeSuggestion: 0,
                                filteredSuggestions,
                                showSuggestions: true,
                            });
                        }
                    } else {
                        this.props.updateError("Unexpected error occured on tag search")
                    }

                }).catch(e => {
                    this.props.updateError(e)
                    // this.gotoTop()
                    this.setState({
                        isSearching: false,
                        isTagLoading: false
                    })

                })
            }
        }
    }
    onKeyDown = (e) => {
        const { tags, tag, tagsList } = this.state
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (e.keyCode == 188) {
            let tag = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');

            if (tag.length > 1 && !tags.includes(tag.trim().toLowerCase())) {
                if ((this.checkMax(tag)) && (this.checkMin(tag))) {
                    tags.push(tag.trim().toLowerCase());
                    this.setState({
                        tags,
                        tag: '',
                        activeSuggestion: 0,
                        filteredSuggestions: [],
                        showSuggestions: false
                    }, () => {
                    })


                } else {
                    this.props.updateError("Tag must be at least 3 characters min and 50 characters max")
                }

            } else {
                this.props.updateError("You have already entered the tag")
            }

        }
        if (e.keyCode === 13) {
            e.preventDefault();

            if ((filteredSuggestions && (filteredSuggestions.length > 0))) {
                const searchResult = filteredSuggestions[activeSuggestion]
                this.setState({
                    activeSuggestion: 0,
                    showSuggestions: false,
                    tag: ''
                });

                this.handleTagSelect(searchResult);
            } else {
                const { tag } = this.state
                if (tag && (this.checkCharExist(tag))) {
                    this.props.updateError("No suggestions available,add space to create new tag")
                }
            }
        } else if (e.keyCode === 38) {

            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {


            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }

    }
    clickSelectedTag = (selectedSuggestion, e) => {

        this.handleTagSelect(selectedSuggestion)
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            tag: ''
        });
    }
    checkMin = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkMax = (value) => {
        if ((value && value.length) <= 50) {
            return true
        } else {
            return false
        }
    }
    handleTagSelect = (selectedSuggestion) => {
        const { tags, tagsList } = this.state
        const { activeSuggestion, filteredSuggestions } = this.state;
        if (selectedSuggestion && selectedSuggestion.mainText) {
            let tag = selectedSuggestion.mainText.replace(/[^a-zA-Z0-9 ]/g, '');

            if (tag.length > 1 && !tags.includes(tag)) {
                if ((this.checkMax(tag)) && (this.checkMin(tag))) {
                    document.getElementById('exist-values').focus();
                    tags.push(tag);

                }

            } else {
                this.props.updateError("You have already entered the tag")
            }

        } else {
            this.props.updateError("Please select proper tag")

        }
    }
    removeItem = (value, index) => {
        const { tags } = this.state
        let tagArray = []
        tagArray = tags.filter((tag, idx) => idx != index)
        this.setState({
            tags: tagArray
        }, () => {
        })

    }
    checkCharExist = (value) => {
        var charRegex = /^[ -~]*$/
        if (charRegex.test(value)) {

            return true
        } else {
            return false
        }
    }
    render() {
        const { data } = this.state
        const { isTooLength, tags } = this.state

        const {
            clickSelectedTag,
            state: {
                activeSuggestion,
                filteredSuggestions: sugesstionList,
                showSuggestions,
                tag,
                isTagLoading
            }
        } = this;

        let suggestionsListComponent;
        if (showSuggestions && tag && this.checkCharExist(tag)) {
            if (!isTagLoading) {
                if (sugesstionList.length) {
                    suggestionsListComponent = (
                        <ul className="suggestions maxh110">

                            {sugesstionList.map((result, index) => {

                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }


                                return (
                                    <li
                                        className={className}
                                        // key={result.artId}
                                        onClick={(e) => clickSelectedTag(result, e)}
                                    >
                                        <span>{result.mainText}</span><br />
                                        {/* <small>{result.subText}</small><br/> */}
                                        <small>{result.artDet}</small>

                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else {
                    suggestionsListComponent = (
                        <div className="no-suggestions">
                            <em>No suggestions available.</em>
                        </div>
                    );
                }
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <i className="fas fa-spin fa-circle-notch fa-lg"></i>
                    </div>
                );
            }
        }
        return (
            <Fragment>

                <div className="form-group col-md-12 adconfg">
                    <label className="col-form-label  mb-0" htmlFor="description">Tags</label>

                    {<div className="mandatory-req mb-2 mt-0">Type comma,at the end of a word to create a tag</div>}

                    <div className="input-group mb-2 inputtags">


                        {tags && tags.map((items, index) => (
                            <a className="badge badge-primary mr-1 p-2 mb-2 font-weight-normal">
                                {items}&nbsp; <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeItem(items, index)}></i></a>)
                        )}

                        <textarea
                            className="form-control w-50 tagged"
                            placeholder="Type here..."
                            id="exist-values"
                            name="tag"
                            data-removeBtn="true"
                            value={this.state.tag}
                            onChange={this.handleTagsChange}
                            onKeyDown={this.onKeyDown}
                        >
                        </textarea>


                        {suggestionsListComponent}
                    </div>

                    {<div className="mandatory">{"Minimum 3 to maximum 50 characters, Special characters are not allowed"}</div>}


                </div>
                {/* <hr className="my-3 d-block" /> */}
                {(data && data.isBab) ? '' : <div className="row">
                    <div className="col-md-12 text-center">
                        <button type="button" className="btn btn-primary px-3" onClick={(e) => this.editTags(e, data)} disabled={this.state.disableList}>Update Tags</button>
                    </div>
                </div>}


            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        edittagsuccess: state.art.editedTags,
        edittagfailed: state.art.editedTagsFailed
    }
}
const actionCreators = {
    updateTags: artActions.editArtTag,
    resetUpdateTags: artActions.resetArtEditTags,
    resetUpdateTagsFailed: artActions.resetArtEditTagsFailed

}
const connectEditTag = connect(mapStateToProps, actionCreators)(EditTag)
export { connectEditTag as EditTag }
