import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { adminActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import Toggle from 'react-toggle';
import { ReactPagination } from 'helpers';
import { toast } from 'react-toastify';
import moment from 'moment';

class FetchReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileData: [],
            imageName: '',
            totaluser: '',
            existsValue: '',
            insertuser: '',
            emptyValue: [],
            uploadRes: [],
            accounts: [],
            totalCount: '',
            offSet: 0,
            sortBy: "reportTime",
            sortOrder: "DESC",
            recordLimit: 20,
            pageCount: '',
            isShow: false,
            id: '',
            selectedFilter : 1
        }
    }
    componentDidMount() {
        this.callApi()
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.reportList && props.reportList.statusCode === 200) {
            props.resetGetAllReports()
            // if(props.allUsers)
            return {
                accounts: props.reportList && props.reportList.data && props.reportList.data.reports ? props.reportList.data.reports : [],
                totalCount: props.reportList && props.reportList.data && props.reportList.data.count,
                pageCount: props.reportList && props.reportList.data && props.reportList.data.reports && props.reportList.data.count / state.recordLimit,
                isFetched: true,
            }
        }
        return null



    }
    callApi = () => {
        const { search, role, recordLimit, offSet, selectedFilter } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }

        if (role) {
            obj['roleId'] = role
            // obj['offSet'] = 0

        }
        if (search) {
            obj['search'] = search
            // obj['offSet'] = 0


        }
        if(selectedFilter && Number(selectedFilter)){
            obj['reportStatus'] = selectedFilter
        }
        this.props.getallReports(obj)
    }
    editAccount = (items) => {
        if ((items && items.publicId)) {
            history.push(`/admin/edit/${items && items.publicId}/account`, { obj: { items } })
        }
    }
    deleteAccount = (items) => {
        const { accounts } = this.state
        const filterArray = accounts && accounts.filter(el => (el && el.id) != (items && items.id))
        this.setState({ accounts: filterArray })
    }
    statusChange = (items) => {
        if (items && items.publicId) {
            if (items.accountStatus) {
                this.props.disableAccount(items.publicId)
            } else {
                this.props.enableAccount(items.publicId)
            }
        } else {
            toast.error("Unable to update, public id was missing")
        }

    }
    emailStatusChange = (items) => { }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    resetPassword = (items) => {
        if ((items && items.email)) {
            this.props.resetPassword(items.email)
        }
    }
    componentDidUpdate(props, state) {

        if (this.props &&
            this.props.reviewAbuseStatus &&
            this.props.reviewAbuseStatus.statusCode === 200 &&
            (this.props.reviewAbuseStatus !== props.reviewAbuseStatus)) {
            this.props.resetUpdateReport()
            toast.success("Report Updated")
            this.callApi()
        }
    }
    handleReview = (e, items) => {
        console.log(e.target.value, items)
        if (items && items.id && e.target.value) {
            let obj = {
                reportIds: [items.id],
                status: Number(e.target.value)
            }
            this.props.updateReport(JSON.stringify(obj))
        }
    }
    handleView = (items) => {
        if (this.state.id == (items && items.id) && this.state.isShow) {
            this.setState({ isShow: false, })
        } else {
            this.setState({ isShow: true, id: (items && items.id), remarks: '' })
        }
    }
    handleFilterBy = (e) => {
            this.setState({ selectedFilter: e.target.value, offSet: 0 }, () => {
                this.callApi()
            })
       
    }
    render() {
        const { accounts } = this.state
        const AccountList = accounts && accounts.map((items) => {
            return <tbody>
                <tr>
                    <td><a >{(items && items.reporter && items.reporter.userName) ? (`@${items.reporter.userName}`) : ``}</a> </td>
                    <td><a href={items && items.url} target="_blank" className='usernamelilac'>{items && items.url}</a></td>
                    <td><a className="userdescrip_adm" style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.reporterRemarks} onClick={() => this.handleView(items)}>{items && items.reporterRemarks}</a></td>
                    <td>
                        <select value={items && items.status} onChange={(e) => this.handleReview(e, items)} className="form-control">
                            <option value={1}>Open</option>
                            <option value={2}>Warned Creator</option>
                            <option value={3}>Removed</option>
                            <option value={4}>No Action</option>

                        </select>
                    </td>
                    <td>{moment(items && items.reportTime).format(' DD-MM-YYYY hh:mm:ss A')}</td>
                </tr>
                {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                    <td colSpan={5} className="px-3 graybgbox">
                        <h4 className="mb-0">Detail View</h4>
                        <hr />
                        <div className="row">
                          
                            
                            <div className="col-md-12 mb-3">
                                <label>Reason </label>
                                <div className="comboxgray">
                                    {(items && items.reporterRemarks) ? (items.reporterRemarks) : '-'}
                                </div>
                            </div>




                           

                            <div className="col-sm-12">
                                <hr />
                            </div>

                          

                        </div>




                    </td>
                </tr>}

            </tbody>
        })
        return (
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Reports <span className="betaLink">(Soon)</span></h2>  </div>
                        <div className="col-sm-4 offset-md-2 text-right">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fas fa-sort"></i></div>
                                            </div>

                                            <select value={this.state.selectedFilter} onChange={(e) => this.handleFilterBy(e)} className="form-control">
                                                {/* <option value={0}>Select status</option> */}
                                                <option value={1}>Open</option>
                                                <option value={2}>Warned Creator</option>
                                                <option value={3}>Removed</option>
                                                <option value={4}>No Action</option>

                                            </select>
                                        </div>
                                    </div>
                    </div>
                    <div className="purchasebox p-2"><div className="card"><div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>REPORTER</th>
                                        <th>REPORTED LINK</th>
                                        <th>REASON</th>
                                        <th>ACTION</th>
                                        <th>REPORT TIME</th>
                                    </tr>
                                </thead>
                                {AccountList}

                            </table>
                            {this.state.accounts && this.state.accounts.length === 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>

                    </div>

                    </div>
                    {this.state.accounts && this.state.accounts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}


                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        reportList: state.adminaccount.abusereportList,
        reviewAbuseStatus: state.adminaccount.reviewAbuseStatus
    }
}
const actionCreator = {
    getallReports: adminActions.getAbuseReport,
    resetGetAllReports: adminActions.resetGetAbuseReport,
    updateReport: adminActions.updateAbuseReport,
    resetUpdateReport: adminActions.resetUpdateAbuseReport
}
const connectFetchReports = connect(mapStateToProps, actionCreator)(FetchReports)
export { connectFetchReports as FetchReports }