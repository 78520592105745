import React from "react";
import { Navbar } from "../Navbar";
import { NavbarLanding } from "../Navbar-landing";
import { Footer } from '../footer'
import { Alert } from "helpers/alert";
import { GlobalSearch } from "../global-search/global-search";
import { NavLink } from "react-router-dom";
import { announcementActions } from "store/actions";
import { connect } from "react-redux";
import { GlitterText } from "components/glitterText";
 class AppLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }
    componentDidMount(){
        this.getMessage()
    }
    getMessage(){
        this.props.getAnnouncementMes()
    }
    
    handlePage = () => {
        localStorage.setItem("page","0")
    }

    render() {
        const location = window.location.pathname
        const {getmessage} = this.props
        return (
            <div>
               
               {/* {location && location !== '/' && <Navbar appPath="" />} */}

               {(location && location === (process.env.REACT_APP_HOME_PAGE)) ? <NavbarLanding appPath="" /> : <Navbar appPath="" />}

                {/* {location && !location.includes('drops') && <div className="discovernav">
                <nav className="navbar navbar-expand-lg navbar-light disnowmenu sticky-sm-top border-dark">
                   <div className="container">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2">
                            <li className="nav-item">
                                <NavLink className="nav-link " to={`/discover`}>Spotlight</NavLink></li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="uploadArt" onClick={this.handleSpotlight}>Spotlight</a>
                                </li> */}
                                {/* <li className="nav-item">
                                <NavLink className="nav-link " to={`/arts`} onClick={this.handlePage}>Art</NavLink></li> */}

                                    {/* <a className="nav-link" id="uploadArt" onClick={this.handleArts}>Arts</a> */}
                                {/* </li> */}

                                {/* <li className="nav-item">
                                <NavLink className="nav-link " to={`/creators`} onClick={this.handlePage}>Creators</NavLink></li>
                                <li className="nav-item">
                                <NavLink className="nav-link " to={`/collectors`} onClick={this.handlePage}>Collectors</NavLink></li> */}
                                    {/* <a className="nav-link" id="uploadArt" onClick={this.handleCreator}>Creators</a> */}
                                {/* </li> */}
                            {/* </ul>

                        </div>
                        </div>
                    
                </nav>
                </div>}          */}
                {/* {<div className="alert alert-warning text-center p-1 mb-0 marketplacetxt">Marketplace is under development mode. Running on Flow TESTNET blockchain. Do not send real money! <a href="https://testnet-faucet-v2.onflow.org/fund-account" target="blank" style={{color:"#007bff"}}>Fund your account </a> with "TEST Flow" crypto here. <a href="https://swap-testnet.blocto.app/#/swap" target="blank" style={{color:"#007bff"}}>{"FLOW <=> FUSD"}</a></div>} */}
                {(location && location === (process.env.REACT_APP_HOME_PAGE)) ? '' : (getmessage && getmessage.data && getmessage.data.showBanner && <div className={(getmessage && getmessage.data && getmessage.data.bannerType === "warning") ? "alert alert-warning text-center p-1 mb-0 marketplacetxt" : "alert alert-danger text-center p-1 mb-0 marketplacetxt"}>{getmessage && getmessage.data && getmessage.data.bannerMessage }</div>)}

                {/* {location && !location.includes('landing') &&  getmessage && getmessage.data && getmessage.data.showBanner && <div className={(getmessage && getmessage.data && getmessage.data.bannerType === "warning") ? "alert alert-warning text-center p-1 mb-0 marketplacetxt" : "alert alert-danger text-center p-1 mb-0 marketplacetxt"}>{getmessage && getmessage.data && getmessage.data.bannerMessage }</div>} */}
                {(location && location === (process.env.REACT_APP_HOME_PAGE)) ? '' : 
                <div className="mobilesearch">
                {/* <a href="https://www.eventbrite.com/e/block-party-disrupt-art-tickets-296756716327?ref=estw" target={"_blank"} className="rainbow rainbow_text_animated">RSVP for SXSW Block Party</a> */}

                {/* <a href={`${process.env.REACT_APP_DISRUPT_BAB}`} target="_blank"  className="rainbow rainbow_text_animated">Badazz Bears NFT backed by Jim Jones</a> */}
                    {/* <GlitterText addclass=""/> */}
                    <GlobalSearch /></div>
                }

               
                {this.props.children}
                <Footer/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        getmessage : state.announcement.c_message,
    }
}
const actionCreator = {
    getAnnouncementMes : announcementActions.getAnnouncementMessage,
    resetGetAnnouncementMessage : announcementActions.resetGetAnnouncementMessage
    
}
const connectLayout = connect(mapStateToProps,actionCreator)(AppLayout)
export {connectLayout as AppLayout}
