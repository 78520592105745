import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { defaultFclLimit } from "./fcl-limit";



/**
 * Cancels an auction.
 * @param {number} auctionId The Id of the auction to be cancelled.
 * @returns A promise containing the transaction
 */
export async function cancelFlowAuction(auctionId) {
    const cancelAuctionScript = `
        import ${process.env.REACT_APP_AUCTION_NAME} from ${process.env.REACT_APP_AUCTION_ADDRESS}

        transaction(auctionId: UInt64) {
            // reference to the buyer's NFT collection where they
            // will store the bought NFT

            let vaultCap: &${process.env.REACT_APP_AUCTION_NAME}.AuctionCollection 

            prepare(account: AuthAccount) {

                self.vaultCap = account.borrow<&${process.env.REACT_APP_AUCTION_NAME}.AuctionCollection>(from: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath)
                    ?? panic("Could not borrow owner's auction collection")
            }

            execute {       
                self.vaultCap.cancelAuction(auctionId)
            }
        }
    `;
    try {
        if (!Number.isInteger(auctionId)) {
            throw new Error('Expected the parameter \'auctionId\' to be an integer');
        }

        const blockResponse = await fcl.send([
            fcl.getBlock()
        ])
        const { transactionId } = await fcl.send([
            fcl.transaction(cancelAuctionScript),
            fcl.args([
                fcl.arg(auctionId, t.UInt64)
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ]);
        const result = new Promise((resolve) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        resolve({
                            status: 200,
                            data: {
                                "message": "Auction cancelled",
                                "Value": String(transactionId),
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    } catch (e) {
        return {
            status: 400,
            data: {
                "message": "Exception occured when cancelling auction",
                "Error": String(e)
            }
        };
    }
}

/**
 * Lists a art for auction.
 * @param {number} tokenId The id of the art to be listed for auction.
 * @param {number} auctionEndTimestamp The timestamp representing the end of the auction.
 * @param {number} startingPrice The auction's starting price.
 * @param {number} minimumBidIncrement The minimum increment for each bid.
 * @returns A promis containing the transaction.
 */
export async function listArtForFlowAuction(tokenId, auctionEndTimestamp, startingPrice, minimumBidIncrement) {
    const listForAuctionScript = `
        import ${process.env.REACT_APP_AUCTION_NAME} from ${process.env.REACT_APP_AUCTION_ADDRESS}
        import FungibleToken from ${process.env.REACT_APP_FUSD_FUNGIBLE_CONTRACT}
        import NonFungibleToken from ${process.env.REACT_APP_NON_FUNGIBLE_TOKEN_ADDRESS}
        import FlowToken from ${process.env.REACT_APP_FLOW_TOKEN}
        import ${process.env.REACT_APP_CONTRACT} from ${process.env.REACT_APP_CONTRACT_ADDRESS}
        
        
        transaction(tokenId: UInt64, auctionEndTimestamp: Fix64, startingPrice: UFix64, minimumBidIncrement: UFix64) {
        
            prepare(account: AuthAccount) {
        
                if account.borrow <&${process.env.REACT_APP_AUCTION_NAME}.AuctionCollection > (from: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath) == nil {
                    // create a new sale object     
                    // initializing it with the reference to the owner's Vault
                    let auction <- ${process.env.REACT_APP_AUCTION_NAME}.createAuctionCollection()
        
                    // store the sale resource in the account for storage
                    account.save(<-auction, to: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath)
        
                    // create a public capability to the sale so that others
                    // can call it's methods
                    account.link <&{${process.env.REACT_APP_AUCTION_NAME}.AuctionPublic} > (
                        ${process.env.REACT_APP_AUCTION_NAME}.auctionPublicPath,
                        target: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath
                )
        
                    log("Auction Collection and public capability created.")
        
                }
        
                let accountCollectionRef = account.borrow<&NonFungibleToken.Collection>(from: ${process.env.REACT_APP_CONTRACT}.disruptArtStoragePath)!
        
                // get the public Capability for the signer's NFT collection (for the auction)
                let publicCollectionCap = account.getCapability<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(${process.env.REACT_APP_CONTRACT}.disruptArtPublicPath)
        
                let vaultCap = account.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)
        
                // borrow a reference to the Auction Collection in account storage
                let auctionCollectionRef = account.borrow<&${process.env.REACT_APP_AUCTION_NAME}.AuctionCollection>(from: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath)!
        
                // Create an empty bid Vault for the auction
                let bidVault <- FlowToken.createEmptyVault()
        
                // withdraw the NFT from the collection that you want to sell
                // and move it into the transaction's context
                let NFT <- accountCollectionRef.withdraw(withdrawID: tokenId)

                // list the token for sale by moving it into the sale resource
                auctionCollectionRef.addTokenToAuctionItems(
                    token: <-NFT,
                    minimumBidIncrement:  minimumBidIncrement,
                    startPrice: startingPrice,
                    bidVault: <-bidVault,
                    collectionCap: publicCollectionCap,
                    vaultCap: vaultCap,
                    endTime : auctionEndTimestamp
                )
            }
        }
    `;
    // console.log(listForAuctionScript)
    try {
        // console.log(listForAuctionScript)
        if (!Number.isInteger(tokenId)) {
            throw new Error('Expected the parameter \'tokenId\' to be an integer');
        }
        if (!Number.isFinite(minimumBidIncrement)) {
            throw new Error('Expected the parameter \'minimumBidIncrement\' to be a number');
        }
        if (!Number.isFinite(startingPrice)) {
            throw new Error('Expected the parameter \'startingPrice\' to be a number');
        }

        const blockResponse = await fcl.send([
            fcl.getBlock()
        ])

        console.log(blockResponse);
        const { transactionId } = await fcl.send([
            fcl.transaction(listForAuctionScript),
            fcl.args([
                fcl.arg(tokenId,t.UInt64),
                fcl.arg(auctionEndTimestamp.toFixed(1),t.Fix64),
                fcl.arg(startingPrice.toFixed(8),t.UFix64),
                fcl.arg(minimumBidIncrement.toFixed(8), t.UFix64)
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(defaultFclLimit),
        ]);
        const result = new Promise((resolve) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        resolve({
                            status: 200,
                            data: {
                                "message": "Token listed for auction",
                                "Value": String(transactionId),
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    } catch (e) {
        return {
            status: 400,
            data: {
                "message": "Exception occured when listing token for auction",
                "Error": String(e)
            }
        };
    }
}

/**
 * Settle an auction.
 * @param {number} auctionId The id of the auction to be settled.
 * @param {string} walletAddress The wallet address to which the auction is settled.
 * @returns A promise containting the transaction.
 */
export async function settleFlowAuction(auctionId, walletAddress) {
    const settleAuctionScript = `
        import ${process.env.REACT_APP_AUCTION_NAME} from ${process.env.REACT_APP_AUCTION_ADDRESS}


        transaction(auctionId: UInt64, walletAddress: Address) {
            // reference to the buyer's NFT collection where they
            // will store the bought NFT
        
            prepare(account: AuthAccount) {
        
                let seller = getAccount(walletAddress)
        
                // get the reference to the seller's sale
                let auctionRef = seller.getCapability(${process.env.REACT_APP_AUCTION_NAME}.auctionPublicPath)!
                    .borrow <&AnyResource{${process.env.REACT_APP_AUCTION_NAME}.AuctionPublic}>()
                        ?? panic("Could not borrow seller's sale reference")
        
                auctionRef.settleAuction(auctionId)
            }
        }
    `;
    // console.log(settleAuctionScript)
    try {
        if (!Number.isInteger(auctionId)) {
            throw new Error('Expected the parameter \'autionId\' to be an integer');
        }
        if (typeof walletAddress !== 'string') {
            throw new Error('Expected the parameter \'walletAddress\' to be a string');
        }

        const blockResponse = await fcl.send([
            fcl.getBlock()
        ])
        const { transactionId } = await fcl.send([
            fcl.transaction(settleAuctionScript),
            fcl.args([
                fcl.arg(auctionId, t.UInt64),
                fcl.arg(walletAddress, t.Address)
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ]);
        const result = new Promise((resolve) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        resolve({
                            status: 200,
                            data: {
                                "message": "Auction Settled",
                                "Value": String(transactionId),
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    } catch (e) {
        return {
            status: 400,
            data: {
                "message": "Exception occured when settling auction",
                "Error": String(e)
            }
        };
    }
}

/**
 * Place a bid on an auction.
 * @param {number} auctionId The id of the auction where the bid is to be placed.
 * @param {string} walletAddress The address of the wallet that is placing the bid.
 * @param {number} bidAmount The amount to be placed as a bid.
 * @returns A promise containing the transaction.
 */
export async function placeBidOnFlowAuction(auctionId, walletAddress, bidAmount) {
    const placeBidOnAuctionScript = `
        import ${process.env.REACT_APP_AUCTION_NAME} from ${process.env.REACT_APP_AUCTION_ADDRESS}
        import FungibleToken from ${process.env.REACT_APP_FUSD_FUNGIBLE_CONTRACT}
        import ${process.env.REACT_APP_CONTRACT} from ${process.env.REACT_APP_CONTRACT_ADDRESS}
        
        
        
        transaction(auctionId: UInt64, walletAddress: Address, bidAmount: UFix64) {
            // reference to the buyer's NFT collection where they
            // will store the bought NFT
        
            let vaultCap: Capability<&{FungibleToken.Receiver}>
            let collectionCap: Capability<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>
                // Vault that will hold the tokens that will be used
                // to buy the NFT
                let temporaryVault: @FungibleToken.Vault
        
            prepare(account: AuthAccount) {
            
                // Return early if the account already has a collection
                if account.borrow<&${process.env.REACT_APP_CONTRACT}.Collection>(from: ${process.env.REACT_APP_CONTRACT}.disruptArtStoragePath) == nil {
        
                    // Create a new empty collection
                    let collection <- ${process.env.REACT_APP_CONTRACT}.createEmptyCollection()
            
                    // save it to the account
                    account.save(<-collection, to: ${process.env.REACT_APP_CONTRACT}.disruptArtStoragePath)
            
                    // create a public capability for the collection
                    account.link<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}> (
                        ${process.env.REACT_APP_CONTRACT}.disruptArtPublicPath,
                        target: ${process.env.REACT_APP_CONTRACT}.disruptArtStoragePath
                        )
                }
            
                if account.borrow<&${process.env.REACT_APP_AUCTION_NAME}.AuctionCollection>(from: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath) == nil {
                    // create a new sale object
                    // initializing it with the reference to the owner's Vault
                    let auction <- ${process.env.REACT_APP_AUCTION_NAME}.createAuctionCollection()
            
                    // store the sale resource in the account for storage
                    account.save(<-auction, to: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath)
            
                    // create a public capability to the sale so that others
                    // can call it's methods
                    account.link<&{${process.env.REACT_APP_AUCTION_NAME}.AuctionPublic}>(
                        ${process.env.REACT_APP_AUCTION_NAME}.auctionPublicPath,
                        target: ${process.env.REACT_APP_AUCTION_NAME}.auctionStoragePath
                    )
            
                    log("Auction Collection and public capability created.")
            
                }
            
                // get the references to the buyer's Vault and NFT Collection receiver
                self.collectionCap = account.getCapability<&{${process.env.REACT_APP_CONTRACT}.DisruptArtCollectionPublic}>(${process.env.REACT_APP_CONTRACT}.disruptArtPublicPath)
            
                self.vaultCap = account.getCapability<&{FungibleToken.Receiver}>(/public/flowTokenReceiver)
            
                let vaultRef = account.borrow<&FungibleToken.Vault>(from: /storage/flowTokenVault)
                        ?? panic("Could not borrow owner's Vault reference")
            
                // withdraw tokens from the buyer's Vault
                self.temporaryVault <- vaultRef.withdraw(amount: bidAmount)
            }
        
            execute {
                // get the read-only account storage of the seller
                let seller = getAccount(walletAddress)
            
                // get the reference to the seller's sale
                let auctionRef = seller.getCapability(${process.env.REACT_APP_AUCTION_NAME}.auctionPublicPath)!
                    .borrow<&AnyResource{${process.env.REACT_APP_AUCTION_NAME}.AuctionPublic}> ()
                        ?? panic("Could not borrow seller's sale reference")
            
                auctionRef.placeBid(id: auctionId, bidTokens: <- self.temporaryVault, vaultCap: self.vaultCap, collectionCap: self.collectionCap)
            
            }
        }
    `;
    // console.log(placeBidOnAuctionScript)
    try {
        if (!Number.isInteger(auctionId)) {
            throw new Error('Expected the parameter \'autionId\' to be an integer');
        }
        if (typeof walletAddress !== 'string') {
            throw new Error('Expected the parameter \'walletAddress\' to be a string');
        }
        if (!Number.isFinite(bidAmount)) {
            throw new Error('Expected the parameter \'bidAmount\' to be a number');
        }

        const blockResponse = await fcl.send([
            fcl.getBlock()
        ])
        const { transactionId } = await fcl.send([
            fcl.transaction(placeBidOnAuctionScript),
            fcl.args([
                fcl.arg(auctionId, t.UInt64),
                fcl.arg(walletAddress, t.Address),
                fcl.arg(bidAmount.toFixed(8), t.UFix64)
            ]),
            fcl.proposer(fcl.currentUser().authorization),
            fcl.authorizations([
                fcl.currentUser().authorization
            ]),
            fcl.payer(fcl.currentUser().authorization),
            fcl.ref(blockResponse["block"].id),
            fcl.limit(9999),
        ]);
        const result = new Promise((resolve) => {
            fcl.tx(transactionId)
                .subscribe((transaction) => {
                    if (fcl.tx.isSealed(transaction)) {
                        resolve({
                            status: 200,
                            data: {
                                "message": "Bid placed",
                                "Value": String(transactionId),
                                "transaction": transaction
                            }
                        });
                    }
                });
        });
        return result;
    } catch (e) {
        return {
            status: 400,
            data: {
                "message": "Exception occured when placing bid",
                "Error": String(e)
            }
        };
    }
}
