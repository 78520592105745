import { AudioPreivew } from "components/audio-player/audio-preview";
import { MusicTheme } from "components/musictheme";
import { VideoPreview } from "components/video-player/video-preview";
import React from "react";
import { fetchService } from "services";
import { configPath } from "../../config";

export class ArtPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectURL: '',
            mimeType: '',
            itCause: false,
            isCheck: false
        };
    }



    componentWillUnmount() {

        // revoke the created objectURL to free up memory
        if (this.state.objectURL && this.state.objectURL !== '') {
            URL.revokeObjectURL(this.state.objectURL);
        }
    }
    componentDidMount() {
        if ((this.props && this.props.src)) {
            this.fetchArt(this.props.src, this.props.mimeType)
        }

    }

    componentDidUpdate() {
        // Update the component view when new props has updated the state
        if (
            this.state.objectURL !== null &&
            this.state.objectURL === '' &&
            this.props.src !== undefined
        ) {
            // this.fetchArt(this.props.src)
            this.setState({ objectURL: null, isCheck: true }, () => {
                this.fetchArt(this.props.src, this.props.mimeType)
            })
        }
    }

    static getDerivedStateFromProps(props, state) {

        if ((props.src !== state.prevSrc)) {
            return {
                prevSrc: props.src,
                objectURL: '',
                mimeType: '',


            }
        }
        return null;
    }

    render() {
        let scrnWidth = (document.getElementById("articonstatic") && document.getElementById("articonstatic").offsetWidth) ? document.getElementById("articonstatic").offsetWidth : "100%"
        if (this.props && this.props.src === null) {
            return (<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}>
                {(this.props && this.props.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium">
                    <img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
                <img src={`../${configPath.imageAssets}/no-img.jpg`} alt={this.props.alt} className={this.props.className} width={this.props.width} style={this.props.style} /></span>)

        }
        else if (this.state.objectURL === null) {
            if ((this.props && this.props.isDetail)) {
                return (<img src={`../${configPath.imageAssets}/cover-detail-loader.gif`} className="threedotsloader" alt="" />)

            }
            return (<span className="articonstatic hexagon" id="articonstatic" style={{ height: scrnWidth }}><img src={`../${configPath.imageAssets}/ajax-loader.gif`} className="hexagonloader" width={this.props.width} style={this.props.style} alt="" /></span>)
        }
        else if (this.state.objectURL !== '') {

            if (this.state.mimeType.match(/image.*/)) {
                return (<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}>
                    {(this.props && this.props.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium">
                        <img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
                    <img src={this.state.objectURL} alt={this.props.alt} className={this.props.className} width={this.props.width} style={this.props.style} /></span>)

            } else if (this.state.mimeType.match(/video.*/)) {
                if ((this.props && this.props.isAdmin)) {
                    return (<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}><img src={`/${configPath.imageAssets}/no-video-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} className={this.props.className} alt="" /></span>)

                } else {
                    return (<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}>
                        {(this.props && this.props.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
                        <span className="articoni"><i className="fa fa-video-camera" aria-hidden="true"></i></span>
                        <VideoPreview source={this.state.objectURL} mimeType={this.state.mimeType} className={this.props.className} width={this.props.width} showControls={this.props.showControls} autoPlay={this.props.autoPlay} currentTime={4}/></span>)

                }

            } else if (this.state.mimeType.match(/audio.*/)) {
                if ((this.props && this.props.isAdmin)) {
                    return (<span className="articonstatic" id="articonstatic" style={{ height: scrnWidth }}><img src={`/${configPath.imageAssets}/no-audio-art.jpg`} height={this.props.thumbnailHeight} width={this.props.thumbnailWidth} className={this.props.className} alt="" /></span>)
                } else {
                    if (this.props.isDetail) {
                        return (
                            
                            <MusicTheme source={this.state.objectURL} mimeType={this.state.mimeType} className={this.props.className} thumbnailHeight={this.props.height} thumbnailWidth={this.props.width} showControls={true} autoPlay={this.props.isDetail} loop={this.props.isDetail} sharedData={this.props.sharedData}/>)
                    } else {
                        return (<span className="articon audioart articonstatic" id="articonstatic" style={{ height: scrnWidth }}>
                            {(this.props && this.props.isPremiumArt) && <span className="articoni permium" data-toggle="tooltip" title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt="" /></span>}
                            <span className="articoni"><i className="fa fa-music" aria-hidden="true"></i></span>
                            <AudioPreivew source={this.state.objectURL} mimeType={this.state.mimeType} className={this.props.className} thumbnailHeight={this.props.height} thumbnailWidth={this.props.width} showControls={true} autoPlay={this.props.isDetail} loop={this.props.isDetail} /></span>)
                    }
                }
            }

        }
        else {
            if ((this.props && this.props.isDetail)) {
                return (<img src={`../${configPath.imageAssets}/cover-detail-loader.gif`} className="threedotsloader" alt="" />)

            }
            // Show loader when image is loading
            return (<span className="articonstatic hexagon" id="articonstatic"><img src={`../${configPath.imageAssets}/ajax-loader.gif`} className="hexagonloader" width={this.props.width} style={this.props.style} alt="" /></span>)
        }
    }

    fetchArt(url, artMimeType) {
        if (!artMimeType) {
            fetchService.getBlob(url).then((blob) => {
                this.setState({ isCheck: true })
                // Set the Object URL state property
                // only if the blob is either an image or a video.
                if (blob.type.match(/image.*/) || blob.type.match(/video.*/) || blob.type.match(/audio.*/)) {
                    this.setState({
                        objectURL: URL.createObjectURL(blob),
                        mimeType: blob.type,
                    });

                } else {
                    this.setState({
                        objectURL: null,
                        mimeType: null
                    });
                }
            }).catch((err) => {
                this.setState({
                    objectURL: null,
                    mimeType: null
                });
            })
        } else {
            this.setState({
                objectURL: url,
                mimeType: artMimeType
            })
        }
    }
}