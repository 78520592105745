import React from "react";
import { connect } from "react-redux";
import { CreatorStoreFooter } from "./StoreFooter";
import { CreatorStoreHeader } from "./StoreHeader";
import { CreatorStoreSidebar } from "./StoreSidebar";
 class StoreLayout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open : false
        }
    }
    openSidebar = () => {
        this.setState({ open: !this.state.open })
    }

    render() {
        const pathname = window.location.pathname
        return (
            <div className="store-dash">
                
                <CreatorStoreSidebar pathname={pathname} open={this.state.open}/>
                <div className='ds-content'>
                <CreatorStoreHeader openSidebar={this.openSidebar} pathname={pathname}/>
                    {this.props.children}
                    </div>
                    
                    <CreatorStoreFooter/>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
    }
}
const actionCreator = {
    
}
const connectStoreLayout = connect(mapStateToProps,actionCreator)(StoreLayout)
export {connectStoreLayout as StoreLayout}
