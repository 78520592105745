import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { eventActions, midwestActions } from 'store/actions';
import moment from 'moment'
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { toast } from 'react-toastify';
import { VideoPreview } from 'components/video-player/video-preview';

var dataURL = {}

class MidwestAddEventGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caption: '',
            desc: '',
            submitted: false,
            localImgPreview : '',
            localVideoPreview : '',
            localArtType : '',
            localImageName : '',
            localFileUpload : false,
            localFileData : []
        }

    }
    componentDidMount() {
        this.props.getAllEvents()
    }
    static getDerivedStateFromProps(props, state) {
        console.log(props)
        if ((props && props.addedEvent && props.addedEvent.statusCode === 200)) {
            props.resetaddEvent()
            toast.success("Event gallery added successfully")
            history.push('/admin/midwestEventsGalleryList')
        }
        
        return null

    }
    componentDidUpdate(props,state){
        if((this.props && this.props.allevents && this.props.allevents.statusCode === 200) && (this.props.allevents) !== (props.allevents)){
            props.resetEvents()
            const allevents = (this.props && this.props.allevents && this.props.allevents.data && this.props.allevents.data.conventions)
            
            const allTypes = [{event : "Select event", value : 0}]
            allevents && allevents.length > 0 && allevents.map(items => {
                let obj = {
                    event: items && items.name,
                    value: items && items.id
                }
                allTypes.push(obj)

            })
            this.setState({events : allTypes})
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    onFullNameChange = (e) => {
        this.setState({ caption: e.target.value })
    }
    onDescChange = (e) => {
        this.setState({ desc: e.target.value })

    }

    checkname = (value) => {
        if ((value && value.length) > 50) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= 50) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkDesc = (value) => {
        if (((value && value.length) <= 250) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    handleOnstart = (e) => {
        this.setState({ onstart: e.target.value })
    }
    handleEndTime = (e) => {
        this.setState({ endat: e.target.value })
    }
    checkValidDate = () => {
        if (new Date(this.state.endat) > new Date(this.state.onstart)) {
            return true
        } else {
            return false
        }
    }
    checkmaxInput = (value) => {
        if ((value && value.length) <= 50) {
            return true
        } else {
            return false
        }
    }
    checkInput = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkMaxDescInput = (value) => {
        if ((value && value.length) <= 250) {
            return true
        } else {
            return false
        }
    }
    validURL = (str) => {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        if (re.test(str)) {
            return true
        } else {
            return false
        }
    }
    gotoBack = () => {
        history.goBack()
    }
    getLocalPreview = () => {
        const {localImgPreview, localVideoPreview, localArtType} = this.state
        if (localImgPreview !== '') {

            return (
                <img src={localImgPreview} className="no-images-vertical createartimg" alt="preview" />
            );
        } else if (localVideoPreview !== '') {

            const video = {
                source: localVideoPreview,
                mime: localArtType
            };
            return (
                <VideoPreview source={video.source} mimeType={video.mime} showControls={false} />
            );
        } else if (localImgPreview === '' && localVideoPreview === '') {
            return (
                <img src={localImgPreview} className="no-images-vertical createartimg" alt="" />
            );
        }
    }
    handleChangeFile = () => {
        this.setState({
            localArtType : '',
            localFileUpload : false,
            localImageName : '',
            localImgPreview: '',
            localVideoPreview : '',
            localFileData : []
        })
       
    }
    previewFileProgress1 = (e) => {
        let uploadFile = e.target.files[0]

        const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'video/mp4', 'video/webm']

        let err = '';
        const ImagsizeLimit = 2 * (1e+6);
        const VidesizeLimit = 20 * (1e+6);


        if (uploadFile == undefined || uploadFile.type == undefined) {
            return false
        }
        else if ((types.every(type => uploadFile && uploadFile.type !== type))) {

            err += uploadFile.type + ' is not a supported format\n';
            toast.error(err)
            this.setState({localFileUpload : false})

        } else {
            this.setState({localImageName : e.target.files[0] && e.target.files[0].name})

            if (uploadFile) {
                if ((uploadFile && uploadFile.type && ((uploadFile.type === "image/png") || (uploadFile.type === ("image/jpeg")) || (uploadFile.type === ("image/webp")) || (uploadFile.type === ("image/jpg"))))) {
                    if (uploadFile && uploadFile.size >= ImagsizeLimit) {
                        err += `Maximum supported file size is ${2} MB`;
                        toast.error(err)
                        this.setState({localFileUpload : false})
                    } else {
                        const resp = uploadFile
                        let reader = new FileReader();
                        window.URL.createObjectURL(resp);
                        reader.onloadend = async (theFile) => {
                            var fileData = await resp

                            dataURL = await reader.result
                            this.setState({
                                localArtType : resp.type,
                                localFileUpload : true,
                                localImageName : resp.name,
                                localImgPreview: window.URL.createObjectURL(resp),
                                localVideoPreview : '',
                                localFileData : resp
                            })
                           

                        }
                        if (resp) {
                            reader.readAsDataURL(resp)
                        }
                    }
                } else if (
                    (uploadFile && uploadFile.type &&
                        ((uploadFile.type === "video/mp4") || (uploadFile.type === "video/webm")))
                ) {
                    if (uploadFile && uploadFile.size >= VidesizeLimit) {
                        err += `Maximum supported file size is ${20} MB`;
                        toast.error(err)
                        this.setState({localFileUpload : false})

                    } else {
                        const file = uploadFile
                        const localVideoPreview = URL.createObjectURL(file);
                        this.setState({
                            localArtType : file.type,
                            localFileUpload : true,
                            localImageName : file.name,
                            localImgPreview: '',
                            localVideoPreview : localVideoPreview,
                            localFileData : file
                        })

                    }
                } else {
                    if (err == '') {
                        if (uploadFile && uploadFile.size >= ImagsizeLimit) {
                            err += `Maximum supported file size is ${2} MB`;
                            toast.error(err)
                            this.setState({localFileUpload : false})
                        } else {
                            let reader = new FileReader();
                            let file = uploadFile
                            reader.onloadend = () => {
                                this.setState({
                                    localArtType : file.type,
                                    localFileUpload : true,
                                    localImageName : file.name,
                                    localImgPreview: reader.result,
                                    localVideoPreview : '',
                                    localFileData : file
                                })

                            }

                            if (uploadFile && uploadFile) {
                                reader.readAsDataURL(file)
                            }
                        }
                    }
                }
            }
        }

    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({submitted : true})
        console.log(this.state)
        const {selectType,caption,localArtType,localFileData,desc,localImageName} = this.state
        let formData = new FormData()
        if (selectType) {
            if (caption && this.checkFullName(caption)) {
                if (localImageName && localFileData && localFileData.name ) {
                    formData.append("caption", caption)
                    formData.append("media", localFileData)
                    if (desc) {
                        if (this.checkDesc(desc)) {
                            formData.append("description", desc)
                            this.props.addevent(selectType,(formData))

                        } else {
                            toast.error("Description must be contain atlease 3 characters and atmost 250 characters long")
                        }
                    } else {
                        this.props.addevent(selectType,(formData))
                    }
                } else {
                    toast.error("Please select your gallery file")

                }

            } else {
                toast.error("Caption mandatory and should be atleast 3 characters and atmost 50 characters")
            }
        } else {
            toast.error("Please select event for this gallery")

        }
    }
    handleDropDown = (e) => {
        this.setState({selectType : e.target.value,offset:0},() => {
        })
    }
    render() {
        const props = this.props
        const {events} = this.state
        const eventDropDown = events && events.length > 0 && events.map((filType) => {
            return <option value={filType.value} key={filType.value}>{filType.event}</option>
        });
        return (

            <Fragment>

                <div className="smallilac px-3">
                    <div className='ca-card mb-3 '>
                        <div className="row admintitle align-items-center">
                            <div className="col-sm-6 "><h2 className="font-weight-bold">
                                {(props && props.params && props.params.id) ? "Edit Event Gallery" : "Add Event Gallery"}</h2>  </div>
                            <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}><i className="fas fa-angle-left"></i> Back</button>  </div>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className='ca-card mb-3 '>
                            <div className="row">
                                <div className="form-group col-md-6 mb-4">
                                    <label htmlFor="events " className="font-weight-bold" >Select Event &nbsp;*</label>
                                    {events && events.length > 0 ?
                                        <select value={this.state.selectType} className="form-control" onChange={this.handleDropDown}>
                                        {eventDropDown}
                                    </select> : <div className="col-md-12 text-center">
                                            {/* <div className="loader"></div> */}
                                        </div>}
                                    {this.state.submitted && !(this.state.selectType) && <div className="mandatory small">{"Please select event type"}</div>}

                                </div>
                                <div className="form-group col-md-6 mb-4">
                                    <label htmlFor="caption " className="font-weight-bold" >Caption &nbsp;*</label>
                                    <input type="text" className={this.checkname(this.state.caption) ? "form-control descdan" : "form-control"} id="caption" aria-describedby="name" placeholder="Enter caption" value={this.state.caption} onChange={this.onFullNameChange} />
                                    {<div className="mandatory-req small">{"Min 3 characters and max 50 characters"}</div>}

                                    {this.state.submitted && !this.state.caption && <div className="mandatory small">{"Caption required"}</div>}
                                    {this.state.submitted && this.state.caption && !this.checkInput(this.state.caption) && <div className="mandatory small">{"Caption must be at least 3 characters"}</div>}
                                    {this.state.submitted && this.state.caption && !this.checkmaxInput(this.state.caption) && <div className="mandatory small">Caption should be no more than 50 characters</div>}
                                </div>


                                <div className="form-group col-md-12 ">
                                    <label htmlFor="desc " className="font-weight-bold" >Description</label>
                                    <textarea className="form-control textareaexapand" id="desc" aria-describedby="desc" placeholder="Enter description" value={this.state.desc} onChange={this.onDescChange} />
                                    {<div className="mandatory-req small">{"Min 3 characters and max 250 characters"}</div>}

                                    {this.state.submitted && this.state.desc && !this.checkInput(this.state.desc) && <div className="mandatory small">{"Description must be at least 3 characters"}</div>}
                                    {this.state.submitted && this.state.desc && !this.checkMaxDescInput(this.state.desc) && <div className="mandatory small">Description should be no more than 250 characters</div>}

                                </div>
                                <div className="form-group mb-3 col-md-12">
                                    <label className="col-form-label font-weight-bold">Select your media file</label>

                                    <div className="row mb-3 nftbtns">
                                        <div className="col-md-12"><div className="mandatory-req mb-3 text-white">{this.state.localImageName}</div></div>
                                        <div className="col-md-6">
                                            {this.state.localFileUpload && <div onClick={this.handleChangeFile}><label className="btn btn-secondary w-100">Change file</label></div>}
                                            {!this.state.localFileUpload && <div className="choosefile w-100">
                                                <input type="file" id="pfile" name="image" onChange={this.previewFileProgress1} single="" className="inp-box w-100 p-2" accept="image/jpeg, image/png, image/gif, video/mp4, video/webm" />

                                                <label htmlFor="pfile" className="btn btn-primary w-100 mb-3 p-2">Browse File</label>
                                                {this.state.submitted && !this.state.localImageName && <div className="mandatory">{"Gallery File Required"}</div>}
                                            </div>}
                                            <div className="mandatory-req small">Maximum File Size for Image <strong>2 MB</strong><br /> Maximum File Size for Video <strong>20 MB</strong></div>
                                            <div className="mandatory-req brdnft small">Supported format for Image (JPG, JPEG, PNG, GIF, WEBP) , Video (MP4, WEBM)</div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="previewimg mb-3 form-control">
                                                {this.getLocalPreview()}
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>



                        {<div className="form-group col-md-12 text-right">
                            <hr />
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>}
                    </form>

                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        addedEvent: state.midwestcon.e_galleryAdded,
        allevents : state.midwestcon.midwestEvents


    }
}

const actionCreators = {
    addevent: midwestActions.addEventGallery,
   
    resetaddEvent: midwestActions.resetAddEventGallery,
    getAllEvents : midwestActions.getAllMidwestEvents,
    resetEvents : midwestActions.resetGetAllMidwestEvents


}

const connectMidwestAddEventGallery = connect(mapStateToProps, actionCreators)(MidwestAddEventGallery)

export { connectMidwestAddEventGallery as MidwestAddEventGallery }