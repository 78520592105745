import React from "react";
import PubSub from 'pubsub-js';
import { sendwyreService } from "services/sendwyre.service";
import { getUserAddress } from "utils";
import { Fragment } from "react";
import { Preview } from './preview'
import { ProfilePreview } from './profilePreview'
import history from 'helpers/history'
import { Helmet } from "react-helmet";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "axios";
import { discoverServices } from "services";
import {configPath} from '../config'
export class OwlCarousal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }

            }
        }

    }


    componentDidMount() {
        if ((this.props && this.props.data)) {
            this.setState({
                featureCreators: this.props.data
            })
        }
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            // offSet:0,
            // recordLimit:6
        }
        // discoverServices.getAllFeatureCreators(obj).then(res => {
            
            const featureCollectors = this.props && this.props.featureCreators
            console.log(featureCollectors)
            const featureCreatorsArr1 = featureCollectors && featureCollectors.map((items, index) => {
                return <div className="col-12" onClick={(e) => { e.preventDefault(); history.push(`/creator/@${items && items.userName && encodeURIComponent(items.userName)}`) }} key={items && items.id}>
                    <div className="cardlist artimglist ">
                        <div className="artcovimglink mt-0">
                            <h4 className="mb-3">{items.fullName}</h4>

                            <Preview src={items && items.profileImage && (`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.profileImage}`)} alt="" className="comimg" style={{}} />
                            <div className="badgeview">
                                <div className="picpro">
                                    <span className="artcovimg">
                                        <span className="artcov "></span>
                                        <span className="artcovimginset no-proimg">
                                            <ProfilePreview src={items && items.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${items.profileImage}`} alt="" />
                                        </span>
                                    </span>
                                </div>
                                <h2 className="usernameClick">{items && items.userName && `@${items.userName}`}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            })
            const featureCreatorsArr = featureCollectors && featureCollectors.map((items, index) => {
                return <div className="item falist">
                <div className="falist-con" onClick={(e) => { e.preventDefault(); history.push(`/creator/@${items && items.userName && encodeURIComponent(items.userName)}`) }} id="uploadArt">
                    <div className="fafeaturetxt" title={items && items.userName}>
                        {/* <img src="assets/images/permium.png" alt="" className="permiumicon mr-2"  /> */}
                        {items && items.userName && `@${items.userName}`}</div>
                    <div className="faimg" id="faimg" >
                        <Preview src={items && items.profileImage && (`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.profileImage}`)} alt="" className="comimg"/>
                    </div>
                    <div className="falistdesc" title={items.fullName}><h3><span>{items.fullName}</span></h3></div>
                </div>

            </div>
            })
            this.setState({ featureCreatorsArr, featureCollectors, featureCreatorsArr1 })
        // })


    }

    render() {
        const { featureCollectors } = this.state    

        return (
            <div>
                {(this.state.featureCreatorsArr && this.state.featureCreatorsArr.length > 1) ?
                    <AliceCarousel autoPlay autoPlayInterval={4000} 
                    items={this.state.featureCreatorsArr} 
                    infinite 
                    responsive={this.state.responsive} 
                    mouseDragEnabled={true}
                    autoPlayDirection={"ltr"}
                    mouseTracking={true}   
                     disableDotsControls  
                    >

                    </AliceCarousel> :
                    (this.state.featureCreatorsArr)
                }
            </div>

        )
    }
}
