import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const toastr = {
  customConfirm(title, props) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h3>Are you sure?</h3> */}
            <h6>{title}</h6>
            <div className="custom-btn">
              <button className="btn btn-primary px-5 mr-3" onClick={() => {
                props.onCancel()
                onClose()
              }}>{props.no}</button>
              <button className="btn btn-outline-dark px-5"
                onClick={() => {
                  props.onOk()
                  onClose();
                }}
              >
                {props.yes}
              </button>
            </div>
          </div>
        );
      }
    });
  },
  appUpdateAlert(onOk) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h3>DisruptArt marketplace has been updated with new improvements & features.</h3>
            <p>
                Kindly click "CONTINUE" to use an updated version.
            </p>
            <div className="custom-btn text-center">
              <button className="btn btn-primary px-5"
                onClick={() => {
                  onOk()
                  onClose();
                }}
              >
                CONTINUE
              </button>
            </div>
          </div>
        );
      }
    })
  }
}