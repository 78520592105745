import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {announcementActions, userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';
import history from 'helpers/history';
import Toggle from 'react-toggle';

class AddNotes extends Component{
    constructor(props){
        super(props);
        this.state = {
            notes:'',
            submitted: false,
            isSend : false,
            isWarning : false,
            isError : false,
            showMessage : false,
            isTooLength : false,
            announcements : [],
            checkedId : 0,
            isChecked : false
        }
    }
    componentDidMount(){
        this.getMessage()
        this.getAnnouncementHistory()
    }
    
    componentDidUpdate(props, state) {
        if (props && props.getmessage && (props.getmessage.statusCode === 200) && props.getmessage.data ) {
            props.resetGetAnnouncement()

            if(props.getmessage.data && props.getmessage.data.bannerType){
               
                if(props.getmessage.data.bannerType === "warning"){
                    
                    this.setState({isWarning:true,isError : false})
                }else{
                    this.setState({isError : true, isWarning : false})
                }
            }
            this.setState({
                notes : props.getmessage.data && props.getmessage.data.bannerMessage,
                showMessage : props.getmessage.data && props.getmessage.data.showBanner
            })
            


        }
        if((this.props && this.props.addedMessage && this.props.addedMessage.statusCode === 200) && (this.props.addedMessage !== props.addedMessage)){
            props.resetAddedAnnouncement()
            this.getMessage()
            this.getAnnouncementHistory()
        }

        if((props && props.getHistory && props.getHistory.statusCode) === 200){
            props.resetAnnouncementHistory()
            this.setState({
                announcements : props.getHistory && props.getHistory.data && props.getHistory.data.entity
            })
        }
        
        if((this.props && this.props.deletedRes && this.props.deletedRes.statusCode === 200) && (this.props.deletedRes !== props.deletedRes)){
            props.resetDeleteAnnouncement()
            toast.success("Banner deleted")
            this.getAnnouncementHistory()
        }


    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    getMessage = () => {
        this.props.getAnnouncementMes()
    }
    getAnnouncementHistory = () => {
        this.props.getAnnouncementsHistory()

    }
    gotoBack = () => {
        history.goBack()
    }
    onNotesChange = (e) => {
        if(((e.target.value).length) <= 200){
            this.setState({notes : e.target.value,isTooLength : false})
        }else{
            this.setState({isTooLength : true, notes : (e.target.value.slice(0,200))})
        }
    }
    
    handleWarnChecked = (value) => {
        const {isError} = this.state
        
        if(!isError){
            this.setState({ isWarning : true })
        }else{
            this.setState({ isError : false, isWarning : true })
        }
    }
    handleErrorChecked = (value) => {
        const {isWarning} = this.state
       
        if(!isWarning){
            this.setState({ isError : true})
        }else{
            this.setState({ isWarning : false, isError : true })
        }
    }
    statusChange = (value) => {
        const status = value ? false : true
        this.setState({
            showMessage : status
        })
    }
    checkNotes = (value) => {
        if(((value && value.length) <= 200) && ((value && value.length) >= 3)){
            return true
        }else{
            return false
        }
    }
    checkLength = (value) => {
        if((value && value.length) > 200){
            return true
        }else{
            return false
        }
    }
   handleSubmit = (e) => {
        e.preventDefault()
        this.setState({submitted:true})
        const {notes,isWarning,isError,showMessage} = this.state
       if(showMessage){
            if(notes){     
                if(this.checkNotes(notes)){
                    if((isWarning || isError)){
                        let obj = {
                            bannerMessage : notes,
                            bannerType : isWarning ? "warning" : "danger",
                            showBanner : showMessage
                        }
                        this.props.addAnnouncementMes(JSON.stringify(obj))
                    }else{
                        toast.error("Please select your banner type")
                    }
                }else{
                    toast.error('Message must be contain atleast 3 characters and atmost 200 characters')
                }    
            }else{
            toast.error("Please add your message")
            }
        }else{
            let obj = {
                bannerMessage : notes,
                bannerType : isWarning ? "warning" : "danger",
                showBanner : showMessage
            }
            this.props.addAnnouncementMes(JSON.stringify(obj))
        }
   }
   handleCheck = (items) => {
       const {checkedId} = this.state
       if(checkedId === items.id){
        this.setState({
            checkedId : 0
        })  
       }else{
       this.setState({
           checkedId : items.id,
           notes : items.bannerMessage,
       })
       if(items.bannerType == "danger"){
           this.setState({
               isError : true,
               isWarning : false
           })
       }else{
        this.setState({
            isWarning : true,
            isError : false
        })
       }
    }
   }
   handleDelete = (items) => {
    if((items && items.id)){
        this.props.deleteAnnouncement(items.id)

        if(this.state.notes === items.bannerMessage){
            if(items.bannerType === "warning"){
                if(this.state.isWarning){
                    let obj = {
                        bannerMessage : this.state.notes,
                        bannerType : "warning",
                        showBanner : false
                    }
                    this.props.addAnnouncementMes(JSON.stringify(obj))
                }
                
            }else{
                if(this.state.isError){
                    let obj = {
                        bannerMessage : this.state.notes,
                        bannerType : "danger",
                        showBanner : false
                    }
                    this.props.addAnnouncementMes(JSON.stringify(obj))
                }
            }
        }
    }
   }
    render(){
        const { isTooLength, announcements, checkedId } = this.state
       console.log(announcements)
        const announcementsList = announcements && announcements.map((items, index) => {
            return <tbody>
                <tr className={items && items.isDisruptArtUser ? "lilacbg1" : ''}>
                    <td>
                        <input type="checkbox" className="form-check-input" id={items.id} checked={(checkedId === items.id) ? true : false} onClick={() => this.handleCheck(items)}/>
                        <label className="form-check-label" htmlFor={items.id}> </label>

                        </td>
                    <td>{items && items.id}</td>
                    <td>{items && items.bannerType && (items.bannerType === "warning" ? "Warning" : "Error")}</td>
                    <td>{items && items.bannerMessage}</td>
                    <td><button type='button' onClick={() => {this.handleDelete(items)}}><i className="fa fa-trash-o" aria-hidden="true"></i></button></td>
                </tr>
            </tbody >

        })
        return(
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-5 admintitle">
                        <div className="col-sm-6 "><h2 className="font-weight-bold">Announcement </h2>  </div>
                        {/* <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={this.gotoBack}>Back</button>  </div> */}
                        
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                        <div className="form-group  col-md-6 offset-md-3">
                            <label className="font-weight-bold" >Show Banner &nbsp;*</label><br/>
                            <Toggle checked={this.state.showMessage} onChange={() => this.statusChange(this.state.showMessage)} />
                            </div>
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <label htmlFor="notes" className="font-weight-bold" >Banner Message &nbsp;*</label>
                                <textarea  className={isTooLength ? "form-control descdan" : "form-control"} id="notes" aria-describedby="notes" placeholder="Enter Message" value={this.state.notes} onChange={this.onNotesChange} disabled={!this.state.showMessage}/>
                                {this.state.submitted && !this.state.notes && this.state.showMessage && <div className="mandatory">{"Message required"}</div>}
                                {!this.state.submitted && this.state.notes && !this.checkNotes(this.state.notes) && !isTooLength && <div className="mandatory-req">{"Must be contain atleast 3 characters and atmost 200 characters"}</div>}
                                {!this.state.submitted && this.state.notes && isTooLength && <div className="mandatory">{"Must be contain atleast 3 characters and atmost 200 characters"}</div>}
                                {this.state.submitted && this.state.notes && !this.checkNotes(this.state.notes) && <div className="mandatory">Message must be contain atleast 3 characters and atmost 200 characters</div>}                                        

                            </div>
                            
                            <div className="form-group  col-md-6 offset-md-3">
                            <label className="font-weight-bold" >Banner Type &nbsp;*</label>
                                <input type="radio" className="form-check-input mr-3" id="warn" checked={this.state.isWarning} onClick={() => this.handleWarnChecked(this.state.isWarning)} disabled={!this.state.showMessage}/>
                                <label className="form-check-label" htmlFor="warn">Warning</label>
                            </div>
                            
                            <div className="form-group mb-4 col-md-6 offset-md-3">
                                <input type="radio" className="form-check-input mr-3" id="error" checked={this.state.isError} onClick={() => this.handleErrorChecked(this.state.isError)} disabled={!this.state.showMessage}/>
                                <label className="form-check-label" htmlFor="error">Error</label>
                            </div>
                            
                            <div className="form-group mb-4 col-md-6 text-center offset-md-3">
                                <button type="submit" className="btn btn-primary w-25">Submit</button>
                            </div>
                                                                                          
                        </div>
                    </form>
                    {<div className="table-responsive col-md-9 offset-md-2">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>SELECT</th>
                                        <th>ID</th>
                                        <th>BANNER TYPE</th>
                                        <th>BANNER MESSAGE</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>

                                {announcementsList}


                            </table>
                            {announcements && announcements.length == 0 && <div className="text-center">No Data Found</div>}
                        </div>}
                </div>
             
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        getmessage : state.announcement.a_message,
        addedMessage : state.announcement.mesAdded,
        getHistory : state.announcement.announcementHistory,
        deletedRes : state.announcement.deletedBanner
    }
}
const actionCreator = {
    getAnnouncementMes : announcementActions.getAnnouncement,
    addAnnouncementMes : announcementActions.addAnnouncement,
    resetAddedAnnouncement : announcementActions.resetAddedAnnouncement,
    resetGetAnnouncement : announcementActions.resetGetAnnouncement,
    getAnnouncementsHistory : announcementActions.getAnnouncementHistory,
    resetAnnouncementHistory : announcementActions.resetGetAnnouncementHistory,
    deleteAnnouncement : announcementActions.deleteAnnouncement,
    resetDeleteAnnouncement : announcementActions.resetDeleteAnnouncement
}
const connectNotes = connect(mapStateToProps,actionCreator)(AddNotes)
export {connectNotes as AddNotes}