import { configPath } from "config";
import { accessHeader } from "helpers"
import PubSub from 'pubsub-js'; 
import { fetchService } from "./fetch-service";

export const walletServices = {
    linkWallet,
	checkWallet,
    syncWallet
}

function linkWallet(obj){
    const requestOptions = {
        method: 'POST',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/wallet-account/user/link-wallet-account`,requestOptions).then(handleResponse,handleError)
}

function checkWallet(address){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/wallet-account/user/${address}/check-wallet-account`,requestOptions).then(handleResponse,handleError)
}

function syncWallet(address){
    const requestOptions = {
        method: 'POST',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/wallet-account/user/${address}/sync-wallet-art`,requestOptions).then(handleResponse,handleError)
}


function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}
			const error = data && data.message
			return Promise.reject(error)
		}
		return data;
	});
}

function handleError(err) {
    console.log(err)
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            return Promise.reject(err)
        }else{
            PubSub.publish('msg', false)
            return Promise.reject("Unexpected error occured")
        }
    }
    catch(err) {
        return Promise.reject(err)
    }
}