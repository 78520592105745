import React from "react";
import { Navbar } from "../components/Navbar";
import { Footer } from '../components/footer'
import { configPath } from "config";
import { Helmet } from "react-helmet";

export class PageNotFound extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Page not found</title>
                    <meta name="title" content="Page not found"></meta>
                    <meta name="description" content={`Page not found`}></meta>
                </Helmet>
            <Navbar appPath="" />
            <div className="container text-center pagenotfound py-5">
                <img src={`${configPath.imageAssets}/404.png`} alt="" className="mw-100 mb-xs-3 mb-5 pnfimg"/>
                <p>
                    The page you are looking for could not be found.
                </p>
                <p><a href="/" className="btn btn-primary px-5">Back to home</a></p>
            </div>
            <Footer/>
            </div>
        );
    }
}