import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userAction } from 'store/actions';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from 'config';
import GoogleLogin from 'react-google-login';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { SolarSystemLoading } from 'react-loadingg';
import { Register } from './register';
import { WebLoginPage } from './WebLogin';
import { WebSignUp } from './WebSignup';

var timeout;
var loadTimeout;
class LoginWeb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loginData: [],
            reCaptchaToken: '',
            isSuccess: false,
            isAlert: false,
            style: '',
            loader: true,
            logsign : true        
        }
    }
    

    static getDerivedStateFromProps(props, state) {
        let path = state.artId + state.hashId

        if ((props && props.webLogRes && props.webLogRes.statusCode) === 200) {
            props.resetWebLogin()
            state && state.isDapperPurchase ? history.push(path, {isForDapperPurchase: true}) : state && state.artId ? history.push(path, { isFromLogin: true }) : state && state.creatorUrl ? history.push(state.creatorUrl, { isFromLogin: true }) : state && state.reportForm ? history.push(state.reportForm, {isForReport : true}): history.push('/')
            // history.push('/')

            // return {
            //     isAlert : false,
            //     message:"",
            //     style:'',
            //     isSuccess:true
            // }
        }
        if ((props && props.failedRes && state.submitted)) {
            if (props.failedRes.message) {
                return {
                    isAlert: true,
                    message: (props && props.failedRes && props.failedRes.message && ((props.failedRes.message).charAt(0).toUpperCase()) + (props.failedRes.message).slice(1).toLowerCase()),
                    style: "danger"

                }
            } else {
                if (
                    (props &&
                        props.failedRes &&
                        props.failedRes.includes("userNameOrEmail must be at least 3 characters long. And userNameOrEmail must not contain leading and trailing spaces."))) {
                    return {
                        isAlert: true,
                        message: 'Username/email or password you entered is incorrect',
                        style: "danger"

                    }
                }
                return {
                    isAlert: true,
                    message: (props && props.failedRes && ((props.failedRes).charAt(0).toUpperCase()) + (props.failedRes).slice(1).toLowerCase()),
                    style: "danger"

                }
            }
        }


    }
    componentDidUpdate(props, state) {
        if ((props && props.failedRes)) {
            if ((props.failedRes && props.failedRes.message && props.failedRes.message.toLowerCase()) === "email has not been verified") {

                if (props && props.failedRes && props.failedRes.id) {
                    history.push(`/registration/user/${props.failedRes.id}/verify`)
                }
            } else if (props.failedRes && props.failedRes.length > 0) {
                this.setState({ isAlert: true, message: props.failedRes, style: "danger" })
            }
            props.resetLoginFailure()
            this.resetTimeout()
            this.gotoTop()

        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }

    }
    componentDidMount() {
        this.setLoader()
        localArtPage()
        localDropPage()
        localAirdropPage()
        if (!isWebLogin()) {
            this.setState({
                artId: (this.props && this.props.location && this.props.location.state && this.props.location.state.artId),
                hashId: (this.props && this.props.location && this.props.location.state && this.props.location.state.hashId),
                creatorUrl: (this.props && this.props.location && this.props.location.state && this.props.location.state.creatorUrl && this.props.location.state.creatorUrl),
                reportForm : (this.props && this.props.location && this.props.location.state && this.props.location.state.reportForm),
                isDapperPurchase: (this.props && this.props.location && this.props.location.state && this.props.location.state.isDapperPurchase)
            })
            this.gotoTop()
        } else {
            history.push('/')
        }
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value).toLowerCase()
        })
    }
    handlePasswordChange = (e) => {
        this.setState({
            [e.target.name]: (e.target.value)
        })
    }
    captchaChange = (value) => {
        this.setState({ reCaptchaToken: value })
    }
    handleRegister = () => {
        this.props.enableRegister(true)
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    
    handleLogin = async (googleData) => {
        if (googleData.tokenId) {
            let obj = { tokenId: googleData.tokenId }
            this.props.googleLogin(JSON.stringify(obj))
        } else {
            // console.error('Google sign-in error',googleData);
        }
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    setLoader = () => {
        loadTimeout = setTimeout(() => {

            this.setState({ loader: false })
        }, 4000)
    }
    componentWillUnmount() {
        clearTimeout(loadTimeout)
    }
    updateLogError = (message) => {
        console.log(message)
        this.setState({ isAlert: true, message: message, style: "danger" })
        this.gotoTop()
    }
    updateLogSuccess = (message) => {
        this.setState({ isAlert: true, message: message, style: "success" })
        this.gotoTop()
    }
    updateRegError = (message) => {
        this.setState({ isAlert: true, message: message, style: "danger" })
        this.gotoTop()
    }
    updateRegSuccess = (message) => {
        this.setState({ isAlert: true, message: message, style: "success" })
        this.gotoTop()
    }

    handleUpdate = () => {
        this.setState({logsign: !this.state.logsign})
    }

    
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        
        return (
            <Fragment>
                <Helmet>
                    <title>Login</title>
                    <meta name="title" content="Login"></meta>
                    <meta name="description" content={`Login to ${process.env.REACT_APP_SITE_CONTRACT}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}


                <div className="container logsign my-5">
                    <div className='row'>
                    <div className='col-md-6'>
                    <div className='dlilabg pb-5'>

                        <WebLoginPage updateLogError={(v) => {this.updateLogError(v)}} updateLogSuccess={(v) => {this.updateLogSuccess(v)}}/>
                        </div>
                        </div>
                        <div className='col-md-6'>
                                <div className="accordion logacordbtn" id="accordionExample">
                                        <div className={this.state.logsign ?  "card-header align-items-center d-flex flex-column" : "card-header align-items-center d-flex flex-column  d-nonecus"} >
                                            <h2 className="mb-3">
                                                New to Disrupt Art? </h2>
                                                <button onClick = {this.handleUpdate} className="btn btn-primary-gradient px-5" type="button" id="headingOne" data-toggle="collapse" data-target="#collapseSignup" aria-expanded="false" aria-controls="collapseSignup">
                                                    Signup
                                                </button>                                           
                                        </div>
                                        <div id="collapseSignup" className="collapse" aria-labelledby="headingOne" >
                                            <div className="card-body p-0">
                                               
                                                   <WebSignUp updateRegError={(value) => { this.updateRegError(value) }} updateRegSuccess={(value) => { this.updateRegSuccess(value) }} />

                                               
                                            </div>
                                        </div>
                                </div>
                            </div>
                    </div>
                </div>
                {this.state.loader ?
                    <div className="loader"><SolarSystemLoading /></div> :
                    <div className="loader-hide"><SolarSystemLoading /></div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        webLogRes: state.user.webLogRes,
        failedRes: state.user.failedRes
    }
}

const actionCreators = {
    login: userAction.webLogin,
    googleLogin: userAction.googleLogin,
    resetWebLogin: userAction.resetWebLogin,
    resetLoginFailure: userAction.resetLoginFailure,
    getUserProfile: userAction.getUsers,

}

const connectLogin = connect(mapStateToProps, actionCreators)(LoginWeb)

export { connectLogin as LoginWeb }
