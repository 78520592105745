
import { configPath } from '../../config';
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'
import { getUserAddress } from 'utils';
import { flowActions, walletActions } from 'store/actions';
import { isConnectWallet, userBalance } from 'helpers';
import { isDapperConnected } from 'helpers/switchauthdapper';
import { SwitchAccount } from 'components/switchaccountPopup';
import { toast, Zoom } from 'react-toastify';

class CreatorStoreHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            image: '',
            open: false,
            address: '',
            isSwitchAcc: false
        }
    }

    componentDidUpdate(props, state) {
        if ((this.props &&
            this.props.usersList &&
            this.props.usersList.statusCode === 200) &&
            (this.props.usersList) !== (props.usersList) &&
            this.props.usersList.data
        ) {
            let data = this.props && this.props.usersList && this.props.usersList.data

            this.setState({
                fullname: data.fullName ? data.fullName : '',
                image: data.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.profileImage}` : null
            })
        }
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode)) {
            this.props.resetlinkWallet()
            this.getAddress()
        }
        if((this.props && this.props.isDisconnectWallet) && (props.isDisconnectWallet) != (this.props.isDisconnectWallet)){
            this.props.resetDisconnectWallet()
        }
    }
    openSidebar = () => {
        this.setState({ open: !this.state.open })
        if (this.props && this.props.openSidebar()) {
            this.props.openSidebar()
        }
    }
    pageTitle = () => {
        const { pathname } = this.props
        // if(pathname && pathname.includes('creatorstore')){
        //     return 'Welcome to DisruptArt Store'
        // }else if(pathname && pathname.includes('settings')){
        //     return 'Store Profile'
        // }
        // else {
            return 'Welcome to DisruptArt Store'
        // }
    }
    componentDidMount(){
        this.getAddress()
    }
    getAddress(){
        userBalance().then(text => {
            if (text && text.address) {
                this.setState({ address: text.address })
            }
            if (text && text.balance && text.balance.data && text.balance.data.Value) {
                this.setState({ flowBalance: JSON.parse(text.balance.data.Value) },() => {
                })

            }
        }).catch(err => { })
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        }) 
    }
    switchWalletConnected = (wallet) => {
        if(wallet){
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
        }
    }
    handleSwitchAccFailed = (message) => {
        toast.error( message, { transition: Zoom })
     
    }
    render() {
        const { open } = this.state
        return (
            <Fragment>
                {this.state.isSwitchAcc ? 
                    <SwitchAccount 
                    closeModal={this.closeSwitchAccModal} 
                    switchWalletConnected={(val) => this.switchWalletConnected(val)}
                    handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}/> 
                    : ''}
                <div className='ds-tpro'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-7'>
                                <div className='ds-mobloto'>
                                    <a href='/store/adddrops' className='ds-logo'>
                                        <img src={`/${configPath.imageAssets}/dastore.png`} alt="Disrupt Now" className="logoaw" />
                                    </a>
                                </div>

                                <div className='ds-weltxt deskbtxt'>
                                    <h1>Welcome to DisruptArt Store <span className="betaLink">(Soon)</span></h1>
                                    <p>Hello {this.state.fullname}, Welcome back!</p>
                                </div>
                            </div>
                            <div className='col-5 text-right'>
                                <div className='ds-userdeta' >   
                                <ul className='list-unstyled sdwallet'>
                                {isConnectWallet() ? <li>{isDapperConnected() ? 
                                            <span className='mb-0'><img src={`/${configPath.imageAssets}/dapper_logo.png`} alt="Disrupt Now" className="logoaw " />{this.state.address}</span> : 
                                            <span><img src={`/${configPath.imageAssets}/blocto_logo.png`} alt="Disrupt Now" className="logoaw "/> 
                                            {this.state.address}</span>}</li> : ''}
                                            </ul> 
                                    <div className="dropdown ds-proimg" onClick={this.openSidebar}>
                                        <span className="dropdown-toggle">
                                            <img src={this.state.image} alt="" />
                                        </span>
                                        <ul className="dropdown-menu text-center">
                                            <li><h5 className='mb-0'>{this.state.fullname}</h5></li>
                                            
                                            <li><hr/></li>
                                            
                                            <li>{isConnectWallet() && <span className="nav-link cursor-pointer" title={"Switch wallet account"}>
                                                    <span className="nav-link" onClick={this.handleToSwitchAccount}><i aria-hidden="true" className="fa fa-exchange mr-2"></i> Switch</span>
                                                </span>}</li>                                            
                                        </ul>
                                    </div>

                                    <div className={open ? 'ds-toggle open' : 'ds-toggle'} onClick={this.openSidebar}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usersList: state.user.usersList,
        wallet: state.wallet.wallet,
        isDisconnectWallet: state.flow.bloctoDisconnected

    }
}
const actionCreators = {
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetDisconnectWallet: flowActions.resetDisconnectBloctoWallet


}
const connectCreatorStoreHeader = connect(mapStateToProps, actionCreators)(CreatorStoreHeader)
export { connectCreatorStoreHeader as CreatorStoreHeader }
