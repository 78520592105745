import * as fcl from "@onflow/fcl";

import { adminServices, userServices } from '../../services'
import { userConstants } from '../../constants'
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { toast } from 'react-toastify';
export const userAction = {
    login,
    logout,
    adminLogin,
    adminLogout,
    dashboard,
    refresh,
    getUsers,
    resetGetUsers,
    updateProfile,
    getAllUsers,
    resetAllUsers,
    getAllCreators,
    getCreatorApplications,
    getUSD,
    register,
    verify,
    resetRegister,
    resetPassword,
    changePassword,
    webLogin,
    resetWebLogin,
    statusApprove,
    modifyStatus,
    raiseRequest,
    forgotPassword,
    changeUserPassword,
    resetchangeUserPassword,
    googleLogin,
    revokeCreator,
    resetRegFailure,
    resetLoginFailure,
    resetFailedchangeUserPassword,
    clearWebLoginInfo,
    bulkupload,
    resetBulkUpload,
    sendOTP,
    getUploadUsers,
    setupSocialUserProfile,
    enableDisableTourOption,
    addFavourites,
    resetAddFavourites,
    resetAddFavouritesFailed,
    removeFavourites,
    resetRemoveFavourites,
    resetRemoveFavouritesFailed,
    getUserFavourites,
    resetGetUserFavourites,
    resetGetUserFavouritesFailed,
    getFavouritedUser,
    resetGetFavouritedUser,
    resetGetFavouritedUserFailed,
    addBankDetail,
    resetaddbankDetail,
    resetaddbankDetailFailed,
    getBankDetail,
    resetgetbankDetail,
    resetgetbankDetailFailed,
    getuserWalletAccDetail,
    resetgetuserwalletaccdetail,
    resetgetuserwalletaccdetailFailed,
    addPersonalDetail,
    resetaddpersonalDetail,
    resetaddpersonalDetailFailed,
    getPersonalDetail,
    resetgetPersonalDetail,
    resetgetPersonalDetailFailed,
    getSelfAirDropArt,
    resetGetSelfAirdropArts,
    resetGetSelfAirdropArtsFailed,
    changeSelfPassword,
    resetChangeSelfPassword,
    resetChangeSelfPasswordFailed
}

function showLoader() {
    PubSub.publish('msg', true);
}

function hideLoader() {
    PubSub.publish('msg', false);
}

function login(obj) {
    return dispatch => {
        // showLoader()
        userServices.login(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    let userDetail = JSON.stringify(user)
                    localStorage.setItem("userDetail", userDetail)
                    dispatch({ type: userConstants.LOGIN_SUCCESS, user, isWebLoggedIn: true })
                    // hideLoader()
                }
            }, error => {
                // hideLoader()
                // console.log("---Login Error---",error)
            })
    }
}
function logout() {

    userServices.logout().then(res => {
        clearWebLoginInfo();
        return { type: userConstants.LOGOUT }
    })
}

function register(obj) {
    return dispatch => {
        showLoader()
        userServices.register(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    // toast.success("Register Successfully...!")
                    dispatch({ type: userConstants.REGISTER_USER, regRes: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: userConstants.REGISTER_FAILED, failedReg: error })
                // toast.error(error)
                // console.log("register user error ",error)
            })
    }
}

function resetRegister() {
    return dispatch => {
        dispatch({ type: userConstants.REGISTER_USER, regRes: [] })

    }
}
function resetRegFailure() {
    return dispatch => {
        dispatch({ type: userConstants.REGISTER_FAILED, failedReg: '' })
    }
}

function webLogin(obj) {


    return dispatch => {
        showLoader()
        userServices.webLogin(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {

                    // toast.success("Login Successfully...!",{ transition: Zoom})
                    let userDetail = JSON.stringify(user)
                    localStorage.setItem("webLogin", userDetail)
                    dispatch({ type: userConstants.WEBLOGIN, webLogRes: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                dispatch({ type: userConstants.LOGIN_FAILED, failedRes: error })
                // toast.error(error)
                // console.log("weblogin user error ",error)
            })
    }
}

function resetWebLogin() {
    return dispatch => {
        dispatch({ type: userConstants.WEBLOGIN, webLogRes: [] })
    }
}

function resetLoginFailure() {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_FAILED, failedRes: "" })
    }
}

function statusApprove(obj) {
    return dispatch => {
        showLoader()
        userServices.statusApprove(obj)
            .then(user => {
                // if((user && user.statusCode) == 200){
                // toast.success("User approved as creator")
                dispatch({ type: userConstants.APPROVE_STATUS, approveStatus: user })
                hideLoader()
                // }
            }, error => {
                hideLoader()
                toast.error(error)

            })
    }
}

function modifyStatus(obj, userId, status) {
    return dispatch => {
        showLoader()
        userServices.modifyStatus(obj, userId, status)
            .then(user => {
                // if((user && user.statusCode) == 200){
                toast.success("Status Updated")
                dispatch({ type: userConstants.MODIFY_STATUS, modifiedStatus: user })
                hideLoader()
                // }
            }, error => {
                toast.error(error)
                hideLoader()

            })
    }
}

function raiseRequest() {
    return dispatch => {
        showLoader()
        userServices.raiseRequest()
            .then(user => {
                dispatch({ type: userConstants.RAISE_CREATOR_REQUEST, creatorRes: user, raiseReq: true })
                hideLoader()
                // }
            }, error => {
                toast.error(error)
                hideLoader()

            })
    }
}

function verify(obj, userId) {
    return dispatch => {
        showLoader()
        userServices.verify(obj, userId)
            .then(user => {
                dispatch({ type: userConstants.VERIFY_EMAIL, verifyRes: user })
                hideLoader()

            }, error => {
                hideLoader()
                toast.error(error)
                dispatch({ type: userConstants.ALREADY_VERIFY, verifyRes: error })
                // console.log("verify email error",error)
            })
    }
}
function adminLogin(obj) {
    return dispatch => {
        showLoader()
        userServices.adminLogin(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    localStorage.setItem("adminDetail", JSON.stringify(user))
                    dispatch({ type: userConstants.ADMIN_LOGIN_SUCCESS, user, isAdmin: true })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                toast.error(error)
                // console.log("----admin Error----",error)
            })
    }
}

function adminLogout() {
    localStorage.removeItem('adminDetail')
    history.push('/admin/login')
    return { type: userConstants.ADMIN_LOGIN_SUCCESS, isAdmin: false, user: {} }

}
function getUsers() {
    return dispatch => {
        // showLoader()
        userServices.getUsers()
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.GET_USERS, usersList: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function resetGetUsers() {
    return dispatch => {
        dispatch({ type: userConstants.GET_USERS, usersList: [] })
    }
}
function updateProfile(obj) {
    return dispatch => {
        showLoader()
        userServices.updateProfile(obj)
            .then(user => {
                // if((user && user.statusCode) == 204){
                // toast.success("Profile Updated Successfully!",{position:toast.POSITION.BOTTOM_CENTER})
                dispatch({ type: userConstants.USER_PROFILE_SUCCESS, isUpdate: true })
                hideLoader()
                // }
            }, error => {
                hideLoader()
                // toast.error(error,{position:toast.POSITION.BOTTOM_CENTER})
                // console.log("----profile error----",error)
            })
    }
}
function getUSD() {
    return dispatch => {
        userServices.getUSD()
            .then(usd => {
                if (usd) {
                    dispatch({ type: userConstants.GET_USD, usd: usd })
                }
            }, error => {
                // console.log("usd",error)
            })
    }
}
function getAllUsers(obj) {
    return dispatch => {
        showLoader()
        adminServices.getAllUsers(obj)
            .then(user => {
                if (user) {
                    user.request = obj
                    dispatch({ type: userConstants.GET_ALL_USERS, allUsers: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("user error",error)
            })
    }
}

function resetAllUsers() {
    return dispatch => {
        dispatch({ type: userConstants.GET_ALL_USERS, allUsers: '' })
    }
}

function getAllCreators(obj) {
    return dispatch => {
        showLoader()
        adminServices.getAllCreators(obj)
            .then(user => {
                if (user) {
                    dispatch({ type: userConstants.GET_ALL_CREATORS, allCreators: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("user error",error)
            })
    }
}


function getCreatorApplications(obj) {
    return dispatch => {
        showLoader()
        adminServices.getCreatorApplications(obj)
            .then(user => {
                if (user) {
                    dispatch({ type: userConstants.GET_ALL_CREATOR_APPLICATIONS, allCreatorApplications: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
                // console.log("user error",error)
            })
    }
}
function resetPassword(email) {
    return dispatch => {
        showLoader()
        adminServices.resetPassword(email)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.RESET_PASSWORD, resetpassword: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function changePassword(adminId) {
    return dispatch => {
        showLoader()
        adminServices.changePassword(adminId)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.CHANGE_PASSWORD, changepassword: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function forgotPassword(email) {
    return dispatch => {
        showLoader()
        userServices.forgotPassword(email)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.FORGOT_PASSWORD_USER, forgotPasswordUser: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function changeUserPassword(obj, userId) {
    return dispatch => {
        showLoader()
        userServices.changeUserPassword(obj, userId)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.CHANGE_USER_PASSWORD, changeuser: user })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.CHANGE_PASSWORD_FAILED, failedchangeuser: error })
                hideLoader()
                hideLoader()
            })
    }
}
function resetchangeUserPassword() {
    return dispatch => {
        dispatch({ type: userConstants.CHANGE_USER_PASSWORD, changeuser: '' })
    }
}
function resetFailedchangeUserPassword() {
    return dispatch => {
        dispatch({ type: userConstants.CHANGE_PASSWORD_FAILED, failedchangeuser: '' })
    }
}
function refresh(obj) {
    return dispatch => {
        userServices.refresh(obj)
            .then(user => { })
    }
}
function googleLogin(obj) {
    return dispatch => {
        showLoader()
        userServices.googleLogin(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    let userDetail = JSON.stringify(user)
                    localStorage.setItem("webLogin", userDetail)
                    dispatch({ type: userConstants.WEBLOGIN, webLogRes: user })
                } 
                
                hideLoader()
            }, error => {
                hideLoader()
                dispatch({ type: userConstants.LOGIN_FAILED, failedRes: error })
                // console.log("weblogin user error ",error)
            })
    }
}
function revokeCreator(userId) {
    return dispatch => {
        showLoader()
        adminServices.revokeCreator(userId)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.REVOKE_CREATOR, revokeCreator: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function dashboard() {
    return dispatch => {
        showLoader()
        adminServices.dashboard()
            .then(dash => {
                // console.log(dash)
                dispatch({ type: userConstants.DASHBOARD, dashboard: dash })
                hideLoader()
                // }
            }, error => {
                toast.error(error)
                hideLoader()

            })
    }
}
function bulkupload(obj) {
    return dispatch => {
        showLoader()
        adminServices.bulkupload(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    toast.success("File Uploaded Successfully")
                    history.push('/admin/users')
                    dispatch({ type: userConstants.BULK_UPLOAD, bulkUser: user })
                    hideLoader()
                }
            }, error => {
                toast.error(error)
                hideLoader()
            })
    }
}
function resetBulkUpload() {
    return dispatch => {
        dispatch({ type: userConstants.BULK_UPLOAD, bulkUser: [] })
    }
}
function sendOTP(email) {
    return dispatch => {
        showLoader()
        adminServices.sendOTP(email)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    toast.success(user && user.data && user.data.message)
                    dispatch({ type: userConstants.SEND_OTP, otp: user })
                    hideLoader()
                }
            }, error => {
                toast.error(error)
                hideLoader()
            })
    }
}
function getUploadUsers(obj) {
    return dispatch => {
        showLoader()
        adminServices.getUploadUsers(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.GET_UPLOAD_USERS, uploadedUsers: user })
                    hideLoader()
                }
            }, error => {
                hideLoader()
            })
    }
}
function clearWebLoginInfo() {
    localStorage.removeItem("userDetail");
    localStorage.removeItem("webLogin");
    localStorage.removeItem('walletDetail');
    sessionStorage.removeItem('CURRENT_USER');
    fcl.unauthenticate()
    
    if((window && window.location && window.location.pathname) === (process.env.REACT_APP_HOME_PAGE)){
        window.location.reload()
    }else{
        history.push('/');
    }
}
function setupSocialUserProfile(userName, token) {
    return dispatch => {
        showLoader()
        userServices.setupSocialUserProfile(userName,token)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    // toast.success("Login Successfully...!")
                    let userDetail = JSON.stringify(user)
                    localStorage.setItem("webLogin", userDetail)
                    dispatch({ type: userConstants.WEBLOGIN, webLogRes: user })
                }
                hideLoader()
            }, error => {
                hideLoader()
                // toast.error(error)
                dispatch({ type: userConstants.LOGIN_FAILED, failedRes: error })
                console.log("weblogin user error ",error)
            })
    }
}
function enableDisableTourOption(){
    return dispatch => {
        const tourEnable = localStorage.getItem('enable_tour')
        const tourEnableDetail = JSON.parse(tourEnable)
        if(tourEnableDetail !== null){
            dispatch({ type : userConstants.TOUR_OPTION, isTourEnabled : tourEnableDetail})
        }else{
            dispatch({ type : userConstants.TOUR_OPTION, isTourEnabled : false})
        }
    }
}
function addFavourites(obj){
    return dispatch => {
        showLoader()
        userServices.addFavourites(obj)
            .then(art => {
                if ((art && art.statusCode) === 200) {
                    dispatch({ type: userConstants.ADD_FAVOURITES, addedFav : art })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.ADD_FAVOURITES_FAILED, addFavFailed : error})
                hideLoader()
            })
    }
}
function resetAddFavourites(){
    return dispatch => {
        dispatch({type : userConstants.ADD_FAVOURITES,addedFav : []})
    }
}
function resetAddFavouritesFailed(){
    return dispatch => {
        dispatch({type : userConstants.ADD_FAVOURITES_FAILED,addFavFailed : ''})
    }
}
function removeFavourites(obj){
    return dispatch => {
        showLoader()
        userServices.removeFavourites(obj)
            .then(art => {
                    dispatch({ type: userConstants.REMOVE_FAVOURITES, removeFav : {statusCode : 200} })
                    hideLoader()
                
            }, error => {
                dispatch({ type: userConstants.REMOVE_FAVOURITES_FAILED, removeFavFailed : error})
                hideLoader()
            })
    }
}
function resetRemoveFavourites(){
    return dispatch => {
        dispatch({type : userConstants.REMOVE_FAVOURITES,removeFav : []})
    }
}
function resetRemoveFavouritesFailed(){
    return dispatch => {
        dispatch({type : userConstants.REMOVE_FAVOURITES_FAILED,removeFavFailed : ''})
    }
}
function getUserFavourites(obj){
    return dispatch => {
        showLoader()
        userServices.getUserFavourites(obj)
            .then(art => {
                if ((art && art.statusCode) === 200) {
                    dispatch({ type: userConstants.GET_USER_FAVOURITES, userFavs : art })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.GET_USER_FAVOURITES_FAILED, userFavsFailed : error})
                hideLoader()
            })
    }
}
function resetGetUserFavourites(){
    return dispatch => {
        dispatch({type : userConstants.GET_USER_FAVOURITES,userFavs : []})
    }
}
function resetGetUserFavouritesFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_USER_FAVOURITES_FAILED,userFavsFailed : ''})
    }
}
function getFavouritedUser(id){
    return dispatch => {
        showLoader()
        userServices.getFavouritedUser(id)
            .then(art => {
                if ((art && art.statusCode) === 200) {
                    dispatch({ type: userConstants.GET_FAVOURITEDUSER, favrtdUser : art })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.GET_FAVOURITEDUSER_FAILED, favrtdUserFailes : error})
                hideLoader()
            })
    }
}
function resetGetFavouritedUser(){
    return dispatch => {
        dispatch({type : userConstants.GET_FAVOURITEDUSER,favrtdUser : []})
    }
}
function resetGetFavouritedUserFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_FAVOURITEDUSER_FAILED,favrtdUserFailes : ''})
    }
}
function addBankDetail(obj){
    return dispatch => {
        showLoader()
        userServices.addBankDetail(obj)
        .then(user => {
            if ((user && user.statusCode) === 200) {
                dispatch({ type: userConstants.ADD_BANK_DETAIL, bankDetailAdded : user })
                hideLoader()
            }
        }, error => {
            dispatch({ type: userConstants.ADD_BANK_DETAIL_FAILED, addedBankDetailFailed : error})
            hideLoader()
        })
    }
}
function resetaddbankDetail(){
    return dispatch => {
        dispatch({type : userConstants.ADD_BANK_DETAIL,bankDetailAdded : []})
    }
}
function resetaddbankDetailFailed(){
    return dispatch => {
        dispatch({type : userConstants.ADD_BANK_DETAIL_FAILED,addedBankDetailFailed : ''})
    }
}
function getBankDetail(){
    return dispatch => {
        showLoader()
        userServices.getBankDetail()
        .then(user => {
            if ((user && user.statusCode) === 200) {
                dispatch({ type: userConstants.GET_BANK_DETAIL, gotBankDetail : user })
                hideLoader()
            }
        }, error => {
            dispatch({ type: userConstants.GET_BANK_DETAIL_FAILED, getBankDetailFailed : error})
            hideLoader()
        })
    }
}
function resetgetbankDetail(){
    return dispatch => {
        dispatch({type : userConstants.GET_BANK_DETAIL,gotBankDetail : []})
    }
}
function resetgetbankDetailFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_BANK_DETAIL_FAILED,getBankDetailFailed : ''})
    }
}
function getuserWalletAccDetail(){
    return dispatch => {
        showLoader()
        userServices.getuserWalletAccDetail()
        .then(user => {
            if ((user && user.statusCode) === 200) {
                dispatch({ type: userConstants.GET_USERS_WALLET_DETAIL, userwallets : user })
                hideLoader()
            }
        }, error => {
            dispatch({ type: userConstants.GET_USERS_WALLET_DETAIL_FAILED, userwalletsFailed : error})
            hideLoader()
        })
    }
}
function resetgetuserwalletaccdetail(){
    return dispatch => {
        dispatch({type : userConstants.GET_USERS_WALLET_DETAIL,userwallets : []})
    }
}
function resetgetuserwalletaccdetailFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_USERS_WALLET_DETAIL_FAILED,userwalletsFailed : ''})
    }
}
function addPersonalDetail(obj){
    return dispatch => {
        showLoader()
        userServices.addPersonalDetail(obj)
        .then(user => {
            if ((user && user.statusCode) === 200) {
                dispatch({ type: userConstants.ADD_PERSONAL_DETAIL, personalDetailAdded : user })
                hideLoader()
            }
        }, error => {
            dispatch({ type: userConstants.ADD_PERSONAL_DETAIL_FAILED, addPersonalDetailFailed : error})
            hideLoader()
        })
    }
}
function resetaddpersonalDetail(){
    return dispatch => {
        dispatch({type : userConstants.ADD_PERSONAL_DETAIL,personalDetailAdded : []})
    }
}
function resetaddpersonalDetailFailed(){
    return dispatch => {
        dispatch({type : userConstants.ADD_PERSONAL_DETAIL_FAILED,addPersonalDetailFailed : ''})
    }
}
function getPersonalDetail(){
    return dispatch => {
        showLoader()
        userServices.getPersonalDetail()
        .then(user => {
            if ((user && user.statusCode) === 200) {
                dispatch({ type: userConstants.GET_PERSONAL_DETAIL, gotPersonalDetail : user })
                hideLoader()
            }
        }, error => {
            dispatch({ type: userConstants.GET_PERSONAL_DETAIL_FAILED, getPersonalDetailFailed : error})
            hideLoader()
        })
    }
}
function resetgetPersonalDetail(){
    return dispatch => {
        dispatch({type : userConstants.GET_PERSONAL_DETAIL,gotPersonalDetail : []})
    }
}
function resetgetPersonalDetailFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_PERSONAL_DETAIL_FAILED,getPersonalDetailFailed : ''})
    }
}

function getSelfAirDropArt(obj){
    return dispatch => {
        // showLoader()
        userServices.getSelfAirDropArt(obj)
            .then(art => {
                if ((art && art.statusCode) === 200) {
                    dispatch({ type: userConstants.GET_SELF_AIRDROP_ARTS, selfAirdrops : art, isCheckSelfAirdrop : true })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.GET_SELF_AIRDROP_ARTS_FAILED, selfAirdropsFailed : error, isCheckSelfAirdrop : true})
                hideLoader()
            })
    }
}
function resetGetSelfAirdropArts(){
    return dispatch => {
        dispatch({type : userConstants.GET_SELF_AIRDROP_ARTS, selfAirdrops  : [], isCheckSelfAirdrop : false})
    }
}
function resetGetSelfAirdropArtsFailed(){
    return dispatch => {
        dispatch({type : userConstants.GET_SELF_AIRDROP_ARTS_FAILED,selfAirdropsFailed : '', isCheckSelfAirdrop : false})
    }
}

function changeSelfPassword(obj) {
    return dispatch => {
        showLoader()
        userServices.changeSelfPassword(obj)
            .then(user => {
                if ((user && user.statusCode) === 200) {
                    dispatch({ type: userConstants.CHANGE_SELF_PASSWORD, changeSelfPass: user })
                    hideLoader()
                }
            }, error => {
                dispatch({ type: userConstants.CHANGE_SELF_PASSWORD_FAILED, changeSelfPassFailed: error })
                hideLoader()
                hideLoader()
            })
    }
}
function resetChangeSelfPassword() {
    return dispatch => {
        dispatch({ type: userConstants.CHANGE_SELF_PASSWORD, changeSelfPass: '' })
    }
}
function resetChangeSelfPasswordFailed() {
    return dispatch => {
        dispatch({ type: userConstants.CHANGE_SELF_PASSWORD_FAILED, changeSelfPassFailed: '' })
    }
}