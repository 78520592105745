import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { userServices } from 'services';
import { userAction } from 'store/actions';
import PubSub from 'pubsub-js';
import { Alert } from "helpers/alert";
import { Helmet } from 'react-helmet';
var timeout;
class Verification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verified: false,
            verificationFailed: false,
            userId: -1,
            hasVerificationResponseReceived: false,
            message:'',
            isAlert : false
        };

        // Binding `this` to resendVerificationClickHandler
        // so that the context of `this` is not lost inside 
        // the resendVerificationClickHandler function.
        // This happens because event handlers are called in a
        // different context.
        this.resendVerificationClickHandler = this.resendVerificationClickHandler.bind(this);
    }
    componentDidMount() {

        let queryString = window.location.search;
        // console.log("query",queryString);
        let urlParams = new URLSearchParams(queryString);
        let verificationCode = urlParams.get('verificationCode')
        // console.log("verificationCode",verificationCode);
        let userId = this.props && this.props.match && this.props.match.params && this.props.match.params.userId && this.props.match.params.userId
        this.setState({
            userId: parseInt(userId)
        });
        if (verificationCode) {
            let obj = {
                verificationCode
            }
            this.props.verify(JSON.stringify(obj), userId)
        }
    }

    static getDerivedStateFromProps(props, state) {
        // console.log("verification props", props.verifyRes)

        if ((props && props.verifyRes && props.verifyRes.statusCode) === 200) {

            // Email verified successfully.
            return {
                verified: true,
                verificationFailed: false,
                hasVerificationResponseReceived: true
            }
        } else if ((props && props.verifyRes && props.verifyRes.statusCode) === 400) {
            if (
                (props.verifyRes.data &&
                    props.verifyRes.data &&
                    props.verifyRes.data.errorCode)
                === 'EMAIL_VERIFICATION_FAILED'
            ) {
                // Email verification failed.
                return {
                    verified: false,
                    verificationFailed: true,
                    hasVerificationResponseReceived: true
                }
            }

            // Email has already been verified
            return {
                verified: false,
                verificationFailed: false,
                hasVerificationResponseReceived: true
            }
        }

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
              
            this.setState({isAlert:false,message:'',style:""})
        },15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }

    resendVerificationClickHandler() {
        if (this.state.userId !== -1) {
            this.showLoader()
            userServices.resendVerificationEmail(this.state.userId)
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({isAlert:true,message:'An email containing the verification link has been sent to your email',style:"success"})
                        this.closeAlert()
                        // toast.success('An email containing the verification link has been sent to your email');
                    }
                    this.hideLoader()
                })
                .catch((err) => {
                    // console.error(err);
                    this.setState({isAlert:true,message:'Unable to send an email containing the verification link',style:"danger"})
                    this.closeAlert()
                    // toast.error('Unable to send an email containing the verification link');
                    this.hideLoader()
                })
        }
    }

    render() {
        if (this.state.hasVerificationResponseReceived === false) {
            this.showLoader();
            return (
                <Fragment>   
                     <Helmet>
                    <title>Verification Email</title>
                    <meta name="title" content="Verification Email"></meta>
                    <meta name="description" content={`Verification Email`}></meta>
                </Helmet>               
                    <div className="container detailWallet">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="dotsloading">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h3 className="mt-3">Please wait while your email is being verified.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
        this.hideLoader()
        return (
            <Fragment>
                <Helmet>
                    <title>Verification Email</title>
                </Helmet>
                {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert}/>}

                {(this.state.verified && !this.state.verificationFailed) &&
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">Your Email has been verified successfully! </h3>
                                    <h4 className="mt-3 usernamelilac">Welcome To The DisruptArt</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                }
                {(!this.state.verified && !this.state.verificationFailed) &&
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">Your email has been already verified </h3>
                                </div>
                            </div>
                        </div>
                    </div>}
                {(!this.state.verified && this.state.verificationFailed) && 
                 <div className="container">
                 <div className="card py-5 my-5">
                     <div className="card-body">
                         <div className="text-center">
                             <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                 <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                 <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                             </svg>
                             <h3 className="mt-3">Failed to verify your email. The link is invalid.</h3>

                             <p className="mt-3">
                                 Please click the button below to resend the verification link to your email.
                             </p>
                             <button className="btn btn-primary" onClick={this.resendVerificationClickHandler}>Resend verification link</button>

                         </div>

                     </div>
                 </div>
             </div>
                
                }
            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        verifyRes: state.user.verifyRes
    }
}

const actionCreators = {
    verify: userAction.verify
}

const connectVerification = connect(mapStateToProps, actionCreators)(Verification)

export { connectVerification as Verification }