import { reportConstants } from "../../constants"
import { reportServices } from "services"
import PubSub from 'pubsub-js';

export const reportActions = {
    
    addReport,
    resetAddReportFailed,
    resetAddReportSuccess
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}


function addReport(obj){
    return dispatch => {
        showLoader()
        reportServices.addReport(obj)
        .then(res => {
            if(res){
                dispatch({type:reportConstants.ADD_ABUSE_REPORT,addedReport:res})
                hideLoader()
            }
        },error => {
            dispatch({type : reportConstants.ADD_ABUSE_REPORT_FAILED,addReportFailed : error})
            hideLoader()
        })
    }
}
function resetAddReportSuccess(){
    return dispatch => {
        dispatch({type:reportConstants.ADD_ABUSE_REPORT,addedReport:[]})
    }
}
function resetAddReportFailed(){
    return dispatch => {
        dispatch({type : reportConstants.ADD_ABUSE_REPORT_FAILED,addReportFailed : ''})
    }
}


