import React from "react";
import PubSub from 'pubsub-js';
import { sendwyreService } from "services/sendwyre.service";
import { getUserAddress } from "utils";
import { Fragment } from "react";

export class SendWyreCheckout extends React.Component {
    constructor(props) {
        super(props);
        this.widget = null;
        this.onPaymentSuccess = this.onPaymentSuccess.bind(this);

    }

    onPaymentSuccess(e) {
        if (this.props.onsuccess) {
            this.props.onsuccess(e);
        }
    }

    open() {
        PubSub.publish('msg', true);
        this.getWalletOrderReservation()
            .then((reservationId) => {
                PubSub.publish('msg', false);
                const env = process.env.REACT_APP_SENDWYRE_ENVRIONMENT;
                // this.props.getHost(reservationId)
                // @ts-ignore
                this.widget = new window.Wyre({
                    env,
                    reservation: reservationId,
                    /*A reservation id is mandatory. In order for the widget to open properly you must use a new, unexpired reservation id. Reservation ids are only valid for 1 hour. A new reservation must also be made if a transaction fails.*/
                    operation: {
                        type: 'debitcard-hosted-dialog'
                    }
                });
                this.widget.on("paymentSuccess", this.onPaymentSuccess);
                this.widget.open();
            }).catch((e) => {
                PubSub.publish('msg', false);
                if (this.props.onerror) {
                    const err = e.message ? e.message : String(e);
                    this.props.onerror(err);
                }
                console.error(e);
            });
    }

    async getWalletOrderReservation() {
        const walletAddress = await getUserAddress();
        const user = localStorage.getItem("webLogin");
        const userDetail = JSON.parse(user);
        if (
            !!walletAddress &&
            !!userDetail &&
            !!userDetail.data &&
            !!userDetail.data.authUser
        ) {
            try {
                const reservation = await sendwyreService.getWalletOrderReservation(
                    walletAddress,
                    userDetail.data.authUser.email,
                    userDetail.data.authUser.fullName,
                );
                return reservation.data.reservationId;
            } catch (e) {
                throw e;
            }
        }
        if (!walletAddress) {
            throw new Error('Wallet not connected');
        }
        throw new Error('User is not logged in');
    }

    componentDidMount() {
        this.$el = (this.el);
    }

    render() {

        return (
        <div id="wyre-dropin-widget-container" ref={el => this.el = el} />

        )
    }
}