import 'core-js';
import 'abort-controller/polyfill';
import "./config" 
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import {store} from './store'
import { ToastContainer } from "react-toastify";
 
ReactDOM.render(
  <Provider store = {store}>
    {/* <ToastContainer autoClose={3000} style={{ width: "95%",textAlign:"center"}}/> */}
    <ToastContainer autoClose={3000}/>

    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
