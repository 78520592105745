import { eventConstants } from "../../constants";

const initialState = {
    eventHistory : []
}
export function events(state=initialState,action){
    switch(action.type){
        case eventConstants.GET_EVENT_HISTORY:
            return {
                eventHistory : action.eventHistory
            }
     
            default:
                return state
    }
}