import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, followActions, userAction, walletActions } from 'store/actions';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { airDropService, followServices, userServices } from 'services';
import { configPath } from '../../config'
import { CommonDisableError } from '../common_disable_error';
import { SwitchAccount } from 'components/switchaccountPopup';
import PubSub from 'pubsub-js';
import { RetrieveTokens, createSetUp, getUserAddress, roleEnum } from 'utils';
import CommonError from 'components/Common-error-ui/commonError';

var timeout;
let collectionTimeout;
class WelcomeUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitted: false,
            isSuccess: false,
            isAlert: false,
            message: '',
            style: '',
            isVerified: false,
            code: '',
            isError: false,
            isCalled: false,
            verifiedMes: '',
            unregEmail: '',
            isSwitchAcc: false,
            currentUserDet: {},
            isFollower: true,
            tokenId: '',
            token: '',
            isSuccessClaim: false,
            isAirdropClaimMessage: '',
            isArtDisabled: false,
            disabledMessage: ''
        }
        this.closedMessage = <p>Oops! Process closed without completing it.</p>
    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({ isSuccess: false })


        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let token = (urlParams.get('token'))
        let code = this.props && this.props.match && this.props.match.params && this.props.match.params.uuid && this.props.match.params.uuid

        if (code) {
            this.setState({ code, token })
            this.props.registerAirdropUser(code, token)
        } else {
            this.setState({
                isAlert: true,
                message: 'Invalid url',
                style: "danger"
            })
        }
        console.log("uuid,token",code,token)

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            console.log(props.failedReg)
            if ((props && props.failedReg && props.failedReg.length > 0)) {
                props.resetregUserFailed()

                if (props.failedReg && (props.failedReg.toLowerCase().includes('logintoken must be a uuid'))) {
                    return {
                        isVerified: true,
                        isError: true,
                        verifiedMes: 'Oops! Invalid Link'
                    }
                } else if (props.failedReg && (props.failedReg.toLowerCase().includes('account has been disabled'))) {
                    return {
                        isVerified: false,
                        isError: true,
                        verifiedMes: props.failedReg
                    }
                } else if (props.failedReg && (props.failedReg.toLowerCase().includes('token is already used'))) {
                    return {
                        isVerified: true,
                        isError: true,
                        verifiedMes: `Sorry, Link you're trying to access has already been used`
                    }
                }
                else {
                    return {

                        isVerified: true,
                        isError: true,
                        verifiedMes: props && props.failedReg
                    }
                }


            }

        }
    }
    componentDidUpdate(props, state) {

        if ((props && props.failedVerify)) {
            props.resetVerifyUserFailed()
            this.gotoTop()
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
            this.gotoTop()

        }

       console.log(this.props.reguser)
        if (this.props && this.props.reguser && this.props.reguser.statusCode === 200 && (this.props.reguser !== props.reguser)) {
            if (this.props && this.props.reguser && this.props.reguser.data && this.props.reguser.data.authResult) {

                this.props.resetRegAirdrop()
                this.props.getUserProfile()
                
                this.setState({
                    submitted: false,
                    isSuccess: true,
                    isAlert: false,
                    message: '',
                    style: '',
                    code: '',
                    isError: false,
                    isVerified: true
                })
                this.gotoCreator(this.props.reguser.data.authResult.authUser)

            }


        }

    }
    redirectToAirDrops = () => {
        localStorage.setItem('page', "0")
        history.push('/wallet',{GoToTab : 2})
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }


    gotoLogin = () => {
        history.push('/login')
    }
    gotoMycollection = () => {
        history.push('/wallet')
    }
    gotoMyProfile = () => {
        history.push('/profile')
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === (roleEnum.CREATOR)) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`)
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === (roleEnum.COLLECTOR)) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`)
        }
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false,

        })

    }
    handleErrorModal = () => {
        this.setState({
            isVerified: true,
            isError: true,
            verifiedMes: <p>Oops! You closed the step without completing it.</p>
        })
    }
    switchWalletConnected = (wallet) => {
        if (wallet) {
            this.setState({
                isSwitchAcc: false,
                isError: false
            })
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ isForAirdropPage: true, path: '' })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isVerified: true,
            isError: true,
            verifiedMes: <p>Oops! You closed the step without completing it.</p>

        })

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

   
    closeModal = () => {
        this.setState({
            isFollower: true
        })
    }
    handleFollow = () => {
        this.props.goFollow(this.state.userId)

    }
    resetUser = () => {
        this.setState({ isFollower: true, clickedUser: '' })

    }
    componentWillUnmount(){
        clearTimeout(collectionTimeout)
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        const { currentUserDet } = this.state
        return (
            <Fragment>
                <Helmet>
                    <title>Welcome Guest</title>
                    <meta name="title" content="Welcome Guest"></meta>
                    <meta name="description" content={`Create a new account on ${process.env.REACT_APP_MARKETPLACE}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}

               
                {this.state.isAirDrop ?
                    <div className="show-content">
                        <div className="child-show purchasebox">

                            <p className="text-white">{this.state.isAirdropClaimMessage}</p>

                            <img src={`../../${configPath.imageAssets}/post-transaction-loader.gif`} style={{ width: "25%", display: "block", marginLeft: "auto", marginRight: "auto" }} alt="" />
                        </div>
                    </div> : ''
                }
                {(!(this.state.isVerified) && (this.state.isError)) ?
                    (this.state.verifiedMes && this.state.verifiedMes.includes('disable')) ?
                        <CommonDisableError /> :
                        <div className="container detailWallet">
                            <div className="card py-5 my-5">
                                <div className="card-body">
                                    <div className="text-center">

                                        <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                            <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                        </svg>
                                        <h3 className="mt-3">Something went wrong, Please try again later </h3>
                                    </div>
                                </div>
                            </div>
                        </div> :
                    ''}
                {!(this.state.isVerified) && !(this.state.isError) && <div className="container detailWallet">
                    <div className="card py-5 my-5">
                        <div className="card-body">
                            <div className="text-center">
                                <h3 className="mt-3">Please wait</h3>

                                <div className="dotsloading">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
               
                {(this.state.isVerified && this.state.isError) ? 
                (this.state.isArtDisabled) ? <CommonError disabledMessage={this.state.disabledMessage}/> : 
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <svg className="crosscus" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                        <line className="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                    </svg>
                                    <h3 className="mt-3">{this.state.verifiedMes} </h3>
                                    <div className="form-group text-center mt-4">
                                        {isWebLogin() ?  <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.gotoMycollection() }}>Go to collections</button> :
                                            <button type="button" className="btn btn-primary px-5" onClick={this.gotoLogin}>Go to Login</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}

               
                {this.state.isSwitchAcc ?
                    <SwitchAccount
                        closeModal={this.closeSwitchAccModal}
                        switchWalletConnected={(val) => this.switchWalletConnected(val)}
                        handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}
                        errorModal={this.handleErrorModal} />
                    : ''}
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        reguser: state.airdrop.welcomeUser,
        failedReg: state.airdrop.welcomeUserFailed,
        userFollow: state.follow.userFollow,
        wallet: state.wallet.wallet,
        failedFollow: state.follow.failedFollow,
    }
}

const actionCreators = {
    registerAirdropUser: airdropActions.autoLoginWelcomeUser,
    resetRegAirdrop: airdropActions.resetAuoLoginWelcomeUser,
    resetregUserFailed: airdropActions.resetAuoLoginWelcomeUserFailed,
    getUserProfile: userAction.getUsers,
    goFollow: followActions.follow,
    resetFollow: followActions.resetFollow,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    resetFailedFollow: followActions.resetFailedFollow,



}

const connectWelcomeUser = connect(mapStateToProps, actionCreators)(WelcomeUser)

export { connectWelcomeUser as WelcomeUser }