import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import { adminServices } from 'services';
import {adminActions, userAction} from '../../../store/actions'
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import Toggle from 'react-toggle';
import { isSuperAdmin, ReactPagination } from 'helpers';
import { toast } from 'react-toastify';
import { CommonDeniedError } from '../common_error';

class AdminAccounts extends Component{
    constructor(props){
        super(props);
        this.state = {
            fileData : [],
            imageName : '',
            totaluser : '',
            existsValue : '',
            insertuser : '',
            emptyValue : [],
            uploadRes : [],
            accounts :[],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 20,
            pageCount: '',
            isAccessGrant: true
        }
    }
    componentDidMount(){
        if(isSuperAdmin()){
            this.callApi()
        }else{
            this.setState({isAccessGrant : false})
        }
    }
 
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props,state){
            if (props && props.adminsList && props.adminsList.statusCode === 200) {
                props.resetGetAllAdmin()
                // if(props.allUsers)
                    return {
                        accounts: props.adminsList && props.adminsList.data && props.adminsList.data.admin && props.adminsList.data.admin.entity ? props.adminsList.data.admin.entity : [],
                        totalCount: props.adminsList && props.adminsList.data && props.adminsList.data.admin && props.adminsList.data.admin.count,
                        pageCount: props.adminsList && props.adminsList.data && props.adminsList.data.admin && props.adminsList.data.admin.count / state.recordLimit,
                        isFetched: true,
                    }
            }
            return null
    
        

    }
    callApi = () => {
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
       
        if (role) {
            obj['roleId'] = role
            // obj['offSet'] = 0

        }
        if (search) {
            obj['search'] = search
            // obj['offSet'] = 0


        }
        
        this.props.getallAdmin(obj)
    }
    editAccount = (items) => {
        if((items && items.publicId)){
            history.push(`/admin/edit/${items && items.publicId}/account`,{obj:{items}})
        }
    }
    deleteAccount = (items) => {
        const {accounts} = this.state
        const filterArray = accounts && accounts.filter( el => (el && el.id) != (items && items.id))
        this.setState({accounts : filterArray})
    }
    statusChange = (items) => {
        if(items && items.publicId){
            if(items.accountStatus){
                this.props.disableAccount(items.publicId)
            }else{
                this.props.enableAccount(items.publicId)
            }
        }else{
            toast.error("Unable to update, public id was missing")
        }
        
    }
    emailStatusChange = (items) => {}
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    resetPassword = (items) => {
        if((items && items.email)){
            this.props.resetPassword(items.email)
        }
    }
    componentDidUpdate(props,state){
        if(this.props && 
            this.props.accountEnabled &&
            this.props.accountEnabled.statusCode === 200 &&
            (this.props.accountEnabled !== props.accountEnabled)){
                this.props.resetEnableAccount()
                toast.success("Account enabled successfully")
                this.callApi()
            }

            if(this.props && 
                this.props.accountDisabled &&
                this.props.accountDisabled.statusCode === 200 &&
                (this.props.accountDisabled !== props.accountDisabled)){
                    this.props.resetDisableAccount()
                    toast.success("Account disabled successfully")
                    this.callApi()
                }
            if(this.props && this.props.resetResponse && this.props.resetResponse.statusCode === 200 && 
                (this.props.resetResponse !== props.resetResponse)){
                    this.props.resetResetPassword()
                    toast.success("Change password link has been sent to email")
                }
    }
    render(){
        const {accounts} = this.state
        const AccountList = accounts && accounts.map ((items) => {
            return  <tbody>
                <tr>
                    <td><a >{items && items.displayName}</a> </td>
                    <td><a >{items && items.email}</a></td>
                    <td><a className={'card flowtop'} style={{display:"inline-block"}}>

                        <Toggle checked={items && items.accountStatus} onChange={() => this.statusChange(items)} disabled={(items && items.roleId === 3)}/>
                        {<span className="copycon">
                    <span className="uparrow"></span>
                    {items && items.accountStatus ? ("Disable Admin") : ("Enable Admin")}
                </span>}
                        </a>
                  </td>
                    {/* <td><span className={'card flowtop'} style={{display:"inline-block"}}>
                        <Toggle checked={items && items.emailVerified} onChange={() => this.emailStatusChange(items)} disabled={items && items.emailVerified == 1}/>
                        {!(items && items.emailVerified) && <span className="copycon">
                    <span className="uparrow"></span>
                    {"Verify email"}
                </span>}
                        </span>
                        </td> */}
                    <td>
                    <a className=" designLink  mr-3" type="button" onClick={() => {this.editAccount(items)}}><i className="fa fa-pencil" aria-hidden="true"></i></a> 
                        {/* <button className='btn btn-primary ml-2 btn-smc mr-3' type='button' onClick={() => {this.resetPassword(items)}}>Reset Password Link</button> */}
                        {/* <a className=" designLink " type="button" onClick={() => {this.deleteAccount(items)}}><i className="fa fa-trash" aria-hidden="true"></i></a> */}
                    </td>
                </tr>
               
                
            </tbody>
            })
        return(
            <Fragment>
                {this.state.isAccessGrant ? <div className="smallilac px-3">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Admin Accounts <span className="betaLink">(Soon)</span></h2>  </div>
                        <div className="col-sm-6 text-right"><button className=" btn btn-primary" type="button" onClick={() => {history.push('/admin/add/accounts')}}>Add Account</button>  </div>
                        
                    </div>
                    <div className="purchasebox p-2"><div className="card"><div className="card-body">
                    <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>FULL NAME</th>
                                        <th>EMAIL</th>
                                        <th>STATUS</th>
                                        {/* <th>EMAIL VERIFIED</th> */}
                                        <th>ACTIONS</th>
                                    </tr>
                                </thead>
                                {AccountList}

                            </table>
                            {this.state.accounts && this.state.accounts.length === 0 && <div className="text-center">No Data Found</div>}
                        </div>
                        </div>

                        </div>

                        </div>
                        {this.state.accounts && this.state.accounts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                    
                </div> : <CommonDeniedError/>}
             
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        adminsList : state.adminaccount.admins,
        accountEnabled : state.adminaccount.enabledAccount,
        accountDisabled : state.adminaccount.disabledAccount,
        resetResponse : state.adminaccount.resetRes,

    }
}
const actionCreator = {
    getallAdmin : adminActions.getAllAdminAccount,
    resetGetAllAdmin : adminActions.resetGetAllAdminAccounts,
    enableAccount : adminActions.enableAdminAccount,
    resetEnableAccount : adminActions.resetEnabledAccount,
    disableAccount : adminActions.disableAdminAccount,
    resetDisableAccount : adminActions.resetDisabledAccount,
    resetPassword : adminActions.adminresetPassword,
    resetResetPassword : adminActions.resetAdminResetPassword,
}
const connectAccounts = connect(mapStateToProps,actionCreator)(AdminAccounts)
export {connectAccounts as AdminAccounts}