export function flowDecimal(value){
    if(value){
        return getFlooredFixed(value,2)
    }else{
        return 0.00
    }
    

}
function getFlooredFixed(v, d) {
    var with2Decimals = v.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    if((with2Decimals)){
       const reminder = with2Decimals % 1
            if(reminder == 0){
                return (v && v.toFixed(2))
            }else{
                return with2Decimals
            }
    }
    // return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}

export function getUSDValue(rate,value){
    if(value && rate){
        return flowDecimal(parseFloat((value) * (rate)))
    }else{
        return ''
    }
}