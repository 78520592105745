import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { adminActions } from 'store/actions';
import moment from 'moment'
import { flowDecimal, ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import { debounce } from 'utils';

class FetchTransactionHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            totalCount: '',
            offSet: 0,
            sortBy: "initiatedDate",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            hideApprove: false,
            remarks: '',
            search: '',
            openModal: false,
            selectedRole: "Select Role",
            saleList: []
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);

    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.history) {
            props.resetHistory()
            return {
                arts: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.history ? props.getSaleDetail.data.history : [],
                totalCount: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.count,
                pageCount: props.getSaleDetail && props.getSaleDetail.data && props.getSaleDetail.data.count / state.recordLimit,

            }
        }

    }
    handleRefresh = () => {
        this.setState({ sortBy: "initiatedDate", search: '', sortOrder: "DESC", offSet: 0, title: '', sDate: '', eDate: '', ownerName: '',forcePg : 0 }, () => {
            this.callApi()
        })
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value,offSet: 0,forcePg : this.state.offSet ? this.state.offSet / this.state.recordLimit : 0
        }, () => { this.debouncedFetch() })
    }
    handleSortBy = (e) => {
        this.setState({ sortBy: e.target.value }, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({ sortOrder: e.target.value }, () => {
            this.callApi()
        })
    }

    callApi = () => {

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if(this.state.search){
            obj['search'] = this.state.search
        }
        this.props.getArtSaleList(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }
    
   

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

   
    
    gotoArt = (items) => {
        const baseurl = process.env.REACT_APP_BASEPATH
        const id = items.id
        const navPath =  (baseurl + `/admin/transactionDetail/${id}`)
        window.open(navPath, '_blank')

    }

    gotoCreator = (owner) => {
        const baseurl = process.env.REACT_APP_BASEPATH

        if (owner && owner.roleId && owner.roleId == 1) {
            const id = `/creator/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        } else if (owner && owner.roleId && owner.roleId == 2) {
            const id = `/collector/@${owner.userName && encodeURIComponent(owner.userName)}`
            const navPath = (baseurl + id)
            window.open(navPath, '_blank')

        }
    }
    render() {
        const { saleList, arts } = this.state
        const salesList = arts && arts.map((items, index) => {
            return <tbody>
                <tr>
                    <td><a className="designLink userdescrip_adm" style={{maxWidth:"150px",display:"inline-block"}} title={items && items.art && items.art.title} onClick={() => this.gotoArt(items)}>{items && items.art && items.art.title}</a><a className={'designLink mr-2 float-right'} onClick={() => this.gotoArt(items)}><i className="fas fa-external-link-alt"></i></a></td>
                    <td><a className="designLink userdescrip_adm" style={{maxWidth:"150px",display:"inline-block"}} title={items && items.initiatedUser && items.initiatedUser.userName} onClick={() => this.gotoCreator(items.initiatedUser)}>{items && items.initiatedUser && items.initiatedUser.userName && `@${items.initiatedUser.userName}`}</a></td>
                    <td>{items && items.initiatedWalletAccount && items.initiatedWalletAccount.walletAddress ? items.initiatedWalletAccount.walletAddress : '-'}</td>
                    <td>{items && items.tokenCount ? items.tokenCount : '-'}</td>
                    <td>{(items && items.listPrice != null) ? (items && items.listPrice && ((flowDecimal(parseFloat(items.listPrice)) + ' USD'))) : '-'}</td>
                    <td>{items && items.initiatedFor ? 'Store' : 'Marketplace'}</td>
                    <td>{items && items.eventStatus ? items.eventStatus : '-'}</td>

                    <td>{moment(items && items.initiatedDate).format(" DD-MM-YYYY hh:mm:ss A")}</td>


                </tr>
               
            </tbody >

        })

        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Listing History</h2>

                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div>
                                            <input type="text" className="form-control" placeholder="Search..." name="search" onChange={this.handleSearch} value={this.state.search} />

                                        </div>
                                    </div>

                                    <div className="col-6 text-right">
                                        <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>ART NAME</th>
                                        <th>SELLER</th>
                                        <th>INITIATOR WALLET</th>
                                        <th>COUNT</th>
                                        <th>PRICE</th>
                                        <th>INITIATED FROM</th>
                                        <th>EVENT STATUS</th>
                                        <th>INITIATE DATE</th>

                                    </tr>
                                </thead>
                                {salesList}

                            </table>
                            {arts && arts.length == 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {arts && arts.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePg }} />}

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        getSaleDetail: state.adminaccount.dropHistory,
    }
}

const actionCreators = {
    getArtSaleList: adminActions.listDropHistory,
    resetHistory: adminActions.resetListDropHistory

}

const connectFetchTransactionHistory = connect(mapStateToProps, actionCreators)(FetchTransactionHistory)

export { connectFetchTransactionHistory as FetchTransactionHistory }