import React from "react";

export class MusicNFTVideoPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isClick : false
        }
        this.videoRef1 = React.createRef();
        this.videoRef2 = React.createRef();

        this.onVideoClickHandler = this.onVideoClickHandler.bind(this);
    }
    componentDidMount() {
        const video1 = this.videoRef1.current;
        const video2 = this.videoRef2.current;
        
        video1.currentTime = this.props.currentTime ? this.props.currentTime : 0
        video2.currentTime = this.props.currentTime ? this.props.currentTime : 0

    }
    componentDidUpdate(props,state){
        const video1 = this.videoRef1.current;
        const video2 = this.videoRef2.current;
        if(!(this.state.isClick)){
            if(!(props.paused)){
                video1.pause();
                video2.pause()
                        
                var playPromise1 = video1.play();
                var playPromise2 = video2.play();

                    if (playPromise1 !== undefined && playPromise2 !== undefined) {
                        playPromise1.then(() => {
                            video1.play();
                        }).catch(err => {
                            video1.pause();
                        })
                        playPromise2.then(() => {
                            video2.play();
                        }).catch(err => {
                            video2.pause();
                        })

                    }else{
                        video1.play();
                        video2.play()
                    }
            }else{
                
                        
               
                        video1.pause();
                        video2.pause()
                   

              

                
            }
        }
        if(this.props.paused !== props.paused){
            this.setState({isClick : false})

        }
    }
    onVideoClickHandler(e) {
        e.preventDefault();
        this.setState({isClick : true})
        const video1 = this.videoRef1.current;
        const video2 = this.videoRef2.current;

        // console.log(`%c video clicked!!`,'font-size:20px')
        if (video1.paused) {
            video1.play();
            video2.play()
        } else {
            video1.pause();
            video2.pause()
        }
        e.stopPropagation();
    }

    render() {
        return (
            <div className='col-md-12'>
                <div className='mtartimg'>
                    <video
                        muted={this.props.mute}
                        loop
                        playsInline
                        controls={this.props.showControls}
                        className={this.props.className}
                        width={this.props.width}
                        ref={this.videoRef1}
                        // onClick={this.onVideoClickHandler}
                        // autoPlay={this.props.play}
                    >
                        <source src={this.props.source} type={this.props.mimeType} />
                        Your browser does not support HTML video.
                    </video>

                </div>
                <div className='mtartimg mtmirrorbg'>
                    <div className='mtartmirror'>
                        <div className='mtartminheight'>
                            <video
                                muted={this.props.mute}
                                loop
                                playsInline
                                controls={this.props.showControls}
                                className={this.props.className}
                                width={this.props.width}
                                ref={this.videoRef2}
                                // onClick={this.onVideoClickHandler}
                                // autoPlay={this.props.play}
                            >
                                <source src={this.props.source} type={this.props.mimeType} />
                                Your browser does not support HTML video.
                            </video>
                        </div>
                    </div>
                </div>

            </div>


        )

    }
}