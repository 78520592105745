import { midwestconConstants } from "../../constants"
import { adminServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const midwestActions = {
    getAllMidwestEvents,
    resetGetAllMidwestEvents,
    addMidwestEvents,
    resetAddedMidwestEvent,
    getMidwestEventDetail,
    resetGetMidwestEventDetail,
    updateMidwestEvent,
    resetUpdatedMidwestEvent,
    addEventGallery,
    resetAddEventGallery,
    getAllEventGallery,
    resetGetAllEventGallery,
    deleteEventGallery,
    resetDeletedEventGallery,
    getEventGalleryDetail,
    resetGetEventGalleryDetail
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getAllMidwestEvents(obj){
    return dispatch => {
        showLoader()
        adminServices.getAllMidwestEvents(obj)
        .then(events => {
            if(events){
                dispatch({type:midwestconConstants.ADMIN_GET_MIDWEST_EVENTS,midwestEvents:events})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetAllMidwestEvents(){
    return dispatch => {
        dispatch({type:midwestconConstants.ADMIN_GET_MIDWEST_EVENTS,midwestEvents:[]})
    }
}
function addMidwestEvents(obj){
    return dispatch => {
        showLoader()
        adminServices.addMidwestEvents(obj)
        .then(events => {
            if(events){
                
                dispatch({type:midwestconConstants.ADMIN_ADD_MIDWEST_EVENTS,addedMidwestEvent:events})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddedMidwestEvent(){
    return dispatch => {
        dispatch({type:midwestconConstants.ADMIN_ADD_MIDWEST_EVENTS,addedMidwestEvent:[]})
    }
}
function getMidwestEventDetail(id){
    return dispatch => {
        showLoader()
        adminServices.getMidwestEventDetail(id)
        .then (events => {
            if((events && events.statusCode) === 200){
                dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_DETAIL,getMidwestEventDetail : events})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetMidwestEventDetail(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_DETAIL,getMidwestEventDetail : []})
    }
}
function updateMidwestEvent(obj){
    return dispatch => {
        showLoader()
        adminServices.updateMidwestEvent(obj)
        .then (events => {
            if(events){
                toast.success("Event updated successfully")
                dispatch({type : midwestconConstants.ADMIN_EDIT_MIDWSET_EVENTS,updatedMidwestEvent : events})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetUpdatedMidwestEvent(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_EDIT_MIDWSET_EVENTS,updatedMidwestEvent : []})
    }
}

function addEventGallery(id,obj){
    return dispatch => {
        showLoader()
        adminServices.addEventGallery(id,obj)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : midwestconConstants.ADMIN_ADD_MIDWEST_EVENT_GALLERY,e_galleryAdded : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddEventGallery(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_ADD_MIDWEST_EVENT_GALLERY,e_galleryAdded : []})
    }
}
function getAllEventGallery(obj,id){
    return dispatch => {
        showLoader()
        adminServices.getAllEventGallery(obj,id)
        .then (events => {
            hideLoader()
            if(events){
                dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY,eventGallery : events})
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetAllEventGallery(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY,eventGallery : []})
    }
}
function deleteEventGallery(id,itemId){
    return dispatch => {
        showLoader()
        adminServices.deleteEventGallery(id,itemId)
        .then(events => {
            hideLoader()
            toast.success("Deleted Successfully")
            let obj = {
                statusCode : 200
            }
                dispatch({type : midwestconConstants.ADMIN_DELETE_MIDWEST_EVENT_GALLERY,deletedEventGallery : obj})
            
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetDeletedEventGallery(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_DELETE_MIDWEST_EVENT_GALLERY,deletedEventGallery : {}})
    }
}
function getEventGalleryDetail(id,itemId,fileName){
    return dispatch => {
        showLoader()
        adminServices.getEventGalleryDetail(id,itemId,fileName)
        .then(events => {
            hideLoader()
            dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY_DETAIL, e_gallery_detail : events})
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetGetEventGalleryDetail(){
    return dispatch => {
        dispatch({type : midwestconConstants.ADMIN_GET_MIDWEST_EVENT_GALLERY_DETAIL,e_gallery_detail:[]})
    }
}