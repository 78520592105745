import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import Toggle from 'react-toggle';
import { Link } from 'react-router-dom';

class CreatorsList extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShow:false,
            id:'',
            creators : [],
            totalCount : '',
            offSet : 0,
            sortBy: "createdAt",
            sortOrder : "DESC",
            recordLimit : 10,
            pageCount : '',
            hideApprove:false,
            remarks:'',
            search:''
        }
    }
    componentDidMount(){
        this.callApi()
    }
    static getDerivedStateFromProps(props,state){
        if(props && props.allCreators){
            console.log("allCreators",props.allCreators)
            return {
                creators : props.allCreators && props.allCreators.data && props.allCreators.data.creators ? props.allCreators.data.creators : [],
                totalCount : props.allCreators && props.allCreators.data && props.allCreators.data.count,
                pageCount : props.allCreators && props.allCreators.data && props.allCreators.data.count / state.recordLimit
            }
        }
    }
    handleRefresh = () => {
        this.setState({sortBy: "createdAt",search:'',sortOrder : "DESC",offSet:0},() => {
            this.callApi()
        })
    }
    handleSearch = (e) => {
        this.setState({
            search : e.target.value
        },() => {this.callApi()})
    }
    handleSortBy = (e) => {
        this.setState({sortBy:e.target.value}, () => {
            this.callApi()
        })
    }
    handleSortOrder = (e) => {
        this.setState({sortOrder:e.target.value}, () => {
            this.callApi()
        })
    }
    callApi = () => {
        const {search} = this.state

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            // offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder : this.state.sortOrder
        }
        if(search){
            obj['search'] = search
            
        }else{
            obj['offSet'] = this.state.offSet
        }
        this.props.getAllCreators(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet }, () => this.callApi())

    }
    handleView = (items) => {
        if(this.state.id == (items && items.id) && this.state.isShow){
            this.setState({isShow: true,})
        }else{
        this.setState({isShow: true,id:(items && items.id),remarks:''})
        }
    }
    
    render(){
        console.log("Creators",this.state.creators,this.state.totalCount)
        const creatorsList = this.state.creators && this.state.creators.map((items,index) => {
            return <tbody>
                 <tr>
                <td>{items && items.id}</td>
                <td>{moment(items && items.createdAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>
                <td><a className="designLink" onClick={() => {this.handleView(items)}}>{items && items.userName}</a></td>
                <td><a className="designLink" onClick={() => {this.handleView(items)}}>{items && items.email}</a></td>
                <td><a className="designLink" onClick={() => {this.handleView(items)}}>{items && items.fullName}</a></td>
                <td>{moment(items && items.updatedAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>
                {/* <td><Toggle defaultChecked={items && items.accountStatus} /></td>
                <td><Toggle defaultChecked={items && items.isEmailVerified} disabled={items && items.isEmailVerified == true} /></td> */}

                
            </tr>
            {this.state.isShow && (this.state.id == (items && items.id)) && <tr>
                <td colSpan="9" className="px-3">
                    <div className="row">
                        <div className="col-sm-4">
                      
                        <p>Detailed Bio</p>
                        <p>{items && items.fullName}</p>
                       
                        </div>
                        <div className="col-sm-4">
                      
                      <p>Remarks : {items && items.adminRemarks}</p>
                      <p>Now Account Status {items && items.accountStatus}</p>
                     
                      </div>
                        
                    </div>
                
                
                
                
                </td>
            </tr>}
            </tbody>

        })
                    
        
        return(
           
            <Fragment>
                 <div className="container-fluid">
                    <div className="mb-4"><h2 className="font-weight-bold">Creators</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                            <div className="row">
                         
                            
                            <div className="col-6">
                            <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                        <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search}/>
                                </div>
                           </div>
                           <div className="col-6 text-right">
                               <div className="btn btn-primary btn-sm px-3 " onClick={this.handleRefresh}>
                               <i className="fa fa-refresh" aria-hidden="true"></i>
                               </div>
                           </div>
                        </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>DATE TIME</th>
                                        <th>USER NAME</th>
                                        <th>EMAIL</th>
                                        <th>FULL NAME</th>
                                        <th>JOINED DATE TIME</th>
                                        {/* <th>Status</th>
                                        <th>Email Verified</th> */}
                                        {/* <th>ACTIONS</th> */}
                                    </tr>
                                </thead>
                                
                                    {creatorsList}
                                
                            </table>
                            {this.state.creators && !this.state.creators.length > 0 && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.creators && this.state.creators.length > 0  &&  <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange }} />}
                                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allCreators : state.user.allCreators,
    }
}

const actionCreators = {
    getAllCreators : userAction.getAllCreators,
}

const connectCreator = connect(mapStateToProps,actionCreators)(CreatorsList)

export {connectCreator as CreatorsList}