import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import './Creatorstore.css'
import PubSub from 'pubsub-js';
import { creatorStoreActions, userAction } from 'store/actions';
import history from 'helpers/history';
import { AutoInput } from './AutoInput';
import { DraggableSecList } from './DraggableSecList';
import { adminServices, storeServices } from 'services';
import { toast, Zoom } from 'react-toastify';
import { flowDecimal } from 'helpers';

class StoreSections extends Component {
    constructor(props) {
        super(props);
        this.spotLightArtSelected = this.spotLightArtSelected.bind(this);
        this.spotLightArtDeletedHandler = this.spotLightArtDeletedHandler.bind(this);


        this.state = {
            section: {},
            sectionName: '',
            isEdit: false,
            defaultName: ''
        }
        this.maxLength = 50

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        console.log(this.props)
        if (this.props && this.props.data && this.props.data.id) {
            this.setState({ section: this.props.data })
            // this.props.getSectionArts(this.props.data.id)
            this.getSpotLight(this.props.data.id)
            this.getSectionDetail(this.props.data.id)

        }
    }

    componentDidUpdate(props, state) {

        if (this.props && this.props.getArtFailed && this.props.getArtFailed.length > 0 &&
            (this.props.getArtFailed !== props.getArtFailed)) {
            this.props.resetFetSectionArtsFailed()
            // toast.error(this.props.getArtFailed,{transition:Zoom})
        }

        //now not using redux
        if (this.props && this.props.artsList && this.props.artsList.statusCode === 200 &&
            (this.props.artsList !== props.artsList)) {
            this.props.resetGetSectionArts()
            let res = this.props.artsList && this.props.artsList.data && this.props.artsList.data.arts
            let spotLightArts = []

            if (res && res.length > 0) {
                res.forEach((item) => {
                    spotLightArts.push({
                        text: item && item.title,
                        id: item && item.id,
                        subText: (item &&
                            item?.status === 0)
                            ? '(Disabled)' : undefined
                    })
                })
                this.setState({ spotLightArts, spotLightArray: res })
            }
        }

        if (this.props && this.props.sectionAddedSuccess && this.props.sectionAddedSuccess.statusCode === 200 &&
            (this.props.sectionAddedSuccess !== props.sectionAddedSuccess)) {
            this.props.resetAddSectionSuccess()
            // this.getSpotLight(6)

        }
        if (this.props && this.props.data && this.props.data.id && (this.props.data !== props.data)) {
            this.setState({ section: this.props.data})
            // this.props.getSectionArts(this.props.data.id)
            // this.getSpotLight(this.props.data.id)

        }
        

    }
    getSpotLight = (id) => {
        let spotLightArts = []
        this.showLoader()
        storeServices.getSectionArts(id).then(res => {
            this.hideLoader()
            if (res && res.statusCode === 200) {
                res && res.data && res.data.arts && res.data.arts.forEach((item) => {
                    spotLightArts.push({
                        text: item && item.title,
                        id: item && item.drops && item.drops[0] && item.drops[0].id,
                        subText: (item &&
                            item?.status === 0)
                            ? '(Disabled)' : undefined,
                        price: (item && item.drops && item.drops[0] && item.drops[0].markedPrice) ? (flowDecimal(parseFloat(item.drops[0].markedPrice))) : '',
                        isPriority: item && item.drops && item.drops[0] && item.drops[0].isPriority,
                        status: item && item.drops && item.drops[0] && item.drops[0].status 
                    })
                })
                this.setState({ spotLightArts, spotLightArray: res && res.data && res.data.arts })
            }
        }).catch(e => { this.hideLoader() })
    }
    spotLightArtSelected(e, items) {
        const { spotLightArts } = this.state;

        const isArtSelected = spotLightArts && spotLightArts.find(art => art.artId === e.artId)
        // if (
        //     !isArtSelected
        // ) {
        let obj = {
            dropId: e.artId
        }
        // this.props.addToSection(JSON.stringify(obj),items.id)
        storeServices.addSectionArts(JSON.stringify(obj), items.id).then((response) => {
            if (response && response.statusCode === 200) {
                this.getSpotLight(items.id)
                this.hideLoader()
                toast.success("Art added successfully")
                // spotLightArts.push({
                //     id: e.artId,
                //     text: e.mainText
                // })
                // this.setState({
                //     spotLightArts: Array.from(spotLightArts)
                // });
            }
        }).catch(e => {
            toast.error(e)
            this.hideLoader()
        }
        );

        // }

    }
    spotLightArtDeletedHandler(e, item) {
        const { spotLightArts } = this.state;
        const foundIndex = spotLightArts && spotLightArts.findIndex(
            (datum) => e.id === datum.id
        );
        if (foundIndex !== -1) {
            // spotLightArts && spotLightArts.splice(foundIndex, 1);

            let obj = {
                dropId: e.id
            }
            if (item && item.id) {
                this.showLoader()
                storeServices.deleteSectionArts(JSON.stringify(obj), item.id).then(update => {
                    this.hideLoader()
                    this.getSpotLight(item.id)
                    toast.success("Art removed successfully")
                }).catch(e => {
                    this.hideLoader()
                    toast.error(e)
                })
            }

            // this.setState({
            //     spotLightArts: Array.from(spotLightArts)
            // });
        }
    }
    handleSectionName = (e) => {
        if ((e.target.value).length <= this.maxLength) {
            this.setState({
                sectionName: e.target.value
            });
        } else {
            this.setState({ sectionName: (e.target.value.slice(0, this.maxLength)) })
        }
    }
    handleEditName = () => {
        this.setState({ isEdit: !this.state.isEdit })
    }
    editSectionName = (e, section) => {
        console.log(this.state.sectionName, section)
        if (section && section.id && this.state.sectionName && this.checkSectionName(this.state.sectionName)) {
            let formData = new FormData()
            formData.append("sectionId", section.id);
            formData.append("title", this.state.sectionName);

            this.showLoader()
            storeServices.editSectionName((formData)).then(update => {
                this.hideLoader()
                this.setState({ isEdit: false })
                this.props.updateSectionName()
                this.getSectionDetail(section.id)
                toast.success("Updated successfully")
            }).catch(e => {
                this.hideLoader()
                toast.error(e)
            })
        }
    }
    checkSectionName = (value) => {
        if (((value && value.length) <= 50) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    cancelSection = () => {
        this.setState({ isEdit: false })
    }
    getSectionDetail = (id) => {
        this.showLoader()
        storeServices.getSectionDetail(id).then(res => {
            this.hideLoader()
            if (res && res.statusCode === 200) {
                if(res && res.data && res.data.title){
                    this.setState({defaultName : res.data.title,sectionName:res.data.title})
                }
            }
        }).catch(e => { this.hideLoader() })
    }
    updateFeaturedDrop = (e, item) => {
        const { spotLightArts } = this.state;
        const foundIndex = spotLightArts && spotLightArts.findIndex(
            (datum) => e.id === datum.id
        );
        if (foundIndex !== -1) {
            let obj = {
                dropId: e.id,
                isPriority: (e?.isPriority) ? false : true
            }
            if (item && item.id) {
                this.showLoader()
                storeServices.storeupdateFeatureddropsArts(item.id).then(update => {
                    if(update && update.statusCode === 200){
                        this.hideLoader()
                        this.getSpotLight(item.id)
                        toast.success("Updated Successfully")
                    }
                }).catch(e => {
                    this.hideLoader()
                    toast.error(e,{transition:Zoom})
                })
            }

        }
    }
    render() {

        const { section, spotLightArts, defaultName, isEdit } = this.state
        return (
            <Fragment>
                <div className="col-md-12 ds-sectit">
                    <div className="propagecont">
                        {isEdit ?
                            <div>
                                <div className="input-group mb-3  ds-inputs">
                                    <input type="text"
                                        className="form-control mb-2"
                                        onChange={this.handleSectionName}
                                        value={this.state.sectionName}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-primary btn-icon" type="button" onClick={(e) => { this.editSectionName(e, section) }} ><i className='fa fa-check'></i></button>
                                    </div>
                                    <div className="input-group-append">
                                        <button className="btn btn-primary btn-icon" type="button" onClick={this.cancelSection}><i className='fa fa-times'></i></button>
                                    </div>

                                </div>

                                {this.state.sectionName && !this.checkSectionName(this.state.sectionName) && <div className="mandatory">{"Section name must be at least 3 characters and atmost 50 characters"}</div>}</div> :
                            <h3>{defaultName} <span className='btn btn-primary btn-sm btn-icon' onClick={this.handleEditName}><i className='fa fa-pencil'></i></span></h3>}
                        <p className="small font-italic">
                            <i className="fas fa-info-circle"></i>&nbsp;
                            Disabled arts are not visible to end users
                        </p>
                        <AutoInput
                            id="spotLightSuggest"
                            onSuggetionSelected={(e) => this.spotLightArtSelected(e, section)}
                            isSection={1}
                            isDropsOnly={true}
                        />

                        <DraggableSecList
                            id="spotLightList"
                            data={spotLightArts}
                            onItemDelete={(e) => this.spotLightArtDeletedHandler(e, section)}
                            onFeaturedDropUpdate={(e) => this.updateFeaturedDrop(e, section)}

                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        artsList: state.creatorStore.sectionArts,
        getArtFailed: state.creatorStore.getsectionartsFailed,
        sectionAddedSuccess: state.creatorStore.sectionadded,
        sectionAddedFailed: state.creatorStore.addsectionFailed,
        featuredDropSucess: state.creatorStore.featuredDropUpdated,
        featuredDropFailed: state.creatorStore.featuredDropUpdatedFailed
    }
}
const actionCreators = {
    getSectionArts: creatorStoreActions.getSectionArts,
    resetGetSectionArts: creatorStoreActions.resetGetSectionArts,
    resetFetSectionArtsFailed: creatorStoreActions.resetGetSectionArtsFailed,
    addToSection: creatorStoreActions.addSectionArts,
    resetAddSectionSuccess: creatorStoreActions.resetAddSectionSuccess,
    resetAddSectionFailed: creatorStoreActions.resetAddSectionFailed,
    updateFeatureDrops: creatorStoreActions.storeupdateFeatureddropsArts,
    resetFeaturedDrops: creatorStoreActions.resetUpdateFeaturedDropSuccess,
    resetFeaturedDropsFailed: creatorStoreActions.resetUpdateFeaturedDropFailed

}
const connectStoreSections = connect(mapStateToProps, actionCreators)(StoreSections)
export { connectStoreSections as StoreSections }
