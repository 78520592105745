import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import moment, { min } from 'moment'
import { adminServices } from 'services';
import PubSub from 'pubsub-js';
import { AutoSuggestInput } from 'components/auto-suggest-input/auto-suggest-input';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, LabelList
} from 'recharts';



class MintArtDetail extends Component {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

    constructor(props) {
        super(props);
        this.state = {
            mintDet: [],
            mintDetailArr: [],
            isArtGet: false,
            artname:''
        }

    }

    handleRefresh = () => {
        this.setState({ isArtGet: false, mintDet: [], mintDetailArr: [] }, () => {
        })
    }
  

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
   
    selectedArt = (e) => {
        const detailArr = []
        if (e && e.mainText) {
            this.showLoader()
            adminServices.getMintDetail(e.mainText).then(res => {
                this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    if ((res && res.data && res.data.details)) {
                        (res.data.details && res.data.details).map(items => {
                            // console.log(items)
                            let obj = {
                                name: (items.blockHeight),
                                Count: (items.mintCount),
                                txnId: (items.txnId)
                            }
                            detailArr.push(obj)
                        })
                    }
                    this.setState({ mintDet: detailArr, isArtGet: true, mintDetailArr: res && res.data && res.data.details,artname:e.mainText })
                    // console.log(res, detailArr)
                }

            }).catch(err => {
                this.hideLoader()
            })
        }
        // console.log(e)
    }
   
    timeDifference = (start,end) => {
        // console.log(start,end)

        var endTime = (moment(end).format('MMM DD, YYYY HH:mm:ss'))
        const startTime = moment(start).format('MMM DD, YYYY HH:mm:ss')

        var countDownDate = new Date(endTime).getTime();
        var now = new Date(startTime).getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        // console.log(countDownDate,now,distance,days,hours,minutes,seconds)

        return (((days && days > 0) ? (days + 'd') : '') + " " + ((hours && hours > 0) ? (hours + 'h') : '') + " " + ((minutes && minutes > 0) ? (minutes + 'm') : '') + " " + ((seconds && seconds > 0) ? (seconds + 's') : '0s'))

    }

    render() {

        const { mintDet, isArtGet, mintDetailArr,artname } = this.state
        const totalMint = []
        var totalCount;
        mintDetailArr && mintDetailArr.map((item,ind) => {
            totalMint.push(item.mintCount)
            const reducer = (accumulator, curr) => accumulator + curr
            totalCount = totalMint && totalMint.reduce(reducer)
        })
        
        const maxLimitForMint = 200
        const artsList = mintDetailArr && mintDetailArr.map((items, index, array) => {
            return <tbody >
                <tr className={(items && items.mintCount < maxLimitForMint) ? "lilacbg1" : ''}>
                    {(items && items.blockTimestamp) && <td>{moment(items && items.blockTimestamp).format('MMM/DD/YYYY hh:mm:ss A')}</td>}
                    <td>{items.mintCount}</td>
                    <td><a href={process.env.REACT_APP_FLOW_TRANSACTION + `/${items && items.txnId}`} target="_blank" className="designLink1">{items && items.txnId}</a></td>
                    {(items && items.blockTimestamp) && <td>{(this.timeDifference(((array[(Number(index) - 1)]) && (array[(Number(index) - 1)]).blockTimestamp),(items.blockTimestamp)))}</td>}
                    <td>{items.blockHeight}</td>

                </tr>

            </tbody>

        })


        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Mint Detail</h2></div>
                    <p className=" font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 For Multiple Editions Only
                            </p>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6" >
                                    <AutoSuggestInput
                                        id="spotLightSuggest"
                                        placeholder="Search art"
                                        onSuggetionSelected={this.selectedArt}
                                        isSpotArt={true}
                                        isDropsOnly={false}
                                    />
                                </div>
                                <div className="col-6 text-right">
                                    <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                    Clear                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {isArtGet && ((mintDet && mintDet.length > 0)) && <div className="table-responsive mb-3">
                        <h3>{artname}</h3>
                        <h4>Total Minted Arts : {totalCount}</h4>
                        <h5>Graph Representation</h5>
                        <LineChart width={((mintDet && mintDet.length >= 25)) ? 1500 : 500} height={500} data={mintDet}>
                            <CartesianGrid strokeDasharray="1" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Count" stroke="#8884d8" strokeWidth={2} >
                                {/* <LabelList dataKey="txnId" position="center" angle="90" fill="white" /> */}

                            </Line>
                        </LineChart>

                    </div>}
                    {isArtGet && 
                    (((mintDet && mintDet.length > 0)) ? <div className="table-responsive mb-3">
                        <h5>Table Representation</h5>

                        <table className="table table-bordered table-striped-cusom ">

                            <thead>
                                <tr>
                                    {mintDetailArr && mintDetailArr[0] && mintDetailArr[0].blockTimestamp && <th>DATE TIME</th>}
                                    <th>MINT COUNT</th>
                                    <th>TRANSACTION ID</th>
                                    {mintDetailArr && mintDetailArr[0] && mintDetailArr[0].blockTimestamp && <th>TIME DIFFERENCE</th>}
                                    <th>BLOCK HEIGHT</th>
                                </tr>
                            </thead>
                            {artsList}

                        </table>
                    </div> : <div className="table-responsive mb-3 text-center">No Record Found</div>)}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const actionCreators = {

}

const connectMintArt = connect(mapStateToProps, actionCreators)(MintArtDetail)

export { connectMintArt as MintArtDetail }