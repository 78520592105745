import React, { Fragment } from "react";
import { connect } from "react-redux";
import PubSub from 'pubsub-js';
import { artServices, userServices } from "services";
import moment from 'moment'
import { Link } from 'react-router-dom';
import { configPath } from '../../config'
import history from "helpers/history";
import { flowDecimal, ReactPagination } from "helpers";
import { TabList } from "react-tabs";
import { getFlowUsdValue } from "helpers/getFlowUsd";
import { Helmet } from "react-helmet";
import { InvalidPage } from "components/Common-error-ui/Invalidpag";

var transactions = []

class ArtActivity2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokensArray: [],
            tabIndex: 0,
            recordLimit: 10,
            page: 1,
            cur_page: 1,
            totalRec: '',
            totalTxnRec: '',
            per_page: 10,
            offset: 0,
            isActivityCall: false,
            tokenId: '',
            totalTokenId: [],
            artArray: [],
            rate: 0,
            isInvalidPage: false
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.getArtDetail()
    }
    getArtDetail = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let tokenId = ""
        let data = []
        let tokensArray = []
        let artArray = []
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            let id = this.props.match.params.id
            this.showLoader()
            artServices.getArtActivities(id).then(res => {
                this.hideLoader()
                if ((res && res.statusCode) == 200) {
                    data = res && res.data
                    tokenId = res && res.data && res.data.entity && res.data.entity[0] && res.data.entity[0].token_id
                    res && res.data && res.data.entity && res.data.entity.map(items => {
                        tokensArray.push(items && items.token_id)
                        artArray.push(items && items.id)
                    })
                    tokensArray.sort((a, b) => (a) - (b))
                    artArray.sort((a, b) => (a) - (b))
                    this.setState({ tokensArray, artArray, tokenId, data, isInvalidPage: false })
                    this.paginator(this.state.page, 10)
                }
            }).catch(err => {
                this.hideLoader()
                console.log(err)
                if (err && err.toLowerCase().includes('requested resource could not be found')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.toLowerCase().includes('not found')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.toLowerCase().includes('must be number')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.toLowerCase().includes('dosenot exist')) {
                    this.setState({
                        isInvalidPage: true
                    })
                } else if (err && err.toLowerCase().includes('in request are either missing or invalid')) {
                    this.setState({
                        isInvalidPage: true
                    })
                }
            })


        }

    }
    getTxnDetail = (tokenId) => {
        let artTxnHistoy = []
        this.showLoader()
        artServices.getArtTransactionHistory(tokenId)
            .then((res) => {
                this.hideLoader()
                transactions = res && res.data && res.data.addressDetails
                artTxnHistoy = res && res.data && res.data.addressDetails && res.data.addressDetails.transactions
                const art = res && res.data && res.data.addressDetails && res.data.addressDetails
                let artCreator, artOwner = null;
                if ((artTxnHistoy).length) {
                    if (art.creatorWalletAddress !== artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress) {
                        artCreator = {
                            id: art.creator.id,
                            userAddress: art.creatorWalletAddress,
                            userName: art.creator.userName,
                            roleId: art.creator.roleId
                        };
                    } else {
                        artCreator = {
                            id: artTxnHistoy[artTxnHistoy.length - 1].owner.id,
                            userAddress: artTxnHistoy[artTxnHistoy.length - 1].ownerWalletAddress,
                            userName: artTxnHistoy[artTxnHistoy.length - 1].owner.userName,
                            roleId: artTxnHistoy[artTxnHistoy.length - 1].owner.roleId
                        };
                    }
                    artOwner = {
                        id: artTxnHistoy[0].owner.id,
                        userAddress: artTxnHistoy[0].ownerWalletAddress,
                        userName: artTxnHistoy[0].owner.userName,
                        roleId: artTxnHistoy[0].owner.roleId
                    };
                }
                this.setState({ artTxnHistoy, artCreator, artOwner, transactions, isActivityCall: true }, () => {
                    this.state.artTxnHistoy && this.txnPaginator(1, this.state.per_page)
                })
            })
            .catch((err) => {
                this.hideLoader()
                artTxnHistoy = [];
                this.setState({ artTxnHistoy });
            });
    }
   
    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    getpurchaseDet = (item) => {
        if ((item && item.owner && item.owner.userName)) {
            if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                return (
                    <div className="purchasebox activitybox">
                        <div className="row">
                            <div className="col-6"><h5 className="mb-0 acvticotxtbox">
                                <span className="acvticon">
                                    {
                                        (item &&
                                            item.priceDetail &&
                                            item.priceDetail.auctionId != null
                                        ) ? (
                                            <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        ) :
                                            (item &&
                                                item.priceDetail &&
                                                item.priceDetail.dropSellingPrice != null
                                            ) ? (
                                                <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            ) :
                                                (
                                                    <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                                )
                                    }
                                </span>
                                <span className="acvttext">
                                    {
                                        (
                                            item &&
                                            item.airDropDetail &&
                                            item.airDropDetail.airDropId
                                        ) ? "Claimed by"
                                            : "Purchased by"
                                    }  {
                                        (item && item.owner && item.owner.email === "hidden") ?
                                            <span><i className="fa fa-user-secret" aria-hidden="true"> </i> {(item.owner.userName)}</span> :
                                            <Link to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link>}&nbsp;
                                    <br /> {
                                        (
                                            item &&
                                            item.priceDetail &&
                                            item.priceDetail.dropSellingPrice
                                        ) ?
                                            "for " + (flowDecimal(parseFloat(item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))
                                            //  (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.dropSellingPrice)))) + ' USD')
                                            : (
                                                item &&
                                                item.priceDetail &&
                                                item.priceDetail.auctionSettlementPrice
                                            ) ?
                                                "for " + (flowDecimal(parseFloat(item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")
                                                //  (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.auctionSettlementPrice)))) + ' USD'
                                                : ''
                                    }
                                </span>

                            </h5>

                            </div>
                            <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>

                        </div>
                    </div>
                )
            }
            return (
                <div className="purchasebox activitybox">

                    <div className="row ">
                        <div className="col-6"><h5 className="mb-0 acvticotxtbox">
                            <span className="acvticon">
                                {
                                    (item &&
                                        item.priceDetail &&
                                        item.priceDetail.auctionId != null
                                    ) ? (
                                        <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                    ) :
                                        (item &&
                                            item.priceDetail &&
                                            item.priceDetail.dropSellingPrice != null
                                        ) ? (
                                            <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        ) :
                                            (
                                                <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            )
                                } </span>
                            <span className="acvttext">{
                                (
                                    item &&
                                    item.airDropDetail &&
                                    item.airDropDetail.airDropId
                                ) ? "Claimed by"
                                    : "Purchased by"
                            } {(item && item.owner && item.owner.email === "hidden") ? <span><i className="fa fa-user-secret" aria-hidden="true"></i> {(item.owner.userName)}</span> : <Link to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@') ? item.owner.userName : (`@${item.owner.userName}`)))}</Link>}&nbsp;
                                <br /> {(item && item.priceDetail && item.priceDetail.dropSellingPrice) ?
                                    "for " + (flowDecimal(parseFloat(item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))
                                    // (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.dropSellingPrice)))) + ' USD')
                                    : (
                                        item &&
                                        item.priceDetail &&
                                        item.priceDetail.auctionSettlementPrice
                                    ) ?
                                    "for " + (flowDecimal(parseFloat(item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")
                                        //  (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.auctionSettlementPrice)))) + ' USD'
                                        : ''}
                            </span></h5></div>
                        <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>
                    </div>
                </div>
            )
        }
        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
            return (
                <div className="purchasebox activitybox">
                    <div className="row ">
                        {/* <div className="col-sm-6">Purchased by <Link to={`/creator/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                        <div className="col-6"><h5 className="mb-0 acvticotxtbox">
                            <span className="acvticon">
                                {
                                    (item &&
                                        item.priceDetail &&
                                        item.priceDetail.auctionId != null
                                    ) ? (
                                        <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                    ) :
                                        (item &&
                                            item.priceDetail &&
                                            item.priceDetail.dropSellingPrice != null
                                        ) ? (
                                            <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        ) :
                                            (
                                                <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                            )
                                }
                            </span>
                            <span className="acvttext"> {
                                (
                                    item &&
                                    item.airDropDetail &&
                                    item.airDropDetail.airDropId
                                ) ? "Claimed by"
                                    : "Purchased by"
                            } {item.ownerWalletAddress}<br /> {(item && item.priceDetail && item.priceDetail.dropSellingPrice) ?
                                "for " + (flowDecimal(parseFloat(item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))
                                // (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.dropSellingPrice)))) + ' USD')

                                : (
                                    item &&
                                    item.priceDetail &&
                                    item.priceDetail.auctionSettlementPrice
                                ) ?
                                    "for " + (flowDecimal(parseFloat(item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")
                                    //  (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.auctionSettlementPrice)))) + ' USD'
                                    : ''
                                }</span></h5></div>
                        <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>

                    </div>
                </div>
            )
        }
        return (
            <div className="purchasebox activitybox">
                <div className="row">
                    {/* <div className="col-sm-6">Purchased by <Link to={`/collector/${(item && item.owner && item.owner.id)}`}>{item.ownerWalletAddress}</Link></div> */}
                    <div className="col-6"><h5 className="mb-0 acvticotxtbox">
                        <span className="acvticon">
                            {
                                (item &&
                                    item.priceDetail &&
                                    item.priceDetail.auctionId != null
                                ) ? (
                                    <i className="fa fa-gavel mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                ) :
                                    (item &&
                                        item.priceDetail &&
                                        item.priceDetail.dropSellingPrice != null
                                    ) ? (
                                        <i className="fa fa-shopping-cart mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                    ) :
                                        (
                                            <i className="fa fa-dropbox mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>
                                        )
                            }
                        </span>
                        <span className="acvttext">{
                            (
                                item &&
                                item.airDropDetail &&
                                item.airDropDetail.airDropId
                            ) ? "Claimed by"
                                : "Purchased by"
                        } {item.ownerWalletAddress}<br /> for {(item && item.priceDetail && item.priceDetail.dropSellingPrice) ?
                            (flowDecimal(parseFloat(item.priceDetail.dropSellingPrice)) + " " + this.showCurrencyValue(item))
                            // (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.dropSellingPrice)))) + ' USD')
                            : (
                                item &&
                                item.priceDetail &&
                                item.priceDetail.auctionSettlementPrice
                            ) ?
                                (flowDecimal(parseFloat(item.priceDetail.auctionSettlementPrice)) + " " + "FLOW")
                                //  (flowDecimal(parseFloat((this.state.rate) * (item.priceDetail.auctionSettlementPrice)))) + ' USD'
                                : ''
                            }</span></h5></div>
                    <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>
                </div>
            </div>
        )
    }
    getArtTransactions() {
        const { artTxnHistoy, tabIndex, dataTxnArray, cur_page, total_pages, isActivityCall } = this.state;
        // console.log(dataTxnArray,this.state.cur_page,this.state.total_pages)
        const txnHistory = transactions && transactions.transactions
        if ((dataTxnArray && dataTxnArray.length)) {
            const txnRows = (dataTxnArray).map((item, index) => {
                if (cur_page == total_pages) {
                    if (index !== ((dataTxnArray.length) - 1)) {
                        return this.getpurchaseDet(item)
                    } else {

                        // return user name when it is not null
                        if ((item && item.owner && item.owner.userName)) {
                            if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                                return (
                                    <div className="purchasebox activitybox">
                                        <div className="row ">
                                            <div className="col-6"><h5 className="mb-0"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i> Minted by <Link to={{ pathname: `/creator/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`, state: { obj: { userName: item && item.owner && item.owner.userName } } }}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></h5></div>
                                            <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div className="purchasebox activitybox">
                                    <div className="row">
                                        <div className="col-6"><h5 className="mb-0"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>Minted by  <Link to={`/collector/@${item && item.owner && item.owner.userName && encodeURIComponent(item.owner.userName)}`}>{((item.owner.userName && item.owner.userName.includes('@')) ? item.owner.userName : (`@${item.owner.userName}`))}</Link></h5></div>
                                        <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>
                                    </div>
                                </div>
                            )
                        }
                        if (item && item.owner && item.owner.id != null && item.owner.roleId == 1) {
                            return (
                                <div className="purchasebox activitybox">
                                    <div className="row">
                                        {/* <div className="col-sm-6">Minted by <Link to={`/creator/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                                        <div className="col-6"><h5 className="mb-0"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>Minted by {item.ownerWalletAddress}</h5></div>
                                        <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="purchasebox activitybox">
                                <div className="row">
                                    {/* <div className="col-sm-6">Minted by <Link to={`/collector/${item && item.owner && item.owner.id}`}>{item.ownerWalletAddress}</Link></div> */}
                                    <div className="col-6"><h5 className="mb-0"><i className="fa fa-connectdevelop mt-1 mr-2 float-left mb-3" aria-hidden="true"></i>Minted by {item.ownerWalletAddress}</h5></div>
                                    <div className="col-6 text-right"><h6 className="mb-0">at {moment(item.createdAt).format('DD MMM, YYYY [at] h:mma')} <a target="blank" href={item && item.flowscanURL} className="ml-2"><i className="fas fa-external-link-alt"></i></a></h6></div>

                                </div>
                            </div>
                        )
                    }
                } else {
                    return this.getpurchaseDet(item)
                }
            });
            return (
                <div className='w-100 d-block py-3'>
                    {txnRows}
                </div>

            );
        } else {
            if (isActivityCall) {
                return (
                    <p className="text-center">
                        <i className="fa fa-info-circle"></i>&nbsp;No Activity data available.<br />
                        {/* <img src={`../${configPath.imageAssets}/no-images.png`} /> */}
                    </p>
                )
            }
        }
    }
    handleIndex = (tabIndex) => {
        const { tokensArray, dataArray } = this.state
        this.getTxnDetail(dataArray[tabIndex])
        this.setState({ tabIndex })
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    gotoArt = (id) => {
        if (id && id != null && id != undefined) {
            history.push(`/art/${id}`)
        }
    }
    onPageChange = (data) => {
        let offset = this.state.recordLimit * (data && data.selected)
        const page = data.selected + 1
        this.paginator(page, this.state.recordLimit)

    }
    onPageTxnChange = (data) => {
        let offset = this.state.recordLimit * (data && data.selected)
        const cur_page = data.selected + 1
        this.txnPaginator(cur_page, this.state.per_page)
    }
    txnPaginator = (current_page, per_page_items) => {
        // console.log(this.state.artTxnHistoy)
        const items = this.state.artTxnHistoy ? this.state.artTxnHistoy : []
        let page = current_page || 1,
            per_page = per_page_items || 10,
            offset = (page - 1) * per_page,
            paginatedItems = items && items.slice(offset).slice(0, per_page_items),
            total_pages = Math.ceil(items.length / per_page);
        // console.log(items,paginatedItems)
        this.setState({
            dataTxnArray: paginatedItems,
            total_pages,
            cur_page: page,
            totalTxnRec: items.length,
            per_page,
        })

    }
    paginator = (current_page, per_page_items) => {
        const items = this.state.tokensArray
        const tokenId = this.state.tokenId
        let page = current_page || 1,
            per_page = per_page_items || 10,
            offset = (page - 1) * per_page,
            paginatedItems = items.slice(offset).slice(0, per_page_items),
            total_pages = Math.ceil(items.length / per_page);
        this.getTxnDetail(paginatedItems && paginatedItems[0] ? paginatedItems[0] : tokenId)

        this.setState({
            pageCount: total_pages,
            recordLimit: per_page,
            dataArray: paginatedItems,
            totalRec: items.length,
            tabIndex: 0,
            offset
        })

        // return {
        //     page: page,
        //     per_page: per_page,
        //     pre_page: page - 1 ? page - 1 : null,
        //     next_page: (total_pages > page) ? page + 1 : null,
        //     total: items.length,
        //     total_pages: total_pages,
        //     data: paginatedItems
        // };
    }
    gotoEditionArt = () => {
        const TabIndex = this.state.tabIndex
        const offset = this.state.offset
        const { artArray } = this.state
        console.log(artArray)
        if (artArray && artArray.length > 0 && (artArray[(TabIndex + offset)] != undefined)) {
            history.push(`/art/${artArray[(TabIndex + offset)]}`)
        }
    }
    showCurrencyValue = (item) => {
        if(item &&
            item.priceDetail &&
            item.priceDetail.withDapper){
                return 'USD'
        }else{
            return 'FLOW'
        }
    }
    render() {
        const { tokensArray, tabIndex, data, dataArray, totalRec, page, offset, isInvalidPage } = this.state
        const metaTitle = data ? `${data.title} ` : ''
        return (
            <Fragment>
                <Helmet>
                    <title>{`${metaTitle} - Activity on ${process.env.REACT_APP_BASE_SITE}`}</title>
                    <meta name="title" content={`${metaTitle} - Activity on ${process.env.REACT_APP_BASE_SITE}`}></meta>
                    <meta name="description" content={`${metaTitle} - Activity on ${process.env.REACT_APP_BASE_SITE}`}></meta>
                </Helmet>
                {!(isInvalidPage) ? <div className="container">
                    <div className="pagetitabtn mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="d-inline-block"><span className="usernamelilac" id="uploadArt" onClick={this.gotoEditionArt}>{data && data.title}</span> <span className="usernamelilac" id="uploadArt" onClick={() => this.gotoCreator(data && data.creator)}>{data && data.creator && data.creator.fullName}</span> - Activity </h1>
                            </div>
                        </div>
                        <hr className="mb-3" />
                    </div>
                    <div className="activitysec">
                        <div className="row">
                            <div className="col-md-3">
                                <nav className="nav-sidebar">
                                    <ul className="nav tabs">
                                        {dataArray && dataArray.map((items, index) => (<li className=""><a onClick={() => { this.handleIndex(index) }} id="uploadArt" className={(tabIndex == index) ? "tabActive" : ''}>Edition of {((Number(offset) + 1) + (Number(index)))} - #{dataArray && dataArray[index]}</a></li>))}
                                    </ul>
                                    <div className="w-100 text-center">

                                        {(totalRec > this.state.recordLimit) && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}

                                    </div>
                                </nav>
                            </div>
                            <div className="tab-content col-md-9">
                                <div className="tab-pane active text-style" >
                                    <h4 className="mb-3" id="uploadArt" onClick={() => { this.gotoEditionArt(tabIndex, offset) }}>Edition of {((Number(offset) + 1) + (Number(tabIndex)))}</h4>

                                    {this.getArtTransactions()}


                                    <div className="w-100 text-center">
                                        {(this.state.totalTxnRec > this.state.per_page) && <ReactPagination pageDetails={{ pageCount: this.state.total_pages, perPage: this.state.per_page, onPageChange: this.onPageTxnChange, forcePg: this.state.forcePage }} />}

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div> : <InvalidPage />}




            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {



    }
}
const actionCreators = {



}

const connectArtActivity = connect(mapStateToProps, actionCreators)(ArtActivity2)
export { connectArtActivity as ArtActivity2 }
