import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, userAction } from 'store/actions';
import moment from 'moment'
import { ReactPagination, toastr } from 'helpers';

import PubSub from 'pubsub-js';
import Toggle from 'react-toggle';

import { debounce } from 'utils';
import { adminServices, airDropService } from 'services';
import Modal from 'react-modal'
import { toast } from 'react-toastify';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',

    },
};
class FetchAirdropMissedTokens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
            id: '',
            missedTokens: [],
            totalCount: '',
            offSet: 0,
            sortBy: "createdAt",
            sortOrder: "DESC",
            recordLimit: 10,
            pageCount: '',
            search: '',
            isFetched: false,
            isOpenWithdraw: false,
            selectedItem: {}
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.callApi()

    }

   
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
   


    handleRefresh = () => {
        this.setState({
            sortBy: "createdAt",
            search: '',
            sortOrder: "DESC",
            offSet: 0,
            
            recordLimit: 10,
           
        }, () => {
            this.callApi()
        })

    }
   
    callApi = () => {
        const { search, role, recordLimit, offSet } = this.state
        this.setState({ forcePage: offSet != null ? ((offSet) / this.state.recordLimit) : 0 })

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let obj = {
            // offSet: this.state.offSet,

            // sortBy: this.state.sortBy,
            // sortOrder: this.state.sortOrder
        }
        // if (recordLimit) {
        //     obj['recordLimit'] = recordLimit
        // }
        // if (role) {
        //     obj['roleId'] = role


        // }
        // if (search) {
        //     obj['search'] = search
        // }
        this.showLoader()
        adminServices.getMissedAirdropTokens(obj).then(res => {
            this.hideLoader()
            if(res && res.statusCode === 200){
                if(res && res.data && res.data.missedAirDropTokens){
                    this.setState({
                        missedTokens : res.data.missedAirDropTokens,
                        isFetched: true
                        // totalCount: props.allAirdrops && props.allAirdrops.data && props.allAirdrops.data.count,
                        // pageCount: props.allAirdrops && props.allAirdrops.data && props.allAirdrops.data.count / state.recordLimit,
                        // isFetched: true,
                    })
                }
                
            }
        }).catch(e => {
            this.hideLoader()
            this.setState({
                missedTokens : [],
                isFetched: true
            })
        })

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)
        this.setState({ offSet, isShow: false }, () => this.callApi())

    }
    
    gotoWithdraw = (e,item) => {
        e.preventDefault()
        this.setState({
            isOpenWithdraw: true,
            selectedItem: item
        })
    }
    approveWithdraw = () => {
        const {selectedItem} = this.state
        this.setState({
            isOpenWithdraw: false
        })
        let obj = {
            "tokenId": selectedItem.tokenId,
            "creatorWalletAddress": selectedItem.creatorWalletAddress
          }
          this.showLoader()
          adminServices.withdrawFromAdminAccount(JSON.stringify(obj)).then(res => {
            this.hideLoader()
            if(res && res.statusCode === 200){
                toast.success("Token withdrawn from admin account")
                this.callApi()
            }else{
                if(res && res.messaage){
                    toast.error(res.message)
                }
            }
          }).catch(e => {
            this.hideLoader()
            if(e && typeof e === 'string'){
                toast.error(e)
            }
          })
    }
    closeWithdraw = () => {
        this.setState({
            isOpenWithdraw: false,
            selectedItem: {}

        })
    }
    render() {
        const airdropList = this.state.missedTokens && this.state.missedTokens.length > 0 && this.state.missedTokens.map((items, index) => {
            return <tbody key={index}>
                <tr className={items && items.isDisruptArtUser ? "lilacbg1" : ''}>
                    <td>{items && items.artName}</td>
                    <td>{items && items.tokenId}</td>
                    <td>{items && items.creatorWalletAddress}</td>
                    <td>{moment(items && items.depositedAt).format('DD-MM-YYYY')}, {moment.utc(items && items.depositedAt).local().format("hh:mm:ss A")}</td>
                    <td><button className='btn btn-primary' type="button" onClick={(e) => this.gotoWithdraw(e,items)}>Withdraw</button></td>

                </tr>
            </tbody >

        })
       
        
        

        return (

            <Fragment>
                <Modal
                    isOpen={this.state.isOpenWithdraw}
                    style={customStyles}
                    className="creatorrequ"
                    ariaHideApp={false}
                    contentLabel="Report Modal">
                    <div className="custom-ui reportpop text-left py-3 px-5 minw-35rem">

                        <div className="py-3">

                            <h4 className='mb-3 text-center'>Are you certain you wish to proceed with the withdrawal?</h4>
                            <div className="col-sm-12 text-center">
                                This action cannot be undone, so please ensure that you have confirmed the correct address before proceeding with the withdrawal.
                            </div>

                            <div className='row mt-5'>
                                <div className='col-6'><button type="button" className="btn btn-primary px-3 mr-2" onClick={this.closeWithdraw}>Cancel</button></div>
                                <div className='col-6 text-right'><button className='btn btn-primary-fill' type="button" onClick={this.approveWithdraw}>Withdraw</button></div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="container-fluid">
                    <div className="row mb-4 admintitle">
                        <h2 className="font-weight-bold col-sm-6">Missed Tokens</h2>
                    </div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body px-0">
                                <div className="row">
                                    <div className="col-3 d-none">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-12 text-right">
                                        <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                            <i className="fa fa-refresh" aria-hidden="true"></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>ART NAME</th>
                                        <th>TOKEN ID</th>
                                        <th>CREATOR ADDRESS</th>
                                        <th>DEPOSITED AT</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>

                                {airdropList}


                            </table>
                            {this.state.missedTokens && this.state.missedTokens.length === 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {/* {this.state.missedTokens && this.state.missedTokens.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />} */}

                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const actionCreators = {
    
}

const connectFetchAirdropMissedTokens = connect(mapStateToProps, actionCreators)(FetchAirdropMissedTokens)

export { connectFetchAirdropMissedTokens as FetchAirdropMissedTokens }
