import React from "react";
import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { ArtContent } from "./art-content";
import { creatorActions, userAction } from "store/actions";
import { connect } from "react-redux";
import history from "helpers/history";
import { isWebLogin, localAirdropPage, localArtPage, localDropPage, ReactPagination } from 'helpers'
import { getUserAddress } from "utils";
import { configPath } from "../config";
import Modal from 'react-modal'
import { CreatorRequest } from "./creatorRequest";
import { Alert } from "helpers/alert";
import { Helmet } from "react-helmet";
import { Preview } from "./preview";
import { ProfilePreview } from "./profilePreview";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: '35%',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '700px', // <-- This sets the height
        overlfow: 'auto' // <-- This tells the modal to scrol
    },
};
class Creators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            creatorsList: [],
            totalCount: 0,
            size: 12,
            offSet: 0,
            isRequest: false,
            userRole: '2',
            isSuccess: false,
            isShowLoader : true
        }
    }
   
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localStorage.setItem('page',JSON.stringify(0));
        this.callApi()
        localArtPage()
        localDropPage()
        localAirdropPage()

        isWebLogin() && this.props.getUserProfile()
        getUserAddress().then(res => {
            this.setState({ currentUser: res })
        })
       

    }
    static getDerivedStateFromProps(props, state) {
        if(props && props.creatorsList && props.creatorsList.statusCode == 200){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
        return {
            creatorsList: props && props.creatorsList && props.creatorsList.data ? props.creatorsList.data : [],
            totalCount: props && props.creatorsList && props.creatorsList.data && props.creatorsList.data.count,
            pageCount: state.totalCount / state.size,
            userRole: props && props.usersList && props.usersList.data && props.usersList.data.roleId ? props.usersList.data.roleId : '',
            isShowLoader : false
        }

    }
   
   
    gotoTesting = () => {
        this.setState({ isRequest: true })
    }
    handleError = (message) => {
        this.setState({ isAlert: true, message, style: "danger" })
        this.closeAlert()
    }
    closeAlert = () => {
        setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    closeTest = () => {
        this.setState({ isRequest: false })
    }
    componentDidUpdate(props) {
        let nft = configPath.basename

        if (window.location.pathname.includes(nft)) {
            if ((props && props.location && props.location.search) != (window.location.search)) {
                this.callApi()
            }
        } else if ((props && props.location && props.location.search) != (window.location.search)) {
            this.callApi()
        }

    }
    callApi = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        let offSet = localStorage.getItem("page")
       
        this.setState({forcePage: offSet != null  ? (JSON.parse(offSet)/this.state.size) : 0})
        let obj = {
            offSet : offSet != null ? offSet : this.state.offSet,
            recordLimit: this.state.size,
            sortBy: 'updatedAt',
            sortOrder: 'DESC'
        }
        this.props.getCreators(obj)
        this.props.resetCreators()

    }

    onPageChange = (data) => {
        let offSet = this.state.size * (data && data.selected)
        localStorage.setItem("page",JSON.stringify(offSet))

        this.setState({ offSet }, () => this.callApi())

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    async onCreateContractClick(e) {
        e.preventDefault();

        const deployTransaction = `\
               transaction(code: String) {
                   prepare(acct: AuthAccount) {
                       acct.contracts.add(name: "DisruptNow", code: code.decodeHex())
                   }
        }`

        // Contract code starts
        // ######################################################################################

        const contract = `\
           pub contract DisruptNow {

    // Total number of token supply
    pub var totalSupply: UInt64

    // Contract Events
    pub event ContractInitialized()
    pub event Withdraw(id: UInt64, from: Address?)
    pub event Deposit(id: UInt64, to: Address?)


    // TOKEN RESOURCE
    pub resource NFT {

        // Unique id for NFT Token
        pub let tokenid :UInt64

        // Meta data to store token data (use dict for data)
        pub let metadata: {String : String}

        // Token owner tracker 
        // first one is the contract creator
        pub var tokentracker: [Address?] 

        // Token owner set this baseprice
        pub(set) var baseprice : UFix64

        // owner royality percentage
        pub let royality:UFix64

        // In current store static dict in meta data
        init( tokenid : UInt64, ipfsendpoint : String, creator: Address, price:UFix64, royality:UFix64 ) {
            self.tokenid = tokenid
            self.metadata = {"ipfs-endpoint" : ipfsendpoint}
            self.tokentracker=[]
            self.baseprice = price
            self.royality=royality
        }

    }

    // NFT Reciever interface
    pub resource interface NFTReceiver  {

        pub fun tokenExists(tokenid:UInt64) : Bool

    }
    // NFT provider interface
    pub resource interface NFTProvider {
        // withdraw function post
        pub fun withdraw(tokenid: UInt64): @NFT {
            post {
                result.tokenid == tokenid: "The ID of the withdrawn token must be the same as the requested ID"
            }
        }

    }

    // Accounts public collection
    pub resource interface NFTPublicCollection {

        pub fun deposit(token:@NFT)

        pub fun getids() : [UInt64]

        pub fun getvalues(tokenid : UInt64) : &DisruptNow.NFT

        pub fun changebaseprice(tokenid : UInt64 , baseprice: UFix64)

    }

    // NFT Collection resource.
    pub resource Collection :  NFTReceiver, NFTProvider, NFTPublicCollection {
        
        // Contains NFT's of caller
        pub var ownedNfts: @{UInt64 : NFT}

        init() {
            self.ownedNfts <- {}
        }

        pub fun deposit(token: @NFT) {

            let token <- token as! @DisruptNow.NFT

            let id: UInt64 = token.tokenid

            // Append the token owner address
            token.tokentracker.append(self.owner?.address)

            // add the new token to the dictionary which removes the old one
            let oldToken <- self.ownedNfts[id] <- token

            emit Deposit(id: id, to: self.owner?.address)

            destroy oldToken
        }

        // function returns token keys of owner
        pub fun getids():[UInt64] {
            return self.ownedNfts.keys
        }

        // function returns token data of token id
        pub fun getvalues(tokenid : UInt64) : &DisruptNow.NFT {
            pre {
                self.ownedNfts[tokenid] != nil: "NFT does not exist in the collection!"
            }
            return &self.ownedNfts[tokenid] as &DisruptNow.NFT
        }

        // function to check wether the owner have token or not
        pub fun tokenExists(tokenid:UInt64) : Bool {
            return self.ownedNfts[tokenid] != nil
        }

        // Change token base price
        pub fun changebaseprice(tokenid : UInt64 , baseprice: UFix64) {

            pre {
                self.tokenExists(tokenid: tokenid) : "NFT token does not exists in collection"
            }

            var token <- self.ownedNfts.remove(key: tokenid) ?? panic("missing NFT")

            token.baseprice = baseprice

            let oldToken <- self.ownedNfts[tokenid] <- token

            destroy oldToken

        }

        pub fun withdraw(tokenid:UInt64) : @NFT {

            let token <- self.ownedNfts.remove(key: tokenid) ?? panic("missing NFT")

            emit Withdraw(id: token.tokenid, from: self.owner?.address)

            return <-token

        }

        destroy(){
            destroy self.ownedNfts
        }

    }

    // This is used to create the empty collection. without this address cannot access our NFT token
    pub fun createEmptyCollection(): @Collection {
        return <- create Collection()
    }

    pub resource NFTMint {
        pub fun Mint(recipient: &{NFTPublicCollection},ipfsendpoint:String,creator: Address, price:UFix64, royality:UFix64) {
            let token <- create NFT(tokenid: DisruptNow.totalSupply, ipfsendpoint:ipfsendpoint, creator:creator,price:price,royality:royality)
            recipient.deposit(token: <- token)
            DisruptNow.totalSupply = DisruptNow.totalSupply + 1 as UInt64
        }
    }


    // Contract init
    init() {

        // total supply is zero at the time of contract deployment
        self.totalSupply = 0

        self.account.save(<-self.createEmptyCollection(), to: /storage/NFTCollection)

        self.account.link<&{NFTPublicCollection}>(/public/NFTPublicCollection, target:/storage/NFTCollection)

        self.account.save(<- create NFTMint(), to:/storage/NFTMint)

        emit ContractInitialized()

    }

}`

        // Contract code ends
        // ######################################################################################


        try {

            const blockResponse = await fcl.send([
                fcl.getBlock(),
            ])

            const { transactionId } = await fcl.send([
                fcl.transaction(deployTransaction),
                fcl.args([
                    fcl.arg(
                        Buffer.from(contract, "utf8").toString("hex"),
                        t.String
                    )
                ]),
                fcl.proposer(fcl.currentUser().authorization),
                fcl.authorizations([
                    fcl.currentUser().authorization
                ]),
                fcl.payer(fcl.currentUser().authorization),
                fcl.ref(blockResponse["block"].id),
                fcl.limit(1000),
            ])

            /*const unsub = fcl
              .tx({ transactionId })
              .subscribe(transaction => {
      
                if (fcl.tx.isSealed(transaction)) {
                  unsub()
                }
              }) */
        } catch (error) {
            // console.log(error);
        }




    }



    render() {
        const artContent = [];
        for (let i = 0; i < 24; i++) {
            artContent.push(<ArtContent />)
        }
        const shareUrl = `${process.env.REACT_APP_BASEPATH}${window.location.pathname}`

        return (
            <div className="art artcontent creators">
                <Helmet>
                    <title>Creators</title>
                    <meta name="title" content={`Browse ${process.env.REACT_APP_SITE_CONTRACT} Creators`}></meta>
                    <meta name="description" content={`Browse all NFT creators on ${process.env.REACT_APP_SITE_CONTRACT} NFT Platform`}></meta>
                </Helmet>
                {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />}

                {this.state.isSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3">Thanks for applying to become one of a creator in Disrupt.art, <br /> We will review your application and upgrade your account as a creator account soon. <br />Until then enjoy Arts & Drops and start collecting more NFT in Disrupt.art.</div>
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); this.setState({ isSuccess: false }); }}>Close</button></div></div>
                }
                <Modal
                    isOpen={this.state.isRequest}
                    style={customStyles}
                    className="creatorrequ"
                    contentLabel="Example Modal"

                >

                    <button onClick={this.closeTest} className="btn btn-primary px-2 clsbtn">X</button>
                    <CreatorRequest closeModal={this.closeTest} success={() => this.setState({ isSuccess: true })} errorMessage={(mes) => this.handleError(mes)} />

                </Modal>
                <div className="container">
                    <div className="pagetitabtn">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="w-auto float-left">Creators </h1>
                                {isWebLogin() && this.state.userRole && (this.state.userRole == "2") && <div className="probtns float-right pt-0">
                                    <button className="btn btn-outline-dark btn-sm " onClick={this.gotoTesting}>Become a Creator</button></div>}
                            </div>
                        </div>
                        <hr />
                    </div>
                    
                
                <div className="row">
                    {this.state.creatorsList && this.state.creatorsList.creators && this.state.creatorsList.creators.map((content, index) => (

                        <div className="col-sm-6 col-md-4 col-lg-3" key={content && content.id} >
                            <div className="cardlist" style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); history.push(`/creator/@${content && content.userName && encodeURIComponent(content.userName)}`, { obj: { userAddress: content && content.userAddress,userName: content && content.userName } }) }} >
                            <Preview src={(content && content.bannerImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${content && content.bannerImage}`)} alt="" className="comimg" isPremiumAccount={content && content.isPremiumAccount}/>

                                <div className="upic">
                                    <span className="artcovimg">
                                        <span className="artcov "></span>
                                        <span className="artcovimginset no-proimg" >
                                            <ProfilePreview src={(content && content.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${content && content.profileImage}`)} alt="Avatar" />

                                        </span>
                                    </span>
                                </div>
                                <div className="cont">
                                    <h3 className=""  title={content && content.fullName ? content.fullName : "Creator Name"}>{content && content.fullName ? content.fullName : "Creator Name"}</h3>
                                    <h6 className="mt-2 usernamelilac" >@{content && content.userName ? content.userName : "@username"}</h6>
                                    <p className="mb-0 userdescrip" >{content && content.description && content.description}</p>
                                </div>
                                <div className="userwithpic" >
                                    <p className="update" title={content && content.userName ? content.userName : "@username"}>{content && content.userName ? content.userName : "@username"}</p>
                                    <h6>{content && content.profileViews} Views</h6>

                                </div>

                            </div>
                        </div>
                    )
                    )}
                </div>

                {this.state.creatorsList && this.state.creatorsList.creators && this.state.creatorsList.creators.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.size, onPageChange: this.onPageChange,forcePg : this.state.forcePage }} />}
                {this.state.totalCount == 0 &&
                    <div className="col-md-4 offset-md-4 text-center">
                        <img src={`../${configPath.imageAssets}/noartyet.png`} className="mw-100" alt="" />
                    </div>
                }
            </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        creatorsList: state.creator.creatorsList,
        usersList: state.user.usersList,
    }
}
const actionCreators = {
    getCreators: creatorActions.getCreators,
    resetCreators : creatorActions.resetCreators,
    getUserProfile: userAction.getUsers
}
const connectCreator = connect(mapStateToProps, actionCreators)(Creators)
export { connectCreator as Creators }
