import { joyrideConstants } from "../../constants";

const initialState = {
    joySteps: [],
    isLoginShowed : false,
    isConnectWalletShowed : false,
    isAfterConnectWalletShowed : false
}
export function joyrideReducer(state = initialState, action) {
    switch (action.type) {
        case joyrideConstants.GET_JOYRIDE_STEPS:
            return {
                ...state,
                joySteps: action.joySteps,
                isLoginShowed : action.isLoginShowed,
                isConnectWalletShowed : action.isConnectWalletShowed,
                isAfterConnectWalletShowed : action.isAfterConnectWalletShowed
            }
        default:
            return state
    }
}