import { configPath } from "../config";
import { adminToken } from "helpers"
import { fetchService } from "./fetch-service";

export const adminServices = {
    getAllUsers,
    getAllCreators,
    emailVerificationChange,
    getCreatorApplications,
    resetPassword,
    changePassword,
    revokeCreator,
    dashboard,
    bulkupload,
    sendOTP,
    getUploadUsers,
    getAllArts,
    getAddressConfig,
    downloadCSV,
    addFeaturedArt,
    addFeaturedCollector,
    searchFeaturedCollector,
    searchFeaturedCreator,
    addFeaturedCreator,
    searchSpotlightArt,
    addSpotlightArt,
    updateSpotlightArt,
    updateFeaturedArt,
    updateFeaturedCreator,
    updateFeaturedCollector,
    updateUserToPremium,
    getSpotlightArts,
    getFeaturedArts,
    getFeaturedCollectors,
    getFeaturedCreators,
    getArtSaleList,
    getMintDetail,
    getUserWalletAddress,
    processBlockRange,
    getEventHistory,
    updateDiscoverCount,
    getExportData,
    getTagsData,
    addTagsData,
    getPriorityCreators,
    addPriorityCreators,
    removePriorityCreators,
    getAnnouncement,
    addAnnouncement,
    getAirdropListing,
    getAirdropDetail,
    addCategories,
    getCategories,
    getAllTrendingTags,
    addTrendingTags,
    deleteTrendingTags,
    fetchuserAirdrops,
    getAllGlitterEvents,
    addGlitterEvent,
    deleteGlitterEvent,
    getOneGlitterEvent,
    updateGlitterEvent,
    getAllMintslots,
    updateMintSlot,
    updateArtCategory,
    getArtDetail,
    updateCategoryParent,
    getAdminDropList,
    updatePriorityRank,
    getDiscoverMaxCountLimit,
    updateDiscoverSettings,
    getAllMidwestEvents,
    getMidwestEventDetail,
    updateMidwestEvent,
    addMidwestEvents,
    addEventGallery,
    getAllEventGallery,
    deleteEventGallery,
    getEventGalleryDetail,
    getAnnouncementHistory,
    deleteAnnouncement,
    updateStoreStatusForCreator,
    getAllAdminAccount,
    addAdminAccount,
    enableAdminAccount,
    disableAdminAccount,
    getAbuseReport,
    updateAbuseReport,
    getAdminUser,
    adminresetPassword,
    adminChangePassword,
    listDropHistory,
    getdropHistoryDetail,
    dropHistoryRelisting,
    dropHistoryrelistSuccess,
    getStores,
    getStoreDetail,
    getStoreSalesDetail,
    updateAirdropVisibilityStatus,
    getAllAvailableBadges,
    addBadges,
    updateBadgeStatus,
    getMissedAirdropTokens,
    withdrawFromAdminAccount
}

function getAllUsers(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : ''


    params =  offSet + recordLimit + sortBy + sortOrder + search + roleId

    return fetch(`${configPath.apiUrl}/super-admin/user/getall?` + params,requestOptions).then(handleResponse)
}

function getAllCreators(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params = offSet + recordLimit + sortBy + sortOrder + search

    return fetchService.fetchResource(`${configPath.apiUrl}/super-admin/user/creator?` + params,requestOptions).then(handleResponse)
}
function emailVerificationChange(userId){
    const requestOptions = {
        method: 'PUT',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/${userId}/verification-email` ,requestOptions).then(handleResponse)

}
function resetPassword(email){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/${email}/reset-password` ,requestOptions).then(handleResponse)

}
function changePassword(adminId){
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/${adminId}/change-password` ,requestOptions).then(handleResponse)

}
function getCreatorApplications(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params = offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/super-admin/user/creator-applicant?` + params,requestOptions).then(handleResponse)
}

function revokeCreator(userId){
    const requestOptions = {
        method: 'PUT',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/revoke-creator/${userId}` ,requestOptions).then(handleResponse)

}
function dashboard(){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/dashboard` ,requestOptions).then(handleResponse)

}
function bulkupload(obj){
    const requestOptions = {
        method: 'POST',
        headers: {...adminToken(),'accept' : 'application/json',},
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/bulkImport` ,requestOptions).then(handleResponse)

}
function sendOTP(email){
    const requestOptions = {
        method: 'POST',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/${email}/send-otp` ,requestOptions).then(handleResponse)

}
function getUploadUsers(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params = offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/super-admin/user/bulkImport?` + params,requestOptions).then(handleResponse)
}

function getAllArts(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let starting = obj && obj.starting ? '&starting=' + obj.starting : ''
    let ending = obj && obj.ending ? '&ending=' + obj.ending : ''
    let ownerId = obj && obj.ownerId ? '&ownerId=' + obj.ownerId : ''
    let title = obj && obj.title ? '&titleSearch=' + obj.title : ''
    let ownerName = obj && obj.ownerName ? '&ownerName=' + obj.ownerName : ''


    params = offSet + recordLimit + sortBy + sortOrder + search + starting + ending + ownerId + title + ownerName

    return fetch(`${configPath.apiUrl}/super-admin/art/getAllArts?` + params,requestOptions).then(handleResponse)
}
function getAddressConfig(){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/app-config` ,requestOptions).then(handleResponse)

}
function downloadCSV(){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/csv/download` ,requestOptions)

}
function searchSpotlightArt(art) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/search/arts?searchTerm=${art}`, requestOptions).then(handleResponse);
}
function addSpotlightArt(artId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            artId
        })
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/spotLightArts`, requestOptions).then(handleResponse);
}
function addFeaturedArt(artId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            artId
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredArts`, requestOptions).then(handleResponse);
}
function searchFeaturedCreator(creator) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/search/creators?searchTerm=${creator}`, requestOptions).then(handleResponse);
}
function addFeaturedCreator(userId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            userId
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCreators`, requestOptions).then(handleResponse);
}
function searchFeaturedCollector(collector) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/search/collectors?searchTerm=${collector}`, requestOptions).then(handleResponse);
}
function addFeaturedCollector(userId) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            userId
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCollectors`, requestOptions).then(handleResponse);
}
function updateSpotlightArt(artIds) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            artIds
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/spotLightArts`, requestOptions).then(handleResponse);
}
function updateFeaturedArt(artIds) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            artIds
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredArts`, requestOptions).then(handleResponse);
}
function updateFeaturedCreator(userIds) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            userIds
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCreators`, requestOptions).then(handleResponse);
}
function updateFeaturedCollector(userIds) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify({
            userIds
        })
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCollectors`, requestOptions).then(handleResponse);
}
function updateUserToPremium(obj,userId){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/premium-drop/${userId}`, requestOptions).then(handleResponse);
}function getSpotlightArts() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetch(`${configPath.apiUrl}/super-admin/spotLightArts`, requestOptions).then(handleResponse);
}
function getFeaturedArts() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredArts`, requestOptions).then(handleResponse);
}
function getFeaturedCreators() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCreators`, requestOptions).then(handleResponse);
}
function getFeaturedCollectors() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetch(`${configPath.apiUrl}/super-admin/featuredCollectors`, requestOptions).then(handleResponse);
}
function getArtSaleList(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''



    params =  offSet + recordLimit + sortBy + sortOrder 

    return fetch(`${configPath.apiUrl}/super-admin/drop/sale?` + params,requestOptions).then(handleResponse)
}
function getMintDetail(title){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/art/${encodeURIComponent(title)}/mint-details?` ,requestOptions).then(handleResponse)

}
function getUserWalletAddress(userId){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/getall/${userId}` ,requestOptions).then(handleResponse)

}
function processBlockRange(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: 
            obj
    };
    return fetch(`${configPath.apiUrl}/flow-events/process-block-range`, requestOptions).then(handleResponse);
}
function getEventHistory(obj) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''
    let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

    let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

    let search = obj && obj.search ? '&search=' + obj.search : '';
    let eventType = obj && obj.eventType ? '&eventType=' + obj.eventType : '';
    params = offSet + recordLimit + search + eventType
    return fetch(`${configPath.apiUrl}/super-admin/event-history?` + params, requestOptions).then(handleResponse);
}
function updateDiscoverCount(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/app-config`, requestOptions).then(handleResponse);
}
function getExportData(obj) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''
    let search = obj && obj.search ? '&search=' + obj.search : '';
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : '';
    params = search + roleId

    return fetch(`${configPath.apiUrl}/super-admin/user/export/getall?` + params, requestOptions).then(handleResponse);
}
function addTagsData(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/tag-management/tag`, requestOptions).then(handleResponse);
}
function getTagsData(obj) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''
    let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

    let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

    let search = obj && obj.search ? '&search=' + obj.search : '';
    
    params = offSet + recordLimit + search
    return fetch(`${configPath.apiUrl}/super-admin/tag-management/tag?` + params, requestOptions).then(handleResponse);
}
function getPriorityCreators() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/creator/priority-creator`, requestOptions).then(handleResponse);
}
function addPriorityCreators(userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
 
    };
    return fetch(`${configPath.apiUrl}/super-admin/creator/add-priority/${userId}`, requestOptions).then(handleResponse);
}
function removePriorityCreators(userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
 
    };
    return fetch(`${configPath.apiUrl}/super-admin/creator/remove-priority/${userId}`, requestOptions).then(handleResponse);
}
function getAnnouncement() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/announcement`, requestOptions).then(handleResponse);
}
function addAnnouncement(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/announcement`, requestOptions).then(handleResponse);
}
function getAirdropListing(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let roleId = obj && obj.roleId ? '&roleId=' + obj.roleId : ''


    params =  offSet + recordLimit + sortBy + sortOrder + search + roleId

    return fetch(`${configPath.apiUrl}/super-admin/air-drop?` + params,requestOptions).then(handleResponse)
}
function getAirdropDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/air-drop/${id}`, requestOptions).then(handleResponse);
}
function getCategories() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/nft-category-management/nft-category`, requestOptions).then(handleResponse);
}
function addCategories(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/nft-category-management/nft-category`, requestOptions).then(handleResponse);
}
function getAllTrendingTags() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/trending-tag/`, requestOptions).then(handleResponse);
}
function addTrendingTags(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/trending-tag`, requestOptions).then(handleResponse);
}
function deleteTrendingTags(tagId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/trending-tag/${tagId}`, requestOptions).then(handleResponse);
}
function fetchuserAirdrops(email){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/air-drop/email/${email}`, requestOptions).then(handleResponse);
}

function getAllGlitterEvents() {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/glittering-texts`, requestOptions).then(handleResponse);
}
function addGlitterEvent(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/glittering-texts`, requestOptions).then(handleResponse);
}
function deleteGlitterEvent(eveId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/glittering-texts/${eveId}`, requestOptions).then(handleResponse);
}
function getOneGlitterEvent(id){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/glittering-texts/${id}`, requestOptions).then(handleResponse);
}
function updateGlitterEvent(obj,id){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/glittering-texts/${id}`, requestOptions).then(handleResponse);
}
function getAllMintslots(){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };

    return fetch(`${configPath.apiUrl}/super-admin/mint-slot-management/mint-slot`, requestOptions).then(handleResponse);
}
function updateMintSlot(obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/mint-slot-management/mint-slot`, requestOptions).then(handleResponse);
}
function updateArtCategory(artId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    
    };
    return fetch(`${configPath.apiUrl}/art-management/art/categoryId/${artId}`, requestOptions).then(handleResponse);
}
function getArtDetail(artId){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/art/${artId}/detail`, requestOptions).then(handleResponse);
}
function updateCategoryParent(id,obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/nft-category-management/nft-category/${id}`, requestOptions).then(handleResponse);
}
function getAdminDropList(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
    let starting = obj && obj.starting ? '&starting=' + obj.starting : ''
    let ending = obj && obj.ending ? '&ending=' + obj.ending : ''
    let ownerId = obj && obj.ownerId ? '&ownerId=' + obj.ownerId : ''
    let title = obj && obj.title ? '&artTitle=' + obj.title : ''
    let ownerName = obj && obj.ownerName ? '&listedUserName=' + obj.ownerName : ''
    let isDropOnly = obj && obj.isDropOnly ? '&priorityDropsOny=' + obj.isDropOnly : ''
	let artType = obj && obj.artType ? "&artType=" + obj.artType : ''
	let priorityRank = obj && obj.priorityRank ? "&priorityRank=" + obj.priorityRank : ''
   
    params = offSet + recordLimit + sortBy + sortOrder + search + starting + ending + ownerId + title + ownerName + isDropOnly + artType + priorityRank

    return fetch(`${configPath.apiUrl}/super-admin/drop-management/drop?` + params,requestOptions).then(handleResponse,handleError)
}
function updatePriorityRank(dropId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/drop-management/drop/${dropId}/priorityRank`, requestOptions).then(handleResponse);
}
function getDiscoverMaxCountLimit(){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/config/discover`, requestOptions).then(handleResponse);
}
function updateDiscoverSettings(obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/config/discover`, requestOptions).then(handleResponse);
}
function getAllMidwestEvents(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''
 
    params =  offSet + recordLimit + sortBy + sortOrder + search 

    return fetch(`${configPath.apiUrl}/convention-management/convention?` + params,requestOptions).then(handleResponse)
}
function getMidwestEventDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   

    return fetch(`${configPath.apiUrl}/convention-management/convention/${id}`,requestOptions).then(handleResponse,handleError)
}
function updateMidwestEvent(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/convention-management/convention`, requestOptions).then(handleResponse,handleError)
}
function addMidwestEvents(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/convention-management/convention`, requestOptions).then(handleResponse,handleError)
}
function addEventGallery(id,obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/convention-management/convention/${id}/gallery`, requestOptions).then(handleResponse,handleError)
}
function getEventGalleryDetail(id,itemId,fileName) {
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
   
    return fetch(`${configPath.apiUrl}/convention-management/convention/${id}/gallery/${itemId}/file/${fileName}`,requestOptions).then(handleResponse,handleError)
}
function getAllEventGallery(obj,id){
    const requestOptions = {
        method: 'GET',
        headers: {...adminToken(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let type = obj && obj.type ? '&type=' + obj.type : ''
 
    params =  offSet + recordLimit + sortBy + sortOrder + type 

    return fetch(`${configPath.apiUrl}/convention-management/convention/${id}/gallery?` + params,requestOptions).then(handleResponse)
}
function deleteEventGallery(id,itemId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/convention-management/convention/${id}/gallery/${itemId}`, requestOptions).then(handleResponse,handleError)
}

function getAnnouncementHistory(){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/announcement-history`, requestOptions).then(handleResponse);
}
function deleteAnnouncement(a_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/announcement-history/${a_id}`, requestOptions).then(handleResponse);
}
function updateStoreStatusForCreator(obj,uuid) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/user/${uuid}/storeStatus`, requestOptions).then(handleResponse,handleError)
}
function getAllAdminAccount(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let type = obj && obj.type ? '&type=' + obj.type : ''
 
    params =  offSet + recordLimit + sortBy + sortOrder + type 
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user?` + params, requestOptions).then(handleResponse);
}
function addAdminAccount(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user`, requestOptions).then(handleResponse,handleError)
}
function enableAdminAccount(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user/${id}/accountStatus/enable`, requestOptions).then(handleResponse,handleError)
}
function disableAdminAccount(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user/${id}/accountStatus/disable`, requestOptions).then(handleResponse,handleError)
}
function getAbuseReport(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let type = obj && obj.type ? '&type=' + obj.type : ''
 
    let reportStatus = obj && obj.reportStatus ? '&reportStatus=' + obj.reportStatus : ''

    params =  offSet + recordLimit + sortBy + sortOrder + type + reportStatus
    return fetch(`${configPath.apiUrl}/abuse-report-management/abuse-report?` + params, requestOptions).then(handleResponse);
}
function updateAbuseReport(obj) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/abuse-report-management/abuse-report`, requestOptions).then(handleResponse,handleError)
}
function getAdminUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user/${id}`, requestOptions).then(handleResponse,handleError)
}
function adminresetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user/${email}/reset-password`, requestOptions).then(handleResponse,handleError)
}
function adminChangePassword(obj,publicId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    };
    return fetch(`${configPath.apiUrl}/super-admin/admin-user-management/user/${publicId}/change-password`, requestOptions).then(handleResponse,handleError)
}
function listDropHistory(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let type = obj && obj.type ? '&type=' + obj.type : ''
 
    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + type + search
    return fetch(`${configPath.apiUrl}/drop-history-management/dapper?` + params, requestOptions).then(handleResponse);
}
function getdropHistoryDetail(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/drop-history-management/dapper/${id}`, requestOptions).then(handleResponse,handleError)
}
function dropHistoryRelisting(obj,id) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    };
    return fetch(`${configPath.apiUrl}/drop-history-management/dapper/${id}/token-relisting`, requestOptions).then(handleResponse,handleError)
}
function dropHistoryrelistSuccess(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/drop/dapper/for-sale/${id}/listing/completion`, requestOptions).then(handleResponse,handleError)
}
function getStores(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''


    params =  offSet + recordLimit + sortBy + sortOrder + search
    return fetch(`${configPath.apiUrl}/super-admin/store?` + params, requestOptions).then(handleResponse);
}
function getStoreDetail(uuid){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetch(`${configPath.apiUrl}/super-admin/store/${uuid}`, requestOptions).then(handleResponse,handleError)
}
function getStoreSalesDetail(uuid,obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    params =  offSet + recordLimit + sortBy + sortOrder 
    
    return fetch(`${configPath.apiUrl}/super-admin/store/${uuid}/sales?` + params, requestOptions).then(handleResponse,handleError)
}
function updateAirdropVisibilityStatus(dropId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body : obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/air-drop/${dropId}/visibility`, requestOptions).then(handleResponse,handleError);
}
function getAllAvailableBadges(){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetch(`${configPath.apiUrl}/super-admin/badges`, requestOptions).then(handleResponse,handleError);
}
function addBadges(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/badges`, requestOptions).then(handleResponse);
}
function updateBadgeStatus(obj,id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/badges/${id}`, requestOptions).then(handleResponse);
}
function getMissedAirdropTokens(obj){
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(),'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    params =  offSet + recordLimit + sortBy + sortOrder 
    
    return fetch(`${configPath.apiUrl}/super-admin/get-missed-airdrops?` + params, requestOptions).then(handleResponse,handleError)
}
function withdrawFromAdminAccount(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: obj
    
    };
    return fetch(`${configPath.apiUrl}/super-admin/withdraw-missed-airdrop`, requestOptions).then(handleResponse,handleError);
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
               localStorage.removeItem('adminDetail')
			}
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || (data && data.message)
			return Promise.reject(error)
		}
		return data;
	});
}
function handleError(err){
    try {
        if (err instanceof TypeError) {
            return Promise.reject(err)
        }else{
			return Promise.reject(`Unexpected error occured : ${err}`)
        }
    }
    catch(err) {
    console.log(err)
  
    }
}