import { configPath } from 'config'
import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import { Helmet } from "react-helmet";

class NYC extends Component {
	render() {
		return (
			<Fragment>
				{/* <Helmet>
                    <title>Blockchain for the culture</title>
                    <meta name="title" content="Explore Blockchain for the culture"></meta>
                    <meta name="description" content={`Explore Blockchain for the culture ${process.env.REACT_APP_SITE_CONTRACT} NFT marketplace`}></meta>
                </Helmet> */}
				<div className="container">
					<div className="cobmain">
					<div className="nyc-present">
						<div className="row">
							<div className="col-12 text-center text-uppercase">
								<span className='barline'></span>
								<h4>Presented by disrupt art & rise new york</h4>
								
							</div>
						</div>
						
						<div className="row">
							<div className='col-12'>
								<img src={`/${configPath.imageAssets}/Jim-Jones.png`} alt="" className="mw-100 nyc-bears" />
							</div>
						</div>

						<div className="row">
							<div className="col-12 text-center">
								<img src={`/${configPath.imageAssets}/nyc-outline.png`} alt="" className="w-100 nyc-city" />
							</div>
						</div>
						<div className="row">
							<div className='col-12 text-center'>
								<h3 className='mt-3'>JUNE 22ND</h3>
							<a href="https://www.eventbrite.com/e/blockchain-for-the-culture-tickets-353831217697" target={'_blank'} className="btn btn-primary px-5 text-uppercase">Register Now</a>						
							</div>
						</div>
						<div className='cob-venue'>
							<div className="row">
								<div className="col-md-12 text-center text-uppercase">
									<div className='pagetitabtn text-center'>
										<h1 className='text-uppercase '>Blockchain
											<span>for the culture</span>
										</h1>
										<h2>Featuring: <span>Jim Jones | Badazz bears | Disrupt the flow</span></h2>
										<div className='nyc-logos'>
											<img src={`/${configPath.imageAssets}/logoaw.png`} alt="" className="mw-100" />
											<img src={`/${configPath.imageAssets}/web3-familia.jpg`} alt="" className="mw-100" />
											<img src={`/${configPath.imageAssets}/Rise-logo.png`} alt="" className="mw-100" />

										</div>
									</div>
								</div>
							</div>
						</div>
						<h3 className='p-3 text-center nyc-address'>Starts at 6pm - New York 43 West 23rd Street, 2nd-6th Floor, NEW YORK, NY 10010</h3>
					</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export { NYC }

