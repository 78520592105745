import { storeCreatorConstants } from "../../constants"
import { adminServices, artServices, storeServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const creatorStoreActions = {
    updateStoreStatusForCreator,
    resetStoreCreatorStatus,
    addstoreCreatorProfile,
    resetAddProfileFailed,
    resetAddStoreCreatorProfile,
    resetGetStoreCreatorProfile,
    getStoreCreatorProfile,
    resetGetStoreCreatorProfilefailed,
    searchArts,
    resetSearchArts,
    resetSearchArtsFailed,
    getUsersArts,
    resetGetUsersArts,
    resetGetUsersArtsFailed,
    storeListArtForSale,
    resetStoreListArtForSale,
    resetListedArtForSaleFailed,
    getStoreSection,
    resetGetStoreSection,
    resetGetStoreSectionFailed,
    createStoreSection,
    resetCreateStoreSection,
    resetCreateStoreSctionFailed,
    getSectionArts,
    resetGetSectionArts,
    addSectionArts,
    resetAddSectionSuccess,
    resetAddSectionFailed,
    deleteSectionArts,
    resetDeleteArtsection,
    resetDeleteArtSectionFailed,
    resetGetSectionArtsFailed,
    storeGetUserDrop,
    resetGetUsersDropsSuccess,
    resetGetUserDropsFailed,
    storeGetArtDetail,
    resetGetArtDetail,
    resetGetArtDetailFailed,
    storeWithdrawArts,
    resetStoreWithdrawArts,
    resetStoreWithdrawArtsFailed,
    storeAddBannerdata,
    resetAddBannerSuccess,
    resetAddBannerFailed,
    storeGetBannerdata,
    resetGetBannerFailed,
    resetGetBannerSuccess,
    storeDeleteBannerData,
    resetDeleteBannerSuccess,
    resetDeleteBannerFailed,
    storeGetSocialDefinition,
    resetGetSocialDefenition,
    resetGetSocialDefinitionFailed,
    storeGetSaleHistory,
    resetGetSaleHistory,
    resetGetSaleHistoryFailed,
    getSectionDetail,
    resetGetSectionDetail,
    resetGetSectionDetailFailed,
    editSectionName,
    resetEditFormSuccess,
    resetEditFormFailed,
    deleteSocialMediaMapLink,
    resetDeleteSocialLink,
    resetDeleteSocialLinkFailed,
    storeupdateFeatureddropsArts,
    resetUpdateFeaturedDropSuccess,
    resetUpdateFeaturedDropFailed,
    removeFromStarredCollection,
    resetRemoveFromStarSuccess,
    resetRemoveFromStarFailed,
    getSectionGalleryItem,
    resetGetgalleryItem,
    resetGetgalleryItemFailed,
    getSectionTimelineItem,
    resetGetTimelineItemFailed,
    resetGetTimelineItems,
    getSectionMetaTags,
    resetGetMetatags,
    resetGetMetatagsFailed,    
    storeGetPurchaseData,
    resetGetPurchaseData,
    resetGetPurchaseDataFailed,
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}


function updateStoreStatusForCreator(obj,uuid){
    return dispatch => {
        showLoader()
        adminServices.updateStoreStatusForCreator(obj,uuid)
        .then(status => {
            let obj = {
                statusCode : 200
            }
                dispatch({type:storeCreatorConstants.UPDATE_STORE_CREATOR_STATUS,updatedCreatorStatus:obj})
                hideLoader()
            
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetStoreCreatorStatus(){
    return dispatch => {
        dispatch({type:storeCreatorConstants.UPDATE_STORE_CREATOR_STATUS,updatedCreatorStatus:{}})
    }
}
function addstoreCreatorProfile(obj){
    return dispatch => {
        showLoader()
        storeServices.addstoreCreatorProfile(obj)
        .then(res => {
            let obj = {
                statusCode : 200
            }
            dispatch({type : storeCreatorConstants.ADD_STORE_CREATOR_PROFILE,profileadded : obj})
            hideLoader()
        },error => {
            hideLoader()
            dispatch({type : storeCreatorConstants.STORE_CREATOT_PROFILE_FAILED,addedProfilefailed : error})
        })
    }
}
function resetAddStoreCreatorProfile(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.ADD_STORE_CREATOR_PROFILE,profileadded : {}})
    }
}
function resetAddProfileFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_CREATOT_PROFILE_FAILED,addedProfilefailed : []})
    }
}
function getStoreCreatorProfile(uuid){
    return dispatch => {
        showLoader()
        storeServices.getStoreCreatorProfile(uuid)
        .then(res => {
            dispatch({type : storeCreatorConstants.GET_STORE_CREATOR_PROFILE,profile : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.GET_STORE_PROFILE_FAILED,getprofilefailed : error})
            hideLoader()
        })
    }
}
function resetGetStoreCreatorProfile(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_STORE_CREATOR_PROFILE,profile : []})
    }
}
function resetGetStoreCreatorProfilefailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_STORE_PROFILE_FAILED,getprofilefailed : ''})
    }
}
function searchArts(art,isDropsOnly){
    return dispatch => {
        showLoader()
        artServices.searchArts(art,isDropsOnly)
        .then(res => {
            dispatch({type : storeCreatorConstants.STORE_SEARCH_ARTS,searchedArts : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_SEARCH_ARTS_FAILED,searchArtsFailed : error})
            hideLoader()
        })
    }
}
function resetSearchArts(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_SEARCH_ARTS,searchedArts:[]})
    }
}
function resetSearchArtsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_SEARCH_ARTS_FAILED,searchArtsFailed : ''})
    }
}
function getUsersArts(obj){
    return dispatch => {
        storeServices.getUsersArts(obj)
        .then(res => {
            dispatch({type: storeCreatorConstants.GET_USERS_ARTS,userArts : res})
        },error => {
            dispatch({type : storeCreatorConstants.GET_USERS_ARTS_FAILED,userArtsFailed:error})
        })
    }
}
function resetGetUsersArts(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_USERS_ARTS,userArts :[]})
    }
}
function resetGetUsersArtsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_USERS_ARTS_FAILED,userArtsFailed:''})
    }
}
function storeListArtForSale(data){
    return dispatch => {
        // showLoader()
        storeServices.storeListArtForSale(data)
        .then(res => {
            dispatch({type: storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE,listedArtForsale : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE_FAILED,listedArtSaleFailed : error})
            hideLoader()
        })
    }
}
function resetStoreListArtForSale(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE,listedArtForsale : []})
    }
}
function resetListedArtForSaleFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_LIST_ARTS_FOR_SALE_FAILED,listedArtSaleFailed : ''})
    }
}
function getStoreSection(data,obj){
    return dispatch => {
        showLoader()
        storeServices.getStoreSection(data,obj)
        .then(res => {
            dispatch({type: storeCreatorConstants.STORE_GET_SECTIONS,storeSections : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SECTIONS_FAILED,storeSectionFailed : error})
            hideLoader()
        })
    }
}
function resetGetStoreSection(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTIONS,storeSections : []})
    }
}
function resetGetStoreSectionFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTIONS_FAILED,storeSectionFailed : ''})
    }
}
function createStoreSection(data,id){
    return dispatch => {
        showLoader()
        storeServices.createStoreSection(data,id)
        .then(res => {
            dispatch({type: storeCreatorConstants.STORE_CREATE_SECTION,createSection : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_CREATE_SECTION_FAILED,createSectionFailed : error})
            hideLoader()
        })
    }
}
function resetCreateStoreSection(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_CREATE_SECTION,createSection : []})
    }
}
function resetCreateStoreSctionFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_CREATE_SECTION_FAILED,createSectionFailed : ''})
    }
}
function getSectionArts(id){
    return dispatch => {
        showLoader()
        storeServices.getSectionArts(id)
        .then(res => {
            dispatch({type : storeCreatorConstants.GET_SECTION1_ARTS,sectionArts : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.GET_SECTION_ARTS_FAILED,getsectionartsFailed : error})
            hideLoader()
        })
    }
}
function resetGetSectionArts(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_SECTION1_ARTS,sectionArts:[]})
    }
}
function resetGetSectionArtsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.GET_SECTION_ARTS_FAILED,getsectionartsFailed:''})
    }
}
function addSectionArts(obj,id){
    return dispatch => {
        showLoader()
        storeServices.addSectionArts(obj,id)
        .then(res => {
            toast.success('Art added successfully!')
            dispatch({type : storeCreatorConstants.STORE_ADD_ARTS_SECTION,sectionadded : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_ADD_ARTS_SECTION_FAILED,addsectionFailed : error})
            toast.error(error)
            hideLoader()
        })
    }
}
function resetAddSectionSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_ADD_ARTS_SECTION,sectionadded : []})
    }
}
function resetAddSectionFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_ADD_ARTS_SECTION_FAILED,addsectionFailed : ''})
    }
}
function deleteSectionArts(obj,id){
    return dispatch => {
        showLoader()
        storeServices.deleteSectionArts(obj,id)
        .then(res => {
            dispatch({type : storeCreatorConstants.STORE_DELETE_ARTS_SECTION,artdeleted : res})
            hideLoader()
        },error => {
            dispatch({type : storeCreatorConstants.STORE_DELETE_ARTS_SECTION_FAILED,deleteSectionFailed : error})
            hideLoader()
        })
    }
}
function resetDeleteArtsection(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_ARTS_SECTION,artdeleted : []})
    }
}
function resetDeleteArtSectionFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_ARTS_SECTION_FAILED,deleteSectionFailed : ''})
    }
}

function storeGetUserDrop(obj){
    return dispatch => {
        storeServices.storeGetUserDrop(obj)
        .then(res => {
            dispatch({type: storeCreatorConstants.STORE_GET_USERS_DROPS_LIST,getUserDrops : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_USERS_DROPS_LIST_FAILED,getUserDropsFailed:error})
        })
    }
}
function resetGetUsersDropsSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_USERS_DROPS_LIST,getUserDrops :[]})
    }
}
function resetGetUserDropsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_USERS_DROPS_LIST_FAILED,getUserDropsFailed:''})
    }
}
function storeGetArtDetail(obj){
    return dispatch => {
        showLoader()
        storeServices.storeGetArtDetail(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_ART_DETAIL,getArtDetail : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_ART_DETAIL_FAILED,getArtDetailFailed:error})
            hideLoader()
        })
    }
}
function resetGetArtDetail(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_ART_DETAIL,getArtDetail :[]})
    }
}
function resetGetArtDetailFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_ART_DETAIL_FAILED,getArtDetailFailed:''})
    }
}
function storeWithdrawArts(obj){
    return dispatch => {
        showLoader()
        storeServices.storeWithdrawArts(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_WITHDRAW_ARTS,withdrawn : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_WITHDRAW_ARTS_FAILED,withdrawnFailed:error})
            hideLoader()
        })
    }
}
function resetStoreWithdrawArts(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_WITHDRAW_ARTS,withdrawn :[]})
    }
}
function resetStoreWithdrawArtsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_WITHDRAW_ARTS_FAILED,withdrawnFailed:''})
    }
}
function storeAddBannerdata(obj){
    return dispatch => {
        showLoader()
        storeServices.storeAddBannerdata(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_ADD_BANNER_DATA,bannedadded : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_ADD_BANNER_DATA_FAILED,bannerAddedFailed:error})
            hideLoader()
        })
    }
}
function resetAddBannerSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_ADD_BANNER_DATA,bannedadded :[]})
    }
}
function resetAddBannerFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_ADD_BANNER_DATA_FAILED,bannerAddedFailed:''})
    }
}
function storeGetBannerdata(obj){
    return dispatch => {
        showLoader()
        storeServices.storeGetBannerdata(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_BANNER_DATA,getBannerData : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_BANNER_DATA_FAILED,getBannerDataFailed:error})
            hideLoader()
        })
    }
}
function resetGetBannerSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_BANNER_DATA,getBannerData :[]})
    }
}
function resetGetBannerFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_BANNER_DATA_FAILED,getBannerDataFailed:''})
    }
}
function storeDeleteBannerData(obj){
    return dispatch => {
        showLoader()
        storeServices.storeDeleteBannerData(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_DELETE_BANNER,deletedBanner : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_DELETE_BANNER_FAILED,deletedBannerFailed:error})
            hideLoader()
        })
    }
}
function resetDeleteBannerSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_BANNER,deletedBanner :[]})
    }
}
function resetDeleteBannerFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_BANNER_FAILED,deletedBannerFailed:''})
    }
}
function storeGetSocialDefinition(obj){
    return dispatch => {
        showLoader()
        storeServices.storeGetSocialDefinition(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION,socialDefenitions : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION_FAILED,socialDefenitionsFailed:error})
            hideLoader()
        })
    }
}
function resetGetSocialDefenition(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION,socialDefenitions :[]})
    }
}
function resetGetSocialDefinitionFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SOCIAL_DEFINITION_FAILED,socialDefenitionsFailed:''})
    }
}
function storeGetSaleHistory(obj){
    return dispatch => {
        // showLoader()
        storeServices.storeGetSaleHistory(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_SALEHISTORY,getSaleHistory : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SALEHISTOTY_FAILED,getSaleHistoryFailed:error})
            hideLoader()
        })
    }
}
function resetGetSaleHistory(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SALEHISTORY,getSaleHistory :[]})
    }
}
function resetGetSaleHistoryFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SALEHISTOTY_FAILED,getSaleHistoryFailed:''})
    }
}
function getSectionDetail(id){
    return dispatch => {
        showLoader()
        storeServices.getSectionDetail(id)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_SECTION_DETAIL,getSectionDetail : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SECTION_DETAIL_FAILED,getSectionDetailFailed:error})
            hideLoader()
        })
    }
}
function resetGetSectionDetail(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_DETAIL,getSectionDetail :[]})
    }
}
function resetGetSectionDetailFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_DETAIL_FAILED,getSectionDetailFailed:''})
    }
}
function editSectionName(obj){
    return dispatch => {
        showLoader()
        storeServices.editSectionName(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_SUBMIT_SECTION_FORM,submitSuccess : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_SUBMIT_SECTION_FORM_FAILED,submitFailed:error})
            hideLoader()
        })
    }
}
function resetEditFormSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_SUBMIT_SECTION_FORM,submitSuccess :[]})
    }
}
function resetEditFormFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_SUBMIT_SECTION_FORM_FAILED,submitFailed:''})
    }
}
function deleteSocialMediaMapLink(obj){
    return dispatch => {
        showLoader()
        storeServices.deleteSocialMediaMapLink(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK,deletedSocialLinks : {statusCode : 200}})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK_FAILED, deletesocialLinkFailed : error})
            hideLoader()
        })
    }
}
function resetDeleteSocialLink(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK,deletedSocialLinks : []})
    }
}
function resetDeleteSocialLinkFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_DELETE_SOCIAL_MEDIA_MAP_LINK_FAILED,deletesocialLinkFailed : ''})
    }
}
function storeupdateFeatureddropsArts(id){
    return dispatch => {
        showLoader()
        storeServices.storeupdateFeatureddropsArts(id)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS,featuredDropUpdated : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS_FAILED, featuredDropUpdatedFailed : error})
            hideLoader()
        })
    }
}
function resetUpdateFeaturedDropSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS,featuredDropUpdated : []})
    }
}
function resetUpdateFeaturedDropFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_UPDATE_FEATURED_DROPS_ARTS_FAILED,featuredDropUpdatedFailed : ''})
    }
}
function removeFromStarredCollection(id){
    return dispatch => {
        showLoader()
        storeServices.removeFromStarredCollection(id)
        .then(res => {
            hideLoader()
            dispatch({type : storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP, removedFeatured : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP_FAILED, removefromFeaturedFailed : error})
            hideLoader()
        })

    }
}
function resetRemoveFromStarSuccess(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP,removedFeatured : []})
    }
}
function resetRemoveFromStarFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_REMOVE_FEATURED_STATUS_DROP_FAILED,removefromFeaturedFailed : ''})
    }
}
function getSectionGalleryItem(id){
    return dispatch => {
        showLoader()
        storeServices.getSectionGalleryItem(id)
        .then(res => {
            hideLoader()
            dispatch({type : storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM, galleryItems : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM_FAILED, galleryItemsFailed : error})
            hideLoader()
        })

    }
}
function resetGetgalleryItem(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM,galleryItems : []})
    }
}
function resetGetgalleryItemFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_GALLERY_ITEM_FAILED,galleryItemsFailed : ''})
    }
}
function getSectionTimelineItem(id){
    return dispatch => {
        showLoader()
        storeServices.getSectionTimelineItem(id)
        .then(res => {
            hideLoader()
            dispatch({type : storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM, timelineItems : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM_FAILED, timelineItemsFailed : error})
            hideLoader()
        })
    }
}
function resetGetTimelineItems(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM,timelineItems : []})
    }
}
function resetGetTimelineItemFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_SECTION_TIMELINE_ITEM_FAILED,timelineItemsFailed : ''})
    }
}
function getSectionMetaTags(){
    return dispatch => {
        showLoader()
        storeServices.getSectionMetaTags()
        .then(res => {
            hideLoader()
            dispatch({type : storeCreatorConstants.STORE_GET_META_TAGS, metatags : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_META_TAGS_FAILED, metatagsFailed : error})
            hideLoader()
        })
    }
}
function resetGetMetatags(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_META_TAGS,metatags : []})
    }
}
function resetGetMetatagsFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_META_TAGS_FAILED,metatagsFailed : ''})
    }
}

function storeGetPurchaseData(obj){
    return dispatch => {
        // showLoader()
        storeServices.storeGetPurchaseData(obj)
        .then(res => {
            hideLoader()
            dispatch({type: storeCreatorConstants.STORE_GET_PURCHASE_DATA, getPurchaseData : res})
        },error => {
            dispatch({type : storeCreatorConstants.STORE_GET_PURCHASE_DATA_FAILED, getPurchaseDataFailed:error})
            hideLoader()
        })
    }
}
function resetGetPurchaseData(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_PURCHASE_DATA,getPurchaseData :[]})
    }
}
function resetGetPurchaseDataFailed(){
    return dispatch => {
        dispatch({type : storeCreatorConstants.STORE_GET_PURCHASE_DATA_FAILED,getPurchaseDataFailed:''})
    }
}