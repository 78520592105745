import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { adminServices } from 'services';
import { discoverActions, userAction } from '../../../store/actions'
import PubSub from 'pubsub-js';
import { toast } from 'react-toastify';

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: 48,
            featureCollector: '',
            spotlight: '',
            featureArt: '',
            featureCreator: '',
            submitted: false,
            limit: 50
        }
    }
    componentDidMount() {
        this.getDiscoverDet()
    }
    getDiscoverDet = () => {
        this.showLoader()
        adminServices.getAddressConfig().then(res => {
            this.hideLoader()
            if ((res && res.statusCode === 200) && res.data) {
                this.setState({
                    // spotlight : res.data && res.data.spotlightMaxLimit,
                    // featureArt : res.data && res.data.featuredArtMaxLimit,
                    // featureCreator : res.data && res.data.featuredCreatorMaxLimit,
                    // featureCollector : res.data && res.data.featuredCollectorMaxLimit,
                    time: res.data && res.data.premiumDropDurationMinutes
                })
            }
        }).catch(err => {
            this.hideLoader()
        })
        this.props.getDiscoverLimits() // get max count limits
    }
    componentDidUpdate(props, state) {
        if ((this.props.maxCounts && this.props.maxCounts.statusCode === 200) && ((this.props.maxCounts) != (props.maxCounts))) {
            this.props.resetGetLimits()
            this.setState({
                spotlight: this.props.maxCounts.data && this.props.maxCounts.data.spotlightMaxLimit,
                featureArt: this.props.maxCounts.data && this.props.maxCounts.data.featuredArtMaxLimit,
                featureCreator: this.props.maxCounts.data && this.props.maxCounts.data.featuredCreatorMaxLimit,
                featureCollector: this.props.maxCounts.data && this.props.maxCounts.data.featuredCollectorMaxLimit,
            })
        }
        if ((this.props.updatedSettings && this.props.updatedSettings.statusCode === 200) && ((this.props.updatedSettings) != (props.updatedSettings))) {
            this.props.resetUpdateSettings()
            toast.success("Discover settings updated successfully")
            this.getDiscoverDet()
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    static getDerivedStateFromProps(props, state) {


    }
    handleSpotChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= this.state.limit)) {
            this.setState({ spotlight: e.target.value })
        } else {
            this.setState({ spotlight: '' })
        }
    }
    handleFeatureArtChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= this.state.limit)) {
            this.setState({ featureArt: e.target.value })
        } else {
            this.setState({ featureArt: '' })
        }
    }
    handleFeatureCreatorChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= this.state.limit)) {
            this.setState({ featureCreator: e.target.value })
        } else {
            this.setState({ featureCreator: '' })

        }
    }
    handleFeatureCollectorChange = (e) => {
        if (((e.target.value) >= 0) && ((e.target.value) % 1 === 0) && (e.target.value <= this.state.limit)) {
            this.setState({ featureCollector: e.target.value })
        } else {
            this.setState({ featureCollector: '' })
        }
    }
    isValidNumber = (e) => {
        if (parseInt(e)) {
            return true
        } else {
            return false
        }
    }
    isValidLength = (e) => {
        if ((e <= this.state.limit)) {
            return true
        } else {
            return false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        const { spotlight, featureArt, featureCreator, featureCollector, time } = this.state

        this.setState({ submitted: true })
        let obj = {}
        if (time) {

            obj['premiumDropDurationMinutes'] = Number(time)
            this.showLoader()
            adminServices.updateDiscoverCount(JSON.stringify(obj)).then(res => {
                this.hideLoader()
                this.getDiscoverDet()
                this.setState({ submitted: false })
                toast.success("Updated Successfully")
            }).catch(err => {
                this.hideLoader()
                console.log(err)
            })
        } else {
            toast.error("Please enter duration in minutes")
        }
    }
    onTimeChange = (e) => {
        if (e.target.value > 0) {
            this.setState({
                time: e.target.value
            })
        } else {
            this.setState({
                time: ''
            })
        }
    }
    handleDiscoverSubmit = (e) => {
        e.preventDefault()
        const { spotlight, featureArt, featureCreator, featureCollector, time } = this.state

        this.setState({ submitted: true })
        let obj = {}
        if (spotlight || featureCollector || featureCreator || featureArt) {
            if (spotlight) {
                obj['spotlightMaxLimit'] = Number(spotlight)
            } else {
                return false
            }
            if (featureCollector) {
                obj['featuredCollectorMaxLimit'] = Number(featureCollector)
            } else {
                return false
            }
            if (featureCreator) {
                obj['featuredCreatorMaxLimit'] = Number(featureCreator)
            } else {
                return false
            }
            if (featureArt) {
                obj['featuredArtMaxLimit'] = Number(featureArt)
            } else {
                return false
            }
            this.props.updateDiscoverSettings(JSON.stringify(obj))
        } else {
            toast.error("Please fill the mandatory fields")
        }
    }
    render() {
        return (
            <Fragment>
                <div className="smallilac px-3">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Settings</h2>  </div>
                        <div className="col-sm-6 text-right">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='purchasebox'>
                                <form onSubmit={this.handleSubmit}>

                                    <div className="form-group mb-4 col-md-6 offset-md-2">
                                        <label htmlFor="time " className="font-weight-bold">Premium Drops (minutes)</label>

                                        <div className="input-group dollorsec">
                                            <input type="text" className="form-control" id="time" aria-describedby="time" value={this.state.time} onChange={this.onTimeChange} />
                                            <div className="input-group-append ">
                                                <span className="input-group-text bg-white" id="basic-addon2">Minutes</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4 col-md-6 offset-md-2 text-center">
                                        <button type="submit" className="btn btn-primary ">Submit</button>
                                    </div>
                                </form>
                            </div>
                            <div className='purchasebox'>
                            <div className="col-sm-6"><h2 className="font-weight-bold mb-3">Discover Settings</h2>  </div>

                            <form>
                                <div className="form-group mb-4 col-md-6 offset-md-2">
                                    <label htmlFor="spotlight">Spotlight Count</label>
                                    <input type="text" name="spotlight" id="spotlight" onChange={this.handleSpotChange} value={this.state.spotlight} className="form-control" placeholder="Enter spotlight count here" />
                                    {<div className="mandatory-req">Maximum count is {this.state.limit}</div>}
                                    {this.state.submitted && !this.state.spotlight && <div className="mandatory">Spotlight should have atleat 1 count</div>}
                                    {this.state.submitted && this.state.spotlight && !this.isValidNumber(this.state.spotlight) && <div className="mandatory">Invalid Count</div>}
                                    {this.state.submitted && this.state.spotlight && !this.isValidLength(this.state.spotlight) && <div className="mandatory">Count must be less than or equal to {this.state.limit}</div>}

                                </div>
                                <div className="form-group mb-4 col-md-6 offset-md-2">
                                    <label htmlFor="featureArt">Featured Arts Count</label>
                                    <input type="text" name="featureArt" id="featureArt" onChange={this.handleFeatureArtChange} value={this.state.featureArt} className="form-control" placeholder="Enter feature art count here" />
                                    {<div className="mandatory-req">Maximum count is {this.state.limit}</div>}
                                    {this.state.submitted && !this.state.featureArt && <div className="mandatory">FeatureArt should have atleat 1 count</div>}
                                    {this.state.submitted && this.state.featureArt && !this.isValidNumber(this.state.featureArt) && <div className="mandatory">Invalid Count</div>}
                                    {/* {!this.state.submitted && <div className="mandatory-req">Maximum count is {this.state.limit}</div>} */}
                                    {this.state.submitted && this.state.featureArt && !this.isValidLength(this.state.featureArt) && <div className="mandatory">Count must be less than or equal to {this.state.limit}</div>}

                                </div>
                                <div className="form-group mb-4 col-md-6 offset-md-2">
                                    <label htmlFor="featureCreator">Featured Creators Count</label>
                                    <input type="text" name="featureCreator" id="featureCreator" onChange={this.handleFeatureCreatorChange} value={this.state.featureCreator} className="form-control" placeholder="Enter feature creator count here" />
                                    {<div className="mandatory-req">Maximum count is {this.state.limit}</div>}
                                    {this.state.submitted && !this.state.featureCreator && <div className="mandatory">FeatureCreator should have atleat 1 count</div>}
                                    {this.state.submitted && this.state.featureCreator && !this.isValidNumber(this.state.featureCreator) && <div className="mandatory">Invalid Count</div>}
                                    {/* {!this.state.submitted && <div className="mandatory-req">Maximum count is {this.state.limit}</div>} */}
                                    {this.state.submitted && this.state.featureCreator && !this.isValidLength(this.state.featureCreator) && <div className="mandatory">Count must be less than or equal to {this.state.limit}</div>}

                                </div>
                                {/* <div className="form-group mb-4 col-md-6 offset-md-2">
                                    <label htmlFor="featureCollector">Feature Collectors Count</label>
                                    <input type="text" name="featureCollector" id="featureCollector" onChange={this.handleFeatureCollectorChange} value={this.state.featureCollector} className="form-control" placeholder="Enter feature collector Here" />
                                    {<div className="mandatory-req">Maximum count is {this.state.limit}</div>}
                                    {this.state.submitted && !this.state.featureCollector && <div className="mandatory">FeatureCollector should have atleat 1 count</div>}
                                    {this.state.submitted && this.state.featureCollector && !this.isValidNumber(this.state.featureCollector) && <div className="mandatory">Invalid Count</div>}
                                    {this.state.submitted && this.state.featureCollector && !this.isValidLength(this.state.featureCollector) && <div className="mandatory">Count must be less than or equal to {this.state.limit}</div>}

                                </div> */}
                                <div className="form-group mb-4 col-md-6 offset-md-2 text-center">
                                    <button type="button" className="btn btn-primary " onClick={this.handleDiscoverSubmit}>Submit</button>
                                </div>
                            </form>
                            </div>
                        </div>

                    </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        bulkResponse: state.user.bulkUser,
        maxCounts: state.discover.maxCounts,
        updatedSettings: state.discover.updatedSettings
    }
}
const actionCreator = {
    bulkUserUpload: userAction.bulkupload,
    resetBulkUpload: userAction.resetBulkUpload,
    getDiscoverLimits: discoverActions.getDiscoverMaxCountLimit,
    resetGetLimits: discoverActions.resetGetDiscoverSettings,
    updateDiscoverSettings: discoverActions.updateDiscoverSettings,
    resetUpdateSettings: discoverActions.resetUpdateDiscoverSettings
}
const connectSettings = connect(mapStateToProps, actionCreator)(Settings)
export { connectSettings as Settings }