import { configPath } from 'config'
import history from 'helpers/history'
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { adminServices } from 'services';
import { userAction } from 'store/actions'
import { flowbalance, fusdbalance } from 'utils';
import PubSub from 'pubsub-js';

class MarketStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            adminAddress: '',
            marketplaceAddress: '',
            adminBalance: '',
            marketBalance: '',
            adminFlowBalance: '',
            marketFlowBalance: ''
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        this.getBalance()
    }
    getBalance = () => {
        this.showLoader()
        adminServices.getAddressConfig().then(res => {
            this.hideLoader()
            this.showLoader()

            // fusdbalance((res && res.data && res.data.marketplaceAddress)).then(m_balance => {
            //     this.hideLoader()
            //     if ((m_balance && m_balance.status) == 200) {
            //         console.log("balan", m_balance)
            //         this.setState({ marketBalance: JSON.parse(m_balance && m_balance.data && m_balance.data.Value) })
            //     }
            // }).catch(error => {
            //     this.hideLoader()
            // })
            flowbalance((res && res.data && res.data.marketplaceAddress)).then(m_balance => {
                if ((m_balance && m_balance.status) == 200) {
                    console.log("balan", m_balance)
                    this.setState({ marketFlowBalance: JSON.parse(m_balance && m_balance.data && m_balance.data.Value) })
                }
            }).catch(error => { })
            this.setState({ adminAddress: (res && res.data && res.data.adminAddress), marketplaceAddress: (res && res.data && res.data.marketplaceAddress) })
        }).catch(err => {
            this.hideLoader()
        })
    }

    handleRefresh = () => {
        this.getBalance()
    }
    render() {
        const { marketBalance, marketFlowBalance, adminBalance, adminFlowBalance, adminAddress, marketplaceAddress } = this.state
        return (
            <Fragment>

                <div className="smallilac px-3">
                    <div className="row mb-3 admintitle">
                        <div className="col-sm-6"><h2 className="font-weight-bold">Market Details</h2>  </div>
                        <div className="col-sm-6 text-right">
                            <div className="btn btn-primary btn-sm px-3" onClick={this.handleRefresh}>
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="purchasebox">
                                <label>Market Address</label>
                                <h4 className="usernamelilac">{marketplaceAddress}</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="purchasebox">
                                <label>FLOW Balance</label>
                                <Link><h4 className="usernamelilac" title={marketFlowBalance && marketFlowBalance}>{marketFlowBalance ? parseFloat(marketFlowBalance).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : '0'} <span className="small text-white">FLOW</span></h4></Link></div>
                        </div>
                        <div className="col-md-4">
                            <div className="purchasebox">
                                <label>FUSD Balance</label>
                                <Link><h4 className="usernamelilac" title={marketBalance && marketBalance}>{(marketBalance && marketBalance > 0) ? parseFloat(marketBalance).toString().match(/^-?\d+(?:\.\d{0,4})?/)[0] : '0'} <span className="small text-white">FUSD</span></h4></Link>
                            </div>
                        </div>
                    </div>


                </div>
            </Fragment>
        )
    }

}

export { MarketStatus }