import { configPath } from "../config";
import { accessHeader, adminToken } from "helpers"
import { fetchService } from "./fetch-service";
import history from '../helpers/history'
import PubSub from 'pubsub-js'; 
import axios from "axios";

export const artServices = {
    uploadArt,
	getArts,
	detailArt,
	checkArt,
	notDrop,
	getArtByToken,
	getRelativeArts,
	getArtTransactionHistory,
	getUserArts,
	getOneUserArts,
	creatorArts,
	artStatusChange,
	getOwnerArts,
	uploadFile,
	uploadFileToIPFS,
	getArtsForArtPage,
	searchArts,
	artMintProgress,
	checkArts,
	artDownloadProcess,
	mimeDownloadProcess,
	getAvailableTokens,
	getArtActivities,
	getArtsForTagName,
	metadataAttr,
	getArtsbyCategory,
	getCategoryArtDetail,
	updateThumbnailnft,
	artsVisibilitystatusChange,
	editArtCategory,
	editArtTag,
	dapperListArtForSale,
	getArtsForRelatedWallet,
	getWalletAddressDetail,
	getCustodialArts,
	custodialToUser,
	artWithSerials,
	getAllAvailableBadges,
	updateBadges,
	getSelectedBadges,
	removeBadges,
	getUsersFavs,
	getAvailableArtWithSerials,
	refreshAvailableArtWithSerials
}

function uploadArt(obj){
    const requestOptions = {
        method: 'POST',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art`,requestOptions).then(handleResponse,handleError)
}

function getArtsForArtPage(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal : obj.signal
	}
	let params = '';
	let offSet = obj && obj.offSet;

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : '';

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : '';

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : '';
	let type = obj && obj.type ? '&type=' + obj.type : '';

	let rarity = obj && obj.rarity ? '&rarity=' + obj.rarity : '';

	params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + type + rarity;

	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/art-page/list?` + params,requestOptions).then(handleResponse,handleError);
}
function getArts(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art?` + params,requestOptions).then(handleResponse,handleError)
}
function checkArt(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art?title=${obj}`,requestOptions).then(handleResponse,handleError)
}
function notDrop(){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art?InDrops`,requestOptions).then(handleResponse,handleError)
}
function detailArt(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		// body : obj
	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/` + params,requestOptions).then(handleResponse,handleError)
}
function getRelativeArts(id,address){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	// return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/token/${id}/related/${address}`,requestOptions).then(handleResponse,handleError)
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/token-group/${id}/related/${address}`,requestOptions).then(handleResponse,handleError)

}
function getArtByToken(obj){
	const requestOptions = {
		method : "GET",
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},

	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/token/` + params,requestOptions).then(handleResponse,handleError)

}

function getArtTransactionHistory(tokenId) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'accept': 'application/json', 'Content-Type': 'application/json' },

	}
	let params = tokenId
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/address/token-transaction-history/` + params, requestOptions).then(handleResponse,handleError)
}
function getUserArts(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal : obj && obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''
	let showHiddenArts = obj && obj.showHiddenArts ? "&showHiddenArts=" + obj.showHiddenArts : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/owner/user?`+ params ,requestOptions).then(handleResponse,handleError)
}
function getOneUserArts(obj,userId){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/user/${userId}?` + params,requestOptions).then(handleResponse,handleError)
}
function artStatusChange(artId,status){
    const requestOptions = {
        method: 'PUT',
        headers: {...adminToken(),'Content-Type':'application/json','accept' : 'application/json'},
		body : status
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/status/${artId}`,requestOptions).then(handleResponse,handleError)
}
function creatorArts(obj,userId){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal : obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/creator/user/${userId}?`+ params ,requestOptions).then(handleResponse,handleError)
}
function getOwnerArts(artId,ownerName,artName){
	
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	let params = ''

	let id = artId ? '&artId=' + artId : ''	

	
		params = id
	
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/name/${encodeURIComponent(ownerName)}/${encodeURIComponent(artName)}?`+ params ,requestOptions).then(handleResponse)
}
function uploadFile(obj,accesstoken){
    const requestOptions = {
        method: 'POST',
        headers: {'Authorization': `Bearer ${accesstoken}`},
        body: obj,
		
    };
    return fetch(`${configPath.uploadPath}`,requestOptions).then(handleResponse,handleError)
}
function searchArts(art,isDropsOnly) {
    const requestOptions = {
        method: 'GET',
        headers: { ...adminToken(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
	let params = isDropsOnly ? "activeDropsOnly=" + true : ''
    return fetch(`${configPath.apiUrl}/art-management/art/search/${art}?` + params, requestOptions).then(handleResponse);
}
function checkArts(art) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/attribute/search?title=${art}`, requestOptions).then(handleResponse);
}
function artMintProgress(art) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
       
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/${encodeURIComponent(art)}/progress`, requestOptions)
}
function artDownloadProcess(tokenId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/download/${tokenId}`, requestOptions)
}
function mimeDownloadProcess(tokenId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/download/mime-type/${tokenId}`, requestOptions).then(handleResponse)
}
function getArtActivities(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		// body : obj
	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/all-arts/` + params,requestOptions).then(handleResponse,handleError)
}
function uploadFileToIPFS(formData,apikey, secretkey ,uploadProgressCallback,cancelToken) {
	const uploadPromise = axios.post(
		`${configPath.uploadPath}`,
		formData,
		{
			cancelToken : cancelToken.token,
			headers: {
				// 'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'multipart/form-data',
				'pinata_api_key': apikey, 
				'pinata_secret_api_key': secretkey
			},
			onUploadProgress: uploadProgressCallback
		}
	);
	return new Promise((resolve, reject) => {
		uploadPromise.then((response) => {
			try {
				const res = JSON.parse(response.data);
				resolve(res)
			} catch (er) {
				resolve(response.data);
			}
		}).catch((e) => {
			if (axios.isAxiosError(e)) {
				if (e.response) {
					if(e.response && e.response.data && e.response.data.error && e.response.data.error.details){
						const error = e.response.data.error.details;
						reject(error);
					}else{
						const error = e.response.data && e.response.data.message;
						reject(error);
					}
					
				} else if (e.response) {
					const error = 'Unable to reach ' + configPath.uploadPath;
					reject(error);
				} else {
					const error = 'Failed to upload to ' + configPath.uploadPath;
					reject(error);
				}
			} else {
				const error = 'Unhandled error occured while uploading';
				reject(error);
				console.error(e);
			}
		})
	});
}
function getAvailableTokens(tokenGroupId, title,count) {
    const payLoad = {
        tokenGroupId,
        title,
        count
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(payLoad)
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/available-tokens`, requestOptions).then(handleResponse, handleError);
}
function getArtsForTagName(tagName, queryParams) {
	const requestOptions = {
		method: 'GET',
		headers: { ...accessHeader(), 'accept': 'application/json', 'Content-Type': 'application/json' },
		signal : queryParams && queryParams.signal
	}
	let params = '';
	let offSet = queryParams && queryParams.offSet;

	let recordLimit = queryParams && queryParams.recordLimit ? '&recordLimit=' + queryParams.recordLimit : '';

	let sortBy = queryParams && queryParams.sortBy ? "&sortBy=" + queryParams.sortBy : '';

	let sortOrder = queryParams && queryParams.sortOrder ? '&sortOrder=' + queryParams.sortOrder : '';
	let type = queryParams && queryParams.type ? '&type=' + queryParams.type : '';

	let rarity = queryParams && queryParams.rarity ? '&rarity=' + queryParams.rarity : '';

	params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + type + rarity;
	const encodedTagName = encodeURIComponent(tagName);
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/tag/${encodedTagName}?` + params, requestOptions).then(handleResponse, handleError);
}
function metadataAttr(url){
	const uploadPromise = axios.get(url)
	return new Promise((resolve, reject) => {
		uploadPromise.then((response) => {
			try {
				const res = JSON.parse(response.data);
				resolve(res)
			} catch (er) {
				resolve(response.data);
			}
		}).catch((e) => {
			if (axios.isAxiosError(e)) {
				if (e.response) {
					if(e.response && e.response.data && e.response.data.error && e.response.data.error.details){
						const error = e.response.data.error.details;
						reject(error);
					}else{
						const error = e.response.data && e.response.data.message;
						reject(error);
					}
					
				} else if (e.response) {
					const error = 'Unable to reach ' + configPath.uploadPath;
					reject(error);
				} else {
					const error = 'Failed to upload to ' + configPath.uploadPath;
					reject(error);
				}
			} else {
				const error = 'Unhandled error occured while uploading';
				reject(error);
				console.error(e);
			}
		})
	});
	// return fetchService.fetchResource(`${url}`,requestOptions).then(handleResponse,handleError)
}
function getArtsbyCategory(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal : obj.signal
	}
	let params = '';
	let offSet = obj && obj.offSet;

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : '';

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : '';

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : '';
	let type = obj && obj.type ? '&type=' + obj.type : '';

	let rarity = obj && obj.rarity ? '&rarity=' + obj.rarity : '';

	params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + type + rarity;
	const encodedName = encodeURIComponent(obj.categoryName);
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/nft-category/${encodedName}?` + params,requestOptions).then(handleResponse,handleError)
}
function getCategoryArtDetail(categoryName) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/nft-category-management/nft-category/${categoryName}`, requestOptions).then(handleResponse,handleError)
}
function updateThumbnailnft(obj,artId){
	const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'accept': 'application/json' },
        body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/thumbnail/${artId}`, requestOptions).then(handleThumbnailResponse, handleError)
}
function artsVisibilitystatusChange(obj,artId){
	const requestOptions = {
        method: 'PUT',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
        body: JSON.stringify(obj)
    }
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/visibility/${artId}`, requestOptions).then(handleThumbnailResponse, handleError)

}
function editArtCategory(artId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
		body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/${artId}/nft-category`,requestOptions).then(handleResponse,handleError)
}
function editArtTag(artId,obj){
    const requestOptions = {
        method: 'PUT',
        headers: {...accessHeader(),'Content-Type':'application/json','accept' : 'application/json'},
		body : obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/${artId}/tag`,requestOptions).then(handleResponse,handleError)
}
function dapperListArtForSale(obj){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/dapper/drop` ,requestOptions).then(handleResponse,handleError)
}
function getArtsForRelatedWallet(walletAddress,artId,obj){
	const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
	let params = '';

	let fields = obj && obj.fields ? '&fields=' + obj.fields : '';
	let count = obj && obj.count ? `&count=` + obj.count : '';
	params = fields + count
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/wallet-account/${walletAddress}/art/${artId}?` + params, requestOptions).then(handleResponse,handleError)
}
function getWalletAddressDetail(id){
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/${id}/wallet-account`, requestOptions).then(handleResponse, handleError)
}
function getCustodialArts(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal: obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''
	let showHiddenArts = obj && obj.showHiddenArts ? "&showHiddenArts=" + obj.showHiddenArts : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/owner/custodial-wallet?`+ params ,requestOptions).then(handleResponse,handleError)
}
function custodialToUser(obj){
    const requestOptions = {
        method : 'POST',
        headers :  {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body : obj
    }
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/transfer/custodial-to-user` ,requestOptions)
}
function artWithSerials(groupId){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art-group/${groupId}/art-with-serials`,requestOptions).then(handleResponse,handleError)
}
function getAllAvailableBadges() {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/badges`, requestOptions).then(handleResponse,handleError)
}
function updateBadges(obj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/badges`, requestOptions).then(handleResponse,handleError)
}
function getSelectedBadges(artId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/badges/${artId}`, requestOptions).then(handleResponse,handleError)
}
function removeBadges(artId) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
    };
    return fetchService.fetchResource(`${configPath.apiUrl}/art-management/badges/${artId}`, requestOptions)
}
function getUsersFavs(obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		signal : obj && obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet 

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''
	let showHiddenArts = obj && obj.showHiddenArts ? "&showHiddenArts=" + obj.showHiddenArts : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''
	

	if(notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	}else{
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art/owner/user?`+ params ,requestOptions).then(handleResponse,handleError)
}
function getAvailableArtWithSerials(groupId,address){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art-group/${groupId}/available-editions/${address}`,requestOptions).then(handleResponse,handleError)
}
function refreshAvailableArtWithSerials(groupId){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/art-management/art-group/${groupId}/update-edition-number`,requestOptions).then(handleResponse,handleError)
}
function handleThumbnailResponse(response){
	if(!response.ok){
		return response.text().then(text => {
			const data = text && JSON.parse(text);
			if (response.status === 403) {
				// logout();
			}else if(response.status ===400){
				if (data.message && data.message.toLowerCase().includes('not found') ) {
					history.push('/notFound');
				}else if(data.message && data.message.toLowerCase().includes('does not exist')){
					history.push('/notFound');
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		})	
		
	}else{
		return {
			statusCode : 200
		}
	}
}
function handleResponse(response) {
	if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
				// logout();
			}else if(response.status ===400){
				if (data.message && data.message.toLowerCase().includes('not found') ) {
					history.push('/notFound');
				}else if(data.message && data.message.toLowerCase().includes('does not exist')){
					history.push('/notFound');
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
			
		}
		return data;
	});
}
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            return Promise.reject('TypeError : Failed to fetch')
        }else{
            PubSub.publish('msg', false)
			return Promise.reject(`Unexpected error occured : ${err}`)
        }
    }
    catch(err) {
    console.log(err)
  
    }
}