import React from 'react'
import { Fragment } from 'react';
import { Component } from 'react';
import history from 'helpers/history';
import { MarketTokenPrice, NarketTokenOwner, NarketTokenCreator,marketSaleWithdrawn, changePrice, getUserAddress, RetrieveMarketToken, getDisplayMessageForRuntimeTxnError} from 'utils';
import PubSub from 'pubsub-js';
import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { artServices, dropServices } from 'services';
import { artActions, dropActions } from 'store/actions';
import { configPath } from 'config';

class MarketDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
            data : [],
            dropData :[],
            dropList : [],
            owner : '',
            changedPrice:'',
            price : '',
            creator:'',
            relativeArts :[],
            usd:'',
            rate :'',
            noData:false,
            isSuccess : false,
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount(){
       this.getDetailArt()

        // if(this.props && this.props.location && this.props.location.state && this.props.location.state.data){
        //     // data = this.props.location.state.data && this.props.location.state.data.items
        //     userAddress = this.props.location.state.data && this.props.location.state.data.items && this.props.location.state.data.items.userAddress
        //     tokenId = this.props.location.state.data && this.props.location.state.data.items && this.props.location.state.data.items.tokenId
        // }        
        // if(this.props && this.props.location && this.props.location.state && this.props.location.state.dropObj){
        //     // dropData = this.props.location.state.dropObj && this.props.location.state.dropObj.items ? this.props.location.state.dropObj.items : []
        //     tokenId = this.props.location.state.dropObj && this.props.location.state.dropObj.items && this.props.location.state.dropObj.items.tokenId
        //     userAddress = this.props.location.state.dropObj && this.props.location.state.dropObj.items && this.props.location.state.dropObj.items.art && this.props.location.state.dropObj.items.art.owner && this.props.location.state.dropObj.items.art.owner.userAddress ? this.props.location.state.dropObj.items.art.owner.userAddress : ''
        //     console.log("drop",dropData,tokenId,userAddress)
        //     // this.setState({dropData})

        // }
            // this.showLoader()
           
            

            // this.getRelativeArts()

        
        
    }
   
   
   
//    
    static getDerivedStateFromProps(props,state){
        let rate = ""
        if(props){
            props && props.usd && props.usd.data && props.usd.data.src_side_base && props.usd.data.src_side_base.map(item => {
                if((item && item.asset) == "USD"){
                    rate = item.rate
                }
            })
            
            return {
                rate,
                relativeArts : props.relativeArts && props.relativeArts.data && props.relativeArts.data.entity ? props.relativeArts.data.entity : []
                // tokenId :  props.dropList && props.dropList.data && props.dropList.data.art && props.dropList.data.art.tokenId,
                // userAddress : props.dropList && props.dropList.data && props.dropList.data.art && props.dropList.data.art.ownerUserAddress ? props.dropList.data.art.ownerUserAddress : '',
                

            }
        }
    }

    getDetailArt = () => {
        let userAddress = ""
        let tokenId = ""
        let dropList = []
        let id = ''
        console.log(this.props && this.props.match && this.props.match.params)
        if(this.props && this.props.match && this.props.match.params && this.props.match.params.id && (this.props.match.params.id != "undefined")){
            id = this.props.match.params.id
        }else{
            return this.setState({noData:true})
        }
        // this.props.getDetailDropArt(id)
        this.showLoader()
        dropServices.getDetailDropArt(id).then(res => {
            this.hideLoader()
            if((res && res.statusCode) == 200){
            userAddress = res && res.data && res.data.art && res.data.art.ownerUserAddress
            dropList = res && res.data ? res.data : []
            tokenId =  res && res.data && res.data.tokenId
            this.setState({dropList,tokenId})

            this.props.getRelativeArts(tokenId,userAddress)

            this.showLoader()
            NarketTokenCreator(userAddress,tokenId).then(res => {
                this.hideLoader()
                this.setState({creator : res && res.data && res.data.Value && res.data.Value.value && res.data.Value.value.value})
            })
            .catch(err => {
                this.hideLoader()
            })

            this.showLoader()
            NarketTokenOwner(userAddress,tokenId).then(res => {
                this.hideLoader()
                this.setState({owner:res && res.data && res.data.Value && res.data.Value.value && res.data.Value.value.value})
            })
            .catch(err => {
                this.hideLoader()
            })

            this.showLoader()
            MarketTokenPrice(userAddress,tokenId).then(res => {
                this.hideLoader()
                this.setState({price: res && res.data && res.data.Value && res.data.Value.value})
            })
            .catch(err => {
                this.hideLoader()
            })
            //}
        }
        })
        .catch(err => {
            this.hideLoader()
        })


    }
    

    getRelativeArts = () => {
        let creator = ""
        getUserAddress().then(user => {
        let relativeArray = []
        this.showLoader()
        RetrieveMarketToken(user).then(res => {
            this.hideLoader()
            if ((res && res.status) == 200) {
                res && res.data && res.data.Value && res.data.Value.map(items => {
                    this.showLoader()
                    artServices.getArtByToken(items && items.value).then(art => {
                        this.hideLoader()
                        if((art && art.statusCode) == 200){
                            relativeArray.push(art && art.data)
                            this.setState({relativeArray})
                        }
                    })
                    .catch(err => {
                        this.hideLoader()
                    })

                })
                
        }
        })
    })
    }
    priceChange = (e) => {
        if(e.target.value >= 0){
        const rate = this.state.rate ? (this.state.rate * e.target.value) : ''

        this.setState({changedPrice : e.target.value,usd:rate ? rate.toFixed(2) : ''})
        }else{
            toast.error("Please enter valid price",{position:toast.POSITION.BOTTOM_CENTER})
        }
    }
    submitPrice = (e) => {
        e.preventDefault()
        let basePrice = ''
        const num = (Number(this.state.changedPrice))
        // const precision = num.toPrecision(5)
        const precision = num.toFixed(8)

        // if (this.state.changedPrice && Number.isInteger(num)) {
            basePrice = precision
        // } else {
        //     basePrice = this.state.changedPrice
        // }
        if(basePrice && basePrice > 0){
        this.showLoader()
        changePrice(this.state.tokenId,basePrice).then(res => {
            this.hideLoader()
            if((res && res.status) == 200){
                if(res.data && res.data.transaction && res.data.transaction.errorMessage){
                    console.log("price Changederr",res)
                    this.setState({changedPrice:'',usd:''})
                    toast.error(getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage),{position:toast.POSITION.BOTTOM_CENTER})
                }else{
                console.log("price Changed",res)
                // toast.success("Price Changed",{position:toast.POSITION.BOTTOM_CENTER})
                // history.goBack()
                this.setState({price:this.state.changedPrice,changedPrice:'',usd:'',isSuccess:true})
                }
            }else{
                this.setState({changedPrice:'',usd:''})
                toast.error("User rejected signature",{position:toast.POSITION.BOTTOM_CENTER})
            }
        })
    }else{
        toast.error("Please enter valid price",{position:toast.POSITION.BOTTOM_CENTER})
    }
    }
    componentDidUpdate(props,nextProps){
        if((props && props.location && props.location.pathname) != (window.location.pathname)){
            this.getDetailArt()
        }
    }
    gotoDetail = (items) => {
        if((items && items.drops && items.drops[0] && items.drops[0].id)){
            history.push(`/marketDetail/${items.drops[0].id}`)
        }
        else{
            history.push(`/art/${items && items.id}`)
        }
    }
    handleResale = (e) => {
        e.preventDefault()
        this.showLoader()
        marketSaleWithdrawn(this.state.tokenId).then(res => {
            console.log("msale",res)
            this.hideLoader()
            if((res && res.status) == 200){
                if(res.data && res.data.transaction && res.data.transaction.errorMessage){
                    toast.error(getDisplayMessageForRuntimeTxnError(res.data.transaction.errorMessage),{position:toast.POSITION.BOTTOM_CENTER})
                }else{

                toast.success("Token Removed From Market",{position:toast.POSITION.BOTTOM_CENTER})
                history.goBack()
                }
            }else{
                toast.error("User rejected signature",{position:toast.POSITION.BOTTOM_CENTER})
            }
        })
    }
    render(){
        const {data,dropData,relativeArray,dropList,creator,tokenId,userAddress,relativeArts} = this.state
        console.log("relativeArts",relativeArts,dropList)

        return (
            <Fragment>
                {this.state.isSuccess && 
                    <div className="show-success">
                        <img src={`https://ipfs.infura.io/ipfs/${dropList && dropList.art && dropList.art.imageHash}`} width="170px" />
                        <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <div className="mt-3"><b>Price Changed Successfully...!</b></div>
                        <div className="socialicons mt-3"><a href="https://twitter.com/DisruptNowio" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disruptnow.io/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.com/users/DisruptNow#5704" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div>
                        {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                        <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/drops') }}>Go To Drop</button></div>
                }
                {/* <ToastContainer autoClose={3000}/> */}
                   {dropList && dropList.length != 0 && <div className="modal fade" id="changeprice" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Change Price</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {this.setState({changedPrice:"",usd:''})}}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group dollorsec">
                                    <input type="number" value={this.state.changedPrice} onChange={this.priceChange} className="form-control"/>
                                    <div className="input-group-append ">
                                        <span className="input-group-text bg-white" id="basic-addon2">Flow</span>
                                    </div>
                                    <div className="input-group-append dollorsec">
                                                            <span className="input-group-text disabled" id="basic-addon2">{`$ ${this.state.usd}`}</span>
                                                        </div>

                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {this.setState({changedPrice:"",usd:''})}}>Close</button> */}
                                <button type="button" className="btn btn-primary" onClick={this.submitPrice} data-dismiss={(this.state.changedPrice && this.state.changedPrice > 0 ) ? "modal" : ''}>Save changes</button>
                            </div>
                            </div>
                        </div>
                    </div>}
                <div className="detailWallet">
                <div className="container">
                        <h1 className="mb-3">
                            Art Detail <button type="button" className="btn btn-primary px-5 btn-sm float-right" data-toggle="modal" data-target="#changeprice">Change Price</button>                            
                        </h1>
                        {dropList && dropList.length != 0 &&  <div className="card mb-3">
                            <div className="card-body">
                                <div className="det-head"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* {data && data.length != 0 && <img src={((`https://ipfs.infura.io/ipfs/${data.imageHash}`))} className="mw-100" alt="" />}
                                        {dropData && dropData.length != 0 && <img src={(dropData && dropData.art && dropData.art.imageHash && (`https://ipfs.infura.io/ipfs/${dropData.art.imageHash}`))} className="mw-100" alt="" />} */}
                                        <img src={(dropList && dropList.art && dropList.art.imageHash && (`https://ipfs.infura.io/ipfs/${dropList.art.imageHash}`))} className="mw-100" alt="" />

                                    </div>
                                    <div className="col-md-6">
                                        <div className="det-align py-3 row">
                                            <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Title :</h5>
                                                {/* {data && data.length != 0 && <h4 className="mb-3 d-inline-block ml-3"> {data && data.title}</h4>}
                                                {dropData && dropData.length != 0 && <h4 className="mb-3 d-inline-block ml-3"> {dropData && dropData.art && dropData.art.title}</h4>} */}
                                                <h4 className="mb-3 d-inline-block ml-3"> {dropList && dropList.art  && dropList.art.title}</h4>

                                            </div>

                                            {this.state.price && <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Price :</h5>
                                                <div className="mb-3 d-inline-block ml-3">{this.state.price + ' ' +  '$' + '(' + ((this.state.price * this.state.rate).toFixed(2)) + ')'}</div>
                                            </div>}

                                            {this.state.creator && <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Creator :</h5>
                                                <h4 className="mb-3 d-inline-block ml-3"> {this.state.creator}</h4>
                                            </div>}

                                            {this.state.owner && <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Owner :</h5>
                                                <h4 className="mb-3 d-inline-block ml-3"> {this.state.owner}</h4>
                                            </div>}
                                            
                                            {/* <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Royalty :</h5>
                                               
                                                <h4 className="mb-3 d-inline-block ml-3"> {dropList && dropList.art && dropList.art.royalty}</h4>


                                            </div> */}

                                            <div className="col-md-12">
                                                <h5 className="mb-3 d-inline-block">Total Copies :</h5>
                                                {/* {data && data.length != 0 && <h4 className="mb-3 d-inline-block ml-3"> {data && data.totalCopies}</h4>}
                                                {dropData && dropData.length != 0 && <h4 className="mb-3 d-inline-block ml-3"> {dropData && dropData.art && dropData.art.totalCopies}</h4>} */}
                                                <h4 className="mb-3 d-inline-block ml-3"> {dropList && dropList.art && dropList.art.totalCopies}</h4>


                                            </div>

                                            <div className="text-center col-md-12">
                                            <hr />
                                                <button type="button" className="btn btn-primary mr-3 px-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Back</button>
                                                <button type="button" className="btn btn-primary px-5" onClick={this.handleResale}>Withdraw</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>}
                        { relativeArts && relativeArts.length > 0 && <div className="container">
                        <h4 className="mb-3">{dropList && dropList.art && dropList.art.owner && dropList.art.owner.userName ? ` More From ${dropList.art.owner.userName}` : "More Arts"}</h4>

                        <div className="card progallists mb-5">
                            <div className="card-body">
                                <div className="row">
                                    {relativeArts && relativeArts.map((items,index) => (

                                    <div className="col-12 col-sm-6 col-md-3" onClick={()=> this.gotoDetail(items)}>
                                        <div className="cardlist">

                                        <span className="artcovimg ">
                                            <span className="artcov"></span>
                                            <span className="artcovimginset">
                                                <img src={items && items.imageHash ? `https://ipfs.infura.io/ipfs/${items && items.imageHash}` : `${configPath.imageAssets}/art-pic1.jpg`} className="comimg mb-0" />
                                            </span>
                                        </span>
                                        <span className="cont">
                                                    <h3>{items && items.title ? items.title : "Title"}</h3>
                                                    <h6>{items && items.drops && items.drops[0] && items.drops[0].markedPrice ? (items.drops[0].markedPrice + ' '+'Flow' + '( $' + (items.drops[0].markedPrice * this.state.rate).toFixed(2) + ")") : "Not for sale"}</h6>
                                                </span>
                                        </div>
                                    </div>))}
                                    
                                </div>
                            </div>
                        </div>
                        </div>}
                        
                    </div>
                    {/* {this.state.noData && 
                         <div className="col-md-4 offset-md-4 text-center">
                         <img src={"../nft/assets/images/no-images.png"} className="mw-100" alt="" />
                     </div>
                        } */}
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        usd: state.user.usd,
        dropList : state.drop.detailDrop,
        relativeArts : state.art.relativeArts

    }
}
const actionCreators = {
    getDetailDropArt: dropActions.getDetailDropArt,
    getRelativeArts : artActions.getRelativeArts


}

const connectMarketDetail = connect(mapStateToProps,actionCreators)(MarketDetail)

export {connectMarketDetail as MarketDetail}