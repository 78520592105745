
export function checkWalletAddress(address1,address2){

        var add1 = new String(address1);
        var add2 = new String(address2);
        var isEquel = JSON.stringify(add1) === JSON.stringify(add2);
        if(isEquel){
             return true
        }else {
            return false
        }
    
        
   
}
