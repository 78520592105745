import { dropConstants } from "../../constants";

const initialState = {
    drop: false,
    dropList: [],
    detailDrop: [],
    userDropList: [],
    singleUserDrop: [],
    isCheckDrop: false,
    isCheckUserDrop: false,
    auctionList: [],
    saleArtList: [],
    isCheckArtDrop: false,
    admindropList: [],
    priorityDropUpdated : {},
    withdrawnArt: [],
    withdrawArtFailed: ''
}
export function drop(state = initialState, action) {
    switch (action.type) {
        case dropConstants.UPLOAD_DROP:
            return {
                drop: action.drop
            }
        case dropConstants.GET_DROP:
            return {
                dropList: action.dropList,
                isCheckDrop: action.isCheckDrop
            }
        case dropConstants.DETAIL_DROP_ART:
            return {
                detailDrop: action.detailDrop
            }
        case dropConstants.GET_USER_DROP:
            return {
                ...state,
                userDropList: action.userDropList,
                isCheckArtDrop: action.isCheckArtDrop
            }
        case dropConstants.GET_ONE_USER_DROP:
            return {
                singleUserDrop: action.singleUserDrop,
                isCheckUserDrop: action.isCheckUserDrop
            }
        case dropConstants.GET_AUCTION_DETAIL:
            return {
                auctionList: action.auctionList
            }
        case dropConstants.GET_ART_SALE_LIST:
            return {
                saleArtList: action.saleArtList
            }
        case dropConstants.GET_ADMIN_DROP_LIST:
            return {
                ...state,
                admindropList: action.admindropList
            }
        case dropConstants.UPDATE_PRIORITY_RANK:
            return {
                ...state,
                priorityDropUpdated: action.priorityDropUpdated
            }
        case dropConstants.WITHDRAW_ART:
            return {
                ...state,
                withdrawnArt: action.withdrawnArt
            }
        case dropConstants.WITHDRAW_ART_FAILED:
            return {
                ...state,
                withdrawArtFailed: action.withdrawArtFailed
            }

        default:
            return state
    }
}