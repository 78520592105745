import { flowConstants } from "../../constants";

const initialState = {
    bloctoDisconnected : false
}
export function flow(state=initialState,action){
    switch(action.type){
        case flowConstants.DISCONNECT_BLOCTO_WALLET:
            return {
                bloctoDisconnected : action.bloctoDisconnected
            }
     
            default:
                return state
    }
}