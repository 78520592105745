import { configPath } from "../config";
import PubSub from 'pubsub-js'; 
import { fetchService } from "./fetch-service";
import { accessHeader } from "helpers";

export const ipfsServices = {
    getAccessToken,
    getApiSecretKeys,
    revokeKeys
 }

function getAccessToken(){
	const requestOptions = {
		method : 'GET',
		headers : {'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/ipfs-service/auth/access-token`,requestOptions).then(handleResponse,handleError)
}
function getApiSecretKeys(){
	const requestOptions = {
		method : 'POST',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/ipfs-service/api-key`,requestOptions).then(handleResponse,handleError)
}
function revokeKeys(keys){
	const requestOptions = {
		method : 'PUT',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
        body: keys
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/ipfs-service/api-key/revoke`,requestOptions).then(handleResponse,handleError)
}
function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 403) {
               localStorage.removeItem('adminDetail')
			}
            const error = (data && data.data && data.data.data && data.data.data[0] && data.data.data[0][0]) || (data && data.message)
			return Promise.reject(error)
		}
		return data;
	});
}
function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            Promise.reject("TypeError : Failed to fetch")
        }else{
            PubSub.publish('msg', false)
            Promise.reject("Unhandled error occurred while get access token")

        }
    }
    catch(err) {
        Promise.reject(err)
    }
}