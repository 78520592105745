import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { ModwestAddEvent } from './AddEvent';



class MidwestEditEvent extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        const params = this.props && this.props.match && this.props.match.params
        return (

            <Fragment>
                <ModwestAddEvent params={params}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const actionCreators = {


}

const connectModwestEditEvent = connect(mapStateToProps, actionCreators)(MidwestEditEvent)

export { connectModwestEditEvent as MidwestEditEvent }