import React from "react";
import history from 'helpers/history'
import { ArtPreview } from './art-preview/art-preview'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import { flowDecimal, getUSDValue } from "helpers";
import moment from 'moment'


export class SpotlightAliceCarousal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            },
            show: false,
        }


    }
    getImageSource = (items) => {
        if ((items && items.localPreview && items.localPreview !== null)) {
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        } else {
            if (items && items.thumbnailHash) {
                return items.thumbnailHash
            }
        }
    }
    getMimetype = (items) => {
        if ((items && items.localPreview && items.localPreview !== null && items.localPreviewMimeType !== null)) {
            return items.localPreviewMimeType
        } else {
            if (items && items.thumbnailMimeType) {
                return items.thumbnailMimeType
            }
        }
    }
    getPrice = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            return `${flowDecimal(parseFloat(items.drops[0].markedPrice))} FLOW`
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                return `${flowDecimal(parseFloat(amount))} FLOW`
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        return `${flowDecimal(parseFloat(amount))} FLOW`
                    }

                }
            }
        } else {
            return false
        }
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    getUsdValue = (items, rate) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            return `$${(getUSDValue(rate, items.drops[0].markedPrice))} `
        } else if ((items && items.drops && items.drops[0] && items.drops[0].auction)) {
            if ((items.drops && items.drops[0] && items.drops[0].auction.lastBid)) {
                const lastBid = items.drops[0].auction.lastBid
                const bidIncrement = items.drops[0].auction.minimumBidIncrement
                var amount = Math.round((Number(lastBid) + Number(bidIncrement)) * 1e12) / 1e12
                return `$${getUSDValue(rate, (amount))} `
            } else {
                if ((items.drops[0].auction && items.drops[0].auction.minimumBidIncrement && items.drops[0].auction.startPrice)) {
                    const startingPrice = items.drops[0].auction.startPrice
                    const bidIncrement = items.drops[0].auction.minimumBidIncrement

                    var amount = Math.round((Number(startingPrice) + Number(bidIncrement)) * 1e12) / 1e12
                    if (this.dayClosed(items.drops[0])) {
                        return false
                    } else {
                        return `$${getUSDValue(rate, (amount))} `
                    }

                }
            }
        } else {
            return false
        }
    }

    startTime = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;

        if (distance < 0) {
            return `Bidding Closed`
        }

    }
    gotoBuy = (items) => {
        if (items.id && items.drops && items.drops[0] && items.drops[0].id) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        }else if(items.id){
            history.push(`/art/${items.id}`)
        }
    }
    isAvailabletoBuy = (items) => {
        if (items && items.drops && items.drops[0]) {
            if (this.dayClosed(items.drops[0])) {
                return false
            }
            return true
        } else {
            return false
        }
    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    componentDidMount() {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
        }
        this.updateSpotArts()
        // })
        this.setCount()

    }
    updateSpotArts = () => {
        const rate = this.props && this.props.rate
        const spotLight = this.props && this.props.spotLight
        const spotLightArts = spotLight && spotLight.map((items, index) => {
            return <div className={(spotLight && spotLight.length > 3) ? "item falist" : 'item falist col-md-4'} key={items && items.id}>
                <div className="falist-con" id="uploadArt">
                    {items && items.isPremiumArt &&
                        <div className="fafeaturetxt">
                            <img src="assets/images/permium.png" alt="" className="permiumicon" />
                        </div>}
                    <div className="faimg" id="faimg" onClick={() => this.gotoBuy(items)}
                    >

                        <ArtPreview src={(items && items.thumbnailHash)} className="mw-100" alt="" thumbnailHeight="100px" thumbnailWidth="100px" height="300px" showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />
                    </div>
                    <h3 className={(spotLight && spotLight.length > 3) ? '' : ''} title={items && items.creator && items.creator.fullName}><span onClick={() => this.gotoCreator(items && items.creator)} id="uploadArt">{items && items.creator && items.creator.fullName && `${items.creator.fullName}`}</span></h3>

                </div>
                <div className="falistdesc">
                    <div className='row'>
                        
                        <div className={ 'col-md-6'}><h4><span onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title}</span></h4></div>
                        <div className='col-md-6'>
                            <div className='dscpb'>
                                {(this.getPrice(items) && this.getUsdValue(items, rate)) ? <div className='dscpbtxt'><span className='small'>{this.getUsdValue(items, rate)} </span><br/>{this.getPrice(items)}</div> : ''} 
                                {this.startTime(items && items.drops && items.drops[0]) ? <div className="dscpbtxt">{items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && this.startTime(items && items.drops && items.drops[0])}</div> : ''}
                                {this.isAvailabletoBuy(items) ? <div className='dscpbbtn'><button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={() => this.gotoBuy(items)}>Buy Now</button></div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })

        this.setState({ spotLightArts, spotLightArtsLen: spotLightArts && spotLightArts.length })
    }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }


    }
    gotoPath = (e, items) => {
        // console.log(e.target)
        if (e.target.tagName == "H3") {
            this.gotoDetail(items)
        } else if (e.target.tagName == "SPAN") {
            this.gotoCreator(items && items.creator)
        }
    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }

    render() {

        const rate = this.props && this.props.rate
        const spotLight = this.props && this.props.spotLight
        return (
            
            <div className={((this.state.spotLightArtsLen) && (this.state.spotLightArtsLen) > 3) ? 'row1 align-items-center' : 'row justify-content-center'}>


                {(this.state.spotLightArts) && (this.state.spotLightArtsLen) && (this.state.spotLightArtsLen) > 3 ?
                    <AliceCarousel
                        autoPlay autoPlayInterval={3500}
                        infinite
                        items={this.state.spotLightArts}
                        responsive={this.state.responsive}
                        autoPlayDirection={"ltr"}
                        mouseTracking={true}
                        disableDotsControls
                    >

                    </AliceCarousel> :
                    (this.state.spotLightArts)
                }
            </div>

        )
    }
}
