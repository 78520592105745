import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { airdropActions, userAction } from 'store/actions';
import { Helmet } from 'react-helmet';
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { userServices } from 'services';
import ReCAPTCHA from "react-google-recaptcha"
import { configPath } from '../config'

var timeout;
class SignupAirdrop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: '',
            username: '',
            password: '',
            submitted: false,
            repassword: '',
            isUserLoading: false,
            isValidUsername: false,
            isSuccess: false,
            isAlert: false,
            isUsernameError: '',
            isUserTyping: false,
            message: '',
            style: '',
            isVerified : false,
            reCaptchaToken: '',
            isAgree: false,
            code : '',
            isAlreadyRegistered : false,
            isCalled : false,
            verifiedMes : '',
            unregEmail: ''
        }
    }

    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        if (isWebLogin()) {
            history.push('/airdrops')
        } else {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            this.setState({ isSuccess: false })
            
    
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let verificationCode = urlParams.get('code')
            let code = this.props && this.props.match && this.props.match.params && this.props.match.params.code && this.props.match.params.code

            console.log("query", code);
            
            
            if (code) {
                this.setState({code})
                let obj = {
                    uuid : code
                }
                this.props.verifyAirdropUser(JSON.stringify(obj))
            } else {
                this.setState({
                    isAlert: true,
                    message: 'Invalid url',
                    style: "danger"
                })
            }
        }

    }

    static getDerivedStateFromProps(props, state) {
        if (props) {
            if (props && props.reguser && props.reguser.statusCode === 200) {
                if (props && props.reguser && props.reguser.data && props.reguser.data.accessToken) {
                    props.resetRegAirdrop()
                    props.getUserProfile()
                    history.push('/airdrops')
                    return {
                        fullname: '',
                        username: '',
                        password: '',
                        email: '',
                        isAgree: false,
                        submitted: false,
                        isSuccess: true,
                        reCaptchaToken: '',
                        isAlert: false,
                        message: '',
                        style: '',
                        code : '',
                        isAlreadyRegistered : false,
                        isCalled : false,
                        isVerified : false
                    }
                    
                }
               
                
            }
            if ((props && props.failedReg && state.submitted)) {
                // props.resetRegFailure()

                if ((props && props.failedReg) === 'userName should contain only A-Z, a-z, 0-9 and Period symbol(.)') {
                    return {
                        isAlert: true,
                        message: 'Username should contain only a-z, 0-9 and period symbol(.)',
                        style: "danger"
                    }
                }

                else {
                    return {
                        isAlert: true,
                        message: props && props.failedReg,
                        style: "danger"
                    }
                }
            }

            if((props && props.verify && props.verify.statusCode === 200)){
                props.resetVerifyUser()
                return {
                    isVerified : true,
                    isAlreadyRegistered : props.verify && props.verify.data && props.verify.data.isAlreadyRegistered,
                    unregEmail : props.verify && props.verify.data && props.verify.data.email
                }
            }

            if ((props && props.failedVerify )) {
                if(props.failedVerify.toLowerCase().includes('your email has been already registered and need to be verified')){
                    return {
                        isAlreadyRegistered : true,
                        isVerified : true,
                        verifiedMes : props.failedVerify
                    }
                }else if(props.failedVerify.toLowerCase().includes('your email has been already verified')){
                    return {
                        isAlreadyRegistered : true,
                        isVerified : true,
                        verifiedMes : props.failedVerify
                    }
                }
                else{
                    return {
                        isAlert: true,
                        message: props && props.failedVerify,
                        style: "danger",
                        verifiedMes : ''
                    }
                }
            }
        }
    }
    componentDidUpdate(props, state) {
        if ((props && props.failedReg)) {
            props.resetregUserFailed()
            this.gotoTop()

        }
        if ((props && props.failedVerify )) {
            props.resetVerifyUserFailed()
            this.gotoTop()
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, repassword: '' })
    }

    handleUserName = (e) => {
        const form = document.getElementById('username') ;
        form && form.removeEventListener('focusout', (event) => {
            this.checkUsernameVerification()
        },true);
            
           
        if (((e.target.value && e.target.value.length) <= 50)) {
            this.setState({ [e.target.name]: (e.target.value).toLowerCase(), isUserTyping: true, isUsernameError: '', isCalled : true }, () => {
                form.addEventListener('focusout', (event) => {
                    this.checkUsernameVerification()
                },true);
                this.userNameValidation(e.target.value)
            })
        }
    }
    handleFullNameChange = (e) => {
        const fullname = document.getElementById('fullname');
        fullname.removeEventListener('focusout', () => {
            this.setState({fullnameError : true})
        },true)
                
        if (((e.target.value && e.target.value.length) <= 110)) {

            this.setState({ [e.target.name]: e.target.value, fullnameError : false, isCalled : true },() => {
                fullname.addEventListener('focusout', () => {
                    this.setState({fullnameError : true})
                },true)
            })
        }
    }
    handleReEnterPassword = (e) => {
        // if((this.state.password && this.state.password.length >= (e.target.value && e.target.value.length)))
        this.setState({ [e.target.name]: e.target.value })

    }
    captchaChange = (value) => {
        this.setState({ reCaptchaToken: value })
        // console.log("Captcha value:", value,configPath.siteKey)
    }
    handleChecked = (value) => {
        let isAgree = value ? false : true
        this.setState({ isAgree })
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    checkPassword = (value) => {
        if ((value && value.length) >= 8) {
            return true
        } else {
            return false
        }
    }

    checkusername = (value) => {
        if (((value && value.length) <= 50) && (value && value.length) >= 4) {
            return true
        } else {
            return false
        }
    }

    checkFullName = (value) => {
        if (((value && value.length) <= 110) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }

    handleClose = () => {
        this.setState({
            isSuccess: false
        })
        history.push('/login')
    }
    checkConfirmPassword = () => {
        const { password, repassword } = this.state
        if (password === repassword) {
            return true
        } else {
            return false
        }
    }

    userNameValidation = (value) => {
        const regext = /^([a-z0-9.]{4,})+$/
        if (regext.test(value)) {
            return true
        } else {
            return false
        }

    }

    checkUsernameVerification = () => {
        const { username, isCalled } = this.state
        let obj = {
            userName: username
        }
        this.setState({
            isCalled : false
        })
        if ((username) && isCalled) {
            if (this.checkusername(username)) {
                if ((this.userNameValidation(username))) {

                    this.setState({ isUserLoading: true, isUsernameError: '' })

                    userServices.usernameVerification(obj).then(res => {
                        if ((res && res.statusCode) === 200) {
                            this.setState({
                                isValidUsername: true, isUserLoading: false, isUsernameError: false
                            })
                        } else {
                            this.setState({
                                isAlert: true,
                                message: 'Unexpected error occured',
                                style: "danger",
                                isUserLoading: false,
                                isUsernameError: '',
                                isValidUsername: false,
                                isUserTyping: false

                            })
                            this.gotoTop()
                        }
                    }).catch(error => {
                        if ((error).toLowerCase().includes("user name is already taken")) {
                            this.setState({
                                isUsernameError: "Someone else is using above username,please try a different one",
                                isValidUsername: false,
                                isUserLoading: false,
                                isUserTyping: false

                            })
                        } else {
                            this.setState({
                                isAlert: true,
                                message: error,
                                style: "danger",
                                isUserLoading: false,
                                isUsernameError: '',
                                isValidUsername: false,
                                isUserTyping: false

                            })
                            this.gotoTop()
                        }
                    })
                } else {
                    this.setState({
                        isUsernameError: ""
                    })
                }
            } else {
                this.setState({
                    isUsernameError: ""
                })
            }
        } else {
            this.setState({
                isUsernameError: ""
            })
        }

    }
    handleSubmit = async(e) => {
        e.preventDefault();
        const { fullname, username, email, password, isAgree, repassword, reCaptchaToken, isUserLoading, isUsernameError, isValidUsername, isEmailLoading, isEmailError, isValidEmail } = this.state;
        this.setState({ submitted: true })
        const token = await this.captcha.executeAsync();

        this.captcha.reset()
        this.setState({
            reCaptchaToken: ''
        })
        if (
            // fullname &&
            // username &&
            password &&
            this.checkPassword(password) &&
            // this.checkusername(username) &&
            // this.checkFullName(fullname) && 
            repassword) {
            // if (this.userNameValidation(username)) {

                // if (!isUserLoading) {
                    // if ((isValidUsername && !isUsernameError)) {
                        if (this.checkConfirmPassword()) {
                            if (isAgree) {
                                if (token) {
                                    if(this.state.code){
                            let obj = {
                                uuid : this.state.code,
                                // fullName: fullname,
                                // userName: username,
                                reCaptchaToken : token,
                                password,
                            }
                            this.props.registerAirdropUser(JSON.stringify(obj))
                        }else{
                            this.setState({ isAlert: true, message: 'Invalid url', style: "danger" })
                            this.gotoTop()
                        }
                        } else {
                            // toast.error("Please check the recaptcha")
                            this.setState({ isAlert: true, message: 'Recaptcha execution failed', style: "danger" })
                            this.gotoTop()
    
                            // this.closeAlert()
                        }

                        } else {
                            this.setState({ isAlert: true, message: 'Please agree the terms and conditions', style: "danger" })
                            this.gotoTop()
        
                            //  this.closeAlert()
                            // toast.error("Please agree the terms and conditions")
                        }
                        } else {
                            this.setState({ isAlert: true, message: 'Password did not match. Please enter correct password', style: "danger" })
                            this.gotoTop()
                        }

                    // } else {
                    //     this.setState({ isAlert: true, message: 'Please provide a valid username ', style: "danger" })
                    //     this.gotoTop()
                    // }
                // } else {
                //     this.setState({ isAlert: true, message: 'Please wait, we are checking your username', style: "danger" })
                //     this.gotoTop()
                // }


            // } else {
            //     this.setState({ isAlert: true, message: 'UserName should contain only a-z, 0-9 and Period symbol(.)', style: "danger" })
            //     this.gotoTop()
            // }
        } else {
            this.setState({ isAlert: true, message: 'Mandatory fields are missing', style: "danger" })
            this.gotoTop()

        }
    }

    handleLogin = async (googleData) => {
        if (googleData.tokenId) {
            let obj = { tokenId: googleData.tokenId }
            this.props.googleLogin(JSON.stringify(obj))
            history.push('/login')
        }
    }
    gotoLogin = () => {
        history.push('/login')
    }
    render() {
        if (this.state.isAlert) {
            this.resetTimeout()
            this.closeAlert()

        }
        return (
            <Fragment>
                <Helmet>
                    <title>Sign Up</title>
                    <meta name="title" content="Sign Up"></meta>
                    <meta name="description" content={`Create a new account on ${process.env.REACT_APP_MARKETPLACE}`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {!(this.state.isVerified) && !(this.state.isAlreadyRegistered) && <div className="container detailWallet">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="dotsloading">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <h3 className="mt-3">Please wait </h3>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {(this.state.isVerified && this.state.isAlreadyRegistered) &&
                    <div className="container">
                        <div className="card py-5 my-5">
                            <div className="card-body">
                                <div className="text-center">
                                    <div className="success-checkmark">
                                        <div className="check-icon">
                                            <span className="icon-line line-tip"></span>
                                            <span className="icon-line line-long"></span>
                                            <div className="icon-circle"></div>
                                            <div className="icon-fix"></div>
                                        </div>
                                    </div>
                                    <h3 className="mt-3">{this.state.verifiedMes} </h3>
                                    <div className="form-group text-center mt-4">
                                    <button type="button" className="btn btn-primary px-5" onClick={this.gotoLogin}>Login</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {(this.state.isVerified) && !(this.state.isAlreadyRegistered) && <div className="container">
                    <h1 className="text-center mt-5 mb-3">New to Disrupt Art? Please Sign Up</h1>

                    <div className="purchasebox1 p-3 reglogfrm">
                        <form onSubmit={this.handleSubmit}>

                            {/* <div className="form-group">
                                <label htmlFor="fullname">Fullname</label>
                                <input type="text" name="fullname" id="fullname" onChange={this.handleFullNameChange} value={this.state.fullname} className="form-control" placeholder="Enter your fullname here" />
                                {this.state.submitted && !this.state.fullname &&  <div className="mandatory">Fullname is required</div>}
                                {this.state.submitted && this.state.fullname && !this.checkFullName(this.state.fullname) && this.state.fullnameError && <div className="mandatory">Min 3 - Max 110 characters</div>}
                                {!this.state.submitted && this.state.fullname && !this.checkFullName(this.state.fullname) && this.state.fullnameError && <div className="mandatory">{"Min 3 - Max 110 characters"}</div>}
                                {this.state.fullname && this.checkFullName(this.state.fullname) && <div className="mandatory-req">{"Looks good!"}</div>}

                            </div> */}

                            {/* <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-append ">
                                        <span className="input-group-text bg-primary igt-custom" id="basic-addon2">@</span>
                                    </div>
                                    <input type="text" name="username" id="username" onChange={this.handleUserName} value={this.state.username} className="form-control" placeholder="Username here" />
                                    {(this.state.isUserLoading) && <div className="icon-container">
                                        <i className="userloader"></i>
                                    </div>}
                                </div>
                                {this.state.username && this.checkusername(this.state.username) && this.userNameValidation(this.state.username) && this.state.isValidUsername && <div className="mandatory-req"><span className="badge badge-olutline-primary"><i className="fa fa-check" aria-hidden="true"></i></span> Looks good and valid!</div>}
                                {this.state.username && this.state.isUsernameError && !this.state.isUserTyping && <div className="mandatory">{this.state.isUsernameError}</div>}
                                {this.state.submitted && !this.state.username && !this.state.isUsernameError && <div className="mandatory">Username is required</div>}
                                {this.state.username && !this.checkusername(this.state.username) && !this.state.isUsernameError && <div className="mandatory">{"Min 4 - Max 50 characters"}</div>}
                                {this.state.username && this.checkusername(this.state.username) && this.userNameValidation(this.state.username) && !this.state.isUsernameError && !this.state.isValidUsername && <div className="mandatory-req">{"Looks good!"}</div>}
                                {this.state.username && !this.userNameValidation(this.state.username) && this.checkusername(this.state.username) && !this.state.isUsernameError && <div className="mandatory">Username should contain only a-z, 0-9 and Period symbol(.)</div>}
                            </div> */}
                            <div className="form-group mb-4">
                                <label className=" mb-2">Email</label>
                                <input type="email" name="unregEmail" id="unregEmail" value={this.state.unregEmail} className="form-control" disabled />

                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" id="password" onChange={this.handleInputChange} value={this.state.password} className="form-control" placeholder="Enter password here" />
                                {this.state.submitted && !this.state.password && <div className="mandatory">Password is required</div>}
                                {this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory">Min 8 characters</div>}
                                {!this.state.submitted && this.state.password && !this.checkPassword(this.state.password) && <div className="mandatory-req">{"Min 8 characters"}</div>}


                            </div>
                            <div className="form-group">
                                <label htmlFor="repassword">Confirm Password</label>
                                <input type="password" name="repassword" id="repassword" onChange={this.handleReEnterPassword} value={this.state.repassword} className="form-control" placeholder="Enter password here" disabled={!(this.checkPassword(this.state.password))} />
                                {this.state.submitted && !this.state.repassword && <div className="mandatory">Password is required</div>}
                                {/* {this.state.submitted && this.state.repassword && !this.checkPassword(this.state.repassword) && <div className="mandatory">Password must be contain atleast 8 characters</div>}                                         */}
                                {this.state.submitted && this.state.repassword && (this.state.repassword !== this.state.password) && <div className="mandatory">{"Password did not match"}</div>}
                                {!this.state.submitted && this.state.repassword && (this.state.repassword !== this.state.password) && <div className="mandatory-req">{"Password did not match"}</div>}
                                {this.state.repassword && (this.state.repassword == this.state.password) && <div className="mandatory-req">{"Looks good!"}</div>}
                            </div>
                            <div className="form-group d-flex justify-content-center">
                                <ReCAPTCHA sitekey={configPath.siteKey} ref={e => (this.captcha = e)} onChange={this.captchaChange} theme={'dark'}  size="invisible"/>
                            </div>
                            <div className="form-group">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" checked={this.state.isAgree} onChange={() => this.handleChecked(this.state.isAgree)} />
                                <label className="form-check-label" htmlFor="exampleCheck1"> I agree with the <a href="/nft/terms/" target="blank" className="mx-1 d-inline">terms and conditions of Disrupt.art</a></label>
                            </div>
                            <div className="form-group text-center">
                                <button type="submit" className="btn btn-primary px-5">Register</button>
                            </div>

                        </form>
                    </div>
                </div>
                }


            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        verify: state.airdrop.verifyAirdropUser,
        failedVerify: state.airdrop.failedAirdropUser,
        reguser: state.airdrop.regAirdropUser,
        failedReg: state.airdrop.failedRegAirdrop
    }
}

const actionCreators = {
    registerAirdropUser: airdropActions.registerAirdropUser,
    resetRegAirdrop: airdropActions.resetRegAirdropUser,
    verifyAirdropUser: airdropActions.verifyAirdropUser,
    resetVerifyUser: airdropActions.resetVerifyAirdropUser,
    resetregUserFailed: airdropActions.resetFailedRegAirdropUser,
    resetVerifyUserFailed: airdropActions.resetFailedVerifyAirdropUser,
    getUserProfile: userAction.getUsers,


}

const connectAirdropRegister = connect(mapStateToProps, actionCreators)(SignupAirdrop)

export { connectAirdropRegister as SignupAirdrop }