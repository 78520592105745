import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'
import { discoverActions, userAction, walletActions } from 'store/actions'

import { Helmet } from 'react-helmet';
import AliceCarousel from 'react-alice-carousel';
import history from 'helpers/history'
import PubSub from 'pubsub-js';
import moment from 'moment';
import { SolarSystemLoading } from 'react-loadingg';

import "react-alice-carousel/lib/alice-carousel.css";
import { ArtPreview } from './art-preview/art-preview';
import { OwlCarousalArt } from './owlCarousalArt';
import { OwlCarousal } from './owlCarousalCreator';
import { configPath } from 'config'
import { isWebLogin, localAirdropPage, localArtPage, localDropPage } from 'helpers';
import { SpotlightAliceCarousal } from './spotlightalicecarousal';
import { getFlowUsdValue } from 'helpers/getFlowUsd';

class DiscoverVersion2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responsive: {
                0: { items: 1 },
                767: { items: 1 },
                992: { items: 2 },
                1920: { items: 2 }
            },
            responsiveArt: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            },
            responsiveCreator: {
                0: { items: 1 },
                576: { items: 2 },
                768: { items: 3 },
                1920: { items: 3 }
            },
            spotLight: [],
            featureArts: [],
            featureCreators: [],
            featureCollectors: [],
            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            offSet: 0,
            recordLimit: 4,
            artRecord: 4,
            creatorRecord: 6,
            collectorRecord: 8,
            isArtTrue: false,
            isCreatorTrue: false,
            isCollectorTrue: false,
            isSpotCheck: false,
            isFeatureArt: false,
            isCreator: false,
            isCollector: false,
            spotlightOn: true,
            show: false,
            rate : 0
        }

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        

        this.getUSD()
    }
    getSpotLight = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.gotoTop()
        this.props.getAllSpotLightArts(obj)
    }
    getFeatureArt = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureArts(obj)
    }
    getFeatureCreator = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureCreators(obj)
    }
    getFeatureCollector = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureCollectors(obj)
    }
    getAllFeatureArt = () => {
        this.setState({ isArtTrue: true })
        this.getFeatureArt('', '')
    }
    getLessFeatureArt = () => {
        this.setState({ isArtTrue: false })
        this.getFeatureArt(this.state.offSet, this.state.artRecord)
    }
    getAllFeatureCreator = () => {
        this.setState({ isCreatorTrue: true })
        this.getFeatureCreator('', '')
    }
    getLessFeatureCreator = () => {
        this.setState({ isCreatorTrue: false })
        this.getFeatureCreator(this.state.offSet, this.state.creatorRecord)
    }
    getAllFeatureCollector = () => {
        this.setState({ isCollectorTrue: true })
        this.getFeatureCollector('', '')
    }
    getLessFeatureCollector = () => {
        this.setState({ isCollectorTrue: false })
        this.getFeatureCollector(this.state.offSet, this.state.collectorRecord)
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.spotArts && props.spotArts.statusCode == 200) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            props.resetSpotLight()

            return {
                spotLight: props && props.spotArts && props.spotArts.data && props.spotArts.data.entity,
                isSpotCheck: true
            }
        }
        if (props && props.featureArts && props.featureArts.statusCode == 200) {
            props.resetFeatureArts()
            return {
                featureArts: props && props.featureArts && props.featureArts.data && props.featureArts.data.entity,
                isFeatureArt: true,
              
            }
        }
        if (props && props.featureCreators && props.featureCreators.statusCode == 200) {
            props.resetFeatureCreators()
            return {
                featureCreators: props && props.featureCreators && props.featureCreators.data && props.featureCreators.data.entity,
                isCreator: true
            }
        }
        if (props && props.featureCollectors && props.featureCollectors.statusCode == 200) {
            props.resetFeatureCollectors()
            return {
                featureCollectors: props && props.featureCollectors && props.featureCollectors.data && props.featureCollectors.data.entity,
                isCollector: true
            }
        }
        return null;
    }
    showLoader() { PubSub.publish('msg', true); }
    hideLoader() { PubSub.publish('msg', false); }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }


    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    handleSpotlight = () => {
        this.setState({ spotlightOn: true })
    }
    handleArts = () => {
        history.push('arts?page=1&limit=12')
    }
    handleCreator = () => {
        history.push('creators?page=1&limit=12')
    }
     
    getEdition = (items) => {

        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.dropsCount != null)) {
            return <h6>Edition : 1</h6>
        } else if ((items && items.drops && items.drops[0] && items.dropsEdition != null)) {
            return (<h6>Edition :  {items.dropsEdition}</h6>)
        } else if ((items.toalCount != null && items.dropsCount != null)) {
            return <h6>Edition : {((items.toalCount) - (items.dropsCount))}</h6>
        } else if ((items.toalCount != null && items.dropsCount == null)) {
            return <h6>Edition : {((items.toalCount))}</h6>
        } else {
            <h6>Edition : 1</h6>
        }
    }
    gotoPath = (e, items) => {
        if (e.target.tagName == "H3") {
            this.gotoDetail(items)
        } else if (e.target.tagName == "SPAN") {
            this.gotoCreator(items && items.creator)
        }
    }
    gotoArts = () => {
        history.push('/arts')
        this.handlePage()
    }
    gotoCreators = () => {
        history.push('/creators')
        this.handlePage()
    }
    gotoCollectors = () => {
        history.push('/collectors')
        this.handlePage()
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    gotoDrops = () => {

        history.push('/drops')
        this.handlePage()
    }
    handlePage = () => {
        localStorage.setItem("page",0)
    }
    getUSD = async () => {
        const value =  await getFlowUsdValue()
        if(value){
            this.setState({rate : value})
            this.getSpotLight('', '')
            this.getFeatureArt('','')
            this.getFeatureCreator('','')
            this.getFeatureCollector('','')
        }
        
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    render() {
        const { spotLight, featureArts, featureCreators, featureCollectors, isSpotCheck, isFeatureArt, isCreator, isCollector } = this.state
        const metaDesc = `Discover amazing NFT collections from various disruptor creators. ${process.env.REACT_APP_BASE_SITE} Marketplace's featured and spotlight NFTs`
        return (

            <Fragment>
                <Helmet>
                    <title>{`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}</title>
                    <meta name="title" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}></meta>
                    <meta name="description" content={metaDesc}></meta>
                </Helmet>

                <div className="dv2">
                    <div className="d-slider">
                        <div id="carouselExampleControls" className="carousel slide carousel-fade" data-ride="carousel" data-interval="4000">
                            <div className="carousel-inner">

                            {/* <div className="carousel-item active">
                                    <div className="ds-img" style={{ backgroundImage: "url('assets/images/slider/bab-banner.png')" }}></div>
                                    <div className="ds-inner-con text-center w-100">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp mx-auto ">
                                            <h1 className="ds-heading">Badazz Bears</h1>
                                            <div className="ds-description">Buy, Sell and Trade your Badazz Bears
                                            </div>
                                            <div className="ds-button">
                                                <a href='https://disrupt.art/bab/' target={'_blank'} className="btn btn-outline-light">JOIN US</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="carousel-item active">
                                    <div className="ds-img" style={{ backgroundImage: "url('assets/images/slider/mwc-banner-2.png')" }}></div>
                                    <div className="ds-inner-con">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp">
                                            <h1 className="ds-heading">MidwestCon</h1>
                                            <div className="ds-description">An immersive celebration of Web3, art, and culture.<br/>August 12-14 | art academy of cincinnati</div>
                                            <div className="ds-button">
                                                <a href='https://midwestcon.disrupt.art/' target={'_blank'} className="btn btn-outline-light">Buy Tickets</a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="carousel-item active" >
                                    <div className="ds-img" style={{ backgroundImage: "url('assets/images/slider/header-5.jpg')" }}></div>
                                    <div className="ds-inner-con">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp">
                                            <h1 className="ds-heading">Art for Disruptors</h1>
                                            <div className="ds-description">Disrupt Art is a Global Marketplace created to revolutionize the impact of Art, Music, Fashion and Film.
                                            </div>
                                            {!(isWebLogin()) && <div className="ds-button">
                                                <a onClick={() => {history.push('/signup')}} className="btn btn-outline-light">JOIN US</a>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="carousel-item">
                                    <div className="ds-img" style={{ backgroundImage: "url('assets/images/slider/rob-richardson.png')" }}></div>
                                    <div className="ds-inner-con">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp">
                                            <h1 className="ds-heading">Join the Movement</h1>
                                            {<div className="ds-button iminbtn">
                                                <a href="https://www.fundblackfounders.com/disruptart" target="_blank" className="btn btn-outline-light ds-btn-text ">I'm in</a>
                                            </div>}
                                            <h4 className="mt-5">Rob Richardson</h4>
                                            <p>CEO and Founder Disrupt Art</p>
                                            <img src="assets/images/slider/rob-richardson-fbf-logo.png" alt='' className='mw-100' />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="carousel-item ">
                                    <div className="ds-img ds-overlay" style={{ backgroundImage: "url('assets/images/slider/drums-g6e7a7108e_1920.jpg')" }}></div>
                                    <div className="ds-inner-con">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp">
                                            <h1 className="ds-heading">Making NFTs Accessible</h1>
                                            <div className="ds-description">We are focused on equity, diversity and bridging NFT technology to the world. Disrupt Art is the first Black owned full scale NFT marketplace.</div>
                                            {!(isWebLogin()) && <div className="ds-button">
                                                <a onClick={() => {history.push('/signup')}} className="btn btn-outline-light">JOIN US</a>
                                            </div>}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="carousel-item">
                                    <div className="ds-img" style={{ backgroundImage: "url('assets/images/slider/gal5.jpg')" }}></div>
                                    <div className="ds-inner-con">
                                        <div className="ds-con animated animatedFadeInUp fadeInUp mx580">
                                            <h1 className="ds-heading">Collect NFT Drops</h1>
                                            {/* <div className="ds-description">Disrupt Art on the Flow Blockchain makes the transaction fees for creators pennies on the dollars versus the hundreds of dollars it can cost artists to sell on Ethereum.</div> */}
                                            {<div className="ds-button text-center">
                                                <a onClick={() => history.push('/drops')} className="btn btn-outline-light ds-btn-text ">Collect</a>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span className="fa fa-angle-left" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span className="fa fa-angle-right" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>

                    
                    {!isSpotCheck && <div className="featuredsec spotlight" id="spotlight">
                        <div className="container">
                            <div className="ds-title text-center">Spotlight</div>
                            <div className={isSpotCheck ? 'row' : 'row discovload'}>
                            {!isSpotCheck && <SolarSystemLoading/>}
                            </div>
                            </div>
                            </div>}
                    {((spotLight && spotLight.length > 0) || (featureArts && featureArts.length > 0) || (featureCreators && featureCreators.length > 0) || (featureCollectors && featureCollectors.length > 0)) && isSpotCheck &&
                    <div className="featuredsec spotlight" id="spotlight">
                        <div className="container">
                            <div className="ds-title text-center">Spotlight</div>
                            {spotLight && spotLight.length > 0 &&
                            <SpotlightAliceCarousal spotLight={spotLight} rate={this.state.rate}/>}
                                {spotLight && spotLight.length == 0 && isSpotCheck &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true" style={{color:"black"}}></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoArts}>Explore Art</button>

                            </div>
                        }

                        </div>
                    </div>
                     }

                        {!isFeatureArt && <div className="featuredsec featureart dark-purple">
                        <div className="container">
                            <div className="ds-title ds-title-sm">Featured Art</div>
                            <div className={isFeatureArt ? 'row' : 'row discovload'}>

                            {!isFeatureArt && <SolarSystemLoading/>}
                            </div>
                            </div>
                            </div>}
                    {((spotLight && spotLight.length > 0) || (featureArts && featureArts.length > 0) || (featureCreators && featureCreators.length > 0) || (featureCollectors && featureCollectors.length > 0)) && isFeatureArt && 
                    <div className="featuredsec featureart dark-purple">
                        <div className="container">
                            <div className="ds-title ds-title-sm">Featured Art</div>
                            {featureArts && featureArts.length > 0 &&
                            <OwlCarousalArt featureArts={featureArts} rate={this.state.rate}/>}

                        </div>
                        {isFeatureArt && (featureArts && featureArts.length > 0) && <div className="text-center mt-3 mt-sm-5 ds-button">
                            <button className="btn btn-rounded btn-outline-light  btn-sm" onClick={this.gotoDrops} style={{textTransform:"capitalize"}}>Explore & Collect NFTs</button>
                        </div>}
                        {featureArts && featureArts.length == 0 && isFeatureArt &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-light  btn-sm" onClick={this.gotoArts}>Explore Art</button>
                            </div>
                        } 
                    </div>
                    }       

                        {!isCreator && <div className="featuredsec featuredcreator">
                            <div className="container">
                                <div className="ds-title ds-title-sm">Featured Creators</div>
                                <div className={isCreator ? 'row' : 'row discovload'}>
                                {!isCreator && <SolarSystemLoading/>}
                                </div>
                                </div>
                                </div>}
                        {((spotLight && spotLight.length > 0) || (featureArts && featureArts.length > 0) || (featureCreators && featureCreators.length > 0) || (featureCollectors && featureCollectors.length > 0)) && isCreator &&
                        <div className="featuredsec featuredcreator">
                            <div className="container">
                                <div className="ds-title ds-title-sm">Featured Creators</div>
                                {/* {!isCreator && <SolarSystemLoading/>} */}
                                <OwlCarousal featureCreators={featureCreators}/>

                            </div>
                            {isCreator && (featureCreators && featureCreators.length > 0) && <div className="text-center mt-2 ds-button">
                                <button className="btn btn-primary" onClick={this.gotoCreators} style={{textTransform:"capitalize"}}>View All Creators</button>
                            </div>}
                            {featureCreators && featureCreators.length == 0 && isCreator &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true" style={{color:"black"}}></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoCreators}>Browse Creators</button>
                                {/* <img src={`../${configPath.imageAssets}/no-creators-yet.png`} className="mw-100" alt="" /> */}
                            </div>
                        }
                        </div>
                        }
                        {((spotLight && spotLight.length == 0) && (featureArts && featureArts.length == 0) && (featureCreators && featureCreators.length == 0) && (featureCollectors && featureCollectors.length  == 0)) &&
                         ((isSpotCheck && isCollector && isCreator && isFeatureArt) &&
                         <div className="col-md-4 offset-md-4 text-center">
 
                             <img src={`../${configPath.imageAssets}/sand-clock.png`} className="mw-100 mb-5" alt="" style={{ width: "150px", height: "150px", marginTop: "15px" }} />
                             <h5 className="usernamelilac  mb-3">Stay tuned., Some cool NFTs are on the way!</h5>
                             <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm mb-3" onClick={this.gotoArts}>Explore</button>
                         </div>)
                 }
<div className="dark-purple" id="whodis">
                        <div className="container">
                            <h2 className="text-center">Who are the Disruptors</h2>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <img src={'assets/images/pexels-lucas-pezeta-4336968-1024x768.jpg'} alt="" className="mw-100 p-4 secimgds" />
                                </div>
                                <div className="col-md-6">
                                    <div className="watdcon">
                                        {/* <h3>Who are the Disruptors</h3> */}
                                        <p>We are gathering the innovators, tinkerers, thinkers, and creatives across the globe to reimagine the future of art, music, fashion, and film. At the intersection of art, technology, and culture the disruptors are curating an interactive space for creators to revolutionize the impact of art. Join us and disrupt.</p>
                                        {!(isWebLogin()) && <div className="ds-button">
                                            <a onClick={() => {history.push('/signup')}} className="btn btn-outline-light">Become a Disruptor</a>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="danlogo ">
                            <div id="danlogo">
                                <div className="container text-center">
                                <img src="assets/images/discrpt-art2.png" alt=""  />
                                </div>
                            </div>
                        </div>

                        <div className="makenfts">
                            <div className="container">
                                <div className="row mt-5">                                  
                                    <div className="col-md-6">
                                        <div className="watdcon">
                                            <h3>Making NFTs Accessible</h3>
                                            <p>We see NFTs as not just being for gamers and crypto enthusiasts but also for traditional artists, collectors, philanthropic organizations and large scale enterprises. NFTs are the future of art, film, music and brand IP. </p>
                                            {!(isWebLogin()) && <div className="ds-button"><a onClick={() => {history.push('/signup')}} className="btn btn-primary">Join US</a>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="threeimgs">
                                            <div className="timg"><img src="assets/images/collection3-764x1024.jpg" alt=""  /></div>
                                            <img src="assets/images/collection2.jpg" alt="" />
                                            <div className="bimg"><img src="assets/images/collection1-760x1024.jpg" alt=""  /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>


            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        spotArts: state.discover.spotArts,
        featureArts: state.discover.featureArts,
        featureCreators: state.discover.featureCreators,
        featureCollectors: state.discover.featureCollectors
    }
}
const actionCreators = {
    getAllSpotLightArts: discoverActions.getAllSpotLightArts,
    resetSpotLight: discoverActions.resetSpotLight,
    getAllFeatureArts: discoverActions.getAllFeatureArts,
    getAllFeatureCreators: discoverActions.getAllFeatureCreators,
    getAllFeatureCollectors: discoverActions.getAllFeatureCollectors,
    resetFeatureArts : discoverActions.resetFeatureArts,
    resetFeatureCreators : discoverActions.resetFeatureCreators,
    resetFeatureCollectors : discoverActions.resetFeatureCollectors
}

const connectDiscoverv2 = connect(mapStateToProps, actionCreators)(DiscoverVersion2)
export { connectDiscoverv2 as DiscoverVersion2 }

