import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { userAction, walletActions } from 'store/actions';
import { Helmet } from 'react-helmet';
import { configPath } from "../config";

import * as fcl from "@onflow/fcl";
import { accountSetup, isConnectWallet, isWebLogin } from 'helpers';
import { Alert } from "helpers/alert";
import { createSetUp, getUserAddress, RetrieveTokens } from 'utils';
import PubSub from 'pubsub-js';
import { connectBlocto } from 'helpers/getFlowUsd';
import { SwitchAccount } from './switchaccountPopup';

var timeout;
class ConnectWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActSetup : false,
            callSetup : false,
            isSwitchAcc : false
        }
    }



    componentDidMount() {
        if ((isWebLogin()) && !isConnectWallet()) {
            this.on()
            // this.handleWalletLogin()
        } else {
            history.push('/')
        }
    }
    on = () => {
        document.getElementById("overlay").style.display = "block";
    }
    off = () => {
        document.getElementById("overlay").style.display = "none";
    }


    componentDidUpdate(props, state) {
        if (((this.props && this.props.wallet && this.props.wallet.statusCode) == 200) && (props.wallet && props.wallet.statusCode) != (this.props.wallet && this.props.wallet.statusCode) && (state.callSetup)) {
            this.props.resetlinkWallet()
            this.createaccountSetup()
            this.setState({
                callSetup : false
            })
        }
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
    }
    handleWalletLogin = async () => {
        try{
            const wallet = await connectBlocto()
                    if(wallet && wallet.walletAddress){
                        this.props.linkWallet(JSON.stringify(wallet))
                        this.setState({ callSetup: true })
                    }else{
                        this.props.walletnotsetup(wallet)
                        history.push('/discover')
                    }
                }catch(e){
                    this.props.walletnotsetup(e)
                    history.push('/discover')
                }
    }
    createaccountSetup = async () => {
        const walletAddress = await getUserAddress()
        try {
            const setup = await accountSetup(walletAddress)
            if(setup && setup.status === 200){
                this.props.walletSuccessSetup(setup.message)
                history.push('/discover')
            }else if(setup && setup.status === 201){
                history.push('/discover')
            }else{
                this.props.walletnotsetup(setup)
             history.push('/discover')
            }
        }catch(e){
            this.props.walletnotsetup(e)
             history.push('/discover')
        }
    }
   
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    closeSwitchAccModal = () => {
        this.setState({
            isSwitchAcc: false
        })
    }
    handleToSwitchAccount = () => {
        this.setState({
            isSwitchAcc: true
        }) 
    }
    switchWalletConnected = (wallet) => {
        if(wallet){
            this.closeSwitchAccModal()
            this.props.linkWallet(JSON.stringify(wallet))
            this.setState({ callSetup: true })
        }
    }
    handleSwitchAccFailed = (message) => {
        this.props.walletnotsetup(message)
        history.push('/discover')
    }
    render() {

        return (
            <Fragment>
                <Helmet>
                    <title>Connect Wallet</title>
                    <meta name="title" content="Connect wallet"></meta>
                    <meta name="description" content={`Login to ${process.env.REACT_APP_SITE_CONTRACT}`}></meta>
                </Helmet>

                {this.state.isAlert && <div className="alertcommon" style={{ zIndex: 1 }}>
                    <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert} />

                </div>}
                {this.state.isSwitchAcc ? 
                    <SwitchAccount 
                    closeModal={this.closeSwitchAccModal} 
                    switchWalletConnected={(val) => this.switchWalletConnected(val)}
                    handleSwitchAccFailed={(val) => this.handleSwitchAccFailed(val)}/> 
                    : ''}
                <div className="container">
                    <div className='otpverifiy'>

                        <h1 className="text-center mt-5 mb-3">Connect Wallet</h1>
                        <div id="overlay" onClick={this.off} >
                            
                        </div>
                        <div className="text-center mt-5 mb-3 " >
                                <button type="button" className="btn btn-primary mr-3 connect-wallet" onClick={this.handleToSwitchAccount}>Connect Wallet</button>

                            </div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        wallet: state.wallet.wallet,
    }
}

const actionCreators = {

    getUserProfile: userAction.getUsers,
    linkWallet: walletActions.linkWallet,
    resetlinkWallet: walletActions.resetlinkWallet,
    walletSuccessSetup : walletActions.walletSuccess,
    walletnotsetup : walletActions.walletError,

}

const connectConnectWallet = connect(mapStateToProps, actionCreators)(ConnectWallet)

export { connectConnectWallet as ConnectWallet }
