import React, { Fragment } from "react";

export class ArtActivity1 extends React.Component {

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="pagetitabtn mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="d-inline-block"><span className="usernamelilac">Haunting Eyes</span> - Activity </h1>
                                <div className="probtns text-center activebtnscus float-right pt-0 aecls">                                 
                                 <select className="js-example-basic-single" name="state">
                                        <option value="e1">Edition of 1</option>
                                        <option value="e2">Edition of 2</option>
                                        <option value="e3">Edition of 3</option>
                                        <option value="e4">Edition of 4</option>
                                        <option value="e5">Edition of 5</option>
                                    </select>
                            </div>
                            </div>
                        </div>
                        <hr className="mb-3" />
                    </div>
                    <div className="activitysec">   

                     <h4 className="mb-3">Edition of 1</h4>    

                        <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/creator/@dhilip11">@dhilip11</a>&nbsp;for 45 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 12 Nov, 2021 at 11:10am <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/e6ea82ce6ef524fabe7fc54476b15d579ed54a8da7fec09905036abdbb955fa3"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 36 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 12 Nov, 2021 at 10:44am <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/de6ec269d84aa5c8e253400811113dd791b7a7cc227adef5f370b0dbc47b7f34"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/creator/@dhilip6">@dhilip6</a>&nbsp;for 35 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 7:00pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/40da0d3f54d67cfee023775c38b4fda782789624f000362167ca2c9b243df0cc"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 25 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:53pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/a239113df024fa0e586d146f7cfb27cf3371f3decf7e1fd4f43c8f8fce45b1e0"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-gavel mt-1 mr-2 " aria-hidden="true"></i> Purchased by
                                        <a href="/creator/@dhilip6">@dhilip6</a>&nbsp;for 351 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:28pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/7aa3d9ef097b95240e335ad19b82de5ab5b47a50dd6de90f677da8d1eae917bf"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 15 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:13pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/662996e9df09fe709805c045998688e451c446ca3f03b506029db0d87f8b3d7d"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 15 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:13pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/662996e9df09fe709805c045998688e451c446ca3f03b506029db0d87f8b3d7d"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 15 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:13pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/662996e9df09fe709805c045998688e451c446ca3f03b506029db0d87f8b3d7d"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-shopping-cart mt-1 mr-2 " aria-hidden="true"></i>
                                        Purchased by <a href="/collector/@dhilip7">@dhilip7</a>&nbsp;for 15 FUSD</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:13pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/662996e9df09fe709805c045998688e451c446ca3f03b506029db0d87f8b3d7d"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            </div>
                            <div className="purchasebox activitybox">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-0"><i className="fa fa-connectdevelop mt-1 mr-2 " aria-hidden="true"></i>
                                        Minted by <a href="/creator/@dhilip10">@dhilip10</a></h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6 className="mb-0">at 11 Nov, 2021 at 6:06pm <a target="blank"
                                        href="https://flow-view-source.com/testnet/tx/0ddb756db7ac092e1f3fcc9781cc76020468ce32002b0aed60512a5d345fa8d5"
                                        className="ml-2"><i className="fas fa-external-link-alt" aria-hidden="true"></i></a></h6>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="w-100 text-center">
                            <ul className="pagination">
                                <li className="previous disabled"><a tabindex="0" role="button" aria-disabled="true" aria-label="Previous page"
                                    rel="prev">&lt;</a></li>
                                <li className="active"><a role="button" tabindex="0" aria-label="Page 1 is your current page"
                                    aria-current="page">1</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 2">2</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 3">3</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 4">4</a></li>
                                <li className="break"><a role="button" tabindex="0">...</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 34">34</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 35">35</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 36">36</a></li>
                                <li><a role="button" tabindex="0" aria-label="Page 37">37</a></li>
                                <li className="next"><a tabindex="0" role="button" aria-disabled="false" aria-label="Next page" rel="next">&gt;</a>
                                </li>
                            </ul>
                        </div>
                </div>
            </Fragment>
        )
    }
}