import { categoryConstants } from "../../constants"
import { adminServices, tagServices } from "services"
import PubSub from 'pubsub-js';
import { toast } from "react-toastify";

export const categoryActions = {
    getCategories,
    resetGetCategories,
    addCategories,
    resetAddCategories,
    getUserCategories,
    resetGetUserCategories,
    updateCategoryParent,
    resetUpdateCategoryParent
}
function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
}

function getCategories(){
    return dispatch => {
        showLoader()
        adminServices.getCategories()
        .then(tags => {
            if(tags){
                dispatch({type:categoryConstants.GET_CATEGORIES,categories:tags})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetGetCategories(){
    return dispatch => {
        dispatch({type:categoryConstants.GET_CATEGORIES,categories:[]})
    }
}
function addCategories(obj){
    return dispatch => {
        showLoader()
        adminServices.addCategories(obj)
        .then(tags => {
            if(tags){
                toast.success("Category added")
                dispatch({type:categoryConstants.ADD_CATEGORIES,addedCategory:tags})
                hideLoader()
            }
        },error => {
            hideLoader()
            toast.error(error)
        })
    }
}
function resetAddCategories(){
    return dispatch => {
        dispatch({type:categoryConstants.ADD_CATEGORIES,addedCategory:[]})
    }
}
function getUserCategories(){
    return dispatch => {
        // showLoader()
        tagServices.getAllCategories()
        .then (catg => {
            if((catg && catg.statusCode) === 200){
                dispatch({type : categoryConstants.GET_USER_CATEGORIES,userCategories : catg})
                // hideLoader()
            }
        },error => {
            // hideLoader()
        })
    }
}
function resetGetUserCategories(){
    return dispatch => {
        dispatch({type : categoryConstants.GET_USER_CATEGORIES,userCategories : []})
    }
}
function updateCategoryParent(id,obj){
    return dispatch => {
        showLoader()
        adminServices.updateCategoryParent(id,obj)
        .then (catg => {
            if((catg && catg.statusCode) === 200){
                toast.success("Category updated")
                dispatch({type : categoryConstants.UPDATE_CATEGORIES,updateCategory : catg})
                hideLoader()
            }
        },error => {
            toast.error(error)
            hideLoader()
        })
    }
}
function resetUpdateCategoryParent(){
    return dispatch => {
        dispatch({type : categoryConstants.UPDATE_CATEGORIES,updateCategory : []})
    }
}


