import { flowDecimal } from "helpers";
import moment from "moment";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { adminServices, artServices, storeServices } from "services";
import { creatorStoreActions } from "store/actions";
import { debounce } from "utils";

class AutoInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: "",
            isLoading: false,
            elementId: Date.now().toString()
        };
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.debouncedSearchArt = debounce(this.searhForArts.bind(this),300);
        this.debouncedSearchCreator = debounce(this.searchCreator.bind(this),300);
        this.debouncedSearchCollector = debounce(this.searchCollector.bind(this),300);
    }

    callSuggestionSelectedHandler(selectedSuggestion) {
        if (
            this.props.onSuggetionSelected &&
            typeof this.props.onSuggetionSelected === "function"
        ) {
            this.props.onSuggetionSelected(selectedSuggestion);
        }
    }

    onChange(e) {
        e.persist();
        const userInput = e.currentTarget.value;
        if (
            userInput !== '' 
            // this.props.onTextChange &&
            // typeof this.props.onTextChange === "function"
        ) {
            this.fetchSearchResults(userInput)
            // this.props.onTextChange(userInput);
        }

        this.setState({
            userInput,
            showSuggestions: true
        });
    }
    onClick(selectedSuggestion, e) {
        // console.log('click event object', e)
        this.callSuggestionSelectedHandler(selectedSuggestion);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: ''
        });
    }

    onKeyDown(e) {
        // console.log("key",e)
        const { activeSuggestion, filteredSuggestions } = this.state;

        if (e.keyCode === 13) {
            e.preventDefault();
            const searchResult = filteredSuggestions[activeSuggestion]
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: ''
            });

            this.callSuggestionSelectedHandler(searchResult);
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    }
    searchCreator(searchTerm){
        const {isFeatureArt} = this.props
        adminServices.searchFeaturedCreator(searchTerm).then((response) => {
            // console.log(response)

            this.setState({
                isSearching: false,
                isLoading: false
            })
            if (
                response.data &&
                response.data.creator &&
                Array.isArray(response.data.creator) 
            ) {
                const filteredSuggestions = [];
                for (const user of response.data.creator) {
                    filteredSuggestions.push({
                        artId: user.id,
                        mainText: user.userName,
                        subText : user.fullName
                    })
                }
    
                this.setState({
                    activeSuggestion: 0,
                    filteredSuggestions,
                    showSuggestions: true,
                });
            }
        }).catch(e => {
            this.setState({
                isSearching: false,
                isLoading: false
            })
            // console.error(e);
        })
    }
    searchCollector(searchTerm){
        const {isFeatureArt} = this.props
        adminServices.searchFeaturedCollector(searchTerm).then((response) => {
            // console.log(response)

            this.setState({
                isSearching: false,
                isLoading: false
            })
            if (
                response.data &&
                response.data.creator &&
                Array.isArray(response.data.creator) 
            ) {
                const filteredSuggestions = [];
                for (const user of response.data.creator) {
                    filteredSuggestions.push({
                        artId: user.id,
                        mainText: user.userName,
                        subText : user.fullName,

                    })
                }
    
                this.setState({
                    activeSuggestion: 0,
                    filteredSuggestions,
                    showSuggestions: true,
                });
            }
        }).catch(e => {
            this.setState({
                isSearching: false,
                isLoading: false
            })
            // console.error(e);
        })
    }
    getDetail = (art) => {
        if((art && art.drops && art.drops[0] && art.drops[0].markedPrice)){
            return `List price : ${(flowDecimal(parseFloat(art.drops[0].markedPrice)) + ' ' + "USD")}`
        }else if((art && art.drops && art.drops[0] && art.drops[0].auction)){
            const auctionEnd = new Date(art.drops[0].auction.endDay);
            if(Date.now() > auctionEnd.getTime()){
                return `Auction has ended`
            }else{
                return `In Auction. Ends by : ${moment(art.drops[0].auction.endDay).format('MMM/DD/YYYY hh:mm:ss A')}`
            }
        }else{
            return "Not for sale"
        }
    }
    searhForArts(term){
        const searchTerm = term && term.trim()
        if(searchTerm && searchTerm.length >= 3){
            storeServices.getSearchedDrop(searchTerm).then(response => {
        
                this.setState({
                    isSearching: false,
                    isLoading: false
                })
                if (
                    response.data &&
                    response.data.arts &&
                    Array.isArray(response.data.arts) 
                ) {
                    const filteredSuggestions = [];
                    for (const art of response.data.arts) {
                        filteredSuggestions.push({
                            artId: Number(art && art.drops && art.drops[0] && art.drops[0].id),
                            mainText: art.title,
                            subText : art && art.owner && art.owner.userName && `Owner : @${art.owner.userName}`,
                            artDet : this.getDetail(art)
                        })
                    }
        
                    this.setState({
                        activeSuggestion: 0,
                        filteredSuggestions,
                        showSuggestions: true,
                    });
                }
            }).catch(e => {
                this.setState({
                    isSearching: false,
                    isLoading: false
                })
                toast.error(e)
            })
        }
        
    }
    componentDidUpdate(props, state) {
        console.log(this.props.selectedArts)

        if (this.props.selectedArts &&
            this.props.selectedArts.statusCode === 200 &&
            // this.props.isSection === this.state.isSection
            (this.props.selectedArts !== (props.selectedArts))
        ) {
            this.props.resetSearchedArts()
            console.log(this.props.selectedArts)
            this.setState({
                isFetched: true,
                isSearching: false,
                isLoading: false
            })
            if (
                this.props.selectedArts.data &&
                this.props.selectedArts.data.arts &&
                Array.isArray(this.props.selectedArts.data.arts)
            ) {
                const filteredSuggestions = [];
                for (const art of this.props.selectedArts.data.arts) {
                    filteredSuggestions.push({
                        artId: Number(art.id),
                        mainText: art.title,
                        subText: art && art.owner && art.owner.userName && `Owner : @${art.owner.userName}`,
                        artDet: this.getDetail(art)
                    })
                }

                this.setState({
                    activeSuggestion: 0,
                    filteredSuggestions,
                    showSuggestions: true,
                });
            }
        }
    }
    fetchSearchResults(searchTerm) {
        const {isSection} = this.props
        this.setState({
            isSearching: true
        })
        if(isSection === 1){
            this.debouncedSearchArt(searchTerm)
        }else if(isSection === 2){
            this.debouncedSearchArt(searchTerm)
        }else if(isSection === 3){
            this.debouncedSearchCreator(searchTerm)
        }
    }
   
  

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions: sugesstionList,
                showSuggestions,
                userInput,
                isLoading
            }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (!isLoading) {
                if (sugesstionList.length) {
                    suggestionsListComponent = (
                        <ul className="suggestions">
                            
                            {sugesstionList.map((result, index) => {

                                let className;

                                // Flag the active suggestion with a class
                                if (index === activeSuggestion) {
                                    className = "suggestion-active";
                                }


                                return (
                                    <li
                                        className={className}
                                        // key={result.artId}
                                        onClick={(e) => onClick(result, e)}
                                    >
                                        <span>{result.mainText}</span><br />
                                        {/* <small>{result.subText}</small><br/> */}
                                        <small>{result.artDet}</small>

                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else {
                    suggestionsListComponent = (
                        <div className="no-suggestions">
                            <em>No suggestions available.</em>
                        </div>
                    );
                }
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <i className="fas fa-spin fa-circle-notch fa-lg"></i>
                    </div>
                );
            }
        }
        const inputPlaceHolder = (
            this.props.placeholder &&
            typeof this.props.placeholder === 'string'
        )
            ? this.props.placeholder
            : null
        return (
            <Fragment>
                <form className="form-inline" autoComplete="false">
                    <div className="form-group mt-1">
                        <div className={"auto-suggest " + (this.state.userInput.length ? "icon-hidden ds-inputs bg-transparent" : "ds-inputs bg-transparent")}>
                            <input type="search"
                                id={this.state.elementId}
                                className="form-control"
                                // placeholder={
                                //     inputPlaceHolder ? inputPlaceHolder : "Search..."
                                // }
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                value={userInput}
                                style={{width:"500px"}}

                            />
                            {suggestionsListComponent}
                            <div className="input-floating-label"><span>Search Art</span></div>

                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        selectedArts: state.creatorStore.searchedArts,

    }
}
const actionCreator = {
    searchArts: creatorStoreActions.searchArts,
    resetSearchedArts: creatorStoreActions.resetSearchArts,
    resetSearchedArtsFailed: creatorStoreActions.resetSearchArtsFailed

}
const connectAutoInput = connect(mapStateToProps, actionCreator)(AutoInput)
export { connectAutoInput as AutoInput }