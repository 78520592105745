import React from "react";
import PubSub from 'pubsub-js';
import { connect } from "react-redux";
import { configPath } from "../../config";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { toast, Zoom } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { creatorStoreActions } from "store/actions";
import { storeServices } from "services";
import Toggle from 'react-toggle';
import moment from "moment";
import DatePicker from 'react-datetime';
import { SketchPicker } from 'react-color';

const sectionDefaultName = {
    TEAM: 'team',
    AMBASSADORS: 'ambassadors',
    SPONSORS: 'sponsors',
    ABOUT_US: 'about us',
    BANNER: 'banner',
    TIMELINE: 'timeline'
}
class DynamicSectionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: '',
            subContent: '',
            content: '',
            isDisable: false,
            bgcolor1: '#0e2618',
            // bgcolor2: '#172f0d',
            bgcolor2: '#19350c',

            bgcolor3: '#1f4219',
            bgcolor4: '#19350c',
            displayColorPicker1: false,
            displayColorPicker2: false,
            section: {},
            sectionName: '',
            editText: '',
            isBackground: 'image',
            background: '#fff',
            theme: 'snow',
            modules: {
                toolbar: [
                    [{ 'header': '1' },
                    ],
                    [
                        // 'bold', 
                        'italic',
                        'underline',
                        // 'blockquote'
                    ],
                    [{
                        'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
                    }]
                ],
                clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                }
            },
            formats: [
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
                'list', 'bullet', 'indent',
                'link', 'image', 'video', 'color'
            ],
            timelineList: [],
            galleryImages: [],
            galleryImage: '',
            galleryCaption: '',
            sectionDet: {},
            sectionId: '',
            isDisabled: false,
            isTooLengthCaption: false,
            cardName: '',
            cardDef: '',
            evnDate: '',
            isTooLengthCardName: false,
            isTooLengthCardDef: false
        };
        this.handleChange = this.handleHeadChange.bind(this)
        this.handleHtmlContentChange = this.handleSubContentchange.bind(this)
        this.Categories = {
            BANNER: 'Banner',
            CONTENT: 'Content',
            DROPS: 'Drops',
            TIMELINE: 'Timeline',
            PHOTOGALLERY: 'PhotoGallery',
            SPONSORS: 'Sponsors',
            STATIC: 'Static',
            ANNOUNCEMENT: 'Announcement'
        }
        this.CategoriesLower = {
            BANNER: 'banner',
            CONTENT: 'content',
            DROPS: 'drops',
            TIMELINE: 'timeline',
            PHOTOGALLERY: 'photogallery',
            SPONSORS: 'sponsors',
            STATIC: 'static',
            ANNOUNCEMENT: 'announcement'

        }
        
        this.titleMaxLength = 100
        this.titleMinLength = 3
        this.captionMinLength = 3
        this.captionMaxLength = 50
        this.contentMinLength = 3
        this.contentMaxLength = 3000
        this.cardMinLength = 3
        this.cardMaxLength = 30
        this.cardDefMinLength = 3
        this.cardDefMaxLength = 50

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()

    }

    componentDidUpdate(prevProps, state) {
        if (
            (this.props && this.props.sectionDetail && this.props.sectionDetail.statusCode === 200) &&
            (this.props.sectionDetail !== (prevProps && prevProps.sectionDetail))) {
            this.props.resetDetailSuccess()
            this.setState({
                isFetched: true,
            })

            if (this.props && this.props.sectionDetail && this.props.sectionDetail.data) {
                if (this.props.sectionDetail.data && this.props.sectionDetail.data.name &&
                    this.props.sectionDetail.data.name.toLowerCase().includes(sectionDefaultName.ABOUT_US)) {
                    this.setState({
                        modules: {
                            toolbar: [
                                [
                                    'bold',
                                    'italic',
                                    'underline',
                                ],
                                [{
                                    'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
                                }]
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            }
                        },
                    })
                } else if (this.props.sectionDetail.data && this.props.sectionDetail.data.name &&
                    this.props.sectionDetail.data.name.toLowerCase().includes(sectionDefaultName.BANNER)) {
                    this.setState({
                        modules: {
                            toolbar: [
                                [{ 'header': '1' },
                                ],
                                [
                                    // 'bold', 
                                    'italic',
                                    'underline',
                                    // 'blockquote'
                                ],
                                [{
                                    'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
                                }]
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            }
                        },
                    })
                } else {
                    this.setState({
                        modules: {
                            toolbar: [
                                [
                                    'bold',
                                    'italic',
                                    'underline',
                                ],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{
                                    'color': ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color']
                                }]
                            ],
                            clipboard: {
                                // toggle to add extra line breaks when pasting HTML:
                                matchVisual: false,
                            }
                        },
                    })
                }
                this.setState({
                    sectionDet: this.props.sectionDetail.data,
                    sectionName: this.props.sectionDetail.data.title ? this.props.sectionDetail.data.title : '',
                    sectionId: this.props.sectionDetail.data.id,
                    subContent: this.props.sectionDetail.data.content ? this.props.sectionDetail.data.content : '',
                    isDisabled: this.props.sectionDetail.data.disabled ? true : false,

                })
                if (this.props.sectionDetail.data.background) {
                    if (this.props.sectionDetail.data.background.startsWith('/api/v1')) {
                        console.log(this.props.sectionDetail.data.background)
                        this.setState({
                            banner: this.props.sectionDetail.data.background ? `${configPath.baseUrl}${this.props.sectionDetail.data.background}` : '',
                            isBackground: 'image'
                        })
                    } else {
                        console.log(this.props.sectionDetail.data.background)

                        let colors = this.props.sectionDetail.data.background.substring(this.props.sectionDetail.data.background.indexOf('(') + 1, this.props.sectionDetail.data.background.indexOf(')'));
                        if (colors) {
                            let colorsArray = colors.split(',')
                            this.setState({
                                bgcolor1: colorsArray[0],
                                bgcolor2: colorsArray[1]
                            })
                        }
                        this.setState({
                            banner: null,
                            isBackground: "color"
                        })
                    }
                }

            }
        }
        if (this.props &&
            this.props.sectionDetailFailed &&
            this.props.sectionDetailFailed.length > 0 &&
            (this.props.sectionDetailFailed !== prevProps.sectionDetailFailed)
        ) {
            this.props.resetDetailFailed()
            toast.error(this.props.sectionDetailFailed, { transition: Zoom })

        }
        if (this.props &&
            this.props.formsubmitFailed &&
            this.props.formsubmitFailed.length > 0 &&
            (this.props.formsubmitFailed !== prevProps.formsubmitFailed)
        ) {
            this.props.resetSubmitFormFailed()
            toast.error(this.props.formsubmitFailed, { transition: Zoom })

        }
        if (
            (this.props && this.props.formsubmitSuccess && this.props.formsubmitSuccess.statusCode === 200) &&
            (this.props.formsubmitSuccess !== (prevProps && prevProps.formsubmitSuccess))) {
            this.props.resetSubmitFormSuccess()
            this.props.getDetail(this.state.sectionId)
            toast.success("Updated successfully", { transition: Zoom })
            this.setState({ bannerFileData: {} })
        }
        if (
            (this.props && this.props.galleryList && this.props.galleryList.statusCode === 200) &&
            (this.props.galleryList !== (prevProps && prevProps.galleryList))) {
            this.props.resetGetGalleryItem()
            if (this.props.galleryList && this.props.galleryList.data && this.props.galleryList.data.gallery && this.props.galleryList.data.gallery.length > 0) {
                let definedArray = []
                this.props.galleryList.data.gallery.map((items, index) => {
                    let obj = {
                        input: { image: configPath.baseUrl + (items && items.imageUrl), caption: items && items.caption, id: items && items.id }
                    }
                    definedArray.push(obj)

                })
                this.setState({ galleryImages: definedArray })
            }
        }
        if (this.props &&
            this.props.galleryListFailed &&
            this.props.galleryListFailed.length > 0 &&
            (this.props.galleryListFailed !== prevProps.galleryListFailed)
        ) {
            this.props.resetGetGalleryItmeFailed()
            this.setState({ galleryImages: [] })

        }
        if (
            (this.props && this.props.timelineList && this.props.timelineList.statusCode === 200) &&
            (this.props.timelineList !== (prevProps && prevProps.timelineList))) {
            this.props.resetGetTimelines()
            if (this.props.timelineList && this.props.timelineList.data && this.props.timelineList.data.timeline && this.props.timelineList.data.timeline.length > 0) {
                let definedArray = []
                this.props.timelineList.data.timeline.map((items, index) => {
                    let obj = {
                        input: {
                            title: (items && items.title),
                            content: items && items.content, id: items && items.id,
                            date: moment(items && items.eventDate).format('YYYY-MMMM')
                        }
                    }
                    definedArray.push(obj)

                })
                this.setState({ timelineList: definedArray })
            }
        }
        if (this.props &&
            this.props.timelineListFailed &&
            this.props.timelineListFailed.length > 0 &&
            (this.props.timelineListFailed !== prevProps.timelineListFailed)
        ) {
            this.props.resetGetTimelinesFailed()
            this.setState({ timelineList: [] })

        }

    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.selectedSection && props.selectedSection.value && (props.selectedSection !== (state.section))) {
            if (props.selectedSection && props.selectedSection.value) {
                props.getDetail(props.selectedSection.value)
                if (props.selectedSection
                    && props.selectedSection.label
                    && (props.selectedSection.label.toLowerCase().includes(sectionDefaultName.AMBASSADORS) ||
                        props.selectedSection.label.toLowerCase().includes(sectionDefaultName.TEAM) ||
                        props.selectedSection.label.toLowerCase().includes(sectionDefaultName.SPONSORS))) {
                    props.getGalleryItem(props.selectedSection.value)
                }
                if (props.selectedSection
                    && props.selectedSection.label
                    && (props.selectedSection.label.toLowerCase().includes(sectionDefaultName.TIMELINE))) {
                    props.getTimelineItems(props.selectedSection.value)
                }
                return {
                    section: props.selectedSection,
                    banner: '',
                    subContent: '',
                    content: '',
                    isDisable: false,
                    bgcolor1: '#0e2618',
                    bgcolor2: '#19350c',
                    bgcolor3: '#1f4219',
                    bgcolor4: '#19350c',
                    sectionName: '',
                    editText: '',
                    bannerFileData: {},
                    isBackground: 'image',
                    timelineList: [],
                    galleryImages: [],
                    sectionDet: {},
                    sectionId: '',
                    galleryImage: '',
                    galleryImageData: {},
                    galleryCaption: '',
                    isTooLengthCaption: false,
                    cardName: '',
                    cardDef: '',
                    evnDate: '',
                    isTooLengthCardName: false,
                    isTooLengthCardDef: false,
                    displayColorPicker1: false,
                    displayColorPicker2: false

                }
            }

        }
        return null
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }


    checkFullName = (value) => {
        if (((value && value.length) <= this.titleMaxLength) && ((value && value.length) >= this.titleMinLength)) {
            return true
        } else {
            return false
        }
    }
    checkName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }

    checkURL(value) {
        var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

        const urlReg = /https:\/\/.*/;
        if (re.test(value)) {
            return true;
        }
        return false;
    }
    onHaedTextChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onErrorHandle = (error) => {
        toast.error(error, { transition: Zoom })

    }
    onBannerPicChanged = (e) => {
        const file = e.target.files[0]
        const types = ['image/png', 'image/jpeg', 'image/webp']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        e.target.value = null;
        if (types.every(type => file && file.type !== type)) {

            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)


        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported cover file size is ${2} MB`;
            this.onErrorHandle(err)

        }
        else if (file) {

            const reader = new FileReader();
            reader.onload = async (e) => {
                const dimension = await this.getImageDimension(file);
                console.log(dimension)
                if (dimension && Number(dimension.width) == 1920 && dimension.height == 1080) {
                    this.setState({
                        bannerFileData: file,
                        banner: reader.result
                    })
                } else {
                    this.setState({
                        bannerFileData: {},
                        banner: ''
                    })
                    err += `Image resolution should be 1920px * 1080px`;
                    this.onErrorHandle(err)
                }

            };
            reader.readAsDataURL(file)
        }
    }
    isNotEmptyObj = (obj) => {
        if (obj && obj.name) {
            return true
        } else {
            return false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { subContent, banner, bannerFileData, isDisabled, sectionId, sectionName, bgcolor1 } = this.state
        let formData = new FormData()
        const title = this.state.sectionName.replace(/<[^>]+>/g, '')
        const content = this.state.subContent.replace(/<[^>]+>/g, '')
        const bgcolor = `linear-gradient(${this.state.bgcolor1},${this.state.bgcolor2})`

        if (title) {
            if (this.checkFullName(title)) {
                formData.append('title', sectionName)
            } else {
                toast.error(`Title must be min ${this.titleMinLength} and max ${this.titleMaxLength} characters`)
                return false
            }
        } else {
            formData.append('title', null)
        }
        if (content) {
            if (this.checkContentLength(content)) {
                formData.append('content', subContent)
            } else {
                toast.error(`Content must be min ${this.contentMinLength} and max ${this.contentMaxLength} characters`)
                return false
            }
        } else {
            formData.append('content', null)
        }
        formData.append("sectionId", sectionId);
        formData.append('disabled', isDisabled.toString())

        if (this.state.isBackground === 'image') {
            if (banner && bannerFileData) {
                this.isNotEmptyObj(bannerFileData) && formData.append("background", bannerFileData);
            }
        } else {
            if(this.state.isBackground === 'color'){
                formData.append('backgroundColor', (bgcolor))
            }
        }
        this.props.submitForm(formData)




    }

    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    getImageDimension(file) {
        const dimensionPromise = new Promise((resolve) => {
            const src = URL.createObjectURL(file);
            const image = new Image();
            image.src = src;

            image.onload = function () {
                const dimension = {
                    width: image.width,
                    height: image.height
                };
                URL.revokeObjectURL(src);
                resolve(dimension);
            };
        });
        return dimensionPromise;
    }
    isBannerNeed = (section) => {
        if (section) {
            return true
        } else {
            return true
        }
    }
    handleHeadChange(html) {
        this.setState({ sectionName: html }, () => {
            // console.log(this.state.sectionName)

            // console.log(this.state.sectionName.replace(/<[^>]+>/g, ''))
        });
    }
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };
    handleBackground = (e) => {
        if (e.target.value) {
            this.setState({
                isBackground: e.target.value,
                bannerFileData: {},
                // banner: ''
            })
        }
    }
    checkCharacType = (value) => {
        const char = /[^a-zA-Z0-9# ]/
        if ((value && char.test(value))) {
            return false
        } else {
            return true
        }
    }
    onColorChange = (event) => {
        if (this.checkCharacType(event.target.value)) {
            this.setState({
                [event.target.name]: event.target.value
            });
        }

    }



    handleRemoveImageLinks = (i) => {
        const { sectionId } = this.state
        const gallery = this.state.galleryImages
        if (gallery && gallery[i] && gallery[i].input && gallery[i].input.id) {
            this.showLoader()
            storeServices.deleteSectionGalleryItem(sectionId, gallery[i].input.id).then(res => {
                this.hideLoader()
                toast.success("Removed successfully", { transition: Zoom })
                this.setState((state, props) => {
                    state.galleryImages.splice(i, 1)
                    return {
                        galleryImages: state.galleryImages
                    }
                })
            }).catch(e => {
                this.hideLoader()
                toast.error(e, { transition: Zoom })
            })
        }

    }
    onProfilePicChanged = (e, isGallery) => {

        const file = e.target.files[0]
        const types = ['image/png', 'image/jpeg', 'image/webp']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        e.target.value = null;

        if (types.every(type => file && file.type !== type)) {
            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)

        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported logo file size is ${2} MB`;
            this.onErrorHandle(err)

        }

        else if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const dimension = await this.getImageDimension(file);
                if (isGallery) {
                    if (dimension && Number(dimension.width) >= 400 && dimension.height >= 400) {
                        this.setState({
                            galleryImageData: file,
                            galleryImage: reader.result
                        })
                    } else {
                        this.setState({
                            galleryImageData: {},
                            galleryImage: ''
                        })
                        err += `Minimum resolution 400px*400px`;
                        this.onErrorHandle(err)
                    }
                } else {
                    if (dimension && Number(dimension.width) <= 1024 && Number(dimension.width) >= 350) {
                        this.setState({
                            galleryImageData: file,
                            galleryImage: reader.result
                        })
                    } else {
                        this.setState({
                            galleryImageData: {},
                            galleryImage: ''
                        })
                        err += `Image width must be Min 350px - Max 1024Px`;
                        this.onErrorHandle(err)
                    }
                }


            };
            reader.readAsDataURL(file)

        }
    }
    handleAddMoreImages = () => {
        this.setState((state, props) => {
            let obj = {
                input: { image: '', imageData: {} }
            }
            state.galleryImages.push(obj)
            return {
                galleryImages: state.galleryImages
            }
        })
    }

    isCardNeed = (section) => {
        if (section && section.category && section.category.name && section.category.name.toLowerCase().includes(this.CategoriesLower.TIMELINE)) {
            return true
        } else {
            return false
        }
    }
    isSponsorNeed = (section) => {
        if (section && section.category && section.category.name && section.category.name.toLowerCase().includes(this.CategoriesLower.BANNER)) {
            return true
        } else {
            return false
        }
    }
    isGalleryNeed = (section) => {
        if (section
            && section.category
            && section.category.name
            && (section.category.name.toLowerCase().includes(this.CategoriesLower.PHOTOGALLERY) ||
                section.category.name.toLowerCase().includes(this.CategoriesLower.SPONSORS))) {
            return true
        } else {
            return false
        }
    }
    getBackgroundImageValue = (id) => {
        if (id) {
            storeServices.getBackgroundImage(id).then(res => {
                console.log(res)
            })
        }
    }
    statusChange = (e) => {
        this.setState({
            isDisabled: !this.state.isDisabled
        })
        const { sectionId } = this.state
        let formData = new FormData()
        formData.append("sectionId", sectionId);
        formData.append('disabled', (!this.state.isDisabled).toString())
        this.props.submitForm(formData)



    }
    onGalleryCaptionChange = (e) => {
        if (((e.target.value).length <= this.captionMaxLength)) {
            this.setState({
                galleryCaption: e.target.value, isTooLengthCaption: false,
            })

        } else {
            this.setState({ isTooLengthCaption: true, galleryCaption: (e.target.value.slice(0, this.captionMaxLength)) })
        }

    }
    checkCaptionName = (value) => {
        if (((value && value.length) <= this.captionMaxLength) && ((value && value.length) >= this.captionMinLength)) {
            return true
        } else {
            return false
        }
    }
    handleToSubmitGallery = (e, isGallery) => {
        e.preventDefault()
        const { galleryCaption, galleryImage, galleryImageData, sectionId } = this.state
        let formData = new FormData()
        formData.append("sectionId", sectionId);
        if (sectionId) {
            if ((galleryCaption && this.checkCaptionName(galleryCaption)) || !(isGallery)) {
                if (galleryImage && galleryImageData) {
                    this.isNotEmptyObj(galleryImageData) && formData.append("image", galleryImageData);
                    if (isGallery) {
                        formData.append('caption', galleryCaption)
                    } else {
                        formData.append('caption', `image${Date.now()}`)
                    }
                    this.showLoader()
                    storeServices.updateSectionGalleryItem(sectionId, (formData)).then(res => {
                        this.hideLoader()
                        if (res && res.statusCode === 200) {
                            if (res && res.data && res.data.section) {
                                toast.success("Added successfully")
                                this.props.getGalleryItem(sectionId)
                                this.setState({ galleryCaption: '', galleryImage: '', galleryImageData: {}, isTooLengthCaption: false })
                            }
                        }
                    }).catch(err => {
                        this.hideLoader()
                        if (err && typeof err === "string" && err.toLowerCase().includes('image already exist')) {
                            toast.error("Caption already exist", { transition: Zoom })
                        } else {
                            toast.error(err, { transition: Zoom })
                        }
                    })
                } else {
                    toast.error("Please upload your image")
                }
            } else {
                toast.error("Please enter valid caption")
            }
        } else {
            toast.error('Section id was missing')
        }


    }
    isGallery = (section) => {
        if (section
            && section.category
            && section.category.name
            && (section.category.name.toLowerCase().includes(this.CategoriesLower.PHOTOGALLERY))) {
            return true
        } else {
            return false
        }
    }
    isHtmlHeadNeed = (section) => {
        if (section
            && section.category
            && section.category.name
            && (section.category.name.toLowerCase().includes(this.CategoriesLower.BANNER))) {
            return true
        } else {
            return false
        }
    }
    isHtmlContentNeed = (section) => {
        if (section
            && section.category
            && section.category.name
            && ((section.category.name.toLowerCase().includes(this.CategoriesLower.CONTENT)) ||
                section.category.name.toLowerCase().includes(this.CategoriesLower.DROPS))) {
            return true
        } else {
            return false
        }
    }
    handleContentChange = (event) => {
        this.setState({
            subContent: event.target.value
        });
    }
    handleSubContentchange(html) {
        this.setState({ subContent: html }, () => {
            // console.log(this.state.sectionName)

            // console.log(this.state.sectionName.replace(/<[^>]+>/g, ''))
        });
    }
    isHeadBoxNeed = (section) => {
        if (section
            && section.category
            && section.category.name
            && !(section.category.name.toLowerCase().includes(this.CategoriesLower.ANNOUNCEMENT))) {
            return true
        } else {
            return false
        }
    }
    checkContentLength = (value) => {
        if (((value && value.length) <= this.contentMaxLength) && ((value && value.length) >= this.contentMinLength)) {
            return true
        } else {
            return false
        }
    }
    isContentBoxNeed = (section) => {
        if (section
            && section.category
            && section.category.name
            && ((section.category.name.toLowerCase().includes(this.CategoriesLower.BANNER)) ||
                (section.category.name.toLowerCase().includes(this.CategoriesLower.STATIC)) ||
                (section.category.name.toLowerCase().includes(this.CategoriesLower.ANNOUNCEMENT)))
        ) {
            return true
        } else {
            return false
        }
    }
    handleEvnDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
    }
    checkCardName = (value) => {
        if (((value && value.length) <= this.cardMaxLength) && ((value && value.length) >= this.cardMinLength)) {
            return true
        } else {
            return false
        }
    }
    checkCardDef = (value) => {
        if (((value && value.length) <= this.cardDefMaxLength) && ((value && value.length) >= this.cardDefMinLength)) {
            return true
        } else {
            return false
        }
    }
    handleToSubmitTimeline = (e) => {
        e.preventDefault()
        const { cardDef, cardName, evnDate, sectionId } = this.state
        if (sectionId) {
            if ((cardName && this.checkCardName(cardName))) {
                if (cardDef && this.checkCardDef(cardDef)) {
                    if (evnDate) {
                        let obj = {
                            title: cardName,
                            content: cardDef,
                            eventDate: moment(evnDate, 'MMMM-YYYY').toISOString()
                        }
                        this.showLoader()
                        storeServices.updateSectionTimelineItem(sectionId, (JSON.stringify(obj))).then(res => {
                            this.hideLoader()
                            if (res && res.statusCode === 200) {
                                if (res && res.data && res.data.event) {
                                    toast.success("Added successfully")
                                    this.props.getTimelineItems(sectionId)
                                    this.setState({ cardDef: '', cardName: '', evnDate: '', isTooLengthCardName: false, isTooLengthCardDef: false })
                                }
                            }
                        }).catch(err => {
                            this.hideLoader()
                            if (err && typeof err === "string" && err.toLowerCase().includes('image already exist')) {
                                toast.error("Caption already exist", { transition: Zoom })
                            } else {
                                toast.error(err, { transition: Zoom })
                            }
                        })
                    } else {
                        toast.error("Please select event month")
                    }
                } else {
                    toast.error("Please enter valid definition")
                }
            } else {
                toast.error("Please enter valid card title")
            }
        } else {
            toast.error('Section id was missing')
        }
    }
    handleRemoveLinks = (i) => {
        const { sectionId } = this.state
        const card = this.state.timelineList
        if (card && card[i] && card[i].input && card[i].input.id) {
            this.showLoader()
            storeServices.deleteSectionTimelineItem(sectionId, card[i].input.id).then(res => {
                this.hideLoader()
                toast.success("Removed successfully", { transition: Zoom })
                this.setState((state, props) => {
                    state.timelineList.splice(i, 1)
                    return {
                        timelineList: state.timelineList
                    }
                })
            }).catch(e => {
                this.hideLoader()
                toast.error(e, { transition: Zoom })
            })
        }

    }

    changeInputLink = (e) => {
        if (((e.target.value).length <= this.cardMaxLength)) {
            this.setState({
                cardName: e.target.value, isTooLengthCardName: false,
            })

        } else {
            this.setState({ isTooLengthCardName: true, cardName: (e.target.value.slice(0, this.cardMaxLength)) })
        }
    }
    changeDefinitionLink = (e) => {
        if (((e.target.value).length <= this.cardDefMaxLength)) {
            this.setState({
                cardDef: e.target.value, isTooLengthCardDef: false,
            })

        } else {
            this.setState({ isTooLengthCardDef: true, cardDef: (e.target.value.slice(0, this.cardDefMaxLength)) })
        }

    }
    handleOnstart = (e) => {
        this.setState({
            evnDate: moment(e).format('MMMM-yyyy')
        })
    }
    onChangePicker1 = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleColorClick1 = () => {
        this.setState({
            displayColorPicker1: !this.state.displayColorPicker1
        })
    }
    handleColorClick2 = () => {
        this.setState({
            displayColorPicker2: !this.state.displayColorPicker2
        })
    }
    handleColorClose1 = () => {
        this.setState({
            displayColorPicker1: false
        })
    }
    handleColorClose2 = () => {
        this.setState({
            displayColorPicker2: false
        })
    }
    handleColorChange1 = (newColor) => {
        console.log(newColor)
        this.setState({
            bgcolor1: newColor.hex
        })
    }
    handleColorChange2 = (newColor) => {
        this.setState({
            bgcolor2: newColor.hex
        })
    }
    render() {
        const { section, timelineList, galleryImages, sectionDet, isTooLengthCaption, isTooLengthCardName, isTooLengthCardDef } = this.state
        return (


            <div className="container-fluid1">

                <div className="propagecont mb-5">
                    <div className="ds-intit">
                        <div className="row">
                            <div className="col-md-8"> <h3 className="mb-0">{section && section.label}</h3></div>
                            <div className="col-md-4">

                                <div className="form-group mb-4 text-right">
                                    <div className="badgeview hideshowart">

                                        <h2 className="usernameClick" >

                                            {<span className='hideshowart'> <span className='mr-2 span-cursor'>Visibility</span>
                                                <a className={'card flowtop'}><Toggle checked={!this.state.isDisabled} onChange={this.statusChange} />
                                                    {<span className="copycon">
                                                        <span className="uparrow"></span>
                                                        {this.state.isDisabled ? (`Enable ${section && section.label}`) : (`Disable ${section && section.label}`)}
                                                    </span>} </a>
                                                <span className='ml-2 small usernamelilac span-cursor'>{(this.state.isDisabled) ? `[Hidden]` : '[Visible]'}</span></span>}
                                        </h2>


                                    </div>

                                </div>

                            </div>
                        </div>




                    </div>
                    <div className="container1">
                        <div className="row">

                            {this.isBannerNeed(sectionDet) ?
                                <div className="col-md-6">

                                    <div className="form-group ">
                                        <label className=" mb-2 mt-2" >What you want in your background? </label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="radio" className="form-check-input mr-3" id="image" value={"image"} onClick={this.handleBackground} checked={this.state.isBackground === "image"} />
                                                <label className="form-check-label" htmlFor="image">Background Image</label>
                                            </div>
                                            <div className="col-md-6">
                                                <input type="radio" className="form-check-input mr-3" id="color" value={"color"} onClick={this.handleBackground} checked={this.state.isBackground === "color"} />
                                                <label className="form-check-label" htmlFor="color">Background Color</label>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.isBackground === 'image') ? <div className="coverimg coverbrd imgcovergif rounded-0 mb-3">
                                        <img src={this.state.banner ? this.state.banner : `../${configPath.imageAssets}/cover-default.png`} alt="" />
                                        <span className="editcov">
                                            <span className="choosefile" title="Upload Background Image">
                                                <input type="file" name="banner" className="custom-file-input inp-box" id="bannerPic" accept="image/png, image/jpeg, image/webp, image/gif" onChange={this.onBannerPicChanged
                                                } />

                                                <label htmlFor="bannerPic" className="btn btn-outline-dark w-auto px-3" >
                                                    <i className="fas fa-pencil-alt"></i>
                                                </label>
                                            </span>
                                        </span>
                                    </div> : ''}

                                    {this.isBannerNeed(sectionDet) ? (this.state.isBackground === 'image') ? <div className="row sizecls small ">
                                        <div className="col-12 text-center">
                                            <span className="usernamelilac ">Image size :</span> 1920px x 1080px
                                        </div>

                                    </div> : '' : ''}
                                    {(this.state.isBackground === 'color') ? <form>
                                    <div className="form-group mb-4 ">
                                            <div className="ds-inputs mb-3 row">     
                                            <div className="col-md-6">
<div className="input-group"  onClick={this.handleColorClick1}>
  <input type="text" className="form-control py-3" value={this.state.bgcolor1} aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <div className="input-group-append">
    <span className="input-group-text" id="basic-addon2"  style={{backgroundColor: this.state.bgcolor1,width: 100}}> </span>
  </div>
  </div>                                          

                                                {this.state.displayColorPicker1 ? (
                                                    <div style={{
                                                        position: 'absolute',
                                                        zIndex: '2',
                                                        top: '50px',
                                                        right: '0px'
                                                    }}>
                                                        <div style={{
                                                            position: 'fixed',
                                                            top: '0px',
                                                            right: '0px',
                                                            bottom: '0px',
                                                            left: '0px',
                                                        }} onClick={this.handleColorClose1} />
                                                        <SketchPicker
                                                            color={this.state.bgcolor1}
                                                            onChange={this.handleColorChange1}

                                                        />
                                                    </div>
                                                ) : null}
                                                </div>
                                              <div className="col-md-6">
                                              <div className="input-group"  onClick={this.handleColorClick2}>
  <input type="text" className="form-control py-3" value={this.state.bgcolor2} aria-label="Recipient's username" aria-describedby="basic-addon2" />
  <div className="input-group-append">
    <span className="input-group-text" id="basic-addon2"  style={{backgroundColor: this.state.bgcolor2,width: 100}}> </span>
  </div>
</div>

                                               
                                                {this.state.displayColorPicker2 ? (
                                                    <div style={{
                                                        position: 'absolute',
                                                        zIndex: '2',
                                                        top: '50px',
                                                        right: '0px'
                                                    }}>
                                                        <div style={{
                                                            position: 'fixed',
                                                            top: '0px',
                                                            right: '0px',
                                                            bottom: '0px',
                                                            left: '0px',
                                                        }} onClick={this.handleColorClose2} />
                                                    <SketchPicker color={this.state.bgcolor2} onChange={this.handleColorChange2} />
                                                    </div>
                                                ) : null}
</div>
                                            </div>
                                        </div>
                                        <div className="form-group mb-4 ">
                                        
                                        <div 
                                            style={{backgroundImage: `linear-gradient(${this.state.bgcolor1},${this.state.bgcolor2})`, height: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px', borderRadius: '10px'}}
                                        >
                                            <p className="text-center text-white">Preview</p>
                                        </div>
                                       
                                        </div>
                                    </form> : ''}

                                </div> : ''}




                            <form className="col-md-6">
                                {this.isHtmlHeadNeed(sectionDet) ? <div className="form-group mb-4 ">
                                    <div className="input-floating-label"><span>Section Name</span></div>

                                    <div className="ds-inputs">

                                        <ReactQuill
                                            theme={this.state.theme}
                                            onChange={this.handleChange}
                                            value={this.state.sectionName}
                                            modules={this.state.modules}
                                            formats={this.state.formats}
                                            bounds={'.app'}
                                            placeholder={'Enter your section name'}
                                        />

                                    </div>

                                </div> :
                                    this.isHeadBoxNeed(sectionDet) ? <div className="form-group mb-4">

                                        <div className="ds-inputs">
                                            <input type="text" name="sectionName" className="form-control" id="sectionName" aria-describedby="sectionName" value={this.state.sectionName} onChange={this.onHaedTextChange} />
                                            <div className="input-floating-label"><span>Section Name</span></div>
                                        </div>
                                        {this.state.submitted && this.state.sectionName && !this.checkFullName(this.state.sectionName) && <div className="mandatory">{`Min ${this.titleMinLength} - Max${this.titleMaxLength} characters`}</div>}
                                    </div> : ''}

                                {this.isHtmlContentNeed(sectionDet) ? <div className="form-group mb-4">

                                    <div className="ds-inputs">

                                        <ReactQuill
                                            theme={this.state.theme}
                                            onChange={this.handleHtmlContentChange}
                                            value={this.state.subContent}
                                            modules={this.state.modules}
                                            formats={this.state.formats}
                                            bounds={'.app'}
                                            placeholder={'Write your content'}
                                        />
                                    </div>
                                </div> :
                                    this.isContentBoxNeed(sectionDet) ? <div className="form-group mb-4">

                                        <div className="ds-inputs">
                                            <textarea name="subContent" className="form-control" id="subContent" aria-describedby="subContent" value={this.state.subContent} onChange={this.handleContentChange} />
                                            <div className="input-floating-label"><span>Content</span></div>
                                        </div>
                                        {this.state.submitted && this.state.subContent && !this.checkContentLength(this.state.subContent) && <div className="mandatory">{`Min ${this.contentMinLength} - Max ${this.contentMaxLength} characters`}</div>}
                                    </div> : ''}





                                {/* <p className='auptxt' dangerouslySetInnerHTML={{ __html: this.state.editText }}></p> */}
                                <div className="form-group mb-4 text-center">

                                    <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                                </div>

                            </form>
                            <form className="col-md-12">
                                {this.isCardNeed(sectionDet) ?
                                    <div className="row align-items-center mt-5" >
                                        <div className="from-group mb-3 col-md-12">
                                            <h5 className="usernamelilac mb-2">Update your timeline cards</h5>
                                            <hr className="lightline" />
                                        </div>
                                        {timelineList && timelineList.map((items, index) => (
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="form-group mb-4 col-md-4">

                                                        <div className="ds-inputs">

                                                            <div className="mb-3">
                                                                <input type="text" className="form-control" aria-describedby={items && items.input && items.input.title} value={items && items.input && items.input.title} name={`cardName${index}`} readOnly />


                                                            </div>
                                                            <div className="input-floating-label"><span>card title</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-4 col-md-4">

                                                        <div className="ds-inputs">

                                                            <div className="mb-3">
                                                                <input type="text" className="form-control" aria-describedby={items && items.input && items.input.content} value={items && items.input && items.input.content} name={`definition${index}`} readOnly />

                                                            </div>
                                                            <div className="input-floating-label"><span>card definition</span></div>
                                                        </div>

                                                    </div>
                                                    <div className="form-group mb-4 col-md-4">

                                                        <div className="ds-inputs">

                                                            <div className="input-group mb-3">
                                                                <input type="text" name={`evnDate${index}`} value={items && items.input && items.input.date} className="form-control" readOnly />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="button" onClick={() => this.handleRemoveLinks(index)} title="Remove from timeline">X</button>
                                                                </div>
                                                            </div>
                                                            <div className="input-floating-label"><span>Event month</span></div>
                                                        </div>

                                                    </div></div></div>))}

                                    </div>

                                    : ''}
                                {this.isCardNeed(sectionDet) ?
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="form-group mb-4 col-md-3">
                                                <div className="ds-inputs">
                                                    <div className="mb-3">
                                                        <input type="text" className={isTooLengthCardName ? "form-control descdan" : 'form-control'} aria-describedby={"cardName"} value={this.state.cardName} onChange={this.changeInputLink} name={`cardName`} />
                                                    </div>
                                                    <div className="input-floating-label"><span>Enter card title</span></div>
                                                    {this.state.cardName && !this.checkCardName(this.state.cardName) && <div className="mandatory">{`Min ${this.cardMinLength} - Max ${this.cardMaxLength}`}</div>}

                                                </div>
                                            </div>
                                            <div className="form-group mb-4 col-md-4">
                                                <div className="ds-inputs">
                                                    <div className="mb-3">
                                                        <input type="text" className={isTooLengthCardDef ? "form-control descdan" : "form-control"} aria-describedby={'cardDef'} value={this.state.cardDef} onChange={this.changeDefinitionLink} name={`cardDef`} />
                                                    </div>
                                                    <div className="input-floating-label"><span>Enter card definition</span></div>
                                                    {this.state.cardDef && !this.checkCardDef(this.state.cardDef) && <div className="mandatory">{`Min ${this.cardDefMinLength} - Max ${this.cardDefMaxLength}`}</div>}

                                                </div>

                                            </div>
                                            <div className="form-group mb-4 col-md-3">
                                                <div className="ds-inputs">
                                                    <div className="mb-3">
                                                        <DatePicker
                                                            onChange={(val) => this.handleOnstart(val)}
                                                            dateFormat="MMMM-yyyy"
                                                            timeFormat=""
                                                            value={this.state.evnDate}
                                                            closeOnSelect
                                                            renderInput={(props) => {
                                                                return <input {...props} value={(this.state.evnDate)} />
                                                            }} />
                                                        {/* <input type="month" name="evnDate" onChange={this.handleEvnDate} value={this.state.evnDate} className="form-control"  /> */}
                                                    </div>
                                                    <div className="input-floating-label"><span>Select event month</span></div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4 col-md-2">
                                                <button className="btn btn-primary px-3" type="button" onClick={(e) => this.handleToSubmitTimeline(e)} title="Add to timeline"><i className="fas fa-check facustom"></i></button>

                                            </div>
                                        </div>
                                    </div>


                                    : ''}

                            </form>
                            <form className="col-md-12">

                                {this.isGalleryNeed(sectionDet) ? <div className="row align-items-center mt-5" >
                                    <div className="from-group mb-3 col-md-12">
                                        <h5 className="usernamelilac mb-2">{this.isGallery(sectionDet) ? `Upload your ${section && section.label} images` : `Upload your ${section && section.label} logos`}</h5>
                                        <hr className="lightline" />
                                    </div>
                                    {galleryImages && galleryImages.length > 0 && galleryImages.map((items, i) => (

                                        <div className="form-group mb-5 col-6 col-sm-4 col-md-2" key={i}>

                                            <div className="picpro mt-0 mb-3">
                                                <span className="artcovimg ">
                                                    <span className="artcov"></span>
                                                    <span className="artcovimginset no-proimg">
                                                        <img src={items && items.input && items.input.image ? items.input.image : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                    </span>

                                                </span>

                                                <span className="editpro close" title="Remove Image">
                                                    <button className="btn btn-primary" type="button" onClick={() => this.handleRemoveImageLinks(i)}>X</button>
                                                </span>
                                                <div className="input-group-append">

                                                </div>

                                            </div>


                                            {this.isGallery(sectionDet) ? <div className="row sizecls small ">
                                                <div className="col-12 text-center">
                                                    {items && items.input && items.input.caption}
                                                </div>

                                            </div> : ''}
                                        </div>))}

                                </div> : ''}
                                <div className="row align-items-center" >
                                    <form className="col-6 col-sm-4 col-md-2">

                                        {this.isGalleryNeed(sectionDet) ?

                                            <div className="form-group mb-5">

                                                <div className="picpro mt-0 mb-4">
                                                    <span className="artcovimg ">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            {this.isGallery(sectionDet) ?
                                                                <img src={this.state.galleryImage ? this.state.galleryImage : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" /> :
                                                                <img src={this.state.galleryImage ? this.state.galleryImage : `../${configPath.imageAssets}/no-img.jpg`} alt="" />}
                                                        </span>

                                                    </span>
                                                    <span className="editpro">
                                                        <span className="choosefile" title="Upload Image">

                                                            <input
                                                                type="file"
                                                                className="custom-file-input inp-box"
                                                                id={`profilePic`}
                                                                accept="image/png, image/jpeg, image/webp, image/gif"
                                                                onChange={(e) =>
                                                                    this.onProfilePicChanged(e, this.isGallery(sectionDet))
                                                                }
                                                            />
                                                            <label htmlFor={`profilePic`} className="btn btn-primary w-auto px-3"><i className="fas fa-pencil-alt"></i></label>



                                                        </span>
                                                    </span>

                                                    <div className="input-group-append">

                                                    </div>
                                                </div>

                                                {this.isGallery(sectionDet) ? '' : <div className="row sizecls small">
                                                    <div className="col-12 text-center">
                                                        <span className="small usernamelilac">(Recommended : transparent background png images)</span></div></div>}



                                                {this.isGallery(sectionDet) ? <div className="form-group mb-5">
                                                    <div className="ds-inputs">
                                                        <div className="input-group mb-3 w-100">
                                                            <input type="text" name="galleryCaption" className={isTooLengthCaption ? "form-control descdan" : 'form-control'} id="galleryCaption" aria-describedby="galleryCaption" value={this.state.galleryCaption} onChange={this.onGalleryCaptionChange} />
                                                            <div className="input-floating-label"><span>Caption</span></div>
                                                            <div className="input-group-append text-center">
                                                                <button className="btn btn-primary px-3" type="button" onClick={(e) => this.handleToSubmitGallery(e, this.isGallery(sectionDet))}><i className="fas fa-check facustom"></i></button>

                                                            </div>
                                                        </div>

                                                        {this.state.galleryCaption && !this.checkCaptionName(this.state.galleryCaption) && <div className="mandatory">{`Min ${this.captionMinLength} - Max ${this.captionMaxLength}`}</div>}

                                                    </div>

                                                </div> : <div className="input-group-append text-center">
                                                    <button className="btn btn-primary px-3" type="button" onClick={(e) => this.handleToSubmitGallery(e, this.isGallery(sectionDet))}><i className="fas fa-check facustom"></i></button>

                                                </div>}

                                            </div> : ''}
                                    </form>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formsubmitFailed: state.creatorStore.submitFailed,
        formsubmitSuccess: state.creatorStore.submitSuccess,
        usersList: state.creatorStore.profile,
        sectionDetail: state.creatorStore.getSectionDetail,
        sectionDetailFailed: state.creatorStore.getSectionDetailFailed,
        galleryList: state.creatorStore.galleryItems,
        galleryListFailed: state.creatorStore.galleryItemsFailed,
        timelineList: state.creatorStore.timelineItems,
        timelineListFailed: state.creatorStore.timelineItemsFailed,

    }
}

const actionCreators = {
    submitForm: creatorStoreActions.editSectionName,
    resetSubmitFormSuccess: creatorStoreActions.resetEditFormSuccess,
    resetSubmitFormFailed: creatorStoreActions.resetEditFormFailed,
    getsections: creatorStoreActions.getStoreSection,
    getDetail: creatorStoreActions.getSectionDetail,
    resetDetailSuccess: creatorStoreActions.resetGetSectionDetail,
    resetDetailFailed: creatorStoreActions.resetGetSectionDetailFailed,
    getGalleryItem: creatorStoreActions.getSectionGalleryItem,
    resetGetGalleryItem: creatorStoreActions.resetGetgalleryItem,
    resetGetGalleryItmeFailed: creatorStoreActions.resetGetgalleryItemFailed,
    getTimelineItems: creatorStoreActions.getSectionTimelineItem,
    resetGetTimelines: creatorStoreActions.resetGetTimelineItems,
    resetGetTimelinesFailed: creatorStoreActions.resetGetTimelineItemFailed

}

const connectDynamicSectionForm = connect(mapStateToProps, actionCreators)(DynamicSectionForm)

export { connectDynamicSectionForm as DynamicSectionForm }