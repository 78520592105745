import React from 'react'

import {AdminLayout} from './AdminLayout'

import { Redirect, Route } from 'react-router'
import { isAdminLogin } from 'helpers';

export const AdminLayoutRoute = ({ component: Component, ...rest }) => {
	
	return (
		<Route {...rest} render={props => (

			isAdminLogin() 

				? <AdminLayout><Component {...props} /></AdminLayout>

				: <Redirect to={{ pathname: '/admin/login' }} />
		)} />
	);
};