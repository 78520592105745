import { AutoSuggestInput } from "components/auto-suggest-input/auto-suggest-input";
import { DraggableList } from "components/draggable-list/draggable-list";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import { adminServices, discoverServices } from "services";
import PubSub from 'pubsub-js';
import { discoverActions } from "store/actions";
import { connect } from "react-redux";

class AdminDiscover extends React.Component {

    constructor(props) {
        super(props);
        this.spotLightArtSelected = this.spotLightArtSelected.bind(this);
        this.spotLightArtDeletedHandler = this.spotLightArtDeletedHandler.bind(this);
        this.featuredArtSelected = this.featuredArtSelected.bind(this);
        this.featuredArtDeletedHandler = this.featuredArtDeletedHandler.bind(this);
        this.featuredCreatorSelected = this.featuredCreatorSelected.bind(this);
        this.featuredCreatorDeletedHandler = this.featuredCreatorDeletedHandler.bind(this);
        this.featuredCollectorSelected = this.featuredCollectorSelected.bind(this);
        this.featuredCollectorDeletedHandler = this.featuredCollectorDeletedHandler.bind(this);

        this.state = {
            spotLightArts: [],
            featuredArts: [],
            featuredCreators: [],
            featuredCollectors: [],
            offSet : 0,
            recordLimit : 10,
            sortBy : 'updatedAt',
            sortOrder : 'DESC',
            spotCount : '',
            artCount : '',
            creatorCount : '',
            collectorCount : ''
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    componentDidMount(){
        this.getSpotLight()
        this.getFeatureArt()
        this.getFeatureCreator()
        this.getFeatureCollector()  
        this.getDiscoverDet()
    }
    getSpotLight = (offSet,recordLimit) => {
        let spotLightArts = []
        this.showLoader()
        adminServices.getSpotlightArts().then(res => {
            this.hideLoader()
            if(res && res.statusCode === 200){
                res && res.data && res.data.entity && res.data.entity.forEach((item) => {
                    spotLightArts.push({
                        text: item && item.title,
                        id: item && item.id,
                        subText: (item &&
                            item?.status === 0)
                            ? '(Disabled)' : undefined
                    })
                })
                this.setState({ spotLightArts, spotLightArray: res && res.data && res.data.entity, spotCount: res && res.data && res.data.count })
            }
        }).catch(e => {this.hideLoader()})
    }

    getFeatureArt = (offSet,recordLimit) => {
        let featuredArts = []
        this.showLoader()
        adminServices.getFeaturedArts().then(res => {
            this.hideLoader()
            if(res && res.statusCode === 200){
                res && res.data && res.data.entity && res.data.entity.forEach((item) => {
                    featuredArts.push({
                        text: item && item.title,
                        id: item && item.id,
                        subText: (item &&
                            item?.status === 0)
                            ? '(Disabled)' : undefined
                    })
                })
                this.setState({ featuredArts, artCount: res && res.data && res.data.count })

            }
        }).catch(e=>{ this.hideLoader() })
    }

    getFeatureCreator = (offSet,recordLimit) => {
        let featuredCreators = []
         this.showLoader()
         adminServices.getFeaturedCreators().then(res => {
            this.hideLoader()
            if(res && res.statusCode == 200){
                res && res.data && res.data.entity && res.data.entity.forEach((item) => {
                    featuredCreators.push({
                        text:item && item.userName,
                        id:item && item.id,
                        subText: (item &&
                            item?.accountStatus === 0)
                            ? '(Disabled)' : undefined
                    })
                })
            this.setState({featuredCreators,creatorCount : res && res.data && res.data.count})

            }
        }).catch(e=>{ this.hideLoader() })
    }

    getFeatureCollector = (offSet,recordLimit) => {
        let featuredCollectors = []
        this.showLoader()
        adminServices.getFeaturedCollectors().then(res => {
            this.hideLoader()
            if (res && res.statusCode === 200) {
                res && res.data && res.data.entity && res.data.entity.forEach((item) => {
                    featuredCollectors.push({
                        text: item && item.userName,
                        id: item && item.id,
                        subText: (item &&
                            item?.accountStatus === 0)
                            ? '(Disabled)' : undefined
                    })
                })
                this.setState({ featuredCollectors, featureCollectorArray: res && res.data && res.data.entity, collectorCount: res && res.data && res.data.count })

            }
        }).catch(e => {this.hideLoader()})
    }
    showAllFeatureArts = () => {
        this.getFeatureArt('','')
    }
    showAllFeatureCreators = () => {
        this.getFeatureCreator('','')
    }
    showAllSpotLight = () => {
        this.getSpotLight('','')
    }
    showAllFeatureCollectors = () => {
        this.getFeatureCollector('','')
    }

    spotLightArtSelected(e) {
        // console.log('Spot light art selected', e);
        const { spotLightArts,spotlightLimit } = this.state;
        const isArtSelected = spotLightArts.find(art => art.artId === e.artId)
        if (
            !isArtSelected 
        ) {
            if(this.state.spotCount < (spotlightLimit)){
            this.showLoader()
            // console.log(this.state.spotCount,"spotcount")
          
            adminServices.addSpotlightArt(e.artId).then((response) => {
                if(response && response.statusCode === 200){
                    this.getSpotLight('','')
                this.hideLoader()
                toast.success("Art added")
                // console.log('updated spot light arts', this.state.spotLightArts);
                spotLightArts.push({
                    id: e.artId,
                    text: e.mainText
                })
                this.setState({
                    spotLightArts: Array.from(spotLightArts)
                });
            }
            }).catch(e => {
                toast.error(e)
                this.hideLoader()
            }
                );
        }else{
            toast.error("Maximum limit for spotlight art was reached")
        }
        }
    
    }

    spotLightArtDeletedHandler(item, e) {
        const { spotLightArts } = this.state;
        let updatedIds = []
        const foundIndex = spotLightArts.findIndex(
            (datum) => item.id === datum.id
        );
        if (foundIndex !== -1) {
            spotLightArts.splice(foundIndex, 1);
            spotLightArts.forEach(ids => {
                updatedIds.push(ids.id)
            })
            this.updateSpotLight(updatedIds)
            this.setState({
                spotLightArts: Array.from(spotLightArts)
            });
        }
    }
    updateSpotLight = (updatedIds) => {
        this.showLoader()
        adminServices.updateSpotlightArt(updatedIds).then(update => {
            this.hideLoader()
            if(update && update.statusCode === 200){
                this.getSpotLight('','')
                toast.success("Art removed")
            }
        }).catch(e => {
            this.hideLoader()
            toast.error(e)
        })
    }
    featuredArtSelected(e) {
        // console.log("featured art",e)
        const { featuredArts,artCount,featureArtLimit } = this.state;
        const isArtSelected = featuredArts.find(art => art.artId === e.artId)
        if (
            !isArtSelected
        ) {
            if(artCount < (featureArtLimit)){
            this.showLoader()
            adminServices.addFeaturedArt(e.artId).then((response) => {
                this.hideLoader()
                if(response && response.statusCode === 200){
                    this.getFeatureArt('','')
                toast.success("Art added")
                featuredArts.push({
                    id: e.artId,
                    text: e.mainText
                })
                this.setState({
                    featuredArts: Array.from(featuredArts)
                });
            }
            }).catch(e => {
                this.hideLoader()
                 toast.error(e)
                 
                });
            }else{
                toast.error("Maximum limit for featured art was reached")

            }

        }
    }

    featuredArtDeletedHandler(item, e) {
        const { featuredArts } = this.state;
        let updatedIds = []
        const foundIndex = featuredArts.findIndex(
            (datum) => item.id === datum.id
        );
        if (foundIndex !== -1) {
            featuredArts.splice(foundIndex, 1);
            featuredArts.forEach(ids => {
                updatedIds.push(ids.id)
            })
            this.showLoader()
            adminServices.updateFeaturedArt(updatedIds).then(update => {
                this.hideLoader()
                if(update && update.statusCode === 200){
                    this.getFeatureArt('','')
                    toast.success("Art removed")
                }
            }).catch(e => {
                this.hideLoader()
                toast.error(e)
            })

            this.setState({
                featuredArts: Array.from(featuredArts)
            });
        }
    }

    featuredCreatorSelected(e) {
        const { featuredCreators,creatorCount, featureCreatorLimit } = this.state;
        const isArtSelected = featuredCreators.find(art => art.artId === e.artId)
        if (
            !isArtSelected
        ) {
            if(creatorCount < (featureCreatorLimit)){

            this.showLoader()
            adminServices.addFeaturedCreator(e.artId).then((response) => {
                this.hideLoader()
                if(response && response.statusCode === 200){
                    this.getFeatureCreator('','')
                toast.success("Creator added")
                featuredCreators.push({
                    id: e.artId,
                    text: e.mainText
                })
                this.setState({
                    featuredCreators: Array.from(featuredCreators)
                });
            }
            }).catch(e => {
                this.hideLoader()
                toast.error(e)})
            }else{
                toast.error("Maximum limit for featured creator was reached")

            }
        }
    }

    featuredCreatorDeletedHandler(item, e) {
        const { featuredCreators } = this.state;
        let updatedIds = []
        const foundIndex = featuredCreators.findIndex(
            (datum) => item.id === datum.id
        );
        if (foundIndex !== -1) {
            featuredCreators.splice(foundIndex, 1);
            featuredCreators.forEach(ids => {
                updatedIds.push(ids.id)
            })
            this.showLoader()
            adminServices.updateFeaturedCreator(updatedIds).then(update => {
                this.hideLoader()
                if(update && update.statusCode === 200){
                    this.getFeatureCreator('','')
                    toast.success("Creator removed")
                }
            }).catch(e => {
                this.hideLoader()
                toast.error(e)
            })
            this.setState({
                featuredCreators: Array.from(featuredCreators)
            });
        }
    }

    featuredCollectorSelected(e) {
        const { featuredCollectors,collectorCount, featureCollectorLimit } = this.state;
        const isArtSelected = featuredCollectors.find(art => art.artId === e.artId)
        if (
            !isArtSelected
        ) {
            if(collectorCount < (featureCollectorLimit)){
            this.showLoader()
            adminServices.addFeaturedCollector(e.artId).then((response) => {
                this.hideLoader()
                if(response && response.statusCode === 200){
                    this.getFeatureCollector('','')  
                toast.success("Collector added")
                featuredCollectors.push({
                    id: e.artId,
                    text: e.mainText
                })
                this.setState({
                    featuredCollectors: Array.from(featuredCollectors)
                });
            }
            }).catch(e => {
                this.hideLoader()
                toast.error(e)});
            }else{
                toast.error("Maximum limit for featured collector was reached")

            }

        }
    }

    featuredCollectorDeletedHandler(item, e) {
        const { featuredCollectors } = this.state;
        let updatedIds = []
        const foundIndex = featuredCollectors.findIndex(
            (datum) => item.id === datum.id
        );
        if (foundIndex !== -1) {
            featuredCollectors.splice(foundIndex, 1);
            featuredCollectors.forEach(ids => {
                updatedIds.push(ids.id)
            })
            this.showLoader()
            adminServices.updateFeaturedCollector(updatedIds).then(update => {
                this.hideLoader()
                if(update && update.statusCode === 200){
                    this.getFeatureCollector('','')  
                    toast.success("Collector removed")
                }
            }).catch(e => {
                this.hideLoader()
                toast.error(e)
            })
            this.setState({
                featuredCollectors: Array.from(featuredCollectors)
            });
        }
    }
    updateOrderChange = (item,update) => {
        // console.log(item,update)
        if(update === "spot"){
            this.updateSpotLight(item)
        }
    }
    getDiscoverDet = () => {
        this.props.getDiscoverLimits()

    }
    componentDidUpdate(props, state) {
        if ((this.props.maxCounts && this.props.maxCounts.statusCode === 200) && ((this.props.maxCounts) != (props.maxCounts))) {
            this.props.resetGetLimits()
            this.setState({
                spotlightLimit: this.props.maxCounts.data && this.props.maxCounts.data.spotlightMaxLimit,
                featureArtLimit: this.props.maxCounts.data && this.props.maxCounts.data.featuredArtMaxLimit,
                featureCreatorLimit: this.props.maxCounts.data && this.props.maxCounts.data.featuredCreatorMaxLimit,
                featureCollectorLimit: this.props.maxCounts.data && this.props.maxCounts.data.featuredCollectorMaxLimit,
            })
        }
        
    }
    render() {
        // console.log("spot light1",this.state.spotLightArts)

        const {
            spotLightArts,
            featuredArts,
            featuredCreators,
            featuredCollectors,
        } = this.state;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Discover page configuration</h2>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <h3>Select arts for spotlight</h3>
                            <p className="small font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 Disabled arts are not visible to end users
                            </p>
                            <AutoSuggestInput
                                id="spotLightSuggest"
                                placeholder="Search art"
                                onSuggetionSelected={this.spotLightArtSelected}
                                isSpotArt = {true}
                                isDropsOnly = {true}
                            />
                            <DraggableList id="spotLightList" data={spotLightArts} onItemDelete={this.spotLightArtDeletedHandler} onOrderChanged={(e) => {this.updateOrderChange(e,"spot")}} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <h3>Select arts for Featured art</h3>
                            <p className="small font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 Disabled arts are not visible to end users
                            </p>
                            <AutoSuggestInput
                                id="featureArtSuggest"
                                placeholder="Search art"
                                isFeatureArt = {true}
                                onSuggetionSelected={this.featuredArtSelected}
                                isDropsOnly = {true}
                            />
                            <DraggableList id="featuredArtList" data={featuredArts} onItemDelete={this.featuredArtDeletedHandler} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <h3>Select creators for featured creators</h3>
                            <p className="small font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 Disabled creators are not visible to end users
                            </p>
                            <AutoSuggestInput
                                id="featuredCreatorSuggest"
                                placeholder="Search creators"
                                isFeaturedCreator = {true}
                                onSuggetionSelected={this.featuredCreatorSelected}
                            />
                            <DraggableList id="featuredCreatorList" data={featuredCreators} onItemDelete={this.featuredCreatorDeletedHandler} />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-sm-12 mb-5">
                            <h3>Select collectors for featured collectors</h3>
                            <p className="small font-italic">
                                <i className="fas fa-info-circle"></i>&nbsp;
                                 Disabled collectors are not visible to end users
                            </p>
                            <AutoSuggestInput
                                id="featuredCollectorSuggest"
                                placeholder="Search collectors"
                                isFeaturedCollector = {true}
                                onSuggetionSelected={this.featuredCollectorSelected}
                            />
                            <DraggableList id="featuredCollectorList" data={featuredCollectors} onItemDelete={this.featuredCollectorDeletedHandler} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        maxCounts: state.discover.maxCounts,
    }
}
const actionCreator = {
    getDiscoverLimits: discoverActions.getDiscoverMaxCountLimit,
    resetGetLimits: discoverActions.resetGetDiscoverSettings,

}
const connectAdminDiscover = connect(mapStateToProps, actionCreator)(AdminDiscover)
export { connectAdminDiscover as AdminDiscover }