export const announcementConstants = {
  
    GET_ANNOUNCEMENT : 'GET_ANNOUNCEMENT',
    ADD_ANNOUNCEMENT : 'ADD_ANNOUNCEMENT',
    GET_COMMON_ANNOUNCEMENT : 'GET_COMMON_ANNOUNCEMENT',
    ADD_GLITTER_EVENTS : 'ADD_GLITTER_EVENTS',
    GET_GLITTER_EVENTS : 'GET_GLITTER_EVENTS',
    DELETE_GLITTER_EVENTS : 'DELETE_GLITTER_EVENTS',
    GET_ONE_GLITTER_EVENT : 'GET_ONE_GLITTER_EVENT',
    UPDATE_GLITTER_EVENT : 'UPDATE_GLITTER_EVENT',
    GET_GLITTER_TEXT : 'GET_GLITTER_TEXT',
    GET_ANNOUNCEMENT_HISTORY : 'GET_ANNOUNCEMENT_HISTORY',
    DELETE_ANNOUNCEMENT : 'DELETE_ANNOUNCEMENT'
 }