import React from "react";
import { connect } from "react-redux";
import { userAction } from "store/actions";
import { toast, ToastContainer } from 'react-toastify';
import { configPath } from "../../config";
import { Helmet } from "react-helmet";
class ProfileContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            fullName: '',
            description: '',
            email: '',
            image: '',
            instagramProfile: '',
            twiterProfile: ''
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.usersList && props.usersList.data) {
            let data = props && props.usersList && props.usersList.data
            return {
                userName: data.userName,
                fullName: data.fullName,
                description: data.description,
                email: data.email,
                userAddress: data.userAddress,
                // image: data.profileImage ? `https://ipfs.infura.io/ipfs/${data.profileImage}` : null,
                image: data.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.profileImage}` : null,
                // banner: data.coverImage ? `https://ipfs.infura.io/ipfs/${data.coverImage}` : null
                banner: data.coverImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.coverImage}` : null,
                twiterProfile: data.twiterProfile,
                instagramProfile: data.instagramProfile
            }
        } else {
            let data = localStorage.getItem('webLogin')
            let loginData = JSON.parse(data)
            return {
                userName: loginData && loginData.data && loginData.data.authUser && loginData.data.authUser.userName,
                fullName: loginData && loginData.data && loginData.data.authUser && loginData.data.authUser.fullName,
                email: loginData && loginData.data && loginData.data.authUser && loginData.data.authUser.email
            }
        }
    }
    componentDidMount() {
        this.props.getUserProfile()
        // this.props.getUSD()

    }
    render() {
        return (
            <div className="container">
                <Helmet>
                    <title>Profile</title>
                </Helmet>
                {/* <ToastContainer autoClose={3000} /> */}
                <div className="coverimg imgcovergif rounded-0 coverbrd ">
                    <img src={this.state.banner ? this.state.banner : `../${configPath.imageAssets}/cover-default.png`} alt="" className="w-100" />
                </div>
                <div className="propagecont mb-5">
                    <div className="container">
                        <div className="row mb-3 mb-md-5">
                            <div className="col-lg-12">
                                <div className="picpro">
                                    <span className="artcovimg ">
                                        <span className="artcov"></span>
                                        <span className="artcovimginset no-proimg">
                                            <img src={this.state.image ? this.state.image : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-0 mt-md-5 row">
                            <div className="col-md-4">
                                <h6 className=" mb-2 text-uppercase">User name</h6>
                                <div className="purchasebox p-3">
                                    <div className="">{this.state.userName}</div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <h6 className=" mb-2 text-uppercase">Email</h6>
                                <div className="purchasebox p-3">
                                    <div className="">{this.state.email}</div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <h6 className=" mb-2 text-uppercase">Full name</h6>
                                <div className="purchasebox p-3">
                                    <div className="">{this.state.fullName}</div>
                                </div>
                            </div>

                            {
                                this.state.instagramProfile ?
                                    <div className="col-md-4">
                                        <h6 className=" mb-2 text-uppercase">Instagram</h6>
                                        <div className="purchasebox p-3">
                                            <div className="">{this.state.instagramProfile}</div>
                                        </div>
                                    </div> :
                                    null
                            }

                            {
                                this.state.twiterProfile ?
                                    <div className="col-md-4">
                                        <h6 className=" mb-2 text-uppercase">Twitter</h6>
                                        <div className="purchasebox p-3">
                                            <div className="">{this.state.twiterProfile}</div>
                                        </div>
                                    </div> :
                                    null
                            }

                            <div className="col-md-12 mt-3">
                                <h6 className=" mb-2 text-uppercase">Description</h6>
                                <div className="purchasebox p-3">
                                    <div className="text-justify prodescontent">{this.state.description}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        usersList: state.user.usersList,
        isUpdate: state.user.isUpdate
    }
}
const actionCreators = {
    updateProfile: userAction.updateProfile,
    getUserProfile: userAction.getUsers,
    getUSD: userAction.getUSD

}
const connectProfile = connect(mapStateToProps, actionCreators)(ProfileContent)
export { connectProfile as ProfileContent }