import * as fcl from "@onflow/fcl";

export async function flowGetLatestBlock() {
    const script = `
        pub fun main(): UInt64 {
            return getCurrentBlock().height
        }
    `;
    try {
        let scriptresult = await fcl.send([fcl.script(script)]);
        return {
            status: 200,
            data: { "message": "Latest block", "Value": String(JSON.stringify(scriptresult["encodedData"]["value"], null, 1)) }
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch (e) {
        return {
            status: 400,
            data: { "message": "Exception happens", "Error": String(e) }
        };
    }
}