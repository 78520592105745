import history from 'helpers/history';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { userServices } from 'services';
import { userAction } from 'store/actions';
import PubSub from 'pubsub-js';
import { Alert } from "helpers/alert";
import { Helmet } from 'react-helmet';

class ForgotPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            username : '',
            submitted : false,
            isSuccess : false,
            message:'',
            isAlert : false
        }
    }
    componentDidMount() {
        
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        


    }
    handleChange = (e) => {
        this.setState({
            [e.target.name] : (e.target.value).toLowerCase()
        })
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        setTimeout(() => {
              
            this.setState({isAlert:false,message:'',style:""})
        },15000)
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var test = emailReg.test(value)
        if(emailReg.test(value)){
            return true
        }else{
            return false
        }
    } 
    handleSubmit = (e) => {
        e.preventDefault()
        // console.log(this.state.username)
        const {username} = this.state
        this.setState({submitted:true})
        if(username && this.checkemail(username)){
            this.showLoader()
            userServices.forgotPassword(username).then(res => {
                this.hideLoader()
                if((res && res.statusCode) == 200)
                this.setState({isSuccess:true})
            }).catch(err => {
                this.hideLoader()
                this.setState({isAlert:true,message:err,style:"danger"})
                this.closeAlert()
                // toast.error(err)
                // console.log(err)
            })
        }
    }
    render(){
        return (
            <Fragment>
                <Helmet>
                    <title>Forgot Password</title>
                    <meta name="title" content="Forgot Password"></meta>
                    <meta name="description" content={`Reset your ${process.env.REACT_APP_MARKETPLACE} account password`}></meta>
                </Helmet>
                {this.state.isAlert && <Alert message={this.state.message} style={this.state.style} isAlert={this.state.isAlert}/>}

                <div className="container restpasswordh">
                {this.state.isSuccess &&
                    <div className="show-success">
                    <div className="success-pop">
                        <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <div className="mt-3">Reset link sent to your email successfully.<br/>Please check your registered email</div>
                        {/* <div className="socialicons mt-3"><a href="https://twitter.com/DisruptNowio" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></a><a href="https://www.instagram.com/disruptnow.io/" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i></a><a href="https://discord.com/users/DisruptNow#5704" target="_blank"><i className="fab fa-discord" aria-hidden="true"></i></a></div> */}
                        {/* <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.goBack() }}>Close</button> */}
                        <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/login') }}>close</button></div></div>
                }
                    <h1 className="text-center mt-5 mb-3">Reset Password</h1>
                    <div className="row">
                        <div className="col-md-4 offset-md-4">
                            <div className="purchasebox1 p-3">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group row">
                                        <label for="username" >Email</label>
                                        <input className="form-control" value={this.state.username} id="username" name="username" onChange={this.handleChange} placeholder="Enter registered email address here"/>
                                        {this.state.submitted && !this.state.username && <div className="mandatory">Email required</div>}
                                        {this.state.submitted && this.state.username && !this.checkemail(this.state.username) && <div className="mandatory">Invalid email</div>}

                                    </div>
                                    <div className="form-group text-center">
                                        <button type="submit" className="btn btn-primary px-5">Send Link</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const actionCreators = {
    forgotPassword : userAction.forgotPassword
}

const connectPassword = connect(mapStateToProps,actionCreators)(ForgotPassword)

export {connectPassword as ForgotPassword}