import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"


export const flowaccountcapacity = async(useraddress) => {

    let scriptcode = `\
            import FlowStorageFees from `+process.env.REACT_APP_FLOW_STORAGE+`
            pub fun main():UFix64 {
                let account =  getAccount(`+useraddress+`)
                return UFix64(account.storageCapacity)
            }`
    try {
        let scriptresult = await fcl.send([fcl.script(scriptcode)]);
        return {
          status: 200,
          data: { "message" : "Flow Account Capacity" , "Value" : String(JSON.stringify(scriptresult["encodedData"]["value"],null,1)) } 
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch(e) {
        return {
          status: 400,
          data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
    }

}
export const flowgetdetails = async (useraddress) => {
    let scriptcode = `
    pub struct Result {
      pub let address: Address
      pub let balance: UFix64
      pub let availableBalance: UFix64
      pub let storageUsed: UInt64
      pub let storageCapacity: UInt64
    
    
    
      init(
      address: Address,
      balance: UFix64,
      availableBalance: UFix64,
      storageUsed: UInt64,
      storageCapacity: UInt64
      ) {
      self.address = address
      self.balance = balance
      self.availableBalance = availableBalance
      self.storageUsed = storageUsed
      self.storageCapacity = storageCapacity
      }
    }
    
    
    
    pub fun main(address: Address): Result {
      let account = getAuthAccount(address)
      return Result(
      address: account.address,
      balance: account.balance,
      availableBalance: account.availableBalance,
      storageUsed: account.storageUsed,
      storageCapacity: account.storageCapacity
      )
    }`;
    try {
      const result = await fcl.query({
        cadence: scriptcode,
        args: (arg, t) => [
          arg(useraddress, t.Address),
        ],
      });
      return {
        status: 200,
        data: { "message" : "Flow Account Storage Used" , "Value" : result } 
      };
    } catch (e) {
      return {
        status: 400,
        data: { "message" : "Exception happens" , "Error" : String(e) } 
      };
    }
  };

export const flowaccountstorageused = async(useraddress) => {

    let scriptcode = `\
            import FlowStorageFees from `+process.env.REACT_APP_FLOW_STORAGE+`
            pub fun main():UFix64 {
                let account =  getAccount(`+useraddress+`)
                return UFix64(account.storageUsed)
            }`
    try {
        let scriptresult = await fcl.send([fcl.script(scriptcode)]);
        return {
          status: 200,
          data: { "message" : "Flow Account Storage Used" , "Value" : String(JSON.stringify(scriptresult["encodedData"]["value"],null,1)) } 
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch(e) {
        return {
          status: 400,
          data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
    }

}


export const flowaccountstorageusedpercentage = async(useraddress) => {

    let scriptcode = `\
            import FlowStorageFees from `+process.env.REACT_APP_FLOW_STORAGE+`
            pub fun main():String {
                let account =  getAccount(`+useraddress+`)
                let available_capacity = UFix64(account.storageCapacity)
                let storage_used = UFix64(account.storageUsed)
                let used_dec =  UFix64(storage_used/available_capacity)
                let used_percentage = (used_dec * 100.00) 
                return used_percentage.toString()
            }`
    try {
        let scriptresult = await fcl.send([fcl.script(scriptcode)]);
        return {
          status: 200,
          data: { "message" : "Flow Account Storage Used Percentage" , "Value" : String(JSON.stringify(scriptresult["encodedData"]["value"],null,1)) } 
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch(e) {
        return {
          status: 400,
          data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
    }

}



export const flowaccountstorageavailablepercentage = async(useraddress) => {

    let scriptcode = `\
            import FlowStorageFees from `+process.env.REACT_APP_FLOW_STORAGE+`
            pub fun main():String {
                let account =  getAccount(`+useraddress+`)
                let available_capacity = UFix64(account.storageCapacity)
                let storage_used = UFix64(account.storageUsed)
                let used_dec =  UFix64(storage_used/available_capacity)
                let used_percentage = (used_dec * 100.00) 
                let perctange_available = (100.00 - used_percentage)
                return perctange_available.toString()
            }`
    try {
        let scriptresult = await fcl.send([fcl.script(scriptcode)]);
        return {
          status: 200,
          data: { "message" : "Flow Account Storage Available Percentage" , "Value" : String(JSON.stringify(scriptresult["encodedData"]["value"],null,1)) } 
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch(e) {
        return {
          status: 400,
          data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
    }

}
