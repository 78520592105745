import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { adminServices, creatorServices } from 'services';
import moment from 'moment'


class FetchuserDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            filtered : [],
            isError : false
        }

    }
    componentDidMount(){
        this.callApi()
    }
     

    callApi = () => {
        let userAddress = ""

        let id = decodeURIComponent(this.props.match.params.id).replace('@', '')

                this.getWalletAddress(id)
        

    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    
    getWalletAddress = (id) => {
        this.showLoader()
        adminServices.getUserWalletAddress(id).then(res => {
            this.hideLoader()
            if((res && res.statusCode) == 200){
                const arr = res && res.data && res.data.details && res.data.details.wallet && res.data.details.wallet
                const walletAddresses = arr.map(add => (add.walletAddress))
                 
                const filtered = arr.filter((adds,index) => !walletAddresses.includes((adds.walletAddress),index+1))
                this.setState({filtered,wallet : arr,detailCreator : res.data.details
                })
            }
        }).catch(err => {
            this.hideLoader()

        })
    }
    render(){
        const {detailCreator} = this.state
        return(
           
            <Fragment>
             
                 <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">User Detail</h2></div>
                    {(!(this.state.isError)) ? <div className="purchasebox p-2">
                        <div className="card">
                            <div className="row">
                            {(detailCreator && detailCreator.userName) && <div className="col-md-4 mb-3">
                                <label>Username</label>
                                <div className="comboxgray">
                                    {detailCreator && detailCreator.userName && `@${detailCreator.userName}`}
                                </div>
                            </div>}
                            {(detailCreator && detailCreator.fullName) && <div className="col-md-4 mb-3">
                                <label>Fullname</label>
                                <div className="comboxgray">
                                    {detailCreator && detailCreator.fullName}
                                </div>
                            </div>}
                            {(detailCreator && detailCreator.email) && <div className="col-md-4 mb-3">
                                <label>Email</label>
                                <div className="comboxgray">
                                    {detailCreator && detailCreator.email}
                                </div>
                            </div>}
                            {this.state.filtered && this.state.filtered.length > 0 &&
                                <div className="col-md-12 mb-3">
                                    <label>Wallet Address</label>
                                
                                    <div className="comboxgray">{this.state.filtered && this.state.filtered.map((data,index,array) => {
                                  
                                            return  <a className="badge bg-dark mr-1 p-2" href={process.env.REACT_APP_ADDRESS_EXPLORER + `/${data.walletAddress}`} target="_blank">
                                            {data.walletAddress}</a>
                                  
                                    })}
                                    
                                    </div>
                                </div>}
                            {detailCreator && detailCreator.description != null && <div className="col-md-12 mb-3"><label>Bio</label>
                            <div className="comboxgray">
                                {(detailCreator && detailCreator.description && detailCreator.description.length > 600) ?
                                                <div className="showless">
                                                    <input id="showless1" type="radio" name="showless" />
                                                    <label htmlFor="showless1" className="showless1">Read more</label>
                                                    <input id="showless2" type="radio" name="showless" checked={this.state.checked} />
                                                    <label htmlFor="showless2" className="showless2">Show less</label>
                                                    <div className="content">
                                                        <div id="showless1" className="showlesscontent">
                                                            {detailCreator && detailCreator.description}
                                                        </div>
                                                    </div>
                                                </div> : <div className="content">
                                                        <div id="showless1" className="showlesscontent">
                                                            {detailCreator && detailCreator.description}
                                                        </div>
                                                    </div>}
                                </div></div>}
                            {(detailCreator && detailCreator.role && detailCreator.role.id) && <div className="col-md-4 mb-3">
                                <label>Role</label>
                                <div className="comboxgray">
                                    {detailCreator && detailCreator.role && detailCreator.role.id && ((detailCreator.role.id == "1") ? "Creator" : "Collector") }
                                </div>
                            </div>}
                            {(detailCreator && detailCreator.createdAt) && <div className="col-md-3 mb-3">
                                <label>Joined</label>
                                <div className="comboxgray">
                                    {detailCreator && detailCreator.createdAt && moment(detailCreator.createdAt).format('MMMM YYYY')}
                                </div>
                            </div>}
                            
                            </div>
                               </div>       
                           </div> :
                            <div className="container-fluid text-center">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <div className="error-template userdisableview">
                                    <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>
            
                                        <h3 className="mb-2 usernamelilac text-uppercase">USER ACCOUNT HAS BEEN DISABLED NOW</h3>                                 
            
            
                                    </div>
                                </div>
            
                            </div>
                        </div> 
                           }
                       
                        </div>
                         
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
}

const actionCreators = {


}

const connectFetchuserDetail = connect(mapStateToProps,actionCreators)(FetchuserDetail)

export {connectFetchuserDetail as FetchuserDetail}