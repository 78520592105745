import { proposer } from '@onflow/fcl'
import React, { Fragment } from 'react'



const Alert = (props) => {
    var timeout;
    const hideAlert = () => {
        if( document.getElementById("parent")){
            document.getElementById("parent").style.display="none"
            // clearTimeout(timeout);
        }
    }
    if(props){
    //     timeout = setTimeout(() => {
    //         document.getElementById("parent").style.display="none"
    //     },10000)
    //     if( document.getElementById("parent")){
    //         document.getElementById("parent").style.display="block"
    //         }
    // }else{
        if( document.getElementById("parent")){
            document.getElementById("parent").style.display="block"
            }
    // }
    if(props && props.isAlert){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        // setTimeout(() => {
          

        //     var element = document.getElementById("parent")
        //     element.className += " " + "alertheight";
        // },0.5000)
    //     window.onscroll = function() {
    //         const sticky = 44
    // var element = document.getElementById("parent")

    // if (window.pageYOffset > sticky) {
    //     console.log("scrolled")
        
    //     // if(element && element != null && element.classList)
    //     element.className += " " + "sticky";
    //     // element.classList.add('sticky');
   
    // } 
        };
        

    return (
        <Fragment>
            {/* <div style={{backgroundColor:`${props && props.color}`,textAlign:"center"}}>{props && props.message}</div> */}
            <div className={(props.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (props.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
            {/* <div  id="parent" > */}
                <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{props && props.message}</span>
                        <span className="closebtn" onClick={hideAlert}>&times;</span>

                    </div>
                
                </div>
               
        </Fragment>
    )
    }else{
        return false
    }
   
}


export {Alert}