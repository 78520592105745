import React from "react";
import history from "helpers/history";
import { categoryActions, dropActions, tagActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { Fragment } from "react";
import { checkMintedTokens, checkTokenGroup, ReactPagination, flowDecimal, getUSDValue, localArtPage, localAirdropPage } from 'helpers'
import { createSetUp, getUserAddress } from "utils";
import { configPath } from "../config";
import { ArtPreview } from "./art-preview/art-preview";
import { Helmet } from "react-helmet";
import moment from 'moment'
import { userServices } from "services";
import Select from 'react-select';
import { SolarSystemLoading } from 'react-loadingg';
import { getFlowUsdValue } from "helpers/getFlowUsd";
import PubSub from 'pubsub-js';
import { JoyRide } from "./Joy-ride";
import { checkDropPage } from "helpers/joy-rideCheck";

let scrolled = false
let controller = new AbortController();
var extraLimit = 0
const allTypes = [
    { value: 1, label: "All" },
    { value: 2, label: "Flat Price" },
    { value: 3, label: "Auction" },

]
const sortOptions = [
    { value: 1, label: 'Most Recent', sortOrder: 'DESC' },
    { value: 2, label: 'Oldest',sortOrder : "ASC" },
]
const priceOptions = [
    { value: 1, label: 'Most Recent', sortOrder: 'DESC' },
    { value: 2, label: 'Oldest',sortOrder : "ASC" },
    { value: 3, label: 'Price Low - High', sortOrder : "ASC",sortBy : "markedPrice" },
    { value: 4, label: 'Price High - Low',sortOrder : "DESC",sortBy : "markedPrice" },
    // { value: 5, label: 'Rarity : High',rarity : "high" },
    // { value: 6, label: 'Rarity : Medium',rarity : "medium" },
    // { value: 7, label: 'Rarity : Low',rarity : "low" },
];

const auctionOptions = [
    { value: 1, label: 'Recent Auctions',sortOrder : 'DESC' },
    { value: 2, label: 'Ending Soon', sortOrder : 'ASC', sortBy : "auctionPeriod" },
    { value: 3, label: 'Bid Amount Low - High' ,sortOrder : 'ASC',sortBy : 'bidAmout' },
    { value: 4, label: 'Bid Amount High - Low', sortOrder : 'DESC', sortBy : 'bidAmout' },
    // { value: 5, label: 'Rarity : High',rarity : "high" },
    // { value: 6, label: 'Rarity : Medium',rarity : "medium" },
    // { value: 7, label: 'Rarity : Low',rarity : "low" },
    { value: 5, label: 'Bidding Closed',closedAuction : 'true' },
];
const rarityOptions = [
    { value: 0, label: 'Select rarity type'},
    { value: 1, label: 'Rarity : High',rarity : "high" },
    { value: 2, label: 'Rarity : Medium',rarity : "medium" },
    { value: 3, label: 'Rarity : Low',rarity : "low" },
]
const customStylesforAll = {
    control: (base, state) => ({
        ...base,
        minWidth: "100px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",
        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
const customStyles = {
    control: (base, state) => ({
        ...base,
        minWidth: "200px",
        background: "transparent",
        color: "white",
        // match with the menu
        borderRadius: state.isFocused ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "white" : "white",
        borderWidth: state.isFocused ? "2px" : "2px",
        fontWeight: state.isFocused ? "bold" : "bold",
        position: state.isFocused ? "relative" : "relative",

        //   top:state.isFocused ? "8px" : "8px",
        minHeight: state.isFocused ? "41px" : "41px",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "white" : "white",

        }
    }),
    menu: base => ({
        ...base,
        // override border radius to match the box
        borderRadius: 10,
        // kill the gap
        marginTop: 0,
        backgroundColor: "black"
    }),
    menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
    }),
    singleValue: base => ({
        ...base,
        color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#797979" : null,
            color: "white",
            cursor: "pointer"
        };
    }
}
class ArtContent extends React.Component {
    constructor(props) {
        super(props);
        this.types = {
            1: "All",
            2: "Flat Price",
            3: "Auction"
        }
        this.filterTypes = {
            1: "Most Recent",
            2: "Oldest",
            3: "Price Low - High",
            4: "Price High - Low",
        }
        this.filterAuctionTypes = {
            1: "Recent Auctions",
            2: "Ending Soon",
            3: "Bid Amount Low - High",
            4: "Bid Amount High - Low",
        }
        this.rarityTypes = {
            1: "Rarity : High",
            2: "Rarity : Medium",
            3: "Rarity : Low",
        }
        const allTypes = [];
        for (const key in this.types) {
            allTypes.push({
                evtName: this.types[key],
                value: parseInt(key)
            });
        }
        const filtTypes = [];

        for (const key in this.filterTypes) {
            filtTypes.push({
                evtName: this.filterTypes[key],
                value: parseInt(key)
            });
        }
        const auctionTypes = [];
        for (const key in this.filterAuctionTypes) {
            auctionTypes.push({
                evtName: this.filterAuctionTypes[key],
                value: parseInt(key)
            });
        }
        this.state = {
            dropList: [],
            offSet: 0,
            isCheck: false,
            recordLimit: 15,
            totalCount: '',
            pageCount: '',
            rate: '',
            currentUser: '',
            sortOrder: "DESC",
            sortBy: "createdAt",
            show: false,
            isFilter: "all",
            // filtTypes,
            // selectedFiltType: 1,
            // auctionTypes,
            // selectedAuctionType : 1,
            // allTypes,
            // selectedAllType: 1,
            allFiltSelectOption: { value: 1, label: "All" },
            faltpriceSelectOption:{ value: 1, label: 'Most Recent', sortOrder: 'DESC' },
            auctionSelectOption: { value: 1, label: 'Recent Auctions',sortOrder : 'DESC' },
            loadText: 1,
            trendingTags: [],
            selectedTag: '',
            categoryTypes : [],
            categoryOption : {value : 0, label : "Select Category"},
            flatrarityOption:  { value: 0, label: 'Select rarity type'},
            sortOption : {value: 1, label: 'Most Recent', sortOrder: 'DESC'},
            isTourInitiated : false
        }
    }
    componentDidMount() {
        this.props.getAllTrendingTags()
        this.props.getUserCategories()
        localArtPage()
        localAirdropPage()
        getUserAddress().then(res => {
            this.setState({ currentUser: res })
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        // this.getUSD() ------ stop conversion
        this.setState({isTourInitiated : true})
        const data = JSON.parse(localStorage.getItem('dropData'))
        const dataLength = data && data[0] && data[0].length
        const extraLimit = JSON.parse(localStorage.getItem('extraLimit'))
        const count = Number(dataLength - (this.state.recordLimit) - extraLimit)
        if ((dataLength)) {
            scrolled = false
            this.setState({
                dropList: data,
                offSet: count
            }, () => {
            })

        } else {
            this.callDrops()
        }
        this.setCount()

        window.addEventListener('load', this.startTime);

        const { offSet, recordLimit, dropList, isScroll } = this.state
        window.addEventListener('scroll', this.infiniteScroll)

    }
    infiniteScroll = () => {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;
        const fullHeight = scrollTop + clientHeight
        if ((Number(fullHeight)) >= (Number(scrollHeight) - 1200) &&
            (this.state.dropList && this.state.dropList[0] && this.state.dropList[0].length > 0) && !scrolled
        ) {
            this.loadData()
            scrolled = true;


        }

    }
    
    getUSD = async () => {
        const value =  await getFlowUsdValue()
        this.setState({rate : value})
    }
    static getDerivedStateFromProps(props, state) {
        // let rate = ""
        if (props && props.dropList && props.dropList.statusCode == 200) {
            props.resetDropArt()
           
            if ((state.dropList && state.dropList.length > 0) && state.dropList[0]) {

                if (props.dropList && props.dropList.data && props.dropList.data.drops && props.dropList.data.drops.length == 0) {
                    scrolled = true
                } else {
                    props.dropList && props.dropList.data && props.dropList.data.drops && props.dropList.data.drops.map((items) => {
                        state.dropList[0].push(items)
                        scrolled = false
                    })
                }

            } else {

                state.dropList.push(props.dropList && props.dropList.data && props.dropList.data.drops)
                scrolled = false
                // if(props && props.dropList && props.dropList.data && props.dropList.data.drops && props.dropList.data.drops.length > 15){
                    extraLimit = Number((props.dropList && props.dropList.data && props.dropList.data.drops && props.dropList.data.drops.length) - (state.recordLimit))
                // }else{
                //     extraLimit = 0
                // }
            }

            return {
                loadText: 1,
                isCheck: props.isCheckDrop,
                // dropList:  props.dropList && props.dropList.data && props.dropList.data.drops ? props.dropList.data.drops : [],
                totalCount: props.dropList && props.dropList.data && props.dropList.data.count ? props.dropList.data.count : '',
                pageCount: props.dropList && props.dropList.data && props.dropList.data.count / state.recordLimit,

                // rate: rate
            }
        }
        if ((props && props.trendingTags && props.trendingTags.statusCode) === 200) {
            props.resetTrendingTag()
            if (props.trendingTags.data && props.trendingTags.data.trendingTags) {
                return {
                    trendingTags: props.trendingTags.data.trendingTags
                }
            }
        }
        if((props && props.getCategories && props.getCategories.statusCode) === 200){
            props.resetCategories()
            if(props.getCategories && props.getCategories.data && props.getCategories.data.tags){
                let array = []
                props.getCategories.data.tags && props.getCategories.data.tags.length > 0 && props.getCategories.data.tags.map((items) => {
                    let obj = {
                        value : items && items.id,
                        label : <span><i className={items && items.iconClass}></i>&nbsp; {items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)}</span>,
                        name : items && items.name,
                        iconclass : <i className={items && items.iconClass}></i>
                    }
                    array.push(obj)
                })
                return {
                    categoryTypes : array
                }
            }
        }
        return null;

    }
    closeAlert = () => {
        setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    refreshArts = () => {
        controller.abort()
        controller = new AbortController();
        localStorage.setItem("droppage", "0")
        this.setState({
            isFilter: "all",
            selectedAllType: 1,
            selectedFiltType: 1,
            selectedAuctionType: 1,
            allFiltSelectOption: { value: 1, label: "All" },
            faltpriceSelectOption: { value: 1, label: 'Most Recent',sortOrder: 'DESC' },
            auctionSelectOption: { value: 1, label: 'Recent Auctions',sortOrder : 'DESC' },
            categoryOption : {value : 0, label : "Select Category"},
            offSet: 0,
            dropList: [],
            selectedTag: '',
            flatrarityOption:  { value: 0, label: 'Select rarity type'},

            // refreshDisable : true
        }, () => {
            this.callDrops()
        })


    }
    componentDidUpdate(props) {
        let nft = configPath.basename
        if (window.location.pathname.includes(nft)) {
            if ((props && props.urlProp && props.urlProp.location && props.urlProp.location.search) != (window.location.search)) {
                this.callDrops()
            }
        } else if ((props && props.urlProp && props.urlProp.location && props.urlProp.location.search) != (window.location.search)) {

            this.callDrops()

        }

    }
    callDrops = () => {

        controller = new AbortController();
        let signal = controller.signal;
        const {
            isFilter,
            dropList,
            allFiltSelectOption,
            faltpriceSelectOption,
            auctionSelectOption,
            categoryOption,
            flatrarityOption
         } = this.state
        let offSet = this.state.offSet;
        let recordLimit = this.state.recordLimit;
        // if((dropList && dropList[0])){
        //     console.log(localStorage.getItem("droppage"))
        //     offSet = localStorage.getItem("droppage")
        //     recordLimit = this.state.recordLimit
        // }else{
        //     console.log(localStorage.getItem("droppage"))
        //     if((localStorage.getItem("droppage")) == 0){
        //         recordLimit = this.state.recordLimit
        //         offSet = localStorage.getItem("droppage")
        //     }else{
        //         let records = Number(Number(localStorage.getItem("droppage")) + this.state.recordLimit)
        //         recordLimit = records
        //         offSet = 0
        //     }
        // }

        // let offSet = this.state.offSet
        // this.setState({ forcePage: offSet != null ? (JSON.parse(offSet) / this.state.recordLimit) : 0 })
        let obj = {
            offSet: offSet != null ? offSet : this.state.offSet,
            recordLimit,
            sortBy: 'updatedAt',
            sortOrder: this.state.sortOrder,
            signal
        }
        //types -> all,art,music,film
        if (isFilter == "all") {
            obj.artType = null
        } else {
            // if(categoryOption && categoryOption.value){
            //     obj.artType = categoryOption.name
            // }
            obj.artType = isFilter
        }
        // types -> all,flat price, auction
        if (allFiltSelectOption.value == 1) {
            obj.type = null
            if(flatrarityOption.rarity){
                obj.rarity = flatrarityOption.rarity
            }
        }
        else if (allFiltSelectOption.value == 2) {
            obj.type = "flatPrice"
            if(faltpriceSelectOption.sortOrder){
                obj.sortOrder = faltpriceSelectOption.sortOrder
            }
            if(faltpriceSelectOption.sortBy){
                obj.sortBy = faltpriceSelectOption.sortBy
            }
            // if(faltpriceSelectOption.rarity){
            //     obj.rarity = faltpriceSelectOption.rarity
            // }
            if(flatrarityOption.rarity){
                obj.rarity = flatrarityOption.rarity
            }
            
        } else if (allFiltSelectOption.value == 3) {
            obj.type = "auction"
            if(auctionSelectOption.sortOrder){
                obj.sortOrder = auctionSelectOption.sortOrder
            }
            if(auctionSelectOption.sortBy){
                obj.sortBy = auctionSelectOption.sortBy
            }
            if(auctionSelectOption.closedAuction){
                obj.closedAuction = auctionSelectOption.closedAuction
            }
            // if(faltpriceSelectOption.rarity){
            //     obj.rarity = faltpriceSelectOption.rarity
            // }
            if(flatrarityOption.rarity){
                obj.rarity = flatrarityOption.rarity
            }
           
           
        } else {
            obj.type = null
        }
        if (this.state.selectedTag) {
            obj.tag = this.state.selectedTag
        } else {
            obj.tag = null
        }


        this.props.getDrops(obj)
        // this.props.getUserCategories()

    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    startTime = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return <div className="mt-1" ><p className="mb-0  usernamelilac">Bidding Closed</p></div>
        }
        return <div className="mt-1"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + "d" : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }

    // onPageChange = (data) => {
    //     let offSet = this.state.recordLimit * (data && data.selected)
    //     localStorage.setItem("page", JSON.stringify(offSet))

    //     this.setState({ offSet }, () => this.callDrops())

    // }
    createSetupClicked(e) {
        createSetUp().then(response => console.log('account setup txn', response)).catch((e) => console.error('account setup error', e));
    }

    checkdropsLength = (tokens, totalTokens, items) => {
        if (isNaN(items && items.tokenGroupId)) {
            if (items && items.tokenGroupId && (items.tokenGroupId).includes("-")) {
                return 1
            }
        }
        if (totalTokens && Number.isInteger(totalTokens)) {
            return tokens
        } else {
            return 1
        }
    }
    handleFilter = (value) => {

        controller.abort()
        controller = new AbortController();
        this.setState({
            isFilter: value,
            offSet: 0,
            dropList: [],
            categoryOption : {value : 0, label : "Select Category"}

            // selectedAllType : 1, for reset other filters while select this
            // selectedFiltType : 1, 
            // selectedAuctionType : 1
        }, () => {

            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    handletypeChange = (e) => {

        localStorage.setItem("page", JSON.stringify(0))
        if (this.types[e.target.value]) {
            this.setState({
                selectedAllType: parseInt(e.target.value),
                selectedFiltType: 1,
                selectedAuctionType: 1,

            }, () => this.callDrops());
        } else {
            this.setState({
                selectedAllType: 1,

            }, () => this.callDrops());
        }

    }
    handlefilterTypeChange = (e) => {

        localStorage.setItem("page", JSON.stringify(0))
        if (this.filterTypes[e.target.value]) {
            this.setState({
                selectedFiltType: parseInt(e.target.value),

            }, () => this.callDrops());
        } else {
            this.setState({
                selectedFiltType: 1,

            }, () => this.callDrops());
        }

    }
    handleauctionTypeChange = (e) => {

        localStorage.setItem("page", JSON.stringify(0))
        if (this.filterAuctionTypes[e.target.value]) {
            this.setState({
                selectedAuctionType: parseInt(e.target.value),

            }, () => this.callDrops());
        } else {
            this.setState({
                selectedAuctionType: 1,

            }, () => this.callDrops());
        }

    }
    handleSelectChange = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            allFiltSelectOption: option,
            faltpriceSelectOption: { value: 1, label: 'Most Recent', sortOrder: 'DESC' },
            auctionSelectOption: { value: 1, label: 'Recent Auctions',sortOrder : 'DESC' },
            offSet: 0,
            dropList: [],
            flatrarityOption:  { value: 0, label: 'Select rarity type'},

        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    
    handleSelectChangeForPrice = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            faltpriceSelectOption: option,
            offSet: 0,
            dropList: []

        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    handleSelectChangeForAuction = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            auctionSelectOption: option,
            offSet: 0,
            dropList: []
        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    handleSelectChangeForRarity = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            flatrarityOption: option,
            offSet: 0,
            dropList: []

        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    handleSelectChangeForSorting = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            sortOption: option,
            offSet: 0,
            dropList: []

        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        }) 
    }
    gotoDetail = (items) => {

        if (
            (items && items.art && items.art.permanentId) &&
            (items && items.id) &&
            (items && items.group && items.group.tokenGroupId)
        ) {
            history.push(`/art/${items && items.art.permanentId}#drop${items.id}`)
        } else if ((items && items.art && items.art.id) && (items && items.id)) {
            history.push(`/art/${items && items.art.id}#drop${items.id}`)
        }

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    navigationCheck = (e, items) => {
        if ((e.target.id === "creatorname")) {
            this.gotoCreator(items && items.art && items.art.creator)
        } else if ((e.target.id === "ownername")) {
            this.gotoCreator(items && items.user)
        }
        else {
            this.gotoDetail(items)
        }
    }
    loadData = () => {
        const { recordLimit, offSet } = this.state
        let page = Number(offSet) + recordLimit

        this.setState({
            offSet: page, loadText: 2
        }, () => {
            this.callDrops()
        })

    }
    handleTrendTag = (items) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            selectedTag : items && items.name,
            offSet : 0,
            dropList : []
        
            // selectedAllType : 1, for reset other filters while select this
            // selectedFiltType : 1, 
            // selectedAuctionType : 1
        }, () => {
            
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
        // history.push(`/art/tag/${items && items.name}`)

    }
    showLoader() {
        PubSub.publish('msg', true);
    }
    chechUser = (creator,owner) => {
        if((creator && creator.userName) === (owner && owner.userName)){
            return true
        }else{
            return false
        }
    }
    hideLoader() {
        PubSub.publish('msg', false);
    }
    handleCategoryChange = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            categoryOption: option,
            offSet: 0,
            dropList: [],
            isFilter : ''

        }, () => {
            localStorage.setItem("page", JSON.stringify(0))
            this.callDrops()
        })
    }
    checkEDitionCount = (items) => {
        if(items && items.group && items.group !== null && items.art && items.art.toatalMintedTokens){
        if((checkMintedTokens(items.group, items.art.toatalMintedTokens)) > 1){
            return ' Total Editions'
        }else{
            return ' Total Edition'
        }
    }else{
        return ' Total Edition'
    }

    }
    getSteps = (data,type,state) => {
        if(this.state.isTourInitiated && (state === "skipped" || state === "finished")){
            this.setState({
                isTourInitiated : false
            })
        }
    }
    showlistprice = (items) => {
        if(items && items.storefrontListingResourceId !== null && items.withDapper){
            return  <p className="update usernamelilac" title={items && items.markedPrice}>
            {(items && items.auction && items.auction.id && items.auction.id != null) ? '' :
                items && items.markedPrice ? (flowDecimal(parseFloat(items.markedPrice)) + " USD") : "0 USD"}
          
            <br />
    
            {/* {(items && items.markedPrice) && <span className="small text-white" title={getUSDValue(this.state.rate, items.markedPrice)}>{(getUSDValue(this.state.rate, items.markedPrice)) + ' USD'}</span>} */}
        </p>
        }else{
            return <p className="update usernamelilac d-none" title={items && items.markedPrice}>
            {(items && items.auction && items.auction.id && items.auction.id != null) ? '' :
                items && items.markedPrice ? (flowDecimal(parseFloat(items.markedPrice)) + ' ' + "FLOW") : "0 FLOW"}
          
            <br />
    
            {/* {(items && items.markedPrice) && <span className="small text-white" title={getUSDValue(this.state.rate, items.markedPrice)}>{(getUSDValue(this.state.rate, items.markedPrice)) + ' USD'}</span>} */}
        </p>
        }
       
    }
    componentWillUnmount() {
        controller.abort()
        controller = new AbortController();
        if (extraLimit && extraLimit > 0) {
            localStorage.setItem("extraLimit", JSON.stringify(extraLimit))
        }

        if (
            (this.state.isFilter === "all") && 
            (this.state.allFiltSelectOption && this.state.allFiltSelectOption.value == 1) &&  
            (this.state.flatrarityOption && this.state.flatrarityOption.value == 0) && 
            (this.state.selectedTag === "")
            ) {
            localStorage.setItem("dropData", JSON.stringify(this.state.dropList))
        } else {
            localStorage.setItem("dropData", JSON.stringify([]))
        }
        
        // fix Warning: Can't perform a React state update on an unmounted component
        window.removeEventListener('scroll', this.infiniteScroll)


        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        const basePath = process.env.REACT_APP_BASEPATH

        const { isTourInitiated, rate, isCheck, trendingTags, allFiltSelectOption, categoryTypes } = this.state
        
        return (
            <Fragment>
                <Helmet>
                    <title>Drops</title>
                    <meta name="title" content="Explore Latest NFT Drops"></meta>
                    <meta name="description" content={`Explore latest NFT drops on ${process.env.REACT_APP_SITE_CONTRACT} NFT marketplace`}></meta>

                </Helmet>
                <div className="pagetitabtn">
                    
                            <div className="droplisttit">
                                <div className="probtns activebtnscus py-0 row">
                                    <div className="col-md-6"> <h1 className="mb-3 d-inline-block drops_title">Drops</h1></div>
                                    <div className="col-md-6 text-right"><button type="button" className="d-none btn btn-rounded btn btn-primary-gradient btn-sm ml-0" onClick={this.createSetupClicked}>Setup account</button><button type="button" className="btn btn-rounded btn btn-primary-gradient btn-sm mr-0" onClick={this.refreshArts}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>
                                </div>
                               
                                <div className="probtns activebtnscus py-0 row">
                                    <div className="col-md-7">
                                    <ul className="list-unstyled mb-0">
                                        <li><button type="button" className={(this.state.isFilter == "all") ? "btn btn-primary btn-sm active" : 'btn btn-primary-gradient btn-sm'} onClick={() => { this.handleFilter("all") }}>All</button></li>
                                        {categoryTypes && categoryTypes.length > 0 && categoryTypes.map(item => (
                                            <li key={item && item.value}><button type="button" className={(this.state.isFilter == (item && item.name)) ? "btn btn-primary btn-sm active" : 'btn btn-primary-gradient btn-sm'} onClick={() => { this.handleFilter(item && item.name) }}>
                                            {item && item.iconclass} &nbsp;
                                            {item && item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</button></li>
                                        ))}

                                        </ul>

                                    </div>
                                    <div className="col-md-5">
                                    <ul className="list-unstyled mb-0 text-right">                                       
                                        
                                        
                                        <li className='mr-2' style={{ display: "inline-block", verticalAlign: "middle", height: "45px", textAlign:'center' }} >
                                            <Select
                                                value={this.state.allFiltSelectOption}
                                                onChange={this.handleSelectChange}
                                                options={allTypes}
                                                styles={customStylesforAll}
                                                menuPortalTarget={document.body}
                                                isSearchable={false}
                                            />
                                        </li>
                                        {(allFiltSelectOption.value == 2) && <li className="mr-1" style={{ display: "inline-block", verticalAlign: "middle", height: "45px", textAlign:'center' }} >
                                            <Select
                                                value={this.state.faltpriceSelectOption}
                                                onChange={this.handleSelectChangeForPrice}
                                                options={priceOptions}
                                                styles={customStyles}
                                                menuPortalTarget={document.body}
                                                isSearchable={false}
                                            />
                                        </li>}
                                       
                                       
                                        {(allFiltSelectOption.value == 3) && <li className="mr-1" style={{ display: "inline-block", verticalAlign: "middle", height: "45px" , textAlign:'center'}}  >
                                            <Select
                                                value={this.state.auctionSelectOption}
                                                onChange={this.handleSelectChangeForAuction}
                                                options={auctionOptions}
                                                styles={customStyles}
                                                menuPortalTarget={document.body}
                                                isSearchable={false}
                                            />
                                        </li>}
                                        {<li className='' style={{ display: "inline-block", verticalAlign: "middle", height: "45px", textAlign:'center' }} >
                                            <Select
                                                value={this.state.flatrarityOption}
                                                onChange={this.handleSelectChangeForRarity}
                                                options={rarityOptions}
                                                styles={customStyles}
                                                menuPortalTarget={document.body}
                                                isSearchable={false}
                                            />
                                        </li>}
                                        

                                    </ul>
                                    </div>                                    
                                </div>
                            </div>
                            <hr className="mb-3" />
                   
                            <div className="droplisttit justify-content-end mb-3">
                                <div className="probtns activebtnscus py-0">
                                    <ul className="list-unstyled mb-0 text-right">
                                        {trendingTags && trendingTags.map((items) => (

                                            <li key={items && items.id}>

                                                <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "btn cushashtag btn-sm active" : 'btn cushashtag btn-sm  '} onClick={() => { this.handleTrendTag(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button></li>

                                        ))}

                                    </ul>
                                </div>
                            
                    </div>
                   

                </div>




                <div className="row">
                {this.state.dropList && this.state.dropList[0] && this.state.dropList[0].length > 0 && isTourInitiated && <JoyRide start={checkDropPage()} page="drops" isScroll={true} getSteps={(d,t,s) => this.getSteps(d,t,s)} />}

                    {this.state.dropList && this.state.dropList[0] && this.state.dropList[0].length > 0 && this.state.dropList[0].map((items, index) => (



                        <div className="col-sm-6 col-md-4 d-flex listed_nft" key={index} >

                            <div className="cardlist dropseclist flex1"
                                onClick={(e) => {
                                    e.preventDefault();

                                    this.navigationCheck(e, items)
                                }}>

                                <a className="artcovimglink">

                                    <ArtPreview src={items && items.art && items.art.thumbnailHash} alt={items && items.art && items.art.title ? items.art.title : "Avatar"} isPremiumArt={items && items.art && items.art.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.art && items.art.thumbnailMimeType} />
                                </a>
                                <div className="cont">

                                    <div className="row">
                                        <div className="col-sm-12 mb-3 ">
                                            <div className="piccent">
                                                <div className="upic" style={{ cursor: "pointer" }}>
                                                    <span className="artcovimg">
                                                        <span className="artcov"></span>
                                                        <span className="artcovimginset no-proimg">
                                                            <img src={items && items.art && items.art.creator && items.art.creator.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.art && items.art.creator && items.art.creator.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>

                                            <h3 className="userdescrip text-center" title={items && items.art && items.art.title}>{items && items.art && items.art.title ? items.art.title : "Title"}</h3>
                                            <h6 className={(this.chechUser((items && items.art && items.art.creator),(items && items.art && items.art.owner))) ? "usernamelilac mb-0 text-center piccenth6 created_by" : "mb-0 text-center piccenth6 created_by"} style={{ cursor: "pointer", }} title={items && items.art && items.art.creator && items.art.creator.fullName} id="creatorname">{items && items.art && items.art.creator && ((items.art.creator.fullName && items.art.creator.fullName.includes('@')) ? items.art.creator.fullName : (`by ${items.art.creator.fullName}`))}</h6>


                                        </div>

                                        <div className="col-6">
                                            {((items && items.auction == null) && <div className="userwithpic edition_count">
                                                {(items && items.auction && items.auction.id && items.auction.id != null) ? '' :
                                                    items && items.group && items.group != null ?
                                                        <p className="mb-0 small mt-1">
                                                            <span className="usernamelilac">{this.checkdropsLength((items && items.totalTokens && items.totalTokens), (items && items.art && items.art.toatalMintedTokens), items.group)}
                                                            </span> of &nbsp;
                                                            {(items && items.art && items.art.toatalMintedTokens) ?
                                                                (checkMintedTokens(items.group, items.art.toatalMintedTokens)) : 1} {(this.checkEDitionCount(items))}</p> :
                                                        <p className="mb-0 small mt-1">1 of <span className="usernamelilac">1</span> Total Edition</p>}

                                            </div>)}
                                            <div className="userwithpic ws-wrap listed_by">

                                                {/* {items && items.art && items.art.owner && items.art.owner.isPremiumUser && <span className="articoni permium" data-toggle="tooltip" title="" data-original-title="Premium"><img src={`../${configPath.imageAssets}/permium.png`} alt=""/></span>} */}
                                                <span id="uploadArt " className="">Listed by</span>&nbsp;<span className={(this.chechUser((items && items.art && items.art.creator),(items && items.art && items.art.owner))) ? "usernamelilac mb-0 creatordetalist " : "mb-0 usernamelilac "} style={{ cursor: "pointer", maxWidth: "150px", textOverflow:'ellipsis', overflow:'hidden' }} title={items && items.art && items.art.owner && items.art.owner.userName} id="ownername">{items && items.art && items.art.owner && items.art.owner.userName ? ((items.art.owner.userName && items.art.owner.userName.includes('@')) ? (`${items.art.owner.userName}`) : (`@${items.art.owner.userName}`)) : items.art.ownerWalletAddress != null && items.art.ownerWalletAddress}</span>

                                            </div>

                                        </div>

                                        <div className="col-6 text-right" style={{ cursor: "pointer" }}>
                                            {(items && items.markedPrice) && this.showlistprice(items)}

                                            {!this.dayClosed(items) ?
                                                (items && items.auction && items.auction.id && items.auction.id != null) &&
                                                <p className="mb-0 d-none"><span className="usernamelilac  " title={items && items.auction && items.auction.lastBid && (flowDecimal(parseFloat(items.auction.lastBid)))}>
                                                    {items && items.auction && items.auction.id && items.auction.id != null &&
                                                        (items.auction.lastBid ? (flowDecimal(parseFloat(items.auction.lastBid)) + ' ' + "FLOW") :
                                                            <span className="usernamelilac ">
                                                                {items && items.auction && items.auction.id && items.auction.id != null &&
                                                                    (items.auction.startPrice && `${(flowDecimal(parseFloat(items.auction.startPrice)) + ' ' + "FLOW")}`)}
                                                            </span>)}</span><br />
                                                </p>

                                                : (items && items.auction && items.auction.id && items.auction.id != null) &&
                                                ((items && items.auction && items.auction.lastBid) && <p className="mb-0 d-none">
                                                    <span className="usernamelilac " title={items && items.auction && items.auction.lastBid && (flowDecimal(parseFloat(items.auction.lastBid)))}>
                                                        {items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ? (flowDecimal(parseFloat(items.auction.lastBid)) + ' ' + "FLOW") : '')}
                                                    </span><br />
                                                    {/* {(items.auction && items.auction.lastBid) &&
                                                        <span className="small text-white d-none" title={getUSDValue(this.state.rate, items.auction.lastBid)}>{(flowDecimal(parseFloat(((this.state.rate) * (items.auction && items.auction.lastBid))))) + ' USD'}</span>} */}
                                                </p>)}
                                            {!this.dayClosed(items) ? items && items.auction && items.auction.id && items.auction.id != null &&
                                                <p className="mb-0 d-none"><span className="usernamelilac " >{items && items.auction && items.auction.id && items.auction.id != null && (items.auction.lastBid ? (
                                                    <span className="small text-white d-none" title={getUSDValue(this.state.rate, items.auction.lastBid)}>{(flowDecimal(parseFloat(((this.state.rate) * (items.auction && items.auction.lastBid))))) + ' USD'}</span>)
                                                    : <span className="usernamelilac   d-none">
                                                        {items && items.auction && items.auction.id && items.auction.id != null && (items.auction.startPrice &&
                                                            <span className="small text-white" title={getUSDValue(this.state.rate, items.auction.startPrice)}>{(flowDecimal(parseFloat(((this.state.rate) * (items.auction.startPrice))))) + ' USD'}</span>
                                                        )}
                                                    </span>)}</span><br />
                                                </p>

                                                : ''}

                                            {this.state.show && items && items.auction && items.auction.id && items.auction.id != null && this.startTime(items)}

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>))}
                    {!this.state.dropList[0] && <div className="solarloader" >
                        {<SolarSystemLoading />}

                    </div>}

                   
                    {(this.state.loadText == 2) && (this.state.dropList && this.state.dropList[0]) &&
                        <div className="solarloader" >
                            <SolarSystemLoading />

                        </div>
                    }
                    {(this.state.loadText == 1) && (this.state.dropList && this.state.dropList[0]) && scrolled &&
                        <div className="text-center solarloader usernamelilac" style={{ fontSize: "20px" }} >
                            You have reached the end of list

                        </div>
                    }

                    {this.state.dropList && this.state.dropList[0] && this.state.dropList[0].length == 0 && isCheck &&

                        <div className="col-md-4 offset-md-4 text-center">
                            <img src={`../${configPath.imageAssets}/sand-img.png`} className="mw-100 mb-5" alt="" style={{ width: "150px", height: "150px", marginTop: "15px" }} />
                            <h5 className="usernamelilac ">Stay tuned., Some cool NFTs are on the way!</h5>
                        </div>

                    }
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dropList: state.drop.dropList,
        usd: state.user.usd,
        isCheckDrop: state.drop.isCheckDrop,
        getCategories: state.category.userCategories,
        trendingTags: state.tags.trendingTags

    }
}
const actionCreators = {
    getDrops: dropActions.getDropArt,
    resetDropArt: dropActions.resetDropArt,
    getUSD: userAction.getUSD,
    getUserCategories: categoryActions.getUserCategories,
    resetCategories: categoryActions.resetGetUserCategories,
    getAllTrendingTags: tagActions.getAllTrendingTags,
    resetTrendingTag: tagActions.resetTrendingTags

}
const connectDrops = connect(mapStateToProps, actionCreators)(ArtContent)
export { connectDrops as ArtContent }
