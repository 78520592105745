import React from "react";
import PubSub from 'pubsub-js';
import { creatorStoreActions } from "store/actions";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { toast, Zoom } from "react-toastify";
import { storeServices } from "services";


class MetaTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metaTitle: '',
            metaDescription: '',
            metaKeywords: '',
            submitted: false,
            isTooLengthTitle: false,
            isTooLengthDesc: false,
            isTooLengthKeyword: false
        };

        this.mintitleLength = 3
        this.maxtitleLength = 70
        this.minDescriptionLength = 3
        this.maxDescriptionLength = 160
        this.minKeywordsLength = 3
        this.maxKeywordsLength = 310
        this.keyMaxLength = 30
        this.keyMinLength = 3
        this.maxAllowedWords = 10
    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.getTags()
        this.gotoTop()
    }
    getTags = () => {

        this.props.getMetatags()
    }
    componentDidUpdate(prevProps, state) {

        if (this.props.allMetatags &&
            (this.props.allMetatags.statusCode === 200) &&
            (this.props.allMetatags !== (prevProps && prevProps.allMetatags))) {
            this.props.resetmetatags()
            if (this.props && this.props.allMetatags && this.props.allMetatags.data) {
                let data = this.props && this.props.allMetatags && this.props.allMetatags.data && this.props.allMetatags.data.metadta
                this.setState({
                    metaTitle: data && data.metaTitle,
                    metaDescription: data && data.metaDescription,
                    metaKeywords: data && data.metaKeywords && data.metaKeywords.toString()
                });


            }
        }
        if (this.props &&
            this.props.getmetatagsFailed &&
            this.props.getmetatagsFailed.length > 0 &&
            (this.props.getmetatagsFailed !== prevProps.getmetatagsFailed)
        ) {
            this.props.resetmetatagsfailed()
            toast.error(this.props.getmetatagsFailed, { transition: Zoom })

        }




    }

    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    checkMetaTitle = (value) => {
        if (((value && value.length) <= (this.maxtitleLength)) && ((value && value.length) >= (this.mintitleLength))) {
            return true
        } else {
            return false
        }
    }

    onMetatitleChange = (event) => {
       
        if (((event.target.value).length <= this.maxtitleLength)) {
            this.setState({
                metaTitle: event.target.value, isTooLengthTitle: false,
            })

        } else {
            this.setState({ isTooLengthTitle: true, metaTitle: (event.target.value.slice(0, this.maxtitleLength)) })
        }
    }
    checkMetaDescription = (value) => {
        if (((value && value.length) <= (this.maxDescriptionLength)) && ((value && value.length) >= (this.minDescriptionLength))) {
            return true
        } else {
            return false
        }
    }

    onMetaDescChange = (event) => {
        
        if (((event.target.value).length <= this.maxDescriptionLength)) {
            this.setState({
                metaDescription: event.target.value, isTooLengthDesc: false,
            })

        } else {
            this.setState({ isTooLengthDesc: true, metaDescription: (event.target.value.slice(0, this.maxDescriptionLength)) })
        }
    }
    checkMetaKeywords = (value) => {
        if (((value && value.length) <= (this.maxKeywordsLength)) && ((value && value.length) >= (this.minKeywordsLength))) {
            return true
        } else {
            return false
        }
    }

    onMetaKeywordshange = (event) => {
        
        if (((event.target.value).length <= this.maxKeywordsLength)) {
            this.setState({
                metaKeywords: event.target.value, isTooLengthKeyword: false,
            })

        } else {
            this.setState({ isTooLengthKeyword: true, metaKeywords: (event.target.value.slice(0, this.maxKeywordsLength)) })
        }
    }

    onErrorHandle = (error) => {
        toast.error(error, { transition: Zoom })

    }

    IsAllkeywordsValid = (arrayOfkeys) => {
        if(arrayOfkeys && arrayOfkeys.length > 0){
            const isValid = arrayOfkeys.every(e => e.length <= (this.keyMaxLength) && e.length >= (this.keyMinLength))
            if(isValid){
                return true
            }else{
                return false
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { metaTitle, metaDescription, metaKeywords } = this.state
        
        if (metaTitle && this.checkMetaTitle(metaTitle)) {
            if (metaDescription && this.checkMetaDescription(metaDescription)) {
                if (metaKeywords && this.checkMetaKeywords(metaKeywords)) {
                    let keystr = metaKeywords
                    if(keystr && keystr.slice(-1).includes(',')){
                        keystr = keystr.substring(0, keystr.length - 1)
                    }                    
                    let keywords = keystr.split(',')
                    if(this.IsAllkeywordsValid(keywords)){
                        let obj = {
                            metaTitle,
                            metaDescription,
                            metaKeywords : keywords
                        }
                        this.showLoader()
                        storeServices.updateSectionMetaTags(JSON.stringify(obj)).then(res => {
                            this.hideLoader()
                            if (res && res.statusCode === 200) {
                                if (res && res.data && res.data.metadta) {
                                    toast.success("Meta tags updated successfully")
                                    this.getTags()
                                    this.setState({
                                        isTooLengthTitle: false,
                                        isTooLengthDesc: false,
                                        isTooLengthKeyword: false
                                    })
                                }
                            }
                        }).catch(e => {
                            this.hideLoader()
                            if( e && typeof e === "string"){
                                toast.error(e, { transition: Zoom })
                            }
                        })
                    }else{
                        toast.error(`Each keyword must be minimum ${this.keyMinLength} and maximum ${this.keyMaxLength} characters`)
                    }
                    
                }else{
                    toast.error("Please enter valid meta keywords",{transition : Zoom})
                }
            }else{
                toast.error("Please enter valid meta description",{transition : Zoom})
            }
        }else{
            toast.error("Please enter valid meta title",{transition : Zoom})
        }
    }

    gotoTop = () => {
        window.scrollTo({
            top: 0,
        });
    }
   
    render() {
        const {isTooLengthTitle, isTooLengthDesc, isTooLengthKeyword} = this.state
        return (

            <div className="">
                <Helmet>
                    <title>Meta Tags</title>
                    <meta name="title" content={`Update meta tags`}></meta>
                    <meta name="description" content={`Update meta tags`}></meta>
                </Helmet>
                <div className="container-fluid">
                    <div className="ds-intit">
                        <h1 className="mb-0">Meta Tags <span className="betaLink">(Soon)</span></h1>
                    </div>

                    <div className="propagecont mb-5">
                        <div className="container1">
                            <div className="row mb-5">

                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="form-group mb-4 col-md-4">

                                            <div className="ds-inputs">
                                                <textarea className={isTooLengthTitle ? "form-control descdan" : "form-control"} id="metaTitle" aria-describedby="metaTitle" value={this.state.metaTitle} onChange={this.onMetatitleChange} />
                                                <div className="input-floating-label"><span>Meta Title</span></div>
                                            </div>
                                            {this.state.submitted && !this.state.metaTitle && <div className="mandatory">{"Meta title is Required"}</div>}
                                            {this.state.submitted && this.state.metaTitle && !this.checkMetaTitle(this.state.metaTitle) && <div className="mandatory">{`Min ${this.mintitleLength} - Max ${this.maxtitleLength}`}</div>}
                                        </div>
                                        <div className="form-group mb-4 col-md-4">

                                            <div className="ds-inputs">
                                                <textarea className={isTooLengthDesc ? "form-control descdan" : "form-control"} id="metaDescription" aria-describedby="metaDescription" value={this.state.metaDescription} onChange={this.onMetaDescChange} />
                                                <div className="input-floating-label"><span>Meta Description</span></div>
                                            </div>
                                            {this.state.submitted && !this.state.metaDescription && <div className="mandatory">{"Meta description is Required"}</div>}
                                            {this.state.submitted && this.state.metaDescription && !this.checkMetaDescription(this.state.metaDescription) && <div className="mandatory">{`Min ${this.minDescriptionLength} - Max ${this.maxDescriptionLength}`}</div>}
                                        </div>
                                        <div className="form-group mb-4 col-md-4">

                                            <div className="ds-inputs">
                                                <textarea className={isTooLengthKeyword ? "form-control descdan" : "form-control"} id="metaKeywords" aria-describedby="metaKeywords"  value={this.state.metaKeywords} onChange={this.onMetaKeywordshange} />
                                                <div className="input-floating-label"><span>Meta keywords</span></div>
                                            </div>
                                            <div className="mt-2 usernamelilac small">Keywords should be comma separated (example : disrupt, store)</div>
                                            <div className="mt-2 usernamelilac small">Maximum allowed keywords : <b>{this.maxAllowedWords}</b></div>

                                            {this.state.submitted && !this.state.metaKeywords && <div className="mandatory">{"Meta keywords are Required"}</div>}
                                            {this.state.submitted && this.state.metaKeywords && !this.checkMetaKeywords(this.state.metaKeywords) && <div className="mandatory">{`Min ${this.minKeywordsLength} - Max ${this.maxKeywordsLength}`}</div>}
                                        </div>

                                    </div>


                                </div>

                            </div>


                            <div className="text-center col-md-12">
                                <hr className="lightline" />
                                <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allMetatags: state.creatorStore.metatags,
        getmetatagsFailed: state.creatorStore.metatagsFailed,
    }
}

const actionCreators = {

    getMetatags: creatorStoreActions.getSectionMetaTags,
    resetmetatags: creatorStoreActions.resetGetMetatags,
    resetmetatagsfailed: creatorStoreActions.resetGetMetatagsFailed,

}

const connectMetaTags = connect(mapStateToProps, actionCreators)(MetaTags)

export { connectMetaTags as MetaTags }