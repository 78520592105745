import React from 'react'
import { Fragment } from 'react';
import ReactPaginate from 'react-paginate';

export function ReactPagination(props) {
    return (
        <Fragment>
            <div className="w-100 text-center">
              
                    <ReactPaginate
                        pageCount={props && props.pageDetails && props.pageDetails.pageCount}
                        pageRangeDisplayed={3}
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        onPageChange={props && props.pageDetails && props.pageDetails.onPageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        forcePage={props && props.pageDetails &&  props.pageDetails.forcePg}

                    >
                    </ReactPaginate>
              
            </div>
        </Fragment>
    )
}