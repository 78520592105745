import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { configPath } from 'config';
import { toast } from 'react-toastify';

class AdminForgotPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            password:'',
            submitted : false
        }
    }

    handleChange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({submitted:true})
        const {password} = this.state

        if(password){
            let obj = {
                password
            }
            // if(this.isNumber(this.state.uname)){
            //     obj['mobile'] = uname
            // }else{
            //     obj['email'] = uname
            // }
            // this.props.login(JSON.stringify(obj))
        }else{
            toast.error("Please enter your new password")
        }
    }
    render(){
        return (
            <Fragment>
                <div className="wrapper">
                    <div className="login">
                        <div className="login-form">
                            <a href="/admin/login"><img src={`/${configPath.imageAssets}/logoaw.png`} alt="" className="authogo"/></a>
                            <h3 className="login-title">Reset Password</h3>
                            <div className="form-wrapper">
                                <div className="input-wrapper">
                                    <form onSubmit={this.handleSubmit} className="row">

                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="label">Password</label>
                                                <input type="password" className="input form-control" name="password" value={this.state.password} placeholder="Enter Password*" onChange={this.handleChange}/>
                                                {this.state.submitted && !this.state.password && <div className="mandatory">{"Password is Required!"}</div>}

                                            </div>
                                        </div>

                                      

                                        <div className="actions col-sm-12 mt-3">
                                            <button type="submit" className="action btn btn-primary">Reset</button>
                                        </div>

                                       
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAdmin : state.user.isAdmin,
        admin : state.user.admin
    }
}
const actionCreators = {
   
}
const connectedForgot = connect(mapStateToProps,actionCreators)(AdminForgotPassword)
export {connectedForgot as AdminForgotPassword}