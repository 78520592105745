import { configPath } from 'config'
import { isConnectWallet, isWebLogin } from 'helpers'
import history from 'helpers/history'
import React, { Component, Fragment } from 'react'

import { connect } from 'react-redux'

class Maintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <Fragment>
                <div className='mtbgcon'>
                <div className="container-fluid text-center">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="error-template maintenancebgcon">
                            <img src={`../${configPath.imageAssets}/maintenance.png`} className="mw-100 my-5" alt="" />
                                <h1 className="mb-3 usernamelilac text-uppercase">Oops!</h1>
                                <h3 className="mb-2 usernamelilac text-uppercase">Temporarily down for maintenance</h3>
                                <h4  className="mb-2">We’ll be back soon!</h4>
                                <p className="mb-5" >Sorry for the inconvenience but we’re performing some maintenance at the moment.
                        we’ll be back online shortly!</p>
                                    <p className="mb-0">The Team</p>
                                    <p className="usernamelilac">DisruptArt</p>


                            </div> */}
                            <img src={`../${configPath.imageAssets}/da-trans.png`} className="mb-5" alt="" />
                            <h1 className="mb-3">Sorry we'll be down for<br/>scheduled maintenance on</h1>
                            <h3 className="mb-3 ">Wed, Jun 15 | 10:30 AM EST </h3>
                            <h4  className="mb-3">We expect to be back up and running on</h4>
                            <h3 className="mb-3 ">Thu, Jun 16 | 09:30 AM EST</h3>

                            <p className="mt-5" >Thanks for your patience. Keep Disrupting!</p>
                        </div>

                    </div>
                </div>
                </div>

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {

    }
}
const actionCreators = {


}

const connectMaintenance = connect(mapStateToProps, actionCreators)(Maintenance)
export { connectMaintenance as Maintenance }
