import { walletConstants } from "../../constants";

const initialState = {
    wallet : [],
    checkWallet : [],
    walletlinkErr : [],
    walletsuc : [],
    walleterr : [],
}
export function wallet(state=initialState,action){
    switch(action.type){
        case walletConstants.WALLET_LINK_ACCOUNT:
            return {
                wallet : action.wallet
            }
            case walletConstants.GET_WALLET_ACCOUNT:
                return {
                    checkWallet : action.checkWallet
                }
                case walletConstants.WALLET_LINK_FAILED:
                    return {
                        walletlinkErr : action.walletlinkErr
                    }
                    case walletConstants.WALLET_SUCCESS:
                        return {
                            ...state,
                            walletsuc : action.walletsuc
                        }
                        case walletConstants.WALLET_ERROR:
                            return {
                                ...state,
                                walleterr : action.walleterr
                            }
                            
            
            default:
                return state
    }
}