import * as fcl from "@onflow/fcl"
import * as t from "@onflow/types"
import { defaultFclLimit } from "./fcl-limit";


export const flowbalance = async(useraddress) => {

    let scriptcode = `\
            import FungibleToken from `+process.env.REACT_APP_FUNGIBLE_TOKEN+`
            pub fun main():UFix64 {
                let receiverRef =  getAccount(`+useraddress+`)
                            .getCapability(/public/flowTokenBalance)
                            .borrow<&{FungibleToken.Balance}>() ?? panic("failed to borrow reference to recipient vault")
                return receiverRef.balance
            }`
    try {
        let scriptresult = await fcl.send([fcl.script(scriptcode)]);
        return {
          status: 200,
          data: { "message" : "Flow Token Balance" , "Value" : String(JSON.stringify(scriptresult["encodedData"]["value"],null,1)) } 
        };
        //console.log(JSON.stringify(scriptresult["encodedData"]["value"],null,1));
    }
    catch(e) {
	return {
          status: 400,
          data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
    }

}

export const transferFlow = async(recpaddress,amount) => {

    let transactioncode = `\
                                        import FungibleToken from `+process.env.REACT_APP_FUNGIBLE_TOKEN+`
                                        import FlowToken from `+process.env.REACT_APP_FLOW_TOKEN+`

                                        transaction(recp: Address) {
                                                let sentVault: @FungibleToken.Vault
                                                prepare(signer: AuthAccount) {
                                                let vaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
                                                         ?? panic("failed to borrow reference to sender vault")

                                                self.sentVault <- vaultRef.withdraw(amount: `+amount+`)
                                        }

                                       execute {
                                                let receiverRef =  getAccount(recp)
                                                .getCapability(/public/flowTokenReceiver)
                                                .borrow<&{FungibleToken.Receiver}>()
                                                ?? panic("failed to borrow reference to recipient vault")

                                                receiverRef.deposit(from: <-self.sentVault)
                                        }
                                        }`;
    
    try {

        const blockResponse = await fcl.send([
            fcl.getBlock(),
        ])

        const { transactionId } = await fcl.send([
        fcl.transaction(transactioncode),
        fcl.args([fcl.arg(recpaddress, t.Address)]),
        fcl.proposer(fcl.currentUser().authorization),
        fcl.authorizations([
            fcl.currentUser().authorization
        ]),
        fcl.payer(fcl.currentUser().authorization),
        fcl.ref(blockResponse["block"].id),
        fcl.limit(defaultFclLimit),
        ])

        return {
            status: 200,
            data: { "message" : "Flow Token Transfer Raised" , "Value" : transactionId } 
        };

    } catch (e) {
        return {
            status: 400,
            data: { "message" : "Exception happens" , "Error" : String(e) } 
        };
   	}

}