const createClient = require('ipfs-http-client')

const client = createClient('https://ipfs.infura.io:5001')

const convertToBuffer = async(reader) => {
      //file is converted to a buffer for upload to IPFS
        const buffer = await Buffer.from(reader.result);
      //set this buffer -using es6 syntax
       return buffer
};

export const addfile = async(file) => {
   let reader = new window.FileReader()
   reader.readAsArrayBuffer(file)
   console.log(reader)
   let obj = {convertToBuffer,client,reader}
   return obj
  //   reader.onloadend = async () =>  {
  //    let buffer = await convertToBuffer(reader)
  //    const { cid } = await client.add(buffer)
  //    console.log(cid.string);
  //  }
}

