import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { eventActions } from 'store/actions';
import moment from 'moment'
import { ReactPagination } from 'helpers';
import PubSub from 'pubsub-js';
import history from 'helpers/history';
import { debounce } from 'utils';


class FetchEvents extends Component {
    constructor(props) {
        super(props);
        this.eventTypes = {
            1: "For sale",
            2: "Withdraw",
            3: "Purchase",
            4: "Price change",
            5: "For auction",
            6: "New bid",
            7: "Auction settled",
            8: "Auction cancelled",
        }
        const evtTypes = [];
        for (const key in this.eventTypes) {
            evtTypes.push({
                evtName: this.eventTypes[key],
                value: parseInt(key)
            });
        }
        this.state = {
            events: [],
            totalCount: '',
            offSet: 0,
            recordLimit: 10,
            pageCount: '',
            isFetched: false,
            search: '',
            evtTypes,
            selectedEvtType: -1
        }
        this.debouncedFetch = debounce(this.callApi.bind(this), 600);
    }
    componentDidMount() {
        this.callApi()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.alleventHistory)) {
            // console.log(props.alleventHistory)
            return {
                events: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.entity ? props.alleventHistory.data.entity : [],
                totalCount: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.count,
                pageCount: props.alleventHistory && props.alleventHistory.data && props.alleventHistory.data.count / state.recordLimit,
                isFetched: true
            }
        }

    }
    handleRefresh = () => {
        this.setState({ offSet: 0, forcePage: 0,search : '', selectedEvtType: -1 }, () => {
            this.callApi()
        })
    }


    callApi = () => {
        const { search, selectedEvtType } = this.state;
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,

        }
        if (search) {
            obj.search = search;
        }
        if (Number.isInteger(selectedEvtType) && selectedEvtType !== -1) {
            obj.eventType = selectedEvtType;
        }
        this.props.getAllEventsHistory(obj)

    }
    onPageChange = (data) => {
        let offSet = this.state.recordLimit * (data && data.selected)

        this.setState({ offSet }, () => this.callApi())

    }

    handleDate = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => {

        })
        // console.log(e.target.value)
    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    checkEventType = (items) => {
        if (items) {
            return this.eventTypes[items.eventType];
        }
    }
    handleSearch = (e) => {
        this.setState({
            search: e.target.value, offSet: 0
        }, () => { this.debouncedFetch() })
    }
    handleEvtTypeChange = (e) => {
        if (this.eventTypes[e.target.value]) {
            this.setState({
                selectedEvtType: parseInt(e.target.value)
            }, () => this.callApi());
        } else {
            this.setState({
                selectedEvtType: -1
            }, () => this.callApi());
        }
    }
    gotoUser = (items) => {
        if (items && items.initiatedUser && items.initiatedUser.id) {
            const baseurl = process.env.REACT_APP_BASEPATH
            const username = items && items.initiatedUser && items.initiatedUser.userName && encodeURIComponent(items.initiatedUser.userName)

            const navPath = (baseurl + `/admin/userDetail/${items && items.initiatedUser && items.initiatedUser.id}`)
            window.open(navPath, '_blank')
        }
    }
    render() {


        const eventList = this.state.events && this.state.events.map((items, index) => {
            return <tbody>
                <tr>
                    <td><a className="designLink userdescrip_adm" style={{ display: 'inline-block', maxWidth: "150px" }} title={items && items.initiatedUser && items.initiatedUser.userName} onClick={() => { this.gotoUser(items) }}>{items && items.initiatedUser && items.initiatedUser.userName ? `@${items.initiatedUser.userName}` : '-'}</a></td>
                    <td>{items && items.art && items.art.title && items.art.title}</td>
                    <td>{items && this.checkEventType(items)}</td>
                    <td>{items && items.requestedCount ? items.requestedCount : "-"}</td>
                    <td>{items && items.blockHeight && items.blockHeight}</td>
                    <td>{moment(items && items.createdAt).format('MMM/DD/YYYY hh:mm:ss A')}</td>


                </tr>

            </tbody>

        })

        const eventTypeOptions = this.state.evtTypes.map((evtType) => {
            return <option value={evtType.value}>{evtType.evtName}</option>
        });
        const eventTypeSelect = (
            <select onChange={this.handleEvtTypeChange} className="form-control">
                <option value={-1}>Select event type</option>
                {eventTypeOptions}
            </select>
        );
        return (

            <Fragment>

                <div className="container-fluid">
                    <div className="mb-4 admintitle"><h2 className="font-weight-bold">Events History</h2></div>
                    <div className="purchasebox p-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i className="fa fa-search" aria-hidden="true"></i></div>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Search" name="search" onChange={this.handleSearch} value={this.state.search} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text"><i class="fas fa-sort"></i></div>
                                            </div>
                                            {eventTypeSelect}

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">

                                        <div className="text-right">
                                            <div className="btn btn-primary btn-sm px-3 ml-2" onClick={this.handleRefresh}>
                                                <i className="fa fa-refresh" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped-cusom ">
                                <thead>
                                    <tr>
                                        <th>INITIATED USER</th>
                                        <th>ART NAME</th>
                                        <th>EVENT TYPE</th>
                                        <th>REQUESTED COUNT</th>
                                        <th>BLOCK HEIGHT</th>
                                        <th>CREATED AT</th>

                                    </tr>
                                </thead>

                                {eventList}

                            </table>
                            {this.state.events && !this.state.events.length > 0 && this.state.isFetched && <div className="text-center">No Data Found</div>}
                        </div>
                    </div>
                    {this.state.events && this.state.events.length > 0 && <ReactPagination pageDetails={{ pageCount: this.state.pageCount, perPage: this.state.recordLimit, onPageChange: this.onPageChange, forcePg: this.state.forcePage }} />}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        alleventHistory: state.events.eventHistory,

    }
}

const actionCreators = {
    getAllEventsHistory: eventActions.getEventHistory,


}

const connectEvents = connect(mapStateToProps, actionCreators)(FetchEvents)

export { connectEvents as FetchEvents }