import React from "react";
import PubSub from 'pubsub-js';
import { userAction } from "store/actions";
import { connect } from "react-redux";
import { userServices } from "services";
import { configPath } from "../../config";
import { Helmet } from "react-helmet";
import history from "helpers/history";
import { localAirdropPage, localArtPage, localDropPage } from "helpers";
import { debounce } from "utils";

var timeout;
class ProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            userAddress: '',
            description: '',
            imageHash: '',
            bannerImageHash: '',
            image: '',
            uName: '',
            banner: '',
            message: '',
            instagramProfile: '',
            isInstagramLinkValid: false,
            twiterProfile: '',
            discordProfile: '',
            websiteProfile: '',
            isTwitterLinkValid: false,
            isWebsiteLinkValid: false,
            isDiscordLinkValid: false,
            isAlert: false,
            submitted: false,
            formHasError: false,
            addLink1: '',
            addLink2: '',
            addLink3: '',
            isAddLink1Valid: false,
            isAddLink2Valid: false,
            isAddLink3Valid: false,
            isUsernameError: '',
            isUserLoading: false,
            isValidUsername: true,
            isUserTyping: false,
            roleId: 0,

        };
        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onInstagramLinkChange = this.onInstagramLinkChange.bind(this);
        this.onTwitterLinkChange = this.onTwitterLinkChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.debouncedFetch = debounce(this.checkUsernameVerification.bind(this), 300);

    }
    componentDidMount() {
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.props.getUserProfile()
        const form = document.getElementById('uName');
        // form && form.addEventListener('focusout', (event) => {
        //     this.checkUsernameVerification()
        // });
       
    }

    componentDidUpdate(prevProps, state) {
        if ((state && state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        if (this.props !== (prevProps && prevProps)) {
            if (this.props && this.props.usersList && this.props.usersList.data) {
                let data = this.props && this.props.usersList && this.props.usersList.data
                this.setState({
                    fullName: data.fullName,
                    email: data.email,
                    userAddress: data.userAddress,
                    uName: data.userName,
                    description: data.description,
                    image: data.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.profileImage}` : null,
                    banner: data.coverImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${data.coverImage}` : null,
                    imageHash: data.profileImage ? data.profileImage : null,
                    bannerImageHash: data.coverImage ? data.coverImage : null,
                    instagramProfile: data.instagramProfile ? data.instagramProfile : '',
                    isInstagramLinkValid: data.instagramProfile && this.checkURL(data.instagramProfile) ? true : false,
                    twiterProfile: data.twiterProfile ? data.twiterProfile : '',
                    isTwitterLinkValid: data.twiterProfile && this.checkURL(data.twiterProfile) ? true : false,
                    discordProfile: data.discord ? data.discord : '',
                    addLink1: data.additionalLink1 ? data.additionalLink1 : '',
                    addLink2: data.additionalLink2 ? data.additionalLink2 : '',
                    addLink3: data.additionalLink3 ? data.additionalLink3 : '',
                    isAddLink1Valid: data.additionalLink1 && this.checkURL(data.additionalLink1) ? true : false,
                    isAddLink2Valid: data.additionalLink2 && this.checkURL(data.additionalLink2) ? true : false,
                    isAddLink3Valid: data.additionalLink3 && this.checkURL(data.additionalLink3) ? true : false,
                    websiteProfile: data.website ? data.website : '',
                    isWebsiteLinkValid: data.website && this.checkURL(data.website) ? true : false,
                    roleId: data.roleId

                });
            }
        }
    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {
        timeout = setTimeout(() => {
            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    onUserNameChange = (e) => {
        if (((e.target.value && e.target.value.length) <= 50)) {
            this.setState({ uName: (e.target.value).toLowerCase(),isUserTyping : true, isUserLoading : true }, () => {
                this.debouncedFetch()
                this.userNameValidation(e.target.value)
            })
        }
    }
    checkDesc = (value) => {
        if ((value && value.length) >= 3) {
            return true
        } else {
            return false
        }
    }
    checkmaxDesc = (value) => {
        if ((value && value.length) <= 6000) {
            return true
        } else {
            return false
        }
    }
    checkFullName = (value) => {
        if (((value && value.length) <= 110) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 3)) {
            return true
        } else {
            return false
        }
    }
    checkDiscordName = (value) => {
        if (((value && value.length) <= 120) && ((value && value.length) >= 4)) {
            return true
        } else {
            return false
        }
    }
    checkusername = (value) => {
        if (((value && value.length) <= 50) && (value && value.length) >= 4) {

            return true
        } else {
            return false
        }
    }
    checkemail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailReg.test(value)) {
            return true
        } else {
            return false
        }
    }
    checkURL(value) {
        const urlReg = /https:\/\/.*/;
        if (urlReg.test(value)) {
            return true;
        }
        return false;
    }
    onFullNameChange(event) {
        this.setState({
            fullName: event.target.value
        });
    }
    onInstagramLinkChange(event) {
        this.setState({
            instagramProfile: event.target.value,
            isInstagramLinkValid: this.checkURL(event.target.value)
        });
    }
    onTwitterLinkChange(event) {
        this.setState({
            twiterProfile: event.target.value,
            isTwitterLinkValid: this.checkURL(event.target.value)
        });
    }
    onDiscordLinkChange = (e) => {
        this.setState({
            discordProfile: e.target.value,
            // isDiscordLinkValid : this.checkDiscord(e.target.value)
        });

    }
    checkDiscord = (userName) => {
        var usernameRegex = /^[a-z0-9_-]{3,16}$/
        // console.log(usernameRegex.test(userName))

        if (usernameRegex.test(userName)) {
            return true
        } else {
            return false
        }
    }
    onWebsiteLinkChange = (e) => {
        this.setState({
            websiteProfile: e.target.value,
            isWebsiteLinkValid: this.checkURL(e.target.value)
        });
    }
    onAddLink1Change = (e) => {
        this.setState({
            addLink1: e.target.value,
            isAddLink1Valid: this.checkURL(e.target.value)
        })
    }
    onAddLink2Change = (e) => {
        this.setState({
            addLink2: e.target.value,
            isAddLink2Valid: this.checkURL(e.target.value)

        })
    }
    onAddLink3Change = (e) => {
        this.setState({
            addLink3: e.target.value,
            isAddLink3Valid: this.checkURL(e.target.value)

        })
    }
    onDescriptionChange(event) {
        this.setState({
            description: event.target.value
        });
    }
    onErrorHandle = (error) => {

        this.setState({ isAlert: true, message: error, style: "danger" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()

    }
    async onProfilePicChanged(file) {
        const types = ['image/png', 'image/jpeg', 'image/gif']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        if (types.every(type => file && file.type !== type)) {
            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)

        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported profile file size is ${2} MB`;
            this.onErrorHandle(err)

        }

        else if (file) {

            const reader = new FileReader();
            reader.onload = (e) => {

                this.setState({
                    fileData: file,
                    image: reader.result
                })
                // this.onFileLoaded(e.target.result);
            };
            reader.readAsDataURL(file)
        }
    }

    async onBannerPicChanged(file) {
        const types = ['image/png', 'image/jpeg', 'image/gif']
        let err = '';
        const sizeLimit = 2 * (1e+6);
        if (types.every(type => file && file.type !== type)) {

            err += file.type + ' is not a supported format\n';
            this.onErrorHandle(err)


        } else if (file && file.size >= sizeLimit) {
            err += `Maximum supported cover file size is ${2} MB`;
            this.onErrorHandle(err)

        }
        else if (file) {

            const reader = new FileReader();
            reader.onload = (e) => {

                this.setState({
                    bannerFileData: file,
                    banner: reader.result
                })
            };
            reader.readAsDataURL(file)
        }
    }

    onEditProfileClicked = () => {
        this.gotoCreator()
    }

    onProfileSubmit() {
        const webLoginDetails = JSON.parse(localStorage.getItem('webLogin'));
        if(webLoginDetails && 
            webLoginDetails.data && 
            webLoginDetails.data.authUser && 
            webLoginDetails.data.authUser.userName && 
            webLoginDetails.data.authUser.fullName){
                webLoginDetails.data.authUser.userName = this.state.uName
                webLoginDetails.data.authUser.fullName = this.state.fullName
                const userDetail = JSON.stringify(webLoginDetails);
                localStorage.setItem("webLogin", userDetail);
            }
        this.setState({ isAlert: true, message: 'Profile Updated Successfully!', style: "success" })
        this.gotoTop()
        this.resetTimeout()
        this.closeAlert()
    }
    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ submitted: true })
        const { fullName, description, fileData, email, uName, bannerFileData, instagramProfile, twiterProfile, discordProfile, addLink1, addLink2, addLink3, websiteProfile, isValidUsername, isUsernameError, isUserLoading } = this.state
        if (fullName &&
            description &&
            email &&
            uName &&
            this.checkDesc(description) &&
            this.checkmaxDesc(description) &&
            this.checkemail(email) &&
            this.checkFullName(fullName) &&
            this.checkusername(uName)) {
            if (this.userNameValidation(uName)) {
                if ((isValidUsername && !isUsernameError)) {
                    if (!isUserLoading) {
                        let formData = new FormData()

                        formData.append("fullName", fullName);
                        formData.append("description", description);
                        fileData && formData.append("profileImage", fileData);
                        bannerFileData && formData.append('coverImage', bannerFileData);
                        formData.append('userName', uName)

                        if (instagramProfile) {
                            if (this.checkURL(instagramProfile)) {
                                if (this.checkName(instagramProfile)) {
                                    formData.append('instagramLink', instagramProfile)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            formData.append('instagramLink', null)
                        }
                        if (twiterProfile) {
                            if (this.checkURL(twiterProfile)) {
                                if (this.checkName(twiterProfile)) {
                                    formData.append('twitterLink', twiterProfile)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            formData.append('twitterLink', null)
                        }
                        // formData.append('instagramLink', !!instagramProfile?instagramProfile:null);

                        // formData.append('twitterLink', !!twiterProfile?twiterProfile:null);
                        if (addLink1) {
                            if (this.checkURL(addLink1)) {
                                if (this.checkName(addLink1)) {
                                    formData.append('additionalLink1', addLink1)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            formData.append('additionalLink1', null)
                        }
                        if (addLink2) {
                            if (this.checkURL(addLink2)) {
                                if (this.checkName(addLink2)) {

                                    formData.append('additionalLink2', addLink2)
                                }
                                else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            formData.append('additionalLink2', null)
                        }
                        if (addLink3) {
                            if (this.checkURL(addLink3)) {
                                if (this.checkName(addLink3)) {

                                    formData.append('additionalLink3', addLink3)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            formData.append('additionalLink3', null)
                        }
                        if (discordProfile) {
                            if (this.checkDiscordName(discordProfile)) {
                                formData.append('discord', discordProfile)
                            } else {
                                return false
                            }

                        } else {
                            formData.append('discord', null)
                        }
                        if (websiteProfile) {
                            if (this.checkURL(websiteProfile)) {
                                if (this.checkName(websiteProfile)) {
                                    formData.append('website', websiteProfile)
                                } else {
                                    return false
                                }
                            } else {
                                return false
                            }

                        } else {
                            formData.append('website', null)
                        }


                        // this.props.updateProfile(JSON.stringify(obj))
                        this.showLoader()
                        userServices.updateProfile(formData).then(res => {
                            this.hideLoader()
                            if ((res && res.statusCode) === 200) {
                                this.onProfileSubmit()
                                this.props.getUserProfile()
                            } else {
                                this.setState({ isAlert: true, message: 'Unexpected error occured while upload profile', style: "success" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            }

                        }).catch(err => {
                            this.hideLoader()

                            if (err && err.includes('File too large')) {
                                this.setState({ isAlert: true, message: (`Maximum supported file size is ${2} MB, ${(err && ((err).charAt(0).toUpperCase()) + (err).slice(1).toLowerCase())}`), style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            } else {
                                this.setState({ isAlert: true, message: (err && ((err).charAt(0).toUpperCase()) + (err).slice(1).toLowerCase()), style: "danger" })
                                this.gotoTop()
                                this.resetTimeout()
                                this.closeAlert()
                            }
                            return false
                        })


                    } else {
                        this.setState({ isAlert: true, message: 'Please wait, we are checking your username', style: "danger" })
                        this.gotoTop()
                    }
                } else {
                    this.setState({ isAlert: true, message: 'Please provide a valid username ', style: "danger" })
                    this.gotoTop()
                }
            } else {
                this.setState({ isAlert: true, message: 'UserName should contain only a-z, 0-9 and Period symbol(.)', style: "danger" })
                this.gotoTop()
                this.resetTimeout()
                this.closeAlert()
            }

        } else {
            this.setState({ isAlert: true, message: 'Mandatory fields are missing', style: "danger" })
            this.gotoTop()
            this.resetTimeout()
            this.closeAlert()
        }

    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    userNameValidation = (value) => {
        const regext = /^([a-z0-9.]{4,})+$/
        if (regext.test(value)) {
            return true
        } else {
            return false
        }

    }
    checkUsernameVerification = () => {
        const { uName } = this.state
        if (this.props.usersList.data && this.props.usersList.data.userName) {
            if (uName === this.props.usersList.data.userName) {
                this.setState({
                    isValidUsername: true, isUserLoading: false, isUsernameError: false, isTyping: false
                })
            } else {
                let obj = {
                    userName: uName
                }
                this.setState({
                })
                if ((uName)) {
                    if (this.checkusername(uName)) {
                        if ((this.userNameValidation(uName))) {

                            this.setState({ isUserLoading: true, isValidUsername: false })

                            userServices.usernameVerification(obj).then(res => {
                                if ((res && res.statusCode) === 200) {
                                    this.setState({
                                        isValidUsername: true, isUserLoading: false, isUsernameError: false, isTyping: false
                                    })
                                } else {
                                    this.setState({
                                        isAlert: true,
                                        message: 'Unexpected error occured',
                                        style: "danger",
                                        isUserLoading: false,
                                        isUsernameError: '',
                                        isValidUsername: false,
                                        isUserTyping: false

                                    })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                }
                            }).catch(error => {
                                if ((error).toLowerCase().includes("user name is already taken")) {
                                    this.setState({
                                        isUsernameError: "Someone else is using above username,please try a different one",
                                        isValidUsername: false,
                                        isUserLoading: false,
                                        isUserTyping: false

                                    })
                                } else {
                                    this.setState({
                                        isAlert: true,
                                        message: error,
                                        style: "danger",
                                        isUserLoading: false,
                                        isUsernameError: '',
                                        isValidUsername: false,
                                        isUserTyping: false

                                    })
                                    this.gotoTop()
                                    this.resetTimeout()
                                    this.closeAlert()
                                }
                            })
                        } else {
                            this.setState({
                                isUsernameError: ""
                            })
                        }
                    } else {
                        this.setState({
                            isUsernameError: ""
                        })
                    }
                } else {
                    this.setState({
                        isUsernameError: ""
                    })
                }
            }
        }

    }
    gotoCreator = () => {
        if (this.props && this.props.usersList && this.props.usersList.data && this.props.usersList.data.userName) {
            if (this.state.roleId && this.state.roleId == 1) {
                history.push(`/creator/@${this.props.usersList.data.userName && encodeURIComponent(this.props.usersList.data.userName)}`, { obj: { ownuserName: this.props.usersList.data.userName } })
            } else if (this.state.roleId && this.state.roleId == 2) {
                history.push(`/collector/@${this.props.usersList.data.userName && encodeURIComponent(this.props.usersList.data.userName)}`)
            }
        } else {
            history.goBack()
        }

    }
    
    render() {

        return (

            <div className="">
                <Helmet>
                    <title>Profile Update</title>
                    <meta name="title" content={`Update profile`}></meta>
                    <meta name="description" content={`Update profile`}></meta>
                </Helmet>

                {this.state.isAlert && <div className={(this.state.style == "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style == "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>
                </div>}
                <div className="container mt-3">
                    <h1 className="mb-0">Profile

                        <button className="btn btn-primary-gradient float-right" onClick={this.onEditProfileClicked}>
                            Back
                        </button>
                    </h1>

                    <hr className="mb-1" />
                    <div className="coverimg coverbrd imgcovergif rounded-0">
                        {/* <div className="covernoimg no-images"></div> */}
                        <img src={this.state.banner ? this.state.banner : `${configPath.imageAssets}/cover-default.png`} alt="" />
                        <span className="editcov">
                            <span className="choosefile" title="Upload Cover Image">
                                <input type="file" className="custom-file-input inp-box" id="bannerPic" accept="image/png, image/jpeg, image/webp, image/gif" onChange={(e) => {
                                    this.onBannerPicChanged(e.target.files[0])
                                }} />
                                {/* {this.state.submitted && !this.state.banner && <div className="mandatory">{"Banner image is Required"}</div>} */}

                                <label htmlFor="bannerPic" className="btn btn-outline-dark w-auto px-3" >
                                    <i className="fas fa-pencil-alt"></i>
                                </label>
                            </span>
                        </span>
                    </div>
                    <div className="propagecont mb-5">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-lg-12 ">
                                    <div className="picpro">
                                        <span className="artcovimg ">
                                            <span className="artcov"></span>
                                            <span className="artcovimginset no-proimg">
                                                <img src={this.state.image ? this.state.image : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" />
                                            </span>

                                        </span>
                                        <span className="editpro">
                                            <span className="choosefile" title="Upload Profile Image">
                                                <input type="file" className="custom-file-input inp-box" id="profilePic" accept="image/png, image/jpeg, image/webp, image/gif" onChange={(e) => {
                                                    this.onProfilePicChanged(e.target.files[0])
                                                }} />
                                                {/* {this.state.submitted && !this.state.image && <div className="mandatory">{"Profile image is Required"}</div>} */}
                                                <label htmlFor="profilePic" className="btn btn-outline-dark w-auto px-3"><i className="fas fa-pencil-alt"></i></label>

                                            </span>
                                        </span>

                                    </div>
                                    <div className="row sizecls small">
                                        <div className="col-6">
                                            <span className="usernamelilac ">Profile image size :</span> 400px x 400px
                                        </div>
                                        <div className="col-6 text-right">
                                            <span className="usernamelilac ">Cover image size :</span> 1920px x 300px
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form>

                                <div className="mt-0 mt-md-5 pt-md-3 row">

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="form-group mb-4 col-md-4">
                                                <label className=" mb-2" htmlFor="uName">User name</label>
                                                <div className="input-group mb-3">
                                                    <div className="input-group-append ">
                                                        <span className="input-group-text bg-primary igt-custom" id="basic-addon2">@</span>
                                                    </div>
                                                    <input type="text" className="form-control" id="uName" aria-describedby="uName" placeholder="Enter user name" value={this.state.uName} onChange={this.onUserNameChange} />
                                                    {(this.state.isUserLoading) && <div className="icon-container">
                                                        <i className="userloader"></i>
                                                    </div>}
                                                </div>
                                                {this.state.uName && this.checkusername(this.state.uName) && this.userNameValidation(this.state.uName) && this.state.isValidUsername && <div className="mandatory-req"><span className="badge badge-olutline-primary"><i className="fa fa-check" aria-hidden="true"></i></span> Looks good and valid!</div>}
                                                {this.state.uName && this.state.isUsernameError && !this.state.isUserTyping && <div className="mandatory">{this.state.isUsernameError}</div>}
                                                {this.state.submitted && !this.state.uName && !this.state.isUsernameError && <div className="mandatory">Username is required</div>}
                                                {this.state.uName && !this.checkusername(this.state.uName) && !this.state.isUsernameError && <div className="mandatory">{"Min 4 - Max 50 characters"}</div>}
                                                {this.state.uName && this.checkusername(this.state.uName) && this.userNameValidation(this.state.uName) && !this.state.isUsernameError && !this.state.isValidUsername && <div className="mandatory-req">{"Looks good!"}</div>}
                                                {this.state.uName && !this.userNameValidation(this.state.uName) && this.checkusername(this.state.uName) && !this.state.isUsernameError && <div className="mandatory">Username should contain only a-z, 0-9 and Period symbol(.)</div>}                                            </div>
                                            <div className="form-group mb-4 col-md-4">
                                                <label className=" mb-2">Email</label>
                                                <div className="purchasebox p-3">
                                                    <div className="">{this.state.email}</div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4 col-md-4">
                                                <label htmlFor="fullName " className="">Full name</label>
                                                <input type="text" className="form-control" id="fullName" aria-describedby="fullName" placeholder="Enter full name" value={this.state.fullName} onChange={this.onFullNameChange} />
                                                {this.state.submitted && !this.state.fullName && <div className="mandatory">{"Fullname is Required"}</div>}
                                                {this.state.submitted && this.state.fullName && !this.checkFullName(this.state.fullName) && <div className="mandatory">{"Fullname must be at least 3 characters and atmost 110 characters"}</div>}
                                            </div>
                                            <div className="form-group mb-4 col-md-12">
                                                <label htmlFor="description" className="" >Bio</label>
                                                <textarea className="form-control" maxlength="6000" rows="6" id="description" aria-describedby="description" placeholder="Enter bio" value={this.state.description} onChange={this.onDescriptionChange}></textarea>
                                                {this.state.submitted && !this.state.description && <div className="mandatory">{"Bio is Required"}</div>}
                                                {this.state.submitted && this.state.description && !this.checkDesc(this.state.description) && <div className="mandatory">{"Bio must be at least 3 characters"}</div>}
                                                {this.state.submitted && this.state.description && !this.checkmaxDesc(this.state.description) && <div className="mandatory">{"Bio must be atmost 6000 characters"}</div>}
                                                {!this.state.submitted && this.state.description && !this.checkmaxDesc(this.state.description) && <div className="mandatory-req">{"Bio must be atmost 6000 characters"}</div>}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="from-group mb-3">
                                                    <label htmlFor="instagramProfile" className="" >Instagram</label>
                                                    <input type="text" className="form-control" id="instagramProfile" aria-describedby="instagramProfile" placeholder="Enter instagram profile link" value={this.state.instagramProfile} onChange={this.onInstagramLinkChange} />
                                                    <div className="usernamelilac small mt-1">Ex: https://www.instagram.com/disruptart</div>
                                                    {!this.state.isInstagramLinkValid && this.state.instagramProfile.length > 0 && <div className="mandatory">{"Instagram should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.instagramProfile && !this.checkName(this.state.instagramProfile) && <div className="mandatory">{"Instagram must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>

                                                <div className="from-group mb-3">
                                                    <label htmlFor="twiterProfile" className="">Twitter</label>
                                                    <input type="text" className="form-control" id="twiterProfile" aria-describedby="twiterProfile" placeholder="Enter twitter profile link" value={this.state.twiterProfile} onChange={this.onTwitterLinkChange} />
                                                    <div className="usernamelilac small mt-1">Ex: https://twitter.com/disruptart</div>
                                                    {!this.state.isTwitterLinkValid && this.state.twiterProfile.length > 0 && <div className="mandatory">{"Twitter should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.twiterProfile && !this.checkName(this.state.twiterProfile) && <div className="mandatory">{"Twitter must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>

                                                <div className="from-group mb-3">
                                                    <label htmlFor="discordProfile" className="">Discord</label>
                                                    <input type="text" className="form-control" id="discordProfile" aria-describedby="discordProfile" placeholder="Enter discord username" value={this.state.discordProfile} onChange={this.onDiscordLinkChange} />
                                                    <div className="usernamelilac small mt-1">Ex: kart#1234</div>
                                                    {/* {!this.state.isDiscordLinkValid && this.state.discordProfile.length > 0 && <div className="mandatory">{"Discord should be a valid username"}</div>} */}
                                                    {this.state.submitted && this.state.discordProfile && !this.checkDiscordName(this.state.discordProfile) && <div className="mandatory">{"Discord username must be at least 4 characters and atmost 120 characters"}</div>}
                                                    {/* {this.state.submitted && !this.state.discordProfile && <div className="mandatory">{"Discord username mandatory"}</div>} */}
                                                </div>
                                            </div>


                                            <div className="col-md-4">
                                                <div className="from-group mb-3">
                                                    <label htmlFor="websiteProfile" className="">Website</label>
                                                    <input type="text" className="form-control" id="websiteProfile" aria-describedby="websiteProfile" value={this.state.websiteProfile} onChange={this.onWebsiteLinkChange} />
                                                    <div className="usernamelilac small mt-1">Ex: https://disrupt.art</div>
                                                    {!this.state.isWebsiteLinkValid && this.state.websiteProfile.length > 0 && <div className="mandatory">{"Website should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.websiteProfile && !this.checkName(this.state.websiteProfile) && <div className="mandatory">{"Website must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="from-group mb-3">
                                                    <label htmlFor="addLink1" className="" >Additional Links</label>
                                                    <input type="text" className="form-control" id="addLink1" aria-describedby="addLink1" value={this.state.addLink1} onChange={this.onAddLink1Change} />
                                                    <div className="usernamelilac small mt-1">Ex: https://disrupt.art</div>
                                                    {!this.state.isAddLink1Valid && this.state.addLink1.length > 0 && <div className="mandatory">{"Should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.addLink1 && !this.checkName(this.state.addLink1) && <div className="mandatory">{"Link must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>
                                                <div className="from-group mb-3">
                                                    <input type="text" className="form-control" id="addLink2" aria-describedby="addLink2" value={this.state.addLink2} onChange={this.onAddLink2Change} />
                                                    <div className="usernamelilac small mt-1">Ex: https://disrupt.art</div>
                                                    {!this.state.isAddLink2Valid && this.state.addLink2.length > 0 && <div className="mandatory">{"Should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.addLink2 && !this.checkName(this.state.addLink1) && <div className="mandatory">{"Link must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>
                                                <div className="from-group">
                                                    <input type="text" className="form-control" id="addLink3" aria-describedby="addLink3" value={this.state.addLink3} onChange={this.onAddLink3Change} />
                                                    <div className="usernamelilac small mt-1">Ex: https://disrupt.art</div>
                                                    {!this.state.isAddLink3Valid && this.state.addLink3.length > 0 && <div className="mandatory">{"Should be a valid link"}</div>}
                                                    {this.state.submitted && this.state.addLink && !this.checkName(this.state.addLink1) && <div className="mandatory">{"Link must be at least 3 characters and atmost 120 characters"}</div>}
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                    <div className="text-center col-md-12">
                                        <hr />
                                        <button className="btn btn-primary px-4" onClick={this.handleSubmit}>Save</button>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usersList: state.user.usersList,
        isUpdate: state.user.isUpdate
    }
}

const actionCreators = {
    updateProfile: userAction.updateProfile,
    getUserProfile: userAction.getUsers,
    getUSD: userAction.getUSD

}

const connectProfileform = connect(mapStateToProps, actionCreators)(ProfileForm)

export { connectProfileform as ProfileForm }