import { combineReducers } from 'redux'

import { user } from './user.reducer'
import { art } from './art.reducer'
import { drop } from './drop.reducer'
import { creator } from './creator.reducer'
import { wallet } from './wallet.reducer'
import { collector } from './collector.reducer'
import { follow } from './follow.reducer'
import { discover } from './discover.reducer'
import { events } from './event.reducer'
import { airdrop } from './airdrop.reducer'
import { tags } from './tag.reducer'
import { announcement } from './announcement.reducer'
import { category } from './category.reducer'
import { mintslot } from './mint.reducer'
import { midwestcon} from './midwest.reducer'
import { creatorStore } from './storecreator.reducer'
import { joyrideReducer } from './joyride.reducer'
import { adminaccount } from './admin.reducer'
import { reportReducer } from './report.reducer'
import { flow } from './flow.reducer'
import { transactions } from './transaction.reducer'
const rootReducer = combineReducers({
    user,
    art,
    drop,
    creator,
    wallet,
    collector,
    follow,
    discover,
    events,
    airdrop,
    tags,
    announcement,
    category,
    mintslot,
    midwestcon,
    creatorStore,
    joyrideReducer,
    adminaccount,
    reportReducer,
    flow,
    transactions
})

export default rootReducer