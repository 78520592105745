import { configPath } from "config";
import { accessHeader } from "helpers"
import PubSub from 'pubsub-js'; 
import { fetchService } from "./fetch-service";
import history from 'helpers/history';

export const collectorServices = {
    detailCollector,
	getCollectors
}


function detailCollector(userId){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
		// body : obj
	}
	
	return fetchService.fetchResource(`${configPath.apiUrl}/collector-management/collector/${encodeURIComponent(userId)}/detail`,requestOptions).then(handleResponse,handleError)
}
function getCollectors(obj){
    const requestOptions = {
        method: 'GET',
        headers: {...accessHeader(),'Content-Type': 'application/json','accept' : 'application/json'},
    };
    let params = ''
	let offSet = obj && obj.offSet != null && obj.offSet !== undefined ? "&pageOffset=" + obj.offSet : ''

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

    let search = obj && obj.search ? '&search=' + obj.search : ''

    params =  offSet + recordLimit + sortBy + sortOrder + search

    return fetch(`${configPath.apiUrl}/collector-management/collector?` + params,requestOptions).then(handleResponse,handleError)
}


function handleResponse(response) {
    if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			}else if(response.status ===400){
				if (data.message && data.message.toLowerCase().includes('user not found')) {
					history.push('/notFound');
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
}

function handleError(err) {
    try {
        if (err instanceof TypeError) {
            PubSub.publish('msg', false)
            throw err
        }else{
            PubSub.publish('msg', false)

        }
    }
    catch(err) {
    console.log(err)
  
    }
}