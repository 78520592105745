import { artConstants } from "../../constants";

const initialState = {
    art: false,
    artsList: [],
    detailArt: [],
    notDrops: [],
    tokenArts: [],
    relativeArts: [],
    singleUserArts: [],
    userArtsList: [],
    failedArt: [],
    creatorArts: [],
    isCheckArt: false,
    isCheckUserArt: false,
    isCheckCreatorArt: false,
    isCheckCollect: false,
    allArts: [],
    ownerArt: [],
    isCheckAdmArt: false,
    categoryArts : [],
    isCheckCategoryArt : false,
    categoryDetail : [],
    thumbnailUpdated : [],
    uploadThumbnailFailed : [],
    artStatusChangedSuccess : [],
    artStatusChangedFailed : [],
    editedCategoryStatus : [],
    editcategoryFailed : [],
    editedTags : [],
    dapperlistedArtForsale : [],
    dapperlistedArtSaleFailed : '',
    walletRelatedArts : [],
    walletRelatedArtsFailed : '',
    walletAddDetail: [],
    walletAddDetailFailed: '',
    custodialArts: [],
    custodialArtsFailed: '',
    isCheckCustodialArt: false
}
export function art(state = initialState, action) {
    switch (action.type) {
        case artConstants.UPLOAD_ART:
            return {
                art: action.art
            }
        case artConstants.GET_ARTS:
            return {
                artsList: action.artsList,
                isCheckArt: action.isCheckArt,
            }
        case artConstants.GET_DETAIL_ART:
            return {
                detailArt: action.detailArt
            }
        case artConstants.GET_NOT_DROPS:
            return {
                notDrops: action.notDrops
            }
        case artConstants.GET_ART_BY_TOKEN:
            return {
                tokenArts: action.tokenArts
            }
        case artConstants.GET_REALATIVE_ARTS:
            return {
                relativeArts: action.relativeArts
            }
        case artConstants.GET_USER_ARTS:
            return {
                userArtsList: action.userArtsList,
                isCheckUserArt: action.isCheckUserArt
            }
        case artConstants.GET_ONE_USER_ARTS:
            return {
                singleUserArts: action.singleUserArts,
                isCheckCollect: action.isCheckCollect
            }
        case artConstants.FAILED_UPLOAD_ART:
            return {
                failedArt: action.failedArt
            }
        case artConstants.GET_CREATOR_ARTS:
            return {
                creatorArts: action.creatorArts,
                isCheckCreatorArt: action.isCheckCreatorArt
            }
        case artConstants.GET_ALL_ARTS:
            return {
                allArts: action.allArts,
                isCheckAdmArt: action.isCheckAdmArt
            }
        case artConstants.GET_OWNER_ARTS:
            return {
                ownerArt: action.ownerArt
            }
        case artConstants.GET_ART_FOR_TAG:
            return {
                tagArtsList: action.tagArtsList,
                isCheckTagArt: action.isCheckTagArt,
            }
        case artConstants.GET_CATEGORY_ARTS:
            return {
                ...state,
                categoryArts : action.categoryArts,
                isCheckCategoryArt : action.isCheckCategoryArt
            }
        case artConstants.GET_CATEGORY_DETAIL:
            return {
                ...state,
                categoryDetail : action.categoryDetail
            }
        case artConstants.EDIT_THUMBNAIL:
            return {
                ...state,
                thumbnailUpdated : action.thumbnailUpdated
            }
        case artConstants.EDIT_THUMBNAIL_FAILED:
            return {
                ...state,
                uploadThumbnailFailed : action.uploadThumbnailFailed
            }
        case artConstants.SHOW_HIDE_STATUS_ARTS_CHANGE:
            return {
                ...state,
                artStatusChangedSuccess : action.artStatusChangedSuccess
            }
        case artConstants.SHOW_HIDE_STATUS_CHANGE_FAILED:
            return {
                ...state,
                artStatusChangedFailed : action.artStatusChangedFailed
            }
        case artConstants.EDIT_CATEGORY:
            return {
                ...state,
                editedCategoryStatus : action.editedCategoryStatus
            }
        case artConstants.EDIT_CATEGORY_FAILED:
            return {
                ...state,
                editcategoryFailed : action.editcategoryFailed
            }
        case artConstants.EDIT_TAGS:
            return {
                ...state,
                editedTags : action.editedTags
            }
        case artConstants.EDIT_TAGS_FAILED:
            return {
                ...state,
                editedTagsFailed : action.editedTagsFailed
            }
        case artConstants.DAPPER_LIST_ARTS_FOR_SALE:
            return {
                ...state,
                dapperlistedArtForsale : action.dapperlistedArtForsale
            }
        case artConstants.DAPPER_LIST_ARTS_FOR_SALE_FAILED:
            return {
                ...state,
                dapperlistedArtSaleFailed : action.dapperlistedArtSaleFailed
            }
        case artConstants.GET_ARTS_FOR_RELATED_WALLET:
            return {
                ...state,
                walletRelatedArts : action.walletRelatedArts
            }
        case artConstants.GET_ARTS_FOR_RELATED_WALLET_FAILED:
            return {
                ...state,
                walletRelatedArtsFailed : action.walletRelatedArtsFailed
            }
        case artConstants.GET_WALLET_ADDRESS_DETAIL:
            return {
                ...state,
                walletAddDetail: action.walletAddDetail
            }
        case artConstants.GET_WALLET_ADDRESS_DETAIL_FAILED:
            return {
                ...state,
                walletAddDetailFailed: action.walletAddDetailFailed
            }
        case artConstants.GET_CUSTODIAL_ARTS:
            return {
                custodialArts: action.custodialArts,
                isCheckCustodialArt: action.isCheckCustodialArt,
            }
        case artConstants.GET_CUSTODIAL_ARTS_FAILED:
            return {
                custodialArtsFailed: action.custodialArtsFailed,
                isCheckCustodialArt: action.isCheckCustodialArt
            }
        default:
            return state
    }
}