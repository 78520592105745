import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import PubSub from 'pubsub-js';
import { adminServices, artServices, creatorServices } from 'services';
import moment from 'moment'
import { categoryActions } from 'store/actions';
import { toast } from 'react-toastify';
import { checkMintedTokens } from 'helpers';
import history from 'helpers/history';


class FetchArtDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
            isError: false,
            detail: {}
        }

    }
    componentDidMount() {
        this.callApi()
        this.props.getAllCategories()
    }


    callApi = () => {
        let userAddress = ""

        let id = decodeURIComponent(this.props.match.params.id).replace('@', '')

        this.setState({ isSale: true, isCheckCreator: false, isCheckCollect: false, })
        this.showLoader()
        adminServices.getArtDetail(id).then(res => {
            this.hideLoader()
            if ((res && res.statusCode) == 200) {
                this.setState({ detail: res && res.data && res.data.art, isShowLoader: false, isError: false })
            }
        }).catch(err => {
            if (err && err.includes("disabled")) {
                this.setState({ isError: true })
            }
            this.setState({ isShowLoader: false })
            this.hideLoader()
        })

    }
    static getDerivedStateFromProps(props, state) {


        if (props && props.allCategories && props.allCategories.statusCode === 200) {
            props.resetGetCategories()

            console.log(props.allCategories)
            if (props.allCategories.data && props.allCategories.data.tags) {
                return {
                    allCategories: props.allCategories.data.tags
                }
            }

        }

        // props.resetAllUsers()

    }
    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }

    handleCategoryChange = (e, item) => {
        const { allCategories } = this.state
        if ((item && item.id)) {
            if (e.target.value) {
                const selectedCategory = allCategories && allCategories.length > 0 && allCategories.filter(cat => (cat.name === e.target.value))
                console.log(e.target.value, item, selectedCategory)
                const obj = {
                    categoryId: selectedCategory && selectedCategory[0] && selectedCategory[0].id
                }
                this.showLoader()
                adminServices.updateArtCategory((item.id), JSON.stringify(obj)).then(res => {
                    if ((res && res.statusCode) === 200) {
                        toast.success("Category updated")
                        this.callApi()
                        this.hideLoader()

                    } else {
                        this.hideLoader()
                    }
                }).catch(error => {
                    toast.error(error)
                    this.hideLoader()
                })
            }
        }
    }
    gotoback = () => {
        // if((this.props && this.props.location && this.props.location.state && this.props.location.state.obj)){
        //     let obj = {
        //         obj :this.props.location.state.obj
        //     }
        //     history.push('/admin/artsList' ,{obj})
        // }else{
            history.push('/admin/artsList')
        // }
        
    }
    render() {
        const { detail, allCategories } = this.state
        return (

            <Fragment>

                <div className="container-fluid">
                    
                    <div className="mb-4 admintitle">
                    <div className='row'><h2 className="font-weight-bold mr-3">Art Detail</h2>
                    <button className='btn btn-primary' type='button' onClick={this.gotoback}>back</button>
                    </div>
                    </div>
                    {<div className="purchasebox p-2">
                        <div className="card">
                            <div className="row">

                                {(detail && detail.title) && <div className="col-md-4 mb-3">
                                    <label>Art name</label>
                                    <div className="comboxgray">
                                        {detail && detail.title}
                                    </div>
                                </div>}
                                {(detail && detail.editionCount) && <div className="col-md-4 mb-3">
                                    <label>Total Editions</label>
                                    <div className="comboxgray">
                                        {(checkMintedTokens((detail), (detail.editionCount)))}
                                    </div>
                                </div>}
                                {<div className="col-md-4 mb-3">
                                    <label>{(detail && detail.ownerUser && detail.ownerUser.userName) ? "Username" : "Owner address"}</label>
                                    <div className="comboxgray">
                                        {(detail && detail.ownerUser && detail.ownerUser.userName) ? `@${detail.ownerUser.userName}` 
                                        : (detail && detail.ownerWalletAddress) ? (detail.ownerWalletAddress) : `------`}
                                    </div>
                                </div>}
                                {(detail && detail.ownedEditionCount) ? <div className="col-md-4 mb-3">
                                    <label>Owned Editions</label>
                                    <div className="comboxgray">
                                        {(checkMintedTokens((detail), (detail.ownedEditionCount)))}
                                    </div>
                                </div> : ''}
                                {(allCategories && allCategories.length > 0) ? <div className="col-md-4 mb-3">
                                    <label>Categories</label>
                                    <div className="comboxgray">

                                        <select onChange={(e) => { this.handleCategoryChange(e, detail) }} value={(detail && detail.nftCategory && detail.nftCategory.name) ? detail.nftCategory.name : '-'} className="form-control ">
                                            <option value={'-'} disabled>Select</option>
                                            {allCategories.map(items => (
                                                <option value={items && items.name}>{items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)}</option>
                                            ))}
                                        </select></div></div> : <div className="comboxgray">No categories yet</div>}
                                {(detail && detail.tagArts && detail.tagArts.length > 0) && <div className="col-md-4 mb-3">
                                    <label>Tags</label>
                                    
                                    <div className="comboxgray">
                                    {detail && detail.tagArts && detail.tagArts.map((items) => (
                                        <a className="badge bg-dark mr-1 p-2">
                                        {items && items.tag && items.tag.name}</a>))}
                                        
                                    </div>
                                </div>}
                            </div>
                        </div>



                    </div>
                    }

                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allCategories: state.category.categories,
    }
}

const actionCreators = {
    getAllCategories: categoryActions.getCategories,
    resetGetCategories: categoryActions.resetGetCategories

}

const connectFetchArtDetail = connect(mapStateToProps, actionCreators)(FetchArtDetail)

export { connectFetchArtDetail as FetchArtDetail }