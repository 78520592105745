import React from "react";
import history from "helpers/history";
import { airdropActions, userAction } from "store/actions";
import { connect } from "react-redux";
import { Fragment } from "react";
import { getCurrentUserId, isConnectWallet, isWebLogin, localArtPage, localDropPage } from 'helpers'
import { configPath } from "../config";
import { Helmet } from "react-helmet";
import moment from 'moment'
import { airDropService, artServices, fetchService, walletServices } from "services";
import PubSub from 'pubsub-js';
import { Link } from 'react-router-dom';
import Toggle from 'react-toggle';
import { dayClosed } from "helpers/datetimecheck";
import { getCreatorImage, getCreatorName } from "./artdetail-view/bab-common-view";
import { ArtViewPreview } from "./art-preview/artview-preview";
import { InvalidPage } from "./Common-error-ui/Invalidpag";
import { WithdrawPopup } from "./withdrawPopup";
import { getUserAddress } from "utils";
import Modal from 'react-modal'
import { AirDropStatus } from "helpers/enums";
import CommonError from "./Common-error-ui/commonError";


var timeout;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
class AirDropDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailDropList: {},
            isAlert: false,
            notClaimedUsers: [],
            isAirDropSuccess: false,
            status: false,
            failedObj: {},
            isReminderFailed: false,
            isInvalidPage: false,
            withdrawPopup: false, // withdraw popup
            isWithdrawSwitchAcc: false, // wallet switch popuup,
            isForWithdrawArt: false,
            profileData: {},
            isCalled: false,
            isErrorPoped: false,
            isImage: false,
            isOpenHidePopup: false,
            visibleStatus: false,
            artNotfound: false,
            disabledMessage: "",
            isAlertOff: true
        }
        this.scrollRef = React.createRef()
        this.limitedAirdropCount = 10
    }
    componentDidMount() {
        this.scrollRef.current && this.scrollRef.current.scrollIntoView()
        localArtPage()
        localDropPage()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        localStorage.setItem('page', JSON.stringify(0));
        this.getAirdropDetails()

    }

    getAirdropDetails = () => {
        if (isWebLogin()) {

            if ((this.props && this.props.match && this.props.match.params && this.props.match.params.id)) {
                const id = this.props.match.params.id
                this.showLoader()
                airDropService.getAirDropDetailUser(id).then(res => {
                    this.hideLoader()
                    if ((res && res.statusCode) == 200) {
                        //all selected Users
                        const selectedArray = res && res.data && res.data.airDropSelectedUsers
                        //disabled Users
                        const disabledUsersCount = selectedArray && selectedArray.filter(el => (el.status == 0)).length
                        //current enabled users
                        const enabledUsersCount = selectedArray && selectedArray.filter(el => (el.status == 1)).length
                        //filter registered users with enabled  -> all registered users
                        const usersname = selectedArray && selectedArray.filter(arr => {
                            if ((arr.user) != null && (arr.status === 1)) {
                                return true
                            } else {
                                return false
                            }
                        }
                        ).map(users => {
                            return users.user
                        })
                        //filter unregistered email with enabled
                        const unregisteredEmail = selectedArray && selectedArray.filter(arr => {
                            if ((arr.unregisteredEmail) != null && (arr.status === 1)) {
                                return true
                            } else {
                                return false
                            }
                        }
                        ).map(users => {
                            return users.unregisteredEmail
                        })

                        //all claim detail array
                        const detailArray = res && res.data && res.data.airDropDetails

                        //create object for airdrop(cliam/yet to claim) detail table
                        let airdropDetailArray = []


                        const tableRows = []
                        for (const user of selectedArray) {
                            if (user && user.status) {
                                const claimedDetail = detailArray.find((detail) => user.userId === detail.userId);
                                let obj = {}
                                if (claimedDetail !== null && claimedDetail !== undefined) {
                                    obj = {
                                        id: claimedDetail.userId,
                                        user: claimedDetail.user,
                                        updatedAt: claimedDetail.updatedAt,
                                        status: 1,
                                        claimed: true,

                                    }
                                    airdropDetailArray.push(obj)
                                }
                                else {
                                    if (user && user.user && user.userId && user.status) {

                                        obj = {
                                            id: user.userId,
                                            user: user.user,
                                            status: user.status,
                                            claimed: false,
                                            withdrawn: user.withdrawn
                                        }
                                        airdropDetailArray.push(obj)
                                    }

                                    ;
                                }
                            }

                        }


                        //filter claimed users
                        const airdropsUsers = detailArray && detailArray.filter(arr =>
                            arr.user != null
                        ).map(users => {
                            return users.user
                        })
                        const airdropsEmails = detailArray && detailArray.filter(arr => {
                            if ((arr.unregisteredEmail) != null) {
                                return true
                            } else {
                                return false
                            }
                        }
                        ).map(users => {
                            return users.unregisteredEmail
                        })
                        //filter unclaimed users
                        const notClaimedUsers = usersname.filter((arr) => !airdropsUsers.find(arr1 => arr.id === arr1.id))
                        //filter unclaimed emails
                        const notClaimedEmails = unregisteredEmail.filter((arr) => !airdropsUsers.find(arr1 => ((arr).toLowerCase()) === ((arr1 && arr1.email).toLowerCase())))

                        //unclaimed usernames
                        var notClaimed = notClaimedUsers.map(el => el.userName)

                        if (notClaimedEmails.length > 0) {
                            notClaimedEmails.map(items => {
                                notClaimed.push(items)

                            })
                        }
                        let tokens = res.data && res.data.totalTokens //total listed tokens
                        let tokensClaimed = res.data && res.data.totalTokensClaimed // claimed tokens count
                        const notClaimedPersons = (tokens - tokensClaimed) //unclaimed persons count
                        this.setState({
                            detailDropList: res.data ? res.data : [],
                            notClaimedUsers,
                            notClaimedEmails,
                            notClaimedPersons,
                            notClaimed,
                            airdropDetailArray,
                            selectedMembers: '',
                            disabledUsersCount,
                            enabledUsersCount
                        })
                        if (res && res.data && res.data.airDropDetails && res.data.airDropDetails[0] && res.data.airDropDetails[0].token && res.data.airDropDetails[0].token.id) {
                            artServices.detailArt(res.data.airDropDetails[0].token.id)
                                .then((art) => {
                                    if ((art && art.statusCode) === 200) {
                                        this.setState({
                                            artDetail: art.data,
                                            artNotfound: false,
                                        });
                                        this.fetchArt(art.data && art.data.thumbnailHash);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        isAirDrop: false,
                                        message: error,
                                        style: "danger",
                                    });
                                    if (
                                        error &&
                                        error.toLowerCase().includes("has been disabled by the administrator")
                                    ) {
                                        this.setState({
                                            isAlert: false,
                                            artNotfound: true,
                                            disabledMessage: error,
                                        });
                                    } else if (
                                        error &&
                                        error.toLowerCase().includes("must be a number string")
                                    ) {
                                        this.setState({
                                            isInvalidPage: true
                                        });
                                    } else {
                                        this.setState({
                                            isAlert: true,
                                            artNotfound: false,
                                        });
                                    }
                                    this.gotoTop();
                                });

                        }

                        if (this.isRestrictedType(res.data)) {
                            this.setState({ disableList: true })
                        }

                    }

                }).catch(err => {
                    if (err && err.toLowerCase().includes('must be a number string')) {
                        this.setState({
                            isInvalidPage: true
                        })
                    } else if (err && err.toLowerCase().includes('missing')) {
                        this.setState({
                            isInvalidPage: true
                        })
                    } else {
                        this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: err, style: "danger" })
                        this.gotoTop();
                        this.hideLoader()
                    }
                })
            }
        } else {
            history.push('/login')
        }


    }


    showLoader() {
        PubSub.publish('msg', true);
    }

    hideLoader() {
        PubSub.publish('msg', false);
    }
    closeAlert = () => {


        timeout = setTimeout(() => {

            this.setState({ isAlert: false, message: '', style: "" })
        }, 15000)
    }
    resetTimeout = () => {
        clearTimeout(timeout)
    }
    hideAlert = () => {
        this.setState({ isAlert: false, message: '', style: '' })
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }





    closeModal = () => {
        this.setState({
            isFollower: true
        })
    }


    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }

    gotoDetail = (items) => {

        if (
            (items && items.art && items.art.id)
        ) {
            history.push(`/art/${items.art.id}`)
        }

    }
    getListUser(user) {
        if (user) {
            if ((user.id != null) && (user.roleId == 1)) {
                return (<Link className="usernamelilac" to={{ pathname: `/creator/@${user.userName && encodeURIComponent(user.userName)}`, state: { obj: { userName: user && user.userName } } }} >{((user.userName && user.userName.includes('@')) ? user.userName : (`@${user.userName}`))}</Link>);
            }
            return <Link className="usernamelilac" to={`/collector/@${user.userName && encodeURIComponent(user.userName)}`}>{((user.userName && user.userName.includes('@')) ? user.userName : (`@${user.userName}`))}</Link>

        }
    }
    checkIsEmail = (value) => {
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((emailReg.test(value))) {
            return true
        } else {
            return false
        }
    }
    usernameValidation = (value) => {
        let isValide = true
        const array = value.split(',')
        const regext = /^([a-zA-Z0-9.]{4,})+$/
        var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < array.length; i++) {
            // if((array[i]) !== ""){
            if ((!(emailReg.test(array[i]))) &&
                (!(regext.test(array[i])))
            ) {
                isValide = false;
            }
            // }
        }
        return isValide;

    }
    handleSelectedMembers = (e) => {

        const { airdropDetailArray, enabledUsersCount, detailDropList } = this.state
        var regex = /[,]+$/;
        var count = airdropDetailArray.filter(function (s) { return s.status === 0 }).length;
        const changedUsersLength = this.state.selectedMembers.split(',').length
        const changedUsers = this.state.selectedMembers.split(',')
        const disabledCount = Number(((detailDropList && detailDropList.totalTokens)) - enabledUsersCount)
        const totaldisabledCount = Number(disabledCount + count)


        // if (changedUsersLength <= (totaldisabledCount)) {

        this.setState({
            selectedMembers: (e.target.value).toLowerCase(),
        }, () => {
            if (this.state.selectedMembers.split(',')[totaldisabledCount]) {
                this.setState({
                    selectedMembers: (e.target.value).toLowerCase().slice(0, -1)
                })
            }
        })

        // } else {
        //     this.setState({
        //         selectedMembers: (this.state.selectedMembers).toLowerCase().slice(0, -1)
        //     })
        //     return false
        // }


    }
    handleAirDrop = (detail) => {
        const { notClaimedPersons, notClaimedUsers, notClaimedEmails, detailDropList, enabledUsersCount, selectedMembers, airdropDetailArray } = this.state
        const notClaimedUsersLength = Number(notClaimedUsers.length + notClaimedEmails.length)
        const changedUsers = selectedMembers.split(',')
        const users = changedUsers.filter(el => el != "")

        const changedUsersLength = users.length

        var count = airdropDetailArray.filter(function (s) { return s.status === 0 }).length;
        const disabledCount = Number(((detailDropList && detailDropList.totalTokens)) - enabledUsersCount)
        const totaldisabledCount = Number(disabledCount + count)
        var disabledUsers = airdropDetailArray.filter(function (s) { return s.status === 0 });
        let usersToRemove = []
        disabledUsers && disabledUsers.map(items => {
            usersToRemove.push(items.userName)
        })
        //if won't disabled anything but you edition > aasinged user count
        if (usersToRemove && usersToRemove.length === 0) {

            let addUser = {
                airDropId: detailDropList && detailDropList.id,
                usersToAdd: changedUsers,
                isAlertOff: (this.state.isAlertOff ? "0" : "1")
            }
            if (changedUsersLength > 0) {
                if ((changedUsersLength > totaldisabledCount)) {
                    this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'No.of editions listed and selected members must be equal', style: "danger", count: '' })
                    this.gotoTop();
                }
                else if ((this.state.selectedMembers === "")) {
                    this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter new username', style: "danger", count: '' })
                    this.gotoTop();
                } else if (
                    (this.state.selectedMembers) && (this.state.selectedMembers !== '') && !(this.usernameValidation(this.state.selectedMembers))) {
                    this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter proper username', style: "danger", count: '' })
                    this.gotoTop();
                } else {
                    this.showLoader()

                    airDropService.usersaddtoairdrops(addUser).then(addUser => {
                        this.hideLoader()

                        if ((addUser && addUser.statusCode) == 200) {
                            this.setState({ isAirDropSuccess: true })
                        }
                    }).catch(error => {
                        this.hideLoader()
                        this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: error, style: "danger" })
                        this.gotoTop();
                        console.log(error)
                    })
                }
            } else {
                this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: "No changes to save", style: "success" })
                this.gotoTop();
            }


        } else {
            if ((totaldisabledCount === changedUsersLength)) {
                if (totaldisabledCount > 0) {
                    if ((this.state.selectedMembers === "")) {
                        this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter new username', style: "danger", count: '' })
                        this.gotoTop();
                    } else if (
                        (this.state.selectedMembers) && (this.state.selectedMembers !== '') && !(this.usernameValidation(this.state.selectedMembers))) {
                        this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'Please enter proper username', style: "danger", count: '' })
                        this.gotoTop();
                    } else {
                        let obj = {
                            airDropId: detailDropList && detailDropList.id,
                            usersToRemove
                        }
                        let addUser = {
                            airDropId: detailDropList && detailDropList.id,
                            usersToAdd: changedUsers,
                            isAlertOff: (this.state.isAlertOff ? "0" : "1")

                        }
                        if (usersToRemove && usersToRemove.length > 0) {
                            this.showLoader()
                            airDropService.userstoremovefromairdrops(obj).then(res => {
                                this.hideLoader()
                                if ((res && res.statusCode) === 200) {
                                    this.showLoader()
                                    airDropService.usersaddtoairdrops(addUser).then(addUser => {
                                        if ((addUser && addUser.statusCode) == 200) {

                                            this.setState({ isAirDropSuccess: true })
                                        }
                                        this.hideLoader()
                                    }).catch(error => {
                                        this.hideLoader()
                                        this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: error, style: "danger" })
                                        this.gotoTop();
                                        console.log(error)
                                    })
                                }
                            }).catch(error => {
                                this.hideLoader()
                                this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: error, style: "danger" })
                                this.gotoTop();
                                console.log(error)
                            })

                        } else {
                            if (changedUsersLength > 0) {
                                airDropService.usersaddtoairdrops(addUser).then(addUser => {
                                    if ((addUser && addUser.statusCode) == 200) {

                                        this.setState({ isAirDropSuccess: true })
                                    }
                                    this.hideLoader()
                                }).catch(error => {
                                    this.hideLoader()
                                    this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: error, style: "danger" })
                                    this.gotoTop();
                                    console.log(error)
                                })
                            }
                        }


                    }
                }
                else {
                    this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: "You won't disabled any users", style: "danger" })
                    this.gotoTop();
                }



            } else {
                this.setState({ disableList: false, isAirDrop: false, isAlert: true, message: 'No.of editions listed and selected members must be equal', style: "danger", count: '' })
                this.gotoTop();
            }
        }

    }
    // redirectToAirDrops = () => {
    //     localStorage.setItem('page', "0")
    //     history.push('/airdrops')
    // }
    statusChange = (items, idx, user) => {
        const { airdropDetailArray } = this.state
        const checkDisabled = airdropDetailArray && airdropDetailArray.some(user =>
            user.status === 0
        )
        const status = items.status ? 0 : 1
        if (status) {
            this.setState({ selectedMembers: '' })
        }
        airdropDetailArray[idx].status = status
        airdropDetailArray[idx].userName = user
        this.setState({
            airdropDetailArray
        })


    }
    checkUsersDisabled = () => {
        const { detailDropList, airdropDetailArray, enabledUsersCount, notClaimedPersons } = this.state
        const checkDisabled = airdropDetailArray && airdropDetailArray.some(user =>
            user.status === 0
        )
        if (((detailDropList && detailDropList.totalTokens) > (enabledUsersCount)) || (checkDisabled)) {
            return true
        } else {
            return false
        }
    }
    checkCurrentUsers = () => {
        const { detailDropList } = this.state
        const localUser = localStorage.getItem('webLogin')
        const localUserDetail = JSON.parse(localUser)
        if ((((localUserDetail && localUserDetail.data && localUserDetail.data.authUser && localUserDetail.data.authUser.id) == (detailDropList && detailDropList.user && detailDropList.user && detailDropList.user.id)))) {
            return true
        } else {
            return false
        }
    }
    componentDidUpdate(props, state) {
        if ((this.props && this.props.aidropReminderRes && this.props.aidropReminderRes.statusCode === 200) && (props.aidropReminderRes !== (this.props.aidropReminderRes))) {
            props.resetairdropreminder()
            this.setState({ isAirDrop: false, isAlert: true, message: "Reminder sent successfully", style: "success" })
            this.gotoTop();
        }
        if ((this.props && this.props.airdropReminderFailed && this.props.airdropReminderFailed.nextEligibileDate && this.props.airdropReminderFailed.message) && (props.airdropReminderFailed) !== (this.props.airdropReminderFailed)) {
            props.resetairdropreminderfailed()
            this.setState({
                failedObj: this.props.airdropReminderFailed,
                isReminderFailed: true
            })

        }
        if ((this.props && this.props.airdropReminderFailed && this.props.airdropReminderFailed.length > 0) && (props.airdropReminderFailed) !== (this.props.airdropReminderFailed)) {
            props.resetairdropreminderfailed()
            this.setState({ isAirDrop: false, isAlert: true, message: this.props.airdropReminderFailed, style: "danger" })
            this.gotoTop();
        }
        if ((this.props && this.props.usersList && this.props.usersList.statusCode === 200) && (this.props.usersList.data !== state.profileData)) {
            this.setState({ profileData: this.props.usersList.data })
        }

    }
    airdropReminder = (e, list) => {
        e.preventDefault()
        if ((list && list.id)) {
            this.props.airdropreminder(list.id)
        }
    }
    gotoTagView = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/art/tag/${encodedTagName}`)
    }
    gotoCategoryPaga = (item) => {
        const encodedTagName = `${encodeURIComponent(item.name)}`;
        history.push(`/arts/category/${encodedTagName}`)
    }
    closePopup = () => {
        this.setState({
            isReminderFailed: false,
            failedObj: {}
        })
    }
    isArtisMusicCategory = (item) => {
        if (item && item.id) {
            if (item && item.nftCategory !== null && item.nftCategory !== undefined && item.nftCategory.name === "music") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    closeWithdrawPopup = () => {
        this.setState({ withdrawPopup: false })
    }
    openWithdraw = () => {
        this.setState({ withdrawPopup: true })
    }
    handleErrorpopup = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "danger",
            isAirDrop: false
        })
        this.gotoTop()
        this.closeWithdrawPopup()
    }
    handleSuccessPopup = (message) => {
        this.setState({
            isAlert: true,
            message: message,
            style: "success",
        })
        this.gotoTop()
        this.closeWithdrawPopup()
    }
    isValidToWithdraw = () => {
        const { notClaimedPersons, detailDropList, profileData } = this.state

        if (this.props.usersList && this.props.usersList.statusCode === 200) {
            if ((notClaimedPersons !== 0)
                && ((detailDropList && detailDropList.user && detailDropList.user.userName) === (profileData && profileData.userName))
                && (detailDropList.status !== AirDropStatus.DONE) && (detailDropList.status !== AirDropStatus.WITHDRAWN)) {
                return true
            } else {
                return false
            }
        } else {
            if (this.state.isCalled === false) {
                this.props.getUserProfile()
                this.setState({ isCalled: true })
            }

        }

    }


    hideAirdrop = async (detail) => {
        const { detailDropList } = this.state
        const airdropId = detailDropList.id
        const status = detailDropList.status ? true : false
        try {
            this.showLoader()

            let obj = {
                isHidden: status
            }
            const hiddenRes = await airDropService.hideOrVisibleAirdrop(airdropId, JSON.stringify(obj))
            if (hiddenRes && hiddenRes.statusCode === 200) {
                this.hideLoader()
                this.setState({ isHidden: true, visibleStatus: status })

            } else {
                this.hideLoader()
                this.setState({ isErrorPoped: true, cadencErrorMessage: String(hiddenRes) })
            }

        } catch (e) {
            this.setState({ isErrorPoped: true })
            this.hideLoader()

            if (e && typeof e === 'string' && e.toLowerCase().includes('art is not available for')) {
                this.setState({ cadencErrorMessage: String('Art is not available. Please refresh and try again later') })

            } else if (e && e.data && e.data.Error) {

                this.setState({ cadencErrorMessage: String(e.data.Error) })

            } else if (e && e.Error) {

                this.setState({ cadencErrorMessage: String(e.Error) })

            } else if (e && e.message) {

                this.setState({ cadencErrorMessage: String(e.message) })

            } else {
                console.log(e)
                if (e && typeof e === 'string' && e.toLowerCase().includes('airdrop has been hidden by admin')) {
                    this.setState({ cadencErrorMessage: (<p>This Airdrop has been hidden by administrator. To unhide this airdrop, we kindly request you to contact the <Link to="/contact" className="usernamelilac">Disrupt admin</Link></p>) })

                } else {
                    this.setState({ cadencErrorMessage: String(e) })
                }


            }
        }






    }
    closeSuccessModal = () => {
        this.setState({ isAirDropSuccess: false });
        this.getAirdropDetails()
    }
    closeErrorPopup = (e) => {
        e.preventDefault();
        this.setState({ isErrorPoped: false });
    }
    fetchArt(url) {
        fetchService.getBlob(url).then((blob) => {

            if (blob.type.match(/image.*/)) {
                return this.setState({ isImage: true })
            } else {
                return this.setState({ isImage: false })
            }
        }).catch((err) => {

        })

    }
    resetPage = () => {
        localStorage.setItem('page', "0")
    }
    gotoContactPage = () => {
        history.push('/contact')
    }
    closeUserUpdatedPopup = () => {
        this.getAirdropDetails()
        this.setState({ isAirDropSuccess: false })
    }

    isRestrictedType = (detailDropList) => {
        if (detailDropList && (detailDropList.totalTokens > this.limitedAirdropCount) && (detailDropList.airDropType === airDropService.airDropType.SELECTED_USERS)) {
            return true
        } else {
            return false
        }
    }
    handleSendAlertToUsers = () => {
        this.setState({
            isAlertOff : !this.state.isAlertOff
        })
    }
    render() {
        if ((this.state.isAlert)) {
            this.resetTimeout()
            this.closeAlert()
        }
        this.checkUsersDisabled()
        const localUser = localStorage.getItem('webLogin')
        const localUserDetail = JSON.parse(localUser)

        const { detailDropList, artDetail, notClaimed, airdropDetailArray, notClaimedPersons, enabledUsersCount, disabledUsersCount, isInvalidPage, visibleStatus, artNotfound,
            disabledMessage } = this.state


        return (
            <Fragment>
                <Helmet>
                    <title>Air Drops</title>
                    <meta name="title" content="Explore Latest NFT Drops"></meta>
                    <meta name="description" content={`Explore latest NFT drops on ${process.env.REACT_APP_SITE_CONTRACT} NFT marketplace`}></meta>
                </Helmet>
                {this.state.isAlert && <div className={(this.state.style === "danger") ? "alert alert-custom text-center alert-danger alertheight" : (this.state.style === "success") ? "alert alert-custom text-center alert-success alertheight" : ''} id="parent" >
                    <div className="d-flex flex-row align-items-center h-100 w-100">
                        <span className="artmsg">{this.state.message}</span>
                        <span className="closebtn" onClick={this.hideAlert}>&times;</span>

                    </div>

                </div>}
                {this.state.isAirDropSuccess &&
                    <div className="show-success">
                        <div className="success-pop">
                            {<img src={artDetail && artDetail.thumbnailHash} width="170px" alt="" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Woohoo..! Users Updated Successfully ! Good luck!</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your listed art to appear in Air drops :)</div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.closeSuccessModal() }}>Close</button></div></div>

                }
                {this.state.isReminderFailed &&
                    <div className="show-success">
                        <div className="success-pop">
                            {<img src={artDetail && artDetail.thumbnailHash} width="170px" alt="" className="mb-3" />}
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" style={{ width: "40px", height: "40px" }}><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            {this.state.failedObj && this.state.failedObj.message ? <div className="mt-3"><p>{this.state.failedObj.message}</p><p>Last reminder sent on - {this.state.failedObj.lastNotified && moment(this.state.failedObj.lastNotified).format('DD MMM,YYYY [at] hh:mm:ss A')}</p><p>Next reminder can be sent on - {this.state.failedObj.nextEligibileDate && moment(this.state.failedObj.nextEligibileDate).format('DD MMM,YYYY [at] hh:mm:ss A')}</p></div> : ''}

                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { this.closePopup() }}>Close</button></div></div>

                }
                {this.state.withdrawPopup ?
                    <WithdrawPopup
                        airdropDetail={detailDropList}
                        availableCount={notClaimedPersons}
                        successPopup={(val) => this.handleSuccessPopup(val)}
                        errorPopup={(val) => this.handleErrorpopup(val)}
                        artDetail={artDetail}
                        closeWithdrawMainPopup={this.closeWithdrawPopup}
                    />
                    : ''
                }
                {this.state.isErrorPoped &&
                    <div className="show-cancel">
                        <div className="success-pop pop-size">
                            <svg className="crossmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
                                <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                                <path className="cross__path cross__path--left" fill="none" d="M16,36 l20,-20" />
                            </svg>
                            <div className="py-3">{this.state.cadencErrorMessage}</div>

                            <div><button type="button" className="btn btn-primary px-5" onClick={(e) => { this.closeErrorPopup(e) }}>Close</button></div></div></div>
                }
                {this.state.isHidden &&
                    <div className="show-success">
                        <div className="success-pop">
                            {this.state.isImage && <img src={artDetail && artDetail.thumbnailHash} width="170px" />}
                            <svg className="checkmark mt-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <div className="mt-3"><b>Airdrop visibility status updated successfully</b></div>
                            {/* <div className="mt-3">It may take 30 seconds for your changes to appear in collections</div> */}
                            <button type="button" className="btn btn-primary px-5 mt-5" onClick={(e) => { e.preventDefault(); history.push('/wallet'); this.resetPage() }}>Go To Collections</button></div></div>
                }


                <div>
                    {artNotfound ? (
                        <CommonError disabledMessage={disabledMessage} />
                    ) : (
                        <>
                            {!isInvalidPage ? (
                                <div ref={this.scrollRef}>
                                    <div className="propagecont mb-5">

                                        <div className="detasingimg ">
                                            <div className="container">
                                                <span className="detaimg text-center">
                                                    <ArtViewPreview src={artDetail && artDetail.imageHash ? artDetail && artDetail.imageHash : `${configPath.imageAssets}/art-3.jpg`} className="mw-100" showControls={true} isDetail={true} isPremiumArt={artDetail && artDetail.isPremiumArt} mimeType={artDetail && artDetail.imageMimeType} sharedData={artDetail} />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="container">
                                            <div className="detailcontpa">
                                                <div className="row">

                                                    <div className="col-6">
                                                        {/* {(this.isArtisMusicCategory(artDetail)) ? '' :  */}
                                                        <div className="badgeview propicdetail">
                                                            <div className="picpro">
                                                                <span className="artcovimg">
                                                                    <span className="artcov "></span>
                                                                    <span className="artcovimginset no-proimg ">
                                                                        {getCreatorImage(artDetail)}
                                                                    </span>
                                                                </span>

                                                            </div>
                                                            {getCreatorName(artDetail)}
                                                        </div>
                                                        {/* } */}
                                                    </div>

                                                    <div className="col-6">

                                                        {<div className={(!dayClosed(detailDropList && detailDropList.airDropDate)) ? "badgeview sharedrop disable-reminder" : "badgeview sharedrop"} title={(detailDropList && detailDropList.airDropType === 1) ? "Click here to send email reminder for unclaimed recipients" : "Click here to send email reminder for your followers"} >
                                                            <div className="dropdown">
                                                                <h2 className="dropdown-toggle usernameClick" onClick={(e) => this.airdropReminder(e, detailDropList)}  >
                                                                    <i className="fas fa-bell bellcus"></i> Reminder
                                                                </h2>

                                                            </div>

                                                        </div>}
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="dettittxt mb-3"><h2 className="py-0 ">{detailDropList && detailDropList.artTitle ? detailDropList.artTitle : ''} &nbsp;<span className="ownerDec" onClick={() => this.gotoCreator(detailDropList && detailDropList.creator)}>{detailDropList && detailDropList.creator && detailDropList.creator.fullName && `by ${detailDropList.creator.fullName}`}</span></h2>

                                                        </div>
                                                        <div className="dettittxt mb-3">
                                                            <h4 className="py-0 ">{artDetail && artDetail.description}</h4>
                                                        </div>
                                                        {(artDetail && artDetail.tags && artDetail.tags.length > 0) ? <div className="row mb-3">
                                                            <div className="col-md-12">
                                                                <div className="probtns activebtnscus py-0 custagbadge">
                                                                    <ul className="list-unstyled mb-0 ">
                                                                        {artDetail && artDetail.tags && artDetail.tags.map((items) => (

                                                                            <li>

                                                                                <button type="button" className={(this.state.selectedTag === (items && items.name)) ? "badge badge-dark btn-sm active cushashtag" : 'badge badge-dark badge-sm cushashtag'} onClick={() => { this.gotoTagView(items) }}><i className="fa fa-hashtag" aria-hidden="true"></i>{items && items.name}</button>
                                                                            </li>

                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                        {(artDetail && artDetail.nftCategory) ? <div className='mb-3'>
                                                            <h4 className='category-btn' onClick={() => this.gotoCategoryPaga(artDetail.nftCategory)}><i className={artDetail && artDetail.nftCategory && artDetail.nftCategory.iconClass} aria-hidden="true"></i>&nbsp; {artDetail && artDetail.nftCategory && artDetail.nftCategory.name && artDetail.nftCategory.name.charAt(0).toUpperCase() + artDetail.nftCategory.name.slice(1)}</h4>
                                                        </div> : ''}


                                                        <div className="offset-md-3 mb-3">

                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-8 mb-3">
                                                                <a target="blank" href={artDetail && artDetail.imageHash && artDetail && artDetail.imageHash} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                                    <span className="texticon"> View On IPFS</span>
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-8 mb-3">
                                                                <a target="blank" href={artDetail && artDetail.metadataJsonUrl && artDetail && artDetail.metadataJsonUrl} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                                    <span className="texticon"> View Metadata</span>
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-sm-8 mb-3">
                                                                <a target="blank" href={artDetail && artDetail.flowScanURL && artDetail.flowScanURL} className="btn btn-outline-dark btn-block text-left d-flex flex-row justify-content-between align-items-center">
                                                                    <span className="texticon"> View on Flow Scan</span>
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </a>
                                                            </div>
                                                        </div>



                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="purchasebox">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h5 className="mb-3">Listed Editions</h5>
                                                                    <h3 className=" usernamelilac">{((detailDropList && detailDropList.totalTokens) ? (detailDropList.totalTokens) : '0') + (((detailDropList && detailDropList.totalTokens) > 1) ? " Editions" : " Edition")}</h3>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h5 className="mb-3">Claimed Editions</h5>
                                                                    <h3 className=" usernamelilac">{((detailDropList && detailDropList.totalTokensClaimed) ? (detailDropList.totalTokensClaimed) + (((detailDropList && detailDropList.totalTokensClaimed) > 1) ? " Editions" : " Edition") : '-')}</h3>
                                                                </div>
                                                                {(this.isValidToWithdraw()) ? <div className="col-md-12">
                                                                    <div className="dacustomdeta">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-md-6">
                                                                                {<span className='hideshowart'>
                                                                                    <a className={'card flowtop'}><Toggle checked={detailDropList.status} onChange={this.hideAirdrop} />
                                                                                        {<span className="copycon">
                                                                                            <span className="uparrow"></span>
                                                                                            {(detailDropList.status) ? ("Hide Airdrop") : ("Show Airdrop")}
                                                                                        </span>} </a>
                                                                                    <span className='ml-2 small usernamelilac span-cursor'>{(detailDropList.status) ? `[Visible]` : '[Hidden]'}</span></span>}
                                                                                {(detailDropList.hiddenByAdmin) ?
                                                                                    <h6 className="small m-0">This airdrop has been hidden by admin.*</h6>
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <button type="button" className="btn btn-primary mt-3 btn-sm mr-3"
                                                                                    disabled={this.state.disableWithdraw}
                                                                                    onClick={this.openWithdraw} >Withdraw Airdrop</button>


                                                                            </div></div></div></div> : ''}

                                                            </div>
                                                        </div>
                                                        <div className="purchasebox">
                                                            <div className={'row justify-content-center align-items-center'}>
                                                                <div className="col-sm-4">
                                                                    <h5 className="mb-2 ">Listed by</h5>
                                                                    <span className="airmailtxt" title={detailDropList && detailDropList.user && detailDropList.user.userName}>{(detailDropList && detailDropList.user && detailDropList.user.userName ? (`@${detailDropList.user.userName}`) : '')}</span>

                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <h5 className="mb-2 ">Airdrop Type</h5>


                                                                    <span>{(detailDropList && (detailDropList.airDropType === airDropService.airDropType.LOCATED_USERS) ? 'Public (Location)' : (detailDropList.airDropType === airDropService.airDropType.SELECTED_USERS) ? "Selected Users" : "Public")}</span>

                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <h5 className="mb-2 ">Airdrop Starts On</h5>
                                                                    <h6 className="mb-0">{detailDropList && detailDropList.airDropDate && moment(detailDropList.airDropDate).format('DD MMM, YYYY [at] h:mma')}</h6>
                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div className="purchasebox purc-hgt">
                                                            <div className="row ">

                                                                <div className="col-sm-12">
                                                                    <div className="table-responsive1">
                                                                        <table className="table custable adtable">
                                                                            {(detailDropList && detailDropList.airDropType == 1) ?
                                                                                <tbody>
                                                                                    <tr>
                                                                                        {this.checkCurrentUsers() && <th>Allowed to claim</th>}
                                                                                        <th>User</th>
                                                                                        <th>Status</th>
                                                                                        <th>Claimed On</th>
                                                                                    </tr>
                                                                                    {airdropDetailArray && airdropDetailArray.map((items, index) => (
                                                                                        (((items && items.user && items.user.userName)) || (notClaimed[index])) &&
                                                                                        <tr className={!(items.status) ? "strikeout" : ''}>
                                                                                            {this.checkCurrentUsers() && <td><a className={'flowtop'} style={{ display: "inline-block" }}>
                                                                                                <span className="overtxt">Allowed to claim</span><Toggle checked={items.status} onChange={() => this.statusChange(items, index, (items && items.user && items.user.userName))} disabled={(items && items.claimed) || ((detailDropList.status === AirDropStatus.WITHDRAWN))} />
                                                                                            </a></td>}
                                                                                            <td ><span className="overtxt">User</span>
                                                                                                <span className="airmailtxt" data-toggle="tooltip" data-placement="bottom" title={(items && items.user && items.user.userName) ? `@${items.user.userName}` : notClaimed[index]}>
                                                                                                    {(items && items.user && items.user.userName) ? `@${items.user.userName}` : notClaimed[index]}</span></td>
                                                                                            <td><span className="overtxt">Status</span>{(items && items.claimed) ? "Claimed" : (detailDropList.status === AirDropStatus.WITHDRAWN) ? 'Withdrawn' : "Yet to Claim"}</td>
                                                                                            <td><span className="overtxt">Claimed On</span>{(items && items.claimed) ? (moment(items && items.updatedAt).format("DD-MM-YYYY, hh:mm:ss A")) : "-"}</td>

                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody> : (
                                                                                    <tbody>
                                                                                        <tr>

                                                                                            <th>User</th>
                                                                                            <th>Status</th>
                                                                                            <th>Claimed On</th>
                                                                                        </tr>
                                                                                        {detailDropList && detailDropList.airDropDetails && detailDropList.airDropDetails.map((items, index) => (
                                                                                            <tr>
                                                                                                <td><span className="overtxt">User</span>
                                                                                                    <span className="airmailtxt" data-toggle="tooltip" data-placement="bottom" title={(items && items.user && items.user.userName) && `@${items.user.userName}`}>{(items && items.user && items.user.userName) ? `@${items.user.userName}` : '-'}</span></td>
                                                                                                <td><span className="overtxt">Status</span>{(items && items.user && items.user.userName) ? "Claimed" : (items.withdrawn) ? 'Withdrawn' : "Yet to Claim"}</td>
                                                                                                <td><span className="overtxt">Claimed On</span>{(items && items.user && items.user.userName) ? (moment(items && items.updatedAt).format("DD-MM-YYYY, hh:mm:ss A")) : "-"}</td>

                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                )}
                                                                        </table>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>


                                                        {(notClaimedPersons !== 0) &&
                                                            (detailDropList && detailDropList.airDropSelectedUsers && detailDropList.airDropSelectedUsers.length > 0) &&
                                                            ((localUserDetail && localUserDetail.data && localUserDetail.data.authUser && localUserDetail.data.authUser.id) == (detailDropList && detailDropList.user && detailDropList.user && detailDropList.user.id))
                                                            && <div className="purchasebox ">
                                                                <h5 className="col-md-6">Enter an Email or Disrupt Username</h5>
                                                                <div className="form-group adconfg">


                                                                    <textarea className="form-control mt-3" value={this.state.selectedMembers} onChange={this.handleSelectedMembers} disabled={!((this.checkUsersDisabled()))} ></textarea>

                                                                    <div className="usernamelilac small mt-1 word-break-word">Enter username or email as comma separated values (For ex. kitty,robert,johndoe@example.com,janedoe@example.com)</div>
                                                                    {this.state.selectedMembers && !this.usernameValidation(this.state.selectedMembers) && <div className="mandatory mt-2">{"Please enter proper username. Username has only contain alphabets,numbers and atleast 4 characters long. or Enter proper email address"}</div>}<br />
                                                                    {this.state.notClaimedUsers && <div className="comboxgray">
                                                                        {this.state.selectedMembers && this.state.selectedMembers.split(',').map(items => (
                                                                            (items !== "") &&
                                                                            <a className="badge bg-dark mr-1 p-2" >
                                                                                {this.checkIsEmail(items) ?
                                                                                    <span>
                                                                                        <i className="fa fa-envelope" aria-hidden="true"></i> &nbsp;{items}
                                                                                    </span>
                                                                                    :
                                                                                    `@${items}`}</a>)
                                                                        )}
                                                                    </div>}
                                                                </div>
                                                                {(detailDropList.status === AirDropStatus.WITHDRAWN) ? '' : <hr className="my-3 d-block" />}
                                                                {((detailDropList.status === AirDropStatus.WITHDRAWN) || !(this.checkUsersDisabled())) ? '' : <div className="form-group">
                                                                    <input type="checkbox" className="form-check-input mr-3" id="alertCheck" checked={this.state.isAlertOff} onChange={this.handleSendAlertToUsers} disabled={!((this.checkUsersDisabled()))}/>
                                                                    <label className="form-check-label" htmlFor="alertCheck">Send email alert to collectors</label>
                                                                </div>}

                                                            </div>}
                                                        <hr className="my-3 d-block" />
                                                        {((notClaimedPersons !== 0)) && (detailDropList && detailDropList.airDropSelectedUsers && detailDropList.airDropSelectedUsers.length > 0) &&
                                                            ((localUserDetail && localUserDetail.data && localUserDetail.data.authUser && localUserDetail.data.authUser.id) == (detailDropList && detailDropList.user && detailDropList.user && detailDropList.user.id)) &&
                                                            <div className="row">
                                                                {(detailDropList.status === AirDropStatus.WITHDRAWN) ? '' : <div className="col-md-12 text-center">
                                                                    {this.isRestrictedType(detailDropList) ? <div className="usernamelilac mt-1 word-break-word mb-2">Due to certain complications and issues, we have decided to temporarily restrict the edit functionality for airdrops with more than {this.limitedAirdropCount} editions., If you want, please withdraw this airdrop and relist again. If you have any queries, please contact Disrupt Art admin</div> : ''}

                                                                    <button type="button" className="btn btn-primary px-3" disabled={this.state.disableList} onClick={() => { this.handleAirDrop((detailDropList)) }}>Save</button>
                                                                </div>}

                                                            </div>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <InvalidPage />
                            )}
                        </>
                    )}
                </div>

            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dropList: state.airdrop.airdropDetailUser,
        isCheckDrop: state.airdrop.isCheckAirDropDetail,
        aidropReminderRes: state.airdrop.aidropReminderRes,
        airdropReminderFailed: state.airdrop.airdropReminderFailed,
        wallet: state.wallet.wallet,
        usersList: state.user.usersList,
    }
}
const actionCreators = {
    getDrops: airdropActions.getAirDropDetailUser,
    resetDropArt: airdropActions.resetAirDropDetailUser,
    airdropreminder: airdropActions.airdropReminder,
    resetairdropreminder: airdropActions.resetAirdropReminder,
    resetairdropreminderfailed: airdropActions.resetAirdropReminderFailed,
    resetGetUsers: userAction.resetGetUsers,
    getUserProfile: userAction.getUsers,

}
const connectAirDropDetailUser = connect(mapStateToProps, actionCreators)(AirDropDetail)
export { connectAirDropDetailUser as AirDropDetail }
