import { collectorConstants } from "../../constants"
import { collectorServices } from "services"
import PubSub from 'pubsub-js';


export const collectorActions = {
    detailCollector,
    getCollectors
}

function showLoader() {
	PubSub.publish('msg', true);
}

function hideLoader() {
	PubSub.publish('msg', false);
} 

function detailCollector(userId){
    return dispatch => {
        showLoader()
        collectorServices.detailCollector(userId)
        .then(collector => {
            if((collector && collector.statusCode) === 200){
                
                dispatch({type:collectorConstants.GET_DETAIL_COLLECTOR,collectorDetail:collector})
                hideLoader()
            }
        },error => {
            
            // console.log("error",error)
            hideLoader()
        })
    }
}
function getCollectors(obj){
    return dispatch => {
        showLoader()
        collectorServices.getCollectors(obj)
        .then(collector => {
            if((collector && collector.statusCode) === 200){
                
                dispatch({type:collectorConstants.GET_ALL_COLLECTORS,collectors:collector})
                hideLoader()
            }
        },error => {
            
            // console.log("error",error)
            hideLoader()
        })
    }
}





