import { configPath } from "../config";
import { checkMintedTokens, flowDecimal, getUSDValue, localAirdropPage, localArtPage, localDropPage, ReactPagination } from "helpers";
import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Select from 'react-select';
import { ArtPreview } from "./art-preview/art-preview";
import { customStyles, options } from "./gallery";
import history from 'helpers/history';
import { artActions, categoryActions, tagActions } from "store/actions";
import { connect } from "react-redux";
import moment from "moment";
import PubSub from 'pubsub-js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SolarSystemLoading } from "react-loadingg";
import { artServices } from "services";
import { getFlowUsdValue } from "helpers/getFlowUsd";

let controller = new AbortController();

class CategoryView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artsList: [],
            offSet: 0,
            recordLimit: 12,
            totalCount: '',
            currentUser: '',
            sortBy: "createdAt",
            sortOrder: "DESC",
            isCheck: false,
            isCheckArt: false,
            show: false,

            // filtTypes,
            // selectedFiltType: 1,
            selectedOption: { value: 'DESC', label: 'Most Recent' },
            categoryName: '',
            testMessage: '',
            selectedTag: '',
            categoryTypes: [],
            categoryOption: { value: 0, label: "Select Category" },
            hasMore: true,
            loader: true
        }
    }
    showLoader = () => {
        PubSub.publish('msg', true);
    }

    hideLoader = () => {
        PubSub.publish('msg', false);
    }
    componentDidMount() {
        // this.props.getAllTrendingTags()
        this.props.getUserCategories()
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        localArtPage()
        localDropPage()
        localAirdropPage()
        this.initialTagSet()
        this.setCount()
    }
    static getDerivedStateFromProps(props, state) {
        if ((props && props.artsList && props.artsList.statusCode === 200)) {

            props.resetGetArts();
            const page = Number(state.recordLimit) + Number(state.offSet)
            if (props.artsList.data.arts.length === 0 || (props.artsList.data.arts.length < (state.recordLimit))) {
                const data = state.artsList.concat((props.artsList && props.artsList.data && props.artsList.data.arts))

                return {
                    hasMore: false,
                    loader: false,
                    artsList: data,
                    offSet: page,
                    isCheckArt: props.isCheckArt,
                }

            }
            const data = state.artsList.concat((props.artsList && props.artsList.data && props.artsList.data.arts))
            console.log(data)
            return {
                artsList: data,
                offSet: page,
                isCheckArt: props.isCheckArt,
            }



        }

        if ((props && props.trendingTags && props.trendingTags.statusCode) === 200) {
            props.resetTrendingTag()
            if (props.trendingTags.data && props.trendingTags.data.trendingTags) {
                return {
                    trendingTags: props.trendingTags.data.trendingTags
                }
            }
        }
        if ((props && props.getCategories && props.getCategories.statusCode) === 200) {
            props.resetCategories()
            if (props.getCategories && props.getCategories.data && props.getCategories.data.tags) {
                let array = []
                props.getCategories.data.tags && props.getCategories.data.tags.length > 0 && props.getCategories.data.tags.map((items) => {
                    let obj = {
                        value: items && items.id,
                        label: <span><i className={items && items.iconClass}></i>&nbsp; {items && items.name && items.name.charAt(0).toUpperCase() + items.name.slice(1)}</span>,
                        name: items && items.name,
                        iconclass: <i className={items && items.iconClass}></i>

                    }
                    array.push(obj)
                })
                return {
                    categoryTypes: array
                }
            }
        }
        if ((props && props.categoryDetails && props.categoryDetails.statusCode) === 200) {
            props.resetCategoryDetail()
            return {
                icon: props.categoryDetails && props.categoryDetails.data && props.categoryDetails.data.category && props.categoryDetails.data.category.iconClass
            }
        }
        return null;
    }
    initialTagSet = () => {
        const { match } = this.props;
        if (match && match.params && match.params.name) {
            this.props.getcategoryDetail(encodeURIComponent(match.params.name))
            this.setState({
                categoryName: match.params.name, selectedTag: match.params.name
            }, () => {
                this.callApi();
            })
        }
    }
    componentDidUpdate(props, state) {
        let nft = configPath.basename
        const checkpath = window.location.pathname && window.location.pathname.split('/')
        if (checkpath && checkpath[1] && checkpath[1].includes('nft')) {
            if ((nft + (props && props.location && props.location.pathname)) != decodeURIComponent(window.location.pathname)) {
                this.initialTagSet()
            }
        } else if ((props && props.location && props.location.pathname) != decodeURIComponent(window.location.pathname)) {
            console.log(props, window.location.pathname)
            this.initialTagSet()
        }

    }
    callApi = () => {
        // this.showLoader()
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // });
        controller = new AbortController();
        let signal = controller.signal;
        const { isFilter, selectedOption, categoryName, categoryOption } = this.state
        let offSet = localStorage.getItem("tagPage")
        this.setState({ forcePage: offSet != null ? ((JSON.parse(offSet)) / this.state.recordLimit) : 0 })

        let obj = {
            offSet: this.state.offSet,
            recordLimit: this.state.recordLimit,
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder,
            signal
        }
        if (categoryName === "all") {
            obj.categoryName = null
        } else {
            // if(categoryOption && categoryOption.value){
            //     obj.type = categoryOption.name
            // }
            obj.categoryName = categoryName
        }
        if (selectedOption.id === 1 || selectedOption.id === 2) {
            obj.sortOrder = selectedOption.value
        } else if (
            (selectedOption.id === 3) ||
            (selectedOption.id === 4) ||
            (selectedOption.id === 5)
        ) {
            obj.rarity = selectedOption.value
        } else {
            obj.sortOrder = this.state.sortOrder
        }
        this.props.getArts(obj)
        // this.props.resetGetArtsForTag()

    }
    handleFilter = (value) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            categoryName: value,
            categoryOption: { value: 0, label: "Select Category" },
            offSet: 0,
            artsList: [],
            hasMore: true,
            loader: true
        }, () => {
            history.push(`/arts/category/${value}`)
        })
    }
    handleSelectChange = (option) => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            selectedOption: option,
            artsList: [],
            hasMore: true,
            loader: true,
            offSet: 0
        }, () => {
            this.callApi()
        })
    }
    refreshFilterTypes = () => {
        controller.abort()
        controller = new AbortController();
        this.setState({
            artsList: [], selectedFiltType: 1, selectedOption: { value: 'DESC', label: 'Most Recent' }, categoryOption: { value: 0, label: "Select Category" }, offSet: 0, hasMore: true,
            loader: true
        }, () => {
            this.callApi()
        })
    }
    navigationCheck = (e, items) => {
        if (e.target.tagName === "H6" || (e.target.id === "creatorprofile")) {
            this.gotoCreator(items && items.creator)
        } else {
            this.gotoDetail(items)
        }
    }
    startTime = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return <div className="mt-1 update"><p className="mb-0  usernamelilac">Bidding Closed</p></div>
        }
        return <div className="mt-1 update"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + 'd' : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId === 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    gotoDetail = (items) => {
        if (items && items.drops && items.drops[0] && items.drops[0].id && items.id) {
            history.push(`/art/${items && items.id}#drop${items.drops[0].id}`)
        } else {
            history.push(`/art/${items && items.id}`, { artObj: { isFromArts: true } });
        }

    }
    onPageChange = (data) => {

        let offSet = this.state.recordLimit * (data && data.selected)
        if (this.state.sale) {
            localStorage.setItem("tagPage", JSON.stringify(offSet))
        } else {
            localStorage.setItem("tagPage", JSON.stringify(offSet))

            this.setState({ offSet }, () => this.callApi())
        }

    }
    handleTrendTag = (items) => {
        this.setState({
            selectedTag: items && items.name
        })
        localStorage.setItem('tagPage', "0")
        history.push(`/art/tag/${items && items.name}`)

    }
    handleCategoryChange = (option) => {
        this.setState({
            categoryOption: option,
            categoryName: ''

        }, () => {
            localStorage.setItem("tagPage", JSON.stringify(0))
            this.callApi()
        })
    }
    getUSD = async () => {
        const value = await getFlowUsdValue()
        this.setState({ rate: value })
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    showMarkedPrice = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].markedPrice)) {
            if(items && items.drops && items.drops[0] && items.drops[0].withDapper && items.drops[0].storefrontListingResourceId){
                return <p className="update usernamelilac" title={items.drops[0].markedPrice}>
                {items.drops[0].markedPrice ? (flowDecimal(parseFloat(items.drops[0].markedPrice)) + " USD") : "0 USD"}
                <br />
            </p>
            }else{
                return <p className="update usernamelilac d-none" title={items.drops[0].markedPrice}>
                {items.drops[0].markedPrice ? (flowDecimal(parseFloat(items.drops[0].markedPrice)) + ' ' + "FLOW") : "0 FLOW"}
                <br />
            </p>
            }
            
        } else {
            if (!this.dayClosed(items && items.drops && items.drops[0])) {
                if (items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id) {
                    return <p className="mb-0 d-none">
                        <span className="usernamelilac " title={items.drops[0].auctionn && items.drops[0].auction.lastBid && (flowDecimal(parseFloat(items.drops[0].auction.lastBid)))}>
                            {
                                (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' ' + "FLOW") :
                                    <span className="usernamelilac ">
                                        {items.drops[0].auction.id && items.drops[0].auction.id != null &&
                                            (items.drops[0].auction.startPrice && `${(flowDecimal(parseFloat(items.drops[0].auction.startPrice)) + ' ' + "FLOW")}`)}
                                    </span>)}</span><br />
                    </p>

                }
            } else {
                if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.lastBid)) {
                    return <p className="mb-0 d-none">
                        <span className="usernamelilac " title={items.drops[0].auction.lastBid && (flowDecimal(parseFloat(items.drops[0].auction.lastBid)))}>
                            {items.drops[0].auction.id != null && (items.drops[0].auction.lastBid ? (flowDecimal(parseFloat(items.drops[0].auction.lastBid)) + ' ' + "FLOW") : '')}
                        </span><br />
                       
                    </p>
                }
            }



        }

    }
    checkdropsLength = (tokens, totalTokens, items) => {
        if (isNaN(items && items.tokenGroupId)) {
            if (items && items.tokenGroupId && (items.tokenGroupId).includes("-")) {
                return 1
            }
        }
        if (totalTokens && Number.isInteger(totalTokens)) {
            return tokens
        } else {
            return 1
        }
    }
    showEditionWhenDrops = (items) => {

        if (items && items.drops && items.drops[0] && items.drops[0].markedPrice) {
            if (items && items.groups && items.groups != null) {
                return <p className="mb-0 small mt-1">
                    <span className="usernamelilac">{this.checkdropsLength((items && items.drops[0] && items.drops[0].totalTokens), (items && items.toatalMintedTokens), items.group)}
                    </span> of &nbsp;
                    {(items && items.toatalMintedTokens) ?
                        (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {(this.checkEDitionCount(items))}</p>
            } else {
                return <p className="mb-0 small mt-1">1 of <span className="usernamelilac">1</span> Total Edition</p>
            }
        } else {
            return ''
        }
    }
    showEditionWhenNotDrops = (items) => {
        if (items && items.drops && items.drops[0]) {
            return ''
        } else {
            if (items && items.groups && items.groups != null) {
                return <p className="mb-0 small mt-1">{(items && items.toatalMintedTokens) ? (checkMintedTokens(items.groups, items.toatalMintedTokens)) : 1} {(checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1 ? "Editions" : "Edition"}  </p>
            } else {
                return <p className="mb-0 small mt-1">Edition 1 </p>
            }
        }
    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    showUSDValue = (items) => {
        if (!this.dayClosed(items && items.drops && items.drops[0])) {
            if (items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null) {
                return <p className="mb-0 d-none">
                    <span className="usernamelilac " >
                        {(items.drops[0].auction.lastBid ? (
                            <span className="small text-white " title={getUSDValue(this.state.rate, items.drops[0].auction.lastBid)}>{(getUSDValue((this.state.rate), (items.drops[0].auction.lastBid))) + ' USD'}</span>)
                            : <span className="usernamelilac ">
                                {(items.drops[0].auction.startPrice &&
                                    <span className="small text-white" title={getUSDValue(this.state.rate, items.drops[0].auction.startPrice)}>{(getUSDValue((this.state.rate), (items.drops[0].auction.startPrice))) + ' USD'}</span>
                                )}
                            </span>)}</span><br />
                </p>

            }
        } else {
            return ''
        }
    }
    chechUser = (creator, owner) => {
        if ((creator && creator.userName) === (owner && owner.userName)) {
            return true
        } else {
            return false
        }
    }
    showListedUser = (items) => {
        if ((items && items.drops && items.drops[0] && items.drops[0].user && items.drops[0].user.userName)) {
            return <div className="userwithpic ws-wrap">
                <span id="uploadArt">Listed by</span>&nbsp;<span className={(this.chechUser((items && items.creator), (items.drops[0].user))) ? "usernamelilac mb-0 creatordetalist" : "mb-0 usernamelilac"} style={{ cursor: "pointer", maxWidth: "150px", textOverflow: 'ellipsis', overflow: 'hidden' }} title={items.drops[0].user.userName} id="ownername">{items.drops[0].user && items.drops[0].user.userName ? (`${items.drops[0].user.userName}`)  : ''}</span>
            </div>
        }

    }
    getImageSource = (items) => {
        if((items && items.localPreview && items.localPreview !== null)){
            return process.env.REACT_APP_PROFILE_IMG_PATH + items.localPreview
        }else{
            if(items && items.thumbnailHash){
                return items.thumbnailHash
            }
        }
    }
    getMimetype = (items) => {
        if((items && items.localPreview && items.localPreview !== null && items.localPreviewMimeType !== null)){
            return items.localPreviewMimeType
        }else{
            if(items && items.thumbnailMimeType){
                return items.thumbnailMimeType
            }
        }
    }
    checkEDitionCount = (items) => {
        if(items && items.groups && items.groups !== null && items.toatalMintedTokens){
        if((checkMintedTokens(items.groups, items.toatalMintedTokens)) > 1){
            return ' Total Editions'
        }else{
            return ' Total Edition'
        }
    }else{
        return 'Total Edition'
    }

    }
    componentWillUnmount() {
        controller.abort()
        controller = new AbortController();
    }
    render() {
        const { artsList, isCheckArt, categoryName, trendingTags, categoryTypes, hasMore, loader } = this.state
        const metaTitle = categoryName && categoryName !== '' ? `${categoryName.charAt(0).toUpperCase() + categoryName.slice(1)} on ${process.env.REACT_APP_BASE_SITE}` : ''
        return (
            <div className="gallery artpage">
                <div className="container">

                    <Fragment>
                        <Helmet>
                            <title>{metaTitle}</title>
                            <meta name="title" content="Explore NFT Arts"></meta>
                            <meta name="description" content={`${process.env.REACT_APP_SITE_CONTRACT} NFT Marketplace  - Mint, Sell & Collect Digital Collectible NFTs on Flow Blockchain`}></meta>
                        </Helmet>
                        <div className="pagetitabtn">
                            <div className="droplisttit">
                                <div className="probtns activebtnscus py-0 row">
                                    <div className="col-6"> <h1 className="mb-3 d-inline-block"> <i className={this.state.icon}></i> {categoryName && categoryName !== '' ? `${categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}` : 'Category'}</h1></div>
                                    <div className="col-6 text-right"><button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.refreshFilterTypes}><i className="fa fa-refresh" aria-hidden="true" ></i></button></div>
                                </div>
                            </div>


                            <div className="probtns activebtnscus py-0 row">
                                <div className="col-md-7">
                                    <ul className="list-unstyled mb-0">
                                        {/* <li><button type="button" className={(this.state.categoryName === "all") ? "btn btn-outline-dark btn-sm active" : 'btn btn-primary-gradient  btn-sm'} onClick={() => { this.handleFilter("all") }}>All</button></li> */}
                                        {/*                                                 
                                                {categoryTypes && categoryTypes.length > 0 && categoryTypes.map(item => (
                                                    <li><button type="button" className={(this.state.categoryName == (item && item.name)) ? "btn btn-outline-dark btn-sm active" : 'btn btn-primary-gradient  btn-sm'} onClick={() => { this.handleFilter(item && item.name) }}>
                                                        {item && item.iconclass} &nbsp;
                                                        {item && item.name && item.name.charAt(0).toUpperCase() + item.name.slice(1)}</button></li>
                                                ))} */}
                                    </ul>
                                </div>
                                <div className="col-md-5">
                                    <ul className="list-unstyled mb-0 text-right">
                                        <li style={{ display: "inline-block", verticalAlign: "middle", height: "45px", textAlign: 'center' }} >

                                            <Select
                                                value={this.state.selectedOption}
                                                onChange={this.handleSelectChange}
                                                options={options}
                                                styles={customStyles}
                                                menuPortalTarget={document.body}
                                                isSearchable={false}
                                            />
                                        </li>


                                    </ul>
                                </div>
                            </div>



                            <hr />
                        </div>
                        <div>
                            <InfiniteScroll
                                dataLength={artsList && artsList.length} //This is important field to render the next data
                                next={(artsList && artsList.length > 0) ? this.callApi : ''}
                                hasMore={hasMore}
                                loader={
                                    <div className="solarloader" >
                                        <SolarSystemLoading />

                                    </div>}
                                endMessage={
                                    (artsList.length > 0) ?
                                        <p style={{ textAlign: 'center', fontSize: "20px" }} className="usernamelilac">
                                            <b>You have reached the end of list</b>
                                        </p> : (!loader && <div className="col-md-4 offset-md-4 text-center">
                                            <img src={`/${configPath.imageAssets}/no-nft-yet.png`} className="mw-100" alt="" />
                                        </div>)
                                }
                                style={{ overflow: "hidden" }}

                            >
                                <div className="gallery">
                                    <div className="row">
                                        {artsList && artsList.map((items, index) => (
                                            <div className="col-sm-6 col-md-4" key={items && items.id} onClick={(e) => this.navigationCheck(e, items)}>
                                                <div className="cardlist artimglist">
                                                    <ArtPreview src={items && items.thumbnailHash} alt={items && items.title} isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType} />

                                                    <div className="cont">
                                                        <div className="row">
                                                            <div className="col-sm-12 mb-3">
                                                                <div className="piccent">
                                                                    <div className="upic" style={{ cursor: "pointer" }}>
                                                                        <span className="artcovimg">
                                                                            <span className="artcov"></span>
                                                                            <span className="artcovimginset no-proimg">
                                                                                <img src={items && items.creator && items.creator.profileImage ? `${process.env.REACT_APP_PROFILE_IMG_PATH}${items && items.creator && items.creator.profileImage}` : `../${configPath.imageAssets}/no-proimg.jpg`} alt="" id="creatorprofile" />
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <h3 className="userdescrip" title={items && items.title}>{items && items.title}</h3>
                                                                <h6 className={(this.chechUser((items && items.creator),(items && items.drops && items.drops[0] && items.drops[0].user))) ? "usernamelilac mb-0 text-center piccenth6" : "mb-0 text-center piccenth6"} style={{ cursor: "pointer", }} title={items && items.creator && items.creator.fullName}>{items && items.creator && ((items.creator.fullName && items.creator.fullName.includes('@')) ? items.creator.fullName : (`by ${items.creator.fullName}`))}</h6>


                                                            </div>
                                                            <div className="col-6">
                                                                {this.showEditionWhenDrops(items)}
                                                                {this.showListedUser(items)}

                                                                {(items && items.drops && items.drops[0]) ? "" : <div className="userwithpic ">

                                                                    <h6 className="mb-0" style={{ cursor: "pointer", maxWidth: "110px" }} title={items && items.creator && items.creator.userName}>{items && items.creator && ((items.creator.userName && items.creator.userName.includes('@')) ? items.creator.userName : (`@${items.creator.userName}`))}</h6>

                                                                </div>}

                                                            </div>
                                                            <div className="col-6 text-right" style={{ cursor: "pointer" }}>
                                                                {this.showEditionWhenNotDrops(items)}

                                                                {this.showMarkedPrice(items)}
                                                                {this.showUSDValue(items)}
                                                                {this.state.show && items && items.drops && items.drops[0] && items.drops[0].auction && items.drops[0].auction.id && items.drops[0].auction.id != null && this.startTime(items && items.drops && items.drops[0])
                                                                }
                                                            </div>
                                                            

                                                        </div>


                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                </div>
                            </InfiniteScroll>
                        </div>
                    </Fragment>
                </div >

            </div >
        )
    }
}
const mapStateToProps = (state) => {
    return {
        artsList: state.art.categoryArts,
        isCheckArt: state.art.isCheckCategoryArt,
        trendingTags: state.tags.trendingTags,
        getCategories: state.category.userCategories,
        categoryDetails: state.art.categoryDetail
    }
}
const actionCreators = {
    getArts: artActions.getArtsbyCategory,
    resetGetArts: artActions.resetgetArtsbyCategory,
    getAllTrendingTags: tagActions.getAllTrendingTags,
    resetTrendingTag: tagActions.resetTrendingTags,
    getUserCategories: categoryActions.getUserCategories,
    resetCategories: categoryActions.resetGetUserCategories,
    getcategoryDetail: artActions.getCategoryArtDetail,
    resetCategoryDetail: artActions.resetGetCategoryArtDetail
}
const connectCategoryView = connect(mapStateToProps, actionCreators)(CategoryView)
export { connectCategoryView as CategoryView }