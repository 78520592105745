import React, { Component } from "react";

class CommonError extends Component {
  constructor(props) {
    super(props);
    this.state={}
  }

  render() {
    const { disabledMessage } = this.props;

    return (
      <>
        <div className="container-fluid text-center">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <div className="error-template userdisableview">
                <i className="fa fa-ban mw-100 my-5 fa-5x" aria-hidden="true"></i>

                <h3 className="mb-2 usernamelilac text-uppercase">{disabledMessage}</h3>
                {disabledMessage && disabledMessage.includes("admin") ? (
                  <h4 className="mb-2">If you have any queries, please contact Disrupt Art admin</h4>
                ) : (
                  ""
                )}

                {disabledMessage && disabledMessage.includes("admin") ? <p className="mb-0">The Team</p> : ""}
                {disabledMessage && disabledMessage.includes("admin") ? <p className="usernamelilac">DisruptArt</p> : ""}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CommonError;
