import { configPath } from 'config'
import { isConnectWallet, isWebLogin } from 'helpers'
import history from 'helpers/history'
import React, { Component, Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import * as fcl from "@onflow/fcl";
import { discoverServices, fetchService, walletServices } from 'services'
import PubSub from 'pubsub-js';
import { connect } from 'react-redux'
import { discoverActions, userAction, walletActions } from 'store/actions'
import Modal from 'react-modal'
import { CreatorRequest } from "./creatorRequest";
import { Alert } from "helpers/alert";
import { Helmet } from 'react-helmet';
import { discoverConstants } from 'constants';
import { ArtPreview } from './art-preview/art-preview';
import { Gallery } from './gallery';
import { Creators } from './creators';
import moment from 'moment';
import { Preview } from './preview';
import { ProfilePreview } from './profilePreview';
import { OwlCarousal } from './owlCarousalCreator';
import { OwlCarousalArt } from './owlCarousalArt';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

class Discover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spotLight: [],
            featureArts: [],
            featureCreators: [],
            featureCollectors: [],
            sortBy: 'updatedAt',
            sortOrder: 'DESC',
            offSet: 0,
            recordLimit: 4,
            artRecord: 4,
            creatorRecord: 6,
            collectorRecord: 8,
            isArtTrue: false,
            isCreatorTrue: false,
            isCollectorTrue: false,
            isSpotCheck: false,
            isFeatureArt: false,
            isCreator: false,
            isCollector: false,
            spotlightOn: true,
            show: false,
            slideIndex: 0,
            isRender : false
        }
        this.ownCaurosalRef = React.createRef();

    }
    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        this.setCount()
        this.getSpotLight('', '')
        this.getFeatureArt(this.state.offSet, this.state.artRecord)
        this.getFeatureCreator(this.state.offSet, this.state.creatorRecord)
        this.getFeatureCollector(this.state.offSet, this.state.collectorRecord)
        this.setTime()


    }
    getSpotLight = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.gotoTop()
        this.props.getAllSpotLightArts(obj)
    }
    getFeatureArt = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureArts(obj)
    }
    getFeatureCreator = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureCreators(obj)
    }
    getFeatureCollector = (offSet, recordLimit) => {
        let obj = {
            sortBy: this.state.sortBy,
            sortOrder: this.state.sortOrder
        }
        if (offSet != 0) {
            obj['offSet'] = null
        } else {
            obj['offSet'] = 0
        }
        if (recordLimit) {
            obj['recordLimit'] = recordLimit
        }
        this.props.getAllFeatureCollectors(obj)
    }
    getAllFeatureArt = () => {
        this.setState({ isArtTrue: true })
        this.getFeatureArt('', '')
    }
    getLessFeatureArt = () => {
        this.setState({ isArtTrue: false })
        this.getFeatureArt(this.state.offSet, this.state.artRecord)
    }
    getAllFeatureCreator = () => {
        this.setState({ isCreatorTrue: true })
        this.getFeatureCreator('', '')
    }
    getLessFeatureCreator = () => {
        this.setState({ isCreatorTrue: false })
        this.getFeatureCreator(this.state.offSet, this.state.creatorRecord)
    }
    getAllFeatureCollector = () => {
        this.setState({ isCollectorTrue: true })
        this.getFeatureCollector('', '')
    }
    getLessFeatureCollector = () => {
        this.setState({ isCollectorTrue: false })
        this.getFeatureCollector(this.state.offSet, this.state.collectorRecord)
    }
    static getDerivedStateFromProps(props, state) {
        if (props && props.spotArts && props.spotArts.statusCode == 200) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
            props.resetSpotLight()

            return {
                spotLight: props && props.spotArts && props.spotArts.data && props.spotArts.data.entity,
                isSpotCheck: true
            }
        }
        if (props && props.featureArts && props.featureArts.statusCode == 200) {
            return {
                featureArts: props && props.featureArts && props.featureArts.data && props.featureArts.data.entity,
                isFeatureArt: true,
              
            }
        }
        if (props && props.featureCreators && props.featureCreators.statusCode == 200) {
            return {
                featureCreators: props && props.featureCreators && props.featureCreators.data && props.featureCreators.data.entity,
                isCreator: true
            }
        }
        if (props && props.featureCollectors && props.featureCollectors.statusCode == 200) {
            return {
                featureCollectors: props && props.featureCollectors && props.featureCollectors.data && props.featureCollectors.data.entity,
                isCollector: true
            }
        }
    }
    componentDidUpdate(){ }
    
    showLoader() { PubSub.publish('msg', true); }
    hideLoader() { PubSub.publish('msg', false); }
    gotoDetail = (items) => {

        let webLog = localStorage.getItem("webLogin")
        let loginDetail = JSON.parse(webLog)

        if ((items && items.drops && items.drops.length == 0) && (loginDetail && loginDetail.data && loginDetail.data.authUser && loginDetail.data.authUser.userName) == (items && items.owner && items.owner.userName)) {

            history.push(`/list/${items && items.tokenId}`)

        } else {

            history.push(`/art/${items && items.id}`)
        }


    }
    gotoCreator = (owner) => {
        if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 1) {
            history.push(`/creator/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress, userName: owner && owner.userName } })
        } else if (owner && owner.id && owner.id != null && owner.roleId && owner.roleId == 2) {
            history.push(`/collector/@${owner.userName && encodeURIComponent(owner.userName)}`, { obj: { userAddress: owner && owner.userAddress } })
        }
    }
    handleSpotlight = () => {
        this.setState({ spotlightOn: true })
    }
    handleArts = () => {
        history.push('arts?page=1&limit=12')
    }
    handleCreator = () => {
        history.push('creators?page=1&limit=12')
    }
    dayClosed = (items) => {
        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance < 0) {
            return true
        } else {
            return false
        }
    }
    startTime = (items) => {

        var futureDate = (items && items.auction && items.auction.endDay && (moment(items.auction.endDay).format('MMM DD, YYYY HH:mm:ss')))
        var countDownDate = new Date(futureDate).getTime();
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            return false
        }
        // this.setState({seconds,days,hours,minutes})
        // setInterval(this.startTime(items), 1000);
        return <div className="mt-1"><p className="mb-0 "><span className="usernamelilac">{(`${days ? days + "d" : ''}  ${hours}h  ${minutes}m  ${seconds}s`)}</span></p></div>

    }
    setCount = (items) => {
        setInterval(() => {
            this.setState({ show: true })
        }, 1000)
    }
    getEdition = (items) => {

        if ((items && items.drops && items.drops[0] && items.drops[0].auction && items.dropsCount != null)) {
            return <h6>Edition : 1</h6>
        } else if ((items && items.drops && items.drops[0] && items.dropsEdition != null)) {
            return (<h6>Edition :  {items.dropsEdition}</h6>)
        } else if ((items.toalCount != null && items.dropsCount != null)) {
            return <h6>Edition : {((items.toalCount) - (items.dropsCount))}</h6>
        } else if ((items.toalCount != null && items.dropsCount == null)) {
            return <h6>Edition : {((items.toalCount))}</h6>
        } else {
            <h6>Edition : 1</h6>
        }
    }
    gotoPath = (e, items) => {
        // console.log(e.target)
        if (e.target.tagName == "H3") {
            this.gotoDetail(items)
        } else if (e.target.tagName == "SPAN") {
            this.gotoCreator(items && items.creator)
        }
    }
    gotoArts = () => {
        history.push('/arts')
    }
    gotoCreators = () => {
        history.push('/creators')
    }
    gotoCollectors = () => {
        history.push('/collectors')
    }
    gotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  
    handleCarousal = (index) => {
        const {slideIndex} = this.state
        const indexIs = slideIndex + index
        this.showSlides(indexIs)

    }
    showSlides = (index) => {
        const {featureArts} = this.state
        // console.log(featureArts,featureArts && featureArts.length,index)
        if((index) >= (featureArts && featureArts.length)){
            this.setState({slideIndex:0})
        }else{
            this.setState({slideIndex:index})

        }
    }
    setTime = (items) => {
        setInterval(() => {
            this.handleCarousal(1)
        }, 3000)
    }
    render() {
        // console.log("===imag path===",process.env.REACT_APP_PROFILE_IMG_PATH)
        const { spotLight, featureArts, featureCreators, featureCollectors, isSpotCheck, isFeatureArt, isCreator, isCollector } = this.state
        const metaDesc = `Discover amazing NFT collections from various disruptor creators. ${process.env.REACT_APP_BASE_SITE} Marketplace's featured and spotlight NFTs`

        
        return (
            <Fragment>
                <Helmet>
                    <title>Discover</title>
                    <meta name="title" content={`Discover NFTs - ${process.env.REACT_APP_BASE_SITE}`}></meta>
                    <meta name="description" content={metaDesc}></meta>
                </Helmet>
                <div ref={el => this.el = el} />

                {((spotLight && spotLight.length > 0) || (featureArts && featureArts.length > 0) || (featureCreators && featureCreators.length > 0) || (featureCollectors && featureCollectors.length > 0)) ? <div className="container">
                    <div className="pagetitabtn">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="mb-0 mt-3 text-center">Spotlight</h1>
                            </div>
                        </div>
                    </div>
                    <div className="dicoverarts">
                        {spotLight && spotLight.map((items) => (
                            <div className="bigart" >
                                <div className="row">
                                    <div className="col-md-6 offset-md-3">
                                        <div className="bigartimg" onClick={() => this.gotoDetail(items)}>

                                            <ArtPreview src={(items && items.thumbnailHash) && items.thumbnailHash} className="mw-100" alt="" thumbnailHeight="100px" thumbnailWidth="100px" height="300px" isPremiumArt={items && items.isPremiumArt} showControls={true} autoPlay={true} mimeType={items && items.thumbnailMimeType}/>
                                            <div className="bigarthovertxt">
                                                <h3 className="bigarttit" onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title} <span >{items && items.creator && items.creator.fullName && `by ${items.creator.fullName}`}</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                       

                                        {/* <h3 className="bigarttit" onClick={() => this.gotoDetail(items)} id="uploadArt">{items && items.title}</h3>
                                        <span className="ownerDec" onClick={() => this.gotoCreator(items && items.creator)}>{items && items.creator && items.creator.fullName && `by @${items.creator.fullName}`}</span> */}
                                        <div className="bidown mb-5">
                                            <div className="row ">
                                                <div className="col-6" onClick={() => this.gotoDetail(items)} id="uploadArt">
                                                   

                                                </div>
                                             
                                            </div>
                                        </div>
                                        {/* <a href="" className="btn btn-primary">View Artwork</a> */}
                                    </div>
                                </div>
                            </div>))}

                        {spotLight && spotLight.length == 0 && isSpotCheck &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoArts}>Explore Art</button>

                            </div>
                        }
                        <div className="mediumart">
                            <h2>Featured Art</h2>
                         
                            <hr />
                            {featureArts && featureArts.length > 0 &&
                            <OwlCarousalArt />}

                              
                        </div>
                        {featureArts && featureArts.length == 0 && isFeatureArt &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoArts}>Explore Art</button>
                            </div>
                        }   


                        <div className="smallarts">
                            <h2>Featured Creators
                                {featureCreators && featureCreators.length > 6 && !this.state.isCreatorTrue ? <span className="float-right" id="uploadArt"><a className="usernamelilac" onClick={this.getAllFeatureCreator}>View all</a></span> :
                                    (featureCreators && featureCreators.length > 6 && <span className="float-right" id="uploadArt"><a className="usernamelilac" onClick={this.getLessFeatureCreator}>Less</a></span>)}
                            </h2>
                            <hr />
                            <OwlCarousal data = {featureCreators}/>
                            
                        </div>
                        {featureCreators && featureCreators.length == 0 && isCreator &&
                            <div className="col-md-4 offset-md-4 text-center">
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br />
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoCreators}>Browse Creators</button>
                                {/* <img src={`../${configPath.imageAssets}/no-creators-yet.png`} className="mw-100" alt="" /> */}
                            </div>
                        }

                        {/* {*<div className="smallarts">
                            <h2>Top Collectors
                              
                                {featureCollectors && featureCollectors.length > 8 && !this.state.isCollectorTrue ? <span className="float-right" id="uploadArt"><a onClick={this.getAllFeatureCollector} className="usernamelilac">View all</a></span> :
                                    (featureCollectors && featureCollectors.length > 8 && <span className="float-right" id="uploadArt"><a onClick={this.getLessFeatureCollector} className="usernamelilac">Less</a></span>)}
                            </h2>
                            <hr />
                            <div className="row">
                                {featureCollectors && featureCollectors.map((items => (
                                    <div className="col-md-3 col-6" onClick={(e) => { e.preventDefault(); history.push(`/collector/@${items && items.userName && encodeURIComponent(items.userName)}`) }}>
                                        <div className="cardlist artimglist">
                                            <div className="artcovimglink mt-0">
                                                <h4 className="mb-3">{items && items.fullName}</h4>
                                                <span className="artcovimg ">
                                                    <span className="artcov"></span>
                                                    <span className="artcovimginset">
                                                        <Preview src={items && items.coverImage && (`${process.env.REACT_APP_PROFILE_IMG_PATH}${items.coverImage}`)} alt="" />
                                                    </span>
                                                    <a href="" className="viewcollection">View Collections</a>
                                                </span>
                                                <div className="badgeview">
                                                    <div className="picpro">
                                                        <span className="artcovimg">
                                                            <span className="artcov "></span>
                                                            <span className="artcovimginset no-proimg">
                                                                <ProfilePreview src={items && items.profileImage && `${process.env.REACT_APP_PROFILE_IMG_PATH}${items.profileImage}`} alt="" />
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <h2 className="usernameClick">{items && items.userName && `@${items.userName}`}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)))}

                            </div>
                        </div> 
                        {featureCollectors && featureCollectors.length == 0 && isCollector &&
                            <div className="col-md-4 offset-md-4 text-center">
                                
                                <i className="fa fa-compass fa-3x mb-3" aria-hidden="true"></i><br/>
                                <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm" onClick={this.gotoCollectors}>Browse Collectors</button>
                            </div>
                        }
                        */}



                    </div>
                </div> :
                    ((isSpotCheck && isCollector && isCreator && isFeatureArt) &&
                        <div className="col-md-4 offset-md-4 text-center">

                            <img src={`../${configPath.imageAssets}/sand-img.png`} className="mw-100 mb-5" alt="" style={{ width: "150px", height: "150px", marginTop: "15px" }} />
                            <h5 className="usernamelilac  mb-3">Stay tuned., Some cool NFTs are on the way!</h5>
                            <button type="button" className=" btn btn-rounded btn-outline-dark btn-sm mb-3" onClick={this.gotoArts}>Explore</button>
                        </div>)
                }

            </Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        spotArts: state.discover.spotArts,
        featureArts: state.discover.featureArts,
        featureCreators: state.discover.featureCreators,
        featureCollectors: state.discover.featureCollectors
    }
}
const actionCreators = {
    getAllSpotLightArts: discoverActions.getAllSpotLightArts,
    resetSpotLight: discoverActions.resetSpotLight,
    getAllFeatureArts: discoverActions.getAllFeatureArts,
    getAllFeatureCreators: discoverActions.getAllFeatureCreators,
    getAllFeatureCollectors: discoverActions.getAllFeatureCollectors
}

const connectDiscover = connect(mapStateToProps, actionCreators)(Discover)
export { connectDiscover as Discover }

