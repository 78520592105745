import { configPath } from "config";
import { accessHeader } from "helpers"
import PubSub from 'pubsub-js';
import { fetchService } from "./fetch-service";
import history from "helpers/history";
import { flowGetLatestBlock } from "utils/flow-latest-block";

const DropEventType = {
	FOR_SALE: 'FOR_SALE',
	WITHDRAW: 'WITHDRAW',
	PURCHASE: 'PURCHASE',
	PRICE_CHANGE: 'PRICE_CHANGE',
	FOR_AUCTION: 'FOR_AUCTION',
	NEW_BID: 'NEW_BID',
	AUCTION_SETTLED: 'AUCTION_SETTLED',
	AUCTION_CANCELLED: 'AUCTION_CANCELLED'
};

export const dropServices = {
	uploadDrop,
	getDropArt,
	getDetailDropArt,
	getUserDrop,
	getOneUserDrop,
	getAuctionDetail,
	saveDropEventHistory,
	DropEventType,
	saveEvent,
	dropDetail,
	withdrawArt,
}

function uploadDrop(obj) {
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/create`, requestOptions).then(handleResponse, handleError)
}

function getDropArt(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		signal : obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''
	let type = obj && obj.type ? "&type=" + obj.type : ''

	let artType = obj && obj.artType ? "&artType=" + obj.artType : ''

	let rarity = obj && obj.rarity ? "&rarity=" + obj.rarity : ''
	let closedAuction = obj && obj.closedAuction ? "&closedAuction=" + obj.closedAuction : ''
	let tag = obj && obj.tag ? "&tag=" + obj.tag : ''
	// let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''

	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder + type +  artType + rarity + closedAuction + tag

	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop` + params, requestOptions).then(handleResponse, handleError)
}

function getDetailDropArt(obj) {
	const requestOptions = {
		method: 'GET',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
	}
	let params = obj
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/${params}`, requestOptions).then(handleResponse, handleError)
}

function getUserDrop(obj) {
	const requestOptions = {
		method: "GET",
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		signal: obj.signal
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''
	let showHiddenArts = obj && obj.showHiddenArts ? '&showHiddenArts=' + obj.showHiddenArts : ''


	// let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''

	params = "?pageOffset=" + offSet + recordLimit + sortBy + sortOrder + showHiddenArts

	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/owner/user` + params, requestOptions).then(handleResponse, handleError)
}

function getOneUserDrop(obj, userId) {
	const requestOptions = {
		method: 'GET',
		headers: { ...accessHeader(), 'accept': 'application/json', 'Content-Type': 'application/json' },
	}
	let params = ''
	let offSet = obj && obj.offSet

	let recordLimit = obj && obj.recordLimit ? '&recordLimit=' + obj.recordLimit : ''

	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	let notInDrops = obj && obj.notInDrops ? `notInDrops=` + obj.notInDrops : ''


	if (notInDrops) {
		params = notInDrops + "&pageOffset=" + offSet + recordLimit
	} else {
		params = "&pageOffset=" + offSet + recordLimit + sortBy + sortOrder
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/user/${userId}?` + params, requestOptions).then(handleResponse, handleError)
}
function getAuctionDetail(auctionId) {
	const requestOptions = {
		method: 'GET',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' }
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/auctionDetails/${auctionId}`, requestOptions).then(handleResponse, handleError)
}
function dropDetail(dropId,artId,obj){
	const requestOptions = {
		method : 'GET',
		headers : {...accessHeader(),'accept' : 'application/json','Content-Type':'application/json'},
	}
	let params = ''
	let sortBy = obj && obj.sortBy ? "&sortBy=" + obj.sortBy : ''

	let sortOrder = obj && obj.sortOrder ? '&sortOrder=' + obj.sortOrder : ''

	params = sortBy + sortOrder

	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/${dropId}/art/${artId}?` + params ,requestOptions).then(handleResponse,handleError)
}
function saveDropEventHistory(tokenGroupId, blockHeight, editions, eventType, tokenId) {
	const requestBody = {
		tokenGroupId: Number.isInteger(tokenGroupId) ? tokenGroupId : undefined,
		blockHeight,
		count: editions,
		eventType,
		tokenId
	};
	const requestOptions = {
		method: 'POST',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: JSON.stringify(requestBody)
	}
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/drop/event-history`, requestOptions).then(handleResponseForSaveEvents, handleError);
}
async function saveEvent(tokenGroupId, count, eventType, tokenId) {
	let groupId = 0;
	if (Number.isInteger(tokenGroupId)) {
		groupId = tokenGroupId;
	} else {
		groupId = null;
	}
	const latestBlockRes = await flowGetLatestBlock();
	if (latestBlockRes.status === 200 && !latestBlockRes.data.Error) {
		const latestBlock = parseInt(
			JSON.parse(latestBlockRes.data.Value)
		);
		await dropServices.saveDropEventHistory(
			groupId,
			latestBlock,
			count,
			eventType,
			tokenId
		);
		// console.log("%cSaved drop event history", 'font-size:20px')
		return true;
	} else {
		if (latestBlockRes.data.Error) {
			const error = latestBlockRes.data.Error
                
			const splitError = error.split(':');
			if (
				splitError[2] &&
				splitError[2].includes("Session expired")
			) {
				return "Session Expired.Please reconnect your wallet account";
			} else if (
				splitError[2]
			) {
				return splitError[2]
			} else if (splitError[1]) {
				if(error && error.toLowerCase().includes('declined')){
					return "User rejected signature"
				}else{
					return splitError[1]
				}
			} else {
				return splitError[0];
			}
		}
	}
	return true
}
function withdrawArt(obj,dropId) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...accessHeader(), 'Content-Type': 'application/json', 'accept': 'application/json' },
		body: obj
	};
	return fetchService.fetchResource(`${configPath.apiUrl}/drop-management/dapper/drop`, requestOptions).then(handleWithdrawResponse, handleError)
}

function handleResponse(response) {
	if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		
		if(data.statusCode === 400){
			
			if (data.message && data.message.toLowerCase().includes('token(s) not available in drop')) {
				history.push('/notFound');
			}else if (data.message && data.message.toLowerCase().includes('not found')) {
				history.push('/notFound');
			}
		}
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			} else if (response.status === 400) {
				if (data.message && data.message.toLowerCase().includes('drop not found')) {
					history.push('/notFound');
				}else if (data.message && data.message.toLowerCase().includes('auction closed')) {
					history.push('/notFound');
				}else{
					return {
						statusCode : 400,
						message : data.message
					}
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
}

function handleError(err) {
	try {
		if (err instanceof TypeError) {
			PubSub.publish('msg', false)
			return Promise.reject(err)
		} else {
			PubSub.publish('msg', false)
			return Promise.reject(`Unexpected error occured : ${err}`)
		}
	}
	catch (err) {
		console.log(err)
		return Promise.reject(`Unexpected error occured : ${err}`)
	}
}
function handleResponseForSaveEvents(response) {
	if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		
		if(data.statusCode === 400){
			
			if (data.message && data.message.toLowerCase().includes('token(s) not available in drop')) {
				history.push('/notFound');
			}else if (data.message && data.message.toLowerCase().includes('not found')) {
				history.push('/notFound');
			}else{
				if (data.message) {
					return Promise.reject(data.message)
				}else{
					return Promise.reject("Failed to save event")
				}
			}
		}
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			} else if (response.status === 400) {
				if (data.message && data.message.toLowerCase().includes('drop not found')) {
					history.push('/notFound');
				}else if (data.message && data.message.toLowerCase().includes('auction closed')) {
					history.push('/notFound');
				}else{
					return Promise.reject("Failed to save event")
				}
				console.log(data)

			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return data;
	});
}
}
function handleWithdrawResponse(response) {
	if(!(response && response.res)){
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		
		if(data.statusCode === 400){
			
			if (data.message && data.message.toLowerCase().includes('token(s) not available in drop')) {
				history.push('/notFound');
			}else if (data.message && data.message.toLowerCase().includes('not found')) {
				history.push('/notFound');
			}else{
				if(data && data.message){
					return Promise.reject(`Failed to withdraw art : ${data.message}`)
				}else{
					return Promise.reject("Failed to withdraw art")
				}
			}
		}
		if (!response.ok) {
			if (response.status === 401) {
				// logout();
			} else if (response.status === 400) {
				if (data.message && data.message.toLowerCase().includes('drop not found')) {
					history.push('/notFound');
				}else if (data.message && data.message.toLowerCase().includes('auction closed')) {
					history.push('/notFound');
				}else{
					if(data && data.message){
						return Promise.reject(`Failed to withdraw art : ${data.message}`)
					}else{
						return Promise.reject("Failed to withdraw art")
					}
				}
			}
			const validationErorrs = [];
            if (data &&
                data.data &&
                data.data.errorCode &&
                (
                    data.data.errorCode === 'INVALID_REQUEST_BODY' ||
                    data.data.errorCode === 'INVALID_REQUEST_QUERY_PARAMETERS' ||
                    data.data.errorCode === 'INVALID_REQUEST_URL_PARAMETERS'
                ) &&
                data.data.data[0] &&
                Array.isArray(data.data.data[0])) {

                for (const detail of data.data.data) {
                    if (Array.isArray(detail)) {
                        for (const message of detail) {
                            if (typeof message === 'string') {
                                validationErorrs.push(message);
                                console.log(validationErorrs, data.data)

                            }
                        }
                    }
                }
                return Promise.reject(validationErorrs[0]);

            }
            else {
                const error = (data && data.message)
                return Promise.reject(error)
            }
		}
		return {statusCode : 200};
	});
}
}